import { Location } from 'history'
import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router'
import { useConfirm } from '../useConfirm'

export function useNavigateConfirm(enabled: boolean, message: string) {
  const history = useHistory()
  const confirm = useConfirm()

  const ask = useCallback(
    async (
      location: Location<unknown>,
      unblock: ReturnType<typeof history.block>
    ) => {
      const { data: answer } = await confirm(message)
      if (!answer) return

      unblock()
      history.push(location.pathname, {
        __useNavigateConfirmBypass: true,
      })
    },
    [confirm, history, message]
  )

  useEffect(() => {
    if (!enabled) return

    const unblock = history.block(location => {
      if (
        (location.state as null | { __useNavigateConfirmBypass: boolean })?.[
          '__useNavigateConfirmBypass'
        ]
      )
        return

      ask(location, unblock)
      return false
    })

    return () => {
      unblock()
    }
  }, [ask, enabled, history])
}
