import { ComponentSwitch as Switch, Icon } from '@ur/react-components'
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { getFileIconAndColor, splitFilename } from 'util/file'
import { Attachment } from '../types'
import { checkIsImage } from '../util'

const DeleteButton = styled.div`
  position: absolute;
  display: none;

  top: -3px;
  right: -3px;
  width: 20px;
  height: 20px;

  cursor: pointer;
  border-radius: 50%;
  background-color: #9b0808;
  transition: transform 0.2s ease-out, opacity 0.2s linear,
    background-color 0.2s linear;
  opacity: 0.75;
  transform-origin: 100% 0%;

  &::after {
    content: '\f00d';

    position: absolute;
    top: calc(50%);
    left: calc(50%);
    transform: translate(-50%, -50%);

    font-family: 'Font Awesome 5 Pro';
    font-weight: 500;
    font-size: 0.8rem;
    color: white;
  }

  &:hover {
    transform: scale(1.1);
    opacity: 1;
    background-color: #c71818;
  }
`
interface WrapperProps {
  deletable: boolean
  contentHeight: string
}

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  border-radius: 6px;

  ${props =>
    props.contentHeight &&
    css`
      width: calc(${props.contentHeight} * 4 / 3);
    `}

  ${props =>
    props.deletable &&
    css`
      &:hover ${DeleteButton} {
        display: block;
      }
    `};

  img {
    height: 100%;
    border-radius: 6px;
  }

  ${props => props.theme.media.mobile} {
    ${props =>
      props.deletable &&
      css`
        ${DeleteButton} {
          display: block;
        }
      `};
  }
`
interface ContentWrapperProps {
  height: string | undefined
  clickable: boolean
}
const ContentWrapper = styled.div<ContentWrapperProps>`
  display: flex;
  justify-content: center;
  height: ${props => props.height};
  margin-bottom: 4px;
  overflow: hidden;

  border-radius: 6px;

  ${props =>
    props.clickable &&
    css`
      cursor: pointer;
    `};

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  img {
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
  }
`

const OtherAttachment = styled(ContentWrapper)<ContentWrapperProps>`
  height: ${props => props.height};
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
`
interface AttachmentFooterProps {
  contentHeight: string
}
const AttachmentFooter = styled.div<AttachmentFooterProps>`
  font-size: 0.9rem;
  padding: 0 0.2rem 0.2rem 0.2rem;
  height: 1.1rem;
  color: ${props => props.theme.colors.primary};
  position: relative;

  .longName {
    display: none;
  }
  .shortName {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .filename {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &:hover {
    .longName {
      min-width: calc(${props => props.contentHeight} * 4 / 3);
      display: block;
      box-sizing: border-box;
      text-align: center;
      background: white;
      padding: 0.5rem;
      border-radius: 6px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      z-index: 999;
      position: absolute;
      float: left;
      left: 50%;
      word-break: break-all;
      transform: translateX(-50%);
    }
    .shortName {
      display: none;
    }
  }
`

interface PostAttachmentProps {
  attachment: Attachment
  nonClickable?: boolean
  deletable?: boolean
  height?: string

  onClick?: (attachment: Attachment) => void
  onDelete?: (attachment: Attachment) => void
}

export const PostAttachment: React.VFC<PostAttachmentProps> = ({
  attachment,
  nonClickable = false,
  deletable = false,
  height = '100px',

  onClick,
  onDelete = () => void 0,
}) => {
  const isImage = checkIsImage(attachment.file)

  const [loadedFile, setLoadedFile] = useState('')

  useEffect(() => {
    if (typeof attachment.file === 'string') {
      setLoadedFile(attachment.file)
      return
    }

    const reader = new FileReader()
    reader.onload = evt => {
      const result = evt.target?.result?.toString()
      if (!result) return
      setLoadedFile(result)
    }
    reader.readAsDataURL(attachment.file)
  }, [attachment.file])

  function handleDelete(evt: React.MouseEvent<HTMLDivElement>) {
    evt.stopPropagation()
    if (!deletable) return

    onDelete(attachment)
  }

  const [icon, iconColor] = getFileIconAndColor(attachment.file)

  const shortFilename = splitFilename(attachment.name)

  return (
    <Wrapper
      title={attachment.name}
      deletable={deletable}
      contentHeight={height}
    >
      {deletable && <DeleteButton role="button" onClick={handleDelete} />}
      <Switch>
        <Switch.Case case={isImage}>
          <ContentWrapper
            height={height}
            clickable={!nonClickable}
            onClick={() => onClick?.(attachment)}
          >
            <img src={loadedFile} alt={attachment.name} />
          </ContentWrapper>
        </Switch.Case>

        <Switch.Case default>
          <OtherAttachment
            height={height}
            clickable={!nonClickable}
            onClick={() => onClick?.(attachment)}
          >
            <Icon icon={icon} color={iconColor} size="4rem" />
          </OtherAttachment>
        </Switch.Case>
      </Switch>

      <AttachmentFooter contentHeight={height}>
        <div className="shortName">
          <span className="filename">{shortFilename[0]}</span>
          <span>{shortFilename[1]}</span>
        </div>
        <div className="longName">{attachment.name}</div>
      </AttachmentFooter>
    </Wrapper>
  )
}
