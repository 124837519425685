import React, { useState, createContext } from 'react'

const SearchContext = createContext<[string, (val: string) => any]>([
  '',
  (val: string) => '',
])

const SearchProvider: React.FC = ({ children }) => {
  const [searchText, setSearchText] = useState('')

  return (
    <SearchContext.Provider value={[searchText, setSearchText]}>
      {children}
    </SearchContext.Provider>
  )
}

export { SearchContext, SearchProvider }
