import { formatDistanceToNow as formatDistanceToNowBase } from 'date-fns'
import { getLocale } from './getLocale'

export interface FormatDistanceToNowOptions {
  addSuffix?: boolean
  includeSeconds?: boolean
}
export function formatDistanceToNow(
  firstDate: Date,
  options: FormatDistanceToNowOptions = {}
) {
  return formatDistanceToNowBase(firstDate, {
    ...options,
    locale: getLocale(),
  })
}
