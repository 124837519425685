import { Icon } from '@ur/react-components'
import { useConfirm } from 'util/hooks'
import {
    SalaryWorkTimeNode,
} from 'modules/companies'
import { Table, TableMenu } from 'components'
import styled from 'styled-components'
import { useSalaryMutations } from '../../util'
import { useTranslate } from '@ur/react-hooks'
import { isMobileOnly } from 'react-device-detect'
import { useHistory } from 'react-router'

const Arrows = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
`

const Arrow = styled(Icon)`
  cursor: pointer;
`

const ReorderTableCell = styled.td`
  width: 1px;
  padding: 0 0 0 1rem!important;
`

interface SalaryWorkTimesTableProps {
    salaryWorkTimes: SalaryWorkTimeNode[]
}

export const SalaryWorkTimesTables: React.VFC<SalaryWorkTimesTableProps> = ({
    salaryWorkTimes
}) => {
    const translations = useTranslate({
      info: 'settings.info.salary',
      salarySettings: 'settings.salary-and-work-time',
      salaryType: 'settings.salary-type',

      defaultSalaryCode: 'settings.default-salary-code',

      module: 'common.module',
      name: 'common.name',
      dailyLimit: 'settings.daily-work-limit-short',
      weeklyLimit: 'settings.period-work-limit-short',
      pauseDuration: 'settings.pause-duration-short',
      pauseThreshold: 'settings.pause-threshold-short',
      salaryPeriod: 'settings.period',
      prompt: {
          deleteTitle: 'settings.prompts.salary-setting-delete-title',
          delete: ['settings.prompts.delete', { name: '' }],
      },

      priority: 'common.priority',

      delete: 'common.delete',
      edit: 'common.edit',
      add: 'common.add',
      departments: 'common.departments',

      salaryTypes: {
        FIXED: 'settings.fixed-salary',
        HOUR: 'settings.hourly-salary',
        DAY: 'settings.daily-salary',
      }
      
    })
    const confirm = useConfirm()
    const history = useHistory()
    const mutations = useSalaryMutations()

    async function handleDelete(salaryWorkTime: SalaryWorkTimeNode) {
        const { data: answer } = await confirm(translations.prompt.deleteTitle)
        if (!answer) return
    
        mutations.delete({
          variables: {
            id: salaryWorkTime.id,
          },
        })
      }

    return (
        <Table
            noShadow
            noBorders
            noHeaderMargin
            noRowMargin
          >
            <thead>
              <tr>
                {salaryWorkTimes.length > 1 && (
                  <th className="center" colSpan={2}>{translations.priority}</th>
                )}
                <th className="center">{translations.name}</th>
                <th className="center">{translations.salaryType}</th>

                {!isMobileOnly && (
                  <>
                    <th className="center">{translations.salaryPeriod}</th>
                  </>
                )}
                <th></th>
              </tr>
            </thead>

            <tbody>
                {salaryWorkTimes.map((salaryWorkTime, i) => {
                  const pushToEditPage = () => salaryWorkTime.user?.id 
                    ? history.push(`/users/${salaryWorkTime.user?.id}/salary/${salaryWorkTime.id}`) 
                    : history.push('/settings/salary/' + salaryWorkTime.id)
                  return <tr key={salaryWorkTime.id}>
                    {salaryWorkTimes.length > 1 && (
                      <>
                      <ReorderTableCell>
                        <Arrows>
                          {i !== 0 && (
                            <Arrow
                              icon="chevron-up"
                              hoverColor='secondary'
                              margin="0 0 4px 0"
                              onClick={() => mutations.swap({
                                variables: {
                                  input: {
                                    first: salaryWorkTime.id,
                                    second: salaryWorkTimes[i - 1].id,
                                  }
                                },
                              })}
                            />
                          )}
                          
                          {i !== salaryWorkTimes.length - 1 && (
                            <Arrow
                              icon="chevron-down"
                              hoverColor='secondary'
                              margin="0 0 4px 0"
                              onClick={() => mutations.swap({
                                variables: {
                                  input: {
                                    first: salaryWorkTime.id,
                                    second: salaryWorkTimes[i + 1].id,
                                  }
                                },
                              })}
                            />
                          )}
                        </Arrows>
                      </ReorderTableCell>

                      <td className="center">{i+1}</td>
                      </>
                    )}
                    <td className="center">{salaryWorkTime.name}</td>
                    <td className="center">
                      {translations.salaryTypes[salaryWorkTime.salaryType]}
                    </td>

                    {!isMobileOnly && (
                      <>
                        <td className="center">
                          {salaryWorkTime.salaryDateStart}.&nbsp;-&nbsp;
                          {salaryWorkTime.salaryDateEnd}.
                        </td>
                      </>
                    )}

                    <TableMenu
                      items={[
                        {
                          label: translations.edit,
                          onClick: () => pushToEditPage(),
                        },
                        {
                          label: translations.delete,
                          onClick: () => handleDelete(salaryWorkTime),
                        },
                      ]}
                    />
                  </tr>
                })
              }
            </tbody>
          </Table>
    )
}