import { MouseEventHandler } from 'react'
import styled from 'styled-components'

interface StyledCellProps {
  centered: boolean
}
const StyledCell = styled.td<StyledCellProps>`
  & > section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    align-items: ${props => props.centered && 'center'};
  }
`
export interface CellProps {
  className?: string
  narrow?: boolean
  centered?: boolean

  onClick?: MouseEventHandler<HTMLTableCellElement>
}
export const Cell: React.FC<CellProps> = ({
  children,
  className,
  narrow = false,
  centered = false,

  onClick,
}) => (
  <StyledCell
    className={className}
    width={narrow ? '1px' : undefined}
    centered={!!centered}
    onClick={onClick}
  >
    <section>{children}</section>
  </StyledCell>
)
