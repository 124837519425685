import { useQuery } from '@apollo/client'
import { usePrompt } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Button, Input } from 'components'
import { Card } from 'components/Card'
import uniq from 'lodash/uniq'
import { ALL_VEHICLES_QUERY } from 'modules/vehicles/queries'
import {
  CreateVehicleByRegNrModalResolve,
  VehicleFilter,
} from 'modules/vehicles/types'
import {
  AllVehiclesQuery,
  AllVehiclesQueryVariables,
} from 'modules/vehicles/types.graphql'
import { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useBreadcrumbs } from 'util/hooks'
import { FleetTable } from '../components'
import { CreateVehicleByRegNrModal } from '../components/CreateVehicleByRegNrModal'
import { FleetFilter } from '../components/FleetFilter'
import { useVehicleMutations } from '../mutations.hooks'

const Wrapper = styled.div`
  ${props => props.theme.layout.default};
`

const Controls = styled(Card)`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  gap: 1rem;

  margin-bottom: 1rem;
  padding: 1rem;
`

interface FleetOverviewProps {}

export const FleetOverview: React.VFC<FleetOverviewProps> = () => {
  const addPrompt = usePrompt()
  const { mutations } = useVehicleMutations()
  const translations = useTranslate({
    title: 'vehicles.fleet-overview',
    addVehicle: 'vehicles.add-vehicle',
  })
  const { setOverrides, overrides } = useBreadcrumbs()

  /* CreateModal */
  async function handleCreateByRegNrVehicle() {
    const { data } = await addPrompt<CreateVehicleByRegNrModalResolve | null>(
      resolve => <CreateVehicleByRegNrModal onSubmit={resolve} />
    )
    if (!data) return
    const { form } = data
    await mutations.createByRegNr({
      variables: {
        input: form,
      },
    })
  }
  /* Forms */

  const {
    formValues: filter,
    formChangeHandler: handler,
    updateForm,
  } = useForm<VehicleFilter>({
    values: {
      query: undefined,
      owned: true,
      orderBy: 'registrationNumber',
      excludeTypes: [],
    },
    config: {
      storage: {
        storeFormState: true,
        retrieveFormStateOnMount: true,
        sessionStorage: true,
        storeFormStateName: 'firmadok__fleet-overview-filter',
      },
    },
  })
  const { data } = useQuery<AllVehiclesQuery, AllVehiclesQueryVariables>(
    ALL_VEHICLES_QUERY,
    {
      variables: {
        q: filter.query,
        currentlyOwned: filter.owned,
        orderBy: filter.orderBy,
      },
    }
  )

  const vehicles = useMemo(
    () => data?.allVehicles.edges.map(edge => edge.node) ?? [],
    [data]
  )
  const vehicleTypes = useMemo(
    () =>
      uniq(vehicles.map(vehicle => vehicle.vehicleType)).filter(
        type => type !== null
      ),
    [vehicles]
  )

  const includedVehicles = vehicles.filter(vehicle => !filter.excludeTypes?.includes(vehicle.vehicleType) ?? true)

  useEffect(() => {
    if (overrides['vehicle-fleet']) return
    setOverrides({ 'vehicle-fleet': translations.title })
  }, [overrides, setOverrides, translations.title])

  return (
    <Wrapper>
      <h2>{translations.title}</h2>
      <Controls>
        <Input
          value={filter.query ?? ''}
          type="search"
          fullWidth
          onChange={handler('query')}
        />
        <FleetFilter
          filter={filter}
          vehicleTypes={vehicleTypes}
          onUpdate={updateForm}
        />
        <Button
          iconLeftProps={{ icon: 'plus', size: '1rem', type: 'solid' }}
          color="primary"
          onClick={handleCreateByRegNrVehicle}
        >
          {translations.addVehicle}
        </Button>
      </Controls>
      <FleetTable
        vehicles={includedVehicles}
        filter={filter}
        onSort={handler('orderBy')}
      />
    </Wrapper>
  )
}
