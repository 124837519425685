import { useClickOutside, useTranslate } from '@ur/react-hooks'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { FilterButton, Popup } from './components'

const Wrapper = styled.div`
  position: relative;
`

export const FilterPopup: React.FC = ({ children }) => {
  const [open, setOpen] = useState(false)
  const translations = useTranslate({
    filter: 'common.filter',
  })

  const popupRef = useRef<HTMLElement>(null)
  useClickOutside(popupRef, () => setOpen(false), false)

  function handleClick(value: boolean) {
    return function (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
      event.stopPropagation()
      setOpen(value)
    }
  }

  return (
    <Wrapper>
      <FilterButton
        iconLeftProps={{ icon: 'filter' }}
        iconRightProps={{ icon: 'chevron-down' }}
        onClick={handleClick(true)}
      >
        {translations.filter}
      </FilterButton>

      {open && (
        <Popup ref={popupRef}>
          <FilterButton
            open
            iconLeftProps={{ icon: 'filter' }}
            iconRightProps={{ icon: 'check', type: 'light' }}
            onClick={handleClick(false)}
          >
            {translations.filter}
          </FilterButton>
          {children}
        </Popup>
      )}
    </Wrapper>
  )
}
