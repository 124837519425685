/* VehicleCell */

import styled from 'styled-components'
import { Cell } from '../Cell'
import { useTranslate } from '@ur/react-hooks'

const StyledCell = styled(Cell)`
  position: relative;
  width: 100px;
  max-width: 100px;
  min-width: 100px;
`

const StyledVehicles = styled.div<{expandable: boolean}>`
  display: block;
  background-color: inherit;

  min-width: 90px;
  margin-top: 2px;
  padding: 8px 10px 4px;

  font-size: 0.9rem;
  font-weight: 600;
  border-radius: 6px;
  border: 1px solid ${props => props.theme.colors.text.dark};
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
`
interface VehicleProps {
  className?: string

  plates: (string | null)[]
}
export const Vehicles: React.VFC<VehicleProps> = ({ plates, className }) => {
  const translations = useTranslate({
    vehicleExpandable: ['common.vehicle-expandable-text', {amount: 0}],
  })

  if (plates.length === 0) return null

  const text = plates.length > 1 ? translations.vehicleExpandable({ amount: plates.length }) : plates[0]

  return <StyledVehicles 
      className={className}
      expandable={plates.length > 1}
    >
      {text}
    </StyledVehicles>
}

interface VehicleCellProps extends VehicleProps {}
export const VehicleCell: React.VFC<VehicleCellProps> = ({
  ...vehicleProps
}) => (
  <StyledCell {...vehicleProps}>
    <Vehicles {...vehicleProps} />
  </StyledCell>
)
