import styled, { css } from 'styled-components'
import { Button, ButtonProps } from 'components/Button'

const Wrapper = styled.div`
  height: 50px;
  display: flex;
  z-index: 0;
  margin-bottom: 2rem;
`

export interface Tab {
  text: JSX.Element | string | number
  key: string
}

interface TabsProps {
  activeTab: Tab
  tabs: Tab[]
  onChange: (tab: Tab) => void
}

interface TabButtonProps extends ButtonProps {
  active?: boolean
  first?: boolean
  last?: boolean
}

export const TabButton = styled(Button)<TabButtonProps>`
  border-radius: 5px 5px 0 0;
  padding: 1rem;
  height: 100%;
  border-bottom: 2px solid ${props => props.theme.colors.secondary};
  transition: background-color 0.2s ease;
  ${props =>
    !props.active &&
    css`
      background: none;
      &:hover {
        background: ${props.theme.colors.white};
      }
    `}
`

export const Tabs: React.VFC<TabsProps> = ({ activeTab, tabs, onChange }) => {
  return (
    <Wrapper>
      {tabs.map(tab => {
        return (
          <TabButton
            active={tab.key === activeTab.key}
            key={tab.key}
            onClick={() => onChange(tab)}
          >
            {tab.text}
          </TabButton>
        )
      })}
    </Wrapper>
  )
}
