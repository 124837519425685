import { useMutation } from "@apollo/client"
import { useToast } from "@ur/react-components"
import { useTranslate } from "@ur/react-hooks"
import { CREATE_DEPARTMENT_MUTATION, CREATE_HOLIDAY_ALLOWANCE_MUTATION, CREATE_SALARY_CODE_MUTATION, DELETE_DEPARTMENT_MUTATION, DELETE_HOLIDAY_ALLOWANCE_MUTATION, DELETE_SALARY_CODE_MUTATION, PATCH_DEPARTMENT_MUTATION, PATCH_HOLIDAY_ALLOWANCE_MUTATION, PATCH_SALARY_CODE_MUTATION } from "modules/companies/mutations"
import { ALL_DEPARTMENTS_QUERY, ALL_HOLIDAY_ALLOWANCES_QUERY, ALL_SALARY_CODES_QUERY } from "modules/companies/queries"
import { CreateDepartmentMutation, CreateDepartmentMutationVariables, DeleteDepartmentMutation, DeleteDepartmentMutationVariables, PatchDepartmentMutation, PatchDepartmentMutationVariables,
    CreateSalaryCodeMutation, CreateSalaryCodeMutationVariables, DeleteSalaryCodeMutation, DeleteSalaryCodeMutationVariables, PatchSalaryCodeMutation, PatchSalaryCodeMutationVariables, PatchHolidayAllowanceMutation, PatchHolidayAllowanceMutationVariables, DeleteHolidayAllowanceMutation, DeleteHolidayAllowanceMutationVariables, CreateHolidayAllowanceMutation, CreateHolidayAllowanceMutationVariables } from "modules/companies/types.graphql"
import { useOnErrorAuto } from "util/hooks"



export function useDepartmentMutations() {

    const addToast = useToast()
    const onError = useOnErrorAuto()
    const translations = useTranslate({
        create: 'companies.toasts.department-created',
        update: 'companies.toasts.department-updated',
        delete: 'companies.toasts.department-deleted',
    })

    const [createDepartment] = useMutation<
        CreateDepartmentMutation,
        CreateDepartmentMutationVariables
    >(CREATE_DEPARTMENT_MUTATION, {
        onCompleted: () => {
            addToast(
                'success',
                translations.create,
            )
        },
        onError: (error) => onError()(error),
        awaitRefetchQueries: true,
        refetchQueries: [{ query: ALL_DEPARTMENTS_QUERY }],
    })
    
    const [patchDepartment] = useMutation<
        PatchDepartmentMutation,
        PatchDepartmentMutationVariables
    >(PATCH_DEPARTMENT_MUTATION, {
        onCompleted: () => {
            addToast(
                'success',
                translations.update,
            )
        },
        onError: (error) => onError()(error),
        awaitRefetchQueries: true,
        refetchQueries: [{ query: ALL_DEPARTMENTS_QUERY }],
    })
    
    const [deleteDepartment] = useMutation<
        DeleteDepartmentMutation,
        DeleteDepartmentMutationVariables
    >(DELETE_DEPARTMENT_MUTATION, {
        onCompleted: () => {
            addToast(
                'success',
                translations.delete,
            )
        },
        onError: (error) => onError()(error),
        awaitRefetchQueries: true,
        refetchQueries: [{ query: ALL_DEPARTMENTS_QUERY }],
    })
    
    return { createDepartment, patchDepartment, deleteDepartment }
}

export function useSalaryCodeMutations() {

    const addToast = useToast()
    const onError = useOnErrorAuto()
    const translations = useTranslate({
        create: 'companies.toasts.salary-code-created',
        update: 'companies.toasts.salary-code-updated',
        delete: 'companies.toasts.salary-code-deleted',
    })

    const [createSalaryCode] = useMutation<
        CreateSalaryCodeMutation,
        CreateSalaryCodeMutationVariables
    >(CREATE_SALARY_CODE_MUTATION, {
        onCompleted: () => {
            addToast(
                'success',
                translations.create,
            )
        },
        onError: (error) => onError()(error),
        awaitRefetchQueries: true,
        refetchQueries: [
            { query: ALL_SALARY_CODES_QUERY, variables: { showInManualTimeEntryOvertime: false } },
            { query: ALL_SALARY_CODES_QUERY, variables: { showInManualTimeEntryOvertime: false, isDiet: false } },
            { query: ALL_SALARY_CODES_QUERY, variables: { showInManualTimeEntryOvertime: false, isDiet: true } }
        ],
    })
    
    const [patchSalaryCode] = useMutation<
        PatchSalaryCodeMutation,
        PatchSalaryCodeMutationVariables
    >(PATCH_SALARY_CODE_MUTATION, {
        onCompleted: () => {
            addToast(
                'success',
                translations.update,
            )
        },
        onError: (error) => onError()(error),
        awaitRefetchQueries: true,
        refetchQueries: [
            { query: ALL_SALARY_CODES_QUERY, variables: { showInManualTimeEntryOvertime: false } },
            { query: ALL_SALARY_CODES_QUERY, variables: { showInManualTimeEntryOvertime: false, isDiet: false } },
            { query: ALL_SALARY_CODES_QUERY, variables: { showInManualTimeEntryOvertime: false, isDiet: true } }
        ],
    })
    
    const [deleteSalaryCode] = useMutation<
        DeleteSalaryCodeMutation,
        DeleteSalaryCodeMutationVariables
    >(DELETE_SALARY_CODE_MUTATION, {
        onCompleted: () => {
            addToast(
                'success',
                translations.delete,
            )
        },
        onError: (error) => onError()(error),
        awaitRefetchQueries: true,
        refetchQueries: [
            { query: ALL_SALARY_CODES_QUERY, variables: { showInManualTimeEntryOvertime: false } },
            { query: ALL_SALARY_CODES_QUERY, variables: { showInManualTimeEntryOvertime: false, isDiet: false } },
            { query: ALL_SALARY_CODES_QUERY, variables: { showInManualTimeEntryOvertime: false, isDiet: true } }
        ],
    })
    
    return { createSalaryCode, patchSalaryCode, deleteSalaryCode }
}


export function useHolidayAllowanceMutations() {
    const addToast = useToast()
    const onError = useOnErrorAuto()
    const translations = useTranslate({
        create: 'companies.toasts.holiday-allowance-created',
        update: 'companies.toasts.holiday-allowance-updated',
        delete: 'companies.toasts.holiday-allowance-deleted',
    })

    const [createHolidayAllowance] = useMutation<
        CreateHolidayAllowanceMutation,
        CreateHolidayAllowanceMutationVariables
    >(CREATE_HOLIDAY_ALLOWANCE_MUTATION, {
        onCompleted: () => {
            addToast(
                'success',
                translations.create,
            )
        },
        onError: (error) => onError()(error),
        awaitRefetchQueries: true,
        refetchQueries: [{ query: ALL_HOLIDAY_ALLOWANCES_QUERY }],
    })
    
    const [patchHolidayAllowance] = useMutation<
        PatchHolidayAllowanceMutation,
        PatchHolidayAllowanceMutationVariables
    >(PATCH_HOLIDAY_ALLOWANCE_MUTATION, {
        onCompleted: () => {
            addToast(
                'success',
                translations.update,
            )
        },
        onError: (error) => onError()(error),
        awaitRefetchQueries: true,
        refetchQueries: [{ query: ALL_HOLIDAY_ALLOWANCES_QUERY }],
    })
    
    const [deleteHolidayAllowance] = useMutation<
        DeleteHolidayAllowanceMutation,
        DeleteHolidayAllowanceMutationVariables
    >(DELETE_HOLIDAY_ALLOWANCE_MUTATION, {
        onCompleted: () => {
            addToast(
                'success',
                translations.delete,
            )
        },
        onError: (error) => onError()(error),
        awaitRefetchQueries: true,
        refetchQueries: [{ query: ALL_HOLIDAY_ALLOWANCES_QUERY }],
    })
    
    return { createHolidayAllowance, patchHolidayAllowance, deleteHolidayAllowance }
}