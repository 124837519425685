import { useForm, useTranslate } from '@ur/react-hooks'
import { RegularModal } from 'components/Modal'
import styled from 'styled-components'
import { Field, Form } from 'modules/companies'
import { SelectWeekDays } from 'components/SelectWeekday/SelectWeekDays'
import { TimePicker } from 'components/TimePicker'
import { time } from 'lib/time'
import { TimeGraph } from 'components/TimeGraph/TimeGraph'
import theme from 'theme'
import uniqueId from 'lodash/uniqueId'
import floor from 'lodash/floor'
import { format } from 'date-fns'
import { SalaryCodeSelect } from 'components/Select/SalaryCodeSelect'

const TimePickers = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`

const Tooltip = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
`

const TooltipTitle = styled.div`
  font-weight: 600;
  font-size: 13px;
`

const TooltipText = styled.div`
  font-size: 13px;
`

const ErrorWrapper = styled.div`
  color: ${props => props.theme.colors.red};
  display: flex;
  flex-direction: column;
`

export interface PeriodAddonForm {
  id: string
  periodStart: Date
  periodEnd: Date
  salaryCode: string | null

  salaryCodeName: string

  monday: boolean
  tuesday: boolean
  wednesday: boolean
  thursday: boolean
  friday: boolean
  saturday: boolean
  sunday: boolean
}

export interface OvertimePeriodForm extends PeriodAddonForm {}

interface PeriodPromptProps {
  period: PeriodAddonForm | OvertimePeriodForm | null
  existingPeriods: (PeriodAddonForm | OvertimePeriodForm)[]
  onSubmit: (period: PeriodAddonForm | OvertimePeriodForm | null) => void
  field: "periodAddons" | "overtimePeriods"
}

export const PeriodPrompt: React.UFC<PeriodPromptProps> = ({
  period,
  existingPeriods,
  onSubmit,
  field,
}) => {
  const translations = useTranslate({
    createOvertimePeriod: 'companies.create-overtime-period',
    editOvertimePeriod: 'companies.edit-overtime-period',
    createPeriodAddon: 'companies.create-period-addon',
    editPeriodAddon: 'companies.edit-period-addon',
    save: 'common.save',
    timeInterval: 'common.time-interval',
    selectWeekdays: 'common.select-week-days',
    overtimeRate: 'common.overtime-rate',
    startBeforeEnd: 'timesheets.error.start-before-end',
    requied: 'common.required',
    existingOvertimePeriod: 'companies.existing-overtime-period',
    salaryCode: 'settings.salary-code',
    overlappingPeriodsAreNotAllowed:
      'companies.overlapping-periods-are-not-allowed',
  })

  const {
    formValues,
    formValid,
    updateForm,
    formChangeHandler: handler,
    formErrors: errors,
  } = useForm<PeriodAddonForm>({
    values: {
      id: uniqueId('overtime-period-'),
      periodStart: time(period?.periodStart ?? '18:00').toDate(
        new Date()
      ),
      periodEnd: time(period?.periodEnd ?? '24:00').toDate(new Date()),
      salaryCode: period?.salaryCode ?? null,
      salaryCodeName: period?.salaryCodeName ?? '',

      monday: period?.monday ?? false,
      tuesday: period?.tuesday ?? false,
      wednesday: period?.wednesday ?? false,
      thursday: period?.thursday ?? false,
      friday: period?.friday ?? false,
      saturday: period?.saturday ?? false,
      sunday: period?.sunday ?? false,
    },
    validators: {
      periodStart: (value, values) => {
        if (!value) return translations.requied
        if (
          values.periodEnd.getHours() === 0 &&
          values.periodEnd.getMinutes() === 0
        )
          return null
        if (
          (value.getHours() === values.periodEnd.getHours() &&
            value.getMinutes() === values.periodEnd.getMinutes()) ||
          values.periodEnd < value
        )
          return translations.startBeforeEnd
        return null
      },
      periodEnd: (value, values) => {
        if (!value) return translations.requied
        if (value.getHours() === 0 && value.getMinutes() === 0) return null
        if (
          (value.getHours() === values.periodStart.getHours() &&
            value.getMinutes() === values.periodStart.getMinutes()) ||
          value < values.periodStart
        )
          return translations.startBeforeEnd
        return null
      },
      monday: (value, values) => {
        if (
          !value &&
          !values.tuesday &&
          !values.wednesday &&
          !values.thursday &&
          !values.friday &&
          !values.saturday &&
          !values.sunday
        )
          return translations.requied
        return null
      },
      tuesday: (value, values) => {
        if (
          !value &&
          !values.monday &&
          !values.wednesday &&
          !values.thursday &&
          !values.friday &&
          !values.saturday &&
          !values.sunday
        )
          return translations.requied
        return null
      },
      wednesday: (value, values) => {
        if (
          !value &&
          !values.monday &&
          !values.tuesday &&
          !values.thursday &&
          !values.friday &&
          !values.saturday &&
          !values.sunday
        )
          return translations.requied
        return null
      },
      thursday: (value, values) => {
        if (
          !value &&
          !values.monday &&
          !values.tuesday &&
          !values.wednesday &&
          !values.friday &&
          !values.saturday &&
          !values.sunday
        )
          return translations.requied
        return null
      },
      friday: (value, values) => {
        if (
          !value &&
          !values.monday &&
          !values.tuesday &&
          !values.wednesday &&
          !values.thursday &&
          !values.saturday &&
          !values.sunday
        )
          return translations.requied
        return null
      },
      saturday: (value, values) => {
        if (
          !value &&
          !values.monday &&
          !values.tuesday &&
          !values.wednesday &&
          !values.thursday &&
          !values.friday &&
          !values.sunday
        )
          return translations.requied
        return null
      },
      sunday: (value, values) => {
        if (
          !value &&
          !values.monday &&
          !values.tuesday &&
          !values.wednesday &&
          !values.thursday &&
          !values.friday &&
          !values.saturday
        )
          return translations.requied
        return null
      },
    },
    config: {
      validateImmediately: true,
    },
  })

  const relevantOvertimePeriods = existingPeriods.filter(
    existingPeriod =>
      existingPeriod.id !== period?.id &&
      ((formValues.monday &&
        existingPeriod.monday === formValues.monday) ||
        (formValues.tuesday &&
          existingPeriod.tuesday === formValues.tuesday) ||
        (formValues.wednesday &&
          existingPeriod.wednesday === formValues.wednesday) ||
        (formValues.thursday &&
          existingPeriod.thursday === formValues.thursday) ||
        (formValues.friday &&
          existingPeriod.friday === formValues.friday) ||
        (formValues.saturday &&
          existingPeriod.saturday === formValues.saturday) ||
        (formValues.sunday &&
          existingPeriod.sunday === formValues.sunday))
  )

  const handleDaySelect = (dayIndex: number, selected: boolean) => {
    if (dayIndex === 0) {
      updateForm({ monday: selected })
    } else if (dayIndex === 1) {
      updateForm({ tuesday: selected })
    } else if (dayIndex === 2) {
      updateForm({ wednesday: selected })
    } else if (dayIndex === 3) {
      updateForm({ thursday: selected })
    } else if (dayIndex === 4) {
      updateForm({ friday: selected })
    } else if (dayIndex === 5) {
      updateForm({ saturday: selected })
    } else if (dayIndex === 6) {
      updateForm({ sunday: selected })
    }
  }

  function handleSubmit() {
    if (!formValid) return
    onSubmit({
      ...formValues,
    })
  }

  const periodAddonGraphStartInMinutes = time(formValues.periodStart).reduce(
    'minutes'
  )
  const periodAddonGraphEndInMinutes =
    time(formValues.periodEnd).reduce('minutes') === 0
      ? 24 * 60
      : time(formValues.periodEnd).reduce('minutes')
  const periodAddonGraph = {
    start: periodAddonGraphStartInMinutes,
    end: periodAddonGraphEndInMinutes,
    color: theme.colors.secondary,
    borderColor: '#00ABA7',
  }

  const weekDayValues = [
    formValues.monday,
    formValues.tuesday,
    formValues.wednesday,
    formValues.thursday,
    formValues.friday,
    formValues.saturday,
    formValues.sunday,
  ]

  const weekDayError =
    errors.monday ||
    errors.tuesday ||
    errors.wednesday ||
    errors.thursday ||
    errors.friday ||
    errors.saturday ||
    errors.sunday

  const relevantOvertimePeriodGraphs = relevantOvertimePeriods.map(
    periodAddon => {
      const start = time(periodAddon.periodStart).reduce('minutes')
      const end =
        time(periodAddon.periodEnd).reduce('minutes') === 0
          ? 24 * 60
          : time(periodAddon.periodEnd).reduce('minutes')
      return {
        start: start,
        end: end,
        color: theme.colors.gray6,
        borderColor: '#9399BB',
        tooltip: (
          <Tooltip>
            <TooltipTitle>{translations.existingOvertimePeriod}</TooltipTitle>
            <TooltipText>
              {format(periodAddon.periodStart, 'HH:mm')} -{' '}
              {format(periodAddon.periodEnd, 'HH:mm')}
            </TooltipText>
          </Tooltip>
        ),
      }
    }
  )

  const overlappingPeriods = relevantOvertimePeriodGraphs.filter(
    relevantOvertimePeriodGraph => {
      const start = relevantOvertimePeriodGraph.start
      const end = relevantOvertimePeriodGraph.end

      return (
        (start >= periodAddonGraphStartInMinutes &&
          start < periodAddonGraphEndInMinutes) ||
        (end > periodAddonGraphStartInMinutes &&
          end <= periodAddonGraphEndInMinutes) ||
        (start <= periodAddonGraphStartInMinutes &&
          end >= periodAddonGraphEndInMinutes)
      )
    }
  )

  const hasOverlappingPeriods = overlappingPeriods.length > 0

  const createTitle = field === 'periodAddons' ? translations.createPeriodAddon : translations.createOvertimePeriod
  const editTitle = field === 'periodAddons' ? translations.editPeriodAddon : translations.editOvertimePeriod

  return (
    <RegularModal
      title={
        period ? editTitle : createTitle
      }
      width="488px"
      submitText={translations.save}
      submitDisabled={!formValid || hasOverlappingPeriods}
      onCancel={() => onSubmit(null)}
      onSubmit={() => handleSubmit()}
    >
      <Form>
        <Field>
        <label>{translations.salaryCode}</label>
          <SalaryCodeSelect
            fullWidth
            nullable
            value={formValues.salaryCode}
            onChange={(value, option) => {
              updateForm({
                salaryCodeName: option?.extra?.name ?? '',
                salaryCode: value,
              })
            }}
          />
        </Field>
        <Field>
          <label>{translations.selectWeekdays}</label>
          <SelectWeekDays
            initialValues={weekDayValues}
            onSelectDay={handleDaySelect}
            error={weekDayError}
          />
        </Field>
        <Field>
          <label>{translations.timeInterval}</label>
          <TimePickers>
            <TimePicker
              width="100%"
              disableWheel
              value={formValues.periodStart}
              onChange={handler('periodStart')}
            />
            <TimePicker
              width="100%"
              disableWheel
              value={formValues.periodEnd}
              onChange={handler('periodEnd')}
              error={errors.periodEnd ?? errors.periodStart}
            />
          </TimePickers>
        </Field>

        <Field>
          <TimeGraph
            includeHourMarks
            graphs={[periodAddonGraph].concat(relevantOvertimePeriodGraphs)}
          />
        </Field>
        {hasOverlappingPeriods && (
          <Field>
            <ErrorWrapper>
              {translations.overlappingPeriodsAreNotAllowed}:
              {overlappingPeriods.map((period, index) => {
                return (
                  <p key={index}>
                    {floor(period.start / 60)}:
                    {String(period.start % 60).padStart(2, '0')} -{' '}
                    {floor(period.end / 60)}:
                    {String(period.end % 60).padStart(2, '0')}
                  </p>
                )
              })}
            </ErrorWrapper>
          </Field>
        )}
      </Form>
    </RegularModal>
  )
}
