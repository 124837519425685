import { useToast } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import {
  addHours,
  endOfDay,
  endOfMonth,
  formatISO,
  isBefore,
  parseISO,
  startOfDay,
  startOfMonth,
} from 'date-fns'
import { Module } from 'modules/companies/consts'
import { useCallback, useMemo, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { format } from 'util/date-fns'
import { useCompany, useConfirm, useOnErrorAuto, useUser } from 'util/hooks'
import { useModuleConfig } from 'util/hooks/useModuleConfig'
import { getSalaryDatesForMonth, ExportType } from 'modules/exports'
import {
  CreateTerminalEntryMutation,
  CreateTerminalEntryMutationVariables,
  CREATE_TERMINAL_ENTRY,
  ExportTerminalMutation,
  ExportTerminalMutationVariables,
  TerminalEntryNode,
  UpdateTerminalEntryMutation,
  UpdateTerminalEntryMutationVariables,
  UPDATE_TERMINAL_ENTRY_MUTATION,
} from '.'
import { useTerminalFilter } from './hooks'
import { DELETE_TERMINAL_ENTRY_MUTATION } from './mutations'
import {
  EditTerminalEntryForm,
  ExportTerminalEntriesForm,
} from './TerminalView/types'
import {
  DeleteTerminalEntryMutation,
  DeleteTerminalEntryMutationVariables,
  ExportTerminalMass,
  ExportTerminalMassVariables,
} from './types.graphql'
import { USER_USER_TYPES_QUERY } from 'modules/users/queries'
import { UserUserTypesQuery } from 'modules/users'
import { IdVariable } from 'types/graphql'
import { getUsersHighestPrioritySalaryWorkTime } from 'modules/companies/util'
import {
  EXPORT_TERMINAL,
  EXPORT_TERMINAL_MASS,
} from 'modules/exports/mutations'
import { useHistory } from 'react-router'

export function useTerminalEntriesMutations(
  latestEntry: TerminalEntryNode | null = null,
  lastCheckIn: Date = new Date(),
  userId: string = ''
) {
  const addToast = useToast()
  const [disableButton, setDisableButton] = useState(false)
  const translations = useTranslate({
    error: {
      checkIn: 'error.could-not-check-in',
      checkOut: 'error.could-not-check-out',
    },
    toasts: {
      checkedInAt: ['terminal.checked-in-at', { time: '' }],
      checkedOutAt: ['terminal.checked-out-at', { time: '' }],
      editEntrySuccess: 'terminal.success.edit-terminal-entry',
      editEntryError: 'terminal.success.edit-terminal-entry',
      deleteEntrySuccess: 'terminal.success.delete-terminal-entry',
      deleteEntryError: 'terminal.error.delete-terminal-entry',
    },
    prompt: {
      deleteTerminalEntry: 'terminal.prompt.delete-terminal-entry',
      deleteTerminalEntryTitle: 'terminal.prompt.delete-terminal-entry-title',
    },
  })

  const onErrorAuto = useOnErrorAuto()

  /* CheckInOutButton */

  const [checkInTerminal, { loading: checkInLoading }] = useMutation<
    CreateTerminalEntryMutation,
    CreateTerminalEntryMutationVariables
  >(CREATE_TERMINAL_ENTRY, {
    refetchQueries: ['TodaysTerminalEntries', 'AllTerminalEntries'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      addToast(
        'success',
        translations.toasts.checkedInAt({ time: format(new Date(), 'HH:mm') })
      )
    },
    onError: onErrorAuto(translations.error.checkIn),
  })

  const [checkOutTerminal, { loading: checkOutLoading }] = useMutation<
    UpdateTerminalEntryMutation,
    UpdateTerminalEntryMutationVariables
  >(UPDATE_TERMINAL_ENTRY_MUTATION, {
    refetchQueries: ['TodaysTerminalEntries', 'AllTerminalEntries'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      addToast(
        'success',
        translations.toasts.checkedOutAt({ time: format(new Date(), 'HH:mm') })
      )
    },
    onError: onErrorAuto(translations.error.checkOut),
  })

  const forgotToCheckOut = useMemo(
    () =>
      latestEntry && lastCheckIn
        ? isBefore(lastCheckIn, startOfDay(new Date()))
        : false,
    [latestEntry, lastCheckIn]
  )

  const handleCheckIn = useCallback(async () => {
    setDisableButton(true)
    try {
      await checkInTerminal({
        variables: {
          input: {
            user: userId,
            checkIn: new Date(),
          },
        },
      })
    } finally {
      setDisableButton(false)
    }
  }, [checkInTerminal, userId])

  const handleCheckOut = useCallback(async () => {
    if (!latestEntry || !lastCheckIn) return
    setDisableButton(true)
    try {
      if (forgotToCheckOut) {
        /* TODO: replace with suitable SalaryWorkTimeModel value */
        const normalWorkday = 8 // hours

        const fixedCheckOut = addHours(lastCheckIn, normalWorkday)
        await checkOutTerminal({
          variables: {
            id: latestEntry.id,
            input: {
              user: userId,
              checkIn: parseISO(latestEntry.checkIn),
              checkOut: fixedCheckOut,
            },
          },
        })
      } else {
        await checkOutTerminal({
          variables: {
            id: latestEntry.id,
            input: {
              user: userId,
              checkIn: parseISO(latestEntry.checkIn),
              checkOut: new Date(),
            },
          },
        })
      }
    } finally {
      setDisableButton(false)
    }
  }, [checkOutTerminal, forgotToCheckOut, latestEntry, lastCheckIn, userId])

  /* Edit Terminal Entry */

  const confirm = useConfirm()
  const [entryLoading, setEntryLoading] = useState<string | null>(null)
  const [editTerminalEntry, setEditTerminalEntry] =
    useState<TerminalEntryNode | null>(null)

  const [updateTerminalEntry, { loading: patchLoading }] = useMutation(
    UPDATE_TERMINAL_ENTRY_MUTATION,
    {
      refetchQueries: ['AllTerminalEntries'],
      onCompleted: () => {
        addToast('success', translations.toasts.editEntrySuccess)
      },
      onError: onErrorAuto(translations.toasts.editEntryError),
    }
  )

  async function handleEditTerminalEntry(values: EditTerminalEntryForm) {
    const entry = editTerminalEntry
    if (!entry) return

    setEditTerminalEntry(null)

    try {
      setEntryLoading(entry.id)
      await updateTerminalEntry({
        variables: {
          id: entry.id,
          input: {
            checkIn: values.checkIn,
            checkOut: values.checkOut,
            user: entry.user.id,
          },
        },
      })
    } finally {
      setEntryLoading(null)
    }
  }

  const [deleteTerminalEntryMutation, { loading: deleteEntryLoading }] =
    useMutation<
      DeleteTerminalEntryMutation,
      DeleteTerminalEntryMutationVariables
    >(DELETE_TERMINAL_ENTRY_MUTATION, {
      onCompleted: () => {
        addToast('success', translations.toasts.deleteEntrySuccess)
      },
      onError: () => {
        addToast('error', translations.toasts.deleteEntryError)
      },
    })

  async function handleDeleteTerminalEntry(entry: TerminalEntryNode) {
    const promptMessage = translations.prompt.deleteTerminalEntry
    const promptTitle = translations.prompt.deleteTerminalEntryTitle

    const { data: answer } = await confirm(promptMessage, promptTitle)
    if (!answer) return

    try {
      setEntryLoading(entry.id)
      await deleteTerminalEntryMutation({
        variables: { id: entry.id },
      })
    } finally {
      setEntryLoading(null)
    }
  }

  const mutationLoading =
    checkInLoading || checkOutLoading || deleteEntryLoading || patchLoading

  return {
    checkIn: handleCheckIn,
    checkOut: handleCheckOut,
    loading: mutationLoading,
    disableButton,

    editTerminalEntry,
    entryLoading,
    setEditTerminalEntry,
    handleEditTerminalEntry,
    handleDeleteTerminalEntry,
  }
}

export function useExportTerminalEntriesMutations() {
  const me = useUser()
  const company = useCompany()
  const [loading, setLoading] = useState(false)
  const addToast = useToast()
  const history = useHistory()
  const translations = useTranslate({
    mailSuccess: 'exports.success-email-process',
  })
  const { filter, updateFilter, filterHandler } = useTerminalFilter()

  const { data } = useQuery<UserUserTypesQuery, IdVariable>(
    USER_USER_TYPES_QUERY,
    {
      variables: { id: filter.user },
    }
  )
  const user = data?.user

  const [exportMutation] = useMutation<
    ExportTerminalMutation,
    ExportTerminalMutationVariables
  >(EXPORT_TERMINAL, {
    onCompleted: data => {
      if (data?.exportTerminal.html) {
        history.push('/export', { html: data?.exportTerminal.html })
      } else if (data?.exportTerminal.ok) {
        addToast('success', translations.mailSuccess)
      } else {
        addToast('error', 'Server error')
      }
    },
  })

  const startMonthYearTime = startOfMonth(filter.month)
  const endMonthYearTime = endOfMonth(startMonthYearTime)
  // TODO (Marius): Add new hook version when it is merged in
  const { moduleConfig: module } = useModuleConfig(Module.TERMINAL)

  const salaryWorkTime = !module
    ? null
    : getUsersHighestPrioritySalaryWorkTime(user, module)

  const salaryDates = getSalaryDatesForMonth(
    salaryWorkTime?.salaryDateStart ?? 27,
    salaryWorkTime?.salaryDateEnd ?? 26,
    filter.month.getFullYear(),
    filter.month.getMonth()
  )

  async function doExport(values: ExportTerminalEntriesForm) {
    try {
      setLoading(true)
      const useSalaryDates = values.exportType === ExportType.ENTRY_SALARY

      const firstOfMonth = formatISO(
        useSalaryDates ? startOfDay(salaryDates[0]) : startMonthYearTime
      )
      const lastOfMonth = formatISO(
        useSalaryDates ? endOfDay(salaryDates[1]) : endMonthYearTime
      )

      await exportMutation({
        variables: {
          exportType: values.exportType,
          exportFormat: values.exportFormat,
          userMail: me.id,
          user: filter.user,
          company: company.id,
          dateStart: firstOfMonth,
          dateEnd: lastOfMonth,
          exportOption: values.exportOption,
        },
      })
    } finally {
      setLoading(false)
    }
  }

  return {
    filter,
    loading,
    salaryDates,
    startMonthYearTime,
    endMonthYearTime,
    doExport,
    updateFilter,
    filterHandler,
  }
}

export const useTerminalExportMutations = (onCompleted?: () => void) => {
  const translations = useTranslate({
    error: 'error.generic-server-error',
    downloadSuccess: 'exports.success-user-exports',
    mailSuccess: 'exports.success-email-process',
  })
  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()
  const history = useHistory()

  const [exportTerminal, { loading: terminalLoading }] = useMutation<
    ExportTerminalMutation,
    ExportTerminalMutationVariables
  >(EXPORT_TERMINAL, {
    onCompleted: data => {
      if (data?.exportTerminal.html) {
        history.push('/export', { html: data?.exportTerminal.html })
      } else if (data?.exportTerminal.ok) {
        addToast('success', translations.downloadSuccess)
      }
      onCompleted?.()
    },
    onError: onErrorAuto(translations.error),
  })

  const [exportTerminalMass, { loading: terminalMassLoading }] = useMutation<
    ExportTerminalMass,
    ExportTerminalMassVariables
  >(EXPORT_TERMINAL_MASS, {
    onCompleted: data => {
      if (data?.exportTerminalMass.html) {
        history.push('/export', { html: data?.exportTerminalMass.html })
      } else if (data?.exportTerminalMass.ok) {
        addToast('success', translations.downloadSuccess)
      }
      onCompleted?.()
    },
    onError: onErrorAuto(translations.error),
  })

  return {
    exportTerminal,
    exportTerminalMass,
    loading: terminalLoading || terminalMassLoading,
  }
}
