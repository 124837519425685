import { gql } from '@apollo/client'

export const CREATE_TERMINAL_ENTRY = gql`
  mutation CreateTerminalEntries($input: CreateTerminalEntryInput!) {
    createTerminalEntry(input: $input) {
      terminalEntry {
        id
      }
    }
  }
`

export const UPDATE_TERMINAL_ENTRY_MUTATION = gql`
  mutation UpdateTerminalEntry($id: ID!, $input: UpdateTerminalEntryInput!) {
    updateTerminalEntry(id: $id, input: $input) {
      terminalEntry {
        id
      }
    }
  }
`
export const DELETE_TERMINAL_ENTRY_MUTATION = gql`
  mutation DeleteTerminalEntry($id: ID!) {
    deleteTerminalEntry(id: $id) {
      found
      deletedId
    }
  }
`

export const CREATE_TERMINAL_MUTATION = gql`
  mutation CreateTerminal($input: CreateTerminalInput!) {
    createTerminal(input: $input) {
      terminal {
        id
      }
    }
  }
`

export const UPDATE_TERMINAL_MUTATION = gql`
  mutation UpdateTerminal($id: ID!, $input: UpdateTerminalInput!) {
    updateTerminal(id: $id, input: $input) {
      terminal {
        id
      }
    }
  }
`

export const PATCH_TERMINAL_MUTATION = gql`
  mutation PatchTerminal($id: ID!, $input: PatchTerminalInput!) {
    patchTerminal(id: $id, input: $input) {
      terminal {
        id
      }
    }
  }
`

export const DELETE_TERMINAL_MUTATION = gql`
  mutation DeleteTerminal($id: ID!) {
    deleteTerminal(id: $id) {
      found
      deletedId
    }
  }
`
