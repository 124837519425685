import { Coordinates } from 'types/graphql/maps'

export function getCoordinateFromGMaps(
  latLng: google.maps.LatLng
): Coordinates {
  return {
    lat: latLng.lat(),
    lng: latLng.lng(),
  }
}

export function getAutoCompleteSourceFromPredictions(
  predictions: google.maps.places.AutocompletePrediction[],
  map: Map<string, string>
) {
  return predictions?.map(pred => {
    // The description field is the human readable value, whereas if we pick an option from here
    // we want to use the ID related to said description. We do this by making use of a hashmap which encodes a
    // string with an ID which we can use when we want to perform geocoding. If the key already exists we don't bother.
    if (!map?.has(pred.description)) {
      map?.set(pred.description, pred.place_id)
    }
    return pred.description
  })
}
