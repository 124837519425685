import { Loader, SpinnerLoaderProps } from '@ur/react-components'
import React from 'react'
import styled from 'styled-components'

interface WrapperProps {
  marginTop: string
  marginBottom: string
}
const Wrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom}; ;
`

interface CenteredLoaderProps extends SpinnerLoaderProps {
  marginTop?: string
  marginBottom?: string
}

export const CenteredLoader: React.VFC<CenteredLoaderProps> = ({
  marginTop = '8rem',
  marginBottom = '0',

  ...loaderProps
}) => (
  <Wrapper marginTop={marginTop} marginBottom={marginBottom}>
    <Loader.Spinner {...loaderProps} />
  </Wrapper>
)
