import { css } from 'styled-components'
import { KeyValue } from 'types/util'
import get from 'lodash/get'
import theme from 'theme'

/**
 * This function returns props.theme.colors[props.color] if it exists, otherwise
 * props.color.
 *
 * @param {Object} props The props passed to a component.
 */
export function overloadColorProp(props: KeyValue<any>, key = 'color') {
  return props[key] in props.theme.colors
    ? props.theme.colors[props[key]]
    : props[key]
}

export function overloadColor(color?: string, fallback: string | null = null) {
  if (typeof color === 'undefined') return undefined
  return get(theme.colors, color, fallback ?? color)
}

export const buttonUnset = css`
  border: none;
  border-radius: 0;
  box-shadow: none;
  background: inherit;
  appearance: none;
  outline: none;
  padding: 0;
  margin: 0;
  font: inherit;
  letter-spacing: 0.05rem;
`

export function validateHex(hex: string) {
  if (!hex) return null
  if (hex.charAt(0) === '#') hex = hex.substring(1)
  if (
    hex.length !== 3 &&
    hex.length !== 4 &&
    hex.length !== 6 &&
    hex.length !== 8
  ) {
    return null
  }

  if (hex.length < 6)
    hex = hex.split('').reduce((acc, cur) => acc + cur + cur, '')

  if (!/^([a-f0-9]{2}){3,4}$/i.test(hex)) {
    return null
  }

  return hex
}

export function rgbFromHex(hex: string) {
  const validated = validateHex(hex)
  if (!validated) return null

  const red = parseInt(hex.substring(0, 2), 16)
  const green = parseInt(hex.substring(2, 4), 16)
  const blue = parseInt(hex.substring(4, 6), 16)

  return [red, green, blue]
}

/**
 * Return a light or dark color contrasting the input
 * @param hex Hex color code to contrast
 * @param lightColor Preferred light color return, can be from theme
 * @param darkColor Preferred dark color return, can be from theme
 * @param fallback A fallback color in case hex is invalid, can be from theme
 * @see https://www.w3.org/TR/AERT/#color-contrast
 */
export function contrastColor(
  hex: string,
  lightColor = 'white',
  darkColor = '#333333',
  fallback = '#333333'
) {
  if (!hex) return overloadColor(fallback)

  const fromHex = rgbFromHex(hex)
  if (!fromHex) return overloadColor(fallback)

  const [r, g, b] = fromHex
  // These constants are recommended by W3: https://www.w3.org/TR/AERT/#color-contrast
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255

  if (luminance > 0.5) return overloadColor(darkColor)
  else return overloadColor(lightColor)
}
