import { gql } from '@apollo/client'
import { CompanyFragments } from './fragments'

export const MY_COMPANIES = gql`
  query MyCompanies {
    me {
      id
      companies {
        id
        fullName
        shortName
      }
    }
  }
`

export const CURRENT_COMPANY_QUERY = gql`
  query CurrentCompany {
    currentCompany {
      ...CompanyInfo
      ...CompanyUserTypes
      numberOfUsers
    }
  }
  ${CompanyFragments.info}
  ${CompanyFragments.userTypes}
`

export const CURRENT_COMPANY_SETTINGS_QUERY = gql`
  query CurrentCompanySettings {
    currentCompany {
      ...CompanyInfo
      country
      language
    }
    allCompanies {
      edges {
        node {
          ...CompanyInfo
        }
      }
    }
  }
  ${CompanyFragments.info}
`

export const CURRENT_COMPANY_NOTIFICATION_SETTINGS_QUERY = gql`
  query CurrentCompanyNotificationSettings {
    currentCompany {
      id
      notificationSettings {
        edges {
          node {
            id
            name
            sendSms
            sendEmail
            sendInApp
          }
        }
      }
    }
  }
`

export const ALL_SALARY_MODULE_CONFIGS_QUERY = gql`
  query AllSalaryModuleConfigs {
    allSalaryModuleConfigs {
      edges {
        node {
          id
          name
          active
          moduleOptions {
            id
            name
            active
          }
          responsibleUsers {
            id
            fullName
            initials
            profilePicture
          }
          hasDashboardIntegration
          salaryWorkTimes {
            id
            priority
            dailyWorkLimit
            periodWorkLimit
            pauseDuration
            pauseThreshold
            paidPauseThreshold
            salaryDateStart
            salaryDateEnd
            name
            usePeriodOvertime
            useSalaryDayPayment
            salaryType
            useWeekOvertime

            defaultSalaryCode {
              id
              name
              externalId
            }

            dayOvertimeSalaryCode {
              id
              name
              externalId
            }

            weekOvertimeSalaryCode {
              id
              name
              externalId
            }

            periodAddons {
              id
              periodStart
              periodEnd

              salaryCode {
                id
                name
                externalId
              }

              monday
              tuesday
              wednesday
              thursday
              friday
              saturday
              sunday
            }

            overtimePeriods {
              id
              periodStart
              periodEnd

              salaryCode {
                id
                name
                externalId
              }

              monday
              tuesday
              wednesday
              thursday
              friday
              saturday
              sunday
            }

            userTypes {
              edges {
                node {
                  id
                  name
                }
              }
            }
            defaultDepartment {
              id
              name
            }
            availableDepartments {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const SALARY_WORK_TIME_QUERY = gql`
  query SalaryWorkTime($id: ID!) {
    salaryWorkTime(id: $id) {
      id
      priority
      salaryType
      dailyWorkLimit
      periodWorkLimit
      pauseDuration
      pauseThreshold
      countPauseInTotal
      paidPauseThreshold
      salaryDateStart
      salaryDateEnd
      name
      groupSalaryOnVehicle
      usePeriodOvertime
      useSalaryDayPayment
      useWeekOvertime
      user {
        id
        fullName
      }
      userTypes {
        edges {
          node {
            id
            name
          }
        }
      }
      defaultDepartment {
        id
        name
      }
      availableDepartments {
        id
        name
      }
      defaultSalaryCode {
        id
        name
        externalId
      }
      salaryDayCode {
        id
        name
        externalId
      }
      dayOvertimeSalaryCode {
        id
        name
        externalId
      }

      weekOvertimeSalaryCode {
        id
        name
        externalId
      }
      periodAddons {
        id
        periodStart
        periodEnd

        salaryCode {
          id
          name
          externalId
        }

        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
      overtimePeriods {
        id
        periodStart
        periodEnd

        salaryCode {
          id
          name
          externalId
        }

        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
      moduleConfig {
        id
        name
        active
        salaryWorkTimes {
          id
          priority
        }
      }
    }
  }
`

export const MODULE_CONFIG_QUERY = gql`
  query ModuleConfig($name: String!) {
    moduleConfig(name: $name) {
      id
      name
      active
      moduleOptions {
        id
        name
        active
      }
      responsibleUsers {
        id
        fullName
        initials
        profilePicture
      }
      salaryWorkTimes {
        id
        priority
        dailyWorkLimit
        periodWorkLimit
        pauseDuration
        pauseThreshold
        countPauseInTotal
        paidPauseThreshold
        salaryDateStart
        salaryDateEnd
        name

        groupSalaryOnVehicle
        usePeriodOvertime
        useSalaryDayPayment
        useWeekOvertime

        defaultSalaryCode {
          id
          name
          externalId
        }

        defaultDepartment {
          id
          name
        }
        availableDepartments {
          id
          name
        }

        periodAddons {
          id

          salaryCode {
            id
            name
            externalId
          }
        }
        overtimePeriods {
          id

          salaryCode {
            id
            name
            externalId
          }
        }

        userTypes {
          edges {
            node {
              id
              name
            }
          }
        }
      }
      hasDashboardIntegration
    }
  }
`

export const ALL_MODULE_CONFIG_QUERYS_SHALLOW = gql`
  query AllModuleConfigsShallow {
    allModuleConfigs {
      edges {
        node {
          id
          name
          active
          moduleOptions {
            id
            name
            active
          }
        }
      }
    }
  }
`

export const ALL_API_KEYS = gql`
  query AllApiKeys {
    allVolvoApiKeys {
      edges {
        node {
          id
          active
          username
          password
        }
      }
    }
    allScaniaApiKeys {
      edges {
        node {
          id
          active
          fmsAccessKey
          fmsSecretKey
          tachographKey
        }
      }
    }
    allFleetboardApiKeys {
      edges {
        node {
          id
          active
          fleet
          username
          password
        }
      }
    }
    allManApiKeys {
      edges {
        node {
          id
          active
          integrationId
        }
      }
    }
  }
`

export const VOLVO_API_KEY = gql`
  query VolvoApiKey($id: ID!) {
    volvoApiKey(id: $id) {
      ...VolvoApiKey
    }
  }
  ${CompanyFragments.volvoApiKey}
`

export const SCANIA_API_KEY = gql`
  query ScaniaApiKey($id: ID!) {
    scaniaApiKey(id: $id) {
      ...ScaniaApiKey
    }
  }
  ${CompanyFragments.scaniaApiKey}
`


export const ALL_DEPARTMENTS_QUERY = gql`
  query Departments {
    allDepartments {
      edges {
        node {
          id
          name
          externalId
        }
      }
    }
  }
`

export const ALL_HOLIDAY_ALLOWANCES_QUERY = gql`
  query AllHolidayAllowances {
    allHolidayAllowances {
      edges {
        node {
          id
          salaryCode {
            id
            name
            externalId
          }
          day
        }
      }
    }
  }
`

export const ALL_SALARY_CODES_QUERY = gql`
  query SalaryCodes($isDiet: Boolean, $showInManualTimeEntryOvertime: Boolean) {
    allSalaryCodes(isDiet: $isDiet, showInManualTimeEntryOvertime: $showInManualTimeEntryOvertime) {
      edges {
        node {
          id
          name
          externalId
          isDiet
          showInManualTimeEntryOvertime
        }
      }
    }
  }
`

export const COMPANY_DRIVER_CARDS_QUERY = gql`
  query CompanyDriverCards {
    companyDriverCards(isExpired: true) {
      edges {
        node {
          id
          expiresAt
          user {
            id
            fullName
          }
        }
      }
    }
  }
`