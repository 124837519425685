import { useForm, useTranslate } from '@ur/react-hooks'
import { Button as BaseButton, Input, ModuleTitle } from 'components'
import { Card } from 'components/Card'
import { PlainLink } from 'components/Link'

import { PermissionsRequired } from 'containers/permission-containers'
import React from 'react'
import { isMobile, MobileView } from 'react-device-detect'
import styled from 'styled-components'
import { PERMISSIONS } from 'util/permissions'
import { IssuesTable } from '../components'
import { IssuePopupFilter } from '../components/IssuePopupFilter'
import { IssuesTableFilter } from '../types'

const Wrapper = styled.div`
  ${props => props.theme.layout.default};

  ${props => props.theme.layout.largePad} {
    padding: 122px 52px 52px;
    box-sizing: border-box;
  }
`
const Controls = styled(Card)`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  gap: 1rem;

  margin-bottom: 1rem;
  padding: 1rem;
`
const MobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`
const Button = styled(BaseButton)`
  ${props => props.theme.media.mobile} {
    padding: 0 3rem 0 1rem;
  }
  ${props => props.theme.media.largePad} {
    padding: 0 3rem 0 1rem;
  }
`

interface IssuesProps {}

export const Issues: React.VFC<IssuesProps> = () => {
  const translations = useTranslate({
    issues: 'common.issues',
    createNew: 'common.create',
    findIssue: 'issues.find-issue',
  })

  const {
    formValues: filter,
    formChangeHandler: filterHandler,
    updateForm,
  } = useForm<IssuesTableFilter>({
    values: {
      query: '',
      orderBy: '-timeOfIssue',
      excludeCategories: [],
      open: undefined,
      archived: false,
      severities: []
    },
    config: {
      storage: {
        storeFormState: true,
        retrieveFormStateOnMount: true,
        sessionStorage: true,
        storeFormStateName: 'firmadok__issue-overview-filter',
      },
    },
  })

  return (
    <Wrapper>
      <MobileView>
        <MobileHeader>
          <ModuleTitle inlineHeader>{translations.issues}</ModuleTitle>

          <PermissionsRequired permissions={PERMISSIONS.issues.add.issue}>
            <PlainLink to="/issues/create">
              <Button
                iconLeftProps={{ icon: 'plus', margin: '0' }}
                height="42px"
                padding="0"
                fontSize="0.8em"
              >
                {translations.createNew}
              </Button>
            </PlainLink>
          </PermissionsRequired>
        </MobileHeader>
      </MobileView>
      {!isMobile && <ModuleTitle>{translations.issues}</ModuleTitle>}

      <Controls>
        <Input
          value={filter.query}
          type="search"
          placeholder={translations.findIssue}
          onChange={filterHandler('query')}
        />

        {!isMobile && (
          <>
            <IssuePopupFilter filter={filter} onUpdate={updateForm} />

            <PermissionsRequired permissions={PERMISSIONS.issues.add.issue}>
              <PlainLink to="/issues/create">
                <Button iconLeftProps={{ icon: 'plus' }}>
                  {translations.createNew}
                </Button>
              </PlainLink>
            </PermissionsRequired>
          </>
        )}
      </Controls>

      <IssuesTable filter={filter} onSort={filterHandler('orderBy')} />
    </Wrapper>
  )
}
