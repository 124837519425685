import { useTranslate } from '@ur/react-hooks'
import { NullableCalendarLogicBlock } from 'components'
import { isSunday } from 'date-fns'
import { MouseEvent, useState } from 'react'
import { usePopper } from 'react-popper'
import { format } from 'util/date-fns'
import { CalendarMainItems } from './components/CalendarMainItems'
import { CalendarBlock } from './components/CalendarBlock'
import { CalendarExtraBlocksPopup } from './components/CalendarExtraBlocksPopup'
import {
  BlockWrapper,
  CalendarCell,
  CalendarCellHeader,
  CalendarWrapper,
  ExtraSlotBlock,
  PopupWrapper,
  WeekDayHeader,
} from './components/components'
import { CalendarData, CalendarLogicBlock, CalendarLogicData } from './types'

interface CalendarProps {
  data: CalendarData[]
}

export const Calendar: React.VFC<CalendarProps> = ({ data }) => {
  const translations = useTranslate({
    showAll: 'common.show-all',
  })

  const [showPopup, setShowPopup] = useState(false)
  const [popupContext, setPopupContext] =
    useState<CalendarLogicData | null>(null)

  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] =
    useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement)

  const firstWeek = data.slice(0, 7).map(day => day.date)

  const calendarHeader = firstWeek.map((day, i) => {
    return (
      <WeekDayHeader key={'WeekHeader-' + i}>
        {format(day, 'E').toUpperCase()}.
      </WeekDayHeader>
    )
  })

  // Main idea is to get a structure with 4 hard coded slots in the calendar and add a separate handling of extra slots
  const calendarDataWithLogic = data.reduce<CalendarLogicData[]>(
    (acc, cur, i) => {
      // Get previous block, next node list and current node ids if possible
      const previous = acc.length > 0 ? acc[i - 1] : null
      const next = i < data.length - 1 ? data[i + 1] : null
      const currentIds = cur.nodes.map(node => node.id)

      const previousBlocks = previous?.mainSlots?.reduce<CalendarLogicBlock[]>(
        (acc, cur) => {
          if (cur !== null) {
            acc.push(cur)
          }
          return acc
        },
        []
      )

      const previousExtraBlocks = previous?.extraSlotItems ?? []

      // Extract ids here to avoid duplication of map functions below
      const previousIds = previousBlocks
        ?.map(block => block.id)
        .concat(previousExtraBlocks?.map(block => block.id))
      const nextIds = next?.nodes?.map(node => node.id)

      // Find nodes that has
      const hasNextIds = currentIds.filter(id => nextIds?.includes(id))

      // New nodes, not seen in previous nodes
      const newNodes = cur.nodes.filter(node => !previousIds?.includes(node.id))

      // The old slots, or empty slot state if none
      const oldSlots = previous?.mainSlots ?? [null, null, null, null]

      // Counter to keep track of how many of the new nodes are used in the main slots
      let j = 0

      // Block generation and editing for new and old nodes
      const newSlots: NullableCalendarLogicBlock[] = oldSlots?.map(
        (block: NullableCalendarLogicBlock, i: number) => {
          const empty = block === null || !currentIds.includes(block.id)

          if (empty && newNodes.length > j) {
            // Create a new block from node j
            const newNode = newNodes[j]
            const newBlock = {
              id: newNode.id,
              text: newNode?.text,
              previous: false,
              next: hasNextIds.includes(newNode.id),
              color: newNode.color,
              onClick: newNode.onClick,
            } as CalendarLogicBlock
            // Increase j and return the new block
            j += 1
            return newBlock
          } else if (!empty) {
            // If block exists, edit the block and return
            return {
              ...block,
              text: '',
              previous: true,
              next: hasNextIds.includes(oldSlots[i]?.id ?? ''),
            } as CalendarLogicBlock
          } else {
            return null
          }
        }
      )

      // The old extraSlotItems, or empty slot state if none
      const oldExtraSlots = previous?.extraSlotItems ?? []

      // Blocks to keep from extraSlot list
      const keepExtraSlotItems: CalendarLogicBlock[] = oldExtraSlots
        ?.filter(block => currentIds.includes(block.id))
        ?.map(block => {
          return {
            ...block,
            previous: true,
            next: hasNextIds.includes(block.id),
          } as CalendarLogicBlock
        })

      // If there are still nodes left in newNodes, slice the array and add this to extraSlots
      const newExtraSlotItems = newNodes.slice(j).map(node => {
        return {
          id: node.id,
          text: node?.text,
          previous: false,
          next: hasNextIds.includes(node.id),
          color: node.color,
          onClick: node.onClick,
        } as CalendarLogicBlock
      })

      // Add a new CalendarLogicData item to acc and return
      const currentBlock = {
        date: cur.date,
        mainSlots: newSlots,
        extraSlotItems: [...keepExtraSlotItems, ...newExtraSlotItems],
        previousHasExtraBlock: keepExtraSlotItems.length > 0,
      }

      acc.push(currentBlock as CalendarLogicData)

      return acc
    },
    []
  )

  const handleClick = (
    evt: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
    data: CalendarLogicData
  ) => {
    setReferenceElement(evt.currentTarget)
    setShowPopup(true)
    setPopupContext(data)
  }

  return (
    <CalendarWrapper>
      {calendarDataWithLogic.map((calendarDataCell, i) => {
        const showSingleBlock = calendarDataCell.extraSlotItems.length === 1
        const showExtraBlock = calendarDataCell.extraSlotItems.length > 1
        return (
          <CalendarCell
            key={'CalendarCell-' + i}
            borderTop={!firstWeek.includes(calendarDataCell.date)}
            borderRight={!isSunday(calendarDataCell.date)}
          >
            {calendarHeader.length > i && calendarHeader[i]}
            <CalendarCellHeader>
              {calendarDataCell.date.getDate()}
            </CalendarCellHeader>
            <BlockWrapper>
              <CalendarMainItems items={calendarDataCell.mainSlots} />
              {showExtraBlock && (
                <ExtraSlotBlock
                  starting={!calendarDataCell.previousHasExtraBlock}
                  onClick={evt => handleClick(evt, calendarDataCell)}
                >
                  {translations.showAll}(
                  {calendarDataCell?.extraSlotItems.length})
                </ExtraSlotBlock>
              )}
              {showSingleBlock &&
                calendarDataCell.extraSlotItems.map(block => (
                  <CalendarBlock
                    key={'CalendarBlock-4'}
                    background={block?.color ?? ''}
                    ending={!block.next}
                    starting={!block.previous}
                    text={
                      !calendarDataCell.previousHasExtraBlock
                        ? block?.text ?? null
                        : ''
                    }
                    onClick={block?.onClick}
                  />
                ))}
            </BlockWrapper>
          </CalendarCell>
        )
      })}
      <PopupWrapper
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        {showPopup &&
          popupContext !== null &&
          popupContext.extraSlotItems.length > 1 && (
            <CalendarExtraBlocksPopup
              context={popupContext}
              onClickOutside={() => setShowPopup(false)}
            />
          )}
      </PopupWrapper>
    </CalendarWrapper>
  )
}
