import padStart from 'lodash/padStart'

/**
 * Makes the thread sleep for the given amount of milliseconds
 * @param ms
 */
export const sleep = (ms: number) => new Promise(res => setTimeout(res, ms))

/**
 * Returns the given amount of seconds to a formatted string of "hh:mm"
 * @param durationAsSeconds
 */
export function durationAsSecondsToString(durationAsSeconds: number) {
  const hoursToReturn = padStart(
    Math.floor(durationAsSeconds / 3600).toString(),
    2,
    '0'
  )
  const minutesToReturn = padStart(
    Math.floor((durationAsSeconds / 60) % 60).toString(),
    2,
    '0'
  )

  return `${hoursToReturn}:${minutesToReturn}`
}

/**
 * Returns the given amount of seconds as minutes
 * @param durationAsSeconds
 */
export function durationAsSecondsToMinutes(durationAsSeconds: number) {
  return Math.floor(durationAsSeconds / 60)
}

/**
 * Returns the given amount of seconds as hours
 * @param durationAsSeconds
 */
export function durationAsSecondsToHours(durationAsSeconds: number) {
  return Math.floor(durationAsSeconds / 3600)
}

/**
 * Returns full amount of minutes in date, discounting year, month and date
 * @param date Date to parse
 */
export function getFullMinutes(date: Date) {
  return date.getHours() * 60 + date.getMinutes()
}
/**
 * Get number of hours with decimal
 * @param date Date to parse
 */
export function getHoursDecimal(date: Date) {
  return date.getHours() + date.getMinutes() / 60
}

export type TimePrecision = 'hours' | 'minutes' | 'seconds'

/**
 * Convert string of format hh:mm to total of selected precision
 * @param hhmm Time string in format hh:mm (24-hour)
 * @param precision Return precision, default "seconds"
 * @returns floored numeric of given precision, or null if invalid time string
 */
export function hhmmToNumeric(
  hhmm: string,
  precision: TimePrecision = 'seconds'
) {
  if (!/^\d{2,}:\d{2}$/.test(hhmm)) return null

  const [hours, minutes] = hhmm.split(':').map(value => parseInt(value, 10))

  switch (precision) {
    case 'seconds':
      return hours * 3600 + minutes * 60
    case 'minutes':
      return hours * 60 + minutes
    case 'hours':
      return hours
  }
}

/**
 * Graphene omits the seconds from the string if its value is 0. This function
 * handles both cases and parses the value to the provided format
 * @param timeDelta Time string, can either be in HH:mm or HH:mm:ss format
 * @param precision Return precision, default "seconds"
 * @returns roofed numeric of given precision, or null if invalid time string
 */
export function grapheneTimeDeltaToNumeric(
  hhmm: string,
  precision: TimePrecision = 'seconds'
) {
  const oneColonCheck = /^\d{2}:\d{2}$/.test(hhmm)
  const twoColonCheck = /^\d{2}:\d{2}:\d{2}$/.test(hhmm)

  if (!oneColonCheck && !twoColonCheck) return null

  const [hours, minutes, seconds] = hhmm
    .split(':')
    .map(value => parseInt(value, 10))

  switch (precision) {
    case 'seconds':
      return hours * 3600 + minutes * 60 + seconds
    case 'minutes':
      const secondsToMin = seconds > 0 ? 1 : 0
      return hours * 60 + minutes + secondsToMin
    case 'hours':
      const minutesToHour = minutes > 30 ? 1 : 0
      return hours + minutesToHour
  }
}

/**
 * Check if a date is an invalid date
 * @param date Date to check
 * @param fallback Fallback date
 * @returns date or fallback if fallback is given, otherwise boolean
 */
export function isInvalidDate(date: Date): boolean
export function isInvalidDate(date: Date, fallback: Date): Date
export function isInvalidDate(date: Date, fallback?: Date) {
  const invalid = isNaN(date.getTime())

  if (typeof fallback !== 'undefined') return invalid ? fallback : date

  return invalid
}
