import { useToast } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Button, Input, PhoneInput, Select } from 'components'
import { Card } from 'components/Card'
import { Form, FormField } from 'components/Form'
import { Label } from 'components/Label'
import { parsePhoneNumber } from 'libphonenumber-js'
import React, { useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { emailValidation } from 'util/forms'
import {
  useBreadcrumbs,
  useCompany,
  useConfirm,
  useOnErrorAuto,
} from 'util/hooks'
import {
  CreateUserMutation,
  CreateUserMutationVariables,
  PatchUserMutation,
  PatchUserMutationVariables,
  UserNode,
} from '.'
import { CREATE_USER_MUTATION, PATCH_USER_MUTATION } from './mutations'
import { AreaProps } from './UserProfile/components'

const Layout = styled.div`
  ${props => props.theme.layout.default};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .form-card {
    width: clamp(600px, 60vw, 90%);
  }

  ${props => props.theme.layout.mobile} {
    .form-card {
      width: 100%;
    }
  }
`

const GridForm = styled(Form)`
  width: 100%;
  padding: 1rem;
  display: grid;
  grid-gap: 1rem;
  > div + div {
    margin-top: 0;
  }
  grid-template-areas:
    'firstName lastName'
    'phone email'
    'userType submit';

  .submit {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  ${props => props.theme.layout.mobile} {
    grid-template-areas:
      'firstName'
      'lastName'
      'email'
      'phone'
      'userType'
      'submit';
  }
`

const SubmitButton = styled(Button)`
  white-space: nowrap;
`

const GridFormField = styled(FormField)<AreaProps>`
  grid-area: ${props => props.area};
`

interface CreateUserForm {
  company: string
  email: string
  firstName: string
  lastName: string
  phoneNumber: string
  userType: string
}

export const CreateUser: React.VFC = () => {
  const confirm = useConfirm()
  const company = useCompany()
  const history = useHistory()

  const translations = useTranslate({
    create: 'users.create-user',
    newUser: 'users.new',
    confirm: {
      deleteExistingNumber: 'users.delete-existing-number',
      numberExists: ['users.number-already-exists', { name: '' }],
    },
    error: {
      email: 'error.valid-email-required',
      duplicate: {
        typeExist: ['error.type-already-exist', { type: '' }],
        invalid: 'error.invalid-value',
      },
      fieldRequired: ['error.field-is-required', { field: '' }],
      formError: 'common.form-error',
      invalidPhoneNumber: 'error.invalid-phone-number',
    },
    fields: {
      email: 'common.email',
      firstName: 'common.firstName',
      lastName: 'common.lastName',
      phoneNumber: 'common.phone-number',
      userType: 'common.user-type',
    },
    loading: 'common.loading',
    phoneNumber: 'common.phone-number',
    theEmail: 'common.the-email',
    toasts: {
      createError: ['toasts.error.create-type', { type: '' }],
      successAdd: ['toasts.success-add-type', { type: '' }],
      updateError: ['toasts.error.update-type', { type: '' }],
    },
    user: 'common.user',
  })
  const { setOverrides, overrides } = useBreadcrumbs()
  const addToast = useToast()

  const validateFieldNotEmpty = (errorMessage: string | null) => {
    return (value: string | null) => (!!value ? null : errorMessage)
  }
  const {
    formValues: form,
    formErrors: errors,
    formValid,
    updateForm,
    updateFormErrors,
    submitHandler,
    formChangeHandler: handler,
  } = useForm<CreateUserForm>({
    values: {
      company: company.id,
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '+47',
      userType: company.userTypes[0].id,
    },
    validators: {
      email: email =>
        emailValidation(email) ? null : translations.error.email,
      firstName: validateFieldNotEmpty(
        translations.error.fieldRequired({
          field: translations.fields.firstName,
        })
      ),
      lastName: validateFieldNotEmpty(
        translations.error.fieldRequired({
          field: translations.fields.lastName,
        })
      ),
      phoneNumber(value) {
        try {
          return parsePhoneNumber(value)?.isValid()
            ? null
            : translations.error.invalidPhoneNumber
        } catch {
          return translations.error.invalidPhoneNumber
        }
      },
      userType: validateFieldNotEmpty(
        translations.error.fieldRequired({
          field: translations.fields.lastName,
        })
      ),
    },
  })

  function userTypeHandler<K extends keyof typeof form>(key: K) {
    return (value: typeof form[K] | null) => {
      if (value === null) return
      updateForm({ [key]: value })
    }
  }

  const onErrorAuto = useOnErrorAuto()

  const [patchUserMutation] = useMutation<
    PatchUserMutation,
    PatchUserMutationVariables
  >(PATCH_USER_MUTATION, {
    onError: () => {
      addToast(
        'error',
        translations.toasts.updateError({
          type: translations.user.toLowerCase(),
        })
      )
    },
  })

  async function handleNumberExists(user: Pick<UserNode, 'id' | 'fullName'>) {
    const { data: answer } = await confirm(
      translations.confirm.deleteExistingNumber,
      translations.confirm.numberExists({ name: user.fullName })
    )
    if (!answer) return

    try {
      await patchUserMutation({
        variables: {
          id: user.id,
          input: {
            phoneNumber: '',
          },
        },
      })
    } finally {
      createUserMutation({
        variables: {
          ...form,
        },
      })
    }
  }

  const [createUserMutation, { loading }] = useMutation<
    CreateUserMutation,
    CreateUserMutationVariables
  >(CREATE_USER_MUTATION, {
    onCompleted: data => {
      const { createUser } = data
      if (!createUser.ok) {
        if (createUser.user) {
          handleNumberExists(createUser.user)
        } else {
          addToast(
            'error',
            translations.toasts.createError({
              type: translations.user.toLowerCase(),
            })
          )
        }
      } else {
        addToast(
          'success',
          translations.toasts.successAdd({
            type: translations.user.toLowerCase(),
          })
        )
        history.push('/users')
      }
    },
    onError: onErrorAuto(
      {},
      {
        showToast: false,
        callback({ message }: { message: string }) {
          if (message.includes('UNIQUE constraint failed: users_user.email')) {
            addToast(
              'warning',
              translations.error.duplicate.typeExist({
                type: translations.theEmail,
              })
            )
            updateFormErrors({
              email: translations.error.duplicate.typeExist({
                type: translations.theEmail,
              }),
            })
            return
          }
          if (message.includes('The phone number is already taken')) {
            addToast(
              'warning',
              translations.error.duplicate.typeExist({
                type: translations.phoneNumber,
              })
            )
            updateFormErrors({
              phoneNumber: translations.error.duplicate.typeExist({
                type: translations.phoneNumber,
              }),
            })
            return
          }
        },
      }
    ),
  })

  function submitForm(values: typeof form) {
    createUserMutation({
      variables: {
        ...values,
      },
    })
  }
  const userTypeOptions = [...company.userTypes].sort((a,b) => {
    if (a.name < b.name) return -1
    if (a.name > b.name) return 1
    return 0
  }).map(type => ({
    value: type.id,
    label: type.name,
  }))

  useEffect(() => {
    if (overrides['create']) return
    setOverrides({ create: translations.newUser })
  }, [overrides, setOverrides, translations.newUser])

  return (
    <Layout>
      <header>
        <h1>{translations.newUser}</h1>
      </header>
      <Card className="form-card">
        <GridForm preventDefault onSubmit={submitHandler(submitForm)}>
          <GridFormField area="email">
            <Label htmlFor="email">{translations.fields.email}</Label>
            <Input
              id="email"
              width="100%"
              background="inherit"
              tabIndex={4}
              value={form.email}
              error={errors.email}
              onChange={handler('email')}
            />
          </GridFormField>

          <GridFormField area="firstName">
            <Label htmlFor="firstName">{translations.fields.firstName}</Label>
            <Input
              id="firstName"
              width="100%"
              background="inherit"
              autoFocus
              tabIndex={1}
              value={form.firstName}
              error={errors.firstName}
              onChange={handler('firstName')}
            />
          </GridFormField>

          <GridFormField area="lastName">
            <Label htmlFor="lastName">{translations.fields.lastName}</Label>
            <Input
              id="lastName"
              width="100%"
              background="inherit"
              tabIndex={1}
              value={form.lastName}
              error={errors.lastName}
              onChange={handler('lastName')}
            />
          </GridFormField>
          <GridFormField area="phone">
            <Label htmlFor="phoneNumber">
              {translations.fields.phoneNumber}
            </Label>
            <PhoneInput
              id="phoneNumber"
              width="100%"
              background="inherit"
              tabIndex={2}
              value={form.phoneNumber}
              error={errors.phoneNumber}
              onChange={handler('phoneNumber')}
            />
          </GridFormField>

          <GridFormField area="userType">
            <Label htmlFor="userType">{translations.fields.userType}</Label>
            <Select
              id="userType"
              width="100%"
              background="white"
              tabIndex={5}
              value={form.userType}
              error={errors.userType}
              options={userTypeOptions}
              onChange={userTypeHandler('userType')}
            />
          </GridFormField>

          <GridFormField area="submit" className="submit">
            <SubmitButton
              width="200px"
              tabIndex={6}
              disabled={loading || !formValid}
            >
              {loading ? translations.loading : translations.create}
            </SubmitButton>
          </GridFormField>
        </GridForm>
      </Card>
    </Layout>
  )
}
