import { Icon } from '@ur/react-components'
import {
  CalendarPopupHeader,
  CalendarExtraBlocksPopupHeaderWrapper,
  CalendarExtraBlocksPopupWrapper,
  TimesIconWrapper,
} from './components'
import { CalendarLogicData } from '../types'
import { useRef } from 'react'
import { format } from 'util/date-fns'
import useClickOutside from 'util/hooks/useClickOutside'
import { CalendarBlock } from './CalendarBlock'

interface CalendarExtraBlocksPopupProps {
  context: CalendarLogicData
  onClickOutside: () => void
}

export const CalendarExtraBlocksPopup: React.VFC<CalendarExtraBlocksPopupProps> =
  ({ context, onClickOutside }) => {
    const wrapperRef = useRef<HTMLDivElement | null>(null)
    const items = context.extraSlotItems
    const displayDate = format(context.date, 'E. dd.MM').toUpperCase()
    useClickOutside(wrapperRef, onClickOutside, true, true)
    return (
      <CalendarExtraBlocksPopupWrapper ref={wrapperRef}>
        <CalendarExtraBlocksPopupHeaderWrapper>
          <CalendarPopupHeader>{displayDate}</CalendarPopupHeader>
          <TimesIconWrapper>
            <Icon
              icon="times"
              type="light"
              size="1.6rem"
              color="gray5"
              onClick={onClickOutside}
            />
          </TimesIconWrapper>
        </CalendarExtraBlocksPopupHeaderWrapper>
        {items.map(block => {
          return (
            <CalendarBlock
              key={block.id}
              background={block?.color ?? ''}
              ending={true}
              starting={true}
              text={block.text}
              onClick={block.onClick}
            />
          )
        })}
      </CalendarExtraBlocksPopupWrapper>
    )
  }
