import { UserTypeNode } from 'modules/users'
import styled from 'styled-components'

export interface UserTypesCellProps {
  userTypes: UserTypeNode[]
}

const TypesWrapper = styled.div`
  display: flex;
  overflow-x: hidden;
`

const TypeBlock = styled.div`
  display: inline-block;
  padding: 9px 18px 6px;
  border-radius: 999px;
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  background: ${props => props.theme.colors.primary};
  color: white;
  margin-right: 4px;
`

export const UserTypesCell: React.VFC<UserTypesCellProps> = ({ userTypes }) => {
  return (
    <td>
      <TypesWrapper>
        {userTypes.map(type => (
          <TypeBlock key={type.id}>{type.name}</TypeBlock>
        ))}
      </TypesWrapper>
    </td>
  )
}
