import styled from 'styled-components'

type ThumbnailShapeType =
  | 'circle'
  | 'rectangle'
  | 'roundedRectangle'
  | 'rhombus'
  | 'pentagon'
  | 'hexagon'
  | 'hexagonFlatTop'
  | 'octagon'
  | 'kite'

interface ThumbnailProps {
  // Used to specify width AND height if these are not given.
  size?: number
  // The width of the thumbnail. Size will be used in its place if not specified.
  width?: string
  // The height of the thumbnail. Size will be used in its place if not specified.
  height?: string
  objectFit?: string
  shape?: ThumbnailShapeType
}

const shapeClipPathLookups = {
  circle: 'circle(50% at 50% 50%)',
  rectangle: '', // No clip-path equals a square
  roundedRectangle: '', // No clip-path equals a square
  rhombus: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)',
  pentagon: 'polygon(50% 0%, 100% 40%, 80% 100%, 20% 100%, 0% 40%)',
  hexagon: 'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)',
  hexagonFlatTop: 'polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%)',
  octagon:
    'polygon(30% 0, 70% 0, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0 70%, 0 30%)',
  kite: 'polygon(50% 0%, 100% 30%, 50% 100%, 0% 30%)',
}

export const Thumbnail = styled.img.attrs<ThumbnailProps>(props => ({
  size: 35,
  objectFit: 'cover',
  shape: 'circle',
  ...props,
}))<ThumbnailProps>`
  width: ${props => props?.width || `${props.size}px`};
  height: ${props => props?.height || `${props.size}px`};
  object-fit: ${props => (props.objectFit ? props.objectFit : 'cover')};
  clip-path: ${props => shapeClipPathLookups[props.shape!]};
  ${props => props.shape === 'roundedRectangle' && `border-radius: 5px;`}
`
