import { FirmadokLogoNoText } from 'components'
import React from 'react'
import { usePushHistory } from 'util/hooks'
import styled from 'styled-components'
import { useCompany } from 'util/hooks'
import { CompanyLogo } from './CompanyLogo/'

interface LogoWrapperProps {
  companyLogo: boolean
}

const Wrapper = styled.div<LogoWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: ${props => (props.companyLogo ? 'center' : 'flex-start')};

  width: 100%;
  height: calc(${props => props.theme.layout.headerHeight} - 1.6em);
  margin-bottom: 2em;
  padding: 0.5em 0.5em 0 0.5em;

  span {
    margin-left: 0.8em;
    font-size: 1.4em;
    font-weight: 700;
    color: ${props => props.theme.colors.primary};
  }
`

const Logo = styled.div`
  height: 100%;
  padding: 0.8em;

  border-radius: 30%;
  background-color: ${props => props.theme.colors.primary};
  cursor: pointer;

  box-shadow: 4px 4px 18px -2px ${props => props.theme.colors.primary}77;
`

export const SidebarTop: React.VFC = () => {
  const pushHistory = usePushHistory()
  const company = useCompany()
  const renderCompanyLogo = !!company.logo && company.useCompanyLogo

  return (
    <Wrapper companyLogo={renderCompanyLogo}>
      {renderCompanyLogo ? (
        <CompanyLogo logo={company.logo!} />
      ) : (
        <>
          <Logo onClick={evt => pushHistory('/', evt)}>
            <FirmadokLogoNoText height="100%" />
          </Logo>
          <span>Firmadok</span>
        </>
      )}
    </Wrapper>
  )
}
