import { DefaultMessages, useTranslate } from '@ur/react-hooks'
import { FAIcon } from 'components/icons'
import styled from 'styled-components'

const ErrorWrapper = styled.div`
  height: 84vh;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: red;
  padding-left: 2rem;
  padding-right: 2rem;
  ${props => props.theme.layout.mobile} {
    height: 72vh;
  }
  div {
    display: inline-block;
    margin-right: 1rem;
  }
`

interface CenteredErrorMessageProps {
  className?: string

  intlId?: keyof DefaultMessages
  defaultMessage?: string
  message?: string
  icon?: string | JSX.Element
  noAccess?: boolean
}

export const CenteredErrorMessage: React.FC<CenteredErrorMessageProps> = ({
  className,

  intlId,
  message = true,
  icon = 'exclamation-triangle',
  noAccess = false,
}) => {
  const translations = useTranslate({
    noAccess: 'permissions.no-access-to-page',
    default: intlId || 'errors.generic-error',
  })
  const _message = message
    ? noAccess
      ? translations.noAccess
      : translations.default
    : null
  const _icon =
    typeof icon === 'string' ? <FAIcon icon={icon} color="error" /> : icon
  return (
    <ErrorWrapper className={className}>
      <div>{_icon}</div>
      {_message}
    </ErrorWrapper>
  )
}
