import styled from 'styled-components'
import { SectionHeader } from '../common'

export const ExportSectionHeader = styled.h2`
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const MassExportSectionHeader = styled(SectionHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
