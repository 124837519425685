import { useQuery } from '@apollo/client'
import { useTranslate } from '@ur/react-hooks'
import { Title } from 'components'
import { ROUTE_PLAN_INSTRUCTIONS_QUERY } from 'modules/routePlanner/queries'
import {
  RoutePlanInstructions,
  RoutePlanInstructionsVariables,
} from 'modules/routePlanner/types.graphql'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { RouteInstructionList } from './RouteInstructionList'

interface WrapperProps {
  gridArea: string
}

const Wrapper = styled.div<WrapperProps>`
  grid-area: ${props => props.gridArea};
  display: flex;
  flex-direction: column;
  max-height: 500px;
  padding: 1rem;
  height: 100%;
  border: 1px solid ${props => props.theme.colors.gray8};
  border-radius: 0 10px 0 0;
  overflow: clip;

  ${props => props.theme.media.mobile} {
    border: 0;
    border-radius: 0;
    border-top: 1px solid ${props => props.theme.colors.gray8};
  }
`

interface RouteInstructionParams {
  routePlanId: string
}

export const RouteInstructions: React.VFC<{
  gridArea: string
}> = ({ gridArea }) => {
  const { routePlanId } = useParams<RouteInstructionParams>()
  const { data, loading } = useQuery<
    RoutePlanInstructions,
    RoutePlanInstructionsVariables
  >(ROUTE_PLAN_INSTRUCTIONS_QUERY, {
    variables: { routePlanId },
  })

  const translations = useTranslate({
    routeInstructions: 'route-planner.route-instructions',
  })

  const instructions = data?.routePlanInstructions

  return (
    <Wrapper gridArea={gridArea}>
      <Title.H2>{translations.routeInstructions}</Title.H2>
      <RouteInstructionList
        routeInstructions={instructions ?? []}
        loading={loading}
      />
    </Wrapper>
  )
}
