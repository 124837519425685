import { useTranslate } from '@ur/react-hooks'
import { Cell, CellProps } from 'components'
import { ActivitiesDynamicColumnDayReport } from 'modules/activities/types.graphql'


interface SalaryCodeProps {
  report: ActivitiesDynamicColumnDayReport,
  subColumn: boolean
  useExternalId: boolean
}
export const SalaryCode: React.FC<SalaryCodeProps> = ({
  report,
  subColumn = false,
  useExternalId = false,
}) => {
  const translations = useTranslate({
    nSalaryCodes: ['common.n-salary-code', {n: 2}],
  })
  const singleDataKey = useExternalId ? 'salaryId' : 'salaryName'
  if (subColumn) {
    return <>{report[singleDataKey]}</>
  }
  const data = useExternalId ? report['salaryIds'] : report['salaryNames']

  const display = data.length > 1 ? translations.nSalaryCodes({n: data.length}) : data.length === 1 ? data[0] : ''
  return <>{display}</>

}
interface SalaryCodeCellProps extends SalaryCodeProps, CellProps {}
export const SalaryCodeCell: React.VFC<SalaryCodeCellProps> = ({
  narrow = true,
  centered = true,
  ...salaryCodeProps
}) => (
  <Cell narrow={narrow} centered={centered}>
    <SalaryCode {...salaryCodeProps} />
  </Cell>
)
