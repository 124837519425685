import { AddDriverCard } from 'modules/users/UserProfile/AddDriverCard'
import React from 'react'
import { useUser } from 'util/hooks'

interface DashboardDriverCardProps {}

export const DashboardDriverCard: React.VFC<DashboardDriverCardProps> = () => {
  const user = useUser()

  if (!!user.activeDriverCard) return null

  return <AddDriverCard user={user} />
}
