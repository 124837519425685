import styled from 'styled-components'
import { overloadColorProp } from 'util/style'
import { CSSFontWeight, CSSTextAlign, CSSTextTransform } from 'types/style'

export interface TextProps {
  fontSize?: string
  fontWeight?: CSSFontWeight
  textAlign?: CSSTextAlign
  textTransform?: CSSTextTransform
  lineHeight?: string | number

  block?: boolean
  color?: string

  userSelect?: string
}

export const Text = styled.span.attrs((props: TextProps) => ({
  color: props.color || 'black',
  textAlign: props.textAlign || 'left',
  textTransform: props.textTransform || 'none',
  fontWeight: props.fontWeight || 'normal',
  ...props,
}))`
  display: ${props => (props.block ? 'block' : 'inline')};
  color: ${overloadColorProp};

  font-size: ${props => props.fontSize};
  text-align: ${props => props.textAlign};
  text-transform: ${props => props.textTransform};
  font-weight: ${props => props.fontWeight};
  line-height: ${props => props.lineHeight};
  user-select: ${props => props.userSelect};
`
