import { Icon, Loader, useToast } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import {
  Button,
  DropArea,
  QuillAttachmentsContainer,
  QuillEditor as BaseQuillEditor,
  QuillEditorToolbar,
} from 'components'
import Quill, { QuillOptionsStatic } from 'quill'
import React, { useImperativeHandle, useRef, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { FileAttachment, useAttachmentHandlers } from 'util/hooks'
import { safeParseJson, urlIsValid } from 'util/parsing'
import { Attachment } from 'modules/forum'
import { PostAttachment } from './PostAttachment'
import Delta from 'quill-delta'
import Op from 'quill-delta/dist/Op'
import { quillValidation } from 'util/forms'

const Wrapper = styled.div`
  max-width: 100%;
`
interface QuillEditorProps {
  minHeight: string
}
const QuillEditor = styled(BaseQuillEditor)<QuillEditorProps>`
  min-height: ${props => props.minHeight};
  border: 0 !important;
  background: rgba(255, 255, 255, 0) !important;

  .ql-container {
    min-height: 72px;
    padding: 1rem !important;
    background: inherit !important;

    .ql-editor {
      min-height: 1ch;
      border: 0;
      background: inherit !important;

      &::before {
        margin-left: 1rem;
        font-style: normal;
      }
    }
  }
`
const ToolbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  background: ${props => props.theme.colors.quaternary};
  border-bottom-left-radius: ${props => props.theme.sizes.defaultBorderRadius};
  border-bottom-right-radius: ${props => props.theme.sizes.defaultBorderRadius};

  div.buttons {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
  }

  ${props => props.theme.media.mobile} {
    flex-direction: column;

    div.buttons {
      justify-content: flex-end;
      margin-right: 0;
      padding: 0.5rem;
    }
  }
`

const ToolbarButton = styled(Button)`
  height: 38px;
  padding: 0 1.5rem;

  border-radius: 8px;
  font-size: 0.8rem;
  font-weight: 500;
  white-space: nowrap;

  i {
    margin: 0 0.5rem 0 0;
  }

  & + button {
    margin-left: 0.5rem;
  }
  &.attachment {
    border: 1px solid ${props => props.theme.colors.gray6};
    background-color: inherit;
    color: ${props => props.theme.colors.gray3};
  }
  &.submit {
    i {
      margin: 0 0 0 0.5rem;
    }
    div {
      margin-left: 6px;
    }
  }
`

export interface PostQuillForm {
  content: string
  attachments: Attachment[]
}

export interface ForumQuillEditorRef {
  quill: Quill | null
}

interface ForumQuillEditorProps {
  initialContent?: string
  attachments?: Attachment[]

  submitText?: string
  placeholder?: string
  loading?: boolean

  /** If true, the submit button will not be disabled while text content is blank. */
  noValidation?: boolean
  noAttachmentReset?: boolean
  roundedTopCorners?: boolean
  minHeight?: string

  onInit?: (quill: Quill) => void
  onSubmit: (
    content: string,
    attachmentsAdd: FileAttachment[],
    attachmentsRemove: string[]
  ) => Promise<void>
}

export const ForumQuillEditor = React.forwardRef<
  ForumQuillEditorRef,
  ForumQuillEditorProps
>(
  (
    {
      initialContent = '[]',
      attachments = [],

      submitText,
      placeholder,
      loading = false,

      noValidation = false,
      noAttachmentReset = false,
      roundedTopCorners = false,
      minHeight = '72px',

      onInit = () => void 0,
      onSubmit,
    },
    ref
  ) => {
    const translations = useTranslate({
      addAttachment: 'common.add-attachment',
      submit: 'forum.post-comment',

      linkHelper: 'forum.quill-link-helper',
      enterUrl: 'common.enter-url',
      invalidUrl: 'common.invalid-url',
    })

    const quillRef = useRef<Quill>(null)
    const fileInputRef = useRef<HTMLInputElement>(null)

    const theme = useTheme()
    const addToast = useToast()

    const [content, setContent] = useState(initialContent)
    const [contentValid, setContentValid] = useState(
      noValidation || validateContent(initialContent)
    )

    useImperativeHandle(ref, () => ({
      quill: quillRef.current,
    }))

    const {
      allAttachments,
      attachmentsAdd,
      attachmentsRemove,
      resetEditedAttachments,
      handleDropAttachment,
      handleAddAttachment,
      handleRemoveAttachment,
    } = useAttachmentHandlers(attachments)

    function validateContent(content: string) {
      const ops = safeParseJson<{ ops: Op[] }>(content, { ops: [] })
      const delta = new Delta(ops)
      return quillValidation(delta)
    }

    function handleChange(content: string) {
      setContent(content)
      setContentValid(noValidation || validateContent(content))
    }

    async function handleSubmit() {
      await onSubmit(content, attachmentsAdd, attachmentsRemove)
      if (!noAttachmentReset) resetEditedAttachments()
    }

    function handleQuillLink(this: { quill: Quill }, value: boolean) {
      const selectionLength = this.quill.getSelection()?.length ?? 0
      if (selectionLength === 0) {
        addToast('info', translations.linkHelper)
        return
      }

      if (value) {
        let href = window.prompt(translations.enterUrl)
        if (!href) return

        // Check if mailto or tel
        if (!/mailto:.+@.+\..+/.test(href) && !/tel:(\+)?\d+/.test(href)) {
          // Attach https if not present
          if (!/^\w+:\/\//.test(href)) href = `https://${href}`

          if (!urlIsValid(href)) {
            addToast('error', translations.invalidUrl)
            return
          }
        }

        this.quill.format('link', href)
      } else {
        this.quill.format('link', false)
      }
    }

    const quillOptions: QuillOptionsStatic = {
      bounds: '#editor',
      modules: {
        toolbar: {
          container: '#toolbar',
          handlers: {
            link: handleQuillLink,
          },
        },
        uploader: {
          handler: () => void 0,
        },
      },
    }

    return (
      <Wrapper>
        <DropArea
          dragStyle={{
            backgroundColor: '#d6d6f1',
            borderTopLeftRadius: roundedTopCorners
              ? '0'
              : theme.sizes.defaultBorderRadius,
            borderTopRightRadius: roundedTopCorners
              ? '0'
              : theme.sizes.defaultBorderRadius,
          }}
          onDrop={handleDropAttachment}
        >
          <QuillEditor
            id="editor"
            outerQuillRef={quillRef}
            options={quillOptions}
            placeholder={placeholder}
            minHeight={minHeight}
            onChange={handleChange}
            onInit={onInit}
          />

          {!!allAttachments.length && (
            <QuillAttachmentsContainer>
              {allAttachments.map(attachment => (
                <PostAttachment
                  key={attachment.id}
                  attachment={attachment}
                  nonClickable
                  deletable
                  onDelete={() => handleRemoveAttachment(attachment)}
                />
              ))}
            </QuillAttachmentsContainer>
          )}
        </DropArea>

        <ToolbarWrapper>
          <QuillEditorToolbar />

          <div className="buttons">
            <ToolbarButton
              className="attachment"
              onClick={() => fileInputRef.current?.click()}
            >
              {/* TODO not correct icon */}
              <Icon icon="paperclip" type="regular" />
              {translations.addAttachment}
            </ToolbarButton>

            <ToolbarButton
              className="submit"
              disabled={!contentValid}
              onClick={handleSubmit}
            >
              {submitText ?? translations.submit}
              {/* TODO not correct icon */}
              {!loading ? (
                <Icon icon="paper-plane" type="solid" fixedWidth />
              ) : (
                <Loader.Spinner size={18} thickness={2} />
              )}
            </ToolbarButton>

            <input
              ref={fileInputRef}
              hidden
              type="file"
              multiple
              onChange={handleAddAttachment}
            />
          </div>
        </ToolbarWrapper>
      </Wrapper>
    )
  }
)
