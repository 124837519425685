import styled from 'styled-components'

import { Card as BaseCard } from 'components/Card'

export const FormCard = styled(BaseCard)`
  margin-top: 1rem;
  padding: 2rem;
`

export const Field = styled.div`
  & + div {
    margin-top: 1rem;
  }
`
