import { Icon } from '@ur/react-components'
import { Card } from 'components/Card'
import React, { useMemo, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { animated, useTransition } from 'react-spring'
import styled from 'styled-components'
import { BaseProps } from 'types/props'
import { usePermissions, useElementSize } from 'util/hooks'

const Item = styled(NavLink)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 1.8rem;

  color: ${props => props.theme.colors.gray4};
  font-weight: 600;

  &.active {
    background-color: white;
    box-shadow: ${props => props.theme.shadow.default};
    color: ${props => props.theme.colors.text.dark};
  }
  &:hover {
    text-decoration: none;
    color: ${props => props.theme.colors.secondary};
  }

  ${props => props.theme.media.mobile} {
    padding: 1rem;
  }
`

const Wrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 0;

  background-color: rgba(255, 255, 255, 0.3);
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  overflow: hidden;

  ${Item} + ${Item} {
    border-top: solid 1px ${props => props.theme.colors.gray9};
  }
`

const Expandable = styled.div``

export interface LinkListItem {
  text: string
  href: string
  permissions?: string[] | string
  hasModule?: boolean
  /** Use exact route. Default: true */
  exact?: boolean
  /** If set, find selected element by this regex */
  matchBy?: RegExp
}

interface LinkListProps extends BaseProps {
  items: LinkListItem[]
  expandable?: boolean
}

export const LinkList: React.VFC<LinkListProps> = ({
  className,
  id,
  items,
  expandable = false,
}) => {
  const location = useLocation()
  const { hasPermissions } = usePermissions()

  const [expanded, setExpanded] = useState(false)

  const [expandedElement, setExpandedElement] =
    useState<HTMLElement | null>(null)
  const { height: expandedHeight } = useElementSize(expandedElement)

  const expandTransition = useTransition(expanded, {
    from: {
      height: 0,
    },
    enter: {
      height: expandedHeight,
    },
    leave: {
      height: 0,
    },
    update: { height: expandedHeight },
  })

  const filteredItems = useMemo(
    () =>
      items.filter(item =>
        !item?.hasModule && typeof item.hasModule !== 'undefined'
          ? false
          : typeof item.permissions === 'undefined'
          ? true
          : hasPermissions(item.permissions)
      ),
    [hasPermissions, items]
  )

  const [selected, unselected] = useMemo(() => {
    if (!expandable) return [filteredItems[0], []]

    return filteredItems.reduce<[LinkListItem, LinkListItem[]]>(
      (acc, item) => {
        const matched =
          typeof item.matchBy !== 'undefined'
            ? item.matchBy.test(location.pathname)
            : location.pathname.startsWith(item.href)

        if (matched) acc[0] = item
        else acc[1].push(item)
        return acc
      },
      [filteredItems[0], []]
    )
  }, [expandable, filteredItems, location.pathname])

  return (
    <Wrapper className={className} id={id}>
      {!expandable ? (
        filteredItems.map(item => (
          <Item key={item.href} to={item.href} exact={item.exact ?? true}>
            {item.text}
          </Item>
        ))
      ) : (
        <Expandable>
          <div className="selected">
            <Item
              to="#"
              exact={selected.exact}
              onClick={() => setExpanded(v => !v)}
            >
              {selected.text}
              <Icon
                icon={expanded ? 'chevron-up' : 'chevron-down'}
                color="gray6"
              />
            </Item>
          </div>
          {expandTransition(
            (style, show) =>
              show && (
                <animated.div
                  style={{
                    ...style,
                    overflow: 'hidden',
                    position: 'relative',
                  }}
                >
                  <div className="list" ref={setExpandedElement}>
                    {unselected.map(item => (
                      <Item
                        key={item.href}
                        to={item.href}
                        exact={item.exact ?? true}
                        onClick={() => setExpanded(false)}
                      >
                        {item.text}
                      </Item>
                    ))}
                  </div>
                </animated.div>
              )
          )}
        </Expandable>
      )}
    </Wrapper>
  )
}
