import { useTranslate } from '@ur/react-hooks'
import {
  passwordContainsNonNumericValue,
  passwordContainsNumericValue,
  passwordLengthValidation,
} from 'util/forms'

export function usePasswordValidation() {
  const translations = useTranslate({
    numeric: 'password.must-contain-numeric-value',
    nonNumeric: 'password.must-contain-nonnumeric',
    tooShort: 'password.too-short',
  })
  const passwordTooShort = (x: string) => {
    if (!passwordLengthValidation(x)) {
      return translations.tooShort
    } else return null
  }
  const passwordDoesNotContainNumber = (x: string) => {
    if (!passwordContainsNumericValue(x)) {
      return translations.numeric
    } else return null
  }
  const passwordDoesNotContainNonNumericValue = (x: string) => {
    if (!passwordContainsNonNumericValue(x)) {
      return translations.nonNumeric
    } else return null
  }

  const validatePassword = (value: string): string | null => {
    const passwordLengthReturn = passwordTooShort(value)
    const noNumberReturn = passwordDoesNotContainNumber(value)
    const noCharReturn = passwordDoesNotContainNonNumericValue(value)

    if (!!passwordLengthReturn) return passwordLengthReturn
    if (!!noNumberReturn) return noNumberReturn
    if (!!noCharReturn) return noCharReturn
    return null
  }

  return {
    validatePassword,
  }
}
