import { useTranslate } from '@ur/react-hooks'
import { Button as BaseButton, Input, ModuleTitle } from 'components'
import { Card } from 'components/Card'
import { PlainLink } from 'components/Link'

import { PermissionsRequired } from 'containers/permission-containers'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import { useDebounce } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { ThreadsTable } from '../components/thread'

const Wrapper = styled.div`
  ${props => props.theme.layout.default};
`
const Controls = styled(Card)`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  gap: 1rem;

  margin-bottom: 1rem;
  padding: 1rem;
`
const MobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`
const Button = styled(BaseButton)`
  ${props => props.theme.media.mobile} {
    padding: 0 3rem 0 1rem;
  }
`

export const Forum: React.VFC = () => {
  const translations = useTranslate({
    forum: 'common.forum',
    searchInForum: 'forum.search',
    newThread: 'forum.new-thread',
  })

  const [query, setQuery] = useState('')
  const [sort, setSort] = useState<string>(
    localStorage.getItem('forumSort') || '-createdAt'
  )

  const debouncedQuery = useDebounce(query)

  useEffect(() => {
    localStorage.setItem('forumSort', sort || '-createdAt')
  }, [sort])

  return (
    <Wrapper>
      {isMobile && (
        <MobileHeader>
          <ModuleTitle inlineHeader>{translations.forum}</ModuleTitle>

          <PermissionsRequired permissions={PERMISSIONS.forum.add.thread}>
            <PlainLink to="/forum/create">
              <Button
                iconLeftProps={{ icon: 'plus', margin: '0' }}
                height="42px"
                padding="0"
                fontSize="0.8em"
              >
                {translations.newThread}
              </Button>
            </PlainLink>
          </PermissionsRequired>
        </MobileHeader>
      )}

      {!isMobile && <ModuleTitle>{translations.forum}</ModuleTitle>}
      <Controls>
        <Input
          value={query}
          type="search"
          placeholder={translations.searchInForum}
          onChange={setQuery}
        />
        {!isMobile && (
          <PermissionsRequired permissions={PERMISSIONS.forum.add.thread}>
            <PlainLink to="/forum/create">
              <Button iconLeftProps={{ icon: 'plus' }}>
                {translations.newThread}
              </Button>
            </PlainLink>
          </PermissionsRequired>
        )}
      </Controls>

      <ThreadsTable query={debouncedQuery} sort={sort} onSort={setSort} />
    </Wrapper>
  )
}
