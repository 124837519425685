import React from 'react'
import styled from 'styled-components'

import {
  ChecklistType,
  ChecklistEntries,
  PatchChecklistItem,
} from 'modules/checklists'
import { Checkbox } from 'components'

const HorizontalFlex = styled.div`
  display: flex;
  flex-direction: row;
  p {
    margin-left: 1rem;
  }
`

const VerticalFlex = styled.div`
  display: flex;
  flex-direction: column;
  ${HorizontalFlex} {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    &:first-child {
      margin-top: 0.25rem;
    }
  }
`

const ChecklistWrapper = styled.div`
  margin-bottom: 1rem;
  width: 100%;
`

export const Checklist: React.FC<{
  checklist: ChecklistType
  onChange: (
    checklist: ChecklistType,
    checklistItem: PatchChecklistItem,
    value: ChecklistEntries
  ) => void
}> = ({ checklist, onChange }) => {
  return (
    <ChecklistWrapper>
      {<h3>{checklist.title}</h3>}
      <VerticalFlex>
        {Object.keys(checklist.entries).map(key => (
          <HorizontalFlex key={key}>
            <Checkbox
              checked={checklist.entries[key].done}
              onChange={value =>
                onChange(
                  checklist,
                  { id: checklist.entries[key].id, done: value },
                  Object.assign(checklist.entries, {
                    [checklist.entries[key].id]: {
                      id: checklist.entries[key].id,
                      text: checklist.entries[key].text,
                      done: value,
                    },
                  })
                )
              }
            />
            <p>{checklist.entries[key].text}</p>
          </HorizontalFlex>
        ))}
      </VerticalFlex>
    </ChecklistWrapper>
  )
}
