import React, { useMemo } from 'react'
import styled from 'styled-components'
import { overloadColor } from 'util/style'
import {
  Bell,
  Book,
  CalendarMonth,
  CalendarSelection,
  CalendarWeek,
  Chat,
  Clock,
  Cog,
  CogFilled,
  Comments,
  Dashboard,
  EquipmentMaterials,
  FormCreator,
  House,
  Infringement,
  RoutePlanner,
  Petrol,
  Truck,
  TruckSide,
  Users,
  UserX,
  Warning,
  AvailabilityIcon,
} from './icons'
import { FileArrowDown } from './icons/file-arrow-down'

interface WrapperProps {
  hoverColor?: string
}

const Wrapper = styled.span<WrapperProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  svg {
    &:hover {
      fill: ${props => props.hoverColor && overloadColor(props.hoverColor)};
    }
  }
`

export type FirmadokIcon =
  | 'bell'
  | 'book'
  | 'calendar-month'
  | 'calendar-selection'
  | 'calendar-week'
  | 'chat'
  | 'clock'
  | 'cog'
  | 'cog-filled'
  | 'comments'
  | 'dashboard'
  | 'equipment-materials'
  | 'file-arrow-down'
  | 'form-creator'
  | 'house'
  | 'infringement'
  | 'route-planner'
  | 'petrol'
  | 'truck'
  | 'truck-side'
  | 'user-x'
  | 'users'
  | 'warning'
  | 'availability-icon'

export interface SVGIconProps extends React.SVGAttributes<SVGSVGElement> {
  className?: string
  icon: FirmadokIcon

  hoverColor?: string
}

export const SVGIcon: React.FC<SVGIconProps> = ({
  className,
  icon,
  hoverColor,
  ...svgProps
}) => {
  svgProps = {
    ...svgProps,
    fill:
      typeof svgProps.fill !== 'undefined'
        ? overloadColor(svgProps.fill) ?? undefined
        : undefined,
  }

  const SVG = useMemo(() => {
    switch (icon) {
      case 'bell':
        return Bell
      case 'book':
        return Book
      case 'calendar-month':
        return CalendarMonth
      case 'calendar-selection':
        return CalendarSelection
      case 'calendar-week':
        return CalendarWeek
      case 'chat':
        return Chat
      case 'clock':
        return Clock
      case 'cog':
        return Cog
      case 'cog-filled':
        return CogFilled
      case 'comments':
        return Comments
      case 'dashboard':
        return Dashboard
      case 'equipment-materials':
        return EquipmentMaterials
      case 'file-arrow-down':
        return FileArrowDown
      case 'form-creator':
        return FormCreator
      case 'house':
        return House
      case 'infringement':
        return Infringement
      case 'route-planner':
        return RoutePlanner
      case 'petrol':
        return Petrol
      case 'truck':
        return Truck
      case 'truck-side':
        return TruckSide
      case 'user-x':
        return UserX
      case 'users':
        return Users
      case 'warning':
        return Warning
      case 'availability-icon':
        return AvailabilityIcon
    }
  }, [icon])

  return (
    <Wrapper className={className} hoverColor={hoverColor}>
      <SVG {...svgProps} />
    </Wrapper>
  )
}
