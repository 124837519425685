import { ApolloClient, ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import { getPreferredLanguage } from 'locale/util'
import { getLoginToken } from 'modules/login/util'
import { inMemoryCache } from 'store/cache'
import { API_URL } from 'util/env'

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getLoginToken()

  const companyMatch = window.location.pathname.match(/\/c\/([\w-]+)/)
  const companyShortName = companyMatch?.pop()
  const companyHeader = !!companyShortName
    ? { 'X-Firmadok-Company': companyShortName }
    : {}

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      ...companyHeader,
    },
  }
})

const languageLink = setContext((_, { headers }) => {
  const lang = getPreferredLanguage()

  return {
    headers: {
      ...headers,
      'Accept-Language': lang ? lang : '',
    },
  }
})

const uploadLink = createUploadLink({
  uri: API_URL + '/graphql',
})

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (
    networkError &&
    // This is required to limit the type of networkError, as not all posibilties
    // have a statusCode
    'statusCode' in networkError &&
    networkError.statusCode === 403
  ) {
    localStorage.setItem('FIRMADOK_REDIRECT_LOGIN', window.location.pathname)
    window.location.href = '/login'
    return
  }
  if (graphQLErrors) graphQLErrors.map(({ message }) => console.error(message))
})

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, uploadLink, languageLink]),
  cache: inMemoryCache,
})

export default client
