import { PromptResolve, SelectOption } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { RegularModal, Select } from 'components'
import { Field, Form } from 'modules/companies'
import { EditAbsenceStatusForm } from 'modules/timesheets/types'
import { AbsenceNode } from '../types.graphql'

interface EditAbsenceStatusModalProps {
  absence: Omit<AbsenceNode, 'company'>
  onSubmit: PromptResolve<EditAbsenceStatusForm | null>
}

export const EditAbsenceStatusModal: React.VFC<EditAbsenceStatusModalProps> = ({
  absence,
  onSubmit,
}) => {
  const translations = useTranslate({
    status: 'common.status',
    editAbsenceStatus: 'timesheets.edit-absence-status',
    approved: 'timesheets.absence-approved',
    pending: 'timesheets.absence-pending',
    rejected: 'timesheets.absence-rejected',

    cancel: 'common.cancel',
    submit: 'common.submit',
  })

  const {
    formValues: form,
    updateForm,
    submitHandler: submit,
  } = useForm<EditAbsenceStatusForm>({
    values: {
      requestStatus: absence.requestStatus ?? '',
    },
  })

  const requestStatusOptions: SelectOption<string>[] = [
    { value: 'APPROVED', label: translations.approved },
    { value: 'PENDING', label: translations.pending },
    { value: 'REJECTED', label: translations.rejected },
  ]

  return (
    <RegularModal
      title={translations.editAbsenceStatus}
      submitText={translations.submit}
      cancelOnClickOutside
      width="400px"
      onSubmit={submit(onSubmit)}
      onCancel={() => onSubmit(null)}
    >
      <Form>
        <Field>
          <label>{translations.status}</label>
          <Select
            value={form.requestStatus}
            options={requestStatusOptions}
            fullWidth
            grayed
            onChange={value => updateForm({ requestStatus: value ?? '' })}
          />
        </Field>
      </Form>
    </RegularModal>
  )
}
