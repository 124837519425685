import { useTranslate } from '@ur/react-hooks'
import { FormErrors, UpdateFormFn } from '@ur/react-hooks/build/useForm/types'
import { PhoneInput } from 'components'
import React from 'react'
import styled from 'styled-components'
import { safeParsePhoneNumber } from 'util/parsing'
import { ProfileQuery } from '..'
import { ProfileEditForm } from '../types'
import { Input, InputArea, ProfileCard } from './components'

const Wrapper = styled(ProfileCard)`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'header header'
    'name   phone';
  gap: 1.5rem;

  header {
    grid-area: header;
  }

  ${props => props.theme.media.mobile} {
    grid-template-columns: 1fr;
    grid-template-areas:
      'header'
      'name'
      'phone';
    gap: 1rem;
  }
`

interface ContactPersonProps {
  user: ProfileQuery['user'] | null
  editing: boolean
  editForm: ProfileEditForm
  errors: FormErrors<ProfileEditForm>

  onUpdateForm: UpdateFormFn<ProfileEditForm>
}

export const ContactPerson: React.VFC<ContactPersonProps> = ({
  user,
  editing,
  editForm,
  errors,

  onUpdateForm,
}) => {
  const translations = useTranslate({
    contactPerson: 'common.contact-person',
    name: 'common.name',
    phoneNumber: 'common.phone-number',
  })

  return (
    <Wrapper>
      <header>
        <h3>{translations.contactPerson}</h3>
      </header>

      <InputArea area="name">
        <label>{translations.name}</label>
        <Input
          value={editForm.contactPersonName}
          error={errors.contactPersonName}
          editing={editing}
          onChange={contactPersonName => onUpdateForm({ contactPersonName })}
        />
      </InputArea>

      <InputArea area="phone">
        <label>{translations.phoneNumber}</label>
        {editing ? (
          <PhoneInput
            value={editForm.contactPersonPhoneNumber}
            error={errors.contactPersonPhoneNumber}
            height="54px"
            background="inherit"
            onChange={contactPersonPhoneNumber =>
              onUpdateForm({ contactPersonPhoneNumber })
            }
          />
        ) : (
          <Input
            value={safeParsePhoneNumber(editForm.contactPersonPhoneNumber)}
            editing={editing}
            iconRightProps={{
              icon: 'phone',
              type: 'solid',
              cursor: 'pointer',
              onClick: () =>
                window.open(`tel:${user?.contactPersonPhoneNumber}`),
            }}
            onChange={() => void 0}
          />
        )}
      </InputArea>
    </Wrapper>
  )
}
