import { useTranslate } from '@ur/react-hooks'
import React from 'react'
import styled from 'styled-components'
import { FormTemplateFieldType } from '..'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`
interface TypeProps {
  active: boolean
}
const Type = styled.div.attrs({ role: 'button' })<TypeProps>`
  flex-grow: 1;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.875rem;
  margin: 0 0.5rem;

  font-weight: 600;
  font-size: 0.9rem;
  text-transform: uppercase;
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};

  color: ${props => props.active && 'white'};
  background-color: ${props => props.active && props.theme.colors.secondary};
  user-select: none;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: ${props =>
      !props.active && `${props.theme.colors.secondary}56`};
  }

  ${props => props.theme.media.mobile} {
    height: 2.5rem;
    margin: 0;
    padding: 2px 0 0;
  }
`

interface FieldTypeSelectProps {
  value: FormTemplateFieldType
  onChange: (value: FormTemplateFieldType) => void
}

export const FieldTypeSelect: React.VFC<FieldTypeSelectProps> = ({
  value,
  onChange,
}) => {
  const translations = useTranslate({
    TEXT: 'form.field-type-text',
    NUMBER: 'form.field-type-number',
    DATE: 'form.field-type-date',
    CHECKBOX: 'form.field-type-boolean',
    USER: 'form.field-type-user',
    VEHICLE_DEPARTMENT: 'form.field-type-vehicle-department',
  })

  const types: FormTemplateFieldType[] = [
    'TEXT',
    'NUMBER',
    'DATE',
    'CHECKBOX',
    'USER',
    'VEHICLE_DEPARTMENT',
  ]

  return (
    <Wrapper>
      {types.map(type => (
        <Type key={type} active={value === type} onClick={() => onChange(type)}>
          {translations[type]}
        </Type>
      ))}
    </Wrapper>
  )
}
