import { gql } from '@apollo/client'

/* ABSENCES*/

export const EXPORT_ABSENCES_MASS = gql`
  mutation ExportAbsencesMass(
    $userId: ID!
    $users: [ID]!
    $companyId: ID!
    $dateStart: String!
    $dateEnd: String!
    $exportType: String!
    $exportFormat: ExportFormat
    $orderBy: String
    $exportOption: ExportOption
  ) {
    exportAbsencesMass(
      userId: $userId
      users: $users
      companyId: $companyId
      dateStart: $dateStart
      dateEnd: $dateEnd
      exportType: $exportType
      exportFormat: $exportFormat
      orderBy: $orderBy
      exportOption: $exportOption
    ) {
      ok
    }
  }
`

/* ACTIVITIES */
export const EXPORT_ACTIVITIES = gql`
  mutation ExportActivities(
    $userMail: String!
    $user: ID!
    $company: ID!
    $dateStart: String!
    $dateEnd: String!
    $exportType: String!
    $exportFormat: ExportFormat
    $exportOption: ExportOption
    $weekly: Boolean
  ) {
    exportActivities(
      userMail: $userMail
      user: $user
      company: $company
      dateStart: $dateStart
      dateEnd: $dateEnd
      exportType: $exportType
      exportFormat: $exportFormat
      exportOption: $exportOption
      weekly: $weekly
    ) {
      ok
      html
    }
  }
`

export const EXPORT_ACTIVITIES_MASS = gql`
  mutation ExportActivitiesMass(
    $exportType: String!
    $exportFormat: ExportFormat!
    $user: ID!
    $users: [ID]
    $company: ID!
    $dateStart: String!
    $dateEnd: String!
    $orderBy: String
    $includeAbsences: Boolean
    $exportOption: ExportOption
    $weekly: Boolean
  ) {
    exportActivitiesMass(
      exportType: $exportType
      exportFormat: $exportFormat
      user: $user
      users: $users
      company: $company
      dateStart: $dateStart
      dateEnd: $dateEnd
      orderBy: $orderBy
      includeAbsences: $includeAbsences
      exportOption: $exportOption
      weekly: $weekly
    ) {
      ok
      html
    }
  }
`

/* TERMINAL */
export const EXPORT_TERMINAL = gql`
  mutation ExportTerminal(
    $userMail: String!
    $user: ID!
    $company: ID!
    $dateStart: String!
    $dateEnd: String!
    $exportType: String!
    $exportFormat: ExportFormat
    $exportOption: ExportOption
  ) {
    exportTerminal(
      userMail: $userMail
      user: $user
      company: $company
      dateStart: $dateStart
      dateEnd: $dateEnd
      exportType: $exportType
      exportFormat: $exportFormat
      exportOption: $exportOption
    ) {
      ok
      html
    }
  }
`

export const EXPORT_TERMINAL_MASS = gql`
  mutation ExportTerminalMass(
    $userMail: String!
    $user: ID!
    $users: [ID]!
    $company: ID!
    $dateStart: String!
    $dateEnd: String!
    $exportType: String!
    $exportFormat: ExportFormat
    $orderBy: String
    $exportOption: ExportOption
  ) {
    exportTerminalMass(
      userMail: $userMail
      user: $user
      users: $users
      company: $company
      dateStart: $dateStart
      dateEnd: $dateEnd
      exportType: $exportType
      exportFormat: $exportFormat
      orderBy: $orderBy
      exportOption: $exportOption
    ) {
      ok
      html
    }
  }
`

/* ISSUES */
export const EXPORT_ISSUE = gql`
  mutation ExportIssue(
    $userId: ID!
    $reportId: ID!
    $recipients: [String]
    $exportType: String!
    $exportFormat: ExportFormat
    $exportOption: ExportOption
  ) {
    exportIssue(
      userId: $userId
      reportId: $reportId
      recipients: $recipients
      exportType: $exportType
      exportFormat: $exportFormat
      exportOption: $exportOption
    ) {
      ok
    }
  }
`

export const EXPORT_ISSUES_MASS = gql`
  mutation ExportIssueMass(
    $userId: ID!
    $companyId: ID!
    $recipients: [String]
    $dateStart: String!
    $dateEnd: String!
    $exportType: String!
    $exportFormat: ExportFormat
    $exportOption: ExportOption
    $includeArchived: Boolean
  ) {
    exportIssuesMass(
      userId: $userId
      companyId: $companyId
      dateStart: $dateStart
      dateEnd: $dateEnd
      recipients: $recipients
      exportType: $exportType
      exportFormat: $exportFormat
      exportOption: $exportOption
      includeArchived: $includeArchived
    ) {
      ok
    }
  }
`

/* TIMESHEETS */
export const EXPORT_TIMESHEETS = gql`
  mutation ExportTimesheets(
    $userMail: String!
    $user: ID!
    $company: ID!
    $dateStart: String!
    $dateEnd: String!
    $exportType: String!
    $exportFormat: ExportFormat
    $exportOption: ExportOption
    $project: ID
  ) {
    exportTimesheets(
      userMail: $userMail
      user: $user
      company: $company
      dateStart: $dateStart
      dateEnd: $dateEnd
      exportType: $exportType
      exportFormat: $exportFormat
      exportOption: $exportOption
      project: $project
    ) {
      ok
      html
    }
  }
`

export const EXPORT_TIMESHEETS_MASS = gql`
  mutation ExportTimesheetsMass(
    $userMail: String!
    $user: ID!
    $users: [ID]
    $company: ID!
    $dateStart: String!
    $dateEnd: String!
    $exportType: String!
    $exportFormat: ExportFormat!
    $orderBy: String
    $includeAbsences: Boolean
    $exportOption: ExportOption
    $project: ID
  ) {
    exportTimesheetsMass(
      userMail: $userMail
      user: $user
      users: $users
      company: $company
      dateStart: $dateStart
      dateEnd: $dateEnd
      exportType: $exportType
      exportFormat: $exportFormat
      orderBy: $orderBy
      includeAbsences: $includeAbsences
      exportOption: $exportOption
      project: $project
    ) {
      ok
      html
    }
  }
`

/* INFRINGEMENTS */
export const EXPORT_INFRINGEMENTS = gql`
  mutation ExportInfringements(
    $userMail: ID!
    $user: ID!
    $dateStart: DateTime
    $dateEnd: DateTime
    $exportOption: ExportOption
  ) {
    exportInfringements(
      userMail: $userMail
      user: $user
      dateStart: $dateStart
      dateEnd: $dateEnd
      exportOption: $exportOption
    ) {
      ok
    }
  }
`

export const EXPORT_INFRINGEMENTS_MASS = gql`
  mutation ExportInfringementsMass(
    $userMail: ID!
    $dateStart: DateTime
    $dateEnd: DateTime
    $exportOption: ExportOption
  ) {
    exportInfringementsMass(
      userMail: $userMail
      dateStart: $dateStart
      dateEnd: $dateEnd
      exportOption: $exportOption
    ) {
      ok
    }
  }
`

export const EXPORT_INFRINGEMENT = gql`
  mutation ExportInfringement($infringementId: ID!, $exportOption: ExportOption, $recipientId: ID, $recipientEmails: [String]) {
    exportInfringement(infringementId: $infringementId, exportOption: $exportOption, recipientId: $recipientId, recipientEmails: $recipientEmails) {
      ok
    }
  }
`

export const EXPORT_INFRINGEMENTS_USER_TOTALS = gql`
  mutation ExportInfringementsUserTotals(
    $userMail: ID!
    $dateStart: DateTime!
    $dateEnd: DateTime!
    $exportOption: ExportOption
  ) {
    exportInfringementsUserTotals(
      userMail: $userMail
      dateStart: $dateStart
      dateEnd: $dateEnd
      exportOption: $exportOption
    ) {
      ok
    }
  }
`
