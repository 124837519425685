import { useSalaryMutations } from '../../util'
import { useForm, useTranslate } from '@ur/react-hooks'
import {
  SalarySettingsForm,
  SalarySettingsFormView,
} from './SalarySettingsFormView'
import { SalaryType, SalaryWorkTimeNode } from 'modules/companies/types.graphql'
import { getFullMinutes } from 'util/time'
import { useSalaryModuleTranslations } from '../../hooks'
import { format, setSeconds } from 'date-fns'
import { useHistory, useParams } from 'react-router'
import { useQuery } from '@apollo/client'
import { SALARY_WORK_TIME_QUERY } from 'modules/companies/queries'
import floor from 'lodash/floor'
import { Loader } from '@ur/react-components'
import { time } from 'lib/time'
import { Fragment, useEffect, useState } from 'react'
import { useBreadcrumbs } from 'util/hooks'
import styled from 'styled-components'

const Wrapper = styled.div`
  ${props => props.theme.layout.default};
`

interface EditSalaryWorkTimeProps {}

export const EditSalaryWorkTime: React.UFC<EditSalaryWorkTimeProps> = () => {
  const { salaryWorkTimeId } = useParams<{ salaryWorkTimeId: string }>()
  const [loadComplete, setLoadComplete] = useState(false)
  const { data, loading } = useQuery<
    { salaryWorkTime: SalaryWorkTimeNode },
    { id: string }
  >(SALARY_WORK_TIME_QUERY, {
    variables: {
      id: salaryWorkTimeId ?? '',
    },
    onCompleted: data => {
      const initialData = {
        userId: data.salaryWorkTime.user?.id ?? '',
        userTypes:
          data.salaryWorkTime.userTypes?.edges.map(edge => edge.node.id) ?? [],
        defaultSalaryCode: data.salaryWorkTime.defaultSalaryCode?.id ?? null,
        salaryType: data.salaryWorkTime.salaryType ?? SalaryType.HOUR,
        salaryDayCode: data.salaryWorkTime.salaryDayCode?.id ?? null,
        dayOvertimeSalaryCode: data.salaryWorkTime.dayOvertimeSalaryCode?.id ?? null,
        weekOvertimeSalaryCode: data.salaryWorkTime.weekOvertimeSalaryCode?.id ?? null,
        priority: data.salaryWorkTime.priority ?? 1,
        dailyWorkLimit: floor(
          (data.salaryWorkTime.dailyWorkLimit ?? 28800) / 3600,
          2
        ),
        periodWorkLimit: floor(
          (data.salaryWorkTime.periodWorkLimit ?? 144000) / 3600,
          2
        ),
        pauseDuration: secondsToDate(data.salaryWorkTime.pauseDuration ?? 2700),
        pauseThreshold: secondsToDate(
          data.salaryWorkTime.pauseThreshold ?? 14400
        ),
        countPauseInTotal: data.salaryWorkTime.countPauseInTotal ?? false,
        paidPauseThreshold: secondsToDate(
          data.salaryWorkTime.paidPauseThreshold ?? 0
        ),
        salaryDateStart: data.salaryWorkTime.salaryDateStart ?? 26,
        salaryDateEnd: data.salaryWorkTime.salaryDateEnd ?? 25,
        name: data.salaryWorkTime.name ?? '',
        usePeriodOvertime: data.salaryWorkTime.usePeriodOvertime ?? false,
        useSalaryDayPayment: data.salaryWorkTime.useSalaryDayPayment ?? false,
        useWeekOvertime: data.salaryWorkTime.useWeekOvertime ?? true,
        groupSalaryOnVehicle: data.salaryWorkTime.groupSalaryOnVehicle ?? false,
        periodAddons:
          data.salaryWorkTime.periodAddons.map(periodAddonNode => {
            const { __typename, ...periodAddon } = periodAddonNode
            return {
              ...periodAddon,
              salaryCode: periodAddon.salaryCode?.id ?? '',
              periodStart: time(periodAddon.periodStart).toDate(new Date()),
              periodEnd: time(periodAddon.periodEnd).toDate(new Date()),
              salaryCodeName: periodAddon.salaryCode?.name ?? '',
            }
          }) ?? [],
        overtimePeriods: data.salaryWorkTime.overtimePeriods.map(overtimePeriod => {
          const { __typename, ...overtimePeriodInput } = overtimePeriod
          return {
            ...overtimePeriodInput,
            salaryCode: overtimePeriod.salaryCode?.id ?? '',
            periodStart: time(overtimePeriod.periodStart).toDate(new Date()),
            periodEnd: time(overtimePeriod.periodEnd).toDate(new Date()),
            salaryCodeName: overtimePeriod.salaryCode?.name ?? '',
          }
        }) ?? [],
        defaultDepartment: data.salaryWorkTime.defaultDepartment?.id ?? '',
        availableDepartments:
          data.salaryWorkTime.availableDepartments?.map(node => node.id) ?? [],
      }
      form.updateForm(initialData)
      setLoadComplete(true)
    },
    onError: () => {
      history.goBack()
    }
  })
  const salaryWorkTime = data?.salaryWorkTime
  const config = salaryWorkTime?.moduleConfig
  const history = useHistory()

  const [breadcrumbsOverridden, setBreadcrumbsOverridden] = useState(false)
  const { setOverride } = useBreadcrumbs()

  const getModuleTranslation = useSalaryModuleTranslations()

  const translations = useTranslate({
    editSalarySettings: ['settings.edit-salary-settings-type', { module: '' }],
    createSalarySettings: ['settings.create-salary-settings', { module: '' }],

    validation: {
      driverPause: 'settings.validation.salary-driver-pause-length',
      invalid: 'common.invalid',
      priority: ['settings.validation.salary-priority-unique', { module: '' }],
      required: 'common.required',
    },
  })

  const mutations = useSalaryMutations()

  const validatePauseDuration = (val: Date) => {
    if (!config) return null

    return config.name !== 'Activities'
      ? null
      : getFullMinutes(val) < 45
      ? translations.validation.driverPause
      : null
  }

  const secondsToDate = (seconds: number) =>
    setSeconds(new Date(1993, 0, 9), seconds)
  
  const form = useForm<SalarySettingsForm>({
    values: {
      userId: salaryWorkTime?.user?.id ?? '',
      userTypes:
        salaryWorkTime?.userTypes?.edges.map(edge => edge.node.id) ?? [],
      
      salaryType: salaryWorkTime?.salaryType ?? SalaryType.HOUR,
      defaultSalaryCode: salaryWorkTime?.defaultSalaryCode?.id ?? '',
      salaryDayCode: salaryWorkTime?.salaryDayCode?.id ?? '',
      dayOvertimeSalaryCode: salaryWorkTime?.dayOvertimeSalaryCode?.id ?? '',
      useWeekOvertime: salaryWorkTime?.useWeekOvertime ?? true,
      weekOvertimeSalaryCode: salaryWorkTime?.weekOvertimeSalaryCode?.id ?? '',
      dailyWorkLimit: floor(
        (salaryWorkTime?.dailyWorkLimit ?? 28800) / 3600,
        2
      ),
      periodWorkLimit: floor(
        (salaryWorkTime?.periodWorkLimit ?? 144000) / 3600,
        2
      ),
      pauseDuration: secondsToDate(salaryWorkTime?.pauseDuration ?? 2700),
      pauseThreshold: secondsToDate(salaryWorkTime?.pauseThreshold ?? 14400),
      countPauseInTotal: salaryWorkTime?.countPauseInTotal ?? false,
      paidPauseThreshold: secondsToDate(
        salaryWorkTime?.paidPauseThreshold ?? 0
      ),
      salaryDateStart: salaryWorkTime?.salaryDateStart ?? 26,
      salaryDateEnd: salaryWorkTime?.salaryDateEnd ?? 25,
      name: salaryWorkTime?.name ?? '',
      groupSalaryOnVehicle: salaryWorkTime?.groupSalaryOnVehicle ?? false,
      usePeriodOvertime: false,
      useSalaryDayPayment: false,
      periodAddons:
        salaryWorkTime?.periodAddons.map(periodAddonNode => {
          const { __typename, ...periodAddon } = periodAddonNode
          return {
            ...periodAddon,
            salaryCode: periodAddon.salaryCode?.id ?? '',
            periodStart: time(periodAddon.periodStart).toDate(new Date()),
            periodEnd: time(periodAddon.periodStart).toDate(new Date()),
            salaryCodeName: periodAddon.salaryCode?.name ?? '',
          }
        }) ?? [],
      overtimePeriods: salaryWorkTime?.overtimePeriods.map(overtimePeriod => {
        const { __typename, ...overtimePeriodInput } = overtimePeriod
        return {
          ...overtimePeriodInput,
          salaryCode: overtimePeriod.salaryCode?.id ?? '',
          periodStart: time(overtimePeriod.periodStart).toDate(new Date()),
          periodEnd: time(overtimePeriod.periodEnd).toDate(new Date()),
          salaryCodeName: overtimePeriod.salaryCode?.name ?? '',
        }
      }) ?? [],
      defaultDepartment: salaryWorkTime?.defaultDepartment?.id ?? '',
      availableDepartments:
        salaryWorkTime?.availableDepartments?.map(node => node.id) ?? [],
    },
    validators: {
      pauseDuration: validatePauseDuration,
      salaryDateStart: (val, { salaryDateEnd }) =>
        val === 1 && salaryDateEnd === 31
          ? null
          : val > salaryDateEnd
          ? null
          : translations.validation.invalid,
    },
    config: {
      validateImmediately: true,
    },
  })

  async function handleEdit() {
    mutations.patch({
      variables: {
        id: data?.salaryWorkTime.id ?? '',
        input: {
          user: form.formValues.userId,
          userTypes: form.formValues.userTypes,
          salaryType: form.formValues.salaryType,
          defaultSalaryCode: form.formValues.defaultSalaryCode,
          salaryDayCode: form.formValues.salaryDayCode,
          dayOvertimeSalaryCode: form.formValues.dayOvertimeSalaryCode,
          useWeekOvertime: form.formValues.useWeekOvertime,
          weekOvertimeSalaryCode: form.formValues.weekOvertimeSalaryCode,
          dailyWorkLimit: form.formValues.dailyWorkLimit,
          periodWorkLimit: form.formValues.periodWorkLimit,
          pauseDuration: getFullMinutes(form.formValues.pauseDuration),
          pauseThreshold: getFullMinutes(form.formValues.pauseThreshold),
          countPauseInTotal: form.formValues.countPauseInTotal,
          paidPauseThreshold: getFullMinutes(form.formValues.paidPauseThreshold),
          salaryDateStart: form.formValues.salaryDateStart,
          salaryDateEnd: form.formValues.salaryDateEnd,
          name: form.formValues.name,
          groupSalaryOnVehicle: form.formValues.groupSalaryOnVehicle,
          usePeriodOvertime: form.formValues.usePeriodOvertime,
          useSalaryDayPayment: form.formValues.useSalaryDayPayment,
          periodAddonsManual: form.formValues.periodAddons.map(
            periodAddon => {
              const { id, salaryCodeName, ...periodAddonInput } = periodAddon
              return {
                ...periodAddonInput,
                periodStart: format(periodAddon.periodStart, 'HH:mm'),
                periodEnd: format(periodAddon.periodEnd, 'HH:mm'),
              }
            }
          ),
          overtimePeriodsManual: form.formValues.overtimePeriods.map(
            overtimePeriod => {
              const { id, salaryCodeName, ...overtimePeriodInput } = overtimePeriod
              return {
                ...overtimePeriodInput,
                periodStart: format(overtimePeriod.periodStart, 'HH:mm'),
                periodEnd: format(overtimePeriod.periodEnd, 'HH:mm'),
              }
            }
          ),
          defaultDepartment: form.formValues.defaultDepartment,
          availableDepartments: form.formValues.availableDepartments,
        },
      },
    })
  }

  const title = config?.name ? (
    translations.editSalarySettings({
      module:
        getModuleTranslation(config.name)
    })
  ) : (
    <Loader.Dots />
  )

    useEffect(() => {
      if (!breadcrumbsOverridden && salaryWorkTime && salaryWorkTime.user) {
        setOverride(salaryWorkTime.user.id, salaryWorkTime.user.fullName)
        setBreadcrumbsOverridden(true)
      }
    }, [salaryWorkTime, breadcrumbsOverridden, setOverride])


  const UserWrapper = form.formValues.userId ? Wrapper : Fragment
  return (
    <UserWrapper>
        <h2>{title}</h2>
        {config && !loading && loadComplete && (
          <SalarySettingsFormView
            form={form}
            config={config}
            onSubmit={handleEdit}
          />
        )}
    </UserWrapper>
  )
}
