import { gql } from '@apollo/client'
import { ThumbnailUserFragment, UserFragments } from 'modules/users/fragments'

export const ForumFragments = {
  threadInfo: gql`
    fragment ThreadInfo on ThreadNode {
      id
      title
      content
      attachments {
        id
        name
        file
      }
      createdAt
      postCount
      sticky
      seen
      user {
        id
        initials
        fullName
        profilePicture
      }
    }
  `,
  postInfo: gql`
    fragment PostInfo on PostNode {
      id
      createdAt
      content
    }
  `,
  postAttachmentInfo: gql`
    fragment PostAttachmentInfo on PostAttachmentNode {
      id
      name
      file
    }
  `,
}

export const THREAD_WITH_POSTS_QUERY = gql`
  query ThreadWithPosts(
    $id: ID!
    $numberOfPosts: Int
    $cursor: String
    $withAdminFields: Boolean = false
  ) {
    thread(id: $id) {
      ...ThreadInfo

      numberOfViews @include(if: $withAdminFields)

      posts(before: $cursor, last: $numberOfPosts) {
        edges {
          node {
            ...PostInfo
            user {
              id
              fullName
              initials
              profilePicture
            }
            attachments {
              ...PostAttachmentInfo
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
  ${ForumFragments.threadInfo}
  ${ForumFragments.postInfo}
  ${ForumFragments.postAttachmentInfo}
`

export const POST_QUERY = gql`
  query Post($id: ID!) {
    post(id: $id) {
      ...PostInfo
      attachments {
        ...PostAttachmentInfo
      }
      user {
        ...UserInfo
      }
    }
  }
  ${ForumFragments.postInfo}
  ${ForumFragments.postAttachmentInfo}
  ${UserFragments.info}
`

export const POSTS_QUERY = gql`
  query Posts($threadTitleContains: String) {
    allPosts(last: 4, thread_Title_Icontains: $threadTitleContains) {
      edges {
        node {
          id
          createdAt
          content
          user {
            id
            fullName
          }
          thread {
            id
            title
            postCount
          }
        }
      }
    }
  }
`

export const THREAD_QUERY = gql`
  query Thread($id: ID!) {
    thread(id: $id) {
      ...ThreadInfo
    }
  }
  ${ForumFragments.threadInfo}
`

export const FORUM_THREADS_QUERY = gql`
  query ForumThreads(
    $q: String
    $orderBy: String
    $first: Int
    $after: String
    $withAdminFields: Boolean = false
  ) {
    stickyThreads: allThreads(
      first: $first
      sticky: true
      q: $q
      orderBy: $orderBy
    ) {
      edges {
        node {
          ...ThreadInfo
          seen
          numberOfViews @include(if: $withAdminFields)
        }
      }
    }
    allThreads(
      first: $first
      after: $after
      sticky: false
      q: $q
      orderBy: $orderBy
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          title
          createdAt
          postCount
          seen
          numberOfViews @include(if: $withAdminFields)
          user {
            id
            fullName
            email
          }
        }
      }
    }
  }
  ${ForumFragments.threadInfo}
`

export const THREAD_SEEN_BY_QUERY = gql`
  query ThreadSeenByQuery(
    $thread: ID!
    $first: Int!
    $after: String
    $q: String
    $orderBy: String
  ) {
    thread(id: $thread) {
      id
      seenBy(first: $first, after: $after, q: $q, orderBy: $orderBy) {
        edges {
          node {
            ...ThumbnailUser
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${ThumbnailUserFragment}
`

export const THREAD_VIEWS_QUERY = gql`
  query ThreadViews(
    $id: ID!
    $seenFirst: Int!
    $unseenFirst: Int!
    $seenAfter: String
    $unseenAfter: String
    $q: String
  ) {
    threadViews(id: $id, q: $q) {
      seenBy(first: $seenFirst, after: $seenAfter) {
        edges {
          node {
            ...ThumbnailUser
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
      notSeenBy(first: $unseenFirst, after: $unseenAfter) {
        edges {
          node {
            ...ThumbnailUser
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${ThumbnailUserFragment}
`
