import { useTranslate } from '@ur/react-hooks'
import styled from 'styled-components'
import { AbsenceStatus } from '..'
import { AbsenceStatusValue } from '../consts'

interface AbsenceStatusDisplayWrapperProps {
  pending: boolean
  approved: boolean
}

const AbsenceStatusDisplayWrapper = styled.div<AbsenceStatusDisplayWrapperProps>`
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;

  &::before {
    content: '';
    display: inline-block;

    width: 8px;
    height: 8px;
    margin-right: 0.8ch;
    margin-bottom: 1px;

    border-radius: 50%;
    background: ${props =>
      props.pending
        ? props.theme.colors.orange
        : props.approved
        ? props.theme.colors.green
        : props.theme.colors.red};
  }
`

interface AbsenceStatusDisplayProps {
  status: AbsenceStatus
}

export const AbsenceStatusDisplay: React.VFC<AbsenceStatusDisplayProps> = ({
  status,
}) => {
  const translations = useTranslate({
    approved: 'timesheets.absence-approved',
    pending: 'timesheets.absence-pending',
    rejected: 'timesheets.absence-rejected',
  })
  const pending = status === AbsenceStatusValue.PENDING
  const approved = status === AbsenceStatusValue.APPROVED
  return (
    <AbsenceStatusDisplayWrapper pending={pending} approved={approved}>
      {pending
        ? translations.pending
        : approved
        ? translations.approved
        : translations.rejected}
    </AbsenceStatusDisplayWrapper>
  )
}
