import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { overloadColor } from 'util/style'

interface PlainLinkProps {
  block?: boolean
  fullWidth?: boolean
  hoverUnderline?: boolean
  hoverColor?: string
}

export const PlainLink = styled(Link)<PlainLinkProps>`
  ${props => props.block && 'display: block;'}
  ${props => props.fullWidth && 'width: 100%;'}

  color: inherit;

  &:hover {
    text-decoration: ${props => (props.hoverUnderline ? 'underline' : 'none')};
    color: ${props => overloadColor(props.hoverColor)};
  }
`
