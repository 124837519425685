import React from 'react'
import {
  InfoBox,
  Section,
  SectionHeader,
  SettingsWrapper,
} from 'modules/companies/CompanySettings/components'
import { Button, Table } from 'components'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { useTranslate } from '@ur/react-hooks'
import { Icon, usePrompt } from '@ur/react-components'
import { useCompany, useConfirm, useOnErrorAuto } from 'util/hooks'
import {
  AllChecklistTemplatesQuery,
  ChecklistTemplateForm,
  ShallowChecklistTemplateNode,
} from 'modules/checklists'
import { useQuery } from '@apollo/client'
import { CHECKLIST_TEMPLATES_QUERY } from 'modules/checklists/queries'
import { ChecklistTemplatePrompt } from '../components/ChecklistSettings/ChecklistTemplatePrompt'
import { UserTypesCell } from '../components/ChecklistSettings/UserTypesCell'
import { useChecklistTemplateMutations } from '../util/mutations.hooks'

interface ChecklistTemplatesSettingsProps {}

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  h2 {
    margin-bottom: 0;
  }
`
const Create = styled.div`
  position: relative;
`

const TitleCell = styled.td`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const ChecklistTemplatesSettings: React.VFC<ChecklistTemplatesSettingsProps> =
  () => {
    const translations = useTranslate({
      info: 'settings.info.checklists',
      checklistTemplates: 'common.checklist-templates',
      noChecklistTemplates: 'settings.no-checklist-templates',
      userTypes: 'common.user-types',
      add: 'common.add',
      active: 'common.active',

      prompt: {
        deleteDescription:
          'settings.prompts.delete-checklist-template-description',
        deleteChecklistTemplate: 'settings.prompts.delete-checklist-template',
      },
    })
    const company = useCompany()
    const userTypes = company.userTypes
    const addPrompt = usePrompt()
    const confirm = useConfirm()
    const onErrorAuto = useOnErrorAuto()
    const mutations = useChecklistTemplateMutations()

    async function handleCreateChecklistTemplate() {
      const { data } = await addPrompt<ChecklistTemplateForm | null>(
        resolve => (
          <ChecklistTemplatePrompt userTypes={userTypes} onSubmit={resolve} />
        )
      )
      if (!data) return
      const values = Object.values(data.items)
      mutations.create({
        variables: {
          input: {
            title: data.title,
            mode: data.mode,
            items: values.slice(0, values.length - 1),
            userTypes: data.userTypes,
          },
        },
      })
    }
    async function handleEditChecklistTemplate(
      template: ShallowChecklistTemplateNode
    ) {
      const { data } = await addPrompt<ChecklistTemplateForm | null>(
        resolve => (
          <ChecklistTemplatePrompt
            checklistTemplateId={template.id}
            userTypes={userTypes}
            onSubmit={resolve}
          />
        )
      )
      if (!data) return
      const keys = Object.keys(data.items)
      mutations.patch({
        variables: {
          id: template.id,
          input: {
            title: data.title,
            mode: data.mode,
            userTypes: data.userTypes,
            items: keys.slice(0, keys.length - 1).map(key => ({
              id: key,
              text: data.items[key],
            })),
            itemsRemove: data.itemsRemove,
          },
        },
      })
      return
    }

    async function handleDeleteTemplate(id: string) {
      const { data: answer } = await confirm(
        translations.prompt.deleteDescription,
        translations.prompt.deleteChecklistTemplate
      )
      if (!answer) return

      mutations.delete({
        variables: { id },
      })
    }

    const { data, loading } = useQuery<AllChecklistTemplatesQuery>(
      CHECKLIST_TEMPLATES_QUERY,
      {
        onError: onErrorAuto(),
      }
    )

    const checklistTemplates =
      data?.allChecklistTemplates.edges.map(edge => edge.node) ?? []

    return (
      <SettingsWrapper grid={{ flow: 'row' }}>
        <InfoBox initCollapsed={isMobileOnly}>{translations.info}</InfoBox>

        <Section>
          <Header>
            <SectionHeader loading={loading}>
              {translations.checklistTemplates}
            </SectionHeader>

            <Create>
              {isMobileOnly ? (
                <Button
                  height="32px"
                  padding="0 1rem"
                  onClick={handleCreateChecklistTemplate}
                >
                  <Icon icon="plus" size="0.8rem" />
                </Button>
              ) : (
                <Button
                  height="48px"
                  iconLeftProps={{
                    icon: 'plus',
                  }}
                  onClick={handleCreateChecklistTemplate}
                >
                  {translations.add}
                </Button>
              )}
            </Create>
          </Header>

          <Table
            noData={checklistTemplates.length === 0}
            noDataText={translations.noChecklistTemplates}
            noShadow
            noBorders
            noHeaderMargin
            noRowMargin
            rowHoverColor="secondary"
          >
            <thead>
              <tr>
                <th>{translations.checklistTemplates}</th>
                <th>{translations.userTypes}</th>
                <th></th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {checklistTemplates.map(checklistTemplate => (
                <tr key={checklistTemplate.id}>
                  <TitleCell>{checklistTemplate.title}</TitleCell>
                  <UserTypesCell userTypes={checklistTemplate.userTypes} />
                  <td width="1px">
                    <Icon
                      icon="edit"
                      cursor="pointer"
                      color="gray6"
                      hoverColor="secondary"
                      onClick={() =>
                        handleEditChecklistTemplate(checklistTemplate)
                      }
                    />
                  </td>

                  <td width="1px">
                    <Icon
                      icon="times"
                      cursor="pointer"
                      color="gray6"
                      hoverColor="red"
                      onClick={() => handleDeleteTemplate(checklistTemplate.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Section>
      </SettingsWrapper>
    )
  }
