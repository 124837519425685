import { Icon, usePrompt } from '@ur/react-components'
import { Button, Cell, CellProps } from 'components'
import { ActivitiesDynamicColumnDayReport } from 'modules/activities/types.graphql'
import { MouseEventHandler } from 'react'
import { useSalaryDayMutations } from 'modules/activities/mutations.hooks'
import { useParams } from 'react-router'
import { parseISO } from 'date-fns'
import { format } from 'util/date-fns'
import { usePermissions } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { SalaryDayForm, SalaryDayPrompt } from './SalaryDayPrompt'

interface SalaryDayProps {
  report: Pick<ActivitiesDynamicColumnDayReport, 'salaryDay' | 'date'>,
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLDivElement>
  canEdit?: boolean | null
}

const PartOfDayMapping = {
  1: '1',
  0.75: '3/4',
  0.666: '2/3',
  0.5: '1/2',
  0.333: '1/3',
  0.25: '1/4',
} as {[key: number]: string}
export const SalaryDay: React.FC<SalaryDayProps> = ({
  report,
  canEdit,
  ...props
}) => {
  if (report.salaryDay){
    if (Object.keys(PartOfDayMapping).includes(report.salaryDay.partOfDay.toString())) return <div onClick={(evt) => props.onClick?.(evt)}>{PartOfDayMapping[report.salaryDay.partOfDay]}</div>
    else if (report.salaryDay.partOfDay > 0) return <div onClick={(evt) => props.onClick?.(evt)}>{report.salaryDay.partOfDay}</div>
  }
  if (canEdit) return (
    <Button 
      padding="0.25rem 0.75rem !important"
      height="auto"
      onClick={(evt) => {
        evt.stopPropagation()
        props.onClick?.(evt)
      }}
    >
      <Icon 
        icon="plus" 
        margin="0" 
      />
    </Button>
  )

  return <div onClick={(evt) => props.onClick?.(evt)}></div>

}
interface SalaryDayCellProps extends SalaryDayProps, CellProps {
  subColumn: boolean
  onClick?: () => void
}
export const SalaryDayCell: React.VFC<SalaryDayCellProps> = ({
  narrow = true,
  centered = true,
  subColumn = false,
  report,
  ...props
}) => {
  const addPrompt = usePrompt()
  const { userId } = useParams<{ userId: string }>()
  const { hasPermissions, hasPermissionsAndMe } = usePermissions()
  const mutations = useSalaryDayMutations()

  if (subColumn) return <td></td>

  const canEdit = hasPermissions(PERMISSIONS.activities.change.driveractivity) || ((hasPermissionsAndMe({id: userId}, PERMISSIONS.activities.approve.driveractivity)))

  async function handleAddSalaryDay() {
    const { data } = await addPrompt<SalaryDayForm | null>(resolve => (
      <SalaryDayPrompt
        initialData={{
          userId: userId,
          partOfDay: report.salaryDay?.partOfDay ?? 1,
        }}
        onSubmit={resolve}
      />
    ))
    if (data === null) return

    mutations.updateOrCreateSalaryDay({
      variables: {
        input: {
          ...data,
          date: format(parseISO(report.date), 'yyyy-MM-dd'),
        }
      },
    })
  }

  const handleClick: MouseEventHandler<HTMLTableCellElement> = (report.salaryDay && canEdit)
    ? (event) => {
      event.stopPropagation()
      handleAddSalaryDay()
    }
    : (_event) => void 0

  return (
    <Cell onClick={handleClick} narrow={narrow} centered={centered}>
      <SalaryDay report={report} onClick={handleAddSalaryDay} {...props} canEdit={canEdit} />
    </Cell>
    )
  }
