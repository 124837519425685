import { SVGIconProps } from 'components/icons'
import { LinkProps } from 'react-router-dom'
import { Shortcut, ShortcutSVG } from './components'

export interface IconShortcutProps {
  linkProps: Pick<LinkProps, 'to' | 'className'>
  iconProps: Pick<SVGIconProps, 'icon' | 'width'>
  text: string
}

export const IconShortcut: React.VFC<IconShortcutProps> = ({
  linkProps,
  iconProps,
  text,
}) => {
  return (
    <Shortcut {...linkProps}>
      <ShortcutSVG {...iconProps} />
      <p>{text}</p>
    </Shortcut>
  )
}
