import { useTranslate } from '@ur/react-hooks'
import { countries, CountryCode } from 'util/countries'

type CountryCodeTranslations = Record<CountryCode, string>

export const useCountryCodeTranslations = () => {
  const entries = Object.keys(countries).reduce((acc, cur) => {
    return {
      ...acc,
      [cur]: `countries.codes.${cur}`,
    }
  }, {})
  const baseTranslations = useTranslate(entries) as CountryCodeTranslations
  function getCountryCodeTranslations(
    code: CountryCode,
    translations: CountryCodeTranslations = baseTranslations
  ) {
    return translations[code]
  }
  return { getCountryCodeTranslations }
}
