import { useContext } from 'react'
import UserContext from 'context/UserContext'
import { useQuery } from '@apollo/client'
import { UserSalaryWorkTimeQuery } from 'modules/users'
import { USER_SALARY_WORK_TIME_QUERY } from 'modules/users/queries'

export function useUser(userId?: string, moduleConfigName?: string) {
  const user = useContext(UserContext)
  const otherUser = useUserQuery(userId, moduleConfigName)
  // If other user is found, return that user
  if (otherUser) {
    return otherUser
  }
  
  // Else return "me"
  return user
}


function useUserQuery(userId?: string, moduleConfigName?: string) {
  const { data } = useQuery<UserSalaryWorkTimeQuery, { id: string, moduleConfigName: string }>(USER_SALARY_WORK_TIME_QUERY, {
    variables: { id: userId ?? '', moduleConfigName: moduleConfigName ?? ''},
  })

  return data?.user
}