import { useMutation } from '@apollo/client'
import { useToast } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { useOnErrorAuto } from 'util/hooks'
import {
  ExportActivitiesMass,
  ExportActivitiesMassVariables,
  ExportActivities,
  ExportActivitiesVariables,
  FetchActivities,
  FetchActivitiesVariables,
  ToggleApprovedDriverActivities,
  ToggleApprovedDriverActivitiesVariables,
  UpdateOrCreateDriverDietVariables,
  UpdateOrCreateDriverDiet,
  UpdateOrCreateSalaryDay,
  UpdateOrCreateSalaryDayVariables,
} from '.'
import {
  EXPORT_ACTIVITIES,
  EXPORT_ACTIVITIES_MASS,
} from 'modules/exports/mutations'
import {
  FETCH_DRIVER_ACTIVITIES,
  TOGGLE_APPROVED_DRIVER_ACTIVITY_MUTATION,
  UPDATE_OR_CREATE_DRIVER_DIET_MUTATION,
  UPDATE_OR_CREATE_SALARY_DAY_MUTATION,
} from './mutations'
import { useHistory } from 'react-router'

export const useActivityExportMutations = (onCompleted?: () => void) => {
  const translations = useTranslate({
    hours: 'common.hours',
    diet: 'common.diet',
    summary: 'activities.work-summary',
    error: 'error.generic-server-error',
    downloadSuccess: 'exports.success-user-exports',
    mailSuccess: 'exports.success-email-process',
  })
  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()
  const history = useHistory()

  const [exportActivitiesMass, { loading: mailMassActivityLoading }] =
    useMutation<ExportActivitiesMass, ExportActivitiesMassVariables>(
      EXPORT_ACTIVITIES_MASS,
      {
        onCompleted(data) {
          if (data?.exportActivitiesMass.html) {
            history.push('/export', { html: data?.exportActivitiesMass.html })
          } else if (data?.exportActivitiesMass.ok) {
            addToast('success', translations.downloadSuccess)
          } else addToast('error', translations.error)

          onCompleted?.()
        },
        onError: onErrorAuto(translations.error),
      }
    )

  const [exportActivities, { loading: mailActivityLoading }] = useMutation<
    ExportActivities,
    ExportActivitiesVariables
  >(EXPORT_ACTIVITIES, {
    onCompleted: data => {
      if (data?.exportActivities.html) {
        history.push('/export', { html: data?.exportActivities.html })
      } else if (data?.exportActivities.ok) {
        addToast('success', translations.downloadSuccess)
      } else {
        addToast('error', translations.error)
      }
      onCompleted?.()
    },
  })

  return {
    exportActivitiesMass,
    exportActivities,
    loading: mailMassActivityLoading || mailActivityLoading,
  }
}

export function useActivityMutations() {
  const translations = useTranslate({
    hours: 'common.hours',
    diet: 'common.diet',
    summary: 'activities.work-summary',
    toast: {
      error: 'error.generic-server-error',
      fetch: {
        success: 'activities.starting-activity-fetching-process',
      },
      approve: {
        success: 'activities.success.approve-activities',
      },
    },
  })
  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()

  const [fetchMutation, { loading: fetchLoading }] = useMutation<
    FetchActivities,
    FetchActivitiesVariables
  >(FETCH_DRIVER_ACTIVITIES, {
    onCompleted(data: FetchActivities) {
      if (data?.fetchActivities.ok)
        addToast('success', translations.toast.fetch.success)
      else addToast('error', translations.toast.error)
    },
    onError: onErrorAuto(translations.toast.error),
  })

  const [toggleActivityMutation, { loading: toggleLoading }] = useMutation<
    ToggleApprovedDriverActivities,
    ToggleApprovedDriverActivitiesVariables
  >(TOGGLE_APPROVED_DRIVER_ACTIVITY_MUTATION, {
    refetchQueries: ['DriverActivityPeriodReport', 'AllDynamicColumnsQuery', 'DriverActivityDayReport'],
    onCompleted(data: ToggleApprovedDriverActivities) {
      if (data?.toggleApprovedDriverActivity.ok)
        addToast('success', translations.toast.approve.success)
      else addToast('error', translations.toast.error)
    },
    onError: onErrorAuto(translations.toast.error),
  })

  const loading = toggleLoading || fetchLoading
  return {
    loading,

    toggleApproved: toggleActivityMutation,
    fetch: fetchMutation,
  }
}

export const useDietDayMutations = () => {
  const translations = useTranslate({
    toast: {
      error: 'error.generic-server-error',
      success: 'activities.updated-diet-successfully'
    },
  })
  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()

  const [updateOrCreateDriverDiet] = useMutation<
    UpdateOrCreateDriverDiet,
    UpdateOrCreateDriverDietVariables
  >(UPDATE_OR_CREATE_DRIVER_DIET_MUTATION, {
    onCompleted(data) {
      if (data?.updateOrCreateDriverDiet.ok)
        addToast('success', translations.toast.success)
      else addToast('error', translations.toast.error)
    },
    onError: onErrorAuto(translations.toast.error),
    refetchQueries: ['DriverActivityPeriodReport', 'AllDynamicColumnsQuery', 'DriverActivityDayReport'],
  })
  return {
    updateOrCreateDriverDiet
  }
}


export const useSalaryDayMutations = () => {
  const translations = useTranslate({
    summary: 'activities.work-summary',
    toast: {
      error: 'error.generic-server-error',
      success: 'activities.updated-salary-day-successfully'
    },
  })
  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()

  const [updateOrCreateSalaryDay] = useMutation<
    UpdateOrCreateSalaryDay,
    UpdateOrCreateSalaryDayVariables
  >(UPDATE_OR_CREATE_SALARY_DAY_MUTATION, {
    onCompleted(data) {
      if (data?.updateOrCreateSalaryDay.ok)
        addToast('success', translations.toast.success)
      else addToast('error', translations.toast.error)
    },
    onError: onErrorAuto(translations.toast.error),
    refetchQueries: ['DriverActivityPeriodReport', 'AllDynamicColumnsQuery', 'DriverActivityDayReport'],
  })
  return {
    updateOrCreateSalaryDay
  }
}