import { Button } from './Button'
import styled from 'styled-components'

export const ExportButton = styled(Button)`
  padding: 0 2rem;
  background: white;
  border: 1px solid ${props => props.theme.colors.gray7};
  font-weight: 500;

  &:disabled {
    color: ${props => props.theme.colors.gray7};
  }
`
