import {
  PermissionsRequiredRoute,
  PrivateRoute,
} from 'containers/permission-containers'
import { ActivitiesDetail, ActivitiesOverview } from 'modules/activities'
import { CompanySettings, EditSalaryWorkTime } from 'modules/companies'
import { CreateSalaryWorkTime } from 'modules/companies/CompanySettings/components/SalarySettings/CreateSalaryWorkTime'
import { useModuleConfigs } from 'modules/companies/CompanySettings/hooks'
import { Dashboard } from 'modules/dashboard'
import { UserExports } from 'modules/exports/view/UserExports'
import {
  CreateForm,
  EditForm,
  FormCreator,
  ResponseOverview,
  SubmitForm,
} from 'modules/formcreator'
import { ResponseSums } from 'modules/formcreator/views/ResponseSums'
import { CreateThread, Forum, Thread } from 'modules/forum'
import { Handbook, HandbookCreateEntry, HandbookEntry } from 'modules/handbook'
import { Infringements } from 'modules/infringements'
import { Infringement } from 'modules/infringements/views/Infringement'
import { UserInfringements } from 'modules/infringements/views/UserInfringements'
import { CreateIssue, Issue, Issues, IssuesTvDisplay } from 'modules/issues'
import { RoutePlanner } from 'modules/routePlanner'
import { CreateRoutePlan } from 'modules/routePlanner/view'
import { RoutePlan } from 'modules/routePlanner/view/RoutePlan'
import { Terminal } from 'modules/terminal'
import {
  Absences,
  AbsencesTvDisplay,
  CreateTimeEntry,
  TimeEntries,
} from 'modules/timesheets'
import Users, { CreateUser, UserSettings } from 'modules/users'
import { UserProfile } from 'modules/users/UserProfile'
import { FleetOverview, Vehicle } from 'modules/vehicles/fleet'
import React from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { useUser } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'

export const AuthRoutes: React.FC = () => {
  const user = useUser()
  const {
    activitiesActive,
    terminalActive,
    timesheetsActive,
    absencesActive,
    vehiclesActive,
    issuesActive,
    handbookActive,
    infringementsActive,
    routePlannerActive,
  } = useModuleConfigs()

  return (
    <Switch>
      <PrivateRoute exact path="/dashboard" component={Dashboard} />
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.forum.view.thread}
        exact
        path="/forum"
        component={Forum}
      />
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.forum.add.thread}
        exact
        path="/forum/create"
        component={CreateThread}
      />
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.forum.view.thread}
        exact
        path="/forum/:threadId"
        component={Thread}
      />

      {/* Users */}
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.users.add.user}
        exact
        path="/users/create"
        component={CreateUser}
      />

      <PermissionsRequiredRoute
        permissions={PERMISSIONS.users.view.user}
        path="/users/:userId"
        exact
        component={UserProfile}
      />
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.users.view.user}
        exact
        path="/users"
        component={Users}
      />

      <PermissionsRequiredRoute
        permissions={PERMISSIONS.users.add.user}
        exact
        path="/users/:userId/salary/create"
        component={CreateSalaryWorkTime}
      />
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.users.add.user}
        exact
        path="/users/:userId/salary/:salaryWorkTimeId"
        component={EditSalaryWorkTime}
      />

      <Redirect from="/users/:userId/salary/" to={'/users/:userId/'} />

      {/* Activities */}
      <PrivateRoute
        exact
        path="/activities/:userId/:date"
        component={ActivitiesDetail}
        hasModuleAccess={activitiesActive}
      />
      <PrivateRoute
        exact
        path="/activities/:userId"
        component={ActivitiesOverview}
        hasModuleAccess={activitiesActive}
      />
      <Redirect from="/activities" to={'/activities/' + user.id} />

      {/* Handbook */}
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.handbook.add.manualentry}
        exact
        path="/handbook/create/:folderId"
        component={HandbookCreateEntry}
        hasModuleAccess={handbookActive}
      />
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.handbook.view.manualentry}
        exact
        path="/handbook/:folderSlug/:entrySlug"
        component={HandbookEntry}
        hasModuleAccess={handbookActive}
      />
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.handbook.view.manualfolder}
        path="/handbook/:folderSlug"
        component={Handbook}
        hasModuleAccess={handbookActive}
      />
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.handbook.view.manualfolder}
        path="/handbook"
        component={Handbook}
        hasModuleAccess={handbookActive}
      />
      {/* FormCreator */}
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.formcreator.view.formtemplate}
        exact
        path="/formcreator"
        component={FormCreator}
        hasModuleAccess={handbookActive}
      />
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.formcreator.add.formtemplate}
        exact
        path="/formcreator/create"
        component={CreateForm}
        hasModuleAccess={handbookActive}
      />
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.formcreator.change.formtemplate}
        exact
        path="/formcreator/edit/:formId"
        component={EditForm}
        hasModuleAccess={handbookActive}
      />
      <Redirect from="/formcreator/edit" to="/formcreator" />
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.formcreator.view.formtemplate}
        exact
        path="/formcreator/:formId"
        component={SubmitForm}
        hasModuleAccess={handbookActive}
      />
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.formcreator.view.filledoutform}
        exact
        path="/formcreator/:formId/responses"
        component={ResponseOverview}
        hasModuleAccess={handbookActive}
      />

      <PermissionsRequiredRoute
        permissions={PERMISSIONS.formcreator.change.filledoutform}
        exact
        path="/formcreator/:formId/sums"
        component={ResponseSums}
        hasModuleAccess={handbookActive}
      />

      {/* Issues */}
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.issues.view.issue}
        exact
        path="/issues"
        component={Issues}
        hasModuleAccess={issuesActive}
      />
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.issues.add.issue}
        exact
        path="/issues/create"
        component={CreateIssue}
        hasModuleAccess={issuesActive}
      />
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.issues.view.issue}
        exact
        path="/issues/tv-display"
        component={IssuesTvDisplay}
        hasModuleAccess={issuesActive}
      />
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.issues.view.issue}
        exact
        path="/issues/:issueId"
        component={Issue}
        hasModuleAccess={issuesActive}
      />

      {/* Timesheet */}
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.timesheets.view.timeentry}
        exact
        path="/time-entries"
        component={TimeEntries}
        hasModuleAccess={timesheetsActive}
      />
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.timesheets.add.timeentry}
        exact
        path="/time-entries/register"
        component={CreateTimeEntry}
        hasModuleAccess={timesheetsActive}
      />
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.timesheets.view.absence}
        exact
        path="/absences"
        component={Absences}
        hasModuleAccess={absencesActive}
      />
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.timesheets.view.absence}
        exact
        path="/absences/tv-display"
        component={AbsencesTvDisplay}
        hasModuleAccess={absencesActive}
      />

      {/* Profile Settings */}
      <PrivateRoute path="/usersettings" component={UserSettings} />

      {/* Company Settings */}
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.companies.view.companysetting}
        path="/settings"
        component={CompanySettings}
      />

      {/* User Export */}
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.companies.view.companysetting}
        path="/user-exports"
        component={UserExports}
      />

      {/* Terminal */}
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.terminal.view.terminal}
        exact
        path="/terminal"
        component={Terminal}
        hasModuleAccess={terminalActive}
      />

      {/* Vehicle Fleet */}
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.vehicles.view.vehicle}
        exact
        path="/vehicle-fleet"
        component={FleetOverview}
        hasModuleAccess={vehiclesActive}
      />
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.vehicles.view.vehicle}
        exact
        path="/vehicle-fleet/:vehicleId"
        component={Vehicle}
        hasModuleAccess={vehiclesActive}
      />

      {/* Infringements */}
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.infringements.view.infringement}
        exact
        path="/infringements"
        component={Infringements}
        hasModuleAccess={infringementsActive}
      />

      <PermissionsRequiredRoute
        permissions={PERMISSIONS.infringements.view.infringement}
        exact
        path="/infringements/:infringementId"
        component={Infringement}
        hasModuleAccess={infringementsActive}
      />

      <PermissionsRequiredRoute
        permissions={PERMISSIONS.infringements.view.infringement}
        exact
        path="/infringements/user/:userId"
        component={UserInfringements}
        hasModuleAccess={infringementsActive}
      />
      {/* Route planner */}
      <PermissionsRequiredRoute
        permissions={PERMISSIONS.routePlanner.view.routePlan}
        exact
        path="/route-planner/"
        component={RoutePlanner}
        hasModuleAccess={routePlannerActive}
      />

      <PermissionsRequiredRoute
        permissions={PERMISSIONS.routePlanner.add.routePlan}
        exact
        path="/route-planner/create"
        component={CreateRoutePlan}
        hasModuleAccess={routePlannerActive}
      />

      <PermissionsRequiredRoute
        permissions={PERMISSIONS.routePlanner.view.routePlan}
        exact
        path="/route-planner/:routePlanId"
        component={RoutePlan}
        hasModuleAccess={routePlannerActive}
      />

      {/*
      <PermissionsRequiredRoute
        permissions="issues.view_issue"
        exact
        path="/issues/:issueId"
        component={Issue}
      />
      */}

      <Redirect from="/absences/:absenceId" to="/absences" />
      <Redirect from="/time-entries/:timeEntryId" to="/time-entries" />

      <Redirect from="/" to="/dashboard" />
    </Switch>
  )
}
