import { FontAwesomeIcon } from '@ur/react-components/build/types/css'
import { Attachment } from 'util/hooks'
import { getLoginToken } from 'modules/login/util'
import theme from 'theme'

type IconMapTuple = [FontAwesomeIcon, ...RegExp[]]

const mimeFileIconMap: IconMapTuple[] = [
  ['file-audio', /audio\/.+/],
  ['file-video', /video\/.+/],
  [
    'file-word',
    /application\/vnd\.openxmlformats-officedocument\.wordprocessingml\.(document|template)/,
  ],
  [
    'file-powerpoint',
    /application\/vnd\.openxmlformats-officedocument\.presentationml\.(presentation|slide|slideshow|template)/,
    /application\/vnd\.ms-powerpoint(\.(slide|addin|presentation|slideshow|template)\.macroenabled\.12)?/,
  ],
  [
    'file-excel',
    /application\/vnd\.openxmlformats-officedocument\.spreadsheetml\.(sheet|template)/,
  ],
  ['file-archive', /application\/(zip|x-(rar|7z|ace)-compressed|x-tar)/],
  ['file-pdf', /application\/pdf/],
  ['file-csv', /text\/csv/],
  ['file-alt', /./],
]

const fileNameIconMap: IconMapTuple[] = [
  ['file-audio', /(mp3|wav|aiff|ogg)$/],
  ['file-video', /(mp4|wmv|avi|flv)$/],
  ['file-image', /(png|bmp|svg|jpg|jpeg|gif|tiff)$/],
  ['file-word', /(docx|doc|odt)$/],
  ['file-powerpoint', /(ppt|pptx|odp)$/],
  ['file-excel', /(xls|xlsx|ods)$/],
  ['file-archive', /(zip|tar\.bz|tar\.gz|tar|rar|7z)$/],
  ['file-pdf', /(pdf)$/],
  ['file-csv', /(csv)$/],
  ['file-alt', /./],
]

export function getFileIcon(file: string | File) {
  if (typeof file === 'string') {
    for (let iconMap of fileNameIconMap) {
      const icon = iconMap[0]
      const regexes = iconMap.slice(1) as RegExp[]
      if (regexes.some(regex => regex.test(file))) return icon
    }
  } else return getFileIconByMime(file.type)

  return 'file-alt'
}

export function getFileIconByMime(mime: string) {
  for (let iconMap of mimeFileIconMap) {
    const icon = iconMap[0]
    const regexes = iconMap.slice(1) as RegExp[]
    if (regexes.some(regex => regex.test(mime))) return icon
  }
  return 'file-alt'
}

/**
 * Get color of file icon based on file type
 * @param file Filename/url of File object
 * @param isResolved Whether icon is already resolve, that is, argument "file" is a FontAwesome icon
 * @param fallback Color to use if color for file type not found
 */
export function getFileIconColor(
  file: string | File,
  fallback = '#323237',
  isResolved = false
) {
  const icon = isResolved ? file : getFileIcon(file)

  const { iconSchemes: colors } = theme.colors

  switch (icon) {
    case 'file-pdf':
      return colors.pdf
    case 'file-word':
      return colors.word
    case 'file-powerpoint':
      return colors.powerPoint
    case 'file-excel':
      return colors.excel
    default:
      return fallback
  }
}

/**
 * Get file icon and color
 * @param file Filename/url of File object
 * @param fallbackColor Color to use if color for file type not found
 */
export function getFileIconAndColor(
  file: string | File,
  fallbackColor?: string
): [FontAwesomeIcon, string] {
  const icon = getFileIcon(file)
  const color = getFileIconColor(icon, fallbackColor, true)

  return [icon, color]
}

/**
 * ? Get name and extension of a filename as an array.
 * Returns [name, ''] if regex doesn't match.
 * @param name
 * @returns Array<string>
 */
export function splitFilename(name: string) {
  const fname = name.match(/^(.+)\.(.+)$/)
  return fname ? [fname[1], '.' + fname[2]] : [name, '']
}

export function attachmentIsViewableInFileViewer(
  attachment: string | File
): boolean
export function attachmentIsViewableInFileViewer<
  T extends string | File = string | File
>(attachment: Attachment<T>): RegExpMatchArray | null
export function attachmentIsViewableInFileViewer<
  T extends string | File = string | File
>(attachment: string | File | Attachment<T>) {
  const pattern = /(jpg|jpeg|png|svg|bmp|gif|tiff)$/
  if (typeof attachment === 'string') return pattern.test(attachment)
  if (attachment instanceof File) return pattern.test(attachment.name)

  return attachment.name.match(pattern)
}

export async function downloadFile(url: string, fileName?: string) {
  const loginToken = getLoginToken()

  const response = url.includes('Signature')
    ? await fetch(url)
    : await fetch(url, {
        headers: {
          Authorization: `Bearer ${loginToken}`,
        },
      })

  if (!response.ok) return false

  const blob = await response.blob()

  const fileDownloadUrl = URL.createObjectURL(blob)
  const finalFileName = fileName ?? getDownloadedFileFilename(response)

  const downloadElement = document.createElement('a')
  downloadElement.href = fileDownloadUrl
  downloadElement.download = finalFileName
  document.body.appendChild(downloadElement)
  downloadElement.click()

  requestAnimationFrame(() => {
    URL.revokeObjectURL(fileDownloadUrl)
    document.body.removeChild(downloadElement)
  })
  return response.ok
}

const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/

function getDownloadedFileFilename(response: Response): string {
  const disposition = response.headers.get('Content-Disposition')
  if (!disposition) {
    return ''
  }

  const matches = filenameRegex.exec(disposition)

  if (matches != null && matches[1]) {
    return matches[1].replace(/['"]/g, '')
  }

  return ''
}

function isFileEvent(
  evt: Event
): evt is Event & { target: { files: FileList } } {
  return !!evt.target && 'files' in evt.target
}

interface ShowFileDialogOptions {
  multiple?: boolean
  accept?: string
}
export function showFileDialog(
  callback: (files: File[]) => void,
  options?: ShowFileDialogOptions
) {
  const el = window.document.createElement('input')
  el.hidden = true
  el.type = 'file'

  typeof options?.multiple !== 'undefined' && (el.multiple = options.multiple)
  typeof options?.accept !== 'undefined' && (el.accept = options.accept)

  el.addEventListener('change', evt => {
    if (isFileEvent(evt)) {
      const files = Array.from(evt.target.files)
      callback(files)
    }
  })
  el.click()
}
