import { gql } from '@apollo/client'
import { UserFragments } from './fragments'

export const UPDATE_USER_MUTATION = gql`
  mutation updateUserMutation($id: ID!, $input: UpdateUserInput!) {
    updateUserMutation(id: $id, input: $input) {
      user {
        id
      }
    }
  }
`
export const PATCH_USER_MUTATION = gql`
  mutation patchUserMutation($id: ID!, $input: PatchUserInput!) {
    patchUserMutation(id: $id, input: $input) {
      user {
        id
        fullName
        phoneNumber
      }
    }
  }
`

export const TOGGLE_USER_ACTIVATED_MUTATION = gql`
  mutation ToggleUserActivated($userId: ID!, $active: Boolean!) {
    toggleUserActivated(user: $userId, active: $active) {
      user {
        id
        fullName
        isActive
      }
    }
  }
`

export const SEND_NEW_USER_REGISTRATION_MUTATION = gql`
  mutation SendNewUserRegistration($userId: ID!) {
    sendNewUserRegistration(userId: $userId) {
      ok
      user {
        id
        fullName
        isActive
      }
    }
  }
`

export const REGISTER_USER_MUTATION = gql`
  mutation RegisterUserMutation(
    $user: ID
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $birthNumber: String!
    $internalNumber: String!
    $address: String!
    $city: String!
    $postalCode: String!
    $contactPersonName: String!
    $contactPersonPhoneNumber: String!
  ) {
    registerUser(
      user: $user
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      birthNumber: $birthNumber
      internalNumber: $internalNumber
      address: $address
      city: $city
      postalCode: $postalCode
      contactPersonName: $contactPersonName
      contactPersonPhoneNumber: $contactPersonPhoneNumber
    ) {
      ok
      user {
        ...UserInfo
        ...UserTypes
      }
    }
  }
  ${UserFragments.info}
  ${UserFragments.types}
`

export const CREATE_USER_MUTATION = gql`
  mutation CreateUserMutation(
    $email: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $userType: ID!
    $company: String
  ) {
    createUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      userType: $userType
      company: $company
    ) {
      ok
      user {
        id
        fullName
        phoneNumber
      }
    }
  }
`

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangedPasswordMutation($password: String!, $newPassword: String!) {
    changePassword(password: $password, newPassword: $newPassword) {
      ok
    }
  }
`

export const CREATE_DRIVER_CARD_MUTATION = gql`
  mutation createDriverCard($input: CreateDriverCardInput!) {
    createDriverCard(input: $input) {
      driverCard {
        id
        expiresAt
      }
    }
  }
`
export const PATCH_DRIVER_CARD_MUTATION = gql`
  mutation patchDriverCard($id: ID!, $input: PatchDriverCardInput!) {
    patchDriverCard(id: $id, input: $input) {
      driverCard {
        id
        expiresAt
      }
    }
  }
`

export const DELETE_DRIVER_CARD_MUTATION = gql`
  mutation DeleteDriverCard($id: ID!) {
    deleteDriverCard(id: $id) {
      deletedId
    }
  }
`

export const CREATE_USER_DOCUMENT_MUTATION = gql`
  mutation CreateUserDocument($input: CreateUserDocumentInput!) {
    createUserDocument(input: $input) {
      userDocument {
        id
        name
      }
    }
  }
`

export const PATCH_USER_DOCUMENT_MUTATION = gql`
  mutation PatchUserDocument($id: ID!, $input: PatchUserDocumentInput!) {
    patchUserDocument(id: $id, input: $input) {
      userDocument {
        id
        name
      }
    }
  }
`

export const DELETE_USER_DOCUMENT_MUTATION = gql`
  mutation DeleteUserDocument($id: ID!) {
    deleteUserDocument(id: $id) {
      found
      deletedId
    }
  }
`

// UserDocumentFile

export const CREATE_USER_DOCUMENT_FILE_MUTATION = gql`
  mutation CreateUserDocumentFile($input: CreateUserDocumentFileInput!) {
    createUserDocumentFile(input: $input) {
      userDocumentFile {
        id
      }
    }
  }
`

export const BATCH_CREATE_USER_DOCUMENT_FILE_MUTATION = gql`
  mutation BatchCreateUserDocumentFile(
    $input: [BatchCreateUserDocumentFileInput]!
  ) {
    batchCreateUserDocumentFile(input: $input) {
      userDocumentFiles {
        id
      }
    }
  }
`

export const PATCH_USER_DOCUMENT_FILE_MUTATION = gql`
  mutation PatchUserDocumentFile(
    $id: ID!
    $input: PatchUserDocumentFileInput!
  ) {
    patchUserDocumentFile(id: $id, input: $input) {
      userDocumentFile {
        id
      }
    }
  }
`

export const DELETE_USER_DOCUMENT_FILE_MUTATION = gql`
  mutation DeleteUserDocumentFile($id: ID!) {
    deleteUserDocumentFile(id: $id) {
      deletedId
    }
  }
`


export const CREATE_USER_DOCUMENT_CATEGORY_MUTATION = gql`
  mutation CreateUserDocumentCategory($input: CreateUserDocumentCategoryInput!) {
    createUserDocumentCategory(input: $input) {
      userDocumentCategory {
        id
        name
      }
    }
  }
`

export const PATCH_USER_DOCUMENT_CATEGORY_MUTATION = gql`
  mutation PatchUserDocumentCategory($id: ID!, $input: PatchUserDocumentCategoryInput!) {
    patchUserDocumentCategory(id: $id, input: $input) {
      userDocumentCategory {
        id
        name
      }
    }
  }
`

export const DELETE_USER_DOCUMENT_CATEGORY_MUTATION = gql`
  mutation DeleteUserDocumentCategory($id: ID!) {
    deleteUserDocumentCategory(id: $id) {
      deletedId
    }
  }
`