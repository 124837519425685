import { useEffect, useRef, useState } from 'react'
import { PredictionQueryResponse, UseGoogleAutocompleteOptions } from './types'

async function googleAutocomplete(
  text: string,
  options: google.maps.places.AutocompletionRequest
): Promise<[google.maps.places.AutocompletePrediction[], string]> {
  return new Promise((resolve, reject) => {
    if (!text) {
      return resolve([[], 'Need a valid user text input'])
    }

    if (typeof google === 'undefined') {
      return resolve([
        [],
        'Google places library not detected, have you imported the script?',
      ])
    }

    try {
      new window.google.maps.places.AutocompleteService().getPlacePredictions(
        { ...options, input: text },
        predictions => resolve([predictions ?? [], ''])
      )
    } catch (error) {
      reject(error)
    }
  })
}

export function useGoogleAutocomplete(
  userInput: string,
  options?: UseGoogleAutocompleteOptions
): PredictionQueryResponse {
  const prevUserInput = useRef(userInput)
  const { debounceTimeout, ...googleOptions } = {
    debounceTimeout: 100,
    ...(options || {}),
  }

  const [predictions, setPredictions] = useState<PredictionQueryResponse>({
    predictions: [],
    error: '',
  })

  useEffect(() => {
    const handleDebounce = window.setTimeout(async () => {
      try {
        if (userInput === prevUserInput.current) {
          return
        }
        prevUserInput.current = userInput

        const [nextPredictions, err] = await googleAutocomplete(
          userInput,
          googleOptions as google.maps.places.AutocompletionRequest
        )
        setPredictions({
          predictions: nextPredictions,
          error: err,
        })
      } catch (error) {
        console.error(error)
      }
    }, debounceTimeout)

    return () => {
      window.clearTimeout(handleDebounce)
    }
  }, [userInput, debounceTimeout, googleOptions])

  return predictions
}
