import styled from 'styled-components'
import { CSSCursor } from 'types/style'
import { overloadColorProp } from 'util/style'

interface CircleProps {
  textColor?: string
  color?: string
  size?: number
  inline?: boolean
  border?: string
  fontSize?: string
  fontWeight?: string | number
  cursor?: CSSCursor
}

export const Circle = styled.div.attrs((props: CircleProps) => ({
  color: props.color ?? 'primary',
  size: props.size ?? 35,
  ...props,
}))<CircleProps>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  display: ${props => (props.inline ? 'inline-flex' : 'flex')};
  justify-content: center;
  align-items: center;

  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  color: ${props => (props.textColor ? props.textColor : 'white')};
  background-color: ${overloadColorProp};
  cursor: ${props => props.cursor};

  ${props =>
    props.border &&
    `
    border: ${props.border};
  `}
  border-radius: ${props => props.size}px;
`
