import { useQuery } from '@apollo/client'
import { useForm, useTranslate } from '@ur/react-hooks'
import { endOfMonth, startOfMonth } from 'date-fns'
import { ExportOption } from 'modules/exports'
import { useInfringementsMutations } from 'modules/infringements'
import { useUser } from 'util/hooks'
import {
  Module,
} from '..'
import { ALL_MODULE_CONFIG_QUERYS_SHALLOW } from '../queries'
import {
  AllModuleConfigsQuery,
} from '../types.graphql'
import { InfringementsSettingsForm } from './util/InfringementsSettings'

export const useModuleConfigs = (skip: boolean = false) => {
  const { data, loading, refetch } = useQuery<AllModuleConfigsQuery>(
    ALL_MODULE_CONFIG_QUERYS_SHALLOW,
    {
      skip: skip,
    }
  )

  const modules = data?.allModuleConfigs.edges.map(edge => edge.node) ?? []
  const moduleNameModules = Object.fromEntries(
    modules.map(module => [module.name, module])
  )
  const moduleNames = modules.map(module => module.name)
  const activeModuleNames = modules
    ?.filter(module => module.active)
    .map(module => module.name)

  const activitiesActive = activeModuleNames.includes(Module.ACTIVITIES)
  const terminalActive = activeModuleNames.includes(Module.TERMINAL)
  const timesheetsActive = activeModuleNames.includes(Module.TIMESHEETS)
  const vehiclesActive = activeModuleNames.includes(Module.VEHICLES)
  const absencesActive = activeModuleNames.includes(Module.ABSENCES)
  const handbookActive = activeModuleNames.includes(Module.HANDBOOK)
  const forumActive = activeModuleNames.includes(Module.FORUM)
  const issuesActive = activeModuleNames.includes(Module.ISSUES)
  const infringementsActive = activeModuleNames.includes(Module.INFRINGEMENTS)
  const routePlannerActive = activeModuleNames.includes(Module.ROUTEPLANNER)

  const hasAnyModule =
    activitiesActive ||
    terminalActive ||
    timesheetsActive ||
    absencesActive ||
    issuesActive ||
    forumActive ||
    handbookActive ||
    infringementsActive ||
    routePlannerActive

  return {
    modules,
    moduleNameModules,
    moduleNames,

    loading,

    activitiesActive,
    terminalActive,
    timesheetsActive,
    absencesActive,
    vehiclesActive,
    handbookActive,
    forumActive,
    issuesActive,
    hasAnyModule,
    infringementsActive,
    routePlannerActive,

    refetch,
  }
}

export const useCompositeModules = () => {
  const {
    activitiesActive,
    terminalActive,
    timesheetsActive,
    absencesActive,
    handbookActive,
    issuesActive,
    infringementsActive,
  } = useModuleConfigs()
  const salaryModuleActive = activitiesActive || terminalActive
  const dashboardStatisticsModuleActive = activitiesActive || issuesActive
  const activitiesHandbookIssuesActive =
    activitiesActive || handbookActive || issuesActive
  const mainModuleActive =
    activitiesActive ||
    terminalActive ||
    timesheetsActive ||
    absencesActive ||
    issuesActive ||
    infringementsActive

  return {
    salaryModuleActive,
    dashboardStatisticsModuleActive,
    activitiesHandbookIssuesActive,
    mainModuleActive,
  }
}

export interface DateRangeForm {
  dateStart: Date
  dateEnd: Date
}

export const useExportInfringementsForm = (onCompleted?: () => void) => {
  const me = useUser()

  const translations = useTranslate({
    selectUsersError: 'activities.errors.select-multiple-users',
  })

  const {
    exportInfringementsMass,
    exportInfringements,
    loading: mailLoading,
  } = useInfringementsMutations(onCompleted)

  const {
    formValues: form,
    formErrors: errors,
    updateForm,
    submitHandler: submit,
  } = useForm<InfringementsSettingsForm>({
    values: {
      month: new Date(),
      dateStart: new Date(),
      dateEnd: new Date(),
      useDateRange: false,
      exportOption: ExportOption.SAVE,
      exportMass: false,
      user: '',
    },
    validators: {
      user: (value, form) =>
        !(value || form.exportMass) ? translations.selectUsersError : null,
    },
  })
  function handler<K extends keyof InfringementsSettingsForm>(key: K) {
    return (value: InfringementsSettingsForm[K] | null) => {
      if (value === null) return
      updateForm({ [key]: value })
    }
  }

  async function handleExport(values: InfringementsSettingsForm) {
    const dateStart = form.useDateRange
      ? form.dateStart
      : startOfMonth(values.month)
    const dateEnd = form.useDateRange ? form.dateEnd : endOfMonth(values.month)

    if (form.user) {
      exportInfringements({
        variables: {
          userMail: me.id,
          dateStart: dateStart,
          dateEnd: dateEnd,
          user: form.user,
          exportOption: values.exportOption,
        },
      })
    } else {
      exportInfringementsMass({
        variables: {
          userMail: me.id,
          dateStart: dateStart,
          dateEnd: dateEnd,
          exportOption: values.exportOption,
        },
      })
    }
  }

  return {
    form,
    errors,
    loading: mailLoading,
    submit,
    updateForm,
    handleExport,
    handler,
  }
}

export const useSalaryModuleTranslations = () => {
  const translations = useTranslate({
    activities: 'common.activities',
    terminal: 'common.terminal',
    timesheets: 'common.timesheets',
  })
  function getModuleTranslation(moduleName: string) {
    switch (moduleName) {
      case Module.ACTIVITIES:
        return translations.activities
      case Module.TERMINAL:
        return translations.terminal
      case Module.TIMESHEETS:
        return translations.timesheets
      default:
        return ''
    }
  }
  return getModuleTranslation
}
