import { useMutation, useQuery } from '@apollo/client'
import { Icon, Image, ImageViewer, Loader } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Button, CenteredLoader, Input } from 'components'
import {
  AddThreadInteractionMutation,
  AddThreadInteractionMutationVariables,
  ADD_THREAD_INTERACTION_MUTATION,
  PostAttachmentNode,
  ThreadAttachmentNode,
  ThreadWithPostsQuery,
  ThreadWithPostsQueryVariables,
  THREAD_WITH_POSTS_QUERY,
} from 'modules/forum'
import { CreatePost, PostCard } from 'modules/forum/components'
import { UserProfileLink } from 'modules/users/UserProfile/UserProfileLink'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import { ZIndexRange } from 'types/style'
import { PERMISSIONS } from 'util/permissions'
import { format } from 'util/date-fns'
import { attachmentIsViewableInFileViewer } from 'util/file'
import {
  ERROR_TYPES,
  useAdmin,
  useBreadcrumbs,
  useOnErrorAuto,
  usePermissions,
  useUser,
} from 'util/hooks'

const Wrapper = styled.div`
  ${props => props.theme.layout.default};

  header {
    padding-left: 4px;

    h2 {
      margin: 0;
    }
    p {
      margin: 6px 0 0 0;
      font-weight: 500;
      color: ${props => props.theme.colors.gray5};

      a {
        color: ${props => props.theme.colors.primary};
      }
      span.date {
        margin-left: 0.6ch;
      }
    }
  }
  & > div:not(.image-viewer) {
    margin-top: 1rem;
  }
`
const LoadMore = styled(Button)`
  display: flex;
  justify-content: center;
  margin: 2rem auto;
  padding: 0 38px;

  text-transform: uppercase;
  color: white;

  .loader {
    width: 0;
    transform: translateX(0.6rem);
  }
`
const TitleEdit = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 38px;
  gap: 12px;

  & > * {
    align-self: center;
  }
`
const TitleInput = styled(Input)`
  height: 38px;
  transform: translateX(-12px);

  input {
    background: white;
    padding: 8px;
    font-size: 1rem;
  }
`

const PAGE_SIZE = 20

interface ThreadProps {}

export const Thread: React.VFC<ThreadProps> = () => {
  const translations = useTranslate({
    loading: 'common.loading...',
    creator: ['common.by-name', { name: <em /> }],
    sticky: 'forum.sticky-thread',
    loadPrevious: 'common.load-previous',

    error: {
      doesNotExist: 'forum.errors.thread-does-not-exist',
    },
  })

  const admin = useAdmin()
  const { setOverride, overrides } = useBreadcrumbs()
  const { threadId } = useParams<{ threadId: string }>()
  const history = useHistory()
  const onErrorAuto = useOnErrorAuto()

  const [imageViewerInitialImage, setImageViewerInitialImage] = useState('')
  const [imageViewerAttachments, setImageViewerAttachments] = useState<Image[]>(
    []
  )
  const [editing, setEditing] = useState(false)

  const user = useUser()
  const { hasPermissions } = usePermissions()

  const canSeePosts = hasPermissions(PERMISSIONS.forum.view.post, true)
  const canPost = hasPermissions(PERMISSIONS.forum.add.post, true)

  const {
    formValues: editForm,
    formChangeHandler: editHandler,
    updateForm: updateEditForm,
  } = useForm({
    values: {
      title: '',
      sticky: false,
    },
  })

  const {
    loading: queryLoading,
    data: queryData,
    fetchMore: fetchMorePosts,
  } = useQuery<ThreadWithPostsQuery, ThreadWithPostsQueryVariables>(
    THREAD_WITH_POSTS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        id: threadId,
        withAdminFields: admin,
        numberOfPosts: PAGE_SIZE,
      },
      onCompleted(data) {
        if (!data) return
        updateEditForm({
          title: data.thread.title,
          sticky: data.thread.sticky,
        })
      },
      onError: onErrorAuto(
        {
          [ERROR_TYPES.DOES_NOT_EXIST]: translations.error.doesNotExist,
        },
        {
          callback: () => history.push('/forum'),
        }
      ),
    }
  )

  const [addThreadInteraction] = useMutation<
    AddThreadInteractionMutation,
    AddThreadInteractionMutationVariables
  >(ADD_THREAD_INTERACTION_MUTATION, {
    onError: onErrorAuto(),
  })
  useEffect(() => {
    if ((queryLoading || !queryData?.thread) && !overrides[threadId])
      setOverride(threadId, translations.loading)
    else if (queryData && overrides[threadId] === translations.loading)
      setOverride(queryData.thread.id, queryData.thread.title)
  }, [
    setOverride,
    threadId,
    translations.loading,
    queryData,
    queryLoading,
    overrides,
  ])

  useEffect(() => {
    if (typeof queryData === 'undefined') return

    if (!queryData.thread.seen) {
      addThreadInteraction({
        variables: { thread: queryData.thread.id },
      })
    }
  }, [queryData, addThreadInteraction])

  const loadMore = useCallback(() => {
    if (
      typeof queryData === 'undefined' ||
      typeof queryData.thread.posts.pageInfo === 'undefined'
    )
      return
    if (
      !queryData.thread.posts.pageInfo.hasPreviousPage ||
      !queryData.thread.posts.pageInfo.startCursor
    )
      return

    const cursor = queryData.thread.posts.pageInfo.startCursor
    fetchMorePosts({
      variables: {
        id: queryData.thread.id,
        withAdminFields: admin,
        numberOfPosts: PAGE_SIZE,
        cursor,
      },
    })
  }, [admin, queryData, fetchMorePosts])

  if (!queryData) return <CenteredLoader />

  function handleAttachmentClick(
    attachments: ThreadAttachmentNode[] | PostAttachmentNode[]
  ) {
    return (attachment: ThreadAttachmentNode | PostAttachmentNode) => {
      if (!attachmentIsViewableInFileViewer(attachment.file)) {
        window.open(attachment.file)
        return
      }

      setImageViewerInitialImage(attachment.id)
      const images = attachments.reduce<Image[]>((acc, cur) => {
        if (!attachmentIsViewableInFileViewer(cur.file)) return acc
        return [...acc, cur]
      }, [])
      setImageViewerAttachments(images)
    }
  }

  const thread = queryData.thread
  const posts = thread.posts.edges.map(edge => edge.node)
  const pageInfo = queryData.thread.posts.pageInfo

  return (
    <Wrapper>
      <ImageViewer
        className="image-viewer"
        images={imageViewerAttachments}
        zIndex={ZIndexRange.Extra}
        initialImage={imageViewerInitialImage}
        popup
        open={imageViewerAttachments.length > 0}
        noShadow
        showArrows
        background="black !important"
        imageSize="contain"
        imageBackgroundColor="black !important"
        onClose={() => setImageViewerAttachments([])}
      />

      <header>
        <h2>
          {!editing ? (
            <>
              {thread.sticky && (
                <Icon
                  type="solid"
                  icon="thumbtack"
                  color="orange"
                  size="0.8em"
                  margin="0 6px 0 0"
                  title={translations.sticky}
                />
              )}
              {thread.title}
            </>
          ) : (
            <TitleEdit>
              <Icon
                type={editForm.sticky ? 'solid' : 'regular'}
                icon="thumbtack"
                color="orange"
                size="0.8em"
                margin="0 6px 0 0"
                title={translations.sticky}
                cursor="pointer"
                onClick={() => updateEditForm({ sticky: !editForm.sticky })}
              />

              <TitleInput
                value={editForm.title}
                fullWidth
                onChange={editHandler('title')}
              />
            </TitleEdit>
          )}
        </h2>
        <p>
          {translations.creator({
            name: <UserProfileLink user={thread.user} />,
          })}
          ,
          <span className="date">
            {format(new Date(thread.createdAt), 'PPP')}
          </span>
        </p>
      </header>

      <PostCard
        thread
        id={thread.id}
        user={thread.user}
        content={thread.content}
        createdAt={new Date(thread.createdAt)}
        attachments={thread.attachments}
        numberOfViews={thread.numberOfViews}
        editThreadValues={editForm}
        onAttachmentClick={handleAttachmentClick(thread.attachments)}
        onEditThread={setEditing}
      />

      {pageInfo?.hasPreviousPage && (
        <LoadMore fontSize="1em" height="42px" onClick={loadMore}>
          {translations.loadPrevious}
          {queryLoading && (
            <div className="loader">
              <Loader.Spinner
                size={22}
                thickness={2}
                colorPrimary="white"
                colorSecondary="rgba(0, 0, 0, 0.1)"
              />
            </div>
          )}
        </LoadMore>
      )}

      {posts.filter(post => canSeePosts || post.user.id === user.id).map(post => (
        <PostCard
          id={post.id}
          key={post.id}
          user={post.user}
          content={post.content}
          attachments={post.attachments}
          createdAt={new Date(post.createdAt)}
          onAttachmentClick={handleAttachmentClick(post.attachments)}
        />
      ))}

      {canPost && <CreatePost thread={thread} />}
    </Wrapper>
  )
}
