import { gql, enableExperimentalFragmentVariables } from '@apollo/client'
import { CompanyFragments } from 'modules/companies/fragments'
import { UserFragments } from 'modules/users/fragments'

enableExperimentalFragmentVariables()

export const BOOTSTRAP_QUERY = gql`
  query Bootstrap {
    me {
      ...UserInfo
      ...UserTypes

      activeDriverCard {
        ...DriverCardInfo
      }
      driverCards {
        ...DriverCardInfo
      }
      allPermissions
      primaryCompany {
        id
        fullName
        shortName
      }
      companies {
        id
        fullName
        shortName
        logo
      }
      terminalEntries {
        edges {
          node {
            id
            checkOut
            checkIn
          }
        }
      }
    }
    currentCompany {
      ...CompanyInfo
      ...CompanyUserTypes
      numberOfUsers
      logo
      useCompanyLogo
    }
  }
  ${CompanyFragments.info}
  ${CompanyFragments.userTypes}
  ${UserFragments.info}
  ${UserFragments.types}
  ${UserFragments.driverCard}
`

export const GET_JWT_TOKEN_FROM_SHORT_URL = gql`
  query GetJwtTokenFromShortUrl($urlId: String!) {
    getJwtTokenFromShortUrl(urlId: $urlId)
  }
`
