import { Image } from '@ur/react-components/build/ImageViewer'
import { useForm } from '@ur/react-hooks'
import uniqueId from 'lodash/uniqueId'
import { useMemo } from 'react'
import { attachmentIsViewableInFileViewer } from 'util/file'
import { isFileArray } from 'util/typechecks'

export interface Attachment<T extends string | File = string | File> {
  id: string
  name: string
  file: T
}

export interface FileAttachment extends Omit<Attachment, 'file'> {
  file: File
}

export interface AttachmentHandlers {
  /** Fired when one or more attachments are added */
  onAdd?: (
    newAttachments: FileAttachment[],
    attachmentsAdd: FileAttachment[],
    attachmentsRemove: string[]
  ) => void
  /** Fired when an attachment is removed */
  onRemove?: (
    attachment: Attachment,
    attachmentsRemove: string[],
    attachmentsAdd: FileAttachment[]
  ) => void
}

export function useAttachmentHandlers(
  existingAttachments: Attachment[] = [],
  setImageViewerInitialImage?: (id: string) => void,
  setImageViewerAttachments?: (images: Image[]) => void,
  handlers?: AttachmentHandlers
) {
  const { formValues: form, updateForm } = useForm({
    values: {
      attachmentsAdd: [] as FileAttachment[],
      attachmentsRemove: [] as string[],
    },
  })

  const attachments = useMemo<Attachment[]>(
    () =>
      existingAttachments
        .filter(attachment => !form.attachmentsRemove.includes(attachment.id))
        .concat(form.attachmentsAdd),
    [form.attachmentsAdd, form.attachmentsRemove, existingAttachments]
  )

  function handleDropAttachment(evt: React.DragEvent<HTMLDivElement>) {
    const files = Array.from(evt.dataTransfer.files)
    handleAddAttachment(files)
  }

  function handleAddAttachment(files: File[]): void
  function handleAddAttachment(evt: React.ChangeEvent<HTMLInputElement>): void
  function handleAddAttachment(
    arg: File[] | React.ChangeEvent<HTMLInputElement>
  ) {
    let files: File[] = []
    if (isFileArray(arg)) files = arg
    else {
      files = Array.from(arg.target.files ?? [])
      arg.target.value = ''
    }

    if (!files.length) return

    const newAttachments = files.map(file => ({
      id: uniqueId('attachment'),
      name: file.name,
      file,
    }))
    const attachmentsAdd = [...form.attachmentsAdd, ...newAttachments]

    updateForm({
      attachmentsAdd,
    })

    handlers?.onAdd?.(newAttachments, attachmentsAdd, form.attachmentsRemove)
  }

  function handleRemoveAttachment(attachment: Attachment) {
    if (attachment.id.startsWith('attachment')) {
      const attachmentsAdd = form.attachmentsAdd.filter(
        add => add.id !== attachment.id
      )
      updateForm({
        attachmentsAdd,
      })

      handlers?.onRemove?.(attachment, form.attachmentsRemove, attachmentsAdd)
    } else {
      const attachmentsRemove = [...form.attachmentsRemove, attachment.id]
      updateForm({
        attachmentsRemove,
      })

      handlers?.onRemove?.(attachment, attachmentsRemove, form.attachmentsAdd)
    }
  }

  function handleAttachmentClick(attachment: Attachment) {
    if (
      typeof setImageViewerInitialImage === 'undefined' &&
      typeof setImageViewerAttachments === 'undefined'
    )
      return

    if (
      typeof attachment.file === 'string' &&
      !attachmentIsViewableInFileViewer(attachment.file)
    ) {
      window.open(attachment.file)
      return
    }

    setImageViewerInitialImage?.(attachment.id)
    const images = attachments.reduce<Image[]>((acc, cur) => {
      if (!attachmentIsViewableInFileViewer(cur.file)) return acc
      return [...acc, cur]
    }, [])
    setImageViewerAttachments?.(images)
  }

  function resetEditedAttachments() {
    updateForm({
      attachmentsAdd: [],
      attachmentsRemove: [],
    })
  }

  return {
    handleDropAttachment,
    handleAddAttachment,
    handleRemoveAttachment,
    handleAttachmentClick,
    resetEditedAttachments,
    ...form,
    allAttachments: attachments,
  }
}
