import React, { useState } from 'react'
import styled from 'styled-components'
import { Text } from 'components/Text'
import { FAIcon } from 'components/icons'
import {
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  subMonths,
  addMonths,
  isSameMonth,
  isAfter,
  isSameDay,
  endOfDay,
} from 'date-fns'
import { startOfWeek, endOfWeek, format } from 'util/date-fns'
import { DateRangeCellProps } from './types'
import { ZIndexRange } from 'types/style'
import { isDateSelected } from 'components/DatePicker/DateRangePicker/util'

const MonthDropdown = styled.div<{ grayed: boolean }>`
  position: absolute;

  background: ${props =>
    props.grayed ? props.theme.colors.quaternary : props.theme.colors.white};
  display: grid;
  grid-template-areas:
    'prevMonth upOneLevel nextMonth'
    'body body body';
  grid-template-columns: 35px 4fr 35px;
  grid-template-rows: 35px auto;
  top: 48px;
  padding: 0.25rem;
  width: 100%;
  box-shadow: ${props => props.theme.shadow.bottom};
  border-left: 1px solid ${props => props.theme.colors.gray9};
  border-right: 1px solid ${props => props.theme.colors.gray9};
  border-radius: 0 0 12px 12px;
  z-index: ${ZIndexRange.Dropdowns};
  user-select: none;
`

const PrevMonth = styled.div`
  grid-area: prevMonth;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  :hover {
    color: white;
    background-color: ${props => props.theme.colors.primary};
  }
`

const UpOneLevel = styled.div`
  grid-area: upOneLevel;
  justify-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bold;

  :hover {
    color: white;
    background-color: ${props => props.theme.colors.primary};
  }
`

const NextMonth = styled.div`
  grid-area: nextMonth;
  justify-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  :hover {
    color: white;
    background: ${props => props.theme.colors.primary};
  }
`

const MonthBody = styled.div`
  margin-top: 0.5rem;
  display: grid;
  grid-area: body;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: 35px;
`

const WeekHeaderCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const DateCell = styled.div<DateRangeCellProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 1px;

  border-radius: 5px;

  background: ${props =>
    props.selected
      ? props.isEndDate
        ? props.theme.colors.primary
        : props.theme.colors.lightPrimary
      : 'inherit'};
  color: ${props =>
    props.selected
      ? props.isEndDate
        ? props.theme.colors.white
        : props.theme.colors.black
      : 'inherit'};

  :hover {
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
    opacity: 1;
  }

  ${props =>
    props.notSameMonth &&
    `
    opacity: ${props.selected ? '1' : '0.6'};
  `}

  ${props =>
    props.disabled &&
    `
      background: ${props.theme.colors.gray8};
      opacity: 0.2;
      :hover {
        background: ${props.theme.colors.gray8};
        color: ${props.theme.colors.black};
        opacity: 0.2;
      }
    `}
`

interface DateRangePickerMonthViewProps {
  monthPivot: Date
  dateRange: [Date, Date]
  setMonthPivot: (newMonthPivot: Date) => void
  handleRangeChanged: (newDate: [Date, Date], close?: boolean) => void
  isDisabled: (date: Date) => boolean
  onChangeGranularity: () => void
  grayed: boolean
}

export const DateRangePickerMonthView: React.FC<DateRangePickerMonthViewProps> =
  ({
    monthPivot,
    dateRange,
    setMonthPivot,
    handleRangeChanged,

    isDisabled,

    onChangeGranularity,

    grayed,
  }) => {
    const monthStart = startOfMonth(monthPivot)
    const monthEnd = endOfMonth(monthPivot)
    const [firstDate, setFirstDate] = useState<Date | null>(null)
    const [hoverDate, setHoverDate] = useState<Date | null>(null)

    const firstMondayBeforeMonthStart = startOfWeek(monthStart)
    const lastSundayAfterMonthEnd = endOfWeek(monthEnd)

    const allDatesToDisplay = eachDayOfInterval({
      start: firstMondayBeforeMonthStart,
      end: lastSundayAfterMonthEnd,
    })

    const handleClick = (date: Date) => {
      if (isDisabled(date)) {
        return
      }
      if (firstDate) {
        if (isAfter(date, firstDate)) {
          handleRangeChanged([firstDate, endOfDay(date)])
        } else {
          handleRangeChanged([date, endOfDay(firstDate)])
        }
        setFirstDate(null)
      } else {
        setFirstDate(date)
        handleRangeChanged([date, date], false)
      }
    }

    // Checks if the given date is an end date in the date range.
    // The variables firstDate and hoverdate is used between the first and
    // second second date selection. The dateRange is used with initial
    // or saved values.
    const isEndDate = (date: Date) => {
      return (
        (firstDate && isSameDay(date, firstDate)) ||
        (hoverDate && isSameDay(date, hoverDate) && firstDate !== null) ||
        (isSameDay(date, dateRange[0]) && firstDate === null) ||
        (isSameDay(date, dateRange[1]) && firstDate === null)
      )
    }

    // This function uses a function defined in util that checks if the given
    // date is in the active date range [firstDate-hoverDate] or the initial or
    // saved dateRange. Also checks if the date is disabled.
    const selected = (date: Date) => {
      return isDisabled(date)
        ? false
        : isDateSelected(date, firstDate, hoverDate, dateRange, isDisabled)
    }

    return (
      <MonthDropdown grayed={grayed}>
        <PrevMonth onClick={() => setMonthPivot(subMonths(monthPivot, 1))}>
          <FAIcon icon="chevron-left" type="solid" />
        </PrevMonth>
        <UpOneLevel onClick={onChangeGranularity}>
          {format(monthPivot, 'MMMM yyyy')}
        </UpOneLevel>
        <NextMonth
          onClick={() => {
            setMonthPivot(addMonths(monthPivot, 1))
          }}
        >
          <FAIcon icon="chevron-right" type="solid" />
        </NextMonth>
        <MonthBody>
          {allDatesToDisplay.slice(0, 7).map(date => (
            <WeekHeaderCell key={format(date, 'yyyy-MM-dd')}>
              <Text fontWeight="bold">{format(date, 'EEEEEE')}</Text>
            </WeekHeaderCell>
          ))}
          {allDatesToDisplay.map(date => (
            <DateCell
              key={format(date, 'yyyy-MM-dd')}
              onClick={() => handleClick(date)}
              notSameMonth={!isSameMonth(date, monthPivot)}
              disabled={isDisabled(date)}
              onMouseOver={() => {
                setHoverDate(date)
              }}
              selected={selected(date)}
              isEndDate={isEndDate(date)}
            >
              {format(date, 'dd')}
            </DateCell>
          ))}
        </MonthBody>
      </MonthDropdown>
    )
  }
