import { PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import {
  Checkbox,
  DatePicker,
  CountrySelect,
  Input,
  NumberInput,
  RegularModal,
  Select,
  RadioGroup,
} from 'components'
import {
  Field as BaseField,
  Form as BaseForm,
} from 'modules/users/UserSettings/components'
import {
  VehicleDetailInput,
  EditVehicleDetailsForm,
  EditVehicleDetailsModalResolve,
  VehicleDetailFormField,
} from 'modules/vehicles/types'
import { VehicleDetailNode } from 'modules/vehicles/types.graphql'
import { useMemo } from 'react'
import styled from 'styled-components'
import { CountryCode } from 'util/countries'

const Form = styled(BaseForm)`
  flex-direction: row;
  flex-wrap: wrap;
  & > div {
    width: 100%;
  }
  & > div {
    width: 100%;
  }
  .--checkbox-field {
    width: 50%;
  }
`

const Field = styled(BaseField).attrs<{ hidden: boolean }>(props => ({
  hidden: props.hidden,
}))`
  display: ${props => (props.hidden ? 'none' : 'flex')};
`

interface EditVehicleDetailsModalProps {
  title: string
  vehicle: VehicleDetailNode
  fields: VehicleDetailInput[]
  onSubmit: PromptResolve<EditVehicleDetailsModalResolve | null>
}

interface Accumulator {
  [key: string]: any
  registrationDate: Date | null
}

function resolveFieldValue(
  field: VehicleDetailInput,
  vehicle: VehicleDetailNode
) {
  const value = vehicle[field.key]
  if (!field.type) return value
  if (field.type === 'date') {
    return value && new Date(value as string)
  }
  return value
}

export const EditVehicleDetailsModal: React.VFC<EditVehicleDetailsModalProps> =
  ({ title, vehicle, fields, onSubmit }) => {
    const translations = useTranslate({
      edit: 'vehicles.edit-vehicle',
      date: 'common.date',
    })

    const initialValues: EditVehicleDetailsForm = fields.reduce(
      (acc, field) => {
        acc[field.key] = resolveFieldValue(field, vehicle)
        return acc
      },
      {} as Accumulator
    )

    const {
      formValues: form,
      formChangeHandler: handler,
      submitHandler: submit,
    } = useForm<EditVehicleDetailsForm>({
      values: initialValues,
    })

    const formFields = useMemo(() => {
      return fields.map(field => {
        const fieldValue = form[field.key]
        const requireDependencies = field.dependencies?.requireFields
        const hidden =
          requireDependencies &&
          fields?.filter(
            evalField =>
              requireDependencies?.includes(evalField.key as string) &&
              !!form[evalField.key]
          ).length < requireDependencies.length

        const defaultReturn = () => {
          return (
            <Field key={field.key} hidden={hidden}>
              <label>{field.label}</label>
              <Input
                value={(form[field.key] as string) ?? ''}
                disabled={field.readOnly}
                onChange={handler(field.key)}
              />
            </Field>
          )
        }

        switch (field.type) {
          case VehicleDetailFormField.Date:
            return (
              <Field key={field.key} hidden={hidden}>
                <label>{field.label}</label>
                <DatePicker
                  fullWidth
                  displayDateFormat={'PP'}
                  value={(fieldValue as Date | null) ?? new Date()}
                  clearable
                  disabled={field.readOnly}
                  initCleared={fieldValue === null}
                  placeholder={translations.date}
                  onChange={(value, cleared) =>
                    handler(field.key)(cleared ? null : value)
                  }
                />
              </Field>
            )
          case VehicleDetailFormField.Boolean:
            return (
              <Field
                className="--checkbox-field"
                alignLeft
                key={field.key}
                hidden={hidden}
              >
                <Checkbox
                  checked={form[field.key] as boolean}
                  label={field.label}
                  disabled={field.readOnly}
                  onChange={handler(field.key)}
                />
              </Field>
            )
          case VehicleDetailFormField.Number:
            return (
              <Field key={field.key} hidden={hidden}>
                <label>
                  {field.label} {field.unit && `(${field.unit})`}
                </label>
                <NumberInput
                  value={(form[field.key] as number) ?? ''}
                  disabled={field.readOnly}
                  onChange={handler(field.key)}
                />
              </Field>
            )
          case VehicleDetailFormField.Country:
            return (
              <Field key={field.key} hidden={hidden}>
                <label>{field.label}</label>
                <CountrySelect
                  value={form[field.key] as CountryCode}
                  fullWidth
                  onChange={handler(field.key)}
                />
              </Field>
            )
          case VehicleDetailFormField.Select:
            if (field.options && !(form[field.key] instanceof Date)) {
              return (
                <Field key={field.key} hidden={hidden}>
                  <label>{field.label}</label>
                  <Select
                    value={form[field.key] as string | number | null}
                    options={field.options}
                    fullWidth
                    onChange={handler(field.key)}
                  />
                </Field>
              )
            } else {
              return defaultReturn()
            }
          case VehicleDetailFormField.Radio:
            if (field.options && !(form[field.key] instanceof Date)) {
              return (
                <Field key={field.key} hidden={hidden}>
                  <label>{field.label}</label>
                  <RadioGroup
                    value={(form[field.key] ?? '') as string | number}
                    radios={field.options}
                    onChange={handler(field.key)}
                  />
                </Field>
              )
            } else {
              return defaultReturn()
            }
          default:
            return defaultReturn()
        }
      })
    }, [fields, form, handler, translations.date])

    return (
      <RegularModal
        title={`${translations.edit}: ${title}`}
        cancelOnClickOutside
        overflowY="auto"
        width="480px"
        onCancel={() => onSubmit(null)}
        onSubmit={submit(form => onSubmit({ form }))}
      >
        <Form>{formFields}</Form>
      </RegularModal>
    )
  }
