import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import { CompanyContext } from 'context/CompanyContext'

import { BOOTSTRAP_QUERY } from 'modules/authentication/queries'
import { CenteredErrorMessage } from 'components/CenteredErrorMessage/CenteredErrorMessage'
import { NoAuthRoutes } from './NoAuthRoutes'
import { NoCompanies } from 'modules/companies'
import UserContext from 'context/UserContext'
import MainLayout from './MainLayout'
import { RegisterUser } from 'modules/users/RegisterUser/RegisterUser'
import { ChangePassword } from 'modules/users/UserSettings/ChangePassword'
import { FullPageLoader } from 'components/Loader'
import { BootstrapQuery } from 'modules/authentication/types.graphql'
import { useTranslate } from '@ur/react-hooks'
import { useModuleConfigs } from 'modules/companies/CompanySettings/hooks'
import { HtmlAsPdf } from 'modules/exports/view/HtmlAsPdf'
import { PrivateRoute } from './permission-containers'
import { Fleet } from 'modules/fleet'

// The primary role of this component is to bootstrap
// all necessary information about the current user and company,
// and then add this to different contexts
export function Bootstrap() {
  // The bootstrap query fetches the current user and the current company
  // the user is on, if any.
  const { loading, data, error, refetch } =
    useQuery<BootstrapQuery>(BOOTSTRAP_QUERY)

  // We need to load module configs early on
  const { loading: loadingModuleConfigs, refetch: refetchModuleConfigs } =
    useModuleConfigs(!data?.me)

  useEffect(() => {
    if (data?.me) {
      refetchModuleConfigs().then(() => {
        if (localStorage.getItem('FIRMADOK_REDIRECT_LOGIN')) {
          window.location.href =
            localStorage.getItem('FIRMADOK_REDIRECT_LOGIN') ?? '/'
          localStorage.removeItem('FIRMADOK_REDIRECT_LOGIN')
        }
      })
    }
  }, [data?.me, refetchModuleConfigs])

  const translations = useTranslate({
    genericError: 'errors.generic-error',
  })

  if (error) {
    return (
      <>
        <CenteredErrorMessage message={translations.genericError} />
      </>
    )
  }

  if (loading || data === undefined || loadingModuleConfigs) {
    return <FullPageLoader />
  }

  const me = data.me
  const currentCompany = data.currentCompany

  // If the user is not logged in, render routes for non-authenticated
  // visitors.
  if (me === null || me === undefined) {
    return <NoAuthRoutes />
  }

  // The user might need to either change password or do the initial registration
  if (!me.hasRegistered) {
    return <RegisterUser user={me} />
  } else if (me.needsPasswordChange) {
    return <ChangePassword />
  }

  // If the user is not an a company path, try to find a company
  // the user is associated with and navigate the user there
  if (currentCompany === null) {
    let userCompany = null
    if (me.primaryCompany) userCompany = me.primaryCompany
    if (me.companies.length > 0) userCompany = me.companies[0]

    if (!userCompany) return <NoCompanies />

    window.history.pushState({}, '', '/c/' + userCompany.shortName)
    refetch()
    return null
  }

  // Create a new context which allows a user to switch company. Calling this
  // will update the history, and refetch the bootstrap query
  const SwitchCompanyContext = React.createContext<(arg: string) => void>(
    () => undefined
  )

  // Render the primary internal view, wrapped in the company context
  // and the user context
  return (
    <SwitchCompanyContext.Provider
      value={(newCompany: string) => {
        window.history.pushState({}, '', '/c/' + newCompany)
        refetch()
      }}
    >
      <CompanyContext.Provider value={currentCompany}>
        <UserContext.Provider value={me}>
          {/* We can now safely create the routing context, as we have the company basename in place */}
          <Router basename={'/c/' + currentCompany.shortName}>
            <Switch>
              <PrivateRoute exact path="/export" component={HtmlAsPdf} />
              <PrivateRoute exact path="/fleet" component={Fleet} />
              <Route>
                <MainLayout />
              </Route>
            </Switch>
          </Router>
        </UserContext.Provider>
      </CompanyContext.Provider>
    </SwitchCompanyContext.Provider>
  )
}
