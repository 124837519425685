import { PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { RegularModal, Select } from 'components'
import {
  Field,
  Form,
} from 'modules/companies/CompanySettings/components/common'
import { ExportFormat, ExportType } from 'modules/exports'
import { format } from 'util/date-fns'
import { ExportOption } from 'modules/exports'
import { ExportTerminalEntriesForm } from './types'
import { useExportOptions } from 'modules/exports/hooks'

interface ExportTerminalEntriesPromptProps {
  onSubmit: PromptResolve<ExportTerminalEntriesForm | null>
  salaryDates: [Date, Date]
}

export const ExportTerminalEntriesPrompt: React.VFC<ExportTerminalEntriesPromptProps> =
  ({ onSubmit, salaryDates }) => {
    const translations = useTranslate({
      exportDrivingData: 'activities.export-driving-data',
      export: 'common.export',

      hours: 'common.hours',
      salary: 'common.salary',

      type: 'common.type',
      exportOption: 'common.export-type',
      exportFormat: 'common.export-format',
      excel: 'common.excel',

      options: {
        monthly: 'exports.month-export',
        salary: 'exports.salary-export',
        email: 'common.email',
        download: 'common.download',
      },
    })

    const {
      formValues: form,
      updateForm,
      submitHandler: submit,
    } = useForm<ExportTerminalEntriesForm>({
      values: {
        exportType: ExportType.ENTRY_MONTH,
        exportOption: ExportOption.DOWNLOAD,
        exportFormat: ExportFormat.PDF,
      },
    })

    const exportTypes = [
      {
        value: ExportType.ENTRY_MONTH,
        label: translations.options.monthly,
      },
      {
        value: ExportType.ENTRY_SALARY,
        label: `${translations.options.salary} (${format(
          salaryDates[0],
          'dd.MMMM'
        )}-${format(salaryDates[1], 'dd.MMMM')})`,
      },
    ]

    const exportOptions = useExportOptions(true)

    return (
      <RegularModal
        title={translations.exportDrivingData}
        width="400px"
        submitText={translations.export}
        onCancel={() => onSubmit(null)}
        onSubmit={submit(onSubmit)}
      >
        <Form>
          <Field>
            <label>{translations.type}</label>
            <Select
              value={form.exportType}
              options={exportTypes}
              fullWidth
              height="62px"
              onChange={value =>
                updateForm({
                  exportType: value!,
                })
              }
            />
          </Field>

          <Field>
            <label>{translations.exportOption}</label>
            <Select
              value={form.exportOption}
              options={exportOptions}
              fullWidth
              height="62px"
              onChange={value =>
                updateForm({
                  exportOption: value!,
                })
              }
            />
          </Field>
        </Form>
      </RegularModal>
    )
  }
