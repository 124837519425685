import { Icon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { Table } from 'components'
import React from 'react'
import { TerminalSettingsViewProps } from './types'

interface TerminalSettingsTableProps extends TerminalSettingsViewProps {}

export const TerminalSettingsTable: React.VFC<TerminalSettingsTableProps> = ({
  terminals,

  onEdit,
  onDelete,
}) => {
  const translations = useTranslate({
    name: 'common.name',
    ipAddress: 'terminal.ip-address',
  })

  return (
    <Table
      noShadow
      noBorders
      noHeaderMargin
      noRowMargin
      rowHoverColor="secondary"
    >
      <thead>
        <tr>
          <th>{translations.name}</th>
          <th colSpan={3}>{translations.ipAddress}</th>
        </tr>
      </thead>

      <tbody>
        {terminals.map(terminal => (
          <tr key={terminal.id}>
            <td>{terminal.name}</td>

            <td>
              {terminal.ip}/{terminal.ipFilterRange}
            </td>

            <td width="1px">
              <Icon
                icon="edit"
                cursor="pointer"
                color="gray6"
                hoverColor="secondary"
                onClick={() => onEdit(terminal)}
              />
            </td>

            <td width="1px">
              <Icon
                icon="times"
                cursor="pointer"
                color="gray6"
                hoverColor="red"
                onClick={() => onDelete(terminal)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
