import { BaseLanguage } from '../util'

const ru: BaseLanguage = {
  'activities.add-salary-day': 'NEEDS TRANSLATION',
  'activities.approve-day': 'NEEDS TRANSLATION',
  'activities.approve-month': 'NEEDS TRANSLATION',
  'activities.approve-title': 'NEEDS TRANSLATION',
  'activities.approve-week': 'NEEDS TRANSLATION',
  'activities.availability-time': 'Время доступности',
  'activities.consumption': 'Расход топлива',
  'activities.day-end': 'Конец дня',
  'activities.day-start': 'Начало дня',
  'activities.driver': 'Водитель',
  'activities.driving': 'Вождение',
  'activities.driving-grade': 'Отценка вождения',
  'activities.end-position':
    '{abbreviate, select, true {Конеч. поз.} other {Конечная позиция}}',
  'activities.errors.could-not-load-route-in-map':
    'Не удалось загрузить маршрут на карте',
  'activities.errors.general-error':
    'Произошла ошибка при получение информации о водительской деятельности',
  'activities.errors.select-multiple-users': 'NEEDS TRANSLATION',
  'activities.errors.user-not-found': 'Пользователь не найден',
  'activities.export-driving-data': 'Экспортировать данные о вождении',
  'activities.export-monthly-weekly':
    'Экспортировать разделяя помесячно или понедельно',
  'activities.fetch-data': 'Получить данные о вождении',
  'activities.fuel-usage': 'Расход топлива',
  'activities.last-fetched': 'NEEDS TRANSLATION',
  'activities.missing-data': 'NEEDS TRANSLATION',
  'activities.module-title': 'Данные о вождении',
  'activities.paid-pause': 'NEEDS TRANSLATION',
  'activities.pause-export': 'NEEDS TRANSLATION',
  'activities.print-driving-data': 'Распечатать данные о вождении',
  'activities.regnr': 'Рег. номер',
  'activities.rest': 'Отдых',
  'activities.start-position':
    '{abbreviate, select, true {Нач. поз.} other {Начальная позиция}}',
  'activities.starting-activity-fetching-process':
    'Начало процесса получение деятельности',
  'activities.success.approve-activities': 'NEEDS TRANSLATION',
  'activities.sum-for-month-title': 'Сумма за {month}',
  'activities.sum-next-month':
    'Сумма за нелелю {week} будет рассчитана в следующем месяце',
  'activities.sum-overtime': 'Общие сверхурочные',
  'activities.updated-diet-successfully': 'NEEDS TRANSLATION',
  'activities.updated-salary-day-successfully': 'NEEDS TRANSLATION',
  'activities.work': 'Работа',
  'activities.work-summary': 'Сводка работы',
  'activities.working-hours': 'Рабочее время',
  'admin-required.message':
    'Вы должны быть администратором для просмотра этого.',
  'auth.admin-or-user-required':
    'Вы должны быть {user} или администратором для просмотра этого.',
  'change-password.description':
    'Вы должны сменить пароль перед входом по соображениям безопасности.',
  'change-password.title': 'Изменить пароль',
  'checklists.all-checklist-templates': 'Все шаблоны контрольных списков',
  'checklists.checklist-item': 'Пункт контрольного списка',
  'checklists.checklist-template': 'Шаблон контрольного списка',
  'checklists.generate-new': 'Создать новый',
  'checklists.no-checklists': 'Нет доступных контрольных списков.',
  'commmon.edit-name': 'Редактировать имя',
  'common.about': 'О',
  'common.absence': 'Отсутствие',
  'common.absence-types': 'Типы отсутствия',
  'common.absences': 'Отсутствие',
  'common.action': 'NEEDS TRANSLATION',
  'common.active': 'Активно',
  'common.activities': 'Данные о вождении',
  'common.activity': 'Деятельность',
  'common.add': 'Добавить',
  'common.add-attachment': 'Добавить вложение',
  'common.add-attachments': 'Добавить вложения',
  'common.add-column': 'Добавить столбец',
  'common.add-file': 'Добавить файл',
  'common.add-file-alt': 'Добавить файл',
  'common.add-files': 'Добавить файлы',
  'common.additional-pay': 'NEEDS TRANSLATION',
  'common.address': 'Адрес',
  'common.all': 'Все',
  'common.ambulance': 'Скорая помощь',
  'common.app': 'Приложение',
  'common.archive': 'Архивировать',
  'common.archived': 'Aрхивировано',
  'common.article': 'Статья',
  'common.attachments': 'Вложения',
  'common.author': 'Автор',
  'common.availability': 'NEEDS TRANSLATION',
  'common.basis': 'Основа',
  'common.both': 'Оба',
  'common.business': 'Бизнес',
  'common.businessOverview': 'Обзор бизнеса',
  'common.by-name': 'По {name}',
  'common.cancel': 'Отмена',
  'common.cancel-changes': 'Отменить изменения',
  'common.categories': 'Категории',
  'common.category': 'Категория',
  'common.category-for-type': 'Категории для {type}',
  'common.change-company': 'Изменить компанию',
  'common.change-language': 'Изменить язык',
  'common.change-password': 'Изменить пароль',
  'common.changes-saved': 'Изменения сохранены',
  'common.checklist-templates': 'Шаблоны контрольных списков',
  'common.checklists': 'Контрольные списки',
  'common.choose-ellipsis': 'Выберите...',
  'common.city': 'Город',
  'common.close': 'Закрыть',
  'common.closed': 'Закрыто',
  'common.codes': 'Коды',
  'common.color': 'Цвет',
  'common.comment': 'NEEDS TRANSLATION',
  'common.comment-ellipsis': 'Комментировть... ',
  'common.comments': 'Комментарии',
  'common.company': 'Компания',
  'common.company-name': 'Название компании',
  'common.contact-info': 'Контактная информация',
  'common.contact-person': 'Контактное лицо',
  'common.content': 'Содержание',
  'common.count': 'Счетчик',
  'common.count-of-total': '{count} из {total}',
  'common.count-of-total-verbose':
    'Кол-во сотрудников увидело: {count}/{total}',
  'common.countries': 'Страны',
  'common.country': 'Страна',
  'common.create': 'Создать',
  'common.create-alt': 'Создать',
  'common.create-entry': 'Создать новую запись',
  'common.create-folder': 'Создать новую папку',
  'common.create-form': 'Создать новую форму',
  'common.create-form-entry': 'Создать новую запись формы',
  'common.create-type': 'Создать {type}',
  'common.creating': 'Создание',
  'common.csv': 'NEEDS TRANSLATION',
  'common.current-password': 'Текущий пароль',
  'common.daily': 'Ежедневно',
  'common.dashboard': 'Приборная панель',
  'common.data': 'NEEDS TRANSLATION',
  'common.data-distribution': 'NEEDS TRANSLATION',
  'common.date': 'Дата',
  'common.date-for-type': 'Дата для {type}',
  'common.date-validation-not-in-future':
    'Дата должна быть действительной, и не в будущем',
  'common.day-end': 'Конец дня',
  'common.day-start': 'Начало дня',
  'common.days':
    '{days, plural, =0 {Нет дней} one {# день} two {# дня} three {# дня} four {# дня} other {# дней}}',
  'common.deactivated': 'Деактивировано',
  'common.default-severity': 'NEEDS TRANSLATION',
  'common.delete': 'Удалить',
  'common.delete-alt': 'Удалить',
  'common.delete-type': 'Удалить {type}',
  'common.department': 'NEEDS TRANSLATION',
  'common.department-expandable-text': 'NEEDS TRANSLATION',
  'common.departments': 'NEEDS TRANSLATION',
  'common.description': 'Описание',
  'common.detail': 'Деталь',
  'common.details': 'NEEDS TRANSLATION',
  'common.diet': 'Диета',
  'common.disabled': 'Отключено',
  'common.distance': 'Расстояние',
  'common.document': 'Документ',
  'common.documentation': 'Документация',
  'common.documentationIssues': 'Проблемы с документацией',
  'common.documents': 'Документы',
  'common.download': 'Скачать',
  'common.driver': 'Водитель',
  'common.drivers': 'Водители',
  'common.drivers-handbook': 'Справочник водителя',
  'common.driversHandbook': 'Справочник водителя',
  'common.driversHandbookForm': 'Справочники',
  'common.driving': 'Вождение',
  'common.driving-data': 'Данные о вождении',
  'common.dropdown': 'Выпадающий список',
  'common.dropdown-on-pages': 'Выпадающий список пользователя на странице',
  'common.dropdowns': 'Выпадающие списки',
  'common.edit': 'Редактировать',
  'common.edit-alt': 'Редактировать',
  'common.edit-entry': 'Редактировать запись',
  'common.edit-name': 'Редактировать название',
  'common.edit-type': 'Редактировать {type}',
  'common.email': 'Электронная почта',
  'common.email-already-selected': 'Адрес электронной почты уже выбран',
  'common.employee': 'Сотрудник',
  'common.enabled': 'Включено',
  'common.end': 'Конец',
  'common.end-date': 'Дата окончания',
  'common.end-time': 'Время окончания',
  'common.enter-url': 'Введите URL',
  'common.entry': 'Запись',
  'common.eurovignette': 'Евро-виньетка',
  'common.eurovignettes': 'Евро-виньетки',
  'common.excel': 'NEEDS TRANSLATION',
  'common.excel-flat': 'NEEDS TRANSLATION',
  'common.expired': 'Срок действия истек',
  'common.expires-at': 'Истекает в',
  'common.export': 'Экспортировать',
  'common.export-format': 'NEEDS TRANSLATION',
  'common.export-type': 'Тип экспорта',
  'common.exports-settings': 'NEEDS TRANSLATION',
  'common.external-id': 'NEEDS TRANSLATION',
  'common.fats': 'NEEDS TRANSLATION',
  'common.fats-beta': 'NEEDS TRANSLATION',
  'common.field': 'NEEDS TRANSLATION',
  'common.file': 'Файл',
  'common.files': 'Файлы',
  'common.filter': 'Фильтр',
  'common.finished': 'Закончено',
  'common.fire': 'Пожарная охрана',
  'common.firstName': 'Имя',
  'common.folder': 'Папка',
  'common.form-error': 'Одно или несколько полей заполнены неправильно.',
  'common.form-responses': 'NEEDS TRANSLATION',
  'common.formcreator': 'Создатель форм',
  'common.forms': 'NEEDS TRANSLATION',
  'common.forum': 'Форум',
  'common.forum-posts': 'NEEDS TRANSLATION',
  'common.forum-threads': 'NEEDS TRANSLATION',
  'common.freight-carrier': 'Грузовоперевозчик',
  'common.from': 'Из',
  'common.general': 'Общее',
  'common.generate': 'NEEDS TRANSLATION',
  'common.generate-report': 'NEEDS TRANSLATION',
  'common.go-back': 'Назад',
  'common.handbook': 'Справочник',
  'common.height': 'NEEDS TRANSLATION',
  'common.height-in-cm': 'NEEDS TRANSLATION',
  'common.high': 'NEEDS TRANSLATION',
  'common.holiday-allowances': 'NEEDS TRANSLATION',
  'common.home': 'Главная страница',
  'common.hourlist': 'Табели учета',
  'common.hours': 'Часы',
  'common.hours-abbrev': 'ч',
  'common.hours-lowercase': 'часы',
  'common.id': 'NEEDS TRANSLATION',
  'common.in-app': 'В приложении',
  'common.include-archived': 'NEEDS TRANSLATION',
  'common.information': 'Информация',
  'common.infringement': 'Нарушение',
  'common.infringements': 'Нарушения',
  'common.integrations': 'Интеграции',
  'common.invalid': 'Неверно',
  'common.invalid-date': 'Неверная дата',
  'common.invalid-url': 'Неверный URL',
  'common.issue': 'Проблема',
  'common.issue-categories': 'Категории проблем',
  'common.issued-at': 'Выпущено в',
  'common.issues': 'Проблемы',
  'common.items': 'Предметы',
  'common.kilometers': '{abbreviate, select, true {км} other {километры}}',
  'common.km': 'км',
  'common.km-per-hour-short': 'NEEDS TRANSLATION',
  'common.language': 'Язык',
  'common.last-revision': 'NEEDS TRANSLATION',
  'common.lastName': 'Фамилия',
  'common.length': 'NEEDS TRANSLATION',
  'common.length-in-cm': 'NEEDS TRANSLATION',
  'common.liters': 'Литры',
  'common.liters-short': 'л',
  'common.load-more': 'Загрузить больше',
  'common.load-previous': 'Загрузить предыдущее',
  'common.loading': 'Загрузка',
  'common.loading...': 'Загрузка...',
  'common.location': 'Местоположение',
  'common.log-in': 'Войти',
  'common.log-out': 'Выйти',
  'common.login': 'Логин',
  'common.low': 'NEEDS TRANSLATION',
  'common.make-a-choice': 'Сделайте выбор',
  'common.make-a-selection': 'Пожалуйста, сделайте выбор',
  'common.manual': 'NEEDS TRANSLATION',
  'common.map': 'NEEDS TRANSLATION',
  'common.mass-print': 'Массовая печать',
  'common.maximum': 'Максимум',
  'common.medium': 'NEEDS TRANSLATION',
  'common.menu': 'Меню',
  'common.minimum': 'Минимум',
  'common.minutes': 'Минут',
  'common.minutes-abbrev': 'мин',
  'common.module': 'Модуль',
  'common.month': 'Месяц',
  'common.monthly': 'Ежемесячно',
  'common.move-down': 'Переместить вниз',
  'common.move-up': 'Переместить вверх',
  'common.n-categories': 'NEEDS TRANSLATION',
  'common.n-hours': '{n} часов',
  'common.n-issues': '{n} проблем',
  'common.n-minutes': '{n} минут',
  'common.n-salary-code': 'NEEDS TRANSLATION',
  'common.name': 'Название',
  'common.needs-comment': 'NEEDS TRANSLATION',
  'common.new-password': 'Новый пароль',
  'common.new-password-again': 'Повторите новый пароль',
  'common.no': 'Нет',
  'common.no-active-modules': 'Нет активных модулей',
  'common.no-attachments': 'Нет вложений',
  'common.no-changes': 'Нет изменений',
  'common.no-comment': 'NEEDS TRANSLATION',
  'common.no-more-options': 'Нет больше вариантов',
  'common.no-options-to-choose-from': 'Нет вариантов для выбора',
  'common.no-results': 'Нет результатов',
  'common.none': 'Нет',
  'common.not-archived': 'Не архивировано',
  'common.not-verified-yet': 'Еще не проверно',
  'common.notification': 'Уведомление',
  'common.notifications': 'Уведомления',
  'common.notifications-alt': 'NEEDS TRANSLATION',
  'common.office': 'Офис',
  'common.ok': 'ОК',
  'common.open': 'Открыть',
  'common.open-alt': 'Открыть',
  'common.opened': 'Открыто',
  'common.order': 'Заказ',
  'common.order-by': 'NEEDS TRANSLATION',
  'common.ordinary': 'NEEDS TRANSLATION',
  'common.other': 'Другое',
  'common.other-work': 'Другие работы',
  'common.overtime': 'Сверхурочные',
  'common.overtime-mode': 'NEEDS TRANSLATION',
  'common.overtime-rate': 'NEEDS TRANSLATION',
  'common.password': 'Пароль',
  'common.pause': 'Пауза',
  'common.pdf': 'NEEDS TRANSLATION',
  'common.pdf-salary': 'NEEDS TRANSLATION',
  'common.period': 'Период',
  'common.perks': 'Льготы',
  'common.permissions': 'Права доступа',
  'common.phone': 'Телефон',
  'common.phone-number': 'Номер телефона',
  'common.phone-prefix': 'Телефонный код страны',
  'common.police': 'Полиция',
  'common.postal-code': 'Почтовый индекс',
  'common.poweroffice': 'NEEDS TRANSLATION',
  'common.previous-responses': 'NEEDS TRANSLATION',
  'common.print': 'Распечатать',
  'common.print-type': 'Распечатать {type}',
  'common.priority': 'NEEDS TRANSLATION',
  'common.privacy': 'Конфиденциальность',
  'common.project': 'NEEDS TRANSLATION',
  'common.publish': 'Опубликовать',
  'common.published': 'NEEDS TRANSLATION',
  'common.range': 'Диапазон',
  'common.reason': 'Причина',
  'common.reg-nr': 'Рег. номер',
  'common.register': 'Зарегистрировать',
  'common.remove': 'Убрать',
  'common.report': 'Отчет',
  'common.required': 'Требуется',
  'common.reset': 'Сброс',
  'common.responses': 'Ответы',
  'common.responsible': 'Ответственный',
  'common.responsible-user': 'Ответственный пользователь',
  'common.responsible-users': 'NEEDS TRANSLATION',
  'common.rest': 'Отдых',
  'common.restore': 'Восстановить',
  'common.risk-aspect': 'NEEDS TRANSLATION',
  'common.risk-aspects': 'NEEDS TRANSLATION',
  'common.role': 'Должность',
  'common.route-planner': 'NEEDS TRANSLATION',
  'common.salary': 'NEEDS TRANSLATION',
  'common.salary-code': 'NEEDS TRANSLATION',
  'common.salary-codes': 'NEEDS TRANSLATION',
  'common.salary-day': 'NEEDS TRANSLATION',
  'common.salary-mode': 'NEEDS TRANSLATION',
  'common.save': 'Сохранить',
  'common.save-and-close': 'Сохранить и закрыть',
  'common.save-changes': 'Сохранить изменения',
  'common.save-changes-to-type': 'Сохранить изменения в {type}',
  'common.save-in-user-exports': 'NEEDS TRANSLATION',
  'common.save-languages': 'Сохранить язык',
  'common.saving': 'Сохранение',
  'common.search': 'Поиск...',
  'common.seconds-abbrev': 'с',
  'common.see-all-responses': 'NEEDS TRANSLATION',
  'common.seen-by': 'Увидели',
  'common.select': 'Выбрать',
  'common.select-all': 'NEEDS TRANSLATION',
  'common.select-at-least-one-recipient': 'NEEDS TRANSLATION',
  'common.select-manual-entry': 'NEEDS TRANSLATION',
  'common.select-project': 'NEEDS TRANSLATION',
  'common.select-salary-code': 'NEEDS TRANSLATION',
  'common.select-user': 'Выбрать пользователя',
  'common.select-users': 'Выбрать пользователей',
  'common.select-week-days': 'NEEDS TRANSLATION',
  'common.send-print-to-mail': 'Отправить распечатку на почту?',
  'common.settings': 'Настройки',
  'common.severity': 'Серьезность',
  'common.shortcuts': 'Ярлыки',
  'common.show-all': 'Показать все',
  'common.show-sums': 'NEEDS TRANSLATION',
  'common.singular': 'Единичный',
  'common.sms': 'СМС',
  'common.sort-by-user-type': 'NEEDS TRANSLATION',
  'common.start': 'Начать',
  'common.start-date': 'Дата начала',
  'common.start-time': 'Время начала',
  'common.statistics': 'Статистика',
  'common.statistics-this-week': 'NEEDS TRANSLATION',
  'common.status': 'Статус',
  'common.submit': 'Отправить',
  'common.sum': 'Сумма',
  'common.sums': 'NEEDS TRANSLATION',
  'common.terminal': 'Терминал',
  'common.terminal-network': 'Терминальная сеть',
  'common.the-email': 'Электронная почта',
  'common.the-field': 'Поле',
  'common.thread': 'Тема',
  'common.time': 'Время',
  'common.time-entries': 'Табели учета',
  'common.time-entry': 'Запись времени',
  'common.time-interval': 'NEEDS TRANSLATION',
  'common.timesheets': 'Записи времени',
  'common.timesinceposted': 'Опубликовано ',
  'common.timestamp': 'NEEDS TRANSLATION',
  'common.title': 'Название',
  'common.total-work': 'NEEDS TRANSLATION',
  'common.tours': 'NEEDS TRANSLATION',
  'common.tripletex': 'NEEDS TRANSLATION',
  'common.tv-display': 'NEEDS TRANSLATION',
  'common.twentyfour-seven-office': 'NEEDS TRANSLATION',
  'common.two-weekly': 'NEEDS TRANSLATION',
  'common.type': 'Тип',
  'common.unavailable': 'Недоступно',
  'common.unknown': 'Неизвестно',
  'common.until': 'До',
  'common.untitled': 'Без названия',
  'common.update': 'Обновить',
  'common.update-entry': 'Обновить запись',
  'common.update-folder': 'Обновить папку',
  'common.update-form': 'Обновить форму',
  'common.update-form-entry': 'Обновить запись формы',
  'common.update-password': 'Обновить пароль',
  'common.update-type': 'Обновить {type}',
  'common.updated-at': 'Обновлено в',
  'common.updating': 'Обновление',
  'common.uploaded': 'Загружено',
  'common.uploaded-attachments': 'Загруженные вложения',
  'common.user': 'Пользователь',
  'common.user-document-categories': 'NEEDS TRANSLATION',
  'common.user-documents': 'Пользовательские документы',
  'common.user-type': 'Тип пользователя',
  'common.user-types': 'Типы пользователей',
  'common.username': 'Иия пользователя',
  'common.users': 'Пользователи',
  'common.usersettings': 'Настройки пользователя',
  'common.value': 'Значение',
  'common.vehicle': 'Транспортное средство',
  'common.vehicle-expandable-text': 'NEEDS TRANSLATION',
  'common.vehicle-fleet': 'Автопарк',
  'common.verified': 'Проверено',
  'common.view-comment': 'NEEDS TRANSLATION',
  'common.visma-payroll': 'NEEDS TRANSLATION',
  'common.week': 'Неделя',
  'common.week-n': 'Неделя {n}',
  'common.weekly': 'Еженедельно',
  'common.weeks':
    '{weeks, plural, =0 {Нет недель} one {# неделя} two {# недели} three {# недели} four {# недели} other {# недель}}',
  'common.weight': 'NEEDS TRANSLATION',
  'common.when-by-whom': '{whom} в {when}',
  'common.whole-day': 'NEEDS TRANSLATION',
  'common.width': 'NEEDS TRANSLATION',
  'common.width-in-cm': 'NEEDS TRANSLATION',
  'common.work': 'Работа',
  'common.work-hours': 'Рабочие часы',
  'common.year': 'Год',
  'common.yes': 'Да',
  'common.you-have-unsaved-changes': 'У Вас есть несохраненные изменения',
  'common.you-have-unsaved-changes-navigate':
    'У Вас есть несохраненные изменения. Вы уверены, что хотите покинуть страницу?',
  'companies.create-overtime-period': 'NEEDS TRANSLATION',
  'companies.create-period-addon': 'NEEDS TRANSLATION',
  'companies.edit-overtime-period': 'NEEDS TRANSLATION',
  'companies.edit-period-addon': 'NEEDS TRANSLATION',
  'companies.existing-overtime-period': 'NEEDS TRANSLATION',
  'companies.overlapping-periods-are-not-allowed': 'NEEDS TRANSLATION',
  'companies.toasts.department-created': 'NEEDS TRANSLATION',
  'companies.toasts.department-deleted': 'NEEDS TRANSLATION',
  'companies.toasts.department-updated': 'NEEDS TRANSLATION',
  'companies.toasts.holiday-allowance-created': 'NEEDS TRANSLATION',
  'companies.toasts.holiday-allowance-deleted': 'NEEDS TRANSLATION',
  'companies.toasts.holiday-allowance-updated': 'NEEDS TRANSLATION',
  'companies.toasts.salary-code-created': 'NEEDS TRANSLATION',
  'companies.toasts.salary-code-deleted': 'NEEDS TRANSLATION',
  'companies.toasts.salary-code-updated': 'NEEDS TRANSLATION',
  'countries.codes.AC': 'Остров Вознесения',
  'countries.codes.AD': 'Андорра',
  'countries.codes.AE': 'Объединенные Арабские Эмираты',
  'countries.codes.AF': 'Афганистан',
  'countries.codes.AG': 'Антигуа и Барбуда',
  'countries.codes.AI': 'Ангилья',
  'countries.codes.AL': 'Албания',
  'countries.codes.AM': 'Армения',
  'countries.codes.AO': 'Ангола',
  'countries.codes.AQ': 'Антарктида',
  'countries.codes.AR': 'Аргентина',
  'countries.codes.AS': 'Американское Самоа',
  'countries.codes.AT': 'Австрия',
  'countries.codes.AU': 'Австралия',
  'countries.codes.AW': 'Аруба',
  'countries.codes.AX': 'Аландские острова',
  'countries.codes.AZ': 'Азербайджан',
  'countries.codes.BA': 'Босния и Герцеговина',
  'countries.codes.BB': 'Барбадос',
  'countries.codes.BD': 'Бангладеш',
  'countries.codes.BE': 'Бельгия',
  'countries.codes.BF': 'Буркина-Фасо',
  'countries.codes.BG': 'Болгария',
  'countries.codes.BH': 'Бахрейн',
  'countries.codes.BI': 'Бурунди',
  'countries.codes.BJ': 'Бенин',
  'countries.codes.BL': 'Сен-Бартельми',
  'countries.codes.BM': 'Бермудские Острова',
  'countries.codes.BN': 'Бруней',
  'countries.codes.BO': 'Боливия',
  'countries.codes.BQ': 'Карибские Нидерланды',
  'countries.codes.BR': 'Бразилия',
  'countries.codes.BS': 'Багамские Острова',
  'countries.codes.BT': 'Бутан',
  'countries.codes.BV': 'Остров Буве',
  'countries.codes.BW': 'Ботсвана',
  'countries.codes.BY': 'Беларусь',
  'countries.codes.BZ': 'Белиз',
  'countries.codes.CA': 'Канада',
  'countries.codes.CC': 'Кокосовые острова (Килинг)',
  'countries.codes.CD': 'Демократическая Республика Конго',
  'countries.codes.CF': 'Центральноафриканская Республика',
  'countries.codes.CG': 'CРеспублика Конго',
  'countries.codes.CH': 'Швейцария',
  'countries.codes.CI': 'Кот-д’Ивуар',
  'countries.codes.CK': 'Острова Кука',
  'countries.codes.CL': 'Чили',
  'countries.codes.CM': 'Камерун',
  'countries.codes.CN': 'Китай',
  'countries.codes.CO': 'Колумбия',
  'countries.codes.CP': 'Клиппертон',
  'countries.codes.CR': 'Коса-Рика',
  'countries.codes.CU': 'Куба',
  'countries.codes.CV': 'Кабо-Верде',
  'countries.codes.CW': 'Кюрасао',
  'countries.codes.CX': 'Остров Рождества',
  'countries.codes.CY': 'Кипр',
  'countries.codes.CZ': 'Чехия',
  'countries.codes.DE': 'Германия',
  'countries.codes.DG': 'Диего-Гарсия',
  'countries.codes.DJ': 'Джибути',
  'countries.codes.DK': 'Дания',
  'countries.codes.DM': 'Доминика',
  'countries.codes.DO': 'Доминиканская Республика',
  'countries.codes.DZ': 'Алжир',
  'countries.codes.EA': 'Сеута и Мелилья',
  'countries.codes.EC': 'Эквадор',
  'countries.codes.EE': 'Эстония',
  'countries.codes.EG': 'Егтпет',
  'countries.codes.EH': 'Западная Сахара',
  'countries.codes.ER': 'Эритрея',
  'countries.codes.ES': 'Испания',
  'countries.codes.ET': 'Эфиопия',
  'countries.codes.EU': 'Европейский союз',
  'countries.codes.FI': 'Финляндия',
  'countries.codes.FJ': 'Фиджи',
  'countries.codes.FK': 'Фолклендские острова',
  'countries.codes.FM': 'Микронезия',
  'countries.codes.FO': 'Фарерские острова',
  'countries.codes.FR': 'Франция',
  'countries.codes.GA': 'Габон',
  'countries.codes.GB': 'Великобритания',
  'countries.codes.GD': 'Гренада',
  'countries.codes.GE': 'Грузия',
  'countries.codes.GF': 'Гвиана',
  'countries.codes.GG': 'Гернси',
  'countries.codes.GH': 'Гана',
  'countries.codes.GI': 'Гибралтар',
  'countries.codes.GL': 'Гренландия',
  'countries.codes.GM': 'Гамбия',
  'countries.codes.GN': 'Гвинея',
  'countries.codes.GP': 'Гваделупа',
  'countries.codes.GQ': 'Экваториальная Гвинея',
  'countries.codes.GR': 'Греция',
  'countries.codes.GS': 'Южная Георгия и Южные Сандвичевы Острова',
  'countries.codes.GT': 'Гватемала',
  'countries.codes.GU': 'Гуам',
  'countries.codes.GW': 'Гвинея-Бисау',
  'countries.codes.GY': 'Гайана',
  'countries.codes.HK': 'Гонконг (Сянган)',
  'countries.codes.HM': 'Остров Херд и острова Макдональд',
  'countries.codes.HN': 'Гондурас',
  'countries.codes.HR': 'Хорватия',
  'countries.codes.HT': 'Гаити',
  'countries.codes.HU': 'Венгрия',
  'countries.codes.IC': 'Канарские острова',
  'countries.codes.ID': 'Индонезия',
  'countries.codes.IE': 'Ирландия',
  'countries.codes.IL': 'Израиль',
  'countries.codes.IM': 'Остров Мэн',
  'countries.codes.IN': 'Индия',
  'countries.codes.IO': 'Британская Территория в Индийском Океане (Чагос)',
  'countries.codes.IQ': 'Ирак',
  'countries.codes.IR': 'Иран',
  'countries.codes.IS': 'Исландия',
  'countries.codes.IT': 'Италия',
  'countries.codes.JE': 'Джерси',
  'countries.codes.JM': 'Ямайка',
  'countries.codes.JO': 'Иордан',
  'countries.codes.JP': 'Япония',
  'countries.codes.KE': 'Кения',
  'countries.codes.KG': 'Кыргызстан',
  'countries.codes.KH': 'Камбоджа',
  'countries.codes.KI': 'Кирибати',
  'countries.codes.KM': 'Коморы',
  'countries.codes.KN': 'Сент-Китс и Невис',
  'countries.codes.KP': 'Северная Корея',
  'countries.codes.KR': 'Южная Корея',
  'countries.codes.KW': 'Кувейт',
  'countries.codes.KY': 'Острова Кайман',
  'countries.codes.KZ': 'Казахстан',
  'countries.codes.LA': 'Лаос',
  'countries.codes.LB': 'Ливан',
  'countries.codes.LC': 'Сент-Люсия',
  'countries.codes.LI': 'Лихтенштейн',
  'countries.codes.LK': 'Шри-Ланка',
  'countries.codes.LR': 'Либерия',
  'countries.codes.LS': 'Лесото',
  'countries.codes.LT': 'Литва',
  'countries.codes.LU': 'Люксембург',
  'countries.codes.LV': 'Латвия',
  'countries.codes.LY': 'Ливия',
  'countries.codes.MA': 'Марокко',
  'countries.codes.MC': 'Монако',
  'countries.codes.MD': 'Молдавия',
  'countries.codes.ME': 'Черногория',
  'countries.codes.MF': 'Сен-Мартен',
  'countries.codes.MG': 'Мадагаскар',
  'countries.codes.MH': 'Маршалловы Острова',
  'countries.codes.MK': 'Северная Македония',
  'countries.codes.ML': 'Мали',
  'countries.codes.MM': 'Мьянма (Бирма)',
  'countries.codes.MN': 'Монголия',
  'countries.codes.MO': 'Макао',
  'countries.codes.MP': 'NEEDS TRANSLATION',
  'countries.codes.MQ': 'NEEDS TRANSLATION',
  'countries.codes.MR': 'NEEDS TRANSLATION',
  'countries.codes.MS': 'NEEDS TRANSLATION',
  'countries.codes.MT': 'NEEDS TRANSLATION',
  'countries.codes.MU': 'NEEDS TRANSLATION',
  'countries.codes.MV': 'NEEDS TRANSLATION',
  'countries.codes.MW': 'NEEDS TRANSLATION',
  'countries.codes.MX': 'NEEDS TRANSLATION',
  'countries.codes.MY': 'NEEDS TRANSLATION',
  'countries.codes.MZ': 'NEEDS TRANSLATION',
  'countries.codes.NA': 'NEEDS TRANSLATION',
  'countries.codes.NC': 'NEEDS TRANSLATION',
  'countries.codes.NE': 'NEEDS TRANSLATION',
  'countries.codes.NF': 'NEEDS TRANSLATION',
  'countries.codes.NG': 'NEEDS TRANSLATION',
  'countries.codes.NI': 'NEEDS TRANSLATION',
  'countries.codes.NL': 'NEEDS TRANSLATION',
  'countries.codes.NO': 'NEEDS TRANSLATION',
  'countries.codes.NP': 'NEEDS TRANSLATION',
  'countries.codes.NR': 'NEEDS TRANSLATION',
  'countries.codes.NU': 'NEEDS TRANSLATION',
  'countries.codes.NZ': 'NEEDS TRANSLATION',
  'countries.codes.OM': 'NEEDS TRANSLATION',
  'countries.codes.PA': 'NEEDS TRANSLATION',
  'countries.codes.PE': 'NEEDS TRANSLATION',
  'countries.codes.PF': 'NEEDS TRANSLATION',
  'countries.codes.PG': 'NEEDS TRANSLATION',
  'countries.codes.PH': 'NEEDS TRANSLATION',
  'countries.codes.PK': 'NEEDS TRANSLATION',
  'countries.codes.PL': 'NEEDS TRANSLATION',
  'countries.codes.PM': 'NEEDS TRANSLATION',
  'countries.codes.PN': 'NEEDS TRANSLATION',
  'countries.codes.PR': 'NEEDS TRANSLATION',
  'countries.codes.PS': 'NEEDS TRANSLATION',
  'countries.codes.PT': 'NEEDS TRANSLATION',
  'countries.codes.PW': 'NEEDS TRANSLATION',
  'countries.codes.PY': 'NEEDS TRANSLATION',
  'countries.codes.QA': 'NEEDS TRANSLATION',
  'countries.codes.RE': 'NEEDS TRANSLATION',
  'countries.codes.RO': 'NEEDS TRANSLATION',
  'countries.codes.RS': 'NEEDS TRANSLATION',
  'countries.codes.RU': 'NEEDS TRANSLATION',
  'countries.codes.RW': 'NEEDS TRANSLATION',
  'countries.codes.SA': 'NEEDS TRANSLATION',
  'countries.codes.SB': 'NEEDS TRANSLATION',
  'countries.codes.SC': 'NEEDS TRANSLATION',
  'countries.codes.SD': 'NEEDS TRANSLATION',
  'countries.codes.SE': 'NEEDS TRANSLATION',
  'countries.codes.SG': 'NEEDS TRANSLATION',
  'countries.codes.SH': 'NEEDS TRANSLATION',
  'countries.codes.SI': 'NEEDS TRANSLATION',
  'countries.codes.SJ': 'NEEDS TRANSLATION',
  'countries.codes.SK': 'NEEDS TRANSLATION',
  'countries.codes.SL': 'NEEDS TRANSLATION',
  'countries.codes.SM': 'NEEDS TRANSLATION',
  'countries.codes.SN': 'NEEDS TRANSLATION',
  'countries.codes.SO': 'NEEDS TRANSLATION',
  'countries.codes.SR': 'NEEDS TRANSLATION',
  'countries.codes.SS': 'NEEDS TRANSLATION',
  'countries.codes.ST': 'NEEDS TRANSLATION',
  'countries.codes.SV': 'NEEDS TRANSLATION',
  'countries.codes.SX': 'NEEDS TRANSLATION',
  'countries.codes.SY': 'NEEDS TRANSLATION',
  'countries.codes.SZ': 'Эсватини (Свазиленд)',
  'countries.codes.TA': 'Тристан-да-Кунья',
  'countries.codes.TC': 'Теркс и Кайкос',
  'countries.codes.TD': 'Чад',
  'countries.codes.TF': 'Французские Южные и Антарктические территории',
  'countries.codes.TG': 'Того',
  'countries.codes.TH': 'Таиланд',
  'countries.codes.TJ': 'Таджикистан',
  'countries.codes.TK': 'Токелау',
  'countries.codes.TL': 'Тимор-Лешти',
  'countries.codes.TM': 'Туркменистан',
  'countries.codes.TN': 'Тунис',
  'countries.codes.TO': 'Тонга',
  'countries.codes.TR': 'Турция',
  'countries.codes.TT': 'Тринидад и Тобаго',
  'countries.codes.TV': 'Тувалу',
  'countries.codes.TW': 'Тайвань',
  'countries.codes.TZ': 'Танзания',
  'countries.codes.UA': 'Украина',
  'countries.codes.UG': 'Уганда',
  'countries.codes.UM': 'Внешние малые острова США',
  'countries.codes.UN': 'ООН',
  'countries.codes.US': 'Соединённые Штаты Америки (США)',
  'countries.codes.UY': 'Уругвай',
  'countries.codes.UZ': 'Узбекистан',
  'countries.codes.VA': 'Ватикан',
  'countries.codes.VC': 'Сент-Винсент и Гренадины',
  'countries.codes.VE': 'Венесуэла',
  'countries.codes.VG': 'Виргинские Острова (Великобритания)',
  'countries.codes.VI': 'Виргинские Острова (США)',
  'countries.codes.VN': 'Вьетнам',
  'countries.codes.VU': 'Вануату',
  'countries.codes.WF': 'Уоллис и Футуна',
  'countries.codes.WS': 'Самоа',
  'countries.codes.XK': 'Косово',
  'countries.codes.YE': 'Йемен',
  'countries.codes.YT': 'Майотта',
  'countries.codes.ZA': 'Южно-Африканская Республика (ЮАР)',
  'countries.codes.ZM': 'Замбия',
  'countries.codes.ZW': 'Зимбабве',
  'countries.european-community': 'NEEDS TRANSLATION',
  'countries.rest-of-europe': 'NEEDS TRANSLATION',
  'countries.rest-of-world': 'NEEDS TRANSLATION',
  'createUser.title': 'NEEDS TRANSLATION',
  'dashboard.enter-driver-card-number.errors.invalid-number':
    'NEEDS TRANSLATION',
  'dashboard.lastForumPosts': 'NEEDS TRANSLATION',
  'dashboard.register-hours': 'NEEDS TRANSLATION',
  'dashboard.registerHours': 'NEEDS TRANSLATION',
  'document.files-for-document': 'NEEDS TRANSLATION',
  'documentation.documentation-for-control': 'NEEDS TRANSLATION',
  'documentation.drive-and-rest-time': 'NEEDS TRANSLATION',
  'documents.add-document': 'NEEDS TRANSLATION',
  'documents.continue-to-files': 'NEEDS TRANSLATION',
  'documents.documents-of-user': 'NEEDS TRANSLATION',
  'documents.edit-file': 'NEEDS TRANSLATION',
  'documents.expiration-date': 'NEEDS TRANSLATION',
  'documents.files-for-document': 'NEEDS TRANSLATION',
  'documents.name-on-document': 'NEEDS TRANSLATION',
  'documents.no-documents': 'NEEDS TRANSLATION',
  'documents.no-files': 'NEEDS TRANSLATION',
  'documents.notification-time': 'NEEDS TRANSLATION',
  'documents.notification-time-alt': 'NEEDS TRANSLATION',
  'documents.number-id': 'NEEDS TRANSLATION',
  'documents.open-file': 'NEEDS TRANSLATION',
  'documents.prompts.delete-document': 'NEEDS TRANSLATION',
  'documents.prompts.delete-document-confirmation': 'NEEDS TRANSLATION',
  'documents.prompts.delete-file': 'NEEDS TRANSLATION',
  'documents.prompts.delete-file-title': 'NEEDS TRANSLATION',
  'documents.responsible-user': 'NEEDS TRANSLATION',
  'documents.show-files': 'NEEDS TRANSLATION',
  'documents.upload-document': 'NEEDS TRANSLATION',
  'documents.upload-file': 'NEEDS TRANSLATION',
  'documents.view-file': 'NEEDS TRANSLATION',
  'error.activities-pause-duration': 'NEEDS TRANSLATION',
  'error.address-required': 'NEEDS TRANSLATION',
  'error.archive-type': 'NEEDS TRANSLATION',
  'error.categories': 'NEEDS TRANSLATION',
  'error.city-required': 'NEEDS TRANSLATION',
  'error.company-missing-tachograph-key':
    'У компании отсутствует ключ Tachograph API. Свяжитесь с администратором для дальнейшей поддержки.',
  'error.contact-person-phone-required':
    'Требуется номер телефона контактного лица',
  'error.contact-person-required': 'Требуется имя контактного лица',
  'error.could-not-change-profile-information':
    'Не удалось изменить информацию профиля',
  'error.could-not-check-in': 'Не удалось зарегистрировать въезд',
  'error.could-not-check-out': 'Не удалось зарегистрировать отъезд',
  'error.could-not-create-type': 'Не удалось создать {type}',
  'error.could-not-delete-type': 'Не удалось удалить {type}',
  'error.could-not-edit-type': 'Не удалось отредактировать {type}',
  'error.could-not-find-data': 'Не удалось найти таблицу данных',
  'error.could-not-find-location': 'Не удалось найти местоположение',
  'error.could-not-get-absence-types': 'Не удалось получить типы отсутствий',
  'error.could-not-read-file': 'Не удалось прочитать файл',
  'error.create-issue': 'Не удалось создать проблему',
  'error.driver-card-expiration-date-not-found': 'NEEDS TRANSLATION',
  'error.driver-card-is-expired': 'NEEDS TRANSLATION',
  'error.drivercard-between-14-16': 'В поле необходимо 14-16 символов',
  'error.end-time-too-early':
    'Время окончания должно быть позже, чем время начала',
  'error.expiration-date-not-found-add-expiration-date-manually':
    'NEEDS TRANSLATION',
  'error.field-can-not-be-empty': 'Поле не может быть пустым',
  'error.field-is-required': 'Требуется {field}',
  'error.files.could-not-read': 'Не удалось прочитать файл',
  'error.filled-out-form-entry-does-not-exist':
    'Заполненная запись формы не существует.',
  'error.first-name-required': 'Требуется имя',
  'error.generic': 'Произошла ошибка',
  'error.generic-server-error':
    'Что-то пошло не так на сервере. Пожалуйста, попробуйте еще раз.',
  'error.generic-title': 'NEEDS TRANSLATION',
  'error.internal-number-required': 'Требуется внутренний номер',
  'error.invalid-birth-number':
    'Персональный идентификационный код недействителен',
  'error.invalid-file-type': 'Неверный формат файла',
  'error.invalid-phone-number': 'Неверный номер телефона',
  'error.invalid-value': 'Неверное значение',
  'error.last-name-required': 'Требуется фамилия',
  'error.missing-permission': 'У вас отсутствуют необходимые права доступа',
  'error.name-exists': 'Запись с этим названием уже существует',
  'error.need-at-least-one-user-type':
    'Требуется хотя бы один тип пользователя',
  'error.need-company-name': 'Название компании должно присутствовать',
  'error.no-first-name': 'Отсутствует имя',
  'error.not-permitted': 'Не разрешено',
  'error.not-permitted-to-view': 'У вас нет прав на просмотр',
  'error.number-must-be-positive': 'Число должно быть положительным',
  'error.phone-number-required': 'Требуется номер телефона',
  'error.postal-code-required': 'Требуется почтовый индекс',
  'error.try-again': 'NEEDS TRANSLATION',
  'error.type-already-exist': '{type} уже существует',
  'error.type-does-not-exist': '{type} не существует',
  'error.un-archive-type': 'Не удалось снова открыть {type}',
  'error.update-issue': 'Не удалось обновить проблему',
  'error.user-has-no-infringements-for-the-selected-period':
    'NEEDS TRANSLATION',
  'error.valid-email-required':
    'Требуется действительный адрес электронной почты',
  'error.wrong-password': 'Неправильный пароль',
  'error.wrong-username-or-password':
    'Неправильное имя пользователя или пароль',
  'errors.common.name-already-exists': 'Имя уже существует',
  'errors.could-not-save-changes': 'Не удалось сохранить изменения',
  'errors.defaults.does-not-exist': 'Объект не существует',
  'errors.defaults.foreign-key-constraint':
    'Объект ссылается на что-то, чего не существует',
  'errors.defaults.invalid-value': 'Предоставленное значение недействительно',
  'errors.defaults.not-permitted': 'У вашего пользователя нет прав на это',
  'errors.defaults.unique-constraint': 'Объект должен быть уникальным',
  'errors.driver-card-already-taken': 'Карточка водителя уже занята.',
  'errors.driver-card-not-found-in-external-integration': 'NEEDS TRANSLATION',
  'errors.driver-card-not-found-similar-driver-card-already-taken':
    'Карточка водителя не найдена. Подобная карточка водителя уже занята.',
  'errors.generic-error': 'Произошла ошибка',
  'errors.must-be-whole-number': 'Должно быть целым числом',
  'errors.passwords-not-equal': 'Пароли не одинаковые!',
  'errors.users.could-not-change-password': 'Не удалось изменить пароль',
  'exports.error-email-process': 'NEEDS TRANSLATION',
  'exports.error-user-salary-settings': 'NEEDS TRANSLATION',
  'exports.export-data': 'NEEDS TRANSLATION',
  'exports.month-export': 'NEEDS TRANSLATION',
  'exports.salary-export': 'NEEDS TRANSLATION',
  'exports.success-email-process': 'NEEDS TRANSLATION',
  'exports.success-user-exports': 'NEEDS TRANSLATION',
  'exports.user-exports': 'NEEDS TRANSLATION',
  'files.drop-to-upload': 'Перетащите сюда файлы для загрузки',
  'files.file-too-large':
    'Файл {name} привышает допустимый максимум в {max}MB.',
  'files.upload-files-here': 'Загрузить файлы',
  'forgotten-password.forgotten': 'Забыли пароль?',
  'forgotten-password.go-back': 'Вернуться на страницу входа',
  'forgotten-password.message':
    'Если вы забыли пароль, введите имя пользователя или адрес электронной почты. Если мы сможем найти вашу учетную запись, мы отправим вам письмо с новым паролем.',
  'forgotten-password.success':
    'Если ваша учетная запись существует, вы получите электронное письмо с инструкциями по сбросу пароля.',
  'forgotten-password.title': 'Забытый пароль',
  'form.add-field': 'Добавить поле',
  'form.all-responses-loaded': 'Все ответы загружены',
  'form.archive-form-error': 'Не удалось заархивировать форму',
  'form.archive-form-success': 'Форма отправлена в архив',
  'form.archive-prompt-text':
    'Вы уверены, что хотите заархивировать эту форму?',
  'form.archive-prompt-title': 'Архивировать форму',
  'form.create-filled-form-success': 'Форма заполнена',
  'form.create-form': 'Создать новую форму',
  'form.create-form-alt': 'Создать форму',
  'form.created-form-success': 'Новая форма успешно создана',
  'form.delete-checklist-template-prompt-text':
    'Вы уверены, что хотите удалить этот шаблон контрольного списка?',
  'form.delete-checklist-template-prompt-title':
    'Удалить шаблон контрольного списка',
  'form.delete-entry-prompt-text':
    'Вы уверены, что хотите удалить эту заполненную форму?',
  'form.delete-entry-prompt-title': 'Удалить запись формы',
  'form.delete-field-prompt-text':
    'Вы уверены, что хотите удалить это поле формы?',
  'form.delete-field-prompt-title': 'Удалить поле формы',
  'form.delete-template-prompt-text':
    'Вы уверены, что хотите удалить эту форму?',
  'form.delete-template-prompt-title': 'Удалить форму',
  'form.edit-form': 'Редактировать форму',
  'form.edit-this-form': 'Редактировать эту форму',
  'form.errors.load-responses': 'Не удалось загрузить ответы формы',
  'form.field': 'Поле',
  'form.field-type-boolean': 'Да/Нет',
  'form.field-type-date': 'Дата',
  'form.field-type-number': 'Номер',
  'form.field-type-text': 'Текст',
  'form.field-type-user': 'Пользователь',
  'form.field-type-vehicle-department': 'NEEDS TRANSLATION',
  'form.fields': 'Поля',
  'form.fill-out-this-form': 'Заполнить эту форму',
  'form.filled-out-n-times-by-user':
    '{n, plural, =0 {Вы никогда не заполняли эту форму} one {Вы заполнили эту форму один раз} =2 {Вы заполнили эту форму дважды} other {Вы заполнили эту форму # раз}}',
  'form.filled-out-n-times-by-user-including-today':
    '{n, plural, =0 {Вы никогда не заполняли эту форму} one {Вы заполнили эту форму один раз, включая сегодня} =2 {Вы заполнили эту форму дважды, включая сегодня} other Вы заполнили эту форму # раз, включая сегодня}}',
  'form.form-entry': 'Форма',
  'form.form-entry-deleted': 'NEEDS TRANSLATION',
  'form.form-fields': 'Поля формы',
  'form.form-label-name': 'Название формы',
  'form.form-settings': 'Настройки формы',
  'form.label-archive-date': 'Дата архивирования',
  'form.label-archive-max-uses':
    'Маскимальное количество использований до архивации',
  'form.label-archive-mode': 'Метод архивации',
  'form.label-field-description': 'Описание',
  'form.label-field-input-type': 'Тип ввода',
  'form.label-field-name': 'Название поля',
  'form.label-field-type': 'Тип поля',
  'form.label-field-value': 'Текст',
  'form.label-include-footer': 'Включить нижний колонтитул',
  'form.label-include-icon': 'Включить значок компании',
  'form.label-ingress': 'Вход',
  'form.label-type': 'Тип формы',
  'form.last-response-at': 'Самый последний ответ',
  'form.load-all-responses': 'Загрузить все ответы',
  'form.menu-shortcut': 'NEEDS TRANSLATION',
  'form.no-responses': 'Нет ответов',
  'form.open-overview': 'Открыть обзор',
  'form.prompts.delete-form':
    'Вы полностью уверены, что хотите удалить эту форму? Все ответы также будут удалены.',
  'form.prompts.delete-form-title': 'Удалить форму',
  'form.prompts.unverify-response':
    'Вы хотите отменить проверенный статус этого ответа?',
  'form.prompts.unverify-response-title': 'Удалить проверку',
  'form.prompts.verify-response':
    'Вы хотите присвоить проверенный статус этому ответу?',
  'form.prompts.verify-response-title': 'Проверить',
  'form.remove-field': 'Удалить поле',
  'form.responses-for-form': 'Ответы: {name}',
  'form.see-answers': 'Просмотреть ответы',
  'form.select-form': 'Выбрать форму',
  'form.showing-num-of-total-responses': 'Показано {num} из {total} ответов',
  'form.table-label-created': 'Создано',
  'form.table-label-edit': 'Редактировать',
  'form.table-label-filled-in-by': 'Заполнено',
  'form.table-label-name': 'Название',
  'form.table-label-updated': 'Обновлено в',
  'form.table-label-use-form': 'Использовать форму',
  'form.table-label-used': 'Ответы',
  'form.toasts.create-form-success': 'Форма создана',
  'form.toasts.delete-form-success': 'Форма удалена',
  'form.toasts.patch-form-success': 'Форма отредактирована',
  'form.toasts.submit-success': 'Форма отправлена',
  'form.un-archive-prompt-text':
    'Вы уверены, что хотите повторно открыть эту форму?',
  'form.un-archive-prompt-title': 'Повторно открыть форму',
  'form.update-form-success': 'Форма обновлена',
  'form.updated-form-success': 'Форма успешно обновлена',
  'form.validation.cannot-be-negative': 'NEEDS TRANSLATION',
  'form.validation.required-fields-missing':
    'Не заполнены все обязательные поля',
  'form.verified-by-at': 'Проверено {name}, в {when}',
  'formcreator.distribution-field': 'NEEDS TRANSLATION',
  'formcreator.export-form-submissions': 'NEEDS TRANSLATION',
  'formcreator.filter-field': 'NEEDS TRANSLATION',
  'formcreator.summation-field': 'NEEDS TRANSLATION',
  'forum.add-comment': 'Добавить комментарий',
  'forum.answers-number': '{num} ответов',
  'forum.archived-threads': 'Архивированые темы',
  'forum.create-new-post': 'Создать новое сообщение',
  'forum.create-thread': 'Создать новую тему',
  'forum.create-thread.send-email-error': 'Произошла ошибка при отправке писем',
  'forum.create-thread.send-sms-error': 'Произошла ошибка при отправке СМС',
  'forum.create-thread.success': 'Тема создана',
  'forum.created': 'Создано',
  'forum.edit-post': 'Редактировать сообщение',
  'forum.edit-thread': 'Редактировать тему',
  'forum.errors.content-required': 'Требуется содержание',
  'forum.errors.create-thread': 'Не удалось создать новую тему',
  'forum.errors.delete-post-failed': 'Не удалось удалить комментарий',
  'forum.errors.delete-thread-failed': 'Не удалось удалить тему',
  'forum.errors.thread-does-not-exist': 'Тема не существует',
  'forum.errors.title-required': 'Требуется название',
  'forum.last-posts': 'Последние сообщения',
  'forum.last-threads': 'Последние темы',
  'forum.new': 'Новая тема',
  'forum.new-thread': 'Новая тема',
  'forum.no-views': 'Нет просмотров',
  'forum.number-of-posts': 'Количество сообщений',
  'forum.post':
    '{choice, select, definite {Сообщение} plural {Сообщения} other {Сообщения}}',
  'forum.post-comment': 'Оставить комментарий',
  'forum.posts-error-message': 'Произошла ошибка при получении сообщений',
  'forum.prompts.delete-attachment':
    'Вы уверены, что хотите удалить это вложение?',
  'forum.prompts.delete-attachment-title': 'Удалить вложение',
  'forum.prompts.delete-comment-prompt':
    'Вы уверены, что хотите удалить этот комментарий?',
  'forum.prompts.delete-comment-prompt-title': 'Удалить комментарий',
  'forum.prompts.delete-post':
    'Вы уверены, что хотите удалить этот комментарий?',
  'forum.prompts.delete-post-title': 'Удалить комментарий',
  'forum.prompts.delete-thread': 'Вы уверены, что хотите удалить эту тему?',
  'forum.prompts.delete-thread-text':
    'Вы уверены, что хотите удалить тему {title}? Это действие не может быть отменено.',
  'forum.prompts.delete-thread-title': 'Удалить тему',
  'forum.prompts.download-attachment-text':
    'Не удается открыть это вложение, вы хотите скачать его?',
  'forum.prompts.download-attachment-title': 'Скачать вложение',
  'forum.quill-link-helper':
    'Отметьте текст, который вы хотите преобразовать в ссылку, затем нажмите кнопку ссылки',
  'forum.read-by-n-employees':
    '{n, plural, =0 {Не прочитано ни одним сотрудником} one {Прочитано # сотрудником} other {Прочитано # сотрудниками}}',
  'forum.search': 'Поиск на форуме',
  'forum.seen-at': 'Видели в',
  'forum.send-email-label': 'Отправить уведомление по электронной почте',
  'forum.send-sms-label': 'Отправить смс-уведомление {employeeNum} сотрудникам',
  'forum.sms-notification': 'СМС-уведомление',
  'forum.sticky-thread': 'Закрепленная тема',
  'forum.sticky-threads': 'Закрепленные темы',
  'forum.thread':
    '{choice, select, definite {Тема} plural {Темы} other {Тема}}',
  'forum.threads-error-message': 'Произошла ошибка при получении тем',
  'forum.timesinceposted': 'Часов назад ',
  'forum.toasts.archive-thread-error': 'Не удалось заархивировать тему',
  'forum.toasts.archive-thread-success': 'Тема была заархивирована',
  'forum.toasts.create-thread-success': 'Тема создана',
  'forum.toasts.delete-thread-error': 'Не удалось удалить тему',
  'forum.toasts.delete-thread-success': 'Тема была удалена',
  'forum.toasts.patch-post-error':
    'Что-то пошло не так при обновлении сообщения',
  'forum.toasts.patch-post-success': 'Сообщение было обновлено',
  'forum.toasts.patch-thread-error': 'Что-то пошло не так при обновлении темы',
  'forum.toasts.patch-thread-success': 'Тема обновлена',
  'forum.toasts.restore-thread-success': 'Тема восстановлена',
  'forum.unseen-only': 'Только непросмотренные',
  'forum.update-comment': 'Обновить комментарий',
  'forum.update-thread': 'Обновить тему',
  'forum.validation.thread-must-have-content': 'Тема должна иметь содержание',
  'forum.view-count-title': 'Тема была просмотрена',
  'forum.written-when': 'Написано в {when}',
  'handbook.add-entry': 'Добавить запись',
  'handbook.add-folder': 'Добавить папку',
  'handbook.add-sub-folder': 'Добавить подпапку',
  'handbook.create-entry-success': 'Запись создана',
  'handbook.create-folder-success': 'Папка создана',
  'handbook.create_entry': 'Создать новую запись',
  'handbook.dashboard-links': 'NEEDS TRANSLATION',
  'handbook.edit-folder': 'Редактировать папку',
  'handbook.entry-load-error': 'Ошибка при загрузки записи',
  'handbook.entry.delete-success': 'Запись удалена',
  'handbook.errors.delete-entry': 'Не удалось удалить запись',
  'handbook.errors.delete-folder': 'Не удалось удалить папку',
  'handbook.folder-load-error': 'Ошибка при загрузки папки',
  'handbook.folder.delete-success': 'Папка удалена',
  'handbook.new-root-folder': 'Новая корневая папка',
  'handbook.prompts.delete-entry': 'Вы уверены, что хотите удалить эту запись?',
  'handbook.prompts.delete-entry-title': 'Удалить запись',
  'handbook.prompts.delete-folder':
    'Вы уверены, что хотите удалить эту папку? Все содержимое также будет удалено.',
  'handbook.prompts.delete-folder-title': 'Удалить папку',
  'handbook.search': 'Поиск в справочнике водителя',
  'handbook.start': 'Начало',
  'handbook.toasts.create-entry-success': 'Запись справочника "{name}" создана',
  'handbook.toasts.create-folder-success': 'Папка создана',
  'handbook.toasts.delete-entry-success': 'Запись удалена',
  'handbook.toasts.delete-folder-success': 'Папка удалена',
  'handbook.toasts.patch-folder-success': 'Папка сохранена',
  'handbook.update-entry-success': 'Запись обновлена',
  'handbook.update-folder-success': 'Папка обновлена',
  'infringements.amount-of-infringements-in-categories':
    'Количество нарушений в категориях',
  'infringements.archive-infringement': 'Архивировать нарушение',
  'infringements.archived-basis': 'Заархивированые основания',
  'infringements.archived-date': 'Дата архивирования',
  'infringements.category-infringement': 'NEEDS TRANSLATION',
  'infringements.export-infringement': 'NEEDS TRANSLATION',
  'infringements.export-infringement-data':
    'Экспортировать данные о нарушениях',
  'infringements.export.all': 'NEEDS TRANSLATION',
  'infringements.legal-basis': 'Правовая основа',
  'infringements.lower-limit': 'Нижний предел',
  'infringements.minor-infringement': 'Незначительное нарушение',
  'infringements.most-serious-infringement': 'Самое серьезное нарушение',
  'infringements.my-infringements': 'Мои нарушения',
  'infringements.n-points': 'NEEDS TRANSLATION',
  'infringements.no-infringements': 'Нет нарушений',
  'infringements.restore-infringement': 'Восстановить нарушение',
  'infringements.send-email-success':
    'Нарушения отправлены на электронную почту.',
  'infringements.serious-infringement': 'Серьезное нарушение',
  'infringements.severity': 'Тяжесь',
  'infringements.total-severity': 'Общая тяжесть',
  'infringements.upper-limit': 'Верхний предел',
  'infringements.very-serious-infringement': 'Очень серьезное нарушение',
  'integrations.error-in-API-keys': 'NEEDS TRANSLATION',
  'integrations.error-in-FMS-key': 'NEEDS TRANSLATION',
  'integrations.error-in-tachograph-key': 'NEEDS TRANSLATION',
  'issue.category-responsible-user': 'Ответственный за категорий проблем',
  'issue.existing-attachments': 'Существующие вложения',
  'issues.action-log': 'NEEDS TRANSLATION',
  'issues.actual-cost': 'Фактическая стоимость',
  'issues.add-recipient-with-email': 'Добавить получателя с электронной почтой',
  'issues.archive-issue': 'Архивировать проблему',
  'issues.archived-by-user': 'NEEDS TRANSLATION',
  'issues.attachments': 'Вложения',
  'issues.category-name': 'Название категории',
  'issues.close-issue': 'Закрыть проблему',
  'issues.closed-at': 'Закрыто в',
  'issues.closed-by': 'Закрыто',
  'issues.closed-by-user': 'NEEDS TRANSLATION',
  'issues.cost-estimation': 'Ориентировочная стоимость',
  'issues.create-issue': 'Создать проблему',
  'issues.create-issue-for': 'Создать проблему для:',
  'issues.created-by-user': 'NEEDS TRANSLATION',
  'issues.damage-description': 'Что было повреждено?',
  'issues.damage-description-long':
    'Что было повреждено — Заполните как прямые, так и косвенные объекты.',
  'issues.damage-description-short': 'Поврежденные объекты',
  'issues.damage-direct-and-indirect-objects':
    'Заполните прямые и косвенные объекты',
  'issues.damage-location': 'Где это произошло?',
  'issues.delete-issue': 'Удалить проблему',
  'issues.delete-issue-error-message':
    'Вы пытаетесь удалить категорию с которой еще ассоциированы проблемы. Это невозможно.',
  'issues.describe-the-damage': 'Опишите повреждение / поврежденный объект',
  'issues.description-of-event-unwanted-event': 'NEEDS TRANSLATION',
  'issues.edit-issue': 'Редактировать проблему',
  'issues.equipment-material': 'Оборудование/материал',
  'issues.error-retrieving-users':
    'Произошла ошибка при получении пользователей',
  'issues.errors.create-issue': 'Не удалось создать проблему',
  'issues.errors.must-enter-actual-cost':
    'Необходимо ввести фактическую стоимость',
  'issues.errors.must-enter-solution': 'Требуется решение',
  'issues.errors.patch-issue': 'Не удалось обновить проблему',
  'issues.export-issue': 'NEEDS TRANSLATION',
  'issues.extend-issue-form': 'Вы хотите закрыть проблему сейчас?',
  'issues.fill-in-actual-cost': 'Заполните фактическую стоимость',
  'issues.fill-in-solution': 'Заполните окончательное решение',
  'issues.fill-in-vehicle-reg-nr':
    'Заполните регистрационный номер соответствующего транспортного средства/прицепа',
  'issues.final-solution': 'Окончательное решение',
  'issues.find-issue': 'Найти проблему',
  'issues.give-estimated-cost':
    'Предоставьте приблизительную стоимость проблемы',
  'issues.issue-categories': 'Категории проблем',
  'issues.issue-categories.errors.could-not-load-error':
    'Не удалось загрузить категории',
  'issues.issue-categories.no-categories': 'Нет категорий',
  'issues.issue-category': 'Категория проблемы',
  'issues.issue-description-about': 'О проблеме',
  'issues.issue-location': 'Местоположение проблемы',
  'issues.issue-report': 'Отчет о проблеме',
  'issues.location-description': 'Где на локации произошла проблема?',
  'issues.new-issue': 'Новая проблема',
  'issues.no-issues': 'Нет проблем',
  'issues.opened-by': 'Открыто',
  'issues.reference': '{abbreviate, select, true {Ссыл.} other {Ссылка}}',
  'issues.reopen-issue': 'Снова открыть проблему',
  'issues.reopened-by-user': 'NEEDS TRANSLATION',
  'issues.responsible': 'Ответственный',
  'issues.restore-issue': 'Восстановить проблему',
  'issues.restored-by-user': 'NEEDS TRANSLATION',
  'issues.search-for-issue': 'Поиск проблемы',
  'issues.select-recipient-from-list': 'Выберите получателя из списка',
  'issues.selected-recipients': 'Выбранные получатели',
  'issues.send-email-success': 'Отчет отправлен по электронной почте.',
  'issues.solution-suggestion-about': 'Предложения мер',
  'issues.success-creation': 'Новая проблема создана',
  'issues.success-update': 'Проблема обновлена',
  'issues.suggested-improvement': 'NEEDS TRANSLATION',
  'issues.suggested-solution': 'Предлагаемое решение',
  'issues.time-of-issue': 'Время проблемы',
  'issues.toasts.create-issue-success': 'Проблема создана',
  'issues.toasts.patch-issue-success': 'Проблема обновлена',
  'issues.treated': 'Обработано',
  'issues.untreated': 'Не обработано',
  'issues.update-actual-cost': 'Обновить фактическую стоимость',
  'issues.updated-by-user': 'NEEDS TRANSLATION',
  'issues.validation.solution-required':
    'Для закрытия этой проблемы необходимо предоставить решение',
  'issues.view-all-issues': 'Все проблемы',
  'issues.view-your-issues': 'Ваши проблемы',
  'login.email-or-phone-number': 'Электронная почта или номер телефона',
  'login.forgotten-password': 'Забыли пароль?',
  'login.remember-me': 'Запомнить меня',
  'login.subtitle': 'Добро пожаловать в',
  'modal.and-all-its-content': ' и все его содержание',
  'modal.delete-attachment': 'Вы хотите удалить "{title}" из вложений?',
  'modal.delete-attachment-title': 'Удалить вложение',
  'modal.delete-folder': 'Удалить папку',
  'modal.delete-folder-description':
    'Вы хотите удалить папку "{title}" и ее содержимое?',
  'modal.delete-folder-entry-description':
    'Вы хотите удалить запись "{title}"?',
  'modal.delete-folder-entry-title': 'Удалить запись',
  'modal.delete-folder-title': 'Удалить папку',
  'modal.delete-type': 'Вы уверены, что хотите удалить {type}?',
  'modal.delete-type-title': 'Удалить {type}',
  'modal.remove-attachment': 'Вы хотите убрать "{title}" из списка вложений?',
  'modal.remove-attachment-title': 'Убрать вложение',
  'notifications-settings.issue_closed_notification':
    'Уведомление о закрытии проблемы',
  'notifications-settings.issue_created_notification':
    'Уведомление о создании проблемы',
  'notifications-settings.new_post_created_notification':
    'Новое сообщение создано',
  'notifications-settings.new_thread_created_notification':
    'Новая тема создана',
  'notifications-settings.title': 'Настройки уведомлений',
  'notifications.add-notifications': 'Добавить уведомления?',
  'notifications.error.general': 'Что-то пошло не так при загрузке уведомлений',
  'notifications.go-to-absence': 'NEEDS TRANSLATION',
  'notifications.go-to-document': 'Перейти к документу',
  'notifications.go-to-thread': 'Перейти к теме',
  'notifications.you-have-no-notifications': 'У вас нет уведомлений',
  'password.must-contain-nonnumeric': 'NEEDS TRANSLATION',
  'password.must-contain-numeric-value': 'NEEDS TRANSLATION',
  'password.too-short': 'NEEDS TRANSLATION',
  'permissions.add': 'Создать',
  'permissions.approve': 'NEEDS TRANSLATION',
  'permissions.change': 'Изменить',
  'permissions.change_sticky': 'Изменить закрепленный',
  'permissions.company-settings-general': 'Общие',
  'permissions.forum-send-email': 'Отправить электронное письмо',
  'permissions.forum-send-sms': 'Отправить СМС',
  'permissions.no-access-to-page':
    'У вашего пользователя нет доступа к этой странице',
  'permissions.remove': 'Удалить',
  'permissions.view': 'Просмотреть',
  'permissions.view-and-change': 'Просмотреть/Изменить',
  'permissions.view-others': 'NEEDS TRANSLATION',
  'print.error-email-process':
    'Не удалось создать разпечатку для электронной почты',
  'print.export-type': 'Экспортировать {type}',
  'print.monthly-print': 'Распечатка за месяц',
  'print.salary-print': 'Распечатка зарплатной основы',
  'print.send-to-email': 'Отправить электронное письмо',
  'print.success-email-process':
    'Данные обрабатываются. Вы получите письмо по окончании обработки.',
  'quill.delete-column': 'Удалить выбранный столбец',
  'quill.delete-row': 'Удалить выбранную строку',
  'quill.delete-table': 'Удалить таблицу',
  'quill.insert-column-left': 'Вставить столбец слева',
  'quill.insert-column-right': 'Вставить столбец справа',
  'quill.insert-row-above': 'Вставить строку выше',
  'quill.insert-row-below': 'Вставить строку ниже',
  'quill.new-table': 'Новая таблица',
  'quill.table-tooltip': 'Shift + правый клик, чтобы изменить таблицу.',
  'register-user.description':
    'Поскольку вы впервые входите в систему, вам нужно будет заполнить некоторую стандартную информацию.',
  'register-user.title': 'Заполните вашу личную информацию',
  'reset-password.errors.bad-token': 'Плохой токен',
  'reset-password.errors.expired-token': 'Срок действия токена сброса истек.',
  'reset-password.go-back': 'Вернуться на страницу входа',
  'reset-password.message':
    'Выберите новый пароль. Пароль должен состоять не менее чем из 8 символов и не может состоять только из цифр.',
  'reset-password.submit': 'Сменить пароль и логин',
  'reset-password.success': 'Пароль был сброшен',
  'reset-password.title': 'Сбросить пароль',
  'route-planner.add-new-route': 'NEEDS TRANSLATION',
  'route-planner.axle-weight-restrictions': 'NEEDS TRANSLATION',
  'route-planner.create-route-plan': 'NEEDS TRANSLATION',
  'route-planner.create-route-plan-success': 'NEEDS TRANSLATION',
  'route-planner.delete-route-plan-success': 'NEEDS TRANSLATION',
  'route-planner.destination': 'NEEDS TRANSLATION',
  'route-planner.estimated-driving-duration': 'NEEDS TRANSLATION',
  'route-planner.export-route-plan': 'NEEDS TRANSLATION',
  'route-planner.extra-load': 'NEEDS TRANSLATION',
  'route-planner.form.label.total-weight-in-kg': 'NEEDS TRANSLATION',
  'route-planner.form.label.total-weight-of-vehicle': 'NEEDS TRANSLATION',
  'route-planner.general-weight-restriction': 'NEEDS TRANSLATION',
  'route-planner.include-departure-time': 'NEEDS TRANSLATION',
  'route-planner.load': 'NEEDS TRANSLATION',
  'route-planner.loading-route': 'NEEDS TRANSLATION',
  'route-planner.no-route-instructions': 'NEEDS TRANSLATION',
  'route-planner.no-routes': 'NEEDS TRANSLATION',
  'route-planner.payload': 'NEEDS TRANSLATION',
  'route-planner.payload-in-kilos': 'NEEDS TRANSLATION',
  'route-planner.route-instructions': 'NEEDS TRANSLATION',
  'route-planner.route-plan': 'NEEDS TRANSLATION',
  'route-planner.route-plan-no-route-found': 'NEEDS TRANSLATION',
  'route-planner.route-plan-task-failed': 'NEEDS TRANSLATION',
  'route-planner.route-plan-task-pending': 'NEEDS TRANSLATION',
  'route-planner.route-restrictions': 'NEEDS TRANSLATION',
  'route-planner.single-axle': 'NEEDS TRANSLATION',
  'route-planner.start-location': 'NEEDS TRANSLATION',
  'route-planner.tandem-axle': 'NEEDS TRANSLATION',
  'route-planner.tonne': 'NEEDS TRANSLATION',
  'route-planner.top-speed': 'NEEDS TRANSLATION',
  'route-planner.top-speed-in-km': 'NEEDS TRANSLATION',
  'route-planner.total-weight': 'NEEDS TRANSLATION',
  'route-planner.trailer': 'NEEDS TRANSLATION',
  'route-planner.trailer-weight-in-kilos': 'NEEDS TRANSLATION',
  'route-planner.triple-axle': 'NEEDS TRANSLATION',
  'route-planner.weight-in-tonnes': 'NEEDS TRANSLATION',
  'select-user-settings.title': 'Настройки выпадающего списка пользователя',
  'selectCompany.select': 'Выберите компанию',
  'settings.absence-responsible': 'Ответственный за отсутствие',
  'settings.add-absence-type': 'Добавить тип отсутствия',
  'settings.add-api-key': 'Добавить ключ API',
  'settings.add-department': 'NEEDS TRANSLATION',
  'settings.add-holiday-allowance': 'NEEDS TRANSLATION',
  'settings.add-issue-category': 'Добавить категорию проблем',
  'settings.add-issue-risk-aspect': 'NEEDS TRANSLATION',
  'settings.add-new-terminal': 'Добавить новый терминал',
  'settings.add-salary-code': 'NEEDS TRANSLATION',
  'settings.add-time-entry-project': 'NEEDS TRANSLATION',
  'settings.added-issue-category': 'Добавлена новая категория проблем',
  'settings.available-departments': 'NEEDS TRANSLATION',
  'settings.change-logo': 'Изменить логотип',
  'settings.change-module': 'NEEDS TRANSLATION',
  'settings.change-responsible-user': 'Изменить ответственного пользователя',
  'settings.company-logo': 'Логотип компании',
  'settings.company-settings': 'Настройки компании',
  'settings.create-checklist-template': 'Создать шаблон контрольного списка',
  'settings.create-fleet-board-integration': 'NEEDS TRANSLATION',
  'settings.create-issue-category': 'Создать категорию проблем',
  'settings.create-man-integration': 'NEEDS TRANSLATION',
  'settings.create-name-first': 'Сначала создайте название',
  'settings.create-salary-settings': 'NEEDS TRANSLATION',
  'settings.create-scania-integration': 'Создать интеграцию Scania',
  'settings.create-terminal-area': 'Создать зону терминала',
  'settings.create-user-document': 'Создать пользовательский документ',
  'settings.create-user-type': 'Создать тип пользователя',
  'settings.create-volvo-integration': 'Создать интеграцию Volvo',
  'settings.daily-salary': 'NEEDS TRANSLATION',
  'settings.daily-work-limit': 'Дневное ограничение работы (в часах)',
  'settings.daily-work-limit-short': 'Ежедневно',
  'settings.dashboard-links-preview': 'NEEDS TRANSLATION',
  'settings.day-salary-code': 'NEEDS TRANSLATION',
  'settings.default-department': 'NEEDS TRANSLATION',
  'settings.default-salary-code': 'NEEDS TRANSLATION',
  'settings.delete-overtime-period': 'NEEDS TRANSLATION',
  'settings.delete-period-addon': 'NEEDS TRANSLATION',
  'settings.delete-user-type': 'Удалить тип пользователя',
  'settings.do-you-want-to-delete-overtime-period': 'NEEDS TRANSLATION',
  'settings.do-you-want-to-delete-period-addon': 'NEEDS TRANSLATION',
  'settings.driverdata-print-for-all-drivers':
    'Распечатать все данные вождения',
  'settings.edit-absence-responsible':
    'Редактировать ответственного за отсутствие',
  'settings.edit-absence-type': 'Редактировать тип отсутствия',
  'settings.edit-checklist-template':
    'Редактировать шаблон контрольного списка',
  'settings.edit-department': 'NEEDS TRANSLATION',
  'settings.edit-dynamic-column': 'Редактировать название столбца',
  'settings.edit-fleet-board-integration-n': 'NEEDS TRANSLATION',
  'settings.edit-holiday-allowance': 'NEEDS TRANSLATION',
  'settings.edit-infringements-responsible': 'NEEDS TRANSLATION',
  'settings.edit-issue-category': 'Редактировать категорию проблем',
  'settings.edit-issue-risk-aspect': 'NEEDS TRANSLATION',
  'settings.edit-man-integration-n': 'NEEDS TRANSLATION',
  'settings.edit-responsible-user': 'NEEDS TRANSLATION',
  'settings.edit-salary-and-work-time-for-type':
    'Редактировать настройки зарплаты {type}',
  'settings.edit-salary-code': 'NEEDS TRANSLATION',
  'settings.edit-salary-settings-type':
    'Редактировать настройки зарплаты {module}',
  'settings.edit-scania-integration-n': 'Редактировать интеграцию Scania #{n}',
  'settings.edit-terminal-area': 'Редактировать зону терминала',
  'settings.edit-time-entry-project': 'NEEDS TRANSLATION',
  'settings.edit-type-settings': 'Редактировать настройки {type}',
  'settings.edit-user-document': 'Редактировать пользовательский документ',
  'settings.edit-volvo-integration-n': 'Редактировать интеграцию Volvo #{n}',
  'settings.errors.change-language': 'Не удалось изменить язык',
  'settings.errors.company-settings-generic':
    'Не удалось обновить настройки компании',
  'settings.errors.create-absence-type': 'Не удалось создать тип отсутствия',
  'settings.errors.create-checklist-template-error':
    'Ошибка при создании шаблона контрольного списка',
  'settings.errors.create-fleetboard-integration': 'NEEDS TRANSLATION',
  'settings.errors.create-issue-category':
    'Не удалось создать категорию проблем',
  'settings.errors.create-issue-risk-aspect': 'NEEDS TRANSLATION',
  'settings.errors.create-salary': 'NEEDS TRANSLATION',
  'settings.errors.create-scania-integration':
    'Не удалось создать интеграцию Scania',
  'settings.errors.create-terminal': 'Не удалось создать зону терминала',
  'settings.errors.create-time-entry-project': 'NEEDS TRANSLATION',
  'settings.errors.create-user-document':
    'Не удалось создать пользовательский документ',
  'settings.errors.create-user-document-category': 'NEEDS TRANSLATION',
  'settings.errors.create-user-type': 'Не удалось создать тип пользователя',
  'settings.errors.create-volvo-integration':
    'Не удалось создать интеграцию Volvo',
  'settings.errors.delete-absence-type': 'Не удалось удалить тип отсутствия',
  'settings.errors.delete-fleetboard-integration': 'NEEDS TRANSLATION',
  'settings.errors.delete-issue-category':
    'Не удалось удалить категорию проблем',
  'settings.errors.delete-issue-risk-aspect': 'NEEDS TRANSLATION',
  'settings.errors.delete-salary': 'NEEDS TRANSLATION',
  'settings.errors.delete-scania-integration':
    'Не удалось удалить интеграцию Scania',
  'settings.errors.delete-terminal': 'Не удалось удалить зону терминала',
  'settings.errors.delete-time-entry-project': 'NEEDS TRANSLATION',
  'settings.errors.delete-user-document':
    'Не удалось удалить пользовательский документ',
  'settings.errors.delete-user-document-category': 'NEEDS TRANSLATION',
  'settings.errors.delete-user-type': 'Не удалось удалить тип пользователя',
  'settings.errors.delete-volvo-integration':
    'Не удалось удалить интеграцию Volvo',
  'settings.errors.password-change': 'Не удалось изменить пароль',
  'settings.errors.patch-absence-type': 'Не удалось обновить тип отсутствия',
  'settings.errors.patch-checklist-template-error':
    'Ошибка при обновлении шаблона контрольного списка',
  'settings.errors.patch-fleetboard-integration': 'NEEDS TRANSLATION',
  'settings.errors.patch-issue-category':
    'Не удалось обновить категорию проблем',
  'settings.errors.patch-issue-risk-aspect': 'NEEDS TRANSLATION',
  'settings.errors.patch-salary': 'Не удалось обновить настройки зарплаты',
  'settings.errors.patch-scania-integration':
    'Не удалось обновить интеграцию Scania',
  'settings.errors.patch-terminal': 'Не удалось обновить зону терминала',
  'settings.errors.patch-time-entry-project': 'NEEDS TRANSLATION',
  'settings.errors.patch-user-document':
    'Не удалось обновить пользовательский документ',
  'settings.errors.patch-user-document-category': 'NEEDS TRANSLATION',
  'settings.errors.patch-user-type': 'Не удалось обновить тип пользователя',
  'settings.errors.patch-volvo-integration':
    'Не удалось обновить интеграцию Volvo',
  'settings.errors.update-dropdowns': 'Не удалось обновить выпадающие списки',
  'settings.errors.update-notifications':
    'Не удалось обновить настройки уведомлений',
  'settings.errors.update-user-type': 'Не удалось обновить тип пользователя',
  'settings.errors.user-type-cannot-be-deleted':
    'Этот тип пользователя нельзя удалить',
  'settings.errors.user-type-cannot-be-edited':
    'Этот тип пользователя нельзя отредактировать',
  'settings.errors.user-type-cannot-be-edited-with-name':
    'Тип пользователя "{name}" нельзя отредактировать',
  'settings.export-all-absences-for-period': 'NEEDS TRANSLATION',
  'settings.export-all-driver-activities': 'NEEDS TRANSLATION',
  'settings.export-all-issues-for-period': 'NEEDS TRANSLATION',
  'settings.export-all-terminal-entries': 'NEEDS TRANSLATION',
  'settings.export-all-timesheets': 'NEEDS TRANSLATION',
  'settings.export-driverdata-for-user': 'NEEDS TRANSLATION',
  'settings.export-reports': 'Отчеты об экспорте',
  'settings.export-tables': 'NEEDS TRANSLATION',
  'settings.export-tables.preview': 'NEEDS TRANSLATION',
  'settings.external-id-in-salary-system': 'NEEDS TRANSLATION',
  'settings.fats.max-daily-work-limit': 'NEEDS TRANSLATION',
  'settings.fats.n-weeks': 'NEEDS TRANSLATION',
  'settings.fats.period-generated-for': 'NEEDS TRANSLATION',
  'settings.fats.period-length': 'NEEDS TRANSLATION',
  'settings.fats.start-date-not-monday': 'NEEDS TRANSLATION',
  'settings.fats.start-date-too-late-to-get-valid-interval':
    'NEEDS TRANSLATION',
  'settings.first-to-last-day-in-month': 'С первого по последний день месяца',
  'settings.fixed-salary': 'NEEDS TRANSLATION',
  'settings.fleet-board-key-n': 'NEEDS TRANSLATION',
  'settings.fms-access-key': 'Ключ доступа FMS',
  'settings.fms-secret-key': 'Секретный ключ FMS',
  'settings.group-salary-on-vehicle': 'NEEDS TRANSLATION',
  'settings.has-notification': 'Имеет уведомление',
  'settings.hourly-salary': 'NEEDS TRANSLATION',
  'settings.include-absences': 'NEEDS TRANSLATION',
  'settings.info.absence-types-1':
    'В этом окне вы можете добавлять, редактировать или удалять типы отсутствия.',
  'settings.info.absence-types-2':
    'Рекомендуется проявлять осторожность при редактировании типа отсутствия, так как при этом будут изменены все существующие записи отсутствий этого типа.',
  'settings.info.checklists':
    'В этом окне вы можете добавлять, редактировать и удалять шаблоны контрольных списков.',
  'settings.info.driver-data-1':
    'Здесь вы можете распечатать все доступные данные о водительской деятельности за выбранный месячный период.',
  'settings.info.driver-data-2':
    'Доступны два набора данных. Часы - распечатывают те же значения, которые вы получаете в модуле данных о вождении для  всех водителей, или Диета - распечатывают время начала и остановки, и местоположение, но не рассчитанный рабочий период.',
  'settings.info.dropdowns':
    'Это окно позволяет выбрать, какие типы пользователей будут доступны для выбора в раскрывающихся списках пользователей на определенных страницах. Все пользователи с типом пользователя, выбранным ниже, появятся в этих раскрывающихся списках.',
  'settings.info.exports-1': 'NEEDS TRANSLATION',
  'settings.info.fats-1': 'NEEDS TRANSLATION',
  'settings.info.general':
    'В этом окне вы можете настроить основные параметры, относящиеся к компании, и загрузить логотип вашей компании.',
  'settings.info.handbook-1': 'NEEDS TRANSLATION',
  'settings.info.infringements-data-1': 'NEEDS TRANSLATION',
  'settings.info.integrations':
    'В этом окне вы можете добавлять или редактировать ключи интеграции.',
  'settings.info.issue-categories-1':
    'В этом окне вы можете добавлять, редактировать или удалять категории проблем используемые в формах проблем.',
  'settings.info.issue-categories-2':
    'Ответственный пользователь — это лицо, которое несет наибольшую ответственность за вопросы данной категории и обычно уведомляется о любых событиях, касающихся вопросов этой категории. Ответственные пользователи должны иметь тип пользователя, которому этот просмотр назначен в выпадающем списке.',
  'settings.info.issue-categories-uneditable':
    'Только ответственный пользователь может быть отредактирован.',
  'settings.info.notifications':
    'В этом окне можно выбрать способ отправки уведомлений соответствующим пользователям.',
  'settings.info.route-planner': 'NEEDS TRANSLATION',
  'settings.info.salary':
    'В этом окне вы можете редактировать настройки заработной платы.',
  'settings.info.table':
    'На этой странице вы можете настроить внешний вид таблиц компании. ',
  'settings.info.terminal-1':
    'На этом сайте вы устанавливаете, какие сети следует считать действительными терминальными сетями.',
  'settings.info.terminal-2':
    'IP-адрес использует формат ipv4, а диапазон IP-фильтра — это диапазон подсети, который, если установлено значение 24, означает, что все соединения в IP-сети считаются действительными. При желании можно изменить подсеть, чтобы обеспечить более точную фильтрацию.',
  'settings.info.time-entries-1': 'NEEDS TRANSLATION',
  'settings.info.user-documents-1':
    'В этом окне вы можете редактировать и удалять документы пользователей.',
  'settings.info.user-documents-2':
    'Ответственным за документ является пользователь, который получает уведомление об истечении срока действия документа. Срок действия документа истекает в первый день месяца, выбранного в качестве даты истечения срока действия. Уведомление будет отправлено ответственному пользователю за несколько недель до истечения срока действия, установленного в поле времени уведомления.',
  'settings.info.user-types-1':
    'На этой странице вы можете изменить типы пользователей и права доступа.',
  'settings.info.user-types-2':
    'Обратите внимание, что все изменения в разделе "Изменить" и "Удалить" предоставляют доступ для изменения и удаления ресурсов, созданными другими. Всем, у кого есть разрешение на создание ресурса, также предоставляется доступ на изменение собственных ресурсов.',
  'settings.info.vehicles': 'NEEDS TRANSLATION',
  'settings.infringements-responsible': 'NEEDS TRANSLATION',
  'settings.integration': 'Интеграция',
  'settings.integrations': 'Настройки интеграций',
  'settings.integrations-description':
    'В этом окне вы можете добавлять или редактировать ключи интеграций.',
  'settings.is-administrator': 'Является администратором',
  'settings.is-administrator-description':
    'Учетные записи администратора будут иметь все права доступа, независимо от этой таблицы.',
  'settings.last-day-of-month': 'Последний день месяца',
  'settings.man-key-n': 'NEEDS TRANSLATION',
  'settings.module-options': 'NEEDS TRANSLATION',
  'settings.my-settings': 'Мои настройки',
  'settings.n-departments': 'NEEDS TRANSLATION',
  'settings.no-checklist-templates': 'Нет шаблонов контрольных списков.',
  'settings.no-integration-keys': 'Ключи интеграций не найдены',
  'settings.no-selected-absence-responsible':
    'Никто не выбран ответственным за отсутствие',
  'settings.no-selected-infringements-responsible': 'NEEDS TRANSLATION',
  'settings.notifications.notify-driver-on-infringement': 'NEEDS TRANSLATION',
  'settings.notifications.setting.absence-created': 'NEEDS TRANSLATION',
  'settings.notifications.setting.driver-card':
    'Срок действия карточки водителя',
  'settings.notifications.setting.forum-post': 'Новый комментарий на форуме',
  'settings.notifications.setting.forum-thread': 'Новая тема на форуме',
  'settings.notifications.setting.issue-closed': 'Проблема закрыта',
  'settings.notifications.setting.issue-created': 'Проблема создана',
  'settings.notifications.setting.reset-password': 'Сброс пароля',
  'settings.notifications.setting.user-document': 'Ответственный пользователь',
  'settings.notifications.setting.user-export-ready': 'NEEDS TRANSLATION',
  'settings.notifications.setting.user-registration':
    'Регистрация пользователя',
  'settings.notifications.vehicle-document': 'NEEDS TRANSLATION',
  'settings.notifications.vehicle-expirations': 'NEEDS TRANSLATION',
  'settings.ovetime-periods': 'NEEDS TRANSLATION',
  'settings.paid-pause-threshold': 'NEEDS TRANSLATION',
  'settings.pause-duration': 'Продолжительность паузы',
  'settings.pause-duration-short': 'Перерыв',
  'settings.pause-threshold': 'Лимит работы для активации паузы',
  'settings.pause-threshold-short': 'Работа до перерыва',
  'settings.period': 'NEEDS TRANSLATION',
  'settings.period-work-limit': 'Еженедельное ограничение работы (в часах)',
  'settings.period-work-limit-short': 'Еженедельный',
  'settings.prompts.absence-type-create': 'Название нового типа отсутствия: ',
  'settings.prompts.absence-type-delete':
    'Вы уверены, что хотите удалить тип отсутствия "{name}"?',
  'settings.prompts.absence-type-delete-title': 'Удалить тип отсутствия',
  'settings.prompts.create-user-type':
    'Каким должно быть название нового типа пользователя?',
  'settings.prompts.delete': 'NEEDS TRANSLATION',
  'settings.prompts.delete-checklist-template':
    'Удалить шаблон контрольного списка',
  'settings.prompts.delete-checklist-template-description':
    'Вы хотите удалить шаблон контрольного списка?',
  'settings.prompts.delete-time-entry-project': 'NEEDS TRANSLATION',
  'settings.prompts.department-delete-title': 'NEEDS TRANSLATION',
  'settings.prompts.department-setting-delete-title': 'NEEDS TRANSLATION',
  'settings.prompts.holiday-allowance-delete-title': 'NEEDS TRANSLATION',
  'settings.prompts.integration-delete-title': 'Удалить интеграцию',
  'settings.prompts.integration-fleet-board-delete': 'NEEDS TRANSLATION',
  'settings.prompts.integration-man-delete': 'NEEDS TRANSLATION',
  'settings.prompts.integration-scania-delete':
    'Вы уверены, что хотите удалить интеграцию Scania #{n}?',
  'settings.prompts.integration-volvo-delete':
    'Вы уверены, что хотите удалить интеграцию Volvo #{n}?',
  'settings.prompts.issue-category-delete':
    'Вы уверены, что хотите удалить категорию проблем "{name}"?',
  'settings.prompts.issue-category-delete-title': 'Удалить категорию проблем',
  'settings.prompts.issue-risk-aspect-delete': 'NEEDS TRANSLATION',
  'settings.prompts.issue-risk-aspect-delete-title': 'NEEDS TRANSLATION',
  'settings.prompts.salary-code-delete-title': 'NEEDS TRANSLATION',
  'settings.prompts.salary-setting-delete-title': 'NEEDS TRANSLATION',
  'settings.prompts.terminal-delete':
    'Вы уверены, что хотите удалить зону терминала "{name}"?',
  'settings.prompts.terminal-delete-title': 'Удалить зону терминала',
  'settings.prompts.time-entry-project-delete-title': 'NEEDS TRANSLATION',
  'settings.prompts.user-document-delete':
    'Вы уверены, что хотите удалить пользовательский документ "{name}"?',
  'settings.prompts.user-document-delete-title':
    'Удалить пользовательский документ',
  'settings.prompts.user-type-delete':
    'Вы полностью уверены, что хотите удалить этот тип пользователя?',
  'settings.prompts.user-type-delete-title': 'Удалить тип пользователя',
  'settings.reciept-functionality': 'NEEDS TRANSLATION',
  'settings.refresh-colors': 'Обновить цвета',
  'settings.responsible-user': 'Ответственный пользователь',
  'settings.salary-and-work-time': 'Настройки зарплаты',
  'settings.salary-and-work-time-type': 'Зарплата',
  'settings.salary-code': 'NEEDS TRANSLATION',
  'settings.salary-code-day': 'NEEDS TRANSLATION',
  'settings.salary-code-id-field-placeholder': 'NEEDS TRANSLATION',
  'settings.salary-day-other-salary-code-warning': 'NEEDS TRANSLATION',
  'settings.salary-end-date': 'Дата окончания зарплаты',
  'settings.salary-period': 'Зарплатный период',
  'settings.salary-start-date': 'Дата начала зарплаты',
  'settings.salary-type': 'NEEDS TRANSLATION',
  'settings.save-changes': 'Сохранить изменения',
  'settings.save-settings': 'Сохранить настройки',
  'settings.scania-key': 'Ключ Scania',
  'settings.scania-key-n': 'Ключ Scania #{n}',
  'settings.select-api-key-to-add': 'Выберите ключ API для добавления',
  'settings.select-categories': 'NEEDS TRANSLATION',
  'settings.select-category': 'NEEDS TRANSLATION',
  'settings.select-crontab-setting': 'Выберите частоту генерации',
  'settings.select-module': 'NEEDS TRANSLATION',
  'settings.show-column': 'Показать столбец',
  'settings.show-dynamic-column-description':
    'Когда столбец деактивирован, он скрывается из таблицы и экспортируется из модуля.',
  'settings.show-dynamic-column-error':
    'Как минимум два столбца должны быть активны.',
  'settings.show-in-manual-time-entry-overtime': 'NEEDS TRANSLATION',
  'settings.standard-settings': 'NEEDS TRANSLATION',
  'settings.static-categories': 'Статичные категории',
  'settings.tables': 'Настройки таблицы',
  'settings.tables.add-column-description': 'NEEDS TRANSLATION',
  'settings.tables.add-export-column-description': 'NEEDS TRANSLATION',
  'settings.tables.add-placeholder': 'Выберите столбец для добавления',
  'settings.tables.preview': 'Предварительный просмотр таблицы',
  'settings.tachograph-key': 'Ключ тахографа',
  'settings.terminal-description-1':
    'На этом сайте вы устанавливаете, какие сети следует считать действительными терминальными сетями.',
  'settings.terminal-description-2':
    'IP-адрес использует формат ipv4, а диапазон IP-фильтра — это диапазон подсети, который, если установлено значение 24, означает, что все соединения в IP-сети считаются действительными. При желании можно изменить подсеть, чтобы обеспечить более точную фильтрацию.',
  'settings.time-entry-projects': 'NEEDS TRANSLATION',
  'settings.timesheets.alternative-overtime-option': 'NEEDS TRANSLATION',
  'settings.timesheets.count-pause-as-part-of-total-work': 'NEEDS TRANSLATION',
  'settings.timesheets.department-mandatory': 'NEEDS TRANSLATION',
  'settings.timesheets.merge-timesheets-with-activities': 'NEEDS TRANSLATION',
  'settings.timesheets.periodic-overtime': 'NEEDS TRANSLATION',
  'settings.timesheets.projects': 'NEEDS TRANSLATION',
  'settings.toast.create-man-integration-error': 'NEEDS TRANSLATION',
  'settings.toast.create-man-integration-success': 'NEEDS TRANSLATION',
  'settings.toast.delete-man-integration-error': 'NEEDS TRANSLATION',
  'settings.toast.delete-man-integration-success': 'NEEDS TRANSLATION',
  'settings.toast.patch-man-integration-error': 'NEEDS TRANSLATION',
  'settings.toast.patch-man-integration-success': 'NEEDS TRANSLATION',
  'settings.toasts.add-column-success': 'Столбец добавлен',
  'settings.toasts.change-language-success': 'Язык изменен',
  'settings.toasts.company-settings-updated': 'Настройки компании обновлены',
  'settings.toasts.create-absence-type-success':
    'Тип отсутствия "{name}" создан',
  'settings.toasts.create-checklist-template-success':
    'Шаблон контрольного списка добавлен.',
  'settings.toasts.create-fleetboard-integration-success': 'NEEDS TRANSLATION',
  'settings.toasts.create-issue-category-success':
    'Категория проблем "{name}" создана',
  'settings.toasts.create-issue-risk-aspect-success': 'NEEDS TRANSLATION',
  'settings.toasts.create-salary-success': 'NEEDS TRANSLATION',
  'settings.toasts.create-scania-integration-success':
    'Интеграция Scania создана',
  'settings.toasts.create-terminal-success': 'Зона терминала "{name}" создана',
  'settings.toasts.create-time-entry-project-success': 'NEEDS TRANSLATION',
  'settings.toasts.create-user-document-category-success': 'NEEDS TRANSLATION',
  'settings.toasts.create-user-document-success':
    'Пользовательский документ создан',
  'settings.toasts.create-user-type-success':
    'Тип пользователя "{name}" создан',
  'settings.toasts.create-volvo-integration-success':
    'Интеграция Volvo создана',
  'settings.toasts.delete-absence-type-success': 'Тип отсутствия удален',
  'settings.toasts.delete-fleetboard-integration-success': 'NEEDS TRANSLATION',
  'settings.toasts.delete-issue-category-success': 'Категория проблем удалена',
  'settings.toasts.delete-issue-risk-aspect-success': 'NEEDS TRANSLATION',
  'settings.toasts.delete-salary-success': 'NEEDS TRANSLATION',
  'settings.toasts.delete-scania-integration-success':
    'Интеграция Scania удалена',
  'settings.toasts.delete-terminal-success': 'Зона терминала удалена',
  'settings.toasts.delete-time-entry-project-success': 'NEEDS TRANSLATION',
  'settings.toasts.delete-user-document-category-success': 'NEEDS TRANSLATION',
  'settings.toasts.delete-user-document-success':
    'Пользовательский документ удален',
  'settings.toasts.delete-user-type-success': 'Тип пользователя удален',
  'settings.toasts.delete-volvo-integration-success':
    'Интеграция Volvo удалена',
  'settings.toasts.password-change-success': 'Ваш пароль был изменен',
  'settings.toasts.patch-absence-type-success': 'Тип отсутствия обновлен',
  'settings.toasts.patch-checklist-template-success':
    'Шаблон контрольного списка обновлен',
  'settings.toasts.patch-fleetboard-integration-success': 'NEEDS TRANSLATION',
  'settings.toasts.patch-issue-category-success': 'Категория проблем обновлена',
  'settings.toasts.patch-issue-risk-aspect-success': 'NEEDS TRANSLATION',
  'settings.toasts.patch-salary-success': 'Настройки зарплаты обновлены',
  'settings.toasts.patch-scania-integration-success':
    'Интеграция Scania обновлена',
  'settings.toasts.patch-terminal-success': 'Зона терминала обновлена',
  'settings.toasts.patch-time-entry-project-success': 'NEEDS TRANSLATION',
  'settings.toasts.patch-user-document-category-success': 'NEEDS TRANSLATION',
  'settings.toasts.patch-user-document-success':
    'Пользовательский документ обновлен',
  'settings.toasts.patch-user-type-success': 'Тип пользователя обновлен',
  'settings.toasts.patch-volvo-integration-success':
    'Интеграция Volvo обновлена',
  'settings.toasts.remove-column-success': 'Столбец удален',
  'settings.toasts.update-dropdowns-success': 'Выпадающие списки обновлены',
  'settings.toasts.update-notifications-success':
    'Настройки уведомлений обновлены',
  'settings.toasts.update-user-type-success': 'Тип пользователя обновлен',
  'settings.tv-view': 'Показать на телевизоре',
  'settings.type-of-work': 'NEEDS TRANSLATION',
  'settings.updated-type': 'Обновлен {type}',
  'settings.upload': 'Загрузить',
  'settings.upload-type': 'Загрузить {type}',
  'settings.use-as-site-logo': 'Использовать как логотип',
  'settings.use-period-addons': 'NEEDS TRANSLATION',
  'settings.use-period-overtime': 'NEEDS TRANSLATION',
  'settings.use-salary-day-payment': 'NEEDS TRANSLATION',
  'settings.use-week-overtime': 'NEEDS TRANSLATION',
  'settings.user-documents-description-title':
    'Настройки пользовательского документа',
  'settings.user-type.create-user-type': 'Создать новый тип пользователя',
  'settings.user-types-and-permissions': 'Типы пользователей и права доступа',
  'settings.valid-salary-dates':
    'Дата начала должна быть на один день позже даты окончания.',
  'settings.validation.absence-type-name-exists':
    'Этот тип отсутствия уже существует',
  'settings.validation.already-holiday-allowance-on-day': 'NEEDS TRANSLATION',
  'settings.validation.company.logo-file-size':
    'Размер файла логотипа не может превышать 128 МБ.',
  'settings.validation.company.must-have-name':
    'Компания должна иметь название',
  'settings.validation.ip-address': 'Неверный формат',
  'settings.validation.issue-category-name-exists':
    'Категория проблем с таким названием уже существует',
  'settings.validation.issue-risk-aspect-name-exists': 'NEEDS TRANSLATION',
  'settings.validation.last-admin-user-type-delete':
    'Это единственный тип пользователя с правами администратора, поэтому его нельзя удалить.',
  'settings.validation.last-admin-user-type-toggle':
    'Это единственный тип пользователя с правами администратора, поэтому его нельзя отключить.',
  'settings.validation.salary-driver-pause-length': 'Не менее 45 минут',
  'settings.validation.salary-priority-unique': 'NEEDS TRANSLATION',
  'settings.validation.terminal-range': 'Должно быть от 1 до 32',
  'settings.validation.time-entry-project-name-exists': 'NEEDS TRANSLATION',
  'settings.validation.user-document-name-exists': 'NEEDS TRANSLATION',
  'settings.validation.user-documents.must-have-name': 'Должен иметь название',
  'settings.validation.user-documents.must-set-responsible-user':
    'Необходимо установить пользователя',
  'settings.validation.user-type-name-exists':
    'Тип пользователя с таким названием уже существует',
  'settings.validation.user-type-name-exists-short': 'Название существует',
  'settings.volvo-key': 'Ключ Volvo',
  'settings.volvo-key-n': 'Ключ Volvo #{n}',
  'settings.week-overtime-delayed-overtime-warning': 'NEEDS TRANSLATION',
  'settings.week-salary-code': 'NEEDS TRANSLATION',
  'statistics.average-driver-grade': 'Средняя отценка водителя',
  'statistics.average-fuel-consumption': 'Средний расход топлива',
  'statistics.driver-grade': 'Отценка водителя',
  'statistics.kilometers-driven': 'Пробег в километрах',
  'statistics.l-10-km': 'Л/10 км',
  'statistics.l-100-km': 'Л/100 км',
  'statistics.monthly-company-statistics': 'Месячная статистика компании',
  'statistics.monthly-driver-statistics': 'Ваша месячная статистика',
  'statistics.number-of-issues': 'Кол-во проблем',
  'table.week-sum': 'Еженедельная сумма',
  'terminal.check-in': 'Регистрация въезда',
  'terminal.check-out': 'Регистрация отъезда',
  'terminal.checked-in': 'Въезд зарегистрирован',
  'terminal.checked-in-at': 'Въезд зарегистрирован в {time}',
  'terminal.checked-in-message':
    'Вы зарегистрировали въезд в {checkIn} и проработали {total} ч.',
  'terminal.checked-out': 'Отъезд зарегистрирован',
  'terminal.checked-out-at': 'Отъезд зарегистрирован в {time}',
  'terminal.edit-terminal-entry': 'Редактировать запись терминала',
  'terminal.error.delete-terminal-entry': 'Не удалось удалить запись терминала',
  'terminal.error.edit-terminal-entry':
    'Не удалось отредактировать запись терминала',
  'terminal.ip-address': 'IP-адрес',
  'terminal.ip-filter-range': 'Диапазон IP-фильтра',
  'terminal.monthly-print': 'Распечатка за месяц',
  'terminal.no-activity-period':
    'У вас нет зарегистрированной деятельности за этот период',
  'terminal.no-activity-today':
    'У вас нет зарегистрированной деятельности сегодня.',
  'terminal.not-on-network':
    'Вы должны быть в терминальной сети, чтобы зарегистрировать въезд или отъезд',
  'terminal.old-checked-in-message': 'Вы зарегистрировали въезд {checkIn}',
  'terminal.prompt.delete-terminal-entry':
    'Вы хотите удалить эту запись терминала?',
  'terminal.prompt.delete-terminal-entry-title': 'Удалить запись терминала?',
  'terminal.salary-basis': 'Основа зарплаты',
  'terminal.success.delete-terminal-entry': 'Запись терминала удалена',
  'terminal.success.edit-terminal-entry': 'Запись терминала отредактирована',
  'terminal.terminal-areas': 'Зоны терминала',
  'terminal.terminal-entries': 'Записи терминала',
  'terminal.terminal-entry': 'Запись терминала',
  'terminal.terminal-name': 'Название терминала',
  'terminal.time': 'Время',
  'terminal.user-activity': 'Деятельность для {user}',
  'terminal.user-no-activity-period':
    '{user} не имеет зарегистрированной деятельности за выбранный период',
  'terminal.your-activity': 'Ваша деятельность',
  'terminal.your-activity-today': 'Ваша деятельность сегодня',
  'thread.success-creation': 'Новая тема создана',
  'timesheets.absence-approved': 'Утверждено',
  'timesheets.absence-create-type': 'Создать новый тип отсутствия',
  'timesheets.absence-pending': 'В ожидании',
  'timesheets.absence-rejected': 'Отклонено',
  'timesheets.absence-type': 'Тип отсутствия',
  'timesheets.absence-type-name': 'Название типа отсутствия',
  'timesheets.absence-types': 'Типы отсутствия',
  'timesheets.absence-types-error-1':
    'Отсутствия не могут быть добавлены более одного раза в день для каждого сотрудника',
  'timesheets.delete-absence': 'Удалить отсутствие',
  'timesheets.delete-time-entry': 'Удалить запись времени',
  'timesheets.edit-absence': 'Редактировать отсутствие',
  'timesheets.edit-absence-status': 'Редактировать статус отсутствия',
  'timesheets.edit-time-entry': 'Редактировать запись времени',
  'timesheets.error-create-absence': 'Не удалось добавить отсутствие',
  'timesheets.error-create-timeentry':
    'Запись времени с такой же датой и временем уже существует',
  'timesheets.error-registration-absence':
    'Ошибка регистрации нового отсутствия',
  'timesheets.error-update-absence': 'Ошибка обновлении отсутствия',
  'timesheets.error-update-timeentry': 'Ошибка обновления записи времени',
  'timesheets.error.delete-absence': 'Не удалось удалить отсутствие',
  'timesheets.error.delete-time-entry': 'Не удалось удалить запись времени',
  'timesheets.error.overtime-mode-required': 'NEEDS TRANSLATION',
  'timesheets.error.patch-absence': 'Не удалось обновить отсутствие',
  'timesheets.error.patch-time-entry': 'Не удалось обновить запись времени',
  'timesheets.error.pause-required': 'NEEDS TRANSLATION',
  'timesheets.error.start-before-end':
    'Время начала должно быть раньше времени окончания',
  'timesheets.export-timesheet': 'NEEDS TRANSLATION',
  'timesheets.new-absence-request': 'Новый запрос на отсутствие',
  'timesheets.new-time-entry': 'Новая запись времени',
  'timesheets.note': 'Комментарий об отсутствии',
  'timesheets.overtime-alt': 'Сверхурочные',
  'timesheets.print-timesheet': 'Распечатать табель учета рабочего времени',
  'timesheets.prompt.delete-absence':
    'Вы уверены, что хотите удалить это отсутствие?',
  'timesheets.prompt.delete-absence-title': 'Удалить отсутствие',
  'timesheets.prompt.delete-time-entry':
    'Вы уверены, что хотите удалить эту запись времени?',
  'timesheets.prompt.delete-time-entry-title': 'Удалить запись времени',
  'timesheets.register-absence': 'Зарегистрировать отсутствие',
  'timesheets.register-timeentry': 'Зарегистрировать запись времени ',
  'timesheets.select-absence-type': 'Выбрать тип отсутствия',
  'timesheets.send-request': 'Отправить запрос',
  'timesheets.success-registration-absence':
    'Новое отсутствие зарегистрировано',
  'timesheets.success-registration-timeentry':
    'Новая запись времени зарегистрирована',
  'timesheets.success-update-absence': 'Отсутствие обновлено',
  'timesheets.success-update-timeentry': 'Запись времени обновлена',
  'timesheets.timeentry': 'Запись времени',
  'timesheets.toast.delete-absence-success': 'Отсутствие удалено',
  'timesheets.toast.delete-time-entry-success': 'Запись времени удалена',
  'timesheets.toast.patch-absence-success': 'Отсутствие обновлено',
  'timesheets.toast.patch-time-entry-success': 'Запись времени обновлена',
  'timesheets.update-absence': 'Обновить отсутствие',
  'timesheets.update-timeentry': 'Обновить запись времени',
  'timesheets.view-all-absence': 'Все отсутствия',
  'timesheets.view-all-timeentries': 'Все записи времени',
  'timesheets.view-your-absence': 'Ваши отсутствия',
  'timesheets.view-your-timeentries': 'Ваши записи времени',
  'timesheets.warning.time-entry-exist-in-daterange':
    'Запись времени с пересекающимся периодом уже существует',
  'toast.success-archive-type': '{type} успешно заархивирован',
  'toast.success-un-archive-type': '{type} повторно открыт',
  'toasts.email-sent-successfully': 'Письмо успешно отправлено',
  'toasts.error-add-type': 'Не удалось добавить {type}}',
  'toasts.error-delete-type': 'Не удалось удалить {type}',
  'toasts.error-update-type': 'Не удалось обновить {type}',
  'toasts.error.create-type': 'Не удалось создать {type}',
  'toasts.error.update-type': 'Не удалось обновить {type}',
  'toasts.error.upload-type': 'Не удалось загрузить {type}',
  'toasts.success-add-type': '{type} успешно добавлен.',
  'toasts.success-created-type': '{type} успешно создан',
  'toasts.success-delete-type': '{type} удален',
  'toasts.success-update-type': '{type} обновлен',
  'toasts.success.upload-type': '{type} загружен',
  'user-type-settings.delete-prompt-text':
    'Вы уверены, что хотите удалить тип пользователя?',
  'user-type-settings.delete-prompt-title': 'Удалить тип пользователя',
  'user-type-settings.title': 'Типы пользователей и права доступа',
  'user-type-settings.toasts.error': 'Ошибка при обновлении',
  'user-type-settings.toasts.success': 'Типы пользователей обновлены',
  'user-types.change-settings-label': 'Изменить',
  'user-types.create-settings-label': 'Создать',
  'user-types.driver-activities-self': 'Деятельность (собственная)',
  'user-types.everyones-driver-activities': 'Деятельность (все)',
  'user-types.remove-settings-label': 'Удалить',
  'user-types.view-settings-label': 'Просмотреть',
  'users.activate-user': 'Активировать пользователя',
  'users.active-driver-card': 'Активная карточка водителя',
  'users.add-driver-card': 'Добавить карточку водителя',
  'users.add-driver-card-number': 'Добавить номер карточки водителя',
  'users.add-expiry-date': 'NEEDS TRANSLATION',
  'users.add-your-driver-number': 'Добавить номер вашей карточки водителя',
  'users.awaits-configuration': 'Ожидание конфигурации',
  'users.bio': 'Биография',
  'users.birth-number': 'Персональный идентификационный код',
  'users.change-picture': 'Загрузить изображение',
  'users.change-profile-picture': 'Загрузить изображение',
  'users.contact-person-name': 'Имя контактного лица',
  'users.contact-person-phone': 'Телефон контактного лица',
  'users.create-password.description':
    'Создайте новый пароль для добавленного пользователя',
  'users.create-password.title': 'Создать новый пароль',
  'users.create-user': 'Создать пользователя',
  'users.deactivate-user': 'Деактивировать пользователя',
  'users.delete-driver-card': 'NEEDS TRANSLATION',
  'users.delete-driver-card-message': 'NEEDS TRANSLATION',
  'users.delete-existing-number':
    'Вы хотите удалить существующий номер от его текущего пользователя?',
  'users.delete-expired-driver-card': 'NEEDS TRANSLATION',
  'users.delete-expired-driver-card-message': 'NEEDS TRANSLATION',
  'users.delete-user': 'Удалить пользователя',
  'users.driver-card': 'Карточка водителя',
  'users.driver-card-number': 'Номер карточки водителя',
  'users.edit-driver-card': 'Редактировать карточку водителя',
  'users.edit-profile': 'Редактировать профиль',
  'users.edit-profile-information': 'Изменить информацию профиля',
  'users.edit-user': 'Редактировать пользователя',
  'users.errors.driver-card-expiry-format': 'Формат: ММ.ГГ',
  'users.errors.driver-card-invalid-expiry':
    'Дата истечения срока действия недействительна',
  'users.errors.phone-number-taken':
    'Номер телефона привязан к другому пользователю',
  'users.expired-cards': 'NEEDS TRANSLATION',
  'users.expired-driver-card': 'NEEDS TRANSLATION',
  'users.has-document': 'NEEDS TRANSLATION',
  'users.has-no-companies':
    'Вы не связаны ни с одной компанией. Пожалуйста, свяжитесь с вашим администратором.',
  'users.include-expired-documents': 'NEEDS TRANSLATION',
  'users.internal-number': 'Внутренний номер',
  'users.missing-external-id': 'NEEDS TRANSLATION',
  'users.my-profile': 'Мой профиль',
  'users.n-has-one-or-more-expired-driver-cards': 'NEEDS TRANSLATION',
  'users.n-users': '{n} пользователей',
  'users.new': 'Новый пользователь',
  'users.new-registration-link-send': 'NEEDS TRANSLATION',
  'users.no-more-user-types': 'Нет больше типов пользователей',
  'users.number-already-exists':
    'Пользователь {name} уже использует этот номер',
  'users.personal-info': 'Личная информация',
  'users.profile': 'Профиль',
  'users.profile-did-change': 'Информация в профиле обновлена',
  'users.profile-of-user': 'Профиль: {user}',
  'users.prompts.deactivate-self':
    'Вы уверены, что хотите деактивировать вашего пользователя?',
  'users.prompts.deactivate-self-title':
    'Деактивировать собственного пользователя',
  'users.prompts.deactivate-user':
    'Вы уверены, что хотите деактивировать этого пользователя?',
  'users.prompts.deactivate-user-title': 'Деактивировать пользователя',
  'users.search': 'Поиск пользователя',
  'users.select-user-types': 'Выбрать типы пользователей',
  'users.send-new-invitation': 'NEEDS TRANSLATION',
  'users.settings': 'Мои настройки',
  'users.show-deactivated': 'Показать деактивированных пользователей',
  'users.stopped-in-control': 'Остановлены для проверки?',
  'users.toasts.submit-driver-card-success':
    'Карточка водителя успешно добавлена',
  'users.toasts.user-activated': 'Пользователь {name} активирован',
  'users.toasts.user-deactivated': 'Пользователь {name} деактивирован',
  'users.too-weak-password': 'Слишком слабый пароль',
  'users.user-is-deactivated': 'Этот пользователь деактивирован',
  'vehicles.add-expiration-date': 'Добавить дату истечения срока действия',
  'vehicles.add-vehicle': 'Добавить транспортное средство',
  'vehicles.administrative': 'Административное',
  'vehicles.back-lifter': 'Грузоподъемный борт',
  'vehicles.brand': 'Марка',
  'vehicles.cool-freeze-heat': 'Охладить/заморозить/нагреть',
  'vehicles.crane': 'Кран',
  'vehicles.crane-type': 'Тип крана',
  'vehicles.delete-vehicle': 'Удалить транспортное средство',
  'vehicles.delete-vehicle-message':
    'Вы уверены, что хотите удалить транспортное средство/материал? ',
  'vehicles.delete-vehicle-title': 'Удалить транспортное средство/материал?',
  'vehicles.diesel-card-number': 'Номер топливной карты',
  'vehicles.edit-expiration-date': 'Редактировать срок действия',
  'vehicles.edit-vehicle': 'Редактировать транспортное средство',
  'vehicles.edit-vehicle-document':
    'Редактировать документ транспортного средства',
  'vehicles.engine': 'Двигатель',
  'vehicles.euro-class': 'ЕВРО класс',
  'vehicles.existing-vehicle': 'NEEDS TRANSLATION',
  'vehicles.expiration-dates': 'Сроки действия',
  'vehicles.extension-type': 'Тип расширения',
  'vehicles.ferry-card-number': 'Номер паромной карты',
  'vehicles.first-registered': 'Впервые зарегистрирован',
  'vehicles.fleet': 'NEEDS TRANSLATION',
  'vehicles.fleet-management': 'NEEDS TRANSLATION',
  'vehicles.fleet-overview': 'Оборудование и материалы',
  'vehicles.fridge': 'NEEDS TRANSLATION',
  'vehicles.fridge-type': 'NEEDS TRANSLATION',
  'vehicles.fuel': 'Топливо',
  'vehicles.goods-insurance-type': 'Тип страхования товара',
  'vehicles.height': 'Высота',
  'vehicles.inner-height': 'NEEDS TRANSLATION',
  'vehicles.inner-length': 'NEEDS TRANSLATION',
  'vehicles.inner-width': 'NEEDS TRANSLATION',
  'vehicles.km-per-month': 'Вагоно-километров в месяц',
  'vehicles.km-per-year': 'Вагоно-километров в год',
  'vehicles.license-number': 'Номерной знак',
  'vehicles.loading-height': 'NEEDS TRANSLATION',
  'vehicles.max-back-axle-load': 'Максимальная нагрузка на заднюю ось',
  'vehicles.max-front-axle-load': 'Максимальная нагрузка на переднюю ось',
  'vehicles.maximum-coupling-load': 'Максимальная нагрузка на муфту',
  'vehicles.maximum-payload': 'Максимальная полезная нагрузка',
  'vehicles.maximum-semi-trailer-weight': 'Максимальный вес полуприцепа',
  'vehicles.maximum-speed': 'Максимальная скорость',
  'vehicles.maximum-truck-weight': 'Максимальный вес грузовика',
  'vehicles.maxium-total-weight': 'Максимальный общий вес',
  'vehicles.meat-rack': 'NEEDS TRANSLATION',
  'vehicles.model': 'Модель',
  'vehicles.net-weight': 'Чистый вес',
  'vehicles.new-expiration-date': 'Новый срок действия',
  'vehicles.new-vehicle-document': 'Новый документ транспортного средства',
  'vehicles.next-periodic-control': 'NEEDS TRANSLATION',
  'vehicles.no-expiration-dates': 'Нет сроков действия',
  'vehicles.no-file-selected': 'Файл не выбран',
  'vehicles.owned': 'Имеет владельца',
  'vehicles.pallet-spaces': 'Количество паллетных мест',
  'vehicles.pin-code': 'PIN-код',
  'vehicles.power': 'Мощность',
  'vehicles.previous-periodic-control': 'NEEDS TRANSLATION',
  'vehicles.prompts.delete-expiration-date':
    'Удалить дату истечения срока действия',
  'vehicles.registered-country': 'Страна регистрации',
  'vehicles.service-agreement': 'Сервисное соглашение',
  'vehicles.service-and-maintenance': 'Сервис и техническое обслуживание',
  'vehicles.side-opening': 'Боковое открытие',
  'vehicles.sold-date': 'Дата продажи',
  'vehicles.stationing-location': 'Место стоянки',
  'vehicles.studded-drive-tire': 'Шипы внутренней ведущей шины',
  'vehicles.studded-front-tire': 'Шипы передней шины',
  'vehicles.tires-dimension-bogie': 'Размер шины тележки',
  'vehicles.tires-dimension-drive': 'Размер ведущей шины',
  'vehicles.tires-dimension-front': 'Размер передней шины',
  'vehicles.toasts.vehicle-updated': 'NEEDS TRANSLATION',
  'vehicles.toll-tag-number': 'Номер платной бирки',
  'vehicles.unowned': 'Не имеет владельца',
  'vehicles.usage-properties': 'Свойства использования',
  'vehicles.vbg-coupling': 'Муфта VGB',
  'vehicles.vehicle-details': 'Сведения о транспортном средстве',
  'vehicles.vehicle-technical-type': 'Технический тип транспортного средства',
  'vehicles.vehicle-type': 'Тип транспортного средства',
  'vehicles.view-vehicle-details':
    'Просмотреть сведений о транспортном средстве',
  'vehicles.vin': 'VIN',
  'vehicles.volume': 'Объем',
  'vehicles.weights': 'Весы',
}
export default ru
