import { PromptResolve, useToast } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import {
  Checkbox as BaseCheckbox,
  RegularModal,
  SortableHeader,
  Table,
} from 'components'
import React, { useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import { removeCurrentYear } from 'util/date'
import { format } from 'util/date-fns'
import { buttonUnset } from 'util/style'
import { ALL_FILLED_OUT_FORMS_QUERY } from '../queries'
import { FormTemplateShallow } from '../types'
import {
  AllFilledOutFormsQuery,
  AllFilledOutFormsQueryVariables,
  FilledOutFormOrdering,
} from '../types.graphql'
import { UserNode } from 'modules/users'

const Checkbox = styled(BaseCheckbox).attrs({
  boxSize: '24px',
  checkedIconProps: {
    size: '0.6rem',
  },
  indeterminateIconProps: {
    size: '0.6rem',
  },
})``
const FetchMore = styled.div`
  display: flex;
  justify-content: center;

  button {
    ${buttonUnset}

    color: ${props => props.theme.colors.gray4};
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      color: ${props => props.theme.colors.secondary};
      text-decoration: underline;
    }
  }
`

const PAGE_SIZE = 25

interface ResponsePromptProps {
  user: UserNode
  form: FormTemplateShallow

  onResolve: PromptResolve<string[] | null>
  canViewOthers?: boolean
}

export const ResponsePrompt: React.VFC<ResponsePromptProps> = ({
  user,
  form,
  onResolve,
  canViewOthers = false
}) => {
  const translations = useTranslate({
    responsesForForm: ['form.responses-for-form', { name: '' }],
    noResponses: 'form.no-responses',

    user: 'common.user',
    date: 'common.date',

    verifiedByAt: ['form.verified-by-at', { name: '', when: '' }],
    notVerifiedYet: 'common.not-verified-yet',

    loadMore: 'common.load-more',
    open: 'common.open',
    openOverview: 'form.open-overview',

    error: 'form.errors.load-responses',
  })

  const addToast = useToast()

  const [checkedResponses, setCheckedResponses] = useState<string[]>([])

  const { formValues: filter, formChangeHandler: handler } = useForm({
    values: {
      orderBy: '-createdAt' as FilledOutFormOrdering,
    },
  })

  const { data, loading, fetchMore } = useQuery<
    AllFilledOutFormsQuery,
    AllFilledOutFormsQueryVariables
  >(ALL_FILLED_OUT_FORMS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      first: PAGE_SIZE,

      formTemplate: form.id,
      ...filter,
    },
    onError(e) {
      // Can't use onErroAuto here cause it's technically outside of graph context
      addToast('error', translations.error)
      console.error(e)
    },
  })


  const pageInfo = data?.allFilledOutForms.pageInfo
  const responses = useMemo(
    () => data?.allFilledOutForms.edges.map(edge => edge.node).filter(node => canViewOthers || (node.filledBy.id === user.id)) ?? [],
    [canViewOthers, data?.allFilledOutForms.edges, user.id]
  )
  function handleToggle(id: string) {
    return (value: boolean) =>
      value
        ? setCheckedResponses(v => [...v, id])
        : setCheckedResponses(v => v.filter(val => val !== id))
  }
  function handleToggleAll() {
    if (checkedResponses.length === 0)
      setCheckedResponses(responses.map(response => response.id))
    else setCheckedResponses([])
  }

  function handleFetchMore() {
    if (typeof pageInfo === 'undefined' || !pageInfo.hasNextPage) return

    try {
      fetchMore({
        variables: {
          after: pageInfo.endCursor,
        },
      })
    } catch (e) {
      //@ts-ignore
      if (e.name === 'Invariant Violation') return
      throw e
    }
  }

  function handleSubmit() {
    if (!checkedResponses.length) return
    onResolve(checkedResponses)
  }

  // I'm leaving this here. Initial idea was that we may want a special view for single responses,
  // in which case this would be used.
  // const submitText =
  //   checkedResponses.length <= 1 ? translations.open : translations.openOverview
  const submitText = translations.openOverview

  return (
    <RegularModal
      loading={loading}
      title={translations.responsesForForm({
        name: form.name,
      })}
      width="800px"
      overflowY="auto"
      submitDisabled={!checkedResponses.length}
      submitText={submitText}
      onCancel={() => onResolve(null)}
      onSubmit={handleSubmit}
    >
      <Table
        noShadow
        noHeaderMargin
        noRowMargin
        noData={!loading && !responses.length}
        noDataText={translations.noResponses}
      >
        <thead>
          <tr>
            <th>
              <Checkbox
                checked={checkedResponses.length > 0}
                indeterminate={
                  checkedResponses.length > 0 &&
                  checkedResponses.length < responses.length
                }
                onChange={handleToggleAll}
              />
            </th>

            <SortableHeader
              baseValue="filledBy"
              sortValue={filter.orderBy}
              onSort={handler('orderBy')}
            >
              {translations.user}
            </SortableHeader>

            <SortableHeader
              baseValue="createdAt"
              sortValue={filter.orderBy}
              onSort={handler('orderBy')}
            >
              {translations.date}
            </SortableHeader>
          </tr>
        </thead>

        <tbody>
          {responses.map(response => {
            const date = removeCurrentYear(
              format(new Date(response.createdAt), 'PP HH:mm')
            )
            const checked = checkedResponses.includes(response.id)
            
            return (
              <tr key={response.id}>
                <td width="1px">
                  <Checkbox
                    checked={checked}
                    onChange={handleToggle(response.id)}
                  />
                </td>

                <td>
                  <span
                    role="button"
                    onClick={() => handleToggle(response.id)(!checked)}
                  >
                    {response.filledBy.fullName}
                  </span>
                </td>

                <td width="1px">{date}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>

      {!!pageInfo?.hasNextPage && (
        <FetchMore>
          <button onClick={handleFetchMore}>{translations.loadMore}</button>
        </FetchMore>
      )}
    </RegularModal>
  )
}
