import { useToast } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router'
import { IdVariable } from 'types/graphql'
import { useOnErrorAuto } from 'util/hooks'
import {
  CREATE_MANUAL_FOLDER_MUTATION,
  DELETE_MANUAL_ENTRY_MUTATION,
  DELETE_MANUAL_FOLDER_MUTATION,
  PATCH_MANUAL_FOLDER_MUTATION,
  SHIFT_HANDBOOK_ELEMENT_ORDER_MUTATION,
} from '../mutations'
import {
  CreateManualFolderMutation,
  CreateManualFolderMutationVariables,
  DeleteManualEntryMutation,
  DeleteManualFolderMutation,
  PatchManualFolderMutation,
  PatchManualFolderMutationVariables,
  ShiftHandbookElementOrderMutation,
  ShiftHandbookElementOrderMutationVariables,
} from '../types.graphql'

export function useHandbookMutations() {
  const translations = useTranslate({
    toast: {
      createFolderSuccess: 'handbook.toasts.create-folder-success',
      patchFolderSuccess: 'handbook.toasts.patch-folder-success',
      deleteFolderSuccess: 'handbook.toasts.delete-folder-success',
      deleteEntrySuccess: 'handbook.toasts.delete-entry-success',
    },
    error: {
      generic: 'error.generic-server-error',
      couldNotDeleteFolder: 'handbook.errors.delete-folder',
      couldNotDeleteEntry: 'handbook.errors.delete-entry',
    },
  })

  const addToast = useToast()
  const history = useHistory()
  const onErrorAuto = useOnErrorAuto()

  const [shiftElement] = useMutation<
    ShiftHandbookElementOrderMutation,
    ShiftHandbookElementOrderMutationVariables
  >(SHIFT_HANDBOOK_ELEMENT_ORDER_MUTATION, {
    refetchQueries: ['AllManualFolders', 'ManualFolderContents'],
    onError: onErrorAuto(),
  })
  const [createFolder] = useMutation<
    CreateManualFolderMutation,
    CreateManualFolderMutationVariables
  >(CREATE_MANUAL_FOLDER_MUTATION, {
    onCompleted(data) {
      if (!data) {
        addToast('error', translations.error.generic)
        return
      }

      addToast('success', translations.toast.createFolderSuccess)
      history.push('/handbook')
    },
    onError: onErrorAuto(),
  })
  const [patchFolder] = useMutation<
    PatchManualFolderMutation,
    PatchManualFolderMutationVariables
  >(PATCH_MANUAL_FOLDER_MUTATION, {
    onCompleted(data) {
      if (!data) {
        addToast('error', translations.error.generic)
        return
      }

      addToast('success', translations.toast.patchFolderSuccess)
      history.push('/handbook')
    },
    onError: onErrorAuto(),
  })
  const [deleteFolder] = useMutation<DeleteManualFolderMutation, IdVariable>(
    DELETE_MANUAL_FOLDER_MUTATION,
    {
      onCompleted(data) {
        if (!data || !data.deleteManualFolder.found) {
          addToast('error', translations.error.couldNotDeleteFolder)
          return
        }
        addToast('success', translations.toast.deleteFolderSuccess)
      },
      onError: onErrorAuto(),
    }
  )
  const [deleteEntry] = useMutation<DeleteManualEntryMutation, IdVariable>(
    DELETE_MANUAL_ENTRY_MUTATION,
    {
      onCompleted(data) {
        if (!data || !data.deleteManualEntry.found) {
          addToast('error', translations.error.couldNotDeleteFolder)
          return
        }
        addToast('success', translations.toast.deleteFolderSuccess)
      },
      onError: onErrorAuto(),
    }
  )

  return {
    shift: shiftElement,
    folder: {
      create: createFolder,
      patch: patchFolder,
      delete: deleteFolder,
    },
    entry: {
      delete: deleteEntry,
    },
  }
}
