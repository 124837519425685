import styled from 'styled-components'

const Title = styled.h1<ModuleTitleProps>`
  ${props => props.theme.media.mobile} {
    margin: 0 0 1rem 6px;
    font-size: 1.4rem;
  }
  ${props => props.inlineHeader && 'margin: 0 0 0 0.5rem;'}

  > span {
    font-weight: 600;
    font-size: 1.5rem;
  }
`

interface ModuleTitleProps {
  /**
   * Determines if the styling should differ based on the title being a part
   * of a controls header.
   */
  inlineHeader?: boolean
}

export const ModuleTitle: React.FC<ModuleTitleProps> = ({
  inlineHeader,
  children,
}) => {
  return <Title inlineHeader={inlineHeader}>{children}</Title>
}
