import { gql } from '@apollo/client'

export const PATCH_INFRINGEMENT_MUTATION = gql`
  mutation PatchInfringement($id: ID!, $input: PatchInfringementInput!) {
    patchInfringement(id: $id, input: $input) {
      infringement {
        id
        archivedAt
      }
    }
  }
`
