import { useToast } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router'
import { IdVariable } from 'types/graphql'
import { useOnErrorAuto } from 'util/hooks'
import {
  CREATE_FORM_TEMPLATE_MUTATION,
  DELETE_FORM_TEMPLATE_MUTATION,
  PATCH_FORM_TEMPLATE_MUTATION,
} from './mutations'
import {
  CreateFormTemplateMutation,
  CreateFormTemplateMutationVariables,
  DeleteFormTemplateMutation,
  PatchFormTemplateMutation,
  PatchFormTemplateMutationVariables,
} from './types.graphql'

export function useFormTemplateMutations() {
  const translations = useTranslate({
    success: {
      create: 'form.toasts.create-form-success',
      patch: 'form.toasts.patch-form-success',
      delete: 'form.toasts.delete-form-success',
    },
  })
  const addToast = useToast()
  const history = useHistory()
  const onErrorAuto = useOnErrorAuto()

  const [createFormTemplate, { loading: createLoading }] = useMutation<
    CreateFormTemplateMutation,
    CreateFormTemplateMutationVariables
  >(CREATE_FORM_TEMPLATE_MUTATION, {
    refetchQueries: ['AllFormTemplates', 'AllFormTemplatesShallow'],
    onCompleted() {
      addToast('success', translations.success.create)
      history.push('/formcreator', { __useNavigateConfirmBypass: true })
    },
    onError: onErrorAuto(),
  })

  const [patchFormTemplate, { loading: patchLoading }] = useMutation<
    PatchFormTemplateMutation,
    PatchFormTemplateMutationVariables
  >(PATCH_FORM_TEMPLATE_MUTATION, {
    refetchQueries: ['AllFormTemplates', 'AllFormTemplatesShallow'],
    onCompleted() {
      addToast('success', translations.success.patch)
      history.push('/formcreator', { __useNavigateConfirmBypass: true })
    },
    onError: onErrorAuto(),
  })

  const [deleteFormTemplate, { loading: deleteLoading }] = useMutation<
    DeleteFormTemplateMutation,
    IdVariable
  >(DELETE_FORM_TEMPLATE_MUTATION, {
    refetchQueries: ['AllFormTemplates', 'AllFormTemplatesShallow'],
    onCompleted() {
      addToast('success', translations.success.delete)
      history.push('/formcreator', { __useNavigateConfirmBypass: true })
    },
    onError: onErrorAuto(),
  })

  return {
    create: createFormTemplate,
    patch: patchFormTemplate,
    delete: deleteFormTemplate,
    loading: createLoading || patchLoading || deleteLoading,
  }
}
