import { Icon as BaseIcon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { TableMenuItem } from 'components'
import { PopupMenu } from 'components/PopupMenu'
import React from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { useAdmin, usePermissions } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { HandbookElementType } from '../types'

const StyledBranchMenu = styled.div`
  position: absolute;
  top: 0;
  right: -34px;

  display: none;
  justify-content: space-between;
  align-items: center;
  width: 74px;
  height: 100%;
`
const Arrows = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  height: 100%;
  gap: 4px;
`
const Arrow = styled(BaseIcon).attrs({
  type: 'regular',
  size: '1.4rem',
  color: 'gray7',
  hoverColor: 'secondary',
  cursor: 'pointer',
})`
  &.arrow-up {
    grid-row: 1;
    align-self: end;
  }
  &.arrow-down {
    grid-row: 2;
  }
`

interface BranchMenuProps {
  id: string
  slug: string
  folderSlug?: string
  type: HandbookElementType

  noUpArrow: boolean
  noDownArrow: boolean

  onAddFolder?: () => void
  onEditFolder?: () => void
  onShift: (id: string, up: boolean) => void
  onDelete: (id: string, type: HandbookElementType) => void
}
export const BranchMenu: React.VFC<BranchMenuProps> = ({
  id,
  slug,
  folderSlug,
  type,

  noUpArrow,
  noDownArrow,

  onAddFolder,
  onEditFolder,
  onShift,
  onDelete,
}) => {
  const translations = useTranslate({
    addFolder: 'handbook.add-sub-folder',
    addEntry: 'handbook.add-entry',
    edit: 'common.edit',
    delete: 'common.delete-alt',
  })

  const history = useHistory()
  const admin = useAdmin()
  const { hasPermissions } = usePermissions()

  const canAddFolder =
    admin || hasPermissions(PERMISSIONS.handbook.add.manualfolder)
  const canAddEntry =
    admin || hasPermissions(PERMISSIONS.handbook.add.manualentry)
  const canEdit =
    admin ||
    hasPermissions(
      type === 'folder'
        ? PERMISSIONS.handbook.change.manualfolder
        : PERMISSIONS.handbook.change.manualentry
    )
  const canDelete =
    admin ||
    hasPermissions(
      type === 'folder'
        ? PERMISSIONS.handbook.delete.manualfolder
        : PERMISSIONS.handbook.delete.manualentry
    )

  function handleArrow(up: boolean) {
    return (evt: React.MouseEvent<HTMLElement>) => {
      evt.stopPropagation()

      onShift(id, up)
    }
  }

  const items: TableMenuItem<string>[] = [
    {
      label: translations.addFolder,
      data: id,
      hide: type !== 'folder' || !canAddFolder,
      onClick: () => onAddFolder?.(),
    },
    {
      label: translations.addEntry,
      data: id,
      hide: type !== 'folder' || !canAddEntry,
      onClick: () => history.push(`/handbook/create/${id}`),
    },
    {
      label: translations.edit,
      data: id,
      hide: !canEdit,
      onClick: () => {
        if (type === 'entry' && !!folderSlug)
          history.push(`/handbook/${folderSlug}/${slug}`, { edit: true })
        else onEditFolder?.()
      },
    },
    {
      label: translations.delete,
      data: id,
      hide: !canDelete,
      onClick: () => onDelete(id, type),
    },
  ]
  const showMenu = items.some(item => !item.hide)

  if (!showMenu && !canEdit) return null

  return (
    <StyledBranchMenu
      className="branch-menu"
      onClick={evt => evt.stopPropagation()}
    >
      {showMenu && <PopupMenu items={items} padding="12px" />}
      {canEdit && (
        <Arrows>
          {!noUpArrow && (
            <Arrow
              className="arrow-up"
              icon="chevron-up"
              margin="0 0 4px 0"
              onClick={handleArrow(true)}
            />
          )}
          {!noDownArrow && (
            <Arrow
              className="arrow-down"
              icon="chevron-down"
              margin="4px 0 0 0"
              onClick={handleArrow(false)}
            />
          )}
        </Arrows>
      )}
    </StyledBranchMenu>
  )
}
