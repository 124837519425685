import { UnsavedChanges } from 'components'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { NotificationSettingsTable } from '..'
import {
  InfoBox,
  Section,
  SectionHeader,
  SettingsWrapper,
} from '../components/common'
import { useNotificationSettings } from '../util'

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  h2 {
    margin-bottom: 0;
  }
`

interface NotificationSettingsProps {}

export const NotificationSettings: React.VFC<NotificationSettingsProps> =
  () => {
    const notificationSettings = useNotificationSettings()

    return (
      <SettingsWrapper grid={{ flow: 'row' }}>
        <InfoBox initCollapsed={isMobileOnly}>
          {notificationSettings.translations.info}
        </InfoBox>

        <Section>
          <Header>
            <SectionHeader loading={notificationSettings.loading}>
              {notificationSettings.translations.notifications}
            </SectionHeader>
          </Header>

          {!!notificationSettings.data && (
            <NotificationSettingsTable
              company={notificationSettings.data.currentCompany}
              onChange={notificationSettings.onChangeCallback}
            />
          )}
        </Section>

        <UnsavedChanges
          show={notificationSettings.showUnsavedChangesPopup}
          message={notificationSettings.translations.prompt.message}
          onSave={notificationSettings.handleSave}
        />
      </SettingsWrapper>
    )
  }
