import { Loader } from '@googlemaps/js-api-loader'
import { useEffect, useState } from 'react'
import { GOOGLE_API_KEY } from 'util/env'

const googleLoader = new Loader({
  apiKey: GOOGLE_API_KEY,
  libraries: ['places'],
})

export function useGoogleLoader() {
  const [scriptLoaded, setScriptLoaded] = useState(false)

  useEffect(() => {
    if ((window as any).google) {
      // if window.google object is already available just use it
      setScriptLoaded(true)
      return
    }
    googleLoader.load().then(() => setScriptLoaded(true))
  }, [])

  return {
    scriptLoaded,
  }
}
