import { PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Input, RegularModal, Toggle } from 'components'
import React from 'react'
import styled from 'styled-components'
import { Field, Form } from '..'

export interface DynamicColumnForm {
  name: string
  displayName: string
  enabled: boolean
}

interface EditDynamicColumnModalProps {
  column: DynamicColumnForm
  disableToggle: boolean
  onSubmit: PromptResolve<DynamicColumnForm | null>
}

const DescriptionLabel = styled.label`
  color: ${props => props.theme.colors.gray3} !important;
`
const ErrorLabel = styled.div`
  color: ${props => props.theme.colors.red};
  text-align: right;
  font-size: 0.8rem;
  margin-bottom: 6px;
`

export const EditDynamicColumnModal: React.VFC<EditDynamicColumnModalProps> = ({
  column,
  disableToggle,
  onSubmit,
}) => {
  const translations = useTranslate({
    editColumn: 'settings.edit-dynamic-column',
    name: 'common.name',
    enabled: 'common.enabled',
    disabled: 'common.disabled',
    showColumn: 'settings.show-column',
    showColumnDescription: 'settings.show-dynamic-column-description',
    showColumnError: 'settings.show-dynamic-column-error',
    update: 'common.update',

    validation: {
      required: 'common.required',
    },
  })
  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formChangeHandler: handler,
    submitHandler: submit,
  } = useForm<DynamicColumnForm>({
    values: column ?? {
      displayName: '',
      enabled: true,
    },
    validators: {
      displayName: val => (!!val ? null : translations.validation.required),
    },
    config: {
      initAsInvalid: false,
    },
  })

  return (
    <RegularModal
      title={translations.editColumn}
      width="400px"
      submitText={translations.update}
      submitDisabled={!formValid}
      onCancel={() => onSubmit(null)}
      onSubmit={submit(onSubmit)}
    >
      <Form>
        <Field>
          <label>{translations.name}</label>
          <Input
            value={form.displayName}
            error={errors.displayName}
            autoFocus
            fullWidth
            onChange={handler('displayName')}
          />
        </Field>

        <Field alignLeft>
          <label>{translations.showColumn}</label>
          <DescriptionLabel>
            {translations.showColumnDescription}
          </DescriptionLabel>
          {disableToggle && (
            <ErrorLabel>{translations.showColumnError}</ErrorLabel>
          )}
          <Toggle
            value={form.enabled}
            thumbBackground={form.enabled ? 'secondary' : 'gray8'}
            disabled={disableToggle}
            offIconProps={{
              icon: 'eye-slash',
              size: '20px',
            }}
            onIconProps={{
              icon: 'eye',
              size: '20px',
              color: form.enabled ? 'gray11' : 'gray5',
            }}
            onChange={handler('enabled')}
          />
        </Field>
      </Form>
    </RegularModal>
  )
}
