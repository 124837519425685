import { Icon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { CenteredLoader, TableMenu, TableMenuItem } from 'components'
import { Card } from 'components/Card'
import { Vehicles } from 'components/Table/cells'
import { IssueNodeForAllIssues } from 'modules/issues/types.graphl'
import React from 'react'
import styled from 'styled-components'
import { removeCurrentYear } from 'util/date'
import { format, formatDistanceToNow } from 'util/date-fns'
import { safeParsePhoneNumber } from 'util/parsing'
import {
  IssueCategory as BaseCategory,
  IssueStatus as BaseStatus,
} from './components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > div + div {
    margin-top: 1rem;
  }
`
const Issue = styled(Card)`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'user     status   menu'
    'category category category'
    'date     date     date'
    'vehicle  vehicle  vehicle';
  gap: 6px;

  h3 {
    grid-area: user;
    margin: 0;
  }
  div.menu  {
    grid-area: menu;
    margin-left: 1rem;
  }
`
const Status = styled(BaseStatus)`
  grid-area: status;
  align-self: center;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin: 0;
  margin-top: -4px;

  font-weight: 500;
  font-size: 0.8rem;

  &::before {
    margin-left: 0.8ch;
    margin-right: 0;
    margin-bottom: 2px;
  }
`
const Category = styled(BaseCategory)`
  grid-area: category;
  margin: 0 0 6px -6px;

  div {
    padding: 6px 12px 3px;
    font-size: 0.8rem;
  }
`
const DateField = styled.div`
  grid-area: date;

  div {
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 0.8rem;
  }
  span {
    font-size: 0.7em;
    color: ${props => props.theme.colors.gray6};
  }
`
const VehicleAndReference = styled.div`
  grid-area: vehicle;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;

  span.reference {
    em {
      margin-right: 0.5ch;
      font-style: normal;
      color: ${props => props.theme.colors.gray5};
    }
  }
`

interface IssuesCardsProps {
  issues: IssueNodeForAllIssues[]
  queryLoading: boolean
  issueLoading: string | null

  createMenuItems: (
    issue: IssueNodeForAllIssues
  ) => TableMenuItem<IssueNodeForAllIssues>[]

  onLoadMore: () => void
}

export const IssuesCards: React.VFC<IssuesCardsProps> = ({
  issues,
  queryLoading,
  issueLoading,

  createMenuItems,
}) => {
  const translations = useTranslate({
    open: 'common.open-alt',
    archived: 'common.archived',
    closed: 'common.closed',

    ref: ['issues.reference', { abbreviate: true }, val => val.toLowerCase()],

    editIssue: 'issues.edit-issue',
    closeIssue: 'issues.close-issue',
    reopenIssue: 'issues.reopen-issue',
    archiveIssue: 'issues.archive-issue',
    restoreIssue: 'issues.restore-issue',
  })

  return (
    <Wrapper>
      {queryLoading && <CenteredLoader marginTop="1rem" marginBottom="1rem" />}

      {issues.map(issue => {
        const date = new Date(issue.timeOfIssue)
        const dateString = removeCurrentYear(format(date, 'PPP'))
        const timeAgo = formatDistanceToNow(date, { addSuffix: true })
        const menuItems = createMenuItems(issue)
        const phone = safeParsePhoneNumber(issue.user.phoneNumber)

        return (
          <Issue key={issue.id}>
            <h3>
              {issue.user.fullName}

              {!!issue.user.phoneNumber && (
                <a href={`tel:${issue.user.phoneNumber}`}>
                  <Icon
                    icon="phone"
                    type="solid"
                    role="link"
                    title={phone}
                    color="secondary"
                    margin="0 0 0 6px"
                  />
                </a>
              )}
            </h3>

            <Status
              closed={issue.closed}
              archived={issue.archived}
              openText={translations.open}
              archivedText={translations.archived}
              closedText={translations.closed}
            />

            <div className="menu">
              <TableMenu
                items={menuItems}
                loading={issueLoading === issue.id}
                loaderSize={18}
                loaderThickness={2}
              />
            </div>

            <Category category={issue.category} />

            <DateField>
              <div>{dateString}</div>
              <span>{timeAgo}</span>
            </DateField>

            <VehicleAndReference>
              <div>
                <Vehicles plates={[issue.vehiclePlateNumber ?? '']} />
              </div>
              <span className="reference">
                <em>{translations.ref({ abbreviate: true })}:</em>
                {issue.referenceId}
              </span>
            </VehicleAndReference>
          </Issue>
        )
      })}
    </Wrapper>
  )
}
