import { useMutation, useQuery } from '@apollo/client'
import { usePrompt } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import profilePicturePlaceholder from 'assets/images/profile-picture-placeholder.svg'
import {
  SortableHeader,
  Table as BaseTable,
  TableMenu,
  TableMenuItem,
} from 'components/Table'
import { formatISO } from 'date-fns'
import { Area, Input, InputArea, ProfileCard } from 'modules/users/UserProfile'
import { PictureArea } from 'modules/users/UserProfile/MainInfo'
import { useCallback, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useParams } from 'react-router'
import styled, { useTheme } from 'styled-components'
import { IdVariable } from 'types/graphql'
import {
  useAdmin,
  useBreadcrumbs,
  useLoadingPlaceholder,
  useOnErrorAuto,
  usePermissions,
  useUser,
} from 'util/hooks'
import { safeParsePhoneNumber } from 'util/parsing'
import { PERMISSIONS } from 'util/permissions'
import {
  ArchivedInfringementForm,
  PATCH_INFRINGEMENT_MUTATION,
  SeverityCategory,
  USER_INFRINGEMENTS,
} from '..'
import { ArchiveInfringementPrompt } from '../components/table/ArchiveInfringementModal'
import {
  CenteredTableHeader,
  CenteredTextCell,
  DateCell,
  SeverityCell,
} from '../components/table/components'
import { InfringementsCards } from '../components/table/InfringementsCards'
import { InfringementSeverities } from '../consts'
import {
  InfringementNodeForAllInfringements,
  PatchInfringementMutation,
  PatchInfringementMutationVariables,
  UserInfringementsQuery,
  UserInfringementsQueryVariables,
} from '../types.graphl'

const TotalCountTable = styled(BaseTable)`
  grid-area: total;
`

const Table = styled(BaseTable)`
  grid-area: table;
`

const Row = styled.tr`
  background: inherit;
`

const Wrapper = styled.div`
  ${props => props.theme.layout.default};
  display: grid;
  grid-template-columns: 2fr auto 1fr;
  grid-template-areas:
    'header header header'
    'main main total'
    'table table table';
  gap: 1.5rem;

  header {
    grid-area: header;

    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin: 0;
      color: ${props => props.theme.colors.gray4};

      span {
        color: ${props => props.theme.colors.text.dark};
      }
    }
    div  {
      display: flex;

      button + button {
        margin-left: 1rem;
      }
    }
  }

  ${props => props.theme.media.custom({ max: '1620px' })} {
    display: flex;
    flex-direction: column;
    gap: 0;

    & > * + * {
      margin-top: 1.5rem;
    }
  }

  ${props => props.theme.media.mobile} {
    & > * + * {
      margin-top: 1rem;
    }
  }
`

const Card = styled(ProfileCard).attrs({
  area: 'main',
})`
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    'picture name    name'
    'picture phone   email'
    'picture infringementValue infringementValue';
  gap: 1.5rem;

  ${props => props.theme.media.largePad} {
    grid-template-columns: 1fr;
    grid-template-areas:
      'picture'
      'name'
      'phone'
      'email'
      'infringementValue';

    gap: 1rem;
  }

  ${props => props.theme.media.mobile} {
    grid-template-columns: 1fr;
    grid-template-areas:
      'picture'
      'name'
      'phone'
      'email'
      'infringementValue';

    gap: 1rem;
  }
`

const NameArea = styled(Area).attrs({
  area: 'name',
})`
  padding-bottom: 1.5rem;
  border-bottom: 1px solid ${props => props.theme.colors.gray8};

  h3 {
    margin: 0.8rem 0 0.2rem;
    font-size: 1.6rem;
    font-weight: 600;
  }
  > span {
    font-size: 1.6rem;
    font-weight: 500;
    color: ${props => props.theme.colors.secondary};
  }

  ${props => props.theme.media.largePad} {
    padding-bottom: 0;
    border-bottom: 0;
  }

  ${props => props.theme.media.mobile} {
    padding-bottom: 0;
    border-bottom: 0;
  }
`

interface StyledInfringementsCountWrapperProps {
  backgroundColor?: string
  color?: string
  infringementsTotalValue?: number
  loading?: boolean
}

const StyledInfringementsCountWrapper = styled.div<StyledInfringementsCountWrapperProps>`
  div {
    font-style: normal;
    font-weight: 600;
    text-align: center;
    padding: 0.75rem;
    color: ${props => props.theme.colors.white};

    ${props =>
      props.infringementsTotalValue &&
      props.infringementsTotalValue < 10 &&
      `
      background-color: ${props.theme.colors.infringements.green};
    `}
    ${props =>
      props.infringementsTotalValue &&
      props.infringementsTotalValue >= 10 &&
      `
      background-color: ${props.theme.colors.infringements.orange};
      color: ${props.theme.colors.white};
    `}
    ${props =>
      props.infringementsTotalValue &&
      props.infringementsTotalValue >= 40 &&
      `
      background-color: ${props.theme.colors.infringements.lightRed};
    `}
    ${props =>
      props.infringementsTotalValue &&
      props?.infringementsTotalValue >= 80 &&
      `
      background-color: ${props.theme.colors.infringements.red};
      color: ${props.theme.colors.white};
    `}
    
    ${props =>
      props.backgroundColor &&
      `
      background-color: ${props.backgroundColor};
    `}

    ${props =>
      props.color &&
      `
      color: ${props.color};
    `}
    ${props =>
      props.loading &&
      `
      background-color: ${props.theme.colors.gray10};
    `}

    border-radius: 1000px;
    width: 60px;
    margin: auto;
    font-size: 16px;
  }
`

const Name = styled.div`
  display: flex;
  justify-content: space-between;
  h3 {
    padding-bottom: 0.5rem;
  }
`

const VerticalAligner = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const UserInfringements: React.VFC = () => {
  const { userId } = useParams<{ userId: string }>()
  const me = useUser()
  const admin = useAdmin()
  const { hasPermissionsAndMe } = usePermissions()
  const addPrompt = usePrompt()
  const onErrorAuto = useOnErrorAuto()
  const theme = useTheme()
  const [infringementLoading, setInfringementLoading] =
    useState<string | null>(null)
  const { setOverride, setOverrides, deleteOverrides } = useBreadcrumbs()

  const myInfringements = me.id === userId

  const translations = useTranslate({
    infringements: 'common.infringements',
    phoneNumber: 'common.phone-number',
    birthNumber: 'users.birth-number',
    email: 'common.email',
    address: 'common.address',
    noInfringements: 'infringements.no-infringements',
    myInfringements: 'infringements.my-infringements',

    severity: 'infringements.severity',
    totalSeverity: 'infringements.total-severity',
    archiveInfringement: 'infringements.archive-infringement',
    restoreInfringement: 'infringements.restore-infringement',

    minorInfringement: 'infringements.minor-infringement',
    seriousInfringement: 'infringements.serious-infringement',
    verySeriousInfringement: 'infringements.very-serious-infringement',
    mostSeriousInfringement: 'infringements.most-serious-infringement',
    amountOfInfringementsInCategories:
      'infringements.amount-of-infringements-in-categories',

    date: 'common.date',
    employee: 'common.employee',
    archived: 'common.archived',
    loading: 'common.loading',
    category: 'common.category',

    success: 'toasts.email-sent-successfully',
    error: 'errors.generic-error',
  })

  const { formValues: filter, formChangeHandler: filterHandler } = useForm<{
    orderBy: string
  }>({
    values: {
      orderBy: '-infringementTimestamp',
    },
  })

  const { data, loading: queryLoading } = useQuery<
    UserInfringementsQuery,
    UserInfringementsQueryVariables
  >(USER_INFRINGEMENTS, {
    variables: {
      user: userId,
      orderBy: filter.orderBy,
    },
  })
  const user = data?.user
  const infringements = data?.allInfringements?.edges.map(edge => edge.node)

  const infringementDistribution = {
    [InfringementSeverities.MINOR]: user?.minorInfringementCount,
    [InfringementSeverities.SERIOUS]: user?.seriousInfringementCount,
    [InfringementSeverities.VERY_SERIOUS]: user?.verySeriousInfringementCount,
    [InfringementSeverities.MOST_SERIOUS]: user?.mostSeriousInfringementCount,
  }

  const canEdit = (user: IdVariable) =>
    admin ||
    hasPermissionsAndMe(user, PERMISSIONS.infringements.change.infringement)

  const { dots } = useLoadingPlaceholder(queryLoading)

  const [toggleArchived, { loading: archiveLoading }] = useMutation<
    PatchInfringementMutation,
    PatchInfringementMutationVariables
  >(PATCH_INFRINGEMENT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['UserInfringements'],
    onError: onErrorAuto(),
  })

  // TODO fix exhaustive-deps?
  // eslint-disable-next-line
  const setOverrideCallback = useCallback(setOverride, [])
  // eslint-disable-next-line
  const setOverridesCallback = useCallback(setOverrides, [])
  // eslint-disable-next-line
  const deleteOverridesCallback = useCallback(deleteOverrides, [])
  useEffect(() => {
    if (myInfringements)
      setOverridesCallback({
        user: null,
        [userId]: translations.myInfringements,
      })
    else if (!user)
      setOverridesCallback({
        user: null,
        [userId]: translations.loading,
      })
    else
      setOverridesCallback({
        user: null,
        [user.id]: user.fullName,
      })

    return () => deleteOverridesCallback(userId, 'users')
  }, [
    setOverrideCallback,
    setOverridesCallback,
    deleteOverridesCallback,
    translations.loading,
    userId,
    myInfringements,
    translations.myInfringements,
    user,
  ])

  async function handleArchiveInfringement(
    infringement?: InfringementNodeForAllInfringements
  ) {
    if (typeof infringement === 'undefined') return

    if (infringement.archivedAt !== null) {
      executeArchiveInfringement(infringement)()
      return
    }

    const initialData: ArchivedInfringementForm = {
      archivedBasis: '',
    }

    const { data } = await addPrompt<ArchivedInfringementForm | null>(
      resolve => (
        <ArchiveInfringementPrompt
          initialData={initialData}
          onResolve={resolve}
        />
      )
    )
    if (data === null) return

    executeArchiveInfringement(infringement)(data)
  }

  function executeArchiveInfringement(
    infringement?: InfringementNodeForAllInfringements | null
  ) {
    if (!infringement) return () => void 0

    return async (data?: ArchivedInfringementForm) => {
      const isArchived = infringement.archivedAt !== null
      setInfringementLoading(infringement.id)
      await toggleArchived({
        variables: {
          id: infringement.id,
          input: {
            archivedBy: me.id,
            archivedAt: isArchived ? null : formatISO(new Date()),
            archivedBasis: data?.archivedBasis,
          },
        },
      })
      setInfringementLoading(null)
    }
  }

  function createMenuItems(infringement: InfringementNodeForAllInfringements) {
    const items: TableMenuItem<InfringementNodeForAllInfringements>[] = []

    if (canEdit(infringement.user)) {
      const isArchived = infringement.archivedAt !== null
      items.push({
        label: isArchived
          ? translations.restoreInfringement
          : translations.archiveInfringement,
        data: infringement,
        role: 'link',
        onClick: handleArchiveInfringement,
      })
    }
    return items
  }

  const headerText = !myInfringements
    ? translations.infringements +
      ': ' +
      (user?.fullName ?? translations.loading)
    : translations.myInfringements

  return (
    <Wrapper>
      <header>
        <h2>
          <span>{dots(headerText)}</span>
        </h2>
      </header>
      <Card>
        <PictureArea
          src={user?.profilePicture ?? profilePicturePlaceholder}
        ></PictureArea>

        <NameArea>
          <Name>
            <h3>{dots(user?.fullName)}</h3>
            <VerticalAligner>
              <StyledInfringementsCountWrapper
                loading={queryLoading}
                infringementsTotalValue={user?.infringementsTotalValue ?? 0}
                backgroundColor={
                  (user?.infringementsTotalValue ?? 0) >= 40
                    ? theme.colors.infringements.red
                    : undefined
                }
                color={
                  (user?.infringementsTotalValue ?? 0) < 10
                    ? theme.colors.white
                    : undefined
                }
              >
                <div>
                  {queryLoading ? '-' : user?.infringementsTotalValue ?? 0}
                </div>
              </StyledInfringementsCountWrapper>
            </VerticalAligner>
          </Name>
          <span>
            {dots(user?.userTypes?.map(type => type.name).join(', '), {
              size: 24,
            })}
          </span>
        </NameArea>

        <InputArea area="phone">
          <label>{translations.phoneNumber}</label>
          <Input
            value={safeParsePhoneNumber(user?.phoneNumber ?? '')}
            editing={false}
            iconRightProps={{
              icon: 'phone',
              type: 'solid',
              cursor: 'pointer',
              onClick: () => window.open(`tel:${user?.phoneNumber}`),
            }}
            onChange={() => void 0}
          />
        </InputArea>

        <InputArea area="email">
          <label>{translations.email}</label>
          <Input
            value={user?.email ?? ''}
            editing={false}
            inputMode="email"
            iconRightProps={{
              icon: 'envelope',
              type: 'solid',
              cursor: 'pointer',
              onClick: () => window.open(`mailto:${user?.email}`),
            }}
            onChange={() => void 0}
          />
        </InputArea>
      </Card>
      <TotalCountTable
        loading={queryLoading || archiveLoading}
        loaderProps={{
          columns: !isMobile ? 6 : 1,
          rows: 10,
          expectedWidths: [162, 92, 82],
        }}
        noData={infringements?.length === 0}
        noDataText={translations.noInfringements}
      >
        <thead>
          <tr>
            <th>{translations.severity}</th>
            <CenteredTableHeader>
              {translations.amountOfInfringementsInCategories}
            </CenteredTableHeader>
          </tr>
        </thead>

        <tbody>
          <Row>
            <SeverityCell
              severity={InfringementSeverities.MINOR as SeverityCategory}
            />
            <CenteredTextCell>
              {infringementDistribution[InfringementSeverities.MINOR]}
            </CenteredTextCell>
          </Row>
          <Row>
            <SeverityCell
              severity={InfringementSeverities.SERIOUS as SeverityCategory}
            />
            <CenteredTextCell>
              {infringementDistribution[InfringementSeverities.SERIOUS]}
            </CenteredTextCell>
          </Row>
          <Row>
            <SeverityCell
              severity={InfringementSeverities.VERY_SERIOUS as SeverityCategory}
            />
            <CenteredTextCell>
              {infringementDistribution[InfringementSeverities.VERY_SERIOUS]}
            </CenteredTextCell>
          </Row>
          <Row>
            <SeverityCell
              severity={InfringementSeverities.MOST_SERIOUS as SeverityCategory}
            />
            <CenteredTextCell>
              {infringementDistribution[InfringementSeverities.MOST_SERIOUS]}
            </CenteredTextCell>
          </Row>
        </tbody>
      </TotalCountTable>
      {isMobile ? (
        <InfringementsCards
          infringements={infringements ?? []}
          queryLoading={queryLoading}
          infringementLoading={infringementLoading}
          createMenuItems={createMenuItems}
          onLoadMore={() => void 0}
        />
      ) : (
        <Table
          loading={queryLoading || archiveLoading}
          loaderProps={{
            columns: !isMobile ? 6 : 1,
            rows: 10,
            expectedWidths: [162, 120, 120],
          }}
          noData={infringements?.length === 0}
          noDataText={translations.noInfringements}
        >
          <thead>
            <tr>
              <SortableHeader
                baseValue="infringementTimestamp"
                sortValue={filter.orderBy}
                invertDirection
                onSort={filterHandler('orderBy')}
              >
                {translations.date}
              </SortableHeader>
              <th>{translations.category}</th>

              <SortableHeader
                baseValue="severity"
                sortValue={filter.orderBy}
                onSort={filterHandler('orderBy')}
              >
                {translations.severity}
              </SortableHeader>

              <th></th>
            </tr>
          </thead>

          <tbody>
            {infringements &&
              infringements.map(infringement => {
                const menuItems = createMenuItems(infringement)

                return (
                  <Row key={infringement.id}>
                    <DateCell
                      infringementId={infringement.id}
                      createdAt={infringement.infringementTimestamp}
                    />

                    <td>{infringement.infringementCategory.identifier}</td>

                    <SeverityCell
                      severity={infringement.infringementCategory.severity}
                    />

                    <TableMenu items={menuItems} loading={queryLoading} />
                  </Row>
                )
              })}
          </tbody>
        </Table>
      )}
    </Wrapper>
  )
}
