import styled from 'styled-components'
import { RadioGroup as BaseRadioGroup } from '@ur/react-components'

export const RadioGroup = styled(BaseRadioGroup).attrs(props => ({
  padding: '1rem 0 0 0',
  checkboxProps: {
    boxColor: props.theme.colors.secondary,
    checkedIconProps: {
      margin: '0 0 0 1px',
      icon: 'circle',
      type: 'solid',
    },
  },
}))`
  padding: ${props => props.padding};
  i {
    top: 53%;
    left: 47%;
  }
`
