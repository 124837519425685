declare global {
  interface Math {
    clamp: (num: number, min: number, max: number) => number
  }

  interface String {
    capitalizeFirst: () => string
  }
}

Math.clamp = function (num, min, max) {
  return Math.min(max, Math.max(num, min))
}

// eslint-disable-next-line no-extend-native
String.prototype.capitalizeFirst = function () {
  return this.charAt(0).toUpperCase() + this.slice(1)
}

export {}
