import { useQuery } from '@apollo/client'
import { Icon, Loader } from '@ur/react-components'
import { useClickOutside, useTranslate } from '@ur/react-hooks'
import { FormErrors, UpdateFormFn } from '@ur/react-hooks/build/useForm/types'
import profilePicturePlaceholder from 'assets/images/profile-picture-placeholder.svg'
import { PhoneInput } from 'components'
import xorBy from 'lodash/xorBy'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { ZIndexRange } from 'types/style'
import { useAdmin, useLoadingPlaceholder, useUser } from 'util/hooks'
import { safeParsePhoneNumber } from 'util/parsing'
import { ProfileQuery, UserTypeNode, UserTypesShallowQuery } from '..'
import { USER_TYPES_SHALLOW_QUERY } from '../queries'
import { ProfileEditForm } from '../types'
import { Area, Input, InputArea, ProfileCard } from './components'

interface WrapperProps {
  showBirthNumber?: boolean
}

const Wrapper = styled(ProfileCard).attrs((props: WrapperProps) => ({
  area: 'main',
  showBirthNumber: props.showBirthNumber || false,
}))`
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    'picture name    name'
    'picture phone   email'
    ${props =>
      props.showBirthNumber
        ? "'picture birthNumber address'"
        : "'picture address address'"}
    'picture postalCode city';
  gap: 1.5rem;

  ${props => props.theme.media.largePad} {
    grid-template-columns: 1fr;
    grid-template-rows: unset;
    grid-template-areas:
      'picture'
      'name'
      'phone'
      'email'
      ${props => props.showBirthNumber && 'birthNumber'}
      'address'
      'postalCode'
      'city';

    gap: 1rem;
  }

  ${props => props.theme.media.mobile} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-gap: 1rem;

    > div:nth-child(n + 2) {
      width: 100%;
    }
  }
`

const EditUserTypes = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;

  & > div {
    padding: 8px 12px 5px;
    border-radius: 6px;
    font-size: 1.2rem;
    color: white;
    background: ${props => props.theme.colors.secondary};

    i {
      margin-left: 1.4ch;

      color: rgba(0, 0, 0, 0.4);
      font-weight: 300;
      font-size: 0.8em;
      cursor: pointer;

      &:hover {
        color: white;
      }
    }
  }
  span.error {
    margin-left: 1ch;
    color: ${props => props.theme.colors.red};
    font-size: 1rem;
  }
`
const AddUserType = styled.div.attrs({
  role: 'button',
})`
  position: relative;
  padding: 6px 12px 7px !important;
  background: white !important;
  border: 1px solid ${props => props.theme.colors.gray8};
  cursor: pointer;

  i {
    margin-left: 0 !important;
  }
  &:hover {
    background: ${props => props.theme.colors.secondary} !important;
    border-color: rgba(255, 255, 255, 0);

    i {
      color: white;
    }
  }
  aside {
    position: absolute;
    z-index: ${ZIndexRange.Dropdowns};
    top: -1px;
    left: -1px;

    background: white;
    border: 1px solid ${props => props.theme.colors.gray8};
    border-radius: 6px;
    overflow: hidden;

    & > div {
      display: flex;
      align-items: center;
      height: 36px;
      padding: 0 12px;

      color: ${props => props.theme.colors.text.dark};
      font-size: 0.8em;
      cursor: pointer;

      & + div {
        border-top: 1px solid ${props => props.theme.colors.gray8};
      }
      &:hover {
        background-color: ${props => props.theme.colors.quaternary};
      }
    }
  }
`
interface PictureAreaProps {
  src: string
}
export const PictureArea = styled(Area).attrs({
  area: 'picture',
})<PictureAreaProps>`
  position: relative;
  width: 380px;
  height: 380px;
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};

  background-image: url('${props => props.src}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  div.edit-icon {
    position: absolute;
    left: 12px;
    bottom: 12px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 58px;
    height: 58px;
    padding: 1px 0 0 4px;
    border-radius: 12px;
    background: white;
    cursor: pointer;

    &:hover {
      background: ${props => props.theme.colors.secondary};

      i {
        color: white;
      }
    }
  }

  ${props => props.theme.media.largePad} {
    width: 100%;
    height: 280px;
  }

  ${props => props.theme.media.mobile} {
    width: 100%;
    height: 280px;
  }
`
const NameArea = styled(Area).attrs({
  area: 'name',
})`
  padding-bottom: 1.5rem;
  border-bottom: 1px solid ${props => props.theme.colors.gray8};

  display: flex;
  width: 100%;
  justify-content: space-between;

  h3 {
    margin: 0.8rem 0 0.2rem;
    font-size: 1.6rem;
    font-weight: 600;
  }
  span {
    font-size: 1.6rem;
    font-weight: 500;
    color: ${props => props.theme.colors.secondary};
  }

  ${props => props.theme.media.mobile} {
    padding-bottom: 0;
    border-bottom: 0;
    flex-direction: column;
  }
`

const InternalNumberField = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin: 0.8rem 0 0.2rem;
  label {
    margin: 0 0 4px 4px;
    color: ${props => props.theme.colors.gray5};
    font-weight: 600;
  }
`

const NameAndUserTypes = styled.div`
  display: flex;
  flex-direction: column;
`

interface MainInfoProps {
  user: ProfileQuery['user'] | null
  loading: boolean
  editing: boolean
  initialLoad: boolean
  editForm: ProfileEditForm
  errors: FormErrors<ProfileEditForm>

  onUpdateForm: UpdateFormFn<ProfileEditForm>
}

export const MainInfo: React.VFC<MainInfoProps> = ({
  user,
  loading,
  editing,
  initialLoad,
  editForm,
  errors,

  onUpdateForm,
}) => {
  const translations = useTranslate({
    phoneNumber: 'common.phone-number',
    email: 'common.email',
    address: 'common.address',
    city: 'common.city',
    postalCode: 'common.postal-code',
    birthNumber: 'users.birth-number',
    externalId: 'common.external-id',
  })

  const fileInputRef = useRef<HTMLInputElement>(null)
  const userTypeMenuRef = useRef<HTMLElement>(null)

  const admin = useAdmin()
  const me = useUser()
  const { dots } = useLoadingPlaceholder(loading)

  const [userTypesOpen, setUserTypesOpen] = useState(false)
  const [profilePictureHash, setProfilePictureHash] =
    useState<string | null>(null)

  const { data: userTypesData, loading: userTypesLoading } = useQuery<
    UserTypesShallowQuery,
    never
  >(USER_TYPES_SHALLOW_QUERY, {
    skip: !admin,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  useClickOutside(userTypeMenuRef, () => setUserTypesOpen(false), true)

  const canSeeBirthNumber = admin || me.id === user?.id

  function handleAddUserType(type: Pick<UserTypeNode, 'id' | 'name'>) {
    return () => {
      onUpdateForm({
        userTypes: [...editForm.userTypes, type],
      })
      setUserTypesOpen(false)
    }
  }
  function handleRemoveUserType(type: Pick<UserTypeNode, 'id' | 'name'>) {
    return () => {
      const userTypes = xorBy(editForm.userTypes, [type], type => type.id)
      onUpdateForm({
        userTypes,
      })
    }
  }
  function handlePictureChange(evt: React.ChangeEvent<HTMLInputElement>) {
    const file = Array.from(evt.target.files ?? [])[0]
    if (!file) return
    const reader = new FileReader()

    reader.onload = evt => {
      const result = evt.target?.result
      if (!result) return

      setProfilePictureHash(result.toString())

      onUpdateForm({
        profilePicture: file,
      })
    }
    reader.readAsDataURL(file)

    evt.target.value = ''
  }

  const otherUserTypes =
    userTypesData?.allUserTypes.edges.reduce<
      Pick<UserTypeNode, 'id' | 'name'>[]
    >(
      (acc, { node }) =>
        editForm.userTypes.find(type => type.id === node.id)
          ? acc
          : [...acc, node],
      []
    ) ?? []

  function displayAddress() {
    if (!user) return ''
    const { postalCode, city } = user
    if (city || postalCode) {
      let address = !errors.address ? `${editForm.address},` : ''
      if (postalCode) address += !errors.address ? ` ${postalCode}` : postalCode
      if (city) address += !errors.address ? ` ${city}` : city
      return address
    } else {
      return editForm.address
    }
  }

  return (
    <Wrapper showBirthNumber={canSeeBirthNumber}>
      <PictureArea
        src={
          profilePictureHash ??
          user?.profilePicture ??
          profilePicturePlaceholder
        }
      >
        {editing && (
          <>
            <input
              ref={fileInputRef}
              hidden
              type="file"
              accept="image/*"
              onChange={handlePictureChange}
            />
            <div
              className="edit-icon"
              role="button"
              onClick={() => fileInputRef.current?.click()}
            >
              <Icon icon="pen" type="solid" size="1.2rem" color="gray7" />
            </div>
          </>
        )}
      </PictureArea>

      <NameArea>
        <NameAndUserTypes>
        <h3>
          {dots(user?.fullName)}
        </h3>
        {!admin || !editing ? (
          <span>
            {dots(user?.userTypes.map(type => type.name).join(', '), {
              size: 24,
            })}
          </span>
        ) : (
          <EditUserTypes>
            {editForm.userTypes.map(type => (
              <div key={type.id}>
                {type.name}
                <Icon icon="times" onClick={handleRemoveUserType(type)} />
              </div>
            ))}

            {otherUserTypes.length > 0 && (
              <AddUserType onClick={() => setUserTypesOpen(true)}>
                {userTypesLoading ? (
                  <Loader.Spinner size={18} thickness={2} />
                ) : (
                  <Icon icon="plus" />
                )}

                {userTypesOpen && (
                  <aside
                    ref={userTypeMenuRef}
                    onClick={evt => evt.stopPropagation()}
                  >
                    {otherUserTypes.map(type => (
                      <div
                        key={type.id}
                        role="button"
                        onClick={handleAddUserType(type)}
                      >
                        {type.name}
                      </div>
                    ))}
                  </aside>
                )}
              </AddUserType>
            )}

            {errors.userTypes !== null && (
              <span className="error">{errors.userTypes}</span>
            )}
          </EditUserTypes>
        )}
        </NameAndUserTypes>
        {admin && (
          <InternalNumberField>
            <label>{translations.externalId}</label>
            <Input
              value={editForm.internalNumber}
              editing={editing}
              onChange={(value) => onUpdateForm({ internalNumber: value })}
              iconRightProps={{
                icon: 'id-card',
                type: 'solid',
              }}
            />
          </InternalNumberField>
        )}
      </NameArea>

      <InputArea area="phone">
        <label>{translations.phoneNumber}</label>
        {editing && initialLoad ? (
          <PhoneInput
            value={editForm.phoneNumber}
            error={errors.phoneNumber}
            height="54px"
            background="inherit"
            onChange={phoneNumber => onUpdateForm({ phoneNumber })}
          />
        ) : (
          <Input
            value={safeParsePhoneNumber(editForm.phoneNumber)}
            editing={editing}
            iconRightProps={{
              icon: 'phone',
              type: 'solid',
              cursor: 'pointer',
              onClick: () => window.open(`tel:${user?.phoneNumber}`),
            }}
            onChange={() => void 0}
          />
        )}
      </InputArea>

      {canSeeBirthNumber && (
        <InputArea area="birthNumber">
          <label>{translations.birthNumber}</label>
          <Input
            value={editForm.birthNumber}
            editing={admin ? editing : false}
            onChange={birthNumber => onUpdateForm({ birthNumber })}
            iconRightProps={{
              icon: 'user',
              type: 'solid',
            }}
          />
        </InputArea>
      )}
      
      <InputArea area="email">
        <label>{translations.email}</label>
        <Input
          value={editForm.email}
          error={errors.email}
          editing={editing}
          inputMode="email"
          iconRightProps={{
            icon: 'envelope',
            type: 'solid',
            cursor: 'pointer',
            onClick: () => window.open(`mailto:${user?.email}`),
          }}
          onChange={email => onUpdateForm({ email })}
        />
      </InputArea>

      <InputArea area="address">
        <label>{translations.address}</label>
        <Input
          value={editing ? editForm.address : displayAddress()}
          error={errors.address}
          editing={editing}
          iconRightProps={{
            icon: 'map',
            type: 'solid',
            cursor: 'pointer',
            onClick: () =>
              window.open(
                `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                  editForm.address
                )}`
              ),
          }}
          onChange={address => onUpdateForm({ address })}
        />
      </InputArea>

      {editing && (
        <>
          <InputArea area="postalCode">
            <label>{translations.postalCode}</label>
            <Input
              value={editForm.postalCode}
              error={errors.postalCode}
              editing={editing}
              onChange={postalCode => onUpdateForm({ postalCode })}
            />
          </InputArea>
          <InputArea area="city">
            <label>{translations.city}</label>
            <Input
              value={editForm.city}
              error={errors.city}
              editing={editing}
              onChange={city => onUpdateForm({ city })}
            />
          </InputArea>
        </>
      )}
    </Wrapper>
  )
}
