import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import { InfoBox, Section, SettingsWrapper } from '../components/common'
import { DynamicTableSettings } from '../components'
import { useTranslate } from '@ur/react-hooks'
import { ReceiptFunctionalityCard } from '../components/ActivitiesSettings/ReceiptFunctionalityCard'
import { ModuleResponsibleUsers } from '../components/ModuleResponsibleUsers'
import { Module } from 'modules/companies'

interface ActivitiesSettingsProps {}

export const ActivitiesSettings: React.VFC<ActivitiesSettingsProps> = () => {
  const translations = useTranslate({
    info1: 'settings.info.driver-data-1',
  })

  return (
    <SettingsWrapper
      grid={{
        flow: 'row',
      }}
    >
      <InfoBox initCollapsed={isMobileOnly}>
        <p>{translations.info1}</p>
      </InfoBox>

      <ModuleResponsibleUsers module={Module.ACTIVITIES} />

      <ReceiptFunctionalityCard />

      <Section>
        <DynamicTableSettings name="Activities" />
      </Section>

      <Section>
        <DynamicTableSettings name="Activities" forExport />
      </Section>
    </SettingsWrapper>
  )
}
