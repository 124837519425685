import { gql } from '@apollo/client'

export const CREATE_ISSUE_MUTATION = gql`
  mutation CreateIssue($input: CreateIssueInput!) {
    createIssue(input: $input) {
      issue {
        id
      }
    }
  }
`

export const PATCH_ISSUE_MUTATION = gql`
  mutation PatchIssue($id: ID!, $input: PatchIssueInput!) {
    patchIssue(id: $id, input: $input) {
      issue {
        id
      }
    }
  }
`

export const TOGGLE_ISSUE_ARCHIVED_MUTATION = gql`
  mutation ToggleIssueArchived($id: ID!, $archived: Boolean!) {
    toggleIssueArchived(id: $id, archived: $archived) {
      issue {
        id
        archivedAt
      }
    }
  }
`

export const TOGGLE_ISSUE_CLOSED_MUTATION = gql`
  mutation ToggleIssueClosed(
    $id: ID!
    $closed: Boolean!
    $solution: String
    $actualCost: Int
  ) {
    toggleIssueClosed(
      id: $id
      closed: $closed
      solution: $solution
      actualCost: $actualCost
    ) {
      issue {
        id
        closedAt
      }
    }
  }
`

export const UPDATE_ISSUE_CATEGORY_MUTATION = gql`
  mutation UpdateIssueCategory($id: ID!, $input: UpdateIssueCategoryInput!) {
    updateIssueCategory(id: $id, input: $input) {
      issueCategory {
        name
        id
        responsibleUser {
          id
        }
        company {
          id
          fullName
        }
      }
    }
  }
`

export const CREATE_ISSUE_CATEGORY_MUTATION = gql`
  mutation CreateIssueCategory($input: CreateIssueCategoryInput!) {
    createIssueCategory(input: $input) {
      issueCategory {
        id
        name
      }
    }
  }
`

export const PATCH_ISSUE_CATEGORY_MUTATION = gql`
  mutation PatchIssueCategory($id: ID!, $input: PatchIssueCategoryInput!) {
    patchIssueCategory(id: $id, input: $input) {
      issueCategory {
        id
      }
    }
  }
`
export const DELETE_ISSUE_CATEGORY_MUTATION = gql`
  mutation DeleteIssueCategory($id: ID!) {
    deleteIssueCategory(id: $id) {
      found
      deletedId
    }
  }
`

export const CREATE_ISSUE_RISK_ASPECT_MUTATION = gql`
  mutation CreateIssueRiskAspect($input: CreateIssueRiskAspectInput!) {
    createIssueRiskAspect(input: $input) {
      issueRiskAspect {
        id
        name
      }
    }
  }
`

export const PATCH_ISSUE_RISK_ASPECT_MUTATION = gql`
  mutation PatchIssueRiskAspect($id: ID!, $input: PatchIssueRiskAspectInput!) {
    patchIssueRiskAspect(id: $id, input: $input) {
      issueRiskAspect {
        id
      }
    }
  }
`

export const DELETE_ISSUE_RISK_ASPECT_MUTATION = gql`
  mutation DeleteIssueRiskAspect($id: ID!) {
    deleteIssueRiskAspect(id: $id) {
      found
      deletedId
    }
  }
`

export const DELETE_ISSUE_ATTACHMENT = gql`
  mutation DeleteIssueAttachment($id: ID!) {
    deleteIssueAttachment(id: $id) {
      deletedId
      found
    }
  }
`
