import { ManualEntryBySlugQuery } from './types.graphql'

export const ManualEntryPlaceholder: ManualEntryBySlugQuery['manualEntry'] = {
  id: '',
  slug: '',
  name: '',
  description: '',
  richtext: '',
  attachments: [],
  createdAt: new Date().toUTCString(),
  updatedAt: new Date().toUTCString(),
  folder: {
    id: '',
    name: '',
    slug: '',
    slugHierarchyWithNames: [],
  },
  form: {
    id: '',
    name: '',
    ingress: '',
    archived: true,
    myResponsesCount: 0,
    userRespondedToday: false,
    formFields: {
      __typename: 'FormTemplateFieldConnection',
      edges: [],
    },
  },
}
