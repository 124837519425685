import { useMutation, useQuery } from '@apollo/client'
import { useToast } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { CheckedTableValue } from 'components'
import groupBy from 'lodash/groupBy'
import { BATCH_PATCH_NOTIFICATION_SETTINGS_MUTATION } from 'modules/companies'
import { CURRENT_COMPANY_NOTIFICATION_SETTINGS_QUERY } from 'modules/companies/queries'
import {
  BatchPatchNotificationSettingInput,
  BatchPatchNotificationSettingsMutation,
  BatchPatchNotificationSettingsMutationVariables,
  CurrentCompanyNotificationSettingsQuery,
} from 'modules/companies/types.graphql'
import { useState } from 'react'
import { useNavigateConfirm, useOnErrorAuto } from 'util/hooks'

export function useNotificationSettings() {
  const translations = useTranslate({
    prompt: {
      unsavedChangesNavigate: 'common.you-have-unsaved-changes-navigate',
    },
    success: 'settings.toasts.update-notifications-success',
    error: 'settings.errors.update-notifications',
  })

  const exportedTranslations = useTranslate({
    notifications: 'common.notifications',
    info: 'settings.info.notifications',

    prompt: {
      message: 'common.you-have-unsaved-changes',
    },
  })

  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()

  const [notificationsDiff, setNotificationsDiff] = useState<
    CheckedTableValue[]
  >([])

  useNavigateConfirm(
    notificationsDiff.length > 0,
    translations.prompt.unsavedChangesNavigate
  )

  const { data: queryData, loading: queryLoading } = useQuery<
    CurrentCompanyNotificationSettingsQuery,
    never
  >(CURRENT_COMPANY_NOTIFICATION_SETTINGS_QUERY, {
    onError: onErrorAuto(),
  })

  const [patchNotifications, { loading: patchLoading }] = useMutation<
    BatchPatchNotificationSettingsMutation,
    BatchPatchNotificationSettingsMutationVariables
  >(BATCH_PATCH_NOTIFICATION_SETTINGS_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['CurrentCompanyNotificationSettings'],
    onCompleted() {
      addToast('success', translations.success)
      setNotificationsDiff([])
    },
    onError: onErrorAuto(translations.error),
  })

  function handleSave() {
    if (!queryData) return

    const grouped = groupBy(notificationsDiff, 'row')
    const input: BatchPatchNotificationSettingInput[] = []

    for (const [key, values] of Object.entries(grouped)) {
      let value_object: { [x: string]: boolean | null } = {}
      for (const value of values) {
        value_object[value.column] = value.checked
      }
      input.push({
        id: key,
        ...value_object,
      } as BatchPatchNotificationSettingInput)
    }
    patchNotifications({
      variables: {
        input: input,
      },
    })
  }

  const loading = queryLoading || patchLoading
  const showUnsavedChangesPopup = notificationsDiff.length > 0

  return {
    data: queryData,
    loading,
    translations: exportedTranslations,
    showUnsavedChangesPopup,
    handleSave,
    onChangeCallback: setNotificationsDiff,
  }
}
