import { useToast } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { CreateRoutePlanForm } from 'modules/routePlanner/types'
import {
  CreateRoutePlanMutation,
  CreateRoutePlanMutationInput,
} from 'modules/routePlanner/types.graphql'
import { VehicleSelectNode } from 'modules/vehicles/types.graphql'
import { useLayoutEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { OnFormSubmit } from 'types/forms'
import { Coordinates } from 'types/graphql'
import { AutoCompleteCountryCodes } from 'util/consts'
import { useGoogleAutocomplete, useUser } from 'util/hooks'
import { UseGoogleAutocompleteOptions } from 'util/hooks/useGoogleAutocomplete'
import {
  getAutoCompleteSourceFromPredictions,
  getCoordinateFromGMaps,
} from 'util/maps'

interface UseCreateRoutePlanLogicInput {
  defaultValues: CreateRoutePlanForm
  onSubmit: OnFormSubmit<CreateRoutePlanMutationInput, CreateRoutePlanMutation>
}

export function useCreateRoutePlanLogic({
  defaultValues,
  onSubmit,
}: UseCreateRoutePlanLogicInput) {
  /**
   * This is where all the form handling is happening. Any stateful logic is drilled to the View layer (Form)
   * through callbacks and values calculated here.
   */
  const me = useUser()
  const history = useHistory()
  const addToast = useToast()
  const [triggerGeocoding, setTriggerGeocoding] = useState(false)
  const mapRef = useRef(new Map<string, string>())
  const [startCoordinate, setStartCoordinate] =
    useState<Coordinates | null>(null)
  const [endCoordinate, setEndCoordinate] = useState<Coordinates | null>(null)

  const coordinates = {
    start: startCoordinate,
    end: endCoordinate,
  }

  function requiredValidator(value: string | number | null) {
    if (!value) return translations.required.capitalizeFirst()
    return null
  }

  function positiveNumberValidator(value: number) {
    if (value < 0) return translations.cannotBeNegative
    return null
  }
  /**
   *
   * function weightRestrictionValidator(value: WeightRestriction | null) {
   *    if (!value) return translations.required.capitalizeFirst()
   *    const required = requiredValidator(value.weight)
   *    const positive = positiveNumberValidator(value.weight)
   *
   *    if (!required && !positive) return null
   *     return required || positive
   * }
   **/

  /**
   * Where we handle validation differs from the article this design-pattern is inspired from.
   * This is mainly due to the fact that we do not use schema based validation, and cannot use
   * hooks outside of components.
   **/
  const form = useForm<CreateRoutePlanForm>({
    values: { ...defaultValues },
    validators: {
      startLocationLatitude: requiredValidator,
      startLocationLongitude: requiredValidator,
      endLocationLatitude: requiredValidator,
      endLocationLongitude: requiredValidator,
      width: [requiredValidator, positiveNumberValidator],
      height: [requiredValidator, positiveNumberValidator],
      length: [requiredValidator, positiveNumberValidator],
      topSpeed: positiveNumberValidator,
      grossWeight: positiveNumberValidator,
    },
    config: {
      initAsInvalid: true,
    },
  })
  const { formValues, updateForm } = form

  const translations = useTranslate({
    createRoutePlanSuccess: 'route-planner.create-route-plan-success',
    error: {
      couldNotLoadRoute: 'activities.errors.could-not-load-route-in-map',
      generic: 'error.generic',
    },
    required: 'common.required',
    cannotBeNegative: 'form.validation.cannot-be-negative',
  })

  async function handleSubmit(data: CreateRoutePlanForm) {
    /**
     * The handler in the logic view is responsible for validation and
     * data sanitization. The API layer should only concern itself with
     * network requests.
     */
    const { startDate, startTime } = data
    let date: Date | null = null

    if (startDate !== null && startTime !== null) {
      date = startDate
      date.setHours(startTime.getHours())
      date.setMinutes(startTime.getMinutes())
      date.setSeconds(0)
    }
    /**
    ** This is until we properly implement weight handling. Keep the form simple for now
    const weightRestrictionData = data.useAxelWeightRestriction
      ? [
          data.singleWeightRestriction,
          data.tandemWeightRestriction,
          data.tripleWeightRestriction,
        ]
      : [data.weightRestriction]

    const weightRestrictions = weightRestrictionData.filter(
      restriction => !!restriction
    ) as WeightRestriction[]
     *

     */

    const routeRestrictions: Omit<
      CreateRoutePlanMutationInput['input']['routeRestriction'],
      'routeWeightRestrictions' | 'trailerWeight' | 'payloadWeight'
    > = {
      width: data.width,
      height: data.height,
      length: data.length,
      topSpeed: data.topSpeed,
      grossWeight: data.grossWeight,
      /*
      trailerWeight: data.trailerWeight,
      payloadWeight: data.payloadWeight,
      routeWeightRestrictions: weightRestrictions,
      */
    }

    const { topSpeed } = routeRestrictions
    if (topSpeed === 0 || topSpeed === undefined) {
      delete routeRestrictions['topSpeed']
    }

    // Validation should not trigger this submit if any of the coordinates are null
    const input: CreateRoutePlanMutationInput = {
      input: {
        user: me.id,
        startLocationName: data.startLocation,
        startLocationLatitude: data.startLocationLatitude as number,
        startLocationLongitude: data.startLocationLongitude as number,

        endLocationName: data.endLocation,
        endLocationLatitude: data.endLocationLatitude as number,
        endLocationLongitude: data.endLocationLongitude as number,

        departureTime: data.includeDepartureTime ? date : null,

        routeRestriction: {
          ...routeRestrictions,
        },
      },
    }

    await onSubmit(input).then(({ errors }) => {
      if (!!errors) {
        return
      }
      history.push('/route-planner')
    })
  }

  useLayoutEffect(() => {
    if (typeof google === 'undefined' || formValues.startLocation.trim() === '')
      return

    if (triggerGeocoding) {
      const service = new google.maps.Geocoder()

      if (formValues.startLocation) {
        service.geocode(
          { address: formValues.startLocation },
          (response, status) => {
            if(response === null) {
              addToast('warning', translations.error.couldNotLoadRoute)
              updateForm({
                startLocation: '',
              })
              return
            }
            if (status === google.maps.GeocoderStatus.OK) {
              setStartCoordinate(
                getCoordinateFromGMaps(response[0].geometry.location)
              )
              updateForm({
                startLocationLatitude: response[0].geometry.location.lat(),
                startLocationLongitude: response[0].geometry.location.lng(),
              })
            } else {
              addToast('warning', translations.error.couldNotLoadRoute)
              updateForm({
                startLocation: '',
              })
            }
          }
        )
      }
      if (formValues.endLocation) {
        service.geocode(
          { address: formValues.endLocation },
          (response, status) => {
            if(response === null) {
              addToast('warning', translations.error.couldNotLoadRoute)
              updateForm({
                endLocation: '',
              })
              return
            }
            if (status === google.maps.GeocoderStatus.OK) {
              setEndCoordinate(
                getCoordinateFromGMaps(response[0].geometry.location)
              )
              updateForm({
                endLocationLatitude: response[0].geometry.location.lat(),
                endLocationLongitude: response[0].geometry.location.lng(),
              })
            } else {
              addToast('warning', translations.error.couldNotLoadRoute)
              updateForm({
                endLocation: '',
              })
            }
          }
        )
      }
    }
    setTriggerGeocoding(false)
  }, [
    addToast,
    formValues.endLocation,
    formValues.startLocation,
    translations.error.couldNotLoadRoute,
    triggerGeocoding,
    updateForm,
  ])

  function handleLocationOnBlur() {
    if (triggerGeocoding) return
    if (
      formValues.startLocationLongitude === null ||
      formValues.startLocationLatitude === null
    ) {
      updateForm({ startLocation: '' })
    }
    if (
      formValues.endLocationLongitude === null ||
      formValues.endLocationLatitude === null
    ) {
      updateForm({ endLocation: '' })
    }
  }

  // Autocomplete handling
  const startPredictions = useGoogleAutocomplete(
    form.formValues.startLocation,
    {
      componentRestrictions: {
        country: AutoCompleteCountryCodes,
      },
    } as UseGoogleAutocompleteOptions
  )

  const endPredictions = useGoogleAutocomplete(form.formValues.endLocation, {
    componentRestrictions: {
      country: AutoCompleteCountryCodes,
    },
  } as UseGoogleAutocompleteOptions)

  const startAutoCompleteSource = getAutoCompleteSourceFromPredictions(
    startPredictions.predictions,
    mapRef.current
  )
  const endAutoCompleteSource = getAutoCompleteSourceFromPredictions(
    endPredictions.predictions,
    mapRef.current
  )

  function handleAutoCompleteItemSelect(
    formField: 'startLocation' | 'endLocation'
  ) {
    return (placename: string) => {
      updateForm({ [formField]: placename })
      setTriggerGeocoding(true)
    }
  }

  function handleVehicleSelect(vehicle: VehicleSelectNode | null) {
    if (vehicle === null) return

    const updateObject = {
      ...(vehicle.innerWidth && { width: vehicle.innerWidth }),
      ...(vehicle.innerHeight && { height: vehicle.innerHeight }),
      ...(vehicle.innerLength && { length: vehicle.innerLength }),
      ...(vehicle.engineMaximumSpeed && {
        topSpeed: vehicle.engineMaximumSpeed,
      }),
      ...(vehicle.netWeight && {
        weightRestriction: { weight: vehicle.netWeight },
      }),
    }

    updateForm({
      ...updateObject,
    })
  }

  return {
    form,
    coordinates,
    startAutoCompleteSource,
    endAutoCompleteSource,
    onSubmit: handleSubmit,
    vehicleSelectCallback: handleVehicleSelect,
    selectAutoCompleteItemCallback: handleAutoCompleteItemSelect,
    handleLocationOnBlurCallback: handleLocationOnBlur,
  }
}
