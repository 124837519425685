import { useTranslate } from '@ur/react-hooks'
import { IconShortcut, ShortcutsWrapper } from 'components/Shortcuts'
import { PermissionsRequired } from 'containers/permission-containers'
import { useModuleConfigs } from 'modules/companies/CompanySettings/hooks'
import React from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import { PERMISSIONS } from 'util/permissions'

const Wrapper = styled.section`
  max-width: 100%;
`

export const DashboardShortcuts: React.VFC = () => {
  const {
    activitiesActive,
    terminalActive,
    timesheetsActive,
    absencesActive,
    issuesActive,
    forumActive,
    handbookActive,
  } = useModuleConfigs()
  const translations = useTranslate({
    shortcuts: 'common.shortcuts',

    forum: 'common.forum',
    users: 'common.users',
    drivingData: 'common.driving-data',
    terminal: 'common.terminal',
    issues: 'common.issues',
    hourlist: 'common.hourlist',
    absences: 'common.absences',
    driversHandbook: 'common.drivers-handbook',
    handbook: 'common.handbook',
  })

  const handbookShortcutText = isMobile
    ? translations.handbook
    : translations.driversHandbook

  return (
    <Wrapper>
      <h2>{translations.shortcuts}</h2>

      <ShortcutsWrapper alignWithSpaceBetween={true}>
        {forumActive && (
          <PermissionsRequired permissions={['forum.view_thread']}>
            <IconShortcut
              linkProps={{ to: '/forum' }}
              iconProps={{ icon: 'chat' }}
              text={translations.forum}
            />
          </PermissionsRequired>
        )}

        {isMobile && (
          <PermissionsRequired permissions={[PERMISSIONS.users.view.user]}>
            <IconShortcut
              linkProps={{ to: '/users' }}
              iconProps={{ icon: 'users' }}
              text={translations.users}
            />
          </PermissionsRequired>
        )}

        {activitiesActive && (
          <PermissionsRequired permissions={['activities.view_driveractivity']}>
            <IconShortcut
              linkProps={{ to: '/activities' }}
              iconProps={{ icon: 'truck' }}
              text={translations.drivingData}
            />
          </PermissionsRequired>
        )}

        {terminalActive && (
          <PermissionsRequired permissions={['terminal.view_terminalentry']}>
            <IconShortcut
              linkProps={{ to: '/terminal' }}
              iconProps={{ icon: 'house' }}
              text={translations.terminal}
            />
          </PermissionsRequired>
        )}

        {issuesActive && (
          <PermissionsRequired permissions={['issues.view_issue']}>
            <IconShortcut
              linkProps={{ to: '/issues' }}
              iconProps={{ icon: 'warning' }}
              text={translations.issues}
            />
          </PermissionsRequired>
        )}

        {timesheetsActive && (
          <PermissionsRequired permissions={['timesheets.view_timeentry']}>
            <IconShortcut
              linkProps={{ to: '/time-entries', className: 'extra-padding' }}
              iconProps={{ icon: 'clock', width: '30px' }}
              text={translations.hourlist}
            />
          </PermissionsRequired>
        )}

        {absencesActive && (
          <PermissionsRequired permissions={['timesheets.view_absence']}>
            <IconShortcut
              linkProps={{ to: '/absences' }}
              iconProps={{ icon: 'user-x' }}
              text={translations.absences}
            />
          </PermissionsRequired>
        )}
        {handbookActive && (
          <PermissionsRequired
            permissions={[PERMISSIONS.handbook.view.manualentry]}
          >
            <IconShortcut
              linkProps={{ to: '/handbook' }}
              iconProps={{ icon: 'book' }}
              text={handbookShortcutText}
            />
          </PermissionsRequired>
        )}
      </ShortcutsWrapper>
    </Wrapper>
  )
}
