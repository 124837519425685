import { PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Input, RegularModal } from 'components'
import { DetectBlur } from 'containers/util-containers'
import { AbsenceTypeNode } from 'modules/timesheets'
import { Field, Form } from '..'
import { Color, SettingsColorPicker } from '../common'

export interface AbsenceTypeForm {
  externalId: string
  name: string
  color: string
}

interface AbsenceTypePromptProps {
  initialData?: AbsenceTypeForm
  existingAbsenceTypes: Pick<AbsenceTypeNode, 'name' | 'id'>[]
  onSubmit: PromptResolve<AbsenceTypeForm | null>
}

export const AbsenceTypePrompt: React.VFC<AbsenceTypePromptProps> = ({
  initialData,
  existingAbsenceTypes,
  onSubmit,
}) => {
  const translations = useTranslate({
    form: {
      label: {
        name: 'common.name',
        color: 'common.color',
        id: 'common.id',
      },
      placeholder: {
        id: 'settings.external-id-in-salary-system'
      }
    },

    addAbsenceType: 'settings.add-absence-type',
    editAbsenceType: 'settings.edit-absence-type',

    create: 'common.create-alt',
    update: 'common.update',

    validation: {
      nameExists: 'settings.validation.absence-type-name-exists',
      required: 'common.required',
    },
  })

  const editing = typeof initialData !== 'undefined'

  function validateName(name: string, formName: string) {
    if (name === initialData?.name) return null
    if (name === '') return translations.validation.required
    if (!!existingAbsenceTypes.find(type => type.name === formName)) {
      return translations.validation.nameExists
    }
    return null
  }

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formChangeHandler: handler,
    submitHandler: submit,
  } = useForm<AbsenceTypeForm>({
    values: initialData ?? {
      externalId: '',
      name: '',
      color: 'hsl(138deg, 80%, 80%)',
    },
    validators: {
      name: (val, form) => validateName(val, form.name),
    },
  })

  return (
    <DetectBlur active onClickOutside={() => onSubmit(null)}>
      <RegularModal
        title={
          editing ? translations.editAbsenceType : translations.addAbsenceType
        }
        submitText={editing ? translations.update : translations.create}
        submitDisabled={!formValid}
        width="400px"
        onCancel={() => onSubmit(null)}
        onSubmit={submit(onSubmit)}
      >
        <Form>
          <Field>
            <label>{translations.form.label.id}</label>
            <Input
              value={form.externalId}
              fullWidth
              onChange={handler('externalId')}
              placeholder={translations.form.placeholder.id}
            />
          </Field>
          <Field>
            <label>{translations.form.label.name}</label>
            <Input
              value={form.name}
              error={errors.name}
              autoFocus
              fullWidth
              onChange={handler('name')}
            />
          </Field>

          <Field>
            <label>{translations.form.label.color}</label>
            <Color color={form.color}>
              <SettingsColorPicker
                value={form.color}
                onChange={handler('color')}
              />
            </Color>
          </Field>
        </Form>
      </RegularModal>
    </DetectBlur>
  )
}
