import { useState, useEffect } from 'react'
import { loadExternalScript } from 'modules/routePlanner/utils'
import { HERE_PLATFORM } from './consts'

export function useHERE() {
  /**
   * This hooks allows us to load the js libraries for HERE maps dynamically.
   * They are loaded by appending the scripts to the DOM. All plugin scripts
   * require the HERE core script to be loaded first.
   *
   * Note: This hook has broken when HERE randomly deprecated the API in favor
   * of their new major version. Documentation for their API versions
   * https://developer.here.com/documentation/maps/3.1.14.0/dev_guide/topics/api-versions.html
   * The fix was to change the version in the bundles from ./consts from '3.0' to '3.1'
   */
  const [HERE, setHERE] = useState<typeof H | null>(null)
  const [coreLoaded, setCoreLoaded] = useState(false)
  const [serviceLoaded, setServiceLoaded] = useState(false)
  const [uiLoaded, setUiLoaded] = useState(false)
  const [mapEventsLoaded, setMapEventsLoaded] = useState(false)

  // Core
  useEffect(() => {
    if (coreLoaded) return
    loadExternalScript(
      HERE_PLATFORM.core.bundle,
      HERE_PLATFORM.core.scriptId,
      () => {
        setCoreLoaded(true)
      }
    )
  }, [coreLoaded, setCoreLoaded])

  // Service
  useEffect(() => {
    if (serviceLoaded || !coreLoaded) return

    loadExternalScript(
      HERE_PLATFORM.service.bundle,
      HERE_PLATFORM.service.scriptId,
      () => {
        setServiceLoaded(true)
      }
    )
  }, [coreLoaded, serviceLoaded])

  // UI
  useEffect(() => {
    if (uiLoaded || !coreLoaded) return

    loadExternalScript(
      HERE_PLATFORM.ui.bundle,
      HERE_PLATFORM.ui.scriptId,
      () => {
        setUiLoaded(true)
      }
    )
  }, [coreLoaded, uiLoaded])

  // Map events
  useEffect(() => {
    if (mapEventsLoaded || !coreLoaded) return
    loadExternalScript(
      HERE_PLATFORM.mapEvents.bundle,
      HERE_PLATFORM.mapEvents.scriptId,
      () => {
        setMapEventsLoaded(true)
      }
    )
  }, [coreLoaded, mapEventsLoaded])

  // Here we mount the H object because all scripts are finally ready
  useEffect(() => {
    if (HERE !== null) return

    const allLoaded = coreLoaded && serviceLoaded && uiLoaded && mapEventsLoaded
    if (!allLoaded) return

    setHERE(window.H)
  }, [HERE, coreLoaded, mapEventsLoaded, serviceLoaded, uiLoaded])
  return HERE
}
