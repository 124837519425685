import { gql } from '@apollo/client'
import { CompanyFragments } from './fragments'
import { UserFragments } from 'modules/users/fragments'

export const UPDATE_COMPANY_MUTATION = gql`
  mutation UpdateCompanyMutation($id: ID!, $input: UpdateCompanyInput!) {
    updateCompany(id: $id, input: $input) {
      company {
        fullName
        country
        language
        logo
      }
    }
  }
`

export const PATCH_COMPANY_MUTATION = gql`
  mutation PatchCompanyMutation($id: ID!, $input: PatchCompanyInput!) {
    patchCompany(id: $id, input: $input) {
      company {
        id
      }
    }
  }
`

export const UPDATE_TERMINAL_SETTINGS = gql`
  mutation UpdateTerminalMutation($id: ID!, $input: UpdateTerminalInput!) {
    updateTerminal(id: $id, input: $input) {
      terminal {
        id
        ip
      }
    }
  }
`

export const UPDATE_COMPANY_USER_TYPES_MUTATION = gql`
  mutation UpdateCompanyUserTypes($updatedUserTypes: [UpdatedUserTypesInput]!) {
    updateCompanyUserTypes(updatedUserTypes: $updatedUserTypes) {
      updatedUserTypes {
        id
      }
    }
  }
`

export const UPDATE_COMPANY_USER_TYPES_DROPDOWNS_MUTATION = gql`
  mutation UpdateCompanyUserTypesDropdowns(
    $updatedUserTypes: [UpdatedUserTypesDropdownInput]!
  ) {
    updateCompanyUserTypesDropdowns(updatedUserTypes: $updatedUserTypes) {
      updatedUserTypes {
        id
      }
    }
  }
`

export const CREATE_USER_TYPE_MUTATION = gql`
  mutation CreateUserType($input: CreateUserTypeInput!) {
    createUserType(input: $input) {
      userType {
        ...UserTypeInfo
      }
    }
  }
  ${UserFragments.userTypeInfo}
`

export const PATCH_USER_TYPE_MUTATION = gql`
  mutation PatchUserType($id: ID!, $input: PatchUserTypeInput!) {
    patchUserType(id: $id, input: $input) {
      userType {
        id
      }
    }
  }
`

export const DELETE_USER_TYPE_MUTATION = gql`
  mutation DeleteUserType($id: ID!) {
    deleteUserType(id: $id) {
      found
      deletedId
    }
  }
`

export const PATCH_SALARY_WORK_TIMES = gql`
  mutation PatchSalaryWorkTime($id: ID!, $input: PatchSalaryWorkTimeInput!) {
    patchSalaryWorkTime(id: $id, input: $input) {
      salaryWorkTime {
        id
      }
    }
  }
`

export const SWAP_SALARY_WORK_TIMES_PRIORITY = gql`
  mutation SwapSalaryWorkTimesPriority($input: SwapSalaryWorkTimesPriorityInput!) {
    swapSalaryWorkTimesPriority(input: $input) {
      ok
    }
  }
`

export const CREATE_VOLVO_API_KEY_MUTATION = gql`
  mutation CreateVolvoApiKey($input: CreateVolvoApiKeyInput!) {
    createVolvoApiKey(input: $input) {
      volvoApiKey {
        ...VolvoApiKey
      }
    }
  }
  ${CompanyFragments.volvoApiKey}
`
export const PATCH_VOLVO_API_KEY_MUTATION = gql`
  mutation PatchVolvoApiKey($id: ID!, $input: PatchVolvoApiKeyInput!) {
    patchVolvoApiKey(id: $id, input: $input) {
      volvoApiKey {
        id
      }
    }
  }
`
export const DELETE_VOLVO_API_KEY_MUTATION = gql`
  mutation DeleteVolvoApiKey($id: ID!) {
    deleteVolvoApiKey(id: $id) {
      found
      deletedId
    }
  }
`

export const CREATE_FLEETBOARD_API_KEY_MUTATION = gql`
  mutation CreateFleetBoardApiKey($input: CreateFleetBoardApiKeyInput!) {
    createFleetboardApiKey(input: $input) {
      fleetBoardApiKey {
        id
        fleet
        username
        password
        active
      }
    }
  }
`
export const PATCH_FLEETBOARD_API_KEY_MUTATION = gql`
  mutation PatchFleetBoardApiKey($id: ID!, $input: PatchFleetBoardApiKeyInput!) {
    patchFleetboardApiKey(id: $id, input: $input) {
      fleetBoardApiKey {
        id
      }
    }
  }
`
export const DELETE_FLEETBOARD_API_KEY_MUTATION = gql`
  mutation DeleteFleetboardApiKey($id: ID!) {
    deleteFleetboardApiKey(id: $id) {
      found
      deletedId
    }
  }
`

export const CREATE_SCANIA_API_KEY_MUTATION = gql`
  mutation CreateScaniaApiKey($input: CreateScaniaApiKeyInput!) {
    createScaniaApiKey(input: $input) {
      scaniaApiKey {
        id
      }
    }
  }
`
export const PATCH_SCANIA_API_KEY_MUTATION = gql`
  mutation PatchScaniaApiKey($id: ID!, $input: PatchScaniaApiKeyInput!) {
    patchScaniaApiKey(id: $id, input: $input) {
      scaniaApiKey {
        id
      }
    }
  }
`
export const DELETE_SCANIA_API_KEY_MUTATION = gql`
  mutation DeleteScaniaApiKey($id: ID!) {
    deleteScaniaApiKey(id: $id) {
      found
      deletedId
    }
  }
`
export const VALIDATE_SCANIA_API_KEY_MUTATION = gql`
  mutation ValidateScaniaApiKey($input: ValidateScaniaApiKeyInput!) {
    validateScaniaApiKey(input: $input) {
      ok
      error
    }
  }
`

export const VALIDATE_VOLVO_API_KEY_MUTATION = gql`
  mutation ValidateVolvoApiKey($input: ValidateVolvoApiKeyInput!) {
    validateVolvoApiKey(input: $input) {
      ok
    }
  }
`

export const CREATE_MAN_API_KEY_MUTATION = gql`
  mutation CreateManApiKey($input: CreateManApiKeyInput!) {
    createManApiKey(input: $input) {
      manApiKey {
        id
      }
    }
  }
`

export const PATCH_MAN_API_KEY_MUTATION = gql`
  mutation PatchManApiKey($id: ID!, $input: PatchManApiKeyInput!) {
    patchManApiKey(id: $id, input: $input) {
      manApiKey {
        id
      }
    }
  }
`

export const DELETE_MAN_API_KEY_MUTATION = gql`
  mutation DeleteManApiKey($id: ID!) {
    deleteManApiKey(id: $id) {
      found
      deletedId
    }
  }
`

export const BATCH_PATCH_NOTIFICATION_SETTINGS_MUTATION = gql`
  mutation BatchPatchNotificationSettingsMutation(
    $input: [BatchPatchNotificationSettingInput]!
  ) {
    batchPatchNotificationSettings(input: $input) {
      notificationSettings {
        id
        name
        sendSms
        sendEmail
        sendInApp
      }
    }
  }
`

export const PATCH_COMPANY_NOTIFICATION_SETTINGS_MUTATION = gql`
  mutation PatchCompanyNotificationSettings(
    $id: ID!
    $input: PatchCompanyNotificationSettingsInput!
  ) {
    patchCompanyNotificationSettings(id: $id, input: $input) {
      company {
        id
        userDocumentNotificationSettings {
          id
          sendSms
          sendEmail
          sendInApp
        }
        issueCreatedNotificationSettings {
          id
          sendSms
          sendEmail
          sendInApp
        }
        issueClosedNotificationSettings {
          id
          sendSms
          sendEmail
          sendInApp
        }
        forumThreadCreatedNotificationSettings {
          id
          sendSms
          sendEmail
          sendInApp
        }
        forumPostCreatedNotificationSetting {
          id
          sendSms
          sendEmail
          sendInApp
        }
      }
    }
  }
`

export const CREATE_SALARY_WORK_TIME_MUTATION = gql`
  mutation CreateSalaryWorkTime($input: CreateSalaryWorkTimeInput!) {
    createSalaryWorkTime(input: $input) {
      salaryWorkTime {
        id
      }
    }
  }
`

export const DELETE_SALARY_WORK_TIME_MUTATION = gql`
  mutation DeleteSalaryWorkTime($id: ID!) {
    deleteSalaryWorkTime(id: $id) {
      found
      deletedId
    }
  }
`

export const PATCH_MODULE_CONFIG_MUTATION = gql`
  mutation PatchModuleConfigMutation(
    $id: ID!
    $input: PatchModuleConfigInput!
  ) {
    patchModuleConfig(id: $id, input: $input) {
      moduleConfig {
        id
      }
    }
  }
`

export const PATCH_MODULE_OPTION_MUTATION = gql`
  mutation PatchModuleOptionMutation(
    $id: ID!
    $input: PatchModuleOptionInput!
  ) {
    patchModuleOption(id: $id, input: $input) {
      moduleOption {
        id
      }
    }
  }
`

export const CREATE_DEPARTMENT_MUTATION = gql`
  mutation CreateDepartmentMutation($input: CreateDepartmentInput!) {
    createDepartment(input: $input) {
      department {
        id
      }
    }
  }
`

export const PATCH_DEPARTMENT_MUTATION = gql`
  mutation PatchDepartmentMutation($id: ID!, $input: PatchDepartmentInput!) {
    patchDepartment(id: $id, input: $input) {
      department {
        id
      }
    }
  }
`

export const DELETE_DEPARTMENT_MUTATION = gql`
  mutation DeleteDepartmentMutation($id: ID!) {
    deleteDepartment(id: $id) {
      found
      deletedId
    }
  }
`



export const CREATE_HOLIDAY_ALLOWANCE_MUTATION = gql`
  mutation CreateHolidayAllowanceMutation($input: CreateHolidayAllowanceInput!) {
    createHolidayAllowance(input: $input) {
      holidayAllowance {
        id
      }
    }
  }
`

export const PATCH_HOLIDAY_ALLOWANCE_MUTATION = gql`
  mutation PatchHolidayAllowanceMutation($id: ID!, $input: PatchHolidayAllowanceInput!) {
    patchHolidayAllowance(id: $id, input: $input) {
      holidayAllowance {
        id
      }
    }
  }
`

export const DELETE_HOLIDAY_ALLOWANCE_MUTATION = gql`
  mutation DeleteHolidayAllowanceMutation($id: ID!) {
    deleteHolidayAllowance(id: $id) {
      found
      deletedId
    }
  }
`


export const CREATE_SALARY_CODE_MUTATION = gql`
  mutation CreateSalaryCodeMutation($input: CreateSalaryCodeInput!) {
    createSalaryCode(input: $input) {
      salaryCode {
        id
      }
    }
  }
`

export const PATCH_SALARY_CODE_MUTATION = gql`
  mutation PatchSalaryCodeMutation($id: ID!, $input: PatchSalaryCodeInput!) {
    patchSalaryCode(id: $id, input: $input) {
      salaryCode {
        id
      }
    }
  }
`

export const DELETE_SALARY_CODE_MUTATION = gql`
  mutation DeleteSalaryCodeMutation($id: ID!) {
    deleteSalaryCode(id: $id) {
      found
      deletedId
    }
  }
`

export const GENERATE_FATS_REPORT = gql`
  mutation GenerateFatsReport($startDate: DateTime!, $periodLength: Int!, $maxDailyWorkLimit: Int!) {
    generateFatsReport(startDate: $startDate, periodLength: $periodLength, maxDailyWorkLimit: $maxDailyWorkLimit) {
      ok
    }
  }
`