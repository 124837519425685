import React from 'react'

export const CogFilled: React.VFC<React.SVGAttributes<SVGSVGElement>> =
  props => (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 50 50"
      enableBackground="new 0 0 50 50"
      xmlSpace="preserve"
      fill="#003057"
      {...props}
    >
      <path
        className="st0"
        d="M45.189,21.648h-1.396c-0.588,0-2.154-0.363-3.125-2.745l0,0c-0.042-0.108-0.085-0.216-0.13-0.323
	c-0.044-0.106-0.09-0.212-0.137-0.319l0,0c-0.996-2.37-0.122-3.709,0.271-4.151l0.985-0.987c1.232-1.231,1.232-3.245,0-4.477
	l-0.13-0.13l-0.13-0.13c-1.231-1.231-3.246-1.231-4.477,0l-0.987,0.987c-0.441,0.39-1.781,1.266-4.151,0.267v0.001
	c-0.106-0.047-0.213-0.094-0.319-0.138c-0.108-0.044-0.216-0.087-0.324-0.128l-0.023-0.002c-2.382-0.969-2.629-2.535-2.768-3.125
	V4.856c0-1.741-1.379-3.166-3.119-3.166h-0.184h-0.186c-1.739,0-3.212,1.424-3.212,3.166v1.395c0,0.589-0.34,2.154-2.72,3.125
	l0.011,0.002c-0.109,0.041-0.21,0.084-0.317,0.128h0.005c-0.108,0.044-0.212,0.09-0.318,0.138V9.642
	c-2.37,0.998-3.708,0.122-4.15-0.267l-0.99-0.987c-1.228-1.231-3.242-1.231-4.476,0l-0.129,0.13l-0.13,0.13
	c-1.231,1.232-1.231,3.246,0,4.477l0.986,0.987c0.416,0.415,1.228,1.82,0.236,4.182v0.001c0.029,0.029-0.076,0.196-0.12,0.304
	l0.007,0.003c-0.039,0.102-0.079,0.202-0.118,0.304l-0.002-0.001C8.454,21.285,6.889,21.613,6.3,21.65H4.903
	c-1.74,0.001-3.212,1.425-3.212,3.167v0.184v0.184c0,1.741,1.472,3.166,3.212,3.166h1.396c0.588,0.001,2.155,0.419,3.124,2.793
	h0.002c0.042,0,0.086,0.193,0.128,0.299l0.009-0.003c0.043,0.1,0.086,0.201,0.13,0.3H9.689c0.999,2.37,0.123,3.709-0.267,4.151
	l-0.986,0.987c-1.231,1.231-1.231,3.246,0,4.477l0.13,0.13l0.129,0.13c1.233,1.231,3.248,1.231,4.476,0l0.99-0.987
	c0.416-0.416,1.819-1.227,4.181-0.235h0.002c0.028-0.029,0.195,0.076,0.302,0.12l0.005-0.009c0.1,0.042,0.201,0.081,0.304,0.12
	l-0.024,0.002c2.38,0.969,2.72,2.535,2.72,3.125v1.396c0,1.741,1.473,3.164,3.212,3.164h0.186h0.184c1.74,0,3.119-1.423,3.119-3.164
	v-1.396c0-0.589,0.419-2.154,2.792-3.125v-0.002c0-0.041,0.214-0.082,0.323-0.128l0.007-0.008c0.102-0.042,0.208-0.086,0.307-0.13
	l0.003,0.001c2.37-0.999,3.709-0.122,4.153,0.267l0.985,0.987c1.232,1.231,3.248,1.231,4.477,0l0.132-0.13l0.13-0.13
	c1.23-1.231,1.232-3.246,0-4.477l-0.985-0.987c-0.394-0.441-1.27-1.781-0.271-4.151v0.001c0.046-0.106,0.093-0.178,0.137-0.318l0,0
	c0.045-0.139,0.088-0.216,0.13-0.324l0,0c0.969-2.381,2.537-2.708,3.125-2.747h1.396c1.741,0,3.119-1.424,3.119-3.166v-0.184v-0.184
	C48.308,23.073,46.93,21.648,45.189,21.648z M25.093,35.142c-5.602,0-10.142-4.541-10.142-10.142c0-5.602,4.54-10.142,10.142-10.142
	s10.142,4.54,10.142,10.142C35.235,30.599,30.695,35.142,25.093,35.142z"
      />
    </svg>
  )
