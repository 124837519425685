export interface CustomMediaOptions {
  min?: string
  max?: string
}

const theme = {
  colors: {
    primary: '#003057',
    secondary: '#00c7b1',
    tertiary: '#9ea2b6',
    quaternary: '#f6f7fb',

    lightPrimary: '#dce2ff',

    red: '#fa1852',
    orange: '#ffb600',
    green: '#53B734',
    yellow: '#ffff99',
    white: '#FFFFFF',

    background: {
      // Used in file drop area in handbooks
      purple: '#d6d6f1',
    },

    text: {
      dark: '#363639',
    },

    // Primary variants
    primary500: '#549DC2',

    // Secondary variants
    secondary500: '#00848F',

    // Red variants
    red12: '#fff9fb',
    darkRed: '#A71541',

    // Greys
    black: '#2A2F5B',
    gray1: '#363745',
    gray2: '#424455',
    gray3: '#5C5C72',
    gray4: '#767689',
    gray5: '#9696A2',
    gray6: '#AFAFB4',
    gray7: '#CACACC',
    gray8: '#E1E1E1',
    gray9: '#e9ecef',
    gray10: '#F3F3F3',
    gray11: '#f7f8fc',

    // Used for activities checkmarks
    activitiesRed: '#E8064D',
    activitiesGreen: '#07CE93',

    // Used to indicate infringement severity
    infringements: {
      green: '#8ace7e',
      yellow: '#f2c14e',
      orange: '#ef9339',
      lightRed: '#eb6424',
      red: '#880d1e',
    },

    // Used for file icons
    iconSchemes: {
      pdf: '#f40f02',
      word: '#2b579a',
      powerPoint: '#d24726',
      excel: '#217346',
      oneNote: '#80397b',
      outlook: '#0072c6',
      oneDrive: '#094ab2',
    },
  },
  shadow: {
    default: '2px 6px 24px 0px #00305718',
    bottom: '0 3px 3px rgb(0 0 0 / 15%)',
  },
  layout: {
    headerHeight: '92px',
    headerHeightMobile: '78px',
    footerHeightDefault: '87px',
    footerHeightMobile: '140px',
    standardModuleHeaderHeight: '123px',
    sidebarWidth: '250px',
    sidebarClosedWidth: '80px',
    collapsedSidebarWidth: '40px',
    default: `
      padding: 32px 52px 52px;
      box-sizing: border-box;

      @media screen and (max-width: 768px){
        padding: 1rem;
      }
    `,

    defaultBorder: '1px solid #E1E1E1',

    small: `@media screen and (max-width: 768px)`,
    mobile: `@media screen and (max-width: 768px)`,
    large: `@media screen and (min-width: 769px)`,
    desktop: `@media screen and (min-width: 769px)`,
    largePad: `@media screen and (min-width: 769px) and (max-width: 1200px)`,
  },
  sizes: {
    defaultBorderRadius: '12px',
  },
  media: {
    mobile: '@media screen and (max-width: 768px)',
    largePad: '@media screen and (min-width: 769px) and (max-width: 1280px)',
    mediumScreen:
      '@media screen and (min-width: 1281px) and (max-width: 1620px)',
    custom: ({ min, max }: CustomMediaOptions) =>
      `@media screen ${!!min ? `and (min-width: ${min})` : ''} ${
        !!max ? `and (max-width: ${max})` : ''
      }`,
  },
  standardBorder: '1px solid #E1E1E1',
  standardBorderRadius: '12px',
  screenLarge: '@media screen and (max-width: 1600px)',
  screenMedium: '@media screen and (max-width: 768px)',
  screenSmall: '@media screen and (max-width: 400px)',
  defaultContentWrapper: `
    box-sizing: border-box;
    width: 100%;
    padding: 1.5rem 2rem;
    position: relative;
  `,
  resetButton: `
    appearance: none;
    border: none;
    background: none;
  `,
}

export default theme
