import { PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import {
  RegularModal as BaseRegularModal,
  DatePicker,
  NumberInput,
  Input,
  Button,
  Checkbox,
  UserSelect,
} from 'components'
import { differenceInWeeks, startOfDay } from 'date-fns'
import { Field, Form } from 'modules/users/UserSettings/components'
import { VehicleDocumentNode } from 'modules/vehicles'
import {
  EditVehicleDocumentForm,
  EditVehicleDocumentPromptResolve,
} from 'modules/vehicles/types'
import { showFileDialog } from 'util/file'
import styled from 'styled-components'
import { useState } from 'react'

const UploadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const UploadButton = styled(Button)`
  background-color: ${props => props.theme.colors.gray8};
  color: ${props => props.theme.colors.gray3};
  padding: 1rem 1.2rem;
  height: 43px;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
  &:hover {
    background-color: ${props => props.theme.colors.gray9};
  }
`
const Filename = styled.div`
  padding: 0 1rem;
  max-width: 70%;
`
const RegularModal = styled(BaseRegularModal)`
  box-shadow: ${props => props.theme.shadow.default};
`

interface EditVehicleDocumentModalProps {
  vehicleDocument?: VehicleDocumentNode
  onSubmit: PromptResolve<EditVehicleDocumentPromptResolve | null>
}

export const EditVehicleDocumentModal: React.VFC<EditVehicleDocumentModalProps> =
  ({ vehicleDocument, onSubmit }) => {
    const translations = useTranslate({
      newDocument: 'vehicles.new-vehicle-document',
      editDocument: 'vehicles.edit-vehicle-document',
      name: 'common.name',
      description: 'common.description',
      hasNotification: 'settings.has-notification',
      expirationDate: 'documents.expiration-date',
      notificationTime: 'documents.notification-time',
      uploadFile: 'documents.upload-file',
      editFile: 'documents.edit-file',
      responsibleUser: 'common.responsible-user',
      validation: {
        required: 'common.required',
        noFileSelected: 'vehicles.no-file-selected',
      },
    })

    const editing = typeof vehicleDocument !== 'undefined'
    const [hasNotification, setHasNotification] = useState(
      !!vehicleDocument?.notificationTime || false
    )

    const expirationDate = vehicleDocument?.expirationDate
      ? new Date(vehicleDocument.expirationDate)
      : null
    const notificationTime =
      !vehicleDocument?.expirationDate || !vehicleDocument?.notificationTime
        ? 1
        : differenceInWeeks(
            new Date(vehicleDocument.expirationDate),
            startOfDay(new Date(vehicleDocument.notificationTime))
          )

    const {
      formValues: form,
      formErrors: errors,
      formValid,
      updateForm,
      formChangeHandler: handler,
      submitHandler: submit,
    } = useForm<EditVehicleDocumentForm>({
      values: {
        name: vehicleDocument?.name || '',
        description: vehicleDocument?.description || '',
        expirationDate: expirationDate,
        notificationTime: notificationTime,
        file: vehicleDocument?.file || null,
        responsibleUser: vehicleDocument?.responsibleUser?.id || null,
      },
      validators: {
        name: val => (!!val ? null : translations.validation.required),
        file: val => (!!val ? null : translations.validation.noFileSelected),
      },
    })

    async function handleUpload(files: File[]) {
      if (!files.length) return
      const file = files[0]
      updateForm({
        name: file.name,
        file,
      })
    }

    return (
      <RegularModal
        title={editing ? translations.editDocument : translations.newDocument}
        width="480px"
        submitDisabled={!formValid}
        submitText="Save"
        cancelOnClickOutside
        onCancel={() => onSubmit(null)}
        onSubmit={submit(form => onSubmit({ form, hasNotification }))}
      >
        <Form>
          {!!form.file && (
            <>
              <Field>
                <label>{translations.name}</label>
                <Input
                  value={form.name}
                  error={errors.name}
                  fullWidth
                  autoFocus
                  onChange={handler('name')}
                />
              </Field>
              <Field>
                <label>{translations.description}</label>
                <Input
                  value={form.description}
                  error={errors.description}
                  fullWidth
                  autoFocus
                  onChange={handler('description')}
                />
              </Field>
            </>
          )}
          {!editing && (
            <Field>
              <label>{translations.uploadFile}</label>

              <UploadWrapper>
                <UploadButton
                  iconLeftProps={{ icon: 'paperclip' }}
                  onClick={() => showFileDialog(handleUpload)}
                >
                  {!!form.file
                    ? translations.editFile
                    : translations.uploadFile}
                </UploadButton>
                {!!form.file && (
                  <Filename>
                    {typeof form.file === 'object' && form.file.name}
                  </Filename>
                )}
              </UploadWrapper>
            </Field>
          )}
          {!!form.file && (
            <Field alignLeft>
              <Checkbox
                checked={hasNotification}
                label={translations.hasNotification}
                boxSize="32px"
                onChange={setHasNotification}
              />
            </Field>
          )}
          {!!form.file && hasNotification && (
            <>
              <Field>
                <label>{translations.responsibleUser}</label>
                <UserSelect
                  value={form.responsibleUser}
                  fullWidth
                  onChange={handler('responsibleUser')}
                />
              </Field>
              <Field>
                <label>{translations.expirationDate}</label>
                <DatePicker
                  value={form.expirationDate ?? new Date()}
                  fullWidth
                  onChange={handler('expirationDate')}
                />
              </Field>
              <Field>
                <label>{translations.notificationTime}</label>
                <NumberInput
                  value={form.notificationTime ?? 1}
                  min={0}
                  fullWidth
                  onChange={handler('notificationTime')}
                />
              </Field>
            </>
          )}
        </Form>
      </RegularModal>
    )
  }
