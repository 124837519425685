import { useEffect, useState, useCallback } from 'react'
import lodashMerge from 'lodash/merge'

type TransformerFn<T> = (item: string) => T

type UpdateValueFn<T> = (newValue: string | T, merge?: boolean) => void
type UseLocalStorageReturn<T> = [T | null, UpdateValueFn<T>, () => void]

function getLSItem(key: string) {
  return localStorage.getItem(key)
}
function setLSItem(key: string, value: string) {
  localStorage.setItem(key, value)
}
function removeLSItem(key: string) {
  localStorage.removeItem(key)
}

/**
 * Retrieve and edit localStorage objects
 * @param key localStorage key
 */
function useLocalStorage<T>(key: string): UseLocalStorageReturn<T>
/**
 * Retrieve, transform and edit localStorage objects
 * @param key localStorage key
 * @param transformer A function to apply to localStorage string, to transform it into another object
 * @param initialValue Set to this initial value if ls item does not exist|
 */
function useLocalStorage<T>(
  key: string,
  transformer: TransformerFn<T>,
  initialValue?: T
): UseLocalStorageReturn<T>
function useLocalStorage<T>(
  key: string,
  transformer?: TransformerFn<T>,
  initialValue?: T
): UseLocalStorageReturn<string | T> {
  const [value, setValue] = useState<string | null>(getLSItem(key))

  useEffect(() => {
    setValue(getLSItem(key))
  }, [key])

  const updateValue = useCallback(
    (newValue: T | string, merge = false) => {
      if (
        merge &&
        value !== null &&
        typeof transformer !== 'undefined' &&
        typeof newValue === 'object'
      )
        newValue = lodashMerge(transformer(value), newValue)
      if (typeof newValue !== 'string') newValue = JSON.stringify(newValue)

      setLSItem(key, newValue)
      setValue(newValue)
    },
    [key, value, transformer]
  )

  useEffect(() => {
    if (typeof initialValue !== 'undefined') {
      const cur = getLSItem(key)
      if (cur === null) updateValue(initialValue)
    }
    // eslint-disable-next-line
  }, [])

  const remove = useCallback(() => {
    removeLSItem(key)
  }, [key])

  if (value === null) return [null, updateValue, remove]

  return typeof transformer !== 'undefined'
    ? [transformer(value), updateValue, remove]
    : [value, updateValue, remove]
}
export { useLocalStorage }
