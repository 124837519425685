import { PlainLink } from 'components/Link'

import { Cell } from 'components/Table/Cell'
import { useInfringementSeverityFunctions } from 'modules/infringements'
import { InfringementSeverities } from 'modules/infringements/consts'
import { SeverityCategory } from 'modules/infringements/types'
import { UserNode } from 'modules/users'
import { Link } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import { removeCurrentYear } from 'util/date'
import { format, formatDistanceToNow } from 'util/date-fns'
import { safeParsePhoneNumber } from 'util/parsing'

export const CenteredTextCell = styled(Cell)`
  & > section {
    text-align: center;
    padding: 0.75rem;
  }
`

export const CenteredTableHeader = styled.th`
  text-align: center !important;
`

/* EmployeeCell */

const StyledEmployeeCell = styled(Cell)`
  div {
    font-style: normal;
    font-weight: 600;
  }
  a.phone {
    font-size: 0.8em;
    color: ${props => props.theme.colors.gray6};

    &:hover {
      text-decoration: none;
      color: ${props => props.theme.colors.secondary};
    }
  }
`
interface EmployeeCellProps {
  user: Pick<UserNode, 'id' | 'fullName' | 'phoneNumber'>
}
export const EmployeeCell: React.VFC<EmployeeCellProps> = ({ user }) => {
  const phone = safeParsePhoneNumber(user.phoneNumber)

  return (
    <StyledEmployeeCell>
      <div>
        <Link to={`/infringements/user/${user.id}`}>{user.fullName}</Link>
      </div>
      {!!user.phoneNumber && (
        <a className="phone" href={`tel:${user.phoneNumber}`}>
          {phone}
        </a>
      )}
    </StyledEmployeeCell>
  )
}

export const EmployeeInfringementCell: React.VFC<EmployeeCellProps> = ({
  user,
}) => {
  const phone = safeParsePhoneNumber(user.phoneNumber)

  return (
    <StyledEmployeeCell>
      <div>
        <Link to={`/infringements/user/${user.id}`}>{user.fullName}</Link>
      </div>
      {!!user.phoneNumber && (
        <a className="phone" href={`tel:${user.phoneNumber}`}>
          {phone}
        </a>
      )}
    </StyledEmployeeCell>
  )
}

/* SeverityCell */

interface StyledTypeProps {
  color: string
  severity: SeverityCategory
}
const StyledType = styled.div<StyledTypeProps>`
  display: flex;

  & > div {
    padding: 9px 18px 6px;

    border-radius: 999px;
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    color: ${props => props.theme.colors.white};
    ${props =>
      props.severity === InfringementSeverities.MINOR &&
      `
      background-color: ${props.theme.colors.infringements.yellow};
    `}
    ${props =>
      props.severity === InfringementSeverities.SERIOUS &&
      `
      background-color: ${props.theme.colors.infringements.orange};
    `}
    ${props =>
      props.severity === InfringementSeverities.VERY_SERIOUS &&
      `
      background-color: ${props.theme.colors.infringements.lightRed};
    `}
    ${props =>
      props.severity === InfringementSeverities.MOST_SERIOUS &&
      `
      background-color: ${props.theme.colors.infringements.red};
    `}
  }
`

interface SeverityProps {
  className?: string

  severity: SeverityCategory
}
export const SeverityContent: React.VFC<SeverityProps> = ({
  className,
  severity,
}) => {
  const theme = useTheme()

  const { getSeverityTranslation } = useInfringementSeverityFunctions()
  const severityDisplayText = getSeverityTranslation(severity)

  return (
    <StyledType
      className={className}
      severity={severity}
      color={
        severity === InfringementSeverities.MOST_SERIOUS ||
        severity === InfringementSeverities.SERIOUS
          ? theme.colors.white
          : 'inherit'
      }
    >
      <div>{severityDisplayText}</div>
    </StyledType>
  )
}

export const SeverityCell: React.VFC<SeverityProps> = ({
  ...severityProps
}) => (
  <Cell>
    <SeverityContent {...severityProps} />
  </Cell>
)

/* DateCell */

const StyledDateCell = styled(Cell)`
  div {
    font-style: normal;
    font-weight: 600;
  }
  span {
    font-size: 0.8em;
    color: ${props => props.theme.colors.gray6};
  }
`
interface DateCellProps {
  infringementId: string
  createdAt: string
}
export const DateCell: React.VFC<DateCellProps> = ({
  infringementId,
  createdAt,
}) => {
  const date = new Date(createdAt)
  const dateString = removeCurrentYear(format(new Date(createdAt), 'PPP'))
  const timeAgo = formatDistanceToNow(date, { addSuffix: true })

  return (
    <StyledDateCell narrow>
      <PlainLink to={`/infringements/${infringementId}`} hoverColor="secondary">
        <div>{dateString}</div>
        <span>{timeAgo}</span>
      </PlainLink>
    </StyledDateCell>
  )
}

/* InfringementsTotalCell */

interface StyledInfringementsTotalCellProps {
  infringementsTotalValue: number
}

const StyledInfringementsTotalCell = styled(
  Cell
)<StyledInfringementsTotalCellProps>`
  span {
    font-style: normal;
    font-weight: 600;
    text-align: center;
    padding: 0.75rem;
    ${props =>
      props.infringementsTotalValue < 10 &&
      `
      background-color: ${props.theme.colors.infringements.green};
    `}
    ${props =>
      props.infringementsTotalValue >= 10 &&
      `
      background-color: ${props.theme.colors.infringements.orange};
    `}
    ${props =>
      props.infringementsTotalValue >= 40 &&
      `
      background-color: ${props.theme.colors.infringements.red};
    `}

    color: ${props =>
      props.infringementsTotalValue < 10
        ? props.theme.colors.white
        : props.theme.colors.white};

    border-radius: 1000px;
    width: 60px;
    margin: auto;
  }
`
interface InfringementsTotalCellProps {
  id: string
  count: number
  infringementsTotalValue: number
}
export const InfringementsTotalCell: React.VFC<InfringementsTotalCellProps> = ({
  id,
  count,
  infringementsTotalValue,
}) => {
  return (
    <StyledInfringementsTotalCell
      infringementsTotalValue={infringementsTotalValue}
      narrow
    >
      <span>{count}</span>
    </StyledInfringementsTotalCell>
  )
}
