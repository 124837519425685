import { useRoutePlannerMutations } from 'modules/routePlanner/mutations.hooks'
import { CreateRoutePlanForm } from 'modules/routePlanner/types'
import { CreateRoutePlanMutationInput } from 'modules/routePlanner/types.graphql'

export function useCreateRoutePlanAPI() {
  /**
   * This is the main entry point for the CreateRoutePlanForm component. This is an API
   * layer whose sole responsibility is send the actual API request and also allows us to query
   * default values and other data that is needed to populate the form. This might not always be the
   * case but the abstractions allows for a clean separation of responsibilities.
   */
  const { createMutation } = useRoutePlannerMutations()

  async function handleSubmit(data: CreateRoutePlanMutationInput) {
    // Notice that we return a promise since the actual invocation is delegated to the logic layer
    return createMutation({
      variables: {
        ...data,
      },
    })
  }

  // Here we can do nullish coalescing with sensible default values if we have to query any
  // default data from the backend. This implies a zero-trust approach to the data received.
  const defaultValues: CreateRoutePlanForm = {
    startLocation: '',
    startLocationLatitude: null,
    startLocationLongitude: null,

    endLocation: '',
    endLocationLatitude: null,
    endLocationLongitude: null,

    startDate: new Date(),
    startTime: new Date(),
    includeDepartureTime: true,

    width: 0,
    height: 0,
    length: 0,
    topSpeed: 0,

    useAxelWeightRestriction: false,

    weightRestriction: null,
    singleWeightRestriction: null,
    tandemWeightRestriction: null,
    tripleWeightRestriction: null,

    payloadWeight: 0,
    trailerWeight: 0,
    grossWeight: 0,
  }

  return {
    defaultValues,
    onSubmit: handleSubmit,
  }
}
