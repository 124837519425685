import gql from 'graphql-tag'

export const CREATE_QUILL_MEDIA_MUTATION = gql`
  mutation CreateQuillMedia($input: CreateQuillMediaInput!) {
    createQuillMedia(input: $input) {
      quillMedia {
        id
        name
        file
      }
    }
  }
`
