import { gql } from '@apollo/client'
import { TimesheetFragments } from './fragments'
import { UserFragments } from 'modules/users/fragments'

export const USER_TIME_ENTRIES_QUERY = gql`
  query UserTimeEntries(
    $user: ID
    $date: DateTime
    $nextDay: DateTime
  ) {
    allTimeEntries(
      user: $user
      datetimeStart_Gt: $date
      datetimeStart_Lt: $nextDay
    ) {
      edges {
        node {
          id

          datetimeStart
          datetimeEnd

          comment
          overtimeMode

          totalWork
          pause
          overtime

          user {
            id
            fullName
            userTypes {
              id
            }
          }
        }
      }
    }
  }
`

export const TIME_ENTRY_QUERY = gql`
  query TimeEntry($id: ID!) {
    timeEntry(id: $id) {
      ...TimeEntryInfo
    }
  }
  ${TimesheetFragments.timeEntryInfo}
`

export const ABSENCE_QUERY = gql`
  query Absence($id: ID!) {
    absence(id: $id) {
      ...AbsenceInfo
    }
    allAbsenceTypes {
      edges {
        node {
          id
          name
        }
      }
    }
  }
  ${TimesheetFragments.absenceInfo}
`

export const USER_ABSENCES_QUERY = gql`
  query UserAbsences(
    $user: ID
    $users: [ID]
    $absenceEndDate: DateTime!
    $absenceStartDate: DateTime!
    $absenceType: ID
    $requestStatus: String
  ) {
    allAbsences(
      user: $user
      users: $users
      datetimeEnd_Gte: $absenceStartDate
      datetimeStart_Lt: $absenceEndDate
      absenceType: $absenceType
      requestStatus: $requestStatus
    ) {
      edges {
        node {
          id

          datetimeStart
          datetimeEnd
          reason
          requestStatus

          user {
            id
            fullName
          }
          absenceType {
            id
            name
            color
          }
        }
      }
    }
  }
`

export const ALL_ABSENCES_QUERY = gql`
  query AllAbsences($first: Int!, $orderBy: String) {
    allAbsences(first: $first, orderBy: $orderBy) {
      edges {
        node {
          ...AbsenceInfo
          user {
            ...UserInfo
          }
        }
      }
    }
  }
  ${TimesheetFragments.absenceInfo}
  ${UserFragments.info}
`

export const ALL_ABSENCE_TYPES_QUERY = gql`
  query AllAbsenceTypes {
    allAbsenceTypes {
      edges {
        node {
          id
          externalId
          name
          color
        }
      }
    }
  }
`

export const ALL_TIME_ENTRY_PROJECTS_QUERY = gql`
  query AllTimeEntryProjects($first: Int, $orderBy: String, $q: String) {
    allTimeEntryProjects(first: $first, orderBy: $orderBy, q: $q) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const TIMESHEETS_REPORT_QUERY = gql`
  query TimesheetsReport(
    $userId: ID!
    $startDate: DateTime!
    $endDate: DateTime!
    $includeWholeWeeks: Boolean
  ) {
    timesheetsReport(userId: $userId, startDate: $startDate, endDate: $endDate, includeWholeWeeks: $includeWholeWeeks) {
      totalOrdinary
      totalWork
      totalPause
      overtime
      user {
        id
        fullName
        userTypes {
          id
          name
        }
      }
      salaryWorkTime {
        id
        pauseThreshold
        pauseDuration
        availableDepartments {
          id
          name
        }
        defaultDepartment {
          id
          name
        }
        dailyWorkLimit
        usePeriodOvertime
      }
      periodReports {

        periodStart
        periodEnd
        
        totalOrdinary
        totalWork
        totalPause

        periodOvertime
        dayOvertime
        overtime

        dayReports {

          totalOrdinary
          totalWork
          totalOvertime
          totalPause

          day
          dayStart
          dayEnd

          comments

          project {
            id
            name
          }
          department {
            id
            name
          }

          timeEntries {
            id
            comment
            datetimeStart
            datetimeEnd

            totalOrdinary
            totalWork
            pause
            user {
              id
            }
            diet
            salaryCode {
              id
              name
              externalId
            }
            project {
              id
              name
            }
            department {
              id
              name
            }
          }
          absences {
            id
            datetimeStart
            datetimeEnd
            reason
            absenceType {
              id
              name
            }
            user {
              id
              fullName
              userTypes {
                id
              }
            }
          }
          overtimeEntries {
            startTime
            endTime
            overtime
            salaryCode {
              id
              name
              externalId
            }
            project {
              id
              name
            }
            department {
              id
              name
            }
          }
        }
      }
    }
  }
`
