import { Icon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { TableMenuItem } from 'components/Table'
import {
  CalendarPopupHeader,
  TimesIconWrapper,
} from 'components/Calendar/components/components'
import { PopupMenu } from 'components/PopupMenu'
import { parseISO } from 'date-fns'
import { Module } from 'modules/companies'
import { useRef, useState } from 'react'
import styled from 'styled-components'
import { format } from 'util/date-fns'
import { useAdmin, useModuleResponsible, usePermissions } from 'util/hooks'
import useClickOutside from 'util/hooks/useClickOutside'
import { PERMISSIONS } from 'util/permissions'
import { AbsenceNode } from '..'
import { AbsenceStatusDisplay } from './AbsenceStatusDisplay'
import { useAbsenceHandler } from './handler.hooks'

export const OuterPopupWrapper = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 1rem;
  border-radius: ${props => props.theme.standardBorderRadius};
  box-shadow: ${props => props.theme.shadow.default};
`

const AbsenceCalendarPopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 340px;
`

const AbsenceCalendarPopupHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 24px;
  padding-bottom: 2rem;
  line-height: 20px;
`

const AbsenceCalendarPopupHeaderButtons = styled.div`
  display: flex;
  gap: 1rem;
`

interface AbsenceCalendarPopupProps {
  absence: AbsenceNode
  onClickOutside: () => void
}

export const AbsenceCalendarPopup: React.VFC<AbsenceCalendarPopupProps> = ({
  absence,
  onClickOutside,
}) => {
  const admin = useAdmin()

  const translations = useTranslate({
    editAbsence: 'timesheets.edit-absence',
    editAbsenceStatus: 'timesheets.edit-absence-status',
    deleteAbsence: 'timesheets.delete-absence',
    absenceType: 'timesheets.absence-type',
    reason: 'common.reason',

    prompt: {
      deleteAbsence: 'timesheets.prompt.delete-absence',
      deleteAbsenceTitle: 'timesheets.prompt.delete-absence-title',
    },
  })

  const { isResponsibleUser } = useModuleResponsible(Module.ABSENCES)

  const [promptActive, setPromptActive] = useState(false)

  const { hasPermissionsAndMe } = usePermissions()

  const canEdit = (id: string) =>
    admin || hasPermissionsAndMe({ id }, PERMISSIONS.timesheets.change.absence)
  const canDelete = (id: string) =>
    admin || hasPermissionsAndMe({ id }, PERMISSIONS.timesheets.delete.absence)
  const canEditAbsenceStatus = admin || isResponsibleUser

  const wrapperRef = useRef<HTMLDivElement | null>(null)

  const displayStartDate = format(parseISO(absence.datetimeStart), 'EEEE dd.MM')
  const displayEndDate = format(parseISO(absence.datetimeEnd), 'EEEE dd.MM')

  const absenceHandler = useAbsenceHandler()

  function handleDeleteAbsence() {
    absenceHandler.delete(absence, setPromptActive)
    onClickOutside()
  }

  function createMenuItems(entry: AbsenceNode) {
    const items: TableMenuItem<AbsenceNode>[] = []

    if (canEdit(entry.user.id))
      items.push({
        label: translations.editAbsence,
        role: 'link',
        onClick: () => absenceHandler.edit(absence, setPromptActive),
      })
    if (canEditAbsenceStatus) {
      items.push({
        label: translations.editAbsenceStatus,
        role: 'link',
        onClick: () => absenceHandler.editStatus(absence, setPromptActive),
      })
    }
    if (canDelete(entry.user.id))
      items.push({
        label: translations.deleteAbsence,
        onClick: () => handleDeleteAbsence(),
      })
    return items
  }

  const menuItems = createMenuItems(absence)

  useClickOutside(wrapperRef, onClickOutside, true, !promptActive)

  return (
    <AbsenceCalendarPopupWrapper ref={wrapperRef}>
      <AbsenceCalendarPopupHeader>
        <AbsenceStatusDisplay status={absence.requestStatus} />
        <AbsenceCalendarPopupHeaderButtons>
          <PopupMenu items={menuItems} />
          <TimesIconWrapper>
            <Icon
              icon="times"
              type="light"
              size="1.6rem"
              color="gray5"
              onClick={onClickOutside}
            />
          </TimesIconWrapper>
        </AbsenceCalendarPopupHeaderButtons>
      </AbsenceCalendarPopupHeader>
      <CalendarPopupHeader>{absence.user.fullName}</CalendarPopupHeader>
      <p>
        {displayStartDate} - {displayEndDate}
      </p>
      <div>
        <b>{translations.absenceType}:</b> {absence.absenceType.name}
      </div>
      <div>
        <b>{translations.reason}:</b> {absence.reason}
      </div>
    </AbsenceCalendarPopupWrapper>
  )
}
