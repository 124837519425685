import { useToast } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { CheckedTableValue, UnsavedChanges } from 'components'
import groupBy from 'lodash/groupBy'
import { UPDATE_COMPANY_USER_TYPES_DROPDOWNS_MUTATION } from 'modules/companies/mutations'
import {
  UpdateCompanyUserTypesDropdownsMutation,
  UpdateCompanyUserTypesDropdownsMutationVariables,
  UpdatedUserTypesDropdownInput,
} from 'modules/companies/types.graphql'
import { UserTypesWithDropdownsQuery } from 'modules/users'
import { USER_TYPES_WITH_DROPDOWNS_QUERY } from 'modules/users/queries'
import React, { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { useBreadcrumbs, useNavigateConfirm, useOnErrorAuto } from 'util/hooks'
import { DropdownsSettingsTable } from '../components'
import {
  InfoBox,
  Section,
  SectionHeader,
  SettingsWrapper,
} from '../components/common'

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  h2 {
    margin-bottom: 0;
  }
`

interface DropdownsSettingsProps {}

export const DropdownsSettings: React.VFC<DropdownsSettingsProps> = () => {
  const translations = useTranslate({
    dropdowns: 'common.dropdowns',

    info: 'settings.info.dropdowns',

    prompt: {
      unsavedChanges: 'common.you-have-unsaved-changes',
      unsavedChangesNavigate: 'common.you-have-unsaved-changes-navigate',
    },
    toast: {
      success: 'settings.toasts.update-dropdowns-success',
      error: 'settings.errors.update-dropdowns',
    },
  })

  const { setOverride, overrides } = useBreadcrumbs()
  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()

  const [dropdownDiff, setDropdownDiff] = useState<CheckedTableValue[]>([])

  useEffect(() => {
    if (overrides['select-users']) return
    setOverride('select-users', translations.dropdowns)
  }, [overrides, setOverride, translations.dropdowns])

  useNavigateConfirm(
    dropdownDiff.length > 0,
    translations.prompt.unsavedChangesNavigate
  )

  const { data: queryData, loading: queryLoading } = useQuery<
    UserTypesWithDropdownsQuery,
    never
  >(USER_TYPES_WITH_DROPDOWNS_QUERY, {
    onError: onErrorAuto(),
  })

  const [updateUserTypes, { loading: updateLoading }] = useMutation<
    UpdateCompanyUserTypesDropdownsMutation,
    UpdateCompanyUserTypesDropdownsMutationVariables
  >(UPDATE_COMPANY_USER_TYPES_DROPDOWNS_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['UserTypesWithDropdowns', 'Bootstrap'],
    onCompleted() {
      addToast('success', translations.toast.success)
    },
    onError: onErrorAuto(translations.toast.error),
  })

  const userTypes = useMemo(
    () =>
      queryData?.allUserTypes.edges
        .map(edge => edge.node)
        .sort((a, b) => a.name.localeCompare(b.name)) ?? [],
    [queryData]
  )

  function handleSave() {
    const grouped = groupBy(dropdownDiff, 'column')
    const updatedUserTypes: UpdatedUserTypesDropdownInput[] = Object.entries(
      grouped
    ).map(([id, dropdowns]) => ({
      id,
      dropdowns: dropdowns.map(({ row, checked }) => ({
        dropdown: row,
        value: checked,
      })),
    }))

    updateUserTypes({
      variables: { updatedUserTypes },
    })
  }

  const isLoading = queryLoading || updateLoading

  return (
    <SettingsWrapper grid={{ flow: 'row' }}>
      <InfoBox initCollapsed={isMobileOnly}>{translations.info}</InfoBox>

      <Section>
        <Header>
          <SectionHeader loading={isLoading}>
            {translations.dropdowns}
          </SectionHeader>
        </Header>

        <DropdownsSettingsTable
          userTypes={userTypes}
          onChange={setDropdownDiff}
        />
      </Section>

      <UnsavedChanges
        show={dropdownDiff.length > 0}
        message={translations.prompt.unsavedChanges}
        onSave={handleSave}
      />
    </SettingsWrapper>
  )
}
