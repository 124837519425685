import React from 'react'

export const Infringement: React.VFC<React.SVGAttributes<SVGSVGElement>> =
  props => (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 21.2211 19.0433"
      enableBackground="new 0 0 21.2211 19.0433"
      xmlSpace="preserve"
      fill="#003057"
      {...props}
    >
      <path d="M16.2152,5.7492l-.09-.11.69-.69a.2535.2535,0,0,0,0-.36l-.19-.2a.263.263,0,0,0-.36,0l-.69.69-.11-.09a7.341,7.341,0,0,0-4.36-1.79l-.14-.01v-1.39h.99a.2562.2562,0,0,0,.26-.25v-.27a.2647.2647,0,0,0-.26-.26h-2.7a.2647.2647,0,0,0-.26.26v.27a.2562.2562,0,0,0,.26.25h.93v1.39l-.14.02a7.4166,7.4166,0,1,0,6.17,2.54Zm-5.6,11.49a6.6351,6.6351,0,1,1,6.63-6.64A6.6493,6.6493,0,0,1,10.6152,17.2392Z" />
      <path d="M11.1852,14.2492a.5679.5679,0,0,1-.41.41.6.6,0,0,1-.74-.73.6031.6031,0,0,1,.41-.41.9962.9962,0,0,1,.16-.02.631.631,0,0,1,.43.17A.6123.6123,0,0,1,11.1852,14.2492Z" />
      <path d="M11.2052,6.7691v4.89a.6132.6132,0,0,1-.19.44.6.6,0,0,1-.46.15.6136.6136,0,0,1-.54-.63v-4.85a.6017.6017,0,0,1,.6-.6A.5933.5933,0,0,1,11.2052,6.7691Z" />
    </svg>
  )
