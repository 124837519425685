import {
  ToggleProps as BaseToggleProps,
} from '@ur/react-components'
import { SVGIconProps } from 'components/icons'
import React from 'react'
import { IconMode, Toggle, ToggleIconProps } from './Toggle'
import { useTranslate } from '@ur/react-hooks'



interface ToggleProps extends BaseToggleProps {
  iconMode?: keyof typeof IconMode

  offIconProps?: ToggleIconProps
  onIconProps?: ToggleIconProps

  localOffIconProps?: SVGIconProps
  localOnIconProps?: SVGIconProps

  leftText?: string
  rightText?: string

  thumbBackground?: string

  onChange: (value: boolean) => void
}

export const MonthWeekToggle: React.VFC<ToggleProps> = ({
  ...toggleProps
}) => {
  const translations = useTranslate({
    month: 'common.month',
    week: 'common.week',
  })
  
  const iconProps: Omit<ToggleIconProps, 'icon'> = {
    size: '1.4rem',
    type: 'solid',
    swapOpacity: true,
  }

  return (
    <Toggle
      leftText={translations.month}
      rightText={translations.week}
      iconMode={IconMode.textAndIcon}
      value={toggleProps.value}
      offIconProps={{
        icon: 'calendar-alt',
        ...iconProps,
        color: !toggleProps.value ? 'white' : undefined,
      }}
      onIconProps={{
        icon: 'calendar-week',
        ...iconProps,
        color: toggleProps.value ? 'white' : undefined,
      }}
      thumbBackground="secondary"
      height="3.875rem"
      disabled={toggleProps.disabled}
      onChange={toggleProps.onChange}
    />
  )
}
