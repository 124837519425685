import { Icon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { Table } from 'components'
import { Color, SettingsColorPicker } from 'modules/companies'
import React from 'react'
import { AbsenceTypesListProps, SimpleAbsenceType } from './types'

interface AbsenceSettingsTableProps extends AbsenceTypesListProps {
  temporaryEditColor: {
    [id: string]: string | null
  }
  onUpdateColor: (categoryId: string) => (color: string) => void
}

export const AbsenceSettingsTable: React.VFC<AbsenceSettingsTableProps> = ({
  absenceTypes,
  temporaryEditColor,

  onEditSubmit,
  onDelete,
  onUpdateColor,
}) => {
  const translations = useTranslate({
    name: 'common.name',

    add: 'common.add',
    edit: 'common.edit',
    delete: 'common.delete-alt',
    cancel: 'common.cancel',
    id: 'common.id'
  })

  function handleEditClick(type: SimpleAbsenceType) {
    onEditSubmit(type)
  }

  return (
    <Table
      noShadow
      noBorders
      noHeaderMargin
      noRowMargin
      rowHoverColor="secondary"
    >
      <thead>
        <tr>
          <th />
          <th>{translations.name}</th>
          <th colSpan={3}>{translations.id}</th>
        </tr>
      </thead>
      <tbody>
        {absenceTypes.map(type => (
          <tr key={type.id}>
            <td width="1px">
              <Color color={type.color}>
                <SettingsColorPicker
                  value={temporaryEditColor[type.id] ?? type.color}
                  onChange={onUpdateColor(type.id)}
                />
              </Color>
            </td>
            <td>{type.name}</td>
            <td>{type.externalId}</td>
            <td width="1px">
              <Icon
                icon="edit"
                cursor="pointer"
                color="gray6"
                hoverColor="secondary"
                onClick={() => handleEditClick(type)}
              />
            </td>

            <td width="1px">
              <Icon
                icon="times"
                cursor="pointer"
                color="gray6"
                hoverColor="red"
                onClick={() => onDelete(type)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
