import React from 'react'
import styled, { css } from 'styled-components'
import { Card as BaseCard } from 'components/Card'
import { ThreadNodeForForumThreads } from '../../types.graphql'
import { formatDistanceToNow } from 'util/date-fns'
import { useTranslate } from '@ur/react-hooks'
import capitalize from 'lodash/capitalize'
import { Link } from 'react-router-dom'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  font-size: 0.8rem;

  & > div + div {
    margin-top: 1rem;
  }
`
interface CardProps {
  sticky: boolean
}
const Card = styled(BaseCard)<CardProps>`
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'title    title'
    'creation creation';

  overflow: hidden;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  a {
    color: inherit;
  }

  div.comments {
    grid-area: comments;
  }
  p {
    grid-area: creation;
    margin: 8px 0 0 0;

    em {
      font-style: normal;
      font-weight: 600;
    }
  }

  ${props =>
    props.sticky &&
    css`
      padding-top: 1rem;
      padding-bottom: 1rem;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 6px;
        height: calc(100% - 1rem);
        transform: translateY(-50%);

        border-radius: 0 999px 999px 0;
        background-color: ${props => props.theme.colors.orange};
      }
    `};
`
interface TitleProps {
  $seen: boolean
}
const Title = styled(Link)<TitleProps>`
  grid-area: title;
  font-size: 1.2em;
  font-weight: ${props => (props.$seen ? 500 : 800)};
`

interface ThreadsCardsProps {
  threads: ThreadNodeForForumThreads[]
  loading: boolean
}

const ThreadsCards: React.FC<ThreadsCardsProps> = ({ threads, loading }) => {
  const translations = useTranslate({
    whenByWhom: ['common.when-by-whom', { when: '', whom: <em /> }],
  })

  return (
    <Wrapper>
      {threads.map(thread => {
        const timeAgo = capitalize(
          formatDistanceToNow(new Date(thread.createdAt), {
            addSuffix: true,
          })
        )

        return (
          <Card key={thread.id} sticky={thread.sticky}>
            <Title to={`/forum/${thread.id}`} $seen={thread.seen}>
              {thread.title}
            </Title>

            <p>
              {translations.whenByWhom({
                when: timeAgo,
                whom: <em>{thread.user.fullName}</em>,
              })}
            </p>
          </Card>
        )
      })}
    </Wrapper>
  )
}

export default ThreadsCards
