import { RelayEdges, BaseNode } from 'types/graphql'
import { DynamicColumnType } from '.'

export enum ColumnNames {
  DATE = 'date',
  REGNR = 'regNr',
  START_TIME_LOCATION = 'dayStartTimeAndLocation',
  END_TIME_LOCATION = 'dayEndTimeAndLocation',
  START_TIME = 'dayStartTime',
  END_TIME = 'dayEndTime',
  START_LOCATION = 'dayStartLocation',
  END_LOCATION = 'dayEndLocation',
  TOTAL_WORK = 'timeTotalWork',
  DRIVING = 'timeDriving',
  OVERTIME = 'timeOvertime',
  AVAILABILITY = 'timeAvailability',
  PAUSE = 'timePause',
  OTHER_WORK = 'timeOtherWork',
  REST = 'timeRest',
  STATUS = 'dataStatus',
  APPROVED = 'approved',
  SALARY_ID = 'salaryId',
  SALARY_NAME = 'salaryName',
  DIET = 'diet',
  SALARY_DAY = 'salaryDay',
  DEPARTMENT = 'department',
}

export interface DynamicColumnNode extends BaseNode {
  __typename: 'DynamicColumnNode'
  id: string
  name: ColumnNames
  displayName: string
  enabled: boolean
  inExport: boolean
  columnType: DynamicColumnType
  order: number
}

export interface ActivitiesWithReceiptCoolumnNode extends DynamicColumnNode {
  approved: boolean
}

export interface AllDynamicColumnsQuery<
  T extends DynamicColumnNode = DynamicColumnNode
> {
  allDynamicColumns: RelayEdges<T>
}
export interface AllDynamicColumnsQueryVariables {
  moduleName: string
  enabled?: boolean
}

export interface PatchDynamicColumnMutation<
  T extends DynamicColumnNode = DynamicColumnNode
> {
  patchDynamicColumn: Pick<T, 'id'>
}

export interface PatchDynamicColumnMutationVariables {
  id: string
  input: {
    name?: string
    displayName?: string
    enabled?: boolean
    inExport?: boolean
    order?: number
  }
}
