import { useTranslate } from '@ur/react-hooks'
import { Card } from 'components/Card'
import { TodaysChecklists } from 'modules/checklists/TodaysChecklists'
import React, { useState } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  max-width: 100%;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ${props => props.theme.layout.mobile} {
    header {
      margin-bottom: 8px;

      h2 {
        margin: 0;
      }
    }
  }
`

const Checklists = styled(Card)`
  display: flex;
  overflow-x: auto;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem 2.5em 1.8em;

  background: white;
  ${props => props.theme.media.mobile} {
    max-width: 100%;
  }
`

export const DashboardChecklists: React.VFC = () => {
  const [hideChecklists, setHideChecklists] = useState(false)
  const translations = useTranslate({
    checklists: 'common.checklists',

    forum: 'common.forum',
    users: 'common.users',
    drivingData: 'common.driving-data',
    terminal: 'common.terminal',
    issues: 'common.issues',
    hourlist: 'common.hourlist',
    absences: 'common.absences',
    driversHandbook: 'common.drivers-handbook',
    handbook: 'common.handbook',
  })

  return !hideChecklists ? (
    <Wrapper>
      <h2>{translations.checklists}</h2>
      <Checklists>
        <TodaysChecklists onEmptyCallback={value => setHideChecklists(value)} />
      </Checklists>
    </Wrapper>
  ) : (
    <></>
  )
}
