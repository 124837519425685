import { PromptResolve, SelectOption } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { RegularModal, UserSelect } from 'components'
import {
  Field,
  Form,
  ResponsibleUserForm,
} from 'modules/companies/CompanySettings'
import { ResponsibleUserNode, ShallowUserNode } from 'modules/users'
import { isMobileOnly } from 'react-device-detect'

interface EditResponsibleUserModalProps {
  responsibleUser: ResponsibleUserNode | null
  onSubmit: PromptResolve<ResponsibleUserForm | null>
}

export const EditResponsibleUserModal: React.VFC<EditResponsibleUserModalProps> =
  ({ responsibleUser, onSubmit }) => {
    const translations = useTranslate({
      update: 'common.update',
      edit: 'settings.edit-responsible-user',
      responsibleUser: 'settings.responsible-user',
    })

    const {
      formValues,
      formEdited,
      updateForm,
      submitHandler: submit,
    } = useForm<ResponsibleUserForm>({
      values: {
        userId: responsibleUser?.id ?? null,
      },
    })

    function handleChangeResponsibleUser(
      id: string | null,
      _user: SelectOption<string, ShallowUserNode> | null
    ) {
      if (!!id) updateForm({ userId: id })
    }

    return (
      <RegularModal
        title={translations.edit}
        submitText={translations.update}
        submitDisabled={!formEdited}
        cancelOnClickOutside
        width="450px"
        onSubmit={submit(onSubmit)}
        onCancel={() => onSubmit(null)}
      >
        <Form>
            <Field>
            <label>{translations.responsibleUser}</label>
            <UserSelect
              value={formValues.userId}
              fullWidth={isMobileOnly}
              onChange={handleChangeResponsibleUser}
            />
          </Field>
        </Form>
      </RegularModal>
    )
  }
