import { isSameDay, isWithinInterval, isAfter } from 'date-fns'

export const isDateSelected = (
  date: Date,
  firstDate: Date | null,
  endDate: Date | null,
  dateRange: [Date, Date],
  isDisabled: (date: Date) => boolean
) => {
  if (firstDate && endDate) {
    if (isAfter(firstDate, endDate)) {
      return isWithinInterval(date, { start: endDate, end: firstDate })
    } else {
      return isWithinInterval(date, { start: firstDate, end: endDate })
    }
  } else {
    return (
      isWithinInterval(date, { start: dateRange[0], end: dateRange[1] }) ||
      isSameDay(date, dateRange[0])
    )
  }
}

export const isDisabled = (
  date: Date,
  disableBefore?: Date,
  disableAfter?: Date,
  disabledDates?: Date[]
): boolean => {
  return (
    (disableAfter && date > disableAfter) ||
    (disableBefore && date < disableBefore) ||
    disabledDates?.some(x => isSameDay(x, date)) ||
    false
  )
}
