import React from 'react'
import styled from 'styled-components'
import { useTranslate } from '@ur/react-hooks'
import { Card } from 'components/Card'
import { useModuleConfig, useUser } from 'util/hooks'
import { getUsersHighestPrioritySalaryWorkTime } from 'modules/companies/util'
import {
  Module,
  TimesheetsModuleConfig,
} from 'modules/companies'
import { TimeEntryFormAPI } from './components/TimeEntryFormAPI'

const Layout = styled.div`
  ${props => props.theme.layout.default};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .form-card {
    width: clamp(600px, 60vw, 90%);
  }

  ${props => props.theme.layout.mobile} {
    .form-card {
      width: 100%;
    }
  }
`



export const CreateTimeEntry: React.FC = () => {
  const translations = useTranslate({
    newTimeEntry: 'timesheets.new-time-entry',
    loading: 'common.loading...',
  })
  const user = useUser()
  const { moduleConfig } = useModuleConfig<TimesheetsModuleConfig>(Module.TIMESHEETS)

  const salaryWorkTime = getUsersHighestPrioritySalaryWorkTime(
    user,
    moduleConfig
  )

  return (
    <Layout>
      <header>
        <h1>{translations.newTimeEntry}</h1>
      </header>
      <Card className="form-card">
        {salaryWorkTime ? (
          <TimeEntryFormAPI salaryWorkTime={salaryWorkTime}/>
        ) : translations.loading}
      </Card>
    </Layout>
  )
}
