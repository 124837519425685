import { PlainLink } from 'components/Link'
import { Cell } from 'components/Table/Cell'
import { IssueCategoryNode } from 'modules/issues/types.graphl'
import React from 'react'
import styled from 'styled-components'
import { removeCurrentYear } from 'util/date'
import { format, formatDistanceToNow } from 'util/date-fns'

/* IssueDateCell */

const StyledIssueDateCell = styled(Cell)`
  div {
    font-style: normal;
    font-weight: 600;
  }
  span {
    font-size: 0.8em;
    color: ${props => props.theme.colors.gray6};
  }
`
interface IssueDateCellProps {
  issueId: string
  timeOfIssue: string
}
export const IssueDateCell: React.VFC<IssueDateCellProps> = ({
  issueId,
  timeOfIssue,
}) => {
  const date = new Date(timeOfIssue)
  const dateString = removeCurrentYear(format(new Date(timeOfIssue), 'PPP'))
  const timeAgo = formatDistanceToNow(date, { addSuffix: true })

  return (
    <StyledIssueDateCell narrow>
      <PlainLink to={`/issues/${issueId}`} hoverColor="secondary">
        <div>{dateString}</div>
        <span>{timeAgo}</span>
      </PlainLink>
    </StyledIssueDateCell>
  )
}

/* IssueReferenceCell */

const StyledIssueReferenceCell = styled(Cell)`
  span {
    margin-top: 4px;
    color: ${props => props.theme.colors.gray2};
    font-size: 0.9rem;
    font-weight: 600;
    letter-spacing: 1px;
  }
`
interface IssueReferenceCellProps {
  reference: string | null
}
export const IssueReferenceCell: React.VFC<IssueReferenceCellProps> = ({
  reference,
}) => (
  <StyledIssueReferenceCell narrow>
    {reference && <span>{reference}</span>}
  </StyledIssueReferenceCell>
)

/* IssueCategoryCell */

interface StyledIssueCategoryProps {
  color: string
}
const StyledIssueCategory = styled.div<StyledIssueCategoryProps>`
  display: flex;

  & > div {
    padding: 9px 18px 6px;

    border-radius: 999px;
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    background: ${props => props.color};
  }
`

interface IssueCategoryProps {
  className?: string

  category: Pick<IssueCategoryNode, 'id' | 'color' | 'name'>
}
export const IssueCategory: React.VFC<IssueCategoryProps> = ({
  className,
  category,
}) => (
  <StyledIssueCategory
    className={className}
    color={category.color ?? '#00c7b1'}
  >
    <div>{category.name}</div>
  </StyledIssueCategory>
)
interface CategoryCellProps extends IssueCategoryProps {}
export const IssueCategoryCell: React.VFC<CategoryCellProps> = ({
  ...categoryProps
}) => (
  <Cell>
    <IssueCategory {...categoryProps} />
  </Cell>
)

/* IssueStatusCell */

interface StyledIssueStatusProps {
  closed: boolean
  archived: boolean
}
const StyledIssueStatus = styled.div<StyledIssueStatusProps>`
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;

  &::before {
    content: '';
    display: inline-block;

    width: 8px;
    height: 8px;
    margin-right: 0.8ch;
    margin-bottom: 1px;

    border-radius: 50%;
    background: ${props =>
      props.archived
        ? props.theme.colors.orange
        : props.closed
        ? props.theme.colors.green
        : props.theme.colors.red};
  }
`
interface IssueStatusProps {
  className?: string

  closed: boolean
  archived: boolean
  openText: string
  archivedText: string
  closedText: string
}
export const IssueStatus: React.VFC<IssueStatusProps> = ({
  className,

  closed,
  archived,
  openText,
  archivedText,
  closedText,
}) => {
  return (
    <StyledIssueStatus
      className={className}
      closed={closed}
      archived={archived}
    >
      {archived ? archivedText : closed ? closedText : openText}
    </StyledIssueStatus>
  )
}
interface IssueStatusCellProps extends IssueStatusProps {}
export const IssueStatusCell: React.VFC<IssueStatusCellProps> = ({
  ...statusProps
}) => (
  <Cell narrow>
    <IssueStatus {...statusProps} />
  </Cell>
)
