import { Icon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { SVGIcon } from 'components'
import { addHours, format, isAfter, isBefore, parseISO } from 'date-fns'
import { addDays } from 'date-fns/esm'
import { Time } from 'lib/time'
import clamp from 'lodash/clamp'
import range from 'lodash/range'
import {
  enforceHourMinuteString,
  getNodeTime,
  optimizeVehicleNodes,
  RangeNode,
  RangeNodeCollection,
} from 'modules/activities'
import { DriverActivityType } from 'modules/activities/consts'
import { DriverActivityDayReportQueryReportData } from 'modules/activities/types.graphql'
import { VehicleColorDictionary } from 'modules/activities/types'
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { isMobile } from 'react-device-detect'
import styled, { css } from 'styled-components'
import { overloadColor } from 'util/style'
import { ActivityArea } from './ActivityArea'
import { Activity, Issue } from './Schedule.types'
import { ScheduleRange } from './ScheduleRange'
import { getLocaleCorrectionOffset } from 'util/date/localeCorrection'

const TOPBAR_HEIGHT = 86
const SIDEBAR_WIDTH = 152
const SUMBAR_WIDTH = 192
const DATA_ROW_HEIGHT = 98

const MOBILE_TOPBAR_HEIGHT = 68
const MOBILE_SIDEBAR_WIDTH = 122
const MOBILE_SUMBAR_WIDTH = 152
const MOBILE_DATA_ROW_HEIGHT = 72

const Wrapper = styled(ActivityArea)`
  display: grid;
  grid-template-columns: ${SIDEBAR_WIDTH}px 1fr ${SUMBAR_WIDTH}px;
  grid-template-rows: ${TOPBAR_HEIGHT}px ${DATA_ROW_HEIGHT * 8}px;
  grid-template-areas:
    'sidebar hours controls'
    'sidebar data  sums';
  width: 100%;

  ${props => props.theme.media.mobile} {
    width: auto;
    overflow-x: auto;
    grid-template-columns: ${MOBILE_SIDEBAR_WIDTH}px auto ${MOBILE_SUMBAR_WIDTH}px;
    grid-template-rows: ${MOBILE_TOPBAR_HEIGHT}px ${MOBILE_DATA_ROW_HEIGHT *
      7}px;
  }
`
const Sidebar = styled.div`
  grid-area: sidebar;
  position: relative;
  z-index: 1;

  display: grid;
  grid-template-rows: ${TOPBAR_HEIGHT}px repeat(8, ${DATA_ROW_HEIGHT}px);

  box-shadow: 7px 0 8px -5px ${props => props.theme.colors.gray8};

  ${props => props.theme.media.mobile} {
    grid-template-rows: ${MOBILE_TOPBAR_HEIGHT}px repeat(
        8,
        ${MOBILE_DATA_ROW_HEIGHT}px
      );
  }
`

const SidebarTitle = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 1.2rem;
  color: rgb(0, 0, 0, 1);
  gap: 4px;

  & > :first-child {
    font-weight: 600;
    color: ${props => props.theme.colors.gray5};
  }

  & > :nth-child(2) {
    font-weight: 600;
  }
`

interface HoursProps {
  columns: number
}

const Hours = styled.div<HoursProps>`
  grid-area: hours;

  display: grid;
  grid-template-columns: 0.7fr repeat(${props => props.columns - 1}, 1fr);
  grid-template-rows: ${TOPBAR_HEIGHT}px;

  border-top: 1px solid ${props => props.theme.colors.gray8};

  & > div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 600;
    color: ${props => props.theme.colors.gray5};

    span {
      position: absolute;
      right: 0;
      transform: translateX(50%);
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: -1px;

      width: 1px;
      height: 22px;
      background: ${props => props.theme.colors.gray8};
    }
  }

  ${props => props.theme.media.mobile} {
    grid-template-columns: 70px repeat(${props => props.columns - 1}, 100px);
    grid-template-rows: ${MOBILE_TOPBAR_HEIGHT}px;
  }
`

const Data = styled.div`
  grid-area: data;

  display: grid;
  grid-template-rows: repeat(8, ${DATA_ROW_HEIGHT}px);
  overflow: hidden;

  scroll-behavior: none;

  ${props => props.theme.media.mobile} {
    grid-template-rows: repeat(8, ${MOBILE_DATA_ROW_HEIGHT}px);
  }
`

interface StyledDataRowProps {
  columns: number
}
const StyledDataRow = styled.div<StyledDataRowProps>`
  position: relative;

  display: grid;
  grid-template-columns: 0.7fr repeat(${props => props.columns - 1}, 1fr);
  border-top: 1px solid ${props => props.theme.colors.gray8};

  &:nth-child(odd) {
    background: ${props => props.theme.colors.quaternary};
  }
  & > div + div {
    border-left: 1px solid ${props => props.theme.colors.gray8};
  }
`
interface DataRowProps {
  numHours: number
  onFirstBoxWidthUpdate?: (width: number) => void
}
const DataRow: React.FC<DataRowProps> = ({
  children,
  numHours,
  onFirstBoxWidthUpdate,
}) => (
  <StyledDataRow columns={numHours}>
    {children}
    {range(numHours).map(i => (
      <div
        key={i}
        ref={node => i === 0 && onFirstBoxWidthUpdate?.(node?.offsetWidth ?? 0)}
      />
    ))}
  </StyledDataRow>
)
const MobileControlsReplacement = styled.div`
  grid-area: controls;
  border-top: 1px solid ${props => props.theme.colors.gray8};
`
const Controls = styled.div`
  grid-area: controls;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: 1rem;

  border-top: 1px solid ${props => props.theme.colors.gray8};

  ${props => props.theme.media.mobile} {
    position: sticky;
    top: 0;
    right: 0;
    height: 100%;

    border-top: 0;
    padding-right: 2px;

    div {
      margin-top: 6px;

      i {
        opacity: 0.75;
      }
    }
  }
`
interface ControlIconProps {
  disabled: boolean
}
const ControlIcon = styled(Icon).attrs({
  type: 'solid',
  size: '1.4rem',
  color: overloadColor('gray8'),
  hoverColor: overloadColor('secondary'),
  cursor: 'pointer',
})<ControlIconProps>`
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;

      &:hover {
        color: ${props.theme.colors.gray8};
      }
    `};

  ${props => props.theme.media.mobile} {
    font-size: 2rem;
  }
`
const Sums = styled.div`
  grid-area: sums;

  display: grid;
  grid-template-rows: repeat(8, ${DATA_ROW_HEIGHT}px);
  border-left: 1px solid ${props => props.theme.colors.gray8};

  ${props => props.theme.media.mobile} {
    grid-template-rows: repeat(8, ${MOBILE_DATA_ROW_HEIGHT}px);
  }
`

const ColorSpan = styled.span<{ color: string }>`
  color: ${props => overloadColor(props.color)};
`

interface ColoredBoxProps {
  color: string
}
const ColoredBox = styled.div<ColoredBoxProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  border-top: 1px solid ${props => props.theme.colors.gray8};
  color: ${props => overloadColor(props.color)};

  i {
    font-size: 1.4em;
  }
  svg {
    fill: ${props => overloadColor(props.color)};
  }
  span {
    font-weight: 600;

    &:not(:first-child) {
      margin-left: 1ch;
    }
  }

  ${props => props.theme.media.mobile} {
    i {
      font-size: 1rem;
    }
    span {
      font-size: 0.9rem;
    }
  }
`

const MIN_RESOLUTION = 3
const MAX_RESOLUTION = 24

interface ScheduleProps {
  activities: Activity[]
  issues: Issue[]
  report: DriverActivityDayReportQueryReportData | null
  date: Date
  vehicleColors: VehicleColorDictionary
}

export const Schedule: React.VFC<ScheduleProps> = ({
  activities,
  issues,
  report,
  date,
  vehicleColors,
}) => {
  const translations = useTranslate({
    issues: 'common.issues',
    work: 'activities.work',
    rest: 'activities.rest',
    pause: 'common.pause',
    driving: 'activities.driving',
    countries: 'common.countries',
    vehicle: 'common.vehicle',

    lastFetched: 'activities.last-fetched',

    hours: ['common.hours', val => val.toLowerCase()],
    minutes: ['common.minutes', val => val.toLowerCase()],

    nHours: ['common.n-hours', { n: '' }],
    nMinutes: ['common.n-minutes', { n: 0 }],
    nIssues: ['common.n-issues', { n: 0 }],
    available: 'common.availability',
    unavailable: 'common.unavailable',
    unknown: 'common.unknown',
  })

  const nextDay = addDays(date, 1)

  const dataRef = useRef<HTMLDivElement>(null)

  const [dataFirstBoxWidth, setDataFirstBoxWidth] = useState(0)
  const [calendarWidth, setCalendarWidth] = useState(0)
  const [startHour, setStartHour] = useState(8)
  const [resolution, setResolution] = useState(12)

  const dstOffset = report?.daylightSavingsChange?.offset ?? 0
  const dstTimestamp = report?.daylightSavingsChange?.changeTime ?? 0
  const newUTCOffset = report?.daylightSavingsChange?.utcNewOffset ?? 0
  const timezoneData = report?.timezoneData
  const lastFetched = report?.lastFetched
    ? format(parseISO(report?.lastFetched), 'dd.MM.yyyy HH:mm')
    : null

  useLayoutEffect(() => {
    const el = dataRef.current
    if (!el) return

    const preventDefault = (evt: WheelEvent) => evt.preventDefault()

    setCalendarWidth(el.offsetWidth)
    el.addEventListener('wheel', preventDefault, { passive: false })

    return () => {
      el.removeEventListener('wheel', preventDefault)
    }
  }, [])

  const hourRange = useMemo(() => {
    return range(startHour, startHour + resolution + 1 - dstOffset)
  }, [startHour, resolution, dstOffset])

  const getActivityBackground = useCallback((activityType: string, differenceInMs: number) => {
    switch (activityType) {
      case DriverActivityType.WORK:
        return 'orange'
      case DriverActivityType.AVAILABLE:
        return 'primary500'
      case DriverActivityType.REST:
        if (report?.timeWorkTotal === "" || report?.timeWorkTotal === "00:00") {
          return 'secondary'
        }
        if (0.25*60*60*1000 <= differenceInMs && differenceInMs <= 6*60*60*1000) {
          return 'secondary500'
        } else {
          return 'secondary'
        }
      case DriverActivityType.PAID_REST:
        return 'orange'
      case DriverActivityType.DRIVE:
        return 'primary'
      case 'Country':
        return 'tertiary'
      default:
        return 'red'
    }
  }, [report])


  const {
    issueNodes,
    workNodes,
    availableNodes,
    restNodes,
    pauseNodes,
    driveNodes,
    countryNodes,
    vehicleNodes,
  } = useMemo<RangeNodeCollection>(() => {
    const activityNodes = activities.reduce<
      Omit<RangeNodeCollection, 'issueNodes' | 'countryNodes'>
    >(
      (acc, cur) => {
        // find offset between browser and report timezones
        const browserTimezoneOffset = timezoneData
          ? getLocaleCorrectionOffset(timezoneData, date)
          : 0 /* TODO: add company timezone as extra backup?*/

        // Find offset for the next day, to check if DST change happens during the day
        const browserNextDayTimezoneOffset = timezoneData
          ? getLocaleCorrectionOffset(timezoneData, nextDay)
          : 0 /* TODO: add company timezone as extra backup?*/

        // We subtract new browser UTC offset from the previous to get the change that happens during the day.
        const browserDSTChangeOnDayOffset =
          browserNextDayTimezoneOffset - browserTimezoneOffset

        // We add the browsers timezone offset for the report to show correct times in the schedule view.
        const dateStart = addHours(
          new Date(cur.datetimeStart),
          browserTimezoneOffset
        )

        // We add the browsers timezone offset for the report to show correct times in the schedule view.
        const dateEnd = addHours(
          new Date(cur.datetimeEnd),
          browserTimezoneOffset
        )

        const realDifferenceMs = dateEnd.getTime() - dateStart.getTime()

        const startYesterday =
          isBefore(dateStart, date) ||
          dateStart.getMinutes() + dateStart.getHours() === 0

        // Also check if date strings are equal, in case we get a dateEnd ending at midnight
        const endNextDay =
          isAfter(dateEnd, nextDay) ||
          dateEnd.toDateString() === nextDay.toDateString()

        // The logic further down assumes that we only have at most one activity starting before the date for the view
        // and one activity ending after or at the same time as nextDay
        const [timeStart, timeStartTextCorrection] = startYesterday
          ? [new Time(0, 0), 0]
          : getNodeTime(
              dateStart,
              dstTimestamp,
              dstOffset,
              cur.datetimeStart,
              newUTCOffset,
              browserTimezoneOffset,
              browserDSTChangeOnDayOffset
            )

        // If the activity ends after the next day, we need to set the end time to the end of the day and in case it's on
        // a daylight savings day we have to add the negative of the offset to the time.
        const [timeEnd, timeEndTextCorrection] = endNextDay
          ? [new Time(24 - dstOffset, 0), dstOffset]
          : getNodeTime(
              dateEnd,
              dstTimestamp,
              dstOffset,
              cur.datetimeEnd,
              newUTCOffset,
              browserTimezoneOffset,
              browserDSTChangeOnDayOffset
            )

        const difference = timeStart.difference(timeEnd, 'minute')

        // Here we add to the graph length to move edge nodes outside of the view
        // We need to add 1 if an activity is starting at the previous day or if it is ending at the next day
        // We need to add 2 if the activity is starting at the previous day and ending at the next day
        const extendGraphEdges = (startYesterday ? 1 : 0) + (endNextDay ? 1 : 0)

        const nodes: [RangeNode, RangeNode] = [
          {
            type: 'regular',
            time: timeStart.reduce('hours', false),
            text: timeStart
              .add(timeStartTextCorrection, 'hours')
              .format('HH:mm'),
            rangeText:
              difference >= 60 ? (difference / 60).toFixed(1) : difference + '',
            rangeSubText:
              difference >= 60 ? translations.hours : translations.minutes,
            background: getActivityBackground(cur.activityType, realDifferenceMs),
          },
          {
            type: 'regular',
            time: timeEnd.reduce('hours', false) + extendGraphEdges,
            text: timeEnd.add(timeEndTextCorrection, 'hours').format('HH:mm'),
          },
        ]
        // "Not available" seems to hide the vehicle vin.
        const identification =
          cur.activityType === DriverActivityType.NOT_AVAILABLE &&
          !cur.vehicleVin
            ? translations.unavailable
            : cur.vehicleVin ?? translations.unknown
        const vehicleNodes: [RangeNode, RangeNode] = [
          {
            type: 'regular',
            time: timeStart.reduce('hours', false),
            text: timeStart
              .add(timeStartTextCorrection, 'hours')
              .format('HH:mm'),
            rangeText: cur.vehicleRegistrationNumber,
            rangeSubText: identification,
            vehicleRange: true,
            background: '#afafaf',
          },
          {
            type: 'regular',
            time: timeEnd.reduce('hours', false) + extendGraphEdges,
            text: timeEnd.add(timeEndTextCorrection, 'hours').format('HH:mm'),
          },
        ]
        acc.vehicleNodes.push(vehicleNodes)

        switch (cur.activityType) {
          case DriverActivityType.AVAILABLE:
            acc.availableNodes.push(nodes)
            break
          case DriverActivityType.WORK:
            acc.workNodes.push(nodes)
            break
          case DriverActivityType.REST:
            if (report?.timeWorkTotal === "" || report?.timeWorkTotal === "00:00") {
              acc.restNodes.push(nodes)
            } else if ((0.25*60*60*1000 <= realDifferenceMs && realDifferenceMs <= 6*60*60*1000)) {
              acc.pauseNodes.push(nodes)
            } else {
              acc.restNodes.push(nodes)
            }
            break
          case DriverActivityType.PAID_REST:
            acc.pauseNodes.push(nodes)
            break
          case DriverActivityType.DRIVE:
            acc.driveNodes.push(nodes)
            break
          default:
            break
        }

        return acc
      },
      {
        workNodes: [],
        restNodes: [],
        pauseNodes: [],
        availableNodes: [],
        driveNodes: [],
        vehicleNodes: [],
      }
    )
    const vehicleNodes = activityNodes.vehicleNodes
    const optimizedVehicleNodes = optimizeVehicleNodes(vehicleNodes) as [
      RangeNode,
      RangeNode
    ][]
    const colorfulVehicleNodes = optimizedVehicleNodes.map<
      [RangeNode, RangeNode]
    >(nodePair => {
      const [startNode, endNode] = nodePair
      return [
        {
          ...startNode,
          background: vehicleColors[startNode.rangeSubText ?? ''],
        },
        endNode,
      ]
    })

    const issueNodes = issues.reduce<RangeNode[][]>((acc, cur) => {
      const time = new Time(cur.timeOfIssue)
      acc.push([
        {
          type: 'issue',
          time: time.reduce('hours', false),
          text: time.format('HH:mm'),
          background: 'red',
          href: `/issues/${cur.id}`,
        },
      ])
      return acc
    }, [])

    return {
      ...activityNodes,
      issueNodes,
      countryNodes: [],
      vehicleNodes: colorfulVehicleNodes,
    }
  }, [activities, issues, timezoneData, date, nextDay, dstTimestamp, dstOffset, newUTCOffset, translations.hours, translations.minutes, translations.unavailable, translations.unknown, getActivityBackground, report?.timeWorkTotal, vehicleColors])

  const createScheduleRange = (nodes: RangeNode[], index: number) => {
    return (
      <ScheduleRange
        key={`${nodes[index]?.background}-${nodes[index]?.text}-${index}`}
        nodes={nodes}
        startTime={startHour}
        endTime={startHour + resolution}
        leftMargin={dataFirstBoxWidth}
        calendarWidth={calendarWidth}
        daylightOffset={report?.daylightSavingsChange?.offset ?? 0}
      />
    )
  }

  function hourAt(mouseX: number) {
    const mousePercentage = mouseX / calendarWidth
    return Math.floor(resolution * mousePercentage) + startHour
  }

  function zoomData(out: boolean, mouseX?: number) {
    if ((resolution === 3 && !out) || (resolution === 24 && out)) return

    const hourAtMouse =
      typeof mouseX !== 'undefined'
        ? hourAt(mouseX)
        : (startHour + resolution) / 2

    const delta = out ? 1 : -1
    const newResolution = clamp(
      resolution + delta,
      MIN_RESOLUTION,
      MAX_RESOLUTION - dstOffset
    )
    setResolution(newResolution)
    setStartHour(
      clamp(Math.floor(hourAtMouse - newResolution / 2), 0, 24 - newResolution)
    )
  }
  function scrollData(left: boolean) {
    if ((left && startHour <= 0) || (!left && startHour + resolution >= 24))
      return
    const delta = left ? -1 : 1
    setStartHour(startHour + delta)
  }

  function handleWheel(evt: React.WheelEvent<HTMLDivElement>) {
    if (isMobile) return

    if (evt.deltaY === 0) return
    if (evt.shiftKey)
      zoomData(
        evt.deltaY > 0,
        evt.pageX - evt.currentTarget.getBoundingClientRect().left
      )
    else scrollData(evt.deltaY > 0)
  }

  const disabledControls = useMemo(
    () => ({
      scrollLeft: startHour <= 0,
      scrollRight: startHour + resolution >= 24,
      zoomOut: resolution >= MAX_RESOLUTION - dstOffset,
      zoomIn: resolution <= MIN_RESOLUTION,
    }),
    [resolution, startHour, dstOffset]
  )

  function hourLabel(hour: number) {
    if (dstOffset < 0 && dstTimestamp && hour > dstTimestamp) {
      hour = hour + dstOffset
    }
    if (dstOffset > 0 && dstTimestamp && hour >= dstTimestamp) {
      hour = hour + dstOffset
    }

    return `${hour === 24 ? '00' : hour}:00`.padStart(5, '0')
  }

  return (
    <Wrapper area="schedule">
      <Sidebar>
        <ColoredBox color="gray4">
          {lastFetched && (
            <SidebarTitle>
              <div>{translations.lastFetched}:</div>
              <div>{lastFetched}</div>
            </SidebarTitle>
          )}
        </ColoredBox>

        <ColoredBox color="red">
          <Icon icon="exclamation-triangle" type="solid" />
          <span>{translations.issues}</span>
        </ColoredBox>

        <ColoredBox color="orange">
          <Icon icon="suitcase" type="solid" />
          <span>{translations.work}</span>
        </ColoredBox>

        <ColoredBox color="primary">
          <SVGIcon icon="truck-side" width="32px" />
          <span>{translations.driving}</span>
        </ColoredBox>

        <ColoredBox color="secondary">
          <Icon icon="moon" type="solid" />
          <span>{translations.rest}</span>
        </ColoredBox>

        <ColoredBox color="secondary500">
          <Icon icon="coffee" type="solid" />
          <span>{translations.pause}</span>
        </ColoredBox>

        <ColoredBox color="primary500">
          <SVGIcon icon="availability-icon" />
          <span>{translations.available}</span>
        </ColoredBox>

        <ColoredBox color="tertiary">
          <Icon icon="truck" type="light" />
          <span>{translations.vehicle}</span>
        </ColoredBox>

        <ColoredBox color="tertiary">
          <Icon icon="globe" type="light" />
          <span>{translations.countries}</span>
        </ColoredBox>
      </Sidebar>

      <Hours columns={hourRange.length}>
        {hourRange.map(hour => (
          <div key={hour}>
            <span>{hourLabel(hour)}</span>
          </div>
        ))}
      </Hours>

      <Controls>
        <div>
          <ControlIcon
            icon="chevron-circle-left"
            disabled={disabledControls.scrollLeft}
            onClick={() => !disabledControls.scrollLeft && scrollData(true)}
          />
          <ControlIcon
            icon="chevron-circle-right"
            disabled={disabledControls.scrollRight}
            onClick={() => !disabledControls.scrollRight && scrollData(false)}
          />
        </div>
        <div>
          <ControlIcon
            icon="minus-circle"
            disabled={disabledControls.zoomOut}
            onClick={() => !disabledControls.zoomOut && zoomData(true)}
          />
          <ControlIcon
            icon="plus-circle"
            disabled={disabledControls.zoomIn}
            onClick={() => !disabledControls.zoomIn && zoomData(false)}
          />
        </div>
      </Controls>

      {isMobile && <MobileControlsReplacement />}

      <Sums>
        <ColoredBox color="red">
          <span>{translations.nIssues({ n: issues.length })}</span>
        </ColoredBox>

        <ColoredBox color="orange">
          <span>
            {translations.nHours({
              n: enforceHourMinuteString(report?.timeOtherWork),
            })}
          </span>
        </ColoredBox>

        <ColoredBox color="primary">
          <span>
            {translations.nHours({
              n: enforceHourMinuteString(report?.timeDriving),
            })}
          </span>
        </ColoredBox>

        <ColoredBox color="secondary">
          <span>
            {translations.nHours({
              n: enforceHourMinuteString(report?.timeRest),
            })}
          </span>
        </ColoredBox>

        <ColoredBox color="secondary500">
          <span>
            {translations.nHours({
              n: enforceHourMinuteString(report?.timePause),
            })}
          </span>
          <ColorSpan color="orange">
            {
              report?.timePaidRest === "00:00" || report?.timePaidRest === "" ? "" : "(+" + enforceHourMinuteString(report?.timePaidRest) + ")"
            }
          </ColorSpan>
        </ColoredBox>

        <ColoredBox color="primary500">
          <span>
            {translations.nHours({
              n: enforceHourMinuteString(report?.timeAvailability),
            })}
          </span>
        </ColoredBox>

        <ColoredBox color="tertiary">
          <span></span>
        </ColoredBox>

        <ColoredBox color="tertiary">
          <span>Norway, Sweden</span>
        </ColoredBox>
      </Sums>

      <Data ref={dataRef} onWheel={handleWheel}>
        <DataRow
          numHours={hourRange.length}
          onFirstBoxWidthUpdate={setDataFirstBoxWidth}
        >
          {issueNodes.map(createScheduleRange)}
        </DataRow>
        <DataRow numHours={hourRange.length}>
          {workNodes.map(createScheduleRange)}
        </DataRow>
        <DataRow numHours={hourRange.length}>
          {driveNodes.map(createScheduleRange)}
        </DataRow>
        <DataRow numHours={hourRange.length}>
          {restNodes.map(createScheduleRange)}
        </DataRow>
        <DataRow numHours={hourRange.length}>
          {pauseNodes.map(createScheduleRange)}
        </DataRow>
        <DataRow numHours={hourRange.length}>
          {availableNodes.map(createScheduleRange)}
        </DataRow>
        <DataRow numHours={hourRange.length}>
          {vehicleNodes.map(createScheduleRange)}
        </DataRow>
        <DataRow numHours={hourRange.length}>
          {countryNodes.map(createScheduleRange)}
        </DataRow>
      </Data>
    </Wrapper>
  )
}
