import { InMemoryCache } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'

// https://www.apollographql.com/docs/react/caching/overview/
export const inMemoryCache = new InMemoryCache({
  /* keyArgs define what attributes warrant a seperate instance
     of the query in the cache. Default is all variables present in the query. */
  typePolicies: {
    Query: {
      fields: {
        threadViews: {
          keyArgs: ['id', 'q'],
        },
        thread: {
          keyArgs: ['id'],
        },
        allDynamicColumns: {
          keyArgs: ['moduleName', 'enabled'],
        },
        userActivityDayReport: {
          keyArgs: ['user', 'date'],
        },
        driverActivityPeriodReport: {
          keyArgs: ['user', 'dateStart', 'dateEnd', 'weekPartitions'],
        },
        allUsers: relayStylePagination(['q', 'dropdown', 'includeExpiredDocuments', 'onlyIncludeUserDocumentCategories']),
        allUserDocumentCategories: {
          keyArgs: ['includeExpiredDocuments'],
        },
        allTimeEntryProjects: relayStylePagination(['q']),
        allManualEntries: relayStylePagination(),
        allUserExports: relayStylePagination(),
        allFilledOutForms: relayStylePagination(),
        allThreads: relayStylePagination(['sticky', 'q']),
        myAppNotifications: relayStylePagination(),
        allInfringements: relayStylePagination([
          'users',
          'company',
          'infringementCategory',
        ]),
        allUsersInfringementsSums: relayStylePagination(['users']),
        allIssues: relayStylePagination(['company', 'user', 'includeSeverities']),
        allRoutePlans: relayStylePagination(),
      },
    },
    ThreadViewNode: {
      fields: {
        // We define pagination for nested pagination queries here.
        notSeenBy: relayStylePagination(['q', 'unseenFirst', 'unseenAfter']),
        seenBy: relayStylePagination(['q', 'seenFirst', 'seenAfter']),
      },
    },
    ThreadNode: {
      fields: {
        posts: relayStylePagination(),
      },
    },
  },
})
