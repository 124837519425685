import { SelectOption } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { ExportOption, ExportFormat } from './consts'

export function useExportOptions(
  allowDownload?: boolean
): SelectOption<ExportOption>[] {
  const translations = useTranslate({
    download: 'common.download',
    email: 'common.email',
    save: 'common.save-in-user-exports',
  })
  const downloadOption = allowDownload
    ? {
        value: ExportOption.DOWNLOAD,
        label: translations.download,
      }
    : null
  const options = [
    {
      value: ExportOption.MAIL,
      label: translations.email,
    },
    {
      value: ExportOption.SAVE,
      label: translations.save,
    },
  ]
  if (downloadOption) {
    return [...options, downloadOption]
  }
  return options
}

export function useExportFormatOptions(
  excludedFormatOptions?: ExportFormat[]
): SelectOption<ExportFormat>[] {
  const translations = useTranslate({
    excel: 'common.excel',
    excelFlat: 'common.excel-flat',
    pdf: 'common.pdf',
    pdfSalary: 'common.pdf-salary',
    csv: 'common.csv',
    visma: 'common.visma-payroll',
    poweroffice: 'common.poweroffice',
    twentyfourSevenOffice: 'common.twentyfour-seven-office',
    tripletex: 'common.tripletex',
  })

  const formatOptions = [
    {
      value: ExportFormat.PDF,
      label: translations.pdf,
    },
    {
      value: ExportFormat.PDF_SALARY,
      label: translations.pdfSalary,
    },
    {
      value: ExportFormat.EXCEL,
      label: translations.excel,
    },
    {
      value: ExportFormat.EXCEL_FLAT,
      label: translations.excelFlat,
    },
    {
      value: ExportFormat.VISMA,
      label: translations.visma,
    },
    {
      value: ExportFormat.POWEROFFICE,
      label: translations.poweroffice,
    },
    {
      value: ExportFormat.TWENTYFOUR_SEVEN_OFFICE,
      label: translations.twentyfourSevenOffice,
    },
    {
      value: ExportFormat.TRIPLETEX,
      label: translations.tripletex,
    },
  ]

  if (excludedFormatOptions) {
    const newFormatOptions = formatOptions.filter(
      value => !excludedFormatOptions.includes(value.value)
    )
    return newFormatOptions
  }
  return formatOptions
}
