import { Icon } from '@ur/react-components'
import { useClickOutside } from '@ur/react-hooks'
import { SVGIcon } from 'components/icons'
import { isAfter, setMonth, setYear } from 'date-fns'
import capitalize from 'lodash/capitalize'
import clamp from 'lodash/clamp'
import range from 'lodash/range'
import React, { useMemo, useRef, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled, { css } from 'styled-components'
import { ZIndexRange } from 'types/style'
import { format } from 'util/date-fns'

interface WrapperProps {
  width?: string
  minWidth?: string
  maxWidth?: string
}
const Wrapper = styled.div<WrapperProps>`
  position: relative;
  width: ${props => props.width};
  min-width: ${props => props.minWidth};
  max-width: ${props => props.maxWidth};

  user-select: none;
`
interface DisplayProps {
  open: boolean
  light: boolean
  height: string
}
const Display = styled.div<DisplayProps>`
  display: grid;
  grid-template-columns: 2fr 140px;
  height: ${props => props.height};

  border: 1px solid ${props => props.theme.colors.gray9};
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  background: ${props =>
    props.light ? 'white' : props.theme.colors.quaternary};

  ${props =>
    props.open &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-width: 0;

      .arrow {
        transform: translateY(-1px);
      }
    `};
`
const DisplayPart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.4rem;

  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;

  &:hover .arrow {
    color: ${props => props.theme.colors.secondary};
  }
`
const Month = styled(DisplayPart)`
  svg {
    width: 1.5rem;
    margin-right: 12px;
  }

  .calendar-icon {
    transform: translateY(3px);
  }
`
const Year = styled(DisplayPart)`
  border-left: 1px solid ${props => props.theme.colors.gray9};

  ${props => props.theme.media.mobile} {
    border-left: 0;
  }
`
interface CurtainProps {
  light: boolean
}
const Curtain = styled.div<CurtainProps>`
  position: absolute;
  z-index: ${ZIndexRange.Dropdowns};
  top: 100%;
  left: 0;
  right: 0;

  display: grid;
  grid-template-columns: 2fr 140px;

  background: ${props =>
    props.light ? 'white' : props.theme.colors.quaternary};
  box-shadow: 0 8px 8px -2px rgba(0, 0, 0, 0.2);

  border: solid ${props => props.theme.colors.gray9};
  border-width: 0 1px 1px 1px;
  border-bottom-left-radius: ${props => props.theme.sizes.defaultBorderRadius};
  border-bottom-right-radius: ${props => props.theme.sizes.defaultBorderRadius};
`
const List = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;

  &:last-of-type {
    border-left: 1px solid ${props => props.theme.colors.gray9};
  }

  ${props => props.theme.media.mobile} {
    &:last-of-type {
      border-left: 0;
    }
  }
`
interface ItemProps {
  active: boolean
  inFuture?: boolean
}
const Item = styled.div<ItemProps>`
  position: relative;
  padding: 0.5rem 1.4rem;

  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: ${props =>
      props.inFuture ? props.theme.colors.gray6 : props.theme.colors.secondary};
  }

  ${props =>
    props.inFuture &&
    css`
      color: ${props.theme.colors.gray6};
    `}

  ${props =>
    props.active &&
    css`
      color: ${props.theme.colors.secondary};

      &::before {
        content: '';
        position: absolute;
        left: 0.4rem;
        transform: translateY(4px);

        display: inline-block;
        width: 6px;
        height: 6px;

        border-radius: 50%;
        background: ${props.theme.colors.secondary};
      }
    `};
`
const MonthItem = styled(Item)`
  margin-left: calc(1.2rem + 12px);
`
const YearItem = styled(Item)`
  ${props =>
    props.active &&
    css`
      &::before {
        left: 0.6rem;
      }
    `};
`

interface MonthPickerProps {
  value: Date

  /**
   * How many years into the future to show in the year select.
   * By default it the current year is in the middle of the list,
   * which means 5 future years.
   *
   * It clamps to a value of min 0 and max 11 as to not remove the selected year
   * from the list. If set to 0 it will try to get up to the current year
   * with a bias towards setting the selected in the middle of the list
   * (5 years ahead).
   */
  futureYears?: number
  /**
   * The earliest year to which one can select, the list will have no years
   * before this.
   */
  earliestYear?: number
  /**
   * Disable click on future months from today
   */
  disableAfter?: boolean
  /**
   * Disable cycling of years on year change
   */
  noYearCycle?: boolean
  /** #050505 background, instead of default gray */
  light?: boolean

  width?: string
  minWidth?: string
  maxWidth?: string
  fullWidth?: boolean
  height?: string

  onChange: (value: Date) => void
}

export const MonthPicker: React.VFC<MonthPickerProps> = ({
  value,

  futureYears = 5,
  earliestYear = 2017,
  disableAfter = true,
  noYearCycle = false,
  light = false,

  width,
  minWidth,
  maxWidth,
  fullWidth = false,
  height = '3.875rem',

  onChange,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  const [open, setOpen] = useState(false)

  const selectedMonthString = useMemo(
    () => capitalize(format(value, 'LLLL')),
    [value]
  )
  const selectedMonth = useMemo(() => value.getMonth(), [value])
  const selectedYear = useMemo(() => value.getFullYear(), [value])

  const months = useMemo(
    () =>
      range(12).map(month =>
        capitalize(format(new Date(selectedYear, month, 1), 'LLLL'))
      ),
    [selectedYear]
  )
  const years = useMemo(() => {
    const baseYear = noYearCycle ? new Date().getFullYear() : selectedYear
    const forwardYears =
      futureYears > 0
        ? futureYears
        : clamp(new Date().getFullYear() - baseYear, 0, 5)
    return range(
      baseYear - (baseYear - earliestYear) + 1,
      baseYear + (1 + forwardYears)
    )
  }, [futureYears, noYearCycle, selectedYear, earliestYear])

  const monthInFuture = (month: number) =>
    isAfter(new Date(selectedYear, month, 1), new Date())

  const monthIsDisabled = (month: number) => {
    return monthInFuture(month) && disableAfter
  }

  const yearIsDisabled = (year: number) => {
    return yearInFuture(year) && disableAfter
  }

  const yearInFuture = (year: number) =>
    isAfter(new Date(year, selectedMonth, 1), new Date())

  useClickOutside(wrapperRef, () => setOpen(false), true)

  function handleMonthClick(month: number) {
    if (monthIsDisabled(month)) return
    onChange(setMonth(value, month))
  }
  function handleYearClick(year: number) {
    if (yearIsDisabled(year)) return
    onChange(setYear(value, year))
  }

  return (
    <Wrapper
      ref={wrapperRef}
      width={fullWidth ? '100%' : width}
      minWidth={minWidth}
      maxWidth={maxWidth}
    >
      <Display
        open={open}
        light={light}
        height={height}
        onClick={() => setOpen(v => !v)}
      >
        <Month>
          <div>
            {!isMobileOnly && (
              <SVGIcon
                className="calendar-icon"
                type="light"
                icon="calendar-month"
                fill="gray6"
              />
            )}
            <span>{selectedMonthString}</span>
          </div>

          {!isMobileOnly && (
            <Icon
              className="arrow"
              icon="chevron-down"
              color="gray7"
              size="1rem"
            />
          )}
        </Month>

        <Year>
          {selectedYear}

          <Icon
            className="arrow"
            icon="chevron-down"
            color="gray7"
            size="1rem"
            margin="0 0 0 2rem"
          />
        </Year>
      </Display>

      {open && (
        <Curtain light={light}>
          <List>
            {months.map((month, index) => (
              <MonthItem
                key={month}
                active={index === selectedMonth}
                inFuture={monthIsDisabled(index)}
                onClick={() => handleMonthClick(index)}
              >
                {month}
              </MonthItem>
            ))}
          </List>
          <List>
            {years.map(year => (
              <YearItem
                key={year}
                active={year === selectedYear}
                inFuture={yearIsDisabled(year)}
                onClick={() => handleYearClick(year)}
              >
                {year}
              </YearItem>
            ))}
          </List>
        </Curtain>
      )}
    </Wrapper>
  )
}
