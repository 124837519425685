import { gql } from '@apollo/client'

export const UserFragments = {
  info: gql`
    fragment UserInfo on UserNode {
      id
      firstName
      lastName
      fullName
      birthNumber
      address
      city
      postalCode
      contactPersonName
      contactPersonPhoneNumber
      phoneNumber
      initials
      internalNumber
      username
      email
      isStaff
      isSuperuser
      isActive
      needsPasswordChange
      hasRegistered
      profilePicture
      position
      isAdministrator
    }
  `,
  reducedInfo: gql`
    fragment ReducedUserInfo on UserNode {
      id
      firstName
      lastName
      fullName
      email
      phoneNumber
      initials
      position
      profilePicture
      isActive
      hasRegistered
      internalNumber
      activeDriverCard {
        isExpired
      }
      hasLoginCode
    }
  `,
  userTypeInfo: gql`
    fragment UserTypeInfo on UserTypeNode {
      id
      name
      color
      isAdministrator
      dropdowns
    }
  `,
  types: gql`
    fragment UserTypes on UserNode {
      userTypes {
        id
        name
        color
        isAdministrator
      }
    }
  `,
  documentInfo: gql`
    fragment UserDocuments on UserNode {
      documents {
        edges {
          node {
            id
            name
            responsibleUser {
              id
              fullName
            }
            expirationDate
            notificationTime
            files {
              id
              file
              name
            }
          }
        }
      }
    }
  `,
  driverCard: gql`
    fragment DriverCardInfo on DriverCardNode {
      id
      cardIssuingMemberState
      driverIdentification
      issuedAt
      expiresAt
      isExpired
    }
  `,
}

export const ThumbnailUserFragment = gql`
  fragment ThumbnailUser on UserNode {
    id
    fullName
    profilePicture
    initials
  }
`
