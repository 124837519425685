import { gql } from '@apollo/client'

export const VehicleFragments = {
  vehicleInfo: gql`
    fragment VehicleInfo on VehicleNode {
      id
      registrationNumber
      vin
    }
  `,
}
