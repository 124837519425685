import { useTranslate } from '@ur/react-hooks'
import { isMobileOnly } from 'react-device-detect'
import { InfoBox, SettingsWrapper } from '..'

interface RoutePlannerSettingsProps {}

export const RoutePlannerSettings: React.VFC<RoutePlannerSettingsProps> =
  () => {
    const translations = useTranslate({
      info: 'settings.info.route-planner',
    })

    return (
      <SettingsWrapper grid={{ flow: 'row' }}>
        <InfoBox initCollapsed={isMobileOnly}>{translations.info}</InfoBox>
      </SettingsWrapper>
    )
  }
