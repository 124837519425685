import React from 'react'
import styled from 'styled-components'
import { AppNotificationNode, AppNotificationType } from '../types'
import { Thumbnail } from 'components/Thumbnail'
import { NotificationType } from '../consts'
import { SVGIcon } from 'components'

const IconWrapper = styled.div`
  margin-top: 1rem;
  svg {
    width: 46px;
    height: 46px;
  }
`

export function getIconFromAppNotificationType(
  type: AppNotificationType
): React.ReactNode {
  switch (type) {
    case NotificationType.ANY || NotificationType.NEW_INFRINGEMENT:
      return (
        <IconWrapper>
          <SVGIcon icon="bell" />
        </IconWrapper>
      )
    case NotificationType.FORUM_THREAD_RESPONSE:
      return (
        <IconWrapper>
          <SVGIcon icon="chat" />
        </IconWrapper>
      )
    case NotificationType.NEW_FORUM_THREAD:
      return (
        <IconWrapper>
          <SVGIcon icon="chat" />
        </IconWrapper>
      )
    case NotificationType.USER_DOCUMENT_ABOUT_TO_EXPIRE:
      return (
        <IconWrapper>
          <SVGIcon icon="warning" />
        </IconWrapper>
      )
    case NotificationType.ABSENCE_STATUS_CHANGED:
      return (
        <IconWrapper>
          <SVGIcon icon="user-x" />
        </IconWrapper>
      )
    case NotificationType.EXPORT_COMPLETED:
      return (
        <IconWrapper>
          <SVGIcon icon="file-arrow-down" />
        </IconWrapper>
      )
  }
}

export function getAppNotificationImage(
  appNotification: AppNotificationNode
): React.ReactNode {
  if (appNotification.imageUrl) {
    return (
      <IconWrapper>
        <Thumbnail src={appNotification.imageUrl} size={50} shape="circle" />
      </IconWrapper>
    )
  } else {
    return getIconFromAppNotificationType(appNotification.type)
  }
}
