import { FormErrors, FormOnChangeFn, UpdateFormFn, useTranslate } from '@ur/react-hooks'
import { Checkbox, DatePicker, Select, TimePicker, UserSelect } from 'components'
import { PlainLink } from 'components/Link'

import { UserNode } from 'modules/users'
import { ThumbnailUser } from 'modules/users/UserThumbOrInitials'
import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { format } from 'util/date-fns'
import { set } from 'date-fns'
import { overloadColor } from 'util/style'
import { EditIssueForm } from '../types'
import { IssueCategoryNode, IssueRiskAspectNode, IssueSeverity } from '../types.graphl'
import { CategorySelect, ShallowCategory } from './CategorySelect'
import { unlessLoadingDots } from 'util/loading'
import { usePermissions } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { useIssueSeverityOptions } from '../consts'
import { IssueRiskAspectSelect } from 'components/Select/IssueRiskAspectSelect'


const ResponsibleWrapper = styled.div`
  .--select-display-selected {
    color: ${props => props.theme.colors.black};
    display: flex;
    .--select-display-selected-clear {
      margin-top: auto;
      margin-bottom: auto;
      max-height: 20px;
    }

  }

`

interface WrapperProps {
  editing: boolean
}
const Wrapper = styled.section<WrapperProps>`
  grid-area: main;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    '. . .'
    '. . .'
    '. date date';

  span.unknown {
    color: ${props => props.theme.colors.red};
  }

  ${props => props.theme.media.mobile} {
    overflow-x: auto;

    ${props =>
      props.editing &&
      css`
        display: flex;
        flex-direction: column;

        & > div {
          border: 1px solid ${props => props.theme.colors.gray8} !important;
          border-width: 1px 0 0 0 !important;

          &.category {
            order: -1;
            border-width: 0 !important;
          }
          &.date {
            order: -1;
          }
        }
      `};
  }
`
const Info = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;

  & + div {
    border-left: 1px solid ${props => props.theme.colors.gray8};

    &:nth-child(4) {
      border-left: 0;
    }
    &:nth-child(n + 4) {
      border-top: 1px solid ${props => props.theme.colors.gray8};
    }
    &:nth-child(7) {
      border-left: 0;
    }
    &:nth-child(8) {
      grid-area: date;
      border-top: 1px solid ${props => props.theme.colors.gray8};
    }
  }

  font-weight: 600;

  >span {
    color: ${props => props.theme.colors.gray5};
  }
  p {
    margin: 0;
    font-size: 1.6em;
  }

  ${props => props.theme.media.mobile} {
    padding: 1rem;

    span {
      font-size: 0.7rem;
    }
    p {
      font-size: 1rem;
    }
  }
`
interface IssueStatusProps {
  closed: boolean
  archived: boolean
}
const IssueStatus = styled.div<IssueStatusProps>`
  display: flex;
  align-items: center;

  &::after {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    margin-left: 0.5ch;
    margin-bottom: 3px;

    border-radius: 50%;
    background: ${props =>
      props.archived
        ? props.theme.colors.orange
        : props.closed
        ? props.theme.colors.green
        : props.theme.colors.red};
  }
`

const Severity = styled.div<{ severity: IssueSeverity | null }>`
  display: flex;
  align-items: center;

  ${props => props.severity !== null && `
    &::after {
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      margin-left: 0.5ch;
      margin-bottom: 3px;

      border-radius: 50%;
      background: ${props.severity === "LOW"
          ? props.theme.colors.green
          : props.severity === "MEDIUM"
          ? props.theme.colors.orange
          : props.theme.colors.red};
    }
  `}
`
interface IssueCategoryProps {
  color: string
}
const IssueCategory = styled.div<IssueCategoryProps>`
  &::after {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    margin-left: 0.5ch;
    margin-bottom: 3px;

    border-radius: 50%;
    background: ${props => overloadColor(props.color)};
  }
`
const Editor = styled.div`
  margin-top: 0.5rem;
`
const DateEditor = styled(Editor)`
  display: flex;

  & > * + * {
    margin-left: 0.5rem;
  }
`

interface MainInfoProps {
  loading: boolean
  editing: boolean
  form: EditIssueForm
  errors: FormErrors<EditIssueForm>

  category: Pick<IssueCategoryNode, 'id' | 'name' | 'color'> & {
    responsibleUser: ThumbnailUser | null
  } | null | undefined
  date: string | undefined
  closedAt: string | null | undefined
  archivedAt: string | null | undefined
  reference: string | null | undefined
  user: ThumbnailUser | null | undefined
  responsible: ThumbnailUser | null | undefined
  severity: IssueSeverity | undefined
  riskAspect: IssueRiskAspectNode | null | undefined

  onChange: FormOnChangeFn<EditIssueForm>
  updateForm: UpdateFormFn<EditIssueForm>
}

export const MainInfo: React.VFC<MainInfoProps> = ({
  loading,
  editing,
  form,
  errors,

  category,
  date,
  closedAt,
  archivedAt,
  reference,
  user,
  responsible,
  severity,
  riskAspect,

  onChange,
  updateForm
}) => {
  const translations = useTranslate({
    unknown: 'common.unknown',

    open: 'common.open-alt',
    closed: 'common.closed',
    archived: 'common.archived',

    status: 'common.status',
    category: 'common.category',
    reference: ['issues.reference', { abbreviate: false }],
    date: 'common.date',
    user: 'common.user',
    responsible: 'common.responsible',

    riskAspect: 'common.risk-aspect',

    none: 'common.none',
    severity: 'common.severity',
    severityGrades: {
      LOW: 'common.low',
      MEDIUM: 'common.medium',
      HIGH: 'common.high',
    }
  })

  const severityOptions = useIssueSeverityOptions()

  const [temporaryResponsible, setTemporaryResponsible] =
    useState<Pick<UserNode, 'id' | 'fullName'> | null>(null)

  const { hasPermissions } = usePermissions()

  const canEdit = hasPermissions(PERMISSIONS.issues.change.issue)

  useEffect(() => {
    setTemporaryResponsible(editing ? responsible ?? null : null)
  }, [editing, responsible])

  function handleChangeCategory(
    id: string,
    category: ShallowCategory | null
  ) {
    updateForm({ category: id, severity: category?.defaultSeverity ?? form.severity})
    setTemporaryResponsible(category?.responsibleUser ?? temporaryResponsible)
  }

  function handleChangeDate(date: Date) {
    const newDate = set(date, {
      hours: form.date.getHours(),
      minutes: form.date.getMinutes(),
      seconds: form.date.getSeconds(),
    })
    onChange('date')(newDate)
  }

  const unlessLoading = unlessLoadingDots(loading, translations.unknown)

  const issueStatus = useMemo(() => {
    if (typeof closedAt === 'undefined' && typeof archivedAt === 'undefined')
      return null

    const archived =
      archivedAt !== null ? ` (${translations.archived.toLowerCase()})` : ''
    let text = ''

    if (closedAt !== null) text = `${translations.closed}${archived}`
    else text = `${translations.open}${archived}`

    return (
      <IssueStatus closed={closedAt !== null} archived={archivedAt !== null}>
        {text}
      </IssueStatus>
    )
  }, [
    archivedAt,
    closedAt,
    translations.archived,
    translations.closed,
    translations.open,
  ])

  const issueCategory = useMemo(() => {
    if (!category) return '-'

    return <IssueCategory color={category.color}>{category.name}</IssueCategory>
  }, [category])

  const displaySeverity = severity ?? form.severity

  return (
    <Wrapper editing={editing}>
      <Info>
        <span>{translations.status}</span>
        {!editing ? (
          <p>{unlessLoading(issueStatus)}</p>
        ) : (
          <Editor>
            <Checkbox
              checked={form.closed}
              label={translations.closed}
              boxSize="2rem"
              onChange={onChange('closed')}
            />
          </Editor>
        )}
      </Info>

      <Info>
        <span>{translations.user}</span>
        <p>
          {unlessLoading(
            <PlainLink
              to={!!user ? `/users/${user?.id}` : '#'}
              hoverColor="secondary"
            >
              {user?.fullName ?? '-'}
            </PlainLink>
          )}
        </p>
      </Info>

      <Info>
        <span>{translations.reference()}</span>
        <p>{unlessLoading(reference)}</p>
      </Info>

      <Info>
        <span>{translations.severity}</span>
        {!editing ? (
          <p><Severity severity={displaySeverity}>{unlessLoading(displaySeverity ? translations.severityGrades[displaySeverity] : "-")}</Severity></p>
        ) : (
          <Editor>
            <Select 
              value={form.severity} 
              options={severityOptions}
              onChange={(value) => onChange('severity')(value)}
              height="3rem"
              grayed
              fullWidth
              nullable
              error={errors.severity}
            />
          </Editor>
        )}
      </Info>

      <Info>
        <span>{translations.responsible}</span>
        {!editing ? (
          <p>
            {unlessLoading(
              <PlainLink
                to={!!responsible ? `/users/${responsible?.id}` : '#'}
                hoverColor="secondary"
              >
                {responsible?.fullName ?? '-'}
              </PlainLink>
            )}
          </p>
        ) : (
          canEdit ? 
            <ResponsibleWrapper>
              <Editor>
                <UserSelect
                  value={temporaryResponsible?.id ?? null}
                  dropdownPageName='issue_category_responsible'
                  height="3rem"
                  onChange={(value, option) => {
                    onChange("responsibleUser")(value)
                    if (value === null) setTemporaryResponsible(category?.responsibleUser ?? null)
                    else setTemporaryResponsible(option?.extra ?? null)
                  }}
                  fullWidth
                  nullable={temporaryResponsible?.id !== category?.responsibleUser?.id}
                  grayed
                />
              </Editor>
            </ResponsibleWrapper>
          : <p>
            {!!temporaryResponsible ? (
              <PlainLink
                to={`/users/${temporaryResponsible.id}`}
                hoverColor="secondary"
              >
                {temporaryResponsible.fullName}
              </PlainLink>
            ) : (
              translations.none
            )}
          </p>
        )}
      </Info>

      <Info className="category">
        <span>{translations.category}</span>
        {!editing ? (
          <p>{unlessLoading(issueCategory)}</p>
        ) : (
          <Editor>
            <CategorySelect
              value={form.category}
              fullWidth
              onChange={handleChangeCategory}
            />
          </Editor>
        )}
      </Info>

      <Info>
        <span>{translations.riskAspect}</span>
        {!editing ? (
        <p>{unlessLoading(riskAspect?.name ?? "-")}</p>
        ) : (
          <Editor>
            <IssueRiskAspectSelect 
              value={form.riskAspect}
              onChange={(value) => onChange('riskAspect')(value)}
              height="3rem"
              fullWidth
              nullable
              grayed
              error={errors.riskAspect}
            />
          </Editor>
        )}
      </Info>

      <Info
        className="date"
        title={date ? format(new Date(date), 'dd.MM.yyyy HH:mm') : undefined}
      >
        <span>{translations.date}</span>
        {!editing ? (
          <p>{unlessLoading(format(new Date(date ?? 0), 'PP, HH:mm'))}</p>
        ) : (
          <DateEditor>
            <TimePicker
              value={form.date}
              height="3rem"
              onChange={onChange('date')}
            />
            <DatePicker
              value={form.date}
              fullWidth
              height="3rem"
              noAnimation
              onChange={handleChangeDate}
            />
          </DateEditor>
        )}
      </Info>
    </Wrapper>
  )
}
