import React, { useState } from 'react'
import styled from 'styled-components'
import { DetectBlur } from 'containers/util-containers/DetectBlur'
import { DateRangePickerMonthView } from './DateRangePickerMonthView'
import { DateRangePickerYearView } from './DateRangePickerYearView'
import { isDisabled } from './util'
import { GRANULARITY, DatePickerProps } from 'components/DatePicker/types'
import { format } from 'util/date-fns'
import { isSameDay } from 'date-fns'

const Wrapper = styled.div`
  font-size: 14px;
  position: relative;
  font-weight: 500;
`

const InputDiv = styled.div<{ open: boolean; grayed?: boolean }>`
  ${props =>
    props.open ? `border-radius: 12px 12px 0 0;` : `border-radius: 12px;`}
  border-color: ${props => props.theme.colors.gray9};
  background: ${props => props.grayed && props.theme.colors.quaternary};

  width: 100%;
  font-size: 1.2rem !important;
  padding: 0 calc(4px + 0.6rem);
  height: 3.875rem;
  line-height: 3.875rem;
  border: 1px solid ${props => props.theme.colors.gray9};
  user-select: none;
`

export const DateRangePicker: React.FC<
  DatePickerProps<[Date, Date]> & { grayed?: boolean }
> = ({
  onChange,
  value,
  displayFormat = 'dd.MM.yyyy',
  error,

  grayed = true,

  disabledDates,
  disableAfter,
  disableBefore,
}) => {
  const [monthPivot, setMonthPivot] = useState(value[0])
  const [yearPivot, setYearPivot] = useState(monthPivot)
  const [dateRange, setDateRange] = useState<[Date, Date]>(value)

  const initialGranularity = GRANULARITY.MONTH

  const [granularity, setGranularity] = useState(initialGranularity)

  const [open, setOpen] = useState(false)

  const isDisabledCallback = (date: Date): boolean => {
    return isDisabled(date, disableBefore, disableAfter, disabledDates)
  }

  const handleRangeChanged = (
    newDateRange: [Date, Date],
    close: boolean = true
  ): void => {
    onChange(newDateRange)
    setDateRange(newDateRange)
    if (close) setOpen(false)
  }

  const handleInputKeyDown = (event: React.KeyboardEvent<Element>): void => {
    if (event.key === 'Tab') {
      setOpen(false)
    }
  }

  return (
    <Wrapper>
      <DetectBlur active detectEscape onClickOutside={() => setOpen(false)}>
        <InputDiv
          onFocus={() => setOpen(true)}
          onClick={() => setOpen(!open)}
          onChange={() => {}}
          onKeyDown={handleInputKeyDown}
          open={open}
          grayed={grayed}
        >
          {isSameDay(dateRange[0], dateRange[1])
            ? format(dateRange ? dateRange[0] : value[0], displayFormat)
            : format(dateRange ? dateRange[0] : value[0], displayFormat) +
              ' - ' +
              format(dateRange ? dateRange[1] : value[1], displayFormat)}
        </InputDiv>
        {open && granularity === GRANULARITY.MONTH && (
          <DateRangePickerMonthView
            dateRange={dateRange}
            monthPivot={monthPivot}
            setMonthPivot={setMonthPivot}
            handleRangeChanged={handleRangeChanged}
            isDisabled={isDisabledCallback}
            onChangeGranularity={() => setGranularity(GRANULARITY.YEAR)}
            grayed={grayed}
          />
        )}
        {open && granularity === GRANULARITY.YEAR && (
          <DateRangePickerYearView
            dateRange={dateRange}
            yearPivot={yearPivot}
            setYearPivot={setYearPivot}
            setMonthPivot={setMonthPivot}
            isDisabled={isDisabledCallback}
            onChangeGranularity={() => setGranularity(GRANULARITY.MONTH)}
            grayed={grayed}
          />
        )}
      </DetectBlur>
    </Wrapper>
  )
}
