import { SelectOption } from '@ur/react-components'
import { useDebounce, useTranslate } from '@ur/react-hooks'
import {
  AllUsersShallowQuery,
  AllUsersShallowQueryVariables,
  ShallowUserNode,
} from 'modules/users'
import { ALL_USERS_SHALLOW_QUERY } from 'modules/users/queries'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import { DropdownFilter } from 'types/graphql/enums'
import { Select, SelectProps as BaseSelectProps } from './Select'
import { getUserLabel } from 'modules/users/util'

const User = styled.div`
  display: flex;
  align-items: center;

  span.name {
    margin-left: 0.6rem;
  }
`

interface UserSelectProps
  extends Omit<
    BaseSelectProps<string, ShallowUserNode>,
    'options' | 'onFetchMore'
  > {
  alwaysInclude?: ShallowUserNode | null
  dropdownPageName?: DropdownFilter
}

export const UserSelect: React.VFC<UserSelectProps> = ({
  alwaysInclude = null,
  dropdownPageName,
  ...selectProps
}) => {
  const { placeholder } = useTranslate({
    placeholder: 'common.select-user',
  })

  const [query, setQuery] = useState('')
  const [allOptions, setAllOptions] = useState<SelectOption<string, ShallowUserNode>[]>([])
  const [currentOptions, setCurrentOptions] = useState<SelectOption<string, ShallowUserNode>[]>([])
  const debouncedQuery = useDebounce(query)

  const { data, loading, fetchMore } = useQuery<
    AllUsersShallowQuery,
    AllUsersShallowQueryVariables
  >(ALL_USERS_SHALLOW_QUERY, {
    variables: {
      q: debouncedQuery,
      orderBy: 'lastName',
      dropdown: dropdownPageName,
    },
  })

  const getLabel = useCallback((user: ShallowUserNode) => {
    const name = getUserLabel(user)

    return (
      <User>
        <span className="name">{name}</span>
      </User>
    )
  }, [])

  const options = useMemo<SelectOption<string, ShallowUserNode>[]>(() => {
    if (!data) return []

    const options = data.allUsers.edges.map(({ node: user }) => ({
      value: user.id,
      label: getLabel(user),
      extra: user,
    }))
    if (alwaysInclude === null) return options

    return [
      {
        value: alwaysInclude.id,
        label: getLabel(alwaysInclude),
        extra: alwaysInclude,
      },
      ...options.filter(option => option.extra.id !== alwaysInclude.id),
    ]
  }, [alwaysInclude, data, getLabel])

  useEffect(() => {
    if (options === currentOptions) return
    const newUniqueOptions = options.filter(option => !allOptions.find(allOption => allOption?.extra?.id === option.extra?.id))
    setCurrentOptions(options)
    setAllOptions([...allOptions, ...newUniqueOptions].sort((a, b) => a.extra?.lastName?.localeCompare(b.extra?.lastName ?? "") ?? 0))
  }, [allOptions, currentOptions, options])

  /**
   * Whenever the user select gets close to the bottom, refetch.
   * @remarks Almost a complete copy of `handleScrollBottom` in `UsersTable.tsx`. Might want to refactor to a hook?
   */
  const handleFetchMore = useCallback(async () => {
    if (typeof data === 'undefined') return
    if (loading || data.allUsers.pageInfo?.endCursor === null) return
    try {
      await fetchMore({
        variables: {
          q: debouncedQuery,
          orderBy: 'lastName',
          after: data.allUsers.pageInfo?.endCursor,
        },
      })
    } catch (e) {
      //@ts-ignore
      if (e.name === 'Invariant Violation') return
      throw e
    }
  }, [data, fetchMore, debouncedQuery, loading])

  return (
    <Select
      options={debouncedQuery === "" ? allOptions : options}
      loading={loading}
      placeholder={placeholder}
      searchable="custom"
      filterBackground="white"
      optionHoverBackground="white"
      onFilterChange={setQuery}
      onFetchMore={handleFetchMore}
      {...selectProps}
    />
  )
}
