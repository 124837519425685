import { FormErrors, FormOnChangeFn, useTranslate } from '@ur/react-hooks'
import {
  Checkbox,
  Input as BaseInput,
  NumberInput as BaseNumberInput,
} from 'components'
import { TextArea as BaseTextArea } from 'components/TextArea'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { AutoCompleteCountryCodes } from 'util/consts'
import { useAdmin, useGoogleAutocomplete } from 'util/hooks'
import { UseGoogleAutocompleteOptions } from 'util/hooks/useGoogleAutocomplete/types'
import { VehicleSelect } from '.'
import { EditIssueForm } from '../types'
import { Box } from './Details'

const Wrapper = styled.div`
  grid-area: details;

  display: grid;
  grid-template-columns: 3fr minmax(250px, auto);
  grid-template-areas:
    'location    reg-nr'
    'locationDescription locationDescription'
    'description description'
    'damage      damage'
    'suggestion  cost-estimate'
    'extend-form extend-form'
    'solution actual-cost';

  background: ${props => props.theme.colors.quaternary};

  ${props => props.theme.media.mobile} {
    overflow-x: auto;

    display: flex;
    flex-direction: column;

    & > div {
      border: 1px solid ${props => props.theme.colors.gray8} !important;
      border-width: 1px 0 0 0 !important;
    }
  }
`
const Editor = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0.5rem;
`
const Input = styled(BaseInput).attrs({
  background: 'white',
  height: '3rem',
})``
const NumberInput = styled(BaseNumberInput).attrs({
  background: 'white',
  width: 'auto',
  height: '3rem',
})`
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`
const TextArea = styled(BaseTextArea).attrs({
  background: 'white',
  height: '5rem',
})``
const PlaceEditor = styled(Editor)`
  flex-direction: row;
`

const CurrencyEditor = styled(Editor)`
  flex-direction: row;

  div.currency {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 3rem;
    padding: 0 0.5rem;

    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.9rem;

    background-color: white;
    border: 1px solid ${props => props.theme.colors.gray9};
    border-left-width: 0;

    border-top-right-radius: ${props => props.theme.sizes.defaultBorderRadius};
    border-bottom-right-radius: ${props =>
      props.theme.sizes.defaultBorderRadius};
  }
`

interface CreateDetailsProps {
  form: EditIssueForm
  errors: FormErrors<EditIssueForm>

  onLocationUpdate: (
    results: google.maps.GeocoderResult[] | null,
    status: google.maps.GeocoderStatus
  ) => void
  onChange: FormOnChangeFn<EditIssueForm>
}

export const CreateDetails: React.VFC<CreateDetailsProps> = ({
  form,
  errors,

  onLocationUpdate,
  onChange,
}) => {
  const translations = useTranslate({
    location: 'common.location',
    equipment: 'issues.equipment-material',
    damage: 'issues.damage-description',
    description: 'common.description',
    suggestedSolution: 'issues.suggested-solution',
    costEstimate: 'issues.cost-estimation',
    locationDescription: 'issues.location-description',
    solution: 'issues.final-solution',
    actualCost: 'issues.actual-cost',
    extendForm: 'issues.extend-issue-form',
  })

  const prevPlacename = useRef<string | null>(null)
  const descriptionPlaceIdMap = useRef(new Map<string, string>())
  const [triggerGeocoding, setTriggerGeocoding] = useState(false)
  const admin = useAdmin()

  const handleFindPlacename = useCallback(() => {
    prevPlacename.current = form.placename
    const service = new google.maps.Geocoder()
    const placeIdValue = descriptionPlaceIdMap.current.get(
      prevPlacename.current
    )

    const geocodeOptions =
      // if we have a placeID from the autocomplete this simply needs to be a lookup with the ID, otherwise we try to find something with geocoding
      placeIdValue !== undefined
        ? { placeId: placeIdValue }
        : { address: form.placename }
    service.geocode(geocodeOptions, onLocationUpdate)
  }, [form.placename, prevPlacename, onLocationUpdate])

  useEffect(() => {
    prevPlacename.current = form.placename

    if (typeof google === 'undefined' || form.placename.trim() === '') return

    if (triggerGeocoding) {
      handleFindPlacename()
      setTriggerGeocoding(false)
    }
  }, [
    form.placename,
    prevPlacename,
    triggerGeocoding,
    onLocationUpdate,
    handleFindPlacename,
  ])

  const predictions = useGoogleAutocomplete(form.placename, {
    componentRestrictions: {
      country: AutoCompleteCountryCodes,
    },
  } as UseGoogleAutocompleteOptions)

  const autoCompleteSource = predictions.predictions?.map(pred => {
    // The description field is the human readable value, whereas if we pick an option from here
    // we want to use the ID related to said description. We do this by making use of a hashmap which encodes a
    // string with an ID which we can use when we want to perform geocoding. If the key already exists we don't bother.
    if (!descriptionPlaceIdMap.current.has(pred.description)) {
      descriptionPlaceIdMap.current.set(pred.description, pred.place_id)
    }
    return pred.description
  })

  function handlePlacenameKeyDown(
    event: React.KeyboardEvent<HTMLInputElement>
  ) {
    const { key } = event
    if (key === 'Enter') {
      setTriggerGeocoding(true)
    }
  }

  function handleAutoCompleteItemSelect(placename: string) {
    onChange('placename')(placename)
    setTriggerGeocoding(true)
  }

  return (
    <Wrapper>
      <Box area="location">
        <h4>{translations.location}</h4>
        <PlaceEditor>
          <Input
            value={form.placename}
            fullWidth
            autoCompleteSource={autoCompleteSource}
            onAutoCompleteItemSelect={handleAutoCompleteItemSelect}
            onChange={onChange('placename')}
            onKeyDown={handlePlacenameKeyDown}
          />
        </PlaceEditor>
      </Box>

      <Box area="reg-nr" borderLeft>
        <h4>{translations.equipment}</h4>
        <Editor>
          <VehicleSelect
            value={form.regNr}
            searchable
            nullable={true}
            placeholder="--"
            width="100%"
            onChange={onChange('regNr')}
          />
        </Editor>
      </Box>

      <Box area="locationDescription" borderTop>
        <h4>{translations.locationDescription}</h4>
        <Editor>
          <TextArea
            value={form.locationDescription}
            error={errors.locationDescription}
            fullWidth
            onChange={onChange('locationDescription')}
          />
        </Editor>
      </Box>

      <Box area="description" borderTop>
        <h4>{translations.description}</h4>
        <Editor>
          <TextArea
            value={form.description}
            error={errors.description}
            fullWidth
            onChange={onChange('description')}
          />
        </Editor>
      </Box>

      <Box area="damage" borderTop>
        <h4>{translations.damage}</h4>
        <Editor>
          <TextArea
            value={form.damage}
            error={errors.damage}
            fullWidth
            onChange={onChange('damage')}
          />
        </Editor>
      </Box>

      <Box area="suggestion" borderTop>
        <h4>{translations.suggestedSolution}</h4>
        <Editor>
          <TextArea
            value={form.suggestedSolution}
            error={errors.suggestedSolution}
            fullWidth
            onChange={onChange('suggestedSolution')}
          />
        </Editor>
      </Box>

      <Box area="cost-estimate" borderTop borderLeft>
        <h4>{translations.costEstimate}</h4>
        <CurrencyEditor>
          <NumberInput
            value={form.estimatedCost}
            onChange={onChange('estimatedCost')}
          />
          <div className="currency">NOK</div>
        </CurrencyEditor>
      </Box>
      {admin && (
        <Box area="extend-form" borderTop>
          <Editor>
            <Checkbox
              checked={form.closed}
              onChange={onChange('closed')}
              label={translations.extendForm}
            />
          </Editor>
        </Box>
      )}
      {form.closed && (
        <>
          <Box area="solution" borderTop>
            <h4>{translations.solution}</h4>
            <Editor>
              <TextArea
                value={form.solution ?? ''}
                error={errors.solution}
                fullWidth
                onChange={onChange('solution')}
              />
            </Editor>
          </Box>

          <Box area="actual-cost" borderTop borderLeft>
            <h4>{translations.actualCost}</h4>
            <CurrencyEditor>
              <NumberInput
                value={form.actualCost ?? 0}
                onChange={onChange('actualCost')}
              />
              <div className="currency">NOK</div>
            </CurrencyEditor>
          </Box>
        </>
      )}
    </Wrapper>
  )
}
