import { Icon, usePrompt, useToast } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import {
  AllIssueDataForSettingsQuery,
  ALL_ISSUE_DATA_FOR_SETTINGS_QUERY,
  CreateIssueCategoryMutationVariables,
  IssueRiskAspectNode,
} from 'modules/issues'
import { ShallowUserNode } from 'modules/users'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { useConfirm, useOnErrorAuto } from 'util/hooks'
import {
  IssueCategorySettingsCard,
  IssueCategoriesSettingsForm,
  IssueCategoryForSettings,
} from '../components'
import {
  AddButton,
  InfoBox,
  Section as BaseSection,
  SectionHeader,
  SettingsWrapper,
} from '../components/common'
import {
  IssueCategorySettingsTable,
  IssueCategoryForm,
  IssueCategoryPrompt,
} from '../components/IssueCategorySettings'
import { SelectUserPrompt } from '../components/SelectUserPrompt'
import { useIssueCategoryMutations, useIssueRiskAspectMutations } from '../util/mutations.hooks'
import { DropdownNames } from 'components/Select/types'
import { IssueRiskAspectForm, IssueRiskAspectPrompt } from '../components/IssueCategorySettings/IssueRiskAspectPrompt'
import { Table } from 'components/Table'

export const LS_KEY_LIST_VIEW_MODE_ISSUE_CATEGORIES_SETTINGS =
  'firmadok-list-view-mode-issue-categories-settings'

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`
const Section = styled(BaseSection)`
  h4 {
    margin: 0;
  }
  ul {
    margin: 0;
    padding-left: 1rem;
  }
`

interface IssueSettingsProps {}

export const IssueSettings: React.VFC<IssueSettingsProps> =
  () => {
    const translations = useTranslate({
      info1: 'settings.info.issue-categories-1',
      info2: 'settings.info.issue-categories-2',

      issueCategories: 'common.issue-categories',
      issueRiskAspect: 'common.risk-aspect',
      issueRiskAspects: 'common.risk-aspects',

      validation: {
        nameExists: 'settings.validation.issue-category-name-exists',
      },
      prompt: {
        deleteCategory: ['settings.prompts.issue-category-delete', { name: '' }],
        deleteCategoryTitle: 'settings.prompts.issue-category-delete-title',
        deleteRiskAspect: ['settings.prompts.issue-risk-aspect-delete', { name: '' }],
        deleteRiskAspectTitle: 'settings.prompts.issue-risk-aspect-delete-title',
      },
    })

    const confirm = useConfirm()

    const [adding, setAdding] = useState(false)
    const [editing, setEditing] = useState<string | null>(null)
    const [temporaryEditColor, setTemporaryEditColor] = useState<{
      [id: string]: string | null
    }>({})

    const {
      formValues: form,
      formChangeHandler: handler,
      updateForm,
    } = useForm<IssueCategoriesSettingsForm>({
      values: {
        newName: '',
        newColor: 'hsl(138deg, 80%, 80%)',
        editName: '',
        defaultSeverity: 'MEDIUM',
        responsibleUser: null,
      },
    })

    // TODO fix exhaustive-deps?
    // eslint-disable-next-line
    const updateFormCallback = useCallback(updateForm, [])
    const onErrorAuto = useOnErrorAuto()
    const addToast = useToast()
    const addPrompt = usePrompt()

    const { data: queryData, loading: queryLoading } = useQuery<
    AllIssueDataForSettingsQuery,
      never
    >(ALL_ISSUE_DATA_FOR_SETTINGS_QUERY, {
      onError: onErrorAuto(),
    })

    const [editableCategories, uneditableCategories] = useMemo(
      () =>
        queryData?.allIssueCategories.edges.reduce<
          [IssueCategoryForSettings[], IssueCategoryForSettings[]]
        >(
          (acc, { node: category }) =>
            category.editable
              ? [[...acc[0], category], acc[1]]
              : [acc[0], [...acc[1], category]],
          [[], []]
        ) ?? [[], []],
      [queryData]
    )

    const riskAspects = queryData?.allIssueRiskAspects?.edges.map(edge => edge.node) ?? []

    useEffect(() => {
      if (!adding) {
        updateFormCallback({
          newName: '',
          newColor: 'hsl(138deg, 80%, 80%)',
          responsibleUser: null,
        })
      }
    }, [adding, updateFormCallback])

    useEffect(() => {
      if (editing === null)
        updateFormCallback({ editName: '', responsibleUser: null })
    }, [editing, updateFormCallback, editableCategories])

    const mutations = useIssueCategoryMutations()
    const riskAspectMutations = useIssueRiskAspectMutations()

    function validateName(name: string) {
      if (name === '') return false
      if (
        !![...editableCategories, ...uneditableCategories].find(
          type => type.name === form.newName
        )
      ) {
        addToast('warning', translations.validation.nameExists)
        return false
      }
      return true
    }

    function checkNameHasChanged(id: string) {
      const category = editableCategories.find(type => type.id === id)
      if (typeof category === 'undefined') return true

      return category.name !== form.editName
    }

    async function submitNewIssueCategory() {
      if (!validateName(form.newName)) return

      await mutations.create({
        variables: {
          input: {
            name: form.newName,
            color: form.newColor,
            defaultSeverity: form.defaultSeverity,
            responsibleUser: form.responsibleUser?.id,
          },
        },
      })
      setAdding(false)
    }
    async function submitEditIssueCategoryName() {
      if (!validateName(form.editName) || editing === null) return
      if (!checkNameHasChanged(editing)) {
        setEditing(null)
        return
      }

      await mutations.patch(true)({
        variables: {
          id: editing,
          input: { name: form.editName },
        },
      })
      setEditing(null)
    }
    function handleUpdateColor(id: string) {
      return async (color: string) => {
        setTemporaryEditColor(v => ({
          ...v,
          [id]: color,
        }))

        try {
          await mutations.patch(false)({
            variables: { id, input: { color } },
          })
        } finally {
          setTemporaryEditColor(v => ({
            ...v,
            [id]: null,
          }))
        }
      }
    }
    async function handleDeleteCategoryPrompt(category: IssueCategoryForSettings) {
      const { data: answer } = await confirm(
        translations.prompt.deleteCategory({ name: category.name }),
        translations.prompt.deleteCategoryTitle
      )
      if (!answer) return

      mutations.delete({
        variables: {
          id: category.id,
        },
      })
    }

    async function handleDeleteRiskAspectPrompt(riskAspect: IssueRiskAspectNode) {
      const { data: answer } = await confirm(
        translations.prompt.deleteRiskAspect({ name: riskAspect.name }),
        translations.prompt.deleteRiskAspectTitle
      )
      if (!answer) return

      riskAspectMutations.delete({
        variables: {
          id: riskAspect.id,
        },
      })
    }
    async function handleResponsibleUserPrompt(
      categoryId: string | null,
      responsibleUser: ShallowUserNode | null
    ) {
      updateForm({ responsibleUser })

      const { data: user } = await addPrompt<typeof responsibleUser>(
        resolve => (
          <SelectUserPrompt
            user={responsibleUser}
            dropdownPageName={DropdownNames.ISSUES}
            onResolve={resolve}
          />
        )
      )
      if (user === null) return

      if (user.id === responsibleUser?.id) {
        updateForm({ responsibleUser: null })
        setEditing(null)
        return
      }

      if (categoryId === null) updateForm({ responsibleUser: user })
      else handleSubmitResponsibleUser(categoryId, user)
    }
    async function handleSubmitResponsibleUser(
      categoryId: string,
      responsibleUser: ShallowUserNode
    ) {
      await mutations.patch(true)({
        variables: {
          id: categoryId,
          input: {
            responsibleUser: responsibleUser.id,
          },
        },
      })
      updateForm({ responsibleUser: null })
      setEditing(null)
    }

    const isLoading = queryLoading || mutations.loading || riskAspectMutations.loading

    async function handleCreateCategoryPrompt() {
      const { data } = await addPrompt<IssueCategoryForm | null>(resolve => (
        <IssueCategoryPrompt
          editableCategories={editableCategories}
          uneditableCategories={uneditableCategories}
          onSubmit={resolve}
        />
      ))
      if (data === null || data.responsibleUser === null) return

      mutations.create({
        variables: {
          input: { ...(data as CreateIssueCategoryMutationVariables['input']) },
        },
      })
    }

    async function handleCreateRiskAspectPrompt() {
      const { data } = await addPrompt<IssueRiskAspectForm | null>(resolve => (
        <IssueRiskAspectPrompt
          existingRiskAspects={riskAspects}
          onSubmit={resolve}
        />
      ))
      if (data === null) return

      riskAspectMutations.create({
        variables: {
          input: { ...(data as CreateIssueCategoryMutationVariables['input']) },
        },
      })
    }

    async function handleEditCategoryPrompt(category: IssueCategoryForSettings) {
      const initialData: IssueCategoryForm = {
        name: category.name,
        color: category.color,
        defaultSeverity: category.defaultSeverity,
        responsibleUser: category.responsibleUser?.id ?? null,
      }
      const { data } = await addPrompt<IssueCategoryForm | null>(resolve => (
        <IssueCategoryPrompt
          initialData={initialData}
          editableCategories={editableCategories}
          uneditableCategories={uneditableCategories}
          onSubmit={resolve}
        />
      ))
      if (data === null || data.responsibleUser === null) return

      mutations.patch(true)({
        variables: {
          id: category.id,
          input: { ...(data as CreateIssueCategoryMutationVariables['input']) },
        },
      })
    }

    async function handleEditRiskAspectPrompt(riskAspect: IssueRiskAspectNode) {
      const initialData: IssueRiskAspectForm = {
        name: riskAspect.name,
      }
      const { data } = await addPrompt<IssueRiskAspectForm | null>(resolve => (
        <IssueRiskAspectPrompt
          initialData={initialData}
          existingRiskAspects={riskAspects}
          onSubmit={resolve}
        />
      ))
      if (data === null) return

      riskAspectMutations.patch({
        variables: {
          id: riskAspect.id,
          input: data,
        },
      })
    }

    return (
      <SettingsWrapper grid={{ flow: 'row' }}>
        <InfoBox initCollapsed={isMobileOnly}>
          <p>{translations.info1}</p>
          <p>{translations.info2}</p>
        </InfoBox>
        <Section>
          <Header>
            <SectionHeader loading={isLoading}>
              {translations.issueRiskAspects}
            </SectionHeader>
            {!isMobileOnly && (
              <AddButton adding={adding} onClick={handleCreateRiskAspectPrompt} />
            )}
          </Header>
          {riskAspects.length > 0 && (
              <Table
                noShadow
                noBorders
                noHeaderMargin
                noRowMargin
                rowHoverColor="secondary"
              >
                <thead>
                  <tr>
                    <th>{translations.issueRiskAspect}</th>
                    <th colSpan={2} />
                  </tr>
                </thead>
                <tbody>
                  {riskAspects.map(aspect => (
                    <tr key={aspect.id}>
                      <td>{aspect.name}</td>
                      <td width="1px">
                        <Icon
                          icon="edit"
                          cursor="pointer"
                          color="gray6"
                          hoverColor="secondary"
                          onClick={() => handleEditRiskAspectPrompt(aspect)}
                        />
                      </td>
                      <td width="1px">
                        <Icon
                          icon="times"
                          cursor="pointer"
                          color="gray6"
                          hoverColor="red"
                          onClick={() => handleDeleteRiskAspectPrompt(aspect)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}

        </Section>
        <Section>
          <Header>
            <SectionHeader loading={isLoading}>
              {translations.issueCategories}
            </SectionHeader>
            {!isMobileOnly && (
              <AddButton adding={adding} onClick={handleCreateCategoryPrompt} />
            )}
          </Header>

          {isMobileOnly ? (
            <IssueCategorySettingsCard
              editableCategories={editableCategories}
              uneditableCategories={uneditableCategories}
              form={form}
              adding={adding}
              editing={editing}
              temporaryEditColor={temporaryEditColor}
              onFormChange={handler}
              onUpdateForm={updateForm}
              onSelectResponsibleUser={handleResponsibleUserPrompt}
              onUpdateColor={handleUpdateColor}
              onAdd={setAdding}
              onAddSubmit={submitNewIssueCategory}
              onEdit={setEditing}
              onEditSubmit={submitEditIssueCategoryName}
              onDelete={handleDeleteCategoryPrompt}
            />
          ) : (
            <IssueCategorySettingsTable
              editableCategories={editableCategories}
              uneditableCategories={uneditableCategories}
              form={form}
              temporaryEditColor={temporaryEditColor}
              onSelectResponsibleUser={handleResponsibleUserPrompt}
              onUpdateColor={handleUpdateColor}
              onEditClick={handleEditCategoryPrompt}
              onDelete={handleDeleteRiskAspectPrompt}
            />
          )}
        </Section>
      </SettingsWrapper>
    )
  }
