import {
  compareAsc,
  differenceInHours,
  differenceInSeconds,
  eachDayOfInterval,
  format as formatDate,
  max,
  min,
  parseISO,
} from 'date-fns'
import { Time } from 'lib/time'
import { AbsenceNode } from 'modules/timesheets'
import { generateRandomColor } from 'util/generators'
import { durationAsSecondsToString, hhmmToNumeric } from 'util/time'
import { DayReport, RangeNode, WeekSummation } from './types'
import { DriverActivityNode } from './types.graphql'

export function getTotalWorkingHoursFromActivities(
  activities: DriverActivityNode[]
) {
  if (activities.length === 0) {
    return 0
  }
  return activities
    .reduce((acc, activity) => {
      if (!activityCountsAsWorking(activity)) return acc
      return acc + getActivityDurationWithDecimals(activity)
    }, 0)
    .toFixed(1)
}

export function getDrivingHoursFromActivities(
  activities: DriverActivityNode[]
) {
  if (activities.length === 0) {
    return 0
  }
  return activities
    .reduce((acc, activity) => {
      if (activity.activityType.toLowerCase() !== 'drive') return acc
      return acc + getActivityDurationWithDecimals(activity)
    }, 0)
    .toFixed(1)
}

export function getRestingHoursFromActivities(
  activities?: DriverActivityNode[]
) {
  if (typeof activities === 'undefined' || activities.length === 0) {
    return 0
  }
  return activities.reduce((acc, activity) => {
    if (activity.activityType.toLowerCase() !== 'rest') return acc
    return acc + getActivityDurationWithDecimals(activity)
  }, 0)
}

export function getEarliestDatetimeFromActivities(
  activities: DriverActivityNode[]
) {
  if (activities.length === 0) {
    return new Date()
  }

  return min(activities.map(activity => parseISO(activity.datetimeStart)))
}

export function stripLeadingAndTrailingRests(activities: DriverActivityNode[]) {
  let startFilterIndex = 0
  let endFilterIndex = activities.length

  // Ensure activities are sorted by start dates
  activities = activities.sort((a, b) =>
    compareAsc(parseISO(a.datetimeStart), parseISO(b.datetimeEnd))
  )

  // Find the first non-rest, where we should start including activities.
  for (let i = 0; i < activities.length; ++i) {
    if (activities[i].activityType !== 'Rest') {
      startFilterIndex = i
      break
    }
  }

  // Find the last non-rest, where we should start including activities.
  for (let i = activities.length - 1; i > 0; --i) {
    if (activities[i].activityType !== 'Rest') {
      endFilterIndex = i
      break
    }
  }

  return activities.slice(startFilterIndex, endFilterIndex + 1)
}

export function getLatestDatetimeFromActivities(
  activities: DriverActivityNode[]
) {
  if (activities.length === 0) {
    return new Date()
  }
  return max(activities.map(activity => parseISO(activity.datetimeEnd)))
}

export function getColorFromActivity(activity: DriverActivityNode) {
  if (!activity || !activity.activityType) {
    return 'tertiary'
  }
  switch (activity.activityType.toLowerCase()) {
    case 'drive':
      return 'primary'
    case 'rest':
      return 'activitiesGreen'
    case 'work':
      return 'orange'
    case 'issue':
      return 'activitiesRed'
    default:
      return 'tertiary'
  }
}

export function getTableColumnFromActivity(activity: DriverActivityNode) {
  if (!activity || !activity.activityType) {
    return -1
  }
  switch (activity.activityType.toLowerCase()) {
    case 'drive':
      return 2
    case 'rest':
      return 3
    case 'work':
      return 4
    case 'issue':
      return 5
    default:
      return -1
  }
}

export function activityCountsAsWorking(activity: DriverActivityNode) {
  if (!activity || !activity.activityType) {
    return false
  }
  switch (activity.activityType.toLowerCase()) {
    case 'drive':
      return true
    case 'rest':
      return false
    case 'work':
      return true
    case 'issue':
      return false
    default:
      return false
  }
}

export function getActivityDuration(activity: DriverActivityNode) {
  if (!activity) {
    return 0
  }

  return differenceInHours(
    parseISO(activity.datetimeStart),
    parseISO(activity.datetimeEnd)
  )
}

export function getActivityDurationWithDecimals(activity: DriverActivityNode) {
  if (!activity) {
    return 0
  }

  return Math.abs(
    differenceInSeconds(
      parseISO(activity.datetimeStart),
      parseISO(activity.datetimeEnd)
    )
  )
}

export function durationStringToObject(durationString?: string) {
  if (!durationString) {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
    }
  }
  const durationSplit = durationString.split(':')

  return {
    hours: parseInt(durationSplit[0], 10) || 0,
    minutes: parseInt(durationSplit[1], 10) || 0,
    seconds: parseInt(durationSplit[2], 10) || 0,
  }
}

export function secondsToDurationObject(seconds: number) {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds / 60) % 60)
  const _seconds = Math.floor((seconds / 3600) % 60)

  return {
    hours,
    minutes,
    seconds: _seconds,
  }
}

export function createWeekSummation(days: DayReport[]): WeekSummation {
  const sums = {
    timeDriving: 0,
    timeOvertime: 0,
    timeAvailability: 0,
    timeRest: 0,
    timePause: 0,
    timePaidRest: 0,
    timeOtherWork: 0,
  }
  // Band of Brothers woo
  for (const day of days) {
    sums.timeDriving += hhmmToNumeric(day.timeDriving ?? '') ?? 0
    sums.timeOvertime += hhmmToNumeric(day.timeOvertime ?? '') ?? 0
    sums.timeAvailability += hhmmToNumeric(day.timeAvailability ?? '') ?? 0
    sums.timeRest += hhmmToNumeric(day.timeRest ?? '') ?? 0
    sums.timePause += hhmmToNumeric(day.timePause ?? '') ?? 0
    sums.timeOtherWork += hhmmToNumeric(day.timeOtherWork ?? '') ?? 0
  }

  return {
    timeDriving: durationAsSecondsToString(sums.timeDriving),
    timeOvertime: durationAsSecondsToString(sums.timeOvertime),
    timeAvailability: durationAsSecondsToString(sums.timeAvailability),
    timeRest: durationAsSecondsToString(sums.timeRest),
    timePause: durationAsSecondsToString(sums.timePause),
    timePaidRest: durationAsSecondsToString(sums.timeRest),
    timeOtherWork: durationAsSecondsToString(sums.timeOtherWork),
  }
}

export function stripSecondsFromDuration(
  durationAsString: string | null
): string {
  if (durationAsString === null) {
    return ''
  }

  if (durationAsString.length === 8) {
    return durationAsString.slice(0, 5)
  }
  return durationAsString
}

export function enforceHourMinuteString(
  durationAsString: string | null | undefined
): string {
  if (!durationAsString) return '00:00'

  if (durationAsString.length > 6) {
    return durationAsString.slice(0, durationAsString.length - 3)
  }
  return durationAsString
}

export function createAbsenceMap(absences: AbsenceNode[]) {
  const absenceMap: { [key: string]: string } = {}
  absences.forEach(absence => {
    const intervalDates = eachDayOfInterval({
      start: parseISO(absence.datetimeStart),
      end: parseISO(absence.datetimeEnd),
    }).map(date => formatDate(date, 'yyyy-MM-dd'))

    const absenceText = `${absence.absenceType.name}: ${absence.reason}`
    intervalDates.forEach(date => {
      if (!(date in absenceMap)) {
        absenceMap[date] = absenceText
      }
    })
  })
  return absenceMap
}

export function optimizeActivities(activities: DriverActivityNode[]) {
  return activities.reduce<DriverActivityNode[]>((acc, cur) => {
    if (acc.length === 0) {
      return [cur]
    }
    const last = acc[acc.length - 1]
    if (
      last.activityType === cur.activityType &&
      last.vehicleVin === cur.vehicleVin &&
      last.datetimeEnd === cur.datetimeStart
    ) {
      acc[acc.length - 1] = {
        ...last,
        vehicleRegistrationNumber:
          last.vehicleRegistrationNumber ?? cur.vehicleRegistrationNumber,
        datetimeEnd: cur.datetimeEnd,
      }
    } else {
      return acc.concat([cur])
    }
    return acc
  }, [])
}

export function optimizeVehicleNodes(nodes: RangeNode[][]) {
  return nodes.reduce<RangeNode[][]>((acc, cur) => {
    if (acc.length === 0) {
      return [cur]
    }
    const last = acc[acc.length - 1]

    // If last vehicle vin, represented in the range text, is the same as the current one
    if (last[0].rangeText === cur[0].rangeText) {
      // We check id the previous node started at midnight. If so we need to add an hour to the end node
      const nextNode =
        last[0].time === 0
          ? {
              ...cur[1],
              time: cur[1].time + 1,
            }
          : cur[1]

      acc[acc.length - 1] = [last[0], nextNode]
    } else {
      return acc.concat([cur])
    }

    return acc
  }, [])
}

export function generateActivityMapColors(numberOfColors: number) {
  let colors = []
  for (let i = 0; i <= numberOfColors; i++) {
    if (i === 0) {
      colors.push('#0088FF')
    } else if (i === 1) {
      colors.push('hsl(276deg, 80%, 80%)')
    } else if (i === 2) {
      colors.push('hsl(1151299deg, 84%, 76%)')
    } else if (i === 3) {
      colors.push('hsl(216720deg, 81%, 77%)')
    } else {
      colors.push(generateRandomColor())
    }
  }
  return colors
}

export function getNodeTime(
  date: Date,
  dstTimestamp: number,
  dstOffset: number,
  dateString: string,
  newUTCTimezone: number,
  localCorrectionOffset: number,
  browserDSTChangeOffset: number
): [Time, number] {
  /**
   * We account for daylight saving changing on a day and return a Time object that's adjusted such that it will position
   * correctly in the detailed activity view. We can make a certain decisions based on the fact that no country has more than
   * 1 hour adjustments for daylight savings time according to https://en.wikipedia.org/wiki/Daylight_saving_time_by_country
   * if we ever need to support Australia we might have to take a second look at this.
   *
   * @param date - The datetime of the node, has to be timezone aware.
   * @param dstTimestamp - The hour of the daylight savings change on the day of the node.
   * @param dstOffset - The offset of the change in daylight savings on the day the node is on.
   * @param dateString - The dates string of the node from the database.
   * @param newUTCTimezone - The new UTC timezone after the daylight savings on the day of the node.
   * @param localCorrectionOffset - The offset of the local timezone to UTC.
   * @param browserDSTChangeOffset - The offset of the browsers daylight savings change on the day.
   * @returns [Time, number] - The adjusted Time for the node and it's text correction to display correct time on the node.
   */
  const time = new Time(date)
  const nodeUTCOffset = Number(dateString.split('+')[1].split(':')[0])
  // Check if the node is after the daylight savings change, and that the browser is on the same timezone as the node.
  if (dstOffset !== 0 && date.getTimezoneOffset() / -60 === newUTCTimezone) {
    // if the time would become negative when adjusted we change it to be on 00:00.

    if (time.reduce('hours') - dstOffset < 0) {
      return [new Time(0, 0), 0]
    }

    // Time class handles added hours beyond 24 as a new day ie. 24+1 = 01, but you can initialize with hours above 24.
    if (time.reduce('hours') - dstOffset >= 24) {
      return [new Time(time.hours - dstOffset, time.minutes), dstOffset]
    }

    const returnTime = time.add(-dstOffset, 'hours')
    // we return the dstOffset as the text correction to display the correct time on the node. This is because the
    // node is positioned based on the time of the node, but the text is displayed based on the time of the node
    // adjusted for the daylight savings change.
    return [returnTime, dstOffset]
  }

  // If the report is viewed from a different timezone, we cannot
  // rely on the date object to give us the correct UTC time for the node.
  if (
    dstOffset !== 0 &&
    localCorrectionOffset !== 0 &&
    nodeUTCOffset === newUTCTimezone &&
    ((time.hours >= dstTimestamp + -dstOffset && dstOffset < 0) ||
      (time.hours > dstTimestamp + -dstOffset && dstOffset > 0))
  ) {
    // we add browserDSTChangeOffset to account for the case where the browser has a dst change
    // on the same day as the node, but not necessarily for the same UTC timezones.
    // this works for London, which is +0, +1, for their dst timezones on the same dates as Norway.
    // I'm not entirely sure why this works, but if I'd have to guess it's because the UTC offset
    // for the date object changes and offsets our hours by 1 when we've already accounted for the
    // dst offset. (for some reason the dst change on browser affects all nodes on the day by +-1 hour, and
    // not just the ones after the dst change.)
    return [time.add(browserDSTChangeOffset, 'hours'), dstOffset]
  }

  if (localCorrectionOffset !== 0) {
    return [time.add(browserDSTChangeOffset, 'hours'), 0]
  }

  return [time, 0]
}

export function parseNumberToTimestamp(number: number): string {
  const time = number.toString().split('.')
  if (time.length === 1) {
    return `${time[0]}:00`.padStart(5, '0')
  }

  const minutes = Math.round(parseFloat(`0.${time[1]}`) * 60)

  return `${time[0]}:${minutes}`.padStart(5, '0')
}
