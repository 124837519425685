import { AnimationControls } from 'framer-motion'
import { Dispatch, SetStateAction } from 'react'

export function useRotateWhileTrueAnimation(
  controls: AnimationControls,
  mutation: any
) {
  /**
   * Initiate fetch and animation
   * @returns onClick handler
   */
  function handleSync() {
    mutation()
    controls.start({
      rotate: 360,
      transition: { duration: 1 },
    })
  }

  /**
   * Resets animation if loading is true
   * @returns void
   */
  function resetAnimation(shouldReanimate: boolean) {
    return () => {
      // The animation stops at 360, so we set rotate to 0.
      // This is to avoid having a counter which rotate would depend upon.
      controls.set({
        rotate: 0,
      })

      // Restart animation if still loading
      if (shouldReanimate) {
        controls.start({
          rotate: 360,
          transition: { duration: 1 },
        })
      }
    }
  }

  /**
   * Chacklogic used to determine if animation should be reanimated
   * for use inside an useEffect. Typically for components that needs this
   * logic where triggering condition is from outside sources. adn keeps an
   * internal state for the animation.
   * @param outerResetCondition - condition to reset animation from outside of
   * syncButton.
   * @param innerResetState - useState state varaiable inside component for
   * reseting animation.
   * @param setStateFunction - useState function for setting state.
   */
  function loadingStateEffect(
    outerResetCondition: boolean,
    innerResetState: boolean,
    setStateFunction: Dispatch<SetStateAction<typeof innerResetState>>
  ) {
    if (outerResetCondition === innerResetState) return
    setStateFunction(outerResetCondition)
  }
  return {
    handleSync,
    resetAnimation,
    loadingStateEffect,
  }
}
