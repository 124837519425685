import React from 'react'

export const EquipmentMaterials: React.VFC<React.SVGAttributes<SVGSVGElement>> =
  props => (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 19.3 17.4"
      enableBackground="new 0 0 19.3 17.4"
      xmlSpace="preserve"
      fill="#003057"
      {...props}
    >
      <path
        className="st0"
        d="M9.7,0C4.9,0,1,3.9,1,8.7s3.9,8.7,8.7,8.7s8.7-3.9,8.7-8.7S14.5,0,9.7,0z M9.7,1.1c4.2,0,7.6,3.4,7.6,7.6h-3.7
	c-0.3-1.3-1.4-2.2-2.7-2.2H8.6c-1.4,0-2.5,1-2.7,2.2H2.1C2.1,4.5,5.5,1.1,9.7,1.1z M9.5,11.6L7.1,9.2C7,9.1,6.9,8.8,7,8.6
	C7.2,8,7.8,7.6,8.6,7.6h2.2c0.7,0,1.3,0.4,1.6,1.1c0.1,0.2,0,0.5-0.1,0.6l-2.4,2.4C9.8,11.7,9.6,11.7,9.5,11.6
	C9.5,11.6,9.5,11.6,9.5,11.6z M2.2,9.8h3.9l3,3v3.4C5.6,16,2.7,13.3,2.2,9.8L2.2,9.8z M10.2,16.2v-3.4l3-3h3.9
	C16.6,13.3,13.8,16,10.2,16.2z"
      />
    </svg>
  )
