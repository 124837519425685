import { Card } from 'components/Card'
import styled from 'styled-components'

interface ShortcutsWrapperProps {
  alignWithSpaceBetween: boolean
}

export const ShortcutsWrapper = styled(Card).attrs(
  (props: ShortcutsWrapperProps) => ({
    alignWithSpaceBetween: props.alignWithSpaceBetween ? true : false,
  })
)`
  display: inline-flex;
  ${props =>
    props.alignWithSpaceBetween
      ? `
    justify-content: space-between;
  `
      : `
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 2rem;
  `}
  align-items: center;
  width: 100%;
  padding: 2.5em 2.5em 1.8em;

  background: white;

  ${props => props.theme.layout.mobile} {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 12px;

    max-width: 100%;
    padding: 12px;
  }
`
