import { useTranslate } from '@ur/react-hooks'
import { Title } from 'components'
import { RoutePlanNode } from 'modules/routePlanner/types.graphql'
import styled from 'styled-components'

interface WrapperProps {
  gridArea: string
}

const Wrapper = styled.div<WrapperProps>`
  ${props => props.theme.defaultContentWrapper};
  grid-area: ${props => props.gridArea}
  padding-top: 0;
`

export const RouteRestrictions: React.VFC<{
  routePlan: Pick<RoutePlanNode, 'routeRestriction'>
  gridArea: string
}> = ({ routePlan, gridArea }) => {
  const translations = useTranslate({
    width: 'common.width',
    height: 'common.height',
    length: 'common.length',
    kmPerHour: 'common.km-per-hour-short',

    axleWeightRestrictions: 'route-planner.axle-weight-restrictions',
    generalWeightRestrictions: 'route-planner.general-weight-restriction',

    topSpeed: 'route-planner.top-speed',
    trailer: 'route-planner.trailer',
    payload: 'route-planner.payload',
    grossWeight: 'route-planner.form.label.total-weight-in-kg',

    singleAxle: 'route-planner.single-axle',
    tandemAxle: 'route-planner.tandem-axle',
    tripleAxle: 'route-planner.triple-axle',

    tonne: 'route-planner.tonne',

    restrictions: 'route-planner.route-restrictions',
  })

  const { routeRestriction } = routePlan

  return (
    <Wrapper gridArea={gridArea}>
      <Title.H2 margin="1rem 0">{translations.restrictions}</Title.H2>
      <div>
        <Title.H4>{translations.width}</Title.H4>
        <p>{routeRestriction?.width ?? 0} cm</p>
      </div>
      <div>
        <Title.H4>{translations.height}</Title.H4>
        <p>{routeRestriction?.height ?? 0} cm</p>
      </div>
      <div>
        <Title.H4>{translations.length}</Title.H4>
        <p>{routeRestriction?.length ?? 0} cm</p>
      </div>
      <div>
        <Title.H4>{translations.topSpeed}</Title.H4>
        <p>
          {routeRestriction?.topSpeed ?? 0} {translations.kmPerHour}
        </p>
      </div>
      <div>
        <Title.H4>{translations.grossWeight}</Title.H4>
        <p>{routeRestriction?.grossWeight ?? 0}</p>
      </div>

      {/** ==== PUT BACK WHEN WEIGHT PROPERLY DEALT WITH
      {routeRestriction?.trailerWeight !== 0 && (
        <div>
          <Title.H4>{translations.trailer}</Title.H4>
          <p>{routeRestriction?.trailerWeight ?? 0} kg</p>
        </div>
      )}

      {routeRestriction?.payloadWeight !== 0 && (
        <div>
          <Title.H4>{translations.payload}</Title.H4>
          <p>{routeRestriction?.payloadWeight ?? 0} kg</p>
        </div>
      )}
      {!!routeRestriction?.axleWeightRestriction && (
        <div>
          <Title.H4>{translations.generalWeightRestrictions}</Title.H4>
          <p>
            {routeRestriction?.axleWeightRestriction ?? 0} {translations.tonne}
          </p>
        </div>
      )}

      {!!routeRestriction?.singleAxleWeightRestriction && (
        <div>
          <Title.H4>{translations.singleAxle}</Title.H4>
          <p>
            {routeRestriction?.singleAxleWeightRestriction ?? 0}{' '}
            {translations.tonne}
          </p>
        </div>
      )}
      {!!routeRestriction?.tandemAxleWeightRestriction && (
        <div>
          <Title.H4>{translations.tandemAxle}</Title.H4>
          <p>
            {routeRestriction?.tandemAxleWeightRestriction ?? 0}{' '}
            {translations.tonne}
          </p>
        </div>
      )}
      {!!routeRestriction?.tripleAxleWeightRestriction && (
        <div>
          <Title.H4>{translations.tripleAxle}</Title.H4>
          <p>
            {routeRestriction?.tripleAxleWeightRestriction ?? 0}{' '}
            {translations.tonne}
          </p>
        </div>
      )}
    * */}
    </Wrapper>
  )
}
