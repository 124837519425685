import { useQuery } from '@apollo/client'
import {
  ModuleConfigNode,
  ModuleConfigQuery,
  ModuleConfigQueryVariables,
} from 'modules/companies/types.graphql'
import { Module, ModuleOptionsValue } from 'modules/companies/consts'
import { MODULE_CONFIG_QUERY } from 'modules/companies/queries'

/**
 * Hook for deep access to a specific module in the com
 * @param name Name of module for which you want to retrieve its module configuration.
 * @returns Returns the module, active status, options found, loadig status, and a getModuleOptions function.
 */
export function useModuleConfig<T extends ModuleConfigNode>(name: Module) {
  const { data, loading } = useQuery<
    ModuleConfigQuery,
    ModuleConfigQueryVariables
  >(MODULE_CONFIG_QUERY, {
    variables: { name: name },
  })

  const module = (data?.moduleConfig || null) as T | null
  const active = module?.active ?? false
  const options = module?.moduleOptions ?? []

  /**
   * Helper function for retrieving a single module option from module
   * @param name Name of module option
   * @returns returns the module config option if it's found else null
   */
  function getModuleOption(
    name: ModuleOptionsValue | keyof typeof ModuleOptionsValue
  ) {
    return options?.find(option => option.name === name) ?? null
  }

  /**
   * Helper function for retrieving a single module option active status from
   * module option name
   *
   * @param name Name of module option
   * @returns Returns the active status as either true or false (false if option is not found)
   */
  function getModuleOptionStatus(
    name: ModuleOptionsValue | keyof typeof ModuleOptionsValue
  ) {
    return options?.find(option => option.name === name)?.active ?? false
  }

  return {
    moduleConfig: module,
    moduleActive: active,
    moduleOptions: options,
    moduleLoading: loading,

    getModuleOption,
    getModuleOptionStatus,
  }
}
