import { PromptResolve, useToast } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Checkbox, Input, RegularModal } from 'components'
import { VolvoApiKeyNode } from 'modules/companies/types.graphql'
import React from 'react'
import styled from 'styled-components'
import { useCompany } from 'util/hooks'
import { Field, Form } from '..'
import { useIntegrationsMutations } from '../../util/mutations.hooks'

const ActiveWrapper = styled.div`
  margin-left: 1rem;
`

export interface VolvoIntegrationForm {
  username: string
  password: string
  active: boolean
}

interface EditVolvoIntegrationModalProps {
  integration: VolvoApiKeyNode | null
  index: number

  onSubmit: PromptResolve<VolvoIntegrationForm | null>
}

export const EditVolvoIntegrationModal: React.VFC<EditVolvoIntegrationModalProps> =
  ({
    integration,
    index,

    onSubmit,
  }) => {
    const translations = useTranslate({
      createIntegration: 'settings.create-volvo-integration',
      editIntegration: ['settings.edit-volvo-integration-n', { n: 0 }],

      username: 'common.username',
      password: 'common.password',
      active: 'common.active',

      save: 'common.save',
      create: 'common.create-alt',

      validation: {
        required: 'common.required',
        errorInApiKeys: 'integrations.error-in-API-keys',
      },
    })

    const mutations = useIntegrationsMutations()
    const company = useCompany()
    const addToast = useToast()

    const editing = integration !== null

    const validateNonEmpty = (value: string) =>
      !!value ? null : translations.validation.required

    const {
      formValues: form,
      formErrors: errors,
      formValid,
      formChangeHandler: handler,
      submitHandler: submit,
    } = useForm<VolvoIntegrationForm>({
      values: {
        username: integration?.username ?? '',
        password: integration?.password ?? '',
        active: integration?.active ?? true,
      },
      validators: {
        username: validateNonEmpty,
        password: validateNonEmpty,
      },
      config: {
        initAsInvalid: !editing,
      },
    })

    async function handleSubmit() {
      const response = await mutations.validate.volvo({
        variables: {
          input: { company: company.id, ...form },
        },
      })
      if (response.data?.validateVolvoApiKey.ok) submit(onSubmit)()
      else {
        addToast('error', translations.validation.errorInApiKeys)
      }
    }

    return (
      <RegularModal
        title={
          editing
            ? translations.editIntegration({ n: index + 1 })
            : translations.createIntegration
        }
        width="600px"
        submitText={editing ? translations.save : translations.create}
        submitDisabled={!formValid}
        footerLeft={
          <ActiveWrapper>
            <Checkbox
              checked={form.active}
              label={translations.active}
              boxWidth="32px"
              boxHeight="32px"
              onChange={handler('active')}
            />
          </ActiveWrapper>
        }
        onCancel={() => onSubmit(null)}
        onSubmit={() => handleSubmit()}
      >
        <Form>
          <Field>
            <label>{translations.username}</label>
            <Input
              value={form.username}
              error={errors.username}
              fullWidth
              autoFocus
              onChange={handler('username')}
            />
          </Field>

          <Field>
            <label>{translations.password}</label>
            <Input
              value={form.password}
              error={errors.password}
              fullWidth
              onChange={handler('password')}
            />
          </Field>
        </Form>
      </RegularModal>
    )
  }
