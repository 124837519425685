import { Icon, Loader, usePrompt } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { Button } from 'components/Button'
import { Table as BaseTable } from 'components/Table'
import { subWeeks } from 'date-fns'
import {
  EditUserDocumentPrompt,
  EditUserDocumentPromptResolve,
} from 'modules/companies'
import { useUserDocumentMutations } from 'modules/companies/CompanySettings/util/mutations.hooks'
import React, { useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import { RelayNodeType } from 'types/graphql'
import { format, formatDistanceStrict } from 'util/date-fns'
import { useConfirm, useOnErrorAuto, usePermissions } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { buttonUnset } from 'util/style'
import { AllUserDocumentsQuery, AllUserDocumentsQueryVariables } from '..'
import { ALL_USER_DOCUMENTS_QUERY } from '../queries'
import { ProfileCard } from '../UserProfile'
import { UserDocumentFilesModal } from './UserDocumentFilesModal'
import { PermissionsRequired } from 'containers/permission-containers'

const Wrapper = styled(ProfileCard).attrs({
  area: 'docs',
})`
  display: flex;
  flex-direction: column;

  header {
    display: flex;

    h3 {
      display: flex;

      div.loader {
        margin-left: 0.5rem;
      }
    }
  }
  footer {
    display: flex;
    justify-content: flex-end;
  }
`
const Table = styled(BaseTable)`
  flex-grow: 1;
`

const FilesButton = styled.button`
  ${buttonUnset}

  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.9rem;

  &:hover {
    color: ${props => props.theme.colors.secondary};
  }
`

export type UserDocument = Exclude<
  RelayNodeType<AllUserDocumentsQuery['allUserDocuments']>,
  undefined
>

interface UserDocumentsProps {
  userId: string
}

export const UserDocuments: React.VFC<UserDocumentsProps> = ({ userId }) => {
  const translations = useTranslate({
    userDocuments: 'common.user-documents',
    notPermitted: 'error.not-permitted',
    add: 'common.add',
    category: 'common.category',
    nCategories: ['common.n-categories', { n: '0'}],

    name: 'common.name',
    expiration: 'documents.expiration-date',
    notification: 'common.notification',
    files: 'common.files',

    noDocuments: 'documents.no-documents',
    showFiles: 'documents.show-files',
    none: 'common.none',
    close: 'common.close',
    finished: 'common.finished',

    prompt: {
      delete: ['settings.prompts.user-document-delete', { name: '' }],
      deleteTitle: 'settings.prompts.user-document-delete-title',
    },
  })

  const addPrompt = usePrompt()
  const confirm = useConfirm()
  const onErrorAuto = useOnErrorAuto()

  const { hasPermissions } = usePermissions()
  const canEdit = hasPermissions(PERMISSIONS.users.change.userdocument)
  const canDelete = hasPermissions(PERMISSIONS.users.delete.userdocument)

  const {
    data: documentsData,
    loading: documentsLoading,
    error: documentsError,
  } = useQuery<AllUserDocumentsQuery, AllUserDocumentsQueryVariables>(
    ALL_USER_DOCUMENTS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        user: userId,
        orderBy: 'expirationDate',
      },
      onError: onErrorAuto(),
    }
  )

  const [filesModalOpen, setFilesModalOpen] = useState(false)
  const [selectedDocument, setSelectedDocument] =
    useState<Pick<UserDocument, 'id' | 'name'> | null>(null)

  const mutations = useUserDocumentMutations()

  function openFilesModal(document: Pick<UserDocument, 'id' | 'name'>) {
    setSelectedDocument(document)
    setFilesModalOpen(true)
  }
  function closeFilesModal() {
    setFilesModalOpen(false)
  }

  async function handleCreate() {
    const { data } = await addPrompt<EditUserDocumentPromptResolve | null>(
      resolve => <EditUserDocumentPrompt onSubmit={resolve} />
    )
    if (data === null) return
    const { form, hasNotification } = data

    const expirationDate = !hasNotification ? null : form.expirationDate
    const notificationTime = !hasNotification
      ? null
      : !expirationDate || !form.notificationTime
      ? null
      : subWeeks(expirationDate, form.notificationTime)

    try {
      const result = await mutations.create({
        variables: {
          input: {
            user: userId,
            name: form.name,
            responsibleUser: !hasNotification
              ? null
              : form.responsibleUser?.id ?? null,
            expirationDate,
            notificationTime,
            categories: form.categories,
          },
        },
      })
      if (!result?.data) return

      openFilesModal(result.data.createUserDocument.userDocument)
    } catch {}
  }
  async function handleEdit(document: UserDocument) {
    const { data } = await addPrompt<EditUserDocumentPromptResolve | null>(
      resolve => (
        <EditUserDocumentPrompt userDocument={document} onSubmit={resolve} />
      )
    )
    if (data === null) return
    const { form, hasNotification } = data

    const expirationDate = !hasNotification ? null : form.expirationDate
    const notificationTime = !hasNotification
      ? null
      : !expirationDate || !form.notificationTime
      ? null
      : subWeeks(expirationDate, form.notificationTime)

    const result = await mutations.patch({
      variables: {
        id: document.id,
        input: {
          name: form.name,
          responsibleUser: !hasNotification
            ? null
            : form.responsibleUser?.id ?? null,
          expirationDate,
          notificationTime,
          categories: form.categories,
        },
      },
    })
    if (!result?.data) return

    openFilesModal(result.data.patchUserDocument.userDocument)
  }
  async function handleDelete(document: UserDocument) {
    const { data: answer } = await confirm(
      translations.prompt.delete({ name: document.name }),
      translations.prompt.deleteTitle
    )
    if (!answer) return

    mutations.delete({
      variables: { id: document.id },
    })
  }

  const documents = useMemo(
    () => documentsData?.allUserDocuments.edges.map(edge => edge.node) ?? [],
    [documentsData]
  )

  return (
    <Wrapper>
      <UserDocumentFilesModal
        open={filesModalOpen}
        document={selectedDocument}
        onClose={closeFilesModal}
      />

      <header>
        <h3>
          {translations.userDocuments}
          {documentsLoading && (
            <div className="loader">
              <Loader.Spinner size={28} />
            </div>
          )}
        </h3>
      </header>

      <Table
        noShadow
        noBorders
        noHeaderMargin
        noRowMargin
        noData={!documents.length}
        noDataText={
          !documentsLoading && !!documentsError
            ? translations.notPermitted
            : translations.noDocuments
        }
        noDataColor={!documentsLoading && !!documentsError ? 'red' : undefined}
      >
        <thead>
          <tr>
            <th>{translations.name}</th>
            <th>{translations.category}</th>
            <th>{translations.expiration}</th>
            <th>{translations.notification}</th>
            <th>{translations.files}</th>
            {canEdit && <th></th>}
            {canDelete && <th></th>}
          </tr>
        </thead>

        <tbody>
          {documents.map(doc => {
            const expiration = !!doc.expirationDate
              ? format(new Date(doc.expirationDate), 'PP')
              : '-'
            const notification =
              !!doc.notificationTime && !!doc.expirationDate
                ? formatDistanceStrict(
                    new Date(doc.expirationDate),
                    new Date(doc.notificationTime)
                  )
                : '-'
            
            const categoryLabel = doc.categories?.length === 1 
              ? doc.categories[0].name 
              : doc.categories?.length === 0 
                ? "-r"
                : translations.nCategories({ n: doc.categories.length.toString() })

            return (
              <tr key={doc.id}>
                <td>{doc.name}</td>

                <td>{categoryLabel}</td>

                <td className="center" width="1px">
                  {expiration}
                </td>

                <td className="center" width="1px">
                  {notification}
                </td>

                <td width="1px">
                  <FilesButton onClick={() => openFilesModal(doc)}>
                    {doc.hasFiles ? translations.showFiles : translations.none}
                  </FilesButton>
                </td>

                {canEdit && (
                  <td width="1px">
                    <Icon
                      icon="edit"
                      cursor="pointer"
                      color="gray6"
                      hoverColor="secondary"
                      onClick={() => handleEdit(doc)}
                    />
                  </td>
                )}

                {canDelete && (
                  <td width="1px">
                    <Icon
                      icon="times"
                      cursor="pointer"
                      color="gray6"
                      hoverColor="red"
                      onClick={() => handleDelete(doc)}
                    />
                  </td>
                )}
              </tr>
            )
          })}
        </tbody>
      </Table>

      <footer>
        <PermissionsRequired permissions={PERMISSIONS.users.add.userdocument}>
          <Button color="white" height="3rem" onClick={handleCreate}>
            {translations.add}
          </Button>
        </PermissionsRequired>
      </footer>
    </Wrapper>
  )
}
