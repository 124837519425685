import { gql } from '@apollo/client'

export const ALL_USER_EXPORTS_QUERY = gql`
  query AllUserExports($orderBy: String, $first: Int, $after: String) {
    allUserExports(orderBy: $orderBy, first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          name
          file
          module
          createdAt
        }
      }
    }
  }
`
