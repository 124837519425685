import React from 'react'
import { useAdmin, usePermissions, useUser } from 'util/hooks'
import { UserNode } from 'modules/users'
import { OnlyRequired } from 'types/util'

interface PermissionsOrMeRequiredProps {
  user: OnlyRequired<UserNode, 'id'>
  permissions: string | string[]
}
export const PermissionsOrMeRequired: React.FC<PermissionsOrMeRequiredProps> =
  ({ children, permissions, user }) => {
    const me = useUser()
    const admin = useAdmin()
    const { hasPermissions } = usePermissions()

    if (user && me.id === user.id) return <>{children}</>
    if (!admin && !hasPermissions(permissions)) return <>{null}</>

    return <>{children}</>
  }
