import { FormErrors, FormOnChangeFn, useTranslate } from '@ur/react-hooks'
import { DatePicker, Select, TimePicker } from 'components'
import { PlainLink } from 'components/Link'

import { set } from 'date-fns'
import { UserNode } from 'modules/users'
import React, { useState } from 'react'
import styled from 'styled-components'
import { CategorySelect, ShallowCategory } from '.'
import { EditIssueForm } from '../types'
import { IssueRiskAspectSelect } from 'components/Select/IssueRiskAspectSelect'
import { useIssueSeverityOptions } from '../consts'

const Wrapper = styled.section`
  grid-area: main;

  display: grid;
  grid-template-columns: repeat(3, auto);

  span.unknown {
    color: ${props => props.theme.colors.red};
  }

  ${props => props.theme.media.mobile} {
    display: flex;
    flex-direction: column;

    & > div {
      border: 1px solid ${props => props.theme.colors.gray8} !important;
      border-width: 1px 0 0 0 !important;

      &.category {
        order: -1;
        border-width: 0 !important;
      }
      &.date {
        order: -1;
      }
    }
  }
`
const Info = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;

  & + div {
    border-left: 1px solid ${props => props.theme.colors.gray8};

    &:nth-child(4) {
      border-left: 0;
    }
    &:nth-child(n + 4) {
      border-top: 1px solid ${props => props.theme.colors.gray8};
    }
    &:nth-child(5) {
      grid-column: 2 / -1;
    }
  }

  > span {
    font-weight: 600;
  }


  > span {
    color: ${props => props.theme.colors.gray5};
  }
  p {
    margin: 0;
    font-size: 1.6em;
  }

  ${props => props.theme.media.mobile} {
    padding: 1rem;

    span {
      font-size: 1rem;
    }
    p {
      font-size: 1rem;
    }
  }
`
const Editor = styled.div`
  margin-top: 0.5rem;
`
const DateEditor = styled(Editor)`
  display: flex;

  & > * + * {
    margin-left: 0.5rem;
  }
`

interface CreateMainInfoProps {
  form: EditIssueForm
  errors: FormErrors<EditIssueForm>

  onChange: FormOnChangeFn<EditIssueForm>
}

export const CreateMainInfo: React.VFC<CreateMainInfoProps> = ({
  form,
  errors,

  onChange,
}) => {
  const translations = useTranslate({
    category: 'common.category',
    responsible: 'common.responsible',
    date: 'common.date',
    severity: 'common.severity',
    riskAspect: 'common.risk-aspect',

    select: 'common.select',
    none: 'common.none',
  })

  const severityOptions = useIssueSeverityOptions()

  const [temporaryResponsible, setTemporaryResponsible] =
    useState<Pick<UserNode, 'id' | 'fullName'> | null>(null)

  function handleChangeCategory(
    id: string,
    category: ShallowCategory | null
  ) {
    onChange('category')(id)
    setTemporaryResponsible(category?.responsibleUser ?? null)
  }
  function handleChangeDate(date: Date) {
    const newDate = set(date, {
      hours: form.date.getHours(),
      minutes: form.date.getMinutes(),
      seconds: form.date.getSeconds(),
    })
    onChange('date')(newDate)
  }

  return (
    <Wrapper>
      <Info className="category">
        <span>{translations.category}</span>
        <Editor>
          <CategorySelect
            value={form.category}
            error={errors.category}
            minWidth="260px"
            fullWidth
            placeholder={translations.select}
            onChange={handleChangeCategory}
          />
        </Editor>
      </Info>

      <Info>
        <span>{translations.responsible}</span>
        <p>
          {!!temporaryResponsible ? (
            <PlainLink
              to={`/users/${temporaryResponsible.id}`}
              hoverColor="secondary"
            >
              {temporaryResponsible.fullName}
            </PlainLink>
          ) : (
            translations.none
          )}
        </p>
      </Info>

      <Info>
        <span>{translations.severity}</span>
        <Editor>
          <Select 
            value={form.severity} 
            options={severityOptions}
            onChange={onChange('severity')}
            height="3rem"
            grayed
            fullWidth
            nullable
          />
        </Editor>
      </Info>
      <Info>
        <span>{translations.riskAspect}</span>
        <Editor>
          <IssueRiskAspectSelect 
            value={form.riskAspect}
            onChange={onChange('riskAspect')}
            height="3rem"
            fullWidth
            grayed
            nullable
          />
        </Editor>
      </Info>
      <Info className="date">
        <span>{translations.date}</span>
        <DateEditor>
          <TimePicker
            value={form.date}
            height="3rem"
            onChange={onChange('date')}
          />
          <DatePicker
            value={form.date}
            fullWidth
            height="3rem"
            noAnimation
            onChange={handleChangeDate}
          />
        </DateEditor>
      </Info>
    </Wrapper>
  )
}
