export enum Module {
  TERMINAL = 'Terminal',
  ACTIVITIES = 'Activities',
  TIMESHEETS = 'Timesheets',
  VEHICLES = 'Vehicles',
  HANDBOOK = 'Handbook',
  ISSUES = 'Issues',
  FORUM = 'Forum',
  ABSENCES = 'Absences',
  INFRINGEMENTS = 'Infringements',
  ROUTEPLANNER = 'RoutePlanner',
}

//This "duplicate" enum exists for use in UserExport module field
export enum ModuleNames {
  TERMINAL = 'TERMINAL',
  ACTIVITIES = 'ACTIVITIES',
  TIMESHEETS = 'TIMESHEETS',
  VEHICLES = 'VEHICLES',
  HANDBOOK = 'HANDBOOK',
  ISSUES = 'ISSUES',
  FORUM = 'FORUM',
  ABSENCES = 'ABSENCES',
  INFRINGEMENTS = 'INFRINGEMENTS',
  ROUTEPLANNER = 'ROUTE_PLANNER',
}

export const SalaryModule = {
  ACTIVITIES: Module.ACTIVITIES,
  TIMESHEETS: Module.TIMESHEETS,
  TERMINAL: Module.TERMINAL,
}

export const ModuleAsPermissionPrefixes = {
  TERMINAL: ['terminal'],
  ACTIVITIES: ['activities', 'dynamic_tables'],
  TIMESHEETS: ['timesheets'],
  VEHICLES: ['vehicles'],
  HANDBOOK: ['handbook', 'formcreator'],
  ISSUES: ['issues'],
  FORUM: ['forum'],
  ABSENCES: ['absences'],
  INFRINGEMENTS: ['infringements'],
  ROUTEPLANNER: ['route_planner'],
  OTHER: ['companies', 'users'],
}

export const Integration = {
  VOLVO: 'Volvo',
  SCANIA: 'Scania',
  FLEETBOARD: 'FleetBoard',
  MAN: 'Man'
}

export enum ModuleOptionsValue {
  ACTIVITIES_RECEIPT_FUNCTIONALITY = 'ACTIVITIES_RECEIPT_FUNCTIONALITY',
  TIMESHEETS_OVERTIME_OPTIONS = 'TIMESHEETS_OVERTIME_OPTIONS',
  TIMESHEETS_COUNT_PAUSE_IN_TOTAL = 'TIMESHEETS_COUNT_PAUSE_IN_TOTAL',
  TIMESHEETS_PROJECTS = 'TIMESHEETS_PROJECTS',
  TIMESHEETS_DAY_INTERVAL_OVERTIME = 'TIMESHEETS_DAY_INTERVAL_OVERTIME',
  MERGE_TIMESHEETS_WITH_ACTIVITIES = 'MERGE_TIMESHEETS_WITH_ACTIVITIES',
  TIMESHEETS_DEPARTMENT_SELECT_REQUIRED = 'TIMESHEETS_DEPARTMENT_SELECT_REQUIRED'
}
