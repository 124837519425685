import ReactDOM from 'react-dom'

import './index.css'
import './polyfills'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Root } from './containers/Root'

import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/browser'
import { Integrations as TracingIntegrations } from '@sentry/tracing'

import { isLocal, STAGE } from 'util/env'

import config from '../package.json'

ReactDOM.render(<Root />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

if (!isLocal()) {
  Sentry.init({
    dsn: 'https://0e59da3e751c4bbf994d41d36fe99b6c@sentry.io/1866532',
    environment: STAGE,
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 0.2,
    release: config.version,
  })
}

// Set up hot reloading
if (module.hot) {
  module.hot.accept('./containers/Root', () => {
    const NextRoot = require('./containers/Root').default
    ReactDOM.render(<NextRoot />, document.getElementById('root'))
  })
}
