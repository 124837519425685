import { useForm, useTranslate } from '@ur/react-hooks'
import { Loader } from '@ur/react-components'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import {
  VehicleDetails,
  VehicleDocuments,
  VehicleExpirations,
} from '../components'
import { Tabs, Tab, TabPane } from 'components/Tabs'
import { useQuery } from '@apollo/client'
import {
  VehicleDetailQuery,
  VehicleDetailQueryVariables,
  VEHICLE_DETAIL_QUERY,
} from 'modules/vehicles'
import { useBreadcrumbs } from 'util/hooks'
import { useVehicleMutations } from '../mutations.hooks'
import { DepartmentSelect } from 'components/Select/DepartmentSelect'

const Wrapper = styled.div`
  ${props => props.theme.layout.default};
`

const TitleWrapper = styled.div`
  display: flex;
  gap: 1rem;
  padding-bottom: 1rem;
`

interface VehicleProps {}
export const Vehicle: React.VFC<VehicleProps> = () => {
  const translations = useTranslate({
    loading: 'common.loading...',
    details: 'vehicles.vehicle-details',
    documents: 'common.documents',
    expirationDates: 'vehicles.expiration-dates',
    department: 'common.department'
  })
  const { setOverride } = useBreadcrumbs()
  const { vehicleId } = useParams<{ vehicleId: string }>()

  const { data, loading } = useQuery<
    VehicleDetailQuery,
    VehicleDetailQueryVariables
  >(VEHICLE_DETAIL_QUERY, {
    variables: {
      id: vehicleId,
    },
    onCompleted(data) {
      if (!data) return
      setOverride(vehicleId, data.vehicle.registrationNumber)
      updateForm({ id: data.vehicle.department?.id ?? '' })
    },
  })

  const {
    formValues: form,
    updateForm,
  } = useForm<{ id: string | null }>({
    values: {
      id: '',
    },
  })
  

  const vehicle = data?.vehicle || null
  useEffect(() => {
    if (!!data) return
    setOverride(vehicleId, translations.loading)
  })

  const {
    mutations: { patch: patchVehicle },
  } = useVehicleMutations()


  async function handleDepartmentChange(departmentId: string | null) {
    updateForm({ id: departmentId })
    await patchVehicle({
      variables: {
        id: vehicleId,
        input: { department: departmentId ?? null },
      },
    })
  }

  const tabs: Tab[] = [
    { key: 'details', text: translations.details },
    { key: 'documents', text: translations.documents },
    { key: 'expirations', text: translations.expirationDates },
  ]
  const [activeTab, setActiveTab] = useState<Tab>(tabs[0])

  return (
    <Wrapper>
      <TitleWrapper>
        <h2>{loading ? translations.loading : vehicle?.registrationNumber}</h2>
        {loading || !vehicle 
          ? <Loader.Dots size={28} /> 
          : <DepartmentSelect 
              value={form.id} 
              onChange={(departmentId) => handleDepartmentChange(departmentId)}
            />
        }
      </TitleWrapper>
      {!!vehicle && (
        <>
          <Tabs
            activeTab={activeTab}
            tabs={tabs}
            onChange={tab => setActiveTab(tab)}
          />
          <TabPane active={activeTab.key === 'details'}>
            <h2>{translations.details}</h2>
            <VehicleDetails vehicle={vehicle} />
          </TabPane>
          <TabPane active={activeTab.key === 'documents'}>
            <VehicleDocuments vehicleId={vehicleId} />
          </TabPane>
          <TabPane active={activeTab.key === 'expirations'}>
            <VehicleExpirations vehicleId={vehicleId} />
          </TabPane>
        </>
      )}
    </Wrapper>
  )
}
