import { Icon, Loader } from '@ur/react-components'
import uniqueId from 'lodash/uniqueId'
import isEqual from 'lodash/isEqual'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { useClickOutside } from '@ur/react-hooks'
import { HTMLAriaRole } from '@ur/react-components/build/types/html'
import { usePopper } from 'react-popper'
import { ZIndexRange } from 'types/style'

interface WrapperProps {
  padding?: string
}
const Wrapper = styled.div<WrapperProps>`
  padding: ${props => props.padding};
  z-index: ${ZIndexRange.Dropdowns};
  cursor: pointer;

  div.loader {
    width: 8px;
  }

  &:hover i {
    color: ${props => props.theme.colors.secondary};
  }
`
const PopupWrapper = styled.div`
  cursor: pointer;
`
const Menu = styled.aside`
  min-width: 220px;
  overflow: hidden;

  background: white;
  box-shadow: ${props => props.theme.shadow.default};
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
`
const Item = styled.div`
  width: 100%;
  padding: 1.2rem 1rem 1rem;
  cursor: pointer;
  user-select: none;
  color: ${props => props.theme.colors.gray4};
  background: white;

  & + div {
    border-top: 1px solid ${props => props.theme.colors.gray9};
  }
  &:hover {
    color: ${props => props.theme.colors.gray1};
  }
`

export interface PopupMenuItem<T> {
  label: string
  data?: T

  role?: HTMLAriaRole
  noCloseOnClick?: boolean
  hide?: boolean

  onClick: (data: T | undefined) => void
}
interface PopupMenuItemWithId<T> extends PopupMenuItem<T> {
  id: string
}

interface PopupMenuProps<T> {
  items: PopupMenuItem<T>[]

  loading?: boolean
  loaderSize?: number
  loaderThickness?: number
  noCloseOnClick?: boolean

  padding?: string
}

export const PopupMenu = <T,>({
  items,

  loading = false,
  loaderSize = 32,
  loaderThickness,
  noCloseOnClick = false,

  padding,
}: PopupMenuProps<T>) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const itemsRef = useRef<PopupMenuItemWithId<T>[] | null>(null)

  const [open, setOpen] = useState(false)
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] =
    useState<HTMLDivElement | null>(null)
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)

  const itemsWithIds = useMemo(() => {
    if (itemsRef.current !== null && isEqual(itemsRef.current, items))
      return itemsRef.current

    const newItems = items.reduce<PopupMenuItemWithId<T>[]>(
      (acc, item) =>
        !item.hide
          ? [
              ...acc,
              {
                id: uniqueId('tableMenuItem'),
                ...item,
              },
            ]
          : acc,
      []
    )
    itemsRef.current = newItems

    return newItems
  }, [items])

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: [-125, -28],
        },
      },
    ],
  })

  useClickOutside(wrapperRef, () => setOpen(false), true)

  function handleClick(item: PopupMenuItemWithId<T>) {
    return (evt: React.MouseEvent<HTMLDivElement>) => {
      evt.stopPropagation()
      item.onClick(item.data)
      if (!noCloseOnClick && !item.noCloseOnClick) setOpen(false)
    }
  }

  useEffect(() => {
    setReferenceElement(wrapperRef?.current ?? null)
  }, [wrapperRef])

  function openMenu(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.stopPropagation()
    if (loading) return
    setOpen(value => !value)
  }

  return (
    <>
      <Wrapper ref={wrapperRef} padding={padding} onClick={openMenu}>
        {!loading ? (
          <Icon icon="ellipsis-v" size="1.8rem" color="gray5" />
        ) : (
          <div className="loader">
            <Loader.Spinner size={loaderSize} thickness={loaderThickness} />
          </div>
        )}
        {open && (
          <PopupWrapper>
            <div
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              <Menu>
                {itemsWithIds.map(item => (
                  <Item
                    key={item.id}
                    role={item.role ?? 'button'}
                    onClick={handleClick(item)}
                  >
                    {item.label}
                  </Item>
                ))}
              </Menu>
              <div style={styles.arrow} ref={setArrowElement} />
            </div>
          </PopupWrapper>
        )}
      </Wrapper>
    </>
  )
}
