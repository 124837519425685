import { PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Checkbox, Input, RegularModal } from 'components'
import { DetectBlur } from 'containers/util-containers'
import { Field, Form } from '..'
import { SalaryCodeNode } from 'modules/companies/types.graphql'
import styled from 'styled-components'
import { SalaryCodeForm } from './SalaryCodeSettings'


const CheckboxWrapper = styled.div`
  padding: 0 0.25rem;
`

interface SalaryCodePromptProps {
  initialData?: SalaryCodeForm
  existingSalaryCodes: Pick<SalaryCodeNode, 'name' | 'id'>[]
  onSubmit: PromptResolve<SalaryCodeForm | null>
}

export const SalaryCodePrompt: React.VFC<SalaryCodePromptProps> = ({
  initialData,
  existingSalaryCodes,
  onSubmit,
}) => {
  const translations = useTranslate({
    form: {
      label: {
        name: 'common.name',
        externalId: 'common.id',
        isDiet: 'common.diet',
        showInManualTimeEntryOvertime:
          'settings.show-in-manual-time-entry-overtime',
      },
      placeholder: {
        id: 'settings.salary-code-id-field-placeholder',
      },
    },

    addSalaryCode: 'settings.add-salary-code',
    editSalaryCode: 'settings.edit-salary-code',

    create: 'common.create-alt',
    update: 'common.update',

    validation: {
      nameExists: 'settings.validation.time-entry-project-name-exists',
      required: 'common.required',
    },
  })

  const editing = typeof initialData !== 'undefined'

  function validateName(name: string, formName: string) {
    if (name === initialData?.name) return null
    if (name === '') return translations.validation.required
    if (
      !!existingSalaryCodes.find(salaryCode => salaryCode.name === formName)
    ) {
      return translations.validation.nameExists
    }
    return null
  }

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formChangeHandler: handler,
    submitHandler: submit,
  } = useForm<SalaryCodeForm>({
    values: initialData ?? {
      externalId: '',
      name: '',
      isDiet: false,
      showInManualTimeEntryOvertime: false,
    },
    validators: {
      name: (val, form) => validateName(val, form.name),
    },
  })

  return (
    <DetectBlur active onClickOutside={() => onSubmit(null)}>
      <RegularModal
        title={
          editing
            ? translations.editSalaryCode
            : translations.addSalaryCode
        }
        submitText={editing ? translations.update : translations.create}
        submitDisabled={!formValid}
        width="400px"
        onCancel={() => onSubmit(null)}
        onSubmit={submit(onSubmit)}
      >
        <Form>
          <Field>
            <label>{translations.form.label.externalId}</label>
            <Input
              value={form.externalId}
              error={errors.externalId}
              autoFocus
              fullWidth
              onChange={handler('externalId')}
              placeholder={translations.form.placeholder.id}
            />
          </Field>
          <Field>
            <label>{translations.form.label.name}</label>
            <Input
              value={form.name}
              error={errors.name}
              autoFocus
              fullWidth
              onChange={handler('name')}
            />
          </Field>
          <Field>
            <label>{translations.form.label.isDiet}</label>
            <CheckboxWrapper>
              <Checkbox
                checked={form.isDiet}
                onChange={handler('isDiet')}
              />
            </CheckboxWrapper>
          </Field>
          <Field>
            <label>{translations.form.label.showInManualTimeEntryOvertime}</label>
            <CheckboxWrapper>
              <Checkbox
                checked={form.showInManualTimeEntryOvertime}
                onChange={handler('showInManualTimeEntryOvertime')}
              />
            </CheckboxWrapper>
          </Field>
        </Form>
      </RegularModal>
    </DetectBlur>
  )
}
