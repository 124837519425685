import { ExportCardProps } from '../types'
import { Field, Form, FormButton, Section } from '../common'
import {
  Checkbox,
  DateRangePicker,
  MonthPicker,
  Select,
  SelectMultipleOnSelectAll,
  Toggle,
} from 'components'
import styled from 'styled-components'
import { useTranslate } from '@ur/react-hooks'
import { ComponentSwitch, Icon } from '@ur/react-components'
import { handleDateRangeChange } from 'util/date/handleDateRangeChange'
import { SelectMultipleUsers } from 'components/Select/SelectMultipleUsers'
import { DropdownNames } from 'components/Select/types'
import { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { responsiveExportGridAreas } from 'modules/companies/util'
import { useExportFormatOptions, useExportOptions } from 'modules/exports/hooks'
import { useMassTimesheetExportForm } from 'modules/timesheets/hooks'
import { Module, ModuleOptionsValue } from 'modules/companies/consts'
import { useModuleConfig } from 'util/hooks'
import { TimeEntryProjectSelect } from 'components/Select/TimeEntryProjectSelect'
import { ExportFormat, ExportOption } from 'modules/exports'
import { ExportSectionHeader, MassExportSectionHeader } from './components'

const SelectMultipleUsersWrapper = styled.div`
  position: relative;
  min-height: 54px;
`

export const ExportTimesheetsCard: React.VFC<ExportCardProps> = ({
  loading,
  onLoadingChangedCallback,
}) => {
  const translations = useTranslate({
    timesheets: 'common.timesheets',
    exportAll: 'settings.export-all-timesheets',
    period: 'common.period',
    exportType: 'common.type',
    exportOption: 'common.export-type',
    project: 'common.project',

    export: 'common.export',
    orderByUserType: 'common.sort-by-user-type',
    selectUsers: 'common.select-users',
    selectAll: 'common.select-all',
    includeAbsences: 'settings.include-absences',
  })

  const {
    loading: formLoading,
    form,
    errors,
    submit,
    updateForm,
    handleExport,
    handler,
    exportTypes,
  } = useMassTimesheetExportForm(() => onLoadingChangedCallback(false))

  const { getModuleOptionStatus } = useModuleConfig(Module.TIMESHEETS)

  const projectActive = getModuleOptionStatus(
    ModuleOptionsValue.TIMESHEETS_PROJECTS
  )

  const exportOptions = useExportOptions(true)
  const excludeExcel = form.exportOption === ExportOption.DOWNLOAD
  const excludedFormats = excludeExcel ? [ExportFormat.EXCEL, ExportFormat.POWEROFFICE, ExportFormat.VISMA, ExportFormat.TWENTYFOUR_SEVEN_OFFICE, ExportFormat.TRIPLETEX, ExportFormat.PDF_SALARY] : []
  const exportFormats = useExportFormatOptions(excludedFormats)

  useEffect(() => {
    if (!formLoading || loading === formLoading) return
    onLoadingChangedCallback(formLoading)
  }, [formLoading, loading, onLoadingChangedCallback])

  const areas = !isMobile
    ? `
        ${responsiveExportGridAreas(true)}
        ${projectActive ? "'project project project'" : ''}
        'sort sort includeAbsences'
      `
    : `
        ${responsiveExportGridAreas(true)}
        ${projectActive ? "'project'" : ''}
        'sort'
        'includeAbsence'
      `
  const selectAllOnChange: SelectMultipleOnSelectAll = (
    options,
    setSelectAll
  ) => {
    return (value: boolean) => {
      if (value) {
        updateForm({ users: options.map(option => option.value) as string[] })
        setSelectAll(true)
      } else {
        updateForm({ users: [] })
        setSelectAll(false)
      }
    }
  }

  const formButtons: FormButton[] = [
    {
      text: (
        <>
          <Icon
            icon="download"
            type="light"
            color="primary"
            margin="0 0.5ch 0 0"
          />
          {translations.export}
        </>
      ),
      type: 'submit',
      buttonProps: {
        fullWidth: isMobile,
        disabled: loading,
      },
      onClick: submit(handleExport),
    },
  ]

  useEffect(() => {
    if (
      form.exportOption === ExportOption.DOWNLOAD &&
      form.exportFormat === ExportFormat.EXCEL
    ) {
      updateForm({ exportFormat: ExportFormat.PDF })
    }
  }, [form.exportOption, form.exportFormat, updateForm])

  return (
    <>
      <ExportSectionHeader>{translations.timesheets}</ExportSectionHeader>
      <Section>
        <MassExportSectionHeader>
          {translations.exportAll}
          <Toggle
            value={form.useDateRange}
            thumbBackground="secondary"
            iconMode="svg"
            localOnIconProps={{
              icon: 'calendar-selection',
              fill: form.useDateRange ? 'white' : 'primary',
            }}
            localOffIconProps={{
              icon: 'calendar-month',
              fill: !form.useDateRange ? 'white' : 'primary',
            }}
            onChange={() => handler('useDateRange')(!form.useDateRange)}
          />
        </MassExportSectionHeader>
        <Form
          grid={{
            columns: !isMobile ? '3fr 2fr' : '1fr',
            areas: areas,
          }}
          buttons={formButtons}
        >
          <Field area="month">
            <label>{translations.period}</label>
            <ComponentSwitch>
              <ComponentSwitch.Case case={form.useDateRange}>
                <DateRangePicker
                  value={[form.dateStart, form.dateEnd]}
                  grayed={false}
                  onChange={handleDateRangeChange(updateForm)}
                />
              </ComponentSwitch.Case>
              <ComponentSwitch.Case default>
                <MonthPicker
                  value={form.month}
                  light
                  futureYears={0}
                  noYearCycle
                  onChange={handler('month')}
                />
              </ComponentSwitch.Case>
            </ComponentSwitch>
          </Field>

          <Field area="exportType">
            <label>{translations.exportType}</label>
            <Select
              value={form.exportType}
              options={exportTypes}
              fullWidth
              height="3.875rem"
              onChange={handler('exportType')}
            />
          </Field>

          <Field area="exportOption">
            <label>{translations.exportOption}</label>
            <Select
              value={form.exportOption}
              options={exportOptions}
              fullWidth
              height="3.875rem"
              onChange={handler('exportOption')}
            />
          </Field>

          <Field area="exportFormat">
            <label>{translations.exportOption}</label>
            <Select
              value={form.exportFormat}
              options={exportFormats}
              disabled={excludeExcel}
              fullWidth
              height="3.875rem"
              onChange={handler('exportFormat')}
            />
          </Field>

          {projectActive && (
            <Field area="project">
              <label>{translations.project}</label>
              <TimeEntryProjectSelect
                value={form.project}
                onChange={handler('project')}
              />
            </Field>
          )}

          <Field area="user">
            <label>{translations.selectUsers}</label>
            <SelectMultipleUsersWrapper>
              <SelectMultipleUsers
                selected={form.users ?? []}
                dropdownPageName={DropdownNames.TIME_ENTRIES}
                grayed={false}
                width="100%"
                minWidth={isMobile ? '0' : '260px'}
                onChange={users => updateForm({ users })}
                onSelectAll={selectAllOnChange}
                withSelectAll={true}
                error={errors.users}
              />
            </SelectMultipleUsersWrapper>
          </Field>

          <Field area="sort">
            <label>{translations.orderByUserType}</label>
            <div>
              <Checkbox
                checked={form.orderByUserType ?? false}
                onChange={handler('orderByUserType')}
                label={translations.orderByUserType}
                inline
              />
            </div>
          </Field>
          <Field area="includeAbsences">
            <label>{translations.includeAbsences}</label>
            <div>
              <Checkbox
                checked={form.includeAbsences ?? false}
                onChange={handler('includeAbsences')}
                label={translations.includeAbsences}
                inline
              />
            </div>
          </Field>
        </Form>
      </Section>
    </>
  )
}
