import { useQuery } from '@apollo/client'
import { useTranslate } from '@ur/react-hooks'
import { CenteredLoader, Checkbox } from 'components'
import { FilterChildren, FilterPopup } from 'components/FilterPopup'
import {
  FilterCheckmarksWrapper,
  Status,
  StatusButton,
  StyledScrollBar,
} from 'components/FilterPopup/components'
import xor from 'lodash/xor'
import React from 'react'
import { useOnErrorAuto } from 'util/hooks'
import { ALL_ISSUE_CATEGORIES_SHALLOW_QUERY } from '../queries'
import { IssuesTableFilter } from '../types'
import { AllIssueCategoriesShallowQuery, IssueSeverity } from '../types.graphl'

export const IssuePopupFilter: React.VFC<FilterChildren<IssuesTableFilter>> = ({
  filter,
  onUpdate,
}) => {
  const translations = useTranslate({
    status: 'common.status',
    archived: 'common.archived',
    categories: 'common.categories',

    both: 'common.both',
    open: 'common.open-alt',
    closed: 'common.closed',

    severity: 'common.severity',

    low: 'common.low',
    medium: 'common.medium',
    high: 'common.high',
  })
  const onErrorAuto = useOnErrorAuto()
  const { data: categoriesData, loading: categoriesLoading } =
    useQuery<AllIssueCategoriesShallowQuery>(
      ALL_ISSUE_CATEGORIES_SHALLOW_QUERY,
      {
        onError: onErrorAuto(),
      }
    )

  const categories =
    categoriesData?.allIssueCategories.edges.map(edge => edge.node) ?? []

  function toggleStatus(closed: boolean | undefined) {
    return () =>
      onUpdate({
        open: closed,
      })
  }
  function toggleArchived(archived: boolean) {
    return () => {
      onUpdate({
        archived,
      })
    }
  }
  function toggleCategory(id: string) {
    return () =>
      onUpdate({
        excludeCategories: xor(filter.excludeCategories, [id]),
      })
  }
  function removeSeverity(severity: IssueSeverity) {
    return () =>
      onUpdate({
        severities: filter.severities.filter(s => s !== severity),
      })
  }
  function addSeverity(severity: IssueSeverity) {
    return () =>
      onUpdate({
        severities: [...filter.severities, severity],
      })
  }
  function handleClickSeverity(severity: IssueSeverity) {
    return filter.severities.includes(severity)
      ? removeSeverity(severity)
      : addSeverity(severity)
  }
  return (
    <FilterPopup>
      <h4>{translations.status}</h4>

      <Status statusCount={3}>
        <StatusButton
          active={filter.open === true}
          onClick={toggleStatus(true)}
        >
          {translations.open}
        </StatusButton>
        <StatusButton
          active={filter.open === false}
          onClick={toggleStatus(false)}
        >
          {translations.closed}
        </StatusButton>
        <StatusButton
          active={filter.open === undefined}
          onClick={toggleStatus(undefined)}
        >
          {translations.both}
        </StatusButton>
      </Status>

      <h4>{translations.severity}</h4>

      <Status statusCount={3}>
        <StatusButton
          active={filter.severities.includes("LOW")}
          onClick={handleClickSeverity("LOW")}
        >
          {translations.low}
        </StatusButton>
        <StatusButton
          active={filter.severities.includes("MEDIUM")}
          onClick={handleClickSeverity("MEDIUM")}
        >
          {translations.medium}
        </StatusButton>
        <StatusButton
          active={filter.severities.includes("HIGH")}
          onClick={handleClickSeverity("HIGH")}
        >
          {translations.high}
        </StatusButton>
      </Status>

      <h4>{translations.archived}</h4>
      <Status statusCount={2}>
        <StatusButton
          active={filter.archived === false}
          onClick={toggleArchived(false)}
        >
          {translations.open}
        </StatusButton>
        <StatusButton
          active={filter.archived === true}
          onClick={toggleArchived(true)}
        >
          {translations.archived}
        </StatusButton>
      </Status>

      <h4>{translations.categories}</h4>

      <FilterCheckmarksWrapper>
        {categoriesLoading && (
          <CenteredLoader marginTop="1rem" marginBottom="1rem" />
        )}
        <StyledScrollBar>
          {categories.map(category => {
            const checked = !filter.excludeCategories.includes(category.id)
            return (
              <Checkbox
                key={category.id}
                checked={checked}
                label={category.name}
                boxWidth="32px"
                boxHeight="32px"
                fontSize="1rem"
                onChange={toggleCategory(category.id)}
              />
            )
          })}
        </StyledScrollBar>
      </FilterCheckmarksWrapper>
    </FilterPopup>
  )
}
