import { GoogleMap, LatLng } from "components"
import styled from "styled-components"
import { LATEST_LOCATIONS_AND_FORECASTS } from "./queries"
import { useQuery } from "@apollo/client"
import { FleetUserWithLocation, LatestLocationsAndForecastsQuery } from "./types.graphql"
import { useState } from "react"
import { DataArea } from "./DataArea"

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;

    grid-template-areas: 'map';
`

const StyledMap = styled(GoogleMap)`
    grid-area: map;
`

const defaultCenter = {
    lat: 64.2574549,
    lng: 11.1844929,
}

export const Fleet: React.FC = () => {
    
    const [activeUser, setActiveUser] = useState<FleetUserWithLocation | null>(null)
    const [zoom, setZoom] = useState(5)
    const [center, setCenter] = useState<LatLng>(defaultCenter)

    const { data } = useQuery<LatestLocationsAndForecastsQuery>(LATEST_LOCATIONS_AND_FORECASTS, {
        // Poll every 5 minutes
        pollInterval: 1000*60*5,
    })

    const users = data?.allUsers.edges.map(edge => edge.node)
    const locationUsers = users?.filter(user => user.lastLocation !== null) as FleetUserWithLocation[] || []
    const remainingDriving = data?.allForecasts || []

    function handleActiveUserChange(userOrId: string | FleetUserWithLocation | null, moveCenter: boolean = false) {
        if (userOrId === null) {
            setActiveUser(null)
            return
        }
        const user = (typeof userOrId === 'string') ? locationUsers?.filter((user) => user.id === userOrId)?.[0] : userOrId
        if (activeUser?.id === user.id) {
            return
        }
        setActiveUser(user)
        if(zoom < 8) {
            setZoom(8)
            setCenter({
                lat: user.lastLocation.latitude,
                lng: user.lastLocation.longitude,
            })
        } else if (moveCenter) {
            setCenter({
                lat: user.lastLocation.latitude,
                lng: user.lastLocation.longitude,
            })
        }
    }

    const markers = locationUsers.map((user) => ({
        position: {
            lat: user.lastLocation.latitude,
            lng: user.lastLocation.longitude,
        },
        title: user.fullName,
        clickable: true,
        opacity: activeUser && activeUser.id !== user.id ? 0.6 : 1,
        onClick: () => handleActiveUserChange(user),

    }))
    const activeVehicle = data?.allVehicles.edges
        .map(edge => edge.node)
        .filter(vehicle => vehicle.vin === activeUser?.lastLocation.vehicleVin)?.[0] ?? null
    
    const activeRemainingDriving = remainingDriving.filter(forecast => forecast.user.id === activeUser?.id)?.[0] ?? null

    return <Wrapper>
        <StyledMap
            zoom={zoom} 
            center={center} 
            options={{
                streetViewControl: false, 
                mapTypeControl: false, 
                zoomControlOptions: {
                    position: 7
                },
            }}
            mapContainerStyle={{height: "100vh", width: '100%'}} 
            markers={markers}
            onZoomChanged={(newZoom) => {
                if (activeUser === null) return
                setZoom(newZoom)
            }}
        />
        <DataArea
            user={activeUser}
            updateActiveUser={(id, moveCenter) => handleActiveUserChange(id, moveCenter)}
            allUsers={locationUsers ?? []}
            vehicle={activeVehicle}
            remainingDriving={activeRemainingDriving}
            onClose={() => setActiveUser(null)}
        />
    </Wrapper>
}