import { PromptOptions, PromptResolve, usePrompt } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { Input } from 'components'
import merge from 'lodash/merge'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { buttonUnset } from 'util/style'

interface StyledConfirmProps {
  hasTitle: boolean
}
const StyledPrompt = styled.aside<StyledConfirmProps>`
  max-width: 600px;
  min-width: 350px;

  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  border: 1rem solid white;
  background: white;
  overflow: hidden;

  header {
    padding: 1rem 2rem;
    text-align: center;
    font-weight: 700;
  }
  p.content {
    margin: 0;
    padding: ${props => (props.hasTitle ? '0 2rem 1rem' : '2rem 2rem 1rem')};
    text-align: center;
  }
  div.input {
    margin-bottom: 1rem;
  }
  div.buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  ${props => props.theme.media.mobile} {
    width: 96vw;
    max-width: 96vw;

    header {
      padding: 1rem;
    }
    p.content {
      padding: ${props => (props.hasTitle ? '0 1rem 1rem' : '1rem')};
    }
  }
`
const ConfirmButton = styled.button`
  ${buttonUnset}

  width: 100%;
  padding: 1rem 0;

  background: inherit;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  text-transform: uppercase;
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};

  &.deny {
    color: ${props => props.theme.colors.gray3};
    background-color: ${props => props.theme.colors.gray8};

    &:hover {
      background-color: ${props => props.theme.colors.gray9};
    }
  }
  &.confirm {
    color: white;
    background-color: ${props => props.theme.colors.secondary};

    &:hover {
      background-color: ${props => props.theme.colors.secondary}ba;
    }
  }

  &:active,
  &:focus {
    outline: none;
  }
`

type UsePromptStringValidateFn = (value: string) => boolean | string | null

interface PromptStringProps {
  prompt: string | null

  title?: string
  denyText: string
  confirmText: string
  initialText?: string

  autoSelect?: boolean
  nullOnInitial?: boolean

  onValidate?: UsePromptStringValidateFn
  onResolve: PromptResolve<string | null>
}
const PromptString: React.VFC<PromptStringProps> = ({
  prompt,

  title,
  denyText,
  confirmText,
  initialText,

  autoSelect,
  nullOnInitial = false,

  onValidate,
  onResolve,
}) => {
  const translations = useTranslate({
    required: 'common.required',
    invalid: 'error.invalid-value',
  })

  const [value, setValue] = useState(initialText ?? '')
  const [error, setError] = useState<string | null>(null)

  function handleSubmit() {
    if (nullOnInitial && value === initialText) {
      onResolve(null)
      return
    }
    if (value === '') {
      setError(translations.required)
      return
    }

    if (typeof onValidate !== 'undefined') {
      const valid = onValidate(value)

      if (valid === false) {
        setError(translations.invalid)
        return
      } else if (typeof valid === 'string') {
        setError(valid)
        return
      }
    }

    onResolve(value)
  }

  return (
    <StyledPrompt hasTitle={typeof title !== 'undefined'}>
      {typeof title !== 'undefined' && <header>{title}</header>}

      {prompt !== null && <p className="content">{prompt}</p>}

      <div className="input">
        <Input
          value={value}
          error={error}
          fullWidth
          autoFocus
          onFocus={evt => autoSelect && evt.target.select()}
          onEnter={handleSubmit}
          onEscape={() => onResolve(null)}
          onChange={setValue}
        />
      </div>

      <div className="buttons">
        <ConfirmButton className="deny" onClick={() => onResolve(null)}>
          {denyText}
        </ConfirmButton>
        <ConfirmButton className="confirm" onClick={handleSubmit}>
          {confirmText}
        </ConfirmButton>
      </div>
    </StyledPrompt>
  )
}

export interface UsePromptStringOptions {
  denyText?: string
  confirmText?: string
  initialText?: string

  autoSelect?: boolean
  nullOnInitial?: boolean

  validate?: UsePromptStringValidateFn

  promptOptions?: PromptOptions
}

export function usePromptString(defaultOptions: UsePromptStringOptions = {}) {
  const translations = useTranslate({
    cancel: 'common.cancel',
    ok: 'common.ok',
  })

  const addPrompt = usePrompt({
    modalProps: {
      placement: 'top-center',
      direction: 'from-top',
      offset: 32,
    },
  })

  const promptString = useCallback(
    (
      prompt: string | null,
      title?: string,
      options: UsePromptStringOptions = {}
    ) => {
      const mergedOptions = merge(defaultOptions, options)

      return addPrompt<string | null>(
        resolve => (
          <PromptString
            prompt={prompt}
            title={title}
            denyText={mergedOptions.denyText ?? translations.cancel}
            confirmText={mergedOptions.confirmText ?? translations.ok}
            initialText={mergedOptions.initialText}
            autoSelect={mergedOptions.autoSelect}
            nullOnInitial={mergedOptions.nullOnInitial}
            onValidate={mergedOptions.validate}
            onResolve={resolve}
          />
        ),
        mergedOptions.promptOptions
      ).then(response => response.data)
    },
    [addPrompt, defaultOptions, translations.cancel, translations.ok]
  )

  return promptString
}
