import { useTranslate } from '@ur/react-hooks'
import { SidebarNavItem, SidebarQuery, SIDEBAR_QUERY } from 'modules/sidebar'
import UserThumbOrInitials from 'modules/users/UserThumbOrInitials'
import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { isMobile } from 'react-device-detect'
import styled, { css } from 'styled-components'
import { useAdmin, useUser } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { MobileUserMenu } from './MobileUserMenu'
import {
  useCompositeModules,
  useModuleConfigs,
} from 'modules/companies/CompanySettings/hooks'
import { FormShortcuts } from './FormShortcuts/FormShortcuts'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .time-entries svg {
    box-sizing: content-box;
    padding: 0 3px;
  }
  .settings svg {
    box-sizing: content-box;
    padding: 0 3px;
  }
`

interface SeparatorProps {
  tall?: boolean
}
const Separator = styled.div<SeparatorProps>`
  width: 100%;
  margin-top: 1em;
  padding: 2em 16px 1em;

  font-size: 0.8em;
  font-weight: 600;
  color: ${props => props.theme.colors.gray3};

  border-top: 1px solid ${props => props.theme.colors.gray8};

  ${props => props.theme.layout.mobile} {
    ${props =>
      props.tall &&
      css`
        margin-top: 2em;
      `};
  }
`
const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.6em;
  padding: 0.6em 16px 1em;

  border-bottom: 1px solid ${props => props.theme.colors.gray8};

  h2 {
    margin: 0;
    font-weight: 600;
  }
  & > div {
    position: relative;
    display: grid;
    grid-auto-flow: column;
    gap: 1em;
  }
`

export const SidebarNav: React.VFC = () => {
  const translations = useTranslate({
    labelGeneral: 'common.general',
    labelDocumentation: 'common.documentation',
    labelInformation: 'common.information',

    menu: 'common.menu',

    dashboard: 'common.dashboard',
    users: 'common.users',
    forum: 'common.forum',
    drivingData: 'common.driving-data',
    terminal: 'common.terminal',
    issues: 'common.issues',
    infringements: 'common.infringements',
    timeEntries: 'common.hourlist',
    absences: 'common.absence',
    handbook: 'common.driversHandbook',
    formCreator: 'common.formcreator',
    companySettings: 'settings.company-settings',
    vehicleFleet: 'vehicles.fleet-overview',
    fleet: 'vehicles.fleet',
    fleetManagement: 'vehicles.fleet-management',
    routePlanner: 'common.route-planner',
    userExports: 'exports.user-exports',
  })

  const admin = useAdmin()
  const user = useUser()
  const {
    activitiesActive,
    terminalActive,
    timesheetsActive,
    absencesActive,
    vehiclesActive,
    forumActive,
    handbookActive,
    infringementsActive,
    issuesActive,
    routePlannerActive,
  } = useModuleConfigs()

  const { mainModuleActive } = useCompositeModules()

  const [mobileUserMenuOpen, setMobileUserMenuOpen] = useState(false)

  const { data, loading } = useQuery<SidebarQuery>(SIDEBAR_QUERY)

  const issueCount = data?.allIssues.total

  return (
    <Wrapper>
      {isMobile && (
        <>
          <Head>
            <h2>{translations.menu}</h2>

            <div>
              <UserThumbOrInitials
                user={user}
                size={42}
                color="primary"
                backgroundColor="secondary"
                fontSize="0.7em"
                fontWeight="600"
                onClick={() => setMobileUserMenuOpen(isMobile)}
              />

              {isMobile && (
                <MobileUserMenu
                  open={mobileUserMenuOpen}
                  user={user}
                  onClose={() => setMobileUserMenuOpen(false)}
                />
              )}
            </div>
          </Head>
        </>
      )}

      <SidebarNavItem
        to="/dashboard"
        icon="dashboard"
        /* TODO: Rmove if we get a svg with better base */
        iconWidth="18px"
        text={translations.dashboard}
      />

      <Separator tall={isMobile}>{translations.labelGeneral}</Separator>

      <SidebarNavItem
        to="/users"
        exact
        icon="users"
        text={translations.users}
        permissionsRequired={PERMISSIONS.users.view.user}
      />
      {forumActive && (
        <SidebarNavItem
          to="/forum"
          icon="chat"
          text={translations.forum}
          permissionsRequired={PERMISSIONS.forum.view.thread}
        />
      )}

      {mainModuleActive && (
        <>
          <Separator>{translations.labelDocumentation}</Separator>
          {activitiesActive && (
            <SidebarNavItem
              to="/activities"
              icon="truck"
              text={translations.drivingData}
              permissionsRequired={PERMISSIONS.activities.view.driveractivity}
            />
          )}

          {terminalActive && (
            <SidebarNavItem
              to="/terminal"
              icon="house"
              text={translations.terminal}
              permissionsRequired={PERMISSIONS.terminal.view.terminalentry}
            />
          )}
          {issuesActive && (
            <SidebarNavItem
              to="/issues"
              icon="warning"
              text={translations.issues}
              badge={admin ? issueCount : undefined}
              badgeLoading={loading}
              permissionsRequired={PERMISSIONS.issues.view.issue}
            />
          )}
          {infringementsActive && (
            <SidebarNavItem
              to="/infringements"
              icon="infringement"
              text={translations.infringements}
              permissionsRequired={PERMISSIONS.infringements.view.infringement}
            />
          )}
          {timesheetsActive && (
            <SidebarNavItem
              className="time-entries"
              to="/time-entries"
              icon="clock"
              iconWidth="20px"
              text={translations.timeEntries}
              permissionsRequired={PERMISSIONS.timesheets.view.timeentry}
            />
          )}
          {absencesActive && (
            <SidebarNavItem
              to="/absences"
              icon="user-x"
              text={translations.absences}
              permissionsRequired={PERMISSIONS.timesheets.view.absence}
            />
          )}
        </>
      )}
      <Separator>{translations.fleet}</Separator>
      
      {vehiclesActive && (
        <>
        <SidebarNavItem
          className="vehicle-fleet"
          to="/vehicle-fleet"
          icon="equipment-materials"
          iconWidth="26px"
          marginoverride="1.075rem"
          text={translations.vehicleFleet}
          permissionsRequired={PERMISSIONS.vehicles.view.vehicle}
        />
        {false && (
          <SidebarNavItem
            className="fleet"
            to="/fleet"
            target="_blank"
            icon="equipment-materials"
            iconWidth="26px"
            marginoverride="1.075rem"
            text={translations.fleetManagement}
            permissionsRequired={PERMISSIONS.vehicles.view.vehicle}
          />
        )}
        </>
      )}

      {handbookActive && (
        <FormShortcuts />
      )}

      <Separator>{translations.labelInformation}</Separator>
      {handbookActive && (
        <SidebarNavItem
          icon="book"
          to="/handbook"
          text={translations.handbook}
          permissionsRequired={PERMISSIONS.handbook.view.manualfolder}
        />
      )}

    {handbookActive && (
      <SidebarNavItem
        to="/formcreator"
        exact
        icon="form-creator"
        text={translations.formCreator}
        permissionsRequired={PERMISSIONS.formcreator.view.formtemplate}
      />
    )}
      <SidebarNavItem
        className="user-exports"
        to="/user-exports"
        icon="file-arrow-down"
        iconWidth="24px"
        marginoverride="1.2rem"
        text={translations.userExports}
        permissionsRequired={PERMISSIONS.companies.export.exportReports}
      />
      {admin && (
        <>
          {routePlannerActive && (
            <>
              <Separator>{translations.routePlanner}</Separator>
              <SidebarNavItem
                className="route-planner"
                to="/route-planner"
                icon="route-planner"
                iconWidth="24px"
                text={translations.routePlanner}
                permissionsRequired={PERMISSIONS.companies.view.companysetting}
              />
            </>
          )}
          <SidebarNavItem
            className="settings"
            to="/settings"
            icon="cog"
            iconWidth="24px"
            text={translations.companySettings}
            permissionsRequired={PERMISSIONS.companies.view.companysetting}
          />
        </>
      )}
    </Wrapper>
  )
}

export const StyledSidebarNav = styled(SidebarNav)
