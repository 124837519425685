import React, { useEffect, useCallback, useRef } from 'react'

interface DetectBlurProps {
  children: React.ReactNode
  event?: string
  active: boolean
  detectEscape?: boolean
  disableTouchEnd?: boolean
  onClickOutside: (e: MouseEvent) => void
}

export const DetectBlur: React.FC<DetectBlurProps> = ({
  children,
  event = 'mouseup',
  active = false,
  detectEscape = false,
  disableTouchEnd = false,
  onClickOutside,
}) => {
  const node = useRef<HTMLDivElement | null>(null)

  const handleClick = useCallback(
    e => {
      if (!active || node === null || node.current === null) {
        return
      }

      if (node.current.contains(e.target)) {
        return
      }

      onClickOutside(e)
    },
    [active, onClickOutside]
  )

  const handleKeyDown = useCallback(
    e => {
      if (!active) return

      if (e.key?.toLowerCase() === 'escape') {
        onClickOutside(e)
      }
    },
    [active, onClickOutside]
  )

  useEffect(() => {
    if (event) document.addEventListener(event, handleClick)
    if (!disableTouchEnd) document.addEventListener('touchend', handleClick)
    if (detectEscape) document.addEventListener('keydown', handleKeyDown)

    return () => {
      if (event) document.removeEventListener(event, handleClick)
      if (!disableTouchEnd) document.addEventListener('touchend', handleClick)
      if (detectEscape) document.removeEventListener('keydown', handleKeyDown)
    }
  }, [event, detectEscape, disableTouchEnd, handleClick, handleKeyDown])

  return <div ref={node}>{children}</div>
}
