import { useQuery } from '@apollo/client'
import { Icon, usePrompt } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { Button, Table as BaseTable } from 'components'
import { subWeeks } from 'date-fns'
import assign from 'lodash/assign'
import { EXPIRATION_TYPES } from 'modules/vehicles/consts'
import { VEHICLE_EXPIRATIONS_QUERY } from 'modules/vehicles/queries'
import {
  EditVehicleExpirationForm,
  EditVehicleExpirationPromptResolve,
} from 'modules/vehicles/types'
import {
  VehicleExpirationNode,
  VehicleExpirationsQuery,
  VehicleExpirationsQueryVariables,
} from 'modules/vehicles/types.graphql'
import { useMemo } from 'react'
import styled from 'styled-components'
import { format, formatDistanceStrict } from 'util/date-fns'
import { useConfirm, useOnErrorAuto, usePermissions } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { useVehicleExpirationMutations } from '../mutations.hooks'
import { EditVehicleExpirationModal } from './EditVehicleExpirationModal'

const Wrapper = styled.div`
  margin-top: 1rem;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
`

const Table = styled(props => <BaseTable {...props} />)`
  position: relative;

  thead {
    text-align: left;
  }

  tbody tr {
    :hover {
      background: ${props => props.theme.colors.gray10};
    }
  }
`

interface VehicleExpirationsProps {
  vehicleId: string
}

export const VehicleExpirations: React.VFC<VehicleExpirationsProps> = ({
  vehicleId,
}) => {
  const translations = useTranslate({
    expirationDates: 'vehicles.expiration-dates',
    add: 'vehicles.add-expiration-date',
    type: 'common.type',
    description: 'common.description',
    expirationDate: 'common.expires-at',
    date: 'common.date',
    notificationTime: 'common.notification',
    noExpirationDates: 'vehicles.no-expiration-dates',
    prompt: {
      delete: 'vehicles.prompts.delete-expiration-date',
      deleteConfirmation: [
        'documents.prompts.delete-document-confirmation',
        { name: '' },
      ],
    },
  })

  const addPrompt = usePrompt()
  const confirm = useConfirm()
  const onErrorAuto = useOnErrorAuto()
  const { hasPermissions } = usePermissions()
  const canEdit = hasPermissions(PERMISSIONS.users.change.userdocument)
  const canDelete = hasPermissions(PERMISSIONS.users.delete.userdocument)

  const { data, loading } = useQuery<
    VehicleExpirationsQuery,
    VehicleExpirationsQueryVariables
  >(VEHICLE_EXPIRATIONS_QUERY, {
    variables: {
      vehicle: vehicleId,
      orderBy: 'expirationDate',
    },
    onError: onErrorAuto(),
  })

  const expirations = useMemo(() => {
    return data?.allVehicleExpirations?.edges?.map(edge => edge?.node) || []
  }, [data])

  const { createExpiration, patchExpiration, deleteExpiration } =
    useVehicleExpirationMutations()

  function getExpirationAndNotificationTime(
    form: EditVehicleExpirationForm,
    hasNotification: boolean
  ) {
    const expirationDate = form.expirationDate || new Date()
    const notificationTime = !hasNotification
      ? null
      : subWeeks(expirationDate, form.notificationTime)
    return { expirationDate, notificationTime }
  }

  async function handleCreate() {
    const { data } = await addPrompt<EditVehicleExpirationPromptResolve | null>(
      resolve => <EditVehicleExpirationModal onSubmit={resolve} />
    )
    if (!data) return
    const { form, hasNotification } = data
    const { expirationDate, notificationTime } =
      getExpirationAndNotificationTime(form, hasNotification)

    const input = assign(form, {
      vehicle: vehicleId,
      expirationDate,
      notificationTime,
    })

    await createExpiration({
      variables: {
        input,
      },
    })
  }

  async function handleEdit(expiration: VehicleExpirationNode) {
    const { data } = await addPrompt<EditVehicleExpirationPromptResolve | null>(
      resolve => (
        <EditVehicleExpirationModal
          vehicleExpiration={expiration}
          onSubmit={resolve}
        />
      )
    )
    if (!data) return
    const { form, hasNotification } = data
    const { expirationDate, notificationTime } =
      getExpirationAndNotificationTime(form, hasNotification)

    await patchExpiration({
      variables: {
        id: expiration.id,
        input: {
          description: form.description,
          expirationType: form.expirationType,
          expirationDate,
          notificationTime,
          responsibleUser: form.responsibleUser,
        },
      },
    })
  }

  async function handleDelete(expiration: VehicleExpirationNode) {
    const expirationDate = format(new Date(expiration.expirationDate), 'PP')
    const expirationType = EXPIRATION_TYPES[expiration.expirationType]
    const confirmName = `${expirationDate} - ${expirationType}`
    const { data: answer } = await confirm(
      translations.prompt.deleteConfirmation({ name: confirmName }),
      translations.prompt.delete
    )
    if (!answer) return
    deleteExpiration({
      variables: {
        id: expiration.id,
      },
    })
  }

  return (
    <Wrapper>
      <Header>
        <h2>{translations.expirationDates}</h2>
        <Button
          iconLeftProps={{ icon: 'plus', size: '1rem', type: 'solid' }}
          height="3rem"
          onClick={handleCreate}
        >
          {translations.add}
        </Button>
      </Header>
      <Table
        loading={loading}
        noShadow
        noData={!expirations.length}
        noDataText={translations.noExpirationDates}
      >
        <thead>
          <tr>
            <th>{translations.date}</th>
            <th>{translations.type}</th>
            <th>{translations.description}</th>
            <th>{translations.notificationTime}</th>
            {canEdit && <th></th>}
            {canDelete && <th></th>}
          </tr>
        </thead>
        <tbody>
          {expirations.map(expiration => {
            const expirationType = EXPIRATION_TYPES[expiration.expirationType]
            const expirationDate = !!expiration.expirationDate
              ? format(new Date(expiration.expirationDate), 'PP')
              : '-'
            const notificationTime =
              !!expiration.notificationTime && !!expiration.expirationDate
                ? formatDistanceStrict(
                    new Date(expiration.expirationDate),
                    new Date(expiration.notificationTime)
                  )
                : '-'
            const canEditThisExpiration = canEdit
            const canDeleteThisExpiration = canDelete && expirationType !== EXPIRATION_TYPES.nextEuControl
            return (
              <tr key={expiration.id}>
                <td>{expirationDate}</td>
                <td className="wrap">{expirationType}</td>
                <td className="wrap">{expiration.description}</td>
                <td>{notificationTime}</td>
                {canEdit && (
                  <td width="1px" className="center">
                    {canEditThisExpiration &&
                      <Icon
                        icon="edit"
                        cursor="pointer"
                        color="gray6"
                        hoverColor="secondary"
                        onClick={() => handleEdit(expiration)}
                      />
                    }
                  </td>
                )}
                {canDelete && (
                  <td>
                    {canDeleteThisExpiration && 
                      <Icon
                        icon="times"
                        cursor="pointer"
                        color="gray6"
                        hoverColor="red"
                        onClick={() => handleDelete(expiration)}
                      />
                    }
                  </td>
                )}
              </tr>
            )
          })}
        </tbody>
      </Table>
    </Wrapper>
  )
}
