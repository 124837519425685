import { Icon, usePrompt } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { Table } from 'components'
import {
  EditResponsibleUserModal,
  Module,
  ResponsibleUserForm,
} from 'modules/companies'
import { UserProfileLink } from 'modules/users/UserProfile/UserProfileLink'
import { UserThumbOrInitials } from 'modules/users/UserThumbOrInitials'
import { useMemo } from 'react'
import styled from 'styled-components'
import { useModuleConfig } from 'util/hooks'
import { useModuleConfigMutations } from '../util/mutations.hooks'
import { AddButton, Section, SectionHeader } from './common'
import { isMobile } from 'react-device-detect'

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const UsersWrapper = styled.div`
  display: flex;  
  flex-direction: column;
  
  gap: 1rem;
`

export const ModuleResponsibleUsers: React.VFC<{module: Module}> = ({module}) => {
  const translations = useTranslate({
    update: 'common.update',
    add: 'common.add',
    responsibleUsers: 'common.responsible-users',
    user: 'common.user',
    noResults: 'common.no-results',
  })
  const addPrompt = usePrompt()

  const { moduleConfig } = useModuleConfig(
    module
  )

  const responsibleUsers = useMemo(() => {
    return moduleConfig?.responsibleUsers ?? []
  }, [moduleConfig])
  const responsibleUserIds = useMemo(() => {
    return responsibleUsers.map(user => user.id)
  }, [responsibleUsers])
  const { patch: patchModuleConfig } = useModuleConfigMutations()

  async function handleCreatePrompt() {
    const { data } = await addPrompt<ResponsibleUserForm | null>(resolve => (
      <EditResponsibleUserModal
        responsibleUser={null}
        onSubmit={resolve}
      />
    ))
    if (!moduleConfig) return
    if (data === null || data.userId === null) return

    await patchModuleConfig({
      variables: {
        id: moduleConfig.id,
        input: {
          responsibleUsers: [...(responsibleUserIds), data.userId],
        },
      },
    })
  }

  async function handleDeleteResponsible(userId: string) {
    if (!moduleConfig) return
    const updatedResponsibleUsers = responsibleUserIds.filter(
      id => id !== userId
    )
    await patchModuleConfig({
      variables: {
        id: moduleConfig.id,
        input: {
          responsibleUsers: updatedResponsibleUsers,
        },
      },
    })
  }

  return (
    <Section>
      <Header>
        <SectionHeader>{translations.responsibleUsers}</SectionHeader>
        {!isMobile &&
          <AddButton adding={false} onClick={handleCreatePrompt} />
        }
      </Header>
        <UsersWrapper>
          <Table
              noShadow
              noBorders
              noHeaderMargin
              noRowMargin
              rowHoverColor="secondary"
            >
              <thead>
                <tr>
                  <th></th>
                  <th colSpan={2}>{translations.user}</th>
                </tr>
              </thead>
              <tbody>
              {responsibleUsers.map((responsibleUser) => (
                <tr key={responsibleUser.id}>
                  <td width="1px">
                    <UserThumbOrInitials user={responsibleUser} />
                  </td>
                  <td>
                    <UserProfileLink user={responsibleUser} />
                  </td>
                  <td width="1px">
                    <Icon
                      icon="times"
                      cursor="pointer"
                      color="gray6"
                      hoverColor="red"
                      onClick={() => handleDeleteResponsible(responsibleUser.id)}
                    />
                  </td>
                </tr>
              ))}
              {!responsibleUsers.length && (
                <tr>
                  <td colSpan={3}>{translations.noResults}</td>
                </tr>
              )}
              </tbody>
            </Table>
            </UsersWrapper>
          
    </Section>
  )
}
