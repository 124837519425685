import React, { useCallback } from 'react'
import { PromptOptions, PromptResolve, usePrompt } from '@ur/react-components'
import styled from 'styled-components'
import { useTranslate } from '@ur/react-hooks'
import { buttonUnset } from 'util/style'
import merge from 'lodash/merge'

interface StyledConfirmProps {
  hasTitle: boolean
}
const StyledConfirm = styled.aside<StyledConfirmProps>`
  max-width: 600px;

  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  border: 1rem solid white;
  background: white;
  overflow: hidden;

  header {
    padding: 1rem 2rem;
    text-align: center;
    font-weight: 700;
  }
  p.content {
    margin: 0;
    padding: ${props => (props.hasTitle ? '0 2rem 2rem' : '2rem')};
    text-align: center;
  }
  div.buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  ${props => props.theme.media.mobile} {
    width: 96vw;
    max-width: 96vw;

    header {
      padding: 1rem;
    }
    p.content {
      padding: ${props => (props.hasTitle ? '0 1rem 1rem' : '1rem')};
    }
  }
`
const ConfirmButton = styled.button`
  ${buttonUnset}

  width: 100%;
  padding: 1rem 0;

  background: inherit;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  text-transform: uppercase;
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};

  &.deny {
    color: ${props => props.theme.colors.gray3};
    background-color: ${props => props.theme.colors.gray8};

    &:hover {
      background-color: ${props => props.theme.colors.gray9};
    }
  }
  &.confirm {
    color: white;
    background-color: ${props => props.theme.colors.secondary};

    &:hover {
      background-color: ${props => props.theme.colors.secondary}ba;
    }
  }

  &:active,
  &:focus {
    outline: none;
  }
`

interface ConfirmProps {
  prompt: string

  title?: string
  denyText: string
  confirmText: string

  onResolve: PromptResolve<boolean>
}
const Confirm: React.VFC<ConfirmProps> = ({
  prompt,
  title,
  denyText,
  confirmText,
  onResolve,
}) => (
  <StyledConfirm hasTitle={typeof title !== 'undefined'}>
    {typeof title !== 'undefined' && <header>{title}</header>}

    <p className="content">{prompt}</p>

    <div className="buttons">
      <ConfirmButton className="deny" onClick={() => onResolve(false)}>
        {denyText}
      </ConfirmButton>
      <ConfirmButton className="confirm" onClick={() => onResolve(true)}>
        {confirmText}
      </ConfirmButton>
    </div>
  </StyledConfirm>
)

interface UseConfirmOptions {
  denyText?: string
  confirmText?: string

  promptOptions?: PromptOptions
}

export function useConfirm(defaultOptions: UseConfirmOptions = {}) {
  const translations = useTranslate({
    cancel: 'common.cancel',
    ok: 'common.ok',
  })

  const addPrompt = usePrompt({
    modalProps: {
      placement: 'top-center',
      direction: 'from-top',
      offset: 32,
    },
  })

  const confirm = useCallback(
    (prompt: string, title?: string, options: UseConfirmOptions = {}) => {
      const mergedOptions = merge(defaultOptions, options)

      return addPrompt(
        resolve => (
          <Confirm
            prompt={prompt}
            title={title}
            denyText={mergedOptions.denyText ?? translations.cancel}
            confirmText={mergedOptions.confirmText ?? translations.ok}
            onResolve={resolve}
          />
        ),
        mergedOptions.promptOptions
      )
    },
    [addPrompt, translations.cancel, translations.ok, defaultOptions]
  )

  return confirm
}
