import { useTranslate } from '@ur/react-hooks'
import { CheckedTable, CheckedTableRow, CheckedTableValue } from 'components'
import differenceWith from 'lodash/differenceWith'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import styled from 'styled-components'
import {
  CompanyWithNotificationSettings,
  NotificationSettingsNames,
  NotificationSettings,
} from '../../util/NotificationSettings'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow-x: auto;
`

interface NotificationSettingsTableProps {
  company: CompanyWithNotificationSettings

  onChange: (diff: CheckedTableValue[]) => void
}

export const NotificationSettingsTable: React.VFC<NotificationSettingsTableProps> =
  ({
    company,

    onChange,
  }) => {
    const translations = useTranslate({
      inAppNotification: 'common.in-app',
      emailNotification: 'common.email',
      smsNotification: 'common.sms',
    })

    const settingTranslations = useTranslate({
      userRegistration: 'settings.notifications.setting.user-registration',
      resetPassword: 'settings.notifications.setting.reset-password',
      userDocument: 'settings.notifications.setting.user-document',
      issueCreated: 'settings.notifications.setting.issue-created',
      issueClosed: 'settings.notifications.setting.issue-closed',
      absenceCreated: 'settings.notifications.setting.absence-created',
      forumThreadCreated: 'settings.notifications.setting.forum-thread',
      forumPostCreated: 'settings.notifications.setting.forum-post',
      driverCardWillExpire: 'settings.notifications.setting.driver-card',
      userExportReady: 'settings.notifications.setting.user-export-ready',
      vehicleExpiration: 'settings.notifications.vehicle-expirations',
      vehicleDocument: 'settings.notifications.vehicle-document',
      notifyDriverOnInfringement: 'settings.notifications.notify-driver-on-infringement',
    })

    const prevCompany = useRef<CompanyWithNotificationSettings | null>(null)
    const initialValues = useRef<CheckedTableValue[]>([])

    const [checkedCollection, setCheckedCollection] = useState<
      CheckedTableValue[]
    >([])

    const createCheckedValues = useCallback(
      (company: CompanyWithNotificationSettings) => {
        const cleaned = omit(
          company,
          'id',
          '__typename'
        ).notificationSettings.edges.map(edge => edge.node)

        const columns = ['sendInApp', 'sendEmail', 'sendSms'] as const

        const values: CheckedTableValue[] = []
        cleaned.forEach(row => {
          if (columns.includes('sendInApp')) {
            for (const column of columns) {
              values.push({
                column,
                row: row.id,
                checked: row[column],
              })
            }
          } else {
            const reducedColumns = ['sendEmail', 'sendSms'] as const
            values.push({
              column: 'sendInApp',
              row: row.id,
              checked: null,
            })
            for (const column of reducedColumns) {
              values.push({
                column,
                row: row.id,
                checked: row[column],
              })
            }
          }
        })
        return values
      },
      []
    )

    useEffect(() => {
      if (isEqual(prevCompany.current, company)) return
      prevCompany.current = company

      const values = createCheckedValues(company)
      initialValues.current = values
      setCheckedCollection(values)
    }, [company, createCheckedValues])

    const columns = useMemo(
      () => [
        translations.inAppNotification,
        translations.emailNotification,
        translations.smsNotification,
      ],
      [
        translations.emailNotification,
        translations.inAppNotification,
        translations.smsNotification,
      ]
    )

    const getIdForTranslation = (
      key: string,
      cleaned: { id: string; name: NotificationSettingsNames }[]
    ) => {
      const found = cleaned.filter(
        value => NotificationSettings[value.name] === key
      )?.[0]
      return found?.id
    }
    const rows = useMemo<CheckedTableRow[]>(() => {
      const cleaned = omit(company, 'id', '__typename')
        .notificationSettings.edges.map(edge => edge.node)
        .map(node => ({ id: node.id, name: node.name }))

      return Object.entries(settingTranslations).map(([key, value]) => ({
        type: 'regular',
        title: value,
        value: getIdForTranslation(key, cleaned),
      }))
    }, [settingTranslations, company])

    function handleChange(values: CheckedTableValue[]) {
      const diff = differenceWith(values, initialValues.current, isEqual)
      setCheckedCollection(values)

      onChange(diff)
    }

    return (
      <Wrapper>
        <ScrollBar>
          <CheckedTable
            value={checkedCollection}
            columns={columns}
            rows={rows}
            onChange={handleChange}
          />
        </ScrollBar>
      </Wrapper>
    )
  }
