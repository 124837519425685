import nb from './translations/nb'
import en from './translations/en'
import ru from './translations/ru'
import lv from './translations/lv'
import ro from './translations/ro'
import pl from './translations/pl'

const messages: { [lang: string]: Record<string, string> } = {
  nb,
  en,
  ru,
  lv,
  ro,
  pl,
  'nb-NO': nb,
  'nn-NO': nb,
  'en-GB': en,
  'en-US': en,
  'ru-RU': ru,
  'lv-LV': lv,
  'ro-RO': ro,
  'pl-PL': pl,
}

export default messages
