import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Input as BaseInput,
  InputProps as BaseInputProps,
} from '@ur/react-components'
import { Select, SelectProps as BaseSelectProps } from 'components/Select'
import styled, { css } from 'styled-components'
import { SelectOption } from '@ur/react-components'
import { countries, CountryCode } from 'util/countries'

import {
  getCountries,
  getCountryCallingCode,
  CountryCode as PhoneCode,
  parsePhoneNumber,
} from 'libphonenumber-js'
import { useCountryCodeTranslations } from 'util/hooks/useCountryCodeTranslations'

const selectOpenStyle = css`
  position: relative;
  z-index: 1;
  .country,
  .--select-curtain,
  .--select-display {
    width: 300px;
  }
  &:focus-within {
    .--select-display,
    .--select-curtain,
    .--input-icon-left,
    .--input-icon-right {
      border-color: ${props => props.theme.colors.secondary};
    }
  }
`
const StyledSelect = styled.div<{ open: boolean }>`
  width: 100px;
  .--select-display {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  ${props => props.open && selectOpenStyle}
`
interface CountryCodeSelectProps
  extends Omit<BaseSelectProps<PhoneCode, string>, 'options' | 'value'> {
  value: PhoneCode
  include?: CountryCode[]
  noTranslation?: boolean
  grayed?: boolean
  overrideName?: (code: CountryCode, name: string) => string
  overrideFlag?: (code: CountryCode, flag: string) => string
}
const CountryCodeSelect: React.VFC<CountryCodeSelectProps> = ({
  include,
  noTranslation = false,
  grayed = false,
  overrideName = (_, name) => name,
  overrideFlag = (_, flag) => flag,
  ...selectProps
}) => {
  // TODO fix exhaustive-deps?
  // eslint-disable-next-line
  const overrideNameCallback = useCallback(overrideName, [])
  // eslint-disable-next-line
  const overrideFlagCallback = useCallback(overrideFlag, [])
  const [optionsOpen, setOptionsOpen] = useState(false)
  const { getCountryCodeTranslations } = useCountryCodeTranslations()

  const translateName = useCallback(
    (code: CountryCode) => {
      return getCountryCodeTranslations(code)
    },
    [getCountryCodeTranslations]
  )

  const options = useMemo<SelectOption<PhoneCode, string>[]>(
    () =>
      Object.entries(countries).reduce<SelectOption<PhoneCode, string>[]>(
        (acc, [key, value]) => {
          const supportedCountries = getCountries()
          if (
            typeof include !== 'undefined' &&
            !include.includes(key as CountryCode)
          )
            return acc
          if (!supportedCountries.includes(key as PhoneCode)) return acc
          let countryName = value.name
          if (!noTranslation) countryName = translateName(key as CountryCode)
          const name = overrideNameCallback(key as CountryCode, countryName)
          const flag = overrideFlagCallback(key as CountryCode, value.emoji)
          const number = getCountryCallingCode(key as PhoneCode) as string
          return [
            ...acc,
            {
              value: key as PhoneCode,
              label: optionsOpen ? (
                <span className="selected">
                  <span className="flag">{flag}&nbsp;</span>
                  {`${name} (+${number})`}
                </span>
              ) : (
                <span className="selected">
                  <span className="flag">{flag}</span>
                </span>
              ),
              extra: `${name}${number}`,
            },
          ]
        },
        []
      ),
    [
      include,
      noTranslation,
      optionsOpen,
      translateName,
      overrideNameCallback,
      overrideFlagCallback,
    ]
  )
  const filterCountries = (
    option: SelectOption<PhoneCode, string>,
    query: string
  ) => option.extra?.toLowerCase().includes(query.toLowerCase()) ?? true
  return (
    <StyledSelect open={optionsOpen}>
      <Select
        className="country"
        options={options}
        searchable={filterCountries}
        width="100px"
        height="3.875rem"
        nullable={false}
        grayed={grayed}
        onOpen={() => setOptionsOpen(true)}
        onClose={() => setOptionsOpen(false)}
        {...selectProps}
      />
    </StyledSelect>
  )
}
const Wrapper = styled.div`
  display: flex;
  &:focus-within {
    .--select-display,
    .--input-icon-left,
    .--input-icon-right {
      border-color: ${props => props.theme.colors.secondary};
    }
  }
`
interface StyledInputProps {
  hasLeftIcon: boolean
  hasRightIcon: boolean
  noBorders: boolean
  fontWeight?: string | number
  padding?: string
}
const StyledInput = styled(BaseInput)<StyledInputProps>`
  width: 100%;
  input {
    padding: ${props => props.padding ?? '0 1em'};
    font-size: ${props => props.fontSize ?? '1.2rem'};
    font-weight: ${props => props.fontWeight};
    border-color: ${props => props.theme.colors.gray9};
    border-left: 0;
    width: 100%;
    &::placeholder {
      color: ${props => props.theme.colors.gray5};
    }
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .--input-icon-left,
  .--input-icon-right {
    font-size: 1.2em;
    padding: 0 1.4rem 0 2rem;
    border-color: ${props => props.theme.colors.gray9};
    transition: border-color 0.1s linear, color 0.1s linear;
    color: ${props => props.theme.colors.gray5};
  }
  ${props =>
    props.noBorders &&
    css`
      input {
        border: 0 !important;
      }
      .--input-icon-left,
      .--input-icon-right {
        border: 0 !important;
      }
      &:focus-within {
        .--input-icon-left,
        .--input-icon-right {
          border: 0 !important;
        }
      }
    `}
`
interface PhoneInputProps extends Omit<BaseInputProps, 'value'> {
  id?: string
  className?: string
  value: string
  noBorders?: boolean
  grayed?: boolean
  height?: string
  padding?: string
  fontWeight?: string | number
}
export const PhoneInput = React.forwardRef<HTMLInputElement, PhoneInputProps>(
  (
    {
      id,
      className,
      value,
      tabIndex,
      noBorders = false,
      grayed = false,
      height = '3.875rem',
      padding,
      fontWeight,
      onChange,
      ...props
    },
    ref
  ) => {
    const [parsedCode, parsedNumber] = useMemo<[PhoneCode, string]>(() => {
      try {
        const parsed = parsePhoneNumber(value)
        return [parsed.country ?? 'NO', parsed.nationalNumber.toString()]
      } catch {
        return ['NO', '']
      }
    }, [value])
    const getNumber = useCallback((code: PhoneCode, number: string) => {
      const countryCode = getCountryCallingCode(code)
      return `+${countryCode}${number}`
    }, [])
    const firstMount = useRef(true)
    const prevNumber = useRef(getNumber(parsedCode, parsedNumber))
    const [code, setCode] = useState<PhoneCode>(parsedCode)
    const [number, setNumber] = useState(parsedNumber)
    useEffect(() => {
      const newNumber = getNumber(code, number)
      if (firstMount.current || prevNumber.current === newNumber) {
        firstMount.current = false
        return
      }
      prevNumber.current = newNumber
      onChange(newNumber)
    }, [code, getNumber, number, onChange])
    function handleFocus(event: React.FocusEvent<HTMLInputElement>) {
      props.onFocus?.(event)
      if (event.defaultPrevented) return
      event.currentTarget.select()
    }

    const background = grayed ? 'quaternary' : props.background ?? 'white'

    return (
      <Wrapper className={className} id={id}>
        <CountryCodeSelect
          value={code}
          onChange={code => setCode(code ?? 'NO')}
          tabIndex={!!tabIndex ? tabIndex + 1 : undefined}
          hideErrorMessage={true}
          height={height}
          grayed={grayed}
        />
        <StyledInput
          ref={ref}
          value={number}
          height={height}
          background={background}
          focusBorderColor="secondary"
          tabIndex={tabIndex}
          hasLeftIcon
          hasRightIcon={typeof props.iconRightProps !== 'undefined'}
          noBorders={noBorders}
          fontWeight={fontWeight}
          padding={padding}
          onChange={setNumber}
          {...props}
          onFocus={handleFocus}
        />
      </Wrapper>
    )
  }
)
