import { usePrompt } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import {
  SortableHeader,
  Table as BaseTable,
  TableMenu,
  TableMenuItem,
} from 'components'
import React from 'react'
import { useQuery } from '@apollo/client'
import { isMobileOnly } from 'react-device-detect'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { format } from 'util/date-fns'
import { useOnErrorAuto, usePermissions, useUser } from 'util/hooks'
import { fromGlobalId } from 'util/parsing'
import { PERMISSIONS } from 'util/permissions'
import { ALL_FORM_TEMPLATES_QUERY } from '../queries'
import { FormTableFilter, FormTemplateShallow } from '../types'
import {
  FormTemplateForFormTable,
  AllFormTemplatesQuery,
  AllFormTemplatesQueryVariables,
  FormTemplateOrdering,
} from '../types.graphql'
import { ResponsePrompt } from './ResponsePrompt'

const Table = styled(BaseTable)`
  tbody tr {
    height: 3.875rem;
  }

  span[role='button'] {
    cursor: pointer;

    &:hover {
      color: ${props => props.theme.colors.secondary};
    }
  }

  i.view-responses-icon {
    display: none;
  }

  tr:hover {
    i.view-responses-icon {
      display: inline;
    }
  }
`

interface FormTableProps {
  filter: FormTableFilter
  onSort: (value: FormTemplateOrdering) => void
}

export const FormTable: React.VFC<FormTableProps> = ({ filter, onSort }) => {
  const translations = useTranslate({
    name: 'common.name',
    updatedAt: 'common.updated-at',
    lastResponseAt: 'form.last-response-at',
    used: 'form.table-label-used',

    editThisForm: 'form.edit-this-form',
    fillOutThisForm: 'form.fill-out-this-form',
    noResults: 'common.no-results',
    answers: 'form.see-answers',
    sums: 'common.sums',
  })

  const me = useUser()
  const onErrorAuto = useOnErrorAuto()
  const addPrompt = usePrompt()
  const history = useHistory()
  const { hasPermissions } = usePermissions()

  const canEdit = hasPermissions(PERMISSIONS.formcreator.change.formtemplate, true)
  const canViewFilled = hasPermissions(
    PERMISSIONS.formcreator.view.filledoutform, true
  )
  const canViewOthers = hasPermissions(PERMISSIONS.formcreator.change.filledoutform, true)

  const { data, loading } = useQuery<
    AllFormTemplatesQuery,
    AllFormTemplatesQueryVariables
  >(ALL_FORM_TEMPLATES_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      q: filter.query,
      archived: filter.archived,
      orderBy: filter.orderBy,
    },
    onError: onErrorAuto(),
  })

  async function handleOpenResponses(form: FormTemplateShallow) {
    const { data: responses } = await addPrompt<string[] | null>(resolve => (
      <ResponsePrompt user={me} canViewOthers={canViewOthers} form={form} onResolve={resolve} />
    ))
    if (responses === null || !responses.length) return

    const ids = responses.reduce<number[]>((acc, relayId) => {
      const id = fromGlobalId(relayId)?.[1]
      if (typeof id === 'undefined') return acc
      return [...acc, id]
    }, [])

    if (!ids.length) return

    history.push(`/formcreator/${form.id}/responses?ids=${ids.join(',')}`)
  }

  const forms = data?.allFormTemplates.edges.map(edge => edge.node) ?? []


  function getFormMenuItems(template: FormTemplateForFormTable) {
    const items: TableMenuItem<FormTemplateForFormTable>[] = []

    const hasDateAndNumberFields = template.fields.some(
      field => field.fieldType === 'DATE'
    ) && template.fields.some(
      field => field.fieldType === 'NUMBER'
    )


    canEdit &&
      items.push({
        label: translations.editThisForm,
        data: template,
        role: 'link',
        onClick: () => history.push(`/formcreator/edit/${template.id}`),
      })
    items.push({
      label: translations.fillOutThisForm,
      data: template,
      role: 'link',
      onClick: () => history.push(`/formcreator/${template.id}`),
    })
    canViewFilled &&
      items.push({
        label: translations.answers,
        onClick: () => handleOpenResponses(template),
      })
    
    canViewOthers && hasDateAndNumberFields &&
      items.push({
        label: translations.sums,
        onClick: () => history.push(`/formcreator/${template.id}/sums`),
      })

    return items
  }

  return (
    <Table
      loading={loading}
      noData={forms.length === 0}
      noDataText={translations.noResults}
    >
      <thead>
        <tr>
          <SortableHeader
            baseValue="name"
            sortValue={filter.orderBy}
            onSort={onSort}
          >
            {translations.name}
          </SortableHeader>

          {!isMobileOnly && (
            <>
              <SortableHeader
                baseValue="updatedAt"
                sortValue={filter.orderBy}
                onSort={onSort}
              >
                {translations.updatedAt}
              </SortableHeader>

              <SortableHeader
                baseValue="lastResponseAt"
                sortValue={filter.orderBy}
                onSort={onSort}
              >
                {translations.lastResponseAt}
              </SortableHeader>
            </>
          )}

          <SortableHeader
            baseValue="numberOfUses"
            sortValue={filter.orderBy}
            onSort={onSort}
          >
            {translations.used}
          </SortableHeader>

          <th></th>
        </tr>
      </thead>

      <tbody>
        {forms?.map(form => {
          const menuItems = getFormMenuItems(form)
          const updated = format(new Date(form.updatedAt), 'dd. MMM yyyy')
          const lastResponse = !form.lastResponseAt
            ? '-'
            : format(new Date(form.lastResponseAt), 'dd. MMM yyyy HH:mm')

          return (
            <tr key={form.id}>
              <td>{form.name}</td>

              {!isMobileOnly && (
                <>
                  <td>{updated}</td>

                  <td>{lastResponse}</td>
                </>
              )}

              <td width="1px" className="center">
                <span>{canViewOthers ? form.filledOutFormsCount : form.myResponsesCount}</span>
              </td>

              <TableMenu items={menuItems} loading={loading}></TableMenu>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}
