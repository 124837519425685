import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Checkbox, Input } from 'components'
import uniqueId from 'lodash/uniqueId'
import { NewChecklistItemPrefix } from 'modules/companies/CompanySettings/components/consts'
import { Icon } from '@ur/react-components'

const HorizontalFlex = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0;

  div:first-child {
    margin-right: 1rem;
  }
`

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 0 1rem;
`

const VerticalFlex = styled.div`
  display: flex;
  flex-direction: column;
  ${HorizontalFlex} {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
    &:first-child {
      margin-top: 0.25rem;
    }
  }
`

const ChecklistWrapper = styled.div`
  margin-bottom: 1rem;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 1rem 0;
`

interface EditChecklistTemplateItemsProps {
  templateItems: { [x: string]: string }
  onChange: (templateItemId: string, text: string) => void
  onRemove: (templateItemId: string) => void
  lastElement: React.RefObject<HTMLInputElement>
  errors: string | null
}

export const EditChecklistTemplateItems: React.FC<EditChecklistTemplateItemsProps> =
  ({ templateItems, onChange, onRemove, lastElement, errors }) => {
    // Hook generates new template item (for the create prompt)
    // if no template exists in the initial data
    useEffect(() => {
      if (Object.keys(templateItems).length < 1)
        onChange(uniqueId(NewChecklistItemPrefix), '')
    }, [onChange, templateItems])

    return (
      <ChecklistWrapper>
        <VerticalFlex>
          {Object.keys(templateItems).map((key, _, allKeys) => {
            const isLastElement = key === allKeys[allKeys.length - 1]
            return (
              <HorizontalFlex key={key} id="checklist-container">
                <Checkbox
                  boxBackground="quaternary"
                  checked={false}
                  disabled
                  onChange={() => {}}
                  tabIndex={-1}
                />
                <Input
                  fullWidth
                  error={errors}
                  value={templateItems[key]}
                  onChange={value => {
                    onChange(key, value)
                  }}
                  ref={isLastElement ? lastElement : null}
                />
                <IconWrapper>
                  {!isLastElement && (
                    <Icon
                      icon="times"
                      cursor="pointer"
                      color="gray6"
                      hoverColor="red"
                      onClick={() => onRemove(key)}
                    />
                  )}
                </IconWrapper>
              </HorizontalFlex>
            )
          })}
        </VerticalFlex>
      </ChecklistWrapper>
    )
  }
