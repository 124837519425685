import { Button } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { Title } from 'components/Title'
import styled from 'styled-components'

interface ErrorFallbackProps {
  error: Error
  resetErrorBoundary: () => void
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 1rem;
`

export const ErrorFallback: React.VFC<ErrorFallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  const translations = useTranslate({
    title: 'error.generic-title',
    button: 'error.try-again',
  })
  return (
    <Wrapper role="alert">
      <Title.H2>{translations.title}:</Title.H2>
      <p>{error.message}</p>
      <Button onClick={resetErrorBoundary}>{translations.button}</Button>
    </Wrapper>
  )
}
