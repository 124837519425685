import { Icon, PromptResolve, usePrompt } from '@ur/react-components'
import { UseFormReturn, useTranslate } from '@ur/react-hooks'
import { TimePicker as BaseTimePicker } from 'components/TimePicker'
import { Input, NumberInput } from 'components/Input'
import { Select, SelectMultiple } from 'components/Select'
import { Card as BaseCard } from 'components/Card'
import clamp from 'lodash/clamp'
import {
  SalaryModuleConfigNode,
  SalaryType,
  SalaryWorkTimeNode,
} from 'modules/companies/types.graphql'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Field, Form } from '..'
import { useCompany, useConfirm } from 'util/hooks'
import { Checkbox } from 'components/Checkbox'
import { isMobileOnly } from 'react-device-detect'
import { Button } from 'components/Button'
import { FAIcon } from 'components/icons'
import {
  OvertimePeriodForm,
  PeriodAddonForm,
  PeriodPrompt,
} from './PeriodAddonPrompt'
import uniqueId from 'lodash/uniqueId'
import { Module } from 'modules/companies/consts'
import { SalaryCodeSelect } from 'components/Select/SalaryCodeSelect'
import { DepartmentSelect } from 'components/Select/DepartmentSelect'
import { SelectMultipleDepartments } from 'components/Select/SelectMultipleDepartments'
import { PeriodAddonView } from './PeriodAddonView'


const Card = styled(BaseCard)`
  margin-bottom: 1rem;
`

const TimePicker = styled(BaseTimePicker)`
  .--time-picker-inner-wrapper {
    justify-content: flex-start;

    input:last-of-type {
      width: 100%;
    }
  }
`
const PeriodField = styled(Field)`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto auto;
  column-gap: 1rem;

  label {
    grid-column: 1 / -1;
  }
  i {
    align-self: center;
  }

  ${props => props.theme.media.mobile} {
    gap: 0.5rem;
  }
`

const SpecialLabel = styled.label`
  border-left: 3px solid ${props => props.theme.colors.primary};
  padding-left: 0.5rem;
`

export interface SalarySettingsForm {
  userTypes: string[]
  userId: string
  dailyWorkLimit: number
  periodWorkLimit: number
  pauseDuration: Date
  pauseThreshold: Date
  countPauseInTotal: boolean
  paidPauseThreshold: Date
  salaryDateStart: number
  salaryDateEnd: number
  name: string
  usePeriodOvertime: boolean
  useSalaryDayPayment: boolean
  groupSalaryOnVehicle: boolean

  salaryType: keyof typeof SalaryType
  defaultSalaryCode: string | null
  salaryDayCode: string | null
  dayOvertimeSalaryCode: string | null
  useWeekOvertime: boolean
  weekOvertimeSalaryCode: string | null
  periodAddons: PeriodAddonForm[]
  overtimePeriods: OvertimePeriodForm[]

  defaultDepartment: string | null
  availableDepartments: string[]
}

interface SalarySettingsFormProps {
  form: UseFormReturn<SalarySettingsForm>
  config: SalaryModuleConfigNode
  salaryWorkTime?: SalaryWorkTimeNode

  onSubmit: PromptResolve<SalarySettingsForm | null>
}

export const SalarySettingsFormView: React.VFC<SalarySettingsFormProps> = ({
  form,
  config,
  onSubmit,
}) => {
  const {
    formValues,
    formErrors: errors,
    formChangeHandler: handler,
    updateForm,
    formValid
  } = form

  const addPrompt = usePrompt()
  const addConfirm = useConfirm()
  const translations = useTranslate({

    pause: 'common.pause',
    overtime: 'common.overtime',
    dataDistribution: 'common.data-distribution',
    salaryCode: 'common.salary-code',
    salaryCodeDay: 'settings.salary-code-day',

    editSalarySettings: ['settings.edit-salary-settings-type', { module: '' }],
    createSalarySettings: ['settings.create-salary-settings', { module: '' }],
    countPauseInTotal: 'settings.timesheets.count-pause-as-part-of-total-work',

    useWeekOvertime: 'settings.use-week-overtime',

    dailyWorkLimit: 'settings.daily-work-limit',
    periodWorkLimit: 'settings.period-work-limit',
    pauseDuration: 'settings.pause-duration',
    pauseThreshold: 'settings.pause-threshold',
    paidPauseThreshold: 'settings.paid-pause-threshold',
    salaryDateStart: 'settings.salary-start-date',
    salaryDateEnd: 'settings.salary-end-date',
    salaryPeriod: 'settings.salary-period',
    name: 'common.name',
    groupSalaryOnVehicle: 'settings.group-salary-on-vehicle',
    usePeriodAddons: 'settings.use-period-addons',
    overtimePeriods: 'settings.ovetime-periods',
    useSalaryDayPayment: 'settings.use-salary-day-payment',

    defaultSalaryCode: 'settings.default-salary-code',
    daySalaryCode: 'settings.day-salary-code',
    weekSalaryCode: 'settings.week-salary-code',

    deleteOvertimePeriod: 'settings.delete-overtime-period',
    doYouWantToDeleteOvertimePeriod:
      'settings.do-you-want-to-delete-overtime-period',
    deletePeriodAddon: 'settings.delete-period-addon',
    doYouWantToDeletePeriodAddon: 'settings.do-you-want-to-delete-period-addon',

    delete: 'common.delete-alt',
    save: 'common.save',
    add: 'common.add',

    userTypes: 'common.user-types',

    activities: 'common.activities',
    terminal: 'common.terminal',
    timesheets: 'common.timesheets',

    fixedSalary: 'settings.fixed-salary',
    hourlySalary: 'settings.hourly-salary',
    dailySalary: 'settings.daily-salary',

    salaryType: 'settings.salary-type',
    standardSettings: 'settings.standard-settings',
    salaryDayOtherSalaryCodeWarning: 'settings.salary-day-other-salary-code-warning',
    weekOvertimeDelayedOvertimeWarning: 'settings.week-overtime-delayed-overtime-warning',
    departments: 'common.departments',
    defaultDepartment: 'settings.default-department',
    availableDepartments: 'settings.available-departments',
    
    validation: {
      driverPause: 'settings.validation.salary-driver-pause-length',
      invalid: 'common.invalid',
    },
  })
  const company = useCompany()
  const companyUserTypes = company?.userTypes ?? []

  function updateDates() {
    return (salaryDateStart: number) => {
      const salaryDateEnd = clamp(
        salaryDateStart === 1 ? 31 : salaryDateStart - 1,
        1,
        31
      )
      updateForm({
        salaryDateStart,
        salaryDateEnd,
      })
    }
  }
  const selectOptions =
    companyUserTypes.map(userType => ({
      value: userType.id,
      label: userType.name,
    })) ?? []

  async function addPeriodAddon(field: "periodAddons" | "overtimePeriods" = "periodAddons") {
    const { data } = await addPrompt<PeriodAddonForm | OvertimePeriodForm | null>(resolve => (
      <PeriodPrompt
        period={null}
        onSubmit={resolve}
        existingPeriods={form.formValues[field]}
        field={field}
      />
    ))
    if (!data) return
    updateForm({
      [field]: [
        ...form.formValues[field],
        {
          id: uniqueId('overtime-period-'),
          periodStart: data.periodStart,
          periodEnd: data.periodEnd,
          salaryCode: data.salaryCode,
          salaryCodeName: data.salaryCodeName,
          monday: data.monday,
          tuesday: data.tuesday,
          wednesday: data.wednesday,
          thursday: data.thursday,
          friday: data.friday,
          saturday: data.saturday,
          sunday: data.sunday,
        },
      ],
    })
  }

  async function editPeriodAddon(periodAddon: PeriodAddonForm | OvertimePeriodForm, field: "periodAddons" | "overtimePeriods" = "periodAddons") {
    const { data } = await addPrompt<PeriodAddonForm | null>(resolve => (
      <PeriodPrompt
        period={periodAddon}
        onSubmit={resolve}
        existingPeriods={form.formValues[field]}
        field={field}
      />
    ))
    if (!data) return
    updateForm({
      [field]: form.formValues[field].map(period =>
        period.id === periodAddon.id ? data : period
      ),
    })
  }

  async function deletePeriodAddon(periodAddon: PeriodAddonForm | OvertimePeriodForm, field: "periodAddons" | "overtimePeriods" = "periodAddons") {
    const { data } = field === "periodAddons" ? await addConfirm(
      translations.doYouWantToDeletePeriodAddon,
      translations.deletePeriodAddon,
      {
        confirmText: translations.delete,
      }
    ) : await addConfirm(
      translations.doYouWantToDeleteOvertimePeriod,
      translations.deleteOvertimePeriod,
      {
        confirmText: translations.delete,
      }
    )
    if (!data) return
    updateForm({
      [field]: form.formValues[field].filter(
        period => period.id !== periodAddon.id
      ),
    })
  }

  useEffect(() => {
    if (
      !(config.name === Module.TIMESHEETS || config.name === Module.ACTIVITIES) &&
      (formValues.usePeriodOvertime || formValues.periodAddons.length > 0)
    )
      updateForm({ usePeriodOvertime: false, periodAddons: [] })
  }, [
    config,
    formValues.periodAddons.length,
    formValues.usePeriodOvertime,
    updateForm,
  ])

  const salaryTypeOptions = config.name === Module.ACTIVITIES ? [
    {
      value: SalaryType.FIXED,
      label: translations.fixedSalary,
    },
    {
      value: SalaryType.HOUR,
      label: translations.hourlySalary,
    },
    {
      value: SalaryType.DAY,
      label: translations.dailySalary,
    },
  ] : [
      {
        value: SalaryType.FIXED,
        label: translations.fixedSalary,
      },
      {
        value: SalaryType.HOUR,
        label: translations.hourlySalary,
      }
  ]

  return (
    <Form
      buttons={[
        {
          text: translations.save,
          buttonProps: {disabled: !formValid},
          onClick: () => onSubmit(formValues),
        },
      ]}
    >
      <Card>
      <h3>{translations.standardSettings}</h3>
      <Field>
        <label>{translations.name}</label>
        <Input
          fullWidth
          placeholder={translations.name}
          value={formValues.name}
          onChange={handler('name')}
        />
      </Field>
      {!formValues.userId && (
        <Field>
          <label>{translations.userTypes}</label>
          <SelectMultiple
            fullWidth
            selected={formValues.userTypes}
            options={selectOptions}
            onChange={handler('userTypes')}
          />
        </Field>
      )}

      <PeriodField>
        <label>{translations.salaryPeriod}</label>

        <NumberInput
          value={formValues.salaryDateStart}
          min={1}
          max={31}
          onChange={updateDates()}
        />

        <Icon icon="arrow-right" color="gray6" size="1.2rem" />

        <NumberInput
          value={formValues.salaryDateEnd}
          error={errors.salaryDateStart}
          disabled
          min={1}
          max={31}
          color="gray6"
          onChange={() => void 0}
        />
      </PeriodField>
      <Field>
        <label>{translations.salaryType}</label>
        <Select
          fullWidth
          value={formValues.salaryType}
          options={salaryTypeOptions}
          onChange={(value) => handler('salaryType')(value as keyof typeof SalaryType)}
        />
      </Field>
      {formValues.salaryType === SalaryType.DAY && (
        <Field>
          <label>{translations.salaryCodeDay}</label>
          <SalaryCodeSelect
            error={errors.salaryDayCode}
            fullWidth
            nullable
            value={formValues.salaryDayCode}
            onChange={handler('salaryDayCode')}
          />
        </Field>
      )}
      <Field>
        <label>{translations.defaultSalaryCode}</label>
        <SalaryCodeSelect
          error={errors.defaultSalaryCode}
          fullWidth
          nullable
          value={formValues.defaultSalaryCode}
          onChange={handler('defaultSalaryCode')}
        />
      </Field>
      {formValues.salaryType === SalaryType.DAY && (
        <Field>
          <SpecialLabel>{translations.salaryDayOtherSalaryCodeWarning}</SpecialLabel>
        </Field>
      )}

      {(config.name === Module.TIMESHEETS || config.name === Module.ACTIVITIES) && (
        <>
        <h3>{translations.departments}</h3>
        <Field>
          <label>{translations.defaultDepartment}</label>
          <DepartmentSelect
          value={formValues.defaultDepartment} 
          onChange={handler('defaultDepartment')}
          width="100%" 
          />
        </Field>
        {(config.name === Module.TIMESHEETS) && (
          <Field>
            <label>{translations.availableDepartments}</label>
            <span>
              <SelectMultipleDepartments
                selected={formValues.availableDepartments}
                onChange={handler('availableDepartments')}
                width="100%"
                grayed={false}
              />
            </span>
          </Field>
        )}
      </>
      )}

      </Card>
      <Card>
        <h3>{translations.daySalaryCode}</h3>
        <Field>
          <label>{translations.dailyWorkLimit}</label>
          <NumberInput
            value={formValues.dailyWorkLimit}
            min={0}
            max={24}
            onChange={handler('dailyWorkLimit')}
          />
        </Field>

        <Field>
          <label>{translations.defaultSalaryCode}</label>
          <SalaryCodeSelect
            error={errors.dayOvertimeSalaryCode}
            fullWidth
            nullable
            value={formValues.dayOvertimeSalaryCode}
            onChange={handler('dayOvertimeSalaryCode')}
          />
        </Field>
        {/*
        <Field>
          <label>{translations.overtimePeriods}</label>
          {formValues.overtimePeriods.map((overtimePeriod, index) => (
            <PeriodAddonView
              periodAddon={overtimePeriod}
              onEdit={() => editPeriodAddon(overtimePeriod, "overtimePeriods")}
              onDelete={() => deletePeriodAddon(overtimePeriod, "overtimePeriods")}
              key={index}
            />
          ))}
          {isMobileOnly ? (
            <Button
              height="32px"
              padding="0 1rem"
              onClick={() => addPeriodAddon("overtimePeriods")}
            >
              <FAIcon icon="plus" size="0.8rem" />
            </Button>
          ) : (
            <Button height="48px" onClick={() => addPeriodAddon("overtimePeriods")}>
              {translations.add}
            </Button>
          )}
        </Field>
        */}
      </Card>
      <Card>
        <h3>{translations.weekSalaryCode}</h3>
        <Field>
          <label>{translations.useWeekOvertime}</label>
          <span>
            <Checkbox checked={formValues.useWeekOvertime} onChange={handler('useWeekOvertime')} />
          </span>
        </Field>
        {formValues.useWeekOvertime && (
          <>
            <Field>
              <label>{translations.periodWorkLimit}</label>
              <NumberInput
                value={formValues.periodWorkLimit}
                min={0}
                max={24 * 7}
                onChange={handler('periodWorkLimit')}
              />
            </Field>
            <Field>
              <label>{translations.salaryCode}</label>
              <SalaryCodeSelect
                error={errors.weekOvertimeSalaryCode}
                fullWidth
                nullable
                value={formValues.weekOvertimeSalaryCode}
                onChange={handler('weekOvertimeSalaryCode')}
              />
            </Field>
          </>
        )}
        <Field>
          <SpecialLabel>{translations.weekOvertimeDelayedOvertimeWarning}</SpecialLabel>
        </Field>
      </Card>
      <Card>
        <h3>{translations.pause}</h3>
        <Field>
          <label>{translations.pauseDuration}</label>
          <TimePicker
            value={formValues.pauseDuration}
            error={errors.pauseDuration}
            width="100%"
            onChange={handler('pauseDuration')}
          />
        </Field>

        <Field>
          <label>{translations.pauseThreshold}</label>
          <TimePicker
            value={formValues.pauseThreshold}
            width="100%"
            onChange={handler('pauseThreshold')}
          />
        </Field>

        {(config.name === Module.TIMESHEETS) && (
          <Field>
            <label>{translations.countPauseInTotal}</label>
            <span>
              <Checkbox
                checked={formValues.countPauseInTotal}
                onChange={handler('countPauseInTotal')}
              />
            </span>
          </Field>
        )}

        {(config.name === Module.ACTIVITIES) && (
          <Field>
            <label>{translations.paidPauseThreshold}</label>
            <TimePicker
              value={formValues.paidPauseThreshold}
              width="100%"
              onChange={handler('paidPauseThreshold')}
            />
          </Field>
        )}
      </Card>
      
      {(config.name === Module.TIMESHEETS || config.name === Module.ACTIVITIES) && (
        <Card>
          <h3>{translations.dataDistribution}</h3>
          {(config.name === Module.ACTIVITIES) && (
            <Field>
              <label>{translations.groupSalaryOnVehicle}</label>
              <span>
                <Checkbox
                  checked={formValues.groupSalaryOnVehicle}
                  onChange={handler('groupSalaryOnVehicle')}
                />
              </span>
            </Field>
          )}
          <Field>
            <label>{translations.usePeriodAddons}</label>
            <span>
              <Checkbox
                checked={formValues.usePeriodOvertime}
                onChange={handler('usePeriodOvertime')}
              />
            </span>
          </Field> 
          {formValues.usePeriodOvertime && (
            <Field>
              {formValues.periodAddons.map((periodAddon, index) => (
                <PeriodAddonView
                  periodAddon={periodAddon}
                  onEdit={() => editPeriodAddon(periodAddon)}
                  onDelete={() => deletePeriodAddon(periodAddon)}
                  key={index}
                />
              ))}
              {isMobileOnly ? (
                <Button
                  height="32px"
                  padding="0 1rem"
                  onClick={() => addPeriodAddon()}
                >
                  <FAIcon icon="plus" size="0.8rem" />
                </Button>
              ) : (
                <Button height="48px" onClick={() => addPeriodAddon()}>
                  {translations.add}
                </Button>
              )}
            </Field>
          )}
        </Card>
      )}
    </Form>
  )
}
