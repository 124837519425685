import { Icon, usePrompt } from '@ur/react-components'
import { Button, Cell, CellProps } from 'components'
import { ActivitiesDynamicColumnDayReport } from 'modules/activities/types.graphql'
import { MouseEventHandler } from 'react'
import { DriverDietForm, DriverDietPrompt } from './DriverDietPrompt'
import { useDietDayMutations } from 'modules/activities/mutations.hooks'
import { useParams } from 'react-router'
import { parseISO } from 'date-fns'
import { format } from 'util/date-fns'
import { usePermissions } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'


interface DietProps {
  report: Pick<ActivitiesDynamicColumnDayReport, 'diet' | 'date'>,
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLDivElement>
  canEdit?: boolean | null
}
export const Diet: React.FC<DietProps> = ({
  report,
  canEdit,
  ...props
}) => {
  if (report.diet && report.diet.salaryCode){
    return <div onClick={(evt) => props.onClick?.(evt)}>{report.diet.salaryCode.name}</div>
  }
  if (canEdit) return (
    <Button 
      padding="0.25rem 0.75rem !important"
      height="auto"
      onClick={(evt) => {
        evt.stopPropagation()
        props.onClick?.(evt)
      }}
    >
      <Icon 
        icon="plus" 
        margin="0" 
      />
    </Button>
  )

  return <div onClick={(evt) => props.onClick?.(evt)}></div>

}
interface DietCellProps extends DietProps, CellProps {
  subColumn: boolean
  onClick?: () => void
}
export const DietCell: React.VFC<DietCellProps> = ({
  narrow = true,
  centered = true,
  subColumn = false,
  report,
  ...dietCellProps
}) => {
  const addPrompt = usePrompt()
  const { userId } = useParams<{ userId: string }>()
  const { hasPermissions, hasPermissionsAndMe } = usePermissions()
  const mutations = useDietDayMutations()

  if (subColumn) return <td></td>

  const canEdit = hasPermissions(PERMISSIONS.activities.change.driveractivity) || ((hasPermissionsAndMe({id: userId}, PERMISSIONS.activities.approve.driveractivity)))

  async function handleAddDriverDiet() {
    const { data } = await addPrompt<DriverDietForm | null>(resolve => (
      <DriverDietPrompt
        initialData={{
          userId: userId,
          salaryCodeId: report.diet?.salaryCode?.id ?? null,
        }}
        onSubmit={resolve}
      />
    ))
    if (data === null) return

    mutations.updateOrCreateDriverDiet({
      variables: {
        input: {
          ...data,
          date: format(parseISO(report.date), 'yyyy-MM-dd'),
        }
      },
    })
  }

  const handleClick: MouseEventHandler<HTMLTableCellElement> = (report.diet && report.diet.salaryCode && canEdit)
    ? (event) => {
      event.stopPropagation()
      handleAddDriverDiet()
    }
    : (_event) => void 0

  return (
    <Cell onClick={handleClick} narrow={narrow} centered={centered}>
      <Diet report={report} onClick={handleAddDriverDiet} {...dietCellProps} canEdit={canEdit} />
    </Cell>
    )
  }
