import { Button as BaseButton } from 'components'
import styled from 'styled-components'
import { DynamicColumnNode } from 'modules/dynamic_tables'
import { AddDynamicColumnPrompt } from './AddDynamicColumnPrompt'
import { DynamicColumnSelectProps } from './types'
import { usePrompt, useToast } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'

const Button = styled(BaseButton)`
  ${props => props.theme.media.mobile} {
    padding: 0 3rem 0 1rem;
  }
`
const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const AddDynamicColumnButton: React.VFC<DynamicColumnSelectProps> = ({
  columns,
  forExport,
  onToggle = () => void 0,
}) => {
  const translations = useTranslate({
    addColumn: 'common.add-column',
    addSuccess: 'settings.toasts.add-column-success',
  })
  const addPrompt = usePrompt()
  const addToast = useToast()

  async function handleAdd() {
    const { data } = await addPrompt<DynamicColumnNode | null>(resolve => (
      <AddDynamicColumnPrompt
        columns={columns}
        forExport={forExport}
        onSubmit={resolve}
      />
    ))
    if (data === null) return
    onToggle(data.id, true)
    addToast('success', translations.addSuccess)
  }

  return (
    <Wrapper>
      <Button iconLeftProps={{ icon: 'plus', margin: '0' }} onClick={handleAdd}>
        {translations.addColumn}
      </Button>
    </Wrapper>
  )
}
