import {
  Icon,
  IconProps,
  Toggle as BaseToggle,
  ToggleProps as BaseToggleProps,
} from '@ur/react-components'
import { SVGIcon, SVGIconProps } from 'components/icons'
import React from 'react'
import styled, { css } from 'styled-components'
import { overloadColor } from 'util/style'

const Wrapper = styled.div`
  position: relative;
  width: fit-content;
`
interface IconWrapperProps {
  side: 'left' | 'right'
}
const IconWrapper = styled.div<IconWrapperProps>`
  position: absolute;
  top: 50%;
  left: ${props => (props.side === 'left' ? '25%' : '75%')};
  z-index: 1;
  transform: translate(-50%, -50%);
  pointer-events: none;
  svg {
    transform: translate(2px, 2px);
  }
`
interface StyledToggleProps {
  thumbBackground: string
}
const StyledToggle = styled(BaseToggle)<StyledToggleProps>`
  height: ${props => props.height || '3.875rem'};
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};

  background-color: ${props =>
    props.background || props.theme.colors.quaternary};
  box-shadow: none;
  border: 1px solid ${props => props.theme.colors.gray9};

  .--toggle-thumb {
    border-radius: 10px;
    box-shadow: none;
    background-color: ${props => overloadColor(props.thumbBackground)};
  }

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.secondary};
    box-shadow: none;
  }

  ${props =>
    props.disabled &&
    css`
      .--toggle-thumb {
        background-color: ${props => props.theme.colors.gray6};
      }
      &:focus {
        border-color: ${props => props.theme.colors.gray9};
      }
    `}
`
interface IconTextWrapperProps {
  defaultColor?: string
  iconProps?: Omit<IconProps, 'icon'>
}
const IconTextWrapper = styled.div<IconTextWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 0.7rem;
  color: ${props =>
    props?.iconProps?.color
      ? props?.iconProps?.color
      : props?.defaultColor ?? 'rgba(0, 0, 0, 1)'};
`

export type ToggleIconProps = Omit<
  IconProps,
  'fixedWidth' | 'hoverColor' | 'cursor'
>

export enum IconMode {
  default = 'default',
  svg = 'svg',
  text = 'text',
  textAndIcon = 'textAndIcon',
}

interface ToggleProps extends BaseToggleProps {
  iconMode?: keyof typeof IconMode

  offIconProps?: ToggleIconProps
  onIconProps?: ToggleIconProps

  localOffIconProps?: SVGIconProps
  localOnIconProps?: SVGIconProps

  leftText?: string
  rightText?: string

  thumbBackground?: string
}

export const Toggle: React.VFC<ToggleProps> = ({
  iconMode = 'default',

  offIconProps,
  onIconProps,

  localOffIconProps,
  localOnIconProps,

  leftText,
  rightText,

  width,
  height,

  thumbBackground = 'rgba(0, 0, 0, 0.12)',

  ...toggleProps
}) => {
  const leftContent = (() => {
    switch (iconMode) {
      case IconMode.svg:
        return (
          localOffIconProps && (
            <SVGIcon
              color="rgba(0, 0, 0, 0.5)"
              width="28px"
              {...localOffIconProps}
            />
          )
        )
      case IconMode.text:
        return leftText ?? 'off'
      case IconMode.textAndIcon:
        return (
          offIconProps && (
            <IconTextWrapper
              iconProps={offIconProps}
              defaultColor="rgba(0, 0, 0, 1)"
            >
              {leftText}
              <Icon
                color="rgba(0, 0, 0, 0.5)"
                translateY="2px"
                {...offIconProps}
                fixedWidth
                type="light"
              />
            </IconTextWrapper>
          )
        )
      default:
        return (
          offIconProps && (
            <Icon
              color="rgba(0, 0, 0, 0.5)"
              translateX="2px"
              translateY="2px"
              {...offIconProps}
              fixedWidth
              type="light"
            />
          )
        )
    }
  })()

  const rightContent = (() => {
    switch (iconMode) {
      case IconMode.svg:
        return (
          localOnIconProps && (
            <SVGIcon
              color="rgba(0, 0, 0, 0.5)"
              width="28px"
              {...localOnIconProps}
            />
          )
        )
      case IconMode.text:
        return rightText ?? 'on'
      case IconMode.textAndIcon:
        return (
          onIconProps && (
            <IconTextWrapper
              iconProps={onIconProps}
              defaultColor="rgba(0, 0, 0, 1)"
            >
              {rightText}
              <Icon
                color="rgba(0, 0, 0, 0.5)"
                translateY="2px"
                {...onIconProps}
                fixedWidth
                type="light"
              />
            </IconTextWrapper>
          )
        )
      default:
        return (
          onIconProps && (
            <Icon
              color="rgba(0, 0, 0, 0.5)"
              translateX="2px"
              translateY="2px"
              {...onIconProps}
              fixedWidth
              type="light"
            />
          )
        )
    }
  })()

  return (
    <Wrapper>
      <IconWrapper side="left">{leftContent}</IconWrapper>
      <IconWrapper side="right">{rightContent}</IconWrapper>

      <StyledToggle
        thumbBackground={thumbBackground}
        width={width ?? 144}
        height={height}
        {...toggleProps}
      />
    </Wrapper>
  )
}
