import { gql } from '@apollo/client'

export const PATCH_CHECKLIST_MUTATION = gql`
  mutation PatchChecklistMutation($id: ID!, $input: PatchChecklistInput!) {
    patchChecklist(id: $id, input: $input) {
      checklist {
        title
        items {
          id
          done
        }
      }
    }
  }
`

export const CREATE_CHECKLIST_TEMPLATE_MUTATION = gql`
  mutation CreateChecklistTemplateMutation(
    $input: CreateChecklistTemplateInput!
  ) {
    createChecklistTemplate(checklistTemplateInput: $input) {
      ok
    }
  }
`

export const DELETE_CHECKLIST_TEMPLATE_MUTATION = gql`
  mutation DeleteChecklistTemplateMutation($id: ID!) {
    deleteChecklistTemplate(id: $id) {
      found
    }
  }
`

export const PATCH_CHECKLIST_TEMPLATE_MUTATION = gql`
  mutation PatchChecklistTemplateMutation(
    $id: ID!
    $input: PatchChecklistTemplateInput!
  ) {
    patchChecklistTemplate(id: $id, input: $input) {
      ok
    }
  }
`

export const BATCH_CHANGE_CHECKLIST_ITEMS = gql`
  mutation BatchChangeChecklistItemsMutation(
    $updatedChecklistItems: [ChangeChecklistItemInput]!
  ) {
    batchChangeChecklistItems(updatedChecklistItems: $updatedChecklistItems) {
      updatedChecklists {
        id
        title
        items {
          id
          done
          text
        }
      }
    }
  }
`
