import React from 'react'
import {
  startOfYear,
  endOfYear,
  eachMonthOfInterval,
  subYears,
  addYears,
  isSameMonth,
  isWithinInterval,
} from 'date-fns'
import { format } from 'util/date-fns'
import styled from 'styled-components'
import { FAIcon } from 'components/icons'
import { MonthRangeCellProps } from './types'
import { ZIndexRange } from 'types/style'

const YearDropdown = styled.div<{ grayed: boolean }>`
  position: absolute;
  background: ${props =>
    props.grayed ? props.theme.colors.quaternary : props.theme.colors.white};
  display: grid;
  grid-template-areas:
    'prevYear downOneLevel nextYear'
    'body body body';
  grid-template-columns: 35px 4fr 35px;
  grid-template-rows: 35px auto;
  top: 48px;
  padding: 0.25rem;
  width: 100%;
  box-shadow: ${props => props.theme.shadow.bottom};
  border-left: 1px solid ${props => props.theme.colors.gray9};
  border-right: 1px solid ${props => props.theme.colors.gray9};
  border-radius: 0 0 12px 12px;
  z-index: ${ZIndexRange.Dropdowns};
  user-select: none;
`

const PrevYear = styled.div`
  grid-area: prevYear;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  :hover {
    color: white;
    background: ${props => props.theme.colors.primary};
  }
`

const DownOneLevel = styled.div`
  grid-area: downOneLevel;
  justify-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bold;

  :hover {
    color: white;
    background: ${props => props.theme.colors.primary};
  }
`

const NextYear = styled.div`
  grid-area: nextYear;
  justify-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  :hover {
    color: white;
    background: ${props => props.theme.colors.primary};
  }
`

const YearBody = styled.div`
  margin-top: 0.5rem;
  display: grid;
  grid-area: body;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 45px;
`

const DateCell = styled.div<MonthRangeCellProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 2px;

  border-radius: 2px;

  color: ${props =>
    props.endMonth
      ? props.theme.colors.white
      : props.selected
      ? props.theme.colors.black
      : 'inherit'};

  background: ${props =>
    props.endMonth
      ? props.theme.colors.primary
      : props.selected
      ? props.theme.colors.lightPrimary
      : null};

  :hover {
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
  }

  ${props =>
    props.disabled &&
    `
    background-color: ${props.theme.colors.gray8};
    :hover {
      background-color: ${props.theme.colors.gray8};
      color: inherit;
    }
    opacity: 0.2;
  `}
`

interface DatePickerYearViewProps {
  dateRange: [Date, Date]
  yearPivot: Date
  setYearPivot: (newYearPivot: Date) => void
  setMonthPivot: (newMonthPivot: Date) => void
  isDisabled: (date: Date) => boolean
  onChangeGranularity: (
    event?: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void
  grayed: boolean
}

export const DateRangePickerYearView: React.FC<DatePickerYearViewProps> = ({
  dateRange,
  yearPivot,
  setYearPivot,
  setMonthPivot,

  isDisabled,

  onChangeGranularity,
  grayed,
}) => {
  const firstMonth = startOfYear(yearPivot)
  const lastMonth = endOfYear(yearPivot)

  const allMonths = eachMonthOfInterval({
    start: firstMonth,
    end: lastMonth,
  })

  const handleClick = (date: Date): void => {
    setMonthPivot(date)
    onChangeGranularity()
  }

  const handleDownOneLevelClick = () => {
    onChangeGranularity()
  }

  const isEndMonth = (date: Date) =>
    isSameMonth(date, dateRange[0]) || isSameMonth(date, dateRange[1])

  const inInterval = (date: Date) =>
    isWithinInterval(date, {
      start: dateRange[0],
      end: dateRange[1],
    })

  return (
    <YearDropdown grayed={grayed}>
      <PrevYear onClick={() => setYearPivot(subYears(yearPivot, 1))}>
        <FAIcon icon="chevron-left" type="solid" />
      </PrevYear>
      <DownOneLevel onClick={handleDownOneLevelClick}>
        {format(yearPivot, 'yyyy')}
      </DownOneLevel>
      <NextYear
        onClick={() => {
          setYearPivot(addYears(yearPivot, 1))
        }}
      >
        <FAIcon icon="chevron-right" type="solid" />
      </NextYear>
      <YearBody>
        {allMonths.map(date => {
          return (
            <DateCell
              selected={inInterval(date)}
              endMonth={isEndMonth(date)}
              key={format(date, 'yyyy-MM-dd')}
              onClick={() => handleClick(date)}
              disabled={isDisabled(date)}
            >
              {format(date, 'MMM')}
            </DateCell>
          )
        })}
      </YearBody>
    </YearDropdown>
  )
}
