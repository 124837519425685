export enum DynamicColumnType {
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  DATETIME_LOCATION = 'DATETIME_LOCATION',
  PLATE_NUMBER = 'PLATE_NUMBER',
  STRING = 'STRING',
  TIME = 'TIME',
  CHECKMARK = 'CHECKMARK',
  STATUS = 'STATUS',
}
