/* eslint-disable no-template-curly-in-string */
import { format } from 'util/date-fns'
import {
  NormalizedRoutePlanNoticeDetail,
  NormalizedRoutePlanNoticeNode,
  RoutePlanIncidentNode,
} from './types.graphql'
export function loadExternalScript(
  src: string,
  scriptId: string,
  callback?: () => void
) {
  /**
   * Accepts a link to an external scripts and appends it to the DOM.
   * @param src The link to the external script.
   * @param scriptId The id of the script. Has to be unique.
   * @param callback A callback function that is called when the script is loaded.
   */
  const existingScript = document.getElementById(scriptId)

  if (!existingScript) {
    const script = document.createElement('script')
    script.src = src
    script.type = 'text/javascript'

    // https://reactjs.org/docs/cross-origin-errors.html
    if (process.env.NODE_ENV !== 'production') {
      script.setAttribute('crossorigin', 'anonymous')
    }
    script.setAttribute('defer', '')
    script.id = scriptId
    document.head.appendChild(script)

    script.onload = () => {
      if (callback) callback()
    }
  }
  if (existingScript && callback) callback()
}

export function parseNoticeDetailsToHTMLString(
  detail: NormalizedRoutePlanNoticeDetail
) {
  // We add div clauses for each existing field value to a string
  // and return it
  let noticeDetails = ''
  if (detail.cause) {
    noticeDetails += `<div><b>Restriksjon</b>: ${detail.cause}</div>`
  }
  if (detail.maxLength) {
    noticeDetails += `<div class="space-between"><span>Max Length:</span> <span>${detail.maxLength}</span></div>`
  }
  if (detail.maxHeight) {
    noticeDetails += `<div><span>Max Height:</span> <span>${detail.maxHeight}</span></div>`
  }
  if (detail.maxWeight) {
    noticeDetails += `<div><span>Max Weight:</span> <span>${detail.maxWeight}</span></div>`
  }
  if (detail.maxWidth) {
    noticeDetails += `<div><span>Max Width:</span> <span>${detail.maxWidth}</span></div>`
  }
  if (detail.axleCount) {
    noticeDetails += `<div><span>Axle Count:</span> <span>${detail.axleCount}</span></div>`
  }
  if (detail.tunnelCategory) {
    noticeDetails += `<div><span>Tunnel Category:</span> <span>${detail.tunnelCategory}</span></div>`
  }
  if (detail.vehicleType) {
    noticeDetails += `<div><span>Vehicle Type:</span> <span>${detail.vehicleType}</span></div>`
  }
  if (detail.trailerCountDescription) {
    noticeDetails += `<div><span>Trailer Count Description:</span> <span>${detail.trailerCountDescription}</span></div>`
  }
  if (detail.trailerCountFrom) {
    noticeDetails += `<div><span>Trailer Count From:</span> <span>${detail.trailerCountFrom}</span></div>`
  }
  if (detail.trailerCountTo) {
    noticeDetails += `<div><span>Trailer Count To:</span> <span>${detail.trailerCountTo}</span></div>`
  }
  return noticeDetails
}

export function parseIncidentDetailsToHTMLString(
  incident: RoutePlanIncidentNode
) {
  return `
    <div><b>Oppsumering</b>: ${incident.summary}</div>
    <div><b>Type</b>: ${incident.type}</div>
    <div><b>Start:</b> ${format(
      new Date(incident.datetimeStart),
      'yy.MM.dd HH:mm'
    )}</div>
    <div><b>Slutt:</b> ${format(
      new Date(incident.datetimeEnd),
      'yy.MM.dd HH:mm'
    )}</div>
    <div><b>Koordinater</b>: ${incident.firstCoordinates.lat}, ${
    incident.firstCoordinates.lng
  }</div>
    `
}

export function addEventListenerToGroup(group: H.map.Group, ui: H.ui.UI) {
  // TODO: Fix proper types on eventhandler
  group.addEventListener(
    'tap',
    function (evt) {
      // @ts-ignore
      const bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
        // @ts-ignore
        content: evt.target.getData(),
      })
      ui.getBubbles().forEach(bub => ui.removeBubble(bub))
      ui.addBubble(bubble)
    },
    false
  )
}

export function createAndAddIncidentMarkersToMap(
  incidents: RoutePlanIncidentNode[],
  HERE: typeof H,
  mapInstance: H.Map,
  uiInstance: H.ui.UI
) {
  const svgMarkupIncident =
    '<svg  width="24" height="24" xmlns="http://www.w3.org/2000/svg">' +
    '<rect stroke="black" fill="${FILL}" x="1" y="1" width="22" height="22" />' +
    '<text x="12" y="18" font-size="12pt" font-family="Arial" font-weight="bold" ' +
    'text-anchor="middle" fill="${STROKE}">I</text></svg>'

  incidents.forEach(incident => {
    const { firstCoordinates } = incident
    const marker = new HERE.map.Marker(
      {
        lat: firstCoordinates.lat,
        lng: firstCoordinates.lng,
      },
      {
        icon: new H.map.Icon(
          svgMarkupIncident
            .replace('${FILL}', 'green')
            .replace('${STROKE}', 'orange')
        ),
      }
    )
    const group = new HERE.map.Group()
    group.addObject(marker)
    mapInstance.addObject(group)
    addEventListenerToGroup(group, uiInstance)
    const incidentDetails = parseIncidentDetailsToHTMLString(incident)

    marker.setData(incidentDetails)
    group.addObject(marker)
  })
}

export function createAndAddNoticeMarkersToMap(
  notices: NormalizedRoutePlanNoticeNode[],
  HERE: typeof H,
  mapInstance: H.Map,
  uiInstance: H.ui.UI
) {
  const svgMarkupNotice =
    '<svg  width="24" height="24" xmlns="http://www.w3.org/2000/svg">' +
    '<rect stroke="black" fill="${FILL}" x="1" y="1" width="22" height="22" />' +
    '<text x="12" y="18" font-size="12pt" font-family="Arial" font-weight="bold" ' +
    'text-anchor="middle" fill="${STROKE}">N</text></svg>'

  notices.forEach(notice => {
    const { coordinates, details } = notice

    const marker = new HERE.map.Marker(
      {
        lat: coordinates.lat,
        lng: coordinates.lng,
      },
      {
        icon: new H.map.Icon(
          svgMarkupNotice
            .replace('${FILL}', 'red')
            .replace('${STROKE}', 'white')
        ),
      }
    )
    const group = new HERE.map.Group()
    mapInstance.addObject(group)
    addEventListenerToGroup(group, uiInstance)
    const concatenatedDetails = details.reduce(
      (acc, detail) => acc + parseNoticeDetailsToHTMLString(detail),
      ''
    )
    const parsedAndConcatenatedDetailsString = `${concatenatedDetails}
    <div><b>Koordinater</b>: <span>${coordinates.lat}, ${coordinates.lng}<span></div>`

    marker.setData(parsedAndConcatenatedDetailsString)
    group.addObject(marker)
  })
}
