import React from 'react'

export const Petrol: React.VFC<React.SVGAttributes<SVGSVGElement>> = props => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 37 37"
    enableBackground="new 0 0 37 37"
    xmlSpace="preserve"
    fill="#003057"
    {...props}
  >
    <path
      className="st0"
      d="M24.221,32.229H1.338c-0.629,0-1.144,0.515-1.144,1.144v2.288c0,0.629,0.515,1.144,1.144,1.144h22.882
	c0.629,0,1.144-0.515,1.144-1.144v-2.288C25.365,32.744,24.85,32.229,24.221,32.229z M35.461,7.867l-5.792-5.792
	c-0.443-0.443-1.173-0.443-1.616,0l-0.808,0.808c-0.443,0.443-0.443,1.173,0,1.616l2.696,2.696v4.441
	c0,2.009,1.494,3.668,3.432,3.947v11.498c0,0.944-0.772,1.716-1.716,1.716s-1.716-0.772-1.716-1.716v-2.288
	c0-3.475-2.817-6.293-6.293-6.293h-0.572V4.771c0-2.524-2.052-4.576-4.576-4.576H7.059c-2.524,0-4.576,2.052-4.576,4.576v25.171
	h20.594v-8.009h0.572c1.58,0,2.86,1.28,2.86,2.86v1.988c0,2.696,1.931,5.149,4.612,5.427c3.075,0.307,5.685-2.109,5.685-5.127
	V11.106C36.806,9.891,36.32,8.725,35.461,7.867L35.461,7.867z M18.5,13.924H7.059V4.771H18.5V13.924z"
    />
  </svg>
)
