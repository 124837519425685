import React from 'react'
import styled from 'styled-components'
import { overloadColorProp } from 'util/style'
import { getIconTypeCode, IconType } from './iconHelpers'

export interface IconProps extends React.DOMAttributes<HTMLElement> {
  icon: string
  type?: IconType
  title?: string
  fixedWidth?: boolean

  display?: string
  className?: string
  size?: string
  hoverColor?: string
  color?: string
  width?: string
  margin?: string

  translateX?: string
  translateY?: string

  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

const BaseIcon: React.FC<IconProps> = ({
  icon,
  className,
  type,
  title,
  fixedWidth,
  onClick,
}) => {
  const iconTypeCode = getIconTypeCode(type)
  const fixedWidthClass = fixedWidth ? 'fa-fw' : ''
  const iconClass = `${iconTypeCode} fa-${icon}`

  return (
    <i
      className={`${fixedWidthClass} ${iconClass} ${className}`}
      title={title}
      onClick={onClick}
    />
  )
}

function getTranslation(translateX?: string, translateY?: string) {
  if (translateX === undefined && translateY === undefined) return ''

  let translation = ''
  if (translateX !== undefined) translation += ` translateX(${translateX}) `
  if (translateY !== undefined) translation += ` translateY(${translateY}) `
  return `transform: ${translation};`
}

export const FAIcon = styled(BaseIcon).attrs<IconProps>(props => ({
  color: props.color ?? 'inherit',
  size: props.size ?? '1em',
}))`
  color: ${props => overloadColorProp(props)};
  font-size: ${props => props.size};
  margin: ${props => props.margin};
  display: ${props => props.display};
  width: ${props => props.width};

  ${props =>
    props.hoverColor &&
    `
    &:hover {
      cursor: ${props.onClick && 'pointer'};
        color: ${overloadColorProp({
          theme: props.theme,
          color: props.hoverColor,
        })};
      }
    `}

  ${props => getTranslation(props.translateX, props.translateY)}
`
