import styled from 'styled-components'
import { useTranslate } from '@ur/react-hooks'
import { Button } from 'components'
import { addDays, formatISO, parseISO, setHours, setMilliseconds, setMinutes } from 'date-fns'
import { TimeEntryFormValues } from '../types'
import {
    Module,
  ModuleOptionsValue,
  SalaryWorkTimeNode, TimesheetsModuleConfig,
} from 'modules/companies'
import { useQuery } from '@apollo/client'
import { TimeEntryNode, UserTimeEntriesQuery, UserTimeEntriesQueryVariables } from '../types.graphql'
import { USER_TIME_ENTRIES_QUERY } from '../queries'
import { useEffect } from 'react'
import { useBreadcrumbs, useModuleConfig, useUser } from 'util/hooks'
import { useTimeEntryMutations } from '../mutations.hooks'
import { useTimeEntryForm } from '../hooks'
import { getFullMinutes } from 'util/time'
import { TimeEntryForm } from './TimeEntryForm'
import { useLocation } from 'react-router'

const SubmitButton = styled(Button)`
  white-space: nowrap;

  ${props => props.theme.media.mobile} {
    width: 100%;
  }
`

const SubmitField = styled.div`
  display: flex;
  justify-content: flex-end;

  div.error {
    display: flex;
    align-items: center;

    margin-right: 1rem;
    color: ${props => props.theme.colors.red};
  }

  ${props => props.theme.media.mobile} {
    flex-direction: column;

    div.error {
      justify-content: center;
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }
`

interface TimeEntryFormProps {
    entry?: TimeEntryNode
    salaryWorkTime: SalaryWorkTimeNode
}

export const TimeEntryFormAPI: React.VFC<TimeEntryFormProps> = ({
    entry = null,
    salaryWorkTime,
}) => {
    const user = useUser()
    const translations = useTranslate({
        newTimeEntry: 'timesheets.new-time-entry',
        createTimeEntry: 'timesheets.register-timeentry',
    
        success: 'timesheets.success-registration-timeentry',
        submit: 'common.submit',
    
        error: {
          create: 'timesheets.error-create-timeentry',
          startBeforeEnd: 'timesheets.error.start-before-end',
          required: 'common.required',
        },
        warning: {
          create: 'timesheets.warning.time-entry-exist-in-daterange',
        },
      })
      const { getModuleOptionStatus } =
      useModuleConfig<TimesheetsModuleConfig>(Module.TIMESHEETS)
    const { setOverrides, overrides } = useBreadcrumbs()

  
  const location = useLocation<{ initialUser?: string }>()
  const form = useTimeEntryForm(entry, salaryWorkTime, location.state?.initialUser)
  const date = setMilliseconds(setHours(setMinutes(form.formValues.start, 0), 0),0)

  const { create } = useTimeEntryMutations()

  const { data } = useQuery<UserTimeEntriesQuery, UserTimeEntriesQueryVariables>(USER_TIME_ENTRIES_QUERY, {
    variables: {
      user: user.id,
      date: date,
      nextDay: addDays(date, 1),
    },
  })
  const existingTimeEntries = data?.allTimeEntries?.edges.map(e => e.node).filter(entry => parseISO(entry.datetimeStart) < form.formValues.start) ?? []
  const overtimeCustomChoice = getModuleOptionStatus(
    ModuleOptionsValue.TIMESHEETS_OVERTIME_OPTIONS
  )
  function onSubmit(values: TimeEntryFormValues) {
    const workingMs = (values.end.getTime() - values.start.getTime()) + existingTimeEntries.reduce((acc, entry) => {
      const start = parseISO(entry.datetimeStart)
      const end = parseISO(entry.datetimeEnd)
      return acc + (end.getTime() - start.getTime())
    }, 0)
    const totalWorkingSeconds = workingMs / 1000
    create({
      variables: {
        input: {
          user: values.userId,
          datetimeStart: formatISO(values.start),
          datetimeEnd: formatISO(values.end),
          project: values.project,
          comment: values.comment,
          salaryCode: values.salaryCodeId,
          diet: values.diet,
          department: values.department,
          pause: salaryWorkTime.pauseThreshold < totalWorkingSeconds ? getFullMinutes(values.pause) : 0,
        },
      },
    })
  }
  
  useEffect(() => {
    if (overrides['register']) return
    form.validateForm()
    setOverrides({ register: translations.newTimeEntry })
  }, [form, overrides, setOverrides, translations.newTimeEntry])

  return (
    <>
    <TimeEntryForm
        onSubmit={onSubmit}
        form={form}
        overtimeCustomChoice={overtimeCustomChoice}
        salaryWorkTime={salaryWorkTime as SalaryWorkTimeNode}
        existingTimeEntries={existingTimeEntries}
    />
    <SubmitField>
    <SubmitButton
        onClick={() => onSubmit(form.formValues)}
        width="200px"
        disabled={!form.formValid}
        >
        {translations.submit}
    </SubmitButton>
    </SubmitField>
    </>
  )
}
