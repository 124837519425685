import { useTranslate } from '@ur/react-hooks'
import {
  Button,
  DatePicker,
  Label,
  NumberInput,
  TimePicker,
  Title,
  VehicleSelect,
} from 'components'
import { useTheme } from 'styled-components'
import { CreateRoutePlanMap } from './components/CreateRoutePlanMap'
import {
  Checkbox,
  ContentDivider,
  DateTimeInput,
  Form,
  FormCardWrapper,
  FormContent,
  FormHeader,
  Input,
  PlaceEditor,
  StyledField,
  TwoColumnField,
  TwoColumns,
} from './components/formComponents'
import { useCreateRoutePlanAPI } from './useCreateRoutePlanAPI'
import { useCreateRoutePlanLogic } from './useCreateRoutePlanLogic'

export const CreateRoutePlanForm: React.VFC = () => {
  /**
   * This is the last layer of the form implementing a 3-layered design pattern as described in the following
   * article (http://web.archive.org/web/20220808130702/https://dev.to/spencerpauly/the-1-best-design-pattern-for-managing-forms-in-react-4215).
   * The Form layer (named View in the article, but we renamed the component for the sake of a more sensible import API) is responsible
   * for simply rendering the UI of the form. It handles no logic or state, besides UI related state.
   *
   * All internal state and mutation logic is delegated to the to hooks useCreateRoutePlanLogic, and useCreateRoutePlanAPI. The entry point
   * of logic is the useCreateRoutePlanAPI hook, which delegates to the useCreateRoutePlanLogic hook.
   */
  const {
    form,
    onSubmit,
    coordinates,
    startAutoCompleteSource,
    endAutoCompleteSource,
    vehicleSelectCallback,
    selectAutoCompleteItemCallback,
    handleLocationOnBlurCallback,
  } = useCreateRoutePlanLogic(useCreateRoutePlanAPI())

  const translations = useTranslate({
    form: {
      subtitle: {
        details: 'common.details',
        vehicleDetails: 'vehicles.vehicle-details',
      },
      label: {
        startLocation: 'route-planner.start-location',
        destination: 'route-planner.destination',
        startTime: 'common.start-time',
        includeDepartureTime: 'route-planner.include-departure-time',

        existingVehicle: 'vehicles.existing-vehicle',
        topSpeed: 'route-planner.top-speed-in-km',
        width: 'common.width-in-cm',
        height: 'common.height-in-cm',
        length: 'common.length-in-cm',
        weight: 'route-planner.form.label.total-weight-in-kg',
      },
      placeholder: {
        date: 'common.date',
      },
    },
    button: {
      submit: 'route-planner.create-route-plan',
    },
  })

  const {
    formValues,
    formValid,
    formErrors,
    validateForm,
    updateForm,
    formChangeHandler: handler,
    submitHandler,
  } = form

  const theme = useTheme()

  return (
    <FormCardWrapper maxWidth="928px">
      <Form onSubmit={submitHandler(onSubmit)} preventDefault>
        <FormHeader backgroundColor="quaternaery" padding="0">
          <CreateRoutePlanMap coordinates={coordinates} />
        </FormHeader>
        <FormContent backgroundColor="quaternary">
          <Title.H3>{translations.form.subtitle.details}</Title.H3>
          <TwoColumns>
            <TwoColumnField>
              <Label fieldRequired>
                {translations.form.label.startLocation}
              </Label>
              <PlaceEditor>
                <Input
                  value={formValues.startLocation}
                  error={formErrors.startLocation}
                  fullWidth
                  autoCompleteSource={startAutoCompleteSource}
                  onAutoCompleteItemSelect={selectAutoCompleteItemCallback(
                    'startLocation'
                  )}
                  background="white"
                  onChange={handler('startLocation')}
                  onBlur={handleLocationOnBlurCallback}
                />
              </PlaceEditor>
            </TwoColumnField>
            <TwoColumnField>
              <Label fieldRequired>{translations.form.label.destination}</Label>
              <PlaceEditor>
                <Input
                  value={formValues.endLocation}
                  fullWidth
                  autoCompleteSource={endAutoCompleteSource}
                  onAutoCompleteItemSelect={selectAutoCompleteItemCallback(
                    'endLocation'
                  )}
                  background="white"
                  onChange={handler('endLocation')}
                  onBlur={handleLocationOnBlurCallback}
                />
              </PlaceEditor>
            </TwoColumnField>
          </TwoColumns>
          <TwoColumns>
            <StyledField>
              <Label>{translations.form.label.startTime}</Label>
              <DateTimeInput>
                <DatePicker
                  value={formValues.startDate}
                  placeholder={translations.form.placeholder.date}
                  disabled={!formValues.includeDepartureTime}
                  onChange={handler('startDate')}
                />
                <TimePicker
                  value={formValues.startTime}
                  disabled={!formValues.includeDepartureTime}
                  disableWheel
                  onChange={handler('startTime')}
                />
              </DateTimeInput>
              <Checkbox
                checked={formValues.includeDepartureTime}
                label={translations.form.label.includeDepartureTime}
                boxBackground={theme.colors.white}
                onChange={handler('includeDepartureTime')}
              />
            </StyledField>
          </TwoColumns>
          <ContentDivider />

          <Title.H3>{translations.form.subtitle.vehicleDetails}</Title.H3>
          <TwoColumns>
            <StyledField>
              <Label>{translations.form.label.existingVehicle}</Label>
              <VehicleSelect
                onVehicleSelectedCallback={vehicleSelectCallback}
              />
            </StyledField>
          </TwoColumns>
          <TwoColumns>
            <StyledField>
              <Label fieldRequired>{translations.form.label.width}</Label>
              <NumberInput
                value={formValues.width}
                error={formErrors.width}
                disableWheel
                min={0}
                onChange={handler('width')}
              />
            </StyledField>
            <StyledField>
              <Label fieldRequired>{translations.form.label.height}</Label>
              <NumberInput
                value={formValues.height}
                error={formErrors.height}
                disableWheel
                min={0}
                onChange={handler('height')}
              />
            </StyledField>
          </TwoColumns>
          <TwoColumns>
            <StyledField>
              <Label fieldRequired>{translations.form.label.length}</Label>
              <NumberInput
                value={formValues.length}
                error={formErrors.length}
                disableWheel
                min={0}
                onChange={handler('length')}
              />
            </StyledField>
            <StyledField>
              <Label fieldRequired>{translations.form.label.topSpeed}</Label>
              <NumberInput
                value={formValues.topSpeed}
                error={formErrors.topSpeed}
                disableWheel
                min={0}
                onChange={handler('topSpeed')}
              />
            </StyledField>
          </TwoColumns>
          <TwoColumns>
            <StyledField>
              <Label fieldRequired>{translations.form.label.weight}</Label>
              <NumberInput
                value={formValues.grossWeight ?? 0}
                error={formErrors.grossWeight}
                disableWheel
                min={0}
                fullWidth
                onChange={value => {
                  updateForm({
                    grossWeight: value,
                  })
                  validateForm('grossWeight')
                }}
              />
            </StyledField>
          </TwoColumns>
          <ContentDivider />
          <StyledField>
            <Button type="submit" disabled={!formValid}>
              {translations.button.submit}
            </Button>
          </StyledField>
        </FormContent>
      </Form>
    </FormCardWrapper>
  )
}
