import { useQuery } from '@apollo/client'
import { useTranslate } from '@ur/react-hooks'
import { Title } from 'components/Title'
import React from 'react'
import styled from 'styled-components'
import { useOnErrorAuto } from 'util/hooks'
import { NeedsPasswordChangeQuery } from '..'
import { NEEDS_PASSWORD_CHANGE_QUERY } from '../queries'
import { ChangePasswordCard } from './ChangePasswordCard'
import { Card } from './components'

const Layout = styled.div`
  ${props => props.theme.layout.default};
`
interface WrapperProps {
  maxWidth?: string
}

const Wrapper = styled(Card)<WrapperProps>`
  max-width: ${props => props.maxWidth};
`
const Titlewrapper = styled.div`
  margin-bottom: 2rem;
`
const StyledTitle = styled(Title.H2)`
  padding: 0 0 1rem 0;
`

export const ChangePassword: React.VFC = () => {
  const translations = useTranslate({
    description: 'change-password.description',
    changePassword: 'common.change-password',
  })
  const onErrorAuto = useOnErrorAuto()

  const { data } = useQuery<NeedsPasswordChangeQuery>(
    NEEDS_PASSWORD_CHANGE_QUERY,
    {
      onError: onErrorAuto(),
    }
  )

  const needPasswordChange = data?.me.needsPasswordChange

  return needPasswordChange ? (
    <Layout>
      <Titlewrapper>
        <StyledTitle>{translations.changePassword}</StyledTitle>
        <p>{translations.description}</p>
      </Titlewrapper>
      <Wrapper maxWidth={'1200px'}>
        <ChangePasswordCard refresh={true} />
      </Wrapper>
    </Layout>
  ) : (
    <Wrapper>
      <h2>{translations.changePassword}</h2>
      <ChangePasswordCard />
    </Wrapper>
  )
}
