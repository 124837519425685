export interface DateCellProps {
  selected?: boolean
  disabled?: boolean
}

export enum GRANULARITY {
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export enum DatePickerMode {
  DAY = 'DAY',
  MONTH_FIRST = 'MONTH_FIRST',
  MONTH_LAST = 'MONTH_LAST',
  YEAR = 'YEAR',
}

export interface DatePickerProps<T> {
  name?: string

  value: T
  displayFormat?: string
  error?: string | null
  mode?: DatePickerMode

  disabledDates?: Date[]
  disableAfter?: Date
  disableBefore?: Date

  onChange: (
    newValue: T,
    event?: React.ChangeEvent<HTMLInputElement | HTMLFormElement>
  ) => void
}

export interface DatetimePickerProps<D, T> {
  onChange: (
    dates: D,
    times: T,
    event?: React.ChangeEvent<HTMLInputElement | HTMLFormElement>
  ) => void
  dateValues: D
  timeValues: T
  title?: string
  errors?: {
    [x: string]: string | null
  }
  mode?: DatePickerMode

  disabledDates?: Date[]
  disableAfter?: Date
  disableBefore?: Date
}
