import { PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Input, RegularModal } from 'components'
import { DetectBlur } from 'containers/util-containers'
import { Field, Form } from '..'
import { IssueRiskAspectNode } from 'modules/issues'

export interface IssueRiskAspectForm {
  name: string
}

interface IssueRiskAspectPromptProps {
  initialData?: IssueRiskAspectForm
  existingRiskAspects: IssueRiskAspectNode[]

  onSubmit: PromptResolve<IssueRiskAspectForm | null>
}

export const IssueRiskAspectPrompt: React.VFC<IssueRiskAspectPromptProps> = ({
  initialData,
  existingRiskAspects,
  onSubmit,
}) => {
  const translations = useTranslate({
    form: {
      label: {
        name: 'common.name',
        color: 'common.color',
        responsibleUser: 'settings.responsible-user',
      },
    },

    addIssueRiskAspect: 'settings.add-issue-risk-aspect',
    editIssueRiskAspect: 'settings.edit-issue-risk-aspect',

    create: 'common.create-alt',
    update: 'common.update',

    validation: {
      nameExists: 'settings.validation.issue-risk-aspect-name-exists',
      required: 'common.required',
    },
    defaultSeverity: 'common.default-severity',
  })


  const editing = typeof initialData !== 'undefined'

  function validateName(name: string, formName: string) {
    if (name === initialData?.name) return null
    if (name === '') return translations.validation.required
    if (
      existingRiskAspects.find(
        aspect => aspect.name === formName
      )
    ) {
      return translations.validation.nameExists
    }
    return null
  }

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formChangeHandler: handler,
    submitHandler: submit,
  } = useForm<IssueRiskAspectForm>({
    values: initialData ?? {
      name: '',
    },
    validators: {
      name: (val, form) => validateName(val, form.name),
    },
  })

  return (
    <DetectBlur active onClickOutside={() => onSubmit(null)}>
      <RegularModal
        title={
          editing
            ? translations.editIssueRiskAspect
            : translations.addIssueRiskAspect
        }
        submitText={editing ? translations.update : translations.create}
        submitDisabled={!formValid}
        width="400px"
        onCancel={() => onSubmit(null)}
        onSubmit={submit(onSubmit)}
      >
        <Form>
          <Field>
            <label>{translations.form.label.name}</label>
            <Input
              value={form.name}
              error={errors.name}
              autoFocus
              fullWidth
              onChange={handler('name')}
            />
          </Field>
        </Form>
      </RegularModal>
    </DetectBlur>
  )
}
