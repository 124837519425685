import { SVGIconProps } from 'components/icons'
import { ShortcutSVG, ShotcutDisplay } from './components'

export interface IconShortcutDisplayProps {
  iconProps: Pick<SVGIconProps, 'icon' | 'width'>
  text: string
}

export const IconShortcutDisplay: React.VFC<IconShortcutDisplayProps> = ({
  iconProps,
  text,
}) => {
  return (
    <ShotcutDisplay>
      <ShortcutSVG {...iconProps} />
      <p>{text}</p>
    </ShotcutDisplay>
  )
}
