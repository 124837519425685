import styled, { css } from 'styled-components'
import { isSafari } from 'react-device-detect'

/* TODO change this shit as soon as Apple get's their ass off the ground */
const BaseAttachmentsContainer = styled.article`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 0.8rem;
  border-top: 1px solid ${props => props.theme.colors.gray8};

  ${() =>
    isSafari &&
    css`
      gap: 0;
      margin-left: -0.8rem;
      margin-bottom: -0.8rem;

      & > * {
        margin-bottom: 0.8rem;
        margin-left: 0.8rem;
      }
    `};
`
const ForumAttachmentsContainer = styled(BaseAttachmentsContainer)`
  padding: 1.5rem 1.5rem;
`

export const PostAttachmentsContainer = styled(ForumAttachmentsContainer)`
  border-radius: 0 0 6px 6px;
`

export const QuillAttachmentsContainer = styled(ForumAttachmentsContainer)`
  min-height: 142px;
  box-sizing: content-box;
  border-bottom: 1px solid ${props => props.theme.colors.gray8};

  & > * {
    flex: 0 0 auto;
  }
`
export const HandbookEntryAttachmentsContainer = styled(
  BaseAttachmentsContainer
)`
  padding: 2rem;
  border-bottom-left-radius: ${props => props.theme.sizes.defaultBorderRadius};
  border-bottom-right-radius: ${props => props.theme.sizes.defaultBorderRadius};

  ${props => props.theme.media.mobile} {
    padding: 1rem;
    justify-content: space-between;
  }
`
