import { Icon, usePrompt } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { Button, CenteredLoader } from 'components'
import { Card } from 'components/Card'
import { PermissionsRequired } from 'containers/permission-containers'
import React, { useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { isMobile } from 'react-device-detect'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import { IdVariable } from 'types/graphql'
import { format } from 'util/date-fns'
import { useBreadcrumbs, useOnErrorAuto, useUser } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import {
  ArchivedInfringementForm,
  INFRINGEMENT_QUERY,
  PATCH_INFRINGEMENT_MUTATION,
  useInfringementsMutations,
} from '..'
import {
  InfringementNodeForInfringement,
  InfringementQuery,
  PatchInfringementMutation,
  PatchInfringementMutationVariables,
} from '../types.graphl'
import { Details } from '../components/Details'
import { formatISO, parseISO } from 'date-fns'
import { ArchiveInfringementPrompt } from '../components/table/ArchiveInfringementModal'
import { MainInfo } from '../components'
import { ExportForm } from 'modules/exports'
import { ExportInfringementsModal } from '../components/ExportInfringementsModal'


const Wrapper = styled.div`
  ${props => props.theme.layout.default};
  s header {
    display: flex;
    justify-content: space-between;
  }
`
const Buttons = styled.div`
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: ${isMobile ? 'space-between' : 'flex-end'};

  button {
    height: 3rem;

    & + button {
      margin-left: 1rem;
    }
  }
`
const Content = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow-x: auto;
`
const Loading = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  background-color: rgba(255, 255, 255, 0.5);
`

export const Infringement: React.VFC = () => {
  const translations = useTranslate({
    infringement: 'common.infringement',
    loading: 'common.loading...',

    open: 'common.open-alt',
    closed: 'common.closed',
    archived: ['common.archived', val => val.toLowerCase()],

    date: 'common.date',
    about: 'common.about',

    export: 'common.export',
    archive: 'common.archive',
    restore: 'common.restore',
  })

  const { infringementId } = useParams<{ infringementId: string }>()
  const history = useHistory()
  const { exportInfringement, loading } = useInfringementsMutations()
  const { setOverride } = useBreadcrumbs()
  const addPrompt = usePrompt()
  const onErrorAuto = useOnErrorAuto()
  const me = useUser()

  const { data, loading: queryLoading } = useQuery<
    InfringementQuery,
    IdVariable
  >(INFRINGEMENT_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      id: infringementId,
    },
    onCompleted(data) {
      if (!data.infringement) history.push('/infringements')

      const { infringement } = data
      const date = format(new Date(infringement.createdAt), 'PP')

      setOverride(infringementId, date)
    },
    onError: onErrorAuto(),
  })

  const [toggleArchived] = useMutation<
    PatchInfringementMutation,
    PatchInfringementMutationVariables
  >(PATCH_INFRINGEMENT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['Infringement'],
    onError: onErrorAuto(),
  })

  useEffect(() => {
    if (!!data) return
    setOverride(infringementId, translations.loading)
  })

  const infringement = data?.infringement

  async function handleArchiveInfringement(
    infringement?: InfringementNodeForInfringement
  ) {
    if (typeof infringement === 'undefined') return

    if (infringement.archivedAt !== null) {
      executeArchiveInfringement(infringement)()
      return
    }

    const initialData: ArchivedInfringementForm = {
      archivedBasis: '',
    }

    const { data } = await addPrompt<ArchivedInfringementForm | null>(
      resolve => (
        <ArchiveInfringementPrompt
          initialData={initialData}
          onResolve={resolve}
        />
      )
    )
    if (data === null) return

    executeArchiveInfringement(infringement)(data)
  }

  function executeArchiveInfringement(
    infringement?: InfringementNodeForInfringement | null
  ) {
    if (!infringement) return () => void 0

    return async (data?: ArchivedInfringementForm) => {
      const isArchived = infringement.archivedAt !== null
      await toggleArchived({
        variables: {
          id: infringement.id,
          input: {
            archivedBy: me.id,
            archivedAt: isArchived ? null : formatISO(new Date()),
            archivedBasis: data?.archivedBasis,
          },
        },
      })
    }
  }

  function doExport(data: ExportForm) {
    const recipients = (data.recipients.length === 0) 
      ? [me.id]
      : data.recipients

    exportInfringement({
      variables: {
        infringementId: infringementId,
        exportOption: data.exportOption,
        recipientId: me.id,
        recipientMails: recipients,
      },
    })
  }

  async function handleExport() {
    if (!infringement) return

    const { data } = await addPrompt<ExportForm | null>(resolve => (
      <ExportInfringementsModal onSubmit={resolve} />
    ))
    if (!data) return

    doExport(data)
  }

  const isLoading = queryLoading || loading || !data

  const articles = infringement?.infringementCategory?.legalBases?.edges.map(
    edge => edge.node.article
  )

  const startDate = infringement?.periodStartDate
    ? parseISO(infringement?.periodStartDate)
    : new Date()
  const endDate = infringement?.periodEndDate
    ? parseISO(infringement?.periodEndDate)
    : new Date()

  const timestamp = infringement?.infringementTimestamp
    ? parseISO(infringement?.infringementTimestamp)
    : new Date()

  return (
    <Wrapper>
      <header>
        <h2>{translations.infringement}</h2>
      </header>

      <Content>
        {isLoading && (
          <Loading>
            <CenteredLoader marginTop="0" size={72} thickness={8} />
          </Loading>
        )}
        {infringement && (
          <>
            <MainInfo
              loading={queryLoading}
              category={infringement.infringementCategory}
              archivedAt={infringement?.archivedAt}
              timestamp={timestamp}
              startDate={startDate}
              endDate={endDate}
            />

            <Details
              loading={queryLoading}
              reasonLowerLimit={infringement?.infringementCategory?.lowerLimit}
              reason={infringement?.infringementCategory?.description}
              reasonUpperLimit={infringement?.infringementCategory?.upperLimit}
              userFullname={infringement?.user?.fullName}
              reasonValue={infringement?.reasonValue}
              archivedAt={infringement?.archivedAt}
              archivedBasis={infringement?.archivedBasis}
              legalBasis={articles}
            />
          </>
        )}
      </Content>

      <Buttons>
        <PermissionsRequired
          permissions={PERMISSIONS.companies.export.exportReports}
        >
          <Button
            disabled={isLoading || !infringement}
            padding="0 2rem"
            onClick={() => handleExport()}
          >
            <Icon icon="inbox-in" fixedWidth margin="0 6px 0 0" />
            {translations.export}
          </Button>
          <Button
            disabled={isLoading || !infringement}
            padding="0 2rem"
            onClick={() => handleArchiveInfringement(infringement)}
          >
            {infringement?.archivedAt === null && (
              <Icon icon="archive" fixedWidth margin="0 6px 0 0" />
            )}
            {infringement?.archivedAt !== null
              ? translations.restore
              : translations.archive}
          </Button>
        </PermissionsRequired>
      </Buttons>
    </Wrapper>
  )
}
