import { Icon, ImageViewer, Loader, useToast } from '@ur/react-components'
import { Image } from '@ur/react-components/build/ImageViewer'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Button, DropArea } from 'components'
import { Label } from 'components/Label'
import { PostAttachment } from 'modules/forum/components/PostAttachment'
import {
  CreateManualEntryMutation,
  CreateManualEntryMutationVariables,
  CREATE_MANUAL_ENTRY_MUTATION,
  ManualFolderShallowQuery,
  MANUAL_FOLDER_SHALLOW_QUERY,
} from 'modules/handbook'
import {
  ContentWrapper,
  Description,
  EditButtons as BaseEditButtons,
  EditHandbookEntryForm,
  EntryQuillEditor,
  EntryQuillEditorRef,
} from 'modules/handbook/components'
import Quill from 'quill'
import Delta from 'quill-delta'
import Op from 'quill-delta/dist/Op'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { isMobile, isMobileOnly } from 'react-device-detect'
import { useHistory, useParams } from 'react-router'
import styled, { useTheme } from 'styled-components'
import { IdVariable } from 'types/graphql'
import { ZIndexRange } from 'types/style'
import { quillValidOrHasAttachments } from 'util/forms'
import {
  useAttachmentHandlers,
  useBreadcrumbs,
  useOnErrorAuto,
} from 'util/hooks'
import { safeParseJson } from 'util/parsing'
import { SelectHandbookForm } from '../components/entries/SelectHandbookForm'
import { AddAttachment, TitleInput, DescriptionInput } from '../components'
import { HandbookEntryAttachmentsContainer } from 'components'

const Wrapper = styled.div`
  ${props => props.theme.layout.default};

  & > header {
    margin-bottom: 1rem;
    padding-left: 4px;

    div.input {
      display: flex;
      align-items: center;

      div.loader {
        margin-left: 1rem;
      }
    }
  }
`
const Richtext = styled.article`
  overflow-x: hidden;
  padding: 0 !important;
`
const EditButtons = styled(BaseEditButtons)`
  align-self: end;
`

interface HandbookCreateEntryProps {}

export const HandbookCreateEntry: React.VFC<HandbookCreateEntryProps> = () => {
  const translations = useTranslate({
    name: 'common.name',
    description: 'common.description',
    content: 'common.content',

    loading: 'common.loading...',
    cancel: 'common.cancel',
    save: 'common.save',

    toast: {
      success: ['handbook.toasts.create-entry-success', { name: '' }],
    },
    validation: {
      required: 'common.required',
    },
    error: {
      generic: 'error.generic-server-error',
    },
  })

  const theme = useTheme()

  const firstMount = useRef(true)
  const quillEditorRef = useRef<EntryQuillEditorRef>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const { folderId } = useParams<{ folderId: string }>()
  const addToast = useToast()
  const history = useHistory()
  const onErrorAuto = useOnErrorAuto()
  const { setOverride } = useBreadcrumbs()

  const [imageViewerInitialImage, setImageViewerInitialImage] = useState('')
  const [imageViewerAttachments, setImageViewerAttachments] = useState<Image[]>(
    []
  )

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formChangeHandler: handler,
    submitHandler: submit,
  } = useForm<EditHandbookEntryForm>({
    values: {
      name: '',
      description: '',
      richtext: '[]',
      form: null,
    },
    validators: {
      name: val => (!!val ? null : translations.validation.required),
      richtext: () =>
        handleQuillValidOrHasAttachments(translations.validation.required),
    },
    config: {
      initAsInvalid: true,
    },
  })

  useEffect(() => {
    if (!firstMount.current) return
    firstMount.current = false

    setOverride(folderId, translations.loading)
  }, [folderId, setOverride, translations.loading])

  const {
    allAttachments,
    attachmentsAdd,
    handleDropAttachment,
    handleAddAttachment,
    handleRemoveAttachment,
    handleAttachmentClick,
  } = useAttachmentHandlers(
    [],
    setImageViewerInitialImage,
    setImageViewerAttachments
  )

  useQuery<ManualFolderShallowQuery, IdVariable>(MANUAL_FOLDER_SHALLOW_QUERY, {
    variables: {
      id: folderId,
    },
    onCompleted(data) {
      if (!data) return
      setOverride(data.manualFolder.id, data.manualFolder.name)
    },
  })

  const [createEntry, { loading }] = useMutation<
    CreateManualEntryMutation,
    CreateManualEntryMutationVariables
  >(CREATE_MANUAL_ENTRY_MUTATION, {
    onCompleted(data) {
      if (!data) {
        addToast('error', translations.error.generic)
        return
      }

      const {
        name,
        slug: entrySlug,
        folder: { slug: folderSlug },
      } = data.createManualEntry.manualEntry

      addToast('success', translations.toast.success({ name }))
      history.push(`/handbook/${folderSlug}/${entrySlug}`)
    },
    onError: onErrorAuto(),
  })

  function handleInitQuillEditor(quill: Quill) {
    const delta = new Delta(safeParseJson<Op[]>(form.richtext, []))
    quill.setContents(delta)
  }

  const isFormValid = useMemo(
    () => formValid || (!!form.name && !!allAttachments.length),
    [allAttachments.length, formValid, form.name]
  )

  /**
   * Convenience function to check if the current Quill and Attachments state is valid.
   * @param translation - A valid translation string
   */
  function handleQuillValidOrHasAttachments(translation: string) {
    const delta = quillEditorRef.current?.quill?.getContents()
    return quillValidOrHasAttachments(delta, allAttachments)
      ? null
      : translation
  }

  function handleCancel() {
    history.push('/handbook')
  }
  function handleSave(values: EditHandbookEntryForm) {
    const plaintext = quillEditorRef.current?.quill?.getText() ?? ''
    const attachments = attachmentsAdd.map(attachment => ({
      name: attachment.name,
      file: attachment.file,
    }))

    createEntry({
      variables: {
        input: {
          folder: folderId,
          name: values.name,
          description: values.description,
          richtext: values.richtext,
          plaintext,
          form: values.form,
          attachments,
        },
      },
    })
  }

  return (
    <Wrapper>
      <ImageViewer
        images={imageViewerAttachments}
        zIndex={ZIndexRange.Extra}
        initialImage={imageViewerInitialImage}
        popup
        open={imageViewerAttachments.length > 0}
        noShadow
        showArrows
        background="black !important"
        imageSize="contain"
        imageBackgroundColor="black !important"
        onClose={() => setImageViewerAttachments([])}
      />

      <header>
        <Label>{translations.name}</Label>
        <div className="input">
          <TitleInput
            value={form.name}
            error={errors.name}
            autoFocus
            fullWidth={isMobileOnly}
            onChange={handler('name')}
          />

          {loading && (
            <div className="loader">
              <Loader.Spinner size={32} />
            </div>
          )}
        </div>
      </header>

      <SelectHandbookForm value={form.form} onChange={handler('form')} />

      <ContentWrapper>
        <Description>
          <div>
            <Label>{translations.description}</Label>
            <DescriptionInput
              value={form.description}
              fullWidth
              onChange={handler('description')}
            />
          </div>

          <EditButtons>
            <Button
              role="link"
              background="gray8"
              disabled={loading}
              onClick={handleCancel}
            >
              {isMobile ? <Icon icon="times" /> : translations.cancel}
            </Button>
            <Button
              disabled={!isFormValid || loading}
              onClick={submit(handleSave)}
            >
              {isMobile ? <Icon icon="save" /> : translations.save}
            </Button>
          </EditButtons>
        </Description>

        <Richtext onClick={() => quillEditorRef.current?.quill?.focus()}>
          <EntryQuillEditor
            ref={quillEditorRef}
            error={errors.richtext}
            placeholder={translations.content}
            onInit={handleInitQuillEditor}
            onChange={handler('richtext')}
          />
        </Richtext>

        <DropArea
          disabled={isMobile}
          dragStyle={{
            backgroundColor: theme.colors.primary,
          }}
          onDrop={handleDropAttachment}
        >
          <HandbookEntryAttachmentsContainer>
            {allAttachments.map(attachment => (
              <PostAttachment
                key={attachment.id}
                attachment={attachment}
                deletable
                height="100px"
                onDelete={handleRemoveAttachment}
                onClick={handleAttachmentClick}
              />
            ))}
            <AddAttachment
              height="100px"
              onClick={() => fileInputRef.current?.click()}
            >
              <Icon icon="plus" size="1.6rem" color="gray7" />
              <input
                hidden
                ref={fileInputRef}
                type="file"
                multiple
                onChange={handleAddAttachment}
              />
            </AddAttachment>
          </HandbookEntryAttachmentsContainer>
        </DropArea>
      </ContentWrapper>
    </Wrapper>
  )
}
