import React from 'react'

export const FirmadokLogoNoText: React.VFC<React.SVGAttributes<SVGSVGElement>> =
  props => (
    <svg viewBox="0 0 79.94 79.94" fill="white" {...props}>
      <path
        className="cls-1"
        d="M110.65,265.06H135.8a3,3,0,0,0,2.95-1.64l5.08-8.8a3.17,3.17,0,0,0,.07-3.07l-5.85-10.12-5.46,9.45a5,5,0,0,1-4.55,2.58H102.18l5.91,10.24a2.82,2.82,0,0,0,2.56,1.36m-9.06-50.12H76.43a3.05,3.05,0,0,0-3,1.64l-5.08,8.8a3.17,3.17,0,0,0-.06,3.07l5.84,10.12,5.46-9.45a5,5,0,0,1,4.55-2.57h25.87l-5.92-10.24A2.81,2.81,0,0,0,101.59,214.94Z"
        transform="translate(-67.97 -200.03)"
      />
      <path
        className="cls-1"
        d="M130.08,248.61l12.58-21.79a3.05,3.05,0,0,0,.06-3.37l-5.08-8.8a3.18,3.18,0,0,0-2.63-1.59H123.32l5.46,9.46a5,5,0,0,1-.05,5.23l-12.93,22.4h11.82a2.81,2.81,0,0,0,2.46-1.53M82.15,231.39,69.58,253.18a3,3,0,0,0-.06,3.37l5.08,8.8a3.16,3.16,0,0,0,2.63,1.6H88.91l-5.46-9.46a5.06,5.06,0,0,1,0-5.23l12.94-22.4H84.61A2.83,2.83,0,0,0,82.15,231.39Z"
        transform="translate(-67.97 -200.03)"
      />
      <path
        className="cls-1"
        d="M125.55,223.55,113,201.76a3.05,3.05,0,0,0-2.89-1.73H99.92a3.15,3.15,0,0,0-2.69,1.48l-5.85,10.12H102.3a5,5,0,0,1,4.51,2.65l12.93,22.41,5.91-10.25a2.79,2.79,0,0,0-.1-2.89m-38.87,32.9,12.58,21.79a3.05,3.05,0,0,0,2.89,1.73h10.16a3.16,3.16,0,0,0,2.7-1.48l5.84-10.12H109.93a5,5,0,0,1-4.5-2.65L92.5,243.32l-5.92,10.24A2.81,2.81,0,0,0,86.68,256.45Z"
        transform="translate(-67.97 -200.03)"
      />
    </svg>
  )
