import { ExportFormat, ExportType } from 'modules/exports'
import { ExportOption } from 'modules/exports'
import { DriverActivityPeriodReport } from './types.graphql'

/* Nodes */

export interface RangeNode {
  type: 'regular' | 'issue'
  /** Hour value - where to start range */
  time: number
  /** Text to display in node */
  text: string
  /** Background of range between this and next node, if any */
  background?: string
  /** Text to display in range between this and next node, if any */
  rangeText?: string
  /** Subtext to display in range between this and next node, if any */
  rangeSubText?: string
  /** If the node is vehicle node */
  vehicleRange?: boolean
  /** Link target, only works for issue nodes */
  href?: string
}

export interface RangeNodeCollection {
  issueNodes: RangeNode[][]
  workNodes: [RangeNode, RangeNode][]
  availableNodes: [RangeNode, RangeNode][]
  restNodes: [RangeNode, RangeNode][]
  pauseNodes: [RangeNode, RangeNode][]
  driveNodes: [RangeNode, RangeNode][]
  countryNodes: RangeNode[][]
  vehicleNodes: [RangeNode, RangeNode][]
}

/* Forms */

export interface ExportActivitiesForm {
  exportType: ExportType
  exportOption: ExportOption
  exportFormat: ExportFormat
  weekly: boolean
}

export interface ActivitiesForm {
  exportType: ExportType
  exportOption: ExportOption
  exportFormat: ExportFormat
  useDateRange: boolean
  month: Date
  dateStart: Date
  dateEnd: Date
  user: string | null
  users: string[]
  orderBy: UserOrderBy
  includeAbsences?: boolean
  weekly: boolean
}

export interface FetchDriverActivitiesForm {
  month: Date
}

/* Other */

export interface DayReport {
  [key: string]: Date | string | boolean | null | undefined
  sortingDate: Date
  regNr: string | null

  startTime: string | null
  endTime: string | null
  dayStartLocation: string | null
  dayEndLocation: string | null

  timeDriving: string | null
  timeOvertime: string | null
  timeAvailability: string | null
  timeRest: string | null
  timePause: string | null
  timeOtherWork: string | null
  dataStatus: boolean | null
  approved?: boolean | null
}
export interface DaySummation {
  work: number
  rest: number
  driving: number
}
export interface WeekSummation {
  [key: string]: string | null
  timeDriving: string | null
  timeOvertime: string | null
  timeAvailability: string | null
  timeRest: string | null
  timePause: string | null
  timePaidRest: string | null
  timeOtherWork: string | null
}
export interface WeekReport extends DriverActivityPeriodReport {
  week: number
  approved: boolean | null
  sums: WeekSummation
}

export type VehicleColorDictionary = {
  [key: string]: string
}

export enum UserOrderBy {
  FAMILY_NAME = "last_name",
  USER_TYPES = "user_types",
  INTERNAL_NUMBER = "internal_number",
}