import { Table as BaseTable, Column } from 'components'
import React, { useMemo, useState } from 'react'
import { TimesheetsDayReport, TimesheetsPeriodReport, TimesheetsReportSalaryWorkTimeNode } from '../types.graphql'
import { TimesheetTableAbsenceRow, TimesheetTableAbsenceSubRow, TimesheetTableOvertimeSubRow, TimesheetTableRow, TimesheetTableSubRow } from './TimesheetTableRow'
import { TimesheetTableSummation } from './TimesheetTableSummations'
import styled, { css } from 'styled-components'
import { parseISO } from 'date-fns'
import { isMobile } from 'react-device-detect'
import { ZIndexRange } from 'types/style'
import { Icon } from '@ur/react-components'

const Table = styled(props => <BaseTable {...props} />)`
  position: relative;

  ${props => props.theme.media.largePad}
  ${props => props.theme.media.mobile} {
    overflow: auto;
  }

  td.absence-info {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 12px 1rem;
    height: 56px;

    span {
      color: ${props => props.theme.colors.gray5};
      font-weight: 600;
    }
  }
`


const InlineDropdownButton = styled.span<{ active: boolean, isActiveRow: boolean}>`
  display: inline-block;
  position: absolute;
  background-color: ${props => props.theme.colors.white};
  height: 56px;
  width: 1.5rem;
  padding-top: 19px;
  padding-left: 0.275rem;
  content: '';
  margin: 0;
  cursor: pointer;
  z-index: ${ZIndexRange.Dropdowns};
  border-top: 1px solid ${props => props.theme.colors.gray10};
  border-bottom: 1px solid ${props => props.theme.colors.gray10};

  :hover {
    background-color: ${props => props.theme.colors.gray10};
  }

  ${props =>
    !props.isActiveRow &&
    css`
      background-color: ${props.theme.colors.gray11};
      color: ${props.theme.colors.gray6} !important;
    `};
`
interface TimesheetsTableProps {
  periodReport: TimesheetsPeriodReport
  salaryWorkTime: TimesheetsReportSalaryWorkTimeNode
  startDate: Date
  endDate: Date
  columns?: Column[]
  projectActive?: boolean
}

export const TimesheetsTable: React.VFC<TimesheetsTableProps> = ({
  periodReport,
  salaryWorkTime,
  startDate,
  endDate,
  columns = [],
  projectActive = false,
}) => {
  const tableHeaders = (
    <tr>
      {columns.map(column => (
        <th key={column.name}>{column.displayName || column.name}</th>
      ))}
      { /* Extra column for menu */}
      <th key="menu"></th>
    </tr>
  )

  const timesheetRows = useMemo(() => {
    return periodReport.dayReports.map(dayReport => {
      
      return (
        <TimesheetsDayReportRows dayReport={dayReport} startDate={startDate} endDate={endDate} projectActive={projectActive} salaryWorkTime={salaryWorkTime}/>
      )
    })
  }, [endDate, periodReport.dayReports, projectActive, salaryWorkTime, startDate])

  return (
    <Table headerMargin rowHoverBackground="gray10">
      <thead>{tableHeaders}</thead>
      <tbody>
        {timesheetRows}
        <TimesheetTableSummation periodReport={periodReport} projectActive={projectActive} />
      </tbody>
    </Table>
  )
}


interface TimesheetsDayReportRowsProps {
  dayReport: TimesheetsDayReport
  salaryWorkTime: TimesheetsReportSalaryWorkTimeNode
  startDate: Date
  endDate: Date
  projectActive?: boolean
}

const TimesheetsDayReportRows: React.VFC<TimesheetsDayReportRowsProps> = ({
  dayReport,
  salaryWorkTime,
  startDate,
  endDate,
  projectActive = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const activeDropdown = dayReport.timeEntries.length + dayReport.absences.length + dayReport.overtimeEntries.length > 1
  const isWithinInterval = parseISO(dayReport.day) >= startDate && parseISO(dayReport.day) <= endDate

  const icon = isExpanded ? "chevron-down" : "chevron-right"

  return (
    <>
      {activeDropdown && !isMobile &&
        <InlineDropdownButton 
          active={isExpanded}
          isActiveRow={isWithinInterval}
          onClick={(e) => {
            if (activeDropdown) {
              e.stopPropagation() 
              setIsExpanded(!isExpanded)
            }
            else {
              e.stopPropagation() 
            }
          }} 
        >
          <Icon margin={isExpanded ? '0 0.1rem' : '0 0.3rem'} size='10px' icon={icon}/>
        </InlineDropdownButton>
      }
      {dayReport.timeEntries.length > 0 || (dayReport.absences.length === 0) ? (
        <TimesheetTableRow
          isWithinInterval={isWithinInterval}
          onClick={() => {}}
          key={new Date(dayReport.day).getDay()}
          day={dayReport}
          projectActive={projectActive}
          salaryWorkTime={salaryWorkTime}
        />
      ) : (
        <TimesheetTableAbsenceRow
          isWithinInterval={isWithinInterval}
          onClick={() => {}}
          key={new Date(dayReport.day).getDay()}
          absence={dayReport.absences[0]}
          date={parseISO(dayReport.day)}
          projectActive={projectActive}
          salaryWorkTime={salaryWorkTime}
        />
      )}
      {(dayReport.timeEntries.length + dayReport.overtimeEntries.length + dayReport.absences.length) > 1 && dayReport.timeEntries.map((timeEntry, index) => {
        return (
          <TimesheetTableSubRow
            isWithinInterval={isWithinInterval}
            onClick={() => {}}
            key={index}
            timeEntry={timeEntry}
            otherEntries={dayReport.timeEntries.filter(otherEntry => otherEntry.id !== timeEntry.id && parseISO(otherEntry.datetimeStart) < parseISO(timeEntry.datetimeStart))}
            hidden={!isExpanded}
            projectActive={projectActive}
            salaryWorkTime={salaryWorkTime}
          />
        )
      })}
      {dayReport.overtimeEntries.map((overtimeEntry, index) => {
        return (
          <TimesheetTableOvertimeSubRow
            isWithinInterval={isWithinInterval}
            onClick={() => {}}
            key={index}
            overtimeEntry={overtimeEntry}
            hidden={!isExpanded}
            projectActive={projectActive}
          />
        )
      })}
      {dayReport.timeEntries.length > 0 && dayReport.absences.map((absence, index) => {
        return (
          <TimesheetTableAbsenceSubRow
            isWithinInterval={isWithinInterval}
            onClick={() => {}}
            key={index}
            absence={absence}
            hidden={!isExpanded}
            projectActive={projectActive}
            salaryWorkTime={salaryWorkTime}
          />
        )
      })}
    </>
  )
}