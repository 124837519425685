import { PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import {
  RegularModal,
  Select,
} from 'components'
import { ExportActivitiesForm } from 'modules/activities/types'
import { Field, Form } from 'modules/companies'
import React, { useEffect } from 'react'
import { ExportFormat, ExportOption, ExportType } from 'modules/exports'
import { useExportFormatOptions, useExportOptions } from 'modules/exports/hooks'
import { MonthWeekToggle } from 'components/Toggle/MonthWeekToggle'

interface ExportActivitiesModalProps {
  onSubmit: PromptResolve<ExportActivitiesForm | null>
}

export const ExportActivitiesModal: React.VFC<ExportActivitiesModalProps> = ({
  onSubmit,
}) => {
  const translations = useTranslate({
    exportDrivingData: 'activities.export-driving-data',
    export: 'common.export',

    hours: 'common.hours',
    diet: 'common.diet',
    email: 'common.email',
    download: 'common.download',

    type: 'common.type',
    exportOption: 'common.export-type',
    exportFormat: 'common.export-format',

    exportWeekly: 'activities.export-monthly-weekly',
    pauseExport: 'activities.pause-export',
  })

  const {
    formValues: form,
    updateForm,
    submitHandler: submit,
  } = useForm<ExportActivitiesForm>({
    values: {
      exportType: ExportType.ACTIVITIES,
      exportOption: ExportOption.DOWNLOAD,
      exportFormat: ExportFormat.PDF,
      weekly: false,
    },
    config: {
      storage: {
        storeFormState: true,
        retrieveFormStateOnMount: true,
        storeFormStateName: 'Export-activites-form',
        transformValues: {
          exportType: value => value as ExportType,
          exportFormat: value => value as ExportFormat,
          exportOption: value => value as ExportOption,
          weekly: value => value as boolean,
        },
      },
    },
  })

  const exportTypes = [
    {
      value: ExportType.ACTIVITIES,
      label: translations.hours,
    },
    {
      value: ExportType.DIET,
      label: translations.diet,
    },
    {
      value: ExportType.PAUSE,
      label: translations.pauseExport
    }
  ]

  const exportOptions = useExportOptions(true)
  const excludeFormats =
    form.exportOption === ExportOption.DOWNLOAD 
      ? [ExportFormat.EXCEL, ExportFormat.EXCEL_FLAT, ExportFormat.VISMA, ExportFormat.POWEROFFICE, ExportFormat.TWENTYFOUR_SEVEN_OFFICE, ExportFormat.TRIPLETEX, ExportFormat.PDF_SALARY] 
      : form.exportType === ExportType.DIET ? [ExportFormat.VISMA, ExportFormat.POWEROFFICE, ExportFormat.TWENTYFOUR_SEVEN_OFFICE, ExportFormat.TRIPLETEX, ExportFormat.PDF_SALARY] : []
  const exportFormatOptions = useExportFormatOptions(excludeFormats)

  
  useEffect(() => {
    if (
      form.exportOption === ExportOption.DOWNLOAD &&
      form.exportFormat !== ExportFormat.PDF
    ) {
      updateForm({
        exportFormat: ExportFormat.PDF,
      })
    }
  }, [form.exportFormat, form.exportOption, updateForm])

  useEffect(() => {
    if (form.exportType !== ExportType.ACTIVITIES && form.weekly) {
      updateForm({
        weekly: false,
      })
    }
    if(form.exportType !== ExportType.ACTIVITIES && form.exportFormat === ExportFormat.VISMA) {
      updateForm({
        exportFormat: ExportFormat.PDF,
      })
    }
  }, [form.exportFormat, form.exportType, form.weekly, updateForm])

  return (
    <RegularModal
      title={translations.exportDrivingData}
      width="400px"
      submitText={translations.export}
      onCancel={() => onSubmit(null)}
      onSubmit={submit(onSubmit)}
    >
      <Form>
        <Field>
          <label>{translations.type}</label>
          <Select
            value={form.exportType}
            options={exportTypes}
            fullWidth
            height="3.875rem"
            onChange={value =>
              updateForm({ exportType: value ?? ExportType.ACTIVITIES })
            }
          />
        </Field>
        <Field>
          <label>{translations.exportWeekly}</label>
          <MonthWeekToggle
            value={form.weekly}
            onChange={value =>
              updateForm({
                weekly: value,
              })
            }
          />
        </Field>
        <Field>
          <label>{translations.exportOption}</label>
          <Select
            value={form.exportOption}
            options={exportOptions}
            fullWidth
            height="3.875rem"
            onChange={value =>
              updateForm({
                exportOption: value ?? ExportOption.DOWNLOAD,
              })
            }
          />
        </Field>
        <Field>
          <label>{translations.exportFormat}</label>
          <Select
            value={form.exportFormat}
            options={exportFormatOptions}
            disabled={form.exportOption === ExportOption.DOWNLOAD}
            fullWidth
            height="3.875rem"
            onChange={value =>
              updateForm({
                exportFormat: value ?? ExportFormat.PDF,
              })
            }
          />
        </Field>
      </Form>
    </RegularModal>
  )
}
