import { Icon, Loader } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import {
  Button as BaseButton,
  Button,
  ButtonProps,
  Input as BaseInput,
} from 'components'
import { Card as BaseCard } from 'components/Card'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { BaseProps } from 'types/props'
import { ZIndexRange } from 'types/style'
import { SimpleColorPicker as BaseSimpleColorPicker } from 'components/ColorPicker'

export interface GridConfig {
  columns?: string
  rows?: string
  areas?: string
  gap?: string
  flow?: 'row' | 'column' | 'dense'
}

function createGridArea(defaultGap?: string) {
  return ({ grid }: { grid?: GridConfig }) => {
    if (typeof grid === 'undefined') return
    return css`
      display: grid;
      grid-template-columns: ${grid.columns};
      grid-template-rows: ${grid.rows};
      grid-template-areas: ${grid.areas};
      gap: ${grid.gap ?? defaultGap};
      grid-auto-flow: ${grid.flow};
    `
  }
}

/* SettingsWrapper */

interface WrapperProps {
  grid?: GridConfig
}
const Wrapper = styled.div<WrapperProps>`
  ${createGridArea('2rem')};

  ${props => props.theme.media.mobile} {
    ${createGridArea('1rem')};
  }
`

interface SettingsWrapperProps extends BaseProps {
  grid?: GridConfig
}
export const SettingsWrapper: React.FC<SettingsWrapperProps> = ({
  children,
  className,
  id,

  grid,
}) => {
  return (
    <Wrapper className={className} id={id} grid={grid}>
      {children}
    </Wrapper>
  )
}

/* Section */

interface StyledSectionProps {
  grid?: GridConfig
}
const StyledSection = styled(BaseCard)<StyledSectionProps>`
  padding: 2rem;

  h2 {
    margin: 0 0 0rem 0;
    font-size: 1.6rem;
    font-weight: 600;
  }

  ${createGridArea('1rem')};

  ${props => props.theme.media.mobile} {
    max-width: calc(100vw - 2rem);
    padding: 1rem;

    h2 {
      margin-bottom: 1rem;
      font-size: 1.2rem;
    }
  }
`
interface SectionProps extends BaseProps {
  grid?: GridConfig
}
export const Section: React.FC<SectionProps> = ({
  children,
  className,
  id,

  grid,
}) => (
  <StyledSection className={className} id={id} grid={grid}>
    {children}
  </StyledSection>
)

/* InfoBox */

interface StyledInfoBoxProps {
  expanded: boolean
}
const StyledInfoBox = styled(StyledSection)<StyledInfoBoxProps>`
  position: relative;
  display: grid;
  grid-template-columns: 5.75rem 1fr;

  ${props =>
    !props.expanded &&
    css`
      & > div.content {
        align-items: center;
        flex-direction: row;

        white-space: nowrap !important;
        overflow: hidden;
        text-overflow: ellipsis;

        p {
          white-space: nowrap !important;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        & > * + * {
          display: none;
        }
      }
    `};

  & > div.icon {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
    padding-right: 2rem;
  }
  & > div.content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: 1.2rem;
    padding-top: 5px;
    color: ${props => props.theme.colors.gray5};
    white-space: pre-wrap;

    p {
      max-width: 100%;
      margin: 0;

      & + p {
        margin: 1em 0 0 0;
      }
    }
  }

  & > div.collapse {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 1.75rem;
    height: 1.75rem;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: ${props => props.theme.colors.secondary};

      i {
        font-weight: 900;
        color: white;
      }
    }
  }

  ${props => props.theme.media.mobile} {
    grid-template-columns: ${props =>
      props.expanded ? '48px 1fr' : '48px 1fr 2rem'};

    & > div.icon {
      padding-right: 1rem;

      i {
        font-size: 2rem;
      }
    }
    & > div.content {
      font-size: 1rem;
    }
    & > div.collapse:hover {
      background-color: inherit;

      i {
        font-weight: normal;
        color: currentColor;
      }
    }
  }
`
interface InfoBoxProps {
  nonCollapsable?: boolean
  initCollapsed?: boolean
}
export const InfoBox: React.FC<InfoBoxProps> = ({
  children,
  nonCollapsable = false,
  initCollapsed = false,
}) => {
  const [expanded, setExpanded] = useState(!initCollapsed)

  return (
    <StyledInfoBox expanded={expanded}>
      {!nonCollapsable && (
        <div className="collapse" onClick={() => setExpanded(v => !v)}>
          <Icon
            icon={expanded ? 'chevron-up' : 'chevron-down'}
            color="gray6"
            translateY={expanded ? undefined : '2px'}
          />
        </div>
      )}

      <div className="icon">
        <Icon icon="info-circle" type="light" size="2.2rem" color="gray6" />
      </div>
      <div className="content">
        {typeof children === 'string' ? <p>{children}</p> : children}
      </div>
    </StyledInfoBox>
  )
}

/* Forms */

interface FieldProps {
  alignLeft?: boolean
  alignRight?: boolean
  area?: string
}
export const Field = styled.div<FieldProps>`
  grid-area: ${props => props.area};
  display: flex;
  flex-direction: column;
  ${props => props.alignLeft && 'align-items: flex-start;'}
  ${props => props.alignRight && 'align-items: flex-end;'}

  & > label {
    font-size: 0.9rem;
    font-weight: 600;
    margin: 0 0 6px 6px;
    color: ${props => props.theme.colors.gray5};
  }
`

const StyledLoadingLabel = styled.label`
  display: flex;

  div.loader {
    margin-left: 6px;
  }
`
interface LoadingLabelProps extends BaseProps {
  loading: boolean
}
export const LoadingLabel: React.FC<LoadingLabelProps> = ({
  children,
  className,
  id,
  loading,
}) => (
  <StyledLoadingLabel className={className} id={id}>
    {children}
    {loading && (
      <div className="loader">
        <Loader.Spinner size={14} thickness={2} />
      </div>
    )}
  </StyledLoadingLabel>
)

interface StyledFormProps {
  area?: string
  grid?: GridConfig
}
const StyledForm = styled.div<StyledFormProps>`
  grid-area: ${props => props.area};
  display: flex;
  flex-direction: column;

  ${createGridArea('1rem')};

  ${props =>
    typeof props.grid === 'undefined' &&
    css`
      ${Field} + ${Field} {
        margin-top: 1rem;
      }
    `}

  .submit {
    grid-column: 1 / -1;
  }
`
type FormButtonType = 'submit' | 'cancel'
interface FormButtonProps {
  buttonType: FormButtonType
}
const FormFieldButton = styled(BaseButton)<FormButtonProps>`
  padding: 0 2rem;

  background-color: ${props =>
    props.buttonType === 'submit' ? props.theme.colors.secondary : 'white'};
  border: ${props =>
    props.buttonType === 'submit'
      ? '0'
      : `1px solid ${props.theme.colors.gray8}`};
`
export interface FormButton {
  text: string | JSX.Element
  type?: FormButtonType
  buttonProps?: Omit<ButtonProps, 'onClick'>
  onClick: (evt: React.MouseEvent<HTMLButtonElement>) => void
}
interface FormProps extends BaseProps {
  area?: string
  grid?: GridConfig
  buttons?: FormButton[]
}
export const Form: React.FC<FormProps> = ({
  children,
  className,
  id,

  area,
  grid,
  buttons,
}) => (
  <StyledForm className={className} id={id} area={area} grid={grid}>
    {children}

    {!!buttons && (
      <Field alignRight className="submit">
        {buttons.map((button, idx) => (
          <FormFieldButton
            key={button.text.toString() + idx}
            buttonType={button.type ?? 'submit'}
            {...button.buttonProps}
            onClick={button.onClick}
          >
            {button.text}
          </FormFieldButton>
        ))}
      </Field>
    )}
  </StyledForm>
)
export const Input = styled(BaseInput)`
  input {
    background-color: white;
  }
`

export const SettingsColorPicker = styled(BaseSimpleColorPicker)`
  display: none;
  position: absolute;
  z-index: ${ZIndexRange.Dropdowns};
  left: -4px;
  top: -4px;
  width: 228px;

  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.15);
`

interface ColorProps {
  color: string
  disabled?: boolean
}

export const Color = styled.div.attrs<ColorProps>(props => ({
  style: {
    backgroundColor: props.color,
  },
}))<ColorProps>`
  position: relative;
  width: 28px;
  height: 28px;

  border-radius: 4px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};

  &:hover {
    ${SettingsColorPicker} {
      display: grid;
    }
  }
`

/* TableAddButton */

interface StyledAddButtonProps {
  adding: boolean
}
const StyledTableAddButton = styled.th<StyledAddButtonProps>`
  span {
    text-transform: none;
    color: ${props => props.theme.colors.gray5};
    user-select: none;
    cursor: pointer;

    i {
      transition: transform 0.1s ease-out;
      ${props => props.adding && 'transform: rotate(45deg);'};
    }

    &:hover {
      color: ${props =>
        props.adding ? props.theme.colors.red : props.theme.colors.secondary};
    }
  }
`
interface TableAddButtonProps {
  adding: boolean
  colSpan?: number
  onClick: (adding: boolean) => void
}
export const TableAddButton: React.VFC<TableAddButtonProps> = ({
  adding,
  colSpan,
  onClick,
}) => {
  const translations = useTranslate({
    add: 'common.add',
    cancel: 'common.cancel',
  })

  return (
    <StyledTableAddButton
      adding={adding}
      colSpan={colSpan}
      onClick={() => onClick(!adding)}
    >
      <span>
        {adding ? translations.cancel : translations.add}
        <Icon icon="plus" margin="0 0 0 6px" />
      </span>
    </StyledTableAddButton>
  )
}

/* AddButton */
interface StyledAddButtonProps {
  adding: boolean
}
const StyledAddButton = styled(Button)<StyledAddButtonProps>`
  height: 3rem;
  padding: 0 1rem;
  width: 150px;
  background: ${props =>
    props.adding ? props.theme.colors.red : props.theme.colors.secondary};
  color: ${props =>
    props.adding ? props.theme.colors.white : props.theme.colors.primary};
  span {
    text-transform: none;
    user-select: none;
    cursor: pointer;
  }
`
interface AddButtonProps {
  adding: boolean
  onClick: (adding: boolean) => void
}
export const AddButton: React.VFC<AddButtonProps> = ({ adding, onClick }) => {
  const translations = useTranslate({
    add: 'common.add',
    cancel: 'common.cancel',
  })

  return (
    <StyledAddButton adding={adding} onClick={() => onClick(!adding)}>
      <Icon icon="plus" margin="0 6px 0 0 " />
      <span>{adding ? translations.cancel : translations.add}</span>
    </StyledAddButton>
  )
}

/* Other */

const StyledSectionHeader = styled.h2`
  & > div.loader {
    display: inline-block;
    margin-left: 12px;
    transform: translateY(2px);
  }
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`

interface SectionHeaderProps extends BaseProps {
  loading?: boolean
}

export const SettingsLoader: React.FC = () => (
  <div className="loader">
    <Loader.Spinner size={22} thickness={2} />
  </div>
)

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  children,
  className,
  id,

  loading,
}) => {
  return (
    <StyledSectionHeader className={className} id={id}>
      {children}
      {loading && <SettingsLoader />}
    </StyledSectionHeader>
  )
}

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`
