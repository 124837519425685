import styled, { css } from 'styled-components'

interface FormFieldProps {
  name?: string
  inline?: boolean
  width?: string

  margin?: string

  error?: string | null
}

export const FormField = styled.div.attrs<FormFieldProps>(props => ({
  width: props.width ?? '100%',
  ...props,
}))<FormFieldProps>`
  display: ${props => (props.inline ? 'inline-block' : 'block')};
  position: relative;
  width: ${props => props.width};
  margin: ${props => props.margin};

  ${props =>
    props.error &&
    css`
      &::after {
        content: '${props.error}';
        position: absolute;
        top: 0;
        right: 0;
        color: ${props.theme.colors.red};
        font-size: 0.8rem;
      }
    `}
  grid-area: ${props => props.name};
`
