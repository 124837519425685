import { Icon, Modal } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { ExternalLink, RegularModal, Table } from 'components'
import React, { useEffect, useMemo } from 'react'
import { useLazyQuery } from '@apollo/client'
import { isMobileOnly } from 'react-device-detect'
import { format } from 'util/date-fns'
import { downloadFile, getFileIconAndColor, showFileDialog } from 'util/file'
import { useConfirm, usePromptString } from 'util/hooks'
import { UserDocument } from '.'
import {
  UserDocumentFileNode,
  UserDocumentFilesForDocumentQuery,
  UserDocumentFilesForDocumentQueryVariables,
} from '..'
import { USER_DOCUMENT_FILES_FOR_DOCUMENT_QUERY } from '../queries'
import { useUserDocumentFileMutations } from './mutations.hooks'

interface UserDocumentFilesModalProps {
  open: boolean
  document: Pick<UserDocument, 'id' | 'name'> | null
  onClose: () => void
}

export const UserDocumentFilesModal: React.VFC<UserDocumentFilesModalProps> = ({
  open,
  document,
  onClose,
}) => {
  const translations = useTranslate({
    saveAndClose: 'common.save-and-close',
    name: 'common.name',
    uploaded: 'common.uploaded',

    changeName: 'commmon.edit-name',
    noFiles: 'documents.no-files',
    uploadFile: 'documents.upload-file',

    validation: {
      required: 'common.required',
    },
    prompt: {
      delete: ['documents.prompts.delete-file', { name: '' }],
      deleteTitle: 'documents.prompts.delete-file-title',
    },
  })

  const promptString = usePromptString({
    autoSelect: true,
    validate: val => (!!val ? null : translations.validation.required),
    promptOptions: {
      modalProps: {
        zIndex: 100000,
      },
    },
  })
  const confirm = useConfirm({
    promptOptions: {
      modalProps: {
        zIndex: 100000,
      },
    },
  })

  const [fetch, { data, loading: queryLoading }] = useLazyQuery<
    UserDocumentFilesForDocumentQuery,
    UserDocumentFilesForDocumentQueryVariables
  >(USER_DOCUMENT_FILES_FOR_DOCUMENT_QUERY, {
    variables: {
      userDocument: document?.id ?? '',
    },
  })

  const mutations = useUserDocumentFileMutations()

  useEffect(() => {
    if (document !== null)
      fetch({
        variables: {
          userDocument: document.id,
        },
      })
  }, [document, fetch])

  function handleUpload(files: File[]) {
    if (!files.length || document === null) return

    mutations.batchCreate({
      variables: {
        input: files.map(file => ({
          userDocument: document.id,
          file: file,
          name: file.name,
        })),
      },
    })
  }
  function handleEdit(file: UserDocumentFileNode) {
    return async () => {
      const name = await promptString(null, translations.changeName, {
        initialText: file.name,
      })
      if (!name || name === file.name) return

      mutations.patch({
        variables: {
          id: file.id,
          input: {
            name,
          },
        },
      })
    }
  }
  function handleDelete(file: UserDocumentFileNode) {
    return async () => {
      const { data: answer } = await confirm(
        translations.prompt.delete({ name: file.name }),
        translations.prompt.deleteTitle
      )
      if (!answer) return

      mutations.delete({
        variables: { id: file.id },
      })
    }
  }

  function handleDownload(file: UserDocumentFileNode) {
    return () => {
      downloadFile(file.file, file.originalName)
    }
  }

  const files = useMemo(
    () => data?.allUserDocumentFiles.edges.map(edge => edge.node) ?? [],
    [data]
  )

  return (
    <Modal
      open={open}
      offset={isMobileOnly ? 8 : 36}
      placement="top-center"
      direction="from-top"
      onClose={onClose}
    >
      {!!document && (
        <RegularModal
          width="600px"
          title={document.name}
          cancelText={translations.uploadFile}
          submitText={translations.saveAndClose}
          cancelIcon={{
            icon: 'paperclip',
          }}
          onCancel={() =>
            showFileDialog(handleUpload, {
              multiple: true,
            })
          }
          onSubmit={onClose}
        >
          <Table
            noShadow
            noBorders
            noHeaderMargin
            noRowMargin
            loading={queryLoading}
            loaderProps={{
              columns: 2,
              rows: 1,
              expectedWidths: [260, 120],
            }}
            noData={!files.length}
            noDataText={translations.noFiles}
          >
            <thead>
              <tr>
                <th>{translations.name}</th>
                <th>{translations.uploaded}</th>
                <th></th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {files.map(file => {
                const uploaded = format(new Date(file.createdAt), 'PP')
                const uploadedLong = format(
                  new Date(file.createdAt),
                  'PPP HH:mm'
                )
                const [icon, color] = getFileIconAndColor(file.originalName)

                return (
                  <tr key={file.id}>
                    <td>
                      <ExternalLink href={file.file}>
                        <Icon
                          icon={icon}
                          color={color}
                          fixedWidth
                          margin="0 4px 0 0"
                        />
                        {file.name}
                      </ExternalLink>

                      <Icon
                        icon="edit"
                        cursor="pointer"
                        color="gray6"
                        hoverColor="secondary"
                        margin="0 0 0 8px"
                        onClick={handleEdit(file)}
                      />
                    </td>

                    <td width="1px" title={uploadedLong}>
                      {uploaded}
                    </td>

                    <td width="1px" className="center">
                      <Icon
                        icon="inbox-in"
                        cursor="pointer"
                        color="gray6"
                        hoverColor="secondary"
                        onClick={handleDownload(file)}
                      />
                    </td>

                    <td width="1px" className="center">
                      <Icon
                        icon="times"
                        cursor="pointer"
                        color="gray6"
                        hoverColor="red"
                        onClick={handleDelete(file)}
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </RegularModal>
      )}
    </Modal>
  )
}
