import { StyledFormProps } from 'components/Form'
import React from 'react'
import styled from 'styled-components'

interface FormProps {
  id?: string
  outerRef?: (
    instance: HTMLFormElement | null
  ) => void | React.RefObject<HTMLFormElement> | null

  preventDefault?: boolean
  autocomplete?: boolean

  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void

  formProps?: StyledFormProps
}

const StyledForm = styled.form<StyledFormProps>`
  display: flex;
  flex-direction: column;

  & > div + div {
    margin-top: 1rem;
  }
`

export const Form: React.FC<FormProps> = ({
  id,
  children,

  preventDefault,
  autocomplete,
  formProps,

  outerRef,
  onSubmit,

  ...props
}) => {
  return (
    <StyledForm
      {...formProps}
      id={id}
      ref={outerRef}
      autoComplete={autocomplete ? 'on' : 'off'}
      onSubmit={event => {
        if (preventDefault) event.preventDefault()
        onSubmit(event)
      }}
      {...props}
    >
      {children}
    </StyledForm>
  )
}
