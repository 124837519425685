import { HandbookElementType, HierarchyEntry } from 'modules/handbook'
import { BranchMenu } from 'modules/handbook/components'
import { Icon } from '@ur/react-components'
import { Link } from 'react-router-dom'
import { Card } from 'components/Card'
import styled from 'styled-components'
import { TreeBranch } from 'components/TreeView/TreeView.types'

const Wrapper = styled(Card)`
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  position: relative;
  padding: 1.4rem 0rem;

  .icon {
    padding: 1rem;
    margin-right: 0;
  }

  &:hover {
    & .branch-menu {
      display: flex;
    }
  }

  .info {
    flex: 1 1 0%;
    h4 {
      margin: 0;
      font-size: 1.2em;
      color: ${props => props.theme.colors.primary};
      a {
        color: ${props => props.theme.colors.primary};
      }
    }
    span {
      font-size: 0.8em;
      color: ${props => props.theme.colors.gray5};
    }
  }
`

interface HandbookEntryCardProps {
  entry: HierarchyEntry
  onDelete: (id: string, type: HandbookElementType) => Promise<void>
}

const iconProps: TreeBranch['iconProps'] = {
  type: 'light',
  size: '1.6rem',
  color: 'primary',
  fixedWidth: true,
}

export const HandbookEntryCard: React.VFC<HandbookEntryCardProps> = ({
  entry,
  onDelete = () => void 0,
}) => {
  return (
    <Wrapper>
      <div className="icon">
        {/* TODO Uses FontAwesomeIcon, should use SVG? */}
        <Icon icon="info-circle" {...iconProps} />
      </div>
      <div className="info">
        <h4>
          <Link to={`/handbook/${entry.folder!.slug!}/${entry.slug}`}>
            {entry.name}
          </Link>
        </h4>

        {entry.description && <span>{entry.description}</span>}
      </div>
      <div className="menuContainer">
        <BranchMenu
          id={entry.id}
          slug={entry.slug}
          folderSlug={entry.folder?.slug}
          type="entry"
          noUpArrow={true}
          noDownArrow={true}
          onShift={() => void 0}
          onDelete={onDelete}
        />
      </div>
    </Wrapper>
  )
}
