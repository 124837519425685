import { Table } from "components/Table"
import { DriverActivityDayReportQueryReportData, DriverActivityReport } from "modules/activities/types.graphql"
import { enforceHourMinuteString } from "modules/activities/util"
import { Row } from "./ActivitiesWeekTableRow"
import styled from "styled-components"
import { useTranslate } from "@ur/react-hooks"

interface AllActivitiesSumTableProps {
  report?: DriverActivityReport | DriverActivityDayReportQueryReportData | null
}

const StyledTable = styled(Table)`
    margin-top: 1rem;
    tr {
        cursor: default;
    }
`

const StyledRow = styled(Row)`
    height: 14px;
`

export const AllActivitiesSumTable: React.FC<AllActivitiesSumTableProps> = ({ report }) => {
    const translations = useTranslate({
        paidPause: 'activities.paid-pause',
        field: 'common.field',
        sum: 'common.sum',
        totalWork: 'common.total-work',
        driverDiets: 'common.diet',
        salaryDays: 'common.salary-day',
    })
    const salaryWorkTime = report?.salaryWorkTime
    const paidPauseActive = (salaryWorkTime?.paidPauseThreshold ?? 0) > 0
    const activitySums = report?.groupedActivitySums
    return (
        <StyledTable headerMargin rowHoverBackground="gray10">
            <thead>
                <tr>
                    <th>{translations.field}</th>
                    <th>{translations.sum}</th>
                </tr>
            </thead>
            <tbody>
                <StyledRow
                    isActive={true}
                    isAbsence={false}
                    onClick={() => {}}
                >
                    <td>{translations.totalWork}</td>
                    <td>{enforceHourMinuteString(report?.timeWorkTotal)}</td>
                </StyledRow>

                {activitySums?.map((activitySum) => (
                    <StyledRow
                        key={activitySum.id}
                        isActive={true}
                        isAbsence={false}
                        onClick={() => {}}
                    >
                        <td>{activitySum.name}</td>
                        <td>{enforceHourMinuteString(activitySum.sum)}</td>
                    </StyledRow>
                ))}

                {paidPauseActive && (
                    <StyledRow
                        isActive={true}
                        isAbsence={false}
                        onClick={() => {}}
                    >
                        <td>{translations.paidPause}</td>
                        <td>{enforceHourMinuteString(report?.timePaidRest)}</td>
                    </StyledRow>
                )}

                {report?.sumDriverDiets && report?.sumDriverDiets > 0 ? (
                    <StyledRow
                        isActive={true}
                        isAbsence={false}
                        onClick={() => {}}
                    >
                        <td>{translations.driverDiets}</td>
                        <td>{report.sumDriverDiets}</td>
                    </StyledRow>
                ): <></>}

                {report?.sumSalaryDays && report?.sumSalaryDays > 0 ? (
                    <StyledRow
                        isActive={true}
                        isAbsence={false}
                        onClick={() => {}}
                    >
                        <td>{translations.salaryDays}</td>
                        <td>{report.sumSalaryDays}</td>
                    </StyledRow>
                ) : <></>}
            </tbody>
        </StyledTable>
    )
}
