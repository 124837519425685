import { CrumbInsert, useToast } from '@ur/react-components'
import {
  UpdateFormFn,
  UpdateInitialValuesFn,
  useTranslate,
} from '@ur/react-hooks'
import { useMutation, useQuery } from '@apollo/client'
import { useHistory } from 'react-router'
import { IdVariable } from 'types/graphql'
import { useOnErrorAuto } from 'util/hooks'
import {
  DELETE_MANUAL_ENTRY_MUTATION,
  PATCH_MANUAL_ENTRY_MUTATION,
} from '../mutations'
import {
  HANDBOOK_DASHBOARD_LINKS_QUERY,
  MANUAL_ENTRY_BY_SLUG_QUERY,
} from '../queries'
import {
  DashboardEntryLinkQuery,
  DeleteManualEntryMutation,
  ManualEntryBySlugQuery,
  ManualEntryBySlugQueryVariables,
  PatchManualEntryMutation,
  PatchManualEntryMutationVariables,
} from '../types.graphql'
import { EditHandbookEntryForm } from '../components/entries/types'
import { useBreadcrumbs } from 'util/hooks/useBreadcrumbs/useBreadcrumbs'
import { getIconShortcutFromManualEntries } from '../util'

export function useHandbookEntryQuery(
  entrySlug: string,
  folderSlug: string,
  updateForm: UpdateFormFn<EditHandbookEntryForm>,
  updateInitialValues: UpdateInitialValuesFn<EditHandbookEntryForm>,
  callback: (data: ManualEntryBySlugQuery) => void
) {
  const { setOverrides, setInserts } = useBreadcrumbs()
  const onErrorAuto = useOnErrorAuto()

  const { data, loading } = useQuery<
    ManualEntryBySlugQuery,
    ManualEntryBySlugQueryVariables
  >(MANUAL_ENTRY_BY_SLUG_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { entrySlug, folderSlug },
    onCompleted(data) {
      if (!data) return

      const { manualEntry: entry } = data
      const inserts: CrumbInsert[] = entry.folder.slugHierarchyWithNames
        .slice(0, -1)
        .map(folder => ({
          index: 0,
          crumb: {
            text: folder.name,
            href: `/handbook/${folder.slug}`,
          },
        }))

      const updatedForm: EditHandbookEntryForm = {
        name: entry.name,
        description: entry.description,
        richtext: entry.richtext,
        form: entry.form?.id ?? null,
      }

      updateForm(updatedForm)
      updateInitialValues(updatedForm)

      setOverrides({
        [entry.slug]: entry.name,
        [entry.folder.slug]: entry.folder.name,
      })
      setInserts(inserts)

      callback(data)
    },
    onError: onErrorAuto(),
  })

  return { data, loading }
}

export function useHandbookAllManualEntries(isDashboardLink?: boolean) {
  const { data, fetchMore, loading } = useQuery<DashboardEntryLinkQuery>(
    HANDBOOK_DASHBOARD_LINKS_QUERY,
    {
      variables: {
        isDashboardLink: isDashboardLink,
      },
    }
  )

  const entries = data?.allManualEntries.edges.map(edge => edge.node) ?? []

  return {
    entries,
    data,
    loading,

    fetchMore,
  }
}

export function useHandbookEntryMutations(
  entrySlug: string,
  folderSlug: string,
  form: EditHandbookEntryForm,
  updateForm: UpdateFormFn<EditHandbookEntryForm>,
  updateInitialValues: UpdateInitialValuesFn<EditHandbookEntryForm>,
  setEditing: (value: boolean) => void,
  resetEditedAttachments: () => void
) {
  const translations = useTranslate({
    toast: {
      entryDeleted: 'handbook.toasts.delete-entry-success',
    },
    error: {
      generic: 'error.generic-server-error',
    },
  })

  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()
  const history = useHistory()
  const [patchEntry, { loading: patchLoading }] = useMutation<
    PatchManualEntryMutation,
    PatchManualEntryMutationVariables
  >(PATCH_MANUAL_ENTRY_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: MANUAL_ENTRY_BY_SLUG_QUERY,
        variables: { entrySlug, folderSlug },
      },
    ],
    onCompleted() {
      setEditing(false)

      const updatedForm: EditHandbookEntryForm = {
        ...form,
      }
      resetEditedAttachments()
      updateForm(updatedForm)
      updateInitialValues(updatedForm)
    },
    onError: onErrorAuto(),
  })
  const [deleteEntry, { loading: deleteLoading }] = useMutation<
    DeleteManualEntryMutation,
    IdVariable
  >(DELETE_MANUAL_ENTRY_MUTATION, {
    onCompleted(data) {
      if (!data) {
        addToast('error', translations.error.generic)
        return
      }

      addToast('success', translations.toast.entryDeleted)
      history.push('/handbook')
    },
    onError: onErrorAuto(),
  })

  return {
    patch: patchEntry,
    delete: deleteEntry,
    loading: patchLoading || deleteLoading,
  }
}

export function useHandbookDashboardLinks() {
  const { entries } = useHandbookAllManualEntries(true)
  const handbookLinks = getIconShortcutFromManualEntries(entries)

  return {
    links: handbookLinks,
    nodes: entries,
  }
}
