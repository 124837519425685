import styled from 'styled-components'

export const TableSummation = styled.tr`
  width: 100%;
  height: 4.5rem;
  border-top: 0 !important;
  padding: 0 !important;

  td {
    padding: 0 !important;

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;

      height: calc(72px - 1rem);
      margin-top: -1rem;
      padding: 0 0 0 1rem;

      font-weight: 700;
      background: ${props => props.theme.colors.yellow};

      span {
        font-size: 0.7rem;
        font-weight: 600;
        text-transform: uppercase;
        color: ${props => props.theme.colors.gray5};
      }
      &.sum {
        text-transform: uppercase;
      }
    }

    &:first-child div {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }
    &:last-child div {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }
`
