import { PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { RegularModal, TextArea } from 'components'
import { Label } from 'components/Label'
import { ArchivedInfringementForm } from 'modules/infringements'
import React from 'react'
import styled from 'styled-components'

const Form = styled.div`
  display: flex;
  flex-direction: column;
`
const Field = styled.div`
  display: flex;
  flex-direction: column;

  & + div {
    margin-top: 1rem;
  }
`

interface ArchivedInfringementPromptProps {
  initialData?: ArchivedInfringementForm

  onResolve: PromptResolve<ArchivedInfringementForm | null>
}

export const ArchiveInfringementPrompt: React.VFC<ArchivedInfringementPromptProps> =
  ({
    initialData = {
      archivedBasis: '',
    },

    onResolve,
  }) => {
    const translations = useTranslate({
      archiveInfringement: 'infringements.archive-infringement',
      basis: 'common.basis',

      cancel: 'common.cancel',
      submit: 'common.submit',

      error: {
        notEmpty: 'common.form-error',
      },
    })

    const {
      formValues: form,
      formErrors: errors,
      formValid,
      formChangeHandler: handler,
      submitHandler,
    } = useForm<ArchivedInfringementForm>({
      values: initialData,
      validators: {
        archivedBasis: val =>
          !!val.trim() ? null : translations.error.notEmpty,
      },
    })

    return (
      <RegularModal
        title={translations.archiveInfringement}
        width="600px"
        cancelText={translations.cancel}
        submitText={translations.submit}
        submitDisabled={!formValid}
        onCancel={() => onResolve(null)}
        onSubmit={submitHandler(onResolve)}
      >
        <Form>
          <Field>
            <Label>{translations.basis}</Label>
            <TextArea
              value={form.archivedBasis}
              error={errors.archivedBasis}
              fullWidth
              autoFocus
              onChange={handler('archivedBasis')}
            />
          </Field>
        </Form>
      </RegularModal>
    )
  }
