import { ComponentSwitch } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import {
  Button,
  Checkbox,
  DateRangePicker,
  FAIcon,
  MonthPicker,
  Select,
  Toggle,
  UserSelect,
} from 'components'
import { ExportCardProps, Field, Form, Section } from 'modules/companies'
import { responsiveExportGridAreas } from 'modules/companies/util'
import { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { handleDateRangeChange } from 'util/date/handleDateRangeChange'
import { useExportOptions } from 'modules/exports/hooks'
import { useExportInfringementsForm } from '../../hooks'
import { ExportSectionHeader, MassExportSectionHeader } from './components'

export const ExportInfringementsCard: React.VFC<ExportCardProps> = ({
  loading,
  onLoadingChangedCallback,
}) => {
  const translations = useTranslate({
    infringements: 'common.infringement',
    exportAll: 'infringements.export-infringement-data',

    period: 'common.period',
    exportType: 'common.type',
    exportOption: 'common.export-type',

    hours: 'common.hours',
    diet: 'common.diet',

    export: 'common.export',
    user: 'common.user',
    exportCompany: 'infringements.export.all',
  })

  const {
    loading: formLoading,
    form,
    errors,
    submit,
    updateForm,
    handleExport,
    handler,
  } = useExportInfringementsForm(() => onLoadingChangedCallback(false))

  const exportOptions = useExportOptions()

  useEffect(() => {
    if (!formLoading || loading === formLoading) return
    onLoadingChangedCallback(formLoading)
  }, [formLoading, loading, onLoadingChangedCallback])
  const areas = !isMobile
    ? `
        ${responsiveExportGridAreas(true)}
        'selectAll selectAll selectAll'
      `
    : `
        ${responsiveExportGridAreas(true)}
        'selectAll'
        'selectAll'
      `

  return (
    <>
      <ExportSectionHeader>{translations.infringements}</ExportSectionHeader>
      <Section>
        <MassExportSectionHeader loading={loading}>
          {translations.exportAll}
          <Toggle
            value={form.useDateRange}
            thumbBackground="secondary"
            iconMode="svg"
            localOnIconProps={{
              icon: 'calendar-selection',
              fill: form.useDateRange ? 'white' : 'primary',
            }}
            localOffIconProps={{
              icon: 'calendar-month',
              fill: !form.useDateRange ? 'white' : 'primary',
            }}
            onChange={() => handler('useDateRange')(!form.useDateRange)}
          />
        </MassExportSectionHeader>

        <Form
          grid={{
            columns: !isMobile ? '3fr 2fr' : '1fr',
            areas: areas,
          }}
        >
          <Field area="month">
            <label>{translations.period}</label>
            <ComponentSwitch>
              <ComponentSwitch.Case case={form.useDateRange}>
                <DateRangePicker
                  value={[form.dateStart, form.dateEnd]}
                  grayed={false}
                  onChange={handleDateRangeChange(updateForm)}
                />
              </ComponentSwitch.Case>
              <ComponentSwitch.Case default>
                <MonthPicker
                  value={form.month}
                  light
                  futureYears={0}
                  noYearCycle
                  onChange={handler('month')}
                />
              </ComponentSwitch.Case>
            </ComponentSwitch>
          </Field>

          <Field area="exportOption">
            <label>{translations.exportOption}</label>
            <Select
              value={form.exportOption}
              options={exportOptions}
              fullWidth
              height="3.875rem"
              onChange={handler('exportOption')}
            />
          </Field>
          <Field area="selectAll">
            <label>{translations.exportCompany}</label>
            <div>
              <Checkbox
                checked={form.exportMass}
                onChange={() => {
                  updateForm({ exportMass: !form.exportMass, user: '' })
                }}
                label={translations.exportCompany}
                inline
              />
            </div>
          </Field>

          {!form.exportMass && (
            <Field area="user">
              <label>{translations.user}</label>
              <UserSelect
                value={form.user ?? ''}
                error={errors.user}
                fullWidth
                dropdownPageName="activities"
                height="3.875rem"
                onChange={handler('user')}
              />
            </Field>
          )}
          <Field alignRight className="submit" area="button">
            <Button
              type="submit"
              onClick={submit(handleExport)}
              disabled={loading}
            >
              <FAIcon
                icon="download"
                type="light"
                color="primary"
                margin="0 0.5ch 0 0"
              />
              {translations.export}
            </Button>
          </Field>
        </Form>
      </Section>
    </>
  )
}
