const pl = {
  'activities.add-salary-day': 'NEEDS TRANSLATION',
  'activities.approve-day': 'Zatwierdź działania na {date}?',
  'activities.approve-month':
    'Zatwierdzić działania na {month} ({dateStart} - {dateEnd})?',
  'activities.approve-title': 'Zatwierdź działania',
  'activities.approve-week':
    'Zatwierdzić działania na tydzień {week} ({dateStart} - {dateEnd})?',
  'activities.availability-time': 'Czas dostępności',
  'activities.consumption': 'Zużycie paliwa',
  'activities.day-end': 'Koniec dnia',
  'activities.day-start': 'Początek dnia',
  'activities.driver': 'Kierowca',
  'activities.driving': 'Prowadzenie',
  'activities.driving-grade': 'Klasa jazdy',
  'activities.end-position':
    '{abbreviate, select, true {End pos.} other {End position}}',
  'activities.errors.could-not-load-route-in-map':
    'Nie można wczytać trasy na mapie',
  'activities.errors.general-error':
    'Coś poszło nie tak podczas pobierania działań kierowcy',
  'activities.errors.select-multiple-users':
    'Wybierz przynajmniej jednego użytkownika do eksportu',
  'activities.errors.user-not-found': 'Nie znaleziono użytkownika',
  'activities.export-driving-data': 'Eksportuj dane dotyczące jazdy',
  'activities.export-monthly-weekly':
    'Eksport podzielony na miesiące lub tygodnie',
  'activities.fetch-data': 'Pobierz dane dotyczące jazdy',
  'activities.fuel-usage': 'Zużycie paliwa',
  'activities.last-fetched': 'Ostatnie pobranie',
  'activities.missing-data': 'Brakujące dane',
  'activities.module-title': 'Dane dotyczące jazdy',
  'activities.paid-pause': 'NEEDS TRANSLATION',
  'activities.pause-export': 'NEEDS TRANSLATION',
  'activities.print-driving-data': 'Drukuj działania',
  'activities.regnr': 'NrRej',
  'activities.rest': 'Odpoczynek',
  'activities.start-position':
    '{abbreviate, select, true {Start pos.} other {Start position}}',
  'activities.starting-activity-fetching-process':
    'Rozpoczęcie procesu pobierania działania',
  'activities.success.approve-activities': 'Działania zatwierdzone',
  'activities.sum-for-month-title': 'Suma za {month}',
  'activities.sum-next-month':
    'Podsumowanie tygodnia {week} jest obliczane w następnym miesiącu',
  'activities.sum-overtime': 'Łączna liczba nadgodzin',
  'activities.updated-diet-successfully': 'Pomyślnie zaktualizowano dietę',
  'activities.updated-salary-day-successfully': 'NEEDS TRANSLATION',
  'activities.work': 'Praca',
  'activities.work-summary': 'Podsumowanie pracy',
  'activities.working-hours': 'Godziny pracy',
  'admin-required.message': 'Musisz być administratorem, aby to zobaczyć.',
  'auth.admin-or-user-required':
    'Aby to zobaczyć, musisz być {user} lub administratorem.',
  'change-password.description':
    'Ze względów bezpieczeństwa przed zalogowaniem musisz zmienić hasło.',
  'change-password.title': 'Zmień hasło',
  'checklists.all-checklist-templates': 'Wszystkie szablony list kontrolnych',
  'checklists.checklist-item': 'Element listy kontrolnej',
  'checklists.checklist-template': 'Szablon listy kontrolnej',
  'checklists.generate-new': 'Generuj nowy',
  'checklists.no-checklists': 'Brak dostępnych list kontrolnych.',
  'commmon.edit-name': 'Edytuj nazwę',
  'common.about': 'O',
  'common.absence': 'Nieobecność',
  'common.absence-types': 'Typy nieobecności',
  'common.absences': 'Nieobecność',
  'common.action': 'NEEDS TRANSLATION',
  'common.active': 'Czynny',
  'common.activities': 'Dane dotyczące jazdy',
  'common.activity': 'Czynność',
  'common.add': 'Dodaj',
  'common.add-attachment': 'Dodaj załącznik',
  'common.add-attachments': 'Dodaj załączniki',
  'common.add-column': 'Dodaj kolumnę',
  'common.add-file': 'Dodaj plik',
  'common.add-file-alt': 'Dodaj plik',
  'common.add-files': 'Dodaj pliki',
  'common.additional-pay': 'NEEDS TRANSLATION',
  'common.address': 'Adres',
  'common.all': 'Wszystkie',
  'common.ambulance': 'Pogotowie ratunkowe',
  'common.app': 'Aplikacja',
  'common.archive': 'Archiwum',
  'common.archived': 'Zarchiwizowane',
  'common.article': 'Artykuł',
  'common.attachments': 'Załączniki',
  'common.author': 'Autor',
  'common.availability': 'Dostępność',
  'common.basis': 'Podstawa',
  'common.both': 'Obydwa',
  'common.business': 'Biznes',
  'common.businessOverview': 'Przegląd firmy',
  'common.by-name': 'Przez {name}',
  'common.cancel': 'Anuluj',
  'common.cancel-changes': 'Anuluj zmiany',
  'common.categories': 'Kategorie',
  'common.category': 'Kategoria',
  'common.category-for-type': 'Kategoria dla {type}',
  'common.change-company': 'Zmień firmę',
  'common.change-language': 'Zmień język',
  'common.change-password': 'Zmień hasło',
  'common.changes-saved': 'Zmiany zostały zapisane',
  'common.checklist-templates': 'Szablony list kontrolnych',
  'common.checklists': 'Listy kontrolne',
  'common.choose-ellipsis': 'Wybierz...',
  'common.city': 'Miasto',
  'common.close': 'Zamknij',
  'common.closed': 'Zamknięte',
  'common.codes': 'Kody',
  'common.color': 'Kolor',
  'common.comment': 'Komentarz',
  'common.comment-ellipsis': 'Komentarz...',
  'common.comments': 'Komentarze',
  'common.company': 'Firma',
  'common.company-name': 'Nazwa firmy',
  'common.contact-info': 'Informacje kontaktowe',
  'common.contact-person': 'Osoba kontaktowa',
  'common.content': 'Treść',
  'common.count': 'Liczba',
  'common.count-of-total': '{count} z {total}',
  'common.count-of-total-verbose': 'Widziało {count}/{total} pracowników',
  'common.countries': 'Kraje',
  'common.country': 'Kraj',
  'common.create': 'Utwórz',
  'common.create-alt': 'Utwórz',
  'common.create-entry': 'Utwórz nowy wpis',
  'common.create-folder': 'Utwórz nowy folder',
  'common.create-form': 'Utwórz nowy formularz',
  'common.create-form-entry': 'Utwórz nowy wpis w formularzu',
  'common.create-type': 'Utwórz {type}',
  'common.creating': 'Tworzenie',
  'common.csv': 'CSV',
  'common.current-password': 'Aktualne hasło',
  'common.daily': 'Codziennie',
  'common.dashboard': 'Panel kontrolny',
  'common.data': 'Dane',
  'common.data-distribution': 'NEEDS TRANSLATION',
  'common.date': 'Data',
  'common.date-for-type': 'Data dla {type}',
  'common.date-validation-not-in-future':
    'Data musi być ważna i nie przypadać w przyszłości',
  'common.day-end': 'Koniec dnia',
  'common.day-start': 'Początek dnia',
  'common.days': '{days, plural, =0 {No days} one {# day} other {# days}}',
  'common.deactivated': 'Dezaktywowany',
  'common.default-severity': 'NEEDS TRANSLATION',
  'common.delete': 'Usuń',
  'common.delete-alt': 'Usuń',
  'common.delete-type': 'Usuń {type}',
  'common.department': 'Departament',
  'common.department-expandable-text': 'NEEDS TRANSLATION',
  'common.departments': 'Departamenty',
  'common.description': 'Opis',
  'common.detail': 'Szczegóły',
  'common.details': 'Szczegóły',
  'common.diet': 'Dieta',
  'common.disabled': 'Wyłączony',
  'common.distance': 'Odległość',
  'common.document': 'Dokument',
  'common.documentation': 'Dokumentacja',
  'common.documentationIssues': 'Problemy z dokumentacją',
  'common.documents': 'Dokumenty',
  'common.download': 'Pobierz',
  'common.driver': 'Kierowca',
  'common.drivers': 'Kierowcy',
  'common.drivers-handbook': 'Podręcznik kierowcy',
  'common.driversHandbook': 'Podręcznik kierowcy',
  'common.driversHandbookForm': 'Formularze podręczników',
  'common.driving': 'Prowadzenie',
  'common.driving-data': 'Dane dotyczące jazdy',
  'common.dropdown': 'Rozwijanie',
  'common.dropdown-on-pages': 'Lista użytkownika na stronie',
  'common.dropdowns': 'Listy rozwijane',
  'common.edit': 'Edytuj',
  'common.edit-alt': 'Edytuj',
  'common.edit-entry': 'Edytuj wpis',
  'common.edit-name': 'Edytuj nazwę',
  'common.edit-type': 'Edytuj {type}',
  'common.email': 'E-mail',
  'common.email-already-selected': 'E-mail jest już wybrany',
  'common.employee': 'Pracownik',
  'common.enabled': 'Włączone',
  'common.end': 'Koniec',
  'common.end-date': 'Data końcowa',
  'common.end-time': 'Czas zakończenia',
  'common.enter-url': 'Wprowadź adres URL',
  'common.entry': 'Wpis',
  'common.eurovignette': 'Eurowinieta',
  'common.eurovignettes': 'Eurowiniety',
  'common.excel': 'Excel',
  'common.excel-flat': 'Excel (jeden arkusz)',
  'common.expired': 'Wygasł',
  'common.expires-at': 'Wygasa o',
  'common.export': 'Eksport',
  'common.export-format': 'Format',
  'common.export-type': 'Typ eksportu',
  'common.exports-settings': 'Eksportuj',
  'common.external-id': 'NEEDS TRANSLATION',
  'common.fats': 'NEEDS TRANSLATION',
  'common.fats-beta': 'NEEDS TRANSLATION',
  'common.field': 'NEEDS TRANSLATION',
  'common.file': 'Plik',
  'common.files': 'Pliki',
  'common.filter': 'Filter',
  'common.finished': 'Skończone',
  'common.fire': 'Ogień',
  'common.firstName': 'Imie',
  'common.folder': 'Folder',
  'common.form-error':
    'Jedno lub więcej pól zostało wypełnionych nieprawidłowo',
  'common.form-responses': 'Wypełnione formularze',
  'common.formcreator': 'Twórca formularza',
  'common.forms': 'Formy',
  'common.forum': 'Forum',
  'common.forum-posts': 'NEEDS TRANSLATION',
  'common.forum-threads': 'NEEDS TRANSLATION',
  'common.freight-carrier': 'Przewoźnik towarowy',
  'common.from': 'Od',
  'common.general': 'Ogólny',
  'common.generate': 'NEEDS TRANSLATION',
  'common.generate-report': 'NEEDS TRANSLATION',
  'common.go-back': 'Wróć',
  'common.handbook': 'Podręcznik',
  'common.height': 'Wysokość',
  'common.height-in-cm': 'Wysokość w cm',
  'common.high': 'NEEDS TRANSLATION',
  'common.holiday-allowances': 'NEEDS TRANSLATION',
  'common.home': 'Do domu',
  'common.hourlist': 'Karty czasu pracy',
  'common.hours': 'Godziny',
  'common.hours-abbrev': 'g',
  'common.hours-lowercase': 'godziny',
  'common.id': 'Id',
  'common.in-app': 'W aplikacji',
  'common.include-archived': 'NEEDS TRANSLATION',
  'common.information': 'Informacja',
  'common.infringement': 'Naruszenie',
  'common.infringements': 'Naruszenia',
  'common.integrations': 'Integracje',
  'common.invalid': 'Nieprawidłowy',
  'common.invalid-date': 'Nieprawidłowa data',
  'common.invalid-url': 'Nieprawidłowy adres URL',
  'common.issue': 'Problem',
  'common.issue-categories': 'Kategorie problemów',
  'common.issued-at': 'Wydano o',
  'common.issues': 'Problemy',
  'common.items': 'Przedmioty',
  'common.kilometers': '{abbreviate, select, true {km} other {kilometers}}',
  'common.km': 'km',
  'common.km-per-hour-short': 'km/h',
  'common.language': 'Język',
  'common.last-revision': 'NEEDS TRANSLATION',
  'common.lastName': 'Nazwisko',
  'common.length': 'Długość',
  'common.length-in-cm': 'Długość w cm',
  'common.liters': 'Litry',
  'common.liters-short': 'l',
  'common.load-more': 'Załaduj więcej',
  'common.load-previous': 'Załaduj poprzedni',
  'common.loading': 'Ładowanie',
  'common.loading...': 'Ładowanie...',
  'common.location': 'Lokalizacja',
  'common.log-in': 'Zaloguj się',
  'common.log-out': 'Wyloguj się',
  'common.login': 'Zaloguj się',
  'common.low': 'NEEDS TRANSLATION',
  'common.make-a-choice': 'Dokonaj wyboru',
  'common.make-a-selection': 'Proszę dokonać wyboru',
  'common.manual': 'NEEDS TRANSLATION',
  'common.map': 'Mapa',
  'common.mass-print': 'Wydrukuj masę',
  'common.maximum': 'Maksymalne',
  'common.medium': 'NEEDS TRANSLATION',
  'common.menu': 'Menu',
  'common.minimum': 'Minimum',
  'common.minutes': 'Minuty',
  'common.minutes-abbrev': 'm',
  'common.module': 'Moduł',
  'common.month': 'Miesiąc',
  'common.monthly': 'Miesięcznie',
  'common.move-down': 'Przesuń w dół',
  'common.move-up': 'Przesuń w górę',
  'common.n-categories': 'NEEDS TRANSLATION',
  'common.n-hours': '{n} godzin',
  'common.n-issues': '{n} problemów',
  'common.n-minutes': '{n} minut',
  'common.n-salary-code': '{n} kody wynagrodzeń',
  'common.name': 'Nazwa',
  'common.needs-comment': 'Wymaga komentarza',
  'common.new-password': 'Nowe hasło',
  'common.new-password-again': 'Znowu nowe hasło',
  'common.no': 'Nie',
  'common.no-active-modules': 'Brak czynnych modułów',
  'common.no-attachments': 'Brak załączników',
  'common.no-changes': 'Brak zmian',
  'common.no-comment': 'Brak komentarza',
  'common.no-more-options': 'Nie ma więcej opcij',
  'common.no-options-to-choose-from': 'Brak opcij do wyboru',
  'common.no-results': 'Brak wyników',
  'common.none': 'Brak',
  'common.not-archived': 'Nie zarchiwizowano',
  'common.not-verified-yet': 'Jeszcze nie zweryfikowano',
  'common.notification': 'Powiadomienie',
  'common.notifications': 'Powiadomienia',
  'common.notifications-alt': 'NEEDS TRANSLATION',
  'common.office': 'Biuro',
  'common.ok': 'OK',
  'common.open': 'Otwórz',
  'common.open-alt': 'Otwórz',
  'common.opened': 'Otwarte',
  'common.order': 'Zamówienie',
  'common.order-by': 'NEEDS TRANSLATION',
  'common.ordinary': 'NEEDS TRANSLATION',
  'common.other': 'Inne',
  'common.other-work': 'Inna praca',
  'common.overtime': 'Nadgodziny',
  'common.overtime-mode': 'Tryb nadgodzin',
  'common.overtime-rate': 'Stawka za nadgodziny',
  'common.password': 'Hasło',
  'common.pause': 'Pauza',
  'common.pdf': 'PDF',
  'common.pdf-salary': 'NEEDS TRANSLATION',
  'common.period': 'Okres',
  'common.perks': 'Korzyści',
  'common.permissions': 'Uprawnienia',
  'common.phone': 'Telefon',
  'common.phone-number': 'Numer telefonu',
  'common.phone-prefix': 'Prefiks kraju',
  'common.police': 'Policja',
  'common.postal-code': 'Kod pocztowy',
  'common.poweroffice': 'NEEDS TRANSLATION',
  'common.previous-responses': 'Poprzednie odpowiedzi',
  'common.print': 'Drukuj',
  'common.print-type': 'Drukuj {type}',
  'common.priority': 'Priorytet',
  'common.privacy': 'Prywatność',
  'common.project': 'Projekt',
  'common.publish': 'Opublikuj',
  'common.published': 'NEEDS TRANSLATION',
  'common.range': 'Zakres',
  'common.reason': 'Powód',
  'common.reg-nr': 'Num.Rej',
  'common.register': 'Zarejestruj się',
  'common.remove': 'Usuń',
  'common.report': 'Raport',
  'common.required': 'Wymagane',
  'common.reset': 'Resetuj',
  'common.responses': 'Odpowiedzi',
  'common.responsible': 'Odpowiedzialny',
  'common.responsible-user': 'Odpowiedzialny użytkownik',
  'common.responsible-users': 'NEEDS TRANSLATION',
  'common.rest': 'Odpoczynek',
  'common.restore': 'Przywróć',
  'common.risk-aspect': 'NEEDS TRANSLATION',
  'common.risk-aspects': 'NEEDS TRANSLATION',
  'common.role': 'Rola',
  'common.route-planner': 'Planowanie trasy',
  'common.salary': 'Wynagrodzenie',
  'common.salary-code': 'NEEDS TRANSLATION',
  'common.salary-codes': 'Kody wynagrodzeń',
  'common.salary-day': 'NEEDS TRANSLATION',
  'common.salary-mode': 'NEEDS TRANSLATION',
  'common.save': 'Zapisz',
  'common.save-and-close': 'Zapisz i zamknij',
  'common.save-changes': 'Zapisz zmiany',
  'common.save-changes-to-type': 'Zapisz zmiany do {type}',
  'common.save-in-user-exports': 'Zapisz w moich eksportach',
  'common.save-languages': 'Zapisz język',
  'common.saving': 'Ochrona',
  'common.search': 'Szukaj...',
  'common.seconds-abbrev': 's',
  'common.see-all-responses': 'Zobacz wszystkie odpowiedzi',
  'common.seen-by': 'Widział przez',
  'common.select': 'Wybierz',
  'common.select-all': 'Wybierz wszystko',
  'common.select-at-least-one-recipient':
    'Wybierz przynajmniej jednego odbiorcę',
  'common.select-manual-entry': 'Wybierz wpis ręczny',
  'common.select-project': 'Wybierz projekt',
  'common.select-salary-code': 'Wybierz kod wynagrodzenia',
  'common.select-user': 'Wybierz użytkownika',
  'common.select-users': 'Wybierz użytkowników',
  'common.select-week-days': 'Wybierz dni tygodnia',
  'common.send-print-to-mail': 'Wysłać wydruk na pocztę?',
  'common.settings': 'Ustawienia',
  'common.severity': 'Ważność',
  'common.shortcuts': 'Skróty',
  'common.show-all': 'Pokaż wszystko',
  'common.show-sums': 'NEEDS TRANSLATION',
  'common.singular': 'Pojedyncza',
  'common.sms': 'SMS',
  'common.sort-by-user-type': 'Sortuj według typu użytkownika',
  'common.start': 'Początek',
  'common.start-date': 'Data rozpoczęcia',
  'common.start-time': 'Czas rozpoczęcia',
  'common.statistics': 'Statystyki',
  'common.statistics-this-week': 'NEEDS TRANSLATION',
  'common.status': 'Status',
  'common.submit': 'Prześlij',
  'common.sum': 'Suma',
  'common.sums': 'NEEDS TRANSLATION',
  'common.terminal': 'Terminal',
  'common.terminal-network': 'Sieć terminali',
  'common.the-email': 'E-mail',
  'common.the-field': 'Pole',
  'common.thread': 'Wątek',
  'common.time': 'Czas',
  'common.time-entries': 'Arkusze czasu pracy',
  'common.time-entry': 'Wprowadzanie czasu',
  'common.time-interval': 'Przedział czasowy',
  'common.timesheets': 'Wpisy czasu',
  'common.timesinceposted': 'Wysłano',
  'common.timestamp': 'Znacznik czasu',
  'common.title': 'Tytuł',
  'common.total-work': 'NEEDS TRANSLATION',
  'common.tours': 'Wycieczki',
  'common.tripletex': 'NEEDS TRANSLATION',
  'common.tv-display': 'TV-display',
  'common.twentyfour-seven-office': 'NEEDS TRANSLATION',
  'common.two-weekly': 'Dwa tygodnie',
  'common.type': 'Typ',
  'common.unavailable': 'Niedostępny',
  'common.unknown': 'Nieznany',
  'common.until': 'Do',
  'common.untitled': 'Bez tytułu',
  'common.update': 'Aktualizacja',
  'common.update-entry': 'Aktualizuj wpis',
  'common.update-folder': 'Folder aktualizacji',
  'common.update-form': 'Formularz aktualizacji',
  'common.update-form-entry': 'Aktualizuj wpis w formularzu',
  'common.update-password': 'Aktualizuj hasło',
  'common.update-type': 'Aktualizuj {type}',
  'common.updated-at': 'Aktualizacja o',
  'common.updating': 'Aktualizacja',
  'common.uploaded': 'Przesłano',
  'common.uploaded-attachments': 'Przesłane załączniki',
  'common.user': 'Użytkownik',
  'common.user-document-categories': 'NEEDS TRANSLATION',
  'common.user-documents': 'Dokumenty użytkownika',
  'common.user-type': 'Typ użytkownika',
  'common.user-types': 'Typy użytkowników',
  'common.username': 'Nazwa użytkownika',
  'common.users': 'Użytkownicy',
  'common.usersettings': 'Ustawienia użytkownika',
  'common.value': 'Wartość',
  'common.vehicle': 'Pojazd',
  'common.vehicle-expandable-text': '{amount} pojazdów',
  'common.vehicle-fleet': 'Flota pojazdów',
  'common.verified': 'Zweryfikowano',
  'common.view-comment': 'Wyświetl komentarz',
  'common.visma-payroll': 'NEEDS TRANSLATION',
  'common.week': 'Tydzień',
  'common.week-n': 'Tydzień {n}',
  'common.weekly': 'Co tydzień',
  'common.weeks': '{weeks, plural, =0 {No weeks} one {# week} other {# weeks}}',
  'common.weight': 'Waga',
  'common.when-by-whom': '{when} przez {whom}',
  'common.whole-day': 'Cały dzień',
  'common.width': 'Szerokość',
  'common.width-in-cm': 'Szerokość w cm',
  'common.work': 'Praca',
  'common.work-hours': 'Godziny pracy',
  'common.year': 'Rok',
  'common.yes': 'Tak',
  'common.you-have-unsaved-changes': 'Masz niezapisane zmiany',
  'common.you-have-unsaved-changes-navigate':
    'Masz niezapisane zmiany. Czy na pewno chcesz opuścić tę stronę?',
  'companies.create-overtime-period': 'Utwórz okres nadgodzin',
  'companies.create-period-addon': 'NEEDS TRANSLATION',
  'companies.edit-overtime-period': 'Edytuj okres pracy w nadgodzinach',
  'companies.edit-period-addon': 'NEEDS TRANSLATION',
  'companies.existing-overtime-period': 'Istniejący okres nadgodzin',
  'companies.overlapping-periods-are-not-allowed':
    'Nakładające się okresy są niedozwolone',
  'companies.toasts.department-created': 'Utworzono departament',
  'companies.toasts.department-deleted': 'Departament usunięty',
  'companies.toasts.department-updated': 'Departament zaktualizowany',
  'companies.toasts.holiday-allowance-created': 'NEEDS TRANSLATION',
  'companies.toasts.holiday-allowance-deleted': 'NEEDS TRANSLATION',
  'companies.toasts.holiday-allowance-updated': 'NEEDS TRANSLATION',
  'companies.toasts.salary-code-created': 'Utworzono kod wynagrodzenia',
  'companies.toasts.salary-code-deleted':
    'Kod wynagrodzenia został pomyślnie usunięty',
  'companies.toasts.salary-code-updated': 'Zaktualizowano kod wynagrodzenia',
  'countries.codes.AC': 'Wyspa Wniebowstąpienia',
  'countries.codes.AD': 'Andora',
  'countries.codes.AE': 'Zjednoczone Emiraty Arabskie',
  'countries.codes.AF': 'Afganistan',
  'countries.codes.AG': 'Antigua & Barbuda',
  'countries.codes.AI': 'Anguilla',
  'countries.codes.AL': 'Albania',
  'countries.codes.AM': 'Armenia',
  'countries.codes.AO': 'Angola',
  'countries.codes.AQ': 'Antarktyda',
  'countries.codes.AR': 'Argentyna',
  'countries.codes.AS': 'Samoa Amerykańskie',
  'countries.codes.AT': 'Austria',
  'countries.codes.AU': 'Australia',
  'countries.codes.AW': 'Aruba',
  'countries.codes.AX': 'Wyspy Alandzkie',
  'countries.codes.AZ': 'Azerbejdżan',
  'countries.codes.BA': 'Bośnia i Hercegowina',
  'countries.codes.BB': 'Barbados',
  'countries.codes.BD': 'Bangladesz',
  'countries.codes.BE': 'Belgia',
  'countries.codes.BF': 'Burkina Faso',
  'countries.codes.BG': 'Bulgaria',
  'countries.codes.BH': 'Bahrajn',
  'countries.codes.BI': 'Burundi',
  'countries.codes.BJ': 'Benin',
  'countries.codes.BL': 'Saint-Barthélemy',
  'countries.codes.BM': 'Bermudy',
  'countries.codes.BN': 'Brunei',
  'countries.codes.BO': 'Boliwia',
  'countries.codes.BQ': 'Karaibska Holandia',
  'countries.codes.BR': 'Brazylia',
  'countries.codes.BS': 'Bahamy',
  'countries.codes.BT': 'Bhutan',
  'countries.codes.BV': 'Wyspa Bouveta',
  'countries.codes.BW': 'Botswana',
  'countries.codes.BY': 'Białoruś',
  'countries.codes.BZ': 'Belize',
  'countries.codes.CA': 'Kanada',
  'countries.codes.CC': 'Wyspy Kokosowe (Keelinga)',
  'countries.codes.CD': 'Kongo – Kinszasa',
  'countries.codes.CF': 'Republika Środkowoafrykańska',
  'countries.codes.CG': 'Kongo – Brazzaville',
  'countries.codes.CH': 'Szwajcaria',
  'countries.codes.CI': 'Wybrzeże Kości Słoniowej',
  'countries.codes.CK': 'Wyspy Cooka',
  'countries.codes.CL': 'Chile',
  'countries.codes.CM': 'Kamerun',
  'countries.codes.CN': 'Chiny',
  'countries.codes.CO': 'Kolumbia',
  'countries.codes.CP': 'Wyspa Clippertona',
  'countries.codes.CR': 'Kostaryka',
  'countries.codes.CU': 'Kuba',
  'countries.codes.CV': 'Wyspy Zielonego Przylądka',
  'countries.codes.CW': 'Curacao',
  'countries.codes.CX': 'Wyspa Bożego Narodzenia',
  'countries.codes.CY': 'Cypr',
  'countries.codes.CZ': 'Czechy',
  'countries.codes.DE': 'Niemcy',
  'countries.codes.DG': 'Diego Garcia',
  'countries.codes.DJ': 'Dżibuti',
  'countries.codes.DK': 'Dania',
  'countries.codes.DM': 'Dominika',
  'countries.codes.DO': 'Republika Dominikany',
  'countries.codes.DZ': 'Algeria',
  'countries.codes.EA': 'Ceuta i Melilla',
  'countries.codes.EC': 'Ekwador',
  'countries.codes.EE': 'Estonia',
  'countries.codes.EG': 'Egipt',
  'countries.codes.EH': 'Sahara Zachodnia',
  'countries.codes.ER': 'Erytrea',
  'countries.codes.ES': 'Hiszpania',
  'countries.codes.ET': 'Etiopia',
  'countries.codes.EU': 'Unia Europejska',
  'countries.codes.FI': 'Finlandia',
  'countries.codes.FJ': 'Fidżi',
  'countries.codes.FK': 'Falklandy',
  'countries.codes.FM': 'Mikronezja',
  'countries.codes.FO': 'Wyspy Owcze',
  'countries.codes.FR': 'Francja',
  'countries.codes.GA': 'Gabon',
  'countries.codes.GB': 'Wielka Brytania',
  'countries.codes.GD': 'Grenada',
  'countries.codes.GE': 'Gruzja',
  'countries.codes.GF': 'Gujana Francuska',
  'countries.codes.GG': 'Guernsey',
  'countries.codes.GH': 'Ghana',
  'countries.codes.GI': 'Gibraltar',
  'countries.codes.GL': 'Grenlandia',
  'countries.codes.GM': 'Gambia',
  'countries.codes.GN': 'Gwinea',
  'countries.codes.GP': 'Gwadelupa',
  'countries.codes.GQ': 'Gwinea Równikowa',
  'countries.codes.GR': 'Grecja',
  'countries.codes.GS': 'Georgia Południowa i Sandwich Południowy',
  'countries.codes.GT': 'Gwatemala',
  'countries.codes.GU': 'Guam',
  'countries.codes.GW': 'Gwinea-Bissau',
  'countries.codes.GY': 'Gujana',
  'countries.codes.HK': 'SAR Hongkong, Chiny',
  'countries.codes.HM': 'Wyspy Hearda i McDonalda',
  'countries.codes.HN': 'Honduras',
  'countries.codes.HR': 'Chorwacja',
  'countries.codes.HT': 'Haiti',
  'countries.codes.HU': 'Węgry',
  'countries.codes.IC': 'Wyspy Kanaryjskie',
  'countries.codes.ID': 'Indonezja',
  'countries.codes.IE': 'Irlandia',
  'countries.codes.IL': 'Izrael',
  'countries.codes.IM': 'Wyspa Man',
  'countries.codes.IN': 'Indie',
  'countries.codes.IO': 'Brytyjskie Terytorium Oceanu Indyjskiego',
  'countries.codes.IQ': 'Irak',
  'countries.codes.IR': 'Iran',
  'countries.codes.IS': 'Islandia',
  'countries.codes.IT': 'Włochy',
  'countries.codes.JE': 'Jersey',
  'countries.codes.JM': 'Jamajka',
  'countries.codes.JO': 'Jordania',
  'countries.codes.JP': 'Japonia',
  'countries.codes.KE': 'Kenia',
  'countries.codes.KG': 'Kirgistan',
  'countries.codes.KH': 'Kambodża',
  'countries.codes.KI': 'Kiribati',
  'countries.codes.KM': 'Komory',
  'countries.codes.KN': 'Św. Kitts i Nevis',
  'countries.codes.KP': 'Korea Północna',
  'countries.codes.KR': 'Korea Południowa',
  'countries.codes.KW': 'Kuwejt',
  'countries.codes.KY': 'Kajmany',
  'countries.codes.KZ': 'Kazachstan',
  'countries.codes.LA': 'Laos',
  'countries.codes.LB': 'Liban',
  'countries.codes.LC': 'St. Lucja',
  'countries.codes.LI': 'Liechtenstein',
  'countries.codes.LK': 'Sri Lanka',
  'countries.codes.LR': 'Liberia',
  'countries.codes.LS': 'Lesoto',
  'countries.codes.LT': 'Litwa',
  'countries.codes.LU': 'Luksemburg',
  'countries.codes.LV': 'Łotwa',
  'countries.codes.LY': 'Libia',
  'countries.codes.MA': 'Moroko',
  'countries.codes.MC': 'Monako',
  'countries.codes.MD': 'Mołdawia',
  'countries.codes.ME': 'Czarnogóra',
  'countries.codes.MF': 'Saint Martin',
  'countries.codes.MG': 'Madagaskar',
  'countries.codes.MH': 'Wyspy Marshalla',
  'countries.codes.MK': 'Macedonia',
  'countries.codes.ML': 'Mali',
  'countries.codes.MM': 'Myanmar (Birma)',
  'countries.codes.MN': 'Mongolia',
  'countries.codes.MO': 'SAR Makau, Chiny',
  'countries.codes.MP': 'NEEDS TRANSLATION',
  'countries.codes.MQ': 'NEEDS TRANSLATION',
  'countries.codes.MR': 'NEEDS TRANSLATION',
  'countries.codes.MS': 'NEEDS TRANSLATION',
  'countries.codes.MT': 'NEEDS TRANSLATION',
  'countries.codes.MU': 'NEEDS TRANSLATION',
  'countries.codes.MV': 'NEEDS TRANSLATION',
  'countries.codes.MW': 'NEEDS TRANSLATION',
  'countries.codes.MX': 'NEEDS TRANSLATION',
  'countries.codes.MY': 'NEEDS TRANSLATION',
  'countries.codes.MZ': 'NEEDS TRANSLATION',
  'countries.codes.NA': 'NEEDS TRANSLATION',
  'countries.codes.NC': 'NEEDS TRANSLATION',
  'countries.codes.NE': 'NEEDS TRANSLATION',
  'countries.codes.NF': 'NEEDS TRANSLATION',
  'countries.codes.NG': 'NEEDS TRANSLATION',
  'countries.codes.NI': 'NEEDS TRANSLATION',
  'countries.codes.NL': 'NEEDS TRANSLATION',
  'countries.codes.NO': 'NEEDS TRANSLATION',
  'countries.codes.NP': 'NEEDS TRANSLATION',
  'countries.codes.NR': 'NEEDS TRANSLATION',
  'countries.codes.NU': 'NEEDS TRANSLATION',
  'countries.codes.NZ': 'NEEDS TRANSLATION',
  'countries.codes.OM': 'NEEDS TRANSLATION',
  'countries.codes.PA': 'NEEDS TRANSLATION',
  'countries.codes.PE': 'NEEDS TRANSLATION',
  'countries.codes.PF': 'NEEDS TRANSLATION',
  'countries.codes.PG': 'NEEDS TRANSLATION',
  'countries.codes.PH': 'NEEDS TRANSLATION',
  'countries.codes.PK': 'NEEDS TRANSLATION',
  'countries.codes.PL': 'NEEDS TRANSLATION',
  'countries.codes.PM': 'NEEDS TRANSLATION',
  'countries.codes.PN': 'NEEDS TRANSLATION',
  'countries.codes.PR': 'NEEDS TRANSLATION',
  'countries.codes.PS': 'NEEDS TRANSLATION',
  'countries.codes.PT': 'NEEDS TRANSLATION',
  'countries.codes.PW': 'NEEDS TRANSLATION',
  'countries.codes.PY': 'NEEDS TRANSLATION',
  'countries.codes.QA': 'NEEDS TRANSLATION',
  'countries.codes.RE': 'NEEDS TRANSLATION',
  'countries.codes.RO': 'NEEDS TRANSLATION',
  'countries.codes.RS': 'NEEDS TRANSLATION',
  'countries.codes.RU': 'NEEDS TRANSLATION',
  'countries.codes.RW': 'NEEDS TRANSLATION',
  'countries.codes.SA': 'NEEDS TRANSLATION',
  'countries.codes.SB': 'NEEDS TRANSLATION',
  'countries.codes.SC': 'NEEDS TRANSLATION',
  'countries.codes.SD': 'NEEDS TRANSLATION',
  'countries.codes.SE': 'NEEDS TRANSLATION',
  'countries.codes.SG': 'NEEDS TRANSLATION',
  'countries.codes.SH': 'NEEDS TRANSLATION',
  'countries.codes.SI': 'NEEDS TRANSLATION',
  'countries.codes.SJ': 'NEEDS TRANSLATION',
  'countries.codes.SK': 'NEEDS TRANSLATION',
  'countries.codes.SL': 'NEEDS TRANSLATION',
  'countries.codes.SM': 'NEEDS TRANSLATION',
  'countries.codes.SN': 'NEEDS TRANSLATION',
  'countries.codes.SO': 'NEEDS TRANSLATION',
  'countries.codes.SR': 'NEEDS TRANSLATION',
  'countries.codes.SS': 'NEEDS TRANSLATION',
  'countries.codes.ST': 'NEEDS TRANSLATION',
  'countries.codes.SV': 'NEEDS TRANSLATION',
  'countries.codes.SX': 'NEEDS TRANSLATION',
  'countries.codes.SY': 'NEEDS TRANSLATION',
  'countries.codes.SZ': 'Suazi',
  'countries.codes.TA': 'Tristan da Cunha',
  'countries.codes.TC': 'Turks i Caicos',
  'countries.codes.TD': 'Czad',
  'countries.codes.TF': 'Francuskie Terytoria Południowe',
  'countries.codes.TG': 'Togo',
  'countries.codes.TH': 'Tajlandia',
  'countries.codes.TJ': 'Tadżykistan',
  'countries.codes.TK': 'Tokelau',
  'countries.codes.TL': 'Timor Wschodni',
  'countries.codes.TM': 'Turkmenistan',
  'countries.codes.TN': 'Tunezja',
  'countries.codes.TO': 'Tonga',
  'countries.codes.TR': 'Turcja',
  'countries.codes.TT': 'Trynidad i Tobago',
  'countries.codes.TV': 'Tuvalu',
  'countries.codes.TW': 'Tajwan',
  'countries.codes.TZ': 'Tanzania',
  'countries.codes.UA': 'Ukraina',
  'countries.codes.UG': 'Uganda',
  'countries.codes.UM': 'USA Wyspy Odległe',
  'countries.codes.UN': 'Organizacja Narodów Zjednoczonych',
  'countries.codes.US': 'Stany Zjednoczone',
  'countries.codes.UY': 'Urugway',
  'countries.codes.UZ': 'Uzbekistan',
  'countries.codes.VA': 'Watykan',
  'countries.codes.VC': 'St. Vincent i Grenadyny',
  'countries.codes.VE': 'Wenezuela',
  'countries.codes.VG': 'Brytyjskie Wyspy Dziewicze',
  'countries.codes.VI': 'USA Wyspy Dziewicze',
  'countries.codes.VN': 'Wietnam',
  'countries.codes.VU': 'Vanuatu',
  'countries.codes.WF': 'Wallis i Futuna',
  'countries.codes.WS': 'Samoa',
  'countries.codes.XK': 'Kosowo',
  'countries.codes.YE': 'Jemen',
  'countries.codes.YT': 'Majotta',
  'countries.codes.ZA': 'Afryka Południowa',
  'countries.codes.ZM': 'Zambia',
  'countries.codes.ZW': 'Zimbabwe',
  'countries.european-community': 'NEEDS TRANSLATION',
  'countries.rest-of-europe': 'NEEDS TRANSLATION',
  'countries.rest-of-world': 'NEEDS TRANSLATION',
  'createUser.title': 'NEEDS TRANSLATION',
  'dashboard.enter-driver-card-number.errors.invalid-number':
    'NEEDS TRANSLATION',
  'dashboard.lastForumPosts': 'NEEDS TRANSLATION',
  'dashboard.register-hours': 'NEEDS TRANSLATION',
  'dashboard.registerHours': 'NEEDS TRANSLATION',
  'document.files-for-document': 'NEEDS TRANSLATION',
  'documentation.documentation-for-control': 'NEEDS TRANSLATION',
  'documentation.drive-and-rest-time': 'NEEDS TRANSLATION',
  'documents.add-document': 'NEEDS TRANSLATION',
  'documents.continue-to-files': 'NEEDS TRANSLATION',
  'documents.documents-of-user': 'NEEDS TRANSLATION',
  'documents.edit-file': 'NEEDS TRANSLATION',
  'documents.expiration-date': 'NEEDS TRANSLATION',
  'documents.files-for-document': 'NEEDS TRANSLATION',
  'documents.name-on-document': 'NEEDS TRANSLATION',
  'documents.no-documents': 'NEEDS TRANSLATION',
  'documents.no-files': 'NEEDS TRANSLATION',
  'documents.notification-time': 'NEEDS TRANSLATION',
  'documents.notification-time-alt': 'NEEDS TRANSLATION',
  'documents.number-id': 'NEEDS TRANSLATION',
  'documents.open-file': 'NEEDS TRANSLATION',
  'documents.prompts.delete-document': 'NEEDS TRANSLATION',
  'documents.prompts.delete-document-confirmation': 'NEEDS TRANSLATION',
  'documents.prompts.delete-file': 'NEEDS TRANSLATION',
  'documents.prompts.delete-file-title': 'NEEDS TRANSLATION',
  'documents.responsible-user': 'NEEDS TRANSLATION',
  'documents.show-files': 'NEEDS TRANSLATION',
  'documents.upload-document': 'NEEDS TRANSLATION',
  'documents.upload-file': 'NEEDS TRANSLATION',
  'documents.view-file': 'NEEDS TRANSLATION',
  'error.activities-pause-duration': 'NEEDS TRANSLATION',
  'error.address-required': 'NEEDS TRANSLATION',
  'error.archive-type': 'NEEDS TRANSLATION',
  'error.categories': 'NEEDS TRANSLATION',
  'error.city-required': 'NEEDS TRANSLATION',
  'error.company-missing-tachograph-key':
    'Firmie brakuje klucza API tachografu. Skontaktuj się z administratorem, aby uzyskać dalszą pomoc.',
  'error.contact-person-phone-required':
    'Wymagany jest numer telefonu osoby kontaktowej',
  'error.contact-person-required':
    'Wymagane jest podanie nazwiska osoby kontaktowej',
  'error.could-not-change-profile-information':
    'Nie można zmienić informacji w profilu',
  'error.could-not-check-in': 'Nie można się zameldować',
  'error.could-not-check-out': 'Nie można się wymeldować',
  'error.could-not-create-type': 'Nie można utworzyć {type}',
  'error.could-not-delete-type': 'Nie można usunąć {type}',
  'error.could-not-edit-type': 'Nie można edytować {type}',
  'error.could-not-find-data': 'Nie można znaleźć danych w tabeli',
  'error.could-not-find-location': 'Nie udało się znaleźć lokalizacji',
  'error.could-not-get-absence-types':
    'Nie udało się uzyskać typów nieobecności',
  'error.could-not-read-file': 'Nie można odczytać pliku',
  'error.create-issue': 'Nie można utworzyć problemu',
  'error.driver-card-expiration-date-not-found':
    'Nie udało się znaleźć daty ważności karty kierowcy.',
  'error.driver-card-is-expired': 'Karta kierowcy utraciła ważność',
  'error.drivercard-between-14-16': 'Pole potrzebuje 14-16 znaków',
  'error.end-time-too-early':
    'Czas zakończenia musi być późniejszy niż czas rozpoczęcia',
  'error.expiration-date-not-found-add-expiration-date-manually':
    'Nie udało się znaleźć daty ważności. Dodaj datę ręcznie.',
  'error.field-can-not-be-empty': 'Pole nie może być puste',
  'error.field-is-required': '{field} jest wymagane',
  'error.files.could-not-read': 'Nie można odczytać pliku',
  'error.filled-out-form-entry-does-not-exist':
    'Wypełniony wpis w formularzu nie istnieje.',
  'error.first-name-required': 'Imię jest wymagane',
  'error.generic': 'Wystąpił błąd',
  'error.generic-server-error':
    'Coś poszło nie tak na serwerze. Proszę spróbuj ponownie.',
  'error.generic-title': 'Coś poszło nie tak',
  'error.internal-number-required': 'Wymagany jest numer wewnętrzny',
  'error.invalid-birth-number': 'Numer urodzenia jest nieprawidłowy',
  'error.invalid-file-type': 'Nieprawidłowy typ pliku',
  'error.invalid-phone-number': 'Nieprawidłowy numer telefonu',
  'error.invalid-value': 'Nieprawidłowa wartość',
  'error.last-name-required': 'Nazwisko jest wymagane',
  'error.missing-permission':
    'Brakuje Ci wymaganych uprawnień, aby uzyskać do tego dostęp',
  'error.name-exists': 'Wpis o tej nazwie już istnieje',
  'error.need-at-least-one-user-type':
    'Potrzebujesz co najmniej jednego typu użytkownika',
  'error.need-company-name': 'Nazwa firmy musi być obecna',
  'error.no-first-name': 'Brak imienia',
  'error.not-permitted': 'Niedozwolone',
  'error.not-permitted-to-view': 'Nie masz uprawnień, aby to oglądać',
  'error.number-must-be-positive': 'Liczba musi być dodatnia',
  'error.phone-number-required': 'Numer telefonu jest wymagany',
  'error.postal-code-required': 'Wymagany jest kod pocztowy',
  'error.try-again': 'Spróbuj ponownie',
  'error.type-already-exist': '{type} już istnieje',
  'error.type-does-not-exist': '{type} nie istnieje',
  'error.un-archive-type': 'Nie można ponownie otworzyć {type}',
  'error.update-issue': 'Nie można zaktualizować problemu',
  'error.user-has-no-infringements-for-the-selected-period':
    'Użytkownik nie ma żadnych naruszeń w wybranym okresie',
  'error.valid-email-required': 'Wymagany jest prawidłowy adres e-mail',
  'error.wrong-password': 'Złe hasło',
  'error.wrong-username-or-password':
    'Nieprawidłowa nazwa użytkownika lub hasło',
  'errors.common.name-already-exists': 'Nazwa już istnieje',
  'errors.could-not-save-changes': 'Nie można zapisać zmian',
  'errors.defaults.does-not-exist': 'Obiekt nie istnieje',
  'errors.defaults.foreign-key-constraint':
    'Obiekt odwołuje się do czegoś, co nie istnieje',
  'errors.defaults.invalid-value': 'Podano niepoprawną wartość',
  'errors.defaults.not-permitted':
    'Twój użytkownik nie ma uprawnień, aby to zrobić',
  'errors.defaults.unique-constraint': 'Obiekt musi być unikalny',
  'errors.driver-card-already-taken': 'Karta kierowcy jest już zajęta.',
  'errors.driver-card-not-found-in-external-integration': 'NEEDS TRANSLATION',
  'errors.driver-card-not-found-similar-driver-card-already-taken':
    'Nie znaleziono karty kierowcy. Podobna karta kierowcy została już zajęta.',
  'errors.generic-error': 'An error has occurred',
  'errors.must-be-whole-number': 'Musi być liczbą całkowitą',
  'errors.passwords-not-equal': 'Hasła nie są równe',
  'errors.users.could-not-change-password': 'Nie można zmienić hasła',
  'exports.error-email-process': 'Nie można wyeksportować do e-maila',
  'exports.error-user-salary-settings':
    'Użytkownik nie ma typów użytkowników powiązanych z ustawieniami wynagrodzeń',
  'exports.export-data': 'Eksportuj dane',
  'exports.month-export': 'Eksport miesiąca',
  'exports.salary-export': 'Eksport podstawy wynagrodzenia',
  'exports.success-email-process':
    'Dane są przetwarzane. Po upływie czasu przetwarzania otrzymasz wiadomość e-mail.',
  'exports.success-user-exports':
    'Dane są przetwarzane. Po upływie czasu przetwarzania otrzymasz powiadomienie.',
  'exports.user-exports': 'Mój eksport',
  'files.drop-to-upload': 'Upuść tutaj pliki, aby je przesłać',
  'files.file-too-large':
    'Plik {name} jest większy niż maksymalny rozmiar {max}MB.',
  'files.upload-files-here': 'Prześlij pliki',
  'forgotten-password.forgotten': 'Zapomniałeś hasła?',
  'forgotten-password.go-back': 'Wróć do logowania',
  'forgotten-password.message':
    'Jeśli zapomniałeś hasła, wpisz swoją nazwę użytkownika lub adres e-mail. Jeśli znajdziemy Twoje konto, wyślemy Ci e-mail z nowym hasłem.',
  'forgotten-password.success':
    'Jeśli Twoje konto istnieje, wiadomość e-mail z instrukcjami resetowania hasła jest już w drodze.',
  'forgotten-password.title': 'Zapomniałem hasła',
  'form.add-field': 'Dodaj pole',
  'form.all-responses-loaded': 'Załadowano wszystkie odpowiedzi',
  'form.archive-form-error': 'Nie można zarchiwizować formularza',
  'form.archive-form-success': 'Formularz został zarchiwizowany',
  'form.archive-prompt-text':
    'Czy na pewno chcesz zarchiwizować ten formularz?',
  'form.archive-prompt-title': 'Formularz archiwalny',
  'form.create-filled-form-success': 'Formularz wypełniony',
  'form.create-form': 'Utwórz nowy formularz',
  'form.create-form-alt': 'Utwórz formularz',
  'form.created-form-success': 'Pomyślnie utworzono nowy formularz',
  'form.delete-checklist-template-prompt-text':
    'Czy na pewno chcesz usunąć ten szablon listy kontrolnej?',
  'form.delete-checklist-template-prompt-title':
    'Usuń szablon listy kontrolnej',
  'form.delete-entry-prompt-text':
    'Czy na pewno chcesz usunąć ten wypełniony formularz?',
  'form.delete-entry-prompt-title': 'Usuń wpis z formularza',
  'form.delete-field-prompt-text':
    'Czy na pewno chcesz usunąć to pole formularza?',
  'form.delete-field-prompt-title': 'Usuń pole formularza',
  'form.delete-template-prompt-text':
    'Czy na pewno chcesz usunąć ten formularz?',
  'form.delete-template-prompt-title': 'Usuń formularz',
  'form.edit-form': 'Edytuj formularz',
  'form.edit-this-form': 'Edytuj ten formularz',
  'form.errors.load-responses': 'Nie można załadować odpowiedzi z formularza',
  'form.field': 'Pole',
  'form.field-type-boolean': 'Tak/Nie',
  'form.field-type-date': 'Dane',
  'form.field-type-number': 'Numer',
  'form.field-type-text': 'Tekst',
  'form.field-type-user': 'Użytkownik',
  'form.field-type-vehicle-department': 'NEEDS TRANSLATION',
  'form.fields': 'Pola',
  'form.fill-out-this-form': 'Wypełnij ten formularz',
  'form.filled-out-n-times-by-user':
    '{n, plural, =0 {You have never filled out this form} one {You have filled out this form once} =2 {You have filled out this form twice} other {You have filled out this form # times}}',
  'form.filled-out-n-times-by-user-including-today':
    '{n, plural, =0 {You have never filled out this form} one {You have filled out this form once, including today} =2 {You have filled out this form twice, including today} other {You have filled out this form # times, including today}}',
  'form.form-entry': 'Formularz',
  'form.form-entry-deleted': 'Wypełniony formularz został usunięty',
  'form.form-fields': 'Pola formularza',
  'form.form-label-name': 'Nazwa formularza',
  'form.form-settings': 'Ustawienia formularzy',
  'form.label-archive-date': 'Data archiwizacji',
  'form.label-archive-max-uses': 'Maksymalna liczba użyć przed archiwizacją',
  'form.label-archive-mode': 'Metoda archiwizacji',
  'form.label-field-description': 'Opis',
  'form.label-field-input-type': 'Typ danych wejściowych',
  'form.label-field-name': 'Nazwa pola',
  'form.label-field-type': 'Typ pola',
  'form.label-field-value': 'Tekst',
  'form.label-include-footer': 'Dołącz stopkę',
  'form.label-include-icon': 'Dołącz ikonę firmy',
  'form.label-ingress': 'Ingres',
  'form.label-type': 'Typ formularza',
  'form.last-response-at': 'Najnowsza odpowiedź',
  'form.load-all-responses': 'Załaduj wszystkie odpowiedzi',
  'form.menu-shortcut': 'Skrót menu',
  'form.no-responses': 'Brak odpowiedzi',
  'form.open-overview': 'Otwórz przegląd',
  'form.prompts.delete-form':
    'Czy jesteś całkowicie pewien, że chcesz usunąć ten formularz? Wszystkie odpowiedzi również zostaną usunięte.',
  'form.prompts.delete-form-title': 'Usuń formularz',
  'form.prompts.unverify-response':
    'Czy chcesz anulować status zmiennej tej odpowiedzi?',
  'form.prompts.unverify-response-title': 'Usuń weryfikację',
  'form.prompts.verify-response': 'Czy chcesz zweryfikować tę odpowiedź?',
  'form.prompts.verify-response-title': 'Zweryfikuj',
  'form.remove-field': 'Usuń pole',
  'form.responses-for-form': 'Odpowiedzi: {name}',
  'form.see-answers': 'Zobacz odpowiedzi',
  'form.select-form': 'Wybierz formularz',
  'form.showing-num-of-total-responses': 'Pokazuję {num} z {total} odpowiedzi',
  'form.table-label-created': 'Utworzono',
  'form.table-label-edit': 'Edytuj',
  'form.table-label-filled-in-by': 'Wypełniony przez',
  'form.table-label-name': 'Nazwa',
  'form.table-label-updated': 'Zaktualizowano o',
  'form.table-label-use-form': 'Użyj formularza',
  'form.table-label-used': 'Odpowiedzi',
  'form.toasts.create-form-success': 'Formularz utworzony',
  'form.toasts.delete-form-success': 'Formularz usunięty',
  'form.toasts.patch-form-success': 'Formularz edytowany',
  'form.toasts.submit-success': 'Formularz został przesłany',
  'form.un-archive-prompt-text':
    'Czy na pewno chcesz ponownie otworzyć ten formularz?',
  'form.un-archive-prompt-title': 'Otwórz ponownie formularz',
  'form.update-form-success': 'Formularz zaktualizowany',
  'form.updated-form-success': 'Formularz został pomyślnie zaktualizowany',
  'form.validation.cannot-be-negative': 'Nie może być ujemna',
  'form.validation.required-fields-missing':
    'Wszystkie wymagane pola nie są wypełnione',
  'form.verified-by-at': 'Zweryfikowany przez {name}, {when}',
  'formcreator.distribution-field': 'NEEDS TRANSLATION',
  'formcreator.export-form-submissions': 'NEEDS TRANSLATION',
  'formcreator.filter-field': 'NEEDS TRANSLATION',
  'formcreator.summation-field': 'NEEDS TRANSLATION',
  'forum.add-comment': 'Dodaj komentarz',
  'forum.answers-number': '{num} odpowiedzi',
  'forum.archived-threads': 'Wątki zarchiwizowane',
  'forum.create-new-post': 'Utwórz nowy post',
  'forum.create-thread': 'Utwórz nowy wątek',
  'forum.create-thread.send-email-error':
    'Wystąpił błąd podczas wysyłania e-maili',
  'forum.create-thread.send-sms-error': 'Wystąpił błąd podczas wysyłania SMS-a',
  'forum.create-thread.success': 'Utworzono wątek',
  'forum.created': 'Utworzono',
  'forum.edit-post': 'Edytuj post',
  'forum.edit-thread': 'Edytuj wątek',
  'forum.errors.content-required': 'Treść jest wymagana',
  'forum.errors.create-thread': 'Nie można utworzyć nowego wątku',
  'forum.errors.delete-post-failed': 'Nie można usunąć komentarza',
  'forum.errors.delete-thread-failed': 'Nie można usunąć wątku',
  'forum.errors.thread-does-not-exist': 'Wątek nie istnieje',
  'forum.errors.title-required': 'Tytuł jest wymagany',
  'forum.last-posts': 'Ostatnie posty',
  'forum.last-threads': 'Ostatnie wątki',
  'forum.new': 'Nowy wątek',
  'forum.new-thread': 'Nowy wątek',
  'forum.no-views': 'Brak wyświetleń',
  'forum.number-of-posts': 'Liczba postów',
  'forum.post':
    '{choice, select, definite {The post} plural {Posts} other {Post}}',
  'forum.post-comment': 'Opublikuj komentarz',
  'forum.posts-error-message': 'Wystąpił błąd podczas pobierania postów',
  'forum.prompts.delete-attachment':
    'Czy na pewno chcesz usunąć ten załącznik?',
  'forum.prompts.delete-attachment-title': 'Usuń załącznik',
  'forum.prompts.delete-comment-prompt':
    'Czy na pewno chcesz usunąć ten komentarz?',
  'forum.prompts.delete-comment-prompt-title': 'Usuń komentarz',
  'forum.prompts.delete-post': 'Czy na pewno chcesz usunąć ten komentarz?',
  'forum.prompts.delete-post-title': 'Usuń komentarz',
  'forum.prompts.delete-thread': 'Czy na pewno chcesz usunąć ten wątek?',
  'forum.prompts.delete-thread-text':
    'Czy na pewno chcesz usunąć wątek {title}? Tej akcji nie można cofnąć.',
  'forum.prompts.delete-thread-title': 'Usuń wątek',
  'forum.prompts.download-attachment-text':
    'Nie można otworzyć tego załącznika. Czy chcesz go pobrać?',
  'forum.prompts.download-attachment-title': 'Pobierz załącznik',
  'forum.quill-link-helper':
    'Zaznacz tekst, który chcesz przekonwertować na link, a następnie naciśnij przycisk linku',
  'forum.read-by-n-employees':
    '{n, plural, =0 {Read by no employees} jeden {Read by # employee} inne {Read by # employees}}',
  'forum.search': 'Przeszukaj forum',
  'forum.seen-at': 'Widziało się w',
  'forum.send-email-label': 'Wyślij powiadomienie e-mailem',
  'forum.send-sms-label':
    'Wyślij powiadomienie SMS do {employeeNum} pracowników',
  'forum.sms-notification': 'Powiadomienie SMS',
  'forum.sticky-thread': 'Przyklejony wątek',
  'forum.sticky-threads': 'Przyklejone wątki',
  'forum.thread':
    '{choice, select, definite {The thread} plural {Threads} other {Thread}}',
  'forum.threads-error-message': 'Wystąpił błąd podczas pobierania wątków',
  'forum.timesinceposted': 'Godziny temu',
  'forum.toasts.archive-thread-error': 'Nie można zarchiwizować wątku',
  'forum.toasts.archive-thread-success': 'Wątek został zarchiwizowany',
  'forum.toasts.create-thread-success': 'Wątek utworzony',
  'forum.toasts.delete-thread-error': 'Nie można usunąć wątku',
  'forum.toasts.delete-thread-success': 'Wątek został usunięty',
  'forum.toasts.patch-post-error':
    'Coś nie poszło nie tak podczas aktualizacji wpisu',
  'forum.toasts.patch-post-success': 'Post został zaktualizowany',
  'forum.toasts.patch-thread-error':
    'Coś poszło nie tak podczas aktualizacji wątku',
  'forum.toasts.patch-thread-success': 'Wątek został zaktualizowany',
  'forum.toasts.restore-thread-success': 'Wątek został przywrócony',
  'forum.unseen-only': 'Tylko niewidoczne',
  'forum.update-comment': 'Aktualizuj komentarz',
  'forum.update-thread': 'Aktualizuj wątek',
  'forum.validation.thread-must-have-content': 'Wątek musi zawierać treść',
  'forum.view-count-title': 'Wątek odwiedził',
  'forum.written-when': 'Napisane {when}',
  'handbook.add-entry': 'Dodaj wpis',
  'handbook.add-folder': 'Dodaj folder',
  'handbook.add-sub-folder': 'Dodaj podfolder',
  'handbook.create-entry-success': 'Utworzono wpis',
  'handbook.create-folder-success': 'Utworzony folder',
  'handbook.create_entry': 'Utwórz nowy wpis',
  'handbook.dashboard-links': 'Linki do panelu',
  'handbook.edit-folder': 'Edytuj folder',
  'handbook.entry-load-error': 'Błąd podczas ładowania wpisu',
  'handbook.entry.delete-success': 'Wpis usunięty',
  'handbook.errors.delete-entry': 'Nie można usunąć wpisu',
  'handbook.errors.delete-folder': 'Nie można usunąć folderu',
  'handbook.folder-load-error': 'Błąd ładowania folderu',
  'handbook.folder.delete-success': 'Usunięty folder',
  'handbook.new-root-folder': 'Nowy folder główny',
  'handbook.prompts.delete-entry': 'Czy na pewno chcesz usunąć ten wpis?',
  'handbook.prompts.delete-entry-title': 'Usuń wpis',
  'handbook.prompts.delete-folder':
    'Czy jesteś pewien, że chcesz usunąć ten folder? Cała zawartość również zostanie usunięta.',
  'handbook.prompts.delete-folder-title': 'Usuń folder',
  'handbook.search': 'Przeszukaj podręcznik kierowcy',
  'handbook.start': 'Początek',
  'handbook.toasts.create-entry-success':
    'Wpis w podręczniku "{name}" został utworzony',
  'handbook.toasts.create-folder-success': 'Folder utworzony',
  'handbook.toasts.delete-entry-success': 'Wpis został usunięty',
  'handbook.toasts.delete-folder-success': 'Folder został usunięty',
  'handbook.toasts.patch-folder-success': 'Folder zapisany',
  'handbook.update-entry-success': 'Zaktualizowano wpis',
  'handbook.update-folder-success': 'Zaktualizowałem folder',
  'infringements.amount-of-infringements-in-categories':
    'Ilość naruszeń w kategoriach',
  'infringements.archive-infringement': 'Naruszenie archiwum',
  'infringements.archived-basis': 'Podstawa archiwalna',
  'infringements.archived-date': 'Data zarchiwizowania',
  'infringements.category-infringement': 'NEEDS TRANSLATION',
  'infringements.export-infringement': 'Naruszenie eksportu',
  'infringements.export-infringement-data': 'Eksportuj dane o naruszeniach',
  'infringements.export.all': 'Naruszenia eksportowe dla firmy',
  'infringements.legal-basis': 'Podstawa prawna',
  'infringements.lower-limit': 'Dolny limit',
  'infringements.minor-infringement': 'Drobne naruszenie',
  'infringements.most-serious-infringement': 'Najpoważniejsze naruszenie',
  'infringements.my-infringements': 'Moje naruszenia',
  'infringements.n-points': '{n} punktów',
  'infringements.no-infringements': 'Brak naruszeń',
  'infringements.restore-infringement': 'Przywrócenie naruszenia',
  'infringements.send-email-success': 'Naruszenia przesłane e-mailem.',
  'infringements.serious-infringement': 'Poważne naruszenie',
  'infringements.severity': 'Ważność',
  'infringements.total-severity': 'Całkowita dotkliwość',
  'infringements.upper-limit': 'Górny limit',
  'infringements.very-serious-infringement': 'Bardzo poważne naruszenie',
  'integrations.error-in-API-keys': 'Błąd w kluczach API',
  'integrations.error-in-FMS-key': 'Błąd w kluczu FMS',
  'integrations.error-in-tachograph-key': 'Błąd w kluczu API tachografu',
  'issue.category-responsible-user': 'Kategoria problemów odpowiedzialna',
  'issue.existing-attachments': 'Istniejące załączniki',
  'issues.action-log': 'NEEDS TRANSLATION',
  'issues.actual-cost': 'Rzeczywisty koszt',
  'issues.add-recipient-with-email': 'Dodaj odbiorcę z e-mailem',
  'issues.archive-issue': 'Wydarzenie archiwalne',
  'issues.archived-by-user': 'NEEDS TRANSLATION',
  'issues.attachments': 'Załączniki',
  'issues.category-name': 'Nazwa kategorii',
  'issues.close-issue': 'Zamknij problem',
  'issues.closed-at': 'Zamknięte o',
  'issues.closed-by': 'Zamknięte przez',
  'issues.closed-by-user': 'NEEDS TRANSLATION',
  'issues.cost-estimation': 'Szacowany koszt',
  'issues.create-issue': 'Utwórz problem',
  'issues.create-issue-for': 'Utwórz problem dla:',
  'issues.created-by-user': 'NEEDS TRANSLATION',
  'issues.damage-description': 'Co zostało uszkodzone',
  'issues.damage-description-long':
    'Co zostało uszkodzone? Wypełnij zarówno obiekty bezpośrednie, jak i pośrednie.',
  'issues.damage-description-short': 'Uszkodzone obiekty',
  'issues.damage-direct-and-indirect-objects':
    'Wypełnij obiekty bezpośrednie i pośrednie',
  'issues.damage-location': 'Gdzie to się stało',
  'issues.delete-issue': 'Usuń problem',
  'issues.delete-issue-error-message':
    'Próbujesz usunąć kategorię i nadal są z nią powiązane problemy.To jest niemożliwe.',
  'issues.describe-the-damage': 'Opisz uszkodzenie/uszkodzony przedmiot',
  'issues.description-of-event-unwanted-event': 'NEEDS TRANSLATION',
  'issues.edit-issue': 'Edytuj problem',
  'issues.equipment-material': 'Sprzęt/materiał',
  'issues.error-retrieving-users':
    'Wystąpił błąd podczas pobierania użytkowników',
  'issues.errors.create-issue': 'Nie można utworzyć problemu',
  'issues.errors.must-enter-actual-cost': 'Należy wpisać koszt rzeczywisty',
  'issues.errors.must-enter-solution': 'Wymagane jest rozwiązanie',
  'issues.errors.patch-issue': 'Nie udało się zaktualizować problemu',
  'issues.export-issue': 'Problem eksportu',
  'issues.extend-issue-form': 'Czy chcesz teraz zamknąć sprawę?',
  'issues.fill-in-actual-cost': 'Wypełnij koszt rzeczywisty',
  'issues.fill-in-solution': 'Wypełnij rozwiązanie końcowe',
  'issues.fill-in-vehicle-reg-nr': 'Wypełnij Nr.Rej. danego pojazdu/przyczepy',
  'issues.final-solution': 'Ostateczne rozwiązanie',
  'issues.find-issue': 'Znajdź problem',
  'issues.give-estimated-cost': 'Podaj szacunkowy koszt emisji',
  'issues.issue-categories': 'Kategorie problemów',
  'issues.issue-categories.errors.could-not-load-error':
    'Nie można wczytać kategorii',
  'issues.issue-categories.no-categories': 'Brak kategorii',
  'issues.issue-category': 'Kategoria problemu',
  'issues.issue-description-about': 'O problemie',
  'issues.issue-location': 'Lokalizacja problemu',
  'issues.issue-report': 'Raport o problemie',
  'issues.location-description': 'Gdzie w lokalizacji wystąpił problem',
  'issues.new-issue': 'Nowy problem',
  'issues.no-issues': 'Brak problemów',
  'issues.opened-by': 'Otwarte przez',
  'issues.reference': '{abbreviate, select, true {Ref.} other {Reference}}',
  'issues.reopen-issue': 'Otwórz ponownie problem',
  'issues.reopened-by-user': 'NEEDS TRANSLATION',
  'issues.responsible': 'Odpowiedzialny',
  'issues.restore-issue': 'Przywróć problem',
  'issues.restored-by-user': 'NEEDS TRANSLATION',
  'issues.search-for-issue': 'Wyszukaj problem',
  'issues.select-recipient-from-list': 'Wybierz odbiorcę z listy',
  'issues.selected-recipients': 'Wybrani odbiorcy',
  'issues.send-email-success': 'Raport został wysłany e-mailem.',
  'issues.solution-suggestion-about': 'Zmierz sugestie',
  'issues.success-creation': 'Utworzono nowy problem',
  'issues.success-update': 'Zaktualizowany problem',
  'issues.suggested-improvement': 'NEEDS TRANSLATION',
  'issues.suggested-solution': 'Sugerowane rozwiązanie',
  'issues.time-of-issue': 'Czas wydarzenia',
  'issues.toasts.create-issue-success': 'Problem został utworzony',
  'issues.toasts.patch-issue-success': 'Problem został zaktualizowany',
  'issues.treated': 'Leczone',
  'issues.untreated': 'Nieleczone',
  'issues.update-actual-cost': 'Aktualizuj koszt rzeczywisty',
  'issues.updated-by-user': 'NEEDS TRANSLATION',
  'issues.validation.solution-required':
    'Aby zamknąć ten problem, musisz podać rozwiązanie',
  'issues.view-all-issues': 'Wszystkie problemy',
  'issues.view-your-issues': 'Twoje problemy',
  'login.email-or-phone-number': 'E-mail lub numer telefonu',
  'login.forgotten-password': 'Zapomniałeś hasła?',
  'login.remember-me': 'Zapamiętaj mnie',
  'login.subtitle': 'Witamy',
  'modal.and-all-its-content': 'i cała jego zawartość',
  'modal.delete-attachment': 'Czy chcesz usunąć "{title}" z załączników?',
  'modal.delete-attachment-title': 'Usuń załącznik',
  'modal.delete-folder': 'Usuń folder',
  'modal.delete-folder-description':
    'Czy chcesz usunąć folder "{title}" i jego zawartość?',
  'modal.delete-folder-entry-description': 'Czy chcesz usunąć wpis "{title}"?',
  'modal.delete-folder-entry-title': 'Usuń wpis',
  'modal.delete-folder-title': 'Usuń folder',
  'modal.delete-type': 'Czy na pewno chcesz usunąć {type}?',
  'modal.delete-type-title': 'Usuń {type}',
  'modal.remove-attachment': 'Czy chcesz usunąć "{title}" z listy załączników?',
  'modal.remove-attachment-title': 'Usuń załącznik',
  'notifications-settings.issue_closed_notification':
    'Powiadomienie o zamknięciu problemu',
  'notifications-settings.issue_created_notification':
    'Powiadomienie o utworzeniu problemu',
  'notifications-settings.new_post_created_notification': 'Utworzono nowy post',
  'notifications-settings.new_thread_created_notification':
    'Utworzono nowy wątek',
  'notifications-settings.title': 'Ustawienia powiadomień',
  'notifications.add-notifications': 'Dodać powiadomienia?',
  'notifications.error.general':
    'Coś poszło nie tak podczas ładowania powiadomień',
  'notifications.go-to-absence': 'Przejdź do nieobecności',
  'notifications.go-to-document': 'Przejdź do dokumentu',
  'notifications.go-to-thread': 'Przejdź do wątku',
  'notifications.you-have-no-notifications': 'Nie masz żadnych powiadomień',
  'password.must-contain-nonnumeric':
    'Hasło musi zawierać przynajmniej jedną wartość nieliczbową',
  'password.must-contain-numeric-value':
    'Hasło musi zawierać przynajmniej jedną wartość liczbową',
  'password.too-short': 'Hasło musi mieć długość co najmniej 7 znaków',
  'permissions.add': 'Utwórz',
  'permissions.approve': 'Zatwierdź',
  'permissions.change': 'Zmień',
  'permissions.change_sticky': 'Zmień przyklejony',
  'permissions.company-settings-general': 'Ogólne',
  'permissions.forum-send-email': 'Wyślij e-mail',
  'permissions.forum-send-sms': 'Wyślij SMS',
  'permissions.no-access-to-page':
    'Twój użytkownik nie ma dostępu do tej strony',
  'permissions.remove': 'Usuń',
  'permissions.view': 'Widok',
  'permissions.view-and-change': 'Wyświetl/Zmień',
  'permissions.view-others': 'Wyświetl innych',
  'print.error-email-process': 'Nie udało się wygenerować wydruku e-mailowego',
  'print.export-type': 'Eksportuj {type}',
  'print.monthly-print': 'Wydruk miesięczny',
  'print.salary-print': 'Wydruk wynagrodzenia',
  'print.send-to-email': 'Wyślij na e-mail',
  'print.success-email-process':
    'Zapytanie jest przetwarzane. Po zakończeniu procesu otrzymasz wiadomość e-mail.',
  'quill.delete-column': 'Usuń wybraną kolumnę',
  'quill.delete-row': 'Usuń wybrany wiersz',
  'quill.delete-table': 'Usuń tabelę',
  'quill.insert-column-left': 'Wstaw kolumnę z lewej strony',
  'quill.insert-column-right': 'Wstaw kolumnę z prawej strony',
  'quill.insert-row-above': 'Wstaw wiersz powyżej',
  'quill.insert-row-below': 'Wstaw wiersz poniżej',
  'quill.new-table': 'Nowa tabela',
  'quill.table-tooltip':
    'Shift + prawy przycisk myszy, aby zmodyfikować tabelę.',
  'register-user.description':
    'Ponieważ logujesz się po raz pierwszy, będziesz musiał podać kilka standardowych informacji.',
  'register-user.title': 'Wpisz swoje dane osobowe',
  'reset-password.errors.bad-token': 'Zły znak',
  'reset-password.errors.expired-token': 'Znak resetowania wygasł.',
  'reset-password.go-back': 'Powrót do logowania',
  'reset-password.message':
    'Proszę wybrać nowe hasło. Hasło musi mieć co najmniej 8 znaków i nie może składać się wyłącznie z cyfr.',
  'reset-password.submit': 'Zmień hasło i zaloguj się',
  'reset-password.success': 'Hasło zostało zresetowane',
  'reset-password.title': 'Zresetuj hasło',
  'route-planner.add-new-route': 'Dodaj nową trasę',
  'route-planner.axle-weight-restrictions': 'Ograniczenia nacisku na oś',
  'route-planner.create-route-plan': 'Utwórz plan trasy',
  'route-planner.create-route-plan-success':
    'Plan trasy został utworzony pomyślnie',
  'route-planner.delete-route-plan-success':
    'Plan trasy został pomyślnie usunięty',
  'route-planner.destination': 'Miejsce docelowe',
  'route-planner.estimated-driving-duration': 'Szacowany czas jazdy',
  'route-planner.export-route-plan': 'Eksportuj plan trasy',
  'route-planner.extra-load': 'Dodatkowy ładunek',
  'route-planner.form.label.total-weight-in-kg': 'Całkowita masa w kg',
  'route-planner.form.label.total-weight-of-vehicle':
    'Całkowita masa pojazdu w kg',
  'route-planner.general-weight-restriction': 'Ogólne ograniczenie wagi',
  'route-planner.include-departure-time': 'Wlącz czas odjazdu?',
  'route-planner.load': 'Załaduj',
  'route-planner.loading-route': 'Wczytuję trasę...',
  'route-planner.no-route-instructions': 'Brak instrukcji dotyczących trasy',
  'route-planner.no-routes': 'Brak tras',
  'route-planner.payload': 'Ładunek',
  'route-planner.payload-in-kilos': 'Ładność w kilogramach',
  'route-planner.route-instructions': 'Instrukcje dotyczące trasy',
  'route-planner.route-plan': 'Plan trasy',
  'route-planner.route-plan-no-route-found':
    'Nie znaleziono trasy dla podanych ograniczeń',
  'route-planner.route-plan-task-failed':
    'Coś poszło nie tak z tym planem trasy',
  'route-planner.route-plan-task-pending':
    'Ten plan trasy oczekuje na realizację',
  'route-planner.route-restrictions': 'Ograniczenia trasy',
  'route-planner.single-axle': 'Pojedyncza oś',
  'route-planner.start-location': 'Lokalizacja początkowa',
  'route-planner.tandem-axle': 'Oś tandemowa',
  'route-planner.tonne': 'Tona',
  'route-planner.top-speed': 'Prędkość maksymalna',
  'route-planner.top-speed-in-km': 'Maksymalna prędkość w km/h',
  'route-planner.total-weight': 'Całkowita waga',
  'route-planner.trailer': 'Przyczepa',
  'route-planner.trailer-weight-in-kilos': 'Masa przyczepy w kilogramach',
  'route-planner.triple-axle': 'Potrójna oś',
  'route-planner.weight-in-tonnes': 'Waga w tonach metrycznych',
  'select-user-settings.title': 'Ustawienia rozwijane użytkownika',
  'selectCompany.select': 'Wybierz firmę',
  'settings.absence-responsible': 'Nieobecność odpowiedzialna',
  'settings.add-absence-type': 'Dodaj typ nieobecności',
  'settings.add-api-key': 'Dodaj klucz API',
  'settings.add-department': 'Dodaj departament',
  'settings.add-holiday-allowance': 'NEEDS TRANSLATION',
  'settings.add-issue-category': 'Dodaj kategorię problemu',
  'settings.add-issue-risk-aspect': 'NEEDS TRANSLATION',
  'settings.add-new-terminal': 'Dodaj nowy terminal',
  'settings.add-salary-code': 'Dodaj kod wynagrodzenia',
  'settings.add-time-entry-project': 'Dodaj projekt',
  'settings.added-issue-category': 'Dodano nową kategorię problemu',
  'settings.available-departments': 'NEEDS TRANSLATION',
  'settings.change-logo': 'Zmień logo',
  'settings.change-module': 'Zmień moduł',
  'settings.change-responsible-user': 'Zmień odpowiedzialnego użytkownika',
  'settings.company-logo': 'Logo firmy',
  'settings.company-settings': 'Ustawienia firmy',
  'settings.create-checklist-template': 'Utwórz szablon listy kontrolnej',
  'settings.create-fleet-board-integration': 'NEEDS TRANSLATION',
  'settings.create-issue-category': 'Utwórz kategorię problemu',
  'settings.create-man-integration': 'NEEDS TRANSLATION',
  'settings.create-name-first': 'Najpierw utwórz nazwę',
  'settings.create-salary-settings':
    'Utwórz ustawienia wynagrodzeń dla {module}',
  'settings.create-scania-integration': 'Utwórz integrację Scania',
  'settings.create-terminal-area': 'Utwórz obszar terminala',
  'settings.create-user-document': 'Utwórz dokument użytkownika',
  'settings.create-user-type': 'Utwórz typ użytkownika',
  'settings.create-volvo-integration': 'Utwórz integrację Volvo',
  'settings.daily-salary': 'NEEDS TRANSLATION',
  'settings.daily-work-limit': 'Dzienny limit pracy (godziny)',
  'settings.daily-work-limit-short': 'Dziennie',
  'settings.dashboard-links-preview': 'Podgląd linków do paneli',
  'settings.day-salary-code': 'NEEDS TRANSLATION',
  'settings.default-department': 'NEEDS TRANSLATION',
  'settings.default-salary-code': 'Domyślny kod wynagrodzenia',
  'settings.delete-overtime-period': 'Usuń okres nadgodzin',
  'settings.delete-period-addon': 'NEEDS TRANSLATION',
  'settings.delete-user-type': 'Usuń typ użytkownika',
  'settings.do-you-want-to-delete-overtime-period':
    'Czy chcesz usunąć nadgodziny?',
  'settings.do-you-want-to-delete-period-addon': 'NEEDS TRANSLATION',
  'settings.driverdata-print-for-all-drivers': 'Wydrukuj wszystkie działania',
  'settings.edit-absence-responsible': 'Edytuj odpowiedzialną za nieobecność',
  'settings.edit-absence-type': 'Edytuj typ nieobecności',
  'settings.edit-checklist-template': 'Edytuj szablon listy kontrolnej',
  'settings.edit-department': 'Aktualizuj departament',
  'settings.edit-dynamic-column': 'Edytuj nazwę kolumny',
  'settings.edit-fleet-board-integration-n': 'NEEDS TRANSLATION',
  'settings.edit-holiday-allowance': 'NEEDS TRANSLATION',
  'settings.edit-infringements-responsible':
    'Edytuj użytkownika odpowiedzialnego za naruszenia',
  'settings.edit-issue-category': 'Edytuj kategorię problemu',
  'settings.edit-issue-risk-aspect': 'NEEDS TRANSLATION',
  'settings.edit-man-integration-n': 'NEEDS TRANSLATION',
  'settings.edit-responsible-user': 'Edytuj odpowiedzialnego użytkownika',
  'settings.edit-salary-and-work-time-for-type':
    'Edytuj {type} ustawienia wynagrodzenia',
  'settings.edit-salary-code': 'Edytuj kod wynagrodzenia',
  'settings.edit-salary-settings-type':
    'Edytuj ustawienia wynagrodzeń: {module}',
  'settings.edit-scania-integration-n': 'Edytuj integrację Scania #{n}',
  'settings.edit-terminal-area': 'Edytuj obszar terminala',
  'settings.edit-time-entry-project': 'Edytuj projekt',
  'settings.edit-type-settings': 'Edytuj {type} ustawienia',
  'settings.edit-user-document': 'Edytuj dokument użytkownika',
  'settings.edit-volvo-integration-n': 'Edytuj integrację Volvo #{n}',
  'settings.errors.change-language': 'Nie można zmienić języka',
  'settings.errors.company-settings-generic':
    'Nie można zaktualizować ustawień firmy',
  'settings.errors.create-absence-type': 'Nie można utworzyć typu nieobecności',
  'settings.errors.create-checklist-template-error':
    'Błąd podczas tworzenia szablonu listy kontrolnej',
  'settings.errors.create-fleetboard-integration': 'NEEDS TRANSLATION',
  'settings.errors.create-issue-category':
    'Nie można utworzyć kategorii problemu',
  'settings.errors.create-issue-risk-aspect': 'NEEDS TRANSLATION',
  'settings.errors.create-salary': 'Nie można utworzyć ustawień wynagrodzeń',
  'settings.errors.create-scania-integration':
    'Nie można utworzyć integracji Scania',
  'settings.errors.create-terminal': 'Nie można utworzyć obszaru terminala',
  'settings.errors.create-time-entry-project': 'Nie można utworzyć projektu',
  'settings.errors.create-user-document':
    'Nie można utworzyć dokumentu użytkownika',
  'settings.errors.create-user-document-category': 'NEEDS TRANSLATION',
  'settings.errors.create-user-type': 'Nie można utworzyć typu użytkownika',
  'settings.errors.create-volvo-integration':
    'Nie można utworzyć integracji z Volvo',
  'settings.errors.delete-absence-type': 'Nie można usunąć typu nieobecności',
  'settings.errors.delete-fleetboard-integration': 'NEEDS TRANSLATION',
  'settings.errors.delete-issue-category':
    'Nie można usunąć kategorii problemu',
  'settings.errors.delete-issue-risk-aspect': 'NEEDS TRANSLATION',
  'settings.errors.delete-salary': 'Nie można usunąć ustawienia wynagrodzenia',
  'settings.errors.delete-scania-integration':
    'Nie można usunąć integracji Scania',
  'settings.errors.delete-terminal': 'Nie można usunąć obszaru terminala',
  'settings.errors.delete-time-entry-project': 'Nie można usunąć projektu',
  'settings.errors.delete-user-document':
    'Nie można usunąć dokumentu użytkownika',
  'settings.errors.delete-user-document-category': 'NEEDS TRANSLATION',
  'settings.errors.delete-user-type': 'Nie można usunąć typu użytkownika',
  'settings.errors.delete-volvo-integration':
    'Nie można usunąć integracji Volvo',
  'settings.errors.password-change': 'Nie można zmienić hasła',
  'settings.errors.patch-absence-type':
    'Nie można zaktualizować typu nieobecności',
  'settings.errors.patch-checklist-template-error':
    'Błąd podczas aktualizacji szablonu listy kontrolnej',
  'settings.errors.patch-fleetboard-integration': 'NEEDS TRANSLATION',
  'settings.errors.patch-issue-category':
    'Nie udało się zaktualizować kategorii problemu',
  'settings.errors.patch-issue-risk-aspect': 'NEEDS TRANSLATION',
  'settings.errors.patch-salary':
    'Nie można zaktualizować ustawień wynagrodzeń',
  'settings.errors.patch-scania-integration':
    'Nie można zaktualizować integracji Scania',
  'settings.errors.patch-terminal': 'Nie można zaktualizować obszaru terminala',
  'settings.errors.patch-time-entry-project': 'Nie można edytować projektu',
  'settings.errors.patch-user-document':
    'Nie można zaktualizować dokumentu użytkownika',
  'settings.errors.patch-user-document-category': 'NEEDS TRANSLATION',
  'settings.errors.patch-user-type': 'Nie można zaktualizować typu użytkownika',
  'settings.errors.patch-volvo-integration':
    'Nie można zaktualizować integracji Volvo',
  'settings.errors.update-dropdowns':
    'Nie można zaktualizować list rozwijanych',
  'settings.errors.update-notifications':
    'Nie można zaktualizować ustawień powiadomień',
  'settings.errors.update-user-type':
    'Nie można zaktualizować typu użytkownika',
  'settings.errors.user-type-cannot-be-deleted':
    'Ten typ użytkownika nie może zostać usunięty',
  'settings.errors.user-type-cannot-be-edited':
    'Tego typu użytkownika nie można edytować',
  'settings.errors.user-type-cannot-be-edited-with-name':
    'Typ użytkownika "{name}" nie może być edytowany',
  'settings.export-all-absences-for-period':
    'Eksportuj wszystkie nieobecności w okresie',
  'settings.export-all-driver-activities':
    'Eksportuj wszystkie czynności kierowcy',
  'settings.export-all-issues-for-period':
    'Eksportuj wszystkie problemy w danym okresie',
  'settings.export-all-terminal-entries': 'Eksportuj wszystkie wpisy terminala',
  'settings.export-all-timesheets': 'Eksportuj wszystkie grafiki',
  'settings.export-driverdata-for-user':
    'Eksportuj działania kierowcy dla użytkownika',
  'settings.export-reports': 'Eksportuj raporty',
  'settings.export-tables': 'Ustawienia tabeli do eksportu',
  'settings.export-tables.preview': 'Podgląd kolumn tabeli eksportu',
  'settings.external-id-in-salary-system': 'NEEDS TRANSLATION',
  'settings.fats.max-daily-work-limit': 'NEEDS TRANSLATION',
  'settings.fats.n-weeks': 'NEEDS TRANSLATION',
  'settings.fats.period-generated-for': 'NEEDS TRANSLATION',
  'settings.fats.period-length': 'NEEDS TRANSLATION',
  'settings.fats.start-date-not-monday': 'NEEDS TRANSLATION',
  'settings.fats.start-date-too-late-to-get-valid-interval':
    'NEEDS TRANSLATION',
  'settings.first-to-last-day-in-month':
    'Od pierwszego do ostatniego dnia miesiąca',
  'settings.fixed-salary': 'NEEDS TRANSLATION',
  'settings.fleet-board-key-n': 'NEEDS TRANSLATION',
  'settings.fms-access-key': 'Klucz dostępu do FMS',
  'settings.fms-secret-key': 'Tajny klucz FMS',
  'settings.group-salary-on-vehicle': 'Użyj departamentu z pojazdów',
  'settings.has-notification': 'Ma powiadomienie',
  'settings.hourly-salary': 'NEEDS TRANSLATION',
  'settings.include-absences': 'Uwzględnij nieobecności',
  'settings.info.absence-types-1':
    'W tym widoku możesz dodawać, edytować i usuwać typy nieobecności.',
  'settings.info.absence-types-2':
    'Zaleca się zachowanie ostrożności podczas edytowania typu nieobecności, ponieważ spowoduje to edycję wszystkich istniejących wpisów nieobecności korzystających z tego typu.',
  'settings.info.checklists':
    'W tym widoku możesz dodawać, edytować i usuwać szablony list kontrolnych.',
  'settings.info.driver-data-1':
    'W tym widoku możesz dostosować ustawienia i uruchomić funkcje narzędziowe dla sterowników i czynności kierowcy.',
  'settings.info.driver-data-2':
    'Dostępne są dwa zbiory danych. Godziny - drukuje te same wartości dla wszystkich kierowców, które są dostępne w module czynności, lub Dieta - drukuje czas i miejsce rozpoczęcia/zakończenia, ale nie obliczony okres pracy.',
  'settings.info.dropdowns':
    'Na tym ekranie możesz wybrać typy użytkowników, które będą dostępne w menu rozwijanym użytkowników na niektórych stronach. Wszyscy użytkownicy o typie wybranym poniżej pojawią się na tych listach rozwijanych.',
  'settings.info.exports-1': 'W tym widoku eksportujesz dowolne dane.',
  'settings.info.fats-1': 'NEEDS TRANSLATION',
  'settings.info.general':
    'W tym widoku możesz skonfigurować podstawowe ustawienia firmy oraz załadować logo swojej firmy.',
  'settings.info.handbook-1':
    'W tym widoku możesz zmienić ustawienia instrukcji sterownika.',
  'settings.info.infringements-data-1':
    'W tym widoku możesz pobrać lub wysłać naruszenia pocztą.',
  'settings.info.integrations':
    'Na tym ekranie możesz dodawać lub edytować klucze integracyjne.',
  'settings.info.issue-categories-1':
    'W tym widoku możesz dodawać, edytować i usuwać kategorie spraw używane w formularzach spraw.',
  'settings.info.issue-categories-2':
    'Odpowiedzialny użytkownik to osoba, która ponosi największą odpowiedzialność w kwestiach danej kategorii i zazwyczaj będzie powiadamiana o wszelkich wydarzeniach związanych z kwestiami tej kategorii. Odpowiedzialni użytkownicy muszą mieć typ użytkownika, do którego przypisano ten widok w widoku rozwijanym.',
  'settings.info.issue-categories-uneditable':
    'Tylko odpowiedzialny użytkownik może być edytowany.',
  'settings.info.notifications':
    'Ta tabela pozwala wybrać sposób powiadamiania użytkowników o różnych zdarzeniach. Pamiętaj, aby zapisać zmiany po zakończeniu. ',
  'settings.info.route-planner':
    'Na tej stronie możesz dostosować ustawienia narzędzia do planowania trasy',
  'settings.info.salary':
    'W tym widoku możesz edytować ustawienia wynagrodzeń.',
  'settings.info.table':
    'Na tej stronie możesz dostosować wygląd tabel w firmie.',
  'settings.info.terminal-1':
    'Na tej stronie możesz ustawić, które sieci powinny być uważane za prawidłowe sieci terminali.',
  'settings.info.terminal-2':
    'Adres IP używa formatu ipv4, a zakres filtrów IP to zakres masek podrzędnych, który po ustawieniu na 24 oznacza, że wszystkie połączenia w sieci IP są uznawane za prawidłowe. Jeśli chcesz, możesz zmienić tę podmaskę, aby zapewnić bardziej szczegółowe filtrowanie.',
  'settings.info.time-entries-1':
    'Na tej stronie możesz skonfigurować karty czasu pracy. Niektóre opcje mogą zmienić wygląd eksportu lub sposób obliczania całkowitego czasu pracy.',
  'settings.info.user-documents-1':
    'W tym widoku możesz edytować i usuwać dokumenty użytkowników.',
  'settings.info.user-documents-2':
    'Dokumentem odpowiedzialnym jest użytkownik, który otrzymuje powiadomienie o wygaśnięciu ważności dokumentu. Dokument traci ważność pierwszego dnia miesiąca wybranego jako data ważności. Powiadomienie zostanie wysłane do odpowiedzialnego użytkownika na kilka tygodni przed wygaśnięciem określonym w polu czasu powiadomienia.',
  'settings.info.user-types-1':
    'Na tej stronie możesz zmienić typy użytkowników i uprawnienia.',
  'settings.info.user-types-2':
    'Pamiętaj, że wszystkie zmiany w opcjach "Zmień" i "Usuń" dają dostęp do zmiany i usuwania zasobów utworzonych przez innych. Każdy, kto ma uprawnienia do tworzenia zasobu, ma także możliwość zmiany własnych zasobów.',
  'settings.info.vehicles': 'NEEDS TRANSLATION',
  'settings.infringements-responsible': 'Infringements responsible user',
  'settings.integration': 'Integracja',
  'settings.integrations': 'Integracje',
  'settings.integrations-description':
    'Na tym ekranie możesz dodawać lub edytować klucze integracyjne.',
  'settings.is-administrator': 'Jest administratorem',
  'settings.is-administrator-description':
    'Konta administratorów będą miały wszystkie uprawnienia, niezależnie od tej tabeli',
  'settings.last-day-of-month': 'Ostatni dzień miesiąca',
  'settings.man-key-n': 'NEEDS TRANSLATION',
  'settings.module-options': 'Opcje modułu',
  'settings.my-settings': 'Moje ustawienia',
  'settings.n-departments': 'NEEDS TRANSLATION',
  'settings.no-checklist-templates': 'Brak szablonów list kontrolnych.',
  'settings.no-integration-keys': 'Nie znaleziono kluczy integracyjnych',
  'settings.no-selected-absence-responsible':
    'Nikt nie jest wybierany jako odpowiedzialny za nieobecność',
  'settings.no-selected-infringements-responsible':
    'Nie wybrano użytkownika odpowiedzialnego za naruszenia',
  'settings.notifications.notify-driver-on-infringement': 'NEEDS TRANSLATION',
  'settings.notifications.setting.absence-created': 'NEEDS TRANSLATION',
  'settings.notifications.setting.driver-card': 'Ważność karty kierowcy',
  'settings.notifications.setting.forum-post': 'Nowy komentarz na forum',
  'settings.notifications.setting.forum-thread': 'Nowy wątek na forum',
  'settings.notifications.setting.issue-closed': 'Sprawa zamknięta',
  'settings.notifications.setting.issue-created': 'Utworzono problem',
  'settings.notifications.setting.reset-password': 'Resetuj hasło',
  'settings.notifications.setting.user-document': 'Wygasł dokument użytkownika',
  'settings.notifications.setting.user-export-ready': 'Eksport zakończony',
  'settings.notifications.setting.user-registration': 'Rejestracja użytkownika',
  'settings.notifications.vehicle-document':
    'Dokument pojazdu, który utracił ważność',
  'settings.notifications.vehicle-expirations': 'Pojazd wygasł',
  'settings.ovetime-periods': 'NEEDS TRANSLATION',
  'settings.paid-pause-threshold':
    'Limit pauzy płatnej (każda pauza poniżej wybranego limitu)',
  'settings.pause-duration': 'Czas trwania pauzy',
  'settings.pause-duration-short': 'Przerwa',
  'settings.pause-threshold': 'Limit pracy dla aktywacji pauzy',
  'settings.pause-threshold-short': 'Praca przed przerwą',
  'settings.period': 'Okres',
  'settings.period-work-limit': 'Tygodniowy limit pracy (godziny)',
  'settings.period-work-limit-short': 'Co tydzień',
  'settings.prompts.absence-type-create': 'Nazwa nowego typu nieobecności: ',
  'settings.prompts.absence-type-delete':
    'Czy na pewno chcesz usunąć typ nieobecności "{name}"?',
  'settings.prompts.absence-type-delete-title': 'Usuń typ nieobecności',
  'settings.prompts.create-user-type':
    'Jaka powinna być nazwa nowego typu użytkownika?',
  'settings.prompts.delete': 'Czy na pewno chcesz usunąć {}?',
  'settings.prompts.delete-checklist-template': 'Usuń szablon listy kontrolnej',
  'settings.prompts.delete-checklist-template-description':
    'Czy chcesz usunąć szablon listy kontrolnej?',
  'settings.prompts.delete-time-entry-project':
    'Czy na pewno chcesz usunąć projekt "{name}"?',
  'settings.prompts.department-delete-title': 'Usuń departament',
  'settings.prompts.department-setting-delete-title':
    'Czy chcesz usunąć departament?',
  'settings.prompts.holiday-allowance-delete-title': 'NEEDS TRANSLATION',
  'settings.prompts.integration-delete-title': 'Usuń integrację',
  'settings.prompts.integration-fleet-board-delete': 'NEEDS TRANSLATION',
  'settings.prompts.integration-man-delete': 'NEEDS TRANSLATION',
  'settings.prompts.integration-scania-delete':
    'Czy na pewno chcesz usunąć integrację Scania #{n}?',
  'settings.prompts.integration-volvo-delete':
    'Czy na pewno chcesz usunąć integrację #{n}?',
  'settings.prompts.issue-category-delete':
    'Czy na pewno chcesz usunąć kategorię problemu "{name}"?',
  'settings.prompts.issue-category-delete-title': 'Usuń kategorię problemu',
  'settings.prompts.issue-risk-aspect-delete': 'NEEDS TRANSLATION',
  'settings.prompts.issue-risk-aspect-delete-title': 'NEEDS TRANSLATION',
  'settings.prompts.salary-code-delete-title': 'Usuń kod wynagrodzenia',
  'settings.prompts.salary-setting-delete-title':
    'Czy chcesz usunąć ustawienia wynagrodzenia i czasu pracy?',
  'settings.prompts.terminal-delete':
    'Czy na pewno chcesz usunąć obszar terminala "{name}"?',
  'settings.prompts.terminal-delete-title': 'Usuń obszar terminala',
  'settings.prompts.time-entry-project-delete-title': 'Usuń projekt',
  'settings.prompts.user-document-delete':
    'Czy na pewno chcesz usunąć dokument użytkownika "{name}"?',
  'settings.prompts.user-document-delete-title': 'Usuń dokument użytkownika',
  'settings.prompts.user-type-delete':
    'Czy jesteś całkowicie pewien, że chcesz usunąć ten typ użytkownika?',
  'settings.prompts.user-type-delete-title': 'Usuń typ użytkownika',
  'settings.reciept-functionality': 'Funkcjonalność odbioru',
  'settings.refresh-colors': 'Odśwież kolory',
  'settings.responsible-user': 'Odpowiedzialny użytkownik',
  'settings.salary-and-work-time': 'Wynagrodzenie i godziny pracy',
  'settings.salary-and-work-time-type': 'Wynagrodzenie',
  'settings.salary-code': 'Rodzaj wynagrodzenia',
  'settings.salary-code-day': 'NEEDS TRANSLATION',
  'settings.salary-code-id-field-placeholder':
    'Zewnętrzny identyfikator w systemie wynagrodzeń',
  'settings.salary-day-other-salary-code-warning': 'NEEDS TRANSLATION',
  'settings.salary-end-date': 'Data końcowa wynagrodzenia',
  'settings.salary-period': 'Okres wynagrodzenia',
  'settings.salary-start-date': 'Data rozpoczęcia wynagrodzenia',
  'settings.salary-type': 'NEEDS TRANSLATION',
  'settings.save-changes': 'Zapisz zmiany',
  'settings.save-settings': 'Zapisz ustawienia',
  'settings.scania-key': 'Klucz Scania',
  'settings.scania-key-n': 'Klucz Scania #{n}',
  'settings.select-api-key-to-add': 'Wybierz klucz API do dodania',
  'settings.select-categories': 'NEEDS TRANSLATION',
  'settings.select-category': 'NEEDS TRANSLATION',
  'settings.select-crontab-setting': 'Wybierz częstotliwość generowania',
  'settings.select-module': 'Wybierz moduł',
  'settings.show-column': 'Pokaż kolumnę',
  'settings.show-dynamic-column-description':
    'Gdy kolumna jest dezaktywowana, jest ona ukryta w tabeli i eksportowana z modułu.',
  'settings.show-dynamic-column-error':
    'Co najmniej dwie kolumny muszą być aktywne.',
  'settings.show-in-manual-time-entry-overtime': 'NEEDS TRANSLATION',
  'settings.standard-settings': 'NEEDS TRANSLATION',
  'settings.static-categories': 'Kategorie statyczne',
  'settings.tables': 'Ustawienia tabeli',
  'settings.tables.add-column-description':
    'Po dodaniu kolumny jest ona pokazywana w tabelach z modułu.',
  'settings.tables.add-export-column-description':
    'Po dodaniu kolumny jest ona pokazywana w tabelach eksportu z modułu.',
  'settings.tables.add-placeholder': 'Wybierz kolumnę do dodania',
  'settings.tables.preview': 'Podgląd tabeli',
  'settings.tachograph-key': 'Klucz do tachografu',
  'settings.terminal-description-1':
    'Na tej stronie możesz ustawić, które sieci powinny być uważane za prawidłowe sieci terminali.',
  'settings.terminal-description-2':
    'Adres IP ma postać ipv4, a zakres filtrów IP to zakres masek podrzędnych, który po ustawieniu na 24 oznacza, że wszystkie połączenia w sieci IP są uznawane za prawidłowe. Jeśli chcesz, możesz zmienić tę podmaskę, aby zapewnić bardziej szczegółowe filtrowanie.',
  'settings.time-entry-projects': 'Projekty',
  'settings.timesheets.alternative-overtime-option':
    'Alternatywna opcja pracy w nadgodzinach',
  'settings.timesheets.count-pause-as-part-of-total-work':
    'Wliczaj przerwę do całkowitego czasu pracy',
  'settings.timesheets.department-mandatory': 'NEEDS TRANSLATION',
  'settings.timesheets.merge-timesheets-with-activities':
    'Połącz dane grafiku z czynnościami',
  'settings.timesheets.periodic-overtime': 'Okresowe nadgodziny',
  'settings.timesheets.projects': 'Projekty',
  'settings.toast.create-man-integration-error': 'NEEDS TRANSLATION',
  'settings.toast.create-man-integration-success': 'NEEDS TRANSLATION',
  'settings.toast.delete-man-integration-error': 'NEEDS TRANSLATION',
  'settings.toast.delete-man-integration-success': 'NEEDS TRANSLATION',
  'settings.toast.patch-man-integration-error': 'NEEDS TRANSLATION',
  'settings.toast.patch-man-integration-success': 'NEEDS TRANSLATION',
  'settings.toasts.add-column-success': 'Kolumna została dodana',
  'settings.toasts.change-language-success': 'Zmieniono język',
  'settings.toasts.company-settings-updated': 'Ustawienia firmy zaktualizowane',
  'settings.toasts.create-absence-type-success':
    'Utworzono typ nieobecności "{name}"',
  'settings.toasts.create-checklist-template-success':
    'Dodano szablon listy kontrolnej.',
  'settings.toasts.create-fleetboard-integration-success': 'NEEDS TRANSLATION',
  'settings.toasts.create-issue-category-success':
    'Utworzono kategorię problemu "{name}"',
  'settings.toasts.create-issue-risk-aspect-success': 'NEEDS TRANSLATION',
  'settings.toasts.create-salary-success': 'Utworzono ustawienia wynagrodzeń',
  'settings.toasts.create-scania-integration-success':
    'Utworzono integrację Scania',
  'settings.toasts.create-terminal-success':
    'Utworzono obszar terminala "{name}"',
  'settings.toasts.create-time-entry-project-success':
    'Projekt "{name}" został stworzony',
  'settings.toasts.create-user-document-category-success': 'NEEDS TRANSLATION',
  'settings.toasts.create-user-document-success':
    'Utworzono dokument użytkownika',
  'settings.toasts.create-user-type-success':
    'Utworzono typ użytkownika "{name}"',
  'settings.toasts.create-volvo-integration-success':
    'Utworzono integrację Volvo',
  'settings.toasts.delete-absence-type-success': 'Usunięto typ nieobecności',
  'settings.toasts.delete-fleetboard-integration-success': 'NEEDS TRANSLATION',
  'settings.toasts.delete-issue-category-success':
    'Kategoria problemu została usunięta',
  'settings.toasts.delete-issue-risk-aspect-success': 'NEEDS TRANSLATION',
  'settings.toasts.delete-salary-success': 'Usunięto ustawienie wynagrodzenia',
  'settings.toasts.delete-scania-integration-success':
    'Usunięto integrację Scania',
  'settings.toasts.delete-terminal-success': 'Usunięto obszar terminala',
  'settings.toasts.delete-time-entry-project-success': 'Projekt usunięty',
  'settings.toasts.delete-user-document-category-success': 'NEEDS TRANSLATION',
  'settings.toasts.delete-user-document-success':
    'Dokument użytkownika został usunięty',
  'settings.toasts.delete-user-type-success': 'Typ użytkownika został usunięty',
  'settings.toasts.delete-volvo-integration-success':
    'Usunięto integrację Volvo',
  'settings.toasts.password-change-success': 'Twoje hasło zostało zmienione',
  'settings.toasts.patch-absence-type-success':
    'Zaktualizowano typ nieobecności',
  'settings.toasts.patch-checklist-template-success':
    'Zaktualizowano szablon listy kontrolnej',
  'settings.toasts.patch-fleetboard-integration-success': 'NEEDS TRANSLATION',
  'settings.toasts.patch-issue-category-success':
    'Kategoria problemu została zaktualizowana',
  'settings.toasts.patch-issue-risk-aspect-success': 'NEEDS TRANSLATION',
  'settings.toasts.patch-salary-success':
    'Zaktualizowano ustawienia wynagrodzeń',
  'settings.toasts.patch-scania-integration-success':
    'Zaktualizowano integrację Scania',
  'settings.toasts.patch-terminal-success': 'Zaktualizowano obszar terminala',
  'settings.toasts.patch-time-entry-project-success':
    'Projekt został zmieniony',
  'settings.toasts.patch-user-document-category-success': 'NEEDS TRANSLATION',
  'settings.toasts.patch-user-document-success':
    'Dokument użytkownika zaktualizowany',
  'settings.toasts.patch-user-type-success': 'Typ użytkownika zaktualizowany',
  'settings.toasts.patch-volvo-integration-success':
    'Aktualizacja integracji Volvo',
  'settings.toasts.remove-column-success': 'Kolumna została usunięta',
  'settings.toasts.update-dropdowns-success': 'Zaktualizowano listy rozwijane',
  'settings.toasts.update-notifications-success':
    'Ustawienia powiadomień zostały zaktualizowane',
  'settings.toasts.update-user-type-success': 'Typ użytkownika zaktualizowany',
  'settings.tv-view': 'Pokaż w telewizji',
  'settings.type-of-work': 'Rodzaj pracy',
  'settings.updated-type': 'Zaktualizowano {type}',
  'settings.upload': 'Prześlij',
  'settings.upload-type': 'Prześlij {type}',
  'settings.use-as-site-logo': 'Użyj jako logo',
  'settings.use-period-addons': 'NEEDS TRANSLATION',
  'settings.use-period-overtime': 'Użyj okresu w nadgodzinach',
  'settings.use-salary-day-payment': 'NEEDS TRANSLATION',
  'settings.use-week-overtime': 'NEEDS TRANSLATION',
  'settings.user-documents-description-title':
    'Ustawienia dokumentu użytkownika',
  'settings.user-type.create-user-type': 'Utwórz nowy typ użytkownika',
  'settings.user-types-and-permissions': 'Typy użytkowników i uprawnienia',
  'settings.valid-salary-dates':
    'Data rozpoczęcia musi przypadać o jeden dzień później niż data zakończenia.',
  'settings.validation.absence-type-name-exists':
    'Ten typ nieobecności już istnieje',
  'settings.validation.already-holiday-allowance-on-day': 'NEEDS TRANSLATION',
  'settings.validation.company.logo-file-size':
    'Rozmiar pliku logo nie może przekraczać 128 MB',
  'settings.validation.company.must-have-name': 'Firma musi mieć nazwę',
  'settings.validation.ip-address': 'Nieprawidłowy format',
  'settings.validation.issue-category-name-exists':
    'Kategoria problemu o tej nazwie już istnieje',
  'settings.validation.issue-risk-aspect-name-exists': 'NEEDS TRANSLATION',
  'settings.validation.last-admin-user-type-delete':
    'To jedyny typ użytkownika admin i dlatego nie można go usunąć.',
  'settings.validation.last-admin-user-type-toggle':
    'To jedyny typ użytkownika admin i dlatego nie można go wyłączyć.',
  'settings.validation.salary-driver-pause-length': 'Co najmniej 45 minut',
  'settings.validation.salary-priority-unique':
    'Istnieje już ustawienie wynagrodzenia z tym priorytetem dla {module}',
  'settings.validation.terminal-range':
    'Musi mieścić się w przedziale od 1 do 32',
  'settings.validation.time-entry-project-name-exists':
    'Projekt o podanej nazwie już istnieje',
  'settings.validation.user-document-name-exists': 'NEEDS TRANSLATION',
  'settings.validation.user-documents.must-have-name': 'Musi mieć nazwę',
  'settings.validation.user-documents.must-set-responsible-user':
    'Należy ustawić użytkownika',
  'settings.validation.user-type-name-exists':
    'Typ użytkownika o tej nazwie już istnieje',
  'settings.validation.user-type-name-exists-short': 'Nazwa istnieje',
  'settings.volvo-key': 'Klucz Volvo',
  'settings.volvo-key-n': 'Klucz Volvo #{n}',
  'settings.week-overtime-delayed-overtime-warning': 'NEEDS TRANSLATION',
  'settings.week-salary-code': 'NEEDS TRANSLATION',
  'statistics.average-driver-grade': 'Średni stopień kierowcy',
  'statistics.average-fuel-consumption': 'Średnie zużycie paliwa',
  'statistics.driver-grade': 'Klasa kierowcy',
  'statistics.kilometers-driven': 'Przejechane kilometry',
  'statistics.l-10-km': 'L/10 km',
  'statistics.l-100-km': 'L/100 km',
  'statistics.monthly-company-statistics': 'Miesięczne statystyki firmy',
  'statistics.monthly-driver-statistics': 'Twoje miesięczne statystyki',
  'statistics.number-of-issues': 'Liczba problemów',
  'table.week-sum': 'Suma tygodniowa',
  'terminal.check-in': 'Zamelduj się',
  'terminal.check-out': 'Wymelduj się',
  'terminal.checked-in': 'Zameldowano się',
  'terminal.checked-in-at': 'Zameldowano się o {time}',
  'terminal.checked-in-message':
    'Zameldowałeś się w {checkIn} i przepracowałeś {total} godzin',
  'terminal.checked-out': 'Wymeldowano się',
  'terminal.checked-out-at': 'Wymeldowano się o {time}',
  'terminal.edit-terminal-entry': 'Edytuj wpis terminala',
  'terminal.error.delete-terminal-entry': 'Nie można usunąć wpisu terminala',
  'terminal.error.edit-terminal-entry': 'Nie można edytować wpisu terminala',
  'terminal.ip-address': 'Adres IP',
  'terminal.ip-filter-range': 'Zakres filtra IP',
  'terminal.monthly-print': 'Wydruk miesięczny',
  'terminal.no-activity-period':
    'Nie masz zarejestrowanej działalności w tym okresie',
  'terminal.no-activity-today': 'Nie zarejestrowałeś dzisiaj żadnej działania.',
  'terminal.not-on-network':
    'Aby się zameldować lub wymeldować, musisz być w sieci terminali',
  'terminal.old-checked-in-message': 'Zameldowałeś się {checkIn}',
  'terminal.prompt.delete-terminal-entry':
    'Czy chcesz usunąć ten wpis terminala?',
  'terminal.prompt.delete-terminal-entry-title': 'Usunąć wpis terminala?',
  'terminal.salary-basis': 'Podstawa wynagrodzenia',
  'terminal.success.delete-terminal-entry': 'Usunięto wpis terminala',
  'terminal.success.edit-terminal-entry': 'Edytowany wpis terminala',
  'terminal.terminal-areas': 'Obszary terminali',
  'terminal.terminal-entries': 'Wpisy terminala',
  'terminal.terminal-entry': 'Wejście do terminala',
  'terminal.terminal-name': 'Nazwa terminala',
  'terminal.time': 'Czas',
  'terminal.user-activity': 'Działania dla {user}',
  'terminal.user-no-activity-period':
    '{user} nie ma zarejestrowanego działania w wybranym okresie',
  'terminal.your-activity': 'Twoje działania',
  'terminal.your-activity-today': 'Twoja dzisiejsza aktywność',
  'thread.success-creation': 'Utworzono nowy wątek',
  'timesheets.absence-approved': 'Zatwierdzony',
  'timesheets.absence-create-type': 'Utwórz nowy typ nieobecności',
  'timesheets.absence-pending': 'Oczekujące',
  'timesheets.absence-rejected': 'Odrzucony',
  'timesheets.absence-type': 'Typ nieobecności',
  'timesheets.absence-type-name': 'Nazwa typu nieobecności',
  'timesheets.absence-types': 'Typy nieobecności',
  'timesheets.absence-types-error-1':
    'Nieobecności nie mogą być dodawane częściej niż raz dziennie na pracownika',
  'timesheets.delete-absence': 'Usuń nieobecność',
  'timesheets.delete-time-entry': 'Usuń wpis czasu',
  'timesheets.edit-absence': 'Edytuj nieobecność',
  'timesheets.edit-absence-status': 'Edytuj status nieobecności',
  'timesheets.edit-time-entry': 'Edytuj wpis czasu',
  'timesheets.error-create-absence': 'Nie można dodać nieobecności',
  'timesheets.error-create-timeentry':
    'Wpis czasu o tej samej dacie i godzinie już istnieje',
  'timesheets.error-registration-absence':
    'Błąd podczas rejestracji nowej nieobecności',
  'timesheets.error-update-absence': 'Błąd podczas aktualizacji nieobecności',
  'timesheets.error-update-timeentry': 'Błąd podczas aktualizacji wpisu czasu',
  'timesheets.error.delete-absence': 'Nie można usunąć nieobecności',
  'timesheets.error.delete-time-entry': 'Nie można usunąć wpisu czasu',
  'timesheets.error.overtime-mode-required': 'Wymagany jest tryb nadgodzinowy',
  'timesheets.error.patch-absence': 'Nie można zaktualizować nieobecności',
  'timesheets.error.patch-time-entry': 'Nie można zaktualizować wpisu czasu',
  'timesheets.error.pause-required': 'NEEDS TRANSLATION',
  'timesheets.error.start-before-end':
    'Czas rozpoczęcia musi przypadać przed czasem zakończenia',
  'timesheets.export-timesheet': 'Eksportuj grafik',
  'timesheets.new-absence-request': 'Nowy wniosek o nieobecność',
  'timesheets.new-time-entry': 'Nowy wpis czasu',
  'timesheets.note': 'Komentarz dotyczący nieobecności',
  'timesheets.overtime-alt': 'Nadgodziny',
  'timesheets.print-timesheet': 'Drukuj grafik',
  'timesheets.prompt.delete-absence':
    'Czy na pewno chcesz usunąć tę nieobecność?',
  'timesheets.prompt.delete-absence-title': 'Usuń nieobecność',
  'timesheets.prompt.delete-time-entry':
    'Czy na pewno chcesz usunąć ten wpis czasu?',
  'timesheets.prompt.delete-time-entry-title': 'Usuń wpis czasu',
  'timesheets.register-absence': 'Zarejestruj nieobecność',
  'timesheets.register-timeentry': 'Zarejestruj wpis czasu',
  'timesheets.select-absence-type': 'Wybierz typ nieobecności',
  'timesheets.send-request': 'Wyślij żądanie',
  'timesheets.success-registration-absence': 'Zarejestrowano nową nieobecność',
  'timesheets.success-registration-timeentry': 'Zarejestrowano nowy wpis czasu',
  'timesheets.success-update-absence': 'Aktualizacja nieobecności',
  'timesheets.success-update-timeentry': 'Zaktualizowano wpis czasu',
  'timesheets.timeentry': 'Wprowadzanie czasu',
  'timesheets.toast.delete-absence-success': 'Nieobecność została usunięta',
  'timesheets.toast.delete-time-entry-success': 'Wpis czasu został usunięty',
  'timesheets.toast.patch-absence-success': 'Nieobecność zaktualizowana',
  'timesheets.toast.patch-time-entry-success': 'Wpis czasu zaktualizowany',
  'timesheets.update-absence': 'Aktualizuj nieobecność',
  'timesheets.update-timeentry': 'Aktualizuj wpis czasu',
  'timesheets.view-all-absence': 'Wszystkie nieobecności',
  'timesheets.view-all-timeentries': 'Wszystkie wpisy czasowe',
  'timesheets.view-your-absence': 'Twoja nieobecność',
  'timesheets.view-your-timeentries': 'Twoje wpisy dotyczące czasu',
  'timesheets.warning.time-entry-exist-in-daterange':
    'Wpis czasu z nakładającym się okresem już istnieje',
  'toast.success-archive-type': 'Pomyślnie zarchiwizowano {type}',
  'toast.success-un-archive-type': 'Otwarto ponownie {type}',
  'toasts.email-sent-successfully': 'E-mail wysłany pomyślnie',
  'toasts.error-add-type': 'Nie można dodać {type}',
  'toasts.error-delete-type': 'Nie można usunąć {type}',
  'toasts.error-update-type': 'Nie można zaktualizować {type}',
  'toasts.error.create-type': 'Nie można utworzyć {type}',
  'toasts.error.update-type': 'Nie można zaktualizować {type}',
  'toasts.error.upload-type': 'Nie można przesłać {type}',
  'toasts.success-add-type': 'Pomyślnie dodano {type}.',
  'toasts.success-created-type': 'Pomyślnie utworzono {type}',
  'toasts.success-delete-type': 'Usunięto {type}',
  'toasts.success-update-type': 'Zaktualizowano {type}',
  'toasts.success.upload-type': 'Przesłano {type}',
  'user-type-settings.delete-prompt-text':
    'Czy na pewno chcesz usunąć typ użytkownika?',
  'user-type-settings.delete-prompt-title': 'Usuń typ użytkownika',
  'user-type-settings.title': 'Typy użytkowników i uprawnienia',
  'user-type-settings.toasts.error': 'Błąd aktualizacji',
  'user-type-settings.toasts.success': 'Zaktualizowano typy użytkowników',
  'user-types.change-settings-label': 'Zmień',
  'user-types.create-settings-label': 'Utwórz',
  'user-types.driver-activities-self': 'Działania (własne)',
  'user-types.everyones-driver-activities': 'Działania (wszyscy)',
  'user-types.remove-settings-label': 'Usuń',
  'user-types.view-settings-label': 'Widok',
  'users.activate-user': 'Aktywuj użytkownika',
  'users.active-driver-card': 'Aktywna karta kierowcy',
  'users.add-driver-card': 'Dodaj kartę kierowcy',
  'users.add-driver-card-number': 'Dodaj numer karty kierowcy',
  'users.add-expiry-date': 'NEEDS TRANSLATION',
  'users.add-your-driver-number': 'Dodaj numer swojej karty kierowcy',
  'users.awaits-configuration': 'Oczekiwanie na konfigurację',
  'users.bio': 'Biografia profilu',
  'users.birth-number': 'Numer urodzenia',
  'users.change-picture': 'Prześlij obraz',
  'users.change-profile-picture': 'Prześlij obraz',
  'users.contact-person-name': 'Nazwisko osoby kontaktowej',
  'users.contact-person-phone': 'Telefon osoby kontaktowej',
  'users.create-password.description':
    'Utwórz nowe hasło dla nowo dodanego użytkownika',
  'users.create-password.title': 'Utwórz nowe hasło',
  'users.create-user': 'Utwórz użytkownika',
  'users.deactivate-user': 'Dezaktywuj użytkownika',
  'users.delete-driver-card': 'NEEDS TRANSLATION',
  'users.delete-driver-card-message': 'NEEDS TRANSLATION',
  'users.delete-existing-number':
    'Czy chcesz usunąć istniejący numer od jego bieżącego użytkownika?',
  'users.delete-expired-driver-card': 'NEEDS TRANSLATION',
  'users.delete-expired-driver-card-message': 'NEEDS TRANSLATION',
  'users.delete-user': 'Usuń użytkownika',
  'users.driver-card': 'Karta kierowcy',
  'users.driver-card-number': 'Numer karty kierowcy',
  'users.edit-driver-card': 'Edytuj kartę kierowcy',
  'users.edit-profile': 'Edytuj profil',
  'users.edit-profile-information': 'Edytuj informacje o profilu',
  'users.edit-user': 'Edytuj użytkownika',
  'users.errors.driver-card-expiry-format': 'Format: MM.RR',
  'users.errors.driver-card-invalid-expiry': 'Data ważności jest nieprawidłowa',
  'users.errors.phone-number-taken':
    'Numer telefonu jest przypisany do innego użytkownika',
  'users.expired-cards': 'NEEDS TRANSLATION',
  'users.expired-driver-card': 'NEEDS TRANSLATION',
  'users.has-document': 'NEEDS TRANSLATION',
  'users.has-no-companies':
    'Nie jesteś powiązany z żadną firmą. Skontaktuj się ze swoim administratorem.',
  'users.include-expired-documents': 'NEEDS TRANSLATION',
  'users.internal-number': 'Numer wewnętrzny',
  'users.missing-external-id': 'NEEDS TRANSLATION',
  'users.my-profile': 'Mój profil',
  'users.n-has-one-or-more-expired-driver-cards': 'NEEDS TRANSLATION',
  'users.n-users': '{n} użytkowników',
  'users.new': 'Nowy użytkownik',
  'users.new-registration-link-send': 'NEEDS TRANSLATION',
  'users.no-more-user-types': 'Koniec z typami użytkowników',
  'users.number-already-exists': 'Użytkownik {name} już używa tego numeru',
  'users.personal-info': 'Dane osobowe',
  'users.profile': 'Profil',
  'users.profile-did-change': 'Informacje w profilu zostały zaktualizowane',
  'users.profile-of-user': 'Profil: {user}',
  'users.prompts.deactivate-self':
    'Czy na pewno chcesz dezaktywować własnego użytkownika?',
  'users.prompts.deactivate-self-title': 'Dezaktywuj własnego użytkownika',
  'users.prompts.deactivate-user':
    'Czy na pewno chcesz dezaktywować tego użytkownika?',
  'users.prompts.deactivate-user-title': 'Dezaktywuj użytkownika',
  'users.search': 'Wyszukaj użytkownika',
  'users.select-user-types': 'Wybierz typy użytkowników',
  'users.send-new-invitation': 'NEEDS TRANSLATION',
  'users.settings': 'Moje ustawienia',
  'users.show-deactivated': 'Pokaż dezaktywowanych użytkowników',
  'users.stopped-in-control': 'Zatrzymany w celu kontroli?',
  'users.toasts.submit-driver-card-success': 'Pomyślnie dodano kartę kierowcy',
  'users.toasts.user-activated': 'Użytkownik {name} został aktywowany',
  'users.toasts.user-deactivated': 'Użytkownik {name} został dezaktywowany',
  'users.too-weak-password': 'Zbyt słabe hasło',
  'users.user-is-deactivated': 'Ten użytkownik jest dezaktywowany',
  'vehicles.add-expiration-date': 'Dodaj datę ważności',
  'vehicles.add-vehicle': 'Add vehicle',
  'vehicles.administrative': 'Administracyjne',
  'vehicles.back-lifter': 'Tylny podnośnik',
  'vehicles.brand': 'Marka',
  'vehicles.cool-freeze-heat': 'Chłodzenie/zamrażanie/ogrzewanie',
  'vehicles.crane': 'Żuraw',
  'vehicles.crane-type': 'Typ dźwigu',
  'vehicles.delete-vehicle': 'Usuń pojazd',
  'vehicles.delete-vehicle-message':
    'Czy na pewno chcesz usunąć ten pojazd/materiał?',
  'vehicles.delete-vehicle-title': 'Usunąć pojazd/materiał?',
  'vehicles.diesel-card-number': 'Nr karty Diesel',
  'vehicles.edit-expiration-date': 'Edytuj datę ważności',
  'vehicles.edit-vehicle': 'Edytuj pojazd',
  'vehicles.edit-vehicle-document': 'Edytuj dokument pojazdu',
  'vehicles.engine': 'Silnik',
  'vehicles.euro-class': 'Euro class',
  'vehicles.existing-vehicle': 'Istniejący pojazd',
  'vehicles.expiration-dates': 'Daty ważności',
  'vehicles.extension-type': 'Typ rozszerzenia',
  'vehicles.ferry-card-number': 'Nr karty promowej',
  'vehicles.first-registered': 'Zarejestrowano po raz pierwszy',
  'vehicles.fleet': 'Flota',
  'vehicles.fleet-management': 'Zarządzanie flotą',
  'vehicles.fleet-overview': 'Sprzęt i materiały',
  'vehicles.fridge': 'Lodówka',
  'vehicles.fridge-type': 'Typ lodówki',
  'vehicles.fuel': 'Paliwo',
  'vehicles.goods-insurance-type': 'Rodzaj ubezpieczenia towaru',
  'vehicles.height': 'Wysokość',
  'vehicles.inner-height': 'Wewnętrzna wysokość',
  'vehicles.inner-length': 'Długość wewnętrzna',
  'vehicles.inner-width': 'Wewnętrzna szerokość',
  'vehicles.km-per-month': 'Samochód km na miesiąc',
  'vehicles.km-per-year': 'Samochód km rocznie',
  'vehicles.license-number': 'Nr licencji',
  'vehicles.loading-height': 'Wysokość załadunku',
  'vehicles.max-back-axle-load': 'Maksymalne obciążenie tylnej osi',
  'vehicles.max-front-axle-load': 'Maksymalne obciążenie osi przedniej',
  'vehicles.maximum-coupling-load': 'Maksymalne obciążenie sprzęgu',
  'vehicles.maximum-payload': 'Maksymalna ładowność',
  'vehicles.maximum-semi-trailer-weight': 'Maksymalna masa naczepy',
  'vehicles.maximum-speed': 'Maksymalna prędkość',
  'vehicles.maximum-truck-weight': 'Maksymalna masa ciężarówki',
  'vehicles.maxium-total-weight': 'Maksymalna masa całkowita',
  'vehicles.meat-rack': 'Stojak na mięso',
  'vehicles.model': 'Model',
  'vehicles.net-weight': 'Masa netto',
  'vehicles.new-expiration-date': 'Nowa data ważności',
  'vehicles.new-vehicle-document': 'Nowy dokument pojazdu',
  'vehicles.next-periodic-control': 'NEEDS TRANSLATION',
  'vehicles.no-expiration-dates': 'Brak dat ważności',
  'vehicles.no-file-selected': 'Nie wybrano pliku',
  'vehicles.owned': 'Posiadane',
  'vehicles.pallet-spaces': 'Liczba miejsc paletowych',
  'vehicles.pin-code': 'Kod PIN',
  'vehicles.power': 'Moc',
  'vehicles.previous-periodic-control': 'NEEDS TRANSLATION',
  'vehicles.prompts.delete-expiration-date': 'Usuń datę ważności',
  'vehicles.registered-country': 'Kraj zarejestrowany',
  'vehicles.service-agreement': 'Umowa serwisowa',
  'vehicles.service-and-maintenance': 'Serwis i konserwacja',
  'vehicles.side-opening': 'Boczne otwarcie',
  'vehicles.sold-date': 'Data sprzedaży',
  'vehicles.stationing-location': 'Miejsce stacjonowania',
  'vehicles.studded-drive-tire': 'Wewnętrzna opona z kolcami',
  'vehicles.studded-front-tire': 'Przednia opona z kolcami',
  'vehicles.tires-dimension-bogie': 'Wymiary opony wózka',
  'vehicles.tires-dimension-drive': 'Wymiar opony napędowej',
  'vehicles.tires-dimension-front': 'Wymiar opony przedniej',
  'vehicles.toasts.vehicle-updated': 'Pojazd został pomyślnie zaktualizowany',
  'vehicles.toll-tag-number': 'Nr znacznika opłaty',
  'vehicles.unowned': 'Nieposiadane',
  'vehicles.usage-properties': 'Właściwości użytkowe',
  'vehicles.vbg-coupling': 'Sprzęgło VBG',
  'vehicles.vehicle-details': 'Szczegóły pojazdu',
  'vehicles.vehicle-technical-type': 'Techniczny typ pojazdu',
  'vehicles.vehicle-type': 'Typ pojazdu',
  'vehicles.view-vehicle-details': 'Wyświetl szczegóły pojazdu',
  'vehicles.vin': 'VIN',
  'vehicles.volume': 'Objętość',
  'vehicles.weights': 'Wagi',
}
export default pl
