import { UpdateFormFn } from '@ur/react-hooks'

interface UpdateDateRangeForm {
  dateStart: Date
  dateEnd: Date
}

export function handleDateRangeChange(
  updateFormFunction: UpdateFormFn<UpdateDateRangeForm>
) {
  return (newDates: [Date, Date]) => {
    updateFormFunction({
      dateStart: newDates[0],
      dateEnd: newDates[1],
    })
  }
}
