import { Checkbox as BaseCheckbox, Input as BaseInput } from 'components'
import {
  CardContentStyleProps,
  CardHeaderStyleProps,
  CardStyleProps,
} from 'components/Card/types'
import { Form as BaseForm } from 'components/Form'
import { Field } from 'modules/companies'
import styled from 'styled-components'
import { overloadColorProp } from 'util/style'

export const Editor = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0.5rem;
`

export const Input = styled(BaseInput).attrs({
  height: '3.8rem',
})``

export const PlaceEditor = styled(Editor)`
  flex-direction: row;
`

export const DateTimeInput = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: row;
`

export const StyledField = styled(Field)`
  margin-top: 1rem;
`

export const TwoColumnField = styled(Field)`
  margin-top: 0;
`

export const TwoColumns = styled.div`
  margin-top: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);

  ${props => props.theme.media.mobile} {
    grid-template-columns: 1fr;
  }
`

export const Form = styled(BaseForm)`
  max-width: 928px;
`

export const FormContent = styled.div<CardContentStyleProps>`
  padding: ${props => props.padding ?? '1rem 1.25rem'};
  margin: ${props => props.margin ?? '0'};
  overflow-x: ${props => props.overflowX ?? 'visible'};
  background-color: ${props => overloadColorProp(props, 'backgroundColor')};
  margin-top: 0 !important;

  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`

export const FormHeader = styled.div<CardHeaderStyleProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: ${props => props.lineHeight ?? 1.6};
  font-weight: ${props => props.fontWeight ?? 400};
  font-size: ${props => props.fontSize ?? '1.25rem'};
  padding: ${props => props.padding ?? '1rem 1.25rem'};
  background-color: ${props => overloadColorProp(props, 'backgroundColor')};
  margin: 0 -1px;
  border-bottom: 1px solid ${props => props.theme.colors.gray9};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`

export const FormCardWrapper = styled.div<CardStyleProps>`
  position: relative;
  grid-area: ${props => props.gridArea};

  background: ${props => props.background ?? props.theme.colors.white};
  padding: ${props => props.padding ?? '0'};
  margin: ${props => props.margin};
  height: ${props => props.height};
  width: ${props => props.width};

  min-width: ${props => props.minWidth};
  max-width: ${props => props.maxWidth};

  box-shadow: ${props => props.theme.shadow.default};
  border: 1px solid ${props => overloadColorProp(props, 'borderColor')};
  box-sizing: border-box;
  border-radius: 12px;

  border: none;
`

export const ContentDivider = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.gray9};
  margin: 0 calc(-1.25rem - 1px);
  padding: 1rem 0;
`

export const Checkbox = styled(BaseCheckbox)`
  align-self: flex-start;
  margin-top: 1rem;
`
