import { useTranslate } from '@ur/react-hooks'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { format } from 'util/date-fns'
import { unlessLoadingDots } from 'util/loading'
import {
  InfringementCategoryNode,
  useSeverityBackground,
  useInfringementSeverityFunctions,
} from '..'

const Wrapper = styled.section`
  grid-area: main;

  display: grid;
  grid-template-columns: 2fr auto;

  span.unknown {
    color: ${props => props.theme.colors.red};
  }

  ${props => props.theme.media.mobile} {
    overflow-x: auto;
  }
`

interface InfringementStatusProps {
  background: string
}
const InfringementStatus = styled.div<InfringementStatusProps>`
  display: flex;
  align-items: center;

  &::after {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    margin-left: 0.5ch;
    margin-bottom: 3px;

    border-radius: 50%;
    background: ${props => props.background};
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;

  & + div {
    border-left: 1px solid ${props => props.theme.colors.gray8};

    &:nth-child(4) {
      border-left: 0;
    }
    &:nth-child(n + 4) {
      border-top: 1px solid ${props => props.theme.colors.gray8};
    }
  }

  font-weight: 600;

  span {
    color: ${props => props.theme.colors.gray5};
  }
  p {
    margin: 0;
    font-size: 1.6em;
  }

  ${props => props.theme.media.mobile} {
    padding: 1rem;

    span {
      font-size: 0.7rem;
    }
    p {
      font-size: 1rem;
    }
  }
`

interface MainInfoProps {
  loading: boolean

  category: InfringementCategoryNode
  timestamp: Date | undefined
  archivedAt: string | null | undefined

  startDate: Date
  endDate: Date
}

export const MainInfo: React.VFC<MainInfoProps> = ({
  loading,

  category,
  timestamp,
  archivedAt,

  startDate,
  endDate,
}) => {
  const translations = useTranslate({
    unknown: 'common.unknown',

    open: 'common.open-alt',
    closed: 'common.closed',
    archived: 'common.archived',

    status: 'common.status',
    date: 'common.date',
    user: 'common.user',
    responsible: 'common.responsible',

    none: 'common.none',
    severity: 'common.severity',

    minorInfringement: 'infringements.minor-infringement',
    seriousInfringement: 'infringements.serious-infringement',
    verySeriousInfringement: 'infringements.very-serious-infringement',
    mostSeriousInfringement: 'infringements.most-serious-infringement',
    timestamp: 'common.timestamp',
    period: 'common.period',
  })

  const getSeverityBackgroundColor = useSeverityBackground()

  const unlessLoading = unlessLoadingDots(loading, translations.unknown)
  const { getSeverityTranslation } = useInfringementSeverityFunctions()
  const severityDisplayText = getSeverityTranslation(category.severity)

  const infringementStatus = useMemo(() => {
    return (
      <InfringementStatus
        background={getSeverityBackgroundColor(category.severity)}
      >
        {category.identifier} - {severityDisplayText}
        {archivedAt !== null ? ' (' + translations.archived + ')' : null}
      </InfringementStatus>
    )
  }, [
    archivedAt,
    category.identifier,
    category.severity,
    getSeverityBackgroundColor,
    severityDisplayText,
    translations.archived,
  ])

  const timestampString = format(timestamp ?? new Date(), 'dd.MM.yyyy HH:mm')

  return (
    <Wrapper>
      <Info>
        <span>{translations.severity}</span>
        <p>{unlessLoading(infringementStatus)}</p>
      </Info>
      <Info>
        <span>{translations.timestamp}</span>
        <p>{unlessLoading(timestampString)}</p>
      </Info>
    </Wrapper>
  )
}
