import React from 'react'

export const TruckSide: React.VFC<React.SVGAttributes<SVGSVGElement>> =
  props => (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 55 55"
      enableBackground="new 0 0 55 55"
      xmlSpace="preserve"
      fill="#003057"
      {...props}
    >
      <g transform="translate(0,-952.36218)">
        <path
          className="st0"
          d="M50.818,960.783c2.159,0,3.928,1.788,3.928,3.947v21.476c0,2.159-1.769,3.947-3.928,3.947h-3.85
		c-0.656,2.325-2.791,4.044-5.321,4.044c-2.529,0-4.684-1.717-5.34-4.044H14.656c-0.657,2.326-2.813,4.044-5.34,4.044
		c-2.527,0-4.664-1.718-5.321-4.044H3.492c-1.776,0-3.231-1.462-3.231-3.231v-7.855c-0.018-0.176,0.002-0.354,0.058-0.522
		c0.006-0.026,0.012-0.052,0.019-0.077c0.054-0.157,0.14-0.302,0.252-0.426l6.617-9.171c0.235-0.319,0.61-0.506,1.006-0.503
		l8.01-0.019v-3.618c0-2.159,1.769-3.947,3.928-3.947L50.818,960.783L50.818,960.783z M34.74,969.471
		c-0.039-0.002-0.077-0.002-0.116,0H22.473c-0.684-0.002-1.24,0.552-1.241,1.236c-0.002,0.684,0.552,1.24,1.236,1.241
		c0.002,0,0.004,0,0.006,0h12.151c0.684,0.032,1.264-0.496,1.296-1.18C35.952,970.083,35.423,969.503,34.74,969.471z
		 M14.153,971.773c-0.039-0.002-0.077-0.002-0.116,0H9.8c-0.4,0.001-0.775,0.196-1.006,0.522l-4.295,6.037
		c-0.395,0.558-0.264,1.331,0.294,1.726c0.213,0.151,0.469,0.231,0.731,0.228h8.513c0.684,0,1.238-0.554,1.238-1.238v-6.037
		C15.278,972.37,14.791,971.833,14.153,971.773z M40.292,975.024c-0.039-0.002-0.077-0.002-0.116,0H22.473
		c-0.684-0.001-1.24,0.552-1.241,1.236c-0.002,0.684,0.552,1.24,1.236,1.241c0.002,0,0.004,0,0.006,0h17.704
		c0.684,0.032,1.264-0.496,1.296-1.18S40.977,975.056,40.292,975.024C40.293,975.024,40.293,975.024,40.292,975.024z
		 M41.647,985.588c-1.704,0-3.076,1.345-3.076,3.057v0.077c0.036,1.671,1.392,2.999,3.076,2.999c1.705,0,3.051-1.358,3.057-3.057
		v-0.019C44.704,986.933,43.351,985.588,41.647,985.588L41.647,985.588z M9.316,985.588c-1.704,0-3.057,1.345-3.057,3.057
		c0,1.704,1.353,3.076,3.057,3.076c1.704,0,3.076-1.372,3.076-3.076C12.392,986.932,11.02,985.588,9.316,985.588z"
        />
        <path
          className="st0"
          d="M51.67,996.464c0.684-0.008,1.244,0.54,1.252,1.224s-0.54,1.245-1.224,1.252c-0.009,0-0.019,0-0.028,0H4.633
		c-0.684,0.008-1.244-0.54-1.252-1.224c-0.008-0.684,0.54-1.245,1.224-1.252c0.009,0,0.019,0,0.028,0H51.67z"
        />
      </g>
    </svg>
  )
