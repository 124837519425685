import { Icon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { Card } from 'components/Card'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;

  ${props => props.theme.media.custom({ max: '1280px' })} {
    height: auto;
  }
`

const List = styled(Card)`
  display: flex;
  flex-direction: column;
  min-width: min-content;
  height: 100%;
  padding: 0;

  overflow: hidden;
  background: white;
`
const Item = styled(Link)`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 1.6em 0;
  min-width: min-content;

  font-size: 1.4em;
  color: ${props => props.theme.colors.text.dark};
  font-weight: 600;
  white-space: nowrap;

  & + a {
    border-top: 1px solid ${props => props.theme.colors.gray8};
  }

  i {
    color: ${props => props.theme.colors.gray5};
    transition: transform ease-out 0.2s;
    padding-left: 1rem;
  }
  &:hover {
    text-decoration: none;
    background: ${props => props.theme.colors.quaternary};
    color: ${props => props.theme.colors.secondary};

    i {
      transform: translateX(8px);
      color: ${props => props.theme.colors.secondary};
    }
  }

  ${props => props.theme.media.custom({ max: '1280px' })} {
    min-height: 6.5rem;
  }
  ${props => props.theme.media.mobile} {
    padding: 1.6em;

    font-size: 1.2em;
  }
`

interface DashboardControlProps {}

export const DashboardControl: React.VFC<DashboardControlProps> = () => {
  const translations = useTranslate({
    stoppedForInspection: 'users.stopped-in-control',

    activities: 'documentation.drive-and-rest-time',
    issues: 'common.issues',
    handbook: 'documentation.documentation-for-control',
  })

  return (
    <Wrapper>
      <h2>{translations.stoppedForInspection}</h2>

      <List>
        <Item to="/activities">
          {translations.activities}
          <Icon icon="chevron-right" type="light" />
        </Item>
        <Item to="/issues">
          {translations.issues}
          <Icon icon="chevron-right" type="light" />
        </Item>
        <Item to="/handbook">
          {translations.handbook} <Icon icon="chevron-right" type="light" />
        </Item>
      </List>
    </Wrapper>
  )
}
