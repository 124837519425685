import { usePrompt } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import {
  AllTerminalsQuery,
  ALL_TERMINALS,
  TerminalNode,
} from 'modules/terminal'
import React from 'react'
import { useQuery } from '@apollo/client'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { useConfirm, useOnErrorAuto } from 'util/hooks'
import {
  AddButton,
  CreateTerminalPrompt,
  InfoBox,
  Section,
  SectionHeader,
  SettingsWrapper,
  TerminalForm,
  TerminalSettingsCards,
  TerminalSettingsTable,
} from '../components'
import { useTerminalMutations } from '../util/mutations.hooks'

export const LS_KEY_LIST_VIEW_MODE_TERMINAL_SETTINGS =
  'firmadok-list-view-mode-terminal-settings'

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

interface TerminalSettingsProps {}

export const TerminalSettings: React.VFC<TerminalSettingsProps> = () => {
  const translations = useTranslate({
    info1: 'settings.info.terminal-1',
    info2: 'settings.info.terminal-2',

    terminalNetworks: 'terminal.terminal-areas',

    prompt: {
      delete: ['settings.prompts.terminal-delete', { name: '' }],
      deleteTitle: 'settings.prompts.terminal-delete-title',
    },
  })

  const addPrompt = usePrompt()
  const confirm = useConfirm()
  const onErrorAuto = useOnErrorAuto()

  const { data: queryData, loading: queryLoading } =
    useQuery<AllTerminalsQuery>(ALL_TERMINALS, {
      onError: onErrorAuto(),
    })

  const terminals = queryData?.allTerminals.edges.map(edge => edge.node) ?? []

  const mutations = useTerminalMutations()

  async function handleAdd() {
    const { data } = await addPrompt<TerminalForm | null>(resolve => (
      <CreateTerminalPrompt onSubmit={resolve} />
    ))
    if (data === null) return

    mutations.create({
      variables: {
        input: {
          name: data.name,
          ip: data.ip,
          ipFilterRange: data.range,
        },
      },
    })
  }
  async function handleEdit(terminal: TerminalNode) {
    const initialData: TerminalForm = {
      name: terminal.name,
      ip: terminal.ip,
      range: terminal.ipFilterRange,
    }
    const { data } = await addPrompt<TerminalForm | null>(resolve => (
      <CreateTerminalPrompt initialData={initialData} onSubmit={resolve} />
    ))
    if (data === null) return

    mutations.patch({
      variables: {
        id: terminal.id,
        input: {
          name: data.name,
          ip: data.ip,
          ipFilterRange: data.range,
        },
      },
    })
  }
  async function handleDelete(terminal: TerminalNode) {
    const { data: answer } = await confirm(
      translations.prompt.delete({ name: terminal.name }),
      translations.prompt.deleteTitle
    )
    if (!answer) return

    mutations.delete({
      variables: {
        id: terminal.id,
      },
    })
  }

  const isLoading = queryLoading || mutations.loading

  return (
    <SettingsWrapper grid={{ flow: 'row' }}>
      <InfoBox initCollapsed={isMobileOnly}>
        <p>{translations.info1}</p>
        <p>{translations.info2}</p>
      </InfoBox>

      <Section>
        <Header>
          <SectionHeader loading={isLoading}>
            {translations.terminalNetworks}
          </SectionHeader>

          {!isMobileOnly && <AddButton adding={false} onClick={handleAdd} />}
        </Header>

        {isMobileOnly ? (
          <TerminalSettingsCards
            terminals={terminals}
            onAdd={handleAdd}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        ) : (
          <TerminalSettingsTable
            terminals={terminals}
            onAdd={handleAdd}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        )}
      </Section>
    </SettingsWrapper>
  )
}
