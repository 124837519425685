import { Module } from 'modules/companies'
import { useModuleConfig } from './useModuleConfig'
import { useMemo } from 'react'
import { useUser } from './useUser'

export function useModuleResponsible(name: Module) {
  const user = useUser()

  const { moduleConfig } = useModuleConfig(name)

  const responsibleUsers = moduleConfig?.responsibleUsers

  const isResponsibleUser = useMemo(() => {
    return responsibleUsers?.map(node => node.id).includes(user.id)
  }, [user, responsibleUsers])

  return {
    responsibleUsers,
    isResponsibleUser,
  }
}
