import { useForm, useTranslate } from '@ur/react-hooks'
import {
  endOfMonth,
  formatISO,
  isSameDay,
  parseISO,
  startOfMonth,
} from 'date-fns'
import truncate from 'lodash/truncate'
import { generateAbsenceDates } from 'modules/timesheets/util'
import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router'
import {
  useCompany,
  useModuleConfig,
  useOnErrorAuto,
  useUser,
} from 'util/hooks'
import { isInvalidDate } from 'util/time'
import { ALL_TERMINAL_ENTRIES } from './queries'
import {
  AllTerminalEntriesQuery,
  AllTerminalEntriesQueryVariables,
} from './types.graphql'
import { useTerminalExportMutations } from './mutations.hooks'
import { SelectOption } from '@ur/react-components'
import {
  ExportFormat,
  ExportOption,
  ExportType,
  getSalaryDatesForMonth,
} from 'modules/exports'
import { ExportMassTerminalEntriesForm } from './TerminalView/types'
import { USER_USER_TYPES_QUERY } from 'modules/users/queries'
import { UserUserTypesQuery } from 'modules/users/types.graphql'
import { IdVariable } from 'types/graphql/common'
import { Module } from 'modules/companies/consts'
import { getUsersHighestPrioritySalaryWorkTime } from 'modules/companies/util'
import { format } from 'util/date-fns'
import { notEmptyArrayValidation } from 'util/forms'

export interface ControlsFilter {
  user: string
  month: Date
}

export function useTerminalFilter(userId?: string) {
  const me = useUser()
  const {
    formValues: filter,
    updateForm: updateFilter,
    formChangeHandler: filterHandler,
  } = useForm<ControlsFilter>({
    values: {
      user: userId ?? me.id,
      month: new Date(),
    },
    config: {
      storage: {
        storeFormState: true,
        excludeFields: ['user'],
        retrieveFormStateOnMount: true,
        sessionStorage: true,
        storeFormStateName: 'firmadok__terminal-overview-filter',
        transformValues: {
          month: val =>
            typeof val === 'string'
              ? isInvalidDate(new Date(val), new Date())
              : undefined,
        },
      },
    },
  })

  return {
    filter,
    updateFilter,
    filterHandler,
  }
}

export function useTerminalQueries(userId: string, month: Date) {
  const history = useHistory()
  const onErrorAuto = useOnErrorAuto()
  const checkInGt = startOfMonth(month)
  const checkInLt = endOfMonth(month)
  const translations = useTranslate({
    error: 'error.could-not-find-data',
  })

  const { loading, data: terminalEntriesForMonth } = useQuery<
    AllTerminalEntriesQuery,
    AllTerminalEntriesQueryVariables
  >(ALL_TERMINAL_ENTRIES, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      user: userId,
      checkInGte: formatISO(checkInGt),
      checkInLt: formatISO(checkInLt),
    },
    onError: onErrorAuto(translations.error),
  })

  const terminalEntries =
    terminalEntriesForMonth?.allTerminalEntries?.edges?.map(
      edge => edge.node
    ) || []
  const absences = useMemo(
    () =>
      terminalEntriesForMonth?.allAbsences?.edges.map(edge => edge.node) || [],
    [terminalEntriesForMonth]
  )
  const absenceDates = useMemo(
    () => generateAbsenceDates(absences, checkInGt),
    [absences, checkInGt]
  )

  const filteredDates = absenceDates.filter(
    date =>
      terminalEntries.filter(node =>
        isSameDay(parseISO(node.checkIn), date.date)
      ).length === 0
  )

  const absenceEntries = filteredDates.map((absence, i) => {
    return {
      id: `absence-${absence.date}-${i}`,
      checkIn: absence.date,
      checkOut: truncate(absence.reason, { length: isMobile ? 80 : 120 }),
      props: {
        disabled: true,
        onClick: () => {
          history.push(`/absences`, {
            userIdHistory: userId,
            date: absence.date,
          })
        },
      },
    }
  })

  return {
    terminalEntries,
    absenceEntries,
    loading,
  }
}

export function useMassTerminalExportForm(onCompleted?: () => void) {
  const me = useUser()
  const company = useCompany()

  const translations = useTranslate({
    salaryExport: 'exports.salary-export',
    monthExport: 'exports.month-export',
    selectUsersError: 'activities.errors.select-multiple-users',
  })

  const { exportTerminalMass, loading: terminalMassLoading } =
    useTerminalExportMutations(onCompleted)

  const {
    formValues: form,
    submitHandler: submit,
    updateForm,
    formErrors: errors,
  } = useForm<ExportMassTerminalEntriesForm>({
    values: {
      month: new Date(),
      dateStart: new Date(),
      dateEnd: new Date(),
      exportType: ExportType.MASS_ENTRY_MONTH,
      exportOption: ExportOption.DOWNLOAD,
      exportFormat: ExportFormat.PDF,
      useDateRange: false,
      user: null,
      users: [],
    },
    validators: {
      users: value =>
        notEmptyArrayValidation(value) ? translations.selectUsersError : null,
    },
    config: {
      storage: {
        storeFormState: true,
        retrieveFormStateOnMount: true,
        storeFormStateName: 'Export-terminal-mass-form',
        transformValues: {
          month: value =>
            typeof value === 'string'
              ? isInvalidDate(new Date(value), new Date())
              : undefined,
        },
      },
    },
  })

  const { data } = useQuery<UserUserTypesQuery, IdVariable>(
    USER_USER_TYPES_QUERY,
    {
      variables: { id: me.id ?? '' },
    }
  )
  const user = data?.user

  const { moduleConfig } = useModuleConfig(Module.TERMINAL)

  const salaryWorkTime = getUsersHighestPrioritySalaryWorkTime(
    user,
    moduleConfig
  )

  const { year, month } = {
    month: form.month.getMonth(),
    year: form.month.getFullYear(),
  }
  const salaryDates = getSalaryDatesForMonth(
    salaryWorkTime?.salaryDateStart ?? 27,
    salaryWorkTime?.salaryDateEnd ?? 26,
    year,
    month
  )

  const exportTypes: SelectOption<ExportType>[] = [
    {
      value: ExportType.MASS_ENTRY_MONTH,
      label: translations.monthExport,
    },
    {
      value: ExportType.MASS_ENTRY_SALARY,
      label: `${translations.salaryExport} (${format(
        salaryDates[0],
        'dd.MMMM'
      )}-${format(salaryDates[1], 'dd.MMMM')})`,
    },
  ]

  function handler<K extends keyof ExportMassTerminalEntriesForm>(key: K) {
    return (value: ExportMassTerminalEntriesForm[K] | null) => {
      if (value === null) return
      updateForm({ [key]: value })
    }
  }

  async function handleExport(values: ExportMassTerminalEntriesForm) {
    const date = form.useDateRange ? form.dateStart : startOfMonth(values.month)
    const [dateStart, dateEnd] = getDateInterval(date, form.dateEnd)
    const orderBy = 'last_name'
    exportTerminalMass({
      variables: {
        exportType: values.exportType,
        exportFormat: values.exportFormat,
        userMail: me.id,
        company: company.id,
        dateStart: dateStart,
        dateEnd: dateEnd,
        user: me.id,
        users: form.users,
        orderBy: orderBy,
        exportOption: values.exportOption,
      },
    })
  }

  function getDateInterval(startDate: Date, endDate?: Date) {
    if (form.exportType === ExportType.MASS_ENTRY_SALARY) {
      return [formatISO(salaryDates[0]), formatISO(salaryDates[1])]
    }
    if (form.useDateRange) {
      const dateEnd = endDate || new Date()
      return [formatISO(startDate), formatISO(dateEnd)]
    }
    return [
      formatISO(startOfMonth(startDate)),
      formatISO(endOfMonth(startDate)),
    ]
  }

  return {
    form,
    errors,
    loading: terminalMassLoading,
    exportTypes,
    handler,
    updateForm,
    submit,
    handleExport,
  }
}
