import { useTimeSince } from 'util/hooks'

export interface TimeSinceProps {
  timestamp: Date
  cutOff?: number
}

export const TimeSince: React.FC<TimeSinceProps> = ({ timestamp, cutOff }) => {
  return <>{useTimeSince(timestamp, cutOff)}</>
}
