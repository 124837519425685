import { gql } from '@apollo/client'

export const FETCH_DRIVER_ACTIVITIES = gql`
  mutation FetchActivities($dateStart: DateTime!, $dateEnd: DateTime!) {
    fetchActivities(dateStart: $dateStart, dateEnd: $dateEnd) {
      ok
    }
  }
`

export const TOGGLE_APPROVED_DRIVER_ACTIVITY_MUTATION = gql`
  mutation ToggleApprovedDriverActivitiesMutation(
    $user: ID!
    $datetimeStart: DateTime!
    $datetimeEnd: DateTime!
    $approve: Boolean!
  ) {
    toggleApprovedDriverActivity(
      user: $user
      datetimeStart: $datetimeStart
      datetimeEnd: $datetimeEnd
      approve: $approve
    ) {
      ok
    }
  }
`

export const UPDATE_OR_CREATE_DRIVER_DIET_MUTATION = gql`
  mutation UpdateOrCreateDriverDiet(
    $input: UpdateOrCreateDriverDietInput!
  ) {
    updateOrCreateDriverDiet(
      input: $input
    ) {
      ok
    }
  }
`

export const UPDATE_OR_CREATE_SALARY_DAY_MUTATION = gql`
  mutation UpdateOrCreateSalaryDay(
    $input: UpdateOrCreateSalaryDayInput!
  ) {
    updateOrCreateSalaryDay(
      input: $input
    ) {
      ok
    }
  }
`