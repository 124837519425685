import {
  CSSTextAlign,
  CSSTextTransform,
} from '@ur/react-components/build/types/css'
import styled from 'styled-components'
import { HTMLRel, HTMLTarget } from 'types/html'
import { CSSFontWeight } from 'types/style'
import { overloadColor } from 'util/style'

export interface ExternalLinkProps {
  href: string
  rel?: HTMLRel
  target?: HTMLTarget
  hoverDecoration?: string

  fontSize?: string
  fontWeight?: CSSFontWeight
  textAlign?: CSSTextAlign
  textTransform?: CSSTextTransform
  lineHeight?: string | number

  block?: boolean
  color?: string

  userSelect?: string
}

export const ExternalLink = styled.a<ExternalLinkProps>`
  text-decoration: none;
  display: ${props => (props.block ? 'block' : 'inline')};
  color: ${props => overloadColor(props.color ?? 'link')};

  font-size: ${props => props.fontSize};
  text-align: ${props => props.textAlign};
  text-transform: ${props => props.textTransform};
  font-weight: ${props => props.fontWeight};
  line-height: ${props => props.lineHeight};

  :hover {
    text-decoration: ${props => props.hoverDecoration};
  }
`

ExternalLink.defaultProps = {
  rel: 'noopener',
  target: '_blank',
  textAlign: 'left',
  textTransform: 'none',
  fontWeight: 'normal',
  lineHeight: 1.5,
  color: 'link',
  hoverDecoration: 'underline',
}

export const PlainExternalLink = styled(ExternalLink)`
  color: inherit;
  :hover {
    text-decoration: none;
  }
`
