import { AllowedLocale } from './util'

export interface Languages {
  name: string
  code: AllowedLocale
}

export const languages: Languages[] = [
  { name: 'Norsk Bokmål', code: 'nb' },
  { name: 'English', code: 'en' },
  { name: 'Pусский', code: 'ru' },
  { name: 'Latvietis', code: 'lv' },
  { name: 'Română', code: 'ro' },
  { name: 'Polskie', code: 'pl' },
]
