import { format, startOfDay } from 'date-fns'
import { FormTemplateFieldNode } from 'modules/formcreator/types.graphql'
import { KeyValue } from 'types/util'
import { FieldType } from './consts'
import {
  FilledOutFormValueNode,
  FormTemplateResponseOverviewQuery,
} from './types.graphql'

export const getDisplayValueFromFieldType = (
  filledOutField: FilledOutFormValueNode,
  fieldType: string,
  translations: { yes: string; no: string }
) => {
  if (fieldType === FieldType.DATE || fieldType === FieldType.DATETIME) {
    return (
      <p>{format(new Date(filledOutField.value as string), 'dd. MMM yyyy')}</p>
    )
  } else if (fieldType === FieldType.USER) {
    return <>{filledOutField?.value ?? ''}</>
  } else if (fieldType === FieldType.CHECKBOX) {
    return filledOutField.value === 'true' ? (
      <>{translations.yes}</>
    ) : (
      <>{translations.no}</>
    )
  } else {
    return <>{filledOutField.value ?? ''}</>
  }
}

function defaultFieldValue(field: FormTemplateFieldNode) {
  switch (field.fieldType) {
    case 'TEXT':
    case 'USER':
      return ''
    case 'NUMBER':
      if (field.minimum !== null) return field.minimum.toString()
      else if (field.maximum !== null && field.maximum < 0)
        return field.maximum.toString()

      return '0'
    case 'CHECKBOX':
      return 'false'
    case 'DATE':
      return startOfDay(new Date()).toUTCString()
    default:
      return ''
  }
}

export const createFieldValues = (fields: FormTemplateFieldNode[]) =>
  fields.reduce<KeyValue<string>>((acc, cur) => {
    acc[cur.id] = defaultFieldValue(cur)
    return acc
  }, {})

export const validateFieldValues = (
  fields: FormTemplateFieldNode[],
  values: KeyValue<string>
) =>
  fields.every(field => {
    if (!field.required) return true

    const value = values[field.id]
    if (typeof value === 'undefined') return false

    return value !== ''
  })

/**
 * Returns response values in same order as form fields
 * @param values Response values
 * @param fields Form fields
 */
export const matchValues = (
  values: FormTemplateResponseOverviewQuery['formTemplate']['responses'][0]['values'],
  fields: FormTemplateResponseOverviewQuery['formTemplate']['fields']
) =>
  fields.map(
    ({ id }) => values.find(value => value.formTemplateField.id === id) ?? null
  )
