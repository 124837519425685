import { Cell, CellProps } from 'components'
import styled from 'styled-components'
import { ZIndexRange } from 'types/style'

const StyledStatus = styled.div<{ dataStatusColor: string }>`
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  position: relative;

  &::before {
    content: '';
    display: inline-block;

    width: 1rem;
    height: 1rem;
    margin-right: 0.8ch;
    margin-bottom: 1px;

    border-radius: 50%;
    background: ${props => props.dataStatusColor};
  }

  .tooltip {
    visibility: hidden;
    position: absolute;
    z-index: ${ZIndexRange.Overlays};
    padding: 0.5rem 1rem;
    border-radius: ${props => props.theme.standardBorderRadius};
    background: ${props => props.theme.colors.white};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    left: -30%;
  }

  :hover .tooltip {
    visibility: visible;
  }
`

interface ActivitiesStatusProps {
  className?: string
  dataStatusColor: string
  tooltip?: string
  tooltipProps?: React.CSSProperties
}
export const ActivitiesStatus: React.FC<ActivitiesStatusProps> = ({
  className,
  dataStatusColor,
  tooltip,
  tooltipProps = undefined,
}) => {
  return (
    <StyledStatus className={className} dataStatusColor={dataStatusColor}>
      {tooltip && <div className="tooltip" style={tooltipProps}>{tooltip}</div>}
    </StyledStatus>
  )
}
interface ActivitiesStatusCellProps extends ActivitiesStatusProps, CellProps {}
export const ActivitiesStatusCell: React.VFC<ActivitiesStatusCellProps> = ({
  narrow = true,
  centered = true,
  ...statusProps
}) => (
  <Cell narrow={narrow} centered={centered}>
    <ActivitiesStatus {...statusProps} />
  </Cell>
)
