import { useToast } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { GoogleMap } from 'components'
import isEqual from 'lodash/isEqual'
import { Coordinates } from 'types/graphql/maps'
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled, { useTheme } from 'styled-components'
import { ActivityArea } from './ActivityArea'
import { VehicleColorDictionary } from 'modules/activities/types'

const Wrapper = styled(ActivityArea)`
  overflow: hidden;
`

interface BoundsLatLng {
  sw: Coordinates
  ne: Coordinates
}

interface Bounds {
  south: number
  west: number
  north: number
  east: number
}

interface MapProps {
  vehicleLocations: {
    coordinates: Coordinates[]
    vehicleVin?: string | null
  }[]
  pathColors: VehicleColorDictionary
}

export const Map: React.VFC<MapProps> = ({ vehicleLocations, pathColors }) => {
  const translations = useTranslate({
    error: {
      couldNotLoadRoute: 'activities.errors.could-not-load-route-in-map',
    },
  })

  const coordinates = vehicleLocations.map(location => location.coordinates)

  const prevCoordinates = useRef<Coordinates[][]>([])

  const theme = useTheme()
  const addToast = useToast()

  const [map, setMap] = useState<google.maps.Map | null>(null)

  const bounds = useMemo(() => {
    return coordinates.reduce<Bounds>(
      (acc, cur) => {
        const reducedCoordinates = cur.reduce<BoundsLatLng>(
          (innerAcc, innerCur) => {
            const swLat =
              innerAcc.sw.lat < innerCur.lat ? innerAcc.sw.lat : innerCur.lat
            const swLng =
              innerAcc.sw.lng < innerCur.lng ? innerAcc.sw.lng : innerCur.lng
            const neLat =
              innerAcc.ne.lat > innerCur.lat ? innerAcc.ne.lat : innerCur.lat
            const neLng =
              innerAcc.ne.lng > innerCur.lng ? innerAcc.ne.lng : innerCur.lng
            return {
              sw: { lat: swLat, lng: swLng },
              ne: { lat: neLat, lng: neLng },
            }
          },
          {
            sw: {
              lat: 90,
              lng: 180,
            },
            ne: {
              lat: -90,
              lng: -180,
            },
          }
        )
        const south =
          acc.south < reducedCoordinates.sw.lat
            ? acc.south
            : reducedCoordinates.sw.lat
        const west =
          acc.west < reducedCoordinates.sw.lng
            ? acc.west
            : reducedCoordinates.sw.lng
        const north =
          acc.north > reducedCoordinates.ne.lat
            ? acc.north
            : reducedCoordinates.ne.lat
        const east =
          acc.east > reducedCoordinates.ne.lng
            ? acc.east
            : reducedCoordinates.ne.lng
        return { south: south, west: west, north: north, east: east }
      },
      { south: 90, west: 180, north: -90, east: -180 }
    )
  }, [coordinates])

  const loadPolyline = useCallback(
    (map: google.maps.Map, coordinates: Coordinates[], color: string) => {
      const path = new google.maps.Polyline({
        path: coordinates,
        geodesic: true,
        strokeColor: color,
        strokeOpacity: 0.6,
        strokeWeight: 4,
      })

      for (let i = 0; i < coordinates.length; i++) {
        const coord = coordinates[i]

        if (i === 0 || i === coordinates.length - 1) {
          const marker = new google.maps.Marker({
            position: coord,
            label: { text: i === 0 ? 'A' : 'B', color: 'white' },
          })
          marker.setMap(map)
        } else {
          const marker = new google.maps.Marker({
            position: coord,
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              strokeWeight: 2,
              strokeColor: 'black',
              fillColor: 'white',
              fillOpacity: 1,
              scale: 4,
            },
          })
          marker.setMap(map)
        }
      }

      path.setMap(map)
    },
    []
  )

  const loadRoute = useCallback(
    (map: google.maps.Map, coordinates: Coordinates[], color: string) => {
      // 25 waypoints is the maximum for google maps, so we just draw a polyline in this case
      if (coordinates.length > 25 || coordinates.length < 2) {
        loadPolyline(map, coordinates, color)
        return
      }

      const service = new google.maps.DirectionsService()
      const renderer = new google.maps.DirectionsRenderer()
      renderer.setOptions({
        polylineOptions: {
          strokeColor: color,
        },
      })
      renderer.setMap(map)
      const waypoints: google.maps.DirectionsWaypoint[] = coordinates.map(
        coordinate => ({
          location: new google.maps.LatLng(coordinate.lat, coordinate.lng),
          stopover: false,
        })
      )
      const origin = waypoints.shift()?.location as
        | google.maps.LatLng
        | undefined
      const destination = waypoints.pop()?.location as
        | google.maps.LatLng
        | undefined

      if (!origin || !destination) return

      service.route(
        {
          origin,
          destination,
          waypoints,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === 'OK') {
            renderer.setDirections(response)
          } else addToast('warning', translations.error.couldNotLoadRoute)
        }
      )
    },
    [addToast, loadPolyline, translations.error.couldNotLoadRoute]
  )

  useLayoutEffect(() => {
    if (
      !pathColors ||
      !map ||
      isEqual(prevCoordinates.current, coordinates) ||
      !coordinates.length ||
      !coordinates.filter(elem => elem.length > 0).length
    )
      return
    prevCoordinates.current = coordinates

    const polyFunction = coordinates.length === 1 ? loadRoute : loadPolyline

    vehicleLocations.map((location, i) =>
      polyFunction(
        map,
        location.coordinates,
        location.vehicleVin ? pathColors[location.vehicleVin] : '#0088FF'
      )
    )

    // Set center and map bounds
    if (vehicleLocations.length === 1) {
      //When only one location is present, set the bounds a bit outwards, else it is too zoomed in.
      const lessZoomedBounds = {
        south: bounds.south - 0.08,
        west: bounds.west - 0.08,
        north: bounds.north + 0.08,
        east: bounds.east + 0.08,
      }
      map.fitBounds(lessZoomedBounds)
    } else {
      map.fitBounds(bounds)
    }
  }, [
    coordinates,
    map,
    loadPolyline,
    pathColors,
    bounds,
    loadRoute,
    vehicleLocations,
  ])

  return (
    <Wrapper area="map">
      <GoogleMap
        center={coordinates[0] && coordinates[0][0]}
        mapContainerStyle={{
          borderTopRightRadius: theme.sizes.defaultBorderRadius,
        }}
        options={{
          streetViewControl: false,
          zoomControl: false,
          mapTypeControl: false,
        }}
        onLoad={setMap}
      />
    </Wrapper>
  )
}
