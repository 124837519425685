import React from 'react'

export const Warning: React.VFC<React.SVGAttributes<SVGSVGElement>> = props => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 164.785 164.785"
    enableBackground="new 0 0 164.785 164.785"
    xmlSpace="preserve"
    fill="#003057"
    {...props}
  >
    <g>
      <g>
        <g>
          <g>
            <path
              className="st0"
              d="M125.848,133.484H38.939c-4.864,0-9.221-2.515-11.655-6.729c-2.434-4.215-2.434-9.246-0.001-13.46
					l43.454-75.264c2.433-4.214,6.79-6.73,11.656-6.73c4.865,0,9.223,2.516,11.656,6.73l43.454,75.264
					c2.432,4.215,2.432,9.246,0,13.46C135.069,130.968,130.712,133.484,125.848,133.484z M82.392,36.969
					c-2.817,0-5.339,1.456-6.747,3.896l-43.454,75.264c-1.408,2.439-1.408,5.352,0,7.791c1.409,2.439,3.931,3.896,6.747,3.896
					h86.909c2.816,0,5.338-1.456,6.746-3.895c1.408-2.439,1.408-5.352,0-7.792L89.14,40.865
					C87.731,38.426,85.209,36.969,82.392,36.969z"
            />
          </g>
        </g>
        <path
          className="st0"
          d="M83.581,113.496c-3.325,0.882-6.289-2.082-5.407-5.407c0.387-1.461,1.569-2.642,3.03-3.03
			c3.327-0.883,6.292,2.082,5.408,5.408C86.224,111.928,85.042,113.109,83.581,113.496z"
        />
        <path
          className="st0"
          d="M86.799,94.946c0,2.575-2.22,4.641-4.846,4.384c-2.289-0.223-3.966-2.281-3.966-4.581V66.183
			c0-2.433,1.973-4.406,4.406-4.406h0c2.433,0,4.406,1.973,4.406,4.406L86.799,94.946L86.799,94.946z"
        />
      </g>
    </g>
  </svg>
)
