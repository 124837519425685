import styled, { useTheme } from 'styled-components'
import { FirmadokLogoNoText } from 'components/icons/FirmadokLogoNoText'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: calc(50vh - 25px);
`

const LogoRotate = styled(FirmadokLogoNoText)`
  width: 50px;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`

export const FullPageLoader: React.VFC = () => {
  const theme = useTheme()
  return (
    <Wrapper>
      <LogoRotate fill={theme.colors.primary} width="1050px" />
    </Wrapper>
  )
}
