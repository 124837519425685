import { Checkmark, CheckmarkProps } from 'components/Table/cells'
import { MouseEventHandler } from 'react'
import styled from 'styled-components'

/* CheckmarkDiv */
interface StyledDivProps {
  centered: boolean

  padding?: string

  onClick?: MouseEventHandler<HTMLDivElement>
}
const StyledDiv = styled.div<StyledDivProps>`
  padding: ${props => props.padding};
  cursor: pointer;
  & > section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    align-items: ${props => props.centered && 'center'};
  }
`
export interface DivProps {
  className?: string
  centered?: boolean

  padding?: string

  onClick?: MouseEventHandler<HTMLDivElement>
}

const IconWrapper: React.FC<DivProps> = ({
  children,
  className,
  centered = false,
  padding,

  onClick,
}) => (
  <StyledDiv
    className={className}
    centered={!!centered}
    padding={padding}
    onClick={onClick}
  >
    <section>{children}</section>
  </StyledDiv>
)

interface CheckmarkDivProps extends CheckmarkProps {
  padding?: string
  onClick?: MouseEventHandler<HTMLDivElement>
}
export const CheckmarkDiv: React.VFC<CheckmarkDivProps> = ({
  onClick,
  padding,
  ...checkmarkProps
}) => (
  <IconWrapper centered onClick={onClick}>
    <Checkmark {...checkmarkProps} />
  </IconWrapper>
)
