import { gql } from '@apollo/client'

export const VEHICLE_PLATE_NUMBERS_QUERY = gql`
  query VehiclePlateNumbers($q: String, $limit: Int) {
    vehiclePlateNumbers(q: $q, limit: $limit)
  }
`

export const ALL_VEHICLES_QUERY = gql`
  query AllVehicles($q: String, $orderBy: String, $currentlyOwned: Boolean) {
    allVehicles(q: $q, orderBy: $orderBy, currentlyOwned: $currentlyOwned) {
      edges {
        node {
          id
          vehicleType
          brand
          model
          vin
          registrationNumber
          vehicleType

          vehicleFetchInfo {
            id
            lastFetch
            integrationEndpoint
          }
        }
      }
    }
  }
`

export const ALL_VEHICLES_SELECT_QUERY = gql`
  query AllVehicles($q: String, $orderBy: String, $currentlyOwned: Boolean) {
    allVehicles(q: $q, orderBy: $orderBy, currentlyOwned: $currentlyOwned) {
      edges {
        node {
          id
          vin
          innerHeight
          innerWidth
          innerLength
          registrationNumber
          engineMaximumSpeed
          netWeight
        }
      }
    }
  }
`

export const VEHICLE_DETAIL_QUERY = gql`
  query VehicleDetail($id: ID!) {
    vehicle(id: $id) {
      id
      department {
        id
        name
      }
      brand
      model
      vin
      registrationNumber
      registrationDate
      vehicleType
      vehicleTechnicalType
      color
      engineMaximumSpeed
      enginePower
      engineEuroClass
      engineVolume
      engineType
      netWeight
      payloadWeight
      allowedTotalWeight
      allowedTruckWeight
      allowedCouplingLoad
      allowedSemiTrailerWeight
      allowedFrontAxleLoad
      allowedBackAxleLoad
      # Service and Maintenance
      serviceAgreement
      tiresStuddedFront
      tiresStuddedBack
      tiresFrontDimension
      tiresDriveDimension
      tiresBogieDimension
      kmPerMonth
      kmPerYear
      # Administrative
      country
      stationingLocation
      soldDate
      licenseNumber
      tollTagNumber
      ferryCardNumber
      dieselCardNumber
      pinCode
      previousPeriodicControl
      nextPeriodicControl
      # Use properties
      coolFreezeHeat
      height
      sideOpening
      hasCrane
      craneType
      backLifter
      vbgCoupling
      palletSpaces
      goodsInsuranceType
      extensionType
      innerHeight
      innerWidth
      innerLength
      loadingHeight
      fridge
      fridgeType
      meatRack
    }
  }
`
export const VEHICLE_DOCUMENTS_QUERY = gql`
  query VehicleDocuments($vehicle: ID!) {
    allVehicleDocuments(vehicle: $vehicle) {
      edges {
        node {
          id
          name
          description
          file
          originalName
          expirationDate
          notificationTime
          vehicle {
            id
          }
          responsibleUser {
            id
            fullName
          }
        }
      }
    }
  }
`

export const VEHICLE_EXPIRATIONS_QUERY = gql`
  query VehicleExpirations($vehicle: ID!) {
    allVehicleExpirations(vehicle: $vehicle) {
      edges {
        node {
          id
          description
          expirationType
          expirationDate
          notificationTime
          vehicle {
            id
          }
          responsibleUser {
            id
            fullName
          }
        }
      }
    }
  }
`
