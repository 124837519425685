import { Icon, Loader, useToast } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { Button, CenteredLoader } from 'components'
import { Card } from 'components/Card'
import { PlainLink } from 'components/Link'

import queryString from 'query-string'
import React, { useEffect, useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { isBrowser, isMobileOnly } from 'react-device-detect'
import ScrollBar from 'react-perfect-scrollbar'
import { useHistory, useLocation, useParams } from 'react-router'
import styled from 'styled-components'
import { removeCurrentYear } from 'util/date'
import { format } from 'util/date-fns'
import { useBreadcrumbs, useConfirm, useOnErrorAuto, usePermissions } from 'util/hooks'
import { toGlobalId } from 'util/parsing'
import { ResponseValueCell } from '../components'
import { FORM_TEMPLATE_RESPONSE_OVERVIEW_QUERY } from '../queries'
import {
  FilledOutFormNode,
  FormTemplateResponseOverviewQuery,
  FormTemplateResponseOverviewQueryVariables,
} from '../types.graphql'
import { matchValues } from '../util'
import { PERMISSIONS } from 'util/permissions'
import { DELETE_FILLED_OUT_FORM } from '../mutations'

const Wrapper = styled.div`
  ${props => props.theme.layout.default};

  header {
    display: flex;
    justify-content: space-between;

    h2 {
      display: flex;

      div.loader {
        margin-left: 0.5rem;
      }
    }
    div.buttons {
      button {
        height: 3rem;
      }
    }
  }

  ${props => props.theme.media.mobile} {
    header {
      flex-direction: column;

      h2 {
        margin: 0 0 0.5rem 0;
      }

      div.buttons {
        display: flex;
        flex-direction: column;

        margin-bottom: 1rem;
      }
    }
  }
`
const Content = styled(Card)`
  position: relative;
  padding: 2rem;
  overflow: auto;

  div.details {
    p {
      max-width: 600px;
      margin: 0 0 0.5rem 0;
      white-space: pre-wrap;
    }
    span {
      display: block;
      margin: 0 0 0.5rem 0;
      font-size: 0.9rem;
      color: ${props => props.theme.colors.gray4};
    }
  }

  ${props => props.theme.media.mobile} {
    padding: 1rem;

    div.details {
      p {
        max-width: 100%;
      }
    }
  }
`
const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0;

  th,
  td {
    padding: 8px 12px;

    text-align: left;
    border: 1px solid ${props => props.theme.colors.gray8};
    border-width: 1px 0 0 1px;

    &:last-child {
      border-right-width: 1px;
    }
    &.nowrap {
      white-space: nowrap;
    }
    &.last-common {
      border-right-width: 2px;
      border-right-color: ${props => props.theme.colors.gray6};
    }
    &.hoverable {
      cursor: pointer;

      &:hover {
        background-color: ${props => props.theme.colors.secondary}20;
      }
    }
  }

  th {
    background-color: ${props => props.theme.colors.quaternary};

    &:first-child {
      border-top-left-radius: 8px;
    }
    &:last-child {
      border-top-right-radius: 8px;
    }
  }

  tr {
    &:nth-child(even) td {
      background-color: ${props => props.theme.colors.gray11};
    }
  }

  tbody tr:last-child {
    td {
      border-bottom-width: 1px;

      &:first-child {
        border-bottom-left-radius: 8px;
      }
      &:last-child {
        border-bottom-right-radius: 8px;
      }
    }
  }
`

interface ResponseOverviewProps {}

export const ResponseOverview: React.VFC<ResponseOverviewProps> = () => {
  const translations = useTranslate({
    loading: 'common.loading...',
    responses: 'common.responses',
    loadAll: 'form.load-all-responses',
    allLoaded: 'form.all-responses-loaded',
    showingNumOfTotalResponses: [
      'form.showing-num-of-total-responses',
      { num: 0, total: 0 },
    ],

    user: 'common.user',
    externalId: 'common.external-id',
    date: 'common.date',
    delete: 'common.delete-alt',

    verifiedByAt: ['form.verified-by-at', { name: '', when: '' }],
    notVerifiedYet: 'common.not-verified-yet',

    prompt: {
      verify: 'form.prompts.verify-response',
      unverify: 'form.prompts.unverify-response',
      verifyTitle: 'form.prompts.verify-response-title',
      unverifyTitle: 'form.prompts.unverify-response-title',
      delete: 'form.delete-entry-prompt-text',
      deleteTitle: 'form.delete-entry-prompt-title',
      deleted: 'form.form-entry-deleted',

    },
  })

  const { hasPermissions } = usePermissions()
  const canViewOthers = hasPermissions(PERMISSIONS.formcreator.change.filledoutform, true)

  const { formId } = useParams<{ formId: string }>()
  const history = useHistory()
  const location = useLocation()
  const parsedParams = queryString.parse(location.search, {
    arrayFormat: 'comma',
  })
  // This looks kinda weird, but it prevents an error if node name of FilledOutFormNode is changed
  const nodeName: FilledOutFormNode['__typename'] = 'FilledOutFormNode'
  const responseIds = useMemo(
    () =>
      !parsedParams.ids
        ? undefined
        : (Array.isArray(parsedParams.ids)
            ? parsedParams.ids
            : [parsedParams.ids]
          ).reduce<string[]>(
            (acc, id) =>
              /^\d+$/.test(id)
                ? [...acc, toGlobalId(nodeName, parseInt(id, 10))]
                : acc,
            []
          ),
    [parsedParams.ids, nodeName]
  )

  const { setOverride, overrides } = useBreadcrumbs()
  const onErrorAuto = useOnErrorAuto()

  const { data, loading, refetch } = useQuery<
    FormTemplateResponseOverviewQuery,
    FormTemplateResponseOverviewQueryVariables
  >(FORM_TEMPLATE_RESPONSE_OVERVIEW_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      formId,
      responseIds,
    },
    onError: onErrorAuto(),
  })
  const confirm = useConfirm()
  const addToast = useToast()
  const [deleteMutation] = useMutation(DELETE_FILLED_OUT_FORM, {
    onError: onErrorAuto(),
    onCompleted: () => {
      addToast('success', translations.prompt.deleted)
      refetch()
    },
  })

  async function handleDelete(responseId: string) {
    const { data: answer } = await confirm(translations.prompt.delete, translations.prompt.deleteTitle)
    if (!answer) return
    deleteMutation({
      variables: {
        id: responseId,
      },
    })
  }

  useEffect(() => {
    if((!data && overrides[formId] !== translations.loading) || (data && overrides[formId] !== data.formTemplate.name)) {
      setOverride(formId, !data ? translations.loading : data.formTemplate.name)
    }
  }, [data, formId, overrides, setOverride, translations.loading])

  function handleLoadAll() {
    history.push(`/formcreator/${formId}/responses`)
  }

  if (!data) return <CenteredLoader />

  const { formTemplate: form } = data
  const isLoading = loading
  const allResponsesLoaded = canViewOthers ? form.responses.length === form.filledOutFormsCount : form.responses.length === form.myResponsesCount

  return (
    <Wrapper>
      <header>
        <h2>
          <span>
            {translations.responses}: {form.name}
          </span>

          {isLoading && (
            <div className="loader">
              <Loader.Spinner size={28} />
            </div>
          )}
        </h2>

        {(!isMobileOnly || !allResponsesLoaded) && (
          <div className="buttons">
            <Button
              disabled={allResponsesLoaded || isLoading}
              onClick={handleLoadAll}
            >
              {allResponsesLoaded
                ? translations.allLoaded
                : translations.loadAll}
            </Button>
          </div>
        )}
      </header>

      <Content>
        <div className="details">
          {!!form.ingress && <p>{form.ingress}</p>}

          <span>
            {translations.showingNumOfTotalResponses({
              num: form.responses.length,
              total: canViewOthers ? form.filledOutFormsCount : form.myResponsesCount,
            })}
          </span>
        </div>

        <ScrollBar>
          <Table>
            <thead>
              <tr>
                <th className="nowrap">{translations.user}</th>
                <th className="nowrap">{translations.externalId}</th>

                <th className="nowrap last-common">{translations.date}</th>

                {form.fields.map(field => (
                  <th key={`header-${field.id}`} className="nowrap">
                    {field.name}
                    {isBrowser && !!field.description && (
                      <Icon
                        icon="info-circle"
                        type="solid"
                        color="secondary"
                        margin="0 0 0 6px"
                        title={field.description}
                      />
                    )}
                  </th>
                ))}
                <th>
                  {translations.delete}
                </th>
              </tr>
            </thead>

            <tbody>
              {form.responses.map(response => {
                const date = removeCurrentYear(
                  format(new Date(response.createdAt), 'PP HH:mm')
                )
                const values = matchValues(response.values, form.fields)

                return (
                  <tr key={response.id}>
                    <td className="nowrap" width="1px">
                      <PlainLink
                        to={`/users/${response.filledBy.id}`}
                        hoverColor="secondary"
                      >
                        {response.filledBy.fullName}
                      </PlainLink>
                    </td>

                    <td>{response.filledBy.internalNumber}</td>

                    <td className="nowrap" width="1px">
                      {date}
                    </td>

                    {values.map((value, idx) =>
                      !value ? (
                        <td key={idx}></td>
                      ) : (
                        <ResponseValueCell
                          key={value.id}
                          title={value.formTemplateField.name}
                          value={value.value}
                          type={value.formTemplateField.fieldType}
                        />
                      )
                    )}
                    <td className="nowrap" width="10px">
                      <span style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <Icon
                          icon='times'
                          color='red'
                          cursor='pointer'
                          onClick={() => handleDelete(response.id)}
                        />
                      </span>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </ScrollBar>
      </Content>
    </Wrapper>
  )
}
