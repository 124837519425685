import { Icon } from '@ur/react-components'
import { useClickOutside, useTranslate } from '@ur/react-hooks'
import { PlainLink } from 'components/Link'

import { removeLoginToken } from 'modules/login/util'
import { UserNode } from 'modules/users'
import UserThumbOrInitials from 'modules/users/UserThumbOrInitials'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { ZIndexRange } from 'types/style'
import { useCompany, useUser } from 'util/hooks'

const Wrapper = styled.aside`
  position: absolute;
  z-index: ${ZIndexRange.Header};
  top: -0.5rem;
  right: -0.5rem;

  display: grid;
  grid-template-columns: auto auto;
  gap: 2rem;
  padding: 0.5rem;

  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  border-top-right-radius: 24px;
  background-color: white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);

  .thumb {
    grid-column: 2;
  }
`
const Menu = styled.div`
  grid-column: 1;

  display: flex;
  flex-direction: column;
  padding: 0.5rem 0 0.5rem 1rem;

  & > div[role='button'],
  & > a {
    white-space: nowrap;
    font-weight: 600;
    color: ${props => props.theme.colors.gray2};

    i {
      margin-right: 6px;
      color: ${props => props.theme.colors.gray4};
    }

    & + div,
    & + a {
      margin-top: 1rem;
    }
  }
`
const Company = styled.div.attrs({ role: 'button' })`
  margin-left: 36px;
  margin-top: 1rem;
`

interface MobileUserMenuProps {
  open: boolean
  user: UserNode
  onClose: () => void
}

export const MobileUserMenu: React.VFC<MobileUserMenuProps> = ({
  open,
  user,
  onClose,
}) => {
  const translations = useTranslate({
    myProfile: 'users.my-profile',
    settings: 'users.settings',
    changeCompany: 'common.change-company',
    logout: 'common.log-out',
  })

  const wrapperRef = useRef<HTMLElement>(null)

  const me = useUser()
  const company = useCompany()

  const [selectCompanyOpen, setSelectCompanyOpen] = useState(false)

  useClickOutside(wrapperRef, onClose)

  const companies = me.companies.filter(other => other.id !== company.id)

  function handleChangeCompany(shortName: string) {
    return (evt: React.MouseEvent<HTMLDivElement>) => {
      evt.stopPropagation()
      window.location.href = `/c/${shortName}/dashboard`
    }
  }
  function handleLogout() {
    removeLoginToken()
    window.location.href = '/login'
  }

  return !open ? null : (
    <Wrapper ref={wrapperRef}>
      <Menu>
        <PlainLink to="/usersettings">
          <Icon icon="cog" fixedWidth />
          {translations.settings}
        </PlainLink>

        <PlainLink to={`/users/${me.id}`}>
          <Icon icon="user" fixedWidth />
          {translations.myProfile}
        </PlainLink>

        {companies.length > 0 && (
          <div role="button" onClick={() => setSelectCompanyOpen(v => !v)}>
            <div>
              <Icon icon="building" fixedWidth />
              {translations.changeCompany}
            </div>

            {selectCompanyOpen &&
              companies.map(company => (
                <Company
                  key={company.id}
                  onClick={handleChangeCompany(company.shortName)}
                >
                  {company.fullName}
                </Company>
              ))}
          </div>
        )}

        <div role="button" onClick={handleLogout}>
          <Icon icon="sign-out" fixedWidth />
          {translations.logout}
        </div>
      </Menu>

      <UserThumbOrInitials
        className="thumb"
        user={user}
        size={42}
        color="primary"
        backgroundColor="secondary"
        fontSize="0.7em"
        fontWeight="600"
        onClick={onClose}
      />
    </Wrapper>
  )
}
