const ro = {
  'activities.add-salary-day': 'NEEDS TRANSLATION',
  'activities.approve-day': 'Aprobați activitățile pentru {date}?',
  'activities.approve-month':
    'Aprobați activitățile pentru {month} ({dateStart} - {dateEnd})?',
  'activities.approve-title': 'Aprobați activitățile',
  'activities.approve-week':
    'Aprobați activitățile pentru săptămâna {week} ({dateStart} - {dateEnd})?',
  'activities.availability-time': 'Timp de disponibilitate',
  'activities.consumption': 'Consum de combustibil',
  'activities.day-end': 'Sfârșit de zi',
  'activities.day-start': 'Început de zi',
  'activities.driver': 'Șofer',
  'activities.driving': 'Conducere',
  'activities.driving-grade': 'Clasă de conducere',
  'activities.end-position': 'Poziția finală',
  'activities.errors.could-not-load-route-in-map':
    'Nu s-a putut încărca ruta pe hartă',
  'activities.errors.general-error':
    'A apărut o problemă la aducerea activităților șoferului',
  'activities.errors.select-multiple-users':
    'Selectați cel puțin un utilizator pentru export',
  'activities.errors.user-not-found': 'Utilizatorul nu a fost găsit',
  'activities.export-driving-data': 'Export date de conducere',
  'activities.export-monthly-weekly': 'Export lunar sau săptămânal',
  'activities.fetch-data': 'Adunare date de conducere',
  'activities.fuel-usage': 'Consum de combustibil',
  'activities.last-fetched': 'Ultima adunare',
  'activities.missing-data': 'Date lipsă',
  'activities.module-title': 'Date de conducere',
  'activities.paid-pause': 'NEEDS TRANSLATION',
  'activities.pause-export': 'NEEDS TRANSLATION',
  'activities.print-driving-data': 'Tipărire activități',
  'activities.regnr': 'Regnr',
  'activities.rest': 'Odihnă',
  'activities.start-position':
    '{abbreviate, select, true {Start pos.} other {Poziție de început}}',
  'activities.starting-activity-fetching-process':
    'Pornire proces adunare activități',
  'activities.success.approve-activities': 'Activități aprobate',
  'activities.sum-for-month-title': 'Suma pentru {month}',
  'activities.sum-next-month':
    'Suma pentru săptămâna {week} este calculată luna viitoare',
  'activities.sum-overtime': 'Total ore suplimentare',
  'activities.updated-diet-successfully': 'Dieta actualizată cu succes',
  'activities.updated-salary-day-successfully': 'NEEDS TRANSLATION',
  'activities.work': 'Muncă',
  'activities.work-summary': 'Rezumat muncă',
  'activities.working-hours': 'Ore de lucru',
  'admin-required.message':
    'Trebuie să fii administrator pentru a vedea acest lucru.',
  'auth.admin-or-user-required':
    'Trebuie să fi {user} sau administrator pentru a vizualiza acest lucru.',
  'change-password.description':
    'Trebuie să-ți schimbi parola înainte de autentificare din motive de securitate.',
  'change-password.title': 'Schimbare parolă',
  'checklists.all-checklist-templates': 'Toate modelele de liste de verificare',
  'checklists.checklist-item': 'Element de listă de verificare',
  'checklists.checklist-template': 'Model listă de verificare',
  'checklists.generate-new': 'Generare nouă',
  'checklists.no-checklists': 'Nicio listă de verificare disponibilă.',
  'commmon.edit-name': 'Editează numele',
  'common.about': 'Despre',
  'common.absence': 'Absență',
  'common.absence-types': 'Tipuri de absență',
  'common.absences': 'Absență',
  'common.action': 'NEEDS TRANSLATION',
  'common.active': 'Activ',
  'common.activities': 'Date de conducere',
  'common.activity': 'Activitate',
  'common.add': 'Adaugă',
  'common.add-attachment': 'Adaugă atașament',
  'common.add-attachments': 'Adaugă atașamente',
  'common.add-column': 'Adaugă coloană',
  'common.add-file': 'Adaugă fișier',
  'common.add-file-alt': 'Adaugă fișier',
  'common.add-files': 'Adaugă fișiere',
  'common.additional-pay': 'NEEDS TRANSLATION',
  'common.address': 'Adresă',
  'common.all': 'Toate',
  'common.ambulance': 'Ambulanță',
  'common.app': 'Aplicație',
  'common.archive': 'Arhivă',
  'common.archived': 'Arhivat',
  'common.article': 'Articol',
  'common.attachments': 'Atașamente',
  'common.author': 'Autor',
  'common.availability': 'Disponibilitate',
  'common.basis': 'Bază',
  'common.both': 'Ambele',
  'common.business': 'Afaceri',
  'common.businessOverview': 'Prezentare generală a afacerii',
  'common.by-name': 'De {name}',
  'common.cancel': 'Anulează',
  'common.cancel-changes': 'Anulează modificările',
  'common.categories': 'Categorii',
  'common.category': 'Categorie',
  'common.category-for-type': 'Categorie pentru {type}',
  'common.change-company': 'Schimbă compania',
  'common.change-language': 'Schimbă limba',
  'common.change-password': 'Schimbă parola',
  'common.changes-saved': 'Modificările au fost salvate',
  'common.checklist-templates': 'Modele de liste de verificare',
  'common.checklists': 'Liste de verificare',
  'common.choose-ellipsis': 'Alege...',
  'common.city': 'Oraș',
  'common.close': 'Închide',
  'common.closed': 'Închis',
  'common.codes': 'Coduri',
  'common.color': 'Culoare',
  'common.comment': 'Comentariu',
  'common.comment-ellipsis': 'Comentariu...',
  'common.comments': 'Comentarii',
  'common.company': 'Companie',
  'common.company-name': 'Nume companie',
  'common.contact-info': 'Informații de contact',
  'common.contact-person': 'Persoană de contact',
  'common.content': 'Conținut',
  'common.count': 'Număr',
  'common.count-of-total': '{count} din {total}',
  'common.count-of-total-verbose': 'Văzut de {count}/{total} angajați',
  'common.countries': 'Țări',
  'common.country': 'Țară',
  'common.create': 'Creează',
  'common.create-alt': 'Creează',
  'common.create-entry': 'Creează o intrare nouă',
  'common.create-folder': 'Creează un folder nou',
  'common.create-form': 'Creează un formular nou',
  'common.create-form-entry': 'Creează o intrare nouă în formular',
  'common.create-type': 'Creează {type}',
  'common.creating': 'Creare',
  'common.csv': 'CSV',
  'common.current-password': 'Parola curentă',
  'common.daily': 'Zilnic',
  'common.dashboard': 'Tablou de bord',
  'common.data': 'Date',
  'common.data-distribution': 'NEEDS TRANSLATION',
  'common.date': 'Dată',
  'common.date-for-type': 'Dată pentru {type}',
  'common.date-validation-not-in-future':
    'Data trebuie să fie validă și nu trebuie să fie în viitor',
  'common.day-end': 'Sfârșitul zilei',
  'common.day-start': 'Începutul zilei',
  'common.days': '{days, plural, =0 {Fără zile} one {# zi} other {# zile}}',
  'common.deactivated': 'Dezactivat',
  'common.default-severity': 'NEEDS TRANSLATION',
  'common.delete': 'Șterge',
  'common.delete-alt': 'Șterge',
  'common.delete-type': 'Șterge {type}',
  'common.department': 'Departament',
  'common.department-expandable-text': 'NEEDS TRANSLATION',
  'common.departments': 'Departamente',
  'common.description': 'Descriere',
  'common.detail': 'Detaliu',
  'common.details': 'Detalii',
  'common.diet': 'Dietă',
  'common.disabled': 'Dezactivat',
  'common.distance': 'Distanță',
  'common.document': 'Document',
  'common.documentation': 'Documentație',
  'common.documentationIssues': 'Devierea documentației',
  'common.documents': 'Documente',
  'common.download': 'Descărcare',
  'common.driver': 'Șofer',
  'common.drivers': 'Șoferi',
  'common.drivers-handbook': 'Manual pentru șoferi',
  'common.driversHandbook': 'Manual pentru șoferi',
  'common.driversHandbookForm': 'Formulare pentru manualul pentru șoferi',
  'common.driving': 'Conducere',
  'common.driving-data': 'Date de conducere',
  'common.dropdown': 'Listă derulantă',
  'common.dropdown-on-pages': 'Listă derulantă a utilizatorului pe pagină',
  'common.dropdowns': 'Liste derulante',
  'common.edit': 'Editează',
  'common.edit-alt': 'Editează',
  'common.edit-entry': 'Editează intrarea',
  'common.edit-name': 'Editează numele',
  'common.edit-type': 'Editează {type}',
  'common.email': 'Email',
  'common.email-already-selected': 'Emailul este deja selectat',
  'common.employee': 'Angajat',
  'common.enabled': 'Activat',
  'common.end': 'Sfârșit',
  'common.end-date': 'Data finală',
  'common.end-time': 'Timpul de sfârșit',
  'common.enter-url': 'Introdu URL-ul',
  'common.entry': 'Intrare',
  'common.eurovignette': 'Eurovignette',
  'common.eurovignettes': 'Eurovignettes',
  'common.excel': 'Excel',
  'common.excel-flat': 'Excel (o foaie)',
  'common.expired': 'Expirat',
  'common.expires-at': 'Expiră la',
  'common.export': 'Export',
  'common.export-format': 'Format',
  'common.export-type': 'Tip de export',
  'common.exports-settings': 'Exporturi',
  'common.external-id': 'NEEDS TRANSLATION',
  'common.fats': 'NEEDS TRANSLATION',
  'common.fats-beta': 'NEEDS TRANSLATION',
  'common.field': 'NEEDS TRANSLATION',
  'common.file': 'Fișier',
  'common.files': 'Fișiere',
  'common.filter': 'Filtru',
  'common.finished': 'Terminat',
  'common.fire': 'Foc',
  'common.firstName': 'Prenume',
  'common.folder': 'Folder',
  'common.form-error': 'Unul sau mai multe câmpuri sunt completate incorect.',
  'common.form-responses': 'Formulare completate',
  'common.formcreator': 'Creator de formulare',
  'common.forms': 'Formulare',
  'common.forum': 'Forum',
  'common.forum-posts': 'NEEDS TRANSLATION',
  'common.forum-threads': 'NEEDS TRANSLATION',
  'common.freight-carrier': 'Transportator de mărfuri',
  'common.from': 'De la',
  'common.general': 'General',
  'common.generate': 'NEEDS TRANSLATION',
  'common.generate-report': 'NEEDS TRANSLATION',
  'common.go-back': 'Înapoi',
  'common.handbook': 'Manual',
  'common.height': 'Înălțime',
  'common.height-in-cm': 'Înălțime în cm',
  'common.high': 'NEEDS TRANSLATION',
  'common.holiday-allowances': 'NEEDS TRANSLATION',
  'common.home': 'Acasă',
  'common.hourlist': 'Foi de pontaj',
  'common.hours': 'Ore',
  'common.hours-abbrev': 'o',
  'common.hours-lowercase': 'ore',
  'common.id': 'ID',
  'common.in-app': 'În aplicație',
  'common.include-archived': 'NEEDS TRANSLATION',
  'common.information': 'Informație',
  'common.infringement': 'Infracțiune',
  'common.infringements': 'Infracțiuni',
  'common.integrations': 'Integrări',
  'common.invalid': 'Invalid',
  'common.invalid-date': 'Data este invalidă',
  'common.invalid-url': 'URL invalid',
  'common.issue': 'Deviație',
  'common.issue-categories': 'Categorii de deviație',
  'common.issued-at': 'Deviat la',
  'common.issues': 'Deviații',
  'common.items': 'Elemente',
  'common.kilometers': '{abbreviate, select, true {km} other {kilometri}}',
  'common.km': 'km',
  'common.km-per-hour-short': 'km/h',
  'common.language': 'Limbă',
  'common.last-revision': 'NEEDS TRANSLATION',
  'common.lastName': 'Nume de familie',
  'common.length': 'Lungime',
  'common.length-in-cm': 'Lungime în cm',
  'common.liters': 'Litri',
  'common.liters-short': 'l',
  'common.load-more': 'Încarcă mai mult',
  'common.load-previous': 'Încarcă anterior',
  'common.loading': 'Încărcare',
  'common.loading...': 'Se încarcă...',
  'common.location': 'Locație',
  'common.log-in': 'Autentificare',
  'common.log-out': 'Deconectare',
  'common.login': 'Conectare',
  'common.low': 'NEEDS TRANSLATION',
  'common.make-a-choice': 'Fă o alegere',
  'common.make-a-selection': 'Vă rugăm să faceți o selecție',
  'common.manual': 'NEEDS TRANSLATION',
  'common.map': 'Hartă',
  'common.mass-print': 'Imprimare în masă',
  'common.maximum': 'Maxim',
  'common.medium': 'NEEDS TRANSLATION',
  'common.menu': 'Meniu',
  'common.minimum': 'Minim',
  'common.minutes': 'Minute',
  'common.minutes-abbrev': 'm',
  'common.module': 'Modul',
  'common.month': 'Lună',
  'common.monthly': 'Lunar',
  'common.move-down': 'Mută în jos',
  'common.move-up': 'Mută în sus',
  'common.n-categories': 'NEEDS TRANSLATION',
  'common.n-hours': '{n} ore',
  'common.n-issues': '{n} deviații',
  'common.n-minutes': '{n} minute',
  'common.n-salary-code': '{n} coduri salariale',
  'common.name': 'Nume',
  'common.needs-comment': 'Necesită comentariu',
  'common.new-password': 'Parolă nouă',
  'common.new-password-again': 'Parolă nouă din nou',
  'common.no': 'Nu',
  'common.no-active-modules': 'Fără module active',
  'common.no-attachments': 'Fără atașamente',
  'common.no-changes': 'Fără modificări',
  'common.no-comment': 'Fără comentariu',
  'common.no-more-options': 'Nu mai există opțiuni',
  'common.no-options-to-choose-from': 'Nu există opțiuni de selectat',
  'common.no-results': 'Fără rezultate',
  'common.none': 'Niciunul',
  'common.not-archived': 'Nearchivat',
  'common.not-verified-yet': 'Încă neverificat',
  'common.notification': 'Notificare',
  'common.notifications': 'Notificări',
  'common.notifications-alt': 'NEEDS TRANSLATION',
  'common.office': 'Oficiu',
  'common.ok': 'OK',
  'common.open': 'Deschide',
  'common.open-alt': 'Deschide',
  'common.opened': 'Deschis',
  'common.order': 'Comandă',
  'common.order-by': 'NEEDS TRANSLATION',
  'common.ordinary': 'NEEDS TRANSLATION',
  'common.other': 'Altul',
  'common.other-work': 'Muncă suplimentară',
  'common.overtime': 'Timp suplimentar',
  'common.overtime-mode': 'Mod timp suplimentar',
  'common.overtime-rate': 'Rată timp suplimentar',
  'common.password': 'Parolă',
  'common.pause': 'Pauză',
  'common.pdf': 'PDF',
  'common.pdf-salary': 'NEEDS TRANSLATION',
  'common.period': 'Perioadă',
  'common.perks': 'Beneficii',
  'common.permissions': 'Permisiuni',
  'common.phone': 'Telefon',
  'common.phone-number': 'Număr de telefon',
  'common.phone-prefix': 'Prefix de țară',
  'common.police': 'Poliție',
  'common.postal-code': 'Cod poștal',
  'common.poweroffice': 'NEEDS TRANSLATION',
  'common.previous-responses': 'Răspunsuri anterioare',
  'common.print': 'Imprimare',
  'common.print-type': 'Imprimare {type}',
  'common.priority': 'Prioritate',
  'common.privacy': 'Confidențialitate',
  'common.project': 'Proiect',
  'common.publish': 'Publică',
  'common.published': 'NEEDS TRANSLATION',
  'common.range': 'Interval',
  'common.reason': 'Motiv',
  'common.reg-nr': 'Reg.nr.',
  'common.register': 'Înregistrare',
  'common.remove': 'Eliminare',
  'common.report': 'Raport',
  'common.required': 'Necesar',
  'common.reset': 'Resetare',
  'common.responses': 'Răspunsuri',
  'common.responsible': 'Responsabil',
  'common.responsible-user': 'Utilizator responsabil',
  'common.responsible-users': 'NEEDS TRANSLATION',
  'common.rest': 'Odihnă',
  'common.restore': 'Restaurare',
  'common.risk-aspect': 'NEEDS TRANSLATION',
  'common.risk-aspects': 'NEEDS TRANSLATION',
  'common.role': 'Rol',
  'common.route-planner': 'Planificator de rută',
  'common.salary': 'Salariu',
  'common.salary-code': 'NEEDS TRANSLATION',
  'common.salary-codes': 'Coduri salariale',
  'common.salary-day': 'NEEDS TRANSLATION',
  'common.salary-mode': 'NEEDS TRANSLATION',
  'common.save': 'Salvare',
  'common.save-and-close': 'Salvare și închidere',
  'common.save-changes': 'Salvare modificări',
  'common.save-changes-to-type': 'Salvează modificările pentru {type}',
  'common.save-in-user-exports': 'Salvează în exporturile mele',
  'common.save-languages': 'Salvează limba',
  'common.saving': 'Salvare',
  'common.search': 'Căutare...',
  'common.seconds-abbrev': 's',
  'common.see-all-responses': 'Vezi toate răspunsurile',
  'common.seen-by': 'Văzut de',
  'common.select': 'Selectează',
  'common.select-all': 'Selectează tot',
  'common.select-at-least-one-recipient': 'Selectează cel puțin un destinatar',
  'common.select-manual-entry': 'Alege introducere manuală',
  'common.select-project': 'Selectează proiectul',
  'common.select-salary-code': 'Selectează codul salarial',
  'common.select-user': 'Selectează utilizatorul',
  'common.select-users': 'Selectează utilizatorii',
  'common.select-week-days': 'Selectează zilele săptămânii',
  'common.send-print-to-mail': 'Trimiteți printarea prin e-mail?',
  'common.settings': 'Setări',
  'common.severity': 'Severitate',
  'common.shortcuts': 'Scurtături',
  'common.show-all': 'Arată tot',
  'common.show-sums': 'NEEDS TRANSLATION',
  'common.singular': 'Singular',
  'common.sms': 'SMS',
  'common.sort-by-user-type': 'Sortează după tipul utilizatorului',
  'common.start': 'Start',
  'common.start-date': 'Data de început',
  'common.start-time': 'Ora de început',
  'common.statistics': 'Statistici',
  'common.statistics-this-week': 'NEEDS TRANSLATION',
  'common.status': 'Stare',
  'common.submit': 'Trimite',
  'common.sum': 'Sumă',
  'common.sums': 'NEEDS TRANSLATION',
  'common.terminal': 'Terminal',
  'common.terminal-network': 'Rețea terminal',
  'common.the-email': 'E-mail-ul',
  'common.the-field': 'Câmpul',
  'common.thread': 'Subiect',
  'common.time': 'Timp',
  'common.time-entries': 'Înregistrări de timp',
  'common.time-entry': 'Înregistrare de timp',
  'common.time-interval': 'Interval de timp',
  'common.timesheets': 'Înregistrările de timp',
  'common.timesinceposted': 'Postat ',
  'common.timestamp': 'Marcaj de timp',
  'common.title': 'Titlu',
  'common.total-work': 'NEEDS TRANSLATION',
  'common.tours': 'Tururi',
  'common.tripletex': 'NEEDS TRANSLATION',
  'common.tv-display': 'Afișaj TV',
  'common.twentyfour-seven-office': 'NEEDS TRANSLATION',
  'common.two-weekly': 'La două săptămâni',
  'common.type': 'Tip',
  'common.unavailable': 'Indisponibil',
  'common.unknown': 'Necunoscut',
  'common.until': 'Până la',
  'common.untitled': 'Fără titlu',
  'common.update': 'Actualizare',
  'common.update-entry': 'Actualizare înregistrare',
  'common.update-folder': 'Actualizare dosar',
  'common.update-form': 'Actualizare formular',
  'common.update-form-entry': 'Actualizare intrare formular',
  'common.update-password': 'Actualizare parolă',
  'common.update-type': 'Actualizare {type}',
  'common.updated-at': 'Actualizat la',
  'common.updating': 'Se actualizează',
  'common.uploaded': 'Încărcat',
  'common.uploaded-attachments': 'Atașamente încărcate',
  'common.user': 'Utilizator',
  'common.user-document-categories': 'NEEDS TRANSLATION',
  'common.user-documents': 'Documente utilizator',
  'common.user-type': 'Tip de utilizator',
  'common.user-types': 'Tipuri de utilizatori',
  'common.username': 'Nume de utilizator',
  'common.users': 'Utilizatori',
  'common.usersettings': 'Setări utilizator',
  'common.value': 'Valoare',
  'common.vehicle': 'Vehicul',
  'common.vehicle-expandable-text': '{amount} vehicule',
  'common.vehicle-fleet': 'Flotă auto',
  'common.verified': 'Verificat',
  'common.view-comment': 'Vizualizare comentariu',
  'common.visma-payroll': 'NEEDS TRANSLATION',
  'common.week': 'Săptămână',
  'common.week-n': 'Săptămâna {n}',
  'common.weekly': 'Săptămânal',
  'common.weeks':
    '{weeks, plural, =0 {Fără săptămâni} one {# săptămână} other {# săptămâni}}',
  'common.weight': 'Greutate',
  'common.when-by-whom': '{when} de {whom}',
  'common.whole-day': 'Toată ziua',
  'common.width': 'Lățime',
  'common.width-in-cm': 'Lățime în cm',
  'common.work': 'Muncă',
  'common.work-hours': 'Ore de lucru',
  'common.year': 'An',
  'common.yes': 'Da',
  'common.you-have-unsaved-changes': 'Aveți modificări nesalvate',
  'common.you-have-unsaved-changes-navigate':
    'Aveți modificări nesalvate. Sigur doriți să părăsiți această pagină?',
  'companies.create-overtime-period': 'Creează perioadă de timp suplimentar',
  'companies.create-period-addon': 'NEEDS TRANSLATION',
  'companies.edit-overtime-period': 'Editează perioada de timp suplimentar',
  'companies.edit-period-addon': 'NEEDS TRANSLATION',
  'companies.existing-overtime-period':
    'Perioadă de timp suplimentar existentă',
  'companies.overlapping-periods-are-not-allowed':
    'Perioadele suprapuse nu sunt permise',
  'companies.toasts.department-created': 'Departament creat',
  'companies.toasts.department-deleted': 'Departament șters',
  'companies.toasts.department-updated': 'Departament actualizat',
  'companies.toasts.holiday-allowance-created': 'NEEDS TRANSLATION',
  'companies.toasts.holiday-allowance-deleted': 'NEEDS TRANSLATION',
  'companies.toasts.holiday-allowance-updated': 'NEEDS TRANSLATION',
  'companies.toasts.salary-code-created': 'Cod salarial creat',
  'companies.toasts.salary-code-deleted': 'Cod salarial șters cu succes',
  'companies.toasts.salary-code-updated': 'Cod salarial actualizat',
  'countries.codes.AC': 'Insula Ascension',
  'countries.codes.AD': 'Andorra',
  'countries.codes.AE': 'Emiratele Arabe Unite',
  'countries.codes.AF': 'Afganistan',
  'countries.codes.AG': 'Antigua și Barbuda',
  'countries.codes.AI': 'Anguilla',
  'countries.codes.AL': 'Albania',
  'countries.codes.AM': 'Armenia',
  'countries.codes.AO': 'Angola',
  'countries.codes.AQ': 'Antarctica',
  'countries.codes.AR': 'Argentina',
  'countries.codes.AS': 'Samoa Americană',
  'countries.codes.AT': 'Austria',
  'countries.codes.AU': 'Australia',
  'countries.codes.AW': 'Aruba',
  'countries.codes.AX': 'Insulele Åland',
  'countries.codes.AZ': 'Azerbaidjan',
  'countries.codes.BA': 'Bosnia și Herțegovina',
  'countries.codes.BB': 'Barbados',
  'countries.codes.BD': 'Bangladesh',
  'countries.codes.BE': 'Belgia',
  'countries.codes.BF': 'Burkina Faso',
  'countries.codes.BG': 'Bulgaria',
  'countries.codes.BH': 'Bahrain',
  'countries.codes.BI': 'Burundi',
  'countries.codes.BJ': 'Benin',
  'countries.codes.BL': 'St. Barthélemy',
  'countries.codes.BM': 'Bermuda',
  'countries.codes.BN': 'Brunei',
  'countries.codes.BO': 'Bolivia',
  'countries.codes.BQ': 'Țările de Jos Caraibe',
  'countries.codes.BR': 'Brazilia',
  'countries.codes.BS': 'Bahamas',
  'countries.codes.BT': 'Bhutan',
  'countries.codes.BV': 'Insula Bouvet',
  'countries.codes.BW': 'Botswana',
  'countries.codes.BY': 'Belarus',
  'countries.codes.BZ': 'Belize',
  'countries.codes.CA': 'Canada',
  'countries.codes.CC': 'Insulele Cocos (Keeling)',
  'countries.codes.CD': 'Congo - Kinshasa',
  'countries.codes.CF': 'Republica Centrafricană',
  'countries.codes.CG': 'Congo - Brazzaville',
  'countries.codes.CH': 'Elveția',
  'countries.codes.CI': 'Coasta de Fildeș',
  'countries.codes.CK': 'Insulele Cook',
  'countries.codes.CL': 'Chile',
  'countries.codes.CM': 'Camerun',
  'countries.codes.CN': 'China',
  'countries.codes.CO': 'Columbia',
  'countries.codes.CP': 'Insula Clipperton',
  'countries.codes.CR': 'Costa Rica',
  'countries.codes.CU': 'Cuba',
  'countries.codes.CV': 'Capul Verde',
  'countries.codes.CW': 'Curaçao',
  'countries.codes.CX': 'Insula Christmas',
  'countries.codes.CY': 'Cipru',
  'countries.codes.CZ': 'Cehia',
  'countries.codes.DE': 'Germania',
  'countries.codes.DG': 'Diego Garcia',
  'countries.codes.DJ': 'Djibouti',
  'countries.codes.DK': 'Danemarca',
  'countries.codes.DM': 'Dominica',
  'countries.codes.DO': 'Republica Dominicană',
  'countries.codes.DZ': 'Algeria',
  'countries.codes.EA': 'Ceuta și Melilla',
  'countries.codes.EC': 'Ecuador',
  'countries.codes.EE': 'Estonia',
  'countries.codes.EG': 'Egipt',
  'countries.codes.EH': 'Sahara Occidentală',
  'countries.codes.ER': 'Eritreea',
  'countries.codes.ES': 'Spania',
  'countries.codes.ET': 'Etiopia',
  'countries.codes.EU': 'Uniunea Europeană',
  'countries.codes.FI': 'Finlanda',
  'countries.codes.FJ': 'Fiji',
  'countries.codes.FK': 'Insulele Falkland',
  'countries.codes.FM': 'Micronezia',
  'countries.codes.FO': 'Insulele Feroe',
  'countries.codes.FR': 'Franța',
  'countries.codes.GA': 'Gabon',
  'countries.codes.GB': 'Regatul Unit',
  'countries.codes.GD': 'Grenada',
  'countries.codes.GE': 'Georgia',
  'countries.codes.GF': 'Guyana Franceză',
  'countries.codes.GG': 'Guernsey',
  'countries.codes.GH': 'Ghana',
  'countries.codes.GI': 'Gibraltar',
  'countries.codes.GL': 'Groenlanda',
  'countries.codes.GM': 'Gambia',
  'countries.codes.GN': 'Guineea',
  'countries.codes.GP': 'Guadeloupe',
  'countries.codes.GQ': 'Guineea Ecuatorială',
  'countries.codes.GR': 'Grecia',
  'countries.codes.GS': 'Georgia de Sud și Insulele Sandwich de Sud',
  'countries.codes.GT': 'Guatemala',
  'countries.codes.GU': 'Guam',
  'countries.codes.GW': 'Guineea-Bissau',
  'countries.codes.GY': 'Guyana',
  'countries.codes.HK': 'Regiunea Administrativă Specială Hong Kong a Chinei',
  'countries.codes.HM': 'Insulele Heard și McDonald',
  'countries.codes.HN': 'Honduras',
  'countries.codes.HR': 'Croația',
  'countries.codes.HT': 'Haiti',
  'countries.codes.HU': 'Ungaria',
  'countries.codes.IC': 'Insulele Canare',
  'countries.codes.ID': 'Indonezia',
  'countries.codes.IE': 'Irlanda',
  'countries.codes.IL': 'Israel',
  'countries.codes.IM': 'Insula Man',
  'countries.codes.IN': 'India',
  'countries.codes.IO': 'Teritoriul Britanic din Oceanul Indian',
  'countries.codes.IQ': 'Irak',
  'countries.codes.IR': 'Iran',
  'countries.codes.IS': 'Islanda',
  'countries.codes.IT': 'Italia',
  'countries.codes.JE': 'Jersey',
  'countries.codes.JM': 'Jamaica',
  'countries.codes.JO': 'Iordania',
  'countries.codes.JP': 'Japonia',
  'countries.codes.KE': 'Kenya',
  'countries.codes.KG': 'Kîrgîzstan',
  'countries.codes.KH': 'Cambodgia',
  'countries.codes.KI': 'Kiribati',
  'countries.codes.KM': 'Comore',
  'countries.codes.KN': 'Sfântul Kitts și Nevis',
  'countries.codes.KP': 'Coreea de Nord',
  'countries.codes.KR': 'Coreea de Sud',
  'countries.codes.KW': 'Kuweit',
  'countries.codes.KY': 'Insulele Cayman',
  'countries.codes.KZ': 'Kazahstan',
  'countries.codes.LA': 'Laos',
  'countries.codes.LB': 'Liban',
  'countries.codes.LC': 'Sfânta Lucia',
  'countries.codes.LI': 'Liechtenstein',
  'countries.codes.LK': 'Sri Lanka',
  'countries.codes.LR': 'Liberia',
  'countries.codes.LS': 'Lesotho',
  'countries.codes.LT': 'Lituania',
  'countries.codes.LU': 'Luxemburg',
  'countries.codes.LV': 'Letonia',
  'countries.codes.LY': 'Libia',
  'countries.codes.MA': 'Maroc',
  'countries.codes.MC': 'Monaco',
  'countries.codes.MD': 'Republica Moldova',
  'countries.codes.ME': 'Muntenegru',
  'countries.codes.MF': 'Sfântul Martin',
  'countries.codes.MG': 'Madagascar',
  'countries.codes.MH': 'Insulele Marshall',
  'countries.codes.MK': 'Macedonia de Nord',
  'countries.codes.ML': 'Mali',
  'countries.codes.MM': 'Myanmar (Birmania)',
  'countries.codes.MN': 'Mongolia',
  'countries.codes.MO': 'Regiunea Administrativă Specială Macao',
  'countries.codes.MP': 'NEEDS TRANSLATION',
  'countries.codes.MQ': 'NEEDS TRANSLATION',
  'countries.codes.MR': 'NEEDS TRANSLATION',
  'countries.codes.MS': 'NEEDS TRANSLATION',
  'countries.codes.MT': 'NEEDS TRANSLATION',
  'countries.codes.MU': 'NEEDS TRANSLATION',
  'countries.codes.MV': 'NEEDS TRANSLATION',
  'countries.codes.MW': 'NEEDS TRANSLATION',
  'countries.codes.MX': 'NEEDS TRANSLATION',
  'countries.codes.MY': 'NEEDS TRANSLATION',
  'countries.codes.MZ': 'NEEDS TRANSLATION',
  'countries.codes.NA': 'NEEDS TRANSLATION',
  'countries.codes.NC': 'NEEDS TRANSLATION',
  'countries.codes.NE': 'NEEDS TRANSLATION',
  'countries.codes.NF': 'NEEDS TRANSLATION',
  'countries.codes.NG': 'NEEDS TRANSLATION',
  'countries.codes.NI': 'NEEDS TRANSLATION',
  'countries.codes.NL': 'NEEDS TRANSLATION',
  'countries.codes.NO': 'NEEDS TRANSLATION',
  'countries.codes.NP': 'NEEDS TRANSLATION',
  'countries.codes.NR': 'NEEDS TRANSLATION',
  'countries.codes.NU': 'NEEDS TRANSLATION',
  'countries.codes.NZ': 'NEEDS TRANSLATION',
  'countries.codes.OM': 'NEEDS TRANSLATION',
  'countries.codes.PA': 'NEEDS TRANSLATION',
  'countries.codes.PE': 'NEEDS TRANSLATION',
  'countries.codes.PF': 'NEEDS TRANSLATION',
  'countries.codes.PG': 'NEEDS TRANSLATION',
  'countries.codes.PH': 'NEEDS TRANSLATION',
  'countries.codes.PK': 'NEEDS TRANSLATION',
  'countries.codes.PL': 'NEEDS TRANSLATION',
  'countries.codes.PM': 'NEEDS TRANSLATION',
  'countries.codes.PN': 'NEEDS TRANSLATION',
  'countries.codes.PR': 'NEEDS TRANSLATION',
  'countries.codes.PS': 'NEEDS TRANSLATION',
  'countries.codes.PT': 'NEEDS TRANSLATION',
  'countries.codes.PW': 'NEEDS TRANSLATION',
  'countries.codes.PY': 'NEEDS TRANSLATION',
  'countries.codes.QA': 'NEEDS TRANSLATION',
  'countries.codes.RE': 'NEEDS TRANSLATION',
  'countries.codes.RO': 'NEEDS TRANSLATION',
  'countries.codes.RS': 'NEEDS TRANSLATION',
  'countries.codes.RU': 'NEEDS TRANSLATION',
  'countries.codes.RW': 'NEEDS TRANSLATION',
  'countries.codes.SA': 'NEEDS TRANSLATION',
  'countries.codes.SB': 'NEEDS TRANSLATION',
  'countries.codes.SC': 'NEEDS TRANSLATION',
  'countries.codes.SD': 'NEEDS TRANSLATION',
  'countries.codes.SE': 'NEEDS TRANSLATION',
  'countries.codes.SG': 'NEEDS TRANSLATION',
  'countries.codes.SH': 'NEEDS TRANSLATION',
  'countries.codes.SI': 'NEEDS TRANSLATION',
  'countries.codes.SJ': 'NEEDS TRANSLATION',
  'countries.codes.SK': 'NEEDS TRANSLATION',
  'countries.codes.SL': 'NEEDS TRANSLATION',
  'countries.codes.SM': 'NEEDS TRANSLATION',
  'countries.codes.SN': 'NEEDS TRANSLATION',
  'countries.codes.SO': 'NEEDS TRANSLATION',
  'countries.codes.SR': 'NEEDS TRANSLATION',
  'countries.codes.SS': 'NEEDS TRANSLATION',
  'countries.codes.ST': 'NEEDS TRANSLATION',
  'countries.codes.SV': 'NEEDS TRANSLATION',
  'countries.codes.SX': 'NEEDS TRANSLATION',
  'countries.codes.SY': 'NEEDS TRANSLATION',
  'countries.codes.SZ': 'Swaziland',
  'countries.codes.TA': 'Tristan da Cunha',
  'countries.codes.TC': 'Insulele Turks și Caicos',
  'countries.codes.TD': 'Ciad',
  'countries.codes.TF': 'Teritoriile Australe și Antarctice Franceze',
  'countries.codes.TG': 'Togo',
  'countries.codes.TH': 'Thailanda',
  'countries.codes.TJ': 'Tadjikistan',
  'countries.codes.TK': 'Tokelau',
  'countries.codes.TL': 'Timorul de Est',
  'countries.codes.TM': 'Turkmenistan',
  'countries.codes.TN': 'Tunisia',
  'countries.codes.TO': 'Tonga',
  'countries.codes.TR': 'Turcia',
  'countries.codes.TT': 'Trinidad și Tobago',
  'countries.codes.TV': 'Tuvalu',
  'countries.codes.TW': 'Taiwan',
  'countries.codes.TZ': 'Tanzania',
  'countries.codes.UA': 'Ucraina',
  'countries.codes.UG': 'Uganda',
  'countries.codes.UM': 'Insulele Periferice Ale Statelor Unite Ale Americii',
  'countries.codes.UN': 'Organizația Națiunilor Unite',
  'countries.codes.US': 'Statele Unite ale Americii',
  'countries.codes.UY': 'Uruguay',
  'countries.codes.UZ': 'Uzbekistan',
  'countries.codes.VA': 'Statul Vatican',
  'countries.codes.VC': 'St. Vincent și Grenadine',
  'countries.codes.VE': 'Venezuela',
  'countries.codes.VG': 'Insulele Virgine Britanice',
  'countries.codes.VI': 'Insulele Virgine Americane',
  'countries.codes.VN': 'Vietnam',
  'countries.codes.VU': 'Vanuatu',
  'countries.codes.WF': 'Wallis și Futuna',
  'countries.codes.WS': 'Samoa',
  'countries.codes.XK': 'Kosovo',
  'countries.codes.YE': 'Yemen',
  'countries.codes.YT': 'Mayotte',
  'countries.codes.ZA': 'Africa de Sud',
  'countries.codes.ZM': 'Zambia',
  'countries.codes.ZW': 'Zimbabwe',
  'countries.european-community': 'NEEDS TRANSLATION',
  'countries.rest-of-europe': 'NEEDS TRANSLATION',
  'countries.rest-of-world': 'NEEDS TRANSLATION',
  'createUser.title': 'NEEDS TRANSLATION',
  'dashboard.enter-driver-card-number.errors.invalid-number':
    'NEEDS TRANSLATION',
  'dashboard.lastForumPosts': 'NEEDS TRANSLATION',
  'dashboard.register-hours': 'NEEDS TRANSLATION',
  'dashboard.registerHours': 'NEEDS TRANSLATION',
  'document.files-for-document': 'NEEDS TRANSLATION',
  'documentation.documentation-for-control': 'NEEDS TRANSLATION',
  'documentation.drive-and-rest-time': 'NEEDS TRANSLATION',
  'documents.add-document': 'NEEDS TRANSLATION',
  'documents.continue-to-files': 'NEEDS TRANSLATION',
  'documents.documents-of-user': 'NEEDS TRANSLATION',
  'documents.edit-file': 'NEEDS TRANSLATION',
  'documents.expiration-date': 'NEEDS TRANSLATION',
  'documents.files-for-document': 'NEEDS TRANSLATION',
  'documents.name-on-document': 'NEEDS TRANSLATION',
  'documents.no-documents': 'NEEDS TRANSLATION',
  'documents.no-files': 'NEEDS TRANSLATION',
  'documents.notification-time': 'NEEDS TRANSLATION',
  'documents.notification-time-alt': 'NEEDS TRANSLATION',
  'documents.number-id': 'NEEDS TRANSLATION',
  'documents.open-file': 'NEEDS TRANSLATION',
  'documents.prompts.delete-document': 'NEEDS TRANSLATION',
  'documents.prompts.delete-document-confirmation': 'NEEDS TRANSLATION',
  'documents.prompts.delete-file': 'NEEDS TRANSLATION',
  'documents.prompts.delete-file-title': 'NEEDS TRANSLATION',
  'documents.responsible-user': 'NEEDS TRANSLATION',
  'documents.show-files': 'NEEDS TRANSLATION',
  'documents.upload-document': 'NEEDS TRANSLATION',
  'documents.upload-file': 'NEEDS TRANSLATION',
  'documents.view-file': 'NEEDS TRANSLATION',
  'error.activities-pause-duration': 'NEEDS TRANSLATION',
  'error.address-required': 'NEEDS TRANSLATION',
  'error.archive-type': 'NEEDS TRANSLATION',
  'error.categories': 'NEEDS TRANSLATION',
  'error.city-required': 'NEEDS TRANSLATION',
  'error.company-missing-tachograph-key': 'NEEDS TRANSLATION',
  'error.contact-person-phone-required':
    'Numărul de telefon al persoanei de contact este obligatoriu',
  'error.contact-person-required':
    'Numele persoanei de contact este obligatoriu',
  'error.could-not-change-profile-information':
    'Nu s-a putut modifica informațiile de profil',
  'error.could-not-check-in': 'Nu s-a putut efectua check-in-ul',
  'error.could-not-check-out': 'Nu s-a putut efectua check-out-ul',
  'error.could-not-create-type': 'Nu s-a putut crea {type}',
  'error.could-not-delete-type': 'Nu s-a putut șterge {type}',
  'error.could-not-edit-type': 'Nu s-a putut edita {type}',
  'error.could-not-find-data': 'Nu s-au găsit datele în tabel',
  'error.could-not-find-location': 'Nu s-a putut găsi locația',
  'error.could-not-get-absence-types':
    'Nu s-au putut obține tipurile de absență',
  'error.could-not-read-file': 'Nu s-a putut citi fișierul',
  'error.create-issue': 'Nu s-a putut crea deviația',
  'error.driver-card-expiration-date-not-found':
    'Nu s-a putut găsi data de expirare pentru cardul șoferului.',
  'error.driver-card-is-expired': 'Cardul șoferului este expirat',
  'error.drivercard-between-14-16':
    'Câmpul trebuie să aibă între 14 și 16 caractere',
  'error.end-time-too-early':
    'Ora de încheiere trebuie să fie ulterioară orei de începere',
  'error.expiration-date-not-found-add-expiration-date-manually':
    'Nu s-a putut găsi data de expirare. Adăugați manual data.',
  'error.field-can-not-be-empty': 'Câmpul nu poate fi gol',
  'error.field-is-required': '{field} este obligatoriu',
  'error.files.could-not-read': 'Nu s-a putut citi fișierul',
  'error.filled-out-form-entry-does-not-exist':
    'Înregistrarea completată a formularului nu există.',
  'error.first-name-required': 'Prenumele este obligatoriu',
  'error.generic': 'A apărut o eroare',
  'error.generic-server-error':
    'Ceva nu a mers bine pe server. Vă rugăm să încercați din nou.',
  'error.generic-title': 'Ceva nu a mers bine',
  'error.internal-number-required': 'Numărul intern este obligatoriu',
  'error.invalid-birth-number': 'Numărul de naștere este invalid',
  'error.invalid-file-type': 'Tip de fișier invalid',
  'error.invalid-phone-number': 'Număr de telefon invalid',
  'error.invalid-value': 'Valoare invalidă',
  'error.last-name-required': 'Numele de familie este obligatoriu',
  'error.missing-permission':
    'Lipsiți de permisiunea necesară pentru accesarea acestui element',
  'error.name-exists': 'Există deja o intrare cu acest nume',
  'error.need-at-least-one-user-type':
    'Este nevoie de cel puțin un tip de utilizator',
  'error.need-company-name': 'Numele companiei trebuie să fie prezent',
  'error.no-first-name': 'Lipsește prenumele',
  'error.not-permitted': 'Nu este permis',
  'error.not-permitted-to-view':
    'Nu aveți permisiunea să vizualizați acest lucru',
  'error.number-must-be-positive': 'Numărul trebuie să fie pozitiv',
  'error.phone-number-required': 'Numărul de telefon este obligatoriu',
  'error.postal-code-required': 'Codul poștal este obligatoriu',
  'error.try-again': 'Încercați din nou',
  'error.type-already-exist': '{type} există deja',
  'error.type-does-not-exist': '{type} nu există',
  'error.un-archive-type': 'Nu s-a putut redeschide {type}',
  'error.update-issue': 'Nu s-a putut actualiza deviația',
  'error.user-has-no-infringements-for-the-selected-period':
    'Utilizatorul nu are încălcări pentru perioada selectată',
  'error.valid-email-required': 'Este necesar un email valid',
  'error.wrong-password': 'Parolă greșită',
  'error.wrong-username-or-password': 'Nume de utilizator sau parolă greșite',
  'errors.common.name-already-exists': 'Numele există deja',
  'errors.could-not-save-changes': 'Nu s-au putut salva modificările',
  'errors.defaults.does-not-exist': 'Obiectul nu există',
  'errors.defaults.foreign-key-constraint':
    'Obiectul face referire la ceva ce nu există',
  'errors.defaults.invalid-value': 'A fost furnizată o valoare invalidă',
  'errors.defaults.not-permitted':
    'Utilizatorul dvs. nu are permisiunea de a face asta',
  'errors.defaults.unique-constraint': 'Obiectul trebuie să fie unic',
  'errors.driver-card-already-taken': 'Cardul șoferului este deja folosit.',
  'errors.driver-card-not-found-in-external-integration': 'NEEDS TRANSLATION',
  'errors.driver-card-not-found-similar-driver-card-already-taken':
    'Cardul șoferului nu a fost găsit. Un card similar este deja folosit.',
  'errors.generic-error': 'A apărut o eroare',
  'errors.must-be-whole-number': 'Trebuie să fie un număr întreg',
  'errors.passwords-not-equal': 'Parolele nu sunt identice',
  'errors.users.could-not-change-password': 'Nu s-a putut schimba parola',
  'exports.error-email-process': 'Nu s-a putut exporta prin email',
  'exports.error-user-salary-settings':
    'Utilizatorul nu are tipuri de utilizatori conectate la setările salariale',
  'exports.export-data': 'Exportă datele',
  'exports.month-export': 'Export lunar',
  'exports.salary-export': 'Export bază salarială',
  'exports.success-email-process':
    'Datele sunt în curs de procesare. Veți primi un e-mail după finalizarea procesului.',
  'exports.success-user-exports':
    'Datele sunt în curs de procesare. Veți primi o notificare după finalizarea procesului.',
  'exports.user-exports': 'Exporturile mele',
  'files.drop-to-upload': 'Trageți fișierele aici pentru a le încărca',
  'files.file-too-large':
    'Fișierul {name} este mai mare decât dimensiunea maximă de {max}MB.',
  'files.upload-files-here': 'Încărcați fișierele aici',
  'forgotten-password.forgotten': 'Ați uitat parola?',
  'forgotten-password.go-back': 'Înapoi la autentificare',
  'forgotten-password.message':
    'Dacă ați uitat parola, vă rugăm să introduceți numele de utilizator sau adresa de email. Dacă putem găsi contul dvs., vă vom trimite un email cu o nouă parolă.',
  'forgotten-password.success':
    'Dacă contul dvs. există, un e-mail este pe drum cu instrucțiuni de resetare a parolei.',
  'forgotten-password.title': 'Ați uitat parola',
  'form.add-field': 'Adăugați câmp',
  'form.all-responses-loaded': 'Toate răspunsurile au fost încărcate',
  'form.archive-form-error': 'Nu s-a putut arhiva formularul',
  'form.archive-form-success': 'Formularul a fost arhivat',
  'form.archive-prompt-text':
    'Sunteți sigur că doriți să arhivați acest formular?',
  'form.archive-prompt-title': 'Arhivare formular',
  'form.create-filled-form-success': 'Formular completat',
  'form.create-form': 'Creează un formular nou',
  'form.create-form-alt': 'Creează formular',
  'form.created-form-success': 'Formular nou creat cu succes',
  'form.delete-checklist-template-prompt-text':
    'Sunteți sigur că doriți să ștergeți acest șablon de verificare?',
  'form.delete-checklist-template-prompt-title':
    'Ștergere șablon de verificare',
  'form.delete-entry-prompt-text':
    'Sunteți sigur că doriți să ștergeți acest formular completat?',
  'form.delete-entry-prompt-title': 'Ștergere înregistrare formular',
  'form.delete-field-prompt-text':
    'Sunteți sigur că doriți să ștergeți acest câmp de formular?',
  'form.delete-field-prompt-title': 'Ștergere câmp de formular',
  'form.delete-template-prompt-text':
    'Sunteți sigur că doriți să ștergeți acest formular?',
  'form.delete-template-prompt-title': 'Șterge formularul',
  'form.edit-form': 'Editează formularul',
  'form.edit-this-form': 'Editează acest formular',
  'form.errors.load-responses':
    'Nu s-au putut încărca răspunsurile formularului',
  'form.field': 'Câmp',
  'form.field-type-boolean': 'Da/Nu',
  'form.field-type-date': 'Dată',
  'form.field-type-number': 'Număr',
  'form.field-type-text': 'Text',
  'form.field-type-user': 'Utilizator',
  'form.field-type-vehicle-department': 'NEEDS TRANSLATION',
  'form.fields': 'Câmpuri',
  'form.fill-out-this-form': 'Completează acest formular',
  'form.filled-out-n-times-by-user':
    '{n, plural, =0 {Nu ați completat niciodată acest formular} one {Ați completat acest formular o dată} =2 {Ați completat acest formular de două ori} other {Ați completat acest formular de # ori}}',
  'form.filled-out-n-times-by-user-including-today':
    '{n, plural, =0 {Nu ați completat niciodată acest formular} one {Ați completat acest formular o dată, inclusiv astăzi} =2 {Ați completat acest formular de două ori, inclusiv astăzi} other {Ați completat acest formular de # ori, inclusiv astăzi}}',
  'form.form-entry': 'Formular',
  'form.form-entry-deleted': 'Formularul completat a fost șters',
  'form.form-fields': 'Câmpuri ale formularului',
  'form.form-label-name': 'Numele formularului',
  'form.form-settings': 'Setări formular',
  'form.label-archive-date': 'Data arhivării',
  'form.label-archive-max-uses':
    'Numărul maxim de utilizări înainte de arhivare',
  'form.label-archive-mode': 'Metoda de arhivare',
  'form.label-field-description': 'Descriere',
  'form.label-field-input-type': 'Tipul introducerii',
  'form.label-field-name': 'Numele câmpului',
  'form.label-field-type': 'Tipul câmpului',
  'form.label-field-value': 'Text',
  'form.label-include-footer': 'Include subsolul',
  'form.label-include-icon': 'Include iconița companiei',
  'form.label-ingress': 'Ingress',
  'form.label-type': 'Tipul formularului',
  'form.last-response-at': 'Cel mai recent răspuns',
  'form.load-all-responses': 'Încarcă toate răspunsurile',
  'form.menu-shortcut': 'Scurtătură meniu',
  'form.no-responses': 'Fără răspunsuri',
  'form.open-overview': 'Deschideți prezentarea generală',
  'form.prompts.delete-form':
    'Sunteți sigur că doriți să ștergeți complet acest formular? Toate răspunsurile vor fi, de asemenea, șterse.',
  'form.prompts.delete-form-title': 'Șterge formularul',
  'form.prompts.unverify-response':
    'Doriți să anulați statutul verificat al acestui răspuns?',
  'form.prompts.unverify-response-title': 'Elimină verificarea',
  'form.prompts.verify-response': 'Doriți să verificați acest răspuns?',
  'form.prompts.verify-response-title': 'Verifică',
  'form.remove-field': 'Elimină câmpul',
  'form.responses-for-form': 'Răspunsuri: {name}',
  'form.see-answers': 'Vezi răspunsurile',
  'form.select-form': 'Selectați formularul',
  'form.showing-num-of-total-responses': 'Afișare {num} din {total} răspunsuri',
  'form.table-label-created': 'Creat',
  'form.table-label-edit': 'Editează',
  'form.table-label-filled-in-by': 'Completat de către',
  'form.table-label-name': 'Nume',
  'form.table-label-updated': 'Actualizat la',
  'form.table-label-use-form': 'Folosește formularul',
  'form.table-label-used': 'Răspunsuri',
  'form.toasts.create-form-success': 'Formular creat',
  'form.toasts.delete-form-success': 'Formular șters',
  'form.toasts.patch-form-success': 'Formular editat',
  'form.toasts.submit-success': 'Formularul a fost trimis',
  'form.un-archive-prompt-text':
    'Sunteți sigur că doriți să re-deschideți acest formular?',
  'form.un-archive-prompt-title': 'Re-deschide formular',
  'form.update-form-success': 'Formular actualizat',
  'form.updated-form-success': 'Formularul a fost actualizat cu succes',
  'form.validation.cannot-be-negative': 'Nu poate fi negativ',
  'form.validation.required-fields-missing':
    'Nu sunt completate toate câmpurile obligatorii',
  'form.verified-by-at': 'Verificat de către {name}, {when}',
  'formcreator.distribution-field': 'NEEDS TRANSLATION',
  'formcreator.export-form-submissions': 'NEEDS TRANSLATION',
  'formcreator.filter-field': 'NEEDS TRANSLATION',
  'formcreator.summation-field': 'NEEDS TRANSLATION',
  'forum.add-comment': 'Adaugă comentariu',
  'forum.answers-number': '{num} răspunsuri',
  'forum.archived-threads': 'Threaduri arhivate',
  'forum.create-new-post': 'Creează un nou post',
  'forum.create-thread': 'Creează un nou thread',
  'forum.create-thread.send-email-error':
    'A apărut o eroare la trimiterea emailurilor',
  'forum.create-thread.send-sms-error':
    'A apărut o eroare la trimiterea SMS-urilor',
  'forum.create-thread.success': 'Thread creat',
  'forum.created': 'Creat',
  'forum.edit-post': 'Editează post',
  'forum.edit-thread': 'Editează thread',
  'forum.errors.content-required': 'Conținutul este obligatoriu',
  'forum.errors.create-thread': 'Nu s-a putut crea un nou thread',
  'forum.errors.delete-post-failed': 'Nu s-a putut șterge comentariul',
  'forum.errors.delete-thread-failed': 'Nu s-a putut șterge thread-ul',
  'forum.errors.thread-does-not-exist': 'Thread-ul nu există',
  'forum.errors.title-required': 'Titlul este obligatoriu',
  'forum.last-posts': 'Ultimile postări',
  'forum.last-threads': 'Ultimile thread-uri',
  'forum.new': 'Thread nou',
  'forum.new-thread': 'Thread nou',
  'forum.no-views': 'Fără vizualizări',
  'forum.number-of-posts': 'Numărul de postări',
  'forum.post':
    '{choice, select, definite {Postarea} plural {Postări} other {Post}}',
  'forum.post-comment': 'Postează comentariu',
  'forum.posts-error-message': 'A apărut o eroare la preluarea postărilor',
  'forum.prompts.delete-attachment':
    'Sunteți sigur că doriți să ștergeți acest atașament?',
  'forum.prompts.delete-attachment-title': 'Șterge atașamentul',
  'forum.prompts.delete-comment-prompt':
    'Sunteți sigur că doriți să ștergeți acest comentariu?',
  'forum.prompts.delete-comment-prompt-title': 'Șterge comentariul',
  'forum.prompts.delete-post':
    'Sunteți sigur că doriți să ștergeți acest comentariu?',
  'forum.prompts.delete-post-title': 'Șterge comentariul',
  'forum.prompts.delete-thread':
    'Sunteți sigur că doriți să ștergeți acest thread?',
  'forum.prompts.delete-thread-text':
    'Sunteți sigur că doriți să ștergeți thread-ul {title}? Această acțiune nu poate fi anulată.',
  'forum.prompts.delete-thread-title': 'Șterge thread-ul',
  'forum.prompts.download-attachment-text':
    'Nu se poate deschide acest atașament, doriți să-l descărcați?',
  'forum.prompts.download-attachment-title': 'Descarcă atașamentul',
  'forum.quill-link-helper':
    'Selectați textul pe care doriți să-l transformați într-un link, apoi apăsați butonul de link',
  'forum.read-by-n-employees':
    '{n, plural, =0 {Citit de niciun angajat} one {Citit de # angajat} other {Citit de # angajați}}',
  'forum.search': 'Caută în forum',
  'forum.seen-at': 'Văzut la',
  'forum.send-email-label': 'Trimite notificare prin email',
  'forum.send-sms-label': 'Trimite notificare SMS la {employeeNum} angajați',
  'forum.sms-notification': 'Notificare SMS',
  'forum.sticky-thread': 'Thread lipicios',
  'forum.sticky-threads': 'Thread-uri lipicioase',
  'forum.thread':
    '{choice, select, definite {Thread-ul} plural {Thread-urile} other {Thread}}',
  'forum.threads-error-message': 'A apărut o eroare la preluarea thread-urilor',
  'forum.timesinceposted': 'Ore în urmă ',
  'forum.toasts.archive-thread-error': 'Nu s-a putut arhiva thread-ul',
  'forum.toasts.archive-thread-success': 'Thread-ul a fost arhivat',
  'forum.toasts.create-thread-success': 'Thread creat',
  'forum.toasts.delete-thread-error': 'Thread-ul nu a putut fi șters',
  'forum.toasts.delete-thread-success': 'Thread-ul a fost șters',
  'forum.toasts.patch-post-error':
    'Ceva nu a mers bine la actualizarea postării',
  'forum.toasts.patch-post-success': 'Postarea a fost actualizată',
  'forum.toasts.patch-thread-error':
    'Ceva nu a mers bine la actualizarea thread-ului',
  'forum.toasts.patch-thread-success': 'Thread-ul a fost actualizat',
  'forum.toasts.restore-thread-success': 'Thread-ul a fost restaurat',
  'forum.unseen-only': 'Doar nevăzut',
  'forum.update-comment': 'Actualizează comentariul',
  'forum.update-thread': 'Actualizează thread-ul',
  'forum.validation.thread-must-have-content':
    'Thread-ul trebuie să aibă conținut',
  'forum.view-count-title': 'Thread-ul a fost văzut de',
  'forum.written-when': 'Scris {when}',
  'handbook.add-entry': 'Adaugă intrare',
  'handbook.add-folder': 'Adaugă folder',
  'handbook.add-sub-folder': 'Adaugă subfolder',
  'handbook.create-entry-success': 'Intrare creată',
  'handbook.create-folder-success': 'Folder creat',
  'handbook.create_entry': 'Creează o nouă intrare',
  'handbook.dashboard-links': 'Linkuri pentru tabloul de bord',
  'handbook.edit-folder': 'Editează folder',
  'handbook.entry-load-error': 'Eroare la încărcarea intrării',
  'handbook.entry.delete-success': 'Intrarea a fost ștearsă',
  'handbook.errors.delete-entry': 'Nu s-a putut șterge intrarea',
  'handbook.errors.delete-folder': 'Nu s-a putut șterge folderul',
  'handbook.folder-load-error': 'Eroare la încărcarea folderului',
  'handbook.folder.delete-success': 'Folderul a fost șters',
  'handbook.new-root-folder': 'Noul folder principal',
  'handbook.prompts.delete-entry':
    'Sunteți sigur că doriți să ștergeți această intrare?',
  'handbook.prompts.delete-entry-title': 'Șterge intrarea',
  'handbook.prompts.delete-folder':
    'Sunteți sigur că doriți să ștergeți acest folder? Toate conținuturile vor fi de asemenea șterse.',
  'handbook.prompts.delete-folder-title': 'Șterge folderul',
  'handbook.search': 'Caută în manualul șoferului',
  'handbook.start': 'Început',
  'handbook.toasts.create-entry-success':
    'Intrarea din manual "{name}" a fost creată',
  'handbook.toasts.create-folder-success': 'Folder creat',
  'handbook.toasts.delete-entry-success': 'Intrarea a fost ștearsă',
  'handbook.toasts.delete-folder-success': 'Folderul a fost șters',
  'handbook.toasts.patch-folder-success': 'Folder salvat',
  'handbook.update-entry-success': 'Intrarea a fost actualizată',
  'handbook.update-folder-success': 'Folderul a fost actualizat',
  'infringements.amount-of-infringements-in-categories':
    'Numărul de încălcări în categorii',
  'infringements.archive-infringement': 'Arhivare încălcare',
  'infringements.archived-basis': 'Baza arhivată',
  'infringements.archived-date': 'Dată arhivată',
  'infringements.category-infringement': 'NEEDS TRANSLATION',
  'infringements.export-infringement': 'Exportare încălcare',
  'infringements.export-infringement-data': 'Exportare date încălcare',
  'infringements.export.all': 'Exportă încălcările pentru companie',
  'infringements.legal-basis': 'Baza legală',
  'infringements.lower-limit': 'Limită inferioară',
  'infringements.minor-infringement': 'Încălcare minoră',
  'infringements.most-serious-infringement': 'Cea mai gravă încălcare',
  'infringements.my-infringements': 'Încălcările mele',
  'infringements.n-points': '{n} puncte',
  'infringements.no-infringements': 'Fără încălcări',
  'infringements.restore-infringement': 'Restaurează încălcare',
  'infringements.send-email-success': 'Încălcările au fost trimise pe email.',
  'infringements.serious-infringement': 'Încălcare gravă',
  'infringements.severity': 'Gravitate',
  'infringements.total-severity': 'Gravitate totală',
  'infringements.upper-limit': 'Limită superioară',
  'infringements.very-serious-infringement': 'Încălcare foarte gravă',
  'integrations.error-in-API-keys': 'Eroare în cheile API',
  'integrations.error-in-FMS-key': 'Eroare în cheia API FMS',
  'integrations.error-in-tachograph-key': 'Eroare în cheia API Tachograf',
  'issue.category-responsible-user':
    'Categorie de responsabilitate pentru deviații',
  'issue.existing-attachments': 'Atașamente existente',
  'issues.action-log': 'NEEDS TRANSLATION',
  'issues.actual-cost': 'Cost real',
  'issues.add-recipient-with-email': 'Adaugă destinatar cu email',
  'issues.archive-issue': 'Arhivare deviație',
  'issues.archived-by-user': 'NEEDS TRANSLATION',
  'issues.attachments': 'Atașamente',
  'issues.category-name': 'Numele categoriei',
  'issues.close-issue': 'Închide deviația',
  'issues.closed-at': 'Închis la',
  'issues.closed-by': 'Închis de către',
  'issues.closed-by-user': 'NEEDS TRANSLATION',
  'issues.cost-estimation': 'Cost estimat',
  'issues.create-issue': 'Creează deviație',
  'issues.create-issue-for': 'Creează deviație pentru:',
  'issues.created-by-user': 'NEEDS TRANSLATION',
  'issues.damage-description': 'Ce a fost deteriorat',
  'issues.damage-description-long':
    'Ce a fost deteriorat - Completați atât obiectele directe, cât și cele indirecte.',
  'issues.damage-description-short': 'Obiecte deteriorate',
  'issues.damage-direct-and-indirect-objects':
    'Completați obiecte directe și indirecte',
  'issues.damage-location': 'Unde s-a întâmplat',
  'issues.delete-issue': 'Șterge deviația',
  'issues.delete-issue-error-message':
    'Încercați să ștergeți o categorie cu deviații încă asociate cu ea. Aceasta nu este posibil.',
  'issues.describe-the-damage': 'Descrieți deteriorarea/obiectul deteriorat',
  'issues.description-of-event-unwanted-event': 'NEEDS TRANSLATION',
  'issues.edit-issue': 'Editează deviația',
  'issues.equipment-material': 'Echipament/material',
  'issues.error-retrieving-users':
    'A apărut o eroare la preluarea utilizatorilor',
  'issues.errors.create-issue': 'Nu s-a putut crea deviația',
  'issues.errors.must-enter-actual-cost': 'Trebuie să introduceți costul real',
  'issues.errors.must-enter-solution': 'Soluția este necesară',
  'issues.errors.patch-issue': 'Nu s-a putut actualiza deviația',
  'issues.export-issue': 'Exportă deviația',
  'issues.extend-issue-form': 'Doriți să închideți deviația acum?',
  'issues.fill-in-actual-cost': 'Completați costul real',
  'issues.fill-in-solution': 'Completați soluția finală',
  'issues.fill-in-vehicle-reg-nr':
    'Completați nr. de înmatriculare al vehiculului/remorcii în cauză',
  'issues.final-solution': 'Soluție finală',
  'issues.find-issue': 'Găsește deviația',
  'issues.give-estimated-cost': 'Oferiți o estimare a costului deviației',
  'issues.issue-categories': 'Categorii de deviații',
  'issues.issue-categories.errors.could-not-load-error':
    'Nu s-au putut încărca categoriile',
  'issues.issue-categories.no-categories': 'Fără categorii',
  'issues.issue-category': 'Categorie de deviație',
  'issues.issue-description-about': 'Despre deviație',
  'issues.issue-location': 'Locația deviației',
  'issues.issue-report': 'Raport de deviație',
  'issues.location-description': 'Unde pe locație a avut loc deviația',
  'issues.new-issue': 'Deviație nouă',
  'issues.no-issues': 'Fără deviații',
  'issues.opened-by': 'Deschis de',
  'issues.reference': '{abbreviate, select, true {Ref.} other {Referință}}',
  'issues.reopen-issue': 'Re-deschide deviația',
  'issues.reopened-by-user': 'NEEDS TRANSLATION',
  'issues.responsible': 'Responsabil',
  'issues.restore-issue': 'Restaurează deviația',
  'issues.restored-by-user': 'NEEDS TRANSLATION',
  'issues.search-for-issue': 'Caută deviație',
  'issues.select-recipient-from-list': 'Selectați destinatarul din listă',
  'issues.selected-recipients': 'Destinatari selectați',
  'issues.send-email-success': 'Raportul a fost trimis prin email.',
  'issues.solution-suggestion-about': 'Sugestii de măsuri',
  'issues.success-creation': 'Creată nouă deviație',
  'issues.success-update': 'Deviație actualizată',
  'issues.suggested-improvement': 'NEEDS TRANSLATION',
  'issues.suggested-solution': 'Soluție sugerată',
  'issues.time-of-issue': 'Timpul deviației',
  'issues.toasts.create-issue-success': 'Deviația a fost creată',
  'issues.toasts.patch-issue-success': 'Deviația a fost actualizată',
  'issues.treated': 'Tratat',
  'issues.untreated': 'Netratat',
  'issues.update-actual-cost': 'Actualizați costul real',
  'issues.updated-by-user': 'NEEDS TRANSLATION',
  'issues.validation.solution-required':
    'Pentru a închide această problemă, trebuie să furnizați o soluție',
  'issues.view-all-issues': 'Toate deviațiile',
  'issues.view-your-issues': 'Deviațiile tale',
  'login.email-or-phone-number': 'Email sau număr de telefon',
  'login.forgotten-password': 'Ai uitat parola?',
  'login.remember-me': 'Ține-mă minte',
  'login.subtitle': 'Bine ați venit la',
  'modal.and-all-its-content': ' și tot conținutul său',
  'modal.delete-attachment': 'Doriți să ștergeți "{title}" din atașamente?',
  'modal.delete-attachment-title': 'Șterge atașamentul',
  'modal.delete-folder': 'Șterge folderul',
  'modal.delete-folder-description':
    'Doriți să ștergeți folderul "{title}" și conținutul său?',
  'modal.delete-folder-entry-description':
    'Doriți să ștergeți intrarea "{title}"?',
  'modal.delete-folder-entry-title': 'Șterge intrarea',
  'modal.delete-folder-title': 'Șterge folderul',
  'modal.delete-type': 'Sunteți sigur că doriți să ștergeți {type}?',
  'modal.delete-type-title': 'Șterge {type}',
  'modal.remove-attachment':
    'Doriți să eliminați "{title}" din lista de atașamente?',
  'modal.remove-attachment-title': 'Elimină atașamentul',
  'notifications-settings.issue_closed_notification':
    'Notificare închidere deviație',
  'notifications-settings.issue_created_notification':
    'Notificare creare deviație',
  'notifications-settings.new_post_created_notification': 'Noua postare creată',
  'notifications-settings.new_thread_created_notification':
    'Nou fir de discuție creat',
  'notifications-settings.title': 'Setări notificări',
  'notifications.add-notifications': 'Adăugați notificări?',
  'notifications.error.general':
    'Ceva nu a mers bine la încărcarea notificărilor',
  'notifications.go-to-absence': 'Mergi la Absențe',
  'notifications.go-to-document': 'Mergi la document',
  'notifications.go-to-thread': 'Mergi la firul de discuție',
  'notifications.you-have-no-notifications': 'Nu aveți nicio notificare',
  'password.must-contain-nonnumeric':
    'Parola trebuie să conțină cel puțin o valoare non-numerică',
  'password.must-contain-numeric-value':
    'Parola trebuie să conțină cel puțin o valoare numerică',
  'password.too-short': 'Parola trebuie să aibă cel puțin 7 caractere',
  'permissions.add': 'Creează',
  'permissions.approve': 'Aprobă',
  'permissions.change': 'Schimbă',
  'permissions.change_sticky': 'Schimbă sticky',
  'permissions.company-settings-general': 'General',
  'permissions.forum-send-email': 'Trimite email',
  'permissions.forum-send-sms': 'Trimite SMS',
  'permissions.no-access-to-page':
    'Utilizatorul dvs. nu are acces la această pagină',
  'permissions.remove': 'Șterge',
  'permissions.view': 'Vezi',
  'permissions.view-and-change': 'Vezi/Schimbă',
  'permissions.view-others': 'Vezi altele',
  'print.error-email-process': 'Generarea printului email a eșuat',
  'print.export-type': 'Export {type}',
  'print.monthly-print': 'Imprimare lunară',
  'print.salary-print': 'Imprimare salarială',
  'print.send-to-email': 'Trimite la email',
  'print.success-email-process':
    'Cererea este în curs de procesare. Când procesul este complet, veți primi un email.',
  'quill.delete-column': 'Șterge coloana selectată',
  'quill.delete-row': 'Șterge rândul selectat',
  'quill.delete-table': 'Șterge tabelul',
  'quill.insert-column-left': 'Introduceți coloana la stânga',
  'quill.insert-column-right': 'Introduceți coloana la dreapta',
  'quill.insert-row-above': 'Introduceți rândul deasupra',
  'quill.insert-row-below': 'Introduceți rândul dedesubt',
  'quill.new-table': 'Tabel nou',
  'quill.table-tooltip': 'Shift + clic dreapta pentru a modifica tabelul.',
  'register-user.description':
    'Deoarece aceasta este prima dvs. autentificare, va trebui să completați niște informații standard.',
  'register-user.title': 'Completați informațiile dvs. personale',
  'reset-password.errors.bad-token': 'Token greșit',
  'reset-password.errors.expired-token': 'Tokenul de resetare a expirat.',
  'reset-password.go-back': 'Înapoi la autentificare',
  'reset-password.message':
    'Vă rugăm să selectați o nouă parolă. Parola trebuie să aibă cel puțin 8 caractere și nu poate consta doar din cifre.',
  'reset-password.submit': 'Schimbați parola și autentificați-vă',
  'reset-password.success': 'Parola a fost resetată',
  'reset-password.title': 'Resetați-vă parola',
  'route-planner.add-new-route': 'Adăugați rută nouă',
  'route-planner.axle-weight-restrictions': 'Restricții de greutate pe axă',
  'route-planner.create-route-plan': 'Creați un plan de rută',
  'route-planner.create-route-plan-success':
    'Planul de rută a fost creat cu succes',
  'route-planner.delete-route-plan-success':
    'Planul de rută a fost șters cu succes',
  'route-planner.destination': 'Destinație',
  'route-planner.estimated-driving-duration': 'Durata estimată a condusului',
  'route-planner.export-route-plan': 'Exportați planul de rută',
  'route-planner.extra-load': 'Încărcare suplimentară',
  'route-planner.form.label.total-weight-in-kg': 'Greutate totală în kg',
  'route-planner.form.label.total-weight-of-vehicle':
    'Greutate totală a vehiculului în kg',
  'route-planner.general-weight-restriction': 'Restricție generală de greutate',
  'route-planner.include-departure-time': 'Includeți ora plecării?',
  'route-planner.load': 'Încărcare',
  'route-planner.loading-route': 'Se încarcă ruta...',
  'route-planner.no-route-instructions': 'Fără instrucțiuni de rută',
  'route-planner.no-routes': 'Fără rute',
  'route-planner.payload': 'Încărcătură',
  'route-planner.payload-in-kilos': 'Încărcătură în kilograme',
  'route-planner.route-instructions': 'Instrucțiuni de rută',
  'route-planner.route-plan': 'Plan de rută',
  'route-planner.route-plan-no-route-found':
    'Nicio rută găsită pentru constrângerile date',
  'route-planner.route-plan-task-failed':
    'Ceva nu a mers bine cu acest plan de rută',
  'route-planner.route-plan-task-pending':
    'Acest plan de rută este în așteptare',
  'route-planner.route-restrictions': 'Restricții de rută',
  'route-planner.single-axle': 'O axă',
  'route-planner.start-location': 'Locație de start',
  'route-planner.tandem-axle': 'Două axe în tandem',
  'route-planner.tonne': 'Tonă',
  'route-planner.top-speed': 'Viteză maximă',
  'route-planner.top-speed-in-km': 'Viteză maximă în km/h',
  'route-planner.total-weight': 'Greutate totală',
  'route-planner.trailer': 'Remorcă',
  'route-planner.trailer-weight-in-kilos': 'Greutate remorcă în kilograme',
  'route-planner.triple-axle': 'Trei axe',
  'route-planner.weight-in-tonnes': 'Greutate în tone metrice',
  'select-user-settings.title': 'Setări meniu derulant utilizator',
  'selectCompany.select': 'Selectați compania',
  'settings.absence-responsible': 'Responsabil pentru absențe',
  'settings.add-absence-type': 'Adăugați tip de absență',
  'settings.add-api-key': 'Adăugați cheie API',
  'settings.add-department': 'Adăugați departament',
  'settings.add-holiday-allowance': 'NEEDS TRANSLATION',
  'settings.add-issue-category': 'Adăugați categorie de abatere',
  'settings.add-issue-risk-aspect': 'NEEDS TRANSLATION',
  'settings.add-new-terminal': 'Adăugați terminal nou',
  'settings.add-salary-code': 'Adăugați cod salarial',
  'settings.add-time-entry-project': 'Adăugați proiect',
  'settings.added-issue-category': 'Categorie de abatere nouă adăugată',
  'settings.available-departments': 'NEEDS TRANSLATION',
  'settings.change-logo': 'Schimbați logo-ul',
  'settings.change-module': 'Schimbați modulul',
  'settings.change-responsible-user': 'Schimbați utilizatorul responsabil',
  'settings.company-logo': 'Logo-ul companiei',
  'settings.company-settings': 'Setările companiei',
  'settings.create-checklist-template': 'Creează șablon de verificare',
  'settings.create-fleet-board-integration': 'NEEDS TRANSLATION',
  'settings.create-issue-category': 'Creează categorie de abatere',
  'settings.create-man-integration': 'NEEDS TRANSLATION',
  'settings.create-name-first': 'Creează numele mai întâi',
  'settings.create-salary-settings': 'Creează setări salariale pentru {module}',
  'settings.create-scania-integration': 'Creează integrare Scania',
  'settings.create-terminal-area': 'Creează zonă terminal',
  'settings.create-user-document': 'Creează document de utilizator',
  'settings.create-user-type': 'Creează tip de utilizator',
  'settings.create-volvo-integration': 'Creează integrare Volvo',
  'settings.daily-salary': 'NEEDS TRANSLATION',
  'settings.daily-work-limit': 'Limită de lucru zilnică (ore)',
  'settings.daily-work-limit-short': 'Zilnic',
  'settings.dashboard-links-preview': 'Previzualizare link-uri tablou de bord',
  'settings.day-salary-code': 'NEEDS TRANSLATION',
  'settings.default-department': 'NEEDS TRANSLATION',
  'settings.default-salary-code': 'Cod salarial implicit',
  'settings.delete-overtime-period': 'Ștergeți perioada de ore suplimentare',
  'settings.delete-period-addon': 'NEEDS TRANSLATION',
  'settings.delete-user-type': 'Ștergeți tipul de utilizator',
  'settings.do-you-want-to-delete-overtime-period':
    'Doriți să ștergeți perioada de ore suplimentare?',
  'settings.do-you-want-to-delete-period-addon': 'NEEDS TRANSLATION',
  'settings.driverdata-print-for-all-drivers': 'Imprimați toate activitățile',
  'settings.edit-absence-responsible': 'Editați responsabilul pentru absențe',
  'settings.edit-absence-type': 'Editați tipul de absență',
  'settings.edit-checklist-template': 'Editați șablonul de verificare',
  'settings.edit-department': 'Actualizați departamentul',
  'settings.edit-dynamic-column': 'Editați numele coloanei',
  'settings.edit-fleet-board-integration-n': 'NEEDS TRANSLATION',
  'settings.edit-holiday-allowance': 'NEEDS TRANSLATION',
  'settings.edit-infringements-responsible':
    'Editați utilizatorul responsabil pentru abateri',
  'settings.edit-issue-category': 'Editați categoria de abatere',
  'settings.edit-issue-risk-aspect': 'NEEDS TRANSLATION',
  'settings.edit-man-integration-n': 'NEEDS TRANSLATION',
  'settings.edit-responsible-user': 'Editați utilizatorul responsabil',
  'settings.edit-salary-and-work-time-for-type':
    'Editați setările salariale pentru {type}',
  'settings.edit-salary-code': 'Editați codul salarial',
  'settings.edit-salary-settings-type': 'Editați setările salariale: {module}',
  'settings.edit-scania-integration-n': 'Editați integrarea Scania #{n}',
  'settings.edit-terminal-area': 'Editați zona terminal',
  'settings.edit-time-entry-project': 'Editați proiectul',
  'settings.edit-type-settings': 'Editați setările pentru {type}',
  'settings.edit-user-document': 'Editați documentul de utilizator',
  'settings.edit-volvo-integration-n': 'Editați integrarea Volvo #{n}',
  'settings.errors.change-language': 'Nu s-a putut schimba limba',
  'settings.errors.company-settings-generic':
    'Nu s-au putut actualiza setările companiei',
  'settings.errors.create-absence-type': 'Nu s-a putut crea tipul de absență',
  'settings.errors.create-checklist-template-error':
    'Eroare la crearea șablonului de verificare',
  'settings.errors.create-fleetboard-integration': 'NEEDS TRANSLATION',
  'settings.errors.create-issue-category':
    'Nu s-a putut crea categoria de abatere',
  'settings.errors.create-issue-risk-aspect': 'NEEDS TRANSLATION',
  'settings.errors.create-salary': 'Nu s-au putut crea setările salariale',
  'settings.errors.create-scania-integration':
    'Nu s-a putut crea integrarea Scania',
  'settings.errors.create-terminal': 'Nu s-a putut crea zona terminal',
  'settings.errors.create-time-entry-project': 'Nu s-a putut crea proiectul',
  'settings.errors.create-user-document':
    'Nu s-a putut crea documentul de utilizator',
  'settings.errors.create-user-document-category': 'NEEDS TRANSLATION',
  'settings.errors.create-user-type': 'Nu s-a putut crea tipul de utilizator',
  'settings.errors.create-volvo-integration':
    'Nu s-a putut crea integrarea Volvo',
  'settings.errors.delete-absence-type':
    'No se pudo eliminar el tipo de ausencia',
  'settings.errors.delete-fleetboard-integration': 'NEEDS TRANSLATION',
  'settings.errors.delete-issue-category':
    'No se pudo eliminar la categoría de desviación',
  'settings.errors.delete-issue-risk-aspect': 'NEEDS TRANSLATION',
  'settings.errors.delete-salary':
    'No se pudo eliminar la configuración de salario',
  'settings.errors.delete-scania-integration':
    'No se pudo eliminar la integración de Scania',
  'settings.errors.delete-terminal': 'No se pudo eliminar el área de terminal',
  'settings.errors.delete-time-entry-project':
    'No se pudo eliminar el proyecto',
  'settings.errors.delete-user-document':
    'No se pudo eliminar el documento de usuario',
  'settings.errors.delete-user-document-category': 'NEEDS TRANSLATION',
  'settings.errors.delete-user-type': 'No se pudo eliminar el tipo de usuario',
  'settings.errors.delete-volvo-integration':
    'No se pudo eliminar la integración de Volvo',
  'settings.errors.password-change': 'No se pudo cambiar la contraseña',
  'settings.errors.patch-absence-type':
    'No se pudo actualizar el tipo de ausencia',
  'settings.errors.patch-checklist-template-error':
    'Error al actualizar la plantilla de lista de verificación',
  'settings.errors.patch-fleetboard-integration': 'NEEDS TRANSLATION',
  'settings.errors.patch-issue-category':
    'No se pudo actualizar la categoría de desviación',
  'settings.errors.patch-issue-risk-aspect': 'NEEDS TRANSLATION',
  'settings.errors.patch-salary':
    'No se pudo actualizar la configuración de salario',
  'settings.errors.patch-scania-integration':
    'No se pudo actualizar la integración de Scania',
  'settings.errors.patch-terminal': 'No se pudo actualizar el área de terminal',
  'settings.errors.patch-time-entry-project': 'No se pudo editar el proyecto',
  'settings.errors.patch-user-document':
    'No se pudo actualizar el documento de usuario',
  'settings.errors.patch-user-document-category': 'NEEDS TRANSLATION',
  'settings.errors.patch-user-type': 'No se pudo actualizar el tipo de usuario',
  'settings.errors.patch-volvo-integration':
    'No se pudo actualizar la integración de Volvo',
  'settings.errors.update-dropdowns':
    'No se pudieron actualizar las listas desplegables',
  'settings.errors.update-notifications':
    'No se pudieron actualizar las configuraciones de notificación',
  'settings.errors.update-user-type':
    'No se pudo actualizar el tipo de usuario',
  'settings.errors.user-type-cannot-be-deleted':
    'Este tipo de usuario no se puede eliminar',
  'settings.errors.user-type-cannot-be-edited':
    'Este tipo de usuario no se puede editar',
  'settings.errors.user-type-cannot-be-edited-with-name':
    'No se puede editar el tipo de usuario "{name}"',
  'settings.export-all-absences-for-period':
    'Exportar todas las ausencias por periodo',
  'settings.export-all-driver-activities':
    'Exportar todas las actividades del conductor',
  'settings.export-all-issues-for-period':
    'Exportar todas las desviaciones dentro del periodo',
  'settings.export-all-terminal-entries':
    'Exportar todas las entradas terminales',
  'settings.export-all-timesheets': 'Exportar todas las hojas de tiempo',
  'settings.export-driverdata-for-user':
    'Exportar actividades de conductor para usuario',
  'settings.export-reports': 'Exportar informes',
  'settings.export-tables': 'Configuración de tablas para exportar',
  'settings.export-tables.preview':
    'Vista previa de las columnas de la tabla de exportación',
  'settings.external-id-in-salary-system': 'NEEDS TRANSLATION',
  'settings.fats.max-daily-work-limit': 'NEEDS TRANSLATION',
  'settings.fats.n-weeks': 'NEEDS TRANSLATION',
  'settings.fats.period-generated-for': 'NEEDS TRANSLATION',
  'settings.fats.period-length': 'NEEDS TRANSLATION',
  'settings.fats.start-date-not-monday': 'NEEDS TRANSLATION',
  'settings.fats.start-date-too-late-to-get-valid-interval':
    'NEEDS TRANSLATION',
  'settings.first-to-last-day-in-month': 'Del primer al último día del mes',
  'settings.fixed-salary': 'NEEDS TRANSLATION',
  'settings.fleet-board-key-n': 'NEEDS TRANSLATION',
  'settings.fms-access-key': 'Clave de acceso FMS',
  'settings.fms-secret-key': 'Clave secreta FMS',
  'settings.group-salary-on-vehicle': 'Usar departamento de vehículos',
  'settings.has-notification': 'Tiene notificación',
  'settings.hourly-salary': 'NEEDS TRANSLATION',
  'settings.include-absences': 'Incluir ausencias',
  'settings.info.absence-types-1':
    'En esta vista puedes agregar, editar o eliminar tipos de ausencias.',
  'settings.info.absence-types-2':
    'Se recomienda precaución al editar un tipo de ausencia, ya que editará todas las entradas de ausencia existentes que utilicen ese tipo.',
  'settings.info.checklists':
    'En esta vista puedes agregar, editar y eliminar plantillas de listas de verificación.',
  'settings.info.driver-data-1':
    'En esta vista puedes ajustar la configuración y ejecutar funciones de utilidad para conductores y actividades de conductor.',
  'settings.info.driver-data-2':
    'Hay dos conjuntos de datos disponibles. Horas: imprime los mismos valores para todos los conductores disponibles en el módulo de actividades, o Dieta: imprime la hora y el lugar de inicio/fin, pero no el período de trabajo calculado.',
  'settings.info.dropdowns':
    'Esta pantalla te permite elegir qué tipos de usuario serán opciones seleccionables en los menús desplegables de ciertas páginas. Todos los usuarios con un tipo de usuario seleccionado aparecerán en esos menús desplegables.',
  'settings.info.exports-1': 'En esta vista puedes exportar cualquier dato.',
  'settings.info.fats-1': 'NEEDS TRANSLATION',
  'settings.info.general':
    'En esta vista puedes configurar algunas configuraciones básicas relacionadas con la empresa y cargar el logotipo de tu empresa.',
  'settings.info.handbook-1':
    'En esta vista puedes cambiar la configuración para el manual del conductor.',
  'settings.info.infringements-data-1':
    'En esta vista puedes descargar o enviar infracciones por correo.',
  'settings.info.integrations':
    'En esta pantalla puedes agregar o editar claves de integración.',
  'settings.info.issue-categories-1':
    'En esta vista puedes agregar, editar y eliminar categorías de desviación utilizadas en formularios de desviación.',
  'settings.info.issue-categories-2':
    'El usuario responsable es la persona que tiene la mayor responsabilidad con respecto a desviaciones de la categoría dada y generalmente será notificado sobre cualquier desarrollo relacionado con problemas de esa categoría. Los usuarios responsables deben tener un tipo de usuario que tenga esta vista asignada a través de la vista desplegable.',
  'settings.info.issue-categories-uneditable':
    'Solo el usuario responsable puede ser editado.',
  'settings.info.notifications':
    'Esta tabla te permite elegir cómo se notifican diferentes eventos a los usuarios. Recuerda guardar los cambios una vez que hayas terminado.',
  'settings.info.route-planner':
    'En esta página puedes personalizar la configuración del planificador de rutas.',
  'settings.info.salary':
    'En esta vista puedes editar la configuración de salario.',
  'settings.info.table':
    'En esta página puedes personalizar el aspecto de las tablas en la empresa.',
  'settings.info.terminal-1':
    'En este sitio defines qué redes deben considerarse redes terminales válidas.',
  'settings.info.terminal-2':
    'La dirección IP utiliza el formato ipv4, y el rango del filtro de IP es un rango de submáscara que, cuando se establece en 24, significa que todas las conexiones en la red IP se consideran válidas. Si se desea, se puede cambiar esa submáscara para asegurar un filtrado más detallado.',
  'settings.info.time-entries-1':
    'En esta página puedes configurar hojas de tiempo. Algunas opciones pueden cambiar la apariencia de una exportación o cómo se calcula el tiempo total de trabajo.',
  'settings.info.user-documents-1':
    'En esta vista puedes editar y eliminar documentos de usuarios.',
  'settings.info.user-documents-2':
    'El usuario responsable del documento es la persona que recibe la notificación cuando el documento caduca. Un documento caducará el primer día del mes seleccionado como fecha de caducidad. La notificación se enviará al usuario responsable unas semanas antes de la caducidad establecida por el campo de tiempo de notificación.',
  'settings.info.user-types-1':
    'En esta página puedes cambiar tipos de usuario y permisos.',
  'settings.info.user-types-2':
    'Ten en cuenta que todos los cambios bajo "Cambiar" y "Eliminar" otorgan acceso para cambiar y eliminar recursos creados por otros. Todos los que tienen permiso para crear un recurso también tienen acceso para cambiar sus propios recursos.',
  'settings.info.vehicles': 'NEEDS TRANSLATION',
  'settings.infringements-responsible': 'Usuario responsable de infracciones',
  'settings.integration': 'Integración',
  'settings.integrations': 'Integraciones',
  'settings.integrations-description':
    'En esta pantalla puedes agregar o editar claves de integración.',
  'settings.is-administrator': 'Es administrador',
  'settings.is-administrator-description':
    'Las cuentas de administrador tendrán todos los permisos, independientemente de esta tabla',
  'settings.last-day-of-month': 'Último día del mes',
  'settings.man-key-n': 'NEEDS TRANSLATION',
  'settings.module-options': 'Opciones del módulo',
  'settings.my-settings': 'Mis ajustes',
  'settings.n-departments': 'NEEDS TRANSLATION',
  'settings.no-checklist-templates':
    'No hay plantillas de lista de verificación.',
  'settings.no-integration-keys': 'No se encontraron claves de integración',
  'settings.no-selected-absence-responsible':
    'Nadie está seleccionado como responsable de ausencias',
  'settings.no-selected-infringements-responsible':
    'No se ha elegido ningún usuario responsable de infracciones',
  'settings.notifications.notify-driver-on-infringement': 'NEEDS TRANSLATION',
  'settings.notifications.setting.absence-created': 'NEEDS TRANSLATION',
  'settings.notifications.setting.driver-card':
    'Vencimiento de tarjeta del conductor',
  'settings.notifications.setting.forum-post': 'Nuevo comentario en el foro',
  'settings.notifications.setting.forum-thread': 'Nuevo hilo de foro',
  'settings.notifications.setting.issue-closed': 'Desviación cerrada',
  'settings.notifications.setting.issue-created': 'Desviación creada',
  'settings.notifications.setting.reset-password': 'Restablecer contraseña',
  'settings.notifications.setting.user-document':
    'Documento de usuario vencido',
  'settings.notifications.setting.user-export-ready': 'Exportación completada',
  'settings.notifications.setting.user-registration': 'Registro de usuario',
  'settings.notifications.vehicle-document': 'Documento de vehículo vencido',
  'settings.notifications.vehicle-expirations': 'Vehículo vencido',
  'settings.ovetime-periods': 'NEEDS TRANSLATION',
  'settings.paid-pause-threshold':
    'Límite para pausa remunerada (cada pausa bajo el límite seleccionado)',
  'settings.pause-duration': 'Duración de la pausa',
  'settings.pause-duration-short': 'Descanso',
  'settings.pause-threshold': 'Límite de trabajo para activación de pausa',
  'settings.pause-threshold-short': 'Trabajo antes del descanso',
  'settings.period': 'Periodo',
  'settings.period-work-limit': 'Límite de trabajo semanal (horas)',
  'settings.period-work-limit-short': 'Semanal',
  'settings.prompts.absence-type-create': 'Nombre del nuevo tipo de ausencia: ',
  'settings.prompts.absence-type-delete':
    '¿Estás seguro de que quieres eliminar el tipo de ausencia "{name}"?',
  'settings.prompts.absence-type-delete-title': 'Eliminar tipo de ausencia',
  'settings.prompts.create-user-type':
    '¿Cuál debería ser el nombre del nuevo tipo de usuario?',
  'settings.prompts.delete': '¿Realmente quieres eliminar {}?',
  'settings.prompts.delete-checklist-template':
    'Eliminar plantilla de lista de verificación',
  'settings.prompts.delete-checklist-template-description':
    '¿Quieres eliminar la plantilla de lista de verificación?',
  'settings.prompts.delete-time-entry-project':
    '¿Estás seguro de que quieres eliminar el proyecto "{name}"?',
  'settings.prompts.department-delete-title': 'Eliminar departamento',
  'settings.prompts.department-setting-delete-title':
    '¿Deseas eliminar el departamento?',
  'settings.prompts.holiday-allowance-delete-title': 'NEEDS TRANSLATION',
  'settings.prompts.integration-delete-title': 'Eliminar integración',
  'settings.prompts.integration-fleet-board-delete': 'NEEDS TRANSLATION',
  'settings.prompts.integration-man-delete': 'NEEDS TRANSLATION',
  'settings.prompts.integration-scania-delete':
    '¿Estás seguro de que quieres eliminar Scania-integration #{n}?',
  'settings.prompts.integration-volvo-delete':
    '¿Estás seguro de que quieres eliminar Volvo-integration #{n}?',
  'settings.prompts.issue-category-delete':
    '¿Estás seguro de que quieres eliminar la categoría de desviación "{name}"?',
  'settings.prompts.issue-category-delete-title':
    'Eliminar categoría de desviación',
  'settings.prompts.issue-risk-aspect-delete': 'NEEDS TRANSLATION',
  'settings.prompts.issue-risk-aspect-delete-title': 'NEEDS TRANSLATION',
  'settings.prompts.salary-code-delete-title': 'Eliminar código de salario',
  'settings.prompts.salary-setting-delete-title':
    '¿Deseas eliminar la configuración de salario y tiempo de trabajo?',
  'settings.prompts.terminal-delete':
    '¿Estás seguro de que deseas eliminar el área terminal "{name}"?',
  'settings.prompts.terminal-delete-title': 'Eliminar área terminal',
  'settings.prompts.time-entry-project-delete-title': 'Eliminar proyecto',
  'settings.prompts.user-document-delete':
    '¿Estás seguro de que deseas eliminar el documento de usuario "{name}"?',
  'settings.prompts.user-document-delete-title':
    'Eliminar documento de usuario',
  'settings.prompts.user-type-delete':
    '¿Estás completamente seguro de que quieres eliminar este tipo de usuario?',
  'settings.prompts.user-type-delete-title': 'Eliminar tipo de usuario',
  'settings.reciept-functionality': 'Funcionalidad de recibo',
  'settings.refresh-colors': 'Actualizar colores',
  'settings.responsible-user': 'Usuario responsable',
  'settings.salary-and-work-time': 'Salario y horas de trabajo',
  'settings.salary-and-work-time-type': 'salario',
  'settings.salary-code': 'Tipo de salario',
  'settings.salary-code-day': 'NEEDS TRANSLATION',
  'settings.salary-code-id-field-placeholder':
    'ID externo en sistema de salario',
  'settings.salary-day-other-salary-code-warning': 'NEEDS TRANSLATION',
  'settings.salary-end-date': 'Fecha de finalización del salario',
  'settings.salary-period': 'Período de salario',
  'settings.salary-start-date': 'Fecha de inicio del salario',
  'settings.salary-type': 'NEEDS TRANSLATION',
  'settings.save-changes': 'Guardar cambios',
  'settings.save-settings': 'Guardar ajustes',
  'settings.scania-key': 'Clave de Scania',
  'settings.scania-key-n': 'Clave de Scania #{n}',
  'settings.select-api-key-to-add': 'Elige la clave API para agregar',
  'settings.select-categories': 'NEEDS TRANSLATION',
  'settings.select-category': 'NEEDS TRANSLATION',
  'settings.select-crontab-setting': 'Seleccionar frecuencia de generación',
  'settings.select-module': 'Seleccionar módulo',
  'settings.show-column': 'Mostrar columna',
  'settings.show-dynamic-column-description':
    'Cuando una columna está desactivada, se oculta de la tabla y de las exportaciones del módulo.',
  'settings.show-dynamic-column-error':
    'Se deben activar al menos dos columnas.',
  'settings.show-in-manual-time-entry-overtime': 'NEEDS TRANSLATION',
  'settings.standard-settings': 'NEEDS TRANSLATION',
  'settings.static-categories': 'Categorías estáticas',
  'settings.tables': 'Ajustes de tabla',
  'settings.tables.add-column-description':
    'Cuando se agrega una columna, se muestra en las tablas del módulo.',
  'settings.tables.add-export-column-description':
    'Cuando se agrega una columna, se muestra en las tablas de exportación del módulo.',
  'settings.tables.add-placeholder': 'Elige una columna para agregar',
  'settings.tables.preview': 'Vista previa de la tabla',
  'settings.tachograph-key': 'Clave del tacógrafo',
  'settings.terminal-description-1':
    'En este sitio defines qué redes deben considerarse redes terminales válidas.',
  'settings.terminal-description-2':
    'La dirección IP está en formato ipv4, y el rango de filtro de IP es un rango de submáscara que, cuando se establece en 24, significa que todas las conexiones en la red IP se consideran válidas. Si se desea, se puede cambiar esa submáscara para asegurar un filtrado más granular.',
  'settings.time-entry-projects': 'Proyectos',
  'settings.timesheets.alternative-overtime-option':
    'Opción alternativa de horas extra',
  'settings.timesheets.count-pause-as-part-of-total-work':
    'Contar la pausa como parte del tiempo total de trabajo',
  'settings.timesheets.department-mandatory': 'NEEDS TRANSLATION',
  'settings.timesheets.merge-timesheets-with-activities':
    'Combinar los datos de la hoja de tiempo con las actividades',
  'settings.timesheets.periodic-overtime': 'Horas extra periódicas',
  'settings.timesheets.projects': 'Proyectos',
  'settings.toast.create-man-integration-error': 'NEEDS TRANSLATION',
  'settings.toast.create-man-integration-success': 'NEEDS TRANSLATION',
  'settings.toast.delete-man-integration-error': 'NEEDS TRANSLATION',
  'settings.toast.delete-man-integration-success': 'NEEDS TRANSLATION',
  'settings.toast.patch-man-integration-error': 'NEEDS TRANSLATION',
  'settings.toast.patch-man-integration-success': 'NEEDS TRANSLATION',
  'settings.toasts.add-column-success': 'La columna fue agregada',
  'settings.toasts.change-language-success': 'Idioma cambiado',
  'settings.toasts.company-settings-updated':
    'Configuración de la empresa actualizada',
  'settings.toasts.create-absence-type-success':
    'Tipo de ausencia "{name}" creado',
  'settings.toasts.create-checklist-template-success':
    'Plantilla de lista de control agregada.',
  'settings.toasts.create-fleetboard-integration-success': 'NEEDS TRANSLATION',
  'settings.toasts.create-issue-category-success':
    'Categoría de desviación "{name}" creada',
  'settings.toasts.create-issue-risk-aspect-success': 'NEEDS TRANSLATION',
  'settings.toasts.create-salary-success': 'Configuración de salario creada',
  'settings.toasts.create-scania-integration-success':
    'Scania-integración creada',
  'settings.toasts.create-terminal-success':
    'Área terminal "{name}" fue creada',
  'settings.toasts.create-time-entry-project-success':
    'El proyecto "{name}" fue creado',
  'settings.toasts.create-user-document-category-success': 'NEEDS TRANSLATION',
  'settings.toasts.create-user-document-success': 'Documento de usuario creado',
  'settings.toasts.create-user-type-success': 'Tipo de usuario "{name}" creado',
  'settings.toasts.create-volvo-integration-success':
    'Volvo-integración creada',
  'settings.toasts.delete-absence-type-success': 'Tipo de ausencia eliminado',
  'settings.toasts.delete-fleetboard-integration-success': 'NEEDS TRANSLATION',
  'settings.toasts.delete-issue-category-success':
    'Categoría de desviación eliminada',
  'settings.toasts.delete-issue-risk-aspect-success': 'NEEDS TRANSLATION',
  'settings.toasts.delete-salary-success': 'Configuración de salario eliminada',
  'settings.toasts.delete-scania-integration-success':
    'Scania-integración eliminada',
  'settings.toasts.delete-terminal-success': 'Área terminal eliminada',
  'settings.toasts.delete-time-entry-project-success': 'Proyecto eliminado',
  'settings.toasts.delete-user-document-category-success': 'NEEDS TRANSLATION',
  'settings.toasts.delete-user-document-success':
    'Documento de usuario eliminado',
  'settings.toasts.delete-user-type-success': 'Tipo de usuario eliminado',
  'settings.toasts.delete-volvo-integration-success':
    'Volvo-integración eliminada',
  'settings.toasts.password-change-success': 'Tu contraseña ha sido cambiada',
  'settings.toasts.patch-absence-type-success': 'Tipo de ausencia actualizado',
  'settings.toasts.patch-checklist-template-success':
    'Plantilla de lista de control actualizada',
  'settings.toasts.patch-fleetboard-integration-success': 'NEEDS TRANSLATION',
  'settings.toasts.patch-issue-category-success':
    'Categoría de desviación actualizada',
  'settings.toasts.patch-issue-risk-aspect-success': 'NEEDS TRANSLATION',
  'settings.toasts.patch-salary-success':
    'Configuración de salario actualizada',
  'settings.toasts.patch-scania-integration-success':
    'Scania-integración actualizada',
  'settings.toasts.patch-terminal-success': 'Área terminal actualizada',
  'settings.toasts.patch-time-entry-project-success':
    'El proyecto ha sido cambiado',
  'settings.toasts.patch-user-document-category-success': 'NEEDS TRANSLATION',
  'settings.toasts.patch-user-document-success':
    'Documento de usuario actualizado',
  'settings.toasts.patch-user-type-success': 'Tipo de usuario actualizado',
  'settings.toasts.patch-volvo-integration-success':
    'Volvo-integración actualizada',
  'settings.toasts.remove-column-success': 'La columna fue removida',
  'settings.toasts.update-dropdowns-success': 'Desplegables actualizados',
  'settings.toasts.update-notifications-success':
    'Configuración de notificaciones actualizada',
  'settings.toasts.update-user-type-success': 'Tipo de usuario actualizado',
  'settings.tv-view': 'Mostrar en TV',
  'settings.type-of-work': 'Tipo de trabajo',
  'settings.updated-type': 'Actualizado {type}',
  'settings.upload': 'Subir',
  'settings.upload-type': 'Subir {type}',
  'settings.use-as-site-logo': 'Usar como logo',
  'settings.use-period-addons': 'NEEDS TRANSLATION',
  'settings.use-period-overtime': 'Usar horas extra periódicas',
  'settings.use-salary-day-payment': 'NEEDS TRANSLATION',
  'settings.use-week-overtime': 'NEEDS TRANSLATION',
  'settings.user-documents-description-title':
    'Configuración de documentos de usuario',
  'settings.user-type.create-user-type': 'Crear nuevo tipo de usuario',
  'settings.user-types-and-permissions': 'Tipos de usuario y permisos',
  'settings.valid-salary-dates':
    'La fecha de inicio debe ser un día después de la fecha de finalización.',
  'settings.validation.absence-type-name-exists':
    'Este tipo de ausencia ya existe',
  'settings.validation.already-holiday-allowance-on-day': 'NEEDS TRANSLATION',
  'settings.validation.company.logo-file-size':
    'El tamaño del archivo del logo no puede exceder los 128 MiB',
  'settings.validation.company.must-have-name':
    'La empresa debe tener un nombre',
  'settings.validation.ip-address': 'Formato inválido',
  'settings.validation.issue-category-name-exists':
    'Ya existe una categoría de desviación con este nombre',
  'settings.validation.issue-risk-aspect-name-exists': 'NEEDS TRANSLATION',
  'settings.validation.last-admin-user-type-delete':
    'Este es el único tipo de usuario administrador y, por lo tanto, no se puede eliminar.',
  'settings.validation.last-admin-user-type-toggle':
    'Este es el único tipo de usuario administrador y, por lo tanto, no se puede desactivar.',
  'settings.validation.salary-driver-pause-length': 'Mínimo 45 minutos',
  'settings.validation.salary-priority-unique':
    'Ya existe una configuración salarial con esta prioridad para {module}',
  'settings.validation.terminal-range': 'Debe estar entre 1 y 32',
  'settings.validation.time-entry-project-name-exists':
    'Ya existe un proyecto con el nombre proporcionado',
  'settings.validation.user-document-name-exists': 'NEEDS TRANSLATION',
  'settings.validation.user-documents.must-have-name': 'Debe tener un nombre',
  'settings.validation.user-documents.must-set-responsible-user':
    'Debe definir un usuario',
  'settings.validation.user-type-name-exists':
    'Ya existe un tipo de usuario con este nombre',
  'settings.validation.user-type-name-exists-short': 'El nombre ya existe',
  'settings.volvo-key': 'Clave Volvo',
  'settings.volvo-key-n': 'Clave Volvo #{n}',
  'settings.week-overtime-delayed-overtime-warning': 'NEEDS TRANSLATION',
  'settings.week-salary-code': 'NEEDS TRANSLATION',
  'statistics.average-driver-grade': 'Promedio de calificación del conductor',
  'statistics.average-fuel-consumption': 'Consumo de combustible promedio',
  'statistics.driver-grade': 'Calificación del conductor',
  'statistics.kilometers-driven': 'Kilómetros recorridos',
  'statistics.l-10-km': 'L/10 km',
  'statistics.l-100-km': 'L/100 km',
  'statistics.monthly-company-statistics':
    'Estadísticas mensuales de la empresa',
  'statistics.monthly-driver-statistics': 'Tus estadísticas mensuales',
  'statistics.number-of-issues': 'Número de desviaciones',
  'table.week-sum': 'Suma semanal',
  'terminal.check-in': 'Registro de entrada',
  'terminal.check-out': 'Registro de salida',
  'terminal.checked-in': 'Registrado',
  'terminal.checked-in-at': 'Registrado a las {time}',
  'terminal.checked-in-message':
    'Registraste entrada a las {checkIn} y has trabajado {total} horas',
  'terminal.checked-out': 'Registrado salida',
  'terminal.checked-out-at': 'Registrado salida a las {time}',
  'terminal.edit-terminal-entry': 'Editar entrada terminal',
  'terminal.error.delete-terminal-entry':
    'No se pudo eliminar la entrada terminal',
  'terminal.error.edit-terminal-entry': 'No se pudo editar la entrada terminal',
  'terminal.ip-address': 'Dirección IP',
  'terminal.ip-filter-range': 'Rango de filtro de IP',
  'terminal.monthly-print': 'Impresión mensual',
  'terminal.no-activity-period':
    'No tienes actividad registrada para este período',
  'terminal.no-activity-today': 'No has registrado ninguna actividad hoy.',
  'terminal.not-on-network':
    'Necesitas estar en una red terminal para marcar entrada o salida',
  'terminal.old-checked-in-message': 'Registraste entrada en {checkIn}',
  'terminal.prompt.delete-terminal-entry':
    '¿Quieres eliminar esta entrada terminal?',
  'terminal.prompt.delete-terminal-entry-title': '¿Eliminar entrada terminal?',
  'terminal.salary-basis': 'Base salarial',
  'terminal.success.delete-terminal-entry': 'Entrada terminal eliminada',
  'terminal.success.edit-terminal-entry': 'Entrada terminal editada',
  'terminal.terminal-areas': 'Áreas terminales',
  'terminal.terminal-entries': 'Entradas terminales',
  'terminal.terminal-entry': 'Entrada terminal',
  'terminal.terminal-name': 'Nombre de terminal',
  'terminal.time': 'Hora',
  'terminal.user-activity': 'Actividad para {user}',
  'terminal.user-no-activity-period':
    '{user} no tiene actividad registrada para el período elegido',
  'terminal.your-activity': 'Tu actividad',
  'terminal.your-activity-today': 'Tu actividad hoy',
  'thread.success-creation': 'Hilo nuevo creado',
  'timesheets.absence-approved': 'Aprobado',
  'timesheets.absence-create-type': 'Crear nuevo tipo de ausencia',
  'timesheets.absence-pending': 'Pendiente',
  'timesheets.absence-rejected': 'Rechazado',
  'timesheets.absence-type': 'Tipo de ausencia',
  'timesheets.absence-type-name': 'Nombre del tipo de ausencia',
  'timesheets.absence-types': 'Tipos de ausencia',
  'timesheets.absence-types-error-1':
    'Las ausencias no se pueden agregar más de una vez al día por empleado',
  'timesheets.delete-absence': 'Eliminar ausencia',
  'timesheets.delete-time-entry': 'Eliminar entrada de tiempo',
  'timesheets.edit-absence': 'Editar ausencia',
  'timesheets.edit-absence-status': 'Editar estado de ausencia',
  'timesheets.edit-time-entry': 'Editar entrada de tiempo',
  'timesheets.error-create-absence': 'No se pudo agregar la ausencia',
  'timesheets.error-create-timeentry':
    'Ya existe una entrada de tiempo con la misma fecha y hora',
  'timesheets.error-registration-absence': 'Error al registrar nueva ausencia',
  'timesheets.error-update-absence': 'Error al actualizar la ausencia',
  'timesheets.error-update-timeentry':
    'Error al actualizar la entrada de tiempo',
  'timesheets.error.delete-absence': 'No se pudo eliminar la ausencia',
  'timesheets.error.delete-time-entry':
    'No se pudo eliminar la entrada de tiempo',
  'timesheets.error.overtime-mode-required':
    'Se requiere el modo de horas extras',
  'timesheets.error.patch-absence': 'No se pudo actualizar la ausencia',
  'timesheets.error.patch-time-entry':
    'No se pudo actualizar la entrada de tiempo',
  'timesheets.error.pause-required': 'NEEDS TRANSLATION',
  'timesheets.error.start-before-end':
    'La hora de inicio debe ser antes de la hora de fin',
  'timesheets.export-timesheet': 'Exportar hoja de tiempo',
  'timesheets.new-absence-request': 'Nueva solicitud de ausencia',
  'timesheets.new-time-entry': 'Nueva entrada de tiempo',
  'timesheets.note': 'Comentario sobre la ausencia',
  'timesheets.overtime-alt': 'Horas extras',
  'timesheets.print-timesheet': 'Imprimir hoja de tiempo',
  'timesheets.prompt.delete-absence':
    '¿Estás seguro de que quieres eliminar esta ausencia?',
  'timesheets.prompt.delete-absence-title': 'Eliminar ausencia',
  'timesheets.prompt.delete-time-entry':
    '¿Estás seguro de que quieres eliminar esta entrada de tiempo?',
  'timesheets.prompt.delete-time-entry-title': 'Eliminar entrada de tiempo',
  'timesheets.register-absence': 'Registrar ausencia',
  'timesheets.register-timeentry': 'Registrar entrada de tiempo',
  'timesheets.select-absence-type': 'Seleccionar tipo de ausencia',
  'timesheets.send-request': 'Enviar solicitud',
  'timesheets.success-registration-absence': 'Nueva ausencia registrada',
  'timesheets.success-registration-timeentry':
    'Nueva entrada de tiempo registrada',
  'timesheets.success-update-absence': 'Ausencia actualizada',
  'timesheets.success-update-timeentry': 'Entrada de tiempo actualizada',
  'timesheets.timeentry': 'Entrada de tiempo',
  'timesheets.toast.delete-absence-success': 'Ausencia eliminada',
  'timesheets.toast.delete-time-entry-success': 'Entrada de tiempo eliminada',
  'timesheets.toast.patch-absence-success': 'Ausencia actualizada',
  'timesheets.toast.patch-time-entry-success': 'Entrada de tiempo actualizada',
  'timesheets.update-absence': 'Actualizar ausencia',
  'timesheets.update-timeentry': 'Actualizar entrada de tiempo',
  'timesheets.view-all-absence': 'Todas las ausencias',
  'timesheets.view-all-timeentries': 'Todas las entradas de tiempo',
  'timesheets.view-your-absence': 'Tu ausencia',
  'timesheets.view-your-timeentries': 'Tus entradas de tiempo',
  'timesheets.warning.time-entry-exist-in-daterange':
    'Ya existe una entrada de tiempo con un período de tiempo superpuesto',
  'toast.success-archive-type': '{type} archivado exitosamente',
  'toast.success-un-archive-type': '{type} reabierto',
  'toasts.email-sent-successfully': 'Correo electrónico enviado exitosamente',
  'toasts.error-add-type': 'No se pudo agregar {type}',
  'toasts.error-delete-type': 'No se pudo eliminar {type}',
  'toasts.error-update-type': 'No se pudo actualizar {type}',
  'toasts.error.create-type': 'No se pudo crear {type}',
  'toasts.error.update-type': 'No se pudo actualizar {type}',
  'toasts.error.upload-type': 'No se pudo cargar {type}',
  'toasts.success-add-type': '{type} agregado exitosamente.',
  'toasts.success-created-type': '{type} creado exitosamente',
  'toasts.success-delete-type': '{type} eliminado',
  'toasts.success-update-type': '{type} actualizado',
  'toasts.success.upload-type': '{type} cargado',
  'user-type-settings.delete-prompt-text':
    '¿Estás seguro de que deseas eliminar el tipo de usuario?',
  'user-type-settings.delete-prompt-title': 'Eliminar tipo de usuario',
  'user-type-settings.title': 'Tipos de usuario y permisos',
  'user-type-settings.toasts.error': 'Error al actualizar',
  'user-type-settings.toasts.success': 'Tipos de usuario actualizados',
  'user-types.change-settings-label': 'Cambiar',
  'user-types.create-settings-label': 'Crear',
  'user-types.driver-activities-self': 'Actividades (propio)',
  'user-types.everyones-driver-activities': 'Actividades (todos)',
  'user-types.remove-settings-label': 'Eliminar',
  'user-types.view-settings-label': 'Ver',
  'users.activate-user': 'Activar usuario',
  'users.active-driver-card': 'Tarjeta de conductor activa',
  'users.add-driver-card': 'Agregar tarjeta de conductor',
  'users.add-driver-card-number': 'Agregar número de tarjeta de conductor',
  'users.add-expiry-date': 'NEEDS TRANSLATION',
  'users.add-your-driver-number': 'Agregar tu número de tarjeta de conductor',
  'users.awaits-configuration': 'En espera de configuración',
  'users.bio': 'Biografía del perfil',
  'users.birth-number': 'Número de nacimiento',
  'users.change-picture': 'Cargar imagen',
  'users.change-profile-picture': 'Cargar imagen de perfil',
  'users.contact-person-name': 'Nombre de la persona de contacto',
  'users.contact-person-phone': 'Teléfono de la persona de contacto',
  'users.create-password.description':
    'Crea una nueva contraseña para tu usuario recién agregado',
  'users.create-password.title': 'Crear nueva contraseña',
  'users.create-user': 'Crear usuario',
  'users.deactivate-user': 'Desactivar usuario',
  'users.delete-driver-card': 'NEEDS TRANSLATION',
  'users.delete-driver-card-message': 'NEEDS TRANSLATION',
  'users.delete-existing-number':
    '¿Deseas eliminar el número existente de su usuario actual?',
  'users.delete-expired-driver-card': 'NEEDS TRANSLATION',
  'users.delete-expired-driver-card-message': 'NEEDS TRANSLATION',
  'users.delete-user': 'Eliminar usuario',
  'users.driver-card': 'Tarjeta de conductor',
  'users.driver-card-number': 'Número de tarjeta de conductor',
  'users.edit-driver-card': 'Editar tarjeta de conductor',
  'users.edit-profile': 'Editar perfil',
  'users.edit-profile-information': 'Editar información del perfil',
  'users.edit-user': 'Editar usuario',
  'users.errors.driver-card-expiry-format': 'Formato: MM.AA',
  'users.errors.driver-card-invalid-expiry':
    'La fecha de vencimiento es inválida',
  'users.errors.phone-number-taken':
    'El número de teléfono está asociado a otro usuario',
  'users.expired-cards': 'NEEDS TRANSLATION',
  'users.expired-driver-card': 'NEEDS TRANSLATION',
  'users.has-document': 'NEEDS TRANSLATION',
  'users.has-no-companies':
    'No estás afiliado a ninguna empresa. Por favor, contacta a tu administrador.',
  'users.include-expired-documents': 'NEEDS TRANSLATION',
  'users.internal-number': 'Número interno',
  'users.missing-external-id': 'NEEDS TRANSLATION',
  'users.my-profile': 'Mi perfil',
  'users.n-has-one-or-more-expired-driver-cards': 'NEEDS TRANSLATION',
  'users.n-users': '{n} usuarios',
  'users.new': 'Nuevo usuario',
  'users.new-registration-link-send': 'NEEDS TRANSLATION',
  'users.no-more-user-types': 'No hay más tipos de usuario',
  'users.number-already-exists': 'Un usuario, {name}, ya utiliza este número',
  'users.personal-info': 'Información personal',
  'users.profile': 'Perfil',
  'users.profile-did-change': 'La información del perfil fue actualizada',
  'users.profile-of-user': 'Perfil: {user}',
  'users.prompts.deactivate-self':
    '¿Estás seguro de desactivar tu propio usuario?',
  'users.prompts.deactivate-self-title': 'Desactivar usuario propio',
  'users.prompts.deactivate-user': '¿Estás seguro de desactivar este usuario?',
  'users.prompts.deactivate-user-title': 'Desactivar usuario',
  'users.search': 'Buscar usuario',
  'users.select-user-types': 'Seleccionar tipos de usuario',
  'users.send-new-invitation': 'NEEDS TRANSLATION',
  'users.settings': 'Mis ajustes',
  'users.show-deactivated': 'Mostrar usuarios desactivados',
  'users.stopped-in-control': '¿Detenido para inspección?',
  'users.toasts.submit-driver-card-success':
    'Tarjeta de conductor agregada exitosamente',
  'users.toasts.user-activated': 'El usuario {name} fue activado',
  'users.toasts.user-deactivated': 'El usuario {name} fue desactivado',
  'users.too-weak-password': 'Contraseña demasiado débil',
  'users.user-is-deactivated': 'Este usuario está desactivado',
  'vehicles.add-expiration-date': 'Agregar fecha de vencimiento',
  'vehicles.add-vehicle': 'Agregar vehículo',
  'vehicles.administrative': 'Administrativo',
  'vehicles.back-lifter': 'Elevador trasero',
  'vehicles.brand': 'Marca',
  'vehicles.cool-freeze-heat': 'Enfriar/congelar/calor',
  'vehicles.crane': 'Grúa',
  'vehicles.crane-type': 'Tipo de grúa',
  'vehicles.delete-vehicle': 'Eliminar vehículo',
  'vehicles.delete-vehicle-message':
    '¿Estás seguro de querer eliminar este vehículo/material?',
  'vehicles.delete-vehicle-title': '¿Eliminar vehículo/material?',
  'vehicles.diesel-card-number': 'Número de tarjeta de diesel',
  'vehicles.edit-expiration-date': 'Editar fecha de vencimiento',
  'vehicles.edit-vehicle': 'Editar vehículo',
  'vehicles.edit-vehicle-document': 'Editar documento del vehículo',
  'vehicles.engine': 'Motor',
  'vehicles.euro-class': 'Clase Euro',
  'vehicles.existing-vehicle': 'Vehículo existente',
  'vehicles.expiration-dates': 'Fechas de vencimiento',
  'vehicles.extension-type': 'Tipo de extensión',
  'vehicles.ferry-card-number': 'Número de tarjeta de ferry',
  'vehicles.first-registered': 'Primera vez registrado',
  'vehicles.fleet': 'Flota',
  'vehicles.fleet-management': 'Gestión de flotas',
  'vehicles.fleet-overview': 'Equipos y materiales',
  'vehicles.fridge': 'Refrigerador',
  'vehicles.fridge-type': 'Tipo de refrigerador',
  'vehicles.fuel': 'Combustible',
  'vehicles.goods-insurance-type': 'Tipo de seguro de bienes',
  'vehicles.height': 'Altura',
  'vehicles.inner-height': 'Altura interna',
  'vehicles.inner-length': 'Longitud interna',
  'vehicles.inner-width': 'Ancho interno',
  'vehicles.km-per-month': 'Km de coche por mes',
  'vehicles.km-per-year': 'Km de coche por año',
  'vehicles.license-number': 'Número de licencia',
  'vehicles.loading-height': 'Altura de carga',
  'vehicles.max-back-axle-load': 'Carga máxima del eje trasero',
  'vehicles.max-front-axle-load': 'Carga máxima del eje delantero',
  'vehicles.maximum-coupling-load': 'Carga máxima de acoplamiento',
  'vehicles.maximum-payload': 'Carga máxima',
  'vehicles.maximum-semi-trailer-weight': 'Peso máximo del semirremolque',
  'vehicles.maximum-speed': 'Velocidad máxima',
  'vehicles.maximum-truck-weight': 'Peso máximo del camión',
  'vehicles.maxium-total-weight': 'Peso total máximo',
  'vehicles.meat-rack': 'Rack de carne',
  'vehicles.model': 'Modelo',
  'vehicles.net-weight': 'Peso neto',
  'vehicles.new-expiration-date': 'Nueva fecha de vencimiento',
  'vehicles.new-vehicle-document': 'Nuevo documento del vehículo',
  'vehicles.next-periodic-control': 'NEEDS TRANSLATION',
  'vehicles.no-expiration-dates': 'Sin fechas de vencimiento',
  'vehicles.no-file-selected': 'Ningún archivo seleccionado',
  'vehicles.owned': 'Propiedad',
  'vehicles.pallet-spaces': 'Número de espacios para palets',
  'vehicles.pin-code': 'Código PIN',
  'vehicles.power': 'Potencia',
  'vehicles.previous-periodic-control': 'NEEDS TRANSLATION',
  'vehicles.prompts.delete-expiration-date': 'Eliminar fecha de vencimiento',
  'vehicles.registered-country': 'País registrado',
  'vehicles.service-agreement': 'Acuerdo de servicio',
  'vehicles.service-and-maintenance': 'Servicio y mantenimiento',
  'vehicles.side-opening': 'Apertura lateral',
  'vehicles.sold-date': 'Fecha de venta',
  'vehicles.stationing-location': 'Ubicación de estacionamiento',
  'vehicles.studded-drive-tire': 'Neumático de tracción con clavos',
  'vehicles.studded-front-tire': 'Neumático delantero con clavos',
  'vehicles.tires-dimension-bogie': 'Dimensión de neumáticos de bogie',
  'vehicles.tires-dimension-drive': 'Dimensión de neumáticos de tracción',
  'vehicles.tires-dimension-front': 'Dimensión de neumáticos delanteros',
  'vehicles.toasts.vehicle-updated': 'Vehículo actualizado exitosamente',
  'vehicles.toll-tag-number': 'Número de etiqueta de peaje',
  'vehicles.unowned': 'No propiedad',
  'vehicles.usage-properties': 'Propiedades de uso',
  'vehicles.vbg-coupling': 'Acoplamiento VBG',
  'vehicles.vehicle-details': 'Detalles del vehículo',
  'vehicles.vehicle-technical-type': 'Tipo técnico del vehículo',
  'vehicles.vehicle-type': 'Tipo de vehículo',
  'vehicles.view-vehicle-details': 'Ver detalles del vehículo',
  'vehicles.vin': 'VIN',
  'vehicles.volume': 'Volumen',
  'vehicles.weights': 'Pesos',
}
export default ro
