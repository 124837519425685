import { Icon, Loader, usePrompt } from '@ur/react-components'
import { useClickOutside } from '@ur/react-hooks'
import { PlainLink } from 'components/Link'

import { UserFullNameQuery } from 'modules/users'
import { USER_FULL_NAME_QUERY } from 'modules/users/queries'
import React, { useMemo, useRef } from 'react'
import { useQuery } from '@apollo/client'
import styled, { css } from 'styled-components'
import { IdVariable } from 'types/graphql'
import { format } from 'util/date-fns'
import { FormTemplateFieldType } from '../types.graphql'

interface ValueCellProps {
  canExpand: boolean
}
const Wrapper = styled.td<ValueCellProps>`
  max-height: 100px;

  & > * {
    white-space: nowrap;
  }

  ${props =>
    props.canExpand &&
    css`
      cursor: cell;

      &:hover {
        background-color: ${props => props.theme.colors.secondary}20;
      }
    `};
`
const Expandable = styled.aside`
  max-width: 600px;
  padding: 1rem;

  background-color: white;
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  box-shadow: ${props => props.theme.shadow.default};

  h2 {
    margin: 0 0 0.5rem 0;
  }
  p {
    margin: 0;
    white-space: pre-wrap;
    line-height: 160%;
  }

  ${props => props.theme.media.mobile} {
    max-width: 96vw;
  }
`

const Types = {
  Text: styled.div`
    margin: 0;
    max-width: 300px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  Number: styled.div`
    text-align: center;
  `,
  Date: styled.div``,
  Checkbox: styled.div`
    text-align: center;
  `,
  User: styled.div``,
}

interface ExpandPromptProps {
  title: string
  text: string
  onClose: (close: null) => void
}
const ExpandPrompt: React.VFC<ExpandPromptProps> = ({
  title,
  text,
  onClose,
}) => {
  const ref = useRef<HTMLElement>(null)

  useClickOutside(ref, () => onClose(null), true)

  return (
    <Expandable ref={ref}>
      <h2>{title}</h2>
      <p>{text}</p>
    </Expandable>
  )
}

interface UserNameProps {
  id: string
}

const UserName: React.VFC<UserNameProps> = ({ id }) => {
  const { data, loading, error } = useQuery<UserFullNameQuery, IdVariable>(
    USER_FULL_NAME_QUERY,
    {
      variables: {
        id,
      },
    }
  )

  return !data || loading ? (
    <Loader.Dots size={22} />
  ) : !!error ? (
    <>N/A</>
  ) : (
    <PlainLink to={`/users/${data.user.id}`} hoverColor="secondary">
      {data.user.fullName}
    </PlainLink>
  )
}

interface ResponseValueCellProps {
  title: string
  value: string
  type: FormTemplateFieldType
}

export const ResponseValueCell: React.VFC<ResponseValueCellProps> = ({
  title,
  value,
  type,
}) => {
  const addPrompt = usePrompt()

  const render = useMemo(() => {
    if (value === '') return null

    switch (type) {
      case 'TEXT':
        return <Types.Text>{value}</Types.Text>
      case 'NUMBER':
        return <Types.Number>{value}</Types.Number>
      case 'DATE':
        if (value === '') return <Types.Date />

        const date = format(new Date(value), 'PP')
        return <Types.Date>{date}</Types.Date>
      case 'CHECKBOX':
        const checked = value === 'true'

        return (
          <Types.Checkbox>
            <Icon
              icon={checked ? 'check-circle' : 'times-circle'}
              type="solid"
              size="0.8rem"
              color={checked ? 'green' : 'red'}
            />
          </Types.Checkbox>
        )
      case 'USER':
        return (
          <Types.User>
            <UserName id={value} />
          </Types.User>
        )
      default:
        return <Types.Text>{value}</Types.Text>
    }
  }, [value, type])

  async function showExpanded() {
    await addPrompt<null>(resolve => (
      <ExpandPrompt title={title} text={value} onClose={resolve} />
    ))
  }

  const canExpand = type === 'TEXT'

  return (
    <Wrapper canExpand={canExpand} onClick={() => canExpand && showExpanded()}>
      {render}
    </Wrapper>
  )
}
