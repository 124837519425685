import { RoutePlanInstructionNode } from 'modules/routePlanner/types.graphql'
import styled from 'styled-components'
import { RouteInstructionIcon } from './RouteInstructionIcon'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0.5rem;

  div {
    margin-right: 0.5rem;
  }

  span:last-child {
    word-break: break-word;
  }
`

export const RouteInstructionItem: React.VFC<{
  routeInstruction: RoutePlanInstructionNode
}> = ({ routeInstruction }) => {
  return (
    <Wrapper>
      <div>
        <RouteInstructionIcon routeInstruction={routeInstruction} />
      </div>
      {/* &#41; is right-parenthesis */}
      <div>{routeInstruction.order}&#41;</div>
      <span>{routeInstruction.instruction}</span>
    </Wrapper>
  )
}
