import { ExpirationTypes } from './types'

export const DriverCardCountryChoices = [
  { value: 'AL', label: 'Albania' },
  { value: 'AND', label: 'Andorra' },
  { value: 'ARM', label: 'Armenia' },
  { value: 'A', label: 'Austria' },
  { value: 'AZ', label: 'Azerbaijan' },
  { value: 'BY', label: 'Belarus' },
  { value: 'B', label: 'Belgium' },
  { value: 'BIH', label: 'Bosnia Herzegovina' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'HR', label: 'Croatia' },
  { value: 'CY', label: 'Cyprus' },
  { value: 'CZ', label: 'Czech Republic' },
  { value: 'DK', label: 'Denmark' },
  { value: 'EST', label: 'Estonia' },
  { value: 'FO', label: 'Faroe Islands' },
  { value: 'FIN', label: 'Finland' },
  { value: 'F', label: 'France' },
  { value: 'GE', label: 'Georgia' },
  { value: 'D', label: 'Germany' },
  { value: 'GR', label: 'Greece' },
  { value: 'H', label: 'Hungary' },
  { value: 'IS', label: 'Iceland' },
  { value: 'IRL', label: 'Ireland' },
  { value: 'I', label: 'Italy' },
  { value: 'KZ', label: 'Kazakhstan' },
  { value: 'LV', label: 'Latvia' },
  { value: 'FL', label: 'Liechtenstein' },
  { value: 'LT', label: 'Lithuania' },
  { value: 'L', label: 'Luxembourg' },
  { value: 'M', label: 'Malta' },
  { value: 'MD', label: 'Moldova' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MNE', label: 'Montenegro' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'MK', label: 'North Macedonia' },
  { value: 'N', label: 'Norway' },
  { value: 'PL', label: 'Poland' },
  { value: 'P', label: 'Portugal' },
  { value: 'RO', label: 'Romania' },
  { value: 'RUS', label: 'Russia' },
  { value: 'RSM', label: 'San Marino' },
  { value: 'SRB', label: 'Serbia' },
  { value: 'SK', label: 'Slovakia' },
  { value: 'SLO', label: 'Slovenia' },
  { value: 'E', label: 'Spain' },
  { value: 'S', label: 'Sweden' },
  { value: 'CH', label: 'Switzerland' },
  { value: 'TJ', label: 'Tajikistan' },
  { value: 'TR', label: 'Turkey' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'UK', label: 'United Kingdom' },
  { value: 'UZ', label: 'Uzbekistan' },
  { value: 'V', label: 'Vatican City' },
  { value: 'RFU', label: 'Reserved for Future Use' },
  { value: 'EC', label: 'European Community' },
  { value: 'EUR', label: 'Rest of Europe' },
  { value: 'WLD', label: 'Rest of the World' },
]

export const VehicleFridgeTypeChoices = [
  { label: 'Temp 1', value: 'TEMP_1' },
  { label: 'Temp 2', value: 'TEMP_2' },
]

export const EXPIRATION_TYPES: ExpirationTypes = {
  adr: 'ADR',
  nextAdrControl: 'Neste ADR kontroll',
  nextTailLiftControl: 'Neste bakløfterkontroll',
  nextEuControl: 'Neste EU kontroll',
  nextFireExtinguisherControl: 'Neste kontroll av brannslukker',
  nextCraneControl: 'Neste krankontroll',
  nextPrinterCalibration: 'Neste skriver kalibrering',
  expirationLeasing: 'Utløp leasingavtale',
  expirationServiceAgreement: 'Utløp serviceavtale',
}

export const EXPIRATION_TYPE_OPTIONS = Object.entries(EXPIRATION_TYPES).map(
  item => {
    return {
      value: item[0],
      label: item[1],
    }
  }
)
