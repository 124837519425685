import { useForm, useTranslate } from '@ur/react-hooks'
import { CenteredLoader, DatePicker, ExportButton, Select } from 'components'
import { Card } from 'components/Card'

import React, { useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import ScrollBar from 'react-perfect-scrollbar'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import { useBreadcrumbs, useCompany, usePermissions } from 'util/hooks'
import { FORM_TEMPLATE_SUMS_QUERY } from '../queries'
import {
  ExportFilledOutForms,
  ExportFilledOutFormsVariables,
  FormTemplateSumsQuery,
  FormTemplateSumsQueryVariables,
} from '../types.graphql'
import { PERMISSIONS } from 'util/permissions'
import { FieldType } from '../consts'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import { Field } from 'modules/companies'
import { EXPORT_FILLED_OUT_FORMS } from '../mutations'
import { usePrompt, useToast } from '@ur/react-components'
import { SelectSalaryCodeAndFormatPrompt, SelectSalaryCodeAndFormatForm } from './SelectSalaryCodeAndFormatPrompt'
import { ExportFormat } from 'modules/exports'

const Filter = styled.div`
  display: flex;
  ${props => props.theme.media.mobile} {
    flex-direction: column;
  }
  gap: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  > div {
    width: 100%;
  }
`

const Wrapper = styled.div`
  ${props => props.theme.layout.default};

  header {
    display: flex;
    justify-content: space-between;

    h2 {
      display: flex;

      div.loader {
        margin-left: 0.5rem;
      }
    }
    div.buttons {
      button {
        height: 3rem;
      }
    }
  }

  ${props => props.theme.media.mobile} {
    header {
      flex-direction: column;

      h2 {
        margin: 0 0 0.5rem 0;
      }

      div.buttons {
        display: flex;
        flex-direction: column;

        margin-bottom: 1rem;
      }
    }
  }
`
const Content = styled(Card)`
  position: relative;
  padding: 2rem;
  overflow: auto;

  div.details {
    p {
      max-width: 600px;
      margin: 0 0 0.5rem 0;
      white-space: pre-wrap;
    }
    span {
      display: block;
      margin: 0 0 0.5rem 0;
      font-size: 0.9rem;
      color: ${props => props.theme.colors.gray4};
    }
  }

  ${props => props.theme.media.mobile} {
    padding: 1rem;

    div.details {
      p {
        max-width: 100%;
      }
    }
  }
`
const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0;

  th,
  td {
    padding: 8px 12px;

    text-align: left;
    border: 1px solid ${props => props.theme.colors.gray8};
    border-width: 1px 0 0 1px;

    &:last-child {
      border-right-width: 1px;
    }
    &.nowrap {
      white-space: nowrap;
    }
    &.last-common {
      border-right-width: 2px;
      border-right-color: ${props => props.theme.colors.gray6};
    }
    &.hoverable {
      cursor: pointer;

      &:hover {
        background-color: ${props => props.theme.colors.secondary}20;
      }
    }
  }

  th {
    background-color: ${props => props.theme.colors.quaternary};

    &:first-child {
      border-top-left-radius: 8px;
    }
    &:last-child {
      border-top-right-radius: 8px;
    }
  }

  tr {
    &:nth-child(even) td {
      background-color: ${props => props.theme.colors.gray11};
    }
  }

  tbody tr:last-child {
    td {
      border-bottom-width: 1px;

      &:first-child {
        border-bottom-left-radius: 8px;
      }
      &:last-child {
        border-bottom-right-radius: 8px;
      }
    }
  }
`

interface ResponseSumsProps {}

export const ResponseSums: React.VFC<ResponseSumsProps> = () => {
  const translations = useTranslate({
    loading: 'common.loading...',
    startDate: 'common.start-date',
    endDate: 'common.end-date',
    filterField: 'formcreator.filter-field',
    summationField: 'formcreator.summation-field',
    distributionField: 'formcreator.distribution-field',
    sums: 'common.sums',
    sum: 'common.sum',
    name: 'common.name',
    noResults: 'common.no-results',
    externalId: 'common.external-id',
    export: 'common.export',
    downloadSuccess: 'exports.success-user-exports',
  })

  const { hasPermissions } = usePermissions()
  const canViewOthers = hasPermissions(PERMISSIONS.formcreator.change.filledoutform, true)

  const company = useCompany()
  const { formId } = useParams<{ formId: string }>()
  const history = useHistory()

  const addPrompt = usePrompt()
  const addToast = useToast()

  const { setOverride, overrides } = useBreadcrumbs()

  const { formValues: form, formChangeHandler: handler } = useForm<{
    startDate: Date
    endDate: Date
    filterFieldId: string | null
    summationFieldId: string | null
    distributionField: string | null
  
  }>({
    values: {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      filterFieldId: null,
      summationFieldId: null,
      distributionField: null,
    },
    config: {
      storage: {
        storeFormState: true,
        retrieveFormStateOnMount: true,
        storeFormStateName: `response-sums-${formId}`,
        excludeFields: ['startDate', 'endDate'],
      },
    },
  })

  const { data, loading } = useQuery<
    FormTemplateSumsQuery,
    FormTemplateSumsQueryVariables
    >(FORM_TEMPLATE_SUMS_QUERY, {
      variables: { 
        companyId: company.id,
        formTemplateId: formId,
        startDate: format(form.startDate, 'yyyy-MM-dd'),
        endDate: format(form.endDate, 'yyyy-MM-dd'),
        filterFieldId: form.filterFieldId ?? undefined,
        summationFieldId: form.summationFieldId ?? undefined,
        distributionFieldId: form.distributionField ?? undefined,
      },
    })

  const [doExport] = useMutation<
      ExportFilledOutForms,
      ExportFilledOutFormsVariables
  >(EXPORT_FILLED_OUT_FORMS, {
    onCompleted: () => {
      addToast('success', translations.downloadSuccess)
    }
  })

  useEffect(() => {
    if((!data && overrides[formId] !== translations.loading) || (data && overrides[formId] !== data.formTemplate.name)) {
      setOverride(formId, !data ? translations.loading : data.formTemplate.name)
    }
  }, [data, formId, overrides, setOverride, translations.loading])


  if (!canViewOthers){
    history.goBack()
  }

  const filterFieldOptions = data?.formTemplate.fields.filter(field => field.fieldType === FieldType.DATE).map(field => ({
    label: field.name,
    value: field.id,
  })) ?? []

  const summationFieldOptions = data?.formTemplate.fields.filter(field => field.fieldType === FieldType.NUMBER).map(field => ({
    label: field.name,
    value: field.id,
  })) ?? []

  const distributionFieldOptions = data?.formTemplate.fields.filter(field => field.fieldType === FieldType.TEXT || field.fieldType === FieldType.VEHICLE_DEPARTMENT).map(field => ({
    label: field.name,
    value: field.id,
  })) ?? []
  const sumsFound = (data?.userSummations?.userSums?.length ?? 0) > 0


  async function handleExport() {
    const { data } = await addPrompt<SelectSalaryCodeAndFormatForm | null>(resolve => (
      <SelectSalaryCodeAndFormatPrompt onSubmit={resolve} />
    ))

    if (!data) return
    if (!data.exportFormat) return
    if (!data.salaryCodeId && data.exportFormat !== ExportFormat.EXCEL) return

    doExport(
      {
        variables: {
          dateEnd: format(form.endDate, 'yyyy-MM-dd'),
          dateStart: format(form.startDate, 'yyyy-MM-dd'),
          exportFormat: data.exportFormat,
          filterFieldId: form.filterFieldId,
          salaryCodeId: data.salaryCodeId,
          summationFieldId: form.summationFieldId,
          filledOutFormId: formId,
          distributionFieldId: form.distributionField,
        }
      }
    )
  }

  return (
    <Wrapper>
      <header>
        <h2>
          {translations.sums} - {data?.formTemplate.name}
        </h2>
      </header>

      <Filter>
        <Field>
          <label>{translations.startDate}</label>
          <DatePicker value={form.startDate} onChange={handler('startDate')} width="auto" closeOnDateChange />
        </Field>
        <Field>
          <label>{translations.endDate}</label>
        <DatePicker value={form.endDate} onChange={handler('endDate')} width="auto" closeOnDateChange />
        </Field>
        <Field>
          <label>{translations.filterField}</label>
        <Select value={form.filterFieldId} onChange={handler('filterFieldId')} options={filterFieldOptions} width="auto" />
        </Field>
        <Field>
          <label>{translations.summationField}</label>
        <Select value={form.summationFieldId} onChange={handler('summationFieldId')} options={summationFieldOptions} width="auto"/>
        </Field>
        <Field>
          <label>{translations.distributionField}</label>
        <Select value={form.distributionField} onChange={handler('distributionField')} options={distributionFieldOptions} width="auto" nullable/>
        </Field>
        <Field>
          <label>{translations.export}</label>
          <ExportButton iconLeftProps={{ icon: 'download' }} onClick={() => handleExport()} disabled={!sumsFound}>
            <span>{translations.export}</span>
          </ExportButton>
        </Field>
      </Filter>

      <Content>
        
        {sumsFound ?
          <ScrollBar>
            <Table>
              <thead>
                <tr>
                  <th>{translations.name}</th>
                  <th>{translations.externalId}</th>
                  <th>{translations.distributionField}</th>
                  <th>{translations.sum}</th>
                </tr>
              </thead>
              <tbody>
                {data?.userSummations.userSums?.map(sum => (
                  <tr key={sum.user.id + sum.distribution}>
                    <td>{sum.user.fullName}</td>
                    <td>{sum.user.internalNumber}</td>
                    <td>{sum.distribution}</td>
                    <td>{sum.sum}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ScrollBar>
        : (
          (loading) ? <CenteredLoader /> : <p>{translations.noResults}</p>
        )}
      </Content>
    </Wrapper>
  )
}
