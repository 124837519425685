import { useMutation } from '@apollo/client'
import { useToast } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { endOfMonth, formatISO, startOfMonth } from 'date-fns'
import { DRIVER_ACTIVITY_PERIOD_REPORT_QUERY } from 'modules/activities'
import {
  CreateChecklistTemplateMutation,
  CreateChecklistTemplateMutationVariables,
  PatchChecklistTemplateMutationVariables,
} from 'modules/checklists'
import {
  CREATE_CHECKLIST_TEMPLATE_MUTATION,
  DELETE_CHECKLIST_TEMPLATE_MUTATION,
  PATCH_CHECKLIST_TEMPLATE_MUTATION,
} from 'modules/checklists/mutations'
import { USER_CHECKLISTS } from 'modules/checklists/queries'
import {
  ALL_MODULE_CONFIG_QUERYS_SHALLOW,
  DELETE_SALARY_WORK_TIME_MUTATION,
} from 'modules/companies'
import {
  CREATE_FLEETBOARD_API_KEY_MUTATION,
  CREATE_MAN_API_KEY_MUTATION,
  CREATE_SALARY_WORK_TIME_MUTATION,
  CREATE_SCANIA_API_KEY_MUTATION,
  CREATE_USER_TYPE_MUTATION,
  CREATE_VOLVO_API_KEY_MUTATION,
  DELETE_FLEETBOARD_API_KEY_MUTATION,
  DELETE_MAN_API_KEY_MUTATION,
  DELETE_SCANIA_API_KEY_MUTATION,
  DELETE_USER_TYPE_MUTATION,
  DELETE_VOLVO_API_KEY_MUTATION,
  PATCH_FLEETBOARD_API_KEY_MUTATION,
  PATCH_MAN_API_KEY_MUTATION,
  PATCH_MODULE_CONFIG_MUTATION,
  PATCH_MODULE_OPTION_MUTATION,
  PATCH_SALARY_WORK_TIMES,
  PATCH_SCANIA_API_KEY_MUTATION,
  PATCH_USER_TYPE_MUTATION,
  PATCH_VOLVO_API_KEY_MUTATION,
  SWAP_SALARY_WORK_TIMES_PRIORITY,
  UPDATE_COMPANY_USER_TYPES_MUTATION,
  VALIDATE_SCANIA_API_KEY_MUTATION,
  VALIDATE_VOLVO_API_KEY_MUTATION,
} from 'modules/companies/mutations'
import {
  CreateFleetBoardApiKeyMutation,
  CreateFleetBoardApiKeyMutationVariables,
  CreateManApiKeyMutation,
  CreateManApiKeyMutationVariables,
  CreateSalaryWorkTimeMutation,
  CreateSalaryWorkTimeMutationVariables,
  CreateScaniaApiKeyMutation,
  CreateScaniaApiKeyMutationVariables,
  CreateUserTypeMutation,
  CreateUserTypeVariables,
  CreateVolvoApiKeyMutation,
  CreateVolvoApiKeyMutationVariables,
  DeleteFleetBoardApiKeyMutation,
  DeleteManApiKeyMutation,
  DeleteSalaryWorkTimeMutation,
  DeleteScaniaApiKeyMutation,
  DeleteUserTypeMutation,
  DeleteVolvoApiKeyMutation,
  PatchFleetBoardApiKeyMutation,
  PatchFleetBoardApiKeyMutationVariables,
  PatchManApiKeyMutation,
  PatchManApiKeyMutationVariables,
  PatchModuleConfigMutation,
  PatchModuleConfigMutationVariables,
  PatchModuleOptionMutation,
  PatchModuleOptionMutationVariables,
  PatchSalaryWorkTimeMutation,
  PatchSalaryWorkTimeMutationVariables,
  PatchScaniaApiKeyMutation,
  PatchScaniaApiKeyMutationVariables,
  PatchUserTypeMutation,
  PatchUserTypeMutationVariables,
  PatchVolvoApiKeyMutation,
  PatchVolvoApiKeyMutationVariables,
  SwapSalaryWorkTimesPriorityMutation,
  SwapSalaryWorkTimesPriorityMutationVariables,
  UpdateCompanyUserTypesMutation,
  UpdateCompanyUserTypesMutationVariables,
  ValidateScaniaApiKeyMutation,
  ValidateScaniaApiKeyMutationVariables,
  ValidateVolvoApiKeyMutation,
  ValidateVolvoApiKeyMutationVariables,
} from 'modules/companies/types.graphql'
import {
  HANDBOOK_DASHBOARD_LINKS_QUERY,
  PatchManualEntryMutation,
  PatchManualEntryMutationVariables,
  PATCH_MANUAL_ENTRY_MUTATION,
} from 'modules/handbook'
import {
  CreateIssueCategoryMutation,
  CreateIssueCategoryMutationVariables,
  CREATE_ISSUE_CATEGORY_MUTATION,
  DeleteIssueCategoryMutation,
  DELETE_ISSUE_CATEGORY_MUTATION,
  PatchIssueCategoryMutation,
  PatchIssueCategoryMutationVariables,
  PATCH_ISSUE_CATEGORY_MUTATION,
  DeleteIssueRiskAspectMutation,
  PatchIssueRiskAspectMutationVariables,
  PatchIssueRiskAspectMutation,
  CreateIssueRiskAspectMutationVariables,
  CreateIssueRiskAspectMutation,
  CREATE_ISSUE_RISK_ASPECT_MUTATION,
  PATCH_ISSUE_RISK_ASPECT_MUTATION,
  DELETE_ISSUE_RISK_ASPECT_MUTATION,
} from 'modules/issues'
import {
  CreateTerminalMutation,
  CreateTerminalMutationVariables,
  CREATE_TERMINAL_MUTATION,
  DeleteTerminalMutation,
  DELETE_TERMINAL_MUTATION,
  PatchTerminalMutation,
  PatchTerminalMutationVariables,
  PATCH_TERMINAL_MUTATION,
} from 'modules/terminal'
import {
  CreateAbsenceTypeMutation,
  CreateAbsenceTypeMutationVariables,
  CREATE_ABSENCE_TYPE_MUTATION,
  DeleteAbsenceTypeMutation,
  DELETE_ABSENCE_TYPE_MUTATION,
  PatchAbsenceTypeMutation,
  PatchAbsenceTypeMutationVariables,
  PATCH_ABSENCE_TYPE_MUTATION,
  DELETE_TIME_ENTRY_PROJECT_MUTATION,
  CREATE_TIME_ENTRY_PROJECT_MUTATION,
  PATCH_TIME_ENTRY_PROJECT_MUTATION,
  PatchTimeEntryProjectMutation,
  PatchTimeEntryProjectMutationVariables,
  DeleteTimeEntryProjectMutation,
  CreateTimeEntryProjectMutation,
  CreateTimeEntryProjectMutationVariables,
} from 'modules/timesheets'
import {
  CreateUserDocumentCategoryMutation,
  CreateUserDocumentCategoryMutationVariables,
  CreateUserDocumentMutation,
  CreateUserDocumentMutationVariables,
  DeleteUserDocumentCategoryMutation,
  DeleteUserDocumentMutation,
  PatchUserDocumentCategoryMutation,
  PatchUserDocumentCategoryMutationVariables,
  PatchUserDocumentMutation,
  PatchUserDocumentMutationVariables,
} from 'modules/users'
import {
  CREATE_USER_DOCUMENT_CATEGORY_MUTATION,
  CREATE_USER_DOCUMENT_MUTATION,
  DELETE_USER_DOCUMENT_CATEGORY_MUTATION,
  DELETE_USER_DOCUMENT_MUTATION,
  PATCH_USER_DOCUMENT_CATEGORY_MUTATION,
  PATCH_USER_DOCUMENT_MUTATION,
} from 'modules/users/mutations'
import { ALL_USER_DOCUMENT_CATEGORIES_QUERY } from 'modules/users/queries'
import { useHistory } from 'react-router'
import { IdVariable } from 'types/graphql'
import { useOnErrorAuto, useUser } from 'util/hooks'

export function useAbsenceSettingsMutations() {
  const translations = useTranslate({
    success: {
      create: ['settings.toasts.create-absence-type-success', { name: '' }],
      patch: 'settings.toasts.patch-absence-type-success',
      delete: 'settings.toasts.delete-absence-type-success',
    },
    error: {
      create: 'settings.errors.create-absence-type',
      patch: 'settings.errors.patch-absence-type',
      delete: 'settings.errors.delete-absence-type',
    },
  })

  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()

  const [createAbsenceType, { loading: createLoading }] = useMutation<
    CreateAbsenceTypeMutation,
    CreateAbsenceTypeMutationVariables
  >(CREATE_ABSENCE_TYPE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllAbsenceTypes'],
    onCompleted(data) {
      if (!data) {
        addToast('error', translations.error.create)
        return
      }
      const { name } = data.createAbsenceType.absenceType
      addToast('success', translations.success.create({ name }))
    },
    onError: onErrorAuto(translations.error.create),
  })

  const [patchAbsenceType, { loading: patchLoading }] = useMutation<
    PatchAbsenceTypeMutation,
    PatchAbsenceTypeMutationVariables
  >(PATCH_ABSENCE_TYPE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllAbsenceTypes'],
    onCompleted(data) {
      if (!data) {
        addToast('error', translations.error.patch)
        return
      }
      addToast('success', translations.success.patch)
    },
    onError: onErrorAuto(translations.error.patch),
  })

  const [deleteAbsenceType, { loading: deleteLoading }] = useMutation<
    DeleteAbsenceTypeMutation,
    IdVariable
  >(DELETE_ABSENCE_TYPE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllAbsenceTypes'],
    onCompleted(data) {
      if (!data?.deleteAbsenceType.found) {
        addToast('error', translations.error.delete)
        return
      }
      addToast('success', translations.success.delete)
    },
    onError: onErrorAuto(translations.error.delete),
  })

  return {
    create: createAbsenceType,
    patch: patchAbsenceType,
    delete: deleteAbsenceType,
    loading: createLoading || patchLoading || deleteLoading,
  }
}

export function useIssueCategoryMutations() {
  const translations = useTranslate({
    success: {
      create: ['settings.toasts.create-issue-category-success', { name: '' }],
      patch: 'settings.toasts.patch-issue-category-success',
      delete: 'settings.toasts.delete-issue-category-success',
    },
    error: {
      create: 'settings.errors.create-issue-category',
      patch: 'settings.errors.patch-issue-category',
      delete: 'settings.errors.delete-issue-category',
    },
  })

  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()

  const [createIssueCategory, { loading: createLoading }] = useMutation<
    CreateIssueCategoryMutation,
    CreateIssueCategoryMutationVariables
  >(CREATE_ISSUE_CATEGORY_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllIssueDataForSettings'],
    onCompleted(data) {
      if (!data) {
        addToast('error', translations.error.create)
        return
      }
      const { name } = data.createIssueCategory.issueCategory
      addToast('success', translations.success.create({ name }))
    },
    onError: onErrorAuto(translations.error.create),
  })

  const [patchIssueCategory, { loading: patchLoading }] = useMutation<
    PatchIssueCategoryMutation,
    PatchIssueCategoryMutationVariables
  >(PATCH_ISSUE_CATEGORY_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllIssueDataForSettings'],
    onCompleted(data) {
      if (!data) {
        addToast('error', translations.error.patch)
        return
      }
      addToast('success', translations.success.patch)
    },
    onError: onErrorAuto(translations.error.patch),
  })

  const [patchIssueCategoryNoToast, { loading: patchNoToastLoading }] =
    useMutation<
      PatchIssueCategoryMutation,
      PatchIssueCategoryMutationVariables
    >(PATCH_ISSUE_CATEGORY_MUTATION, {
      awaitRefetchQueries: true,
      refetchQueries: ['AllIssueDataForSettings'],
      onCompleted(data) {
        if (!data) {
          addToast('error', translations.error.patch)
          return
        }
      },
      onError: onErrorAuto(translations.error.patch),
    })

  const [deleteIssueCategory, { loading: deleteLoading }] = useMutation<
    DeleteIssueCategoryMutation,
    IdVariable
  >(DELETE_ISSUE_CATEGORY_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllIssueDataForSettings'],
    onCompleted(data) {
      if (!data) {
        addToast('error', translations.error.delete)
        return
      }
      addToast('success', translations.success.delete)
    },
    onError: onErrorAuto(translations.error.delete),
  })

  return {
    create: createIssueCategory,
    patch: (showToast: boolean) =>
      showToast ? patchIssueCategory : patchIssueCategoryNoToast,
    delete: deleteIssueCategory,
    loading:
      createLoading || patchLoading || patchNoToastLoading || deleteLoading,
  }
}

export function useIssueRiskAspectMutations() {
  const translations = useTranslate({
    success: {
      create: ['settings.toasts.create-issue-risk-aspect-success', { name: '' }],
      patch: 'settings.toasts.patch-issue-risk-aspect-success',
      delete: 'settings.toasts.delete-issue-risk-aspect-success',
    },
    error: {
      create: 'settings.errors.create-issue-risk-aspect',
      patch: 'settings.errors.patch-issue-risk-aspect',
      delete: 'settings.errors.delete-issue-risk-aspect',
    },
  })

  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()

  const [createIssueRiskAspect, { loading: createLoading }] = useMutation<
    CreateIssueRiskAspectMutation,
    CreateIssueRiskAspectMutationVariables
  >(CREATE_ISSUE_RISK_ASPECT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllIssueDataForSettings'],
    onCompleted(data) {
      if (!data) {
        addToast('error', translations.error.create)
        return
      }
      const { name } = data.createIssueRiskAspect.issueRiskAspect
      addToast('success', translations.success.create({ name }))
    },
    onError: onErrorAuto(translations.error.create),
  })

  const [patchIssueRiskAspect, { loading: patchLoading }] = useMutation<
    PatchIssueRiskAspectMutation,
    PatchIssueRiskAspectMutationVariables
  >(PATCH_ISSUE_RISK_ASPECT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllIssueDataForSettings'],
    onCompleted(data) {
      if (!data) {
        addToast('error', translations.error.patch)
        return
      }
      addToast('success', translations.success.patch)
    },
    onError: onErrorAuto(translations.error.patch),
  })

  const [deleteIssueRiskAspect, { loading: deleteLoading }] = useMutation<
    DeleteIssueRiskAspectMutation,
    IdVariable
  >(DELETE_ISSUE_RISK_ASPECT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllIssueDataForSettings'],
    onCompleted(data) {
      if (!data) {
        addToast('error', translations.error.delete)
        return
      }
      addToast('success', translations.success.delete)
    },
    onError: onErrorAuto(translations.error.delete),
  })

  return {
    create: createIssueRiskAspect,
    patch: patchIssueRiskAspect,
    delete: deleteIssueRiskAspect,
    loading:
      createLoading || patchLoading || deleteLoading,
  }
}

export function useTerminalMutations() {
  const translations = useTranslate({
    success: {
      create: ['settings.toasts.create-terminal-success', { name: '' }],
      patch: 'settings.toasts.patch-terminal-success',
      delete: 'settings.toasts.delete-terminal-success',
    },
    error: {
      create: 'settings.errors.create-terminal',
      patch: 'settings.errors.patch-terminal',
      delete: 'settings.errors.delete-terminal',
    },
  })

  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()

  const [createTerminal, { loading: createLoading }] = useMutation<
    CreateTerminalMutation,
    CreateTerminalMutationVariables
  >(CREATE_TERMINAL_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllTerminals'],
    onCompleted(data) {
      if (!data) {
        addToast('error', translations.error.create)
        return
      }
      const { name } = data.createTerminal.terminal
      addToast('success', translations.success.create({ name }))
    },
    onError: onErrorAuto(translations.error.create),
  })

  const [patchTerminal, { loading: patchLoading }] = useMutation<
    PatchTerminalMutation,
    PatchTerminalMutationVariables
  >(PATCH_TERMINAL_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllTerminals'],
    onCompleted(data) {
      if (!data) {
        addToast('error', translations.error.patch)
        return
      }
      addToast('success', translations.success.patch)
    },
    onError: onErrorAuto(translations.error.patch),
  })

  const [deleteTerminal, { loading: deleteLoading }] = useMutation<
    DeleteTerminalMutation,
    IdVariable
  >(DELETE_TERMINAL_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllTerminals'],
    onCompleted(data) {
      if (!data || !data.deleteTerminal.found) {
        addToast('error', translations.error.delete)
        return
      }
      addToast('success', translations.success.delete)
    },
    onError: onErrorAuto(translations.error.delete),
  })

  return {
    create: createTerminal,
    patch: patchTerminal,
    delete: deleteTerminal,
    loading: createLoading || patchLoading || deleteLoading,
  }
}

export function usePermissionMutations() {
  const translations = useTranslate({
    success: {
      create: ['settings.toasts.create-user-type-success', { name: '' }],
      update: 'common.changes-saved',
      patch: 'settings.toasts.patch-user-type-success',
      delete: 'settings.toasts.delete-user-type-success',
    },
    error: {
      create: 'settings.errors.create-user-type',
      patch: 'settings.errors.patch-user-type',
      update: 'errors.could-not-save-changes',
      delete: 'settings.errors.delete-user-type',

      userTypeCannotBeEdited: 'settings.errors.user-type-cannot-be-edited',
      userTypeCannotBeDeleted: 'settings.errors.user-type-cannot-be-deleted',
    },
  })

  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()

  const [createUserType, { loading: createUserTypeLoading }] = useMutation<
    CreateUserTypeMutation,
    CreateUserTypeVariables
  >(CREATE_USER_TYPE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['UserTypesWithPermissions', 'Bootstrap'],
    onCompleted(data) {
      if (!data) {
        addToast('error', translations.error.create)
        return
      }
      const { name } = data.createUserType.userType
      addToast('success', translations.success.create({ name }))
    },
    onError: onErrorAuto(translations.error.create),
  })

  const [updateUserTypes, { loading: updateUserTypesLoading }] = useMutation<
    UpdateCompanyUserTypesMutation,
    UpdateCompanyUserTypesMutationVariables
  >(UPDATE_COMPANY_USER_TYPES_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['UserTypesWithPermissions', 'Bootstrap'],
    onCompleted(data) {
      if (!data) {
        addToast('error', translations.error.update)
        return
      }
      addToast('success', translations.success.update)
    },
    onError: onErrorAuto(message =>
      message.includes("can't be edited")
        ? translations.error.userTypeCannotBeEdited
        : translations.error.update
    ),
  })

  const [patchUserType, { loading: patchUserTypesLoading }] = useMutation<
    PatchUserTypeMutation,
    PatchUserTypeMutationVariables
  >(PATCH_USER_TYPE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['UserTypesWithPermissions', 'Bootstrap'],
    onCompleted(data) {
      if (!data) {
        addToast('error', translations.error.patch)
        return
      }
      addToast('success', translations.success.patch)
    },
    onError: onErrorAuto(message =>
      message.includes("can't be edited") ||
      message.includes("can't be deleted")
        ? translations.error.userTypeCannotBeEdited
        : translations.error.patch
    ),
  })

  const [deleteUserType, { loading: deleteUserTypeLoading }] = useMutation<
    DeleteUserTypeMutation,
    IdVariable
  >(DELETE_USER_TYPE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['UserTypesWithPermissions', 'Bootstrap'],
    onCompleted(data) {
      if (!data || !data.deleteUserType.found) {
        addToast('error', translations.error.delete)
        return
      }
      addToast('success', translations.success.delete)
    },
    onError: onErrorAuto(message =>
      message.includes("can't be edited") ||
      message.includes("can't be deleted")
        ? translations.error.userTypeCannotBeDeleted
        : translations.error.delete
    ),
  })

  return {
    create: createUserType,
    update: updateUserTypes,
    patch: patchUserType,
    delete: deleteUserType,
    loading:
      createUserTypeLoading ||
      updateUserTypesLoading ||
      patchUserTypesLoading ||
      deleteUserTypeLoading,
  }
}

export function useSalaryMutations() {
  const translations = useTranslate({
    success: {
      create: 'settings.toasts.create-salary-success',
      patch: 'settings.toasts.patch-salary-success',
      delete: 'settings.toasts.delete-salary-success',
    },
    error: {
      create: 'settings.errors.create-salary',
      patch: 'settings.errors.patch-salary',
      delete: 'settings.errors.delete-salary',
    },
  })

  const user = useUser()
  const history = useHistory()
  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()

  const startMonthYearTime = startOfMonth(new Date())
  const endMonthYearTime = endOfMonth(startMonthYearTime)

  const [createSalaryMutation, { loading: createLoading }] = useMutation<
    CreateSalaryWorkTimeMutation,
    CreateSalaryWorkTimeMutationVariables
  >(CREATE_SALARY_WORK_TIME_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      'AllSalaryModuleConfigs',
      {
        query: DRIVER_ACTIVITY_PERIOD_REPORT_QUERY,
        variables: {
          user: user.id,
          dateStart: formatISO(startMonthYearTime),
          dateEnd: formatISO(endMonthYearTime),
        },
      },
    ],
    onCompleted(data) {
      if (!data.createSalaryWorkTime.id) {
        addToast('error', translations.error.create)
      } else {
        addToast('success', translations.success.create)
      }
      history.goBack()
    },
    onError: onErrorAuto(translations.error.create),
  })

  // Refetch this months driving data for user to keep data up to date
  const [patchSalaryMutation, { loading: patchLoading }] = useMutation<
    PatchSalaryWorkTimeMutation,
    PatchSalaryWorkTimeMutationVariables
  >(PATCH_SALARY_WORK_TIMES, {
    awaitRefetchQueries: true,
    refetchQueries: [
      'AllSalaryModuleConfigs',
      'SalaryWorkTime',
      {
        query: DRIVER_ACTIVITY_PERIOD_REPORT_QUERY,
        variables: {
          user: user.id,
          dateStart: formatISO(startMonthYearTime),
          dateEnd: formatISO(endMonthYearTime),
        },
      },
    ],
    onCompleted(data) {
      if (!data || !data.patchSalaryWorkTime) {
        addToast('error', translations.error.patch)
        return
      }
      addToast('success', translations.success.patch)
      history.goBack()
    },
    onError: onErrorAuto(translations.error.patch),
  })

  const [swapSalaryPriorityMutation, { loading: swapLoading }] = useMutation<
    SwapSalaryWorkTimesPriorityMutation,
    SwapSalaryWorkTimesPriorityMutationVariables
  >(SWAP_SALARY_WORK_TIMES_PRIORITY, {
    awaitRefetchQueries: true,
    refetchQueries: [
      'AllSalaryModuleConfigs',
      {
        query: DRIVER_ACTIVITY_PERIOD_REPORT_QUERY,
        variables: {
          user: user.id,
          dateStart: formatISO(startMonthYearTime),
          dateEnd: formatISO(endMonthYearTime),
        },
      },
    ],
    onCompleted(data) {
      if (!data || !data.swapSalaryWorkTimesPriority.ok) {
        addToast('error', translations.error.patch)
        return
      }
      addToast('success', translations.success.patch)
    },
    onError: onErrorAuto(translations.error.patch),
  })

  const [deleteSalaryMutation, { loading: deleteLoading }] = useMutation<
    DeleteSalaryWorkTimeMutation,
    IdVariable
  >(DELETE_SALARY_WORK_TIME_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      'AllSalaryModuleConfigs',
      {
        query: DRIVER_ACTIVITY_PERIOD_REPORT_QUERY,
        variables: {
          user: user.id,
          dateStart: formatISO(startMonthYearTime),
          dateEnd: formatISO(endMonthYearTime),
        },
      },
    ],
    onCompleted() {
      addToast('success', translations.success.delete)
    },
    onError: onErrorAuto(translations.error.delete),
  })

  return {
    create: createSalaryMutation,
    patch: patchSalaryMutation,
    swap: swapSalaryPriorityMutation,
    delete: deleteSalaryMutation,
    loading: patchLoading || deleteLoading || createLoading || swapLoading,
  }
}

export function useIntegrationsMutations() {
  const translations = useTranslate({
    volvo: {
      success: {
        create: 'settings.toasts.create-volvo-integration-success',
        patch: 'settings.toasts.patch-volvo-integration-success',
        delete: 'settings.toasts.delete-volvo-integration-success',
      },
      error: {
        create: 'settings.errors.create-volvo-integration',
        patch: 'settings.errors.patch-volvo-integration',
        delete: 'settings.errors.delete-volvo-integration',
      },
    },
    fleetBoard: {
      success: {
        create: 'settings.toasts.create-fleetboard-integration-success',
        patch: 'settings.toasts.patch-fleetboard-integration-success',
        delete: 'settings.toasts.delete-fleetboard-integration-success',
      },
      error: {
        create: 'settings.errors.create-fleetboard-integration',
        patch: 'settings.errors.patch-fleetboard-integration',
        delete: 'settings.errors.delete-fleetboard-integration',
      },
    },
    scania: {
      success: {
        create: 'settings.toasts.create-scania-integration-success',
        patch: 'settings.toasts.patch-scania-integration-success',
        delete: 'settings.toasts.delete-scania-integration-success',
      },
      error: {
        create: 'settings.errors.create-scania-integration',
        patch: 'settings.errors.patch-scania-integration',
        delete: 'settings.errors.delete-scania-integration',
      },
    },
    man: {
      success: {
        create: 'settings.toast.create-man-integration-success',
        patch: 'settings.toast.patch-man-integration-success',
        delete: 'settings.toast.delete-man-integration-success',
      },
      error: {
        create: 'settings.toast.create-man-integration-error',
        patch: 'settings.toast.patch-man-integration-error',
        delete: 'settings.toast.delete-man-integration-error',
      },
    }
  })

  const addToast = useToast()

  const [createVolvoMutation, { loading: createVolvoLoading }] = useMutation<
    CreateVolvoApiKeyMutation,
    CreateVolvoApiKeyMutationVariables
  >(CREATE_VOLVO_API_KEY_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllApiKeys'],
    onCompleted() {
      addToast('success', translations.volvo.success.create)
    },
    onError() {
      addToast('error', translations.volvo.error.create)
    },
  })

  const [patchVolvoMutation, { loading: patchVolvoLoading }] = useMutation<
    PatchVolvoApiKeyMutation,
    PatchVolvoApiKeyMutationVariables
  >(PATCH_VOLVO_API_KEY_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllApiKeys'],
    onCompleted() {
      addToast('success', translations.volvo.success.patch)
    },
    onError() {
      addToast('error', translations.volvo.error.patch)
    },
  })

  const [deleteVolvoMutation, { loading: deleteVolvoLoading }] = useMutation<
    DeleteVolvoApiKeyMutation,
    IdVariable
  >(DELETE_VOLVO_API_KEY_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllApiKeys'],
    onCompleted() {
      addToast('success', translations.volvo.success.delete)
    },
    onError() {
      addToast('error', translations.volvo.error.delete)
    },
  })

  const [createFleetBoardMutation, { loading: createFleetBoardLoading }] = useMutation<
    CreateFleetBoardApiKeyMutation,
    CreateFleetBoardApiKeyMutationVariables
  >(CREATE_FLEETBOARD_API_KEY_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllApiKeys'],
    onCompleted() {
      addToast('success', translations.fleetBoard.success.create)
    },
    onError() {
      addToast('error', translations.fleetBoard.error.create)
    },
  })

  const [patchFleetBoardMutation, { loading: patchFleetBoardLoading }] = useMutation<
    PatchFleetBoardApiKeyMutation,
    PatchFleetBoardApiKeyMutationVariables
  >(PATCH_FLEETBOARD_API_KEY_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllApiKeys'],
    onCompleted() {
      addToast('success', translations.fleetBoard.success.patch)
    },
    onError() {
      addToast('error', translations.fleetBoard.error.patch)
    },
  })

  const [deleteFleetBoardMutation, { loading: deleteFleetBoardLoading }] = useMutation<
    DeleteFleetBoardApiKeyMutation,
    IdVariable
  >(DELETE_FLEETBOARD_API_KEY_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllApiKeys'],
    onCompleted() {
      addToast('success', translations.fleetBoard.success.delete)
    },
    onError() {
      addToast('error', translations.fleetBoard.error.delete)
    },
  })

  const [createScaniaMutation, { loading: createScaniaLoading }] = useMutation<
    CreateScaniaApiKeyMutation,
    CreateScaniaApiKeyMutationVariables
  >(CREATE_SCANIA_API_KEY_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllApiKeys'],
    onCompleted() {
      addToast('success', translations.scania.success.create)
    },
    onError() {
      addToast('error', translations.scania.error.create)
    },
  })

  const [patchScaniaMutation, { loading: patchScaniaLoading }] = useMutation<
    PatchScaniaApiKeyMutation,
    PatchScaniaApiKeyMutationVariables
  >(PATCH_SCANIA_API_KEY_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllApiKeys'],
    onCompleted() {
      addToast('success', translations.scania.success.patch)
    },
    onError() {
      addToast('error', translations.scania.error.patch)
    },
  })

  const [deleteScaniaMutation, { loading: deleteScaniaLoading }] = useMutation<
    DeleteScaniaApiKeyMutation,
    IdVariable
  >(DELETE_SCANIA_API_KEY_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllApiKeys'],
    onCompleted() {
      addToast('success', translations.scania.success.delete)
    },
    onError() {
      addToast('error', translations.scania.error.delete)
    },
  })

  const [createManMutation, { loading: createManLoading }] = useMutation<
    CreateManApiKeyMutation,
    CreateManApiKeyMutationVariables
  >(CREATE_MAN_API_KEY_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllApiKeys'],
    onCompleted() {
      addToast('success', translations.man.success.create)
    },
    onError() {
      addToast('error', translations.man.error.create)
    }
  })

  const [patchManMutation, { loading: patchManLoading }] = useMutation<
    PatchManApiKeyMutation,
    PatchManApiKeyMutationVariables
    >(PATCH_MAN_API_KEY_MUTATION, {
      awaitRefetchQueries: true,
      refetchQueries: ['AllApiKeys'],
      onCompleted() {
        addToast('success', translations.man.success.patch)
      },
      onError() {
        addToast('error', translations.man.error.patch)
      },
    })

  const [deleteManMutation, { loading: deleteManLoading }] = useMutation<
    DeleteManApiKeyMutation,
    IdVariable
    >(DELETE_MAN_API_KEY_MUTATION, {
      awaitRefetchQueries: true,
      refetchQueries: ['AllApiKeys'],
      onCompleted() {
        addToast('success', translations.man.success.delete)
      }
    })

  const [validateScaniaMutation, { loading: validateScaniaLoading }] =
    useMutation<
      ValidateScaniaApiKeyMutation,
      ValidateScaniaApiKeyMutationVariables
    >(VALIDATE_SCANIA_API_KEY_MUTATION, {
      onError: () => void 0,
    })

  const [validateVolvoMutation, { loading: validateVolvoLoading }] =
    useMutation<
      ValidateVolvoApiKeyMutation,
      ValidateVolvoApiKeyMutationVariables
    >(VALIDATE_VOLVO_API_KEY_MUTATION, {
      onError: () => void 0,
    })

  return {
    create: {
      scania: createScaniaMutation,
      volvo: createVolvoMutation,
      fleetBoard: createFleetBoardMutation,
      man: createManMutation,
    },
    patch: {
      scania: patchScaniaMutation,
      volvo: patchVolvoMutation,
      fleetBoard: patchFleetBoardMutation,
      man: patchManMutation,
    },
    delete: {
      scania: deleteScaniaMutation,
      volvo: deleteVolvoMutation,
      fleetBoard: deleteFleetBoardMutation,
      man: deleteManMutation,
    },
    validate: {
      scania: validateScaniaMutation,
      volvo: validateVolvoMutation,
    },
    loading:
      createVolvoLoading ||
      patchVolvoLoading ||
      deleteVolvoLoading ||
      createScaniaLoading ||
      patchScaniaLoading ||
      deleteScaniaLoading ||
      validateScaniaLoading ||
      validateVolvoLoading ||
      createFleetBoardLoading ||
      patchFleetBoardLoading ||
      deleteFleetBoardLoading ||
      createManLoading ||
      patchManLoading ||
      deleteManLoading,
  }
}

export function useUserDocumentMutations() {
  const translations = useTranslate({
    success: {
      create: 'settings.toasts.create-user-document-success',
      patch: 'settings.toasts.patch-user-document-success',
      delete: 'settings.toasts.delete-user-document-success',
    },
    error: {
      create: 'settings.errors.create-user-document',
      patch: 'settings.errors.patch-user-document',
      delete: 'settings.errors.delete-user-document',
    },
  })

  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()

  const [createUserDocumentMutation, { loading: createLoading }] = useMutation<
    CreateUserDocumentMutation,
    CreateUserDocumentMutationVariables
  >(CREATE_USER_DOCUMENT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllUserDocuments', 'AllUserDocumentCategories'],
    onCompleted(data) {
      if (!data) {
        addToast('error', translations.error.create)
        return
      }
      addToast('success', translations.success.create)
    },
    onError: onErrorAuto(translations.error.create),
  })
  const [patchUserDocumentMutation, { loading: patchLoading }] = useMutation<
    PatchUserDocumentMutation,
    PatchUserDocumentMutationVariables
  >(PATCH_USER_DOCUMENT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllUserDocuments', 'AllUserDocumentCategories'],
    onCompleted(data) {
      if (!data) {
        addToast('error', translations.error.patch)
        return
      }
      addToast('success', translations.success.patch)
    },
    onError: onErrorAuto(translations.error.patch),
  })
  const [deleteUserDocumentMutation, { loading: deleteLoading }] = useMutation<
    DeleteUserDocumentMutation,
    IdVariable
  >(DELETE_USER_DOCUMENT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllUserDocuments', 'AllUserDocumentCategories'],
    onCompleted(data) {
      if (!data) {
        addToast('error', translations.error.delete)
        return
      }
      addToast('success', translations.success.delete)
    },
    onError: onErrorAuto(translations.error.delete),
  })

  return {
    create: createUserDocumentMutation,
    patch: patchUserDocumentMutation,
    delete: deleteUserDocumentMutation,
    loading: createLoading || patchLoading || deleteLoading,
  }
}

export function useUserDocumentCategoryMutations() {
  const translations = useTranslate({
    success: {
      create: 'settings.toasts.create-user-document-category-success',
      patch: 'settings.toasts.patch-user-document-category-success',
      delete: 'settings.toasts.delete-user-document-category-success',
    },
    error: {
      create: 'settings.errors.create-user-document-category',
      patch: 'settings.errors.patch-user-document-category',
      delete: 'settings.errors.delete-user-document-category',
    },
  })

  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()

  const [createUserDocumentCategoryMutation, { loading: createLoading }] =
    useMutation<CreateUserDocumentCategoryMutation, CreateUserDocumentCategoryMutationVariables>(
      CREATE_USER_DOCUMENT_CATEGORY_MUTATION,
      {
        awaitRefetchQueries: true,
        refetchQueries: [{query: ALL_USER_DOCUMENT_CATEGORIES_QUERY}],
        onCompleted(data) {
          if (!data) {
            addToast('error', translations.error.create)
            return
          }
          addToast('success', translations.success.create)
        },
        onError: onErrorAuto(translations.error.create),
      }
    )
  const [patchUserDocumentCategoryMutation, { loading: patchLoading }] =
    useMutation<PatchUserDocumentCategoryMutation, PatchUserDocumentCategoryMutationVariables>(
      PATCH_USER_DOCUMENT_CATEGORY_MUTATION,
      {
        awaitRefetchQueries: true,
        refetchQueries: [{query: ALL_USER_DOCUMENT_CATEGORIES_QUERY}],
        onCompleted(data) {
          if (!data) {
            addToast('error', translations.error.patch)
            return
          }
          addToast('success', translations.success.patch)
        },
        onError: onErrorAuto(translations.error.patch),
      }
    )
  const [deleteUserDocumentCategoryMutation, { loading: deleteLoading }] =
    useMutation<DeleteUserDocumentCategoryMutation, IdVariable>(
      DELETE_USER_DOCUMENT_CATEGORY_MUTATION,
      {
        awaitRefetchQueries: true,
        refetchQueries: [{query: ALL_USER_DOCUMENT_CATEGORIES_QUERY}],
        onCompleted(data) {
          if (!data) {
            addToast('error', translations.error.delete)
            return
          }
          addToast('success', translations.success.delete)
        },
        onError: onErrorAuto(translations.error.delete),
      }
    )

  return {
    create: createUserDocumentCategoryMutation,
    patch: patchUserDocumentCategoryMutation,
    delete: deleteUserDocumentCategoryMutation,
    loading: createLoading || patchLoading || deleteLoading,
  }
}

export function useChecklistTemplateMutations() {
  const translations = useTranslate({
    success: {
      create: 'settings.toasts.create-checklist-template-success',
      patch: 'settings.toasts.patch-checklist-template-success',
    },
    error: {
      create: 'settings.errors.create-checklist-template-error',
      patch: 'settings.errors.patch-checklist-template-error',
    },
  })
  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()

  const [deleteChecklistTemplateMutation, { loading: deleteLoading }] =
    useMutation(DELETE_CHECKLIST_TEMPLATE_MUTATION, {
      refetchQueries: ['AllChecklistTemplates', 'AllChecklists'],
    })

  const [createChecklistTemplateMutation, { loading: createLoading }] =
    useMutation<
      CreateChecklistTemplateMutation,
      CreateChecklistTemplateMutationVariables
    >(CREATE_CHECKLIST_TEMPLATE_MUTATION, {
      refetchQueries: ['AllChecklistTemplates', { query: USER_CHECKLISTS }],
      onCompleted() {
        addToast('success', translations.success.create)
      },
      onError: onErrorAuto(translations.error.create),
    })

  const [patchChecklistTemplateMutation, { loading: patchLoading }] =
    useMutation<{}, PatchChecklistTemplateMutationVariables>(
      PATCH_CHECKLIST_TEMPLATE_MUTATION,
      {
        refetchQueries: ['AllChecklistTemplates', { query: USER_CHECKLISTS }],
        onCompleted() {
          addToast('success', translations.success.patch)
        },
        onError: onErrorAuto(translations.error.patch),
      }
    )
  return {
    delete: deleteChecklistTemplateMutation,
    create: createChecklistTemplateMutation,
    patch: patchChecklistTemplateMutation,
    loading: createLoading || deleteLoading || patchLoading,
  }
}

export function useModuleConfigMutations() {
  const translations = useTranslate({
    success: 'settings.toasts.company-settings-updated',
    error: 'settings.errors.company-settings-generic',
  })
  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()

  const [patchModuleConfig] = useMutation<
    PatchModuleConfigMutation,
    PatchModuleConfigMutationVariables
  >(PATCH_MODULE_CONFIG_MUTATION, {
    refetchQueries: ['Bootstrap', 'ModuleConfig'],
    onCompleted: () => {
      addToast('success', translations.success)
    },
    onError: onErrorAuto(translations.error),
  })

  return {
    patch: patchModuleConfig,
  }
}

export function useModuleOptionMutations() {
  const translations = useTranslate({
    success: 'settings.toasts.company-settings-updated',
    error: 'settings.errors.company-settings-generic',
  })
  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()

  const [patchModuleOption] = useMutation<
    PatchModuleOptionMutation,
    PatchModuleOptionMutationVariables
  >(PATCH_MODULE_OPTION_MUTATION, {
    refetchQueries: [{ query: ALL_MODULE_CONFIG_QUERYS_SHALLOW }],
    onCompleted: () => {
      addToast('success', translations.success)
    },
    onError: onErrorAuto(translations.error),
  })

  return {
    patch: patchModuleOption,
  }
}

export function useHandbookLinkMutations() {
  const translations = useTranslate({
    success: 'settings.toasts.company-settings-updated',
    error: 'settings.errors.company-settings-generic',
  })
  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()

  const [patchManualEntry] = useMutation<
    PatchManualEntryMutation,
    PatchManualEntryMutationVariables
  >(PATCH_MANUAL_ENTRY_MUTATION, {
    refetchQueries: [
      { query: HANDBOOK_DASHBOARD_LINKS_QUERY },
      {
        query: HANDBOOK_DASHBOARD_LINKS_QUERY,
        variables: { isDashboardLink: true },
      },
    ],
    onCompleted: () => {
      addToast('success', translations.success)
    },
    onError: onErrorAuto(translations.error),
  })

  return {
    patch: patchManualEntry,
  }
}

export function useTimesheetSettingsMutations() {
  const translations = useTranslate({
    success: {
      create: [
        'settings.toasts.create-time-entry-project-success',
        { name: '' },
      ],
      patch: 'settings.toasts.patch-time-entry-project-success',
      delete: 'settings.toasts.delete-time-entry-project-success',
    },
    error: {
      create: 'settings.errors.create-time-entry-project',
      patch: 'settings.errors.patch-time-entry-project',
      delete: 'settings.errors.delete-time-entry-project',
    },
  })

  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()

  const [createTimeEntryProject, { loading: createLoading }] = useMutation<
    CreateTimeEntryProjectMutation,
    CreateTimeEntryProjectMutationVariables
  >(CREATE_TIME_ENTRY_PROJECT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllTimeEntryProjects'],
    onCompleted(data) {
      if (!data) {
        addToast('error', translations.error.create)
        return
      }
      const { name } = data.createTimeEntryProject.timeEntryProject
      addToast('success', translations.success.create({ name: name }))
    },
    onError: onErrorAuto(translations.error.create),
  })

  const [patchTimeEntryProject, { loading: patchLoading }] = useMutation<
    PatchTimeEntryProjectMutation,
    PatchTimeEntryProjectMutationVariables
  >(PATCH_TIME_ENTRY_PROJECT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllTimeEntryProjects'],
    onCompleted(data) {
      if (!data) {
        addToast('error', translations.error.patch)
        return
      }
      addToast('success', translations.success.patch)
    },
    onError: onErrorAuto(translations.error.patch),
  })

  const [deleteTimeEntryProject, { loading: deleteLoading }] = useMutation<
    DeleteTimeEntryProjectMutation,
    IdVariable
  >(DELETE_TIME_ENTRY_PROJECT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllTimeEntryProjects'],
    onCompleted(data) {
      if (!data?.deleteTimeEntryProject.found) {
        addToast('error', translations.error.delete)
        return
      }
      addToast('success', translations.success.delete)
    },
    onError: onErrorAuto(translations.error.delete),
  })

  return {
    create: createTimeEntryProject,
    patch: patchTimeEntryProject,
    delete: deleteTimeEntryProject,
    loading: createLoading || patchLoading || deleteLoading,
  }
}
