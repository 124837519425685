import { useTranslate } from '@ur/react-hooks'
import { Checkbox } from 'components'
import { FilterChildren, FilterPopup } from 'components/FilterPopup'
import {
  FilterCheckmarksWrapper,
  Status,
  StatusButton,
  StyledScrollBar,
} from 'components/FilterPopup/components'
import xor from 'lodash/xor'
import React from 'react'
import { InfringementCategoryIdentifiers, InfringementSeverities } from '../consts'
import { InfringementsTableFilter, SeverityCategory } from '../types'
import { useInfringementSeverityFunctions, useInfringementCategoryFunctions } from '../util'

export const InfringementPopupFilter: React.VFC<
  FilterChildren<InfringementsTableFilter>
> = ({ filter, onUpdate }) => {
  const translations = useTranslate({
    archived: 'common.archived',
    status: 'common.status',
    open: 'common.open-alt',

    severity: 'infringements.severity',
    categories: 'common.categories',

    minorInfringement: 'infringements.minor-infringement',
    seriousInfringement: 'infringements.serious-infringement',
    verySeriousInfringement: 'infringements.very-serious-infringement',
    mostSeriousInfringement: 'infringements.most-serious-infringement',
    infringementPoints: ['infringements.n-points', { n: 0 }],
  })
  const severities = Object.keys(InfringementSeverities) as SeverityCategory[]
  const categoryIdentifiers: InfringementCategoryIdentifiers[] = [
    'B',
    'C',
    'D',
  ]

  function toggleArchived(archived: boolean) {
    return () =>
      onUpdate({
        archived,
      })
  }
  function toggleSeverity(severity: SeverityCategory) {
    return () =>
      onUpdate({
        excludeSeverities: xor(filter.excludeSeverities, [
          severity,
        ]) as SeverityCategory[],
      })
  }

  function toggleCategories(category: InfringementCategoryIdentifiers) {
    return () => {
      if (filter.excludeCategories.map(category => category[0]).includes(category)) {
        const newCategories = filter.excludeCategories.filter(cat => cat[0] !== category)
        onUpdate({
          excludeCategories: newCategories
        })
      }
      else {
        const newCategories = [...filter.excludeCategories, ...getCategoriesFromIdentifier(category)]
        onUpdate({
          excludeCategories: newCategories
        })
      }
    }
  }

  const { getSeverityTranslation, getSeverityValue } = useInfringementSeverityFunctions()


  const { getCategoryTranslation, getCategoriesFromIdentifier } = useInfringementCategoryFunctions()

  return (
    <FilterPopup>
      <h4>{translations.status}</h4>
      <Status statusCount={2}>
        <StatusButton
          active={filter.archived === false}
          onClick={toggleArchived(false)}
        >
          {translations.open}
        </StatusButton>
        <StatusButton
          active={filter.archived === true}
          onClick={toggleArchived(true)}
        >
          {translations.archived}
        </StatusButton>
      </Status>

      <h4>{translations.severity}</h4>

      <FilterCheckmarksWrapper>
        <StyledScrollBar>
          {severities.map(severity => {
            const checked = !filter.excludeSeverities?.includes(severity)
            const label = `${getSeverityTranslation(
              severity
            )} (${translations.infringementPoints({
              n: getSeverityValue(severity),
            })})`
            return (
              <Checkbox
                key={severity}
                checked={checked}
                label={label}
                boxWidth="24px"
                boxHeight="24px"
                fontSize="0.8rem"
                onChange={toggleSeverity(severity)}
              />
            )
          })}
        </StyledScrollBar>
      </FilterCheckmarksWrapper>

      <h4>{translations.categories}</h4>

      <FilterCheckmarksWrapper>
        <StyledScrollBar>
          {categoryIdentifiers.map(categoryIdentifier => {
            const checked = !filter.excludeCategories.map(category => category[0]).includes(categoryIdentifier)
            const label = getCategoryTranslation(categoryIdentifier)
            return (
              <Checkbox
                key={categoryIdentifier}
                checked={checked}
                label={label}
                boxWidth="24px"
                boxHeight="24px"
                fontSize="0.8rem"
                onChange={toggleCategories(categoryIdentifier)}
              />
            )
          })}
        </StyledScrollBar>
      </FilterCheckmarksWrapper>


    </FilterPopup>
  )
}
