import { useCallback, useMemo } from 'react'
import { UserNode } from 'modules/users'
import { useUser } from './useUser'
import { useAdmin } from './useAdmin'
import { OnlyRequired } from 'types/util'

export function usePermissions() {
  const me = useUser()
  const admin = useAdmin()

  const hasPermissions = useCallback(
    (permissions: string | string[], allowAdmin: boolean = false) =>
      me.isSuperuser ||
      (allowAdmin && admin) ||
      ([] as string[])
        .concat(permissions)
        .every(perm => me.allPermissions.includes(perm)),
    [me.allPermissions, me.isSuperuser, admin]
  )

  const hasPermissionsOrMe = useCallback(
    (user: Pick<UserNode, 'id'> | null, ...permissions: string[]) =>
      user && (hasPermissions(permissions) || user.id === me.id),
    [me.id, hasPermissions]
  )

  const hasPermissionsAndMe = useCallback(
    (user: Pick<UserNode, 'id'> | null, ...permissions: string[]) =>
      user && hasPermissions(permissions) && user.id === me.id,
    [me.id, hasPermissions]
  )

  const perms = useMemo(
    () => ({
      permissions: me.allPermissions,
      hasPermissions,
      hasPermissionsOrMe,
      hasPermissionsAndMe,
    }),
    [hasPermissions, hasPermissionsOrMe, hasPermissionsAndMe, me.allPermissions]
  )

  return perms
}

export function useProfileAccess(user: OnlyRequired<UserNode, 'id'>) {
  const me = useUser()
  const admin = useAdmin()
  const myProfile = user.id === me.id
  const canAccessProfile = admin || myProfile

  return canAccessProfile
}
