import { useTranslate } from '@ur/react-hooks'
import { Module } from 'modules/companies/consts'
import React from 'react'
import {
  InfoBox,
  SettingsWrapper,
} from '../components'
import { ModuleResponsibleUsers } from '../components/ModuleResponsibleUsers'


interface VehicleFleetSettingsProps {}

export const VehicleFleetSettings: React.VFC<VehicleFleetSettingsProps> = () => {
  const translations = useTranslate({
    info: 'settings.info.vehicles',
  })



  return (
    <SettingsWrapper grid={{ flow: 'row' }}>
        <InfoBox>
        <p>{translations.info}</p>
      </InfoBox>

      <ModuleResponsibleUsers module={Module.VEHICLES} />
    </SettingsWrapper>
  )
}
