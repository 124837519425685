import { useMutation } from '@apollo/client'
import { useToast } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { useOnErrorAuto } from 'util/hooks'
import {
  ALL_ROUTE_PLANS_QUERY,
  CreateRoutePlanMutation,
  CreateRoutePlanMutationInput,
  CREATE_ROUTE_PLAN_MUTATION,
  DELETE_ROUTE_PLAN_MUTATION,
  ExportRouteplanMutation,
  ExportRouteplanMutationVariables,
  EXPORT_ROUTE_PLAN_MUTATION,
} from '.'

export const useRoutePlannerMutations = () => {
  const translations = useTranslate({
    deleteSucces: 'route-planner.delete-route-plan-success',
    createSuccess: 'route-planner.create-route-plan-success',
  })

  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()

  const [createMutation, { loading: createLoading }] = useMutation<
    CreateRoutePlanMutation,
    CreateRoutePlanMutationInput
  >(CREATE_ROUTE_PLAN_MUTATION, {
    refetchQueries: [{ query: ALL_ROUTE_PLANS_QUERY }],
    awaitRefetchQueries: true,
    onCompleted: () => {
      addToast('success', translations.createSuccess)
    },
    onError: onErrorAuto(),
  })

  const [deleteMutation, { loading: deleteLoading }] = useMutation(
    DELETE_ROUTE_PLAN_MUTATION,
    {
      refetchQueries: [{ query: ALL_ROUTE_PLANS_QUERY }],
      awaitRefetchQueries: true,
      onCompleted: () => {
        addToast('success', translations.deleteSucces)
      },
    }
  )

  const [exportRoutePlanMutation, { loading: exportLoading }] = useMutation<
    ExportRouteplanMutation,
    ExportRouteplanMutationVariables
  >(EXPORT_ROUTE_PLAN_MUTATION)

  return {
    createMutation,
    createLoading,
    deleteMutation,
    deleteLoading,
    exportRoutePlanMutation,
    exportLoading,
  }
}
