import { SelectOption } from '@ur/react-components'
import { useDebounce, useTranslate } from '@ur/react-hooks'
import React, { useCallback, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { DropdownFilter } from 'types/graphql/enums'
import { DEFAULT_PAGE_SIZE } from 'util/pagination'
import { Select, SelectProps as BaseSelectProps } from './Select'
import { ALL_SALARY_CODES_QUERY, AllSalaryCodesQuery, AllSalaryCodesQueryVariables, SalaryCodeNode } from 'modules/companies'

interface SalaryCodeSelectProps extends Omit<BaseSelectProps<string, SalaryCodeNode>, 'options'>{
  alwaysInclude?: SalaryCodeNode
  dropdownPageName?: DropdownFilter
  isDiet?: boolean
  showInManualTimeEntryOvertime?: boolean
}

export const SalaryCodeSelect: React.VFC<SalaryCodeSelectProps> = ({
  alwaysInclude = null,
  isDiet = false,
  showInManualTimeEntryOvertime = false,
  dropdownPageName,
  ...selectProps
}) => {
  const { placeholder } = useTranslate({
    placeholder: 'common.select-salary-code',
  })

  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query)
  const { data, loading, fetchMore } = useQuery<AllSalaryCodesQuery, AllSalaryCodesQueryVariables>(
    ALL_SALARY_CODES_QUERY, {
      variables: {
        isDiet: isDiet,
        showInManualTimeEntryOvertime: showInManualTimeEntryOvertime,
      },
    }
  )

  const getLabel = useCallback((project: SalaryCodeNode) => {
    return <span className="name">{project.name}</span>
  }, [])

  const options = useMemo<SelectOption<string, SalaryCodeNode>[]>(() => {
    if (!data) return []

    const options = data.allSalaryCodes.edges.map(
      ({ node: salaryCode }) => ({
        value: salaryCode.id,
        label: getLabel(salaryCode),
        extra: salaryCode,
      })
    )
    if (alwaysInclude === null) return options

    return [
      {
        value: alwaysInclude.id,
        label: getLabel(alwaysInclude),
        extra: alwaysInclude,
      },
      ...options.filter(option => option.extra.id !== alwaysInclude.id),
    ]
  }, [alwaysInclude, data, getLabel])

  /**
   * Whenever the user select gets close to the bottom, refetch.
   * @remarks Almost a complete copy of `handleScrollBottom` in `UsersTable.tsx`. Might want to refactor to a hook?
   */
  const handleFetchMore = useCallback(async () => {
    if (typeof data === 'undefined') return
    if (loading || data.allSalaryCodes.pageInfo?.endCursor === null)
      return
    try {
      await fetchMore({
        variables: {
          first: DEFAULT_PAGE_SIZE,
          q: debouncedQuery,
          orderBy: 'name',
          after: data.allSalaryCodes.pageInfo?.endCursor,
        },
      })
    } catch (e) {
      //@ts-ignore
      if (e.name === 'Invariant Violation') return
      throw e
    }
  }, [data, fetchMore, debouncedQuery, loading])

  return (
    <Select
      options={options}
      loading={loading}
      placeholder={placeholder}
      searchable={false}
      filterBackground="white"
      optionHoverBackground="white"
      onFilterChange={setQuery}
      onFetchMore={handleFetchMore}
      {...selectProps}
    />
  )
}
