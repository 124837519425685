import { Loader } from '@ur/react-components'

type LoaderElement = string | number | JSX.Element | null | undefined

export const unlessLoadingDots = (loading: boolean, unknownText: string) => {
  return (element: LoaderElement) =>
    loading ? (
      <Loader.Dots size={32} />
    ) : (
      element ?? <span className="unknown">{unknownText}</span>
    )
}
