export const PERMISSIONS = {
  activities: {
    add: {
      driveractivity: 'activities.add_driveractivity',
      driverlocation: 'activities.add_driverlocation',
      userdatestartendlocation: 'activities.add_userdatestartendlocation',
    },
    change: {
      driveractivity: 'activities.change_driveractivity',
      driverlocation: 'activities.change_driverlocation',
      userdatestartendlocation: 'activities.change_userdatestartendlocation',
    },
    delete: {
      driveractivity: 'activities.delete_driveractivity',
      driverlocation: 'activities.delete_driverlocation',
      userdatestartendlocation: 'activities.delete_userdatestartendlocation',
    },
    view: {
      driveractivity: 'activities.view_driveractivity',
      driverlocation: 'activities.view_driverlocation',
      userdatestartendlocation: 'activities.view_userdatestartendlocation',
    },
    approve: {
      driveractivity: 'activities.approve_driveractivity',
      othersdriveractivity: 'activities.approve_othersdriveractivity',
    },
  },
  admin: {
    add: {
      logentry: 'admin.add_logentry',
    },
    change: {
      logentry: 'admin.change_logentry',
    },
    delete: {
      logentry: 'admin.delete_logentry',
    },
    view: {
      logentry: 'admin.view_logentry',
    },
  },
  auth: {
    add: {
      group: 'auth.add_group',
      permission: 'auth.add_permission',
    },
    change: {
      group: 'auth.change_group',
      permission: 'auth.change_permission',
    },
    delete: {
      group: 'auth.delete_group',
      permission: 'auth.delete_permission',
    },
    view: {
      group: 'auth.view_group',
      permission: 'auth.view_permission',
    },
  },
  checklists: {
    change: {
      checklisttemplate: 'checklists.change_checklisttemplate',
    },
    add: {
      checklisttemplate: 'checklists.add_checklisttemplate',
    },
  },
  companies: {
    add: {
      company: 'companies.add_company',
      companyapikey: 'companies.add_companyapikey',
      companysetting: 'companies.add_companysetting',
    },
    change: {
      company: 'companies.change_company',
      company_general_settings: 'companies.change_company_general_settings',
      company_sms_settings: 'companies.change_company_sms_settings',
      companyapikey: 'companies.change_companyapikey',
      companysetting: 'companies.change_companysetting',
    },
    delete: {
      company: 'companies.delete_company',
      companyapikey: 'companies.delete_companyapikey',
      companysetting: 'companies.delete_companysetting',
    },
    view: {
      company: 'companies.view_company',
      companyapikey: 'companies.view_companyapikey',
      companysetting: 'companies.view_companysetting',
    },
    export: {
      exportReports: 'companies.export_reports',
    },
  },
  contenttypes: {
    add: {
      contenttype: 'contenttypes.add_contenttype',
    },
    change: {
      contenttype: 'contenttypes.change_contenttype',
    },
    delete: {
      contenttype: 'contenttypes.delete_contenttype',
    },
    view: {
      contenttype: 'contenttypes.view_contenttype',
    },
  },
  django_celery_beat: {
    add: {
      clockedschedule: 'django_celery_beat.add_clockedschedule',
      crontabschedule: 'django_celery_beat.add_crontabschedule',
      intervalschedule: 'django_celery_beat.add_intervalschedule',
      periodictask: 'django_celery_beat.add_periodictask',
      periodictasks: 'django_celery_beat.add_periodictasks',
      solarschedule: 'django_celery_beat.add_solarschedule',
    },
    change: {
      clockedschedule: 'django_celery_beat.change_clockedschedule',
      crontabschedule: 'django_celery_beat.change_crontabschedule',
      intervalschedule: 'django_celery_beat.change_intervalschedule',
      periodictask: 'django_celery_beat.change_periodictask',
      periodictasks: 'django_celery_beat.change_periodictasks',
      solarschedule: 'django_celery_beat.change_solarschedule',
    },
    delete: {
      clockedschedule: 'django_celery_beat.delete_clockedschedule',
      crontabschedule: 'django_celery_beat.delete_crontabschedule',
      intervalschedule: 'django_celery_beat.delete_intervalschedule',
      periodictask: 'django_celery_beat.delete_periodictask',
      periodictasks: 'django_celery_beat.delete_periodictasks',
      solarschedule: 'django_celery_beat.delete_solarschedule',
    },
    view: {
      clockedschedule: 'django_celery_beat.view_clockedschedule',
      crontabschedule: 'django_celery_beat.view_crontabschedule',
      intervalschedule: 'django_celery_beat.view_intervalschedule',
      periodictask: 'django_celery_beat.view_periodictask',
      periodictasks: 'django_celery_beat.view_periodictasks',
      solarschedule: 'django_celery_beat.view_solarschedule',
    },
  },
  django_celery_results: {
    add: {
      taskresult: 'django_celery_results.add_taskresult',
    },
    change: {
      taskresult: 'django_celery_results.change_taskresult',
    },
    delete: {
      taskresult: 'django_celery_results.delete_taskresult',
    },
    view: {
      taskresult: 'django_celery_results.view_taskresult',
    },
  },
  handbook: {
    add: {
      manualentry: 'handbook.add_manualentry',
      manualentryattachment: 'handbook.add_manualentryattachment',
      manualfolder: 'handbook.add_manualfolder',
      form: 'handbook.add_form',
    },
    change: {
      manualentry: 'handbook.change_manualentry',
      manualentryattachment: 'handbook.change_manualentryattachment',
      manualfolder: 'handbook.change_manualfolder',
      form: 'handbook.change_form',
    },
    delete: {
      manualentry: 'handbook.delete_manualentry',
      manualentryattachment: 'handbook.delete_manualentryattachment',
      manualfolder: 'handbook.delete_manualfolder',
      form: 'handbook.delete_form',
    },
    view: {
      manualentry: 'handbook.view_manualentry',
      manualentryattachment: 'handbook.view_manualentryattachment',
      manualfolder: 'handbook.view_manualfolder',
      form: 'handbook.view_form',
    },
  },
  dynamic_tables: {
    add: {
      dynamiccolumn: 'dynamic_tables.add_dynamiccolumn',
    },
    change: {
      dynamiccolumn: 'dynamic_tables.change_dynamiccolumn',
    },
    delete: {
      dynamiccolumn: 'dynamic_tables.delete_dynamiccolumn',
    },
    view: {
      dynamiccolumn: 'dynamic_tables.view_dynamiccolumn',
    },
  },
  formcreator: {
    add: {
      formtemplate: 'formcreator.add_formtemplate',
      formtemplatevalue: 'formcreator.add_formtemplatefield',
      filledoutform: 'formcreator.add_filledoutform',
      filledoutformvalue: 'formcreator.add_filledoutformvalue',
    },
    change: {
      formtemplate: 'formcreator.change_formtemplate',
      formtemplatevalue: 'formcreator.change_formtemplatefield',
      filledoutform: 'formcreator.change_filledoutform',
      filledoutformvalue: 'formcreator.change_filledoutformvalue',
    },
    delete: {
      formtemplate: 'formcreator.delete_formtemplate',
      formtemplatevalue: 'formcreator.delete_formtemplatefield',
      filledoutform: 'formcreator.delete_filledoutform',
      filledoutformvalue: 'formcreator.delete_filledoutformvalue',
    },
    view: {
      formtemplate: 'formcreator.view_formtemplate',
      formtemplatevalue: 'formcreator.view_formtemplatefield',
      filledoutform: 'formcreator.view_filledoutform',
      filledoutformvalue: 'formcreator.view_filledoutformvalue',
    },
  },
  forum: {
    add: {
      post: 'forum.add_post',
      postattachment: 'forum.add_postattachment',
      thread: 'forum.add_thread',
      threadattachment: 'forum.add_threadattachment',
      threaduserinteraction: 'forum.add_threaduserinteraction',
    },
    change: {
      post: 'forum.change_post',
      postattachment: 'forum.change_postattachment',
      sticky: 'forum.change_sticky',
      thread: 'forum.change_thread',
      threadattachment: 'forum.change_threadattachment',
      threaduserinteraction: 'forum.change_threaduserinteraction',
    },
    delete: {
      post: 'forum.delete_post',
      postattachment: 'forum.delete_postattachment',
      thread: 'forum.delete_thread',
      threaduserinteraction: 'forum.delete_threaduserinteraction',
    },
    view: {
      post: 'forum.view_post',
      postattachment: 'forum.view_postattachment',
      thread: 'forum.view_thread',
      threadattachment: 'forum.view_threadattachment',
      threaduserinteraction: 'forum.view_threaduserinteraction',
    },
    send: {
      thread_email: 'forum.send_thread_email',
      thread_sms: 'forum.send_thread_sms',
    },
  },
  infringements: {
    add: {
      infringement: 'infringements.add_infringement',
    },
    change: {
      infringement: 'infringements.change_infringement',
    },
    delete: {
      infringement: 'infringements.delete_infringement',
    },
    view: {
      infringement: 'infringements.view_infringement',
    },
  },
  issues: {
    add: {
      issue: 'issues.add_issue',
      issueattachment: 'issues.add_issueattachment',
      issuecategory: 'issues.add_issuecategory',
    },
    change: {
      issue: 'issues.change_issue',
      issueattachment: 'issues.change_issueattachment',
      issuecategory: 'issues.change_issuecategory',
    },
    delete: {
      issue: 'issues.delete_issue',
      issueattachment: 'issues.delete_issueattachment',
      issuecategory: 'issues.delete_issuecategory',
    },
    view: {
      issue: 'issues.view_issue',
      issueattachment: 'issues.view_issueattachment',
      issuecategory: 'issues.view_issuecategory',
    },
  },
  routePlanner: {
    add: {
      routePlan: 'route_planner.add_routeplan',
      routeRestriction: 'route_planner.add_routerestriction',
      routeWeightRestriction: 'route_planner.add_routeweightrestriction',
    },
    change: {
      routePlan: 'route_planner.change_routeplan',
    },
    delete: {
      routePlan: 'route_planner.delete_routeplan',
    },
    view: {
      route: 'route_planner.view_route',
      routePlan: 'route_planner.view_routeplan',
      routePlanInstruction: 'route_planner.view_routeplaninstruction',
      routePlanNotice: 'route_planner.view_routeplannotice',
      routePlanNoticeDetail: 'route_planner.view_routeplannoticedetail',
      routePlanPoint: 'route_planner.view_routeplanpoint',
      routeRestriction: 'route_planner.view_routerestriction',
      routeWeightRestriction: 'route_planner.view_routeweightrestriction',
    },
  },
  sessions: {
    add: {
      session: 'sessions.add_session',
    },
    change: {
      session: 'sessions.change_session',
    },
    delete: {
      session: 'sessions.delete_session',
    },
    view: {
      session: 'sessions.view_session',
    },
  },
  sms: {
    add: {
      smsrecord: 'sms.add_smsrecord',
    },
    change: {
      smsrecord: 'sms.change_smsrecord',
    },
    delete: {
      smsrecord: 'sms.delete_smsrecord',
    },
    view: {
      smsrecord: 'sms.view_smsrecord',
    },
  },
  terminal: {
    add: {
      terminal: 'terminal.add_terminal',
      terminalentry: 'terminal.add_terminalentry',
    },
    change: {
      terminal: 'terminal.change_terminal',
      terminalentry: 'terminal.change_terminalentry',
    },
    delete: {
      terminal: 'terminal.delete_terminal',
      terminalentry: 'terminal.delete_terminalentry',
    },
    view: {
      terminal: 'terminal.view_terminal',
      terminalentry: 'terminal.view_terminalentry',
    },
  },
  timesheets: {
    add: {
      absence: 'timesheets.add_absence',
      absencetype: 'timesheets.add_absencetype',
      timeentry: 'timesheets.add_timeentry',
    },
    change: {
      absence: 'timesheets.change_absence',
      absencetype: 'timesheets.change_absencetype',
      timeentry: 'timesheets.change_timeentry',
    },
    delete: {
      absence: 'timesheets.delete_absence',
      absencetype: 'timesheets.delete_absencetype',
      timeentry: 'timesheets.delete_timeentry',
    },
    view: {
      absence: 'timesheets.view_absence',
      absencetype: 'timesheets.view_absencetype',
      timeentry: 'timesheets.view_timeentry',
    },
  },
  users: {
    add: {
      user: 'users.add_user',
      userdocument: 'users.add_userdocument',
      driver_card: 'users.add_drivercard',
      userfetchinfo: 'users.add_userfetchinfo',
      usersetting: 'users.add_usersetting',
      usertype: 'users.add_usertype',
      dropdown: 'users.add_dropdown',
    },
    change: {
      user: 'users.change_user',
      userdocument: 'users.change_userdocument',
      userfetchinfo: 'users.change_userfetchinfo',
      usersetting: 'users.change_usersetting',
      driver_card_number: 'users.change_driver_card_number',
      driver_card: 'users.change_drivercard',
      usertype: 'users.change_usertype',
      usertype_is_administrator: 'users.change_usertype_is_administrator',
      dropdown: 'users.change_dropdown',
    },
    delete: {
      user: 'users.delete_user',
      userdocument: 'users.delete_userdocument',

      driver_card: 'users.delete_drivercard',
      userfetchinfo: 'users.delete_userfetchinfo',
      usersetting: 'users.delete_usersetting',
      usertype: 'users.delete_usertype',
    },
    view: {
      user: 'users.view_user',
      userdocument: 'users.view_userdocument',
      driver_card: 'users.view_drivercard',
      userfetchinfo: 'users.view_userfetchinfo',
      usersetting: 'users.view_usersetting',
      usertype: 'users.view_usertype',
    },
  },
  vehicles: {
    add: {
      vehicle: 'vehicles.add_vehicle',
      vehiclefetchinfo: 'vehicles.add_vehiclefetchinfo',
      vehicledocument: 'vehicles.add_vehicledocument',
      vehicleexpiration: 'vehicles.add_vehicleexpiration',
    },
    change: {
      vehicle: 'vehicles.change_vehicle',
      vehiclefetchinfo: 'vehicles.change_vehiclefetchinfo',
      vehicledocument: 'vehicles.change_vehicledocument',
      vehicleexpiration: 'vehicles.change_vehicleexpiration',
    },
    delete: {
      vehicle: 'vehicles.delete_vehicle',
      vehiclefetchinfo: 'vehicles.delete_vehiclefetchinfo',
      vehicledocument: 'vehicles.delete_vehicledocument',
      vehicleexpiration: 'vehicles.delete_vehicleexpiration',
    },
    view: {
      vehicle: 'vehicles.view_vehicle',
      vehiclefetchinfo: 'vehicles.view_vehiclefetchinfo',
      vehicledocument: 'vehicles.view_vehicledocument',
      vehicleexpiration: 'vehicles.view_vehicleexpiration',
    },
  },
}
