import UserContext from 'context/UserContext'
import { useContext, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import {
  TODAYS_TERMINAL_ENTRIES,
  ALL_TERMINAL_ENTRIES,
  TodaysTerminalEntriesQueryVariables,
  TodaysTerminalEntriesQuery,
  AllTerminalEntriesQuery,
  AllTerminalEntriesQueryVariables,
} from 'modules/terminal'
import { useOnErrorAuto, useInterval } from '.'
import { parse, parseISO } from 'date-fns'
import { format } from 'util/date-fns'

export function useTerminal() {
  const onErrorAuto = useOnErrorAuto()
  const user = useContext(UserContext)
  const getTimeDifference = (timestamp: Date) => {
    const diff = new Date(
      new Date().getTime() -
        timestamp.getTime() +
        timestamp.getTimezoneOffset() * 60000
    )
    return format(diff, 'HH:mm')
  }

  const {
    data: todaysEntries,
    loading,
    error,
  } = useQuery<TodaysTerminalEntriesQuery, TodaysTerminalEntriesQueryVariables>(
    TODAYS_TERMINAL_ENTRIES,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: { user: user.id },
      onError: onErrorAuto(),
    }
  )

  const { data: lastEntries } = useQuery<
    AllTerminalEntriesQuery,
    AllTerminalEntriesQueryVariables
  >(ALL_TERMINAL_ENTRIES, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    /* The value last: 50 is a buffer for if for one godforsaken reason we get
    duplicate checkIns, we will be able to check them out anyway. Just to be
    safe. */
    variables: { user: user.id, last: 50 },
    onError: onErrorAuto(),
  })

  const latestEntry = useMemo(() => {
    if (todaysEntries === undefined || lastEntries === undefined) return null
    const entriesNotCheckedOut = lastEntries.allTerminalEntries.edges.filter(
      entry => entry.node.checkOut === null
    )
    if (entriesNotCheckedOut.length === 0) return null
    return entriesNotCheckedOut[0].node
  }, [todaysEntries, lastEntries])

  const lastCheckIn = useMemo(() => {
    return latestEntry ? parseISO(latestEntry.checkIn) : new Date()
  }, [latestEntry])

  const [timeSinceCheckIn, setTimeSinceCheckIn] = useState(
    getTimeDifference(lastCheckIn)
  )

  useInterval(() => {
    const newValue = getTimeDifference(lastCheckIn)
    if (timeSinceCheckIn !== newValue) {
      setTimeSinceCheckIn(newValue)
    }
  }, 10000)

  const isOnNetwork = useMemo(
    () => todaysEntries !== undefined && todaysEntries.isOnTerminalNetwork,
    [todaysEntries]
  )
  const isWorking = useMemo(
    () => todaysEntries !== undefined && todaysEntries.isWorking,
    [todaysEntries]
  )

  const todaysTerminalEntries = !!todaysEntries
    ? todaysEntries.todaysTerminalEntries.edges.map(edge => edge.node)
    : []

  const todaysTotalWorkTime = useMemo(() => {
    if (todaysEntries === undefined) return '-'
    const totalWorkTime = todaysEntries.todaysTerminalEntriesTotalWorkTime
    if (totalWorkTime.length < 6) return '-'
    return format(parse(totalWorkTime, 'HH:mm:ss', new Date()), 'HH:mm')
  }, [todaysEntries])

  return {
    user,
    lastCheckIn,
    timeSinceCheckIn,
    isWorking,
    isOnNetwork,
    latestEntry,
    todaysTerminalEntries,
    todaysTotalWorkTime,
    loading,
    error,
  }
}
