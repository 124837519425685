import { useTranslate } from "@ur/react-hooks"
import { IssueSeverity } from "./types.graphl"

export function useIssueSeverityOptions() {
    const translations = useTranslate({
        severityGrades: {
          LOW: 'common.low',
          MEDIUM: 'common.medium',
          HIGH: 'common.high',
        }
    })
    const severityOptions = [
        { value: "LOW", label: translations.severityGrades.LOW},
        { value: "MEDIUM", label: translations.severityGrades.MEDIUM},
        { value: "HIGH", label: translations.severityGrades.HIGH},
      ] as {
        value: IssueSeverity
        label: string
      }[]
    
    return severityOptions
}