import React, { useState, useEffect, useRef, useImperativeHandle } from 'react'
import styled from 'styled-components'
import Quill from 'quill'
import Delta from 'quill-delta'
import 'quill/dist/quill.snow.css'
import ImageUploader from 'quill-image-uploader'

const Wrapper = styled.div`
  border: none;
  font-family: 'Gilroy', sans-serif !important;

  .ql-toolbar {
    border: none;
  }

  .ql-container {
    border: none !important;
  }

  .ql-container.ql-snow {
    font-family: 'Gilroy', sans-serif !important;
  }

  .ql-editor {
    padding: 0;
    font-family: 'Gilroy', sans-serif;
    font-size: 1rem;
  }

  iframe {
    width: 80%;
    border-radius: 5px;
    min-height: 300px;
    ${props => props.theme.layout.mobile} {
      width: 100%;
    }
  }
`

export interface QuillRendererRef {
  quill: Quill | null
}

interface QuillRendererProps {
  className?: string
  contents: Delta
}

Quill.register('modules/imageUploader', ImageUploader)

export const QuillRenderer = React.forwardRef<
  QuillRendererRef,
  QuillRendererProps
>(({ className, contents }, ref) => {
  const [quill, setQuill] = useState<Quill | null>(null)
  const quillRef = useRef<HTMLDivElement | null>(null)

  useImperativeHandle(ref, () => ({
    quill,
  }))

  useEffect(() => {
    if (quill) {
      return
    }

    if (!quillRef.current) {
      return
    }

    const newQuill = new Quill(quillRef.current, {
      theme: 'snow',
      modules: {
        toolbar: false,
      },
      readOnly: true,
    })
    newQuill.setContents(contents)

    setQuill(newQuill)
  }, [contents, quill])

  return (
    <Wrapper className={className}>
      <div ref={quillRef}></div>
    </Wrapper>
  )
})
