import { useToast } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { QuillEditorToolbar } from 'components'
import { QuillEditor as BaseQuillEditor } from 'components'
import Quill, { QuillOptionsStatic } from 'quill'
import React, { useImperativeHandle, useRef } from 'react'
import styled from 'styled-components'
import { urlIsValid } from 'util/parsing'

const Wrapper = styled.div``
const QuillEditor = styled(BaseQuillEditor)`
  min-height: 72px;
  border: 0;
  background: rgba(255, 255, 255, 0) !important;

  .ql-container {
    min-height: 72px;
    padding: 1rem !important;
    background: inherit !important;

    .ql-editor {
      min-height: 1ch;
      padding: 0 !important;
      border: 0;
      background: inherit !important;

      &::before {
        font-style: normal;
        color: ${props => props.theme.colors.gray5};
      }
    }
  }
`
const ToolbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  background: ${props => props.theme.colors.quaternary};
  border-bottom: 1px solid ${props => props.theme.colors.gray10};
`

export interface EntryQuillEditorRef {
  quill: Quill | null
}

interface EntryQuillEditorProps {
  placeholder?: string
  error?: string | null

  onInit: (quill: Quill) => void
  onChange: (content: string, quill: Quill) => void
}

export const EntryQuillEditor = React.forwardRef<
  EntryQuillEditorRef,
  EntryQuillEditorProps
>(({ placeholder, onInit, onChange }, ref) => {
  const translations = useTranslate({
    addAttachment: 'common.add-attachment',
    submit: 'forum.post-comment',

    linkHelper: 'forum.quill-link-helper',
    enterUrl: 'common.enter-url',
    invalidUrl: 'common.invalid-url',
  })

  const quillRef = useRef<Quill>(null)
  const addToast = useToast()

  useImperativeHandle(ref, () => ({
    quill: quillRef.current,
  }))

  function handleQuillLink(this: { quill: Quill }, value: boolean) {
    const selectionLength = this.quill.getSelection()?.length ?? 0
    if (selectionLength === 0) {
      addToast('info', translations.linkHelper)
      return
    }

    if (value) {
      let href = window.prompt(translations.enterUrl)
      if (!href) return

      // Check if mailto or tel
      if (!/mailto:.+@.+\..+/.test(href) && !/tel:(\+)?\d+/.test(href)) {
        // Attach https if not present
        if (!/^\w+:\/\//.test(href)) href = `https://${href}`

        if (!urlIsValid(href)) {
          addToast('error', translations.invalidUrl)
          return
        }
      }

      this.quill.format('link', href)
    } else {
      this.quill.format('link', false)
    }
  }

  const quillOptions: QuillOptionsStatic = {
    bounds: '#editor',
    modules: {
      toolbar: {
        container: '#toolbar',
        handlers: {
          link: handleQuillLink,
        },
      },
      uploader: {
        handler: () => void 0,
      },
    },
  }

  return (
    <Wrapper>
      <ToolbarWrapper>
        <QuillEditorToolbar hasImages />
      </ToolbarWrapper>

      <QuillEditor
        id="editor"
        placeholder={placeholder}
        outerQuillRef={quillRef}
        options={quillOptions}
        hasImages
        onChange={onChange}
        onInit={onInit}
      />
    </Wrapper>
  )
})
