import styled from 'styled-components'

interface H2Props {
  fontSize?: string
  fontWeight?: number | string
  lineHeight?: number | string
  margin?: string
  padding?: string
}

export const H2 = styled.h2<H2Props>`
  font-size: ${props => props?.fontSize || '1.5rem'};
  font-weight: ${props => props?.fontWeight || '600'};
  line-height: ${props => `${props?.lineHeight}` || '1.4'};
  margin: ${props => props?.margin || '1rem 0'};
  padding: ${props => props?.padding || '0'};

  color: ${props => props.theme.colors.gray1};
`
