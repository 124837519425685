import { useMutation } from '@apollo/client'
import { useToast } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import {
  EXPORT_TIMESHEETS,
  EXPORT_TIMESHEETS_MASS,
  EXPORT_ABSENCES_MASS,
} from 'modules/exports/mutations'
import { useHistory } from 'react-router'
import { IdVariable } from 'types/graphql'
import { useOnErrorAuto } from 'util/hooks'
import {
  CREATE_ABSENCE_MUTATION,
  CREATE_TIME_ENTRY_MUTATION,
  DELETE_ABSENCE_MUTATION,
  DELETE_TIME_ENTRY_MUTATION,
  PATCH_ABSENCE_MUTATION,
  PATCH_TIME_ENTRY_MUTATION,
} from './mutations'
import {
  CreateAbsenceMutation,
  CreateAbsenceVariables,
  DeleteAbsenceMutation,
  DeleteTimeEntryMutation,
  ExportTimesheetsMassMutation,
  ExportTimesheetsMassMutationVariables,
  ExportTimesheetsMutation,
  ExportTimesheetsMutationVariables,
  ExportAbsenceMassMutation,
  ExportAbsenceMassMutationVariables,
  PatchAbsenceMutation,
  PatchAbsenceMutationVariables,
  PatchTimeEntryMutation,
  PatchTimeEntryMutationVariables,
} from './types.graphql'

export function useTimeEntryTableHooks() {
  const translations = useTranslate({
    dayStart: 'common.day-start',
    dayEnd: 'common.day-end',
    hours: 'common.hours',
    pause: 'common.pause',
    overtime: 'timesheets.overtime-alt',

    editTimeEntry: 'timesheets.edit-time-entry',
    deleteTimeEntry: 'timesheets.delete-time-entry',

    prompt: {
      deleteTimeEntry: 'timesheets.prompt.delete-time-entry',
    },
    success: {
      patchTimeEntry: 'timesheets.toast.patch-time-entry-success',
      deleteTimeEntry: 'timesheets.toast.delete-time-entry-success',
    },
    error: {
      patchTimeEntry: 'timesheets.error.patch-time-entry',
      deleteTimeEntry: 'timesheets.error.delete-time-entry',
    },
    warning: {
      patchTimeEntry: 'timesheets.warning.time-entry-exist-in-daterange',
    },
  })

  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()
  const {
    mutations: { patch: patchAbsence, delete: deleteAbsence },
    loading: absenceLoading,
  } = useAbsenceMutations()

  const [patchTimeEntry, { loading: loadingPatchTimeEntry }] = useMutation<
    PatchTimeEntryMutation,
    PatchTimeEntryMutationVariables
  >(PATCH_TIME_ENTRY_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['UserTimeEntries', 'TimesheetsReport'],
    onCompleted() {
      addToast('success', translations.success.patchTimeEntry)
    },
    onError: error => {
      if (
        error.message.includes(
          'ValueError: Dates are overlapping with already saved date range'
        )
      ) {
        addToast('warning', translations.warning.patchTimeEntry)
      } else {
        onErrorAuto(translations.error.patchTimeEntry)(error)
      }
    },
  })

  const [deleteTimeEntry, { loading: loadingDeleteTimeEntry }] = useMutation<
    DeleteTimeEntryMutation,
    IdVariable
  >(DELETE_TIME_ENTRY_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['UserTimeEntries'],
    onCompleted(data) {
      if (!data?.deleteTimeEntry.found) {
        addToast('error', translations.error.deleteTimeEntry)
        return
      }
      addToast('success', translations.success.deleteTimeEntry)
    },
    onError: onErrorAuto(translations.error.deleteTimeEntry),
  })

  return {
    patchTimeEntry,
    patchAbsence,
    deleteTimeEntry,
    deleteAbsence,
    loading: absenceLoading || loadingPatchTimeEntry || loadingDeleteTimeEntry,
  }
}

export function useAbsenceMutations() {
  const translations = useTranslate({
    error: {
      create: 'timesheets.error-create-absence',
      delete: 'timesheets.error.delete-absence',
      patch: 'timesheets.error.patch-absence',
      typeOne: 'timesheets.absence-types-error-1',
    },
    prompt: {
      delete: 'timesheets.prompt.delete-absence',
    },
    success: {
      create: 'timesheets.success-registration-absence',
      delete: 'timesheets.toast.delete-absence-success',
      patch: 'timesheets.toast.patch-absence-success',
    },
  })
  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()
  const history = useHistory()
  const [createAbsence, { loading: loadingCreateAbsence }] = useMutation<
    CreateAbsenceMutation,
    CreateAbsenceVariables
  >(CREATE_ABSENCE_MUTATION, {
    refetchQueries: ['UserAbsences'],
    onCompleted: () => {
      addToast('success', translations.success.create)
      history.push('/absences')
    },
    onError: onErrorAuto(
      {},
      {
        showToast: false,
        callback({ message }: { message: string }) {
          if (
            message.includes(
              'timesheets_absence.date, timesheets_absence.user_id'
            )
          ) {
            addToast('error', translations.error.typeOne)
          } else {
            addToast('error', translations.error.create)
          }
        },
      }
    ),
  })

  const [patchAbsence, { loading: loadingPatchAbsence }] = useMutation<
    PatchAbsenceMutation,
    PatchAbsenceMutationVariables
  >(PATCH_ABSENCE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['UserAbsences', 'TimesheetsReport'],
    onCompleted() {
      addToast('success', translations.success.patch)
    },
    onError: onErrorAuto(translations.error.patch),
  })

  const [deleteAbsence, { loading: loadingDeleteAbsence }] = useMutation<
    DeleteAbsenceMutation,
    IdVariable
  >(DELETE_ABSENCE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['UserTimeEntries', 'UserAbsences'],
    onCompleted(data) {
      if (!data?.deleteAbsence.found) {
        addToast('error', translations.error.delete)
        return
      }
      addToast('success', translations.success.delete)
    },
    onError: onErrorAuto(translations.error.delete),
  })

  return {
    mutations: {
      create: createAbsence,
      patch: patchAbsence,
      delete: deleteAbsence,
    },
    loading:
      loadingCreateAbsence || loadingDeleteAbsence || loadingPatchAbsence,
  }
}

export function useTimeEntryMutations() {
  const addToast = useToast()
  const history = useHistory()

  const translations = useTranslate({
    success: 'timesheets.success-registration-timeentry',
    error: {
      create: 'timesheets.error-create-timeentry',
    },
    warning: {
      create: 'timesheets.warning.time-entry-exist-in-daterange',
    },
  })

  const [createTimeEntryMutation] = useMutation(CREATE_TIME_ENTRY_MUTATION, {
    refetchQueries: ['UserTimeEntries'],
    onCompleted: () => {
      addToast('success', translations.success)
      history.push('/time-entries')
    },
    onError: ({ message }: { message: string; name: string }) => {
      if (
        message.includes(
          'ValueError: Dates are overlapping with already saved date range'
        )
      ) {
        addToast('warning', translations.warning.create)
        return
      } else if (message.includes('UNIQUE')) {
        addToast('error', translations.warning.create)
      } else {
        addToast('error', translations.error.create)
      }
    },
  })
  return {
    create: createTimeEntryMutation,
  }
}

export const useTimeSheetExportMutations = (onCompleted?: () => void) => {
  const translations = useTranslate({
    mailSuccess: 'exports.success-email-process',
    downloadSuccess: 'exports.success-email-process',
    error: 'error.generic-server-error',
    userSalaryError: 'exports.error-user-salary-settings',
  })
  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()
  const history = useHistory()

  const [exportTimesheetsMass, { loading: massTimesheetsLoading }] =
    useMutation<
      ExportTimesheetsMassMutation,
      ExportTimesheetsMassMutationVariables
    >(EXPORT_TIMESHEETS_MASS, {
      onCompleted(data: ExportTimesheetsMassMutation) {
        if (data?.exportTimesheetsMass.html) {
          history.push('/export', { html: data?.exportTimesheetsMass.html })
        }
        if (data?.exportTimesheetsMass.ok) {
          const successMessage = translations.downloadSuccess
          addToast('success', successMessage)
        }
        onCompleted?.()
      },
      onError: onErrorAuto(translations.error),
    })

  const [exportTimesheets, { loading: timesheetsLoading }] = useMutation<
    ExportTimesheetsMutation,
    ExportTimesheetsMutationVariables
  >(EXPORT_TIMESHEETS, {
    onCompleted(data: ExportTimesheetsMutation) {
      if (data?.exportTimesheets.html) {
        history.push('/export', { html: data?.exportTimesheets.html })
      } else if (data?.exportTimesheets.ok) {
        addToast('success', translations.downloadSuccess)
      }
    },
    onError: error => {
      if (
        error.message.includes('The user is not assigned any salary settings')
      ) {
        addToast('error', translations.userSalaryError)
      } else {
        addToast('error', translations.error)
      }
    },
  })

  return {
    exportTimesheetsMass,
    exportTimesheets,
    loading: massTimesheetsLoading || timesheetsLoading,
  }
}
export const useAbsenceExportMutations = (onCompleted?: () => void) => {
  const translations = useTranslate({
    error: 'error.generic-server-error',
    downloadSuccess: 'exports.success-user-exports',
    mailSuccess: 'exports.success-email-process',
  })

  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()

  const [exportAbsencesMass, { loading: massAbsencesLoading }] = useMutation<
    ExportAbsenceMassMutation,
    ExportAbsenceMassMutationVariables
  >(EXPORT_ABSENCES_MASS, {
    onCompleted(data: ExportAbsenceMassMutation) {
      if (data?.exportAbsencesMass.ok) {
        addToast('success', translations.downloadSuccess)
      }
      onCompleted?.()
    },
    onError: onErrorAuto(translations.error),
  })

  return {
    exportAbsencesMass,
    loading: massAbsencesLoading,
  }
}
