import { SelectOption } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import React, { useCallback, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { ALL_USER_DOCUMENT_CATEGORIES_QUERY } from 'modules/users/queries'
import { AllUserDocumentCategorysQuery, UserDocumentCategoryNode } from 'modules/users'
import { SelectMultiple, SelectMultipleProps } from './SelectMultiple'

interface SelectMultipleUserDocumentCategoriesProps extends Omit<SelectMultipleProps<string, UserDocumentCategoryNode>, 'options'>{
}

export const SelectMultipleUserDocumentCategories: React.VFC<SelectMultipleUserDocumentCategoriesProps> = ({
  ...selectProps
}) => {
  const { placeholder } = useTranslate({
    placeholder: 'common.select-salary-code',
  })
  const { data, loading } = useQuery<AllUserDocumentCategorysQuery>(ALL_USER_DOCUMENT_CATEGORIES_QUERY)

  const getLabel = useCallback((project: UserDocumentCategoryNode) => {
    return <span className="name">{project.name}</span>
  }, [])

  const options = useMemo<SelectOption<string, UserDocumentCategoryNode>[]>(() => {
    if (!data) return []

    const options = data.allUserDocumentCategories.edges.map(
      ({ node: userDocumentCategory }) => ({
        value: userDocumentCategory.id,
        label: getLabel(userDocumentCategory),
        extra: userDocumentCategory,
      })
    )
    return options
  }, [data, getLabel])

  return (
    <SelectMultiple
      options={options}
      loading={loading}
      placeholder={placeholder}
      filterBackground="white"
      optionHoverBackground="white"
      {...selectProps}
    />
  )
}
