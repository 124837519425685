import { useForm, useTranslate } from '@ur/react-hooks'
import { ModuleTitle, ToggleIconProps, Toggle, IconMode } from 'components'
import {
  addMonths,
  endOfDay,
  endOfMonth,
  formatISO,
  getWeek,
  parseISO,
  setDate,
  startOfDay,
  startOfMonth,
} from 'date-fns'
import React, { useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import {
  useAdmin,
  useCompany,
  useModuleConfig,
  useOnErrorAuto,
  useUser,
} from 'util/hooks'
import { ExportType, YearMonthExportData } from 'modules/exports'
import { ExportModal, TimesheetsTable } from './components'
import { TimesheetControls } from './components/TimesheetControls'
import { TIMESHEETS_REPORT_QUERY } from './queries'
import { ExportForm, TimesheetFilterForm } from './types'
import {
  TimesheetsReportQuery,
  TimesheetsReportQueryVariables,
} from './types.graphql'
import { TimeEntryViewEnum } from './consts'
import { Column } from '../../components/Table'
import { isInvalidDate } from 'util/time'
import { useTimeSheetExportMutations } from './mutations.hooks'
import { Module, ModuleOptionsValue } from 'modules/companies'
import { isMobile } from 'react-device-detect'

const Wrapper = styled.div`
  ${props => props.theme.layout.default};
`

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-direction: row;
  align-items: center;
  ${props => props.theme.layout.mobile} {
    margin-bottom: 1rem;
  }
`

const Weeks = styled.div`
  h2 {
    margin: 1.5rem 0 1rem 8px;
    font-weight: 600;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const iconProps: Omit<ToggleIconProps, 'icon'> = {
  size: '1.4rem',
}

interface TimeEntriesProps {}

export const TimeEntries: React.VFC<TimeEntriesProps> = () => {
  const translations = useTranslate({
    timeEntries: 'common.time-entries',
    weekN: ['common.week-n', { n: 0 }],
    toasts: {
      exportDownloadSuccess: 'exports.success-user-exports',
      exportSuccess: 'exports.success-email-process',
      exportError: 'exports.error-email-process',
    },
    toggle: {
      weekly: 'common.weekly',
      monthly: 'common.monthly',
    },
    dayStart: 'common.day-start',
    dayEnd: 'common.day-end',
    ordinary: 'common.ordinary',
    hours: 'common.work-hours',
    pause: 'common.pause',
    project: 'common.project',
    overtime: 'common.overtime',
    date: 'common.date',
    salaryCode: 'common.salary-code',
    department: 'common.department',

    employee: 'common.employee',
    reason: 'common.reason',
    absenceType: 'timesheets.absence-type',
    status: 'common.status',

    noResults: 'common.no-results',
    sum: 'common.sum',
    error: { generic: 'error.generic-server-error' },

    comment: 'common.comment',
  })

  const company = useCompany()
  const me = useUser()
  const admin = useAdmin()
  const onErrorAuto = useOnErrorAuto()

  const [exportModalOpen, setExportModalOpen] = useState(false)
  const [exportLoading, setExportLoading] = useState(false)

  const { formValues: filter, updateForm } = useForm<TimesheetFilterForm>({
    values: {
      user: me.id,
      month: new Date(),
      orderBy: 'start',
      view: TimeEntryViewEnum.table,
      salaryMode: false,
    },
    config: {
      storage: {
        storeFormState: true,
        excludeFields: ['user'],
        retrieveFormStateOnMount: true,
        sessionStorage: true,
        storeFormStateName: 'timesheet-controls',
        transformValues: {
          month: value =>
            typeof value === 'string'
              ? isInvalidDate(new Date(value), new Date())
              : undefined,
          salaryMode: value => (typeof value === 'boolean') ? value : false,
        },
      },
    },
  })

  const user = useUser(filter.user, Module.TIMESHEETS)

  const { getModuleOptionStatus } = useModuleConfig(Module.TIMESHEETS)
  const projectActive = getModuleOptionStatus(
    ModuleOptionsValue.TIMESHEETS_PROJECTS
  )


  const { datetimeStartLt, datetimeEndGte } = useMemo(() => {
    const baseDate = startOfDay(filter.month)
    if (filter.salaryMode && user.salaryWorkTime) {
      const salaryStart = user.salaryWorkTime?.salaryDateStart ?? 1
      const salaryEnd = user.salaryWorkTime?.salaryDateEnd ?? 1
      
      const forward = (31 - salaryStart) > 15

      // What salary period is connected to which month is based on the salary start date.
      if (forward) {
        const datetimeStartLt = setDate(baseDate, salaryStart)
        const datetimeEndGte = (salaryStart === 1) ? endOfMonth(baseDate) : setDate(addMonths(baseDate, 1), salaryEnd)

        return {datetimeStartLt, datetimeEndGte}
      }

      const datetimeStartLt = setDate(addMonths(baseDate, -1), salaryStart)
      const datetimeEndGte = setDate(baseDate, salaryEnd)

      return {datetimeStartLt, datetimeEndGte}
    } else {
      const datetimeStartLt = startOfMonth(baseDate)
      const datetimeEndGte = endOfMonth(datetimeStartLt)
  
      return {
        datetimeStartLt,
        datetimeEndGte,
      }
    }
  }, [filter.month, filter.salaryMode, user.salaryWorkTime])

  const { data, loading } = useQuery<
    TimesheetsReportQuery,
    TimesheetsReportQueryVariables
  >(TIMESHEETS_REPORT_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      userId: filter.user ?? me.id,
      startDate: formatISO(datetimeStartLt),
      endDate: formatISO(datetimeEndGte),
      includeWholeWeeks: filter.view === TimeEntryViewEnum.week,
    },
    onError: onErrorAuto(),
  })

  const periodReports = data?.timesheetsReport.periodReports ?? []
  const salaryWorkTime = data?.timesheetsReport.salaryWorkTime

  const { exportTimesheets: exportMutation } = useTimeSheetExportMutations()

  const exportConfig: YearMonthExportData = {
    companyId: company.id,
    month: filter.month.getMonth(),
    year: filter.month.getFullYear(),
    userId: filter.user ?? '',
    mailId: me.id,
  }

  async function handleExport(options: ExportForm, salaryDates: [Date, Date]) {
    try {
      setExportLoading(true)
      const startMonthYearTime = startOfMonth(filter.month)
      const endMonthYearTime = endOfMonth(startMonthYearTime)
      const useSalaryDates = options.exportType === ExportType.ENTRY_SALARY
      const firstOfMonth = formatISO(
        useSalaryDates ? startOfDay(salaryDates[0]) : startMonthYearTime
      )
      const lastOfMonth = formatISO(
        useSalaryDates ? endOfDay(salaryDates[1]) : endMonthYearTime
      )

      await exportMutation({
        variables: {
          userMail: exportConfig.mailId!,
          user: exportConfig.userId,
          company: exportConfig.companyId,
          dateStart: firstOfMonth,
          dateEnd: lastOfMonth,
          exportType: options.exportType,
          exportFormat: options.exportFormat,
          exportOption: options.exportOption,
        },
      })
    } finally {
      setExportLoading(false)
      setExportModalOpen(false)
    }
  }

  const columns: Column[] = useMemo(() => {
    let columns = [
      { name: 'date', displayName: translations.date, type: '' },
      { name: 'startTime', displayName: translations.dayStart, type: '' },
      { name: 'endTime', displayName: translations.dayEnd, type: '' },
      { name: 'totalWork', displayName: company.useOrdinaryHourCalculation ? translations.ordinary : translations.hours, type: '' },
      { name: 'pause', displayName: translations.pause, type: '' },
      { name: 'overtime', displayName: translations.overtime, type: '' },
    ]
    if (!isMobile){
      columns = [
        ...columns,
        { name: 'salaryCode', displayName: translations.salaryCode, type: '' },
        { name: 'department', displayName: translations.department, type: '' },
      ]

      if (projectActive) {
        columns = [
          ...columns,
          { name: 'project', displayName: translations.project, type: '' },
        ]
      }
    }
    columns = [
      ...columns,
      { name: 'comment', displayName: translations.comment, type: '' },
    ]
    return columns
  }, [company.useOrdinaryHourCalculation, projectActive, translations])

  return (
    <Wrapper>
      {admin && (
        <ExportModal
          open={exportModalOpen}
          exportConfig={exportConfig}
          userId={filter.user}
          loading={exportLoading}
          onClose={() => setExportModalOpen(false)}
          onSubmit={handleExport}
        />
      )}
      <TitleWrapper>
        <ModuleTitle>{translations.timeEntries}</ModuleTitle>
        <div>
          <Toggle
            value={filter.view === TimeEntryViewEnum.table}
            iconMode={IconMode.textAndIcon}
            leftText={translations.toggle.weekly}
            rightText={translations.toggle.monthly}
            offIconProps={{
              icon: 'calendar-week',
              ...iconProps,
              color: !(filter.view === TimeEntryViewEnum.table)
                ? 'white'
                : undefined,
            }}
            onIconProps={{
              icon: 'calendar-alt',
              ...iconProps,
              color: filter.view === TimeEntryViewEnum.table ? 'white' : undefined,
            }}
            thumbBackground="secondary"
            background="white"
            height="3.875rem"
            width={160}
            onChange={value =>
              updateForm({
                view: value ? TimeEntryViewEnum.table : TimeEntryViewEnum.week,
              })
            }
          />
        </div>
      </TitleWrapper>

      <TimesheetControls
        filter={filter}
        onOpenExportModal={() => setExportModalOpen(true)}
        onUpdate={updateForm}
      />
      {!loading && !!salaryWorkTime && (
        <>
          {filter.view === TimeEntryViewEnum.table ? 
        periodReports.length && periodReports.length > 0 ? (
          <TimesheetsTable
            periodReport={periodReports[0]}
            salaryWorkTime={salaryWorkTime}
            startDate={datetimeStartLt}
            endDate={datetimeEndGte}
            columns={columns}
            projectActive={projectActive}
          />
      ) : <></>
      : (
        <Weeks>
          {periodReports.map(periodReport => (
            <React.Fragment key={periodReport.periodStart}>
              <Header>
                <h2>{translations.weekN({ n: getWeek(parseISO(periodReport.periodStart))})}</h2>
              </Header>
              <TimesheetsTable
                periodReport={periodReport}
                salaryWorkTime={salaryWorkTime}
                startDate={datetimeStartLt}
                endDate={datetimeEndGte}
                columns={columns}
                projectActive={projectActive}
              />
            </React.Fragment>
          ))}
        </Weeks>
      )}
        </>
      )}
    </Wrapper>
  )
}
