import { useMemo, useCallback, useState, useEffect } from 'react'

const breakpoints = [
  'screen and (max-width: 768px)',
  'screen and (min-width: 769px) and (max-width: 1200px)',
  'screen and (min-width: 1201px)',
]

export default function useMedia() {
  const media = useMemo(
    () => breakpoints.map(breakpoint => window.matchMedia(breakpoint)),
    []
  )

  const getMatches = useCallback(() => media.map(mql => mql.matches), [media])
  const [matches, setMatches] = useState(getMatches)

  useEffect(() => {
    const handler = (e: MediaQueryListEvent) => {
      setMatches(getMatches)
    }
    media.forEach(mql => mql.addListener(handler))
    return () => media.forEach(mql => mql.removeListener(handler))
  }, [getMatches, media])

  return matches
}
