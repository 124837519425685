import { gql } from '@apollo/client'

export const CURRENT_COMPANY_STATS = gql`
  query CurrentCompanyStats($dateGte: Date!, $dateLt: Date!) {
    companyDriverStats(dateStart: $dateGte, dateEnd: $dateLt) {
      averageFuelConsumptionPerHour
      averageFuelConsumptionPerHundredKilometer
      kilometersDriven
      numberOfIssues

      averageDriverGrade
    }
  }
`

export const USER_DRIVER_STATS_QUERY = gql`
  query UserDriverStats($dateGte: Date!, $dateLt: Date!, $userId: ID) {
    userDriverStats(dateStart: $dateGte, dateEnd: $dateLt, userId: $userId) {
      averageFuelConsumptionPerHour
      averageFuelConsumptionPerHundredKilometer
      kilometersDriven
      numberOfIssues

      averageDriverGrade
    }
  }
`
