import { gql } from '@apollo/client'

export const ChecklistFragments = {
  checklistInfo: gql`
    fragment checklistInfo on ChecklistNode {
      id
      title
      createdAt
      updatedAt
      items {
        id
        text
        done
      }
    }
  `,
  checklistTemplateInfo: gql`
    fragment checklistTemplateInfo on ChecklistTemplateNode {
      id
      title
      userTypes {
        id
        name
      }
      mode
      items {
        id
        text
        done
      }
    }
  `,
  shallowChecklistTemplateInfo: gql`
    fragment shallowChecklistTemplateInfo on ChecklistTemplateNode {
      id
      title
      userTypes {
        id
        name
      }
    }
  `,
}
