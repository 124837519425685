import { useTranslate } from '@ur/react-hooks'
import { Table, TableSummation } from 'components'
import { Card } from 'components/Card'
import { parseISO } from 'date-fns'
import { stripSecondsFromDuration } from 'modules/activities'
import { Area, AreaProps } from 'modules/users/UserProfile/components'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { format } from 'util/date-fns'
import { useTerminal } from 'util/hooks'
import { CheckInOutTerminal } from '../CheckInOutTerminal'

const Wrapper = styled.section`
  max-width: 100%;
`
const Inner = styled(Card)`
  display: grid;
  grid-template-areas: 'button table';
  grid-template-columns: auto 1fr;

  .table {
    max-width: 100%;
    font-size: 0.8rem;
  }

  overflow-x: auto;

  ${props => props.theme.layout.mobile} {
    grid-template-areas:
      'time button'
      'table table';
    grid-template-columns: auto auto;

    .time {
      align-self: center;
      display: flex;
      flex-direction: column;
      padding-left: 2rem;

      span + span {
        margin-top: 0.2rem;
        color: ${props => props.theme.colors.gray3};
      }
    }

    .table {
      padding: 0;

      td:first-of-type div {
        margin-left: 1rem !important;
        text-transform: uppercase;
      }
      th:first-of-type {
        padding: 1rem !important;
      }
    }
  }
`

const ButtonField = styled.div<AreaProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 250px;
  padding: 1.25rem;

  border-right: 1px solid ${props => props.theme.colors.gray8};

  span {
    margin-top: 1rem;
    font-size: 1rem;
    text-align: center;
    color: ${props => props.theme.colors.gray3};
  }

  ${props => props.theme.layout.mobile} {
    grid-row: 1;
    align-self: center;
    width: auto;
    margin: 0;
    border-right: none;
  }
`

const StyledCheckInOutTerminal = styled(CheckInOutTerminal)`
  padding-bottom: 1rem;
`

const Summation = styled(TableSummation)`
  height: 5.5rem;

  td {
    padding-top: 1rem !important;
  }
  &:hover {
    background: inherit !important;
  }
  td:first-of-type div {
    text-transform: uppercase;
  }
  ${props => props.theme.media.mobile} {
    td:first-of-type div {
      margin-left: 1rem !important;
      text-transform: uppercase;
    }
    td:last-of-type div {
      margin-right: 1rem !important;
    }
  }
`

export const TerminalCard: React.FC = () => {
  const translations = useTranslate({
    notOnNetwork: 'terminal.not-on-network',
    checkIn: 'terminal.check-in',
    title: 'common.terminal',
    timeText: ['terminal.checked-in-at', { time: '' }],
    labels: {
      date: 'common.date',
      checkedIn: 'terminal.checked-in',
      checkedOut: 'terminal.checked-out',
      time: 'common.time',
      sum: 'common.sum',
    },
  })

  const {
    user,
    latestEntry,
    lastCheckIn,
    timeSinceCheckIn,
    todaysTerminalEntries,
    todaysTotalWorkTime,
    isWorking,
    loading,
    isOnNetwork,
  } = useTerminal()

  const message = !isOnNetwork
    ? translations.notOnNetwork
    : isWorking
    ? timeSinceCheckIn
    : translations.checkIn

  return (
    <Wrapper>
      <h2>{translations.title}</h2>

      <Inner padding="0">
        {isMobileOnly && (
          <Area className="time" area="time">
            {isWorking && (
              <span>
                {translations.timeText({ time: format(lastCheckIn, 'HH:mm') })}
              </span>
            )}
            <span>{message}</span>
          </Area>
        )}

        <ButtonField area="button">
          <StyledCheckInOutTerminal
            user={user}
            latestEntry={latestEntry}
            lastCheckIn={lastCheckIn}
            isWorking={isWorking}
            isOnNetwork={isOnNetwork}
            loading={loading}
          />
          {!isMobileOnly && <span>{message}</span>}
        </ButtonField>

        <Area className="table" area="table">
          <Table headerMargin noBorders noShadow>
            <thead>
              <tr>
                {!isMobileOnly && (
                  <th>
                    <div>{translations.labels.date}</div>
                  </th>
                )}
                <th>
                  <div>{translations.labels.checkedIn}</div>
                </th>
                <th>
                  <div>{translations.labels.checkedOut}</div>
                </th>
                <th>
                  <div>{translations.labels.time}</div>
                </th>
              </tr>
            </thead>

            <tbody>
              {todaysTerminalEntries.length > 0 ? (
                todaysTerminalEntries.map(entry => {
                  const stringCheckOut = entry?.checkOut
                    ? format(parseISO(entry.checkOut), 'HH:mm')
                    : '-'
                  const parsedCheckIn = parseISO(entry.checkIn)
                  const stringCheckIn = format(parsedCheckIn, 'HH:mm')
                  const date = format(parsedCheckIn, 'dd. LLL')
                  const stringTotalWorkTime = entry?.checkOut
                    ? stripSecondsFromDuration(entry.totalWorkTime)
                    : '-'

                  return (
                    <tr key={entry.id}>
                      {!isMobileOnly && (
                        <td>
                          <div>{date}</div>
                        </td>
                      )}
                      <td>
                        <div>{stringCheckIn}</div>
                      </td>
                      <td>
                        <div>{stringCheckOut}</div>
                      </td>
                      <td>
                        <div>{stringTotalWorkTime}</div>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  {!isMobileOnly && (
                    <td>
                      <div>-</div>
                    </td>
                  )}
                  <td>
                    <div>-</div>
                  </td>
                  <td>
                    <div>-</div>
                  </td>
                  <td>
                    <div>-</div>
                  </td>
                </tr>
              )}

              <Summation>
                <td>
                  <div>{translations.labels.sum}</div>
                </td>
                <td colSpan={isMobileOnly ? 1 : 2}>
                  <div></div>
                </td>
                <td>
                  <div>{todaysTotalWorkTime}</div>
                </td>
              </Summation>
            </tbody>
          </Table>
        </Area>
      </Inner>
    </Wrapper>
  )
}
