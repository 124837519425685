import { Icon } from '@ur/react-components'
import { BaseProps } from '@ur/react-components/build/types/props'
import React from 'react'
import styled, { css } from 'styled-components'

interface WrapperProps {
  disabled: boolean
}
const Wrapper = styled.th<WrapperProps>`
  ${props =>
    !props.disabled &&
    css`
      cursor: pointer;
      user-select: none;

      &:hover {
        color: ${props => props.theme.colors.secondary};
      }
    `}
`

interface SortableHeaderProps<Ordering extends string = string>
  extends BaseProps {
  /** The value for which this column should be sorted (ex. createdAt). No minus-prefix here */
  baseValue: Ordering
  /** The actual value of the current table sorting */
  sortValue: Ordering
  /** Normally the minus-prefix denotes ascending, sort; invert that */
  invertDirection?: boolean

  /** Disable to treat as normal th */
  disabled?: boolean

  onSort: (value: Ordering) => void
}

export const SortableHeader = function <Ordering extends string = string>({
  children,
  className,
  id,

  baseValue,
  sortValue,
  invertDirection = false,

  disabled = false,

  onSort,
}: React.PropsWithChildren<SortableHeaderProps<Ordering>>) {
  if (baseValue.charAt(0) === '-')
    throw new Error('SortableHeader: baseValue should not start with a minus')

  const asc = ((val: boolean) => (invertDirection ? !val : val))(
    sortValue.charAt(0) === '-'
  )
  const isSorted = sortValue.includes(baseValue)

  function toggleSort() {
    if (disabled) return
    if (!isSorted) onSort(baseValue)
    onSort(
      sortValue.charAt(0) === '-' ? baseValue : (`-${baseValue}` as Ordering)
    )
  }

  return (
    <Wrapper
      className={className}
      id={id}
      role={disabled ? undefined : 'button'}
      disabled={disabled}
      onClick={toggleSort}
    >
      {children}

      {!disabled && isSorted && (
        <Icon
          icon={asc ? 'chevron-up' : 'chevron-down'}
          color="secondary"
          cursor="pointer"
          margin="0 0 0 12px"
          size="0.8em"
        />
      )}
    </Wrapper>
  )
}
