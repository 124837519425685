import React from 'react'
import { isMobile, isMobileOnly } from 'react-device-detect'
import { Field, Form, FormButton, InfoBox, Section, SettingsWrapper } from '../components/common'
import { useForm, useTranslate } from '@ur/react-hooks'
import { DatePicker, Select } from 'components'
import { addWeeks, format, formatISO, isMonday, previousMonday, startOfDay, subWeeks } from 'date-fns'
import { Icon } from '@ur/react-components'
import { useMutation } from '@apollo/client'
import { GENERATE_FATS_REPORT } from 'modules/companies/mutations'
import styled from 'styled-components'

const FlexFields = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 1rem;
  ${props => props.theme.media.mobile} {
    flex-direction: column;
    width: 100%;
  }
`

const AbsoluteContainer = styled.div`
  position: absolute;
  font-size: 14px;
  bottom: calc(2rem + 10px);
  left: 2rem;
  ${props => props.theme.media.mobile} {
    position: static;
    padding-top: 1rem;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  span {
    font-weight: 600;
    margin: 0 0 6px 6px;
  }
`

interface FATSSettingsSettingsProps {}
interface FATSForm {
  periodLength: number | null
  startDate: Date
  maxDailyWorkLimit: number | null
}

export const FATSSettings: React.VFC<FATSSettingsSettingsProps> = () => {
  const translations = useTranslate({
    info1: 'settings.info.fats-1',
    periodLength: 'settings.fats.period-length',
    nWeeks: ['settings.fats.n-weeks', {n: 0}],
    startDate: 'common.start-date',
    startDateNotMonday: 'settings.fats.start-date-not-monday',
    startDateNotValid: 'settings.fats.start-date-too-late-to-get-valid-interval',
    required: 'common.required',
    generate: 'common.generate-report',
    nHours: ['common.n-hours', {n: 0}],
    maxDailyWorkLimit: 'settings.fats.max-daily-work-limit',
    periodGeneratedFor: 'settings.fats.period-generated-for',
  })

  const emptyArrayPeriodLength = Array(11).fill(0)
  const periodLengthOptions = emptyArrayPeriodLength.map((_, i) => ({label: translations.nWeeks({n: 16 + i}), value: 16 + i}))
  const emptyArrayMaxDailyWorkLimit = Array(4).fill(0)
  const maxDailyWorkLimitOptions = emptyArrayMaxDailyWorkLimit.map((_, i) => ({label: translations.nHours({n: 13 + i}), value: 13 + i}))

  const [generateFATSReport, {loading}] = useMutation(GENERATE_FATS_REPORT)

  const { formValues, formChangeHandler: handler, formErrors, submitHandler: submit, formValid} = useForm<FATSForm>({
    values: {
      periodLength: 16,
      startDate: subWeeks(previousMonday(new Date()), 16),
      maxDailyWorkLimit: 13,
    },
    validators: {
      startDate: (value, others) => {

        const disabledAfter = subWeeks(previousMonday(new Date()), others.periodLength || 0)
        if (!value) return translations.required
        if (value >= disabledAfter) return translations.startDateNotValid
        if (!isMonday(value)) return translations.startDateNotMonday
        return null
      }
    },
    config: {
      validateImmediately: true,
    },
  })
  
  async function handleExport(values: FATSForm) {
    generateFATSReport({
      variables: {
        startDate: formatISO(startOfDay(values.startDate)),
        periodLength: values.periodLength,
        maxDailyWorkLimit: values.maxDailyWorkLimit,
      },
    })
  }

  const formButtons: FormButton[] = [
    {
      text: (
        <>
          <Icon
            icon="download"
            type="light"
            color="primary"
            margin="0 0.5ch 0 0"
          />
          {translations.generate}
        </>
      ),
      type: 'submit',
      buttonProps: {
        fullWidth: isMobile,
        disabled: loading || !formValid,
      },
      onClick: submit(handleExport),
    },
  ]

  
  return (
    <SettingsWrapper
      grid={{
        flow: 'row',
      }}
    >
      <InfoBox initCollapsed={isMobileOnly}>
        <p>{translations.info1}</p>
      </InfoBox>
      
      <Section>
        <header></header>

        <Form buttons={formButtons}>
          <FlexFields>
            <Field>
              <label>{translations.startDate}</label>
              <DatePicker value={formValues.startDate} onChange={handler('startDate')} dateErrorText={formErrors.startDate ?? ''} width={isMobile ? "100%" : "400px"} closeOnDateChange/>
            </Field>
            <Field style={{margin: "0px"}}>
              <label>{translations.periodLength}</label>
              <Select options={periodLengthOptions} value={formValues.periodLength} onChange={handler('periodLength')} nullable={false} width={isMobile ? "100%" : "200px"}/>
            </Field>
          </FlexFields>
          <FlexFields>
            <Field>
              <label>{translations.maxDailyWorkLimit}</label>
              <Select options={maxDailyWorkLimitOptions} value={formValues.maxDailyWorkLimit} onChange={handler('maxDailyWorkLimit')} nullable={false} width={isMobile ? "100%" : "200px"}/>
            </Field>
          </FlexFields>
        </Form>
        <AbsoluteContainer>
          <Field>
            <label>{translations.periodGeneratedFor}:</label> 
            <span>
              {format(formValues.startDate, "dd.MM.YYY") + " - " + format(addWeeks(formValues.startDate, formValues.periodLength ?? 0), "dd.MM.YYY")}
            </span>
          </Field>
            
            
        </AbsoluteContainer>
      </Section>
    </SettingsWrapper>
  )
}
