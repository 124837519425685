import { useToast } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Button, CenteredLoader, UserSelect } from 'components'
import { Card } from 'components/Card'
import { formatISO } from 'date-fns'
import { Coordinates } from 'types/graphql/maps'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { Nullable } from 'types/util'
import { FileAttachment, useAdmin, useUser } from 'util/hooks'
import { useGoogleLoader } from 'util/hooks/useGoogleLoader'
import { Attachments, CreateDetails, CreateMainInfo, Map } from '../components'
import { useCreateIssueMutation } from '../mutations.hooks'
import { EditIssueForm } from '../types'
import { AllIssueRiskAspectsQuery, AllIssueRiskAspectsQueryVariables, CreateIssueInput } from '../types.graphl'
import { ALL_ISSUE_RISK_ASPECTS_QUERY } from '../queries'
import { useQuery } from '@apollo/client'

const Wrapper = styled.div`
  ${props => props.theme.layout.default};

  header {
    display: flex;
    justify-content: space-between;

    .header {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
  .user-select {
    margin-left: 1rem;
    width: 100%;
    max-width: calc(400px - 4ch);

    .--select-display-inner {
      width: 100%;
    }

    .--select-display-selected {
      color: ${props => props.theme.colors.text.dark};
      width: 100%;
      max-width: calc(60vw - 4ch);
      overflow: auto;
      white-space: nowrap;
    }
  }
`
const Buttons = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: ${isMobile ? 'space-between' : 'flex-end'};

  button {
    height: 3rem;

    & + button {
      margin-left: 1rem;
    }
  }
`
const Content = styled(Card)`
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-areas:
    'main         map'
    'details      map'
    'attachments  map';

  padding: 0;

  ${props => props.theme.media.custom({ max: '1500px' })} {
    display: flex;
    flex-direction: column;
  }
`
const Loading = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  background-color: rgba(255, 255, 255, 0.5);
`

interface CreateIssueProps {}

export const CreateIssue: React.VFC<CreateIssueProps> = () => {
  const translations = useTranslate({
    cancel: 'common.cancel',
    save: 'common.save',

    createIssue: 'issues.create-issue',
    createIssueFor: 'issues.create-issue-for',

    validation: {
      required: 'common.required',
      couldNotFindLocation: 'error.could-not-find-location',
    },
  })
  const { scriptLoaded: googleLoaded } = useGoogleLoader()
  const me = useUser()
  const admin = useAdmin()
  const history = useHistory()

  const addToast = useToast()

  const [coordinates, setCoordinates] = useState<Nullable<Coordinates>>({
    lat: null,
    lng: null,
  })
  const [attachments, setAttachments] = useState<FileAttachment[]>([])

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formEdited,
    updateForm,
    formChangeHandler: handler,
    submitHandler: submit,
  } = useForm<EditIssueForm>({
    values: {
      user: me.id,
      responsibleUser: null,
      closed: false,
      archived: false,
      category: '',
      date: new Date(),

      placename: '',
      regNr: null,
      description: '',
      damage: '',
      locationDescription: '',

      estimatedCost: 0,
      suggestedSolution: '',

      actualCost: null,
      solution: '',
      severity: null,
      riskAspect: null,
    },
    validators: {
      category: val => (!!val ? null : translations.validation.required),
      placename: val => (!!val ? null : translations.validation.required),
      description: val => (!!val ? null : translations.validation.required),
      damage: val => (!!val ? null : translations.validation.required),
      suggestedSolution: val =>
        !!val ? null : translations.validation.required,
      riskAspect: val => (!!val || !hasRiskAspects ? null : translations.validation.required),
      severity: val => (!!val ? null : translations.validation.required),
    },
    config: {
      onlyValidateAfterFirstUpdate: true,
      initAsInvalid: true,
    },
  })

  const { data: riskAspectData } = useQuery<AllIssueRiskAspectsQuery, AllIssueRiskAspectsQueryVariables>(
    ALL_ISSUE_RISK_ASPECTS_QUERY,
  )

  const hasRiskAspects = (riskAspectData?.allIssueRiskAspects.edges.length ?? 0) > 0

  const mutations = useCreateIssueMutation()

  function handleLocationUpdate(
    results: google.maps.GeocoderResult[] | null,
    status: google.maps.GeocoderStatus
  ) {
    if (status !== google.maps.GeocoderStatus.OK || !results) return

    const [place] = results
    if (!place || !place.formatted_address) return

    const [lat, lng] = [
      place.geometry.location.lat(),
      place.geometry.location.lng(),
    ]
    setCoordinates({
      lat,
      lng,
    })

    updateForm({
      placename: place.formatted_address,
    })
  }

  function handleCancel() {
    history.push('/issues')
  }

  function handleSubmit(values: EditIssueForm) {
    if (!values.category) return
    if (typeof google === 'undefined' || !googleLoaded)
      throw new Error('Issue submit: google is undefined')

    function runMutation(input: CreateIssueInput) {
      try {
        mutations.create({
          variables: { input },
        })
      } catch {}
    }

    let input: CreateIssueInput = {
      user: (admin ? values.user : me.id) ?? me.id,
      category: values.category,
      timeOfIssue: formatISO(values.date),

      vehiclePlateNumber: values.regNr,
      issueDescription: values.description,
      damageDescription: values.damage,
      solutionSuggestion: values.suggestedSolution,
      costEstimation: values.estimatedCost,

      locationPlacename: values.placename,
      locationLatitude: 0,
      locationLongitude: 0,
      locationDescription: values.locationDescription,

      attachments: attachments.map(attachment => ({
        file: attachment.file,
        name: attachment.name,
      })),

      actualCost: values.actualCost,
      solution: values.solution,
      closed: values.closed,
      severity: values.severity,
    }

    if (coordinates.lat === null || coordinates.lng === null) {
      const service = new google.maps.Geocoder()
      service.geocode(
        {
          address: values.placename,
        },
        (results, status) => {
          if (status !== google.maps.GeocoderStatus.OK) {
            addToast('error', translations.validation.couldNotFindLocation)
            return
          }
          const [place] = results ?? [null]
          if (status !== google.maps.GeocoderStatus.OK || !place) {
            runMutation(input)
            return
          }

          const [lat, lng] = [
            place.geometry.location.lat(),
            place.geometry.location.lng(),
          ]
          input = {
            ...input,
            locationLatitude: lat,
            locationLongitude: lng,
          }
          runMutation(input)
        }
      )
    } else {
      input = {
        ...input,
        locationLatitude: coordinates.lat,
        locationLongitude: coordinates.lng,
      }
      runMutation(input)
    }
  }

  const isLoading = mutations.loading

  return (
    <Wrapper>
      <header>
        <div className="header">
          <h2>
            {admin ? translations.createIssueFor : translations.createIssue}
          </h2>
          {admin && (
            <UserSelect
              className="user-select"
              value={form.user}
              fullWidth
              height="3rem"
              color="dark"
              filterBackground="quaternary"
              onChange={handler('user')}
            />
          )}
        </div>
      </header>

      <Content>
        {isLoading && (
          <Loading>
            <CenteredLoader marginTop="0" size={72} thickness={8} />
          </Loading>
        )}

        <Map
          editing
          latitude={coordinates.lat}
          longitude={coordinates.lng}
          onClick={handleLocationUpdate}
        />

        <CreateMainInfo form={form} errors={errors} onChange={handler} />

        <CreateDetails
          form={form}
          errors={errors}
          onLocationUpdate={handleLocationUpdate}
          onChange={handler}
        />

        <Attachments
          editing
          attachments={[]}
          onUpdate={add => setAttachments(add)}
        />
      </Content>
      <Buttons>
        <Button disabled={isLoading} background="gray8" onClick={handleCancel}>
          {translations.cancel}
        </Button>
        <Button
          disabled={isLoading || !formValid || !formEdited}
          onClick={submit(handleSubmit)}
        >
          {translations.save}
        </Button>
      </Buttons>
    </Wrapper>
  )
}
