import { Button, ButtonProps } from 'components'
import { Loader } from '@ur/react-components'
import { PermissionsRequired } from 'containers/permission-containers'
import {
  TerminalEntryNode,
  useTerminalEntriesMutations,
} from 'modules/terminal'
import { UserNode } from 'modules/users'
import React from 'react'
import styled from 'styled-components'
import { PERMISSIONS } from 'util/permissions'

interface StyledButtonProps extends ButtonProps {
  checkIn: boolean
  loading: boolean
}
const StyledButton = styled(Button)<StyledButtonProps>`
  background-color: ${props =>
    props.checkIn
      ? props.theme.colors.secondary
      : props.theme.colors.activitiesRed};

  color: white;
  font-size: 32px;
  font-weight: 900;
  text-decoration: none;
  border-radius: 50%;
  width: 80px !important;
  height: 80px !important;
  padding: 0;

  font-family: 'Font Awesome 5 Pro';
  &::before {
    content: ${props => (props.checkIn ? '"\f04b"' : '"\f04d"')};
    ${props => props.loading && 'margin-left: 0.1rem;'}
    position: relative;
    left: calc(50% - 14px);

    ${props => props.loading && 'content: "";'}
  }
`

interface CheckInOutTerminalProps {
  user: UserNode
  latestEntry: TerminalEntryNode | null
  lastCheckIn: Date
  isWorking: boolean
  isOnNetwork: boolean
  loading: boolean
}

export const CheckInOutTerminal: React.FC<CheckInOutTerminalProps> = ({
  user,
  latestEntry,
  lastCheckIn,
  isWorking,
  isOnNetwork,
  loading,
}) => {
  const mutations = useTerminalEntriesMutations(
    latestEntry,
    lastCheckIn,
    user.id
  )
  const anyLoading = loading || mutations.loading
  const isDisabled = anyLoading || mutations.disableButton
  return (
    <>
      {isOnNetwork ? (
        loading ? (
          <StyledButton loading={true} checkIn={false} disabled>
            <Loader.Spinner size={36} thickness={2} />
          </StyledButton>
        ) : isWorking ? (
          <PermissionsRequired
            permissions={PERMISSIONS.terminal.change.terminalentry}
          >
            <StyledButton
              checkIn={false}
              loading={anyLoading}
              disabled={isDisabled}
              onClick={mutations.checkOut}
            >
              {anyLoading && <Loader.Spinner size={36} thickness={2} />}
            </StyledButton>
          </PermissionsRequired>
        ) : (
          <PermissionsRequired
            permissions={PERMISSIONS.terminal.add.terminalentry}
          >
            <StyledButton
              checkIn={true}
              loading={anyLoading}
              disabled={isDisabled}
              onClick={mutations.checkIn}
            >
              {anyLoading && <Loader.Spinner size={36} thickness={2} />}
            </StyledButton>
          </PermissionsRequired>
        )
      ) : (
        <StyledButton disabled loading={anyLoading} checkIn={true} />
      )}
    </>
  )
}
