import {
  ComponentSwitch,
  Icon,
  SelectOption,
  useToast,
} from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import {
  Checkbox,
  DateRangePicker,
  ExtendedIconProps,
  Input,
  MonthPicker,
  Select,
  Toggle,
  UserSelect,
} from 'components'
import { responsiveExportGridAreas } from 'modules/companies/util'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import { handleDateRangeChange } from 'util/date/handleDateRangeChange'
import { useExportOptions } from 'modules/exports/hooks'
import { Field, Form, FormButton, Section } from '../common'
import { ExportCardProps } from '../types'
import { useMassIssueExportForm } from 'modules/issues'
import { ExportOption } from 'modules/exports'
import { ShallowUserNode } from 'modules/users'
import { emailValidation } from 'util/forms'
import { ExportSectionHeader, MassExportSectionHeader } from './components'

const StyledCheckbox = styled(Checkbox)`
  justify-content: left;
`

const Users = styled.div`
  border-radius: 5px;
  overflow: hidden;

  .userItem {
    padding: 0.6rem;
    border-bottom: 1px solid ${props => props.theme.colors.gray9};

    display: flex;
    align-items: center;
    .text {
      flex: 1;
    }
  }
  .userItem:last-child {
    border-bottom: none;
  }
  ${props => props.theme.layout.desktop} {
    max-height: 300px;
    overflow-y: auto;
  }
`

export const ExportCompanyIssuesCard: React.VFC<ExportCardProps> = ({
  loading,
  onLoadingChangedCallback,
}) => {
  const translations = useTranslate({
    issues: 'common.issues',
    exportAll: 'settings.export-all-issues-for-period',
    export: 'common.export',
    period: 'common.period',
    exportOption: 'common.export-type',
    selectFromList: 'issues.select-recipient-from-list',
    selectedRecipients: 'issues.selected-recipients',
    addWithEmail: 'issues.add-recipient-with-email',
    errorEmail: 'error.valid-email-required',
    includeArchived: 'common.include-archived',

    toasts: {
      emailAlreadySelected: 'common.email-already-selected',
    },
  })

  /**
   * * Info: The email field is handled seperately because a race condition
   * * when inside same form as the rest */
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState<string | null>(null)
  const addToast = useToast()

  const {
    loading: formLoading,
    form,
    submit,
    updateForm,
    handleExport,
    handler,
  } = useMassIssueExportForm(() => onLoadingChangedCallback(false))

  const exportOptions = useExportOptions()

  function handleUserSelect(
    id: string | null,
    userOption: SelectOption<string, ShallowUserNode> | null
  ) {
    const user = userOption?.extra
    if (user && form.recipients && !form.recipients.includes(user.email)) {
      updateForm({ recipients: [...form.recipients, user.email] })
    } else {
      addToast('warning', translations.toasts.emailAlreadySelected)
    }
  }

  function handleChangeEmail(value: string) {
    if (!value) {
      setEmailError(null)
      setEmail('')
      return
    }
    const validEmail = emailValidation(value)
    setEmailError(validEmail ? null : translations.errorEmail)
    setEmail(value)
  }

  function handleAddEmail() {
    if (form.recipients) {
      const addEmail = email && !emailError && !form.recipients?.includes(email)

      if (email && form.recipients?.includes(email)) {
        addToast('warning', translations.toasts.emailAlreadySelected)
      }

      if (addEmail) {
        updateForm({ recipients: [...form.recipients, email] })
        setEmail('')
      }
    }
  }

  function handleDeleteEmail(email: string) {
    if (form.recipients) {
      const emails = form.recipients.filter(value => value !== email)
      updateForm({ recipients: [...emails] })
    }
  }

  function handleClear() {
    setEmail('')
    setEmailError(null)
  }

  const iconLeftProps: ExtendedIconProps | undefined =
    email !== ''
      ? {
          icon: 'times',
          hoverColor: 'error',
          cursor: 'pointer',
          onClick: handleClear,
        }
      : undefined

  const iconRightProps: ExtendedIconProps | undefined =
    email !== '' && emailError === null
      ? {
          icon: 'plus-circle',
          hoverColor: 'primary',
          cursor: 'pointer',
          onClick: handleAddEmail,
        }
      : undefined

  useEffect(() => {
    if (!formLoading || loading === formLoading) return
    onLoadingChangedCallback(formLoading)
  }, [formLoading, loading, onLoadingChangedCallback])
  const areas = !isMobile
    ? `
        ${responsiveExportGridAreas(true, false)}
        'includeArchived includeArchived includeArchived'
      `
    : `
        ${responsiveExportGridAreas(true, false)}
        'includeArchived'
      `
  const formButtons: FormButton[] = [
    {
      text: (
        <>
          <Icon
            icon="download"
            type="light"
            color="primary"
            margin="0 0.5ch 0 0"
          />
          {translations.export}
        </>
      ),
      type: 'submit',
      buttonProps: {
        fullWidth: isMobile,
        disabled: loading,
      },
      onClick: submit(handleExport),
    },
  ]

  return (
    <>
      <ExportSectionHeader>{translations.issues}</ExportSectionHeader>
      <Section>
        <MassExportSectionHeader>
          {translations.exportAll}
          <Toggle
            value={form.useDateRange}
            thumbBackground="secondary"
            iconMode="svg"
            localOnIconProps={{
              icon: 'calendar-selection',
              fill: form.useDateRange ? 'white' : 'primary',
            }}
            localOffIconProps={{
              icon: 'calendar-month',
              fill: !form.useDateRange ? 'white' : 'primary',
            }}
            onChange={() => handler('useDateRange')(!form.useDateRange)}
          />
        </MassExportSectionHeader>

        <Form
          grid={{
            columns: !isMobile ? '3fr 2fr' : '1fr',
            areas: areas,
          }}
          buttons={formButtons}
        >
          <Field area="month">
            <label>{translations.period}</label>
            <ComponentSwitch>
              <ComponentSwitch.Case case={form.useDateRange}>
                <DateRangePicker
                  value={[form.dateStart, form.dateEnd]}
                  grayed={false}
                  onChange={handleDateRangeChange(updateForm)}
                />
              </ComponentSwitch.Case>
              <ComponentSwitch.Case default>
                <MonthPicker
                  value={form.month}
                  light
                  futureYears={0}
                  noYearCycle
                  onChange={handler('month')}
                />
              </ComponentSwitch.Case>
            </ComponentSwitch>
          </Field>

          <Field area="exportOption">
            <label>{translations.exportOption}</label>
            <Select
              value={form.exportOption}
              options={exportOptions}
              fullWidth
              height="3.875rem"
              onChange={handler('exportOption')}
            />
          </Field>
          {form.exportOption === ExportOption.MAIL && (
            <>
              <Field>
                <label>{translations.selectFromList}</label>
                <UserSelect
                  value={'email'}
                  fullWidth
                  height="3.875rem"
                  filterBackground="quaternary"
                  optionHoverBackground="quaternary"
                  onChange={handleUserSelect}
                />
              </Field>
              <Field>
                <label>{translations.addWithEmail}</label>

                <Input
                  value={email}
                  error={emailError}
                  fullWidth
                  autoComplete="off"
                  iconLeftProps={iconLeftProps}
                  iconRightProps={iconRightProps}
                  onChange={handleChangeEmail}
                  onEnter={handleAddEmail}
                />
              </Field>
              {form.recipients && form.recipients.length > 0 && (
                <Field>
                  <label>{translations.selectedRecipients}</label>
                  <Users>
                    {form.recipients.map(email => (
                      <div className="userItem" key={email}>
                        <div className="text">{email}</div>
                        <Icon
                          className="close"
                          icon="times"
                          type="light"
                          size="1.2rem"
                          color="gray6"
                          hoverColor="secondary"
                          cursor="pointer"
                          onClick={() => handleDeleteEmail(email)}
                        />
                      </div>
                    ))}
                  </Users>
                </Field>
              )}
            </>
          )}
          <Field>
            <label>{translations.includeArchived}</label>
            <StyledCheckbox
              label={translations.includeArchived}
              checked={form.includeArchived}
              onChange={handler('includeArchived')}
            />
          </Field>
        </Form>
      </Section>
    </>
  )
}
