import { PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { RegularModal } from 'components'
import { DetectBlur } from 'containers/util-containers'
import uniqueId from 'lodash/uniqueId'
import {
  ChecklistTemplateForm,
  ChecklistTemplateQuery,
  ChecklistTemplateQueryVariables,
} from 'modules/checklists'
import { CHECKLIST_TEMPLATE_QUERY } from 'modules/checklists/queries'
import { UserTypeNode } from 'modules/users'
import React from 'react'
import { useQuery } from '@apollo/client'
import { NewChecklistItemPrefix } from '../consts'
import { ChecklistTemplatePromptForm } from './ChecklistTemplatePromptForm'
import { ChecklistMode } from './consts'

interface ChecklistTemplatePromptProps {
  checklistTemplateId?: string
  userTypes: UserTypeNode[]
  onSubmit: PromptResolve<ChecklistTemplateForm | null>
}

export const ChecklistTemplatePrompt: React.VFC<ChecklistTemplatePromptProps> =
  ({ checklistTemplateId, userTypes, onSubmit }) => {
    const translations = useTranslate({
      createChecklistTemplate: 'settings.create-checklist-template',
      editChecklistTemplate: 'settings.edit-checklist-template',

      username: 'common.username',
      password: 'common.password',
      active: 'common.active',
      userTypes: 'common.user-types',

      save: 'common.save',
      create: 'common.create-alt',

      validation: {
        required: 'common.required',
      },
    })

    const { data, loading } = useQuery<
      ChecklistTemplateQuery,
      ChecklistTemplateQueryVariables
    >(CHECKLIST_TEMPLATE_QUERY, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip: !checklistTemplateId,
      variables: {
        id: checklistTemplateId ?? '',
      },
      onCompleted(data) {
        if (data?.checklistTemplate) {
          const checklistTemplate = data.checklistTemplate
          updateForm({
            title: checklistTemplate?.title ?? '',
            userTypes: checklistTemplate?.userTypes.map(type => type.id) ?? [],
            mode: checklistTemplate?.mode ?? ChecklistMode.Daily,
            items: {
              ...checklistTemplate?.items
                .map(item => ({
                  [item.id]: item.text,
                }))
                .concat([{ [uniqueId(NewChecklistItemPrefix)]: '' }])
                .reduce((a, b) => ({
                  ...a,
                  ...b,
                })),
            },
            itemsRemove: [],
          })
          updateInitialValues({
            title: checklistTemplate.title,
            userTypes: checklistTemplate.userTypes.map(type => type.id),
            mode: checklistTemplate?.mode ?? ChecklistMode.Daily,
            items: {
              ...checklistTemplate.items
                .map(item => ({
                  [item.id]: item.text,
                }))
                .reduce((a, b) => ({
                  ...a,
                  ...b,
                })),
            },
            itemsRemove: [],
          })
        }
      },
      onError: console.error,
    })

    const editing = typeof checklistTemplateId !== 'undefined'
    const validateNonEmpty = (value: string) =>
      !!value ? null : translations.validation.required

    const validateNonEmptyItem = (values: Record<string, string>) =>
      Object.values(values)
        .slice(0, Object.values(values).length - 1)
        .includes('') || Object.values(values).length === 1
        ? translations.validation.required
        : null

    const {
      formValues: form,
      formErrors: errors,
      formValid,
      formChangeHandler: handler,
      submitHandler: submit,
      updateForm,
      updateInitialValues,
      resetForm,
    } = useForm<ChecklistTemplateForm>({
      values: {
        title: '',
        userTypes: [],
        mode: ChecklistMode.Daily,
        items: {},
        itemsRemove: [],
      },
      validators: {
        title: validateNonEmpty,
        items: validateNonEmptyItem,
      },
      config: {
        initAsInvalid: !editing,
      },
    })

    const initialItems =
      editing && !loading
        ? data?.checklistTemplate?.items.map(item => item.id) ?? []
        : []

    return (
      <DetectBlur active onClickOutside={() => onSubmit(null)}>
        <RegularModal
          title={
            editing
              ? translations.editChecklistTemplate
              : translations.createChecklistTemplate
          }
          overflowY="auto"
          width="600px"
          submitText={editing ? translations.save : translations.create}
          submitDisabled={!formValid}
          onCancel={() => {
            resetForm()
            onSubmit(null)
          }}
          onSubmit={submit(onSubmit)}
          loading={loading}
        >
          <ChecklistTemplatePromptForm
            onUpdate={handler}
            form={form}
            errors={errors}
            userTypes={userTypes}
            onUpdateForm={updateForm}
            initialItems={initialItems}
          />
        </RegularModal>
      </DetectBlur>
    )
  }
