import { Icon } from '@ur/react-components'
import groupBy from 'lodash/groupBy'
import React, { useMemo } from 'react'
import styled from 'styled-components'

const Wrapper = styled.table`
  width: 100%;
  border-collapse: collapse;

  tbody tr:nth-child(odd) {
    background-color: ${props => props.theme.colors.quaternary};
  }
  tbody td {
    padding: 6px 6px 3px;

    &:first-of-type {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-of-type {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`
const HeaderTitle = styled.td`
  font-weight: 600;
  font-size: 0.9rem;
  text-transform: uppercase;
`
const Strike = styled.td.attrs({
  colSpan: 9999,
})`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: calc(100% - 2rem);
    height: 2px;
    left: 1rem;
    top: calc(50% - 1px);
    background-color: ${props => props.theme.colors.primary}22;
  }
`
const Row = styled.tr`
  &:hover td {
    color: ${props => props.theme.colors.secondary} !important;
  }
`
const RowTitle = styled.td`
  color: ${props => props.theme.colors.gray4};
`
const CheckCell = styled.td`
  & > div {
    display: flex;
    justify-content: center;
  }
`

export interface CheckedTableValue {
  column: string
  row: string
  /**
   * Determines wheter the cell should be checked or not, if it can't be toggled
   * it is null.
   */
  checked: boolean | null
}

export interface CheckedTableRow {
  type: 'header' | 'regular'
  title: string | JSX.Element
  value: string
  noStrike?: boolean
}

export interface CheckedTableCell {
  value: string
}

interface CheckedTableProps {
  value: CheckedTableValue[]

  columns: (string | JSX.Element)[]
  rows: CheckedTableRow[]

  onChange: (collection: CheckedTableValue[]) => void
}

export const CheckedTable: React.VFC<CheckedTableProps> = ({
  value,

  columns,
  rows,

  onChange,
}) => {
  const valuesByRow = useMemo(() => groupBy(value, 'row'), [value])

  function toggle(current: CheckedTableValue) {
    const valueIdx = value.findIndex(
      val => val.row === current.row && val.column === current.column
    )
    if (valueIdx < 0) return

    const newValue = [...value]
    newValue.splice(valueIdx, 1, {
      ...newValue[valueIdx],
      checked: !current.checked,
    })

    onChange(newValue)
  }

  return (
    <Wrapper>
      <thead>
        <tr>
          <th></th>
          {columns.map((column, idx) => (
            <th key={idx}>{column}</th>
          ))}
        </tr>
      </thead>

      <tbody>
        {rows.map(row => {
          const values = valuesByRow[row.value] ?? []

          return row.type === 'header' ? (
            <tr key={row.value}>
              <HeaderTitle>{row.title}</HeaderTitle>
              {!row.noStrike ? <Strike /> : <td colSpan={9999} />}
            </tr>
          ) : (
            <Row key={row.value}>
              <RowTitle>{row.title}</RowTitle>

              {values.map(value => (
                <CheckCell key={value.column + value.row}>
                  {value.checked !== null ? (
                    <div>
                      <Icon
                        icon={value.checked ? 'check' : 'times'}
                        type="light"
                        color={value.checked ? 'success' : 'red'}
                        cursor="pointer"
                        onClick={() => toggle(value)}
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                </CheckCell>
              ))}
            </Row>
          )
        })}
      </tbody>
    </Wrapper>
  )
}
