import { useQuery } from '@apollo/client'
import { usePrompt } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { CenteredLoader, ExportButton, Title } from 'components'
import { Card } from 'components/Card'
import { CenteredErrorMessage } from 'components/CenteredErrorMessage'
import { ErrorFallback } from 'components/ErrorFallback'
import React, { useEffect } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { ErrorBoundary } from 'react-error-boundary'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import { format } from 'util/date-fns'
import { useBreadcrumbs, useCompany, useUser } from 'util/hooks'
import { simplifyLocationString } from 'util/string'
import {
  ExportRoutePlanModal,
  RouteDetails,
  RouteInstructions,
  RouteMapHERE,
  RoutePlanMessage,
  RouteRestrictions,
} from '../components/RoutePlan'
import { RoutePlanTaskValue } from '../consts'
import { useRoutePlannerMutations } from '../mutations.hooks'
import { ROUTE_PLAN_QUERY } from '../queries'
import { ExportRoutePlanForm } from '../types'
import { RoutePlanQuery, RoutePlanQueryVariables } from '../types.graphql'

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  ${props => props.theme.layout.mobile} {
    flex-direction: column;
  }
`

const Wrapper = styled.div`
  ${props => props.theme.layout.default};

  header {
    margin-bottom: 0.5rem;

    h2 {
      margin: 0 0 0 0.5rem;
    }
  }
`

const Content = styled(Card)`
  display: grid;
  margin-top: 1rem;
  grid-template-columns: 2fr minmax(300px, 1fr);
  grid-template-rows: 500px auto auto;
  grid-template-areas:
    'map instructions'
    'routedetails instructions'
    'restriction  .';
  padding: 0;

  ${props => props.theme.media.mobile} {
    grid-template-columns: 100%;
    grid-template-rows: 280px repeat(3, auto);
    grid-template-areas:
      'map'
      'routedetails'
      'restriction'
      'instructions';

    overflow: hidden;
  }
`

export const RoutePlan: React.VFC = () => {
  const translations = useTranslate({
    loading: 'common.loading',
    width: 'common.width',
    height: 'common.height',
    length: 'common.length',
    start: 'common.start',
    until: 'common.until',
    distance: 'common.distance',
    download: 'common.download',
    details: 'common.details',
    unknown: 'common.unknown',

    axleWeightRestrictions: 'route-planner.axle-weight-restrictions',
    generalWeightRestrictions: 'route-planner.general-weight-restriction',
    routePlan: 'route-planner.route-plan',
    estimatedDrivingDuration: 'route-planner.estimated-driving-duration',

    singleAxle: 'route-planner.single-axle',
    tandemAxle: 'route-planner.tandem-axle',
    tripleAxle: 'route-planner.triple-axle',

    tonne: 'route-planner.tonne',

    restrictions: 'route-planner.route-restrictions',
  })

  const history = useHistory()

  const { routePlanId } = useParams<{ routePlanId: string }>()
  const { setOverride } = useBreadcrumbs()
  const { exportRoutePlanMutation, exportLoading } = useRoutePlannerMutations()
  const addPrompt = usePrompt()
  const me = useUser()
  const company = useCompany()

  const { data, loading, error } = useQuery<
    RoutePlanQuery,
    RoutePlanQueryVariables
  >(ROUTE_PLAN_QUERY, {
    variables: {
      id: routePlanId,
    },
    onCompleted(data) {
      if (!data) return

      const { routePlan } = data
      if (!routePlan) history.push('/route-planner')

      const date = format(new Date(routePlan.createdAt), 'PP')

      const postFix = routePlan?.user?.fullName ?? translations.unknown

      setOverride(routePlanId, `${date} - ${postFix}`)
    },
  })

  const handleExportRoutePlan = async () => {
    const { data } = await addPrompt<ExportRoutePlanForm | null>(resolve => (
      <ExportRoutePlanModal onSubmit={resolve} />
    ))
    if (!data) return

    exportRoutePlanMutation({
      variables: {
        userId: me.id,
        companyId: company.id,
        routePlan: routePlanId,
        exportOption: data.exportOption,
      },
    })
  }

  useEffect(() => {
    if (!!data) return
    setOverride(routePlanId, translations.loading)
  })

  if (error) return <CenteredErrorMessage />

  if (loading || !data) return <CenteredLoader />

  const { routePlan } = data

  const startCoordinates = routePlan?.startCoordinates ?? {
    lat: 63.74570819703677,
    lng: 11.298897210111281,
  }
  const endCoordinates = routePlan?.endCoordinates ?? {
    lat: 63.74570819703677,
    lng: 11.298897210111281,
  }

  const routePlanTitle = `${translations.routePlan}: ${simplifyLocationString(
    routePlan?.startLocationName ?? ''
  )} - ${simplifyLocationString(routePlan?.endLocationName ?? '')}`

  const shouldRenderRoute =
    routePlan.taskStatus !== RoutePlanTaskValue.NO_ROUTE &&
    routePlan.taskStatus !== RoutePlanTaskValue.FAILURE

  return (
    <Wrapper>
      <HeaderContainer>
        <Title.H1>{routePlanTitle}</Title.H1>

        {shouldRenderRoute && (
          <ExportButton
            iconLeftProps={{ icon: 'download' }}
            fullWidth={isMobileOnly}
            disabled={exportLoading}
            onClick={handleExportRoutePlan}
          >
            {translations.download}
          </ExportButton>
        )}
      </HeaderContainer>
      <RoutePlanMessage routePlan={routePlan} />

      <Content>
        {shouldRenderRoute && (
          <>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <RouteMapHERE
                gridArea="map"
                startLocation={startCoordinates}
                endLocation={endCoordinates}
              />
            </ErrorBoundary>

            <RouteInstructions gridArea="instructions" />
          </>
        )}
        <RouteDetails gridArea="routedetails" routePlan={routePlan} />
        <RouteRestrictions routePlan={routePlan} gridArea="restriction" />
      </Content>
    </Wrapper>
  )
}
