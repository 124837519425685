import { useForm, useTranslate } from '@ur/react-hooks'
import { Button as BaseButton, Input, ModuleTitle, Toggle } from 'components'
import { Card } from 'components/Card'
import { PlainLink } from 'components/Link'

import { PermissionsRequired } from 'containers/permission-containers'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { PERMISSIONS } from 'util/permissions'
import { FormTableFilter } from '../types'
import { FormTable } from '../components'

const Wrapper = styled.div`
  ${props => props.theme.layout.default};
`
const Controls = styled(Card)`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  gap: 1rem;

  margin-bottom: 1rem;
  padding: 1rem;

  ${props => props.theme.media.mobile} {
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;
    gap: 0.5rem;

    *:first-child {
      grid-column: 1 / -1;
    }
  }
`
const Button = styled(BaseButton)`
  ${props => props.theme.media.mobile} {
    width: 100%;
    padding: 0 1rem 0 2rem;
  }
`

interface FormCreatorProps {}

export const FormCreator: React.VFC<FormCreatorProps> = () => {
  const translations = useTranslate({
    filter: 'common.filter',
    formCreator: 'common.formcreator',
    createForm: 'common.create-form',
    create: 'common.create-alt',
  })

  const { formValues: filter, formChangeHandler: handler } =
    useForm<FormTableFilter>({
      values: {
        query: '',
        archived: false,
        orderBy: 'name',
      },
    })

  return (
    <Wrapper>
      <ModuleTitle>{translations.formCreator}</ModuleTitle>
      <Controls>
        <Input
          value={filter.query}
          type="search"
          placeholder={translations.filter}
          onChange={handler('query')}
        />

        <Toggle
          value={filter.archived}
          offIconProps={{
            icon: 'book-open',
          }}
          onIconProps={{
            icon: 'box-open',
          }}
          onChange={handler('archived')}
        />

        <PermissionsRequired
          permissions={PERMISSIONS.formcreator.add.formtemplate}
        >
          <PlainLink to="/formcreator/create">
            <Button iconLeftProps={{ icon: 'plus' }}>
              {isMobileOnly ? translations.create : translations.createForm}
            </Button>
          </PlainLink>
        </PermissionsRequired>
      </Controls>

      <FormTable filter={filter} onSort={handler('orderBy')} />
    </Wrapper>
  )
}
