import { useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import ScrollContext from 'context/ScrollContext'

export const ScrollToTop: React.FC = () => {
  const scrollRef = useContext(ScrollContext)
  const { pathname } = useLocation()

  useEffect(() => {
    if (!scrollRef || !('current' in scrollRef) || !scrollRef.current) return
    scrollRef.current.scrollTo(0, 0)
  }, [pathname, scrollRef])

  return null
}
