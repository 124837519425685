import { useLazyQuery } from '@apollo/client'
import { Loader } from '@ur/react-components'
import { useClickOutside, useTranslate } from '@ur/react-hooks'
import clamp from 'lodash/clamp'
import { UserProfileLink } from 'modules/users/UserProfile/UserProfileLink'
import UserThumbOrInitials from 'modules/users/UserThumbOrInitials'
import React, { useCallback, useRef, useState } from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import styled, { css } from 'styled-components'
import { ZIndexRange } from 'types/style'
import { useCompany, useInfiniteScroll, useOnErrorAuto } from 'util/hooks'
import { THREAD_SEEN_BY_QUERY } from '../../queries'
import {
  ThreadSeenByQuery,
  ThreadSeenByQueryVariables,
} from '../../types.graphql'
import { SeenByModal } from './SeenByModal'

interface WrapperProps {
  /** Fill percentage, between 0 and 1 */
  fill: number
  width: string
  clickable: boolean
  open: boolean
}
const Wrapper = styled.div<WrapperProps>`
  position: relative;

  ${props =>
    props.clickable &&
    !props.open &&
    css`
      cursor: pointer;

      &:hover {
        & > div {
          box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
        }

        p,
        em {
          color: ${props => props.theme.colors.primary};
        }
      }
    `}

  & > div {
    box-sizing: content-box;
    position: relative;
    width: ${props => props.width};
    height: 6px;

    background-color: ${props => props.theme.colors.gray9};
    border: 1px solid ${props => props.theme.colors.gray9};
    border-radius: 999px;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: calc(100% * ${props => props.fill});
      height: 100%;

      background-color: ${props =>
        props.fill === 1
          ? props.theme.colors.secondary
          : props.theme.colors.gray6};
      border-radius: 999px;
    }
  }
  p {
    margin: 6px 0 0;
    font-size: 0.8em;
    color: ${props => props.theme.colors.gray6};

    em {
      font-style: normal;
      font-weight: 700;
      color: ${props => props.theme.colors.gray5};
    }
  }
`
const SeenByPopup = styled.aside`
  position: absolute;
  z-index: ${ZIndexRange.Dropdowns};
  top: -2rem;
  right: -2rem;

  display: grid;
  grid-template-rows: auto 1fr;

  min-width: calc(100% + 4rem);
  max-height: 400px;
  padding: 2rem 2rem 1rem;

  background: white;
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  box-shadow: ${props => props.theme.shadow.default};

  div.loader {
    position: absolute;
    top: 6px;
    left: 0;
    width: 100%;

    display: flex;
    justify-content: center;
  }
  div.bar {
    display: flex;
    justify-content: flex-end;
  }
`
const SeenByList = styled.ul`
  list-style: none;
  margin: 1rem 0 0 0;
  padding: 0;
  overflow-y: auto;
  overscroll-behavior: none;

  li {
    display: flex;
    align-items: center;
    padding: 8px 0;

    & > div {
      margin-right: 1ch;
    }
    a {
      color: inherit;
      font-size: 0.8rem;
    }
  }
`

const PAGE_SIZE = 20

interface SeenBarProps {
  views: number
  verbose?: boolean
  clickable?: boolean
  asModal?: boolean
  threadId?: string

  width?: string
}

export const SeenBar: React.VFC<SeenBarProps> = ({
  views,
  verbose = false,
  clickable = false,
  asModal = false,
  threadId,

  width = '128px',
}) => {
  if (clickable && typeof threadId === 'undefined')
    throw new Error('SeenBar: threadId must be defined if clickable === true')

  const { countOfTotal, countOfTotalVerbose } = useTranslate({
    countOfTotal: ['common.count-of-total', { count: <em />, total: 0 }],
    countOfTotalVerbose: [
      'common.count-of-total-verbose',
      { count: <em />, total: 0 },
    ],
  })

  const seenByRef = useRef<HTMLElement>(null)

  const onErrorAuto = useOnErrorAuto()
  const { numberOfUsers: userCount } = useCompany()

  const [showSeenBy, setShowSeenBy] = useState(false)
  const [listElement, setListElement] = useState<HTMLElement | null>(null)

  useClickOutside(seenByRef, () => setShowSeenBy(false), true)

  const [fetchSeenBy, { data, loading, fetchMore }] = useLazyQuery<
    ThreadSeenByQuery,
    ThreadSeenByQueryVariables
  >(THREAD_SEEN_BY_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onError: onErrorAuto(),
  })

  function handleClick() {
    if (typeof threadId === 'undefined' || !clickable) return

    setShowSeenBy(clickable)

    if (asModal || typeof data !== 'undefined') return

    fetchSeenBy({
      variables: {
        thread: threadId,
        first: PAGE_SIZE,
        orderBy: 'firstName',
      },
    })
  }

  const handleInfiniteScroll = useCallback(async () => {
    if (typeof data === 'undefined') return
    const pageInfo = data.thread.seenBy.pageInfo

    if (!pageInfo.hasNextPage || !pageInfo.endCursor) return
    if (!fetchMore) return

    try {
      await fetchMore({
        variables: {
          first: PAGE_SIZE,
          after: pageInfo.endCursor,
        },
      })
    } catch (e: any | unknown) {
      //@ts-ignore
      if (e.name === 'Invariant Violation') return
      throw e
    }
  }, [data, fetchMore])

  useInfiniteScroll(handleInfiniteScroll, 200, showSeenBy, listElement)

  const fillPct = clamp((views ?? 0) / (userCount || 1), 0, 1)
  const translator = verbose ? countOfTotalVerbose : countOfTotal
  const viewsEl = translator({
    count: <em>{views ?? 0}</em>,
    total: userCount,
  })

  const seenBy = data?.thread.seenBy.edges.map(edge => edge.node) ?? []

  return (
    <>
      {asModal && !!threadId && (
        <SeenByModal
          open={showSeenBy}
          threadId={threadId}
          onClose={() => setShowSeenBy(false)}
        />
      )}
      <Wrapper
        fill={fillPct}
        width={width}
        clickable={clickable}
        open={showSeenBy && !asModal}
        onClick={handleClick}
      >
        <div />
        <p>{viewsEl}</p>

        {showSeenBy && !asModal && (
          <SeenByPopup ref={seenByRef}>
            {loading && (
              <div className="loader">
                <Loader.Dots size={28} colorPrimary="secondary" />
              </div>
            )}

            <div className="bar">
              <SeenBar views={views} verbose={verbose} width={width} />
            </div>

            {!!seenBy.length && (
              <SeenByList>
                <ScrollBar containerRef={setListElement}>
                  {seenBy.map(user => (
                    <li key={user.id}>
                      <UserThumbOrInitials
                        user={user}
                        size={28}
                        fontSize="0.6rem"
                        fontWeight={600}
                      />
                      <UserProfileLink user={user} />
                    </li>
                  ))}
                </ScrollBar>
              </SeenByList>
            )}
          </SeenByPopup>
        )}
      </Wrapper>
    </>
  )
}
