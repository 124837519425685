import { ComponentSwitch, Icon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import {
  Checkbox,
  DateRangePicker,
  MonthPicker,
  MonthWeekToggle,
  Select,
  SelectMultipleOnSelectAll,
  Toggle,
} from 'components'
import { useMassActivityExportForm } from 'modules/activities/hooks'
import { responsiveExportGridAreas } from 'modules/companies/util'
import React, { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import { handleDateRangeChange } from 'util/date/handleDateRangeChange'
import { useExportFormatOptions, useExportOptions } from 'modules/exports/hooks'
import { Field, Form, FormButton, Section, SettingsLoader } from '../common'
import { ExportCardProps } from '../types'
import { SelectMultipleUsers } from 'components/Select/SelectMultipleUsers'
import { DropdownNames } from 'components/Select/types'
import { ExportFormat, ExportOption, ExportType } from 'modules/exports'
import { ExportSectionHeader, MassExportSectionHeader } from './components'
import { UserOrderBy } from 'modules/activities'

const SelectMultipleUsersWrapper = styled.div`
  position: relative;
  min-height: 54px;
`
export const ExportCompanyDriverActivitiesCard: React.VFC<ExportCardProps> = ({
  loading,
  onLoadingChangedCallback,
}) => {
  const translations = useTranslate({
    exportWeekly: 'activities.export-monthly-weekly',
    activities: 'common.activities',
    exportAll: 'settings.export-all-driver-activities',
    period: 'common.period',
    exportType: 'common.type',
    exportOption: 'common.export-type',

    export: 'common.export',
    orderBy: 'common.order-by',
    selectUsers: 'common.select-users',
    selectAll: 'common.select-all',
    includeAbsences: 'settings.include-absences',

    lastName: 'common.lastName',
    userType: 'common.user-type',
    externalId: 'common.external-id',
  })

  const {
    loading: formLoading,
    form,
    errors,
    submit,
    updateForm,
    handleExport,
    handler,
    exportTypes,
    excelExportTypes,
  } = useMassActivityExportForm(() => onLoadingChangedCallback(false))

  const exportOptions = useExportOptions(true)
  const excludeExcel =
    form.exportOption === ExportOption.DOWNLOAD ||
    !excelExportTypes.includes(form.exportType)
  const excludeFormats = excludeExcel 
    ? [ExportFormat.EXCEL, ExportFormat.EXCEL_FLAT, ExportFormat.POWEROFFICE, ExportFormat.VISMA, ExportFormat.TWENTYFOUR_SEVEN_OFFICE, ExportFormat.TRIPLETEX, ExportFormat.PDF_SALARY] 
    : form.exportType !== ExportType.MASS_ACTIVITIES ? [ExportFormat.VISMA, ExportFormat.POWEROFFICE, ExportFormat.TWENTYFOUR_SEVEN_OFFICE, ExportFormat.TRIPLETEX, ExportFormat.PDF_SALARY] : []
  const exportFormats = useExportFormatOptions(excludeFormats)

  const selectAllOnChange: SelectMultipleOnSelectAll = (
    options,
    setSelectAll
  ) => {
    return (value: boolean) => {
      if (value) {
        updateForm({ users: options.map(option => option.value) as string[] })
        setSelectAll(true)
      } else {
        updateForm({ users: [] })
        setSelectAll(false)
      }
    }
  }

  useEffect(() => {
    if (!formLoading || loading === formLoading) return
    onLoadingChangedCallback(formLoading)
  }, [formLoading, loading, onLoadingChangedCallback])
  const areas = !isMobile
    ? `
        ${responsiveExportGridAreas(true)}
        'monthWeek monthWeek sort'
        'includeAbsences includeAbsences includeAbsences'
      `
    : `
        ${responsiveExportGridAreas(true)}
        'monthWeek'
        'sort'
        'includeAbsences'
      `

  const formButtons: FormButton[] = [
    {
      text: (
        <>
          <Icon
            icon="download"
            type="light"
            color="primary"
            margin="0 0.5ch 0 0"
          />
          {translations.export}
        </>
      ),
      type: 'submit',
      buttonProps: {
        fullWidth: isMobile,
        disabled: loading,
      },
      onClick: submit(handleExport),
    },
  ]

  const orderByOptions = [
    { value: UserOrderBy.FAMILY_NAME, label: translations.lastName },
    { value: UserOrderBy.USER_TYPES, label: translations.userType },
    { value: UserOrderBy.INTERNAL_NUMBER, label: translations.externalId },
  ]

  useEffect(() => {
    if (
      (form.exportOption === ExportOption.DOWNLOAD ||
        !excelExportTypes.includes(form.exportType)) &&
      form.exportFormat === ExportFormat.EXCEL
    ) {
      updateForm({ exportFormat: ExportFormat.PDF })
    }
    if (
      form.exportType !== ExportType.MASS_ACTIVITIES &&
      form.exportFormat === ExportFormat.VISMA
    ) {
      updateForm({
        exportFormat: ExportFormat.PDF,
      })
    }
  }, [
    form.exportOption,
    form.exportFormat,
    updateForm,
    form.exportType,
    excelExportTypes,
  ])

  return (
    <>
      <ExportSectionHeader>
        {translations.activities}
        {formLoading && <SettingsLoader />}
      </ExportSectionHeader>
      <Section>
        <MassExportSectionHeader>
          {translations.exportAll}
          <Toggle
            value={form.useDateRange}
            thumbBackground="secondary"
            iconMode="svg"
            localOnIconProps={{
              icon: 'calendar-selection',
              fill: form.useDateRange ? 'white' : 'primary',
            }}
            localOffIconProps={{
              icon: 'calendar-month',
              fill: !form.useDateRange ? 'white' : 'primary',
            }}
            onChange={() => handler('useDateRange')(!form.useDateRange)}
          />
        </MassExportSectionHeader>

        <Form
          grid={{
            columns: !isMobile ? '1fr 2fr 2fr' : '1fr',
            areas: areas,
          }}
          buttons={formButtons}
        >
          <Field area="month">
            <label>{translations.period}</label>
            <ComponentSwitch>
              <ComponentSwitch.Case case={form.useDateRange}>
                <DateRangePicker
                  value={[form.dateStart, form.dateEnd]}
                  grayed={false}
                  onChange={handleDateRangeChange(updateForm)}
                />
              </ComponentSwitch.Case>
              <ComponentSwitch.Case default>
                <MonthPicker
                  value={form.month}
                  light
                  futureYears={0}
                  noYearCycle
                  onChange={handler('month')}
                />
              </ComponentSwitch.Case>
            </ComponentSwitch>
          </Field>

          <Field area="exportType">
            <label>{translations.exportType}</label>
            <Select
              value={form.exportType}
              options={exportTypes}
              fullWidth
              height="3.875rem"
              onChange={handler('exportType')}
            />
          </Field>

          <Field area="exportOption">
            <label>{translations.exportOption}</label>
            <Select
              value={form.exportOption}
              options={exportOptions}
              fullWidth
              height="3.875rem"
              onChange={handler('exportOption')}
            />
          </Field>

          <Field area="exportFormat">
            <label>{translations.exportOption}</label>
            <Select
              value={form.exportFormat}
              options={exportFormats}
              disabled={excludeExcel}
              fullWidth
              height="3.875rem"
              onChange={handler('exportFormat')}
            />
          </Field>

          <Field area="user">
            <label>{translations.selectUsers}</label>
            <SelectMultipleUsersWrapper>
              <SelectMultipleUsers
                selected={form.users ?? []}
                dropdownPageName={DropdownNames.ACTIVITIES}
                grayed={false}
                error={errors.users}
                width="100%"
                minWidth={isMobile ? '0' : '260px'}
                onChange={handler('users')}
                onSelectAll={selectAllOnChange}
                withSelectAll={true}
              />
            </SelectMultipleUsersWrapper>
          </Field>
          <Field area="monthWeek">
            <label>{translations.exportWeekly}</label>
            <MonthWeekToggle
              value={form.weekly}
              onChange={value =>
                updateForm({
                  weekly: value,
                })
              }
            />
          </Field>

          <Field area="sort">
            <label>{translations.orderBy}</label>
            <div>
              <Select value={form.orderBy} onChange={handler('orderBy')} options={orderByOptions} />
            </div>
          </Field>
          <Field area="includeAbsences">
            <label>{translations.includeAbsences}</label>
            <div>
              <Checkbox
                checked={form.includeAbsences ?? false}
                onChange={handler('includeAbsences')}
                label={translations.includeAbsences}
                inline
              />
            </div>
          </Field>
        </Form>
      </Section>
    </>
  )
}
