import { gql } from '@apollo/client'

export const PATCH_DYNAMIC_COLUMN_MUTATION = gql`
  mutation PatchDynamicColumn($id: ID!, $input: PatchDynamicColumnInput!) {
    patchDynamicColumn(id: $id, input: $input) {
      dynamicColumn {
        id
      }
    }
  }
`
