import { useMutation } from '@apollo/client'
import { useOnErrorAuto } from 'util/hooks'
import { CREATE_QUILL_MEDIA_MUTATION } from './queries'
import {
  CreateQuillMediaMutation,
  CreateQuillMediaMutationVariables,
} from './types'

export function useQuillMedia() {
  const onErrorAuto = useOnErrorAuto()

  const [createMedia, { loading }] = useMutation<
    CreateQuillMediaMutation,
    CreateQuillMediaMutationVariables
  >(CREATE_QUILL_MEDIA_MUTATION, {
    onError: onErrorAuto(),
  })

  function handleUpload(file: File) {
    return new Promise((resolve, reject) => {
      createMedia({
        variables: {
          input: {
            name: file.name,
            file: file,
          },
        },
      })
        .then(({ data }) => {
          const fileUrl = data?.createQuillMedia.quillMedia.file ?? ''
          resolve(fileUrl)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  return {
    createMedia,
    handleUpload,
    loading,
  }
}
