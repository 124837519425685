import { useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import theme from 'theme'

const TimeGraphWrapper = styled.div`
  width: 100%;
  position: relative;
  height: 50px;
  border: 1px solid ${theme.colors.gray9};
`

const Background = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;
  div:not(:last-child) {
    border-right: 1px dashed ${props => props.theme.colors.gray8};
  }
`

const Tooltip = styled.span`
  display: none;
  position: absolute;
  width: 190px;
  height: 60px;
  left: calc(50% - 95px);
  top: -40px;
  background: #363841;
  color: ${props => props.theme.colors.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
`

const Hour = styled.div`
  width: 4.1666666%;
  height: 50px;
`

const Graph = styled.div<{ left: number; width: number }>`
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
  height: 50px;
  left: ${props => props.left}px;
  width: ${props => props.width}px;

  :hover {
    span {
      display: block;
    }
  }
`

const GraphBlock = styled.div<{
  width: number
  color: string
  borderLeft: string
  borderRight: string
}>`
  height: 50px;
  background-color: ${props => props.color};
  border-left: ${props => props.borderLeft};
  border-right: ${props => props.borderRight};
  width: ${props => props.width}px;
`

const HourMarks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export interface TimeRangeGraph {
  start: number
  end: number
  color: string
  borderColor?: string
  tooltip?: JSX.Element
}

interface TimeGraphProps {
  graphs: TimeRangeGraph[]
  includeHourMarks?: boolean
}

export const TimeGraph: React.UFC<TimeGraphProps> = ({
  graphs,
  includeHourMarks,
}) => {
  const [graphWidth, setGraphwidth] = useState(0)
  const dataRef = useRef<HTMLDivElement>(null)
  useLayoutEffect(() => {
    const el = dataRef.current
    if (!el) return

    const preventDefault = (evt: WheelEvent) => evt.preventDefault()

    setGraphwidth(el.offsetWidth)
    el.addEventListener('wheel', preventDefault, { passive: false })

    return () => {
      el.removeEventListener('wheel', preventDefault)
    }
  }, [])
  return (
    <>
      {includeHourMarks && (
        <HourMarks>
          <span>00:00</span>
          <span>06:00</span>
          <span>12:00</span>
          <span>18:00</span>
          <span>24:00</span>
        </HourMarks>
      )}
      <TimeGraphWrapper ref={dataRef}>
        <Background>
          {Array.from(Array(24)).map((_, index) => {
            return <Hour key={index} />
          })}
        </Background>
        {graphs.map((graph, index) => {
          const end = graph.end === 0 ? 1440 : graph.end
          const width = end - graph.start
          const leftStart = graph.start

          let blocks: JSX.Element[] = []
          let blockWidth = Math.min(60 - (leftStart % 60), width)
          let graphLength = blockWidth
          blocks.push(
            <GraphBlock
              key={index}
              width={(graphWidth * blockWidth) / 1440}
              color={graph.color}
              borderLeft="none"
              borderRight={
                blockWidth === width
                  ? 'none'
                  : '1px dashed ' + graph.borderColor
              }
            />
          )
          while (graphLength < width) {
            let blockWidth = Math.min(60, width - graphLength)
            blocks.push(
              <GraphBlock
                key={`${index}-${blocks.length}`}
                width={(graphWidth * blockWidth) / 1440}
                color={graph.color}
                borderLeft="none"
                borderRight={
                  width - graphLength - 60 <= 0
                    ? 'none'
                    : '1px dashed ' + graph.borderColor
                }
              />
            )
            graphLength += 60
          }

          return (
            <Graph
              key={index}
              left={(graphWidth * leftStart) / 1440 - 2}
              width={(graphWidth * width) / 1440}
            >
              {blocks.map(block => {
                return block
              })}
              {graph.tooltip && <Tooltip>{graph.tooltip}</Tooltip>}
            </Graph>
          )
        })}
      </TimeGraphWrapper>
    </>
  )
}
