import styled from 'styled-components'

interface H4Props {
  fontSize?: string
  lineHeight?: number | string
  fontWeight?: number | string
  margin?: string
  padding?: string
}

export const H4 = styled.h4<H4Props>`
  margin: ${props => props?.margin || '0'};
  padding: ${props => props?.padding || '0'};
  font-size: ${props => props?.fontSize || '1rem'};
  line-height: ${props => `${props?.lineHeight}` || '1.4'};
  font-weight: ${props => `${props?.fontWeight}` || '400'};
  color: ${props => props?.color || props.theme.colors.black};
`
