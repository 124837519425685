import React, { useRef } from 'react'
import styled from 'styled-components'
import { PopupContentProps, PopupProps } from './Popup.types'
import { ZIndexRange } from 'types/style'
import { ContentScrollProvider } from 'containers/providers'
import { InfiniteScroller } from 'containers/util-containers'

const PopupContent = styled.div<PopupContentProps>`
  visibility: ${props => (props.active ? 'visible' : 'hidden')};
  width: ${props => props.width};
  height: ${props => (props.height ? props.height : '')};
  background-color: #fff;
  color: ${props => props.theme.colors.black};
  text-align: center;
  overflow: visible;
  border-radius: 5px;
  position: absolute;
  z-index: ${ZIndexRange.Extra};
  left: ${props => props.left};
  right: ${props => props.right};
  top: ${props => props.top};
  bottom: ${props => props.bottom};
  box-shadow: 1px 1px 11px rgba(0, 0, 0, 0.15);

  max-height: calc(
    100vh - ${props => props.theme.layout.headerHeight} - 11px - 1rem
  );
  .ps {
    max-height: calc(
      100vh - ${props => props.theme.layout.headerHeight} - 11px - 1rem
    );
  }

  :after {
    content: '';
    position: absolute;
    bottom: 100%;
    right: ${props => props.arrowOffset};
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
  }

  ${props => props.theme.layout.mobile} {
    position: fixed;
    top: calc(${props => props.theme.layout.headerHeight} + 11px);
  }
`

interface PopupListProps {
  maxHeigh?: string
}

const PopupList = styled.ul<PopupListProps>`
  list-style: none;
  width: 100%;
  padding: 0;
  margin: 0;

  max-height: ${props => props.maxHeigh};

  overflow-y: auto;

  li:not(:first-child) {
    border-top: 1px solid ${props => props.theme.colors.gray9};
  }
`

interface ListItemProps {
  hoverEffect?: boolean
  border?: boolean
  clickable?: boolean
}

const ListItem = styled.li<ListItemProps>`
  padding: 1rem 1.5rem;
  width: 100%;
  box-sizing: border-box;
  cursor: ${props => (props.clickable ? 'pointer' : 'inherit')};

  ${props =>
    props.hoverEffect &&
    `
    :hover {
      background-color: ${props.theme.colors.gray9};
      color: ${props.theme.colors.primary};
    }
  `}
`

export const HideScrollBarOverflow = styled.div`
  overflow: hidden;
  height: auto;
  width: 100%;
  border-radius: 5px;
`

export const Popup: React.FC<PopupProps> = ({
  show,
  onClose,
  scrollActive = false,
  handleScrollBottom,

  renderHeader,
  renderItems,

  width = '300px',
  maxHeight,
  height,

  /* Position the center of the triangle (20 pixels wide) in the middle of the content.
   * Note that the position of the triangle is 8 pixels to the left. Hence we subtract 18 pixels:
   * half the width plus 8.
   */
  left,
  right = 'calc(50% - 18px)',
  /* Position the top of the triangle 1 pixel below the origin content */
  top = 'calc(100% + 11px)',

  arrowOffset = '8px',

  itemHoverEffect = true,
}) => {
  const items = renderItems.filter(i => !i.hide)

  const contentRef = useRef(null)
  return (
    <PopupContent
      width={width}
      height={height}
      active={show}
      top={top}
      right={right}
      left={left}
      arrowOffset={arrowOffset}
    >
      {renderHeader ? renderHeader() : null}
      <HideScrollBarOverflow>
        <PopupList ref={contentRef} maxHeigh={maxHeight}>
          <ContentScrollProvider contentRef={contentRef}>
            <InfiniteScroller
              active={scrollActive}
              handleScrollBottom={handleScrollBottom}
            >
              {items.map((item, index) => (
                <ListItem
                  key={`${item.id}-${index}`}
                  hoverEffect={itemHoverEffect}
                  clickable={item.clickable}
                  onClick={() => {
                    if (typeof item.onClick === 'function')
                      item.onClick(onClose)
                  }}
                >
                  {item.content(onClose)}
                </ListItem>
              ))}
            </InfiniteScroller>
          </ContentScrollProvider>
        </PopupList>
      </HideScrollBarOverflow>
    </PopupContent>
  )
}
