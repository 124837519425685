export const STAGE = isLocal() ? 'local' : process.env.REACT_APP_STAGE

export const API_URLS = {
  local: 'http://localhost:8000',
  alpha: 'https://api-firmadok-alpha.ur-internal.com',
  development: 'https://api-firmadok.ur-internal.com',
  staging: 'https://api-staging.firmadok.no',
  production: 'https://api.firmadok.no',
}

export const API_URL = API_URLS[STAGE]

export const HERE_API_KEY = 'bTh0na4-sCovqZ9FZ0qC1ZfNbaXjUd0wOYOWFn8LQJs'

export const GOOGLE_API_KEY =
  process.env.NODE_ENV === 'production'
    ? 'AIzaSyCSVXw27xAwZX047OpceLY7Ml3E3KGKieg'
    : 'AIzaSyB-kqJ4q6G2Pg1HGpA-7_D1kp57J-JjzgM'

export function isLocal() {
  return process.env.NODE_ENV !== 'production'
}
