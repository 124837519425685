import { PromptResolve } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { RegularModal, TextArea } from 'components'

export interface ViewCommentModalProps {
  onClose: PromptResolve<boolean | null>
  comment: string
}

export const ViewCommentModal: React.FC<ViewCommentModalProps> = ({
  onClose,
  comment,
}) => {
  const translations = useTranslate({
    comment: 'common.comment',
    close: 'common.close',
  })

  return (
    <RegularModal
      title={translations.comment}
      width="600px"
      onCancel={() => onClose(null)}
      cancelText={translations.close}
      cancelOnClickOutside={true}
    >
      <TextArea
        onChange={() => void 0}
        value={comment}
        height="200px"
        fullWidth
        disabled
      />
    </RegularModal>
  )
}
