import { InfringementCategory, InfringementCategoryIdentifierEnum } from "./types.graphl"

export const InfringementSeverities = {
  MINOR: 'MINOR',
  SERIOUS: 'SERIOUS',
  VERY_SERIOUS: 'VERY_SERIOUS',
  MOST_SERIOUS: 'MOST_SERIOUS',
}

export const BInfringements = [
  "B1",
  "B2",
  "B3",
  "B4",
  "B5",
  "B6",
  "B7",
  "B8",
  "B9",
  "B10",
  "B11",
  "B12",
  "B13",
  "B14",
  "B15",
  "B16",
] as InfringementCategory[]

export const CInfringements = ["C1", "C2", "C3"] as InfringementCategory[]

export const DInfringements = ["D1", "D2", "D3", "D4", "D5", "D6", "D7", "D8", "D9", "D10", "D11", "D12", "D13", "D14", "D15", "D16", "D17", "D18", "D19", "D20", "D21"] as InfringementCategory[]

export type InfringementCategoryIdentifiers = keyof typeof InfringementCategoryIdentifierEnum
