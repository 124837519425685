import { useCallback } from 'react'
import { useHistory } from 'react-router'
import { useCompany } from '../useCompany'

export function usePushHistory() {
  const history = useHistory()
  const company = useCompany()

  const pushHistory = useCallback(
    (href: string, evt?: { ctrlKey: boolean }, state?: unknown) => {
      const newTab = evt?.ctrlKey
      if (newTab) {
        const url = href.startsWith('/')
          ? `${window.location.origin}/c/${company.shortName}${href}`
          : `${window.location.href}/${href}`
        window.open(url)
      } else history.push(href, state)
    },
    [company.shortName, history]
  )

  return pushHistory
}
