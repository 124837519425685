import React, { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router'
import { SidebarGraph } from 'store/sidebar'
import styled from 'styled-components'
import { ZIndexRange } from 'types/style'
import { SidebarNav, SidebarTop } from '.'

const Wrapper = styled.div`
  position: relative;
  z-index: ${ZIndexRange.Sidebar};
  width: 100%;
  min-height: 100vh;
  padding: 1.4em;

  background: white;

  ${props => props.theme.layout.mobile} {
    position: absolute;
    left: 0;
    top: ${props => props.theme.layout.headerHeightMobile};
    width: 100%;
    overflow: auto;

    background: white;
    box-shadow: inset 0 4px 24px -8px #00000012;
  }
`

export const Sidebar: React.VFC = () => {
  const [{ open, toggle }] = SidebarGraph.useGraph()
  const history = useHistory()

  useEffect(() => {
    if (!isMobile) return

    const unregister = history.listen((_, method) => {
      if (method === 'PUSH') toggle(false)
    })

    return () => {
      unregister()
    }
  }, [history, toggle])

  if (!isMobile)
    return (
      <Wrapper>
        <SidebarTop />
        <SidebarNav />
      </Wrapper>
    )

  if (!open) return null

  return (
    <Wrapper>
      <SidebarNav />
    </Wrapper>
  )
}
