import { Icon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { Card } from 'components/Card'
import React from 'react'
import { isBrowser } from 'react-device-detect'
import styled from 'styled-components'
import { removeCurrentYear } from 'util/date'
import { format } from 'util/date-fns'

import { ResponseValueCell } from '../components'
import {
  FormTemplateForFormSubmitQuery,
} from '../types.graphql'
import { matchValues } from '../util'
import { Button } from 'components'
import { Link } from 'react-router-dom'

const Content = styled(Card)`
  padding: 1rem 2rem 2rem 2rem;
  max-width: 800px;
  button {
    height: 3rem;
  }
`

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  padding-bottom: 1rem;

  th,
  td {
    padding: 8px 12px;

    text-align: left;
    border: 1px solid ${props => props.theme.colors.gray8};
    border-width: 1px 0 0 1px;

    &:last-child {
      border-right-width: 1px;
    }
    &.nowrap {
      white-space: nowrap;
    }
    &.last-common {
      border-right-width: 2px;
      border-right-color: ${props => props.theme.colors.gray6};
    }
    &.hoverable {
      cursor: pointer;

      &:hover {
        background-color: ${props => props.theme.colors.secondary}20;
      }
    }
  }

  th {
    background-color: ${props => props.theme.colors.quaternary};

    &:first-child {
      border-top-left-radius: 8px;
    }
    &:last-child {
      border-top-right-radius: 8px;
    }
  }

  tr {
    &:nth-child(even) td {
      background-color: ${props => props.theme.colors.gray11};
    }
  }

  tbody tr:last-child {
    td {
      border-bottom-width: 1px;

      &:first-child {
        border-bottom-left-radius: 8px;
      }
      &:last-child {
        border-bottom-right-radius: 8px;
      }
    }
  }
`

interface PreviousAnswersProps {
  form: FormTemplateForFormSubmitQuery['formTemplate']
  filledOutForms: FormTemplateForFormSubmitQuery['formTemplate']['filledOutForms']
}

export const PreviousAnswers: React.VFC<PreviousAnswersProps> = ({ filledOutForms, form }) => {
  const translations = useTranslate({
    previousResponses: 'common.previous-responses',
    seeAllResponses: 'common.see-all-responses',

    date: 'common.date',
  })

  const fields = form.formFields.edges.map(edge => edge.node)

  if (filledOutForms.edges.length === 0) return null

  return (
      <Content>
        <h3>{translations.previousResponses}</h3>
          <Table>
            <thead>
              <tr>

                <th className="nowrap last-common">{translations.date}</th>

                {fields.map(field => (
                  <th key={`header-${field.id}`} className="nowrap">
                    {field.name}
                    {isBrowser && !!field.description && (
                      <Icon
                        icon="info-circle"
                        type="solid"
                        color="secondary"
                        margin="0 0 0 6px"
                        title={field.description}
                      />
                    )}
                  </th>
                  )
                )}
              </tr>
            </thead>

            <tbody>
              {filledOutForms.edges.map(edge => {
                const response = edge.node
                const date = removeCurrentYear(
                  format(new Date(response.createdAt), 'PP HH:mm')
                )
                const values = matchValues(response.values, fields)

                return (
                  <tr key={response.id}>
                    <td className="nowrap" width="1px">
                      {date}
                    </td>

                    {values.map((value, idx) =>
                      !value ? (
                        <td key={idx}></td>
                      ) : (
                        <ResponseValueCell
                          key={value.id}
                          title={value.formTemplateField.name}
                          value={value.value}
                          type={value.formTemplateField.fieldType}
                        />
                      )
                    )}
                  </tr>
                )
              })}
            </tbody>
          </Table>
          <Link to={`/formcreator/${form.id}/responses`}>
            <Button>{translations.seeAllResponses}</Button>
          </Link>
      </Content>
  )
}
