import { useToast } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { useMutation } from '@apollo/client'
import { useOnErrorAuto } from 'util/hooks'
import {
  EXPORT_INFRINGEMENTS_USER_TOTALS,
  EXPORT_INFRINGEMENTS,
  EXPORT_INFRINGEMENT,
  EXPORT_INFRINGEMENTS_MASS,
} from 'modules/exports/mutations'
import {
  PatchInfringementMutation,
  PatchInfringementMutationVariables,
  ExportInfringementMutation,
  ExportInfringementMutationVariables,
  ExportInfringementsMutation,
  ExportInfringementsMutationVariables,
  ExportInfringementsUserTotalsMutation,
  ExportInfringementsUserTotalsMutationVariables,
  ExportInfringementsMassMutation,
  ExportInfringementsMassMutationVariables,
} from './types.graphl'
import { PATCH_INFRINGEMENT_MUTATION } from '.'

export function useInfringementsMutations(onCompleted?: () => void) {
  const translations = useTranslate({
    success: 'infringements.send-email-success',
    downloadSuccess: 'exports.success-user-exports',
    error: 'exports.error-email-process',
  })
  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()

  const [toggleInfringementArchived, { loading: archiveInfringementLoading }] =
    useMutation<PatchInfringementMutation, PatchInfringementMutationVariables>(
      PATCH_INFRINGEMENT_MUTATION,
      {
        awaitRefetchQueries: true,
        refetchQueries: ['AllInfringements'],
        onError: onErrorAuto(),
      }
    )

  const [
    exportInfringementsUserSums,
    { loading: exportInfringementsUserSumsLoading },
  ] = useMutation<
    ExportInfringementsUserTotalsMutation,
    ExportInfringementsUserTotalsMutationVariables
  >(EXPORT_INFRINGEMENTS_USER_TOTALS, {
    onCompleted(data: ExportInfringementsUserTotalsMutation) {
      if (data?.exportInfringementsUserTotals.ok) {
        addToast('info', translations.downloadSuccess)
      }
      onCompleted?.()
    },
    onError: onErrorAuto(translations.error),
  })

  const [exportInfringements, { loading: exportInfringementsLoading }] =
    useMutation<
      ExportInfringementsMutation,
      ExportInfringementsMutationVariables
    >(EXPORT_INFRINGEMENTS, {
      onCompleted(data: ExportInfringementsMutation) {
        if (data?.exportInfringements.ok) {
          addToast('info', translations.downloadSuccess)
        }
        onCompleted?.()
      },
      onError: onErrorAuto(translations.error),
    })

  const [exportInfringementsMass, { loading: exportInfringementsMassLoading }] =
    useMutation<
      ExportInfringementsMassMutation,
      ExportInfringementsMassMutationVariables
    >(EXPORT_INFRINGEMENTS_MASS, {
      onCompleted(data: ExportInfringementsMassMutation) {
        if (data.exportInfringementsMass.ok) {
          addToast('info', translations.downloadSuccess)
        }
        onCompleted?.()
      },
      onError: onErrorAuto(translations.error),
    })

  const [exportInfringement, { loading: exportInfringementLoading }] = useMutation<
      ExportInfringementMutation,
      ExportInfringementMutationVariables
    >(EXPORT_INFRINGEMENT, {
      onCompleted(data: ExportInfringementMutation) {
        if (data.exportInfringement.ok) {
          addToast('info', translations.downloadSuccess)
        }
        onCompleted?.()
      },
      onError: onErrorAuto(translations.error),
    })

  return {
    exportInfringement,
    exportInfringements,
    exportInfringementsMass,
    exportInfringementsUserSums,

    toggleInfringementArchived,

    loading:
      exportInfringementsUserSumsLoading ||
      exportInfringementsLoading ||
      exportInfringementLoading ||
      archiveInfringementLoading ||
      exportInfringementsMassLoading,
  }
}
