import { useQuery } from '@apollo/client'
import { Select } from 'components'
import {
  AllVehiclesSelectQuery,
  ALL_VEHICLES_SELECT_QUERY,
  VehicleSelectNode,
} from 'modules/vehicles'

import { useState } from 'react'

interface VehicleSelectProps {
  grayed?: boolean
  onVehicleSelectedCallback: (vehicle: VehicleSelectNode) => void
}

export const VehicleSelect: React.VFC<VehicleSelectProps> = ({
  grayed = false,
  onVehicleSelectedCallback = () => {},
}) => {
  /**
   * Select widget which queries vehicles with dimensions and registration number
   * so we can use it to autopopulate the route planner fields for a given vehicle.
   *
   * @param {onVehicleSelectedCallback} onVehicleSelectedCallback - Handler in the parent
   * component of this component.
   */
  const [selected, setSelected] = useState<string | null>(null)

  const { loading, data } = useQuery<AllVehiclesSelectQuery>(
    ALL_VEHICLES_SELECT_QUERY
  )

  const vehicles = data?.allVehicles.edges.map(edge => edge.node)

  const vehicleOptions = vehicles?.map(vehicle => ({
    value: vehicle.id,
    label: vehicle.registrationNumber || vehicle.vin,
  }))

  const handleOnChange = (value: string | null) => {
    if (loading || !data) return
    setSelected(value)

    if (!value) return
    const vehicle = vehicles?.find(vehicle => vehicle.id === value)

    if (vehicle === undefined) return
    onVehicleSelectedCallback(vehicle)
  }

  return (
    <Select
      searchable
      fullWidth
      grayed={grayed}
      value={selected}
      options={vehicleOptions ?? []}
      loading={loading}
      onChange={handleOnChange}
    />
  )
}
