import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Loader,
  SelectMultiple as BaseSelectMultiple,
  SelectMultipleOption,
  SelectMultipleProps as BaseSelectMultipleProps,
} from '@ur/react-components'
import { Checkbox } from 'components'
import { overloadColor } from 'util/style'
import { useTranslate } from '@ur/react-hooks'
import { isMobile } from 'react-device-detect'

interface WrapperProps {
  filterBackground?: string
  optionHoverBackground?: string
  withSelectAll: boolean
  grayed?: boolean
}

const Wrapper = styled.div<WrapperProps>`
  position: relative;

  ${props =>
    props.withSelectAll &&
    `
    display: flex;
    flex-direction: row;

    .--select-all-checkbox {
      min-width: 110px;
      margin-left: 1rem;
      margin-top: 8px;
      align-self: flex-start;
    }

    ${
      isMobile &&
      `
        flex-direction: column;
        .--select-all-checkbox {
          justify-content: flex-start;
          margin-left: 0px;
          margin-top: 1rem;
        }
        `
    }
  `}

  .--select-display {
    border-color: ${props => props.theme.colors.gray9};
    background: ${props => props.grayed && props.theme.colors.quaternary};
  }
  .--select-display-selected {
    padding: 0.6rem 0.8rem;
    font-size: 1.2rem !important;
    font-weight: 500;
    border-radius: 16px;
  }
  .--select-placeholder {
    padding: 0 0.6rem;
    font-size: 1.2rem !important;
  }
  .--select-curtain {
    border-color: ${props => props.theme.colors.gray9};
    background: ${props => props.grayed && props.theme.colors.quaternary};
  }
  .--select-curtain-option {
    padding: 1rem !important;
    font-size: 1rem !important;

    &:hover {
      background: ${props =>
        props.optionHoverBackground
          ? overloadColor(props.optionHoverBackground)
          : props.grayed
          ? 'white'
          : props.theme.colors.quaternary};
    }
  }
  .--select-curtain-search {
    &::before {
      margin-left: 0.6rem;
      margin-top: -2px;
    }
    input {
      background: ${props =>
        props.filterBackground
          ? overloadColor(props.filterBackground)
          : props.grayed
          ? 'white'
          : props.theme.colors.quaternary};
      padding: 0.6rem 0.6rem 0.6rem 2.2rem;
      border-radius: 8px;
      font-size: 1rem;
    }
  }
`
interface LoadingProps {
  background?: string
}
const Loading = styled.div<LoadingProps>`
  position: absolute;
  z-index: 1;
  top: 3px;
  left: 1rem;

  display: flex;
  align-items: center;
  height: calc(100% - 6px);
  background: ${props => overloadColor(props.background ?? 'white')}; ;
`
export type SelectMultipleOnSelectAll = (
  options: SelectMultipleOption<string | number, any>[],
  setSelectAll: React.Dispatch<React.SetStateAction<boolean>>
) => (value: boolean) => void

export interface SelectMultipleProps<
  ValueType extends string | number,
  ExtraType = any
> extends BaseSelectMultipleProps<ValueType, ExtraType> {
  loading?: boolean
  filterBackground?: string
  optionHoverBackground?: string
  withSelectAll?: boolean
  onSelectAll?: SelectMultipleOnSelectAll

  grayed?: boolean
}

export const SelectMultiple = <
  ValueType extends string | number,
  ExtraType = any
>({
  className,

  loading = false,
  filterBackground,
  optionHoverBackground,
  withSelectAll = false,
  onSelectAll,

  grayed = false,

  ...selectProps
}: SelectMultipleProps<ValueType, ExtraType>) => {
  const translations = useTranslate({
    selectAll: 'common.select-all',
  })

  const [selectAll, setSelectAll] = useState(false)

  useEffect(() => {
    if (selectProps.selected.length !== selectProps.options.length) {
      setSelectAll(false)
    }
  }, [selectProps.onChange, selectProps.options, selectProps.selected.length])

  return (
    <Wrapper
      className={className}
      filterBackground={filterBackground}
      optionHoverBackground={optionHoverBackground}
      grayed={grayed}
      withSelectAll={withSelectAll}
    >
      {loading && (
        <Loading background={selectProps.background}>
          <Loader.Dots size={28} />
        </Loading>
      )}
      <BaseSelectMultiple
        minHeight="54px"
        {...selectProps}
      ></BaseSelectMultiple>
      {withSelectAll ? (
        <Checkbox
          className={'--select-all-checkbox'}
          checked={selectAll}
          onChange={
            onSelectAll
              ? onSelectAll(selectProps.options, setSelectAll)
              : () => {}
          }
          label={translations.selectAll}
          inline
        />
      ) : null}
    </Wrapper>
  )
}
