import { PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { DatePicker, RegularModal } from 'components'
import { DetectBlur } from 'containers/util-containers'
import { Field, Form } from '..'
import { HolidayAllowanceNode } from 'modules/companies/types.graphql'
import { SalaryCodeSelect } from 'components/Select/SalaryCodeSelect'
import { notBlankValidation } from 'util/forms'
import { isSameDay, parseISO } from 'date-fns'

export interface HolidayAllowanceForm {
  day: Date
  salaryCodeId: string | null
}

interface HolidayAllowancePromptProps {
  initialData?: HolidayAllowanceNode
  existingHolidayAllowances: Pick<HolidayAllowanceNode, 'day' | 'id'>[]
  onSubmit: PromptResolve<HolidayAllowanceForm | null>
}

export const HolidayAllowancePrompt: React.VFC<HolidayAllowancePromptProps> = ({
  initialData,
  existingHolidayAllowances,
  onSubmit,
}) => {
  const translations = useTranslate({
    form: {
      label: {
        date: 'common.date',
        salaryCode: 'common.salary-code',
      },
      placeholder: {
        id: 'settings.external-id-in-salary-system',
      },
    },

    addHolidayAllowance: 'settings.add-holiday-allowance',
    editHolidayAllowance: 'settings.edit-holiday-allowance',

    create: 'common.create-alt',
    update: 'common.update',

    validation: {
      alreadyHolidayAllowanceOnDay: 'settings.validation.already-holiday-allowance-on-day',
      required: 'common.required',
    },
  })

  const editing = typeof initialData !== 'undefined'

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formChangeHandler: handler,
    submitHandler: submit,
  } = useForm<HolidayAllowanceForm>({
    values: initialData ? {
      day: parseISO(initialData.day),
      salaryCodeId: initialData.salaryCode?.id ?? null,
    } : {
      salaryCodeId: '',
      day: new Date(),
    },
    validators: {
      salaryCodeId: value => notBlankValidation(value) ? null : translations.validation.required,
      day: value => existingHolidayAllowances.filter(allowance => isSameDay(parseISO(allowance.day), value) && (allowance.id !== initialData?.id)).length === 0 ? null : translations.validation.alreadyHolidayAllowanceOnDay,
    },
  })

  return (
    <DetectBlur active onClickOutside={() => onSubmit(null)}>
      <RegularModal
        title={
          editing
            ? translations.editHolidayAllowance
            : translations.addHolidayAllowance
        }
        submitText={editing ? translations.update : translations.create}
        submitDisabled={!formValid}
        width="400px"
        onCancel={() => onSubmit(null)}
        onSubmit={submit(onSubmit)}
      >
        <Form>
          <Field>
            <label>{translations.form.label.date}</label>
            <DatePicker
              value={form.day}
              noWeekNums
              dateErrorText={errors.day ?? undefined}
              fullWidth
              onChange={handler('day')}
              placeholder={translations.form.placeholder.id}
            />
          </Field>
          <Field>
            <label>{translations.form.label.salaryCode}</label>
            <SalaryCodeSelect
              error={errors.salaryCodeId}
              fullWidth
              value={form.salaryCodeId}
              onChange={handler('salaryCodeId')}
            />
          </Field>
        </Form>
      </RegularModal>
    </DetectBlur>
  )
}
