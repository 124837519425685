import { useTranslate } from '@ur/react-hooks'
import {
  AllAbsenceTypesQuery,
  ALL_ABSENCE_TYPES_QUERY,
} from 'modules/timesheets'
import React, { useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { RelayNodeType } from 'types/graphql'
import { useConfirm } from 'util/hooks'
import {
  AbsenceSettingsCards,
  AbsenceSettingsTable,
  SimpleAbsenceType,
} from '../components'
import {
  AddButton,
  InfoBox,
  Section,
  SectionHeader,
  SettingsWrapper,
} from '../components/common'
import { useAbsenceSettingsMutations } from '../util/mutations.hooks'
import { usePrompt } from '@ur/react-components'
import {
  AbsenceTypeForm,
  AbsenceTypePrompt,
} from '../components/AbsenceSettings/AbsenceTypePrompt'
import { ModuleResponsibleUsers } from '../components/ModuleResponsibleUsers'
import { Module } from 'modules/companies'

export const LS_KEY_LIST_VIEW_MODE_ABSENCE_SETTINGS =
  'firmadok-list-view-mode-absence-type-settings'

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

interface AbsenceSettingsProps {}

export const AbsenceSettings: React.VFC<AbsenceSettingsProps> = () => {
  const translations = useTranslate({
    info1: 'settings.info.absence-types-1',
    info2: 'settings.info.absence-types-2',

    absence: 'common.absence',
    absenceTypes: 'timesheets.absence-types',
    addAbsenceType: 'settings.add-absence-type',
    editAbsenceType: 'settings.edit-absence-type',

    validation: {
      nameExists: 'settings.validation.absence-type-name-exists',
    },
    prompt: {
      add: 'settings.prompts.absence-type-create',
      delete: ['settings.prompts.absence-type-delete', { name: '' }],
      deleteTitle: 'settings.prompts.absence-type-delete-title',
    },
  })

  const confirm = useConfirm()
  const addPrompt = usePrompt()

  const [temporaryEditColor, setTemporaryEditColor] = useState<{
    [id: string]: string | null
  }>({})

  const { data: queryData, loading: queryLoading } =
    useQuery<AllAbsenceTypesQuery>(ALL_ABSENCE_TYPES_QUERY, {
      onError: console.error,
    })

  const mutations = useAbsenceSettingsMutations()

  function handleUpdateColor(id: string) {
    return async (color: string) => {
      setTemporaryEditColor(v => ({
        ...v,
        [id]: color,
      }))

      try {
        await mutations.patch({
          variables: { id, input: { color } },
        })
      } finally {
        setTemporaryEditColor(v => ({
          ...v,
          [id]: null,
        }))
      }
    }
  }

  const absenceTypes = useMemo(
    () => queryData?.allAbsenceTypes.edges.map(edge => edge.node) ?? [],
    [queryData]
  )

  async function handleAddAbsenceType() {
    const { data } = await addPrompt<AbsenceTypeForm | null>(resolve => (
      <AbsenceTypePrompt
        existingAbsenceTypes={absenceTypes}
        onSubmit={resolve}
      />
    ))
    if (data === null) return

    mutations.create({
      variables: {
        input: { externalId: data.externalId, name: data.name, color: data.color },
      },
    })
  }

  async function handleEditAbsenceType(type: SimpleAbsenceType) {
    const { data } = await addPrompt<AbsenceTypeForm | null>(resolve => (
      <AbsenceTypePrompt
        initialData={type}
        existingAbsenceTypes={absenceTypes}
        onSubmit={resolve}
      />
    ))
    if (data === null) return

    mutations.patch({
      variables: {
        id: type.id,
        input: { externalId: data.externalId, name: data.name, color: data.color },
      },
    })
  }

  async function handleDeleteAbsenceType(
    type: RelayNodeType<AllAbsenceTypesQuery['allAbsenceTypes']>
  ) {
    const { data: answer } = await confirm(
      translations.prompt.delete({ name: type.name }),
      translations.prompt.deleteTitle
    )
    if (!answer) return

    mutations.delete({
      variables: {
        id: type.id,
      },
    })
  }

  const isLoading = queryLoading || mutations.loading

  return (
    <SettingsWrapper grid={{ flow: 'row' }}>
      <InfoBox initCollapsed={isMobileOnly}>
        <p>{translations.info1}</p>
        <p>{translations.info2}</p>
      </InfoBox>

      <ModuleResponsibleUsers module={Module.ABSENCES} />

      <Section>
        <Header>
          <SectionHeader loading={isLoading}>
            {translations.absenceTypes}
          </SectionHeader>
          {!isMobileOnly && (
            <AddButton adding={false} onClick={handleAddAbsenceType} />
          )}
        </Header>

        {isMobileOnly ? (
          <AbsenceSettingsCards
            absenceTypes={absenceTypes}
            onAddSubmit={handleAddAbsenceType}
            onEditSubmit={handleEditAbsenceType}
            onDelete={handleDeleteAbsenceType}
          />
        ) : (
          <AbsenceSettingsTable
            absenceTypes={absenceTypes}
            onAddSubmit={handleAddAbsenceType}
            onEditSubmit={handleEditAbsenceType}
            onDelete={handleDeleteAbsenceType}
            temporaryEditColor={temporaryEditColor}
            onUpdateColor={handleUpdateColor}
          />
        )}
      </Section>
    </SettingsWrapper>
  )
}
