import { Module } from 'modules/companies/consts'
import React from 'react'
import styled from 'styled-components'
import { useModuleConfig } from 'util/hooks'
import { OptionToggle } from './OptionToggle'

const Wrapper = styled.div`
  > div + div {
    margin-top: 2rem;
  }
`

export const TimeEntryModuleOptionCards: React.VFC = () => {
  const { moduleOptions } = useModuleConfig(Module.TIMESHEETS)

  return (
    <Wrapper>
      {moduleOptions.map(option => (
        <OptionToggle key={option.id} option={option} />
      ))}
    </Wrapper>
  )
}
