import { PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Input, RegularModal } from 'components'
import { DetectBlur } from 'containers/util-containers'
import { Field, Form } from '..'
import { DepartmentNode } from 'modules/companies/types.graphql'

export interface DepartmentForm {
  externalId: string
  name: string
}

interface DepartmentPromptProps {
  initialData?: DepartmentForm
  existingDepartments: Pick<DepartmentNode, 'name' | 'id'>[]
  onSubmit: PromptResolve<DepartmentForm | null>
}

export const DepartmentPrompt: React.VFC<DepartmentPromptProps> = ({
  initialData,
  existingDepartments,
  onSubmit,
}) => {
  const translations = useTranslate({
    form: {
      label: {
        name: 'common.name',
        externalId: 'common.id',
      },
      placeholder: {
        id: 'settings.external-id-in-salary-system',
      },
    },

    addDepartment: 'settings.add-department',
    editDepartment: 'settings.edit-department',

    create: 'common.create-alt',
    update: 'common.update',

    validation: {
      nameExists: 'settings.validation.time-entry-project-name-exists',
      required: 'common.required',
    },
  })

  const editing = typeof initialData !== 'undefined'

  function validateName(name: string, formName: string) {
    if (name === initialData?.name) return null
    if (name === '') return translations.validation.required
    if (
      !!existingDepartments.find(department => department.name === formName)
    ) {
      return translations.validation.nameExists
    }
    return null
  }

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formChangeHandler: handler,
    submitHandler: submit,
  } = useForm<DepartmentForm>({
    values: initialData ?? {
      externalId: '',
      name: '',
    },
    validators: {
      name: (val, form) => validateName(val, form.name),
    },
  })

  return (
    <DetectBlur active onClickOutside={() => onSubmit(null)}>
      <RegularModal
        title={
          editing
            ? translations.editDepartment
            : translations.addDepartment
        }
        submitText={editing ? translations.update : translations.create}
        submitDisabled={!formValid}
        width="400px"
        onCancel={() => onSubmit(null)}
        onSubmit={submit(onSubmit)}
      >
        <Form>
          <Field>
            <label>{translations.form.label.externalId}</label>
            <Input
              value={form.externalId}
              error={errors.externalId}
              autoFocus
              fullWidth
              onChange={handler('externalId')}
              placeholder={translations.form.placeholder.id}
            />
          </Field>
          <Field>
            <label>{translations.form.label.name}</label>
            <Input
              value={form.name}
              error={errors.name}
              autoFocus
              fullWidth
              onChange={handler('name')}
            />
          </Field>
        </Form>
      </RegularModal>
    </DetectBlur>
  )
}
