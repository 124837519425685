import { gql } from '@apollo/client'

export const CompanyFragments = {
  info: gql`
    fragment CompanyInfo on CompanyNode {
      id
      shortName
      fullName
      country
      language
      logo
      useOrdinaryHourCalculation
    }
  `,
  userTypes: gql`
    fragment CompanyUserTypes on CompanyNode {
      userTypes {
        id
        name
        permissions
        dropdowns
        editable
      }
    }
  `,
  scaniaApiKey: gql`
    fragment ScaniaApiKey on ScaniaApiKeyNode {
      id
      tachographKey
      fmsAccessKey
      fmsSecretKey
      active
    }
  `,
  volvoApiKey: gql`
    fragment VolvoApiKey on VolvoApiKeyNode {
      id
      username
      password
      active
    }
  `,
}
