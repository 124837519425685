import { BaseLanguage } from '../util'

const nb: BaseLanguage = {
  'activities.add-salary-day': 'Legg til døgn',
  'activities.approve-day': 'Godkjenne aktiviteter for {date}?',
  'activities.approve-month':
    'Godkjenne aktiviteter for {month} ({dateStart} - {dateEnd})?',
  'activities.approve-title': 'Godkjenne aktiviteter',
  'activities.approve-week':
    'Godkjenne aktivititer for uke {week} ({dateStart} - {dateEnd})?',
  'activities.availability-time': 'Tilgjengelighetstid',
  'activities.consumption': 'Drivstofforbruk',
  'activities.day-end': 'Dag slutt',
  'activities.day-start': 'Dag start',
  'activities.driver': 'Sjåfør',
  'activities.driving': 'Kjøring',
  'activities.driving-grade': 'Kjørekarakter',
  'activities.end-position':
    '{abbreviate, select, true {Slutt pos.} other {Sluttposisjon}}',
  'activities.errors.could-not-load-route-in-map':
    'Kunne ikke laste rute i kartet',
  'activities.errors.general-error': 'Noe gikk galt ved henting av kjøredata',
  'activities.errors.select-multiple-users':
    'Velg minst én bruker for å eksportere',
  'activities.errors.user-not-found': 'Brukeren finnes ikke',
  'activities.export-driving-data': 'Eksporter kjøredata',
  'activities.export-monthly-weekly':
    'Eksporter oppdelt månedlig eller ukentlig',
  'activities.fetch-data': 'Hent kjøredata',
  'activities.fuel-usage': 'Drivstofforbruk',
  'activities.last-fetched': 'Sist hentet',
  'activities.missing-data': 'Mangler data',
  'activities.module-title': 'Kjøredata',
  'activities.paid-pause': 'Betalt pause',
  'activities.pause-export': 'Pauserapport',
  'activities.print-driving-data': 'Print kjøredata',
  'activities.regnr': 'Regnr',
  'activities.rest': 'Hvile',
  'activities.start-position':
    '{abbreviate, select, true {Start pos.} other {Startposisjon}}',
  'activities.starting-activity-fetching-process':
    'Henting av kjøredata er satt i gang',
  'activities.success.approve-activities': 'Aktiviteter ble godkjent',
  'activities.sum-for-month-title': 'Sum for {month}',
  'activities.sum-next-month': 'Sum for uke {week} utregnes neste måned',
  'activities.sum-overtime': 'Herav overtid',
  'activities.updated-diet-successfully': 'Diett ble oppdatert',
  'activities.updated-salary-day-successfully': 'Døgn på dagen ble oppdatert',
  'activities.work': 'Arbeid',
  'activities.work-summary': 'Arbeidsoppsummering',
  'activities.working-hours': 'Arbeidstimer',
  'admin-required.message': 'Du må være administrator for å se dette.',
  'auth.admin-or-user-required':
    'Du må være {user} eller en administrator for å se dette.',
  'change-password.description':
    'Av sikkerhetsgrunner må du bytte passordet ditt før du logger inn på nytt.',
  'change-password.title': 'Bytt passord',
  'checklists.all-checklist-templates': 'Alle sjekklistemaler',
  'checklists.checklist-item': 'Sjekklistepunkt',
  'checklists.checklist-template': 'Sjekklistemal',
  'checklists.generate-new': 'Generer ny',
  'checklists.no-checklists': 'Ingen sjekklister tilgjengelig.',
  'commmon.edit-name': 'Endre navn',
  'common.about': 'Om',
  'common.absence': 'Fravær',
  'common.absence-types': 'Fraværstyper',
  'common.absences': 'Fravær',
  'common.action': 'Handling',
  'common.active': 'Aktiv',
  'common.activities': 'Kjøredata',
  'common.activity': 'Aktivitet',
  'common.add': 'Legg til',
  'common.add-attachment': 'Legg til vedlegg',
  'common.add-attachments': 'Legg til vedlegg',
  'common.add-column': 'Legg til kolonne',
  'common.add-file': 'Legg ved fil',
  'common.add-file-alt': 'Legg til fil',
  'common.add-files': 'Legg ved filer',
  'common.additional-pay': 'Tillegg',
  'common.address': 'Adresse',
  'common.all': 'Alle',
  'common.ambulance': 'Ambulanse',
  'common.app': 'App',
  'common.archive': 'Arkiver',
  'common.archived': 'Arkivert',
  'common.article': 'Artikkel',
  'common.attachments': 'Vedlegg',
  'common.author': 'Forfatter',
  'common.availability': 'Tilgjengelighet',
  'common.basis': 'Grunnlag',
  'common.both': 'Begge',
  'common.business': 'Firma',
  'common.businessOverview': 'Firmaoversikt',
  'common.by-name': 'Av {name}',
  'common.cancel': 'Avbryt',
  'common.cancel-changes': 'Angre endringer',
  'common.categories': 'Kategorier',
  'common.category': 'Kategori',
  'common.category-for-type': 'Kategori på {type}',
  'common.change-company': 'Bytt firma',
  'common.change-language': 'Endre språk',
  'common.change-password': 'Endre passord',
  'common.changes-saved': 'Endringer lagret',
  'common.checklist-templates': 'Sjekklistemaler',
  'common.checklists': 'Sjekklister',
  'common.choose-ellipsis': 'Velg...',
  'common.city': 'By',
  'common.close': 'Lukk',
  'common.closed': 'Lukket',
  'common.codes': 'Koder',
  'common.color': 'Farge',
  'common.comment': 'Kommentar',
  'common.comment-ellipsis': 'Kommenter...',
  'common.comments': 'Kommentarer',
  'common.company': 'Bedrift',
  'common.company-name': 'Bedriftsnavn',
  'common.contact-info': 'Kontaktinfo',
  'common.contact-person': 'Kontaktperson',
  'common.content': 'Innhold',
  'common.count': 'Antall',
  'common.count-of-total': '{count} av {total}',
  'common.count-of-total-verbose': 'Sett av {count}/{total} ansatte',
  'common.countries': 'Land',
  'common.country': 'Land',
  'common.create': 'Opprett ny',
  'common.create-alt': 'Opprett',
  'common.create-entry': 'Lag nytt innlegg',
  'common.create-folder': 'Lag ny mappe',
  'common.create-form': 'Lag nytt skjema',
  'common.create-form-entry': 'Fyll in skjema',
  'common.create-type': 'Opprett {type}',
  'common.creating': 'Oppretter',
  'common.csv': 'CSV',
  'common.current-password': 'Nåværende passord',
  'common.daily': 'Daglig',
  'common.dashboard': 'Kontrollpanel',
  'common.data': 'Data',
  'common.data-distribution': 'Datafordeling',
  'common.date': 'Dato',
  'common.date-for-type': 'Dato for {type}',
  'common.date-validation-not-in-future':
    'Dato må være gyldig og ikke i fremtiden',
  'common.day-end': 'Dag slutt',
  'common.day-start': 'Dag start',
  'common.days': '{days, plural, =0 {Ingen dager} one {# dag} other {# dager}}',
  'common.deactivated': 'Deaktivert',
  'common.default-severity': 'Standard alvorlighetsgrad',
  'common.delete': 'Slette',
  'common.delete-alt': 'Slett',
  'common.delete-type': 'Slett {type}',
  'common.department': 'Avdeling',
  'common.department-expandable-text': '{amount} avdelinger',
  'common.departments': 'Avdelinger',
  'common.description': 'Beskrivelse',
  'common.detail': 'Detalje',
  'common.details': 'Detaljer',
  'common.diet': 'Diett',
  'common.disabled': 'Deaktivert',
  'common.distance': 'Distanse',
  'common.document': 'Dokument',
  'common.documentation': 'Dokumentasjon',
  'common.documentationIssues': 'Dokumentasjonsavvik',
  'common.documents': 'Dokumenter',
  'common.download': 'Last ned',
  'common.driver': 'Sjåfør',
  'common.drivers': 'Sjåfører',
  'common.drivers-handbook': 'Sjåførhåndbok',
  'common.driversHandbook': 'Sjåførhåndbok',
  'common.driversHandbookForm': 'Håndbokskjema',
  'common.driving': 'Kjøring',
  'common.driving-data': 'Kjøredata',
  'common.dropdown': 'Nedtrekksmeny',
  'common.dropdown-on-pages': 'Nedtrekksmeny på side',
  'common.dropdowns': 'Nedtrekksmenyer',
  'common.edit': 'Endre',
  'common.edit-alt': 'Rediger',
  'common.edit-entry': 'Rediger innlegg',
  'common.edit-name': 'Endre navn',
  'common.edit-type': 'Rediger {type}',
  'common.email': 'E-post',
  'common.email-already-selected': 'Epostadresse er allerede valgt',
  'common.employee': 'Ansatt',
  'common.enabled': 'Aktivert',
  'common.end': 'Slutt',
  'common.end-date': 'Sluttdato',
  'common.end-time': 'Sluttid',
  'common.enter-url': 'Angi URL',
  'common.entry': 'Innlegg',
  'common.eurovignette': 'Eurovignette',
  'common.eurovignettes': 'Eurovignettes',
  'common.excel': 'Excel',
  'common.excel-flat': 'Excel (en side)',
  'common.expired': 'Utløpt',
  'common.expires-at': 'Utløper',
  'common.export': 'Eksporter',
  'common.export-format': 'Format',
  'common.export-type': 'Eksporttype',
  'common.exports-settings': 'Eksport',
  'common.external-id': 'Eksternid',
  'common.fats': 'FATS',
  'common.fats-beta': 'FATS (beta)',
  'common.field': 'Felt',
  'common.file': 'Fil',
  'common.files': 'Filer',
  'common.filter': 'Filter',
  'common.finished': 'Ferdig',
  'common.fire': 'Brann',
  'common.firstName': 'Fornavn',
  'common.folder': 'Mappe',
  'common.form-error': 'Et eller flere felter må fylles inn riktig.',
  'common.form-responses': 'Utfylte skjema',
  'common.formcreator': 'Skjemabehandler',
  'common.forms': 'Skjema',
  'common.forum': 'Forum',
  'common.forum-posts': 'Foruminnlegg',
  'common.forum-threads': 'Forumtråder',
  'common.freight-carrier': 'Lastebærer',
  'common.from': 'Fra',
  'common.general': 'Generelt',
  'common.generate': 'Generer',
  'common.generate-report': 'Generer rapport',
  'common.go-back': 'Tilbake',
  'common.handbook': 'Håndbok',
  'common.height': 'Høyde',
  'common.height-in-cm': 'Høyde i cm',
  'common.high': 'Høy',
  'common.holiday-allowances': 'Helligdagstillegg',
  'common.home': 'Hjem',
  'common.hourlist': 'Timeliste',
  'common.hours': 'Timer',
  'common.hours-abbrev': 't',
  'common.hours-lowercase': 'timer',
  'common.id': 'Id',
  'common.in-app': 'I app',
  'common.include-archived': 'Inkluder arkiverte',
  'common.information': 'Informasjon',
  'common.infringement': 'Brudd',
  'common.infringements': 'Brudd',
  'common.integrations': 'Integrasjoner',
  'common.invalid': 'Ugyldig',
  'common.invalid-date': 'Ugyldig dato',
  'common.invalid-url': 'Ugyldig URL',
  'common.issue': 'Avvik',
  'common.issue-categories': 'Avvikskategorier',
  'common.issued-at': 'Utstedt',
  'common.issues': 'Avvik',
  'common.items': 'Elementer',
  'common.kilometers': '{abbreviate, select, true {km} other {kilometer}}',
  'common.km': 'km',
  'common.km-per-hour-short': 'km/t',
  'common.language': 'Språk',
  'common.last-revision': 'Siste revidering',
  'common.lastName': 'Etternavn',
  'common.length': 'Lengde',
  'common.length-in-cm': 'Lengde i cm',
  'common.liters': 'Liter',
  'common.liters-short': 'l',
  'common.load-more': 'Last flere',
  'common.load-previous': 'Last tidligere',
  'common.loading': 'Laster',
  'common.loading...': 'Laster inn...',
  'common.location': 'Sted',
  'common.log-in': 'Logg inn',
  'common.log-out': 'Logg ut',
  'common.login': 'Login',
  'common.low': 'Lav',
  'common.make-a-choice': 'Gjør et valg',
  'common.make-a-selection': 'Vennligst gjør et valg',
  'common.manual': 'Manuell',
  'common.map': 'Kart',
  'common.mass-print': 'Masseprint',
  'common.maximum': 'Maksimum',
  'common.medium': 'Middels',
  'common.menu': 'Meny',
  'common.minimum': 'Minimum',
  'common.minutes': 'Minutter',
  'common.minutes-abbrev': 'm',
  'common.module': 'Modul',
  'common.month': 'Måned',
  'common.monthly': 'Månedlig',
  'common.move-down': 'Flytt ned',
  'common.move-up': 'Flytt opp',
  'common.n-categories': '{n} kategorier',
  'common.n-hours': '{n} timer',
  'common.n-issues': '{n} avvik',
  'common.n-minutes': '{n} minutter',
  'common.n-salary-code': '{n} lønnsarter',
  'common.name': 'Navn',
  'common.needs-comment': 'Trenger kommentar',
  'common.new-password': 'Nytt passord',
  'common.new-password-again': 'Nytt passord igjen',
  'common.no': 'Nei',
  'common.no-active-modules': 'Ingen aktive moduler',
  'common.no-attachments': 'Ingen vedlegg',
  'common.no-changes': 'Ingen endringer',
  'common.no-comment': 'Ingen kommentar',
  'common.no-more-options': 'Ingen flere valg',
  'common.no-options-to-choose-from': 'Ingen alternativer å velge fra',
  'common.no-results': 'Ingen resultater',
  'common.none': 'Ingen',
  'common.not-archived': 'Ikke arkivert',
  'common.not-verified-yet': 'Ikke godkjent ennå',
  'common.notification': 'Varsling',
  'common.notifications': 'Varsling',
  'common.notifications-alt': 'Varsler',
  'common.office': 'Kontor',
  'common.ok': 'OK',
  'common.open': 'Åpne',
  'common.open-alt': 'Åpen',
  'common.opened': 'Åpnet',
  'common.order': 'Rekkefølge',
  'common.order-by': 'Sorter etter',
  'common.ordinary': 'Ordinær',
  'common.other': 'Annet',
  'common.other-work': 'Annet arbeid',
  'common.overtime': 'Overtid',
  'common.overtime-mode': 'Overtidsmodus',
  'common.overtime-rate': 'Overtidssats',
  'common.password': 'Passord',
  'common.pause': 'Pause',
  'common.pdf': 'PDF',
  'common.pdf-salary': 'PDF (Lønn)',
  'common.period': 'Periode',
  'common.perks': 'Fordeler',
  'common.permissions': 'Tilganger',
  'common.phone': 'Telefon',
  'common.phone-number': 'Telefonnummer',
  'common.phone-prefix': 'Landskode',
  'common.police': 'Politi',
  'common.postal-code': 'Postnummer',
  'common.poweroffice': 'PowerOffice',
  'common.previous-responses': 'Tidligere svar',
  'common.print': 'Print',
  'common.print-type': 'Print {type}',
  'common.priority': 'Prioritet',
  'common.privacy': 'Personvern',
  'common.project': 'Prosjekt',
  'common.publish': 'Publiser',
  'common.published': 'Publisert',
  'common.range': 'Område',
  'common.reason': 'Årsak',
  'common.reg-nr': 'Reg.nr.',
  'common.register': 'Registrer',
  'common.remove': 'Fjern',
  'common.report': 'Rapport',
  'common.required': 'Påkrevd',
  'common.reset': 'Tilbakestill',
  'common.responses': 'Svar',
  'common.responsible': 'Ansvarlig',
  'common.responsible-user': 'Ansvarlig bruker',
  'common.responsible-users': 'Ansvarlige brukere',
  'common.rest': 'Hvile',
  'common.restore': 'Gjenopprett',
  'common.risk-aspect': 'Risikoaspekt',
  'common.risk-aspects': 'Risikoaspekter',
  'common.role': 'Rolle',
  'common.route-planner': 'Kjøreruteplanlegger',
  'common.salary': 'Lønn',
  'common.salary-code': 'Lønnsart',
  'common.salary-codes': 'Lønnsarter',
  'common.salary-day': 'Døgn',
  'common.salary-mode': 'Lønnsmodus',
  'common.save': 'Lagre',
  'common.save-and-close': 'Lagre og lukk',
  'common.save-changes': 'Lagre endringer',
  'common.save-changes-to-type': 'Lagre {type}',
  'common.save-in-user-exports': 'Lagre i mine eksporter',
  'common.save-languages': 'Lagre språk',
  'common.saving': 'Lagrer',
  'common.search': 'Søk ...',
  'common.seconds-abbrev': 's',
  'common.see-all-responses': 'Se alle svar',
  'common.seen-by': 'Sett av',
  'common.select': 'Velg',
  'common.select-all': 'Velg alle',
  'common.select-at-least-one-recipient': 'Velg minst én mottaker',
  'common.select-manual-entry': 'Velg innlegg fra sjåførhåndbok',
  'common.select-project': 'Velg prosjekt',
  'common.select-salary-code': 'Velg lønnsart',
  'common.select-user': 'Velg bruker',
  'common.select-users': 'Velg brukere',
  'common.select-week-days': 'Velg ukedager',
  'common.send-print-to-mail': 'Send utskrift til mail?',
  'common.settings': 'Innstillinger',
  'common.severity': 'Alvorlighetsgrad',
  'common.shortcuts': 'Snarveier',
  'common.show-all': 'Vis alle',
  'common.show-sums': 'Vis summer',
  'common.singular': 'Enkeltvis',
  'common.sms': 'SMS',
  'common.sort-by-user-type': 'Sorter etter brukertype',
  'common.start': 'Start',
  'common.start-date': 'Startdato',
  'common.start-time': 'Starttid',
  'common.statistics': 'Statistikk',
  'common.statistics-this-week': 'Statistikk (Denne uken)',
  'common.status': 'Status',
  'common.submit': 'Send inn',
  'common.sum': 'Sum',
  'common.sums': 'Summer',
  'common.terminal': 'Terminal',
  'common.terminal-network': 'Terminalnettverk',
  'common.the-email': 'E-posten',
  'common.the-field': 'Feltet',
  'common.thread': 'Tråd',
  'common.time': 'Tid',
  'common.time-entries': 'Timeliste',
  'common.time-entry': 'Timeinnlegg',
  'common.time-interval': 'Tidsintervall',
  'common.timesheets': 'Timeliste',
  'common.timesinceposted': 'Tid siden lagt ut',
  'common.timestamp': 'Tidspunkt',
  'common.title': 'Tittel',
  'common.total-work': 'Totalt arbeid',
  'common.tours': 'Turer',
  'common.tripletex': 'Tripletex',
  'common.tv-display': 'TV-visning',
  'common.twentyfour-seven-office': '24SevenOffice',
  'common.two-weekly': 'To uker',
  'common.type': 'Type',
  'common.unavailable': 'Utilgjengelig',
  'common.unknown': 'Ukjent',
  'common.until': 'Til',
  'common.untitled': 'Uten navn',
  'common.update': 'Oppdater',
  'common.update-entry': 'Endre innlegg',
  'common.update-folder': 'Endre mappe',
  'common.update-form': 'Endre skjema',
  'common.update-form-entry': 'Endre skjemainlegg',
  'common.update-password': 'Oppdater passord',
  'common.update-type': 'Endre {type}',
  'common.updated-at': 'Oppdatert',
  'common.updating': 'Oppdaterer',
  'common.uploaded': 'Opplastet',
  'common.uploaded-attachments': 'Opplastede vedlegg',
  'common.user': 'Bruker',
  'common.user-document-categories': 'Dokumentkategorier',
  'common.user-documents': 'Brukerdokumenter',
  'common.user-type': 'Brukertype',
  'common.user-types': 'Brukertyper',
  'common.username': 'Brukernavn',
  'common.users': 'Brukere',
  'common.usersettings': 'Brukerinnstillinger',
  'common.value': 'Verdi',
  'common.vehicle': 'Kjøretøy',
  'common.vehicle-expandable-text': '{amount} kjøretøy',
  'common.vehicle-fleet': 'Kjøretøyflåte',
  'common.verified': 'Godkjent',
  'common.view-comment': 'Se kommentar',
  'common.visma-payroll': 'Visma Payroll',
  'common.week': 'Uke',
  'common.week-n': 'Uke {n}',
  'common.weekly': 'Ukentlig',
  'common.weeks': '{weeks, plural, =0 {Ingen uker} one {# uke} other {# uker}}',
  'common.weight': 'Vekt',
  'common.when-by-whom': '{when} av {whom}',
  'common.whole-day': 'Hele dagen',
  'common.width': 'Bredde',
  'common.width-in-cm': 'Bredde i cm',
  'common.work': 'Arbeid',
  'common.work-hours': 'Arbeidstid',
  'common.year': 'År',
  'common.yes': 'Ja',
  'common.you-have-unsaved-changes':
    'Du har gjort endringer som ikke er lagret',
  'common.you-have-unsaved-changes-navigate':
    'Du har ulagrede endringer. Er du sikker på at du ønsker å forlate denne siden?',
  'companies.create-overtime-period': 'Opprett overtidsperiode',
  'companies.create-period-addon': 'Opprett periodetillegg',
  'companies.edit-overtime-period': 'Endre overtidsperiode',
  'companies.edit-period-addon': 'Endre periodetillegg',
  'companies.existing-overtime-period': 'Eksisterende overtidsperiode',
  'companies.overlapping-periods-are-not-allowed':
    'Overlappende perioder er ikke tillatt',
  'companies.toasts.department-created': 'Avdeling opprettet',
  'companies.toasts.department-deleted': 'Avdeling ble slettet',
  'companies.toasts.department-updated': 'Avdeling ble oppdatert',
  'companies.toasts.holiday-allowance-created':
    'Helligdagstillegg ble lagt til',
  'companies.toasts.holiday-allowance-deleted': 'Helligdagstillegg ble slettet',
  'companies.toasts.holiday-allowance-updated':
    'Helligdagstillegg ble oppdatert',
  'companies.toasts.salary-code-created': 'Lønnsart opprettet',
  'companies.toasts.salary-code-deleted': 'Lønnsart ble slettet',
  'companies.toasts.salary-code-updated': 'Lønnsart oppdatert',
  'countries.codes.AC': 'Ascension Island',
  'countries.codes.AD': 'Andorra',
  'countries.codes.AE': 'De forente arabiske emirater',
  'countries.codes.AF': 'Afghanistan',
  'countries.codes.AG': 'Antigua og Barbuda',
  'countries.codes.AI': 'Anguilla',
  'countries.codes.AL': 'Albania',
  'countries.codes.AM': 'Armenia',
  'countries.codes.AO': 'Angola',
  'countries.codes.AQ': 'Antarktis',
  'countries.codes.AR': 'Argentina',
  'countries.codes.AS': 'Amerikansk Samoa',
  'countries.codes.AT': 'Østerrike',
  'countries.codes.AU': 'Australia',
  'countries.codes.AW': 'Aruba',
  'countries.codes.AX': 'Ålandsøyene',
  'countries.codes.AZ': 'Aserbajdsjan',
  'countries.codes.BA': 'Bosnia og Hercegovina',
  'countries.codes.BB': 'Barbados',
  'countries.codes.BD': 'Bangladesh',
  'countries.codes.BE': 'Belgia ',
  'countries.codes.BF': 'Burkina Faso',
  'countries.codes.BG': 'Bulgaria',
  'countries.codes.BH': 'Bahrain',
  'countries.codes.BI': 'Burundi',
  'countries.codes.BJ': 'Benin',
  'countries.codes.BL': 'St. Barthélemy',
  'countries.codes.BM': 'Bermuda',
  'countries.codes.BN': 'Brunei',
  'countries.codes.BO': 'Bolivia',
  'countries.codes.BQ': 'Karibia Nederland',
  'countries.codes.BR': 'Brasil',
  'countries.codes.BS': 'Bahamas',
  'countries.codes.BT': 'Bhutan',
  'countries.codes.BV': 'Bouvet Island',
  'countries.codes.BW': 'Botswana',
  'countries.codes.BY': 'Hviterussland',
  'countries.codes.BZ': 'Belize',
  'countries.codes.CA': 'Canada',
  'countries.codes.CC': 'Cocos (Keeling) Islands',
  'countries.codes.CD': 'Kongo - Kinshasa',
  'countries.codes.CF': 'Den sentralafrikanske republikk',
  'countries.codes.CG': 'Kongo - Brazzaville',
  'countries.codes.CH': 'Sveits',
  'countries.codes.CI': "Côte d 'Ivoire",
  'countries.codes.CK': 'Cookøyene',
  'countries.codes.CL': 'Chile',
  'countries.codes.CM': 'Kamerun',
  'countries.codes.CN': 'Kina',
  'countries.codes.CO': 'Colombia',
  'countries.codes.CP': 'Clipperton Island',
  'countries.codes.CR': 'Costa Rica',
  'countries.codes.CU': 'Cuba',
  'countries.codes.CV': 'Kapp Verde',
  'countries.codes.CW': 'Curaçao',
  'countries.codes.CX': 'Christmas Island',
  'countries.codes.CY': 'Kypros',
  'countries.codes.CZ': 'Tsjekkia',
  'countries.codes.DE': 'Tyskland',
  'countries.codes.DG': 'Diego Garcia',
  'countries.codes.DJ': 'Djibouti',
  'countries.codes.DK': 'Danmark',
  'countries.codes.DM': 'Dominica',
  'countries.codes.DO': 'Den Dominikanske republikk',
  'countries.codes.DZ': 'Algerie',
  'countries.codes.EA': 'Ceuta & Melilla',
  'countries.codes.EC': 'Ecuador',
  'countries.codes.EE': 'Estland',
  'countries.codes.EG': 'Egypt',
  'countries.codes.EH': 'Vest-Sahara',
  'countries.codes.ER': 'Eritrea',
  'countries.codes.ES': 'Spania',
  'countries.codes.ET': 'Etiopia',
  'countries.codes.EU': 'EU',
  'countries.codes.FI': 'Finland',
  'countries.codes.FJ': 'Fiji',
  'countries.codes.FK': 'Falklandsøyene',
  'countries.codes.FM': 'Mikronesia',
  'countries.codes.FO': 'Færøyene',
  'countries.codes.FR': 'Frankrike',
  'countries.codes.GA': 'Gabon',
  'countries.codes.GB': 'Storbritannia',
  'countries.codes.GD': 'Grenada',
  'countries.codes.GE': 'Georgia',
  'countries.codes.GF': 'Fransk Guyana',
  'countries.codes.GG': 'Guernsey',
  'countries.codes.GH': 'Ghana',
  'countries.codes.GI': 'Gibraltar',
  'countries.codes.GL': 'Grønland',
  'countries.codes.GM': 'Gambia',
  'countries.codes.GN': 'Guinea',
  'countries.codes.GP': 'Guadeloupe',
  'countries.codes.GQ': 'Ekvatorial Guinea',
  'countries.codes.GR': 'Hellas',
  'countries.codes.GS': 'Sør-Georgia og Sør-Sandwichøyene',
  'countries.codes.GT': 'Guatemala',
  'countries.codes.GU': 'Guam',
  'countries.codes.GW': 'Guinea-Bissau',
  'countries.codes.GY': 'Guyana',
  'countries.codes.HK': 'Hong Kong SAR China',
  'countries.codes.HM': 'Heard & McDonald Islands',
  'countries.codes.HN': 'Honduras',
  'countries.codes.HR': 'Kroatia',
  'countries.codes.HT': 'Haiti',
  'countries.codes.HU': 'Ungarn',
  'countries.codes.IC': 'Kanariøyene',
  'countries.codes.ID': 'Indonesia',
  'countries.codes.IE': 'Irland',
  'countries.codes.IL': 'Israel',
  'countries.codes.IM': 'Isle of Man',
  'countries.codes.IN': 'India',
  'countries.codes.IO': 'British Indian Ocean Territory',
  'countries.codes.IQ': 'Irak',
  'countries.codes.IR': 'Iran',
  'countries.codes.IS': 'Island',
  'countries.codes.IT': 'Italia',
  'countries.codes.JE': 'Jersey',
  'countries.codes.JM': 'Jam aica',
  'countries.codes.JO': 'Jordan',
  'countries.codes.JP': 'Japan',
  'countries.codes.KE': 'Kenya',
  'countries.codes.KG': 'Kirgisistan',
  'countries.codes.KH': 'Kambodsja',
  'countries.codes.KI': 'Kiribati',
  'countries.codes.KM': 'Komorene',
  'countries.codes.KN': 'St. Kitts & Nevis',
  'countries.codes.KP': 'Nord-Korea',
  'countries.codes.KR': 'Sør-Korea',
  'countries.codes.KW': 'Kuwait',
  'countries.codes.KY': 'Caymanøyene',
  'countries.codes.KZ': 'Kasakhstan',
  'countries.codes.LA': 'Laos',
  'countries.codes.LB': 'Libanon',
  'countries.codes.LC': 'St. Lucia',
  'countries.codes.LI': 'Liechtenstein',
  'countries.codes.LK': 'Sri Lanka',
  'countries.codes.LR': 'Liberia',
  'countries.codes.LS': 'Lesotho',
  'countries.codes.LT': 'Litauen',
  'countries.codes.LU': 'Luxembourg',
  'countries.codes.LV': 'Latvia',
  'countries.codes.LY': 'Libya',
  'countries.codes.MA': 'Marokko',
  'countries.codes.MC': 'Monaco',
  'countries.codes.MD': 'Moldova',
  'countries.codes.ME': 'Montenegro',
  'countries.codes.MF': 'St. Martin',
  'countries.codes.MG': 'Madagaskar',
  'countries.codes.MH': 'Marshalløyene',
  'countries.codes.MK': 'Makedonia',
  'countries.codes.ML': 'Mali',
  'countries.codes.MM': 'Myanmar (Burma)',
  'countries.codes.MN': 'Mongolia',
  'countries.codes.MO': 'Macau SAR Kina',
  'countries.codes.MP': 'Nord-Marianene',
  'countries.codes.MQ': 'Martinique',
  'countries.codes.MR': 'Mauritania',
  'countries.codes.MS': 'Montserrat',
  'countries.codes.MT': 'Malta',
  'countries.codes.MU': 'Mauritius',
  'countries.codes.MV': 'Maldivene',
  'countries.codes.MW': 'Malawi',
  'countries.codes.MX': 'Mexico',
  'countries.codes.MY': 'Malaysia',
  'countries.codes.MZ': 'Mosambik',
  'countries.codes.NA': 'Namibia',
  'countries.codes.NC': 'Ny-Caledonia ',
  'countries.codes.NE': 'Niger',
  'countries.codes.NF': 'Norfolkøya',
  'countries.codes.NG': 'Nigeria',
  'countries.codes.NI': 'Nicaragua',
  'countries.codes.NL': 'Nederland',
  'countries.codes.NO': 'Norge',
  'countries.codes.NP': 'Nepal',
  'countries.codes.NR': 'Nauru',
  'countries.codes.NU': 'Niue',
  'countries.codes.NZ': 'New Zealand',
  'countries.codes.OM': 'Oman',
  'countries.codes.PA': 'Panama',
  'countries.codes.PE': 'Peru',
  'countries.codes.PF': 'Fransk Polynesia',
  'countries.codes.PG': 'Papua Ny Guinea',
  'countries.codes.PH': 'Filippinene',
  'countries.codes.PK': 'Pakistan',
  'countries.codes.PL': 'Polen',
  'countries.codes.PM': 'St. Pierre & Miquelon',
  'countries.codes.PN': 'Pitcairn Islands',
  'countries.codes.PR': 'Puerto Rico',
  'countries.codes.PS': 'Palestinske territorier',
  'countries.codes.PT': 'Portugal',
  'countries.codes.PW': 'Palau',
  'countries.codes.PY': 'Paraguay',
  'countries.codes.QA': 'Qatar',
  'countries.codes.RE': 'Réunion',
  'countries.codes.RO': 'Romania',
  'countries.codes.RS': 'Serbia',
  'countries.codes.RU': 'Russland',
  'countries.codes.RW': 'Rwanda',
  'countries.codes.SA': 'Saudi-Arabia',
  'countries.codes.SB': 'Salomonøyene',
  'countries.codes.SC': 'Seychellene',
  'countries.codes.SD': 'Sudan',
  'countries.codes.SE': 'Sverige',
  'countries.codes.SG': 'Singapore',
  'countries.codes.SH': 'St. Helena',
  'countries.codes.SI': 'Slovenia',
  'countries.codes.SJ': 'Svalbard & Jan Mayen',
  'countries.codes.SK': 'Slovakia',
  'countries.codes.SL': 'Sierra Leone',
  'countries.codes.SM': 'San Marino',
  'countries.codes.SN': 'Senegal',
  'countries.codes.SO': 'Somalia',
  'countries.codes.SR': 'Surinam',
  'countries.codes.SS': 'Sør-Sudan',
  'countries.codes.ST': 'São Tomé og Príncipe',
  'countries.codes.SV': 'El Salvador',
  'countries.codes.SX': 'Sint Maarten',
  'countries.codes.SY': 'Syria',
  'countries.codes.SZ': 'Swaziland',
  'countries.codes.TA': 'Tristan da Cunha',
  'countries.codes.TC': 'Turks- og Caicosøyene',
  'countries.codes.TD': 'Tsjad',
  'countries.codes.TF': 'franske sørlige territorier',
  'countries.codes.TG': 'Togo',
  'countries.codes.TH': 'Thailand',
  'countries.codes.TJ': 'Tadsjikistan',
  'countries.codes.TK': 'Tokelau',
  'countries.codes.TL': 'Øst-Timor',
  'countries.codes.TM': 'Turkmenistan',
  'countries.codes.TN': 'Tunisia',
  'countries.codes.TO': 'Tonga',
  'countries.codes.TR': 'Tyrkia',
  'countries.codes.TT': 'Trinidad og Tobago',
  'countries.codes.TV': 'Tuvalu',
  'countries.codes.TW': 'Taiwan',
  'countries.codes.TZ': 'Tanzania',
  'countries.codes.UA': 'Ukraina',
  'countries.codes.UG': 'Uganda',
  'countries.codes.UM': 'USA De ytre øyene',
  'countries.codes.UN': 'De forente nasjoner',
  'countries.codes.US': 'De forente stater',
  'countries.codes.UY': 'Uruguay',
  'countries.codes.UZ': 'Usbekistan',
  'countries.codes.VA': 'Vatikanstaten',
  'countries.codes.VC': 'St. Vincent og Grenadinene',
  'countries.codes.VE': 'Venezuela',
  'countries.codes.VG': 'De britiske jomfruøyene',
  'countries.codes.VI': 'USA Jomfruøyene',
  'countries.codes.VN': 'Vietnam',
  'countries.codes.VU': 'Vanuatu',
  'countries.codes.WF': 'Wallis og Futuna',
  'countries.codes.WS': 'Samoa',
  'countries.codes.XK': 'Kosovo',
  'countries.codes.YE': 'Yemen',
  'countries.codes.YT': 'Mayotte',
  'countries.codes.ZA': 'Sør-Afrika',
  'countries.codes.ZM': 'Zambia',
  'countries.codes.ZW': 'Zimbabwe',
  'countries.european-community': 'Europeiske samfunn',
  'countries.rest-of-europe': 'Resten av Europa',
  'countries.rest-of-world': 'Resten av verden',
  'createUser.title': 'Opprett ny bruker',
  'dashboard.enter-driver-card-number.errors.invalid-number':
    'Ugyldig sjåførkortnummer',
  'dashboard.lastForumPosts': 'Siste innlegg',
  'dashboard.register-hours': 'Registrer timer',
  'dashboard.registerHours': 'Registrer timer',
  'document.files-for-document': 'Filer tilhørende {name}',
  'documentation.documentation-for-control': 'Dokumentasjon for kontroll',
  'documentation.drive-and-rest-time': 'Kjøre- og hviletid',
  'documents.add-document': 'Legg til dokument',
  'documents.continue-to-files': 'Fortsett til filer',
  'documents.documents-of-user': 'Dokumenter: {user}',
  'documents.edit-file': 'Endre fil',
  'documents.expiration-date': 'Utløpsdato',
  'documents.files-for-document': 'Filer til {document}',
  'documents.name-on-document': 'Navn på dokument',
  'documents.no-documents': 'Ingen dokumenter',
  'documents.no-files': 'Ingen filer',
  'documents.notification-time':
    'Tid for varsling i antall uker før utløpsdato',
  'documents.notification-time-alt': 'Varslingstidspunkt',
  'documents.number-id': 'Nummer/ID',
  'documents.open-file': 'Åpne fil',
  'documents.prompts.delete-document': 'Slett dokument',
  'documents.prompts.delete-document-confirmation':
    'Er du sikker på at du vil slette "{name}"?',
  'documents.prompts.delete-file':
    'Er du sikker på at du vil slette filen "{name}"?',
  'documents.prompts.delete-file-title': 'Slett fil',
  'documents.responsible-user': 'Dokumentansvarlig',
  'documents.show-files': 'Vis filer',
  'documents.upload-document': 'Last opp dokument',
  'documents.upload-file': 'Last opp fil',
  'documents.view-file': 'Vis fil',
  'error.activities-pause-duration':
    'Kjøredata trenger minimum 45 minutter pause',
  'error.address-required': 'Adresse er påkrevd',
  'error.archive-type': 'Kunne ikke arkivere {type}',
  'error.categories': 'Kunne ikke hente kategorier',
  'error.city-required': 'By er påkrevd',
  'error.company-missing-tachograph-key':
    'Bedriften mangler en Tachograph API nøkkel. Kontakt admin for videre hjelp.',
  'error.contact-person-phone-required':
    'Telefonnummer til kontaktperson er påkrevd',
  'error.contact-person-required': 'Navn på kontaktperson er påkrevd',
  'error.could-not-change-profile-information':
    'Kunne ikke endre profilinformasjon',
  'error.could-not-check-in': 'Kunne ikke sjekke inn',
  'error.could-not-check-out': 'Kunne ikke sjekke ut',
  'error.could-not-create-type': 'Kunne ikke lage {type}',
  'error.could-not-delete-type': 'Kunne ikke slette {type}',
  'error.could-not-edit-type': 'Kunne ikke endre {type}',
  'error.could-not-find-data': 'Kunne ikke finne tabelldata',
  'error.could-not-find-location': 'Klarte ikke finne angitt lokasjon',
  'error.could-not-get-absence-types': 'Kunne ikke finne fraværstyper',
  'error.could-not-read-file': 'Kunne ikke lese fil',
  'error.create-issue': 'Kunne ikke lage avvik',
  'error.driver-card-expiration-date-not-found':
    'Klarte ikke finne utløpsdato for sjåførkortet.',
  'error.driver-card-is-expired': 'Utgått sjåførkort',
  'error.drivercard-between-14-16': 'Feltet trenger 14-16 tegn',
  'error.end-time-too-early': 'Sluttid må være senere enn starttid',
  'error.expiration-date-not-found-add-expiration-date-manually':
    'Fant ingen utløpsdato på kortet. Legg til dato manuelt.',
  'error.field-can-not-be-empty': 'Feltet kan ikke være tomt',
  'error.field-is-required': '{field} er påkrevd',
  'error.files.could-not-read': 'Kunne ikke lese fil',
  'error.filled-out-form-entry-does-not-exist': 'Utfylt skjema finnes ikke.',
  'error.first-name-required': 'Fornavn er påkrevd',
  'error.generic': 'Det har skjedd en feil',
  'error.generic-server-error':
    'Noe gikk galt på tjeneren. Vennligst prøv igjen.',
  'error.generic-title': 'Noe gikk galt',
  'error.internal-number-required': 'Internnummer er påkrevd',
  'error.invalid-birth-number': 'Fødelsnummeret er ugyldig',
  'error.invalid-file-type': 'Ugyldig filtype',
  'error.invalid-phone-number': 'Ugyldig nummer',
  'error.invalid-value': 'Ugyldig verdi',
  'error.last-name-required': 'Etternavn er påkrevd',
  'error.missing-permission':
    'Du mangler nødvendig tilgang til dette innholdet',
  'error.name-exists': 'Det finnes allerede et innlegg med dette navnet',
  'error.need-at-least-one-user-type': 'Trenger minst én brukertype',
  'error.need-company-name': 'Firmanavn må være fylt ut',
  'error.no-first-name': 'Mangler fornavn',
  'error.not-permitted': 'Mangler tilgang',
  'error.not-permitted-to-view': 'Du har ikke tillatelse til å se dette',
  'error.number-must-be-positive': 'Tallet må være positivt',
  'error.phone-number-required': 'Telefonnummer er påkrevd',
  'error.postal-code-required': 'Postnummer er påkrevd',
  'error.try-again': 'Prøv på nytt',
  'error.type-already-exist': '{type} eksisterer allerede',
  'error.type-does-not-exist': '{type} eksisterer ikke',
  'error.un-archive-type': 'Kunne ikke gjenåpne {type}',
  'error.update-issue': 'Kunne ikke oppdatere avvik',
  'error.user-has-no-infringements-for-the-selected-period':
    'Brukeren har ingen brudd i perioden',
  'error.valid-email-required': 'Gyldig emailadresse er påkrevd',
  'error.wrong-password': 'Feil passord',
  'error.wrong-username-or-password': 'Feil brukernavn eller passord',
  'errors.common.name-already-exists': 'Navnet eksisterer allerede',
  'errors.could-not-save-changes': 'Kunne ikke lagre endringer',
  'errors.defaults.does-not-exist': 'Objektet eksisterer ikke',
  'errors.defaults.foreign-key-constraint':
    'Objektet refererer til noe som ikke eksisterer',
  'errors.defaults.invalid-value': 'En av de oppgitte verdiene var ugyldig',
  'errors.defaults.not-permitted':
    'Brukeren din har ikke tilgang til å gjøre dette',
  'errors.defaults.unique-constraint': 'Objektet må være unikt',
  'errors.driver-card-already-taken': 'Sjåførkortet er allerede i bruk.',
  'errors.driver-card-not-found-in-external-integration':
    'Fant ikke sjåførkort i ekstern integrasjon. Sjekk om kortet er lagt inn i dine flåtesystem.',
  'errors.driver-card-not-found-similar-driver-card-already-taken':
    'Sjåførkortet finnes ikke. Lignende sjåførkort er allerede i bruk.',
  'errors.generic-error': 'Noe har gått galt',
  'errors.must-be-whole-number': 'Må være et heltall',
  'errors.passwords-not-equal': 'Passordene er ulike',
  'errors.users.could-not-change-password': 'Kunne ikke bytte passord',
  'exports.error-email-process': 'Klarte ikke lage eksport for mail',
  'exports.error-user-salary-settings':
    'Brukeren har ingen brukertyper tilknyttet salgsinnstillinger',
  'exports.export-data': 'Eksportere utskrifter',
  'exports.month-export': 'Månedsutskrift',
  'exports.salary-export': 'Lønnsgrunnlagsutskrift',
  'exports.success-email-process':
    'Data behandles. Du vil motta en mail når prosessen er ferdig.',
  'exports.success-user-exports':
    'Data behandles. Du vil motta varsel når prosessen er ferdig.',
  'exports.user-exports': 'Mine eksporter',
  'files.drop-to-upload': 'Dropp filer her for å laste dem opp',
  'files.file-too-large':
    'Filen {name} er større enn maksimal størrelse på {max}MB.',
  'files.upload-files-here': 'Last opp filer her',
  'forgotten-password.forgotten': 'Glemt passordet?',
  'forgotten-password.go-back': 'Tilbake til innlogging',
  'forgotten-password.message':
    'Hvis du har glemt passordet ditt, vennligst skriv inn brukernavnet eller e-posten din. Om vi finner kontoen din, vil en e-post med et nytt passord bli sendt til deg.',
  'forgotten-password.success':
    'Hvis kontoen din finnes, er en e-post på vei med instruksjoner for tilbakestilling av passord.',
  'forgotten-password.title': 'Glemt passord',
  'form.add-field': 'Legg til felt',
  'form.all-responses-loaded': 'Alle svar lastet',
  'form.archive-form-error': 'Kunne ikke arkivere skjemaet',
  'form.archive-form-success': 'Skjemaet ble arkivert',
  'form.archive-prompt-text':
    'Er du sikker på at du ønsker å arkivere dette skjemaet?',
  'form.archive-prompt-title': 'Arkivere skjema',
  'form.create-filled-form-success': 'Skjemaet ble fylt ut',
  'form.create-form': 'Lag nytt skjema',
  'form.create-form-alt': 'Opprett skjema',
  'form.created-form-success': 'Skjema opprettet',
  'form.delete-checklist-template-prompt-text':
    'Er du sikker på at du ønsker å slette sjekklistemalen?',
  'form.delete-checklist-template-prompt-title': 'Slett sjekklistemal',
  'form.delete-entry-prompt-text':
    'Er du sikker på at du ønsker å slette dette utfylte skjemaet?',
  'form.delete-entry-prompt-title': 'Slett utfylt skjema',
  'form.delete-field-prompt-text':
    'Er du sikker på at du ønsker å slette dette skjemafeltet?',
  'form.delete-field-prompt-title': 'Slette skjemafelt?',
  'form.delete-template-prompt-text':
    'Er du sikker på at du vil slette dette skjemaet?',
  'form.delete-template-prompt-title': 'Slett skjema',
  'form.edit-form': 'Endre skjema',
  'form.edit-this-form': 'Endre dette skjemaet',
  'form.errors.load-responses': 'Kunne ikke laste skjemasvar',
  'form.field': 'Felt',
  'form.field-type-boolean': 'Ja/Nei',
  'form.field-type-date': 'Dato',
  'form.field-type-number': 'Nummer',
  'form.field-type-text': 'Tekst',
  'form.field-type-user': 'Bruker',
  'form.field-type-vehicle-department': 'Avdeling på kjøretøy',
  'form.fields': 'Felter',
  'form.fill-out-this-form': 'Fyll ut dette skjemaet',
  'form.filled-out-n-times-by-user':
    '{n, plural, =0 {Du har aldri fyllt ut dette skjemaet} one {Du har fyllt ut dette skjemaet 1 gang} other {Du har fyllt ut dette skjemaet # ganger}}',
  'form.filled-out-n-times-by-user-including-today':
    '{n, plural, =0 {Du har aldri fyllt ut dette skjemaet} one {Du har fyllt ut dette skjemaet 1 gang, inkludert i dag} other {Du har fyllt ut dette skjemaet # ganger, inkludert i dag}}',
  'form.form-entry': 'Skjema',
  'form.form-entry-deleted': 'Innsendingen ble slettet',
  'form.form-fields': 'Skjemafelter',
  'form.form-label-name': 'Skjemanavn',
  'form.form-settings': 'Skjemainstillinger',
  'form.label-archive-date': 'Arkiveringsdato',
  'form.label-archive-max-uses':
    'Maks antall ganger skjema kan brukes før arkivering',
  'form.label-archive-mode': 'Arkiveringsmetode',
  'form.label-field-description': 'Beskrivelse',
  'form.label-field-input-type': 'Inputtype',
  'form.label-field-name': 'Feltnavn',
  'form.label-field-type': 'Felttype',
  'form.label-field-value': 'Tekst',
  'form.label-include-footer': 'Inkluder bunntekst',
  'form.label-include-icon': 'Inkluder firmaikon',
  'form.label-ingress': 'Ingress',
  'form.label-type': 'Type skjema',
  'form.last-response-at': 'Siste svar',
  'form.load-all-responses': 'Last alle svar',
  'form.menu-shortcut': 'Snarvei i sidemeny',
  'form.no-responses': 'Ingen svar',
  'form.open-overview': 'Åpne oversikt',
  'form.prompts.delete-form':
    'Er du helt sikker på at du vil slette dette skjemaet? Alle svar vil også bli slettet.',
  'form.prompts.delete-form-title': 'Slett skjema',
  'form.prompts.unverify-response':
    'Vil du fjerne godkjennelsen av dette svaret?',
  'form.prompts.unverify-response-title': 'Fjern godkjennelse',
  'form.prompts.verify-response': 'Vil du godkjenne dette svaret?',
  'form.prompts.verify-response-title': 'Godkjenn',
  'form.remove-field': 'Fjern felt',
  'form.responses-for-form': 'Svar: {name}',
  'form.see-answers': 'Se svar',
  'form.select-form': 'Velg skjema',
  'form.showing-num-of-total-responses': 'Viser {num} av {total} svar',
  'form.table-label-created': 'Opprettet',
  'form.table-label-edit': 'Rediger',
  'form.table-label-filled-in-by': 'Fylt inn av',
  'form.table-label-name': 'Navn',
  'form.table-label-updated': 'Oppdatert',
  'form.table-label-use-form': 'Bruk skjema',
  'form.table-label-used': 'Svar',
  'form.toasts.create-form-success': 'Skjema opprettet',
  'form.toasts.delete-form-success': 'Skjema slettet',
  'form.toasts.patch-form-success': 'Skjema endret',
  'form.toasts.submit-success': 'Skjemaet ble sendt inn',
  'form.un-archive-prompt-text':
    'Er du sikker på at du ønsker å gjenåpne dette skjemaet?',
  'form.un-archive-prompt-title': 'Gjenåpne skjema',
  'form.update-form-success': 'Skjema oppdatert',
  'form.updated-form-success': 'Skjema oppdatert',
  'form.validation.cannot-be-negative': 'Kan ikke være negativ',
  'form.validation.required-fields-missing':
    'Alle påkrevde felter er ikke fyllt ut',
  'form.verified-by-at': 'Godkjent av {name}, {when}',
  'formcreator.distribution-field': 'Fordelingsfelt',
  'formcreator.export-form-submissions': 'Eksporter skjema',
  'formcreator.filter-field': 'Filterfelt',
  'formcreator.summation-field': 'Summeringsfelt',
  'forum.add-comment': 'Legg til kommentar',
  'forum.answers-number': '{num} svar',
  'forum.archived-threads': 'Arkiverte tråder',
  'forum.create-new-post': 'Lag nytt svar',
  'forum.create-thread': 'Lag ny tråd',
  'forum.create-thread.send-email-error':
    'En feil oppstod ved sending av e-poster',
  'forum.create-thread.send-sms-error': 'En feil oppstod ved sending av SMS',
  'forum.create-thread.success': 'Opprettet tråd',
  'forum.created': 'Lagt ut',
  'forum.edit-post': 'Rediger svar',
  'forum.edit-thread': 'Rediger tråd',
  'forum.errors.content-required': 'Innhold er påkrevd',
  'forum.errors.create-thread': 'Kunne ikke opprette ny tråd',
  'forum.errors.delete-post-failed': 'Kunne ikke slette kommentar',
  'forum.errors.delete-thread-failed': 'Kunne ikke slette tråd',
  'forum.errors.thread-does-not-exist': 'Tråden eksisterer ikke',
  'forum.errors.title-required': 'Tittel er påkrevd',
  'forum.last-posts': 'Siste kommenterte innlegg',
  'forum.last-threads': 'Siste tråder',
  'forum.new': 'Ny tråd',
  'forum.new-thread': 'Ny tråd',
  'forum.no-views': 'Ikke sett av noen',
  'forum.number-of-posts': 'Antall svar',
  'forum.post': '{choice, select, definite {Innlegget} other {Innlegg}}',
  'forum.post-comment': 'Post kommentar',
  'forum.posts-error-message': 'Noe galt skjedde ved henting av innlegg',
  'forum.prompts.delete-attachment':
    'Er du sikker på at du vil slette dette vedlegget?',
  'forum.prompts.delete-attachment-title': 'Slett vedlegg',
  'forum.prompts.delete-comment-prompt':
    'Er du sikker på at du ønsker å slette denne kommentaren?',
  'forum.prompts.delete-comment-prompt-title': 'Slett kommentar',
  'forum.prompts.delete-post':
    'Er du sikker på at du ønsker å slette denne kommentaren?',
  'forum.prompts.delete-post-title': 'Slett kommentar',
  'forum.prompts.delete-thread':
    'Er du sikker på at du vil slette denne tråden?',
  'forum.prompts.delete-thread-text':
    'Er du sikker på at ønsker å slette tråden {title}? Denne handlingen kan ikke bli reversert.',
  'forum.prompts.delete-thread-title': 'Slett tråd',
  'forum.prompts.download-attachment-text':
    'Kan ikke åpne dette vedlegget, ønsker du å laste det ned?',
  'forum.prompts.download-attachment-title': 'Last ned vedlegg',
  'forum.quill-link-helper':
    'Marker teksten du vil gjøre til en link, deretter klikk link-knappen',
  'forum.read-by-n-employees':
    '{n, plural, =0 {Lest av ingen ansatte} one {Lest av # ansatt} other {Lest av # ansatte}}',
  'forum.search': 'Søk i forumet',
  'forum.seen-at': 'Sett',
  'forum.send-email-label': 'Send varsling på e-post',
  'forum.send-sms-label': 'Send sms-varsling til {employeeNum} ansatte',
  'forum.sms-notification': 'SMS notifikasjon',
  'forum.sticky-thread': 'Festet tråd',
  'forum.sticky-threads': 'Festede tråder',
  'forum.thread':
    '{choice, select, definite {Tråden} plural {Trådene} other {Tråd}}',
  'forum.threads-error-message': 'Noe galt skjedde ved henting av tråder',
  'forum.timesinceposted': 'Timer siden ',
  'forum.toasts.archive-thread-error': 'Kunne ikke arkivere tråden',
  'forum.toasts.archive-thread-success': 'Tråden ble arkivert',
  'forum.toasts.create-thread-success': 'Tråd opprettet',
  'forum.toasts.delete-thread-error': 'Tråden kunne ikke bli slettet',
  'forum.toasts.delete-thread-success': 'Tråden ble slettet',
  'forum.toasts.patch-post-error':
    'Noe gikk galt under oppdateringen av svaret',
  'forum.toasts.patch-post-success': 'Svaret ble oppdatert',
  'forum.toasts.patch-thread-error':
    'Noe gikk galt under oppdateringen av tråden',
  'forum.toasts.patch-thread-success': 'Tråden ble oppdatert',
  'forum.toasts.restore-thread-success': 'Tråden ble gjenopprettet',
  'forum.unseen-only': 'Kun usett',
  'forum.update-comment': 'Oppdater kommentar',
  'forum.update-thread': 'Oppdater tråd',
  'forum.validation.thread-must-have-content': 'Tråden må ha innhold',
  'forum.view-count-title': 'Tråden er sett av',
  'forum.written-when': 'Skrevet {when}',
  'handbook.add-entry': 'Legg til innlegg',
  'handbook.add-folder': 'Legg til mappe',
  'handbook.add-sub-folder': 'Legg til undermappe',
  'handbook.create-entry-success': 'Innlegg opprettet',
  'handbook.create-folder-success': 'Opprettet mappe',
  'handbook.create_entry': 'Lag nytt innlegg',
  'handbook.dashboard-links': 'Linker for kontrollpanelet',
  'handbook.edit-folder': 'Rediger mappe',
  'handbook.entry-load-error': 'Feil ved innlasting av innlegg',
  'handbook.entry.delete-success': 'Slettet innlegg',
  'handbook.errors.delete-entry': 'Kunne ikke slette innlegg',
  'handbook.errors.delete-folder': 'Kunne ikke slette mappe',
  'handbook.folder-load-error': 'Feil ved innlasting av mappe',
  'handbook.folder.delete-success': 'Slettet mappe',
  'handbook.new-root-folder': 'Ny rotmappe',
  'handbook.prompts.delete-entry':
    'Er du sikker på at du ønsker å slette dette innlegget?',
  'handbook.prompts.delete-entry-title': 'Slett innlegg',
  'handbook.prompts.delete-folder':
    'Er du helt sikker på at du ønsker å slette denne mappen? Alt innhold vil også bli slettet.',
  'handbook.prompts.delete-folder-title': 'Slett mappe',
  'handbook.search': 'Søk i sjåførhåndboken',
  'handbook.start': 'Start',
  'handbook.toasts.create-entry-success':
    'Håndboksinnlegg "{name}" ble opprettet',
  'handbook.toasts.create-folder-success': 'Mappe opprettet',
  'handbook.toasts.delete-entry-success': 'Innlegget ble slettet',
  'handbook.toasts.delete-folder-success': 'Mappen ble slettet',
  'handbook.toasts.patch-folder-success': 'Mappe lagret',
  'handbook.update-entry-success': 'Innlegget ble oppdatert',
  'handbook.update-folder-success': 'Mappen ble oppdatert',
  'infringements.amount-of-infringements-in-categories': 'Brudd pr kategori',
  'infringements.archive-infringement': 'Arkiver brudd',
  'infringements.archived-basis': 'Arkiveringsgrunnlag',
  'infringements.archived-date': 'Arkiveringsdato',
  'infringements.category-infringement': '{category}-brudd',
  'infringements.export-infringement': 'Eksporter brudd',
  'infringements.export-infringement-data': 'Eksporter bruddata',
  'infringements.export.all': 'Eksporter brudd for hele bedriften',
  'infringements.legal-basis': 'Lovverksreferanse',
  'infringements.lower-limit': 'Nedre grense',
  'infringements.minor-infringement': 'Mindre alvorlig brudd',
  'infringements.most-serious-infringement': 'Høyst alvorlig brudd',
  'infringements.my-infringements': 'Mine brudd',
  'infringements.n-points': '{n} poeng',
  'infringements.no-infringements': 'Ingen brudd',
  'infringements.restore-infringement': 'Gjenopprett brudd',
  'infringements.send-email-success': 'Brudd sendt på epost.',
  'infringements.serious-infringement': 'Alvorlig brudd',
  'infringements.severity': 'Alvorlighetsgrad',
  'infringements.total-severity': 'Total alvorlighetsgrad',
  'infringements.upper-limit': 'Øvre grense',
  'infringements.very-serious-infringement': 'Veldig alvorlig brudd',
  'integrations.error-in-API-keys': 'Feil i API-nøklene',
  'integrations.error-in-FMS-key': 'Feil i FMS API-nøkkelen',
  'integrations.error-in-tachograph-key': 'Feil i Tachograph API-nøkkelen',
  'issue.category-responsible-user': 'Avvikskategoriansvarlig',
  'issue.existing-attachments': 'Eksisterende vedlegg',
  'issues.action-log': 'Handlingslogg',
  'issues.actual-cost': 'Faktisk kostnad',
  'issues.add-recipient-with-email': 'Legg til mottaker med epost',
  'issues.archive-issue': 'Arkiver avvik',
  'issues.archived-by-user': 'Arkivert av {user}',
  'issues.attachments': 'Vedlegg',
  'issues.category-name': 'Kategorinavn',
  'issues.close-issue': 'Lukk avvik',
  'issues.closed-at': 'Lukket dato',
  'issues.closed-by': 'Lukket av',
  'issues.closed-by-user': 'Lukket av {user}',
  'issues.cost-estimation': 'Estimert kostnad',
  'issues.create-issue': 'Opprett avvik',
  'issues.create-issue-for': 'Opprett avvik for:',
  'issues.created-by-user': 'Opprettet av {user}',
  'issues.damage-description': 'Hva har blitt skadet',
  'issues.damage-description-long':
    'Hva har blitt skadet — Fyll inn både direkte og indirekte objekter',
  'issues.damage-description-short': 'Skadde objekter',
  'issues.damage-direct-and-indirect-objects':
    'Fyll inn direkte og indirekte objekter',
  'issues.damage-location': 'Hvor skjedde det',
  'issues.delete-issue': 'Slett avvik',
  'issues.delete-issue-error-message':
    'Du forsøker å slette en kategori som har tilknyttede avvik. Dette er ikke mulig.',
  'issues.describe-the-damage': 'Beskriv skaden/påvirket objekt',
  'issues.description-of-event-unwanted-event':
    'Beskrivelse av hendelse/uønsket hendelse',
  'issues.edit-issue': 'Endre avvik',
  'issues.equipment-material': 'Utstyr/materiell',
  'issues.error-retrieving-users': 'En feil oppstod under henting av brukere',
  'issues.errors.create-issue': 'Kunne ikke opprette avvik',
  'issues.errors.must-enter-actual-cost': 'Må angi kostnad',
  'issues.errors.must-enter-solution': 'Løsning er påkrevd',
  'issues.errors.patch-issue': 'Kunne ikke oppdatere avvik',
  'issues.export-issue': 'Eksporter avvik',
  'issues.extend-issue-form': 'Ønsker du å lukke avviket umiddelbart?',
  'issues.fill-in-actual-cost': 'Fyll inn faktisk kostnad',
  'issues.fill-in-solution': 'Fyll inn endelig løsning',
  'issues.fill-in-vehicle-reg-nr':
    'Fyll inn reg.nr. på aktuelt kjøretøy/tilhenger',
  'issues.final-solution': 'Endelig løsning',
  'issues.find-issue': 'Finn avvik',
  'issues.give-estimated-cost': 'Gi et estimat på avvikets kostnader',
  'issues.issue-categories': 'Avvikskategorier',
  'issues.issue-categories.errors.could-not-load-error':
    'Kunne ikke laste kategorier',
  'issues.issue-categories.no-categories': 'Ingen kategorier',
  'issues.issue-category': 'Avvikskategori',
  'issues.issue-description-about': 'Om avviket',
  'issues.issue-location': 'Sted for avvik/hendelse',
  'issues.issue-report': 'Avviksmelding',
  'issues.location-description': 'Hvor på stedet har avviket tatt sted',
  'issues.new-issue': 'Nytt avvik',
  'issues.no-issues': 'Ingen avvik',
  'issues.opened-by': 'Åpnet av',
  'issues.reference': '{abbreviate, select, true {Ref.} other {Referanse}}',
  'issues.reopen-issue': 'Gjenåpne avvik',
  'issues.reopened-by-user': 'Gjenåpnet av {user}',
  'issues.responsible': 'Ansvarlig',
  'issues.restore-issue': 'Gjenopprett avvik',
  'issues.restored-by-user': 'Gjenopprettet av {user}',
  'issues.search-for-issue': 'Søk etter avvik',
  'issues.select-recipient-from-list': 'Velg mottaker fra liste',
  'issues.selected-recipients': 'Valgte mottakere',
  'issues.send-email-success': 'Rapporten ble sendt på epost.',
  'issues.solution-suggestion-about': 'Forslag til tiltak',
  'issues.success-creation': 'Opprettet nytt avvik',
  'issues.success-update': 'Oppdaterte avvik',
  'issues.suggested-improvement': 'Forbedringsforslag',
  'issues.suggested-solution': 'Foreslått løsning',
  'issues.time-of-issue': 'Tid for avvik',
  'issues.toasts.create-issue-success': 'Avviket ble opprettet',
  'issues.toasts.patch-issue-success': 'Avviket ble oppdatert',
  'issues.treated': 'Behandlet',
  'issues.untreated': 'Ubehandlet',
  'issues.update-actual-cost': 'Oppdater faktisk kostnad',
  'issues.updated-by-user': 'Oppdatert av {user}',
  'issues.validation.solution-required':
    'For å lukke dette avviket må du oppgi en løsning',
  'issues.view-all-issues': 'Alle avvik',
  'issues.view-your-issues': 'Dine avvik',
  'login.email-or-phone-number': 'E-post eller telefonnummer',
  'login.forgotten-password': 'Glemt passordet ditt?',
  'login.remember-me': 'Husk meg',
  'login.subtitle': 'Velkommen til',
  'modal.and-all-its-content': ' og alt den inneholder',
  'modal.delete-attachment': 'Ønsker du å slette "{title}" fra vedleggene?',
  'modal.delete-attachment-title': 'Slette vedlegg',
  'modal.delete-folder': 'Slette mappe',
  'modal.delete-folder-description':
    'Ønsker du å slette mappen "{title}" og alt innholdet?',
  'modal.delete-folder-entry-description':
    'Ønsker du å slette innlegget "{title}"?',
  'modal.delete-folder-entry-title': 'Slette innlegg',
  'modal.delete-folder-title': 'Slette mappen',
  'modal.delete-type': 'Er du sikker på at du ønsker å slette {type}?',
  'modal.delete-type-title': 'Slette {type}',
  'modal.remove-attachment': 'Ønsker du å fjerne "{title}" fra vedleggslisten?',
  'modal.remove-attachment-title': 'Fjerne vedlegg',
  'notifications-settings.issue_closed_notification': 'Avvik lukket varsel',
  'notifications-settings.issue_created_notification': 'Avvik opprettet varsel',
  'notifications-settings.new_post_created_notification':
    'Ny forumpost opprettet varsel',
  'notifications-settings.new_thread_created_notification':
    'Ny forumtråd opprettet varsel',
  'notifications-settings.title': 'Varslingsinstillinger',
  'notifications.add-notifications': 'Legg til varsling?',
  'notifications.error.general': 'Noe gikk galt ved innlasting av varsler',
  'notifications.go-to-absence': 'Gå til Fravær',
  'notifications.go-to-document': 'Gå til dokument',
  'notifications.go-to-thread': 'Gå til tråd',
  'notifications.you-have-no-notifications': 'Du har ingen varsler',
  'password.must-contain-nonnumeric':
    'Passordet må inneholde minst én ikke-numerisk karakter',
  'password.must-contain-numeric-value':
    'Passordet må inneholde minst ett tall',
  'password.too-short': 'Passordet må minst inneholde 7 tegn',
  'permissions.add': 'Opprette',
  'permissions.approve': 'Godkjenne',
  'permissions.change': 'Endre',
  'permissions.change_sticky': 'Endre festet tråd',
  'permissions.company-settings-general': 'Generelt',
  'permissions.forum-send-email': 'Sende e-post',
  'permissions.forum-send-sms': 'Sende SMS',
  'permissions.no-access-to-page':
    'Brukeren din har ikke tilgang til denne siden',
  'permissions.remove': 'Slette',
  'permissions.view': 'Se',
  'permissions.view-and-change': 'Se/Endre',
  'permissions.view-others': 'Se andres',
  'print.error-email-process': 'Fikk ikke til å generere email-utskrift',
  'print.export-type': 'Eksporter {type}',
  'print.monthly-print': 'Månedlig utskrift',
  'print.salary-print': 'Lønnsutskrift',
  'print.send-to-email': 'Send til email',
  'print.success-email-process':
    'Forespørsel prosesserer. Når prosessen er fullført, får du en email.',
  'quill.delete-column': 'Slett kolonne',
  'quill.delete-row': 'Slett rad',
  'quill.delete-table': 'Slett tabell',
  'quill.insert-column-left': 'Sett inn kolonne til venstre',
  'quill.insert-column-right': 'Sett inn kolonne til høyre',
  'quill.insert-row-above': 'Sett inn rad over',
  'quill.insert-row-below': 'Sett inn rad under',
  'quill.new-table': 'Ny tabell',
  'quill.table-tooltip': 'Shift + høyreklikk for å modifisere tabell.',
  'register-user.description':
    'Siden dette er første gang du logger inn må du fylle ut noe standard informasjon.',
  'register-user.title': 'Fyll inn personalia',
  'reset-password.errors.bad-token': 'Nøkkelen er ugyldig',
  'reset-password.errors.expired-token':
    'Tilbakestillingsnøkkelen har gått ut på dato.',
  'reset-password.go-back': 'Tilbake til innlogging',
  'reset-password.message':
    'Vennligst velg et nytt passord. Passordet må være minst 8 bokstaver langt og kan ikke bestå utelukkende av tall.',
  'reset-password.submit': 'Skift passord og logg inn',
  'reset-password.success': 'Passordet er blitt resatt',
  'reset-password.title': 'Tilbakestill passordet ditt',
  'route-planner.add-new-route': 'Legg til ny rute',
  'route-planner.axle-weight-restrictions': 'Vektfordeling på aksler',
  'route-planner.create-route-plan': 'Opprett en ruteplan',
  'route-planner.create-route-plan-success': 'Ruteplan ble opprettet',
  'route-planner.delete-route-plan-success': 'Ruteplanen ble slettet',
  'route-planner.destination': 'Destinasjon',
  'route-planner.estimated-driving-duration': 'Estimert kjøretid',
  'route-planner.export-route-plan': 'Eksporter ruteplan',
  'route-planner.extra-load': 'Ekstra last',
  'route-planner.form.label.total-weight-in-kg': 'Totalvekt i kg',
  'route-planner.form.label.total-weight-of-vehicle': 'Totalvekt kjøretøy i kg',
  'route-planner.general-weight-restriction': 'Generell vektfordeling',
  'route-planner.include-departure-time': 'Inkluder starttid?',
  'route-planner.load': 'Last',
  'route-planner.loading-route': 'Laster inn rute...',
  'route-planner.no-route-instructions': 'Ingen veibeskrivelse',
  'route-planner.no-routes': 'Ingen ruter',
  'route-planner.payload': 'Nyttelast',
  'route-planner.payload-in-kilos': 'Nyttelast i kilo',
  'route-planner.route-instructions': 'Veibeksrivelse',
  'route-planner.route-plan': 'Ruteplan',
  'route-planner.route-plan-no-route-found':
    'Ingen rute funnet for gitte restriksjoner',
  'route-planner.route-plan-task-failed': 'Noe gikk galt med denne ruteplanen',
  'route-planner.route-plan-task-pending': 'Ruteplanen er under behandling',
  'route-planner.route-restrictions': 'Ruterestriksjoner',
  'route-planner.single-axle': 'Enkeltaksel',
  'route-planner.start-location': 'Startlokasjon',
  'route-planner.tandem-axle': 'Tandemaksel',
  'route-planner.tonne': 'Tonn',
  'route-planner.top-speed': 'Topphastighet',
  'route-planner.top-speed-in-km': 'Topphastighet i km/t',
  'route-planner.total-weight': 'Totalvekt',
  'route-planner.trailer': 'Tilhenger',
  'route-planner.trailer-weight-in-kilos': 'Tilhengervekt i kilo',
  'route-planner.triple-axle': 'Trippelaksel',
  'route-planner.weight-in-tonnes': 'Vekt i tonn',
  'select-user-settings.title': 'Brukertypeinnstillinger for nedtrekksmenyer',
  'selectCompany.select': 'Velg firma',
  'settings.absence-responsible': 'Fraværsansvarlig',
  'settings.add-absence-type': 'Legg til fraværstype',
  'settings.add-api-key': 'Legg til API-nøkkel',
  'settings.add-department': 'Legg til avdeling',
  'settings.add-holiday-allowance': 'Legg til helligdagstillegg',
  'settings.add-issue-category': 'Legg til avvikskategori',
  'settings.add-issue-risk-aspect': 'Legg til risikoaspekt',
  'settings.add-new-terminal': 'Legg til ny terminal',
  'settings.add-salary-code': 'Legg til lønnsart',
  'settings.add-time-entry-project': 'Legg til prosjekt',
  'settings.added-issue-category': 'La til ny avvikskategori',
  'settings.available-departments': 'Tilgjengelige avdelinger',
  'settings.change-logo': 'Last opp ny logo',
  'settings.change-module': 'Endre modul',
  'settings.change-responsible-user': 'Endre ansvarlig bruker',
  'settings.company-logo': 'Bedriftslogo',
  'settings.company-settings': 'Bedriftsinnstillinger',
  'settings.create-checklist-template': 'Opprett sjekklistemal',
  'settings.create-fleet-board-integration':
    'Opprett integrasjon mot FleetBoard',
  'settings.create-issue-category': 'Opprett avvikskategori',
  'settings.create-man-integration': 'Opprett MAN-integrasjon',
  'settings.create-name-first': 'Opprett navn først',
  'settings.create-salary-settings': 'Lag lønnsinnstillinger for {module}',
  'settings.create-scania-integration': 'Opprett Scania-integrasjon',
  'settings.create-terminal-area': 'Opprett terminalområde',
  'settings.create-user-document': 'Opprett brukerdokument',
  'settings.create-user-type': 'Legg til brukertype',
  'settings.create-volvo-integration': 'Opprett Volvo-integrasjon',
  'settings.daily-salary': 'Døgnbetaling',
  'settings.daily-work-limit': 'Daglig arbeidsgrense (timer)',
  'settings.daily-work-limit-short': 'Daglig',
  'settings.dashboard-links-preview':
    'Forhåndsvisning av linker på kontrollpanelet',
  'settings.day-salary-code': 'Døgnovertid',
  'settings.default-department': 'Standard avdeling',
  'settings.default-salary-code': 'Standard lønnsart',
  'settings.delete-overtime-period': 'Slett overtidsperiode',
  'settings.delete-period-addon': 'Slett periodetillegg',
  'settings.delete-user-type': 'Slett brukertype',
  'settings.do-you-want-to-delete-overtime-period':
    'Vil du slette overtidsperioden?',
  'settings.do-you-want-to-delete-period-addon':
    'Ønsker du å slette periodetillegget?',
  'settings.driverdata-print-for-all-drivers': 'Print all kjøredata',
  'settings.edit-absence-responsible': 'Endre fraværsansvarlig',
  'settings.edit-absence-type': 'Rediger fraværstype',
  'settings.edit-checklist-template': 'Endre sjekklistemal',
  'settings.edit-department': 'Oppdater avdeling',
  'settings.edit-dynamic-column': 'Rediger kolonnenavn',
  'settings.edit-fleet-board-integration-n': 'Endre FleetBoard integrasjon {n}',
  'settings.edit-holiday-allowance': 'Endre helligdagstillegg',
  'settings.edit-infringements-responsible': 'Endre bruddansvarlig',
  'settings.edit-issue-category': 'Endre avvikskategori',
  'settings.edit-issue-risk-aspect': 'Endre risikoaspekt',
  'settings.edit-man-integration-n': 'Endre MAN integrasjon {n}',
  'settings.edit-responsible-user': 'Endre ansvarlig bruker',
  'settings.edit-salary-and-work-time-for-type': 'Endre {type}innstillinger',
  'settings.edit-salary-code': 'Endre lønnsart',
  'settings.edit-salary-settings-type': 'Endre lønnsinnstillinger: {module}',
  'settings.edit-scania-integration-n': 'Endre Scania-integrasjon #{n}',
  'settings.edit-terminal-area': 'Rediger terminalområde',
  'settings.edit-time-entry-project': 'Endre prosjekt',
  'settings.edit-type-settings': 'Endre {type}innstillinger',
  'settings.edit-user-document': 'Endre brukerdokument',
  'settings.edit-volvo-integration-n': 'Endre Volvo-integrasjon #{n}',
  'settings.errors.change-language': 'Kunne ikke endre språk',
  'settings.errors.company-settings-generic':
    'Kunne ikke oppdatere bedriftinnstillinger',
  'settings.errors.create-absence-type': 'Kunne ikke opprette fraværstype',
  'settings.errors.create-checklist-template-error':
    'Feil ved opprettelse av sjekklistemal',
  'settings.errors.create-fleetboard-integration':
    'Feil ved opprettelse av FleetBoard-integrasjon',
  'settings.errors.create-issue-category': 'Kunne ikke opprette avvikskategori',
  'settings.errors.create-issue-risk-aspect':
    'Kunne ikke opprette risikoaspekt',
  'settings.errors.create-salary': 'Klarte ikke opprette lønnsinnstilling',
  'settings.errors.create-scania-integration':
    'Kunne ikke opprette Scania-integrasjon',
  'settings.errors.create-terminal': 'Kunne ikke opprette terminalområde',
  'settings.errors.create-time-entry-project': 'Kunne ikke opprette prosjektet',
  'settings.errors.create-user-document': 'Kunne ikke opprette brukerdokument',
  'settings.errors.create-user-document-category':
    'Kunne ikke opprette kategori',
  'settings.errors.create-user-type': 'Kunne ikke opprette brukertype',
  'settings.errors.create-volvo-integration':
    'Kunne ikke opprette Volvo-integrasjon',
  'settings.errors.delete-absence-type': 'Kunne ikke slette fraværstype',
  'settings.errors.delete-fleetboard-integration':
    'Feil ved sletting av FleetBoard-integrasjon',
  'settings.errors.delete-issue-category': 'Kunne ikke slette avvikskategori',
  'settings.errors.delete-issue-risk-aspect': 'Kunne ikke slette risikoaspekt',
  'settings.errors.delete-salary': 'Kunne ikke slette lønnsinnstilling',
  'settings.errors.delete-scania-integration':
    'Kunne ikke slette Scania-integrasjon',
  'settings.errors.delete-terminal': 'Kunne ikke slette terminalområde',
  'settings.errors.delete-time-entry-project': 'Kunne ikke slette prosjektet',
  'settings.errors.delete-user-document': 'Kunne ikke slette brukerdokument',
  'settings.errors.delete-user-document-category': 'Kunne ikke slette kategori',
  'settings.errors.delete-user-type': 'Kunne ikke slette brukertype',
  'settings.errors.delete-volvo-integration':
    'Kunne ikke slette Volvo-integrasjon',
  'settings.errors.password-change': 'Kunne ikke endre passordet',
  'settings.errors.patch-absence-type': 'Kunne ikke oppdatere fraværstype',
  'settings.errors.patch-checklist-template-error':
    'Det oppsto en feil ved oppdatering av sjekklistemal.',
  'settings.errors.patch-fleetboard-integration':
    'Feil ved endring av FleetBoard-integrasjon',
  'settings.errors.patch-issue-category': 'Kunne ikke endre avvikskategori',
  'settings.errors.patch-issue-risk-aspect':
    'Kunne ikke oppdatere risikoaspekt',
  'settings.errors.patch-salary': 'Kunne ikke oppdatere lønnsinnstillinger',
  'settings.errors.patch-scania-integration':
    'Kunne ikke oppdatere Scania-integrasjon',
  'settings.errors.patch-terminal': 'Kunne ikke oppdatere terminalområde',
  'settings.errors.patch-time-entry-project': 'Kunne ikke endre prosjektet',
  'settings.errors.patch-user-document': 'Kunne ikke oppdatere brukerdokument',
  'settings.errors.patch-user-document-category': 'Kunne ikke endre kategori',
  'settings.errors.patch-user-type': 'Kunne ikke oppdatere brukertype',
  'settings.errors.patch-volvo-integration':
    'Kunne ikke oppdatere Volvo-integrasjon',
  'settings.errors.update-dropdowns': 'Kunne ikke oppdatere nedtrekksmenyer',
  'settings.errors.update-notifications':
    'Kunne ikke oppdatere varslingsinnstillinger',
  'settings.errors.update-user-type': 'Kunne ikke oppdatere brukertype',
  'settings.errors.user-type-cannot-be-deleted':
    'Denne brukertypen kan ikke bli slettet',
  'settings.errors.user-type-cannot-be-edited':
    'Denne brukertypen kan ikke bli endret',
  'settings.errors.user-type-cannot-be-edited-with-name':
    'Brukertypen "{name}" kan ikke bli redigert',
  'settings.export-all-absences-for-period': 'Eksporter alle fravær i periode',
  'settings.export-all-driver-activities': 'Eksporter all kjøredata',
  'settings.export-all-issues-for-period': 'Eksporter alle avvik innen periode',
  'settings.export-all-terminal-entries': 'Eksporter alle terminalinnlegg',
  'settings.export-all-timesheets': 'Eksporter alle timelister',
  'settings.export-driverdata-for-user': 'Eksporter kjøredata for bruker',
  'settings.export-reports': 'Eksportere utskrifter',
  'settings.export-tables': 'Tabellinnstillinger for utskrifter',
  'settings.export-tables.preview': 'Forhåndsvisning av tabell for utskrifter',
  'settings.external-id-in-salary-system': 'Ekstern id i lønnssystem',
  'settings.fats.max-daily-work-limit': 'Maksimal daglig arbeidsgrense',
  'settings.fats.n-weeks': '{n} uker',
  'settings.fats.period-generated-for': 'Periode generert for',
  'settings.fats.period-length': 'Periodens lengde',
  'settings.fats.start-date-not-monday': 'Startdato må være en mandag',
  'settings.fats.start-date-too-late-to-get-valid-interval':
    'Startdato må være tidligere for å få et langt nok intervall',
  'settings.first-to-last-day-in-month': 'Første til siste dag i måneden',
  'settings.fixed-salary': 'Fastlønn',
  'settings.fleet-board-key-n': 'FleetBoard-nøkkel #{n}',
  'settings.fms-access-key': 'FMS tilgangsnøkkel',
  'settings.fms-secret-key': 'FMS hemmelig nøkkel',
  'settings.group-salary-on-vehicle': 'Bruk avdeling fra kjøretøy',
  'settings.has-notification': 'Har påminnelse',
  'settings.hourly-salary': 'Timelønn',
  'settings.include-absences': 'Inkluder fravær',
  'settings.info.absence-types-1':
    'På denne siden kan du legge til, endre og slette fraværstyper.',
  'settings.info.absence-types-2':
    'Husk at ved å endre en fraværstype vil du også endre alle tilknyttede fravær.',
  'settings.info.checklists':
    'På denne siden kan du legge til, redigere og slette sjekklistemaler',
  'settings.info.driver-data-1':
    'Her finner du alle innstillinger og funksjonar for sjåfører og kjøredata.',
  'settings.info.driver-data-2':
    'To datasett er tilgjengelig. Timer - printer like verdier som er tilgjengelig i kjøredata, for alle sjåfører, og Diett - som printer start og sluttid og lokasjon, men ikke den kalkulerte arbeidsperioden.',
  'settings.info.dropdowns':
    'Denne skjermen lar deg velge hvilke brukertyper som skal dukke opp i nedtrekksmenyen på visse sider. Alle brukere med minst én av brukertypene som er valgt vil komme opp i de nedtrekksmenyene.',
  'settings.info.exports-1': 'Her finner du alle eksportfunksjoner.',
  'settings.info.fats-1':
    'Her finner du verktøy for å ta ut FATS-rapport. Funksjonen er i en tidlig fase og støtter kun noen få FATS-paragrafer, men mer kommer fortløpende.',
  'settings.info.general':
    'På denne siden kan du konfigurere noen generelle innstillinger for bedriften samt laste opp bedriftslogo.',
  'settings.info.handbook-1':
    'Her kan du endre på innstillingene for sjåførhåndboken.',
  'settings.info.infringements-data-1':
    'Her kan du laste ned eller hente brudd til mail.',
  'settings.info.integrations':
    'Her kan du endre og legge til integrasjonsnøkler.',
  'settings.info.issue-categories-1':
    'På denne siden kan du legge til, endre og slette avvikskategorier brukt i avviksskjema.',
  'settings.info.issue-categories-2':
    'Ansvarspersonen er den med øverste ansvar for de avvik med sin kategori. Ansvarspersoner trenger en brukertype som er valgbar for denne siden og kan velges på siden "Nedtrekksmeny".',
  'settings.info.issue-categories-uneditable':
    'Kun ansvarlig bruker kan endres.',
  'settings.info.notifications':
    'Denne tabellen lar deg styre hvilke hendelser som skal varsles via bestemte kanaler. Husk å trykke på lagre når du har gjort valgene dine.',
  'settings.info.route-planner':
    'På denne siden kan du endre innstillingene for ruteplanleggeren',
  'settings.info.salary': 'Her kan du endre lønn- og arbeidsinnstillinger.',
  'settings.info.table':
    'På denne siden kan du endre utseende på tabeller i bedriften.',
  'settings.info.terminal-1':
    'På denne siden kan du legge til hvilke nettverk som skal anses som gyldige terminalnettverk og fjerne eventuelle nettverk.',
  'settings.info.terminal-2':
    'IP adressen er på ipv4-format, og IP-filterområdet er en submaske som når satt til 24 dekker hele nettverket på gitte IP-adresse. Det er som oftest ikke nødvendig å endre filterområdet, med mindre det er behov for veldig spesifikk granulitet på nettverksfilteret.',
  'settings.info.time-entries-1':
    'På denne siden kan du konfigurere valg og oppsett av timelistene deres. Noen valg kan endre litt på hvordan utskrifter vil se ut, eller hvordan total arbeidstid er utregnet.',
  'settings.info.user-documents-1':
    'På denne siden kan du endre og slette brukerdokumenter.',
  'settings.info.user-documents-2':
    'Dokumentansvarlig er brukeren som får varsel når et dokument går ut. Et dokument vil gå ut den første i måneden valg for utløpsdato. Varselet vil bli sendt dokumentansvarlig det antall uker som er valgt i varslingstid feltet.',
  'settings.info.user-types-1':
    'På denne siden kan du endre brukertyper og tilganger.',
  'settings.info.user-types-2':
    'Merk at alle endringer under "Endre" og "Slette" gir deg tilgang til å endre og slette ressurser opprettet av andre. Alle som har tilgang til å lage en ressurs har også tilgang til å endre sine egne ressurser.',
  'settings.info.vehicles':
    'Her kan du sette opp ansvarlige for kjøretøy. Varsling vil gå ut til disse brukerene som standard.',
  'settings.infringements-responsible': 'Bruddansvarlig',
  'settings.integration': 'Integrasjon',
  'settings.integrations': 'Integrasjoner',
  'settings.integrations-description':
    'Her kan du endre og legge til integrasjonsnøkler.',
  'settings.is-administrator': 'Er administrator',
  'settings.is-administrator-description':
    'Administrator-brukere vil ha alle tilganger, uavhengig av denne tabellen',
  'settings.last-day-of-month': 'Siste dag i måneden',
  'settings.man-key-n': 'MAN-nøkkel #{n}',
  'settings.module-options': 'Modulvalg',
  'settings.my-settings': 'Mine Innstillinger',
  'settings.n-departments': '{n} avdelinger',
  'settings.no-checklist-templates': 'Det finnes ingen sjekklistemaler.',
  'settings.no-integration-keys': 'Ingen integrasjonsnøkler funnet',
  'settings.no-selected-absence-responsible':
    'Ingen er valgt som fraværsansvarlig',
  'settings.no-selected-infringements-responsible':
    'Ingen bruddansvarlig bruker valgt',
  'settings.notifications.notify-driver-on-infringement':
    'Varsle sjåfør om nytt brudd',
  'settings.notifications.setting.absence-created': 'Fravær opprettet',
  'settings.notifications.setting.driver-card':
    'Forvarsel på utløpsdato for sjåførkort',
  'settings.notifications.setting.forum-post': 'Ny forumkommentar',
  'settings.notifications.setting.forum-thread': 'Ny forumtråd',
  'settings.notifications.setting.issue-closed': 'Avvik lukket',
  'settings.notifications.setting.issue-created': 'Avvik opprettet',
  'settings.notifications.setting.reset-password': 'Tilbakestille passord',
  'settings.notifications.setting.user-document':
    'Utgått dato på brukerdokument',
  'settings.notifications.setting.user-export-ready':
    'Varsel om ferdigstilt eksport',
  'settings.notifications.setting.user-registration': 'Brukerregistrering',
  'settings.notifications.vehicle-document': 'Utgått dato på kjøretøydokument',
  'settings.notifications.vehicle-expirations': 'Utgått dato på kjøretøy',
  'settings.ovetime-periods': 'Overtidsperioder',
  'settings.paid-pause-threshold':
    'Grense for betalt pause(alt under oppgitt verdi)',
  'settings.pause-duration': 'Varighet på pauser',
  'settings.pause-duration-short': 'Pause',
  'settings.pause-threshold': 'Arbeidsgrense for pauseaktivering',
  'settings.pause-threshold-short': 'Arbeid før pause',
  'settings.period': 'Periode',
  'settings.period-work-limit': 'Ukentlig arbeidsgrense (timer)',
  'settings.period-work-limit-short': 'Ukentlig',
  'settings.prompts.absence-type-create': 'Navn på ny fraværstype: ',
  'settings.prompts.absence-type-delete':
    'Er du sikker på at du vil slette fraværstypen "{name}"?',
  'settings.prompts.absence-type-delete-title': 'Slett fraværstype',
  'settings.prompts.create-user-type':
    'Hva skal navnet til den nye brukertypen være?',
  'settings.prompts.delete': 'Ønsker du å slette {name}?',
  'settings.prompts.delete-checklist-template': 'Slett sjekklistemal',
  'settings.prompts.delete-checklist-template-description':
    'Ønsker du å slette sjekklistemalen?',
  'settings.prompts.delete-time-entry-project':
    'Er du sikker på at du vil slette prosjektet "{name}"?',
  'settings.prompts.department-delete-title': 'Slett avdeling',
  'settings.prompts.department-setting-delete-title':
    'Ønsker du å slette avdeling?',
  'settings.prompts.holiday-allowance-delete-title': 'Slett helligdagstillegg',
  'settings.prompts.integration-delete-title': 'Slett integrasjon',
  'settings.prompts.integration-fleet-board-delete':
    'Slett FleetBoard-integrasjon {n}?',
  'settings.prompts.integration-man-delete':
    'Er du sikker på at du vil slette MAN-integrasjon {n}',
  'settings.prompts.integration-scania-delete':
    'Er du sikker på at du vil slette Scania-integrasjon #{n}?',
  'settings.prompts.integration-volvo-delete':
    'Er du sikker på at du vil slette Volvo-integrasjon #{n}?',
  'settings.prompts.issue-category-delete':
    'Er du sikker på at du vil slette avvikskategorien "{name}"?',
  'settings.prompts.issue-category-delete-title': 'Slett avvikskategori',
  'settings.prompts.issue-risk-aspect-delete':
    'Ønsker du å slette risikoaspektet {name}?',
  'settings.prompts.issue-risk-aspect-delete-title': 'Slett risikoaspekt',
  'settings.prompts.salary-code-delete-title': 'Slett lønnsart',
  'settings.prompts.salary-setting-delete-title':
    'Ønsker du å slette lønn- og arbeidstidsinnstilling?',
  'settings.prompts.terminal-delete':
    'Er du sikker på at du vil slette terminalområdet "{name}"?',
  'settings.prompts.terminal-delete-title': 'Slett terminalområde',
  'settings.prompts.time-entry-project-delete-title': 'Slett prosjekt',
  'settings.prompts.user-document-delete':
    'Er du sikker på at du vil slette brukerdokumentet "{name}"?',
  'settings.prompts.user-document-delete-title': 'Slett brukerdokument',
  'settings.prompts.user-type-delete':
    'Er du helt sikker på at du ønsker å slette denne brukertypen?',
  'settings.prompts.user-type-delete-title': 'Slett brukertype',
  'settings.reciept-functionality': 'Kvitteringsfunksjonalitet',
  'settings.refresh-colors': 'Nye farger',
  'settings.responsible-user': 'Ansvarlig bruker',
  'settings.salary-and-work-time': 'Lønn- og arbeidstid',
  'settings.salary-and-work-time-type': 'lønn- og arbeidstids',
  'settings.salary-code': 'Lønnsart',
  'settings.salary-code-day': 'Lønnsart døgn',
  'settings.salary-code-id-field-placeholder': 'Ekstern id i regnskapssystem',
  'settings.salary-day-other-salary-code-warning':
    'Om denne settes prøver systemet å bruke timegrunnlag på dager det ikke er satt opp døgn.',
  'settings.salary-end-date': 'Sluttdato lønnsperiode',
  'settings.salary-period': 'Lønningsperiode',
  'settings.salary-start-date': 'Startdato lønnsperiode',
  'settings.salary-type': 'Type lønn',
  'settings.save-changes': 'Lagre endringer',
  'settings.save-settings': 'Lagre innstillinger',
  'settings.scania-key': 'Scanianøkkel',
  'settings.scania-key-n': 'Scanianøkkel #{n}',
  'settings.select-api-key-to-add': 'Velg API-nøkkel som skal legges til',
  'settings.select-categories': 'Velg kategories',
  'settings.select-category': 'Velg kategori',
  'settings.select-crontab-setting': 'Velg genereringstype',
  'settings.select-module': 'Velg modul',
  'settings.show-column': 'Vis kolonne',
  'settings.show-dynamic-column-description':
    'Når en kolonne deaktiveres vil den skjules fra tabellen og utskrifter fra modulen.',
  'settings.show-dynamic-column-error': 'Minst to kolonner må være aktivert.',
  'settings.show-in-manual-time-entry-overtime':
    'Vis i manuell overstyring av overtid i timeregistrering',
  'settings.standard-settings': 'Standardinnstillinger',
  'settings.static-categories': 'Statiske kategorier',
  'settings.tables': 'Tabellinnstillinger',
  'settings.tables.add-column-description':
    'Når en kolonne legges til, vil den vises i tabeller fra modulen.',
  'settings.tables.add-export-column-description':
    'Når en kolonne legges til, vil den vises i utskrifter for modulen',
  'settings.tables.add-placeholder': 'Velg en kolonne å legge til',
  'settings.tables.preview': 'Forhåndsvisning av tabell',
  'settings.tachograph-key': 'Tachographnøkkel',
  'settings.terminal-description-1':
    'På denne siden kan du legge til hvilke nettverk som skal anses som gyldige terminalnettverk og fjerne eventuelle nettverk.',
  'settings.terminal-description-2':
    'IP adressen er på ipv4 form, og IP-filterområdet er en submaske som når satt til 24 dekker hele nettverket på gitte IP-adresse. Det er som oftest ikke nødvendig å endre filterområdet, med mindre det er behov for veldig spesifikk granulitet på nettverksfilteret.',
  'settings.time-entry-projects': 'Prosjekter',
  'settings.timesheets.alternative-overtime-option':
    'Alternativ valg av overtidsbestemmelser',
  'settings.timesheets.count-pause-as-part-of-total-work':
    'Medregn pause i total arbeidstid',
  'settings.timesheets.department-mandatory': 'Avdeling påkrevd',
  'settings.timesheets.merge-timesheets-with-activities':
    'Vis timeliste som annet arbeid i kjøredata',
  'settings.timesheets.periodic-overtime': 'Periodebasert overtid',
  'settings.timesheets.projects': 'Prosjekter',
  'settings.toast.create-man-integration-error':
    'Kunne ikke opprette MAN apinøkkel',
  'settings.toast.create-man-integration-success': 'MAN apinøkkel opprettet',
  'settings.toast.delete-man-integration-error':
    'Kunne ikke slette MAN apinøkkel',
  'settings.toast.delete-man-integration-success': 'MAN apinøkkel slettet',
  'settings.toast.patch-man-integration-error':
    'Kunne ikke endre MAN apinøkkel',
  'settings.toast.patch-man-integration-success': 'MAN apinøkkel endret',
  'settings.toasts.add-column-success': 'Kolonnen ble lagt til',
  'settings.toasts.change-language-success': 'Språk endret',
  'settings.toasts.company-settings-updated': 'Bedriftinnstillinger oppdatert',
  'settings.toasts.create-absence-type-success':
    'Fraværstype "{name}" opprettet',
  'settings.toasts.create-checklist-template-success':
    'Sjekklistemal ble lagt til.',
  'settings.toasts.create-fleetboard-integration-success':
    'FleetBoard-integrasjon opprettet',
  'settings.toasts.create-issue-category-success':
    'Avvikskategori "{name}" opprettet',
  'settings.toasts.create-issue-risk-aspect-success':
    'Risikoaspekt {name} opprettet',
  'settings.toasts.create-salary-success': 'Lønnsinnstilling ble opprettet',
  'settings.toasts.create-scania-integration-success':
    'Scania-integrasjon opprettet',
  'settings.toasts.create-terminal-success':
    'Terminalområde "{name}" ble opprettet',
  'settings.toasts.create-time-entry-project-success':
    'Prosjektet "{name}" ble opprettet',
  'settings.toasts.create-user-document-category-success': 'Kategori opprettet',
  'settings.toasts.create-user-document-success': 'Brukerdokument opprettet',
  'settings.toasts.create-user-type-success': 'Brukertype "{name}" opprettet',
  'settings.toasts.create-volvo-integration-success':
    'Volvo-integrasjon opprettet',
  'settings.toasts.delete-absence-type-success': 'Fraværstypen ble slettet',
  'settings.toasts.delete-fleetboard-integration-success':
    'FleetBoard-integrasjon ble slettet',
  'settings.toasts.delete-issue-category-success':
    'Avvikskategorien ble slettet',
  'settings.toasts.delete-issue-risk-aspect-success': 'Risikoaspekt slettet',
  'settings.toasts.delete-salary-success': 'Lønnsinnstilling ble slettet',
  'settings.toasts.delete-scania-integration-success':
    'Scania-integrasjon slettet',
  'settings.toasts.delete-terminal-success': 'Terminalområde slettet',
  'settings.toasts.delete-time-entry-project-success': 'Prosjektet ble slettet',
  'settings.toasts.delete-user-document-category-success': 'Kategori slettet',
  'settings.toasts.delete-user-document-success': 'Brukerdokument slettet',
  'settings.toasts.delete-user-type-success': 'Brukertypen ble slettet',
  'settings.toasts.delete-volvo-integration-success':
    'Volvo-integrasjon slettet',
  'settings.toasts.password-change-success': 'Passordet ditt ble endret',
  'settings.toasts.patch-absence-type-success': 'Fraværstypen ble endret',
  'settings.toasts.patch-checklist-template-success':
    'Sjekklistemalen ble oppdatert',
  'settings.toasts.patch-fleetboard-integration-success':
    'FleetBoard-integrasjon ble endret',
  'settings.toasts.patch-issue-category-success': 'Avvikskategorien ble endret',
  'settings.toasts.patch-issue-risk-aspect-success': 'Risikoaspekt oppdatert',
  'settings.toasts.patch-salary-success': 'Lønnsinnstillinger oppdatert',
  'settings.toasts.patch-scania-integration-success':
    'Scania-integrasjon oppdatert',
  'settings.toasts.patch-terminal-success': 'Terminalområde oppdatert',
  'settings.toasts.patch-time-entry-project-success': 'Prosjektet ble endret',
  'settings.toasts.patch-user-document-category-success': 'Kategori endret',
  'settings.toasts.patch-user-document-success': 'Brukerdokument oppdatert',
  'settings.toasts.patch-user-type-success': 'Brukertypen ble endret',
  'settings.toasts.patch-volvo-integration-success':
    'Volvo-integrasjon oppdatert',
  'settings.toasts.remove-column-success': 'Kolonnen ble fjernet',
  'settings.toasts.update-dropdowns-success': 'Nedtrekksmenyer oppdatert',
  'settings.toasts.update-notifications-success':
    'Varslingsinnstillinger oppdatert',
  'settings.toasts.update-user-type-success': 'Brukertypen ble endret',
  'settings.tv-view': 'Vis på TV',
  'settings.type-of-work': 'Lønnsart',
  'settings.updated-type': 'Oppdaterte {type}',
  'settings.upload': 'Last opp',
  'settings.upload-type': 'Last opp {type}',
  'settings.use-as-site-logo': 'Bruk som logo',
  'settings.use-period-addons': 'Bruk periodetillegg',
  'settings.use-period-overtime': 'Bruk periodebasert overtid',
  'settings.use-salary-day-payment': 'Bruk døgnsats',
  'settings.use-week-overtime': 'Bruk ukeovertid',
  'settings.user-documents-description-title': 'Brukerdokumentinnstillinger',
  'settings.user-type.create-user-type': 'Opprett brukertype',
  'settings.user-types-and-permissions': 'Brukertyper og tilganger',
  'settings.valid-salary-dates':
    'Startdag må være en dag senere i måneden enn sluttdag.',
  'settings.validation.absence-type-name-exists':
    'Denne fraværstypen finnes allerede',
  'settings.validation.already-holiday-allowance-on-day':
    'Det finnes allerede et helligdagstillegg for datoen',
  'settings.validation.company.logo-file-size':
    'Filstørrelsen til logoen kan ikke overskride 128 MiB',
  'settings.validation.company.must-have-name': 'Bedriften må ha et navn',
  'settings.validation.ip-address': 'Ugyldig format',
  'settings.validation.issue-category-name-exists':
    'Det finnes allerede en avvikskategori med dette navnet',
  'settings.validation.issue-risk-aspect-name-exists':
    'Et risikoaspekt med detta navnet finnes allerede',
  'settings.validation.last-admin-user-type-delete':
    'Dette er den eneste admin-brukertypen, og kan derfor ikke slettes.',
  'settings.validation.last-admin-user-type-toggle':
    'Dette er den eneste admin-brukertypen, og kan derfor ikke endres til en vanlig brukertype.',
  'settings.validation.salary-driver-pause-length': 'Minst 45 minutter',
  'settings.validation.salary-priority-unique':
    'Det finnes allerede en lønnsinnstilling med denne prioriteten for {module}',
  'settings.validation.terminal-range': 'Må være mellom 1 og 32',
  'settings.validation.time-entry-project-name-exists':
    'Det finnes allerede et prosjekt med dette navnet',
  'settings.validation.user-document-name-exists':
    'Kategori med navnet eksisterer allerede',
  'settings.validation.user-documents.must-have-name': 'Må ha navn',
  'settings.validation.user-documents.must-set-responsible-user':
    'Må sette bruker',
  'settings.validation.user-type-name-exists':
    'En brukertype med dette navnet eksisterer allerede',
  'settings.validation.user-type-name-exists-short': 'Navnet eksisterer',
  'settings.volvo-key': 'Volvonøkkel',
  'settings.volvo-key-n': 'Volvonøkkel #{n}',
  'settings.week-overtime-delayed-overtime-warning':
    'Med kalkulasjon av ukentlig overtid aktivert, legges overtid for uker som overlapper måneder til siste måned.',
  'settings.week-salary-code': 'Ukeovertid',
  'statistics.average-driver-grade': 'Gjennomsnittlig sjåførkarakter',
  'statistics.average-fuel-consumption': 'Gjennomsnittlig drivstofforbruk',
  'statistics.driver-grade': 'Sjåførkarakter',
  'statistics.kilometers-driven': 'Kilometer kjørt',
  'statistics.l-10-km': 'L/mil',
  'statistics.l-100-km': 'L/100 km',
  'statistics.monthly-company-statistics': 'Månedlig firmastatistikk',
  'statistics.monthly-driver-statistics': 'Din månedsstatistikk',
  'statistics.number-of-issues': 'Antall avvik',
  'table.week-sum': 'Ukesum',
  'terminal.check-in': 'Sjekk inn',
  'terminal.check-out': 'Sjekk ut',
  'terminal.checked-in': 'Sjekket inn',
  'terminal.checked-in-at': 'Sjekket inn kl. {time}',
  'terminal.checked-in-message':
    'Du sjekket inn {checkIn} og har arbeidet {total} timer',
  'terminal.checked-out': 'Sjekket ut',
  'terminal.checked-out-at': 'Sjekket ut kl. {time}',
  'terminal.edit-terminal-entry': 'Rediger terminalinnlegg',
  'terminal.error.delete-terminal-entry': 'Kunne ikke slette terminalinnlegg',
  'terminal.error.edit-terminal-entry': 'Kunne ikke endre terminalinnlegg',
  'terminal.ip-address': 'IP-adresse',
  'terminal.ip-filter-range': 'IP-filterområde',
  'terminal.monthly-print': 'Månedlig utskrift',
  'terminal.no-activity-period':
    'Du har ikke noe registrert aktivitet for valgt periode',
  'terminal.no-activity-today': 'Du har ikke registrert noen aktivitet i dag.',
  'terminal.not-on-network':
    'Du må være på et terminalnettverk for å sjekke ut eller inn',
  'terminal.old-checked-in-message': 'Du sjekket inn {checkIn}',
  'terminal.prompt.delete-terminal-entry':
    'Ønsker du å slette terminalinnlegget?',
  'terminal.prompt.delete-terminal-entry-title': 'Slette terminalinnlegg?',
  'terminal.salary-basis': 'Lønnsgrunnlag',
  'terminal.success.delete-terminal-entry': 'Slettet termialinnlegg',
  'terminal.success.edit-terminal-entry': 'Endret terminalinnlegg',
  'terminal.terminal-areas': 'Terminalområder',
  'terminal.terminal-entries': 'Terminalinnlegg',
  'terminal.terminal-entry': 'Terminalinnlegg',
  'terminal.terminal-name': 'Terminalnavn',
  'terminal.time': 'Tid',
  'terminal.user-activity': 'Aktiviteten til {user}',
  'terminal.user-no-activity-period':
    '{user} har ikke noe registrert aktivetet for valgt periode',
  'terminal.your-activity': 'Din aktivitet',
  'terminal.your-activity-today': 'Din aktivitet i dag',
  'thread.success-creation': 'Opprettet ny tråd',
  'timesheets.absence-approved': 'Godkjent',
  'timesheets.absence-create-type': 'Opprett ny fraværstype',
  'timesheets.absence-pending': 'Venter',
  'timesheets.absence-rejected': 'Avvist',
  'timesheets.absence-type': 'Fraværstype',
  'timesheets.absence-type-name': 'Fraværstypenavn',
  'timesheets.absence-types': 'Fraværstyper',
  'timesheets.absence-types-error-1':
    'Fravær kan ikke bli lagt til mer enn én gang per dag per ansatt.',
  'timesheets.delete-absence': 'Slett fravær',
  'timesheets.delete-time-entry': 'Slett oppføring',
  'timesheets.edit-absence': 'Endre fravær',
  'timesheets.edit-absence-status': 'Endre fraværsstatus',
  'timesheets.edit-time-entry': 'Endre oppføring',
  'timesheets.error-create-absence': 'Kunne ikke legge til fravær',
  'timesheets.error-create-timeentry':
    'Det eksisterer allerede et innlegg med samme dato og tider',
  'timesheets.error-registration-absence':
    'Feil ved registrering av nytt fravær',
  'timesheets.error-update-absence': 'Feil ved oppdatering av fravær',
  'timesheets.error-update-timeentry': 'Feil ved oppdatering av timer',
  'timesheets.error.delete-absence': 'Kunne ikke slette fravær',
  'timesheets.error.delete-time-entry': 'Kunne ikke slette timeoppføring',
  'timesheets.error.overtime-mode-required': 'Tillegg er påkrevd',
  'timesheets.error.patch-absence': 'Kunne ikke oppdatere fravær',
  'timesheets.error.patch-time-entry': 'Kunne ikke oppdatere timeoppføring',
  'timesheets.error.pause-required': 'Pause må være minst {n} minutter',
  'timesheets.error.start-before-end': 'Starttid må være før sluttid',
  'timesheets.export-timesheet': 'Skriv ut timeliste',
  'timesheets.new-absence-request': 'Ny fraværsforespørsel',
  'timesheets.new-time-entry': 'Ny timeføring',
  'timesheets.note': 'Kommentar på fravær',
  'timesheets.overtime-alt': 'Herav overtid',
  'timesheets.print-timesheet': 'Skriv ut timelist',
  'timesheets.prompt.delete-absence':
    'Er du sikker på at du ønsker å slette dette fraværet?',
  'timesheets.prompt.delete-absence-title': 'Slett fravær',
  'timesheets.prompt.delete-time-entry':
    'Er du sikker på at du vil slette denne timeoppføringen?',
  'timesheets.prompt.delete-time-entry-title': 'Slett timeoppføring',
  'timesheets.register-absence': 'Registrer fravær',
  'timesheets.register-timeentry': 'Registrer nye timer',
  'timesheets.select-absence-type': 'Velg fraværstype',
  'timesheets.send-request': 'Send forespørsel',
  'timesheets.success-registration-absence': 'Nytt fravær registrert',
  'timesheets.success-registration-timeentry': 'Nye timer registrert',
  'timesheets.success-update-absence': 'Fravær oppdatert',
  'timesheets.success-update-timeentry': 'Timer oppdatert',
  'timesheets.timeentry': 'Timer',
  'timesheets.toast.delete-absence-success': 'Fravær slettet',
  'timesheets.toast.delete-time-entry-success': 'Timeoppføring slettet',
  'timesheets.toast.patch-absence-success': 'Fravær oppdatert',
  'timesheets.toast.patch-time-entry-success': 'Timeoppføring oppdatert',
  'timesheets.update-absence': 'Oppdater fravær',
  'timesheets.update-timeentry': 'Oppdater timer',
  'timesheets.view-all-absence': 'Alt fravær',
  'timesheets.view-all-timeentries': 'Alles timer',
  'timesheets.view-your-absence': 'Ditt fravær',
  'timesheets.view-your-timeentries': 'Dine timer',
  'timesheets.warning.time-entry-exist-in-daterange':
    'En timesføring med overlappende tider eksisterer allerede',
  'toast.success-archive-type': 'Arkiverte {type}',
  'toast.success-un-archive-type': 'Gjenåpnet {type}',
  'toasts.email-sent-successfully': 'Mail ble sendt',
  'toasts.error-add-type': 'Kunne ikke legge til {type}',
  'toasts.error-delete-type': 'Kunne ikke slette {type}',
  'toasts.error-update-type': 'Kunne ikke oppdatere {type}',
  'toasts.error.create-type': 'Kunne ikke opprette {type}',
  'toasts.error.update-type': 'Kunne ikke edit {type}',
  'toasts.error.upload-type': 'Kunne ikke laste opp {type}',
  'toasts.success-add-type': 'La til ny {type}',
  'toasts.success-created-type': 'Opprettet ny {type}',
  'toasts.success-delete-type': 'Slettet {type}',
  'toasts.success-update-type': 'Oppdaterte {type}',
  'toasts.success.upload-type': 'Lastet opp {type}',
  'user-type-settings.delete-prompt-text':
    'Er du sikker på at du vil slette brukertypen?',
  'user-type-settings.delete-prompt-title': 'Slett brukertype',
  'user-type-settings.title': 'Brukertyper og tilganger',
  'user-type-settings.toasts.error': 'Feil ved oppdatering',
  'user-type-settings.toasts.success': 'Oppdaterte brukertyper',
  'user-types.change-settings-label': 'Endre',
  'user-types.create-settings-label': 'Opprette',
  'user-types.driver-activities-self': 'Kjøredata (egen)',
  'user-types.everyones-driver-activities': 'Kjøredata (alles)',
  'user-types.remove-settings-label': 'Slette',
  'user-types.view-settings-label': 'Se',
  'users.activate-user': 'Aktiver bruker',
  'users.active-driver-card': 'Aktivt sjåførkort',
  'users.add-driver-card': 'Legg til sjåførkort',
  'users.add-driver-card-number': 'Legg til sjåførkortnummer',
  'users.add-expiry-date': 'Legg til utløpsdato',
  'users.add-your-driver-number': 'Legg til ditt sjåførkort',
  'users.awaits-configuration': 'Avventer konfigurasjon',
  'users.bio': 'Profilbiografi',
  'users.birth-number': 'Fødselsnummer',
  'users.change-picture': 'Last opp bilde',
  'users.change-profile-picture': 'Last opp bilde',
  'users.contact-person-name': 'Kontaktperson navn',
  'users.contact-person-phone': 'Kontaktperson telefon',
  'users.create-password.description':
    'Opprett et passord for din nyopprettede bruker.',
  'users.create-password.title': 'Opprett nytt passord',
  'users.create-user': 'Opprett bruker',
  'users.deactivate-user': 'Deaktiver bruker',
  'users.delete-driver-card': 'Slett sjåførkort',
  'users.delete-driver-card-message': 'Ønsker du å slette sjåførkortet?',
  'users.delete-existing-number':
    'Ønsker du å slette nummeret hos eksisterende bruker?',
  'users.delete-expired-driver-card': 'Slett utløpt sjåførkort',
  'users.delete-expired-driver-card-message':
    'Ønsker du å slette det utløpte sjåførkortet?',
  'users.delete-user': 'Slett bruker',
  'users.driver-card': 'Sjåførkort',
  'users.driver-card-number': 'Sjåførkort',
  'users.edit-driver-card': 'Endre sjåførkort',
  'users.edit-profile': 'Rediger profil',
  'users.edit-profile-information': 'Rediger profilinformasjonen',
  'users.edit-user': 'Rediger bruker',
  'users.errors.driver-card-expiry-format': 'Format: MM.YY',
  'users.errors.driver-card-invalid-expiry': 'Utløpsdato er ugyldig',
  'users.errors.phone-number-taken':
    'Telefonnummeret er registrert til en annen bruker',
  'users.expired-cards': 'Utløpte sjåførkort',
  'users.expired-driver-card': 'Utløpt sjåførkort',
  'users.has-document': 'Har dokument:',
  'users.has-no-companies':
    'Du er ikke assosiert med noen firmaer. Vennligst ta kontakt med administatoren din.',
  'users.include-expired-documents': 'Inkluder utløpte dokumenter',
  'users.internal-number': 'Internnummer',
  'users.missing-external-id': 'Mangler ekstern id',
  'users.my-profile': 'Min profil',
  'users.n-has-one-or-more-expired-driver-cards':
    '{n} har et eller flere utløpte sjåførkort',
  'users.n-users': '{n} brukere',
  'users.new': 'Ny bruker',
  'users.new-registration-link-send': 'Ny invitasjon ble sendt',
  'users.no-more-user-types': 'Ingen flere brukertyper',
  'users.number-already-exists':
    'En bruker, {name}, har allerede dette nummeret',
  'users.personal-info': 'Personlig informasjon',
  'users.profile': 'Profil',
  'users.profile-did-change': 'Profilinformasjonen ble endret',
  'users.profile-of-user': 'Profil: {user}',
  'users.prompts.deactivate-self':
    'Er du sikker på at du ønsker å deaktivere din egen bruker?',
  'users.prompts.deactivate-self-title': 'Deaktiver egen bruker',
  'users.prompts.deactivate-user':
    'Er du sikker på at du ønsker å deaktivere denne brukeren?',
  'users.prompts.deactivate-user-title': 'Deaktiver bruker',
  'users.search': 'Søk etter bruker',
  'users.select-user-types': 'Velg brukertyper',
  'users.send-new-invitation': 'Send ny invitasjon',
  'users.settings': 'Mine innstillinger',
  'users.show-deactivated': 'Vis deaktiverte brukere',
  'users.stopped-in-control': 'Stoppet i kontroll?',
  'users.toasts.submit-driver-card-success': 'La til nytt sjåførkort',
  'users.toasts.user-activated': 'Brukeren {name} ble aktivert',
  'users.toasts.user-deactivated': 'Brukeren {name} ble deaktivert',
  'users.too-weak-password': 'For svakt passord',
  'users.user-is-deactivated': 'Denne brukeren er deaktivert',
  'vehicles.add-expiration-date': 'Legg til utløpsdato',
  'vehicles.add-vehicle': 'Legg til kjøretøy',
  'vehicles.administrative': 'Administrativt',
  'vehicles.back-lifter': 'Bakløfter',
  'vehicles.brand': 'Merke',
  'vehicles.cool-freeze-heat': 'Kjøl/frys/varme',
  'vehicles.crane': 'Kran',
  'vehicles.crane-type': 'Krantype',
  'vehicles.delete-vehicle': 'Slett utstyr',
  'vehicles.delete-vehicle-message':
    'Er du sikker på at du ønsker å slette dette kjøretøyet/materiellet?',
  'vehicles.delete-vehicle-title': 'Slette kjøretøy/materiell?',
  'vehicles.diesel-card-number': 'Dieselkortnr',
  'vehicles.edit-expiration-date': 'Endre utløpsdato',
  'vehicles.edit-vehicle': 'Endre kjøretøy',
  'vehicles.edit-vehicle-document': 'Endre kjøretøydokument',
  'vehicles.engine': 'Motor',
  'vehicles.euro-class': 'Euroklasse',
  'vehicles.existing-vehicle': 'Eksisterende kjøretøy',
  'vehicles.expiration-dates': 'Utløpsdatoer',
  'vehicles.extension-type': 'Type påbygg',
  'vehicles.ferry-card-number': 'Ferjekortnr',
  'vehicles.first-registered': 'Første gang registert',
  'vehicles.fleet': 'Flåte',
  'vehicles.fleet-management': 'Flåtestyring',
  'vehicles.fleet-overview': 'Utstyr og materiell',
  'vehicles.fridge': 'Kjølemaskin',
  'vehicles.fridge-type': 'Type kjølemaskin',
  'vehicles.fuel': 'Drivstoff',
  'vehicles.goods-insurance-type': 'Godsforsikringstype',
  'vehicles.height': 'Høyde',
  'vehicles.inner-height': 'Indre høyde',
  'vehicles.inner-length': 'Indre lengde',
  'vehicles.inner-width': 'Indre bredde',
  'vehicles.km-per-month': 'Bil km per måned',
  'vehicles.km-per-year': 'Bil km per år',
  'vehicles.license-number': 'Løyvenr',
  'vehicles.loading-height': 'Innlastingshøyde',
  'vehicles.max-back-axle-load': 'Maks aksellast bak',
  'vehicles.max-front-axle-load': 'Maks aksellast foran',
  'vehicles.maximum-coupling-load': 'Maks tilhengerkoplingslast',
  'vehicles.maximum-payload': 'Maks nyttelast',
  'vehicles.maximum-semi-trailer-weight': 'Maks semitilhengervekt',
  'vehicles.maximum-speed': 'Maksfart',
  'vehicles.maximum-truck-weight': 'Maks vogntogvekt',
  'vehicles.maxium-total-weight': 'Maks totalvekt',
  'vehicles.meat-rack': 'Kjøttheng',
  'vehicles.model': 'Modell',
  'vehicles.net-weight': 'Egenvekt',
  'vehicles.new-expiration-date': 'Ny utløpsdato',
  'vehicles.new-vehicle-document': 'Nytt kjøretøydokument',
  'vehicles.next-periodic-control': 'Neste EU-kontroll',
  'vehicles.no-expiration-dates': 'Ingen utløpsdatoer',
  'vehicles.no-file-selected': 'Ingen fil valgt',
  'vehicles.owned': 'Eid',
  'vehicles.pallet-spaces': 'Antall pallplasser',
  'vehicles.pin-code': 'PIN kode',
  'vehicles.power': 'Effekt',
  'vehicles.previous-periodic-control': 'Forrige EU-kontroll',
  'vehicles.prompts.delete-expiration-date': 'Slett utløpsdato',
  'vehicles.registered-country': 'Registrert land',
  'vehicles.service-agreement': 'Serviceavtale',
  'vehicles.service-and-maintenance': 'Service og vedlikehold',
  'vehicles.side-opening': 'Sideåpning',
  'vehicles.sold-date': 'Solgt dato',
  'vehicles.stationing-location': 'Stasjoneringssted',
  'vehicles.studded-drive-tire': 'Pigg indre drivhjul',
  'vehicles.studded-front-tire': 'Pigg forhjul',
  'vehicles.tires-dimension-bogie': 'Dekkdimensjon bogie',
  'vehicles.tires-dimension-drive': 'Dekkdimensjon drivhjul',
  'vehicles.tires-dimension-front': 'Dekkdimensjon forhjul',
  'vehicles.toasts.vehicle-updated': 'Kjøretøyet ble oppdatert',
  'vehicles.toll-tag-number': 'Bombrikkenr',
  'vehicles.unowned': 'Ikke eid',
  'vehicles.usage-properties': 'Bruksegenskaper',
  'vehicles.vbg-coupling': 'VBG kobling',
  'vehicles.vehicle-details': 'Kjøretøydetaljer',
  'vehicles.vehicle-technical-type': 'Teknisk kjøretøygruppe',
  'vehicles.vehicle-type': 'Kjøretøygruppe',
  'vehicles.view-vehicle-details': 'Vis kjøretøydetaljer',
  'vehicles.vin': 'VIN',
  'vehicles.volume': 'Volum',
  'vehicles.weights': 'Vekter',
}
export default nb
