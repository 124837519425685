import {
  Breadcrumbs,
  ComponentSwitch as Switch,
  Crumb,
} from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { useCompany } from 'util/hooks'
import { FirmadokLogoNoText } from 'components'
import { CompanyLogo } from '../sidebar/CompanyLogo'
import capitalize from 'lodash/capitalize'
import React from 'react'
import { isBrowser } from 'react-device-detect'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { ZIndexRange } from 'types/style'
import { overloadColor } from 'util/style'
import { HeaderMenu } from './components'
import {
  AppPath,
  useAppPathTranslations,
} from 'util/hooks/useAppPathTranslations'
import { useBreadcrumbs } from 'util/hooks/useBreadcrumbs/useBreadcrumbs'

const BrowserWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: ${props => props.theme.layout.headerHeight};
  padding: 32px 52px 0;
`
const MobileWrapper = styled(BrowserWrapper)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: ${ZIndexRange.Header};
  height: ${props => props.theme.layout.headerHeightMobile};
  padding: 1.2em;

  background-color: white;
  box-shadow: 0 1px 18px 0 #00000009;

  div.logo-wrapper {
    display: flex;
    align-items: center;

    span {
      margin-left: 0.8em;
      font-size: 1.1em;
      font-weight: 700;
      color: ${props => props.theme.colors.primary};
    }
  }
`
const Logo = styled.div`
  width: 40px;
  height: 40px;
  padding: 10px;

  border-radius: 27%;
  background-color: ${props => props.theme.colors.primary};
  cursor: pointer;

  box-shadow: 4px 4px 14px -2px ${props => props.theme.colors.primary}77;
`
const RouteBreadcrumbs = styled(Breadcrumbs.Route)`
  max-width: 96vw;
  overflow: hidden;
  color: ${props => props.theme.colors.gray4};
  font-weight: 600;

  a {
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }
  }

  ${props => props.theme.media.mobile} {
    display: flex;
    overflow-x: auto;
  }
`

export const Header: React.VFC = () => {
  const translations = useTranslate({
    home: 'common.home',
  })

  const history = useHistory()
  const company = useCompany()
  const { overrides, inserts } = useBreadcrumbs()

  const getAppPathTranslations = useAppPathTranslations()

  function overrideCrumb(
    path: string,
    index: number,
    total: number
  ): Partial<Crumb> | null {
    const text = overrides.hasOwnProperty(path)
      ? overrides[path]
      : getAppPathTranslations(path as AppPath) ?? capitalize(path)

    if (text === null) return null

    return {
      text,
      color: index === total - 1 ? overloadColor('primary') : undefined,
    }
  }

  return (
    <Switch>
      <Switch.Case case={isBrowser}>
        <BrowserWrapper>
          <RouteBreadcrumbs
            mainPageTitle={translations.home}
            overrideCrumb={overrideCrumb}
            inserts={inserts}
            iconProps={{
              size: '0.6em',
            }}
          />

          <HeaderMenu />
        </BrowserWrapper>
      </Switch.Case>

      <Switch.Case default>
        <MobileWrapper>
          {!!company.logo && company.useCompanyLogo ? (
            <CompanyLogo logo={company.logo!} />
          ) : (
            <div className="logo-wrapper">
              <Logo onClick={() => history.push('/')}>
                <FirmadokLogoNoText height="100%" />
              </Logo>
              <span>Firmadok</span>
            </div>
          )}
          <HeaderMenu />
        </MobileWrapper>

        <RouteBreadcrumbs
          className="breadcrumbs"
          mainPageTitle={translations.home}
          overrideCrumb={overrideCrumb}
          iconProps={{
            translateY: '-1px',
            size: '0.6em',
          }}
          iconMargins="8px"
        />
      </Switch.Case>
    </Switch>
  )
}
