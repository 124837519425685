import {
  eachDayOfInterval,
  lastDayOfMonth,
  isBefore,
  parseISO,
  isSameDay,
  isSameMonth,
  set,
} from 'date-fns'
import {
  AbsenceNode,
  AbsenceDate,
  WeekReport,
  TimeEntryNode,
} from './types.graphql'

/* AbsenceDate generation */

interface DatesWithInfo {
  info: string
  dates: Date[]
}

/**
 * Removes absences outside of the range given by month or rangeOverride
 * @param {AbsenceNode[]} nodes - Absence nodes to iterate over
 * @param {Date | null} monthStart - Start  of the selected range if the range is a month
 * @param {Date[] | undefined} rangeOverride - Overrides the month logic with a custom range
 * @returns {AbsenceDate} An array with objects containing date and absence reason.
 */

type ReducedAbsenceNode = Pick<
  AbsenceNode,
  'datetimeStart' | 'datetimeEnd' | 'absenceType' | 'reason'
>

export const generateAbsenceDates = (
  absenceNodes: ReducedAbsenceNode[],
  monthStart: Date | null,
  rangeOverride?: Date[]
) => {
  const cleanNodes = removeAbsencesOutsideRange(
    absenceNodes,
    monthStart,
    rangeOverride
  )
  const dateArrays: DatesWithInfo[] = cleanNodes.map(node => {
    const trimmedRange = rangeFromNode(node, monthStart, rangeOverride)
    return {
      info: node.absenceType.name + ': ' + node.reason,
      dates: eachDayOfInterval({
        start: trimmedRange[0],
        end: trimmedRange[1],
      }),
    }
  })
  const dates: AbsenceDate[][] = dateArrays.map(datesAndText => {
    return datesAndText.dates.map(date => {
      return { date: date, reason: datesAndText.info }
    })
  })
  const mergedDates = ([] as AbsenceDate[]).concat.apply([], dates)
  const uniqueDates = mergedDates.filter(
    (absenceDate, i, arr) =>
      arr
        .slice(0, i)
        .filter(mapAbsence => isSameDay(absenceDate.date, mapAbsence.date))
        .length === 0
  )
  return uniqueDates
}

/**
 * Removes absence nodes outside of of range
 * @param {AbsenceNode[]} nodes - Absence nodes to iterate over
 * @param {Date | null} monthStart - Start  of the selected range if the range is a month
 * @param {Date[] | undefined} rangeOverride - Overrides the month logic with a custom range
 * @returns {absenceNode[]} Array containing absence nodes inside range
 */

const removeAbsencesOutsideRange = (
  nodes: ReducedAbsenceNode[],
  monthStart: Date | null,
  rangeOverride?: Date[]
) => {
  if (typeof rangeOverride !== 'undefined') {
    return nodes.filter(
      node =>
        isBefore(rangeOverride[0], parseISO(node.datetimeEnd)) &&
        isBefore(parseISO(node.datetimeStart), rangeOverride[1])
    )
  } else if (monthStart) {
    const monthEnd = lastDayOfMonth(monthStart)
    set(monthEnd, { hours: 23, minutes: 59 })
    return nodes.filter(
      node =>
        !(
          isBefore(parseISO(node.datetimeEnd), monthStart) &&
          !isBefore(monthEnd, parseISO(node.datetimeStart))
        )
    )
  }
  return nodes
}

/**
 * Generates a date array for the given AbsenceNode that overlaps the selected range
 * @param {AbsenceNode} node - Absence node to generate date array
 * @param {Date | null} monthStart - Start  of the selected range if the range is a month
 * @param {Date[] | undefined} rangeOverride - Overrides the month logic with a custom range
 * @returns {Date[]} Date array containing the range endpoints
 */

const rangeFromNode = (
  node: ReducedAbsenceNode,
  monthStart: Date | null,
  rangeOverride?: Date[]
) => {
  const range = [parseISO(node.datetimeStart), parseISO(node.datetimeEnd)]
  if (rangeOverride) {
    const rangeStart = isBefore(range[0], rangeOverride[0])
      ? rangeOverride[0]
      : range[0]
    const rangeEnd = isBefore(rangeOverride[1], range[1])
      ? rangeOverride[1]
      : range[1]
    return [rangeStart, rangeEnd]
  } else if (monthStart) {
    const monthEnd = lastDayOfMonth(monthStart)
    set(monthEnd, { hours: 23, minutes: 59 })

    const rangeStart =
      isBefore(range[0], monthStart) || !isSameMonth(range[0], monthStart)
        ? monthStart
        : range[0]
    const rangeEnd =
      isBefore(monthEnd, range[1]) || !isSameMonth(range[1], monthEnd)
        ? monthEnd
        : range[1]
    return [rangeStart, rangeEnd]
  }
  return [new Date(), new Date()]
}

export function timeStringToMinutes(timeString: string) {
  if (!/^\d{2}:\d{2}$/.test(timeString)) return 0
  const hours = parseInt(timeString.substring(0, 2))
  const minutes = parseInt(timeString.substring(3))
  return hours * 60 + minutes
}

/**
 * This function takes a in an array of WeekReports and the month and returns all time- and absenceEntries within
 * @param weeks collection of weeks
 * @param month Date object
 * @returns an object with time- and absenceEntries in it as such:
 * `{
 *    timeEntries: timeEntries[],
 *    absenceEntries: absenceEntries[]
 *  }`
 */
export function getCurrentMonthEntriesFromWeekReports(
  weeks: WeekReport[],
  month: Date
) {
  let timeEntries: Omit<TimeEntryNode, 'company'>[] = []
  let absenceEntries: Omit<AbsenceNode, 'company'>[] = []
  for (const week of weeks) {
    for (const day of week.dayReports) {
      if (month.getMonth() === new Date(day.date).getMonth()) {
        timeEntries.push.apply(timeEntries, day.timeEntries)
        absenceEntries.push.apply(absenceEntries, day.absenceEntries)
      }
    }
  }
  return { timeEntries, absenceEntries }
}

interface MinuteInterval {
  start: number
  end: number
}
export function getMinuteIntervalOverlap(
  interval1: MinuteInterval,
  interval2: MinuteInterval
) {
  /**
   * Takes two minute intervals and calculate the overlap
   * @param interval1 The first interval
   * @param interval2 The second interval
   * @return The overlap in minutes
   */
  if (interval1.start >= interval2.end || interval2.start >= interval1.end)
    return 0
  if (interval1.start <= interval2.start && interval1.end >= interval2.end)
    return interval2.end - interval2.start
  if (interval2.start <= interval1.start && interval2.end >= interval1.end)
    return interval1.end - interval1.start
  if (interval1.start <= interval2.start && interval1.end <= interval2.end)
    return interval1.end - interval2.start
  if (interval2.start <= interval1.start && interval2.end <= interval1.end)
    return interval2.end - interval1.start
  return 0
}
