import { useTranslate } from '@ur/react-hooks'
import React from 'react'
import { isSafari } from 'react-device-detect'
import styled, { css } from 'styled-components'
import { TerminalSettingsViewProps } from './types'

// TODO change this shit as soon as Apple get's their ass off the ground
interface WrapperProps {
  isSafari: boolean
}
const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  ${props =>
    props.isSafari &&
    css`
      gap: 0;
      margin-left: -0.5rem;
      margin-bottom: -0.5rem;

      & > * {
        margin-bottom: 0.5rem;
        margin-left: 0.5rem;
      }
    `};

  ${props => props.theme.media.mobile} {
    flex-direction: column;
  }
`
const Terminal = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 220px;
  overflow: hidden;

  background-color: ${props => props.theme.colors.quaternary};
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};

  div.content {
    display: flex;
    flex-direction: column;
    padding: 1rem;

    span {
      &.name {
        font-weight: 600;
      }
      &.ip {
        font-size: 0.9rem;
      }
    }
  }
  div.buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;

    width: 100%;
    padding: 6px 0 4px;

    background-color: rgba(0, 0, 0, 0.05);
    font-size: 0.8rem;
    text-transform: uppercase;

    div[role='button'] {
      cursor: pointer;

      i {
        font-size: 1.2rem;
      }

      &.edit {
        border-top-right-radius: 6px;

        &:hover {
          color: ${props => props.theme.colors.quaternary};
        }
      }
      &.delete {
        border-bottom-right-radius: 6px;

        &:hover {
          color: ${props => props.theme.colors.red};
        }
      }
    }
  }

  ${props => props.theme.media.mobile} {
    min-width: 0;
    width: 100%;
  }
`
const AddButton = styled(Terminal)`
  padding: 1rem;

  text-align: center;
  font-weight: 600;
  font-size: 0.9rem;
  text-transform: uppercase;
  background-color: #eaeaee;
  cursor: pointer;
  user-select: none;
`

interface TerminalSettingsCardsProps extends TerminalSettingsViewProps {}

export const TerminalSettingsCards: React.VFC<TerminalSettingsCardsProps> = ({
  terminals,

  onAdd,
  onEdit,
  onDelete,
}) => {
  const translations = useTranslate({
    name: 'common.name',
    ipAddress: 'terminal.ip-address',

    add: 'common.add',
    edit: 'common.edit',
    delete: 'common.delete-alt',
  })

  return (
    <Wrapper isSafari={isSafari}>
      {terminals.map(terminal => (
        <Terminal key={terminal.id}>
          <div className="content">
            <span className="name">{terminal.name}</span>
            <span className="ip">
              {terminal.ip}/{terminal.ipFilterRange}
            </span>
          </div>

          <div className="buttons">
            <div
              role="button"
              className="edit"
              onClick={() => onEdit(terminal)}
            >
              {translations.edit}
            </div>
            <div
              role="button"
              className="delete"
              onClick={() => onDelete(terminal)}
            >
              {translations.delete}
            </div>
          </div>
        </Terminal>
      ))}

      <AddButton onClick={() => onAdd()}>{translations.add}</AddButton>
    </Wrapper>
  )
}
