import { useTranslate } from '@ur/react-hooks'
import { parseISO } from 'date-fns'
import React from 'react'
import styled from 'styled-components'
import { format } from 'util/date-fns'
import { unlessLoadingDots } from 'util/loading'

const Wrapper = styled.div`
  grid-area: details;

  display: grid;
  grid-template-columns: 1fr auto auto auto;
  grid-template-areas:
    'user user user legalBasis'
    'reason reason reason reasonValue'
    'archivedBasis archivedBasis archivedBasis archivedAt';

  background: ${props => props.theme.colors.quaternary};

  ${props => props.theme.media.mobile} {
    overflow-x: auto;

    grid-template-areas:
      'user user legalBasis'
      'reason reason reasonValue'
      'archivedBasis archivedBasis archivedAt';
  }
`
interface BoxProps {
  area: string
  borderTop?: boolean
  borderRight?: boolean
  borderBottom?: boolean
  borderLeft?: boolean
}
export const Box = styled.div<BoxProps>`
  grid-area: ${props => props.area};

  display: flex;
  flex-direction: column;
  padding: 1.5rem 2rem;

  border: 0px solid ${props => props.theme.colors.gray8};
  border-top-width: ${props => props.borderTop && '1px'};
  border-right-width: ${props => props.borderRight && '1px'};
  border-bottom-width: ${props => props.borderBottom && '1px'};
  border-left-width: ${props => props.borderLeft && '1px'};

  h4 {
    margin: 0 0 0.3rem 0;
    color: ${props => props.theme.colors.gray5};
    font-size: 0.9rem;
    font-weight: 600;
  }
  span {
    font-weight: 600;
    font-size: 1.2rem;
  }

  ${props => props.theme.media.mobile} {
    padding: 1rem;

    h4 {
      margin: 0 0 0.5rem 0;
      font-size: 0.7rem;
    }
    span {
      font-size: 0.9rem;
    }
  }
`

interface DetailsProps {
  loading: boolean

  reason: string | undefined
  reasonLowerLimit: string | null | undefined
  reasonValue: string | null | undefined
  reasonUpperLimit: string | null | undefined
  userFullname: string | undefined
  archivedAt: string | null | undefined
  archivedBasis: string | null | undefined
  legalBasis: string[] | undefined
}

export const Details: React.VFC<DetailsProps> = ({
  loading,

  reasonLowerLimit,
  reason,
  reasonUpperLimit,
  reasonValue,
  userFullname,
  archivedAt,
  archivedBasis,
  legalBasis,
}) => {
  const translations = useTranslate({
    unknown: 'common.unknown',

    driver: 'common.driver',
    basis: 'common.basis',
    value: 'common.value',
    article: 'common.article',

    archivedBasis: 'infringements.archived-basis',
    archivedDate: 'infringements.archived-date',

    reasonLowerLimit: 'infringements.lower-limit',
    reasonUpperLimit: 'infringements.upper-limit',
    legalBasis: 'infringements.legal-basis',
    nHours: ['common.n-hours', { n: '' }],
    nMinutes: ['common.n-minutes', { n: '' }],
  })
  const unlessLoading = unlessLoadingDots(loading, translations.unknown)

  const isArchived = !!archivedAt

  const archivedDate = !!archivedAt ? parseISO(archivedAt) : null
  const archivedDateText =
    archivedDate !== null ? format(archivedDate, 'HH:mm dd.MM.yyyy') : null

  const articleText =
    legalBasis?.length === 0
      ? '-'
      : translations.article + ': ' + legalBasis?.join(', ')

  return (
    <Wrapper>
      <Box area="user">
        <h4>{translations.driver}</h4>
        <span>{unlessLoading(userFullname || '-')}</span>
      </Box>
      <Box area="legalBasis">
        <h4>{translations.legalBasis}</h4>
        <span>{unlessLoading(articleText || '-')}</span>
      </Box>

      <Box area="reason" borderTop>
        <h4>{translations.basis}</h4>
        <span>{unlessLoading(reason || '-')}</span>
      </Box>

      {reasonValue && (
        <>
          <Box area="reasonValue" borderTop borderLeft>
            <h4>{translations.value}</h4>
            <span>{unlessLoading(reasonValue)}</span>
          </Box>
        </>
      )}

      {isArchived && (
        <>
          <Box area="archivedBasis" borderTop>
            <h4>{translations.archivedBasis}</h4>
            <span>{unlessLoading(archivedBasis)}</span>
          </Box>
          <Box area="archivedAt" borderTop borderLeft>
            <h4>{translations.archivedDate}</h4>
            <span>{unlessLoading(archivedDateText)}</span>
          </Box>
        </>
      )}
    </Wrapper>
  )
}
