import { KeyValue } from 'types/util'
import { DropdownDomain } from './types'

export function createDropdownDomains(
  translations: KeyValue<string>
): DropdownDomain[] {
  return [
    {
      title: translations.dropdownTitle,
      dropdowns: {
        [translations.activities]: 'activities',
        [translations.terminal]: 'terminal',
        [translations.timeEntries]: 'time_entries',
        [translations.absence]: 'absences',
        [translations.responsibleUser]: 'responsible_user',
        [translations.issueCategoryResponsible]: 'issue_category_responsible',
      },
    },
  ]
}
