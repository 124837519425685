import { Icon, usePrompt } from "@ur/react-components"
import { DepartmentForm, DepartmentPrompt } from "./DepartmentPrompt"
import { useDepartmentMutations } from "./hooks"
import { AllDepartmentsQuery, DepartmentNode } from "modules/companies/types.graphql"
import { useTranslate } from "@ur/react-hooks"
import { AddButton, Header, Section, SectionHeader } from "../common"
import { ALL_DEPARTMENTS_QUERY } from "modules/companies/queries"
import { useQuery } from "@apollo/client"
import { useConfirm } from "util/hooks"
import { Table } from "components"


export const DepartmentSettings:React.FC = () => {

  const translations = useTranslate({
    prompt: {
      delete: ['settings.prompts.delete', { name: '' }],
      deleteTitle: 'settings.prompts.department-delete-title',
    },
    externalId: 'common.id',
    departments: 'common.departments',
    name: 'common.name',
  })

  const addPrompt = usePrompt()
  const confirm = useConfirm()
  const mutations = useDepartmentMutations()

  const { data } = useQuery<AllDepartmentsQuery, {}>(
    ALL_DEPARTMENTS_QUERY
  )

  const departments = data?.allDepartments.edges?.map(edge => edge.node) ?? []

  async function handleCreateDepartment() {
    const { data } = await addPrompt<DepartmentForm | null>(resolve => (
      <DepartmentPrompt
        existingDepartments={departments}
        onSubmit={resolve}
      />
    ))
    if (data === null) return

    mutations.createDepartment({
      variables: {
        input: data,
      },
    })
  }

  async function handlePatchDepartment(department: DepartmentNode) {
    const { data } = await addPrompt<DepartmentForm | null>(resolve => (
      <DepartmentPrompt
        initialData={department}
        existingDepartments={departments}
        onSubmit={resolve}
      />
    ))
    if (data === null) return

    mutations.patchDepartment({
      variables: {
        id: department.id,
        input: {
          externalId: data.externalId,
          name: data.name,
        }
      },
    })
  }

  async function handleDeleteDepartment(
    department: DepartmentNode
  ) {
    const { data: answer } = await confirm(
      translations.prompt.delete({ name: department.name }),
      translations.prompt.deleteTitle
    )
    if (!answer) return

    mutations.deleteDepartment({
      variables: {
        id: department.id,
      },
    })
  }

  return (
    <Section>
      <Header>
        <SectionHeader>{translations.departments}</SectionHeader>
        <AddButton adding={false} onClick={handleCreateDepartment} />
      </Header>

      <Table
        noShadow
        noBorders
        noHeaderMargin
        noRowMargin
        rowHoverColor="secondary"
      >
        <thead>
          <tr>
            <th>{translations.name}</th>
            <th colSpan={2}>{translations.externalId}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {departments.map(department => (
            <tr key={department.id}>
              <td>{department.name}</td>
              <td>{department.externalId ?? '-'}</td>

              <td width="1px">
                <Icon
                  icon="edit"
                  cursor="pointer"
                  color="gray6"
                  hoverColor="secondary"
                  onClick={() => handlePatchDepartment(department)}
                />
              </td>

              <td width="1px">
                <Icon
                  icon="times"
                  cursor="pointer"
                  color="gray6"
                  hoverColor="red"
                  onClick={() => handleDeleteDepartment(department)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      
    </Section>
  )
}