import { BaseProps } from '@ur/react-components/build/types/props'
import { TableContentLoader, TableContentLoaderProps } from 'components/Loader'
import React, { useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
import { overloadColor } from 'util/style'

interface WrapperProps {
  noRows: boolean
  noShadow: boolean
  noBorders: boolean
  noHeaderMargin: boolean
  noRowMargin: boolean
  rowHoverColor?: string
  rowHoverBackground?: string
  headerMargin: boolean
}
const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  overflow-x: auto;

  background: white;
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  box-shadow: ${props =>
    props.noShadow ? 'none' : props.theme.shadow.default};

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    th,
    td {
      &:not(.wrap) {
        white-space: nowrap;
      }

      svg {
        width: 32px;
      }

      &.center {
        text-align: center !important;
      }
    }
    thead {
      &::before {
        content: '';
        display: table-row;
        height: 1rem;
      }
      ${props =>
        props.headerMargin &&
        css`
          &::after {
            content: '';
            display: table-row;
            height: 1rem;
          }
        `}
      ${props =>
        props.noRows &&
        css`
          &::after {
            content: '';
            display: table-row;
            height: 1rem;
          }
        `}

      tr {
        th {
          padding: 1.2rem 1.2rem 1rem;
          text-align: left;
          text-transform: uppercase;
          background-color: ${props => props.theme.colors.quaternary};

          &:first-of-type {
            transform: ${props => !props.noHeaderMargin && 'translateX(1rem)'};

            border-top-left-radius: ${props =>
              props.theme.sizes.defaultBorderRadius};
            border-bottom-left-radius: ${props =>
              props.theme.sizes.defaultBorderRadius};
          }
          &:last-of-type {
            transform: ${props => !props.noHeaderMargin && 'translateX(-1rem)'};

            border-top-right-radius: ${props =>
              props.theme.sizes.defaultBorderRadius};
            border-bottom-right-radius: ${props =>
              props.theme.sizes.defaultBorderRadius};
          }
        }
      }
    }
    tbody {
      border: 0;

      > :not(:first-of-type) {
        border-top: ${props =>
          !props.noBorders && `1px solid ${props.theme.colors.gray8}`};
      }

      tr {
        &:hover {
          color: ${props => overloadColor(props.rowHoverColor)};
          background: ${props => overloadColor(props.rowHoverBackground)};
        }

        td {
          padding: 12px 1rem;
          font-weight: 500;

          a {
            color: ${props => props.theme.colors.text.dark};

            &[href^='mailto'] {
              color: ${props => props.theme.colors.secondary};
            }
          }

          &:first-of-type {
            transform: ${props => !props.noRowMargin && 'translateX(1rem)'};
          }
          &:last-of-type {
            transform: ${props => !props.noRowMargin && 'translateX(-1rem)'};
          }
        }
      }

      > span {
        :not(:first-child) {
          border-top: ${props =>
            !props.noBorders && `1px solid ${props.theme.colors.gray8}`};
        }
      }
    }
  }

  tbody.custom--no-data {
    text-align: center;
  }

  ${props => props.theme.media.mobile} {
    td:first-of-type {
      transform: none !important;
    }
    td:last-of-type {
      transform: none !important;
    }
  }
`
interface NoDataProps {
  color?: string
}
const NoData = styled.td.attrs({
  colSpan: 99999,
})<NoDataProps>`
  color: ${props => props.color};
`

interface TableProps extends BaseProps {
  loading?: boolean
  loaderProps?: TableContentLoaderProps

  noData?: boolean
  noDataText?: string
  noDataColor?: string

  noShadow?: boolean
  noBorders?: boolean
  noHeaderMargin?: boolean
  noRowMargin?: boolean

  rowHoverColor?: string
  rowHoverBackground?: string

  /** Applies a margin below thead */
  headerMargin?: boolean
}

export const Table: React.FC<TableProps> = ({
  children,
  className,
  id,

  loading = false,
  loaderProps = {},

  noData = false,
  noDataText = 'No data',
  noDataColor,

  noShadow = false,
  noBorders = false,
  noHeaderMargin = false,
  noRowMargin = false,

  rowHoverColor,
  rowHoverBackground,

  headerMargin = false,
}) => {
  const [noRows, setNoRows] = useState(false)

  const updateNoRows = useCallback((node: HTMLTableElement | null) => {
    if (!node) return
    setNoRows(!node.querySelector('tbody tr'))
  }, [])

  return (
    <Wrapper
      className={className}
      id={id}
      noRows={noRows}
      noShadow={noShadow}
      noBorders={noBorders}
      noHeaderMargin={noHeaderMargin}
      noRowMargin={noRowMargin}
      rowHoverColor={rowHoverColor}
      rowHoverBackground={rowHoverBackground}
      headerMargin={headerMargin}
    >
      <table ref={updateNoRows}>
        {children}
        {noData && !loading && (
          <tbody className="custom--no-data">
            <tr>
              <NoData color={noDataColor}>{noDataText}</NoData>
            </tr>
          </tbody>
        )}
      </table>
      {loading && (
        <table>
          <tbody>
            <TableContentLoader {...loaderProps} />
          </tbody>
        </table>
      )}
    </Wrapper>
  )
}
