import { Icon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { SortableHeader, Table as BaseTable } from 'components'
import { CenteredErrorMessage } from 'components/CenteredErrorMessage'
import React, { useCallback } from 'react'
import { useQuery } from '@apollo/client'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { format, formatDistanceToNow } from 'util/date-fns'
import { cleanRegexOperators } from 'util/forms'
import { useAdmin, useInfiniteScroll, useOnErrorAuto } from 'util/hooks'
import { FORUM_THREADS_QUERY } from '../../queries'
import {
  ForumThreadsQuery,
  ForumThreadsQueryVariables,
} from '../../types.graphql'
import { SeenBar } from './SeenBar'
import ThreadsCards from './ThreadsCards'

const TD_HEIGHT = '72px'

const Table = styled(BaseTable)`
  overflow-x: auto;

  td {
    height: ${TD_HEIGHT} !important;
    font-weight: 500;

    div.post-count {
      display: inline-flex;
      justify-content: center;
      min-width: 42px;
      padding: 8px;
      border-radius: 6px;
      background-color: ${props => props.theme.colors.quaternary};
    }
    &.author {
      display: flex;
      flex-direction: column;
      justify-content: center;

      span {
        font-size: 0.8em;
        color: ${props => props.theme.colors.gray5};
      }
    }
  }
`
interface TitleProps {
  $sticky: boolean
  $seen: boolean
}
const Title = styled(Link)<TitleProps>`
  position: relative;
  padding-left: 6px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${props => (props.$seen ? 500 : 800)};

  ${props =>
    props.$sticky &&
    css`
      &::before {
        content: '';
        box-sizing: content-box;
        position: absolute;
        top: 50%;
        left: -12px;
        width: 6px;
        height: calc(${TD_HEIGHT} - 12px);
        transform: translateY(-50%);

        background-color: ${props => props.theme.colors.orange};
        border-radius: 999px;
      }
    `};
`

const FORUM_PAGE_SIZE = 50

interface ThreadsTableProps {
  query?: string
  sort?: string

  onSort?: (value: string) => void
}

export const ThreadsTable: React.VFC<ThreadsTableProps> = ({
  query = '',
  sort = '-createdAt',

  onSort = () => void 0,
}) => {
  const translations = useTranslate({
    title: 'common.title',
    author: 'common.author',
    created: 'forum.created',

    countOfTotal: ['common.count-of-total', { count: <em />, total: 0 }],

    errorThreads: 'forum.threads-error-message',
  })

  const admin = useAdmin()
  const onErrorAuto = useOnErrorAuto()

  const { data, loading, error, fetchMore } = useQuery<
    ForumThreadsQuery,
    ForumThreadsQueryVariables
  >(FORUM_THREADS_QUERY, {
    fetchPolicy: 'cache-first',
    variables: {
      first: FORUM_PAGE_SIZE,
      q: cleanRegexOperators(query),
      orderBy: sort,
      withAdminFields: admin,
    },
    onError: onErrorAuto(translations.errorThreads),
  })

  const pageInfo = data?.allThreads.pageInfo
  const hasNextPage = pageInfo?.hasNextPage ?? false

  const handleScrollBottom = useCallback(async () => {
    if (typeof data === 'undefined' || typeof pageInfo === 'undefined') return
    if (!hasNextPage || !pageInfo.endCursor) return

    const cursor = pageInfo.endCursor
    try {
      await fetchMore({
        variables: {
          first: FORUM_PAGE_SIZE,
          q: cleanRegexOperators(query),
          after: cursor,
          withAdminFields: admin,
        },
      })
    } catch (e) {
      //@ts-ignore
      if (e.name === 'Invariant Violation') return
      throw e
    }
  }, [fetchMore, query, admin, pageInfo, data, hasNextPage])

  useInfiniteScroll(handleScrollBottom, 200, !loading && hasNextPage)

  const stickies = data?.stickyThreads.edges.map(edge => edge.node) ?? []
  const threads = stickies.concat(
    data?.allThreads.edges.map(edge => edge.node) ?? []
  )

  if (error) return <CenteredErrorMessage />

  if (isMobile) return <ThreadsCards threads={threads} loading={loading} />

  return (
    <Table
      loading={loading}
      loaderProps={{
        columns: admin ? 5 : 4,
      }}
    >
      <thead>
        <tr>
          <SortableHeader baseValue="title" sortValue={sort} onSort={onSort}>
            {translations.title}
          </SortableHeader>

          <th>{translations.author}</th>

          <SortableHeader
            baseValue="createdAt"
            sortValue={sort}
            invertDirection
            onSort={onSort}
          >
            {translations.created}
          </SortableHeader>

          <th className="center">
            <Icon icon="comment" size="1.2em" />
          </th>

          {admin && (
            <th className="center">
              <Icon icon="eye" size="1.2em" />
            </th>
          )}
        </tr>
      </thead>

      <tbody>
        {threads.map(thread => {
          const createdAt = new Date(thread.createdAt)
          const formattedCreatedAt = format(createdAt, 'PPPP HH:mm')
          const timeAgo = formatDistanceToNow(createdAt, {
            addSuffix: true,
          })

          return (
            <tr key={thread.id}>
              <td>
                <Title
                  to={`/forum/${thread.id}`}
                  $sticky={thread.sticky}
                  $seen={thread.seen}
                >
                  {thread.title}
                </Title>
              </td>

              <td className="author">
                {thread.user.fullName}
                <span>{thread.user.email}</span>
              </td>

              <td>
                <span title={formattedCreatedAt}>{timeAgo}</span>
              </td>

              <td width="1px">
                <div className="post-count">{thread.postCount}</div>
              </td>

              {admin && (
                <td width="1px">
                  <SeenBar views={thread.numberOfViews ?? 0} />
                </td>
              )}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}
