import React from 'react'
import styled from 'styled-components'
import ContentLoader from 'react-content-loader'

const Layout = styled.div`
  width: 100%;
  height: 95px;
`

const AppNotificationLoadingItem: React.FC = () => {
  return (
    <Layout>
      <ContentLoader style={{ width: '100%' }}>
        <circle cx="30" cy="30" r="30" />
        <rect x="80" y="10" rx="3" ry="3" width="175" height="6" />
        <rect x="80" y="30" rx="3" ry="3" width="45" height="6" />
        <rect x="80" y="70" rx="3" ry="3" width="45" height="6" />
      </ContentLoader>
    </Layout>
  )
}

export default AppNotificationLoadingItem
