import { useQuery } from "@apollo/client"
import { AllFormTemplatesShallowQuery, AllFormTemplatesShallowQueryVariables } from "modules/formcreator"
import { ALL_FORM_TEMPLATES_SHALLOW_QUERY } from "modules/formcreator/queries"
import { SidebarNavItem } from "../SidebarNavItem"
import { PERMISSIONS } from "util/permissions"
import styled from "styled-components"
import { useTranslate } from "@ur/react-hooks"
import { usePermissions } from "util/hooks"


const Separator = styled.div`
  width: 100%;
  margin-top: 1em;
  padding: 2em 16px 1em;

  font-size: 0.8em;
  font-weight: 600;
  color: ${props => props.theme.colors.gray3};

  border-top: 1px solid ${props => props.theme.colors.gray8};
`


export const FormShortcuts: React.VFC = () => {
    const translations = useTranslate({
        forms: 'common.forms',
    })

    const { hasPermissions } = usePermissions()

    const canViewForms = hasPermissions(PERMISSIONS.formcreator.view.formtemplate)

    const { data } = useQuery<
        AllFormTemplatesShallowQuery,
        AllFormTemplatesShallowQueryVariables
    >(ALL_FORM_TEMPLATES_SHALLOW_QUERY, {
        variables: {
        excludeArchived: true,
        },
    })

    const forms = data?.allFormTemplates.edges.map(edge => edge.node) ?? []
    const shortcutForms = forms.filter(form => form.menuShortcut)
    
    return (
        
        <>
        {canViewForms && (
            <>
            {shortcutForms.length > 0 && (<Separator>{translations.forms}</Separator>)}
            {shortcutForms.map(node => (
                <SidebarNavItem
                    key={node.id}
                    to={`/formcreator/${node.id}`}
                    icon="form-creator"
                    text={node.name}
                    permissionsRequired={PERMISSIONS.formcreator.view.formtemplate}
                />
            ))}
            </>
        )}
        </>
    )
}