import { Icon } from '@ur/react-components'
import { RangeNode } from 'modules/activities'
import React, { useRef } from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled, { css, useTheme } from 'styled-components'
import { usePushHistory } from 'util/hooks'
import { overloadColor } from 'util/style'

const NODE_SIZE = 52
const MOBILE_NODE_SIZE = 38

interface WrapperProps {
  width: number
  left: number
  background: string
  isIssueNode: boolean
}
const Wrapper = styled.div.attrs<WrapperProps>(props => ({
  style: {
    left: props.left,
    width: props.width,
  },
}))<WrapperProps>`
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  height: ${NODE_SIZE}px;

  border-radius: 999px;
  background: ${props => overloadColor(props.background)};

  ${props =>
    props.isIssueNode &&
    css`
      background: rgba(255, 255, 255, 0);
    `};

  ${props => props.theme.media.mobile} {
    height: ${MOBILE_NODE_SIZE}px;
  }
`
interface NodeProps {
  left: number
}
const Node = styled.div.attrs<NodeProps>(props => ({
  style: {
    left: props.left,
  },
}))<NodeProps>`
  position: absolute;
  top: 2px;
  width: ${NODE_SIZE - 4}px;
  height: ${NODE_SIZE - 4}px;
  padding-top: 2px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 0.6rem;
  font-weight: 600;
  border-radius: 999px;
  background: white;

  ${props => props.theme.media.mobile} {
    width: ${MOBILE_NODE_SIZE - 4}px;
    height: ${MOBILE_NODE_SIZE - 4}px;
  }
`
interface SingleNodeProps extends NodeProps {
  width: number
}
const SingleNode = styled(Node).attrs<SingleNodeProps>(props => ({
  style: {
    left: props.left,
    width: props.width - 4,
  },
}))<SingleNodeProps>`
  flex-direction: column;
`
const IssueNode = styled.div`
  position: absolute;
  height: calc(100% + 2rem);
  top: calc(2px - 1rem);
  left: 2px;
  padding-top: 2px;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    & > aside {
      display: block;
    }
    i {
      text-shadow: 0 0 3px currentColor;
    }
  }
`
interface IssueNodeTooltipProps {
  background: string
}
const IssueNodeTooltip = styled.aside<IssueNodeTooltipProps>`
  display: none;
  position: absolute;
  z-index: 1;
  top: calc(100% + 8px - 1.5rem);
  left: 50%;
  transform: translateX(-50%);

  padding: 1rem;

  border-radius: 8px;
  background: ${props => overloadColor(props.background)};
  box-shadow: 0 0 3px 0 ${props => overloadColor(props.background)};
  font-weight: 600;
  color: white;
`
interface RangeTextProps {
  left: number
  width: number
  vehicle: boolean
}
const RangeText = styled.span.attrs<RangeTextProps>(props => ({
  style: {
    left: props.left,
    width: props.width,
  },
  vehicle: props.vehicle,
}))<RangeTextProps>`
  position: absolute;
  top: 50%;
  transform: translate(50%, -50%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: white;
  font-size: ${props => props.vehicle ? '1rem' : '0.75rem'};
  font-weight: 700;

  span {
    color: ${props => props.theme.colors.gray10};
    font-size: ${props => props.vehicle ? '0.6em' : '0.8em'};
    font-weight: 600;
  }
`

interface ScheduleRangeProps {
  nodes: RangeNode[]
  /** First hour in calendar */
  startTime: number
  /** Last hour in calendar */
  endTime: number
  /** Pixel value, push rightward */
  leftMargin: number
  /** Width of entire calendar */
  calendarWidth: number
  /** Offset from daylight savings occuring on the day */
  daylightOffset: number
}

export const ScheduleRange: React.VFC<ScheduleRangeProps> = ({
  nodes,
  startTime,
  endTime,
  leftMargin,
  calendarWidth,
  daylightOffset,
}) => {
  if (endTime <= startTime)
    throw new Error('ScheduleRange: startTime must be less than endTime')
  const theme = useTheme()
  const nodesLeft = useRef(nodes.map(_ => 0))
  const pushHistory = usePushHistory()

  if (!nodes.length) return null

  const sortedNodes = nodes.sort((a, b) => a.time - b.time)
  const nodeSize = isMobileOnly ? MOBILE_NODE_SIZE : NODE_SIZE

  const totalHours = endTime - startTime - daylightOffset
  const hourWidth = (calendarWidth - leftMargin) / totalHours

  const [firstNode, secondNode] = sortedNodes
  const firstTime = firstNode.time
  const lastTime = sortedNodes[sortedNodes.length - 1].time
  const numHours = lastTime - firstTime

  const left =
    (firstTime - startTime) * hourWidth +
    leftMargin -
    nodeSize / 2 -
    (firstTime === 0 ? 3 * hourWidth : 0)
  const width =
    hourWidth * numHours + nodeSize + (firstTime === 0 ? 2 * hourWidth : 0)

  const background = firstNode.background ?? theme.colors.gray6
  const isIssueNode = firstNode.type === 'issue'
  const isSingleNode = width < nodeSize * 2 + 12

  function handleClick(evt: React.MouseEvent<HTMLDivElement>) {
    if (!firstNode.href) return
    pushHistory(firstNode.href, evt)
  }

  return (
    <Wrapper
      width={width}
      left={left}
      background={background}
      isIssueNode={isIssueNode}
    >
      {isIssueNode ? (
        <IssueNode onClick={handleClick}>
          <Icon
            icon="exclamation-triangle"
            type="solid"
            color={background}
            size={`${nodeSize - 12}px`}
            cursor="pointer"
          />
          <IssueNodeTooltip background={background}>
            {firstNode.text}
          </IssueNodeTooltip>
        </IssueNode>
      ) : isSingleNode ? (
        <SingleNode left={2} width={width}>
          <span>{firstNode.text}</span>
          {!!secondNode && <span>{secondNode.text}</span>}
        </SingleNode>
      ) : (
        sortedNodes.map((node, i) => {
          const nodeLeft =
            (node.time - firstTime) * hourWidth +
            2 +
            (i > 0 && firstTime === 0 ? 2 * hourWidth : 0)
          const prevNode = i > 0 ? sortedNodes[i - 1] : null
          nodesLeft.current[i] = nodeLeft

          return (
            <React.Fragment key={`${background}-${node.time}`}>
              {prevNode && (prevNode.rangeText || prevNode.rangeSubText) && (
                <RangeText width={width} left={-width / 2} vehicle={prevNode.vehicleRange ?? false}>
                  {prevNode.rangeText}
                  {prevNode.rangeSubText && (
                    <span>{prevNode.rangeSubText}</span>
                  )}
                </RangeText>
              )}
              <Node left={nodeLeft}>{node.text}</Node>
            </React.Fragment>
          )
        })
      )}
    </Wrapper>
  )
}
