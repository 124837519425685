import traph from 'react-traph'

export const USER_SETTING_LANGUAGE_DEFAULT = 'nb'

export interface Settings {
  language: string
}

const SettingsGraph = traph({
  language: USER_SETTING_LANGUAGE_DEFAULT,
})

export default SettingsGraph
