import { Message } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { RoutePlanTaskValue } from 'modules/routePlanner/consts'
import { RoutePlanNode } from 'modules/routePlanner/types.graphql'
import { useTheme } from 'styled-components'

interface RoutePlanMessageProps {
  routePlan: Pick<RoutePlanNode, 'taskStatus' | 'result'>
}

export const RoutePlanMessage: React.VFC<RoutePlanMessageProps> = ({
  routePlan,
}) => {
  /**
   * This component is a banner feedback message for the user. This edge case can happen if they
   * access the detail view of a route plan that is not yet complete. Or if they have triggered a
   * cache invalidation and the task is still running.
   *
   * Expansions:
   *  - If the message is rendered and the task is pending this component can continuously poll
   *  the server for the task status
   *  - If the status has changed to SUCCESS we can refetch the points?
   *  - Apollo has made it a bit harder to use lazyqueries. Could maybe have a <RoutePoller />
   *  which is conditionally mounted depending on the task status.
   */
  const { taskStatus, result } = routePlan

  const theme = useTheme()

  const translations = useTranslate({
    pending: 'route-planner.route-plan-task-pending',
    error: 'route-planner.route-plan-task-failed',
    noRoute: 'route-planner.route-plan-no-route-found',
  })

  function getMessageAndColor(
    status: RoutePlanTaskValue
  ): [string | null, string] {
    switch (status) {
      case RoutePlanTaskValue.FAILURE:
        return [translations.error, theme.colors.darkRed]
      case RoutePlanTaskValue.PENDING:
        return [translations.pending, theme.colors.orange]
      case RoutePlanTaskValue.UNDEFINED:
        return [translations.error, theme.colors.darkRed]
      case RoutePlanTaskValue.STARTED:
        return [translations.pending, theme.colors.orange]
      case RoutePlanTaskValue.NO_ROUTE:
        return [translations.noRoute, theme.colors.darkRed]
      default:
        // SUCCESS and undefined status
        return [null, theme.colors.green]
    }
  }

  const [message, color] = getMessageAndColor(result ? result : taskStatus)
  if (!message) return null

  return (
    <Message
      padding="1rem"
      borderRadius="0.5rem"
      show={true}
      text={message}
      background={color}
      color={theme.colors.white}
      iconProps={{
        icon: 'info-circle',
        color: theme.colors.white,
      }}
    />
  )
}
