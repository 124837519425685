import styled from 'styled-components'

/* AbsenceCell */
export const AbsenceCell = styled.td`
  span {
    margin-left: 12px;
    color: ${props => props.theme.colors.gray5};
    font-weight: 600;
  }
`
