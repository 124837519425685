import { useTranslate } from '@ur/react-hooks'
import { FilterChildren, FilterPopup } from 'components/FilterPopup'
import { Status, StatusButton } from 'components/FilterPopup/components'
import { AbsenceFilterForm } from '../types'

interface AbsencePopupFilterProps extends FilterChildren<AbsenceFilterForm> {
  admin: boolean
}

export const AbsencePopupFilter: React.VFC<AbsencePopupFilterProps> = ({
  filter,
  admin,
  onUpdate,
}) => {
  const translations = useTranslate({
    users: 'common.users',
    singular: 'common.singular',
    all: 'common.all',
    status: 'common.status',
    approved: 'timesheets.absence-approved',
    pending: 'timesheets.absence-pending',
    rejected: 'timesheets.absence-rejected',
  })

  function toggleAllUsers(allUsers: boolean) {
    return () => onUpdate({ allUsers })
  }

  const statusOptions = [
    {
      value: undefined,
      label: translations.all,
    },
    {
      value: 'approved',
      label: translations.approved,
    },
    {
      value: 'pending',
      label: translations.pending,
    },
    {
      value: 'REJECTED',
      label: translations.rejected,
    },
  ]

  return (
    <FilterPopup>
      {admin && (
        <>
          <h4>{translations.users}</h4>
          <Status statusCount={2}>
            <StatusButton
              active={!filter.allUsers}
              onClick={toggleAllUsers(false)}
            >
              {translations.singular}
            </StatusButton>
            <StatusButton
              active={!!filter.allUsers}
              onClick={toggleAllUsers(true)}
            >
              {translations.all}
            </StatusButton>
          </Status>
        </>
      )}
      <h4>{translations.status}</h4>
      <Status statusCount={4}>
        {statusOptions.map((option, index) => (
          <StatusButton
            active={filter.requestStatus === option.value}
            key={index}
            onClick={() => onUpdate({ requestStatus: option.value })}
          >
            {option.label}
          </StatusButton>
        ))}
      </Status>
    </FilterPopup>
  )
}
