import { gql } from '@apollo/client'
import { UserFragments } from 'modules/users/fragments'
import { ActivitiesFragments } from './fragments'

export const DRIVER_ACTIVITY_DAY_REPORT_QUERY = gql`
  query DriverActivityDayReport($user: ID!, $date: DateTime!, $moduleConfig: String = "Activities") {
    driverActivityDayReport(user: $user, date: $date) {
      date
      regNr
      registrationNumbers

      groupedActivities {
        activities {
          id
          activityType
          datetimeStart
          datetimeEnd
        }
        vehicleVin
        regNr

        startTime
        endTime

        timeWorkTotal
        timeDriving
        timeRest
        timePause
        timePaidRest
        timeAvailability
        timeNotOnClock
        timeOtherWork
        timeOvertime
      }

      diet {
        id
        salaryCode {
          id
          name
        }
      }

      driverGrade {
        id
        grade
      }

      salaryDay {
        id
        date
        partOfDay
      }

      sumDriverDiets
      sumSalaryDays

      lastFetched
      startTime
      dayStartLocation
      endTime
      dayEndLocation
      daylightSavingsChange {
        offset
        changeTime
        utcNewOffset
        utcOldOffset
      }

      timezoneData {
        timezone
        offset
      }

      timeWorkTotal
      timeDriving
      timeRest
      timePause
      timePaidRest
      timeOvertime
      timeAvailability
      timeNotOnClock
      timeOtherWork

      distance
      fuelUsage
      drivingGrade
      approved

      dataStatus

      drivenCoordinates {
        coordinates {
          lat
          lng
        }
        vehicleVin
      }

      user {
        fullName
        salaryWorkTime(moduleConfigName: $moduleConfig) {
          id
          paidPauseThreshold
        }
      }
      activities {
        id
        activityType
        datetimeStart
        datetimeEnd
        vehicleVin
        vehicleRegistrationNumber
      }
      issues {
        id
        createdAt
        timeOfIssue
      }
      groupedActivitySums {
        name
        id
        sum
      }
    }
  }
`

export const DRIVER_ACTIVITY_PERIOD_REPORT_QUERY = gql`
  query DriverActivityPeriodReport(
    $user: ID!
    $dateStart: DateTime!
    $dateEnd: DateTime!
  ) {
    report: driverActivityPeriodReport(
      user: $user
      dateStart: $dateStart
      dateEnd: $dateEnd
    ) {
      timeWorkTotal
      timePaidRest
      periodReports {
        dayReports {
          date
          regNr
          registrationNumbers
          department {
            id
            name
            externalId
          }
          departments {
            id
            name
            externalId
          }
          groupedActivities {
            vehicleVin
            regNr

            salaryId
            salaryName

            startTime
            endTime

            timeWorkTotal
            timeDriving
            timeRest
            timePause
            timePaidRest
            timeAvailability
            timeNotOnClock
            timeOtherWork
            timeOvertime

            department {
              id
              name
              externalId
            }
          }

          diet {
            id
            salaryCode {
              id
              name
            }
          }

          salaryDay {
            id
            date
            partOfDay
          }

          startTime
          endTime
          dayStartLocation
          dayEndLocation

          timeDriving
          timeOtherWork
          timeOvertime
          timeAvailability
          timeWorkTotal
          timeRest
          timePause
          timePaidRest
          dataStatus
          approved

          absence {
            reason
          }

          salaryIds
          salaryNames
        }
        periodStart
        periodEnd

        timeWorkTotal
        timeDriving
        timeOtherWork
        timeAvailability
        timeRest
        timePause
        timePaidRest

        dayOvertime
        periodOvertime
        overtime
      }
      timeDriving
      timeOtherWork
      timeAvailability

      dayOvertime
      periodOvertime
      overtime
    }
    user(id: $user) {
      id
      fullName
    }
  }
`

export const DRIVER_ACTIVITY_PERIOD_REPORT_SHALLOW_QUERY = gql`
  query DriverActivityPeriodReport(
    $user: ID!
    $dateStart: DateTime!
    $dateEnd: DateTime!
    $weekPartitions: Boolean
  ) {
    report: driverActivityPeriodReport(
      user: $user
      dateStart: $dateStart
      dateEnd: $dateEnd
      weekPartitions: $weekPartitions
    ) {
      timeWorkTotal
      timePaidRest
      timeDriving
      timeOtherWork
      timeAvailability
    
      sumDriverDiets
      sumSalaryDays

      periodOvertime
      salaryWorkTime {
        paidPauseThreshold
        usePeriodOvertime
        groupSalaryOnVehicle
      }
      
      groupedActivitySums {
        name
        id
        sum
      }
    }
  }
`


export const DRIVER_ACTIVITY_MONTH_SUMS_QUERY = gql`
  query DriverActivityPeriodSums($userId: ID!, $monthStart: DateTime!) {
    sums: driverActivityPeriodSums(userId: $userId, monthStart: $monthStart) {
      timeDriving
      timeOtherWork
      timeAvailability
      timePaidPause
      dayOvertime
    }
  }
`

export const ALL_ACTIVITIES_QUERY = gql`
  query AllActivitiesQuery(
    $user: ID
    $datetimeStart_Gte: DateTime
    $datetimeEnd_Lte: DateTime
  ) {
    allDriverActivities(
      user: $user
      datetimeStart_Gte: $datetimeStart_Gte
      datetimeEnd_Lte: $datetimeEnd_Lte
    ) {
      edges {
        node {
          ...DriverActivityInfo
        }
      }
    }
  }
  ${ActivitiesFragments.driverActivityInfo}
`

export const USER_WITH_ACTIVITIES_QUERY = gql`
  query UserWithActivities(
    $id: ID!
    $datetimeStart_Gte: DateTime
    $datetimeEnd_Lte: DateTime
  ) {
    user(id: $id) {
      ...UserInfo
      activities(
        datetimeStart_Gte: $datetimeStart_Gte
        datetimeEnd_Lte: $datetimeEnd_Lte
      ) {
        edges {
          node {
            ...DriverActivityInfo
          }
        }
      }
    }
  }
  ${UserFragments.info}
  ${ActivitiesFragments.driverActivityInfo}
`
