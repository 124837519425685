import { Icon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { Checkbox, Input, NumberInput, TextArea } from 'components'
import { Label } from 'components/Label'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import styled, { useTheme } from 'styled-components'
import { buttonUnset } from 'util/style'
import { FormField } from '..'
import { Field, FormCard } from '../components'
import { FieldTypeSelect } from '../components/FieldTypeSelect'

interface WrapperProps {
  noName: boolean
  angryNoName: boolean
}
const Wrapper = styled(FormCard)<WrapperProps>`
  position: relative;

  header {
    display: flex;
    justify-content: space-between;

    h2 {
      display: flex;
      align-items: flex-end;
      margin: 0;

      color: ${props =>
        !props.noName
          ? null
          : props.angryNoName
          ? props.theme.colors.red
          : props.theme.colors.gray6};

      span {
        transform: translateY(-1px);

        font-size: 0.8em;
        text-transform: uppercase;

        color: ${props => props.theme.colors.gray6};

        &.type {
          margin-left: 1rem;
          color: ${props => props.theme.colors.gray7};
        }
      }
    }

    button {
      ${buttonUnset}

      height: 24px;
      padding: 2px 0 0;
      margin-left: 2rem;

      font-size: 0.8rem;
      color: ${props => props.theme.colors.red};
      background-color: inherit;
      border-radius: 4px;
      cursor: pointer;

      &::before {
        content: '[ ';
      }
      &::after {
        content: ' ]';
      }
      &:hover {
        padding-left: 1ch;
        padding-right: 1ch;
        font-weight: 600;
        color: white;
        background-color: ${props => props.theme.colors.red};

        &::before,
        &::after {
          content: '';
        }
      }
    }
    i {
      margin-left: 2rem;
    }
  }
`
const Arrows = styled.div`
  position: absolute;
  top: 50%;
  right: -3rem;
  transform: translateY(-50%);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 2rem;
  height: 4.5rem;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: 2rem;
      font-weight: 100;
      color: ${props => props.theme.colors.gray7};
      cursor: pointer;

      & + i {
        margin-top: 1rem;
      }
      &:hover {
        color: ${props => props.theme.colors.secondary};
      }
    }
  }
`
const Form = styled.div`
  margin-top: 1rem;
`
const NameField = styled(Field)`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;

  div.required {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`
const MinMaxField = styled(Field)`
  display: grid;
  grid-template-columns: auto auto;
  gap: 1rem;

  & > div {
    position: relative;

    .enabled {
      position: absolute;
      z-index: 1;
      bottom: calc(3.875rem / 2);
      right: calc(3.875rem / 4);
      transform: translateY(50%);
    }
  }
`
const MobileControlField = styled(Field)`
  display: flex;
  justify-content: space-around;

  & > div {
    display: flex;
    justify-content: center;
    padding: 4px 6px 2px;

    font-weight: 700;
    text-transform: uppercase;
    user-select: none;
    border-radius: 4px;

    &.up,
    &.down {
      color: ${props => props.theme.colors.secondary};
    }
    &.remove {
      color: ${props => props.theme.colors.red};
    }

    &:active {
      color: white;

      &.up,
      &.down {
        background-color: ${props => props.theme.colors.secondary};
      }
      &.remove {
        background-color: ${props => props.theme.colors.red};
      }
    }
  }
`

interface CreateFormFieldProps {
  field: FormField

  isFirst: boolean
  isLast: boolean
  autoFocus: boolean

  onShift: (direction: 'up' | 'down') => void
  onChange: <K extends keyof FormField>(
    prop: K
  ) => (value: FormField[K]) => void
  onRemove: () => void
}

export const CreateFormField: React.VFC<CreateFormFieldProps> = ({
  field,

  isFirst,
  isLast,
  autoFocus,

  onShift,
  onChange,
  onRemove,
}) => {
  const translations = useTranslate({
    field: 'form.field',
    untitled: 'common.untitled',

    remove: 'common.remove',
    removeField: 'form.remove-field',
    moveUp: 'common.move-up',
    moveDown: 'common.move-down',

    name: 'common.name',
    required: 'common.required',
    description: 'common.description',
    minimum: 'common.minimum',
    maximum: 'common.maximum',

    type: {
      TEXT: 'form.field-type-text',
      NUMBER: 'form.field-type-number',
      DATE: 'form.field-type-date',
      CHECKBOX: 'form.field-type-boolean',
      USER: 'form.field-type-user',
      VEHICLE_DEPARTMENT: 'form.field-type-vehicle-department',
    },
  })

  const theme = useTheme()

  const [expanded, setExpanded] = useState(true)
  const [nameBlurred, setNameBlurred] = useState(false)

  return (
    <Wrapper noName={field.name === ''} angryNoName={nameBlurred}>
      {!isMobile && (
        <Arrows>
          {!isFirst && (
            <div role="button" onClick={() => onShift('up')}>
              <Icon icon="chevron-up" />
            </div>
          )}
          {!isLast && (
            <div role="button" onClick={() => onShift('down')}>
              <Icon icon="chevron-down" />
            </div>
          )}
        </Arrows>
      )}

      <header>
        <h2>
          {!isMobile && (
            <>
              <span>{translations.field}:</span>&nbsp;
            </>
          )}

          {field.name || translations.untitled}

          {!expanded && !isMobile && (
            <span className="type">({translations.type[field.type]})</span>
          )}
        </h2>

        <div>
          {!isMobile && expanded && (
            <button onClick={onRemove}>{translations.removeField}</button>
          )}

          <Icon
            icon={expanded ? 'chevron-up' : 'chevron-down'}
            color="gray6"
            hoverColor="secondary"
            cursor="pointer"
            onClick={() => setExpanded(v => !v)}
          />
        </div>
      </header>

      {expanded && (
        <Form>
          <Field>
            <FieldTypeSelect value={field.type} onChange={onChange('type')} />
          </Field>

          <NameField>
            <div>
              <Label>{translations.name}</Label>
                <Input
                  value={field.name}
                  error={
                    nameBlurred && field.name === ''
                      ? translations.required
                      : null
                  }
                  autoFocus={autoFocus}
                  fullWidth
                  onBlur={() => setNameBlurred(true)}
                  onChange={onChange('name')}
                />
            </div>

            <div className="required">
              <Label>{translations.required}</Label>
              <Checkbox
                checked={field.required}
                boxWidth="3.875rem"
                boxHeight="3.875rem"
                boxBorderRadius={theme.sizes.defaultBorderRadius}
                checkedIconProps={{
                  size: '2rem',
                }}
                boxBackground="quaternary"
                onChange={onChange('required')}
              />
            </div>
          </NameField>

          <Field>
            <Label>{translations.description}</Label>
            <TextArea
              value={field.description}
              fullWidth
              onChange={onChange('description')}
            />
          </Field>

          {field.type === 'NUMBER' && (
            <MinMaxField>
              <div>
                <Label>{translations.minimum}</Label>
                <NumberInput
                  value={field.minimum}
                  fullWidth
                  disabled={!field.minimumEnabled}
                  color={field.minimumEnabled ? 'currentColor' : 'gray7'}
                  onChange={onChange('minimum')}
                />
                <Checkbox
                  className="enabled"
                  checked={field.minimumEnabled}
                  onChange={onChange('minimumEnabled')}
                />
              </div>
              <div>
                <Label>{translations.maximum}</Label>
                <NumberInput
                  value={field.maximum}
                  fullWidth
                  disabled={!field.maximumEnabled}
                  color={field.maximumEnabled ? 'currentColor' : 'gray7'}
                  onChange={onChange('maximum')}
                />
                <Checkbox
                  className="enabled"
                  checked={field.maximumEnabled}
                  onChange={onChange('maximumEnabled')}
                />
              </div>
            </MinMaxField>
          )}

          {isMobile && (
            <MobileControlField>
              {!isFirst && (
                <div role="button" className="up" onClick={() => onShift('up')}>
                  {translations.moveUp}
                </div>
              )}

              {!isLast && (
                <div
                  role="button"
                  className="down"
                  onClick={() => onShift('down')}
                >
                  {translations.moveDown}
                </div>
              )}

              <div role="button" className="remove" onClick={onRemove}>
                {translations.remove}
              </div>
            </MobileControlField>
          )}
        </Form>
      )}
    </Wrapper>
  )
}
