import { gql } from '@apollo/client'

export const ALL_MANUAL_FOLDERS_QUERY = gql`
  query AllManualFolders($slug: String, $isRoot: Boolean, $q: String) {
    allManualFolders(slug: $slug, isRoot: $isRoot, q: $q) {
      edges {
        node {
          id
          name
          slug
          order
          description
          slugHierarchy

          parentFolder {
            id
          }
        }
      }
    }
  }
`

export const MANUAL_FOLDER_SHALLOW_QUERY = gql`
  query ManualFolderShallow($id: ID!) {
    manualFolder(id: $id) {
      id
      name
    }
  }
`

export const MANUAL_FOLDER_CONTENTS_QUERY = gql`
  query ManualFolderContents($id: ID!) {
    manualFolder(id: $id) {
      id
      slug

      childrenFolders {
        edges {
          node {
            id
            slug
            order

            name
            description
            hasChildren
          }
        }
      }
      entries {
        edges {
          node {
            id
            slug
            order

            name
            description

            folder {
              id
              slug
            }
          }
        }
      }
    }
  }
`

export const MANUAL_FOLDER_BY_SLUG_QUERY = gql`
  query ManualFolderBySlug($folderSlug: String!) {
    manualFolder: manualFolderBySlug(slug: $folderSlug) {
      id
      slug
      name
      slugHierarchyWithNames {
        id
        name
      }
    }
  }
`

export const MANUAL_ENTRY_BY_SLUG_QUERY = gql`
  query ManualEntryBySlug($entrySlug: String!, $folderSlug: String!) {
    manualEntry: manualEntryBySlug(
      entrySlug: $entrySlug
      folderSlug: $folderSlug
    ) {
      id
      slug
      name

      description
      richtext

      createdAt
      updatedAt

      attachments {
        id
        name
        file
      }

      folder {
        id
        slug
        name
        slugHierarchyWithNames {
          name
          slug
        }
      }

      form {
        id
        name
        ingress
        archived
        myResponsesCount
        userRespondedToday

        formFields {
          edges {
            node {
              id
              name
              description
              fieldType
              order
              required
              minimum
              maximum
            }
          }
        }
      }
    }
  }
`

export const HANDBOOK_SEARCH_ENTRIES_QUERY = gql`
  query HandbookSearchEntries($q: String) {
    allManualEntries(q: $q) {
      edges {
        node {
          id
          name
          order
          slug
          description
          folder {
            id
            slug
          }
        }
      }
    }
  }
`

export const HANDBOOK_DASHBOARD_LINKS_QUERY = gql`
  query HandbookDashboardLinks(
    $first: Int
    $before: String
    $after: String
    $isDashboardLink: Boolean
  ) {
    allManualEntries(
      first: $first
      before: $before
      after: $after
      isDashboardLink: $isDashboardLink
    ) {
      edges {
        node {
          id
          name
          order
          slug

          dashboardShortcutActive

          folder {
            id
            slug
          }
          form {
            id
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`
