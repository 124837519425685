import { Icon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { BaseProps } from 'types/props'
import { generateRandomColor } from 'util/generators'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 4px;

  width: 100%;
  height: 32px;
  padding: 4px;

  border-radius: 4px;
  background-color: white;
`
interface ColorProps {
  color: string
  selected?: boolean
}
const Color = styled.div.attrs<ColorProps>(props => ({
  style: {
    backgroundColor: props.color,
  },
}))<ColorProps>`
  width: 100%;
  height: 100%;

  border-radius: 4px;
  cursor: pointer;

  border: ${props => props.selected && '2px solid black'};
`
const Refresh = styled.div.attrs({
  role: 'button',
})`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  &:hover i {
    color: ${props => props.theme.colors.secondary};
  }
`

interface SimpleColorPickerProps extends BaseProps {
  value: string
  onChange: (value: string) => void
}

export const SimpleColorPicker: React.VFC<SimpleColorPickerProps> = ({
  className,
  id,

  value,

  onChange,
}) => {
  const translations = useTranslate({
    refreshColors: 'settings.refresh-colors',
  })

  const [colors, setColors] = useState<string[]>([])
  const [selectedIndex, setSelectedIndex] = useState(-1)

  const refreshColors = useCallback(() => {
    setSelectedIndex(0)
    return Array(7)
      .fill('')
      .map((_, i) => (i === 0 ? value : generateRandomColor()))
  }, [value])

  useEffect(() => {
    if (selectedIndex === -1) {
      setSelectedIndex(0)
      setColors(refreshColors())
    }
  }, [refreshColors, selectedIndex])

  useEffect(() => {
    const idx = colors.findIndex(color => color === value)
    setSelectedIndex(idx)
  }, [colors, value])

  return (
    <Wrapper className={className} id={id}>
      {colors.map((color, idx) => (
        <Color
          key={color + idx}
          color={color}
          selected={idx === selectedIndex}
          onClick={() => onChange(color)}
        />
      ))}

      <Refresh title={translations.refreshColors}>
        <Icon
          icon="redo"
          color="tertiary"
          onClick={() => setColors(refreshColors())}
        />
      </Refresh>
    </Wrapper>
  )
}
