import { UserNode } from 'modules/users'

export function userHasPermissions(
  user: UserNode,
  permissions: string | string[]
) {
  let overloadedPermissions: string[] = []
  overloadedPermissions = overloadedPermissions.concat(permissions)
  const havePermission = overloadedPermissions.every(permission =>
    user.allPermissions.includes(permission)
  )
  if (!havePermission) {
    return false
  }
  return true
}
