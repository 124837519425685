import React from 'react'
import { CompanyNode } from 'modules/companies'

export const CompanyContext = React.createContext<CompanyNode>({
  id: '',
  shortName: '',
  fullName: '',
  language: '',
  country: '',
  logo: '',
  useCompanyLogo: false,
  numberOfUsers: 0,
  userTypes: [],
  moduleConfigs: [],

  notificationSettings: [],
  useOrdinaryHourCalculation: false,
})
