import React from 'react'
import styled from 'styled-components'
import {
  TimePickerProps as BaseTimePickerProps,
  TimePicker as BaseTimePicker,
} from '@ur/react-components'

const StyledTimePicker = styled(BaseTimePicker)`
  .--time-picker-input-hour {
    width: 25px;
    text-align: right;
  }
  .--time-picker-input-minute {
    width: 25px;
    text-align: left;
  }
  .--time-picker-inner-wrapper:focus-within {
    border-color: ${props => props.theme.colors.secondary};
  }
`

interface TimePickerProps extends BaseTimePickerProps {
  grayed?: boolean
}

export const TimePicker: React.VFC<TimePickerProps> = ({
  grayed,
  ...timePickerProps
}) => {
  const background = grayed
    ? 'quaternary'
    : timePickerProps.background ?? 'white'
  return (
    <StyledTimePicker
      height="3.875rem"
      fontSize="1.2rem"
      padding="0 1rem"
      background={background}
      borderColor="gray9"
      disableWheel
      {...timePickerProps}
    />
  )
}
