import {
  NumberInput as BaseNumberInput,
  NumberInputProps as BaseNumberInputProps,
} from '@ur/react-components'
import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

interface StyledNumberInputProps {
  hasLeftIcon: boolean
  hasRightIcon: boolean
  noBorders: boolean
  fontWeight?: string | number
  padding?: string
}
const StyledNumberInput = styled(BaseNumberInput)<StyledNumberInputProps>`
  input {
    padding: ${props => props.padding ?? '0 1em'};
    font-size: ${props => props.fontSize ?? '1.2rem'};
    font-weight: ${props => props.fontWeight};
    border-color: ${props => props.theme.colors.gray9};

    ${props => props.hasLeftIcon && 'border-left: 0; padding-left: 0;'}
    ${props => props.hasRightIcon && 'border-right: 0;'}

    &::placeholder {
      color: ${props => props.theme.colors.gray5};
    }
  }
  .--input-icon-left,
  .--input-icon-right {
    font-size: 1.2em;
    padding: 0 1.4rem 0 2rem;
    border-color: ${props => props.theme.colors.gray9};

    transition: border-color 0.1s linear, color 0.1s linear;
    color: ${props => props.theme.colors.gray5};
  }
  &:focus-within {
    .--input-icon-left,
    .--input-icon-right {
      border-color: ${props => props.theme.colors.secondary};
      color: ${props => props.theme.colors.secondary};
    }
  }

  ${props =>
    props.noBorders &&
    css`
      input {
        border: 0 !important;
      }
      .--input-icon-left,
      .--input-icon-right {
        border: 0 !important;
      }
      &:focus-within {
        .--input-icon-left,
        .--input-icon-right {
          border: 0 !important;
        }
      }
    `}
`

interface NumberInputProps extends Omit<BaseNumberInputProps, 'type'> {
  disableWheel?: boolean
  noBorders?: boolean
  fontWeight?: string | number
  padding?: string
  grayed?: boolean
}

export const NumberInput: React.VFC<NumberInputProps> = ({
  disableWheel = true,
  noBorders = false,
  grayed = false,
  fontWeight,
  padding,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (disableWheel) {
      const input = inputRef.current
      const onWheel = (event: WheelEvent) => {
        if (disableWheel) event.preventDefault()
      }
      if (input) {
        input.addEventListener('wheel', onWheel, { passive: false })
      }
    }
  }, [inputRef, disableWheel])

  const background = grayed ? 'quaternary' : props.background ?? 'white'
  return (
    <StyledNumberInput
      ref={inputRef}
      height="3.875rem"
      background={background}
      focusBorderColor="secondary"
      selectOnFocus
      {...props}
      hasLeftIcon={typeof props.iconLeftProps !== 'undefined'}
      hasRightIcon={typeof props.iconRightProps !== 'undefined'}
      noBorders={noBorders}
      fontWeight={fontWeight}
      padding={padding}
    />
  )
}
