import { useTranslate } from '@ur/react-hooks'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import { InfoBox, SettingsWrapper } from '../components/common'
import { ModuleResponsibleUsers } from '../components/ModuleResponsibleUsers'
import { Module } from 'modules/companies'

interface InfringementsSettingsProps {}

export const InfringementsSettings: React.VFC<InfringementsSettingsProps> =
  () => {
    const translations = useTranslate({
      info1: 'settings.info.infringements-data-1',
    })

    return (
      <SettingsWrapper
        grid={{
          flow: 'row',
        }}
      >
        <InfoBox initCollapsed={isMobileOnly}>
          <p>{translations.info1}</p>
        </InfoBox>

        <ModuleResponsibleUsers module={Module.INFRINGEMENTS} />
      </SettingsWrapper>
    )
  }
