import { Input as BaseInput } from 'components'
import { Card as BaseCard } from 'components/Card'
import styled from 'styled-components'

export const Card = styled(BaseCard)`
  padding: 2rem;

  h2 {
    margin: 0 0 2rem 0;
    font-size: 1.6rem;
    font-weight: 600;
  }
`
interface FieldProps {
  alignLeft?: boolean
  alignRight?: boolean
}
export const Field = styled.div<FieldProps>`
  display: flex;
  flex-direction: column;
  ${props => props.alignLeft && 'align-items: flex-start;'}
  ${props => props.alignRight && 'align-items: flex-end;'}

  & > label {
    font-size: 0.9rem;
    font-weight: 600;
    margin: 0 0 6px 6px;
    color: ${props => props.theme.colors.gray5};
  }
`
export const Form = styled.div`
  display: flex;
  flex-direction: column;

  ${Field} + ${Field} {
    margin-top: 1rem;
  }
`
export const Input = styled(BaseInput)`
  input {
    background-color: white;
  }
`
