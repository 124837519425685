import styled from 'styled-components'
import theme from 'theme'

import { Button, ButtonProps } from './Button'

export const CompactButton = styled(Button).attrs<ButtonProps>(props => ({
  width: props.fullWidth ? '100%' : props.width ?? 'auto',
  height: props.height ?? '2.5rem',

  color: props.color ?? theme.colors.black,
}))<ButtonProps>`
  display: flex;
  justify-content: center;

  text-align: left;
  i {
    line-height: unset;
    align-self: center;
    margin-right: 1ch;
  }
`
