import { Modal } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { buttonUnset } from 'util/style'

const Wrapper = styled.div`
  box-sizing: border-box;

  * {
    box-sizing: inherit;
  }

  display: flex;
  padding: 1rem;

  background: white;
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  box-shadow: ${props => props.theme.shadow.default};
  pointer-events: none;
  touch-action: none;

  opacity: 0.7;
  transition: opacity 0.1s linear;

  p {
    margin: 0;
    padding: 1rem;
    color: ${props => props.theme.colors.gray2};
  }
  button {
    ${buttonUnset}

    border-radius: 6px;
    color: white;
    background-color: ${props => props.theme.colors.secondary};
    padding: 0.3rem 1rem;

    cursor: pointer;
    pointer-events: auto;
    touch-action: all;
  }
  &:hover {
    opacity: 1;
  }

  ${props => props.theme.media.mobile} {
    width: calc(100vw - 12px);
    max-width: calc(100vw - 12px);
    opacity: 1;

    p {
      padding: 0;
    }
  }
`

interface UnsavedChangesProps {
  show: boolean
  message: string

  onSave: () => void
}

export const UnsavedChanges: React.VFC<UnsavedChangesProps> = ({
  show,
  message,

  onSave,
}) => {
  const translations = useTranslate({
    save: 'common.save',
  })

  return (
    <Modal
      open={show}
      noShade
      placement="bottom-right"
      direction="from-bottom"
      offset={isMobileOnly ? 6 : 32}
      shouldFocusAfterRender={false}
    >
      <Wrapper>
        <p>{message}</p>
        <button onClick={() => onSave()}>{translations.save}</button>
      </Wrapper>
    </Modal>
  )
}
