import { GoogleMap } from 'components'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Coordinates } from 'types/graphql/maps'

const Wrapper = styled.div`
  width: 100%;
`

interface CreateRoutePlanMapProps {
  coordinates: {
    start: Coordinates | null
    end: Coordinates | null
  }
}

export const CreateRoutePlanMap: React.VFC<CreateRoutePlanMapProps> = ({
  coordinates,
}) => {
  const prevStartMarker = useRef<google.maps.Marker | null>(null)
  const prevEndMarker = useRef<google.maps.Marker | null>(null)

  const [map, setMap] = useState<google.maps.Map | null>(null)

  const updateMap = useCallback(
    (map: google.maps.Map) => {
      if (!map) return

      const startCoordinate = prevStartMarker.current?.getPosition()
      const endCoordinate = prevEndMarker?.current?.getPosition()

      if (
        coordinates.start &&
        coordinates?.start?.lat !== startCoordinate?.lat() &&
        coordinates?.start?.lng !== startCoordinate?.lng()
      ) {
        prevStartMarker.current?.setMap(null)
        prevStartMarker.current = new google.maps.Marker({
          position: coordinates.start,
          map,
        })
      }
      if (
        coordinates.end &&
        coordinates?.end?.lat !== endCoordinate?.lat() &&
        coordinates?.end?.lng !== endCoordinate?.lng()
      ) {
        prevEndMarker.current?.setMap(null)
        prevEndMarker.current = new google.maps.Marker({
          position: coordinates.end,
          map,
        })
      }

      const bounds = new google.maps.LatLngBounds()

      const defaultLatLngForBound = {
        lat: 63.74570819703677,
        lng: 11.298897210111281,
      }

      bounds.extend(defaultLatLngForBound)
      if (startCoordinate) bounds.extend(startCoordinate)
      if (endCoordinate) bounds.extend(endCoordinate)

      if (!startCoordinate && !endCoordinate) map.setZoom(7)
      else map.fitBounds(bounds)
    },
    [coordinates]
  )

  useEffect(() => {
    if (!map) return

    updateMap(map)
  }, [coordinates.start, coordinates.end, map, updateMap])

  return (
    <Wrapper>
      <GoogleMap
        mapContainerStyle={{
          height: '300px',
          borderRadius: '10px 10px 0 0',
        }}
        options={{
          streetViewControl: false,
          zoomControl: false,
          mapTypeControl: false,
        }}
        onLoad={setMap}
      />
    </Wrapper>
  )
}
