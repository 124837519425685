import { useTranslate } from '@ur/react-hooks'
import styled from 'styled-components'
import { CreateRoutePlanForm } from '../components/CreateRoutePlanForm'

const Wrapper = styled.div`
  ${props => props.theme.layout.default};
  display: flex;
  flex-direction: column;
`
export const CreateRoutePlan: React.FC = () => {
  const translations = useTranslate({
    createRoutePlan: 'route-planner.add-new-route',
  })

  return (
    <Wrapper>
      <h1>{translations.createRoutePlan}</h1>
      <CreateRoutePlanForm />
    </Wrapper>
  )
}
