import {
  Icon,
  PromptResolve,
  SelectOption,
  useToast,
} from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import {
  ExtendedIconProps,
  Input as BaseInput,
  RegularModal,
  Select,
  UserSelect,
} from 'components'
import { Field, Form } from 'modules/companies'
import { ExportIssueForm } from 'modules/issues/types'
import { ShallowUserNode, UserNode } from 'modules/users'
import { useState } from 'react'
import styled from 'styled-components'

import { emailValidation, notEmptyArrayValidation } from 'util/forms'
import { ExportFormat, ExportOption, ExportType } from 'modules/exports'
import { useExportFormatOptions, useExportOptions } from 'modules/exports/hooks'

interface ExportIssuePromptProps {
  onSubmit: PromptResolve<ExportIssueForm | null>
  user: UserNode
}

const Users = styled.div`
  border-radius: 5px;
  overflow: hidden;

  .userItem {
    padding: 0.6rem;
    border-bottom: 1px solid ${props => props.theme.colors.gray9};

    display: flex;
    align-items: center;
    .text {
      flex: 1;
    }
  }
  .userItem:last-child {
    border-bottom: none;
  }
  ${props => props.theme.layout.desktop} {
    max-height: 300px;
    overflow-y: auto;
  }
`

const Input = styled(BaseInput).attrs({
  background: 'white',
  height: '3.875rem',
})`
  margin-top: 0.6rem;
`

export const ExportIssuePrompt: React.VFC<ExportIssuePromptProps> = ({
  onSubmit,
  user,
}) => {
  const translations = useTranslate({
    exportIssue: 'issues.export-issue',
    export: 'common.export',
    selectedRecipients: 'issues.selected-recipients',
    selectFromList: 'issues.select-recipient-from-list',
    addWithEmail: 'issues.add-recipient-with-email',

    email: 'common.email',
    download: 'common.download',
    errorEmail: 'error.valid-email-required',

    type: 'common.type',
    exportOption: 'common.export-type',
    exportFormat: 'common.export-format',

    toasts: {
      emailAlreadySelected: 'common.email-already-selected',
    },
    selectUsersError: 'common.select-at-least-one-recipient',
  })

  /**
   * * Info: The email field is handled seperately because a race condition
   * * when inside same form as the rest */
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState<string | null>(null)
  const addToast = useToast()

  const {
    formValues: form,
    formErrors: errors,
    updateForm,
    submitHandler: submit,
  } = useForm<ExportIssueForm>({
    values: {
      exportOption: ExportOption.SAVE,
      recipients: [],
      exportFormat: ExportFormat.PDF,
      exportType: ExportType.ISSUES,
    },
    validators: {
      recipients: (value, form) =>
        notEmptyArrayValidation(value) &&
        form.exportOption === ExportOption.MAIL
          ? translations.selectUsersError
          : null,
    },
  })

  const exportOptions = useExportOptions()
  const exportFormatOptions = useExportFormatOptions([ExportFormat.TWENTYFOUR_SEVEN_OFFICE, ExportFormat.POWEROFFICE, ExportFormat.VISMA, ExportFormat.EXCEL, ExportFormat.EXCEL_FLAT, ExportFormat.TRIPLETEX, ExportFormat.PDF_SALARY])

  function handleUserSelect(
    id: string | null,
    userOption: SelectOption<string, ShallowUserNode> | null
  ) {
    const user = userOption?.extra
    if (user && !form.recipients.includes(user.email)) {
      updateForm({ recipients: [...form.recipients, user.email] })
    } else {
      addToast('warning', translations.toasts.emailAlreadySelected)
    }
  }

  function handleChangeEmail(value: string) {
    if (!value) {
      setEmailError(null)
      setEmail('')
      return
    }
    const validEmail = emailValidation(value)
    setEmailError(validEmail ? null : translations.errorEmail)
    setEmail(value)
  }

  function handleAddEmail() {
    const addEmail = email && !emailError && !form.recipients.includes(email)

    if (email && form.recipients.includes(email)) {
      addToast('warning', translations.toasts.emailAlreadySelected)
    }

    if (addEmail) {
      updateForm({ recipients: [...form.recipients, email] })
      setEmail('')
    }
  }

  function handleDeleteEmail(email: string) {
    const emails = form.recipients.filter(value => value !== email)
    updateForm({ recipients: [...emails] })
  }

  function handleClear() {
    setEmail('')
    setEmailError(null)
  }

  const iconLeftProps: ExtendedIconProps | undefined =
    email !== ''
      ? {
          icon: 'times',
          hoverColor: 'error',
          cursor: 'pointer',
          onClick: handleClear,
        }
      : undefined

  const iconRightProps: ExtendedIconProps | undefined =
    email !== '' && emailError === null
      ? {
          icon: 'plus-circle',
          hoverColor: 'primary',
          cursor: 'pointer',
          onClick: handleAddEmail,
        }
      : undefined

  return (
    <RegularModal
      title={translations.exportIssue}
      submitText={translations.export}
      width="400px"
      onCancel={() => onSubmit(null)}
      onSubmit={submit(onSubmit)}
    >
      <Form>
        <Field>
          <label>{translations.exportOption}</label>
          <Select
            value={form.exportOption}
            options={exportOptions}
            fullWidth
            height="3.875rem"
            onChange={value =>
              updateForm({
                exportOption: value ?? ExportOption.DOWNLOAD,
              })
            }
          />
        </Field>
        {form.exportOption === ExportOption.DOWNLOAD && (
          <Field>
            <label>{translations.exportFormat}</label>
            <Select
              value={form.exportFormat}
              options={exportFormatOptions}
              fullWidth
              height="3.875rem"
              onChange={value =>
                updateForm({
                  exportFormat: value ?? ExportFormat.PDF,
                })
              }
            />
          </Field>
        )}
        {form.exportOption === ExportOption.MAIL && (
          <>
            <Field>
              <label>{translations.selectFromList}</label>
              <UserSelect
                value={'email'}
                error={errors.recipients}
                fullWidth
                height="3.875rem"
                filterBackground="quaternary"
                optionHoverBackground="quaternary"
                onChange={handleUserSelect}
              />
            </Field>
            <Field>
              <label>{translations.addWithEmail}</label>

              <Input
                value={email}
                error={emailError}
                fullWidth
                autoComplete="off"
                iconLeftProps={iconLeftProps}
                iconRightProps={iconRightProps}
                onChange={handleChangeEmail}
                onEnter={handleAddEmail}
              />
            </Field>

            {form.recipients.length > 0 && (
              <Field>
                <label>{translations.selectedRecipients}</label>
                <Users>
                  {form.recipients.map(email => (
                    <div className="userItem" key={email}>
                      <div className="text">{email}</div>
                      <Icon
                        className="close"
                        icon="times"
                        type="light"
                        size="1.2rem"
                        color="gray6"
                        hoverColor="secondary"
                        cursor="pointer"
                        onClick={() => handleDeleteEmail(email)}
                      />
                    </div>
                  ))}
                </Users>
              </Field>
            )}
          </>
        )}
      </Form>
    </RegularModal>
  )
}
