import { PromptResolve } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { RegularModal, UserSelect } from 'components'
import { DetectBlur } from 'containers/util-containers'
import { ShallowUserNode } from 'modules/users'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { DropdownFilter } from 'types/graphql/enums'

interface SelectUserPromptProps {
  user: ShallowUserNode | null
  dropdownPageName?: DropdownFilter

  onResolve: PromptResolve<ShallowUserNode | null>
}

export const SelectUserPrompt: React.VFC<SelectUserPromptProps> = ({
  user,
  dropdownPageName,

  onResolve,
}) => {
  const translations = useTranslate({
    selectResponsibleUser: 'settings.responsible-user',
  })

  const [selectedUser, setSelectedUser] = useState<typeof user>(user)

  return (
    <DetectBlur active onClickOutside={() => onResolve(null)}>
      <RegularModal
        title={translations.selectResponsibleUser}
        onCancel={() => onResolve(null)}
        onSubmit={() => onResolve(selectedUser)}
      >
        <UserSelect
          value={selectedUser?.id ?? null}
          dropdownPageName={dropdownPageName}
          alwaysInclude={user ?? undefined}
          fullWidth={isMobile}
          onChange={(_, node) =>
            !node?.extra ? void 0 : setSelectedUser(node.extra)
          }
        />
      </RegularModal>
    </DetectBlur>
  )
}
