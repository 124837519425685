import { useQuery } from '@apollo/client'
import { useDebounce, useTranslate } from '@ur/react-hooks'
import {
  Button,
  Input,
  PlainLink,
  SyncButton,
  Table,
  TableMenu,
  Title,
} from 'components'
import { Card } from 'components/Card'
import { parseISO } from 'date-fns'
import truncate from 'lodash/truncate'
import { useCallback, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { format } from 'util/date-fns'
import { useInfiniteScroll } from 'util/hooks'
import { DEFAULT_PAGE_SIZE } from 'util/pagination'
import { useRoutePlannerMutations } from '../mutations.hooks'
import { ALL_ROUTE_PLANS_QUERY } from '../queries'
import {
  AllRoutePlansQuery,
  AllRoutePlansQueryVariables,
  RoutePlanNode,
} from '../types.graphql'

const RoutePlannerWrapper = styled.div`
  ${props => props.theme.layout.default};
`
const TitleWrapper = styled.div`
  display: flex;
  > * + * {
    margin-left: 1rem;
  }
  align-items: center;
  justify-content: space-between;

  ${props => props.theme.media.mobile} {
    align-items: flex-start;
    flex-direction: column;

    > * + * {
      margin-left: 0;
    }
  }
`

const ControllerButtonGroup = styled.div`
  display: flex;
  flex-direction: row;

  > a:first-child {
    margin-right: 1rem;
  }

  ${props => props.theme.media.mobile} {
    width: 100%;
  }
`

const Controls = styled(Card)`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  gap: 1rem;

  margin-bottom: 1rem;
  padding: 1rem;
`

const LinkRow = styled.tr`
  :hover {
    cursor: pointer;
  }
`

interface RoutePlannerProps {}

export const RoutePlanner: React.UFC<RoutePlannerProps> = () => {
  const history = useHistory()
  const translations = useTranslate({
    date: 'common.date',
    user: 'common.user',
    start: 'common.start',
    end: 'common.end',
    status: 'common.status',
    unkown: 'common.unknown',
    search: 'common.search',

    download: 'common.download',
    delete: 'common.delete',

    routePlanner: 'common.route-planner',
    noRoutes: 'route-planner.no-routes',
    addNewRoute: 'route-planner.add-new-route',
  })
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search)

  const {
    data,
    loading: queryLoading,
    fetchMore,
    refetch,
  } = useQuery<AllRoutePlansQuery, AllRoutePlansQueryVariables>(
    ALL_ROUTE_PLANS_QUERY,
    {
      variables: {
        first: DEFAULT_PAGE_SIZE,
        q: debouncedSearch,
      },
    }
  )

  const routePlans = data?.allRoutePlans.edges.map(edge => edge.node)

  const pageInfo = data?.allRoutePlans.pageInfo

  const handleScrollBottom = useCallback(async () => {
    if (
      typeof data === 'undefined' ||
      typeof pageInfo === 'undefined' ||
      !pageInfo.hasNextPage
    )
      return
    try {
      await fetchMore({
        variables: {
          after: pageInfo.endCursor,
        },
      })
    } catch (e) {
      if (e instanceof Error && e.name === 'Invariant Violation') return
      throw e
    }
  }, [data, fetchMore, pageInfo])

  useInfiniteScroll(handleScrollBottom, 200, !queryLoading)
  const { deleteMutation } = useRoutePlannerMutations()

  const handleDeleteRoutePlan = (routePlan: RoutePlanNode) =>
    deleteMutation({ variables: { id: routePlan.id } })

  return (
    <RoutePlannerWrapper>
      <TitleWrapper>
        <Title.H1>{translations.routePlanner}</Title.H1>
        <ControllerButtonGroup>
          <PlainLink to="/route-planner/create">
            <Button role="link" iconLeftProps={{ icon: 'plus' }}>
              {translations.addNewRoute}
            </Button>
          </PlainLink>
          <SyncButton
            background="white"
            refetchQuery={refetch}
            reset={queryLoading}
            onClick={() => refetch()}
          />
        </ControllerButtonGroup>
      </TitleWrapper>
      <Controls>
        <Input
          value={search}
          placeholder={translations.search}
          grayed
          onChange={setSearch}
        />
      </Controls>
      <Table
        loading={queryLoading}
        loaderProps={{
          columns: !isMobile ? 6 : 1,
          rows: 10,
          expectedWidths: [162, 120, 120],
        }}
        noData={routePlans?.length === 0}
        noDataText={translations.noRoutes}
        rowHoverBackground="gray10"
      >
        <thead>
          <tr>
            <th>{translations.date}</th>
            <th>{translations.user}</th>

            <th>{translations.start}</th>

            <th>{translations.end}</th>

            <th colSpan={2}>{translations.status}</th>
          </tr>
        </thead>

        <tbody>
          {!queryLoading &&
            routePlans &&
            routePlans.map(routePlan => {
              const date = format(parseISO(routePlan.createdAt), 'dd. MMM')
              const status = routePlan.result
                ? routePlan.result
                : routePlan.taskStatus

              return (
                <LinkRow
                  onClick={() => history.push(`/route-planner/${routePlan.id}`)}
                  key={routePlan.id}
                >
                  <td>{date}</td>
                  <td>{routePlan?.user?.fullName ?? translations.unkown}</td>
                  <td>
                    {truncate(routePlan.startLocationName, { length: 25 })}
                  </td>
                  <td>{truncate(routePlan.endLocationName, { length: 25 })}</td>
                  <td>{status}</td>
                  <TableMenu
                    items={[
                      {
                        label: translations.delete,
                        onClick: () => handleDeleteRoutePlan(routePlan),
                      },
                    ]}
                  />
                </LinkRow>
              )
            })}
        </tbody>
      </Table>
    </RoutePlannerWrapper>
  )
}
