import { useTranslate } from '@ur/react-hooks'
import { ExportButton } from 'components'
import { Cell, Table as BaseTable } from 'components/Table'
import { format, parseISO } from 'date-fns'
import { ModuleNames } from 'modules/companies'
import { useState } from 'react'
import styled from 'styled-components'
import { API_URL } from 'util/env'
import { downloadFile } from 'util/file'
import { UserExportNode } from '../types.graphql'

const UserExportsTableWrapper = styled.div``

interface StyledTableRowProps {
  renderBold: boolean
}

const StyledTableRow = styled.tr<StyledTableRowProps>`
  td {
    font-weight: ${props => (props.renderBold ? 'bold' : '500')} !important;
  }
`

const Table = styled(props => <BaseTable {...props} />)`
  position: relative;
  overflow: unset;

  ${props => props.theme.media.largePad},
  ${props => props.theme.media.mobile} {
    overflow: auto;
  }

  td.absence-info {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 12px 1rem;
    height: 56px;

    span {
      color: ${props => props.theme.colors.gray5};
      font-weight: 600;
    }
  }
`

interface UserExportsTableProps {
  userExports: UserExportNode[]
  loading: boolean
  lastVisit: Date | undefined
}

export const UserExportsTable: React.VFC<UserExportsTableProps> = ({
  userExports,
  loading,
  lastVisit,
}) => {
  const [xhrLoading, setXhrLoading] = useState(false)
  const translations = useTranslate({
    noResults: 'common.no-results',
    name: 'common.name',
    time: 'common.time',
    download: 'common.download',
    module: 'common.module',

    activities: 'common.activities',
    absences: 'common.absences',
    forum: 'common.forum',
    handbook: 'common.handbook',
    infringements: 'common.infringements',
    issues: 'common.issues',
    terminal: 'common.terminal',
    timesheets: 'common.timesheets',
    vehicles: 'common.vehicle',
    routePlanner: 'common.route-planner',
  })

  function getModuleNames(name: ModuleNames) {
    switch (name) {
      case ModuleNames.ACTIVITIES:
        return translations.activities
      case ModuleNames.ABSENCES:
        return translations.absences
      case ModuleNames.FORUM:
        return translations.forum
      case ModuleNames.HANDBOOK:
        return translations.handbook
      case ModuleNames.INFRINGEMENTS:
        return translations.infringements
      case ModuleNames.ISSUES:
        return translations.issues
      case ModuleNames.TERMINAL:
        return translations.terminal
      case ModuleNames.TIMESHEETS:
        return translations.timesheets
      case ModuleNames.VEHICLES:
        return translations.vehicles
      case ModuleNames.ROUTEPLANNER:
        return translations.routePlanner
    }
  }

  function handleDownload(userExport: UserExportNode) {
    return async () => {
      try {
        const downloadLink = `${API_URL}/download-user-export/${userExport.id}`
        setXhrLoading(true)
        await downloadFile(downloadLink)
      } finally {
        setXhrLoading(false)
      }
    }
  }

  return (
    <UserExportsTableWrapper>
      <Table
        loading={loading}
        noData={userExports.length === 0}
        noDataText={translations.noResults}
      >
        <thead>
          <tr>
            <th>{translations.name}</th>
            <th>{translations.time}</th>
            <th>{translations.module}</th>
            <th>{translations.download}</th>
          </tr>
        </thead>

        <tbody>
          {userExports.map(userExport => {
            const date = parseISO(userExport.createdAt)
            const time = format(date, 'dd.MM.yyyy HH:mm')
            const createdAt = new Date(userExport.createdAt)
            const renderBold = lastVisit ? createdAt > lastVisit : false
            const moduleName = getModuleNames(userExport.module)
            return (
              <StyledTableRow key={userExport.id} renderBold={renderBold}>
                <Cell>{userExport.name}</Cell>
                <Cell>{time}</Cell>
                <Cell>{moduleName}</Cell>
                <Cell>
                  <div>
                    <ExportButton
                      iconLeftProps={{ icon: 'download' }}
                      width="160px"
                      fullWidth={false}
                      onClick={handleDownload(userExport)}
                      disabled={xhrLoading}
                    >
                      {/* TODO not correct icon */}
                      <span>{translations.download}</span>
                    </ExportButton>
                  </div>
                </Cell>
              </StyledTableRow>
            )
          })}
        </tbody>
      </Table>
    </UserExportsTableWrapper>
  )
}
