import React from 'react'

export const FormCreator: React.VFC<React.SVGAttributes<SVGSVGElement>> =
  props => (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 21.2211 19.0433"
      enableBackground="new 0 0 21.2211 19.0433"
      xmlSpace="preserve"
      fill="#003057"
      {...props}
    >
      <path d="M16.3652,4.6674l-2.76-2.77a1.4845,1.4845,0,0,0-1.01-.42h-6.73a1.4448,1.4448,0,0,0-1.43,1.44v13.7a1.43,1.43,0,0,0,1.43,1.43h9.49a1.43,1.43,0,0,0,1.43-1.43V5.6774A1.4229,1.4229,0,0,0,16.3652,4.6674Zm-.33,11.95a.6806.6806,0,0,1-.68.68h-9.49a.6806.6806,0,0,1-.68-.68v-13.7a.6872.6872,0,0,1,.68-.68h5.95v3.58a.6446.6446,0,0,0,.64.64h3.58Zm-3.47-10.92v-3.48l.19.04a.6072.6072,0,0,1,.31.18l2.77,2.76a.6827.6827,0,0,1,.18.32l.04.18Z" />
      <rect x="7.6952" y="13.1774" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="11.0675" width="5.83" height="0.56" rx="0.15" />
      <path d="M13.5252,9.1074v.27a.1453.1453,0,0,1-.15.14h-5.53a.1453.1453,0,0,1-.15-.14v-.27a.1454.1454,0,0,1,.15-.14h5.53A.1454.1454,0,0,1,13.5252,9.1074Z" />
      <rect x="7.6952" y="13.1774" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="11.0675" width="5.83" height="0.56" rx="0.15" />
      <path d="M13.5252,9.1074v.27a.1453.1453,0,0,1-.15.14h-5.53a.1453.1453,0,0,1-.15-.14v-.27a.1454.1454,0,0,1,.15-.14h5.53A.1454.1454,0,0,1,13.5252,9.1074Z" />
      <path d="M13.5252,9.1074v.27a.1453.1453,0,0,1-.15.14h-5.53a.1453.1453,0,0,1-.15-.14v-.27a.1454.1454,0,0,1,.15-.14h5.53A.1454.1454,0,0,1,13.5252,9.1074Z" />
      <rect x="7.6952" y="11.0675" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="13.1774" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="13.1774" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="11.0675" width="5.83" height="0.56" rx="0.15" />
      <path d="M13.5252,9.1074v.27a.1453.1453,0,0,1-.15.14h-5.53a.1453.1453,0,0,1-.15-.14v-.27a.1454.1454,0,0,1,.15-.14h5.53A.1454.1454,0,0,1,13.5252,9.1074Z" />
      <path d="M13.5252,9.1074v.27a.1453.1453,0,0,1-.15.14h-5.53a.1453.1453,0,0,1-.15-.14v-.27a.1454.1454,0,0,1,.15-.14h5.53A.1454.1454,0,0,1,13.5252,9.1074Z" />
      <rect x="7.6952" y="11.0675" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="13.1774" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="13.1774" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="11.0675" width="5.83" height="0.56" rx="0.15" />
      <path d="M13.5252,9.1074v.27a.1453.1453,0,0,1-.15.14h-5.53a.1453.1453,0,0,1-.15-.14v-.27a.1454.1454,0,0,1,.15-.14h5.53A.1454.1454,0,0,1,13.5252,9.1074Z" />
      <path d="M13.5252,9.1074v.27a.1453.1453,0,0,1-.15.14h-5.53a.1453.1453,0,0,1-.15-.14v-.27a.1454.1454,0,0,1,.15-.14h5.53A.1454.1454,0,0,1,13.5252,9.1074Z" />
      <rect x="7.6952" y="11.0675" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="13.1774" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="13.1774" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="11.0675" width="5.83" height="0.56" rx="0.15" />
      <path d="M13.5252,9.1074v.27a.1453.1453,0,0,1-.15.14h-5.53a.1453.1453,0,0,1-.15-.14v-.27a.1454.1454,0,0,1,.15-.14h5.53A.1454.1454,0,0,1,13.5252,9.1074Z" />
      <path d="M13.5252,9.1074v.27a.1453.1453,0,0,1-.15.14h-5.53a.1453.1453,0,0,1-.15-.14v-.27a.1454.1454,0,0,1,.15-.14h5.53A.1454.1454,0,0,1,13.5252,9.1074Z" />
      <rect x="7.6952" y="11.0675" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="13.1774" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="13.1774" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="11.0675" width="5.83" height="0.56" rx="0.15" />
      <path d="M13.5252,9.1074v.27a.1453.1453,0,0,1-.15.14h-5.53a.1453.1453,0,0,1-.15-.14v-.27a.1454.1454,0,0,1,.15-.14h5.53A.1454.1454,0,0,1,13.5252,9.1074Z" />
      <path d="M13.5252,9.1074v.27a.1453.1453,0,0,1-.15.14h-5.53a.1453.1453,0,0,1-.15-.14v-.27a.1454.1454,0,0,1,.15-.14h5.53A.1454.1454,0,0,1,13.5252,9.1074Z" />
      <rect x="7.6952" y="11.0675" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="13.1774" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="13.1774" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="11.0675" width="5.83" height="0.56" rx="0.15" />
      <path d="M13.5252,9.1074v.27a.1453.1453,0,0,1-.15.14h-5.53a.1453.1453,0,0,1-.15-.14v-.27a.1454.1454,0,0,1,.15-.14h5.53A.1454.1454,0,0,1,13.5252,9.1074Z" />
      <rect x="7.6952" y="13.1774" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="11.0675" width="5.83" height="0.56" rx="0.15" />
      <path d="M13.5252,9.1074v.27a.1453.1453,0,0,1-.15.14h-5.53a.1453.1453,0,0,1-.15-.14v-.27a.1454.1454,0,0,1,.15-.14h5.53A.1454.1454,0,0,1,13.5252,9.1074Z" />
      <path d="M13.5252,9.1074v.27a.1453.1453,0,0,1-.15.14h-5.53a.1453.1453,0,0,1-.15-.14v-.27a.1454.1454,0,0,1,.15-.14h5.53A.1454.1454,0,0,1,13.5252,9.1074Z" />
      <rect x="7.6952" y="11.0675" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="13.1774" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="13.1774" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="11.0675" width="5.83" height="0.56" rx="0.15" />
      <path d="M13.5252,9.1074v.27a.1453.1453,0,0,1-.15.14h-5.53a.1453.1453,0,0,1-.15-.14v-.27a.1454.1454,0,0,1,.15-.14h5.53A.1454.1454,0,0,1,13.5252,9.1074Z" />
      <path d="M13.5252,9.1074v.27a.1453.1453,0,0,1-.15.14h-5.53a.1453.1453,0,0,1-.15-.14v-.27a.1454.1454,0,0,1,.15-.14h5.53A.1454.1454,0,0,1,13.5252,9.1074Z" />
      <rect x="7.6952" y="11.0675" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="13.1774" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="13.1774" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="11.0675" width="5.83" height="0.56" rx="0.15" />
      <path d="M13.5252,9.1074v.27a.1453.1453,0,0,1-.15.14h-5.53a.1453.1453,0,0,1-.15-.14v-.27a.1454.1454,0,0,1,.15-.14h5.53A.1454.1454,0,0,1,13.5252,9.1074Z" />
      <path d="M13.5252,9.1074v.27a.1453.1453,0,0,1-.15.14h-5.53a.1453.1453,0,0,1-.15-.14v-.27a.1454.1454,0,0,1,.15-.14h5.53A.1454.1454,0,0,1,13.5252,9.1074Z" />
      <rect x="7.6952" y="11.0675" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="13.1774" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="13.1774" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="11.0675" width="5.83" height="0.56" rx="0.15" />
      <path d="M13.5252,9.1074v.27a.1453.1453,0,0,1-.15.14h-5.53a.1453.1453,0,0,1-.15-.14v-.27a.1454.1454,0,0,1,.15-.14h5.53A.1454.1454,0,0,1,13.5252,9.1074Z" />
      <path d="M13.5252,9.1074v.27a.1453.1453,0,0,1-.15.14h-5.53a.1453.1453,0,0,1-.15-.14v-.27a.1454.1454,0,0,1,.15-.14h5.53A.1454.1454,0,0,1,13.5252,9.1074Z" />
      <rect x="7.6952" y="11.0675" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="13.1774" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="13.1774" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="11.0675" width="5.83" height="0.56" rx="0.15" />
      <path d="M13.5252,9.1074v.27a.1453.1453,0,0,1-.15.14h-5.53a.1453.1453,0,0,1-.15-.14v-.27a.1454.1454,0,0,1,.15-.14h5.53A.1454.1454,0,0,1,13.5252,9.1074Z" />
      <path d="M13.5252,9.1074v.27a.1453.1453,0,0,1-.15.14h-5.53a.1453.1453,0,0,1-.15-.14v-.27a.1454.1454,0,0,1,.15-.14h5.53A.1454.1454,0,0,1,13.5252,9.1074Z" />
      <rect x="7.6952" y="11.0675" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="13.1774" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="13.1774" width="5.83" height="0.56" rx="0.15" />
      <rect x="7.6952" y="11.0675" width="5.83" height="0.56" rx="0.15" />
      <path d="M13.5252,9.1074v.27a.1453.1453,0,0,1-.15.14h-5.53a.1453.1453,0,0,1-.15-.14v-.27a.1454.1454,0,0,1,.15-.14h5.53A.1454.1454,0,0,1,13.5252,9.1074Z" />
    </svg>
  )
