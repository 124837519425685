import { useTranslate } from '@ur/react-hooks'
import { LinkList, LinkListItem, ModuleTitle } from 'components'
import { PermissionsRequiredRoute } from 'containers/permission-containers'
import React, { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { Switch } from 'react-router'
import styled from 'styled-components'
import { useBreadcrumbs } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { useCompositeModules, useModuleConfigs } from './hooks'
import {
  AbsenceSettings,
  ActivitiesSettings,
  ChecklistTemplatesSettings,
  CreateUserTypeSettings,
  DropdownsSettings,
  GeneralSettings,
  IntegrationsSettings,
  IssueSettings,
  PermissionSettings,
  SalarySettings,
  TerminalSettings,
  TimeEntrySettings,
  UserDocumentSettings,
} from './view'
import { RoutePlannerSettings } from './view/RoutePlannerSettings'
import { ExportsSettings } from './view/ExportsSettings'
import { HandbookSettings } from './view/HandbookSettings'
import { InfringementsSettings } from './view/InfringementsSettings'
import { EditSalaryWorkTime } from './components'
import { CreateSalaryWorkTime } from './components/SalarySettings/CreateSalaryWorkTime'
import { VehicleFleetSettings } from './view/VehicleFleetSettings'
import { FATSSettings } from './view/FATSSettings'

const Wrapper = styled.div`
  ${props => props.theme.layout.default};
`
const Content = styled.div`
  display: grid;
  grid-template-columns: 200px 3fr;
  grid-template-rows: auto auto;
  gap: 2rem;

  ${props => props.theme.media.mobile} {
    display: flex;
    flex-direction: column;
    gap: 0;

    & > div + div {
      margin-top: 1rem;
    }
  }
`
const Links = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: max-content;
  grid-column: 1;
  grid-row: 1 / 3;
`

interface CompanySettingsProps {}

export const CompanySettings: React.VFC<CompanySettingsProps> = () => {
  const translations = useTranslate({
    companySettings: 'settings.company-settings',
    activities: 'common.activities',
    issues: 'common.issue',
    infringements: 'common.infringements',
    absenceTypes: 'common.absences',
    absence: 'common.absence',
    timesheets: 'common.timesheets',
    terminalNetwork: 'common.terminal',
    userTypes: 'common.user-types',
    salary: 'settings.salary-and-work-time',
    integrations: 'settings.integrations',
    dropdowns: 'common.dropdowns',
    userDocuments: 'common.user-documents',
    notifications: 'common.notifications',
    checklists: 'common.checklists',
    export: 'common.exports-settings',
    tableSettings: 'settings.tables',
    routePlanner: 'common.route-planner',
    handbook: 'common.handbook',
    vehicleFleet: 'vehicles.fleet-overview',
    fats: 'common.fats',
    fatsBeta: 'common.fats-beta',
  })

  const { setOverride, overrides } = useBreadcrumbs()
  const {
    activitiesActive,
    terminalActive,
    absencesActive,
    issuesActive,
    routePlannerActive,
    timesheetsActive,
    infringementsActive,
    handbookActive,
    vehiclesActive,
  } = useModuleConfigs()

  const { salaryModuleActive, mainModuleActive } = useCompositeModules()

  const links: LinkListItem[] = [
    {
      text: translations.companySettings,
      href: '/settings',
      matchBy: /\/settings\/?$/,
    },
    {
      text: translations.userTypes,
      href: '/settings/user-types',
    },
    {
      text: translations.dropdowns,
      href: '/settings/dropdowns',
    },
    {
      text: translations.salary,
      href: '/settings/salary',
      hasModule: salaryModuleActive,
    },
    {
      text: translations.userDocuments,
      href: '/settings/user-documents',
    },
    {
      text: translations.checklists,
      href: '/settings/checklist-templates',
    },
    {
      text: translations.integrations,
      href: '/settings/integrations',
      hasModule: activitiesActive,
    },
  ]

  const utilLinks = [
    {
      text: translations.export,
      href: '/settings/export',
      hasModule: mainModuleActive,
    },
    {
      text: translations.fatsBeta,
      href: '/settings/fats',
      hasModule: mainModuleActive,
    },
  ]

  const moduleLinks = [
    {
      text: translations.activities,
      href: '/settings/activities',
      hasModule: activitiesActive,
    },
    {
      text: translations.terminalNetwork,
      href: '/settings/terminal-network',
      hasModule: terminalActive,
    },
    {
      text: translations.issues,
      href: '/settings/issues',
      hasModule: issuesActive,
    },
    {
      text: translations.infringements,
      href: '/settings/infringements',
      hasModule: infringementsActive,
    },
    {
      text: translations.routePlanner,
      href: '/settings/route-planner',
      hasModule: routePlannerActive,
    },
    {
      text: translations.timesheets,
      href: '/settings/time-entries',
      hasModule: timesheetsActive,
    },
    {
      text: translations.absence,
      href: '/settings/absence',
      hasModule: absencesActive,
    },
    {
      text: translations.vehicleFleet,
      href: '/settings/vehicles',
      hasModule: vehiclesActive,
    },
    {
      text: translations.handbook,
      href: '/settings/handbook',
      hasModule: handbookActive,
    },
  ]

  useEffect(() => {
    if (overrides['settings']) return
    setOverride('settings', translations.companySettings)
  }, [overrides, setOverride, translations.companySettings])

  return (
    <Wrapper>
      <ModuleTitle>{translations.companySettings}</ModuleTitle>
      <Content>
        <Links>
          <LinkList items={links} expandable={isMobile} />
          <LinkList items={utilLinks} expandable={isMobile} />
          <LinkList items={moduleLinks} expandable={isMobile} />
        </Links>

        <Switch>
          <PermissionsRequiredRoute
            permissions={PERMISSIONS.companies.change.company_general_settings}
            exact
            path="/settings"
            component={GeneralSettings}
          />
          <PermissionsRequiredRoute
            permissions={PERMISSIONS.activities.change.driveractivity}
            exact
            path="/settings/activities"
            component={ActivitiesSettings}
            hasModuleAccess={activitiesActive}
          />
          <PermissionsRequiredRoute
            permissions={PERMISSIONS.issues.add.issuecategory}
            exact
            path="/settings/issues"
            component={IssueSettings}
            hasModuleAccess={issuesActive}
          />
          <PermissionsRequiredRoute
            permissions={PERMISSIONS.companies.change.companysetting}
            exact
            path="/settings/time-entries"
            component={TimeEntrySettings}
            hasModuleAccess={timesheetsActive}
          />
          <PermissionsRequiredRoute
            permissions={PERMISSIONS.timesheets.add.absencetype}
            exact
            path="/settings/absence"
            component={AbsenceSettings}
            hasModuleAccess={absencesActive}
          />
          <PermissionsRequiredRoute
            permissions={PERMISSIONS.infringements.change.infringement}
            exact
            path="/settings/infringements"
            component={InfringementsSettings}
            hasModuleAccess={infringementsActive}
          />

          <PermissionsRequiredRoute
            permissions={PERMISSIONS.companies.change.company_sms_settings}
            exact
            path="/settings/terminal-network"
            component={TerminalSettings}
            hasModuleAccess={terminalActive}
          />
          <PermissionsRequiredRoute
            permissions={PERMISSIONS.terminal.change.terminal}
            exact
            path="/settings/user-types/create"
            component={CreateUserTypeSettings}
          />
          <PermissionsRequiredRoute
            permissions={[
              PERMISSIONS.users.add.usertype,
              PERMISSIONS.users.change.usertype,
            ]}
            exact
            path="/settings/user-types"
            component={PermissionSettings}
          />
          <PermissionsRequiredRoute
            permissions={PERMISSIONS.companies.change.company_general_settings}
            exact
            path="/settings/export"
            component={ExportsSettings}
            hasModuleAccess={mainModuleActive}
          />
          <PermissionsRequiredRoute
            permissions={PERMISSIONS.companies.change.company_general_settings}
            exact
            path="/settings/fats"
            component={FATSSettings}
            hasModuleAccess={mainModuleActive}
          />
          <PermissionsRequiredRoute
            permissions={PERMISSIONS.companies.change.company_general_settings}
            exact
            path="/settings/salary"
            component={SalarySettings}
            hasModuleAccess={salaryModuleActive}
          />
          <PermissionsRequiredRoute
            permissions={PERMISSIONS.companies.change.company_general_settings}
            exact
            path="/settings/salary/create"
            component={CreateSalaryWorkTime}
            hasModuleAccess={salaryModuleActive}
          />
          <PermissionsRequiredRoute
            permissions={PERMISSIONS.companies.change.company_general_settings}
            exact
            path="/settings/salary/:salaryWorkTimeId"
            component={EditSalaryWorkTime}
            hasModuleAccess={salaryModuleActive}
          />
          <PermissionsRequiredRoute
            permissions={PERMISSIONS.companies.change.company_general_settings}
            exact
            path="/settings/integrations"
            component={IntegrationsSettings}
            hasModuleAccess={activitiesActive}
          />
          <PermissionsRequiredRoute
            permissions={PERMISSIONS.handbook.change.manualentry}
            exact
            path="/settings/handbook"
            component={HandbookSettings}
            hasModuleAccess={handbookActive}
          />
          <PermissionsRequiredRoute
            permissions={[
              PERMISSIONS.users.add.usertype,
              PERMISSIONS.users.change.usertype,
            ]}
            exact
            path="/settings/dropdowns"
            component={DropdownsSettings}
          />
          <PermissionsRequiredRoute
            permissions={[
              PERMISSIONS.users.add.userdocument,
              PERMISSIONS.users.change.userdocument,
            ]}
            exact
            path="/settings/user-documents"
            component={UserDocumentSettings}
          />
          {/* TODO Marius: Checklists don't exist in permissions object? */}
          <PermissionsRequiredRoute
            permissions={[
              PERMISSIONS.checklists.change.checklisttemplate,
              PERMISSIONS.checklists.add.checklisttemplate,
            ]}
            exact
            path="/settings/checklist-templates"
            component={ChecklistTemplatesSettings}
          />
          <PermissionsRequiredRoute
            permissions={PERMISSIONS.companies.change.company_general_settings}
            exact
            path="/settings/route-planner"
            component={RoutePlannerSettings}
            hasModuleAccess={routePlannerActive}
          />
          <PermissionsRequiredRoute
            permissions={PERMISSIONS.companies.change.company_general_settings}
            exact
            path="/settings/vehicles"
            component={VehicleFleetSettings}
            hasModuleAccess={vehiclesActive}
          />
        </Switch>
      </Content>
    </Wrapper>
  )
}
