import { gql } from '@apollo/client'

export const InfringementFragments = {
  infringementInfo: gql`
    fragment InfringementInfo on InfringementNode {
      id
      createdAt
      reasonValue

      periodStartDate
      periodEndDate

      infringementTimestamp

      archivedBy {
        id
        fullName
      }
      archivedAt
      archivedBasis
      user {
        id
        fullName
      }
      infringementCategory {
        id
        identifier
        lowerLimit
        upperLimit
        value
        severity
        description
        legalBases {
          edges {
            node {
              article
            }
          }
        }
      }
    }
  `,
}

export const INFRINGEMENT_QUERY = gql`
  query Infringement($id: ID!) {
    infringement(id: $id) {
      ...InfringementInfo
    }
  }
  ${InfringementFragments.infringementInfo}
`

export const ALL_INFRINGEMENTS_QUERY = gql`
  query AllInfringements(
    $first: Int
    $after: String
    $users: [ID]
    $q: String
    $orderBy: String
    $archivedAtIsNull: Boolean
    $excludeSeverities: [String]
    $excludeCategories: [String]
    $infringementTimestamp_Lt: DateTime
    $infringementTimestamp_Gte: DateTime
  ) {
    allInfringements(
      first: $first
      after: $after
      users: $users
      q: $q
      orderBy: $orderBy
      archivedAt_Isnull: $archivedAtIsNull
      excludeSeverities: $excludeSeverities
      excludeCategories: $excludeCategories
      infringementTimestamp_Lt: $infringementTimestamp_Lt
      infringementTimestamp_Gte: $infringementTimestamp_Gte
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          infringementCategory {
            id
            identifier
            severity
            description
          }
          infringementTimestamp
          archivedAt
          user {
            id
            fullName
            phoneNumber
          }
        }
      }
    }
  }
`

export const USER_INFRINGEMENTS = gql`
  query UserInfringements(
    $user: ID!
    $dateGte: DateTime
    $dateLt: DateTime
    $orderBy: String
  ) {
    user(id: $user) {
      id
      fullName
      phoneNumber
      email
      infringementsTotalValue
      infringementCount
      minorInfringementCount
      seriousInfringementCount
      verySeriousInfringementCount
      mostSeriousInfringementCount
      userTypes {
        name
      }
    }
    allInfringements(
      user: $user
      infringementTimestamp_Gte: $dateGte
      infringementTimestamp_Lt: $dateLt
      orderBy: $orderBy
      archivedAt_Isnull: true
    ) {
      edges {
        node {
          id
          archivedAt
          infringementTimestamp
          infringementCategory {
            id
            identifier
            value
            severity
          }
        }
      }
    }
  }
`

export const ALL_USER_INFRINGEMENTS_QUERY = gql`
  query AllUsersInfringementsSums(
    $first: Int
    $after: String
    $q: String
    $orderBy: String
    $users: [ID]
    $dateGte: DateTime!
    $dateLt: DateTime!
  ) {
    allUsersInfringementsSums(
      first: $first
      after: $after
      q: $q
      orderBy: $orderBy
      users: $users
      infringementTimestamp_Gte: $dateGte
      infringementTimestamp_Lt: $dateLt
    ) {
      edges {
        node {
          id
          fullName
          phoneNumber
          infringementsTotalValue
          infringementCount
        }
      }
    }
  }
`
