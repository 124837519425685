import { PromptResolve } from '@ur/react-components'
import { useTranslate, useForm } from '@ur/react-hooks'
import {
  Checkbox,
  DatePicker,
  NumberInput,
  RegularModal as BaseRegularModal,
  Select,
  UserSelect,
} from 'components'
import { differenceInWeeks, startOfDay } from 'date-fns'
import {
  VehicleExpirationNode,
  EXPIRATION_TYPE_OPTIONS,
} from 'modules/vehicles'
import { Input, Field, Form } from 'modules/users/UserSettings/components'
import {
  EditVehicleExpirationForm,
  EditVehicleExpirationPromptResolve,
} from 'modules/vehicles/types'
import styled from 'styled-components'
import { useState } from 'react'

const RegularModal = styled(BaseRegularModal)`
  box-shadow: ${props => props.theme.shadow.default};
`

interface EditVehicleExpirationModalProps {
  vehicleExpiration?: VehicleExpirationNode
  onSubmit: PromptResolve<EditVehicleExpirationPromptResolve | null>
}

export const EditVehicleExpirationModal: React.VFC<EditVehicleExpirationModalProps> =
  ({ vehicleExpiration, onSubmit }) => {
    const translations = useTranslate({
      save: 'common.save',
      type: 'common.type',
      newExpiration: 'vehicles.new-expiration-date',
      editExpiration: 'vehicles.edit-expiration-date',
      description: 'common.description',
      hasNotification: 'settings.has-notification',
      expirationDate: 'documents.expiration-date',
      notificationTime: 'documents.notification-time',
      responsibleUser: 'common.responsible-user',
      validation: {
        required: 'common.required',
      },
    })
    const editing = typeof vehicleExpiration !== 'undefined'
    const [hasNotification, setHasNotification] = useState(
      !!vehicleExpiration?.notificationTime || false
    )

    const expirationDate = vehicleExpiration?.expirationDate
      ? new Date(vehicleExpiration.expirationDate)
      : null
    const notificationTime =
      !vehicleExpiration?.expirationDate || !vehicleExpiration?.notificationTime
        ? 1
        : differenceInWeeks(
            new Date(vehicleExpiration.expirationDate),
            startOfDay(new Date(vehicleExpiration.notificationTime))
          )

    const {
      formValues: form,
      formErrors: errors,
      formValid,
      submitHandler: submit,
      formChangeHandler: handler,
    } = useForm<EditVehicleExpirationForm>({
      values: {
        description: vehicleExpiration?.description ?? '',
        expirationDate: expirationDate,
        notificationTime: notificationTime,
        expirationType: vehicleExpiration?.expirationType ?? '',
        responsibleUser: vehicleExpiration?.responsibleUser?.id || null,
      },
      validators: {
        expirationDate: val =>
          val !== null ? null : translations.validation.required,
        expirationType: val =>
          !!val ? null : translations.validation.required,
      },
      config: {
        initAsInvalid: true,
      },
    })

    const expirationOptions = form.expirationType !== "nextEuControl"
      ? EXPIRATION_TYPE_OPTIONS.filter(item => item.value !== "nextEuControl")
      : EXPIRATION_TYPE_OPTIONS

    return (
      <RegularModal
        title={
          editing ? translations.editExpiration : translations.newExpiration
        }
        width="480px"
        submitText={translations.save}
        submitDisabled={!formValid}
        cancelOnClickOutside
        onCancel={() => onSubmit(null)}
        onSubmit={submit(form => onSubmit({ form, hasNotification }))}
      >
        <Form>
          <Field>
            <label>{translations.expirationDate}</label>
            <DatePicker
              value={form.expirationDate ?? new Date()}
              disabled={form.expirationType === "nextEuControl"}
              fullWidth
              onChange={handler('expirationDate')}
            />
          </Field>

          <Field>
            <label>{translations.type}</label>
            <Select
              value={form.expirationType}
              disabled={form.expirationType === "nextEuControl"}
              options={expirationOptions}
              fullWidth
              onChange={value => {
                if (!value) return
                handler('expirationType')(value)
              }}
            />
          </Field>
          <Field>
            <label>{translations.description}</label>
            <Input
              value={form.description}
              error={errors.description}
              disabled={form.expirationType === "nextEuControl"}
              fullWidth
              autoFocus
              onChange={handler('description')}
            />
          </Field>
          <Field alignLeft>
            <Checkbox
              checked={hasNotification}
              label={translations.hasNotification}
              boxSize="32px"
              onChange={setHasNotification}
            />
          </Field>
          {hasNotification && (
            <>
            <Field>
              <label>{translations.responsibleUser}</label>
              <UserSelect
                value={form.responsibleUser}
                fullWidth
                onChange={handler('responsibleUser')}
              />
            </Field>
            <Field>
              <label>{translations.notificationTime}</label>
              <NumberInput
                value={form.notificationTime ?? 1}
                min={0}
                fullWidth
                onChange={handler('notificationTime')}
              />
            </Field>
            </>
          )}
        </Form>
      </RegularModal>
    )
  }
