import { Icon, IconProps, Loader } from '@ur/react-components'
import { BaseProps } from '@ur/react-components/build/types/props'
import { useTranslate } from '@ur/react-hooks'
import { DetectBlur } from 'containers/util-containers'
import React from 'react'
import styled from 'styled-components'
import { CSSOverflow } from 'types/style'
import { buttonUnset } from 'util/style'

interface WrapperProps {
  hasTitle: boolean
  overflowY?: CSSOverflow
  width?: string
}
const Wrapper = styled.div<WrapperProps>`
  width: ${props => props.width};
  max-height: 92vh;
  
  border: 1rem solid white;

  overflow-y: ${props => props.overflowY ?? 'auto'};
  ${props => props.overflowY && `
    padding: 1rem 1.75rem 1rem 1rem;
    border: none;
    border-rigth: 0.25rem solid white;
  `}

  background: white;
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};

  header {
    position: relative;
    padding: 1rem;

    h2 {
      display: flex;

      margin: 0;
      font-size: 1.2rem;

      div.loader {
        margin-left: 6px;
      }
    }
    i.close {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }
  div.content {
    padding: ${props => (props.hasTitle ? '0 1rem 1rem' : '1rem')};
  }

  ${props => props.theme.media.mobile} {
    width: 96vw;
    max-height: calc(100vh - 4vw);
    overflow-y: auto;
  }
`
interface ButtonsProps {
  hasFooterLeft: boolean
}
const Buttons = styled.div<ButtonsProps>`
  display: flex;
  justify-content: ${props =>
    props.hasFooterLeft ? 'space-between' : 'flex-end'};
  align-items: center;
  margin-top: 1rem;

  button + button {
    margin-left: 1rem;
  }
`
const ConfirmButton = styled.button`
  ${buttonUnset}

  min-width: 8rem;
  padding: 1rem 1.2rem;

  background: inherit;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  text-transform: uppercase;
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};

  i {
    margin-right: 6px;
  }

  &.deny {
    color: ${props => props.theme.colors.gray3};
    background-color: ${props => props.theme.colors.gray8};

    &:hover {
      background-color: ${props => props.theme.colors.gray9};
    }
  }
  &.confirm {
    color: white;
    background-color: ${props => props.theme.colors.secondary};

    &:hover {
      background-color: ${props => props.theme.colors.secondary}ba;
    }
  }

  &:active,
  &:focus {
    outline: none;
  }

  &:disabled {
    pointer-events: none;
    touch-action: none;

    color: ${props => props.theme.colors.gray6};
    background: ${props => props.theme.colors.gray10};
  }
`

interface RegularModalProps extends BaseProps {
  title?: string

  cancelText?: string
  submitText?: string
  cancelIcon?: IconProps
  submitIcon?: IconProps
  footerLeft?: JSX.Element

  cancelOnClickOutside?: boolean
  cancelDisabled?: boolean
  submitDisabled?: boolean
  loading?: boolean

  overflowY?: CSSOverflow
  width?: string

  /** If defined, shows a close icon in top right and invokes this function on click */
  onClose?: () => void
  onCancel?: () => void
  onSubmit?: () => void
}

export const RegularModal: React.FC<RegularModalProps> = ({
  children,
  className,
  id,

  title,
  cancelText,
  submitText,
  cancelIcon,
  submitIcon,
  footerLeft,

  cancelOnClickOutside = false,
  cancelDisabled = false,
  submitDisabled = false,
  loading = false,

  overflowY = "visible",
  width,

  onClose,
  onCancel,
  onSubmit,
}) => {
  const translations = useTranslate({
    cancel: 'common.cancel',
    ok: 'common.ok',
  })

  const hasCloseButton = typeof onClose !== 'undefined'
  const hasCancelButton = typeof onCancel !== 'undefined'
  const hasSubmitButton = typeof onSubmit !== 'undefined'
  const hasFooterLeft = typeof footerLeft !== 'undefined'

  const hasButtons = hasCancelButton || hasSubmitButton
  const hasFooter = hasFooterLeft || hasButtons

  const wrapButtons = (buttons: JSX.Element) =>
    !hasFooterLeft ? buttons : <div>{buttons}</div>

  return (
    <DetectBlur
      active={cancelOnClickOutside}
      onClickOutside={() => onCancel?.()}
    >
      <Wrapper
        className={className}
        id={id}
        hasTitle={!!title}
        overflowY={overflowY}
        width={width}
      >
        {!!title && (
          <header>
            <h2>
              {title}
              {loading && (
                <div className="loader">
                  <Loader.Spinner size={20} thickness={2} />
                </div>
              )}
            </h2>

            {hasCloseButton && (
              <Icon
                className="close"
                icon="times"
                type="light"
                size="1.2rem"
                color="gray6"
                hoverColor="secondary"
                cursor="pointer"
                onClick={() => onClose?.()}
              />
            )}
          </header>
        )}

        <div className="content">{children}</div>

        {hasFooter && (
          <Buttons hasFooterLeft={hasFooterLeft}>
            {footerLeft}

            {hasButtons &&
              wrapButtons(
                <>
                  {hasCancelButton && (
                    <ConfirmButton
                      className="deny"
                      disabled={cancelDisabled}
                      onClick={() => onCancel?.()}
                    >
                      {!!cancelIcon && <Icon {...cancelIcon} />}
                      {cancelText ?? translations.cancel}
                    </ConfirmButton>
                  )}

                  {hasSubmitButton && (
                    <ConfirmButton
                      className="confirm"
                      disabled={submitDisabled}
                      onClick={() => onSubmit?.()}
                    >
                      {!!submitIcon && <Icon {...submitIcon} />}
                      {submitText ?? translations.ok}
                    </ConfirmButton>
                  )}
                </>
              )}
          </Buttons>
        )}
      </Wrapper>
    </DetectBlur>
  )
}
