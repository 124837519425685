import { useTranslate } from '@ur/react-hooks'
import { Module } from 'modules/companies/consts'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { useConfirm, useModuleConfig } from 'util/hooks'
import {
  InfoBox,
  Section,
  SectionHeader,
  SettingsWrapper,
  TimeEntryModuleOptionCards,
  TimeEntryProjectForm,
  TimeEntryProjectPrompt,
} from '../components'
import { AddButton } from '../components/common'
import { TimeEntryProjectSettingsTable } from '../components/TimeEntrySettings/TimeEntryProjectSettingsTable'
import {
  ALL_TIME_ENTRY_PROJECTS_QUERY,
  AllTimeEntryProjectsQuery,
  TimeEntryProjectNode,
} from 'modules/timesheets'
import { useQuery } from '@apollo/client'
import { useTimesheetSettingsMutations } from '../util'
import { usePrompt } from '@ur/react-components'
import { RelayNodeType } from 'types/graphql'

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

interface TimeEntrySettingsProps {}

export const TimeEntrySettings: React.VFC<TimeEntrySettingsProps> = () => {
  const translations = useTranslate({
    info1: 'settings.info.time-entries-1',

    moduleOptions: 'settings.module-options',
    timeEntryProjects: 'settings.time-entry-projects',

    prompt: {
      delete: ['settings.prompts.delete-time-entry-project', { name: '' }],
      deleteTitle: 'settings.prompts.time-entry-project-delete-title',
    },
  })
  const addPrompt = usePrompt()
  const confirm = useConfirm()

  const { moduleConfig } = useModuleConfig(Module.TIMESHEETS)

  const { data } = useQuery<AllTimeEntryProjectsQuery, {}>(
    ALL_TIME_ENTRY_PROJECTS_QUERY
  )

  const projects =
    data?.allTimeEntryProjects?.edges?.map(edge => edge.node) ?? []

  const mutations = useTimesheetSettingsMutations()

  async function handleAddTimeEntryProject() {
    const { data } = await addPrompt<TimeEntryProjectForm | null>(resolve => (
      <TimeEntryProjectPrompt
        existingTimeEntryProjects={projects}
        onSubmit={resolve}
      />
    ))
    if (data === null) return

    mutations.create({
      variables: {
        input: { name: data.name },
      },
    })
  }

  async function handleEditTimeEntryProject(project: TimeEntryProjectNode) {
    const { data } = await addPrompt<TimeEntryProjectForm | null>(resolve => (
      <TimeEntryProjectPrompt
        initialData={project}
        existingTimeEntryProjects={projects}
        onSubmit={resolve}
      />
    ))
    if (data === null) return

    mutations.patch({
      variables: {
        id: project.id,
        input: { name: data.name },
      },
    })
  }

  async function handleDeleteTimeEntryProject(
    project: RelayNodeType<AllTimeEntryProjectsQuery['allTimeEntryProjects']>
  ) {
    const { data: answer } = await confirm(
      translations.prompt.delete({ name: project.name }),
      translations.prompt.deleteTitle
    )
    if (!answer) return

    mutations.delete({
      variables: {
        id: project.id,
      },
    })
  }

  return (
    <SettingsWrapper grid={{ flow: 'row' }}>
      <InfoBox initCollapsed={isMobileOnly}>
        <p>{translations.info1}</p>
      </InfoBox>

      <Section>
        <Header>
          <SectionHeader>{translations.moduleOptions}</SectionHeader>
        </Header>

        {!!moduleConfig && <TimeEntryModuleOptionCards />}
      </Section>
      <Section>
        <Header>
          <SectionHeader>{translations.timeEntryProjects}</SectionHeader>
          <AddButton adding={false} onClick={handleAddTimeEntryProject} />
        </Header>
        {!!projects && (
          <TimeEntryProjectSettingsTable
            timeEntryProjects={projects}
            onEditSubmit={handleEditTimeEntryProject}
            onDelete={handleDeleteTimeEntryProject}
          />
        )}
      </Section>
    </SettingsWrapper>
  )
}
