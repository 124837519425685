import { endOfDay, isAfter } from 'date-fns'
import Delta from 'quill-delta'
import { Attachment } from './hooks/useAttachmentHandlers'

export function passwordValidation(x: string) {
  return x.length > 6 && !!x.match(/[0-9]/)
}

export function passwordLengthValidation(x: string) {
  return x.length > 6
}

export function passwordContainsNumericValue(x: string) {
  return !!x.match(/\d/)
}

export function passwordContainsNonNumericValue(x: string) {
  return !!x.match(/[^0-9]/)
}

export function notEmptyValidation(x: any) {
  return !!x
}

export function notEmptyArrayValidation(array: string[]) {
  return Array.isArray(array) && !array.length
}

/**
 * Checks if a given Delta is valid.
 * The function goes through each insert operation to check that at least one of them contains content.
 * @param x - A valid quill Delta or undefined object
 */
export function quillValidation(x: Delta | undefined) {
  if (typeof x === 'undefined') return false
  for (const op of x.ops)
    if (typeof op.insert === 'string' && notBlankValidation(op.insert))
      return true
  return false
}
/**
 * Checks whether the string contains only whitespace.
 * @param x - A string
 */
export function notBlankValidation(x: string | undefined | null) {
  if (typeof x === 'undefined') return false
  if (x === null) return false
  return x.search(/\S/g) !== -1
}

/**
 * Checks whether Delta is Valid or form contains atleast one attachment.
 * @param delta - A valid del
 * @param attachments - an array of Attachments
 */
export function quillValidOrHasAttachments(
  delta: Delta | undefined,
  attachments: Attachment[]
) {
  if (attachments.length > 0) return true
  return quillValidation(delta)
}

export function dateIsNotInFutureValidation(date: Date) {
  return isAfter(endOfDay(new Date()), date)
}

export const positiveNumberInRangeValidation = (
  x: string,
  range: [number, number]
) => {
  if (range !== undefined) {
    return (
      positiveNumbersValidaton(x) &&
      x.search('e') === -1 &&
      (parseInt(x, 10) === range[1] ||
        Math.max(parseInt(x, 10), range[1]) !== parseInt(x, 10)) &&
      (parseInt(x, 10) === range[0] ||
        Math.min(parseInt(x, 10), range[0]) !== parseInt(x, 10))
    )
  } else {
    return positiveNumbersValidaton(x)
  }
}

export const positiveNumbersValidaton = (x: string) => {
  return !isNaN(parseInt(x, 10)) && parseInt(x, 10) >= 0
}

export const minNumberValidaton = (x: string, minVal: number) => {
  return !isNaN(parseInt(x, 10)) && parseInt(x, 10) >= minVal
}

export function ipFilterRangeValidation(x: number) {
  return x >= 1 && x <= 32
}

/**
 * Validates ipv4 and ipv6 addresses
 * @param ip Ip address to validate
 * @see https://www.regexpal.com/?fam=104038 for tested examples
 */
export function ipAddressValidation(ip: string) {
  const ip4Or6Exp =
    /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/g
  return !!ip.match(ip4Or6Exp)
}

/**
 * Validate IPv4 addresses
 * @param ip Ip address to validate
 * @see https://stackoverflow.com/a/36760050/3642709
 */
export const ipv4AddressValidation = (ip: string) =>
  /^((25[0-5]|(2[0-4]|1[0-9]|[1-9]|)[0-9])(\.(?!$)|$)){4}$/.test(ip)

export function cleanRegexOperators(string: string) {
  const regexOperators = /[.^$*+?{}[\]\\()]/gi
  return string.replace(regexOperators, operator => '\\'.concat(operator))
}

export function internalNumberValidation(x: string) {
  return maximumLengthValidation(x, 255)
}

export function postalCodeValidation(x: string) {
  return maximumLengthValidation(x, 63)
}

export function maximumLengthValidation(x: string, length: number) {
  return x.length <= length
}

export function emailValidation(x: string) {
  const regexMail = /.+@.+[.].{1,63}/gi
  return x.match(regexMail)
}

export function getFileDataURL(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = evt => {
      if (evt.target === null) {
        reject('FileReader is null')
        return
      }
      if (evt.target.result === null) {
        reject('FileReader result us null')
        return
      }

      resolve(evt.target.result.toString())
    }
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}
