import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { AppNotificationNode, AppNotificationAction } from '../types'
import { getAppNotificationImage } from './util'
import { parseISO } from 'date-fns'
import ReactMarkdown from 'react-markdown'
import { TimeSince } from './TimeSince'
import { useAppNotificationActions } from '../hooks'

const Layout = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 4fr;
  grid-gap: 1rem;
  align-items: start;
`

const ImageWrapper = styled.div``

const Content = styled.div`
  text-align: left;
  align-self: start;
`

const Text = styled.div`
  font-size: 12px;
  text-align: left;

  p {
    margin-bottom: 0;
  }
`

const TimeSinceWrapper = styled.span`
  font-size: 12px;
  color: ${props => props.theme.colors.gray4};
`

const Actions = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
`

const Action = styled(Link)`
  font-size: 12px;
  cursor: pointer;

  :after:not(:last-of-type) {
    /* Font awesome circle*/
    font-family: 'Font Awesome 5 Pro';
    content: '\f111';
  }
`

interface AppNotificationItemProps {
  appNotification: AppNotificationNode
  onActionClicked: (action: AppNotificationAction) => void
}

const AppNotificationItem: React.FC<AppNotificationItemProps> = ({
  appNotification,
  onActionClicked,
}) => {
  const { getAppNotificationActions } = useAppNotificationActions()

  const actions = getAppNotificationActions(appNotification)
  return (
    <Layout>
      <ImageWrapper>{getAppNotificationImage(appNotification)}</ImageWrapper>
      <Content>
        <Text>
          <ReactMarkdown source={appNotification.message} />
        </Text>
        <TimeSinceWrapper>
          <TimeSince
            timestamp={parseISO(appNotification.createdAt)}
            cutOff={1}
          />
        </TimeSinceWrapper>
        <Actions>
          {actions.map(action => (
            <Action
              key={action.text}
              to={action.link}
              onClick={() => onActionClicked(action)}
            >
              {action.text}
            </Action>
          ))}
        </Actions>
      </Content>
    </Layout>
  )
}

export default AppNotificationItem
