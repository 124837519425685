import {
  endOfWeek,
  formatISO,
  startOfWeek,
} from 'date-fns'
import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useLocalStorage } from 'util/hooks'
import { CURRENT_COMPANY_STATS, USER_DRIVER_STATS_QUERY } from './queries'
import {
  CurrentCompanyStatsQuery,
  CurrentCompanyStatsQueryVariables,
  UserDriverStatsQuery,
  UserDriverStatsQueryVariables,
} from './types'

const LS_STAT_TYPE_KEY = 'statistics-type'
const LS_100_KM_KEY = 'statistics-show-100-km'

export type StatType = 'user' | 'company'

export function useStatisticsFilter() {
  const [statType, setStatType] = useLocalStorage(
    LS_STAT_TYPE_KEY,
    val => (['user', 'company'].includes(val) ? (val as StatType) : 'user'),
    'user'
  )

  const [show100km, setShow100km] = useLocalStorage(
    LS_100_KM_KEY,
    val => val === 'true'
  )

  const filterVariables = useMemo<
    Required<Omit<CurrentCompanyStatsQueryVariables, 'company' | 'user'>>
  >(() => {
    const now = new Date()
    const startOfPeriod = startOfWeek
    const endOfPeriod = endOfWeek

    return {
      dateGte: formatISO(startOfPeriod(now), {
        representation: 'date',
      }),
      dateLt: formatISO(endOfPeriod(now), { representation: 'date' }),
      dateTimeGte: formatISO(startOfPeriod(now)),
      dateTimeLt: formatISO(endOfPeriod(now)),
    }
  }, [])

  return {
    filterVariables,

    statType: statType ?? 'user',
    setStatType,

    show100km: show100km ?? true,
    setShow100km,
  }
}

export function useCompanyStats() {
  const { filterVariables } = useStatisticsFilter()

  const { data, loading: companyStatsLoading } = useQuery<
    CurrentCompanyStatsQuery,
    CurrentCompanyStatsQueryVariables
  >(CURRENT_COMPANY_STATS, {
    variables: {
      ...filterVariables,
    },
  })

  return {
    companyData: data?.companyDriverStats,
    companyStatsLoading,
  }
}

export function useDriverStats(userId?: string) {
  const { filterVariables } = useStatisticsFilter()

  const { data, loading: driverStatsLoading } = useQuery<
    UserDriverStatsQuery,
    UserDriverStatsQueryVariables
  >(USER_DRIVER_STATS_QUERY, {
    variables: {
      ...filterVariables,
      userId,
    },
  })

  return {
    driverData: data?.userDriverStats,
    driverStatsLoading,
  }
}

export function useStats(statType: StatType, show100km: boolean) {
  const { companyData, companyStatsLoading } = useCompanyStats()
  const { driverData, driverStatsLoading } = useDriverStats()

  const data = statType === 'company' ? companyData : driverData
  function checkUnavailable(num: number | string) {
    if (typeof num === 'string') num = parseFloat(num)
    return num ? num.toFixed(1) : 'N/A'
  }

  function convertFuelConsumption(tuberculosis: number | string) {
    if (typeof tuberculosis === 'string')
      tuberculosis = parseFloat(tuberculosis)

    return show100km ? tuberculosis : tuberculosis / 10
  }
  const kilometers = checkUnavailable(data?.kilometersDriven ?? 0)
  const fuelEco = checkUnavailable(
    convertFuelConsumption(data?.averageFuelConsumptionPerHundredKilometer ?? 0)
  )
  const numIssues = data?.numberOfIssues ?? 0
  // TODO where does this come from?
  const driverGrade = data?.averageDriverGrade ?? 'N/A'

  const loading = statType === 'company' ? companyStatsLoading : driverStatsLoading

  return { kilometers, fuelEco, numIssues, driverGrade, loading }
}
