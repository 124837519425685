import { UserTypeNode } from 'modules/users'
import { ModuleConfigNode, SalaryWorkTimeNode } from '.'
import { isMobile } from 'react-device-detect'

/**
 * Finds the SalaryWorkTimeNode for a user with the highest priority for a given module
 *
 * @param user UserNode containing user types
 * @param module ModuleConfigNode
 * @returns SalaryWorkTimeNode with the highest priority containing at least one of the users user types.
 */

export function getUsersHighestPrioritySalaryWorkTime(
  user: { userTypes: Pick<UserTypeNode, 'id'>[] } | undefined,
  module: ModuleConfigNode | null
): SalaryWorkTimeNode {
  const salaryWorkTimes = [...(module?.salaryWorkTimes ?? [])] 
  const usersUserTypeIds =
    user?.userTypes?.map(usertype => usertype.id) ?? ([] as string[])
  const filteredSalaryWorkTimes = salaryWorkTimes.filter(salaryWorkTime => {
    const userTypeIds =
      salaryWorkTime.userTypes?.edges?.map(edge => edge.node.id) ?? []
    return userTypeIds.some(id => usersUserTypeIds.includes(id))
  })
  if (filteredSalaryWorkTimes.length === 0) {
    const nonUserTypeSalaryWorkTimes = salaryWorkTimes.filter(salaryWorkTime => salaryWorkTime.userTypes?.edges.length === 0)
    if (nonUserTypeSalaryWorkTimes.length > 0) {
      return nonUserTypeSalaryWorkTimes.reduce((acc, cur) => {
        if (cur.priority < acc.priority) return cur
        return acc
      })
    }
    else {
      return salaryWorkTimes.sort((a, b) => a.priority - b.priority)[0]
    }
  }
  return filteredSalaryWorkTimes.reduce((acc, cur) => {
    if (cur.priority < acc.priority) return cur
    return acc
  })
}

export function responsiveExportGridAreas(
  hasUserField?: boolean,
  hasExportField = true
) {
  if (!isMobile) {
    return `
          'month month  exportOption'
          ${hasExportField ? "'exportType exportType exportFormat'" : ''}
          ${hasUserField ? `'user user user'` : ''}
        `
  }
  return `
        'month'
        'exportOption'
        ${hasExportField ? "'exportType'" : ''}
        ${hasExportField ? "'exportFormat'" : ''}
        ${hasUserField ? `'user'` : ''}
      `
}
