import { endOfMonth, subMonths, formatISO, addMonths } from 'date-fns'
import { YearMonthExportData } from './types'
import { API_URL } from 'util/env'
import { ExportType } from './consts'
import { ExportFormat } from '.'

export const getExportDates = (
  exportType: ExportType,
  year: number,
  month: number
): [string, string] => {
  const [startDate, endDate] =
    exportType === ExportType.ENTRY_MONTH
      ? [new Date(year, month), endOfMonth(new Date(year, month))]
      : [subMonths(new Date(year, month, 26), 1), new Date(year, month, 25)]
  return [formatISO(startDate), formatISO(endDate)]
}

export const getExportLink = (
  data: YearMonthExportData,
  exportType: ExportType,
  dateStart: string,
  dateEnd: string,
  exportFormat: ExportFormat
): string => {
  return `${API_URL}/${exportType}/${data.companyId}/${data.userId}?date_start=${dateStart}&date_end=${dateEnd}&export_format=${exportFormat}`
}

export const getSalaryDatesForMonth = (
  dateStart: number,
  dateEnd: number,
  year: number,
  month: number
): [Date, Date] => {
  const safeMonth = month - 1 === 0 ? 12 : month - 1
  const startMonthDate =
    safeMonth === 12 ? new Date(year - 1, safeMonth) : new Date(year, safeMonth)
  const safeStartYear = startMonthDate.getFullYear()
  const safeStartMonth = startMonthDate.getMonth()
  const nextMonthDate = addMonths(startMonthDate, 1)

  const nextMonthEdgeDate = addMonths(
    new Date(safeStartYear, safeStartMonth),
    1
  )
  nextMonthEdgeDate.setDate(
    Math.min(dateEnd, endOfMonth(nextMonthDate).getDate())
  )

  const startDate =
    endOfMonth(startMonthDate).getDate() < dateStart
      ? addMonths(new Date(safeStartYear, safeStartMonth, 1), 1)
      : new Date(safeStartYear, safeStartMonth, dateStart)
  const endDate =
    dateStart === 1
      ? new Date(
          year,
          safeMonth,
          Math.min(dateEnd, endOfMonth(startMonthDate).getDate())
        )
      : nextMonthEdgeDate

  return [startDate, endDate]
}
