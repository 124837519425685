import { ComponentSwitch as Switch, Icon } from '@ur/react-components'
import { SVGIcon } from 'components/icons'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { SidebarGraph } from 'store/sidebar'
import styled from 'styled-components'
import { HeaderMenuNotifications } from './HeaderMenuNotifications'
import { HeaderMenuUser } from './HeaderMenuUser'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  /* Ensure the DetectBlur elements are 100% height */
  & > * {
    height: 100%;

    & + * {
      margin-left: 2em;
    }
  }
`
interface BoxProps {
  invert: boolean
}
const Box = styled.div<BoxProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 42px;
  height: 42px;

  border-radius: 27%;
  background-color: ${props =>
    props.invert ? props.theme.colors.primary : props.theme.colors.quaternary};

  & + div {
    margin-left: 8px;
  }
  svg {
    width: 55%;
  }
`

export const HeaderMenu: React.VFC = () => {
  const [{ toggle, open }] = SidebarGraph.useGraph()

  return (
    <Wrapper>
      <Switch>
        <Switch.Case case={!isMobile}>
          <HeaderMenuUser />
          <HeaderMenuNotifications />
        </Switch.Case>

        <Switch.Case default>
          <Box invert={false}>
            <SVGIcon icon="bell" fill="gray5" />
          </Box>
          <Box invert={open} onClick={() => toggle()}>
            {/* TODO not the correct icon */}
            <Icon icon="bars" color={open ? 'white' : 'primary'} />
          </Box>
        </Switch.Case>
      </Switch>
    </Wrapper>
  )
}
