import { Icon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { Table } from 'components'
import { PlainLink } from 'components/Link'

import { ShallowUserNode } from 'modules/users'
import { useState } from 'react'
import styled, { css } from 'styled-components'
import { Color, SettingsColorPicker } from '../common'
import { IssueCategoriesSettingsForm, IssueCategoryForSettings } from './types'

const ResponsibleUser = styled(PlainLink)`
  display: flex;
  align-items: center;

  span {
    margin-left: 8px;

    &:hover  {
      color: ${props => props.theme.colors.secondary};
    }
  }
`

interface StaticCategoriesTableHeadProps {
  collapsed: boolean
}
const StaticCategoriesTableHead = styled.thead<StaticCategoriesTableHeadProps>`
  cursor: pointer;
  ${props =>
    !props.collapsed &&
    css`
      tr:first-of-type {
        th {
          &:first-of-type {
            border-bottom-left-radius: 0;
          }
          &:last-of-type {
            border-bottom-right-radius: 0;
          }
        }
      }
      tr:last-of-type {
        th {
          padding-top: 0;

          &:first-of-type {
            border-top-left-radius: 0;
          }
          &:last-of-type {
            border-top-right-radius: 0;
          }
        }
      }
    `};

  th span {
    margin-left: 1ch;
    text-transform: none;
    color: ${props => props.theme.colors.gray3};
    font-weight: 500;
  }
`

interface IssueCategorySettingsTableProps {
  editableCategories: IssueCategoryForSettings[]
  uneditableCategories: IssueCategoryForSettings[]

  form: IssueCategoriesSettingsForm

  temporaryEditColor: {
    [id: string]: string | null
  }

  onSelectResponsibleUser: (
    categoryId: string | null,
    currentUser: ShallowUserNode | null
  ) => void
  onUpdateColor: (categoryId: string) => (color: string) => void
  onEditClick?: (category: IssueCategoryForSettings) => void
  onDelete: (category: IssueCategoryForSettings) => void
}

export const IssueCategorySettingsTable: React.VFC<IssueCategorySettingsTableProps> =
  ({
    editableCategories,
    uneditableCategories,

    form,

    temporaryEditColor,

    onSelectResponsibleUser,
    onUpdateColor,
    onEditClick,
    onDelete,
  }) => {
    const translations = useTranslate({
      name: 'common.name',
      responsibleUser: 'common.responsible-user',
      choose: 'common.choose-ellipsis',

      staticCategories: 'settings.static-categories',
      staticCategoriesInfo: 'settings.info.issue-categories-uneditable',
    })

    const [staticsCollapsed, setStaticsCollapsed] = useState(true)

    function handleEditClick(category: IssueCategoryForSettings) {
      onEditClick?.(category)
    }

    return (
      <Table
        noShadow
        noBorders
        noHeaderMargin
        noRowMargin
        rowHoverColor="secondary"
      >
        <thead>
          <tr>
            <th></th>
            <th>{translations.name}</th>
            <th colSpan={3}>{translations.responsibleUser}</th>
          </tr>
        </thead>

        <tbody>
          {editableCategories.map(category => (
            <tr key={category.id}>
              <td width="1px">
                <Color
                  color={temporaryEditColor[category.id] ?? category.color}
                >
                  <SettingsColorPicker
                    value={temporaryEditColor[category.id] ?? category.color}
                    onChange={onUpdateColor(category.id)}
                  />
                </Color>
              </td>

              <td>{category.name}</td>

              <td>
                <ResponsibleUser
                  to={
                    category.responsibleUser
                      ? `/users/${category.responsibleUser.id}`
                      : '#'
                  }
                >
                  <span>
                    {form.responsibleUser?.fullName ??
                      category.responsibleUser?.fullName}
                  </span>
                </ResponsibleUser>
              </td>

              <td width="1px">
                <Icon
                  icon="edit"
                  cursor="pointer"
                  color="gray6"
                  hoverColor="secondary"
                  onClick={() => handleEditClick(category)}
                />
              </td>

              <td width="1px">
                <Icon
                  icon="times"
                  cursor="pointer"
                  color="gray6"
                  hoverColor="red"
                  onClick={() => onDelete(category)}
                />
              </td>
            </tr>
          ))}
        </tbody>

        <StaticCategoriesTableHead
          collapsed={staticsCollapsed}
          onClick={() => setStaticsCollapsed(v => !v)}
        >
          <tr>
            <th>
              <Icon
                icon={staticsCollapsed ? 'chevron-down' : 'chevron-up'}
                color="gray5"
                hoverColor="secondary"
                cursor="pointer"
              />
            </th>

            <th colSpan={9999}>
              {translations.staticCategories}:
              <span>{translations.staticCategoriesInfo}</span>
            </th>
          </tr>

          {!staticsCollapsed && (
            <tr>
              <th></th>
              <th>{translations.name}</th>
              <th colSpan={3}>{translations.responsibleUser}</th>
            </tr>
          )}
        </StaticCategoriesTableHead>

        {!staticsCollapsed && (
          <tbody>
            {uneditableCategories.map(category => (
              <tr key={category.id}>
                <td width="1px">
                  <Color
                    color={temporaryEditColor[category.id] ?? category.color}
                    disabled
                  />
                </td>

                <td>{category.name}</td>

                <td>
                  <ResponsibleUser
                    to={
                      category.responsibleUser
                        ? `/users/${category.responsibleUser.id}`
                        : '#'
                    }
                  >
                    <span>
                      {form.responsibleUser?.fullName ??
                        category.responsibleUser?.fullName}
                    </span>
                  </ResponsibleUser>
                </td>

                <td width="1px"></td>

                <td width="1px">
                  <Icon
                    icon="edit"
                    cursor="pointer"
                    color="gray6"
                    hoverColor="secondary"
                    onClick={() =>
                      onSelectResponsibleUser(
                        category.id,
                        category.responsibleUser
                      )
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>
    )
  }
