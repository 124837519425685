import { PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Input, NumberInput, RegularModal } from 'components'
import React from 'react'
import { ipv4AddressValidation } from 'util/forms'
import { Field, Form } from '..'

export interface TerminalForm {
  name: string
  ip: string
  range: number
}

interface CreateTerminalPromptProps {
  initialData?: TerminalForm

  onSubmit: PromptResolve<TerminalForm | null>
}

export const CreateTerminalPrompt: React.VFC<CreateTerminalPromptProps> = ({
  initialData,

  onSubmit,
}) => {
  const translations = useTranslate({
    createTerminalArea: 'settings.create-terminal-area',
    editTerminalArea: 'settings.edit-terminal-area',

    name: 'common.name',
    ipAddress: 'terminal.ip-address',
    range: 'terminal.ip-filter-range',

    create: 'common.create-alt',
    update: 'common.update',

    validation: {
      required: 'common.required',
      ip: 'settings.validation.ip-address',
      range: 'settings.validation.terminal-range',
    },
  })

  const editing = typeof initialData !== 'undefined'

  const validateIp = (ip: string) =>
    ipv4AddressValidation(ip) ? null : translations.validation.ip

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formChangeHandler: handler,
    submitHandler: submit,
  } = useForm<TerminalForm>({
    values: initialData ?? {
      name: '',
      ip: '',
      range: 24,
    },
    validators: {
      name: val => (!!val ? null : translations.validation.required),
      ip: validateIp,
      range: val =>
        val >= 1 && val <= 32 ? null : translations.validation.range,
    },
    config: {
      initAsInvalid: !editing,
    },
  })

  return (
    <RegularModal
      title={
        editing
          ? translations.editTerminalArea
          : translations.createTerminalArea
      }
      width="400px"
      submitText={editing ? translations.update : translations.create}
      submitDisabled={!formValid}
      onCancel={() => onSubmit(null)}
      onSubmit={submit(onSubmit)}
    >
      <Form>
        <Field>
          <label>{translations.name}</label>
          <Input
            value={form.name}
            error={errors.name}
            autoFocus
            fullWidth
            onChange={handler('name')}
          />
        </Field>

        <Field>
          <label>{translations.ipAddress}</label>
          <Input
            value={form.ip}
            error={errors.ip}
            fullWidth
            onChange={handler('ip')}
          />
        </Field>

        <Field>
          <label>{translations.range}</label>
          <NumberInput
            value={form.range}
            min={1}
            max={32}
            fullWidth
            onChange={handler('range')}
          />
        </Field>
      </Form>
    </RegularModal>
  )
}
