import { IconShortcutProps } from 'components/Shortcuts'
import { useHandbookDashboardLinks } from './hooks'
import { HierarchyLinkEntry, ManualFolderNode } from './types.graphql'

export const makePlacementObject = (index: number, list: any[]) => {
  const compareLength = list.length
  if (index === 0 && index === compareLength - 1)
    return { first: true, last: true }
  if (index === 0) return { first: true, last: false, next: list[index + 1] }
  if (index === compareLength - 1)
    return { first: false, last: true, previous: list[index - 1] }
  return {
    first: false,
    last: false,
    previous: list[index - 1],
    next: list[index + 1],
  }
}

export const getParentFolderSlug = (folder: ManualFolderNode): string | null =>
  folder.slugHierarchy.length > 1
    ? folder.slugHierarchy[folder.slugHierarchy.length - 2]
    : null

export function getIconShortcutFromManualEntries(nodes: HierarchyLinkEntry[]) {
  return nodes.map(node => getIconShortcutFromManualEntryNode(node))
}

export function getIconShortcutFromManualEntryNode(
  node: HierarchyLinkEntry
): IconShortcutProps {
  const to = `/handbook/${node.folder.slug}/${node.slug}`
  const icon = node.form ? 'form-creator' : 'book'
  const text = node.name
  return {
    iconProps: {
      icon,
    },
    linkProps: {
      to,
    },
    text,
  }
}

export function useCompanyHasDashboardShortcuts() {
  const { links } = useHandbookDashboardLinks()
  return links.length > 0
}
