import { SVGIcon, SVGIconProps } from 'components/icons'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Shortcut = styled(Link)`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  width: 120px;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    border-radius: 20px;
    background: ${props => props.theme.colors.quaternary};
  }
  p {
    margin: 1em 0 0 0;
    color: ${props => props.theme.colors.text.dark};
    font-weight: 600;
  }

  &:hover {
    text-decoration: none;

    span {
      background: ${props => props.theme.colors.secondary};

      svg {
        fill: ${props => props.theme.colors.quaternary};
      }
    }
    p {
      color: ${props => props.theme.colors.secondary};
    }
  }

  ${props => props.theme.layout.mobile} {
    background: ${props => props.theme.colors.quaternary};
    border-radius: 12px;
    padding: 12px 0;
    width: auto;
    span {
      background: inherit;
      padding: 0;
    }
    p {
      margin: 6px 0 0 0;
      font-size: 0.7em;
      font-weight: 500;
    }
  }
`

export const ShotcutDisplay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 120px;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    border-radius: 20px;
    background: ${props => props.theme.colors.quaternary};
  }
  p {
    margin: 1em 0 0 0;
    color: ${props => props.theme.colors.text.dark};
    font-weight: 600;
  }

  ${props => props.theme.layout.mobile} {
    background: ${props => props.theme.colors.quaternary};
    border-radius: 12px;
    padding: 12px 0;
    width: auto;

    span {
      background: inherit;
      padding: 0;
    }
    p {
      margin: 6px 0 0 0;
      font-size: 0.7em;
      font-weight: 500;
    }
  }
`

export const ShortcutSVG = styled(SVGIcon).attrs<SVGIconProps>(props => ({
  width: props.width ?? '42px',
  ...props,
}))<SVGIconProps>``
