import { Icon, ImageViewer } from '@ur/react-components'
import { Image } from '@ur/react-components/build/ImageViewer'
import { useTranslate } from '@ur/react-hooks'
import { Button, DropArea } from 'components'
import { PostAttachment } from 'modules/forum/components/PostAttachment'
import React, { useImperativeHandle, useRef, useState } from 'react'
import { isSafari } from 'react-device-detect'
import styled, { css, useTheme } from 'styled-components'
import { ZIndexRange } from 'types/style'
import { Attachment, FileAttachment, useAttachmentHandlers } from 'util/hooks'

const Wrapper = styled.div`
  grid-area: attachments;

  padding: 2rem;
  border-top: 1px solid ${props => props.theme.colors.gray8};
  background-color: ${props => props.theme.colors.quaternary};
  border-bottom-left-radius: ${props => props.theme.sizes.defaultBorderRadius};

  header {
    h4 {
      margin: 0 0 0.5rem 0;
      color: ${props => props.theme.colors.gray5};
      font-size: 0.9rem;
      font-weight: 600;
    }
  }

  div.buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.5rem;
  }
`
const Files = styled.div`
  box-sizing: content-box;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  max-width: 100%;
  overflow-x: auto;
  padding-top: 0.5rem;

  & > * {
    flex: 0 0 auto;
  }

  ${() =>
    isSafari &&
    css`
      gap: 0;
      & > * + * {
        margin-left: 0.5rem;
      }
    `};

  p {
    margin: 0;
    font-style: italic;
    color: ${props => props.theme.colors.gray4};
  }
`

const ToolbarButton = styled(Button)`
  height: 38px;
  padding: 0 1.5rem;

  border-radius: 8px;
  font-size: 0.8rem;
  font-weight: 500;
  white-space: nowrap;

  color: white;

  i {
    margin: 0 0.5rem 0 0;
  }
`
export interface AttachmentsRef {
  resetEditedAttachments: () => void
}

interface AttachmentsProps {
  editing: boolean
  attachments: Attachment[]

  onUpdate: (add: FileAttachment[], remove: string[]) => void
}

export const Attachments = React.forwardRef<AttachmentsRef, AttachmentsProps>(
  (
    {
      editing,
      attachments,

      onUpdate,
    },
    ref
  ) => {
    const translations = useTranslate({
      attachments: 'common.attachments',
      add: 'common.add',

      noAttachments: 'common.no-attachments',
      addAttachment: 'common.add-attachment',
    })

    const fileInputRef = useRef<HTMLInputElement>(null)

    const theme = useTheme()

    const [imageViewerInitialImage, setImageViewerInitialImage] = useState('')
    const [imageViewerAttachments, setImageViewerAttachments] = useState<
      Image[]
    >([])

    const {
      allAttachments,
      resetEditedAttachments,
      handleAttachmentClick,
      handleDropAttachment,
      handleAddAttachment,
      handleRemoveAttachment,
    } = useAttachmentHandlers(
      attachments,
      setImageViewerInitialImage,
      setImageViewerAttachments,
      {
        onAdd: (_, add, remove) => onUpdate(add, remove),
        onRemove: (_, remove, add) => onUpdate(add, remove),
      }
    )

    useImperativeHandle(ref, () => ({
      resetEditedAttachments,
    }))

    return (
      <Wrapper>
        <ImageViewer
          images={imageViewerAttachments}
          zIndex={ZIndexRange.Extra}
          initialImage={imageViewerInitialImage}
          popup
          open={imageViewerAttachments.length > 0}
          noShadow
          showArrows
          background="black !important"
          imageSize="contain"
          imageBackgroundColor="black !important"
          onClose={() => setImageViewerAttachments([])}
        />

        <header>
          <h4>{translations.attachments}</h4>
        </header>

        <input
          ref={fileInputRef}
          hidden
          type="file"
          multiple
          onChange={handleAddAttachment}
        />

        <DropArea
          dragStyle={{
            backgroundColor: '#d6d6f1',
            borderBottomLeftRadius: theme.sizes.defaultBorderRadius,
          }}
          onDrop={handleDropAttachment}
        >
          <Files>
            {!allAttachments.length && <p>{translations.noAttachments}</p>}
            {allAttachments.map(attachment => (
              <PostAttachment
                key={attachment.id}
                attachment={attachment}
                deletable={editing}
                onClick={handleAttachmentClick}
                onDelete={() => handleRemoveAttachment(attachment)}
              />
            ))}
          </Files>

          {editing && (
            <div className="buttons">
              <ToolbarButton
                className="attachment"
                onClick={() => fileInputRef.current?.click()}
              >
                {/* TODO not correct icon */}
                <Icon icon="paperclip" type="regular" />
                {translations.addAttachment}
              </ToolbarButton>
            </div>
          )}
        </DropArea>
      </Wrapper>
    )
  }
)
