import { useMutation, useQuery } from '@apollo/client'
import { usePrompt } from '@ur/react-components'
import { useDebounce, useTranslate } from '@ur/react-hooks'
import {
  SortableHeader,
  Table as BaseTable,
  TableMenu,
  TableMenuItem,
} from 'components/Table'
import { UserCell, VehicleCell } from 'components/Table/cells'
import { IssueOrderFields } from 'modules/issues'
import {
  TOGGLE_ISSUE_ARCHIVED_MUTATION,
  TOGGLE_ISSUE_CLOSED_MUTATION,
} from 'modules/issues/mutations'
import { UserNode } from 'modules/users'
import React, { useCallback, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import {
  useAdmin,
  useCompany,
  useInfiniteScroll,
  useOnErrorAuto,
  usePermissions,
  useUser,
} from 'util/hooks'
import { DEFAULT_PAGE_SIZE } from 'util/pagination'
import { PERMISSIONS } from 'util/permissions'
import { ALL_ISSUES_QUERY } from '../../queries'
import { CloseIssueForm, IssuesTableFilter } from '../../types'
import {
  AllIssuesQuery,
  AllIssuesQueryVariables,
  IssueNodeForAllIssues,
  ToggleIssueArchivedMutation,
  ToggleIssueArchivedMutationVariables,
  ToggleIssueClosedMutation,
  ToggleIssueClosedMutationVariables,
} from '../../types.graphl'
import { CloseIssuePrompt } from './CloseIssueModal'
import {
  IssueCategoryCell,
  IssueDateCell,
  IssueReferenceCell,
  IssueStatusCell,
} from './components'
import { IssuesCards } from './IssuesCards'

const Table = styled(BaseTable)``
interface RowProps {
  closed: boolean
}
const Row = styled.tr<RowProps>`
  background: ${props =>
    props.closed ? `${props.theme.colors.secondary}07` : 'inherit'};
`

interface IssuesTableProps {
  filter: IssuesTableFilter

  onSort: (orderBy: IssueOrderFields) => void
}

export const IssuesTable: React.VFC<IssuesTableProps> = ({
  filter,
  onSort,
}) => {
  const translations = useTranslate({
    date: 'common.date',
    freightCarrier: 'common.freight-carrier',
    reference: ['issues.reference', { abbreviate: false }],
    employee: 'common.employee',
    category: 'common.category',
    status: 'common.status',

    open: 'common.open-alt',
    archived: 'common.archived',
    closed: 'common.closed',

    noIssues: 'issues.no-issues',
    noResults: 'common.no-results',

    editIssue: 'issues.edit-issue',
    closeIssue: 'issues.close-issue',
    reopenIssue: 'issues.reopen-issue',
    archiveIssue: 'issues.archive-issue',
    restoreIssue: 'issues.restore-issue',
    severityGrades: {
      LOW: 'common.low',
      MEDIUM: 'common.medium',
      HIGH: 'common.high',
    },
    severity: 'common.severity',
    riskAspect: 'common.risk-aspect',
  })

  const company = useCompany()
  const me = useUser()
  const admin = useAdmin()
  const history = useHistory()
  const addPrompt = usePrompt()
  const { hasPermissionsAndMe } = usePermissions()
  const onErrorAuto = useOnErrorAuto()

  const debouncedQuery = useDebounce(filter.query)
  const debouncedExcludeCategories = useDebounce(filter.excludeCategories, 500)
  const debouncedOpen = useDebounce(filter.open)
  const debouncedArchived = useDebounce(filter.archived)

  const canEdit = (user: Pick<UserNode, 'id'>) =>
    admin || hasPermissionsAndMe(user, PERMISSIONS.issues.change.issue)
  const canArchive = admin

  const [issueLoading, setIssueLoading] = useState<string | null>(null)

  const {
    data,
    loading: queryLoading,
    fetchMore,
  } = useQuery<AllIssuesQuery, AllIssuesQueryVariables>(ALL_ISSUES_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      q: debouncedQuery,
      orderBy: filter.orderBy,
      excludeCategories: debouncedExcludeCategories,
      includeSeverities: filter.severities,
      closedAtIsNull: debouncedOpen,
      archivedAtIsNull: !debouncedArchived,
      first: DEFAULT_PAGE_SIZE,
      // Admins can view all data
      user: admin ? undefined : me.id,
      company: company.id,
    },
    onError: onErrorAuto(),
  })
  const [toggleClosed, { loading: closeLoading }] = useMutation<
    ToggleIssueClosedMutation,
    ToggleIssueClosedMutationVariables
  >(TOGGLE_ISSUE_CLOSED_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['Sidebar', 'AllIssues'],
    onError: onErrorAuto(),
  })
  const [toggleArchived, { loading: archiveLoading }] = useMutation<
    ToggleIssueArchivedMutation,
    ToggleIssueArchivedMutationVariables
  >(TOGGLE_ISSUE_ARCHIVED_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['Sidebar', 'AllIssues'],
    onError: onErrorAuto(),
  })

  const issues = data?.allIssues.edges.map(edge => edge.node) ?? []
  const pageInfo = data?.allIssues.pageInfo

  const handleScrollBottom = useCallback(async () => {
    if (
      typeof data === 'undefined' ||
      typeof pageInfo === 'undefined' ||
      !pageInfo.hasNextPage
    )
      return
    try {
      await fetchMore({
        variables: {
          after: pageInfo.endCursor,
        },
      })
    } catch (e) {
      //@ts-ignore
      if (e.name === 'Invariant Violation') return
      throw e
    }
  }, [data, fetchMore, pageInfo])

  useInfiniteScroll(
    handleScrollBottom,
    200,
    !queryLoading && pageInfo?.hasNextPage
  )

  async function handleCloseIssue(issue?: IssueNodeForAllIssues) {
    if (typeof issue === 'undefined') return

    if (issue.closed) {
      executeCloseIssue(issue)()
      return
    }

    const initialData: CloseIssueForm = {
      solution: issue.solution ?? '',
      actualCost:
        typeof issue.actualCost !== 'undefined' && issue.actualCost !== null
          ? issue.actualCost
          : 0,
    }

    const { data } = await addPrompt<CloseIssueForm | null>(resolve => (
      <CloseIssuePrompt initialData={initialData} onResolve={resolve} />
    ))
    if (data === null) return

    executeCloseIssue(issue)(data)
  }
  function executeCloseIssue(issue?: IssueNodeForAllIssues | null) {
    if (!issue) return () => void 0

    return async (data?: CloseIssueForm) => {
      setIssueLoading(issue.id)

      await toggleClosed({
        variables: {
          id: issue.id,
          closed: !issue.closed,
          solution: data?.solution,
          actualCost: data?.actualCost ?? undefined,
        },
      })
      setIssueLoading(null)
    }
  }
  async function handleArchiveIssue(issue?: IssueNodeForAllIssues) {
    if (typeof issue === 'undefined') return

    setIssueLoading(issue.id)
    await toggleArchived({
      variables: {
        id: issue.id,
        archived: !issue.archived,
      },
    })
    setIssueLoading(null)
  }

  function createMenuItems(issue: IssueNodeForAllIssues) {
    const items: TableMenuItem<IssueNodeForAllIssues>[] = []

    if (canEdit(issue.user)) {
      if (!issue.closed && !issue.archived)
        items.push({
          label: translations.editIssue,
          data: issue,
          role: 'link',
          onClick: () =>
            history.push(`/issues/${issue.id}`, {
              edit: true,
            }),
        })

      items.push({
        label: !issue.closed
          ? translations.closeIssue
          : translations.reopenIssue,
        data: issue,
        onClick: handleCloseIssue,
      })
    }
    if (canArchive)
      items.push({
        label: !issue.archived
          ? translations.archiveIssue
          : translations.restoreIssue,
        data: issue,
        onClick: handleArchiveIssue,
      })

    return items
  }

  const filterActive =
    debouncedQuery !== '' ||
    debouncedArchived ||
    debouncedOpen !== undefined ||
    debouncedExcludeCategories.length !== 0
  const noDataText = filterActive
    ? translations.noResults
    : translations.noIssues
  const mutationLoading = closeLoading || archiveLoading

  return (
    <>
      {isMobile ? (
        <IssuesCards
          issues={issues}
          queryLoading={queryLoading}
          issueLoading={mutationLoading ? issueLoading : null}
          createMenuItems={createMenuItems}
          onLoadMore={handleScrollBottom}
        />
      ) : (
        <Table
          loading={queryLoading}
          loaderProps={{
            columns: !isMobile ? 6 : 1,
            rows: 10,
            expectedWidths: [162, 92, 92, 360, 460, 82],
          }}
          noData={issues.length === 0}
          noDataText={noDataText}
        >
          <thead>
            <tr>
              <SortableHeader
                baseValue="timeOfIssue"
                sortValue={filter.orderBy}
                invertDirection
                onSort={onSort}
              >
                {translations.date}
              </SortableHeader>

              <>
                <th>{translations.freightCarrier}</th>

                <th>{translations.reference({ abbreviate: false })}</th>
              </>

              <SortableHeader
                baseValue="user"
                sortValue={filter.orderBy}
                disabled={!admin}
                onSort={onSort}
              >
                {translations.employee}
              </SortableHeader>

              <SortableHeader
                baseValue="category"
                sortValue={filter.orderBy}
                onSort={onSort}
              >
                {translations.category}
              </SortableHeader>
              <SortableHeader
                baseValue="riskAspect"
                sortValue={filter.orderBy}
                onSort={onSort}
              >
                {translations.riskAspect}
              </SortableHeader>
              <SortableHeader
                baseValue="severity"
                sortValue={filter.orderBy}
                onSort={onSort}
              >
                {translations.severity}
              </SortableHeader>

              <th>{translations.status}</th>

              <th></th>
            </tr>
          </thead>

          <tbody>
            {issues.map(issue => {
              const menuItems = createMenuItems(issue)

              const licencePlates = issue.vehiclePlateNumber ? [issue.vehiclePlateNumber] : []

              return (
                <Row key={issue.id} closed={issue.closed}>
                  <IssueDateCell
                    issueId={issue.id}
                    timeOfIssue={issue.timeOfIssue}
                  />

                  <VehicleCell plates={licencePlates} />

                  <IssueReferenceCell reference={issue.referenceId} />

                  <UserCell user={issue.user} />

                  <IssueCategoryCell category={issue.category} />
                  
                  <td>{issue.riskAspect?.name ??  "-"}</td>
                  <td>{issue.severity ? translations.severityGrades[issue.severity] : "-"}</td>

                  <IssueStatusCell
                    closed={issue.closed}
                    archived={issue.archived}
                    openText={translations.open}
                    archivedText={translations.archived}
                    closedText={translations.closed}
                  />

                  <TableMenu
                    items={menuItems}
                    loading={mutationLoading && issueLoading === issue.id}
                  />
                </Row>
              )
            })}
          </tbody>
        </Table>
      )}
    </>
  )
}
