import { Icon, usePrompt } from "@ur/react-components"
import { HolidayAllowanceForm, HolidayAllowancePrompt } from "./HolidayAllowancePrompt"
import { useHolidayAllowanceMutations } from "./hooks"
import { AllHolidayAllowancesQuery, HolidayAllowanceNode } from "modules/companies/types.graphql"
import { useTranslate } from "@ur/react-hooks"
import { AddButton, Header, Section, SectionHeader } from "../common"
import { ALL_HOLIDAY_ALLOWANCES_QUERY } from "modules/companies/queries"
import { useQuery } from "@apollo/client"
import { useConfirm } from "util/hooks"
import { Table } from "components"
import { format, parseISO } from "date-fns"


export const HolidayAllowanceSettings:React.FC = () => {

  const translations = useTranslate({
    prompt: {
      delete: ['settings.prompts.delete', { name: '' }],
      deleteTitle: 'settings.prompts.holiday-allowance-delete-title',
    },
    salaryCode: 'common.salary-code',
    holidayAllowances: 'common.holiday-allowances',
    date: 'common.date',
  })

  const addPrompt = usePrompt()
  const confirm = useConfirm()
  const mutations = useHolidayAllowanceMutations()

  const { data } = useQuery<AllHolidayAllowancesQuery, {}>(
    ALL_HOLIDAY_ALLOWANCES_QUERY
  )
  const holidayAllowances = data?.allHolidayAllowances.edges?.map(edge => edge.node) ?? []

  async function handleCreateHolidayAllowance() {
    const { data } = await addPrompt<HolidayAllowanceForm | null>(resolve => (
      <HolidayAllowancePrompt
        existingHolidayAllowances={holidayAllowances}
        onSubmit={resolve}
      />
    ))
    if (data === null || data.salaryCodeId === null) return

    mutations.createHolidayAllowance({
      variables: {
        input: {
            salaryCode: data.salaryCodeId,
            day: format(data.day, 'yyyy-MM-dd')
        }
      },
    })
  }

  async function handlePatchHolidayAllowance(holidayAllowance: HolidayAllowanceNode) {
    const { data } = await addPrompt<HolidayAllowanceForm | null>(resolve => (
      <HolidayAllowancePrompt
        initialData={holidayAllowance}
        existingHolidayAllowances={holidayAllowances}
        onSubmit={resolve}
      />
    ))
    if (data === null || data.salaryCodeId === null) return

    mutations.patchHolidayAllowance({
      variables: {
        id: holidayAllowance.id,
        input: {
          salaryCode: data.salaryCodeId,
          day: format(data.day, 'yyyy-MM-dd')
        }
      },
    })
  }

  async function handleDeleteHolidayAllowance(
    holidayAllowance: HolidayAllowanceNode
  ) {
    const { data: answer } = await confirm(
      translations.prompt.delete({ name: holidayAllowance.salaryCode?.name + " " + format(parseISO(holidayAllowance.day), 'dd.MM.yyyy')}),
      translations.prompt.deleteTitle
    )
    if (!answer) return

    mutations.deleteHolidayAllowance({
      variables: {
        id: holidayAllowance.id,
      },
    })
  }

  return (
    <Section>
      <Header>
        <SectionHeader>{translations.holidayAllowances}</SectionHeader>
        <AddButton adding={false} onClick={handleCreateHolidayAllowance} />
      </Header>

      <Table
        noShadow
        noBorders
        noHeaderMargin
        noRowMargin
        rowHoverColor="secondary"
      >
        <thead>
          <tr>
            <th>{translations.date}</th>
            <th colSpan={2}>{translations.salaryCode}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {holidayAllowances.map(holidayAllowance => (
            <tr key={holidayAllowance.id}>
              <td>{format(parseISO(holidayAllowance.day), 'dd.MM.yyyy')}</td>
              <td>{holidayAllowance.salaryCode.name ?? '-'}</td>

              <td width="1px">
                <Icon
                  icon="edit"
                  cursor="pointer"
                  color="gray6"
                  hoverColor="secondary"
                  onClick={() => handlePatchHolidayAllowance(holidayAllowance)}
                />
              </td>

              <td width="1px">
                <Icon
                  icon="times"
                  cursor="pointer"
                  color="gray6"
                  hoverColor="red"
                  onClick={() => handleDeleteHolidayAllowance(holidayAllowance)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      
    </Section>
  )
}