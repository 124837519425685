import { useTranslate } from '@ur/react-hooks'
import { useCallback } from 'react'
import { countries, CountryCode } from 'util/countries'
import { format } from 'util/date-fns'
import { useCountryCodeTranslations } from 'util/hooks/useCountryCodeTranslations'
import { VehicleFridgeTypeChoices } from '..'
import { VehicleDetailInput } from '../types'
import { VehicleDetailNode } from '../types.graphql'

export function useVehicleDetailFormatter(vehicle: VehicleDetailNode) {
  const translations = useTranslate({
    yes: 'common.yes',
    no: 'common.no',
  })

  const { getCountryCodeTranslations } = useCountryCodeTranslations()

  const formatValue = useCallback(
    function (field: VehicleDetailInput) {
      const value = vehicle[field.key]
      switch (field.type) {
        case 'date':
          return value && format(new Date(value as string), 'PP')
        case 'boolean':
          return value ? translations.yes : translations.no
        case 'country':
          const countryCode = countries[value as CountryCode]
          if (!countryCode) return '-'
          return getCountryCodeTranslations(value as CountryCode)
        case 'radio':
          return (
            field?.options?.find(option => option.value === value)?.label ??
            value
          )
        case 'select':
          return (
            field?.options?.find(option => option.value === value)?.label ??
            value
          )
        default:
          return value
      }
    },
    [vehicle, translations, getCountryCodeTranslations]
  )

  return {
    formatValue,
  }
}

export function useVehicleDetailFields() {
  const translations = useTranslate({
    brand: 'vehicles.brand',
    model: 'vehicles.model',
    color: 'common.color',
    vin: 'vehicles.vin',
    engineMaximumSpeed: 'vehicles.maximum-speed',
    enginePower: 'vehicles.power',
    engineVolume: 'vehicles.volume',
    engineType: 'vehicles.fuel',
    euroClass: 'vehicles.euro-class',
    netWeight: 'vehicles.net-weight',
    payloadWeight: 'vehicles.maximum-payload',
    allowedTotalWeight: 'vehicles.maxium-total-weight',
    allowedTruckWeight: 'vehicles.maximum-truck-weight',
    allowedCouplingLoad: 'vehicles.maximum-coupling-load',
    allowedSemiTrailerWeight: 'vehicles.maximum-semi-trailer-weight',
    allowedFrontAxleLoad: 'vehicles.max-front-axle-load',
    allowedBackAxleLoad: 'vehicles.max-back-axle-load',
    vehicleType: 'vehicles.vehicle-type',
    vehicleTechnicalType: 'vehicles.vehicle-technical-type',
    registrationDate: 'vehicles.first-registered',
    registeredCountry: 'vehicles.registered-country',
    soldDate: 'vehicles.sold-date',
    serviceAgreement: 'vehicles.service-agreement',
    tiresStuddedFront: 'vehicles.studded-front-tire',
    tiresStuddedBack: 'vehicles.studded-drive-tire',
    tiresFrontDimension: 'vehicles.tires-dimension-front',
    tiresDriveDimension: 'vehicles.tires-dimension-drive',
    tiresBogieDimension: 'vehicles.tires-dimension-bogie',
    kmPerMonth: 'vehicles.km-per-month',
    kmPerYear: 'vehicles.km-per-year',
    stationingLocation: 'vehicles.stationing-location',
    licenseNumber: 'vehicles.license-number',
    tollTagNumber: 'vehicles.toll-tag-number',
    ferryCardNumber: 'vehicles.ferry-card-number',
    dieselCardNumber: 'vehicles.diesel-card-number',
    pinCode: 'vehicles.pin-code',
    coolFreezeHeat: 'vehicles.cool-freeze-heat',
    height: 'vehicles.height',
    sideOpening: 'vehicles.side-opening',
    hasCrane: 'vehicles.crane',
    craneType: 'vehicles.crane-type',
    backLifter: 'vehicles.back-lifter',
    vbgCoupling: 'vehicles.vbg-coupling',
    palletSpaces: 'vehicles.pallet-spaces',
    goodsInsuranceType: 'vehicles.goods-insurance-type',
    extensionType: 'vehicles.extension-type',
    innerHeight: 'vehicles.inner-height',
    innerWidth: 'vehicles.inner-width',
    innerLength: 'vehicles.inner-length',
    loadingHeight: 'vehicles.loading-height',
    fridge: 'vehicles.fridge',
    fridgeType: 'vehicles.fridge-type',
    meatRack: 'vehicles.meat-rack',
    previousPeriodicControl: 'vehicles.previous-periodic-control',
    nextPeriodicControl: 'vehicles.next-periodic-control',
  })

  const generalFields: VehicleDetailInput[] = [
    { key: 'vin', label: translations.vin, readOnly: true },
    {
      key: 'brand',
      label: translations.brand,
    },
    { key: 'model', label: translations.model },
    { key: 'color', label: translations.color },
    { key: 'vehicleType', label: translations.vehicleType },
    { key: 'vehicleTechnicalType', label: translations.vehicleTechnicalType },
  ]

  const engineFields: VehicleDetailInput[] = [
    {
      key: 'engineMaximumSpeed',
      label: translations.engineMaximumSpeed,
      unit: 'km/t',
      type: 'number',
    },
    {
      key: 'enginePower',
      label: translations.enginePower,
      unit: 'KW',
      type: 'number',
    },
    { key: 'engineEuroClass', label: translations.euroClass },
    {
      key: 'engineVolume',
      label: translations.engineVolume,
      unit: 'cm3',
      type: 'number',
    },
    { key: 'engineType', label: translations.engineType },
  ]

  const weightFields: VehicleDetailInput[] = [
    { key: 'netWeight', label: translations.netWeight, unit: 'kg' },
    { key: 'payloadWeight', label: translations.payloadWeight, unit: 'kg' },
    {
      key: 'allowedTotalWeight',
      label: translations.allowedTotalWeight,
    },
    {
      key: 'allowedTruckWeight',
      label: translations.allowedTruckWeight,
    },
    {
      key: 'allowedCouplingLoad',
      label: translations.allowedCouplingLoad,
    },
    {
      key: 'allowedSemiTrailerWeight',
      label: translations.allowedSemiTrailerWeight,
    },
    { key: 'allowedFrontAxleLoad', label: translations.allowedFrontAxleLoad },
    { key: 'allowedBackAxleLoad', label: translations.allowedBackAxleLoad },
  ].map(field => Object.assign(field, { type: 'number', unit: 'kg' }))

  const usageFields: VehicleDetailInput[] = [
    { key: 'coolFreezeHeat', label: translations.coolFreezeHeat },
    { key: 'height', label: translations.height, unit: 'cm', type: 'number' },
    {
      key: 'innerHeight',
      label: translations.innerHeight,
      unit: 'm',
      type: 'number',
    },
    {
      key: 'innerLength',
      label: translations.innerLength,
      unit: 'm',
      type: 'number',
    },
    {
      key: 'innerWidth',
      label: translations.innerWidth,
      unit: 'm',
      type: 'number',
    },
    {
      key: 'loadingHeight',
      label: translations.loadingHeight,
      unit: 'm',
      type: 'number',
    },
    { key: 'sideOpening', label: translations.sideOpening, type: 'boolean' },
    { key: 'hasCrane', label: translations.hasCrane, type: 'boolean' },
    { key: 'craneType', label: translations.craneType },
    { key: 'backLifter', label: translations.backLifter },
    { key: 'vbgCoupling', label: translations.vbgCoupling },
    { key: 'palletSpaces', label: translations.palletSpaces },
    { key: 'goodsInsuranceType', label: translations.goodsInsuranceType },
    { key: 'extensionType', label: translations.extensionType },
    { key: 'meatRack', label: translations.meatRack, type: 'boolean' },
    {
      key: 'fridge',
      label: translations.fridge,
      type: 'boolean',
    },
    {
      key: 'fridgeType',
      label: translations.fridgeType,
      type: 'radio',
      options: VehicleFridgeTypeChoices,
      dependencies: {
        requireFields: ['fridge'],
      },
    },
  ]

  const administrativeFields: VehicleDetailInput[] = [
    {
      key: 'registrationDate',
      label: translations.registrationDate,
      type: 'date',
    },
    { key: 'country', label: translations.registeredCountry, type: 'country' },
    { key: 'stationingLocation', label: translations.stationingLocation },
    { key: 'soldDate', label: translations.soldDate, type: 'date' },
    { key: 'licenseNumber', label: translations.licenseNumber },
    { key: 'tollTagNumber', label: translations.tollTagNumber },
    { key: 'ferryCardNumber', label: translations.ferryCardNumber },
    { key: 'dieselCardNumber', label: translations.dieselCardNumber },
    { key: 'pinCode', label: translations.pinCode, type: 'number' },
    { key: 'previousPeriodicControl', label: translations.previousPeriodicControl, type: 'date', readOnly: true},
    { key: 'nextPeriodicControl', label: translations.nextPeriodicControl, type: 'date', readOnly: true},
  ]

  const serviceFields: VehicleDetailInput[] = [
    { key: 'serviceAgreement', label: translations.serviceAgreement },
    {
      key: 'tiresStuddedFront',
      label: translations.tiresStuddedFront,
      type: 'boolean',
    },
    {
      key: 'tiresStuddedBack',
      label: translations.tiresStuddedBack,
      type: 'boolean',
    },
    { key: 'tiresFrontDimension', label: translations.tiresFrontDimension },
    { key: 'tiresDriveDimension', label: translations.tiresDriveDimension },
    { key: 'tiresBogieDimension', label: translations.tiresBogieDimension },
    { key: 'kmPerMonth', label: translations.kmPerMonth },
    { key: 'kmPerYear', label: translations.kmPerYear },
  ]

  return {
    generalFields,
    engineFields,
    weightFields,
    usageFields,
    administrativeFields,
    serviceFields,
  }
}
