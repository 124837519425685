import { useQuery } from '@apollo/client'
import { useTranslate } from '@ur/react-hooks'
import { SyncButton } from 'components/Button'
import { ModuleTitle } from 'components/Title/ModuleTitle'
import { formatISO } from 'date-fns'
import { ALL_USER_EXPORTS_QUERY } from 'modules/exports/queries'
import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useInfiniteScroll } from 'util/hooks'
import { useBreadcrumbs } from 'util/hooks/useBreadcrumbs'
import { DEFAULT_PAGE_SIZE } from 'util/pagination'
import {
  AllUserExportsQuery,
  AllUserExportsQueryVariables,
} from '../types.graphql'
import { UserExportsTable } from './UserExportsTable'

const UserExportsWrapper = styled.div`
  ${props => props.theme.layout.default};
`

const TitleWrapper = styled.span`
  display: flex;
  > * + * {
    margin-left: 1rem;
  }
  align-items: center;
  justify-content: space-between;
`

interface UserExportsProps {}
export const UserExports: React.VFC<UserExportsProps> = () => {
  const translations = useTranslate({
    userExports: 'exports.user-exports',
  })
  const [lastVisit, setLastVisit] = useState<string>()

  const { setOverrides, overrides } = useBreadcrumbs()

  const { data, loading, refetch, fetchMore } = useQuery<
    AllUserExportsQuery,
    AllUserExportsQueryVariables
  >(ALL_USER_EXPORTS_QUERY, {
    variables: {
      first: DEFAULT_PAGE_SIZE,
      orderBy: '-createdAt',
    },
    onCompleted() {
      setLastVisit(
        lastVisit ?? (localStorage.getItem('userExportsLastVisit') || '')
      )
      localStorage.setItem('userExportsLastVisit', formatISO(new Date()))
    },
    // We need this for the sync animation to trigger
    notifyOnNetworkStatusChange: true,
  })

  const userExports = data?.allUserExports.edges.map(edge => edge.node) ?? []
  const pageInfo = data?.allUserExports.pageInfo

  const handleScrollBottom = useCallback(async () => {
    if (typeof data === 'undefined' || typeof pageInfo === 'undefined') return
    if (!pageInfo.hasNextPage) return
    if (!pageInfo.endCursor) return
    try {
      await fetchMore({
        variables: {
          first: DEFAULT_PAGE_SIZE,
          orderBy: '-createdAt',
          after: pageInfo.endCursor,
        },
      })
    } catch (e) {
      console.error(e)
    }
  }, [data, fetchMore, pageInfo])

  useInfiniteScroll(handleScrollBottom, 300, !loading && pageInfo?.hasNextPage)

  useEffect(() => {
    if (overrides['user-exports']) return
    setOverrides({ 'user-exports': translations.userExports })
  }, [overrides, setOverrides, translations.userExports])

  function dateifyLastVisit() {
    const date = new Date(lastVisit ?? '')
    return isNaN(date.getTime()) ? new Date() : date
  }

  return (
    <UserExportsWrapper>
      <TitleWrapper>
        <ModuleTitle>{translations.userExports}</ModuleTitle>
        <SyncButton background="white" reset={loading} refetchQuery={refetch} />
      </TitleWrapper>

      <UserExportsTable
        userExports={userExports}
        loading={loading}
        lastVisit={dateifyLastVisit()}
      />
    </UserExportsWrapper>
  )
}
