import { BaseProps } from '@ur/react-components/build/types/props'
import React, { useState } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div``

interface DropAreaProps extends BaseProps {
  disabled?: boolean

  dragStyle?: React.CSSProperties
  itemsValidator?: (items: DataTransferItemList) => boolean

  onDrop: (event: React.DragEvent<HTMLDivElement>) => void
}

export const DropArea: React.FC<DropAreaProps> = ({
  children,
  className,
  id,

  disabled = false,

  dragStyle = {},
  itemsValidator,

  onDrop,
}) => {
  const [dragCount, setDragCount] = useState(0)

  const validator =
    itemsValidator ??
    (items => Array.from(items).every(item => item.kind === 'file'))

  const unlessDisabled = (callback: (...args: any[]) => any) =>
    disabled ? void 0 : callback

  function handleDragEnter(evt: React.DragEvent<HTMLDivElement>) {
    if (!validator(evt.dataTransfer.items)) return

    evt.dataTransfer.effectAllowed = 'copy'
    setDragCount(v => v + 1)
  }
  function handleDragOver(evt: React.DragEvent<HTMLDivElement>) {
    evt.preventDefault()
    evt.dataTransfer.dropEffect = 'copy'
  }
  function handleDragLeave() {
    setDragCount(v => v - 1)
  }
  function handleDrop(evt: React.DragEvent<HTMLDivElement>) {
    evt.preventDefault()
    if (!validator(evt.dataTransfer.items)) return

    onDrop(evt)
    setDragCount(0)
  }

  return (
    <Wrapper
      className={className}
      id={id}
      style={!!dragCount ? dragStyle : undefined}
      onDragEnter={unlessDisabled(handleDragEnter)}
      onDragOver={unlessDisabled(handleDragOver)}
      onDragLeave={unlessDisabled(handleDragLeave)}
      onDrop={unlessDisabled(handleDrop)}
    >
      {children}
    </Wrapper>
  )
}
