import { PromptResolve, useToast } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Checkbox, RegularModal, TextArea } from 'components'
import { ScaniaApiKeyNode } from 'modules/companies/types.graphql'
import React from 'react'
import styled from 'styled-components'
import { useCompany } from 'util/hooks'
import { Field, Form } from '..'
import { useIntegrationsMutations } from '../../util/mutations.hooks'

const ActiveWrapper = styled.div`
  margin-left: 1rem;
`

export interface ScaniaIntegrationForm {
  tachographKey: string
  fmsAccessKey: string
  fmsSecretKey: string
  active: boolean
}

interface EditScaniaIntegrationModalProps {
  integration: ScaniaApiKeyNode | null
  index: number

  onSubmit: PromptResolve<ScaniaIntegrationForm | null>
}

export const EditScaniaIntegrationModal: React.VFC<EditScaniaIntegrationModalProps> =
  ({
    integration,
    index,

    onSubmit,
  }) => {
    const translations = useTranslate({
      createIntegration: 'settings.create-scania-integration',
      editIntegration: ['settings.edit-scania-integration-n', { n: 0 }],

      tachographKey: 'settings.tachograph-key',
      fmsAccessKey: 'settings.fms-access-key',
      fmsSecretKey: 'settings.fms-secret-key',
      active: 'common.active',

      save: 'common.save',
      create: 'common.create-alt',

      validation: {
        required: 'common.required',
        tachographError: 'integrations.error-in-tachograph-key',
        FMSError: 'integrations.error-in-FMS-key',
      },
    })

    const mutations = useIntegrationsMutations()
    const company = useCompany()
    const addToast = useToast()
    const editing = integration !== null

    const validateNonEmpty = (value: string) =>
      !!value ? null : translations.validation.required

    const {
      formValues: form,
      formErrors: errors,
      formValid,
      formChangeHandler: handler,
      submitHandler: submit,
    } = useForm<ScaniaIntegrationForm>({
      values: {
        tachographKey: integration?.tachographKey ?? '',
        fmsAccessKey: integration?.fmsAccessKey ?? '',
        fmsSecretKey: integration?.fmsSecretKey ?? '',
        active: integration?.active ?? true,
      },
      validators: {
        tachographKey: validateNonEmpty,
        fmsAccessKey: validateNonEmpty,
        fmsSecretKey: validateNonEmpty,
      },
      config: {
        initAsInvalid: !editing,
      },
    })

    async function handleSubmit() {
      const response = await mutations.validate.scania({
        variables: {
          input: { company: company.id, ...form },
        },
      })

      if (response.data?.validateScaniaApiKey.ok) submit(onSubmit)()
      else {
        if (response.data?.validateScaniaApiKey.error === 'TACHOGRAPH') {
          addToast('error', translations.validation.tachographError)
        } else {
          addToast('error', translations.validation.FMSError)
        }
      }
    }

    return (
      <RegularModal
        title={
          editing
            ? translations.editIntegration({ n: index + 1 })
            : translations.createIntegration
        }
        width="600px"
        submitText={editing ? translations.save : translations.create}
        submitDisabled={!formValid}
        loading={mutations.loading}
        footerLeft={
          <ActiveWrapper>
            <Checkbox
              checked={form.active}
              label={translations.active}
              boxWidth="32px"
              boxHeight="32px"
              onChange={handler('active')}
            />
          </ActiveWrapper>
        }
        onCancel={() => onSubmit(null)}
        onSubmit={() => handleSubmit()}
      >
        <Form>
          <Field>
            <label>{translations.tachographKey}</label>
            <TextArea
              value={form.tachographKey}
              error={errors.tachographKey}
              fullWidth
              autoFocus
              resize="none"
              onChange={handler('tachographKey')}
            />
          </Field>

          <Field>
            <label>{translations.fmsAccessKey}</label>
            <TextArea
              value={form.fmsAccessKey}
              error={errors.fmsAccessKey}
              fullWidth
              resize="none"
              onChange={handler('fmsAccessKey')}
            />
          </Field>

          <Field>
            <label>{translations.fmsSecretKey}</label>
            <TextArea
              value={form.fmsSecretKey}
              error={errors.fmsSecretKey}
              fullWidth
              resize="none"
              onChange={handler('fmsSecretKey')}
            />
          </Field>
        </Form>
      </RegularModal>
    )
  }
