import { useQuery } from '@apollo/client'
import { SelectOption } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { endOfMonth, formatISO, parseISO, startOfMonth } from 'date-fns'
import { Time, time } from 'lib/time'
import { TimesheetsModuleConfig } from 'modules/companies'
import { Module, ModuleOptionsValue } from 'modules/companies/consts'
import { getUsersHighestPrioritySalaryWorkTime } from 'modules/companies/util'
import { getSalaryDatesForMonth, YearMonthExportData } from 'modules/exports'
import { ExportFormat, ExportOption, ExportType } from 'modules/exports/consts'
import { UserUserTypesQuery } from 'modules/users'
import { USER_USER_TYPES_QUERY } from 'modules/users/queries'
import { IdVariable } from 'types/graphql'
import { getFullMinutes, isInvalidDate } from 'util/time'
import { notBlankValidation, notEmptyArrayValidation } from 'util/forms'
import { useAdmin, useCompany, useModuleConfig, useUser } from 'util/hooks'
import { useTimeSheetExportMutations } from './mutations.hooks'
import { useAbsenceExportMutations } from './mutations.hooks'
import {
  ExportAbsensesForm,
  TimeEntryFormValues,
  TimesheetExportForm,
} from './types'
import { TimeEntryNode, TimesheetsReportSalaryWorkTimeNode } from './types.graphql'
import { format } from 'util/date-fns'

export function useTimeEntryForm(entry: Omit<TimeEntryNode, 'company'> | null, salaryWorkTime: TimesheetsReportSalaryWorkTimeNode, initialUser?: string) {
  const translations = useTranslate({
    noComment: 'common.no-comment',
    error: {
      startBeforeEnd: 'timesheets.error.start-before-end',
      required: 'common.required',
      pauseRequired: ['timesheets.error.pause-required', { n: 0}],
    },
  })
  const user = useUser()
  const { getModuleOptionStatus } =
    useModuleConfig<TimesheetsModuleConfig>(Module.TIMESHEETS)
  const overtimeCustomChoice = getModuleOptionStatus(
    ModuleOptionsValue.TIMESHEETS_OVERTIME_OPTIONS
  )
  const departmentMandatory = getModuleOptionStatus(
    ModuleOptionsValue.TIMESHEETS_DEPARTMENT_SELECT_REQUIRED
  )
  const admin = useAdmin()

  // Default to 8 hours
  const dayOvertimeLimit = salaryWorkTime?.dailyWorkLimit ?? 8 * 60 * 60

  const validateChronology = (start: Date, end: Date) =>
    start.getTime() <= end.getTime() ? null : translations.error.startBeforeEnd

  const validateNotBlank = (comment: string) =>
    notBlankValidation(comment) ? null : translations.error.required

  const departmentValidator = (department: string | null) =>
    departmentMandatory ? validateNotBlank(department ?? '') : null

  const validateCustomOvertime = (
    salaryCodeId: string | null,
    start: Date,
    end: Date
  ) => {
    if (
      !overtimeCustomChoice ||
      (end.getTime() - start.getTime()) / 1000 <= dayOvertimeLimit ||
      !!salaryCodeId ||
      salaryWorkTime.usePeriodOvertime
    ) {
      return null
    }
    return translations.error.required
  }

  const form = useForm<TimeEntryFormValues>({
    values: {
      userId: entry ? entry.user.id : admin ? initialUser ?? user.id : user.id,
      start: entry ? parseISO(entry.datetimeStart) : time('08:00').toDate(new Date()),
      end: entry ? parseISO(entry.datetimeEnd) : time('16:00').toDate(new Date()),
      project: entry?.project?.id ?? null,
      department: entry?.department?.id ?? salaryWorkTime?.defaultDepartment?.id ?? null,
      comment: entry?.comment ?? '',
      salaryCodeId: entry?.salaryCode?.id ?? null,
      diet: entry?.diet ?? false,
      pause: entry ? time(entry.pause ?? "00:30").toDate(new Date()) : time(new Time(0, 0, salaryWorkTime.pauseDuration)).toDate(new Date()),
    },
    validators: {
      start: (start, { end }) => validateChronology(start, end),
      end: (end, { start }) => validateChronology(start, end),
      comment: validateNotBlank,
      salaryCodeId: (salaryCodeId, { start, end }) =>
        validateCustomOvertime(salaryCodeId, start, end),
      pause: (pause, { start, end }) => {
        const workingMs = (end.getTime() - start.getTime())
        const totalWorkingSeconds = workingMs / 1000
        const minimumPause = salaryWorkTime.pauseThreshold < totalWorkingSeconds ? salaryWorkTime.pauseDuration / 60 : 0
        const pauseInMinutes = getFullMinutes(pause)
        if (pauseInMinutes >= minimumPause) {
          return null
        }
        return translations.error.pauseRequired({ n: minimumPause })
      },
      department: departmentValidator,
    },
    config: {
      initAsInvalid: true,
    },
  })

  return form
}

export function useTimeEntryModule() {
  const {
    moduleConfig: module,
    moduleActive: active,
    moduleOptions: options,
    getModuleOptionStatus,
  } = useModuleConfig<TimesheetsModuleConfig>(Module.TIMESHEETS)
  const overtimeActive = getModuleOptionStatus(
    ModuleOptionsValue.TIMESHEETS_OVERTIME_OPTIONS
  )

  const overtimeExportActive = getModuleOptionStatus(
    ModuleOptionsValue.TIMESHEETS_DAY_INTERVAL_OVERTIME
  )

  return {
    module,
    active,
    options,
    overtimeActive,
    overtimeExportActive,
  }
}

export function useMassTimesheetExportForm(onCompleted?: () => void) {
  const me = useUser()
  const company = useCompany()

  const { exportTimesheetsMass, loading: formLoading } =
    useTimeSheetExportMutations(onCompleted)

  const translations = useTranslate({
    massTimesheets: 'exports.month-export',
    salaryExport: 'exports.salary-export',
    selectUsersError: 'activities.errors.select-multiple-users',
  })

  const {
    formValues: form,
    formErrors: errors,
    submitHandler: submit,
    updateForm,
  } = useForm<TimesheetExportForm>({
    values: {
      exportType: ExportType.MASS_ENTRY_MONTH,
      exportOption: ExportOption.DOWNLOAD,
      exportFormat: ExportFormat.PDF,
      user: null,
      users: [],
      project: null,
      month: new Date(),
      dateStart: new Date(),
      dateEnd: new Date(),
      useDateRange: false,
      orderByUserType: false,
      includeAbsences: false,
    },
    validators: {
      users: value =>
        notEmptyArrayValidation(value) ? translations.selectUsersError : null,
    },
    config: {
      storage: {
        storeFormState: true,
        // TODO: Find why this alters from other forms displaying error on mount
        // retrieveFormStateOnMount: true,
        excludeFields: ['users'],
        storeFormStateName: 'Export-timesheets-mass-form',
        transformValues: {
          month: value =>
            typeof value === 'string'
              ? isInvalidDate(new Date(value), new Date())
              : undefined,
        },
      },
    },
  })

  const exportConfig: YearMonthExportData = {
    companyId: company.id,
    month: form.month.getMonth(),
    year: form.month.getFullYear(),
    userId: form.user ?? '',
    mailId: me.id,
  }

  const { data } = useQuery<UserUserTypesQuery, IdVariable>(
    USER_USER_TYPES_QUERY,
    {
      variables: { id: me.id ?? '' },
    }
  )
  const user = data?.user

  const { moduleConfig } = useModuleConfig(Module.TIMESHEETS)

  const salaryWorkTime = getUsersHighestPrioritySalaryWorkTime(
    user,
    moduleConfig
  )

  const { year, month } = exportConfig
  const salaryDates = getSalaryDatesForMonth(
    salaryWorkTime?.salaryDateStart ?? 27,
    salaryWorkTime?.salaryDateEnd ?? 26,
    year,
    month
  )

  const exportTypes: SelectOption<ExportType>[] = [
    {
      value: ExportType.MASS_ENTRY_MONTH,
      label: translations.massTimesheets,
    },
    {
      value: ExportType.MASS_ENTRY_SALARY,
      label: `${translations.salaryExport} (${format(
        salaryDates[0],
        'dd.MMMM'
      )}-${format(salaryDates[1], 'dd.MMMM')})`,
    },
  ]

  function handler<K extends keyof TimesheetExportForm>(key: K) {
    return (value: TimesheetExportForm[K] | null) => {
      if (value === null) return
      updateForm({ [key]: value })
    }
  }

  async function handleExport(values: TimesheetExportForm) {
    const date = form.useDateRange ? form.dateStart : startOfMonth(values.month)
    const [dateStart, dateEnd] = getDateInterval(date, form.dateEnd)
    const orderBy = values.orderByUserType ? 'user_types' : 'last_name'
    exportTimesheetsMass({
      variables: {
        exportType: values.exportType,
        exportFormat: values.exportFormat,
        userMail: me.id,
        company: company.id,
        dateStart: dateStart,
        dateEnd: dateEnd,
        user: me.id,
        users: form.users,
        orderBy: orderBy,
        includeAbsences: form.includeAbsences,
        exportOption: values.exportOption,
        project: values.project,
      },
    })
  }
  function getDateInterval(startDate: Date, endDate?: Date) {
    if (form.exportType === ExportType.MASS_ENTRY_SALARY) {
      return [formatISO(salaryDates[0]), formatISO(salaryDates[1])]
    }
    if (form.useDateRange) {
      const dateEnd = endDate || new Date()
      return [formatISO(startDate), formatISO(dateEnd)]
    }
    return [
      formatISO(startOfMonth(startDate)),
      formatISO(endOfMonth(startDate)),
    ]
  }

  return {
    form,
    errors,
    loading: formLoading,
    exportTypes,
    handler,
    updateForm,
    submit,
    handleExport,
  }
}

export function useAbsenceReportForm(onCompleted?: () => void) {
  const me = useUser()
  const company = useCompany()

  const translations = useTranslate({
    selectUsersError: 'activities.errors.select-multiple-users',
  })

  const { exportAbsencesMass, loading: mailLoading } =
    useAbsenceExportMutations(onCompleted)

  const {
    formValues: form,
    formErrors: errors,
    submitHandler: submit,
    updateForm,
  } = useForm<ExportAbsensesForm>({
    values: {
      month: new Date(),
      dateStart: new Date(),
      dateEnd: new Date(),
      exportType: ExportType.ABSENCES,
      exportOption: ExportOption.SAVE,
      exportFormat: ExportFormat.PDF,
      userId: null,
      users: [],
      orderByUserType: false,
      useDateRange: false,
    },
    validators: {
      users: value =>
        notEmptyArrayValidation(value) ? translations.selectUsersError : null,
    },
    config: {
      storage: {
        storeFormState: true,
        // TODO: Find why this alters from other forms displaying error on mount
        // retrieveFormStateOnMount: true,
        storeFormStateName: 'Export-absences-mass-form',
        excludeFields: ['users'],
        transformValues: {
          month: value =>
            typeof value === 'string'
              ? isInvalidDate(new Date(value), new Date())
              : undefined,
        },
      },
    },
  })

  function handler<K extends keyof ExportAbsensesForm>(key: K) {
    return (value: ExportAbsensesForm[K] | null) => {
      if (value === null) return
      updateForm({ [key]: value })
    }
  }

  async function handleExport(values: ExportAbsensesForm) {
    const date = form.useDateRange ? form.dateStart : startOfMonth(values.month)
    const orderBy = values.orderByUserType ? 'user_types' : 'last_name'
    const [dateStart, dateEnd] = getDateInterval(date, form.dateEnd)
    exportAbsencesMass({
      variables: {
        exportType: values.exportType,
        exportFormat: values.exportFormat,
        userId: me.id,
        companyId: company.id,
        dateStart: dateStart,
        dateEnd: dateEnd,
        users: form.users,
        orderBy: orderBy,
        exportOption: values.exportOption,
      },
    })
  }

  function getDateInterval(startDate: Date, endDate?: Date) {
    if (form.useDateRange) {
      const dateEnd = endDate || new Date()
      return [formatISO(startDate), formatISO(dateEnd)]
    }
    return [
      formatISO(startOfMonth(startDate)),
      formatISO(endOfMonth(startDate)),
    ]
  }

  return {
    form,
    errors,
    loading: mailLoading,
    mailLoading,

    handler,
    updateForm,
    submit,
    handleExport,
  }
}
