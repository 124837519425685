import { Icon, Loader, usePrompt } from '@ur/react-components'
import { useDebounce, useTranslate } from '@ur/react-hooks'
import {
  Button,
  CenteredLoader,
  Input,
  TreeView as BaseTreeView,
} from 'components'
import { Card } from 'components/Card'
import { TreeBranch } from 'components/TreeView/TreeView.types'
import { PermissionsRequired } from 'containers/permission-containers'
import each from 'lodash/each'
import groupBy from 'lodash/groupBy'
import isEqual from 'lodash/isEqual'
import keyBy from 'lodash/keyBy'
import omit from 'lodash/omit'
import uniqBy from 'lodash/uniqBy'
import {
  AllManualFoldersQuery,
  AllManualFoldersQueryVariables,
  ALL_MANUAL_FOLDERS_QUERY,
  EditFolderForm,
  HandbookElementType,
  HierarchyEntry,
  HierarchyFolder,
  IdAndSlug,
  ManualFolderBySlugQuery,
  ManualFolderContentsQuery,
  ManualFolderContentsQueryVariables,
  ManualFolderNode,
  MANUAL_FOLDER_BY_SLUG_QUERY,
  MANUAL_FOLDER_CONTENTS_QUERY,
  TreeBranchExtended,
} from 'modules/handbook'
import {
  BranchMenu,
  EditFolderPrompt,
  EntrySearchResults,
} from 'modules/handbook/components'
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import { isMobile, isMobileOnly } from 'react-device-detect'
import { Link, useParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import { useConfirm, useOnErrorAuto } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { useHandbookMutations } from '../hooks'
import { useBreadcrumbs } from 'util/hooks/useBreadcrumbs/useBreadcrumbs'

const Wrapper = styled.div`
  ${props => props.theme.layout.default};

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 1rem;

    h1 {
      display: flex;

      div {
        margin-left: 8px;
      }
    }
  }

  ${props => props.theme.media.mobile} {
    header {
      flex-direction: column;
      align-items: flex-start;

      h1 {
        margin-top: 0;
        font-size: 1.5em;
      }
    }
  }
`
const Emergency = styled.div`
  display: flex;

  a {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 192px;
    height: 4.5rem;
    padding: 10px 22px;

    border-radius: ${props => props.theme.sizes.defaultBorderRadius};

    & > div {
      display: flex;
      flex-direction: column;
      padding-top: 0.6rem;
    }
    h3 {
      margin: 0;
      line-height: 1.8rem;
      font-size: 2.2rem;
      font-weight: 600;
      color: white;
    }
    span {
      color: ${props => props.theme.colors.gray8};
      font-weight: 600;
    }
    i {
      color: white;
    }

    &:hover {
      text-decoration: none;
    }
    &:active {
      box-shadow: inset 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
    }

    & + a {
      margin-left: 0.5rem;
    }
    &[href$='110']  {
      background-color: ${props => props.theme.colors.red};

      &:hover {
        background-color: ${props => props.theme.colors.red}a2;
      }
    }
    &[href$='112']  {
      background-color: ${props => props.theme.colors.primary};

      &:hover {
        background-color: ${props => props.theme.colors.primary}a2;
      }
    }
    &[href$='113']  {
      background-color: ${props => props.theme.colors.secondary};

      &:hover {
        background-color: ${props => props.theme.colors.secondary}a2;
      }
    }
  }

  ${props => props.theme.media.mobile} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;

    a {
      align-items: center;
      width: auto;
      height: auto;
      padding: 12px;

      & > div {
        padding-top: 0;
      }
      h3 {
        line-height: auto;
        font-size: 1.6rem;
      }
      span {
        display: none;
      }
      i {
        font-size: 1.8rem;
      }
    }
  }
`
const Controls = styled(Card)`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  gap: 1rem;

  margin-bottom: 1rem;
  padding: 1rem;
`
const Trees = styled.div`
  & > div + div {
    margin-top: 1rem;
  }
`
const TreeView = styled(BaseTreeView)`
  .--tree-view-branch {
    .--tree-view-branch-content {
      position: relative;

      .info h4 {
        a {
          color: ${props => props.theme.colors.primary};
        }
      }

      &:hover {
        & > .branch-menu {
          display: flex;
        }
      }
    }

    &:last-child {
      & > .--tree-view-branch-content {
        border-bottom-left-radius: ${props =>
          props.theme.sizes.defaultBorderRadius};
        border-bottom-right-radius: ${props =>
          props.theme.sizes.defaultBorderRadius};
      }
    }
  }
`

interface HandbookParams {
  folderSlug?: string
}

export const Handbook: React.VFC = () => {
  const translations = useTranslate({
    driverHandbook: 'common.drivers-handbook',
    search: 'common.search',
    searchInHandbook: 'handbook.search',
    newRootFolder: 'handbook.new-root-folder',

    fire: 'common.fire',
    police: 'common.police',
    ambulance: 'common.ambulance',

    prompt: {
      deleteFolder: 'handbook.prompts.delete-folder',
      deleteEntry: 'handbook.prompts.delete-entry',
      deleteFolderTitle: 'handbook.prompts.delete-folder-title',
      deleteEntryTitle: 'handbook.prompts.delete-entry-title',
    },
  })

  const loadedBranches = useRef<string[]>([])
  const searchInputRef = useRef<HTMLInputElement>(null)

  const confirm = useConfirm()
  const addPrompt = usePrompt()
  const theme = useTheme()
  const onErrorAuto = useOnErrorAuto()
  const { folderSlug } = useParams<HandbookParams>()
  const { setInserts, setOverride } = useBreadcrumbs()

  const [query, setQuery] = useState('')

  const debouncedQuery = useDebounce(query, 350)
  useEffect(() => {
    if (debouncedQuery !== query) return
    loadedBranches.current = []
  }, [debouncedQuery, query])

  const [branches, setBranches] = useState<TreeBranchExtended[]>([])
  const [initialFetchCompleted, setInitialFetchCompleted] = useState(false)
  const [initialBranchUpdateCompleted, setinitialBranchUpdateCompleted] =
    useState(false)
  const [initialBranchIds, setInitialBranchIds] = useState<string[]>([])
  const [expandQueue, setExpandQueue] = useState<{ id: string }[]>([])
  const [overrideOrder, setOverrideOrder] = useState<{ [id: string]: number }>(
    {}
  )
  const [addedFolders, setAddedFolders] = useState<TreeBranchExtended[]>([])
  const [editedFolders, setEditedFolders] = useState<
    Pick<ManualFolderNode, 'id' | 'name' | 'description'>[]
  >([])
  const [deletedElements, setDeletedElements] = useState<string[]>([])

  /* Only fetch root folders when search field is empty */
  const rootOnly = debouncedQuery.length === 0 ? true : undefined

  const [fetchFolders] = useLazyQuery<ManualFolderBySlugQuery>(
    MANUAL_FOLDER_BY_SLUG_QUERY,
    {
      onCompleted: data => {
        const hierarchy = data?.manualFolder?.slugHierarchyWithNames
        setInitialBranchIds(hierarchy.map(slugNameNode => slugNameNode.id))
        setExpandQueue(hierarchy)
        setInserts([])
        setOverride(folderSlug ?? '', null)
      },
    }
  )

  const { loading: rootLoading, refetch } = useQuery<
    AllManualFoldersQuery,
    AllManualFoldersQueryVariables
  >(ALL_MANUAL_FOLDERS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      q: debouncedQuery,
      isRoot: rootOnly,
    },
    onCompleted(data) {
      if (typeof data === 'undefined') return

      const folders = data.allManualFolders.edges.map(edge => edge.node)
      updateHierarchy(null, folders)
      if (typeof folderSlug !== 'undefined' && folderSlug !== '') {
        fetchFolders({ variables: { folderSlug: folderSlug ?? '' } })
      } else {
        setinitialBranchUpdateCompleted(true)
      }
    },
    onError: onErrorAuto(),
  })
  const [fetchFolder, { loading: folderLoading }] = useLazyQuery<
    ManualFolderContentsQuery,
    ManualFolderContentsQueryVariables
  >(MANUAL_FOLDER_CONTENTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onCompleted(data) {
      if (typeof data === 'undefined') return

      const folders = data.manualFolder.childrenFolders.edges.map(
        edge => edge.node
      )
      const entries = data.manualFolder.entries.edges.map(edge => edge.node)
      updateHierarchy(
        {
          id: data.manualFolder.id,
          slug: data.manualFolder.slug,
          __typename: data.manualFolder.__typename,
        },
        [...folders, ...entries]
      )
    },
    onError: onErrorAuto(),
  })

  const mutations = useHandbookMutations()

  const handleShift = useCallback(
    async (id: string, up: boolean) => {
      const result = await mutations.shift({
        variables: {
          id,
          up,
        },
      })

      if (!result.data?.shiftHandbookElementOrder.ok) return
      const { currentElement, siblingElement } =
        result.data.shiftHandbookElementOrder

      setOverrideOrder(v => ({
        ...v,
        [currentElement.id]: currentElement.order,
        [(siblingElement ?? currentElement).id]: (
          siblingElement ?? currentElement
        ).order,
      }))
    },
    [mutations]
  )
  const handleDelete = useCallback(
    async (id: string, type: HandbookElementType) => {
      const message =
        type === 'folder'
          ? translations.prompt.deleteFolder
          : translations.prompt.deleteEntry
      const title =
        type === 'folder'
          ? translations.prompt.deleteFolderTitle
          : translations.prompt.deleteEntryTitle

      const { data: answer } = await confirm(message, title)
      if (!answer) return

      const mutation =
        type === 'folder' ? mutations.folder.delete : mutations.entry.delete
      const result = await mutation({
        variables: {
          id,
        },
        refetchQueries: ['AllManualFolders', 'HandbookSearchEntries'],
      })
      if (!result.data) return

      setDeletedElements(ids => [...ids, id])
    },
    [
      confirm,
      mutations.entry.delete,
      mutations.folder.delete,
      translations.prompt.deleteEntry,
      translations.prompt.deleteEntryTitle,
      translations.prompt.deleteFolder,
      translations.prompt.deleteFolderTitle,
    ]
  )

  function reduceTreeBranches(
    openTreeBranches: (TreeBranchExtended | TreeBranch)[],
    setInitOpen?: boolean
  ) {
    const openBranchIds = openTreeBranches.map(branch => branch.id)
    return (
      treeBranchStructure: TreeBranchExtended[],
      currentBranch: TreeBranchExtended
    ) =>
      openBranchIds.includes(currentBranch.id)
        ? [
            ...treeBranchStructure,
            {
              ...currentBranch,
              initOpen:
                typeof setInitOpen === 'undefined' ? false : setInitOpen,
            },
          ]
        : [...treeBranchStructure, currentBranch]
  }

  const handleAddFolder = useCallback(
    async (parent: IdAndSlug | null) => {
      const { data } = await addPrompt<EditFolderForm | null>(resolve => (
        <EditFolderPrompt onResolve={resolve} />
      ))
      if (data === null) return null

      const result = await mutations.folder.create({
        variables: {
          input: {
            parentFolder: parent?.id ?? null,
            ...data,
          },
        },
      })
      if (!result?.data) return null

      const folder = result.data.createManualFolder.manualFolder
      return folder
    },
    [addPrompt, mutations.folder]
  )
  const handleEditFolder = useCallback(
    async (initial: Pick<ManualFolderNode, 'id' | 'name' | 'description'>) => {
      const { data } = await addPrompt<EditFolderForm | null>(resolve => (
        <EditFolderPrompt initial={initial} onResolve={resolve} />
      ))
      if (data === null) return null

      const result = await mutations.folder.patch({
        variables: {
          id: initial.id,
          input: {
            name: data.name,
            description: data.description,
          },
        },
      })
      if (!result?.data) return null

      const folder = result.data.patchManualFolder.manualFolder
      return folder
    },
    [addPrompt, mutations.folder]
  )

  const handleExpandBranch = useCallback(
    (branch: TreeBranch | null) => {
      if (branch === null) return

      fetchFolder({
        variables: {
          id: branch.id,
          orderBy: 'order',
        },
      })
    },
    [fetchFolder]
  )

  const createTreeBranch = useCallback<
    (
      item: HierarchyFolder | HierarchyEntry,
      parent: IdAndSlug | null
    ) => TreeBranchExtended
  >(
    (item, parent) => {
      const isRootFolder =
        item.__typename === 'ManualFolderNode' && parent === null

      const title: TreeBranch['title'] =
        isRootFolder || 'hasChildren' in item ? (
          item.name
        ) : (
          <Link to={`/handbook/${parent!.slug!}/${item.slug}`}>
            {item.name}
          </Link>
        )

      const icon: TreeBranch['icon'] = isRootFolder
        ? 'folder'
        : 'hasChildren' in item
        ? item.hasChildren
          ? open => (open ? 'chevron-down' : 'chevron-right')
          : 'minus'
        : 'info-circle'

      const iconProps: TreeBranch['iconProps'] = isRootFolder
        ? {
            type: 'light',
            size: '1.6rem',
            color: 'secondary',
            fixedWidth: true,
          }
        : 'hasChildren' in item
        ? {
            type: 'regular',
            size: '1rem',
            color: 'secondary',
            fixedWidth: true,
          }
        : {
            type: 'light',
            size: '1.6rem',
            color: 'primary',
            fixedWidth: true,
          }

      function addFolder(
        promise: Promise<HierarchyFolder | null>,
        parent: IdAndSlug | null
      ) {
        promise.then(folder => {
          if (!folder) return
          const branch = createTreeBranch(folder, parent)
          branch.initOpen = true

          setAddedFolders(branches => [...branches, branch])
        })
      }
      function editFolder(
        promise: Promise<Pick<
          ManualFolderNode,
          'id' | 'name' | 'description'
        > | null>
      ) {
        promise.then(folder => {
          if (!folder) return

          setEditedFolders(folders => [
            ...folders.filter(fold => fold.id !== folder.id),
            folder,
          ])
        })
      }

      const renderExtra: TreeBranch['renderExtra'] = isMobile
        ? undefined
        : (_, idx, total) =>
            isRootFolder || 'hasChildren' in item ? (
              <BranchMenu
                id={item.id}
                slug={item.slug}
                type="folder"
                noUpArrow={idx === 0}
                noDownArrow={idx === total - 1}
                onAddFolder={() => {
                  const parent = {
                    id: item.id,
                    slug: item.slug,
                    __typename: item.__typename,
                  }
                  addFolder(handleAddFolder(parent), parent)
                }}
                onEditFolder={() =>
                  editFolder(
                    handleEditFolder({
                      id: item.id,
                      name: item.name,
                      description: item.description,
                    })
                  )
                }
                onShift={handleShift}
                onDelete={handleDelete}
              />
            ) : (
              <BranchMenu
                id={item.id}
                slug={item.slug}
                folderSlug={item.folder?.slug}
                type="entry"
                noUpArrow={idx === 0}
                noDownArrow={idx === total - 1}
                onShift={handleShift}
                onDelete={handleDelete}
              />
            )

      const handleCollapse = (branch: TreeBranch) => {
        setBranches(treeBranches =>
          treeBranches.reduce<TreeBranchExtended[]>(
            reduceTreeBranches([branch], false),
            [] as TreeBranchExtended[]
          )
        )
      }

      return {
        id: item.id,
        parent: parent?.id ?? null,
        order: item.order,

        title,
        subtitle: item.description,
        icon,
        iconProps,

        background: isRootFolder ? 'white' : undefined,
        borderRadius: isRootFolder
          ? theme.sizes.defaultBorderRadius
          : undefined,

        renderExtra,
        expandable: isRootFolder || ('hasChildren' in item && item.hasChildren),
        noTopBorder: isRootFolder,

        onExpand:
          isRootFolder || 'hasChildren' in item
            ? handleExpandBranch
            : undefined,
        onCollapse: handleCollapse,
      }
    },
    [
      handleAddFolder,
      handleDelete,
      handleEditFolder,
      handleExpandBranch,
      handleShift,
      theme.sizes.defaultBorderRadius,
    ]
  )

  const updateHierarchy = useCallback(
    (parent: IdAndSlug | null, items: (HierarchyFolder | HierarchyEntry)[]) => {
      const isRoot = parent === null

      if (!isRoot) loadedBranches.current.push(parent!.id!)

      let newBranches: TreeBranchExtended[] = []

      for (let i = 0; i < items.length; i++)
        newBranches.push(createTreeBranch(items[i], parent))

      newBranches = newBranches.sort((a, b) => a.order - b.order)

      isRoot
        ? setBranches(_ => newBranches)
        : setBranches(branches => [...branches, ...newBranches])
    },
    [createTreeBranch]
  )

  const handleAddRootFolder = useCallback(async () => {
    const folder = await handleAddFolder(null)
    if (!folder) return

    const branch = createTreeBranch(folder, null)
    setBranches(branches => [...branches, branch])
  }, [createTreeBranch, handleAddFolder])

  const prevBranches = useRef<TreeBranch[] | null>(null)
  const tree = useMemo(() => {
    const updatedBranches = uniqBy([...branches, ...addedFolders], 'id')
      .reduce<TreeBranchExtended[]>(
        (acc, branch) =>
          deletedElements.includes(branch.id)
            ? acc
            : [
                ...acc,
                {
                  ...branch,
                  ...(() => {
                    const edited = editedFolders.find(
                      folder => folder.id === branch.id
                    )
                    if (!edited) return {}

                    return {
                      title: edited.name,
                      subtitle: edited.description,
                    }
                  })(),
                  order: overrideOrder[branch.id] ?? branch.order,
                },
              ],
        []
      )
      .sort((a, b) => a.order - b.order)

    if (
      prevBranches.current !== null &&
      isEqual(prevBranches.current, updatedBranches)
    )
      return prevBranches.current
    prevBranches.current = updatedBranches

    const grouped = groupBy(updatedBranches, 'parent')
    const items = keyBy(updatedBranches, 'id')

    each(omit(grouped, 'null'), (branches, parent) => {
      if (!!items[parent]) items[parent].branches = branches
    })

    return grouped['null'] ?? []
  }, [addedFolders, branches, deletedElements, editedFolders, overrideOrder])

  function handleSearchClear() {
    searchInputRef.current?.focus()
    setQuery('')
  }

  useEffect(() => {
    if (expandQueue.length === 0) return
    setInitialFetchCompleted(false)
    const branch = branches.find(branch => branch.id === expandQueue[0].id)

    if (branch) {
      if (expandQueue.length === 1) setInitialFetchCompleted(true)
      setExpandQueue(expandQueue.slice(1))
      handleExpandBranch(branch ?? null)
    }
  }, [branches, expandQueue, handleExpandBranch])

  useEffect(() => {
    if (!initialFetchCompleted || initialBranchUpdateCompleted) return
    const initialBranches = branches.filter(branch =>
      initialBranchIds.includes(branch.id)
    )

    setinitialBranchUpdateCompleted(true)
    setBranches(treeBranches =>
      treeBranches.reduce<TreeBranchExtended[]>(
        reduceTreeBranches(initialBranches, true),
        [] as TreeBranchExtended[]
      )
    )
  }, [
    branches,
    folderSlug,
    initialBranchIds,
    initialBranchUpdateCompleted,
    initialFetchCompleted,
    setInserts,
  ])

  useEffect(() => {
    refetch()
  }, [refetch])

  useLayoutEffect(() => {
    setInserts([])
    setOverride(folderSlug ?? '', null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Wrapper>
      <header>
        <h1>
          {translations.driverHandbook}
          {folderLoading && (
            <div>
              <Loader.Spinner size={28} />
            </div>
          )}
        </h1>

        <Emergency>
          <a href="tel:110">
            <div>
              <h3>110</h3>
              <span>{translations.fire}</span>
            </div>
            {/* TODO not correct icon */}
            <Icon icon="fire" type="light" size="2.4rem" />
          </a>
          <a href="tel:112">
            <div>
              <h3>112</h3>
              <span>{translations.police}</span>
            </div>
            {/* TODO not correct icon */}
            <Icon icon="user-cowboy" type="light" size="2.2rem" />
          </a>
          <a href="tel:113">
            <div>
              <h3>113</h3>
              <span>{translations.ambulance}</span>
            </div>
            {/* TODO not correct icon */}
            <Icon icon="star-of-life" type="light" size="2.2rem" />
          </a>
        </Emergency>
      </header>

      <Controls>
        <Input
          ref={searchInputRef}
          value={query}
          type="search"
          iconRightProps={
            query.length > 0
              ? {
                  icon: 'times-circle',
                  type: 'light',
                  hoverColor: 'red',
                  cursor: 'pointer',
                  onClick: handleSearchClear,
                  size: '20px',
                }
              : undefined
          }
          placeholder={
            isMobileOnly ? translations.search : translations.searchInHandbook
          }
          onChange={setQuery}
        />

        <PermissionsRequired
          permissions={PERMISSIONS.handbook.add.manualfolder}
        >
          {isMobileOnly ? (
            <Button
              width="auto"
              padding="0 0.8rem"
              onClick={handleAddRootFolder}
            >
              <Icon icon="plus" />
            </Button>
          ) : (
            <Button
              iconLeftProps={{ icon: 'plus' }}
              onClick={handleAddRootFolder}
            >
              {translations.newRootFolder}
            </Button>
          )}
        </PermissionsRequired>
      </Controls>

      <Trees>
        {(rootLoading || !initialBranchUpdateCompleted) && <CenteredLoader />}
        {initialBranchUpdateCompleted &&
          tree.map((branch, index) => (
            <TreeView
              key={branch.id}
              branches={[branch]}
              indent={72}
              indentOffset={-1}
              levelBackgrounds={['white', 'quaternary']}
              overrideIndex={index}
              overrideTotalBranches={tree.length}
            />
          ))}
        {query.length > 1 && (
          <EntrySearchResults query={debouncedQuery} onDelete={handleDelete} />
        )}
      </Trees>
    </Wrapper>
  )
}
