import { Calendar, CalendarNode } from 'components'
import {
  eachDayOfInterval,
  isSameDay,
  isWithinInterval,
  parseISO,
} from 'date-fns'
import { MouseEvent, useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import { AbsenceNode } from '..'
import {
  AbsenceCalendarPopup,
  OuterPopupWrapper as PopupWrapper,
} from './AbsenceCalendarPopup'

export interface AbsencesCalendarProps {
  startDate: Date
  endDate: Date

  absences: AbsenceNode[]
}

export const AbsencesCalendar: React.VFC<AbsencesCalendarProps> = ({
  startDate,
  endDate,
  absences,
}) => {
  const [popupAbsence, setPopupAbsence] = useState<AbsenceNode | null>(null)
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] =
    useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement)

  const calendarDates = eachDayOfInterval({ start: startDate, end: endDate })

  const handleClick = (
    evt: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
    absence: AbsenceNode
  ) => {
    setPopupAbsence(absence)
    setReferenceElement(evt.currentTarget)
  }

  const calendarData = calendarDates.map(date => {
    return {
      date: date,
      nodes: absences.reduce<CalendarNode[]>((acc, cur) => {
        const isInRange =
          isWithinInterval(date, {
            start: parseISO(cur.datetimeStart),
            end: parseISO(cur.datetimeEnd),
          }) ||
          isSameDay(date, parseISO(cur.datetimeStart)) ||
          isSameDay(date, parseISO(cur.datetimeEnd))
        if (isInRange)
          acc.push({
            id: cur.id,
            text: cur.user.fullName,
            color: cur.absenceType.color,
            onClick: (evt: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) =>
              handleClick(evt, cur),
          })
        return acc
      }, []),
    }
  })

  useEffect(() => {
    if (popupAbsence)
      setPopupAbsence(
        absences.find(absence => popupAbsence.id === absence.id) ?? null
      )
  }, [absences, popupAbsence])

  return (
    <>
      <Calendar data={calendarData} />

      {popupAbsence && (
        <PopupWrapper
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <AbsenceCalendarPopup
            absence={popupAbsence}
            onClickOutside={() => setPopupAbsence(null)}
          />
        </PopupWrapper>
      )}
    </>
  )
}
