import { CalendarBlockWrapper } from './components'
import { MouseEventHandler } from 'react'

interface CalendarBlockProps {
  background: string
  starting: boolean
  ending: boolean
  text: string
  onClick?: MouseEventHandler<HTMLDivElement>
}

export const CalendarBlock: React.VFC<CalendarBlockProps> = ({
  background,
  starting,
  ending,
  text,
  onClick,
}) => {
  return (
    <CalendarBlockWrapper
      background={background}
      new={starting}
      ending={ending}
      onClick={onClick}
    >
      {text}
    </CalendarBlockWrapper>
  )
}
