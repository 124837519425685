import { useTranslate } from '@ur/react-hooks'
import { Button, FAIcon, SVGIcon } from 'components'
import {
  ALL_SALARY_MODULE_CONFIGS_QUERY,
} from 'modules/companies'
import { SalaryModuleConfigQuery } from 'modules/companies/types.graphql'
import React from 'react'
import { useQuery } from '@apollo/client'
import { isMobileOnly } from 'react-device-detect'

import { useOnErrorAuto } from 'util/hooks'
import {
  Header,
  InfoBox,
  Section,
  SectionHeader,
  SettingsWrapper,
} from '../components/common'
import { useSalaryMutations } from '../util/mutations.hooks'
import styled from 'styled-components'
import { useSalaryModuleTranslations } from '../hooks'
import { useHistory } from 'react-router'
import { DepartmentSettings } from '../components/SalarySettings/DepartmentSettings'
import { SalaryCodeSettings } from '../components/SalarySettings/SalaryCodeSettings'
import { SalaryWorkTimesTables } from '../components/SalarySettings/SalaryWorkTimesTables'
import { HolidayAllowanceSettings } from '../components/SalarySettings/HolidayAllowanceSettings'
const Create = styled.div`
  position: relative;
`



interface SalarySettingsProps {}

export const SalarySettings: React.VFC<SalarySettingsProps> = () => {
  const translations = useTranslate({
    info: 'settings.info.salary',
    salarySettings: 'settings.salary-and-work-time',

    defaultSalaryCode: 'settings.default-salary-code',

    module: 'common.module',
    name: 'common.name',
    dailyLimit: 'settings.daily-work-limit-short',
    weeklyLimit: 'settings.period-work-limit-short',
    pauseDuration: 'settings.pause-duration-short',
    pauseThreshold: 'settings.pause-threshold-short',
    salaryPeriod: 'settings.period',
    prompt: {
      deleteTitle: 'settings.prompts.salary-setting-delete-title',
      delete: ['settings.prompts.delete', { name: '' }],
    },

    priority: 'common.priority',

    delete: 'common.delete',
    edit: 'common.edit',
    add: 'common.add',
    departments: 'common.departments'
  })
  const history = useHistory()
  const onErrorAuto = useOnErrorAuto()
  const mutations = useSalaryMutations()

  const { data, loading: queryLoading } = useQuery<SalaryModuleConfigQuery>(
    ALL_SALARY_MODULE_CONFIGS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      onError: onErrorAuto(),
    }
  )

  const moduleConfigs =
    data?.allSalaryModuleConfigs.edges
      .map(edge => edge.node)
      .filter(node => node.active) ?? []

  const getModuleTranslation = useSalaryModuleTranslations()


  const isLoading = queryLoading || mutations.loading

  return (
    <SettingsWrapper grid={{ flow: 'row' }}>
      <InfoBox initCollapsed={isMobileOnly}>{translations.info}</InfoBox>
      {moduleConfigs.map(config => {
          const salaryWorkTimes = config.salaryWorkTimes
          const icon = config.name === "Activities" ? "truck" : config.name === "Terminal" ? "house" : "clock"
          const iconWidth = config.name === "Timesheets" ? "20px" : "28px"
          return (
      <Section>
        <Header>
          <SectionHeader loading={isLoading}>
            <SVGIcon icon={icon} fill="black" width={iconWidth} />
            {getModuleTranslation(config.name)}
          </SectionHeader>
          <Create>
            {isMobileOnly ? (
              <Button
                height="32px"
                padding="0 1rem"
                onClick={() => history.push('/settings/salary/create', {
                  salaryModule: config.name,
                })}
              >
                <FAIcon icon="plus" size="0.8rem" />
              </Button>
            ) : (
              <Button
                height="36px"
                padding='0 2rem'
                iconLeftProps={{
                  icon: 'plus',
                }}
                onClick={() => history.push('/settings/salary/create', {
                  salaryModule: config.name,
                })}
              >
                {translations.add}
              </Button>
            )}
          </Create>
        </Header>
          <SalaryWorkTimesTables salaryWorkTimes={salaryWorkTimes} />
      </Section>
       )})}
      <SalaryCodeSettings />
      <DepartmentSettings />
      <HolidayAllowanceSettings />
    </SettingsWrapper>
  )
}
