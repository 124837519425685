import { Loader, useToast } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Button } from 'components'
import { Form } from 'components/Form'
import React from 'react'
import { useMutation } from '@apollo/client'
import { useOnErrorAuto } from 'util/hooks'
import { ChangePasswordMutation, ChangePasswordMutationVariables } from '..'
import { CHANGE_PASSWORD_MUTATION } from '../mutations'
import { Field, Input } from './components'
import { usePasswordValidation } from 'util/hooks/usePasswordValidation'

interface ChangePasswordProps {
  refresh?: boolean
}

export const ChangePasswordCard: React.FC<ChangePasswordProps> = ({
  refresh,
}) => {
  const translations = useTranslate({
    currentPassword: 'common.current-password',
    newPassword: 'common.new-password',
    newPasswordAgain: 'common.new-password-again',

    updatePassword: 'common.update-password',

    validation: {
      required: 'common.required',
      weakPassword: 'users.too-weak-password',
      passwordUnequal: 'errors.passwords-not-equal',
    },
    success: {
      password: 'settings.toasts.password-change-success',
    },
    error: {
      password: 'settings.errors.password-change',
    },
  })

  const addToast = useToast()
  const { validatePassword } = usePasswordValidation()

  const validatePasswordAgain = (value: string, other: string) =>
    value === other ? null : translations.validation.passwordUnequal

  const {
    formValues: form,
    formValid,
    formErrors: errors,
    formChangeHandler: handler,
    resetForm,
    submitHandler,
  } = useForm({
    values: {
      currentPassword: '',
      newPassword: '',
      newPasswordAgain: '',
    },
    validators: {
      currentPassword: val => (!!val ? null : translations.validation.required),
      newPassword: validatePassword,
      newPasswordAgain: (value, { newPassword }) =>
        validatePasswordAgain(value, newPassword),
    },
    config: {
      initAsInvalid: true,
    },
  })

  const onErrorAuto = useOnErrorAuto()

  const [changePassword, { loading: changePasswordLoading }] = useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(CHANGE_PASSWORD_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['Me'],
    onCompleted(data) {
      if (refresh) window.location.reload()
      if (data?.changePassword.ok)
        addToast('success', translations.success.password)
      else addToast('error', translations.error.password)
    },
    onError: onErrorAuto(translations.error.password),
  })

  async function submitPassword(values: typeof form) {
    try {
      await changePassword({
        variables: {
          password: values.currentPassword,
          newPassword: values.newPassword,
        },
      })
      resetForm()
    } catch {}
  }

  return (
    <>
      <Form preventDefault onSubmit={submitHandler(submitPassword)}>
        <Field>
          <label htmlFor="currentPassword">
            {translations.currentPassword}
          </label>
          <Input
            type="password"
            value={form.currentPassword}
            error={errors.currentPassword}
            onChange={handler('currentPassword')}
          />
        </Field>

        <Field>
          <label htmlFor="newPassword">{translations.newPassword}</label>
          <Input
            type="password"
            value={form.newPassword}
            error={form.newPassword === '' ? null : errors.newPassword}
            onChange={handler('newPassword', {
              includeValidation: ['newPasswordAgain'],
            })}
          />
        </Field>

        <Field>
          <label htmlFor="newPasswordAgain">
            {translations.newPasswordAgain}
          </label>
          <Input
            type="password"
            value={form.newPasswordAgain}
            error={
              form.newPasswordAgain === '' ? null : errors.newPasswordAgain
            }
            onChange={handler('newPasswordAgain', {
              includeValidation: ['newPassword'],
            })}
          />
        </Field>

        <Field alignRight>
          {changePasswordLoading ? (
            <Loader.Spinner size={42} />
          ) : (
            <Button type="submit" disabled={!formValid}>
              {translations.updatePassword}
            </Button>
          )}
        </Field>
      </Form>
    </>
  )
}
