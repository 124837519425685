import { Thumbnail } from '@ur/react-components'
import { Circle } from 'components/Circle'
import { UserNode } from 'modules/users'
import React from 'react'
import styled from 'styled-components'
import { CSSCursor } from '@ur/react-components/build/types/css'

interface WrapperProps {
  size: string
}
const Wrapper = styled.div<WrapperProps>`
  width: ${props => props.size};
  height: ${props => props.size};
  border-radius: 50%;
  background: white;
`

export type ThumbnailUser = Pick<
  UserNode,
  'id' | 'fullName' | 'profilePicture' | 'initials'
>

interface UserThumbOrInitialsProps {
  className?: string
  user: ThumbnailUser

  size?: number
  cursor?: CSSCursor
  fontSize?: string
  fontWeight?: string | number
  color?: string
  backgroundColor?: string
  title?: string

  onClick?: (target?: React.MouseEvent) => void
}

export const UserThumbOrInitials: React.FC<UserThumbOrInitialsProps> = ({
  className,

  user,

  size = 35,
  cursor = 'default',
  fontSize,
  fontWeight,
  color,
  backgroundColor = 'primary',
  title,

  onClick,
}) => {
  const pxSize = `${size}px`

  return (
    <Wrapper className={className} size={pxSize} title={title}>
      {user.profilePicture ? (
        <Thumbnail
          size={pxSize}
          src={user.profilePicture}
          cursor={cursor}
          onClick={onClick}
        />
      ) : (
        <Circle
          color={backgroundColor}
          size={size}
          fontSize={fontSize}
          fontWeight={fontWeight}
          textColor={color}
          cursor={cursor}
          onClick={onClick}
        >
          {user.initials}
        </Circle>
      )}
    </Wrapper>
  )
}

export default UserThumbOrInitials
