import en from './translations/en'

const saveLanguageKey = 'firmadok-language-preference'

// Some languages are more specified versions of ones we support.
// For these, we normalise the language string into one we support.
const languageNormalization: { [locale: string]: string } = {
  'en-GB': 'en',
  'en-US': 'en',
  'nb-NO': 'nb',
  'nn-NO': 'nb',
  ru: 'ru',
  lv: 'lv',
  ro: 'ro',
  pl: 'pl',
}

export function setPreferredLanguage(language: string) {
  localStorage.setItem(saveLanguageKey, language)
}

export function getPreferredLanguage() {
  const saved = localStorage.getItem(saveLanguageKey)

  if (!saved) {
    return (
      languageNormalization[window.navigator.language] ||
      window.navigator.language
    )
  }

  return saved
}

export type BaseLanguage = typeof en
export type AllowedLocale = 'en' | 'nb' | 'ru' | 'lv' | 'ro' | 'pl'
