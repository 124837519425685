import { useTranslate } from '@ur/react-hooks'
import { UserNode } from 'modules/users'
import React from 'react'
import styled from 'styled-components'
import { format } from 'util/date-fns'
import { unlessLoadingDots } from 'util/loading'
import { ActivityArea } from './ActivityArea'

const Wrapper = styled(ActivityArea)`
  display: flex;
  height: 120px;

  span.unknown {
    color: ${props => props.theme.colors.red};
  }

  ${props => props.theme.media.mobile} {
    height: 62px;
  }
`
const Info = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 0 2rem;

  & + div {
    border-left: 1px solid ${props => props.theme.colors.gray8};
  }

  font-weight: 600;

  span  {
    color: ${props => props.theme.colors.gray5};
  }
  p {
    margin: 0;
    font-size: 1.6em;
  }

  ${props => props.theme.media.mobile} {
    padding: 0 1rem;

    span {
      font-size: 0.7rem;
    }
    p {
      font-size: 1rem;
    }
  }
`

interface MainInfoProps {
  user: Pick<UserNode, 'id' | 'fullName'> | null
  date: Date
  registrationNumbers: string[]
  loading: boolean
}

export const MainInfo: React.VFC<MainInfoProps> = ({
  user,
  date,
  registrationNumbers,
  loading,
}) => {
  const translations = useTranslate({
    registrationNumber: 'activities.regnr',
    date: 'common.date',
    driver: 'activities.driver',

    unknown: 'common.unknown',
  })

  const unlessLoading = unlessLoadingDots(loading, translations.unknown)

  return (
    <Wrapper area="main-info">
      <Info>
        <span>{translations.registrationNumber}</span>
        {registrationNumbers.map(registrationNumber => (
          <p key={registrationNumber}>{registrationNumber}</p>
        ))}
      </Info>

      <Info>
        <span>{translations.date}</span>
        <p>{format(date, 'dd.MM.yyyy')}</p>
      </Info>

      <Info>
        <span>{translations.driver}</span>
        <p>{unlessLoading(user?.fullName)}</p>
      </Info>
    </Wrapper>
  )
}
