import { UserNode } from 'modules/users'
import { IdVariable, RelayEdges, RelayEdgesWithPageInfo } from 'types/graphql'
import { CompanyNode } from 'modules/companies'
import { SeverityCategory } from './types'
import { ExportOption } from 'modules/exports'

/*  NODES */

export interface InfringementNode {
  id: string
  company: CompanyNode
  user: Pick<
    UserNode,
    'id' | 'fullName' | 'phoneNumber' | 'email' | 'userTypes' | 'profilePicture'
  >
  infringementCategory: InfringementCategoryNode
  createdAt: string

  periodStartDate: string
  periodEndDate: string

  infringementTimestamp: string

  reasonValue: string

  archivedAt: string
  archivedBasis: string
  archivedBy: Pick<UserNode, 'id' | 'fullName'>
}

export interface InfringementCategoryNode {
  id: string
  identifier: InfringementCategory
  severity: SeverityCategory
  value: number
  lowerLimit: string
  upperLimit: string
  description: string
  legalBases: RelayEdges<InfringementSeverityTypeLegalBasisNode>
}

export interface InfringementSeverityTypeLegalBasisNode {
  id: string
  article: string
  description: string
}

export interface UserInfringementCountNode {
  id: string
  fullName: string
  phoneNumber: string
  infringementsTotalValue: number
  infringementCount: number
}

export type UserInfringementNodeForAllUserInfringements = Pick<
  UserNode,
  'id' | 'fullName' | 'phoneNumber' | 'email' | 'userTypes' | 'profilePicture'
> & {
  infringementsTotalValue: number
  infringementsCount: number
  minorInfringementCount: number
  seriousInfringementCount: number
  verySeriousInfringementCount: number
  mostSeriousInfringementCount: number
}

export type InfringementNodeForInfringement = Pick<
  InfringementNode,
  | 'id'
  | 'createdAt'
  | 'reasonValue'
  | 'archivedAt'
  | 'archivedBasis'
  | 'periodStartDate'
  | 'periodEndDate'
  | 'infringementTimestamp'
> & {
  infringementCategory: InfringementCategoryNode
  user: Pick<UserNode, 'id' | 'fullName' | 'phoneNumber'>
}

export type InfringementNodeForAllInfringements = Pick<
  InfringementNode,
  'id' | 'archivedAt' | 'infringementTimestamp'
> & {
  user: Pick<UserNode, 'id' | 'fullName' | 'phoneNumber'>
  infringementCategory: Pick<
    InfringementCategoryNode,
    'id' | 'identifier' | 'severity' | 'description'
  >
}

/* QUERIES */

export interface InfringementQuery {
  infringement: InfringementNodeForInfringement
}

export interface AllInfringementsQuery {
  allInfringements: RelayEdgesWithPageInfo<InfringementNodeForAllInfringements>
}
export interface AllInfringementsQueryVariables {
  q?: string
  orderBy?: string
  first: number
  after?: string
  users?: string[]
  archivedAtIsNull?: boolean
  excludeSeverities?: string[]
  infringementTimestamp_Lt: Date
  infringementTimestamp_Gte: Date
  excludeCategories?: string[]
}

export interface AllUserInfringementsQuery {
  allUsersInfringementsSums: RelayEdgesWithPageInfo<UserInfringementCountNode>
}

export interface AllUserInfringementsQueryVariables {
  q?: string
  orderBy?: string
  first: number
  after?: string
  users?: string[]
  company?: string
  dateGte?: Date
  dateLt?: Date
}

export interface UserInfringementsQuery {
  user: UserInfringementNodeForAllUserInfringements
  allInfringements: RelayEdges<InfringementNodeForAllInfringements>
}

export interface UserInfringementsQueryVariables {
  user: string
  orderBy: string
}

/* MUTATIONS */

export interface PatchInfringementInput {
  archivedBasis?: string | null
  archivedBy?: string
  archivedAt?: string | null
}
export interface PatchInfringementMutation {
  patchInfringement: {
    infringement: IdVariable
  }
}
export interface PatchInfringementMutationVariables {
  id: string
  input: PatchInfringementInput
}

export interface ExportInfringementsMutation {
  exportInfringements: {
    ok: boolean
  }
}

export interface ExportInfringementsMassMutation {
  exportInfringementsMass: {
    ok: boolean
  }
}

export interface ExportInfringementMutation {
  exportInfringement: {
    ok: boolean
  }
}

export interface ExportInfringementMutationVariables {
  infringementId: string
  exportOption: ExportOption
  recipientId?: string
  recipientMails: string[]
}

export interface ExportInfringementsMutationVariables {
  userMail: string
  user?: string
  dateStart?: Date
  dateEnd?: Date
  exportOption: ExportOption
}

export interface ExportInfringementsMassMutationVariables {
  userMail: string
  dateStart?: Date
  dateEnd?: Date
  exportOption: ExportOption
}

export interface ExportInfringementsUserTotalsMutation {
  exportInfringementsUserTotals: {
    ok: boolean
  }
}

export interface ExportInfringementsUserTotalsMutationVariables {
  userMail: string
  user?: string
  dateStart?: Date
  dateEnd?: Date
  exportOption: ExportOption
}

export enum InfringementCategory {
  A1 = 'A1',
  B1 = 'B1',
  B2 = 'B2',
  B3 = 'B3',
  B4 = 'B4',
  B5 = 'B5',
  B6 = 'B6',
  B7 = 'B7',
  B8 = 'B8',
  B9 = 'B9',
  B10 = 'B10',
  B11 = 'B11',
  B12 = 'B12',
  B13 = 'B13',
  B14 = 'B14',
  B15 = 'B15',
  B16 = 'B16',
  C1 = 'C1',
  C2 = 'C2',
  C3 = 'C3',
  D1 = 'D1',
  D2 = 'D2',
  D3 = 'D3',
  D4 = 'D4',
  D5 = 'D5',
  D6 = 'D6',
  D7 = 'D7',
  D8 = 'D8',
  D9 = 'D9',
  D10 = 'D10',
  D11 = 'D11',
  D12 = 'D12',
  D13 = 'D13',
  D14 = 'D14',
  D15 = 'D15',
  D16 = 'D16',
  D17 = 'D17',
  D18 = 'D18',
  D19 = 'D19',
  D20 = 'D20',
  D21 = 'D21',
  E1 = 'E1',
  E2 = 'E2',
  E3 = 'E3',
  E4 = 'E4',
  E5 = 'E5',
  E6 = 'E6',
  E7 = 'E7',
  F1 = 'F1',
  F2 = 'F2',
  G1 = 'G1',
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
  H4 = 'H4',
  H5 = 'H5',
  H6 = 'H6',
  H7 = 'H7',
  H8 = 'H8',
  H9 = 'H9',
  H10 = 'H10',
  H11 = 'H11',
  H12 = 'H12',
  H13 = 'H13',
  H14 = 'H14',
  H15 = 'H15',
  H16 = 'H16',
  H17 = 'H17',
  H18 = 'H18',
  I1 = 'I1',
  I2 = 'I2',
  I3 = 'I3',
  I4 = 'I4',
  I5 = 'I5',
  J1 = 'J1',
  J2 = 'J2',
}

export const InfringementCategoryList = [
  'A1',
  'B1',
  'B2',
  'B3',
  'B4',
  'B5',
  'B6',
  'B7',
  'B8',
  'B9',
  'B10',
  'B11',
  'B12',
  'B13',
  'B14',
  'B15',
  'B16',
  'C1',
  'C2',
  'C3',
  'D1',
  'D2',
  'D3',
  'D4',
  'D5',
  'D6',
  'D7',
  'D8',
  'D9',
  'D10',
  'D11',
  'D12',
  'D13',
  'D14',
  'D15',
  'D16',
  'D17',
  'D18',
  'D19',
  'D20',
  'D21',
  'E1',
  'E2',
  'E3',
  'E4',
  'E5',
  'E6',
  'E7',
  'F1',
  'F2',
  'G1',
  'H1',
  'H2',
  'H3',
  'H4',
  'H5',
  'H6',
  'H7',
  'H8',
  'H9',
  'H10',
  'H11',
  'H12',
  'H13',
  'H14',
  'H15',
  'H16',
  'H17',
  'H18',
  'I1',
  'I2',
  'I3',
  'I4',
  'I5',
  'J1',
  'J2',
]

export enum InfringementCategoryIdentifierEnum {
  B = "B",
  C = "C",
  D = "D",
}