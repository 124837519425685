import { useTranslate } from '@ur/react-hooks'
import {
  SortableHeader,
  Table as BaseTable,
  TableMenu,
  TableMenuItem,
} from 'components'
import { PlainLink } from 'components/Link'

import { VehicleNode } from 'modules/vehicles'
import { VehicleFilter } from 'modules/vehicles/types'
import { VehicleOrdering } from 'modules/vehicles/types.graphql'
import { useHistory } from 'react-router'
import styled, { useTheme } from 'styled-components'
import { useAdmin, useConfirm } from 'util/hooks'
import { useVehicleMutations } from '../mutations.hooks'
import { isMobile } from 'react-device-detect'
import { differenceInDays, format, parseISO } from 'date-fns'
import { ActivitiesStatus } from 'modules/activities/components/overview/ActivitiesStatusCell'

const Table = styled(props => <BaseTable {...props} />)`
  position: relative;

  thead {
    text-align: left;
  }

  tbody tr {
    :hover {
      background: ${props => props.theme.colors.gray10};
    }
  }
`

const VehicleStatusSpan = styled.span`
  display: flex;
`

interface FleetTableProps {
  vehicles: VehicleNode[]
  filter: VehicleFilter
  onSort: (value: VehicleOrdering) => void
}

export const FleetTable: React.VFC<FleetTableProps> = ({
  vehicles,
  filter,
  onSort,
}) => {
  const translations = useTranslate({
    vin: 'vehicles.vin',
    regNr: 'common.reg-nr',
    model: 'vehicles.model',
    type: 'common.type',
    brand: 'vehicles.brand',
    menu: {
      edit: 'vehicles.edit-vehicle',
      viewDetails: 'vehicles.view-vehicle-details',
      deleteVehicle: 'vehicles.delete-vehicle',
      data: 'common.data'
    },
    prompt: {
      delete: {
        title: 'vehicles.delete-vehicle-title',
        message: 'vehicles.delete-vehicle-message',
      },
    },
  })
  const theme = useTheme()
  const admin = useAdmin()
  const history = useHistory()
  const confirm = useConfirm()
  const {
    mutations: { delete: deleteVehicle },
  } = useVehicleMutations()

  async function handleDeleteVehicle(id: string) {
    const promptTitle = translations.prompt.delete.title
    const promptMessage = translations.prompt.delete.message

    const { data: answer } = await confirm(promptMessage, promptTitle)
    if (!answer) return

    deleteVehicle({
      variables: { id },
    })
  }

  function createMenuItems(vehicle: VehicleNode) {
    const items: TableMenuItem<VehicleNode>[] = []
    if (admin)
      items.push(
        {
          label: translations.menu.viewDetails,
          role: 'link',
          onClick: () => history.push(`/vehicle-fleet/${vehicle.id}`),
        },
        {
          label: translations.menu.deleteVehicle,
          onClick: () => handleDeleteVehicle(vehicle.id),
        }
      )

    return items
  }

  return (
    <>
      <Table>
        <thead>
          <tr>
            <SortableHeader
              baseValue="registrationNumber"
              sortValue={filter.orderBy}
              onSort={onSort}
            >
              {translations.regNr}
            </SortableHeader>
            <SortableHeader
              baseValue="vin"
              sortValue={filter.orderBy}
              onSort={onSort}
            >
              {translations.vin}
            </SortableHeader>
            <SortableHeader
              baseValue="brand"
              sortValue={filter.orderBy}
              onSort={onSort}
            >
              {translations.brand}
            </SortableHeader>
            <SortableHeader
              baseValue="model"
              sortValue={filter.orderBy}
              onSort={onSort}
            >
              {translations.model}
            </SortableHeader>
            <SortableHeader
              baseValue="vehicleType"
              sortValue={filter.orderBy}
              onSort={onSort}
            >
              {translations.type}
            </SortableHeader>
            {admin && !isMobile && <th>{translations.menu.data}</th>}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {vehicles.map((vehicle, vehicleIndex) => {
            const menuItems = createMenuItems(vehicle)
            return (
              <tr key={vehicle.vin}>
                <td>
                  <PlainLink
                    to={`/vehicle-fleet/${vehicle.id}`}
                    hoverColor="secondary"
                  >
                    {vehicle.registrationNumber}
                  </PlainLink>
                </td>
                <td>{vehicle.vin}</td>
                <td>{vehicle.brand}</td>
                <td>{vehicle.model}</td>
                <td>{vehicle.vehicleType}</td>
                {admin && !isMobile &&
                    <td>
                      {vehicle.vehicleFetchInfo.map((driverFetchInfo) => {
                        if (!driverFetchInfo.lastFetch) return <></>
                        const fetchDate = parseISO(driverFetchInfo.lastFetch)
                        const fetchDifference = differenceInDays(new Date(), fetchDate)
                        const color = fetchDifference < 7 ? theme.colors.green : fetchDifference < 10 ? theme.colors.orange : theme.colors.red
                        const tooltipProps = vehicleIndex === vehicles.length - 1 ? { top: '-150%'} : undefined

                        const endpoint = driverFetchInfo.integrationEndpoint.toLowerCase().includes("volvo") ? "Volvo" 
                        : driverFetchInfo.integrationEndpoint.toLowerCase().includes("scania") ? "Scania" : "FleetBoard"
                        return (
                          <VehicleStatusSpan>
                            <ActivitiesStatus dataStatusColor={color} tooltip={format(fetchDate, 'HH:mm dd.MM.yyyy')} tooltipProps={tooltipProps} />
                            {endpoint}
                          </VehicleStatusSpan>
                        )
                      })}
                    </td>
                  }
                <TableMenu items={menuItems} />
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}
