import { Table as BaseTable, TableMenu } from 'components'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { parseISO } from 'date-fns'
import { AbsenceNode, AbsenceTypeNode } from '../types.graphql'
import { AbsenceStatusCell, AbsenceTypeCell, AbsenceTypeHeader, DateCell } from './table-cells'
import { useTranslate } from '@ur/react-hooks'
import { useAbsenceHandler } from './handler.hooks'
import truncate from 'lodash/truncate'
import { isMobileOnly } from 'react-device-detect'

const Table = styled(props => <BaseTable {...props} />)`
  position: static;

  ${props => props.theme.media.largePad}
  ${props => props.theme.media.mobile} {
    overflow: auto;
  }

  td.absence-info {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 12px 1rem;
    height: 56px;

    span {
      color: ${props => props.theme.colors.gray5};
      font-weight: 600;
    }
  }
`
interface AbsencesTableProps {
  absences: AbsenceNode[]
  loading: boolean
  showEmployee?: boolean
  selectedAbsenceType: Pick<AbsenceTypeNode, 'id' | 'name'> | null
  onSelectAbsenceType: (absenceType: Pick<AbsenceTypeNode, 'id' | 'name'> | null) => void
}

export const AbsencesTable: React.VFC<AbsencesTableProps> = ({
  absences,
  loading,
  showEmployee = false,
  selectedAbsenceType,
  onSelectAbsenceType
}) => {

  const translations = useTranslate({
    dayStart: 'common.day-start',
    dayEnd: 'common.day-end',
    absenceType: 'timesheets.absence-type',
    status: 'common.status',
    reason: 'common.reason',
    employee: 'common.employee',
    noResults: 'common.no-results'
  })

  const absenceHandler = useAbsenceHandler()

  const tableHeaders = (
    <tr>
      {showEmployee && <th>{translations.employee}</th>}
      <th>{translations.dayStart}</th>
      <th>{translations.dayEnd}</th>
      {!isMobileOnly && (
        <>
          <AbsenceTypeHeader
            absenceType={selectedAbsenceType}
            text={translations.absenceType}
            onRemove={() => onSelectAbsenceType(null)}
          />
          <th>{translations.reason}</th>
          <th>{translations.status}</th>
        </>
      )}
      {/* Extra table header for menu */}
      <th></th>
    </tr>
  )

  const absenceRows = useMemo(() => {
    return absences.map(absence => {

      const dayStart = parseISO(absence.datetimeStart)
      const dayEnd = parseISO(absence.datetimeEnd)


      const menuItems = absenceHandler.createAbsenceMenuItems(absence, true)
      
      return (
        <tr key={absence.id}>
          {showEmployee && <td width="20px">{absence.user.fullName}</td>}
          <DateCell date={dayStart} side="start" type="absence" />
          <DateCell date={dayEnd} side="end" type="absence" />
          {!isMobileOnly && (
            <>
              <AbsenceTypeCell 
                absenceType={absence.absenceType}
                isSelected={!!selectedAbsenceType}
                onSelect={onSelectAbsenceType}
              />
              <td className="absence-info">{truncate(absence.reason, { length: 60})}</td>
              <AbsenceStatusCell requestStatus={absence.requestStatus} />
            </>
          )}
          <TableMenu items={menuItems} />
        </tr>
      )
    })
  }, [absenceHandler, absences, onSelectAbsenceType, selectedAbsenceType, showEmployee])

  return (
    <Table 
    loading={loading}
    noData={absences.length === 0}
    noDataText={translations.noResults}
    >
      <thead>{tableHeaders}</thead>
      <tbody>
        {absenceRows}
      </tbody>
    </Table>
  )
}