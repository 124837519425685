import { useTranslate } from '@ur/react-hooks'
import { Title, Toggle } from 'components'
import { Module, ModuleOptionsValue } from 'modules/companies/consts'
import {
  ModuleOptionNode,
  ModuleOptions,
} from 'modules/companies/types.graphql'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDebounce, useModuleConfig } from 'util/hooks'
import { useModuleOptionMutations } from '../../util'

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

interface OptionToggleProps {
  option: ModuleOptionNode
}

export const OptionToggle: React.VFC<OptionToggleProps> = ({ option }) => {
  /**
   * The logic for a mutation on a Toggle component isn't straight forward,
   * since the Toggle's state should follow the value of the active field on
   * the option. Therefore we need to keep a local state for the Toggle value
   * locally, so we can immidiately change state here for a crisp UX, while
   * we send the mutation away.
   *
   * This is done with the use of useEffects checking if all the module configs
   * are loaded, that the local value is changed from the last known DB value
   * and that the user hasn't changed its mind rapidly (the use of a debeounced
   * value). If there is a diff, and the value keeps being different after the
   * debounced timer has gone by, then we send the patch mutation.
   *
   * We never get a proper read from the server on the final value, but the
   * values will keep in sync this way.
   *
   */
  const translations = useTranslate({
    names: {
      countPause: 'settings.timesheets.count-pause-as-part-of-total-work',
      overtime: 'settings.timesheets.alternative-overtime-option',
      projects: 'settings.timesheets.projects',
      periodicOvertime: 'settings.timesheets.periodic-overtime',
      mergeTimesheetsWithActivities: 'settings.timesheets.merge-timesheets-with-activities',
      departmentMandatory: 'settings.timesheets.department-mandatory'
    },
  })
  const {
    moduleConfig,
    moduleLoading,
    getModuleOption,
    getModuleOptionStatus,
  } = useModuleConfig(Module.TIMESHEETS)
  const moduleOption = getModuleOption(option.name)
  const optionActive = getModuleOptionStatus(option.name)
  const [checked, setChecked] = useState<boolean>(optionActive)
  const [moduleLoaded, setModuleLoaded] = useState<boolean>(false)
  const debouncedValue = useDebounce<boolean>(checked, 400)
  const { patch } = useModuleOptionMutations()

  const handleToggleClick = (value: boolean) => {
    setChecked(value)
  }

  useEffect(() => {
    if (!!moduleConfig && !moduleLoading && !moduleLoaded) {
      setChecked(optionActive)
      setModuleLoaded(true)
    }
  }, [moduleLoading, moduleConfig, moduleLoaded, optionActive])

  useEffect(() => {
    if (
      checked === debouncedValue &&
      debouncedValue !== optionActive &&
      !!moduleOption &&
      !moduleLoading &&
      moduleLoaded
    ) {
      patch({
        variables: {
          id: moduleOption?.id ?? '',
          input: {
            active: debouncedValue,
          },
        },
      })
    }
  }, [
    checked,
    debouncedValue,
    moduleLoading,
    moduleConfig,
    moduleLoaded,
    moduleOption,
    optionActive,
    patch,
  ])

  function getName(moduleName: ModuleOptions) {
    switch (moduleName) {
      case ModuleOptionsValue.TIMESHEETS_OVERTIME_OPTIONS:
        return translations.names.overtime
      case ModuleOptionsValue.TIMESHEETS_COUNT_PAUSE_IN_TOTAL:
        return translations.names.countPause
      case ModuleOptionsValue.TIMESHEETS_PROJECTS:
        return translations.names.projects
      case ModuleOptionsValue.TIMESHEETS_DAY_INTERVAL_OVERTIME:
        return translations.names.periodicOvertime
      case ModuleOptionsValue.MERGE_TIMESHEETS_WITH_ACTIVITIES:
        return translations.names.mergeTimesheetsWithActivities
      case ModuleOptionsValue.TIMESHEETS_DEPARTMENT_SELECT_REQUIRED:
        return translations.names.departmentMandatory
      default:
        return ''
    }
  }

  return (
    <ToggleWrapper>
      <Title.H3>{getName(option.name)}</Title.H3>
      <Toggle
        value={checked}
        thumbBackground="secondary"
        onIconProps={{
          icon: 'check',
        }}
        offIconProps={{
          icon: 'times',
        }}
        onChange={handleToggleClick}
      />
    </ToggleWrapper>
  )
}
