import { useState } from 'react'
import { useOnErrorAuto } from 'util/hooks'
import {
  HandbookElementType,
  HierarchyEntry,
  HandbookSearchEntriesQuery,
  HandbookSearchEntriesQueryVariables,
  HANDBOOK_SEARCH_ENTRIES_QUERY,
} from 'modules/handbook'
import { HandbookEntryCard } from 'modules/handbook/components'
import { useQuery } from '@apollo/client'
import { cleanRegexOperators } from 'util/forms'

interface EntrySearchResultsProps {
  query?: string
  onDelete: (id: string, type: HandbookElementType) => Promise<void>
}

export const EntrySearchResults: React.VFC<EntrySearchResultsProps> = ({
  query = '',
  onDelete,
}) => {
  const [entries, setEntries] = useState<HierarchyEntry[]>([])

  const onErrorAuto = useOnErrorAuto()
  useQuery<HandbookSearchEntriesQuery, HandbookSearchEntriesQueryVariables>(
    HANDBOOK_SEARCH_ENTRIES_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        q: cleanRegexOperators(query),
      },
      onCompleted(data) {
        if (typeof data === 'undefined') return

        const entries = data.allManualEntries.edges.map(edge => edge.node)

        setEntries(entries)
      },
      onError: onErrorAuto(),
    }
  )

  return (
    <>
      {entries.map(entry => {
        return (
          <HandbookEntryCard entry={entry} key={entry.id} onDelete={onDelete} />
        )
      })}
    </>
  )
}
