import { useContext } from 'react'
import UserContext from 'context/UserContext'

export function useUserTypes() {
  const user = useContext(UserContext)

  return {
    userTypes: user.userTypes,
    hasUserType: (userType: string) =>
      [userType].every((permisson: string) =>
        user.userTypes?.map(node => node.name).includes(permisson)
      ),
  }
}
