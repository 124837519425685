import { useMutation } from '@apollo/client'
import { IdVariable } from 'types/graphql'
import { useOnErrorAuto } from 'util/hooks'
import {
  BatchCreateUserDocumentFileMutation,
  BatchCreateUserDocumentFileMutationVariables,
  DeleteUserDocumentFileMutation,
  PatchUserDocumentFileMutation,
  PatchUserDocumentFileMutationVariables,
} from '..'
import {
  BATCH_CREATE_USER_DOCUMENT_FILE_MUTATION,
  DELETE_USER_DOCUMENT_FILE_MUTATION,
  PATCH_USER_DOCUMENT_FILE_MUTATION,
} from '../mutations'

export function useUserDocumentFileMutations() {
  const onErrorAuto = useOnErrorAuto()

  const [batchCreateFile, { loading: batchCreateLoading }] = useMutation<
    BatchCreateUserDocumentFileMutation,
    BatchCreateUserDocumentFileMutationVariables
  >(BATCH_CREATE_USER_DOCUMENT_FILE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['UserDocumentFilesForDocument', 'AllUserDocuments'],
    onError: onErrorAuto(),
  })

  const [patchFile, { loading: patchLoading }] = useMutation<
    PatchUserDocumentFileMutation,
    PatchUserDocumentFileMutationVariables
  >(PATCH_USER_DOCUMENT_FILE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['UserDocumentFilesForDocument'],
    onError: onErrorAuto(),
  })

  const [deleteFile, { loading: deleteLoading }] = useMutation<
    DeleteUserDocumentFileMutation,
    IdVariable
  >(DELETE_USER_DOCUMENT_FILE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['UserDocumentFilesForDocument', 'AllUserDocuments'],
    onError: onErrorAuto(),
  })

  return {
    batchCreate: batchCreateFile,
    patch: patchFile,
    delete: deleteFile,
    loading: batchCreateLoading || patchLoading || deleteLoading,
  }
}
