export enum NotificationType {
  ABSENCE_STATUS_CHANGED = 'ABSENCE_STATUS_CHANGED',
  NEW_FORUM_THREAD = 'NEW_FORUM_THREAD',
  FORUM_THREAD_RESPONSE = 'FORUM_THREAD_RESPONSE',
  USER_DOCUMENT_ABOUT_TO_EXPIRE = 'USER_DOCUMENT_ABOUT_TO_EXPIRE',
  EXPORT_COMPLETED = 'EXPORT_COMPLETED',
  NEW_INFRINGEMENT = 'NEW_INFRINGEMENT',
  ANY = '*',
}

export enum APP_NOTIFICATION_STATUS {
  SEEN = 'SEEN',
  UNSEEN = 'UNSEEN',
  INTERACTED = 'INTERACTED',
  ARCHIVED = 'ARCHIVED',
}
