import { UseTranslateProvider } from '@ur/react-hooks'
import { LanguageContext } from 'context/LanguageContext'
import messages from 'locale/messages'
import { getPreferredLanguage, setPreferredLanguage } from 'locale/util'
import React, { useState } from 'react'

interface IntlContextProviderProps {}

export const IntlContextProvider: React.FC<IntlContextProviderProps> = ({
  children,
}) => {
  const [language, setLanguage] = useState(() => getPreferredLanguage())

  // Check that the language is valid. If not, fallback to norwegian

  let finalMessages = messages[language]
  if (!finalMessages) {
    finalMessages = messages['nb']
    setLanguage('nb')
  }

  // Use english value for every untranslated message
  for (let [key, value] of Object.entries(finalMessages)) {
    if (value === 'NEEDS TRANSLATION') {
      finalMessages[key] = messages['en'][key]
    }
  }

  function handleSetLanguage(language: string) {
    setPreferredLanguage(language)
    setLanguage(language)
  }

  return (
    <LanguageContext.Provider value={[language, handleSetLanguage]}>
      <UseTranslateProvider locale={language} messages={finalMessages}>
        {children}
      </UseTranslateProvider>
    </LanguageContext.Provider>
  )
}
