import { useForm, useTranslate } from '@ur/react-hooks'
import { formatISO, parseISO } from 'date-fns'
import React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import styled from 'styled-components'
import { format } from 'util/date-fns'
import { DeleteDriverCardMutationVariables, DriverCardQuery, DriverCardQueryVariables, PatchDriverCardMutationVariables } from '..'
import { DRIVER_CARD_QUERY } from '../queries'
import { Input, InputArea, ProfileCard } from '../UserProfile/components'
import { Icon } from '@ur/react-components'
import { MonthPicker } from 'components/MonthPicker'
import { Button } from 'components/Button'
import { isMobile } from 'react-device-detect'
import { DELETE_DRIVER_CARD_MUTATION, PATCH_DRIVER_CARD_MUTATION } from '../mutations'
import { useConfirm, usePermissions } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'

const DeleteButton = styled(Icon)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  :hover {
    cursor: pointer;
  }
`

const MonthPickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  button {
    margin-top: 1rem;
  }
`

const Wrapper = styled(ProfileCard)`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'header header header'
    'name driverId expiryDate';
  gap: 1.5rem;

  header {
    grid-area: header;
  }

  ${props => props.theme.media.largePad} {
    grid-template-columns: 1fr;
    grid-template-areas:
      'header'
      'name'
      'driverId'
      'expiryDate';
    gap: 1rem;
  }

  ${props => props.theme.media.mobile} {
    grid-template-columns: 1fr;
    grid-template-areas:
      'header'
      'name'
      'driverId'
      'expiryDate';
    gap: 1rem;
  }
`

export interface DriverCardProps {
  cardId: string
  active: boolean
  area: string
}

export const DriverCard: React.VFC<DriverCardProps> = ({ cardId, active, area }) => {
  const { data, loading } = useQuery<DriverCardQuery, DriverCardQueryVariables>(
    DRIVER_CARD_QUERY,
    { variables: { id: cardId } }
  )
  const translations = useTranslate({
    labels: {
      name: 'common.name',
      cardNumber: 'users.driver-card',
      expires: 'common.expires-at',
      title: 'users.active-driver-card',
      titleExpired: 'users.expired-driver-card',
      submit: 'common.submit',
      addExpiry: 'users.add-expiry-date',
    },
    confirm: {
      title: 'users.delete-expired-driver-card',
      message: 'users.delete-expired-driver-card-message',
    }
  })
  const confirm = useConfirm()

  const { hasPermissions } = usePermissions()

  const canEdit = hasPermissions(PERMISSIONS.users.change.user, true)
  const [patchDriverCard] = useMutation<{},PatchDriverCardMutationVariables>(PATCH_DRIVER_CARD_MUTATION, {
    refetchQueries: ['Profile'],
  })

  const [deleteDriverCard] = useMutation<{}, DeleteDriverCardMutationVariables>(DELETE_DRIVER_CARD_MUTATION, {
    refetchQueries: ['Profile'],
  })

  const card = data?.driverCard

  const { formValues: form, formChangeHandler: handler } = useForm({
    values: {
      expiresAt: card?.expiresAt ? parseISO(card.expiresAt) : new Date(),
    },
  })

  function handleSubmit() {
    patchDriverCard({
      variables: {
        id: cardId,
        input: {
          driverIdentification: card?.driverIdentification,
          cardIssuingMemberState: card?.cardIssuingMemberState,
          expiresAt: formatISO(form.expiresAt),
        }
      },
    })
  }

  async function handleDelete() {
    const { data } = await confirm(
      translations.confirm.message,
      translations.confirm.title
    )
    if (!data) return
    deleteDriverCard({ variables: { id: cardId } })
  }

  // TODO: Add loading view on final design
  return loading ? null : (
    <Wrapper gridArea={area}>
      <header>
        <h3>{active ? translations.labels.title : translations.labels.titleExpired}</h3>
      </header>
      <InputArea area="name">
        <label>{translations.labels.name}</label>
        <Input
          editing={false}
          value={card?.user.fullName ?? ''}
          onChange={() => void 0}
        />
      </InputArea>
      <InputArea area="driverId">
        <label>{translations.labels.cardNumber}</label>
        <Input
          editing={false}
          value={`${card?.cardIssuingMemberState}${card?.driverIdentification}`}
          onChange={() => void 0}
        />
      </InputArea>
      <InputArea area="expiryDate">
        
        {active && !card?.expiresAt && canEdit && (
          <MonthPickerWrapper>
            <label>{translations.labels.addExpiry}</label>
            <MonthPicker
              light={true}
              earliestYear={new Date().getFullYear()}
              disableAfter={false}
              value={form.expiresAt}
              onChange={handler('expiresAt')}
            />
            <Button
              fullWidth={isMobile}
              onClick={() => handleSubmit()}
            >
              {translations.labels.submit}
            </Button>
          </MonthPickerWrapper>
        )}
        {(!active || card?.expiresAt || !canEdit) && (
          <>
          <label>{translations.labels.expires}</label>
          <Input
            editing={false}
            value={card && card.expiresAt ? format(parseISO(card.expiresAt), 'MM/yy') : '-'}
            onChange={() => void 0}
          />
          </>
        )}
      </InputArea>
      {!active && <DeleteButton icon="times" hoverColor='red' onClick={() => handleDelete()} /> }
    </Wrapper>
  )
}
