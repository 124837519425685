import { useQuery } from '@apollo/client'
import { Icon, Loader } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { Card } from 'components/Card'
import { COMPANY_DRIVER_CARDS_QUERY, CompanyDriverCardsQuery } from 'modules/companies'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import theme from 'theme'

const Wrapper = styled.section`
  max-width: 100%;
`

const NotificationWrapper = styled(Link)`
  display: flex;
  gap: 0.5rem;
  font-size: 1.1rem;
  line-height: 1.5rem;
`

const ListCard = styled(Card)`
  display: flex;
  flex-direction: column;
  min-width: min-content;
  height: 100%;

  overflow: hidden;
  background: white;
  gap: 0.5rem;
  padding: 2rem;

  a {
    color: black;
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }
`

interface DashboardNotificationsProps {}

export const DashboardNotifications: React.VFC<DashboardNotificationsProps> = () => {
  const { data, loading } = useQuery<CompanyDriverCardsQuery>(COMPANY_DRIVER_CARDS_QUERY)
  const translations = useTranslate({
    title: 'common.notifications-alt',
    expiredCard: ['users.n-has-one-or-more-expired-driver-cards', { n: "" }],
    noNotifications: 'notifications.you-have-no-notifications',
  })

  return (
    <Wrapper>
      <header>
        <h2>{translations.title}</h2>
      </header>
      <ListCard>
        {loading ? <Loader.Dots /> : data?.companyDriverCards.edges.map(({ node }) => (
          <NotificationWrapper to={`users/${node.user.id}`}>
            <Icon icon='exclamation-triangle' color={theme.colors.red} size='1.1rem' margin='2px 0 0 0'/>
            <>{translations.expiredCard({ n: node.user.fullName})}</>
          </NotificationWrapper>
        ))}
        {!data?.companyDriverCards.edges.length && !loading && <p>{translations.noNotifications}.</p>}
      </ListCard>
    </Wrapper>
  )
}
