import { useTranslate } from '@ur/react-hooks'
import { CenteredLoader } from 'components'
import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import { IdVariable } from 'types/graphql'
import { useBreadcrumbs, useOnErrorAuto, useUser } from 'util/hooks'
import { FormForm } from '../components'
import { FORM_TEMPLATE_FOR_FORM_SUBMIT_QUERY } from '../queries'
import { FormTemplateForFormSubmitQuery } from '../types.graphql'
import { PreviousAnswers } from './PreviousAnswers'

const Wrapper = styled.div`
  ${props => props.theme.layout.default};
  display: flex;
  flex-direction: column;
  gap: 2rem;

  & > .form {
    max-width: 800px;

    .form-fields {
      max-width: 800px;
    }
  }
`

interface SubmitFormProps {}

export const SubmitForm: React.VFC<SubmitFormProps> = () => {
  const translations = useTranslate({
    loading: 'common.loading...',
  })

  const me = useUser()

  const { formId } = useParams<{ formId: string }>()
  const { setOverride } = useBreadcrumbs()
  const history = useHistory()
  const onErrorAuto = useOnErrorAuto()

  const { data, loading } = useQuery<
    FormTemplateForFormSubmitQuery,
    IdVariable & { userId: string }
  >(FORM_TEMPLATE_FOR_FORM_SUBMIT_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { id: formId, userId: me.id },
    onCompleted(data) {
      if (!data) return
      setOverride(data.formTemplate.id, data.formTemplate.name)
    },
    onError: onErrorAuto(),
  })

  useEffect(() => {
    if (!!data) return
    setOverride(formId, translations.loading)
  }, [data, formId, setOverride, translations.loading])

  function handleAfterSubmit(success: boolean) {
    if (success) history.push('/formcreator')
  }

  if (!data || loading) return <CenteredLoader />

  return (
    <Wrapper>
      <FormForm
        className="form"
        form={data.formTemplate}
        onAfterSubmit={handleAfterSubmit}
      />
      <PreviousAnswers filledOutForms={data.formTemplate.filledOutForms} form={data.formTemplate} />
    </Wrapper>
  )
}
