import { DynamicColumnNode } from 'modules/dynamic_tables'
import { PromptResolve } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { RegularModal } from 'components'
import React, { useState } from 'react'
import { Select } from 'components'
import { Field, Form } from '..'

interface DynamicColumnAddPromptProps {
  columns: DynamicColumnNode[]
  forExport: boolean
  onSubmit: PromptResolve<DynamicColumnNode | null>
}

export const AddDynamicColumnPrompt: React.VFC<DynamicColumnAddPromptProps> = ({
  columns,
  forExport,
  onSubmit,
}) => {
  const translations = useTranslate({
    add: 'common.submit',
    addColumn: 'common.add-column',
    addPlaceholder: 'settings.tables.add-placeholder',
    addDescription: 'settings.tables.add-column-description',
    addExportDescription: 'settings.tables.add-export-column-description',
  })

  const options = columns
    .filter(column => (forExport ? !column.inExport : !column.enabled))
    .map(column => {
      return {
        value: column.id,
        label: `${column.displayName}`,
      }
    })

  const [selected, setSelected] = useState<DynamicColumnNode | null>(null)

  function handleSelect(val: string | null) {
    const column = columns.find(column => column.id === val)
    if (column) {
      setSelected(column)
    }
  }

  function handleSubmit() {
    if (!selected) onSubmit(null)
    onSubmit(selected)
  }

  const description = forExport
    ? translations.addExportDescription
    : translations.addDescription

  return (
    <RegularModal
      title={translations.addColumn}
      width="400px"
      submitText={translations.add}
      submitDisabled={!selected}
      onCancel={() => onSubmit(null)}
      onSubmit={handleSubmit}
    >
      <Form>
        <Field>
          <label>{description}</label>
          <Select
            value={selected?.id || null}
            placeholder={translations.addPlaceholder}
            options={options}
            onChange={handleSelect}
          />
        </Field>
      </Form>
    </RegularModal>
  )
}
