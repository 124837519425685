import { useQuery } from '@apollo/client'
import { Loader, Modal } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Button as BaseButton, Select } from 'components'
import { useModuleConfig } from 'util/hooks'
import { Module } from 'modules/companies/consts'
import { getUsersHighestPrioritySalaryWorkTime } from 'modules/companies/util'
import { UserUserTypesQuery } from 'modules/users'
import { USER_USER_TYPES_QUERY } from 'modules/users/queries'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { IdVariable } from 'types/graphql'
import { format } from 'util/date-fns'
import {
  getSalaryDatesForMonth,
  ExportOption,
  YearMonthExportData,
  ExportType,
  ExportFormat,
} from 'modules/exports'
import { useExportOptions, useExportFormatOptions } from 'modules/exports/hooks'
import { ExportForm } from '../types'

const Wrapper = styled.div`
  width: 400px;
  padding: 1.5rem;
  background: white;
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};

  h2 {
    display: flex;
    margin: 0 0 1rem 6px;

    div {
      margin-left: 0.5rem;
    }
  }

  ${props => props.theme.media.mobile} {
    width: 96vw;
  }
`
const Form = styled.div`
  display: flex;
  flex-direction: column;
`
const Field = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;

  label {
    grid-row: 1;
    grid-column: 1 / 3;

    margin: 0 0 6px 6px;
    color: ${props => props.theme.colors.gray3};
    font-weight: 600;
  }
  & + div {
    margin-top: 1rem;
  }

  .--select-display {
    background-color: ${props => props.theme.colors.quaternary};

    .--select-display-selected {
      font-size: 1rem !important;
    }
  }
  .--select-curtain {
    background-color: ${props => props.theme.colors.quaternary};

    .--select-curtain-option:hover {
      background-color: white;
    }
  }
`
const SubmitField = styled(Field)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  ${props => props.theme.media.mobile} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
`
const Button = styled(BaseButton)`
  height: 42px;
  padding: 0 1rem;

  & + * {
    margin-left: 1rem;
  }
  &.cancel {
    background: inherit;
    border: 1px solid ${props => props.theme.colors.gray8};
    color: ${props => props.theme.colors.gray4};
  }

  ${props => props.theme.media.mobile} {
    & + * {
      margin-left: 0;
    }
  }
`

interface ExportModalProps {
  open: boolean
  exportConfig: YearMonthExportData
  userId?: string
  loading: boolean
  onClose: () => void
  onSubmit: (options: ExportForm, salaryDates: [Date, Date]) => void
}

export const ExportModal: React.VFC<ExportModalProps> = ({
  open,
  exportConfig,
  userId,
  loading,
  onClose,
  onSubmit,
}) => {
  const translations = useTranslate({
    exportTimesheet: 'timesheets.export-timesheet',

    type: 'common.type',
    exportOption: 'common.export-type',
    email: 'common.email',
    download: 'common.download',
    exportFormat: 'common.export-format',

    cancel: 'common.cancel',
    submit: 'common.submit',

    options: {
      monthly: 'exports.month-export',
      salary: 'exports.salary-export',
    },
  })

  const {
    formValues: form,
    updateForm,
    submitHandler,
  } = useForm<ExportForm>({
    values: {
      exportType: ExportType.ENTRY_MONTH,
      exportOption: ExportOption.SAVE,
      exportFormat: ExportFormat.PDF,
    },
    config: {
      storage: {
        storeFormState: true,
        retrieveFormStateOnMount: true,
        sessionStorage: true,
        storeFormStateName: 'timesheet-export-form',
        transformValues: {
          exportType: value =>
            typeof value === 'string' ? (value as ExportType) : undefined,
          exportOption: value =>
            typeof value === 'string' ? (value as ExportOption) : undefined,
          exportFormat: value =>
            typeof value === 'string' ? (value as ExportFormat) : undefined,
        },
      },
    },
  })

  const { data } = useQuery<UserUserTypesQuery, IdVariable>(
    USER_USER_TYPES_QUERY,
    {
      variables: { id: userId ?? '' },
    }
  )
  const user = data?.user

  const { moduleConfig } = useModuleConfig(Module.TIMESHEETS)

  const salaryWorkTime = getUsersHighestPrioritySalaryWorkTime(
    user,
    moduleConfig
  )

  const { year, month } = exportConfig
  const salaryDates = getSalaryDatesForMonth(
    salaryWorkTime?.salaryDateStart ?? 27,
    salaryWorkTime?.salaryDateEnd ?? 26,
    year,
    month
  )
  const exportTypes = [
    {
      value: ExportType.ENTRY_MONTH,
      label: translations.options.monthly,
    },
    {
      value: ExportType.ENTRY_SALARY,
      label: `${translations.options.salary} (${format(
        salaryDates[0],
        'dd.MMMM'
      )}-${format(salaryDates[1], 'dd.MMMM')})`,
    },
  ]
  const exportOptions = useExportOptions(true)
  const exportFormatOptions = useExportFormatOptions()

  useEffect(() => {
    if (
      form.exportOption === ExportOption.DOWNLOAD &&
      form.exportFormat !== ExportFormat.PDF
    ) {
      updateForm({ exportFormat: ExportFormat.PDF })
    }
  }, [form.exportOption, form.exportFormat, updateForm])

  return (
    <Modal offset={36} placement="top-center" open={open} onClose={onClose}>
      <Wrapper>
        <h2>
          {translations.exportTimesheet}
          {loading && <Loader.Spinner size={28} thickness={2} />}
        </h2>

        <Form>
          <Field>
            <label>{translations.type}</label>
            <Select
              value={form.exportType}
              options={exportTypes}
              fullWidth
              onChange={value => updateForm({ exportType: value! })}
            />
          </Field>
          <Field>
            <label>{translations.exportFormat}</label>
            <Select
              value={form.exportFormat}
              options={exportFormatOptions}
              disabled={form.exportOption === ExportOption.DOWNLOAD}
              fullWidth
              height="3.875rem"
              onChange={value =>
                updateForm({
                  exportFormat: value ?? ExportFormat.PDF,
                })
              }
            />
          </Field>
          <Field>
            <label>{translations.exportOption}</label>
            <Select
              value={form.exportOption}
              options={exportOptions}
              fullWidth
              onChange={value => updateForm({ exportOption: value! })}
            />
          </Field>
          <SubmitField>
            <Button className="cancel" onClick={onClose}>
              {translations.cancel}
            </Button>
            <Button
              disabled={loading}
              onClick={submitHandler(form => onSubmit(form, salaryDates))}
            >
              {translations.submit}
            </Button>
          </SubmitField>
        </Form>
      </Wrapper>
    </Modal>
  )
}
