/* DepartmentCell */

import styled from 'styled-components'
import { Cell } from '../Cell'
import { useTranslate } from '@ur/react-hooks'

const StyledCell = styled(Cell)`
  position: relative;
  width: 100px;
  max-width: 100px;
  min-width: 100px;
`

const StyledDepartments = styled.div<{expandable: boolean}>`
  display: block;
  background-color: inherit;

  min-width: 90px;
  margin-top: 2px;
  padding: 8px 10px 4px;

  font-size: 0.9rem;
  font-weight: 600;
  text-align: center;
  overflow: hidden;
  ${props => !props.expandable && `
    text-overflow: ellipsis;
  `}
`
interface DepartmentProps {
  className?: string

  departmentNames: (string | null)[]
}
export const Departments: React.VFC<DepartmentProps> = ({ departmentNames, className }) => {
  const translations = useTranslate({
    departmentExpandable: ['common.department-expandable-text', {amount: 0}],
  })

  if (departmentNames.length === 0) return null

  const text = departmentNames.length > 1 ? translations.departmentExpandable({ amount: departmentNames.length }) : departmentNames[0]

  return <StyledDepartments 
      className={className}
      expandable={departmentNames.length > 1}
    >
      {text}
    </StyledDepartments>
}

interface DepartmentCellProps extends DepartmentProps {}
export const DepartmentCell: React.VFC<DepartmentCellProps> = ({
  ...departmentProps
}) => (
  <StyledCell {...departmentProps}>
    <Departments {...departmentProps} />
  </StyledCell>
)
