import { PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { NumberInput, RegularModal } from 'components'
import { DetectBlur } from 'containers/util-containers'
import { Field } from 'modules/companies'
import styled from 'styled-components'


const ButtonFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`

const PartOfDayButton = styled.div<{selected: boolean}>`
  

  ${props => props.selected && `
    background: ${props.theme.colors.primary};
    :first-child {
      background: gray;
    }
    color: white;
    :hover{
      cursor: default;
    }
  `}
  border: 1px solid ${props => props.theme.colors.gray6};
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  padding: 1rem;
  cursor: pointer;
  min-width: 50px;
  text-align: center;
`

interface PartOfDayButtonProps {
  value: number
  text: string
  selected: boolean
  onClick: (value: number, event?: MouseEvent | undefined) => void
}

const PartOfDay:React.FC<PartOfDayButtonProps> = ({value, text, selected, onClick}) => {
  return (
    <PartOfDayButton selected={selected} onClick={() => onClick(value)}>{text}</PartOfDayButton>
  )
}

export interface SalaryDayForm {
  userId: string
  partOfDay: number
}

interface SalaryDayPromptProps {
  initialData: SalaryDayForm
  onSubmit: PromptResolve<SalaryDayForm | null>
}

const BUTTON_PROPS = [
  {value: 0, text: '0'},
  {value: 0.25, text: '1/4'},
  {value: 0.333, text: '1/3'},
  {value: 0.5, text: '1/2'},
  {value: 0.666, text: '2/3'},
  {value: 0.75, text: '3/4'},
  {value: 1, text: '1'},
]

export const SalaryDayPrompt: React.VFC<SalaryDayPromptProps> = ({
  initialData,
  onSubmit,
}) => {
  const translations = useTranslate({
    update: 'common.update',
    addSalaryDay: 'activities.add-salary-day',
  })

  const {
    formValues: form,
    formValid,
    formChangeHandler: handler,
    submitHandler: submit,
  } = useForm<SalaryDayForm>({
    values: initialData ?? {
      userId: '',
      partOfDay: 1,
    },
  })

  return (
    <DetectBlur active onClickOutside={() => onSubmit(null)}>
      <RegularModal
        title={translations.addSalaryDay}
        submitText={translations.update}
        submitDisabled={!formValid}
        width="500px"
        onCancel={() => onSubmit(null)}
        onSubmit={submit(onSubmit)}
      >
        <form>
          <Field>
            <NumberInput max={1} min={0} step={0.25} value={form.partOfDay} onChange={handler('partOfDay')}/>
            <ButtonFlexContainer>
              {BUTTON_PROPS.map((props) => <PartOfDay key={props.value} selected={form.partOfDay === props.value} {...props} onClick={handler('partOfDay')}/>)}
            </ButtonFlexContainer>
          </Field>
        </form>
      </RegularModal>
    </DetectBlur>
  )
}
