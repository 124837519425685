import { Modal } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Button as BaseButton, DatePicker, TimePicker } from 'components'
import { parseISO, set } from 'date-fns'
import { useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { EditTerminalEntryForm } from '../TerminalView/types'
import { TerminalEntryNode } from '../types.graphql'

const Wrapper = styled.div`
  width: 600px;
  padding: 1.5rem;
  background: white;
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};

  h2 {
    margin: 0 0 1rem 6px;
  }

  p.error {
    text-align: center;
    color: ${props => props.theme.colors.red};
  }

  ${props => props.theme.media.mobile} {
    width: 96vw;
  }
`
const Form = styled.div`
  display: flex;
  flex-direction: column;
`
const Field = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5rem;

  label {
    grid-row: 1;
    grid-column: 1 / 3;

    margin: 0 0 6px 6px;
    color: ${props => props.theme.colors.gray3};
    font-weight: 600;
  }
  & + div {
    margin-top: 1rem;
  }
`
const SubmitField = styled(Field)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  ${props => props.theme.media.mobile} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
`
const Button = styled(BaseButton)`
  height: 42px;
  padding: 0 1rem;

  & + * {
    margin-left: 1rem;
  }
  &.cancel {
    background: inherit;
    border: 1px solid ${props => props.theme.colors.gray8};
    color: ${props => props.theme.colors.gray4};
  }

  ${props => props.theme.media.mobile} {
    & + * {
      margin-left: 0;
    }
  }
`

interface EditTerminalEntryModalProps {
  entry: TerminalEntryNode | null

  onClose: () => void
  onSubmit: (form: EditTerminalEntryForm) => void
}

export const EditTerminalEntryModal: React.VFC<EditTerminalEntryModalProps> = ({
  entry,

  onClose,
  onSubmit,
}) => {
  const translations = useTranslate({
    editTerminalEntry: 'terminal.edit-terminal-entry',
    checkIn: 'terminal.checked-in',
    checkOut: 'terminal.checked-out',

    cancel: 'common.cancel',
    submit: 'common.submit',

    error: {
      invalid: 'error.invalid-value',
      startBeforeEnd: 'timesheets.error.start-before-end',
    },
  })

  const lastOpenInit = useRef<string | null>(null)

  const validateChronology = (start: Date, end: Date) =>
    start.getTime() <= end.getTime() ? null : translations.error.invalid

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    updateForm,
    submitHandler,
  } = useForm<EditTerminalEntryForm>({
    values: {
      checkIn: new Date(),
      checkOut: new Date(),
    },
    validators: {
      checkIn: (checkIn, { checkOut }) => validateChronology(checkIn, checkOut),
      checkOut: (checkOut, { checkIn }) =>
        validateChronology(checkIn, checkOut),
    },
  })
  // TODO fix exhaustive-deps?
  // eslint-disable-next-line
  const updateFormCallback = useCallback(updateForm, [entry])

  useEffect(() => {
    if (entry !== null && entry.checkOut !== null) {
      if (lastOpenInit.current === entry.id) return
      lastOpenInit.current = entry.id

      updateFormCallback(
        {
          checkIn: parseISO(entry.checkIn),
          checkOut: parseISO(entry.checkOut),
        },
        { runValidation: false }
      )
    }
  }, [updateFormCallback, entry])

  function updateTime(key: keyof EditTerminalEntryForm) {
    return (value: Date) => {
      const existingValue = form[key]
      const newValue = set(existingValue, {
        hours: value.getHours(),
        minutes: value.getMinutes(),
        seconds: value.getSeconds(),
        milliseconds: value.getMilliseconds(),
      })

      updateForm({
        [key]: newValue,
      })
    }
  }
  function updateDate(key: keyof EditTerminalEntryForm) {
    return (value: Date) => {
      const existingValue = form[key]
      const newValue = set(existingValue, {
        year: value.getFullYear(),
        month: value.getMonth(),
        date: value.getDate(),
      })

      updateForm({
        [key]: newValue,
      })
    }
  }

  return (
    <Modal open={entry !== null} onClose={onClose}>
      <Wrapper>
        <h2>{translations.editTerminalEntry}</h2>

        <p className="error">
          {(!!errors.checkIn || !!errors.checkOut) &&
            translations.error.startBeforeEnd}
        </p>

        <Form>
          <Field>
            <label>{translations.checkIn}</label>
            <TimePicker
              value={form.checkIn}
              width="100px"
              onChange={updateTime('checkIn')}
            />
            <DatePicker
              value={form.checkIn}
              fullWidth
              onChange={updateDate('checkIn')}
            />
          </Field>

          <Field>
            <label>{translations.checkOut}</label>
            <TimePicker
              value={form.checkOut}
              width="100px"
              onChange={updateTime('checkOut')}
            />
            <DatePicker
              value={form.checkOut}
              fullWidth
              onChange={updateDate('checkOut')}
            />
          </Field>

          <SubmitField>
            <Button className="cancel" onClick={onClose}>
              {translations.cancel}
            </Button>
            <Button disabled={!formValid} onClick={submitHandler(onSubmit)}>
              {translations.submit}
            </Button>
          </SubmitField>
        </Form>
      </Wrapper>
    </Modal>
  )
}
