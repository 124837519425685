import { PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Input, RegularModal, Select, UserSelect } from 'components'
import { DetectBlur } from 'containers/util-containers'
import { Field, Form, IssueCategoryForSettings } from '..'
import { Color, SettingsColorPicker } from '../common'
import { IssueSeverity } from 'modules/issues'
import { useIssueSeverityOptions } from 'modules/issues/consts'

export interface IssueCategoryForm {
  name: string
  color: string
  defaultSeverity: IssueSeverity
  responsibleUser?: string | null
}

interface IssueCategoryPromptProps {
  initialData?: IssueCategoryForm
  editableCategories: IssueCategoryForSettings[]
  uneditableCategories: IssueCategoryForSettings[]

  onSubmit: PromptResolve<IssueCategoryForm | null>
}

export const IssueCategoryPrompt: React.VFC<IssueCategoryPromptProps> = ({
  initialData,
  editableCategories,
  uneditableCategories,
  onSubmit,
}) => {
  const translations = useTranslate({
    form: {
      label: {
        name: 'common.name',
        color: 'common.color',
        responsibleUser: 'settings.responsible-user',
      },
    },

    addIssueCategory: 'settings.add-issue-category',
    editIssueCategory: 'settings.edit-issue-category',
    issueCategories: 'common.issue-categories',

    create: 'common.create-alt',
    update: 'common.update',

    validation: {
      nameExists: 'settings.validation.issue-category-name-exists',
      required: 'common.required',
    },
    prompt: {
      delete: ['settings.prompts.issue-category-delete', { name: '' }],
      deleteTitle: 'settings.prompts.issue-category-delete-title',
    },
    defaultSeverity: 'common.default-severity',
  })

  const severityOptions = useIssueSeverityOptions()

  const editing = typeof initialData !== 'undefined'

  function validateName(name: string, formName: string) {
    if (name === initialData?.name) return null
    if (name === '') return translations.validation.required
    if (
      !![...editableCategories, ...uneditableCategories].find(
        type => type.name === formName
      )
    ) {
      return translations.validation.nameExists
    }
    return null
  }

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formChangeHandler: handler,
    updateForm,
    submitHandler: submit,
  } = useForm<IssueCategoryForm>({
    values: initialData ?? {
      name: '',
      color: 'hsl(138deg, 80%, 80%)',
      defaultSeverity: 'MEDIUM',
      responsibleUser: null,
    },
    validators: {
      name: (val, form) => validateName(val, form.name),
    },
  })

  return (
    <DetectBlur active onClickOutside={() => onSubmit(null)}>
      <RegularModal
        title={
          editing
            ? translations.editIssueCategory
            : translations.addIssueCategory
        }
        submitText={editing ? translations.update : translations.create}
        submitDisabled={!formValid}
        width="400px"
        onCancel={() => onSubmit(null)}
        onSubmit={submit(onSubmit)}
      >
        <Form>
          <Field>
            <label>{translations.form.label.name}</label>
            <Input
              value={form.name}
              error={errors.name}
              autoFocus
              fullWidth
              onChange={handler('name')}
            />
          </Field>

          <Field>
            <label>{translations.form.label.responsibleUser}</label>
            <UserSelect
              value={form.responsibleUser ?? null}
              fullWidth
              onChange={responsibleUser =>
                updateForm({ responsibleUser: responsibleUser })
              }
            />
          </Field>
          <Field>
            <label>{translations.defaultSeverity}</label>
            <Select 
              value={form.defaultSeverity} 
              options={severityOptions} nullable={false} 
              onChange={(value) => value ? updateForm({defaultSeverity: value}) : void 0}
              fullWidth
            />
          </Field>
          <Field>
            <label>{translations.form.label.color}</label>
            <Color color={form.color}>
              <SettingsColorPicker
                value={form.color}
                onChange={handler('color')}
              />
            </Color>
          </Field>
        </Form>
      </RegularModal>
    </DetectBlur>
  )
}
