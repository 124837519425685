export enum ExportType {
  ENTRY_SALARY = 'export-salary-report',
  ENTRY_MONTH = 'export-monthly-report',

  MASS_ENTRY_SALARY = 'export-salary-report-mass',
  MASS_ENTRY_MONTH = 'export-monthtly-report-mass',

  DIET = 'export-diet-report',
  ACTIVITIES = 'export-activity-report',
  TOURS = 'export-tour-report',
  PAUSE = 'export-activity-pause-report',

  MASS_DIET = 'mass-export-diet-report',
  MASS_ACTIVITIES = 'mass-export-activity-report',
  MASS_ACTIVITIES_OVERTIME = 'mass-export-activity-overtime-report',
  MASS_SUMMARY = 'mass-export-work-summary-report',
  MASS_TOURS = 'mass-export-tour-report',
  MASS_PAUSE = 'mass-export-activity-pause-report',

  INFRINGEMENTS = 'export-infringement-report',
  INFRINGEMENT_TOTALS = 'export-infringement-totals-report',

  ABSENCES = 'export-absence-report',

  ISSUES = 'export-issue-report',
}

export enum ExportOption {
  MAIL = 'MAIL',
  DOWNLOAD = 'DOWNLOAD',
  SAVE = 'SAVE',
}

// TODO: CSV
export enum ExportFormat {
  EXCEL = 'EXCEL',
  EXCEL_FLAT = 'EXCEL_FLAT',
  PDF = 'PDF',
  PDF_SALARY = 'PDF_SALARY',
  VISMA = 'VISMA',
  POWEROFFICE = 'POWEROFFICE',
  TWENTYFOUR_SEVEN_OFFICE = 'TWENTYFOUR_SEVEN_OFFICE',
  TRIPLETEX = 'TRIPLETEX',
  //CSV = 'CSV',
}
