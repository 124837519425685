import { gql } from '@apollo/client'

export const ALL_DYNAMIC_COLUMNS_QUERY = gql`
  query AllDynamicColumnsQuery($moduleName: String!, $enabled: Boolean) {
    allDynamicColumns(moduleName: $moduleName, enabled: $enabled) {
      edges {
        node {
          id
          name
          displayName
          enabled
          order
          inExport
          columnType
        }
      }
    }
  }
`
