import { Icon } from '@ur/react-components'
import React, { useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { overloadColor } from 'util/style'
import { TreeBranch } from './TreeView.types'

interface WrapperProps {
  showTopBorder: boolean
}
const Wrapper = styled.div<WrapperProps>`
  ${props =>
    props.showTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.gray8};
    `};
`
interface ContentProps {
  indent: number
  hasSubBranches: boolean
  background: string
  borderRadius?: string
}
const Content = styled.div<ContentProps>`
  display: grid;
  grid-template-columns: auto 1fr;
  height: 88px;
  padding: 0.5rem;
  padding-left: calc(0.5rem + ${props => props.indent}px);

  border-radius: ${props => props.borderRadius};
  cursor: ${props => props.hasSubBranches && 'pointer'};
  background: ${props => overloadColor(props.background)};

  & > div {
    align-self: center;

    &.icon {
      padding: 1rem;
    }
    &.info {
      h4 {
        margin: 0;
        font-size: 1.2em;
        color: ${props => props.theme.colors.primary};
      }
      span {
        font-size: 0.8em;
        color: ${props => props.theme.colors.gray5};
      }
    }
  }

  ${props => props.theme.media.mobile} {
    height: auto;
    min-height: 88px;
    padding-left: 0;

    & > div.info {
      h4 {
        font-size: 1em;
      }
    }
  }
`

interface BranchProps {
  branch: TreeBranch
  level: number
  indent: number
  indentOffset: number
  levelBackgrounds?: string | string[]

  index: number
  totalBranches: number
}

export const Branch: React.VFC<BranchProps> = ({
  branch,
  level,
  indent,
  indentOffset,
  levelBackgrounds,

  index,
  totalBranches,
}) => {
  const [open, setOpen] = useState(!!branch.initOpen)

  const hasSubBranches =
    branch.expandable ||
    (typeof branch.branches !== 'undefined' && branch.branches.length > 0)

  async function handleClick(evt: React.MouseEvent<HTMLDivElement>) {
    evt.stopPropagation()

    branch.onClick?.(branch, evt)
    if (evt.defaultPrevented) return

    if (hasSubBranches) {
      const isOpen = open
      setOpen(v => !v)

      if (isOpen) branch.onCollapse?.(branch)
      else branch.onExpand?.(branch)
    }
  }

  const background = useMemo(() => {
    if (typeof branch.background === 'string') return branch.background
    if (typeof branch.background === 'function')
      return branch.background?.(open)

    if (typeof levelBackgrounds === 'undefined') return undefined
    if (typeof levelBackgrounds === 'string') return levelBackgrounds
    if (Array.isArray(levelBackgrounds))
      return levelBackgrounds[level % (levelBackgrounds.length || 1)]

    return undefined
  }, [branch, open, levelBackgrounds, level])

  const icon = useMemo(() => {
    const wrap = (icon: JSX.Element | undefined) => (
      <div className="icon">{icon}</div>
    )

    switch (typeof branch.icon) {
      case 'string':
        return wrap(<Icon icon={branch.icon} {...branch.iconProps} />)
      case 'function':
        const icon = branch.icon(open)
        if (typeof icon === 'string')
          return wrap(<Icon icon={icon} {...branch.iconProps} />)
        return wrap(icon)
      default:
        return wrap(branch.icon)
    }
  }, [branch, open])

  return (
    <Wrapper
      className="--tree-view-branch"
      showTopBorder={!branch.noTopBorder}
      onClick={handleClick}
    >
      <Content
        className="--tree-view-branch-content"
        indent={(level + indentOffset) * indent}
        hasSubBranches={hasSubBranches}
        background={background ?? 'white'}
        borderRadius={branch.borderRadius}
      >
        {icon}

        <div className="info">
          <h4>{branch.title}</h4>
          {branch.subtitle && <span>{branch.subtitle}</span>}
        </div>

        {typeof branch.renderExtra === 'function'
          ? branch.renderExtra(branch, index, totalBranches, open)
          : branch.renderExtra}
      </Content>

      {open &&
        (branch.branches ?? []).map((subBranch, index) => (
          <Branch
            key={subBranch.id}
            branch={subBranch}
            level={level + 1}
            indentOffset={indentOffset}
            indent={indent}
            levelBackgrounds={levelBackgrounds}
            index={index}
            totalBranches={branch.branches?.length ?? 0}
          />
        ))}
    </Wrapper>
  )
}
