import { SelectOption } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { FAIcon } from 'components'
import { Select } from 'components/Select'
import { IconShortcutDisplay, ShortcutsWrapper } from 'components/Shortcuts'
import { Section } from 'modules/companies'
import {
  getIconShortcutFromManualEntryNode,
  HierarchyLinkEntry,
} from 'modules/handbook'
import { useHandbookAllManualEntries } from 'modules/handbook/hooks'
import { useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { DEFAULT_PAGE_SIZE } from 'util/pagination'
import { useHandbookLinkMutations } from '../../util'

const IconShortcutWrapper = styled.div`
  position: relative;
`

const RemoveLinkButton = styled(FAIcon)`
  position: absolute;
  top: 0;
  right: 0;
  background: ${props => props.theme.colors.gray10};
  width: 20px;
  height: 20px;
  padding-top: 4px;
  border-radius: 1000000px;
  ::before {
    margin-left: 6px;
  }
  :hover  {
    color: ${props => props.theme.colors.red};
    cursor: pointer;
  }
`

interface EditDashboardLinksCardProps {}

export const EditDashboardLinksCard: React.VFC<EditDashboardLinksCardProps> =
  () => {
    const translations = useTranslate({
      dashboardLinks: 'handbook.dashboard-links',
      preview: 'settings.dashboard-links-preview',
      selectManualEntry: 'common.select-manual-entry',
    })

    const {
      data,
      entries: nodes,
      fetchMore,
      loading,
    } = useHandbookAllManualEntries()

    const inactiveNodes = nodes.filter(node => !node.dashboardShortcutActive)
    const activeNodes = nodes.filter(node => node.dashboardShortcutActive)
    const { patch } = useHandbookLinkMutations()

    const selectOptions = useMemo<
      SelectOption<string, HierarchyLinkEntry>[]
    >(() => {
      if (!inactiveNodes) return []

      const options = inactiveNodes.map(entry => ({
        value: entry.id,
        label: entry.name,
        extra: entry,
      }))

      const inactiveOnlyOptions = options.filter(
        option =>
          !activeNodes?.find(elem => elem.id === option.value)
      ).sort((a, b) => a.label.localeCompare(b.label))

      return inactiveOnlyOptions
    }, [inactiveNodes, activeNodes])

    const pageInfo = data?.allManualEntries?.pageInfo
    const hasNextPage = pageInfo?.hasNextPage ?? false

    const handleFetchMore = useCallback(async () => {
      if (typeof data === 'undefined' || typeof pageInfo === 'undefined') return
      if (!hasNextPage || !pageInfo.endCursor) return

      const cursor = pageInfo.endCursor
      try {
        await fetchMore({
          variables: {
            first: DEFAULT_PAGE_SIZE,
            isDashboardLink: false,
            after: cursor,
          },
        })
      } catch (e) {
        //@ts-ignore
        if (e.name === 'Invariant Violation') return
        throw e
      }
    }, [fetchMore, pageInfo, data, hasNextPage])

    function handleSelectManualEntry(
      id: string | null,
      _entry: SelectOption<string, HierarchyLinkEntry> | null
    ) {
      if (!id) return
      patch({
        variables: {
          id: id,
          input: {
            dashboardShortcutActive: true,
          },
        },
      })
    }

    function handleRemoveLink(id: string) {
      return () => {
        patch({
          variables: {
            id: id,
            input: {
              dashboardShortcutActive: false,
            },
          },
        })
      }
    }

    return (
      <Section>
        <h2>{translations.dashboardLinks}</h2>
        <Select
          value=""
          options={selectOptions}
          loading={loading}
          placeholder={translations.selectManualEntry}
          onFetchMore={handleFetchMore}
          onChange={handleSelectManualEntry}
          fullWidth
        />
        {activeNodes && activeNodes?.length > 0 && (
          <>
            <h3>{translations.preview}</h3>
            <ShortcutsWrapper>
              {activeNodes?.map(node => {
                const link = getIconShortcutFromManualEntryNode(node)
                const props = { iconProps: link.iconProps, text: link.text }
                return (
                  <IconShortcutWrapper>
                    <IconShortcutDisplay {...props} />
                    <RemoveLinkButton
                      icon="times"
                      onClick={handleRemoveLink(node.id)}
                    />
                  </IconShortcutWrapper>
                )
              })}
            </ShortcutsWrapper>
          </>
        )}
      </Section>
    )
  }
