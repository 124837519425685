import { SelectOption, SelectProps } from '@ur/react-components/build/Select'
import { Select } from 'components'
import React, { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import { RelayNodeType } from 'types/graphql'
import { useOnErrorAuto } from 'util/hooks'
import { ALL_ISSUE_CATEGORIES_SHALLOW_QUERY } from '../queries'
import {
  AllIssueCategoriesShallowQuery,
  AllIssueCategoriesShallowQueryVariables,
} from '../types.graphl'

const Wrapper = styled.div`
  .--select-display-selected {
    color: ${props => props.theme.colors.text.dark};
  }
`

export type ShallowCategory = RelayNodeType<
  AllIssueCategoriesShallowQuery<true>['allIssueCategories']
>

interface CategorySelectProps
  extends Omit<SelectProps<string>, 'options' | 'onChange'> {
  value: string | null
  onChange: (
    value: string,
    user: ShallowCategory | null
  ) => void
}

export const CategorySelect: React.VFC<CategorySelectProps> = ({
  value,
  onChange,

  ...selectProps
}) => {
  const onErrorAuto = useOnErrorAuto()

  const { data, loading } = useQuery<
    AllIssueCategoriesShallowQuery<true>,
    AllIssueCategoriesShallowQueryVariables
  >(ALL_ISSUE_CATEGORIES_SHALLOW_QUERY, {
    variables: {
      withResponsibleUser: true,
    },
    onError: onErrorAuto(),
  })

  function handleChange(
    id: string | null,
    option: SelectOption<string, ShallowCategory> | null
  ) {
    if (id === null || option === null) return
    onChange(id, option.extra ?? null)
  }

  const options = useMemo<SelectOption<string, ShallowCategory>[]>(() => {
    if (!data) return []

    return data.allIssueCategories.edges.map(({ node: category }) => ({
      value: category.id,
      label: category.name,
      extra: category,
    }))
  }, [data])

  return (
    <Wrapper>
      <Select
        value={value}
        options={options}
        grayed
        loading={loading}
        height="3rem"
        onChange={handleChange}
        {...selectProps}
      />
    </Wrapper>
  )
}
