import { useGlobal } from '@ur/react-hooks'
import { Overrides } from '.'

export function useBreadcrumbs() {
  const [overrides, setOverrides] = useGlobal('breadcrumbs.overrides')
  const [inserts, setInserts] = useGlobal('breadcrumbs.inserts')
  const [excludes, setExcludes] = useGlobal('breadcrumbs.excludes')

  function setOverride(key: string, value: string | null) {
    setOverrides({
      ...overrides,
      [key]: value,
    })
  }

  function deleteOverrides(...keys: string[]) {
    const includeKeys = Object.keys(overrides).filter(
      key => !keys.includes(key)
    )
    const filteredOverrides = includeKeys.reduce<Overrides>((acc, key) => {
      return {
        ...acc,
        [key]: overrides[key],
      }
    }, {})
    setOverrides(filteredOverrides)
  }

  return {
    overrides,
    setOverride,
    setOverrides,
    deleteOverrides,

    inserts,
    setInserts,

    excludes,
    setExcludes,
  }
}
