import { useQuery } from "@apollo/client"
import { ALL_USER_DOCUMENT_CATEGORIES_QUERY } from "../queries"
import { AllUserDocumentCategoriesQuery } from ".."
import { useState } from "react"
import { CenteredLoader, Checkbox } from "components"
import styled from "styled-components"
import { FilterCheckmarksWrapper, StyledScrollBar } from "components/FilterPopup/components"
import { FilterPopup } from "components/FilterPopup"
import { useAdmin } from "util/hooks"
import { useTranslate } from "@ur/react-hooks"


const StyledDiv = styled.div`
    display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > * + * {
    margin-top: 0.3rem;
  }
  margin-top: 1rem;
`

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.gray8};
  margin: 1rem 0;
`

const StyledPopup = styled(FilterPopup)`
  margin-bottom: 2rem;
`

const StyledCheckbox = styled(Checkbox)`
    font-weight: 500;
`

export const UserDocumentCategoryFilter: React.VFC<{
    showDeactivated: boolean
    includeExpiredDocuments: boolean
    onSelectionChange: (categories: string[]) => void
    onShowDeactivatedChange: (showDeactivated: boolean) => void
    onIncludeExpiredDocumentsChange: (includeExpiredDocuments: boolean) => void
}> = ({
    showDeactivated,
    includeExpiredDocuments,
    onSelectionChange,
    onShowDeactivatedChange,
    onIncludeExpiredDocumentsChange
}) => {
    const translations = useTranslate({
        showDeactivated: 'users.show-deactivated',
        includeExpiredDocuments: 'users.include-expired-documents',
        hasDocument: 'users.has-document',
    })
    const admin = useAdmin()
    const { data, loading } = useQuery<AllUserDocumentCategoriesQuery, { includeExpiredDocuments: boolean }>(ALL_USER_DOCUMENT_CATEGORIES_QUERY, {
        variables: { includeExpiredDocuments }
    }) 
    const categories = data?.allUserDocumentCategories.edges.map(edge => edge.node) ?? []

    const [selectedCategories, setSelectedCategories] = useState<string[]>([])
    function toggleCategory(id: string) {
        const newCategories = selectedCategories.includes(id) ? selectedCategories.filter(iid => iid !== id) : [...selectedCategories, id]
        setSelectedCategories(newCategories)
        onSelectionChange(newCategories)
      }
    return (
        <StyledPopup>
            {admin && (
                <StyledDiv>
                    <Checkbox
                        checked={showDeactivated}
                        label={translations.showDeactivated}
                        boxWidth="32px"
                        boxHeight="32px"
                        fontSize="1rem"
                        onChange={() => onShowDeactivatedChange(!showDeactivated)}
                    />
                </StyledDiv>
            )}
            {categories.length !== 0 && (
                <h4>{translations.hasDocument}</h4>
            )}
            <FilterCheckmarksWrapper>
                {loading && (
                    <CenteredLoader marginTop="1rem" marginBottom="1rem" />
                )}
                <StyledScrollBar>
                {categories.map(category => {
                    const checked = selectedCategories.includes(category.id)
                    return (
                    <StyledCheckbox
                        key={category.id}
                        checked={checked}
                        label={`${category.name} (${category.numUsers})`}
                        boxWidth="32px"
                        boxHeight="32px"
                        fontSize="1rem"
                        onChange={() => toggleCategory(category.id)}
                    />
                    )
                })}
                <Divider />
                <StyledCheckbox
                    key="expired"
                    checked={includeExpiredDocuments}
                    label={translations.includeExpiredDocuments}
                    boxWidth="32px"
                    boxHeight="32px"
                    fontSize="1rem"
                    onChange={(value) => onIncludeExpiredDocumentsChange(value)}
                />
                </StyledScrollBar>
            </FilterCheckmarksWrapper>
        </StyledPopup>
    )
}