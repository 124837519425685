import { useQuery } from '@apollo/client'
import { Loader } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { useHERE } from 'modules/routePlanner/hooks'
import { ROUTE_PLAN_ROUTES_QUERY } from 'modules/routePlanner/queries'
import {
  createAndAddIncidentMarkersToMap,
  createAndAddNoticeMarkersToMap,
} from 'modules/routePlanner/utils'
import { useLayoutEffect, useRef } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { Coordinates } from 'types/graphql'
import { HERE_API_KEY } from 'util/env'

interface GridAreaDivProp {
  gridArea: string
}

const MapCanvas = styled.div<GridAreaDivProp>`
  grid-area: ${props => props.gridArea};
  width: 100%;
  height: 100%;
  border-radius 10px 0 0 0;
  border: 1px solid ${props => props.theme.colors.gray8};
  overflow: clip;
`

const CenterWrapper = styled.div<GridAreaDivProp>`
  grid-area: ${props => props.gridArea};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`

interface RouteMapHEREParams {
  routePlanId: string
}

export const RouteMapHERE: React.VFC<{
  startLocation: Coordinates
  endLocation: Coordinates
  gridArea: string
}> = ({ startLocation, endLocation, gridArea }) => {
  const translations = useTranslate({
    loadingRoute: 'route-planner.loading-route',
  })
  const mapRef = useRef(null)
  const { routePlanId } = useParams<RouteMapHEREParams>()
  const HERE = useHERE()

  const { data, loading } = useQuery(ROUTE_PLAN_ROUTES_QUERY, {
    variables: { id: routePlanId },
  })

  useLayoutEffect(() => {
    if (loading || !data) return

    if (HERE === null) return

    // Standard plaform setup for HERE
    // https://developer.here.com/documentation/maps/3.1.5.0/dev_guide/topics_api/h-service-platform.html
    const platform = new HERE.service.Platform({
      apikey: HERE_API_KEY,
    })
    const defaultLayers = platform.createDefaultLayers()

    // Map constructor
    // https://developer.here.com/documentation/maps/3.1.5.0/dev_guide/topics_api/h-map.html

    if (mapRef.current === null) return
    const hereMap = new HERE.Map(
      // Compiler crashes if we do not guarantee that the ref is not null
      mapRef.current!,
      defaultLayers.vector.normal.map,
      {
        center: { lat: startLocation.lat, lng: startLocation.lng },
        zoom: 8,
        pixelRatio: window.devicePixelRatio || 1,
        // Pads the viewport so markers don't render on the edge
        padding: { top: 50, left: 50, bottom: 50, right: 50 },
      }
    )

    let lineString = new HERE.geo.LineString()
    const {
      routePlan: { points, notices, incidents },
    } = data

    // https://developer.here.com/documentation/examples/maps-js/geoshapes/polyline-on-the-map
    points.forEach(({ lng, lat }: Coordinates) => {
      lineString.pushPoint({ lng, lat })
    })

    new HERE.mapevents.Behavior(new HERE.mapevents.MapEvents(hereMap))
    const ui = HERE.ui.UI.createDefault(hereMap, defaultLayers)

    if (points.length === 0) return

    hereMap.addObject(
      new HERE.map.Polyline(lineString, { style: { lineWidth: 4 } })
    )

    const startMarker = new HERE.map.Marker(startLocation)
    const endMarker = new HERE.map.Marker(endLocation)
    hereMap.addObject(startMarker)
    hereMap.addObject(endMarker)

    createAndAddIncidentMarkersToMap(incidents, HERE, hereMap, ui)
    createAndAddNoticeMarkersToMap(notices, HERE, hereMap, ui)
    const group = new HERE.map.Group()

    group.addObjects([startMarker, endMarker])
    hereMap.addObject(group)

    hereMap.getViewModel().setLookAtData({
      bounds: group.getBoundingBox(),
    })

    const resizeHandler = () => {
      try {
        hereMap.getViewPort().resize()
      } catch (e) {
        console.error(`Resizing of map threw an error: ${e}`)
      }
    }

    window.addEventListener('resize', resizeHandler)

    return () => {
      window.removeEventListener('resize', resizeHandler)
      hereMap.dispose()
    }
  }, [
    mapRef,
    HERE,
    startLocation.lat,
    startLocation.lng,
    startLocation,
    endLocation,

    loading,
    data,
  ])

  if (loading) {
    return (
      <CenterWrapper gridArea={gridArea}>
        <Loader.Dots />
        <span>{translations.loadingRoute}</span>
      </CenterWrapper>
    )
  }

  return (
    <MapCanvas
      gridArea={gridArea}
      className="map"
      ref={mapRef}
      style={{ height: '100%' }}
    />
  )
}
