import { useTranslate } from '@ur/react-hooks'
import { Toggle } from 'components'
import { useModuleOptionMutations } from 'modules/companies/CompanySettings/util/mutations.hooks'
import { Module, ModuleOptionsValue } from 'modules/companies/consts'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDebounce, useModuleConfig } from 'util/hooks'
import { Section } from '..'

const ReceiptFunctionalityCardWrapper = styled(Section)`
  > h2 {
    margin: auto 0 auto 0;
  }
  > div {
    justify-self: end;
  }
`

export const ReceiptFunctionalityCard: React.VFC = () => {
  const translations = useTranslate({
    recieptFunctionality: 'settings.reciept-functionality',
  })

  const {
    moduleConfig,
    moduleLoading,
    getModuleOption,
    getModuleOptionStatus,
  } = useModuleConfig(Module.ACTIVITIES)
  const moduleOption = getModuleOption(
    ModuleOptionsValue.ACTIVITIES_RECEIPT_FUNCTIONALITY
  )
  const hasReceiptFunctionality = getModuleOptionStatus(
    ModuleOptionsValue.ACTIVITIES_RECEIPT_FUNCTIONALITY
  )
  const [checked, setChecked] = useState<boolean>(hasReceiptFunctionality)
  const [moduleLoaded, setModuleLoaded] = useState<boolean>(false)
  const debouncedValue = useDebounce<boolean>(checked, 400)
  const { patch } = useModuleOptionMutations()

  const handleCheckboxClick = (value: boolean) => {
    setChecked(value)
  }

  useEffect(() => {
    if (!!moduleConfig && !moduleLoading && !moduleLoaded) {
      setChecked(hasReceiptFunctionality)
      setModuleLoaded(true)
    }
  }, [moduleLoading, moduleConfig, moduleLoaded, hasReceiptFunctionality])

  useEffect(() => {
    if (
      checked === debouncedValue &&
      debouncedValue !== hasReceiptFunctionality &&
      !!moduleOption &&
      !moduleLoading &&
      moduleLoaded
    ) {
      patch({
        variables: {
          id: moduleOption?.id ?? '',
          input: {
            active: debouncedValue,
          },
        },
      })
    }
  }, [
    checked,
    debouncedValue,
    moduleLoading,
    moduleConfig,
    moduleLoaded,
    patch,
    moduleOption,
    hasReceiptFunctionality,
  ])

  const gridConfig = { columns: '1fr 1fr' }

  return (
    <ReceiptFunctionalityCardWrapper grid={gridConfig}>
      <h2>{translations.recieptFunctionality}</h2>
      {!moduleLoading && (
        <Toggle
          value={checked}
          thumbBackground="secondary"
          onIconProps={{
            icon: 'check',
          }}
          offIconProps={{
            icon: 'times',
          }}
          onChange={handleCheckboxClick}
        />
      )}
    </ReceiptFunctionalityCardWrapper>
  )
}
