import React from 'react'

export const Users: React.VFC<React.SVGAttributes<SVGSVGElement>> = props => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 164.785 164.785"
    enableBackground="new 0 0 164.785 164.785"
    xmlSpace="preserve"
    fill="#003057"
    {...props}
  >
    <g>
      <path
        className="st0"
        d="M97.934,77.413c-11.906,0-21.591-9.685-21.591-21.591S86.028,34.23,97.934,34.23s21.592,9.687,21.592,21.593
		S109.839,77.413,97.934,77.413z M97.927,39.648c-8.862,0.055-16.114,7.308-16.167,16.167c0,8.925,7.256,16.18,16.174,16.18
		c8.919,0,16.175-7.255,16.175-16.173c0-8.92-7.256-16.175-16.175-16.175H97.927z"
      />
      <path
        className="st0"
        d="M142.379,130.556c-1.494,0-2.709-1.216-2.709-2.71v-4.554c-0.017-16.636-13.564-30.168-30.198-30.168H86.429
		c-16.626,0.009-30.167,13.541-30.185,30.166v4.555c0,1.494-1.215,2.71-2.709,2.71s-2.708-1.216-2.708-2.71v-4.554
		c0.024-19.606,15.995-35.57,35.603-35.588h23.025c19.623,0,35.608,15.966,35.632,35.589v4.552
		C145.087,129.34,143.872,130.556,142.379,130.556z"
      />
      <path
        className="st0"
        d="M51.293,77.429c-9.445,0-17.128-7.684-17.128-17.129s7.683-17.129,17.128-17.129S68.422,50.855,68.422,60.3
		S60.738,77.429,51.293,77.429z M50.207,48.806c-5.917,0.548-10.59,5.538-10.625,11.594c0,6.463,5.254,11.716,11.711,11.716
		c6.458,0,11.712-5.254,11.712-11.711s-5.254-11.711-11.712-11.711h-1.086V48.806z"
      />
      <path
        className="st0"
        d="M22.408,119.172c-1.494,0-2.709-1.216-2.709-2.71c0.025-15.839,12.932-28.739,28.772-28.757h7.331
		c1.494,0,2.708,1.216,2.708,2.71c0,1.494-1.214,2.71-2.708,2.71H48.47c-12.86,0.009-23.336,10.477-23.354,23.336
		C25.116,117.955,23.901,119.172,22.408,119.172z"
      />
    </g>
  </svg>
)
