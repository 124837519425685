import React from 'react'

export const Clock: React.VFC<React.SVGAttributes<SVGSVGElement>> = props => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 14.5 14.4"
    enableBackground="new 0 0 14.5 14.4"
    xmlSpace="preserve"
    fill="#003057"
    {...props}
  >
    <g>
      <g>
        <g>
          <g>
            <path
              className="st0"
              d="M7.2,0C3.2,0,0,3.2,0,7.2s3.2,7.2,7.2,7.2s7.2-3.2,7.2-7.2C14.5,3.2,11.2,0,7.2,0z M7.7,13.5v-0.9
					c0-0.2-0.2-0.5-0.5-0.5s-0.5,0.2-0.5,0.5v0.9c-3.1-0.2-5.6-2.7-5.9-5.9h0.9c0.2,0,0.5-0.2,0.5-0.5c0-0.2-0.2-0.5-0.5-0.5H0.9
					C1.1,3.5,3.6,1,6.8,0.7v0.9c0,0.2,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5V0.9c3.1,0.2,5.6,2.7,5.9,5.9h-0.9c-0.2,0-0.5,0.2-0.5,0.5
					c0,0.2,0.2,0.5,0.5,0.5h0.9C13.3,10.8,10.8,13.3,7.7,13.5z"
            />
          </g>
        </g>
        <path
          className="st0"
          d="M9.9,6.8H7.7V4.1c0-0.2-0.2-0.5-0.5-0.5S6.8,3.8,6.8,4.1v3.2c0,0.2,0.2,0.5,0.5,0.5H10c0.2,0,0.5-0.2,0.5-0.5
			S10.2,6.8,9.9,6.8z"
        />
      </g>
    </g>
  </svg>
)
