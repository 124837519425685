import { useTranslate } from '@ur/react-hooks'
import { LinkList, LinkListItem } from 'components'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { ChangePassword } from '.'
import { ChangeLanguage } from './ChangeLanguage'

const Wrapper = styled.div`
  ${props => props.theme.layout.default};

  ${props => props.theme.media.mobile} {
    h1 {
      margin: 0 0 1rem 6px;
      font-size: 1.4rem;
    }
  }
`
const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: auto auto;
  gap: 2rem;

  ${props => props.theme.media.mobile} {
    display: flex;
    flex-direction: column;
    gap: 0;

    & > div + div {
      margin-top: 1rem;
    }
  }
`
const Links = styled.div`
  grid-column: 1;
  grid-row: 1 / 3;
`

interface UserSettingsProps {}

export const UserSettings: React.VFC<UserSettingsProps> = () => {
  const translations = useTranslate({
    mySettings: 'settings.my-settings',
    userSettings: 'common.usersettings',
  })

  const links: LinkListItem[] = [
    {
      text: translations.userSettings,
      href: '/usersettings',
    },
  ]

  return (
    <Wrapper>
      <h1>{translations.mySettings}</h1>
      <Content>
        {!isMobileOnly && (
          <Links>
            <LinkList items={links} />
          </Links>
        )}

        <ChangePassword />

        <ChangeLanguage />
      </Content>
    </Wrapper>
  )
}
