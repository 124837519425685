import React from 'react'
import styled from 'styled-components'
import {
  Button as BaseButton,
  ButtonProps as BaseButtonProps,
} from '@ur/react-components'

const StyledButton = styled(BaseButton)`
  padding: ${props => props.padding ?? '0 3em'};

  font-weight: 600;

  &:disabled {
    color: white;
    cursor: not-allowed;
  }

  .--button-icon-left,
  .--button-icon-right {
    min-width: 0;
    width: auto;
    color: inherit;
    background-color: rgba(0, 0, 0, 0);
  }
  .--button-icon-left {
    margin-right: 1rem;
  }
  .button-icon-right {
    margin-left: 1rem;
  }
  .--button-content {
    padding: 0;
  }
`

export interface ButtonProps extends BaseButtonProps {}

export const Button: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <StyledButton
      color="primary"
      background="secondary"
      centerContent
      height="3.875rem"
      fontSize="1.2rem"
      {...props}
    >
      {children}
    </StyledButton>
  )
}
