import { Icon, usePrompt } from '@ur/react-components'
import { UpdateFormFn, useTranslate } from '@ur/react-hooks'
import {
  Button as BaseButton,
  ExportButton as BaseExportButton,
  Checkbox,
  MonthPicker,
  UserSelect,
} from 'components'
import { Card } from 'components/Card'
import { PlainLink } from 'components/Link'
import { formatISO } from 'date-fns'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { useAdmin, usePermissions, useUser } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { CreateAbsenceModal } from './CreateAbsenceModal'
import { useAbsenceMutations } from '../mutations.hooks'
import { AbsenceFilterForm, CreateAbsenceForm, TimesheetFilterForm } from '../types'
import { AbsencePopupFilter } from './AbsencePopupFilter'
import { SelectMultipleUsers } from 'components/Select/SelectMultipleUsers'
import { AbsenceView } from '../consts'
import { Field } from 'modules/companies'

const Wrapper = styled(Card)`
  display: grid;
  grid-template-columns: 1fr auto;

  margin-bottom: 1rem;
  padding: 0;

  section {
    display: grid;
    grid-template-columns: 1fr auto auto auto;
    gap: 1rem;
    padding: 1rem;

    & + section {
      border-left: 1px solid ${props => props.theme.colors.gray8};
      align-items: center;
    }
  }

  ${props => props.theme.media.largePad} {
    grid-template-columns: 1fr;

    section {
      display: flex;
      flex-wrap: wrap;

      & + section {
        display: flex;
        justify-content: flex-end;

        padding-top: 0;
        border-left: 0;
      }
    }
  }

  ${props => props.theme.media.custom({ max: '1050px' })} {
    section + section {
      justify-content: flex-start;
    }
  }

  ${props => props.theme.media.mobile} {
    display: flex;
    flex-direction: column;

    section:first-of-type {
      display: flex;
      flex-direction: column;
      gap: 0;

      & + section {
        border-left: 0;
      }
      & > * + * {
        margin-top: 1rem;
      }
    }
    section:last-of-type {
      display: flex;
      justify-content: flex-end;
      padding-top: 0;
    }
  }
`
const Button = styled(BaseButton)`
  ${props => props.theme.media.mobile} {
    padding: 1rem !important;
    text-align: center;
  }

  ${props => props.theme.media.mediumScreen} {
    padding: 1rem 0.4rem 1rem 2rem !important;

    span {
      display: none;
    }
  }
`
const ExportButton = styled(BaseExportButton)`
  ${props => props.theme.media.mobile} {
    padding: 1rem !important;
    text-align: center;
  }

  ${props => props.theme.media.mediumScreen} {
    padding: 1rem 0.4rem 1rem 2rem !important;

    span {
      display: none;
    }
  }
`

interface TimesheetControlsProps {
  filter: TimesheetFilterForm | AbsenceFilterForm

  type?: 'timeentries' | 'absences'

  onOpenExportModal?: () => void
  onUpdate: UpdateFormFn<TimesheetFilterForm> | UpdateFormFn<AbsenceFilterForm>
}

export const TimesheetControls: React.VFC<TimesheetControlsProps> = ({
  filter,

  type = 'timeentries',

  onOpenExportModal = () => void 0,
  onUpdate,
}) => {
  const translations = useTranslate({
    salaryMode: 'common.salary-mode',
    absenceType: 'timesheets.absence-type',
    month: 'common.month',
    user: 'common.user',
    users: 'common.users',
    export: 'common.export',
    registerHours: 'timesheets.register-timeentry',
    registerAbsence: 'timesheets.register-absence',
  })

  const me = useUser()
  const admin = useAdmin()
  const { hasPermissions } = usePermissions()
  const addPrompt = usePrompt()

  const canAdd = hasPermissions(PERMISSIONS.timesheets.add.timeentry, true)
  const showAbsenceFilter = type === 'absences'
  const showRightSection = admin || canAdd

  const {
    mutations: { create: createAbsence },
    loading: mutationsLoading,
  } = useAbsenceMutations()
  async function handleCreateAbsence() {
    const { data } = await addPrompt<CreateAbsenceForm | null>(resolve => (
      <CreateAbsenceModal admin={admin} onSubmit={resolve} />
    ))

    if (!data) return

    const userId = data.user ? data.user : filter.user

    createAbsence({
      variables: {
        input: {
          reason: data.reason,
          absenceType: data.absenceType ?? '',
          datetimeStart: formatISO(data.dates[0]),
          datetimeEnd: formatISO(data.dates[1]),
          user: userId,
        },
      },
    })
  }

  return (
    <Wrapper>
      <section>
        {admin && !filter.allUsers ? (
          <Field>
            <label>{!filter.allUsers ? translations.user : translations.users}</label>
            {filter.view === AbsenceView.calendar ? (
              <SelectMultipleUsers
                selected={filter?.users ?? []}
                background="quaternary"
                width="100%"
                minWidth={isMobileOnly ? '0' : '260px'}
                onChange={users => onUpdate({ users: users ?? [me.id] })}
              />
            ) : (
              <UserSelect
                value={filter.user ?? null}
                background="quaternary"
                width="100%"
                minWidth={isMobileOnly ? '0' : '260px'}
                height="3.875rem"
                onChange={user => onUpdate({ user: user ?? me.id })}
              />
            )}
          </Field>
        ) : (
          <></>
        )}
        {showAbsenceFilter && (
          <Field>
            <label>{translations.absenceType}</label>
            <AbsencePopupFilter
              filter={filter as AbsenceFilterForm}
              admin={admin}
              onUpdate={onUpdate as UpdateFormFn<AbsenceFilterForm>}
            />
          </Field>
        )}
        <Field>
          <label>{translations.month}</label>
          <MonthPicker
            value={filter.month}
            disableAfter={type === 'absences' ? false : true}
            minWidth={isMobileOnly ? '0' : '340px'}
            width={isMobileOnly ? '100%' : undefined}
            maxWidth={isMobileOnly ? '100%' : '420px'}
            onChange={month => onUpdate({ month })}
          />
        </Field>
        <Field>
          <label>{translations.salaryMode}</label>
          <Checkbox 
            checked={filter.salaryMode} 
            onChange={(checked) => onUpdate({ salaryMode: checked })} 
            boxSize="3.875rem" 
            padding='2rem' 
            boxBorderRadius="8px"   
          />
        </Field>
      </section>

      {showRightSection && (
        <section>
          {type !== 'absences' && admin && (
            <ExportButton
              iconLeftProps={{ icon: 'download' }}
              fullWidth={isMobileOnly}
              onClick={onOpenExportModal}
            >
              {/* TODO not correct icon */}
              {isMobileOnly ? (
                translations.export
              ) : (
                <span>{translations.export}</span>
              )}
            </ExportButton>
          )}
          {canAdd && (
            <>
              {type === 'timeentries' ? (
                <PlainLink to={{pathname: `/time-entries/register`, state: {initialUser: filter.user}}}>
                  {isMobileOnly ? (
                    <Button padding="0 1rem !important">
                      <Icon icon="plus" margin="0" />
                    </Button>
                  ) : (
                    <Button iconLeftProps={{ icon: 'plus' }}>
                      <span>{translations.registerHours}</span>
                    </Button>
                  )}
                </PlainLink>
              ) : (
                <>
                  {isMobileOnly ? (
                    <Button
                      padding="0 1rem !important"
                      onClick={handleCreateAbsence}
                      disabled={mutationsLoading}
                      fullWidth
                    >
                      <Icon icon="plus" margin="0 1rem 0 0" />
                      {translations.registerAbsence}
                    </Button>
                  ) : (
                    <Button
                      iconLeftProps={{ icon: 'plus' }}
                      onClick={handleCreateAbsence}
                    >
                      <span>{translations.registerAbsence}</span>
                    </Button>
                  )}
                </>
              )}
            </>
          )}
        </section>
      )}
    </Wrapper>
  )
}
