import { TimeTuple } from './types'

export const fullRegex = /^(\d{1,2}):?(\d{1,2}?:?)(\d{1,2})?\.?\d*/g
export const fullRegexStrict = /^\d{1,2}:\d{1,2}:\d{1,2}\.\d*/g
export const twoDigitRegex = /(\d{1,2})/g

export function wrap(number: number, max: number) {
  return (number + max) % max
}

/**
 * Checks heuristically whether or not we can deduce a time directly. This is the case in scenarios like
 *    1 → 01:00
 *    15 → 15:00
 *    2300 → 23:00
 *    15:00:01.34234 → 15:00:01.34234
 * @param value Time string to check
 */
export function heuristicIsATimeString(value: string) {
  if (value.length <= 3 && value.match(twoDigitRegex)) return true
  return !!value.match(fullRegex)
}

export function normalizeTuple(tuple: number[] | string[]): TimeTuple {
  const baseArray = new Array(4).fill(0)
  const mapped = baseArray.map((_, index) =>
    index >= tuple.length ? 0 : parseInt(tuple[index] + '')
  )

  return [
    (mapped[0] + 24) % 24,
    (mapped[1] + 60) % 60,
    (mapped[2] + 60) % 60,
    (mapped[3] + 1e9) % 1e9,
  ]
}
