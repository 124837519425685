import { useTranslate } from '@ur/react-hooks'
import { IconShortcut, IconShortcutProps, ShortcutsWrapper } from 'components'
import styled from 'styled-components'

const Wrapper = styled.section`
  max-width: 100%;
`

interface DashboardLinksProps {
  links: IconShortcutProps[]
}

export const DashboardLinks: React.VFC<DashboardLinksProps> = ({ links }) => {
  const translations = useTranslate({
    handbook: 'common.drivers-handbook',
  })
  return (
    <Wrapper>
      <h2>{translations.handbook}</h2>
      <ShortcutsWrapper>
        {links.map(link => (
          <IconShortcut {...link} />
        ))}
      </ShortcutsWrapper>
    </Wrapper>
  )
}
