import { SpinnerLoader } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { RoutePlanInstructionNode } from 'modules/routePlanner/types.graphql'
import styled from 'styled-components'
import { RouteInstructionItem } from './RouteInstructionItem'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`

const CenteredWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`

interface RouteInstructionListProps {
  routeInstructions: RoutePlanInstructionNode[]
  loading: boolean
}

export const RouteInstructionList: React.VFC<RouteInstructionListProps> = ({
  routeInstructions,
  loading,
}) => {
  const translations = useTranslate({
    noRouteInstructions: 'route-planner.no-route-instructions',
  })

  if (loading) {
    return (
      <CenteredWrapper>
        <SpinnerLoader />
      </CenteredWrapper>
    )
  }

  if (routeInstructions.length === 0) {
    return <div>{translations.noRouteInstructions}</div>
  }
  return (
    <Wrapper>
      {routeInstructions.map((routeInstruction, index) => (
        <RouteInstructionItem key={index} routeInstruction={routeInstruction} />
      ))}
    </Wrapper>
  )
}
