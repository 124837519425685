import { SalaryModuleType, SalaryModule } from 'modules/companies'
import React from 'react'
import styled from 'styled-components'
import { useModuleConfigs, useSalaryModuleTranslations } from '../../hooks'
import { ZIndexRange } from 'types/style'
import { DetectBlur } from 'containers/util-containers'

const Wrapper = styled.aside<{ color?: string }>`
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  z-index: ${ZIndexRange.Dropdowns};

  background-color: ${props => props.theme.colors.secondary};
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  color: ${props => props.color ?? 'inherit'};
  overflow: hidden;
  cursor: pointer;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 3px 2rem 0;

    font-weight: 600;

    & + div {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
`

interface SelectSalaryModuleType {
  color?: string
  exclude?: SalaryModuleType[]
  open: boolean
  onClose: () => void
  onSubmit: (type: SalaryModuleType) => void
}

export const SelectSalaryModule: React.VFC<SelectSalaryModuleType> = ({
  color,
  exclude,
  open,
  onClose,
  onSubmit,
}) => {
  const translations = useSalaryModuleTranslations()
  const { modules } = useModuleConfigs()

  if (!open) return null

  const moduleConfigs = modules.filter(
    moduleConfig =>
      moduleConfig.active &&
      Object.values(SalaryModule).includes(moduleConfig.name)
  )

  return (
    <DetectBlur active={open} onClickOutside={() => onClose()}>
      <Wrapper color={color}>
        {moduleConfigs.filter((module) => !exclude?.includes(module.name)).map(module => (
          <div key={module.name} onClick={() => onSubmit(module.name)}>
            {translations(module.name)}
          </div>
        ))}
      </Wrapper>
    </DetectBlur>
  )
}
