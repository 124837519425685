import { useToast } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { Select } from 'components'
import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import styled from 'styled-components'
import { useLanguage, useUser } from 'util/hooks'
import { PatchUserMutation, PatchUserMutationVariables } from '..'
import { PATCH_USER_MUTATION } from '../mutations'
import { Card } from './components'
import { useLanguageOptions } from 'locale/hooks'

const Wrapper = styled(Card)`
  grid-column: 2;
  grid-row: 2;
`

interface ChangeLanguageProps {}

export const ChangeLanguage: React.VFC<ChangeLanguageProps> = () => {
  const translations = useTranslate({
    changeLanguage: 'common.change-language',

    success: {
      language: 'settings.toasts.change-language-success',
    },
    error: {
      language: 'settings.errors.change-language',
    },
  })

  const me = useUser()
  const addToast = useToast()
  const { languageOptions } = useLanguageOptions()

  const [currentLanguage, updateCurrentLanguage] = useLanguage()
  const [language, setLanguage] = useState(currentLanguage)

  const [patchUser] = useMutation<
    PatchUserMutation,
    PatchUserMutationVariables
  >(PATCH_USER_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['Me'],
    onCompleted(data) {
      if (data) addToast('success', translations.success.language)
      else addToast('error', translations.error.language)
    },
  })

  async function handleChangeLanguage(value: string | null) {
    if (!value) return
    setLanguage(value)

    try {
      await patchUser({
        variables: {
          id: me.id,
          input: {
            language: value.toUpperCase(),
          },
        },
      })
      updateCurrentLanguage(value)
    } catch {
      setLanguage(currentLanguage)
    }
  }

  return (
    <Wrapper>
      <h2>{translations.changeLanguage}</h2>

      <Select
        value={language}
        options={languageOptions}
        fullWidth
        onChange={handleChangeLanguage}
      />
    </Wrapper>
  )
}
