import { useTranslate } from '@ur/react-hooks'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { TimesheetsPeriodReport } from '../types.graphql'
import { TableSummation } from 'components/Table/TableSummation'
import { isMobile } from 'react-device-detect'

interface TimesheettableSummationProps {
  periodReport: TimesheetsPeriodReport
  projectActive: boolean
}
const Summation = styled(TableSummation)`
  height: 5.5rem;

  td {
    padding-top: 1rem !important;
  }

  &:hover {
    background: inherit !important;
  }

  ${props => props.theme.media.mobile} {
    td:first-of-type div {
      margin-left: 1rem !important;
    }
    td:last-of-type div {
      margin-right: 1rem !important;
    }
  }
`
export const TimesheetTableSummation: React.VFC<TimesheettableSummationProps> =
  ({ periodReport, projectActive }) => {
    const translations = useTranslate({
      sum: 'common.sum',
      ordinary: 'common.ordinary',
      hours: 'common.work-hours',
      pause: 'common.pause',
      overtime: 'common.overtime',
    })

    const summationColumns = useMemo(() => {
      return (
        <>
          <td key="sum">
            <div className="sum">{translations.sum}</div>
          </td>
          <td key="filler1">
            <div />
          </td>
          <td key="filler">
            <div />
          </td>
          <td key="workHours">
            <div>
              <span>{translations.hours}</span>
              {periodReport.totalOrdinary}
            </div>
          </td>
          <td key="pause">
            <div>
              <span>{translations.pause}</span>
              {periodReport.totalPause}
            </div>
          </td>
          <td key="overtime">
            <div>
              <span>{translations.overtime}</span>
              {periodReport.overtime}
            </div>
          </td>
          {!isMobile &&
            <>
            <td>
              <div />
            </td>
            <td>
              <div />
            </td>
            {projectActive && (
              <td>
                <div />
              </td>
            )}
          </>
          }
          
          <td key="comment">
            <div></div>
          </td>
          <td>
            <div />
          </td>
        </>
      )
    }, [translations.hours, translations.sum, translations.pause, translations.overtime, periodReport.totalOrdinary, periodReport.totalPause, periodReport.overtime, projectActive])

    return <Summation>{summationColumns}</Summation>
  }
