import React from 'react'

export const House: React.VFC<React.SVGAttributes<SVGSVGElement>> = props => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 164.785 164.785"
    enableBackground="new 0 0 164.785 164.785"
    xmlSpace="preserve"
    fill="#003057"
    {...props}
  >
    <path
      className="st0"
      d="M23.801,136.172c-1.655,0-3.001-1.345-3.001-3V47.236c0-1.358,0.916-2.549,2.226-2.897l58.593-15.625
	c0.253-0.067,0.514-0.101,0.773-0.101c0.26,0,0.521,0.034,0.776,0.101l58.589,15.625c1.312,0.348,2.227,1.54,2.227,2.897v85.936
	c0,1.655-1.346,3-3.001,3H23.801z M117.548,67.673c1.655,0,3.001,1.345,3.001,3v59.498h17.435V49.545L82.393,34.719l-55.59,14.826
	v80.625h17.434V70.673c0-1.655,1.346-3,3.002-3H117.548z M50.239,130.17h64.308v-25.246H50.239V130.17z M50.239,98.921h64.308
	v-9.622H50.239V98.921z M50.239,83.298h64.308v-9.623H50.239V83.298z"
    />
  </svg>
)
