import { Card } from 'components/Card'
import styled from 'styled-components'

export const ContentWrapper = styled(Card)`
  padding: 0;
  overflow: hidden;

  & > article {
    margin: 0;
    padding: 2rem;

    & + article {
      border-top: 1px solid ${props => props.theme.colors.gray8};
    }
  }

  ${props => props.theme.media.mobile} {
    & > article {
      padding: 1rem;
    }
  }
`
export const Description = styled.article`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;

  & > p {
    align-self: center;
    margin: 0;
    white-space: pre-wrap;
  }
`
export const EditButtons = styled.div`
  display: flex;

  button {
    height: 3rem;

    & + button {
      margin-left: 0.5rem;
    }
  }

  ${props => props.theme.media.mobile} {
    justify-content: flex-end;

    button {
      width: 3rem;
      padding: 0;
    }
  }
`
