import React from 'react'

export const Chat: React.VFC<React.SVGAttributes<SVGSVGElement>> = props => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 164.785 164.785"
    enableBackground="new 0 0 164.785 164.785"
    xmlSpace="preserve"
    fill="#003057"
    {...props}
  >
    <g>
      <path
        className="st0"
        d="M116.773,138.414c-0.821,0-1.33-0.218-1.943-0.83l-21.714-20.972H67.981c-8.641,0-17.987-5.972-18.286-17.041
		l-0.051-1.881L39.023,106.4c-0.676,0.326-1.252,0.594-1.728,0.594c-0.56,0-0.758-0.058-0.826-0.106l-0.108-0.106l-0.127-0.059
		c-1.169-0.573-1.712-1.367-1.712-2.501l0.38-19.562l-0.687-0.193c-6.383-1.786-10.044-6.314-10.044-12.425v-33.28
		c0-4.627,1.753-12.391,13.49-12.391h63.234c11.149,0,13.49,6.737,13.49,12.391v20.889h12.373c4.762,0,8.371,1.201,10.727,3.572
		c2.75,2.766,3.141,6.478,3.129,8.446v29.225c0,9.109-5.827,15.718-13.856,15.718h-7.212v19.029c0,0.988-0.479,2.171-1.53,2.432
		l-0.248,0.063l-0.181,0.18C117.51,138.395,117.463,138.414,116.773,138.414z M64.624,65.225c-0.966,0-9.439,0.171-9.439,6.111
		v28.091c0,9.498,8.352,12.007,12.767,12.007h26.257c0.823,0,1.331,0.216,1.944,0.828L114,129.308v-15.101
		c0-0.823,0.217-1.331,0.831-1.945c0.597-0.598,1.142-0.83,1.942-0.83h9.985c5.71,0,8.311-5.274,8.311-10.173V72.071
		c0-3.279-0.642-6.478-8.311-6.478L64.624,65.225z M37.661,31.916c-7.336,0-7.944,3.237-7.944,6.845v33.28
		c0,4.483,2.766,7.031,8.22,7.573c1.41,0.286,2.497,1.448,2.497,2.749l-0.419,16.033l9.651-7.897V71.28
		c0-0.445,0.026-0.915,0.078-1.359l0.122-1.03h-4.45c-1.71,0-2.773-1.062-2.773-2.773s1.062-2.773,2.773-2.773h7.722l0.311-0.212
		c2.752-2.278,6.626-3.482,11.202-3.482h44.186V38.762c0-4.109-1.351-6.845-7.945-6.845H37.661z"
      />
      <path
        className="st0"
        d="M75.004,98.476c-1.71,0-2.773-1.062-2.773-2.773s1.062-2.773,2.773-2.773h44.364
		c1.71,0,2.774,1.062,2.774,2.773s-1.062,2.773-2.773,2.773H75.004z"
      />
      <path
        className="st0"
        d="M108.284,83.669c-1.71,0-2.773-1.062-2.773-2.773s1.062-2.773,2.773-2.773h11.084
		c1.71,0,2.774,1.062,2.774,2.773s-1.063,2.773-2.774,2.773H108.284z"
      />
      <path
        className="st0"
        d="M75.004,83.698c-1.71,0-2.774-1.062-2.774-2.773s1.063-2.773,2.774-2.773h18.502
		c1.71,0,2.773,1.062,2.773,2.773s-1.062,2.773-2.773,2.773H75.004z"
      />
      <path
        className="st0"
        d="M45.418,54.113c-1.71,0-2.773-1.062-2.773-2.773c0-1.71,1.062-2.773,2.773-2.773h3.695
		c1.71,0,2.774,1.062,2.774,2.773c0,1.71-1.063,2.773-2.774,2.773H45.418z"
      />
      <path
        className="st0"
        d="M71.309,54.113c-1.71,0-2.774-1.062-2.774-2.773c0-1.71,1.063-2.773,2.774-2.773h18.502
		c1.71,0,2.773,1.062,2.773,2.773c0,1.71-1.062,2.773-2.773,2.773H71.309z"
      />
    </g>
  </svg>
)
