import { gql } from '@apollo/client'

export const CREATE_FORM_TEMPLATE_MUTATION = gql`
  mutation CreateFormTemplate($input: CreateFormTemplateInput!) {
    createFormTemplate(input: $input) {
      formTemplate {
        id
      }
    }
  }
`

export const UPDATE_FORM_TEMPLATE_MUTATION = gql`
  mutation UpdateFormTemplate($id: ID!, $input: UpdateFormTemplateInput!) {
    updateFormTemplate(id: $id, input: $input) {
      formTemplate {
        id
      }
    }
  }
`

export const PATCH_FORM_TEMPLATE_MUTATION = gql`
  mutation PatchFormTemplate($id: ID!, $input: PatchFormTemplateInput!) {
    patchFormTemplate(id: $id, input: $input) {
      formTemplate {
        id
      }
    }
  }
`

export const DELETE_FORM_TEMPLATE_MUTATION = gql`
  mutation deleteFormTemplate($id: ID!) {
    deleteFormTemplate(id: $id) {
      found
      deletedId
    }
  }
`

export const CREATE_FILLED_OUT_FORM_MUTATION = gql`
  mutation CreateFilledOutForm($input: CreateFilledOutFormInput!) {
    createFilledOutForm(input: $input) {
      filledOutForm {
        id
      }
    }
  }
`

export const UPDATE_FILLED_OUT_FORM_MUTATION = gql`
  mutation UpdateFilledOutForm($id: ID!, $input: UpdateFilledOutFormInput!) {
    updateFilledOutForm(id: $id, input: $input) {
      filledOutForm {
        id
      }
    }
  }
`
export const DELETE_FILLED_OUT_FORM = gql`
  mutation DeleteFilledOutForm($id: ID!) {
    deleteFilledOutForm(id: $id) {
      found
      deletedId
    }
  }
`

export const TOGGLE_FORM_RESPONSE_VERIFICATION_MUTATION = gql`
  mutation ToggleFormResponseVerification($id: ID!, $verify: Boolean) {
    toggleFormResponseVerification(id: $id, verify: $verify) {
      response {
        id
      }
    }
  }
`


export const EXPORT_FILLED_OUT_FORMS = gql`
  mutation ExportFilledOutForms(
    $dateEnd: String,
    $dateStart: String,
    
    $filledOutFormId: ID,
    $filterFieldId: ID,
    $salaryCodeId: String,
    $summationFieldId: ID,
    $distributionFieldId: ID,

    $exportFormat: ExportFormat,
  ) {
    exportFilledOutForms(
      dateEnd: $dateEnd,
      dateStart: $dateStart,

      filledOutFormId: $filledOutFormId,
      filterFieldId: $filterFieldId,
      salaryCodeId: $salaryCodeId,
      summationFieldId: $summationFieldId,
      distributionFieldId: $distributionFieldId,

      exportFormat: $exportFormat,
    ) {
      ok
    }
  }
`