import styled from 'styled-components'
import { UseFormReturn, useTranslate } from '@ur/react-hooks'
import { Checkbox, DatePicker, TextArea, TimePicker, UserSelect } from 'components'
import { Form, FormField } from 'components/Form'
import { parseISO, set } from 'date-fns'
import { TimeEntryFormValues, TimeEntryFormDates } from '../types'
import { TimeEntryProjectSelect } from 'components/Select/TimeEntryProjectSelect'
import { useAdmin, useModuleConfig } from 'util/hooks'
import {
  Module,
  ModuleOptionsValue,
} from 'modules/companies'
import { SalaryCodeSelect } from 'components/Select/SalaryCodeSelect'
import { TimeEntryNode, TimesheetsReportSalaryWorkTimeNode } from '../types.graphql'
import { DepartmentSelect } from 'components/Select/DepartmentSelect'

const StyledForm = styled(Form)`
  width: 100%;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const StyledFormField = styled(FormField)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  label {
    color: ${props => props.theme.colors.gray3};
    font-weight: 600;
    margin: 0 0 0.5rem 0;
  }
`

const DateTimeField = styled(StyledFormField)`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.5rem;

  label {
    grid-row: 1;
    grid-column: 1 / 3;
    margin: 0 0 0 0;
  }
  ${props => props.theme.media.mobile} {
    .--time-picker-inner-wrapper {
      width: 80px;
      padding: 0;
    }
  }
`
const ErrorDisplay = styled(FormField).attrs({
  area: 'error',
})`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.75rem;
  display: flex;
  justify-content: flex-end;

  div.error {
    display: flex;
    align-items: center;

    margin-right: 1rem;
    color: ${props => props.theme.colors.red};
  }

  ${props => props.theme.media.mobile} {
    flex-direction: column;

    div.error {
      justify-content: center;
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }
`

interface TimeEntryFormProps {
  onSubmit: (values: TimeEntryFormValues) => void
  form: UseFormReturn<TimeEntryFormValues>
  overtimeCustomChoice?: boolean
  salaryWorkTime: TimesheetsReportSalaryWorkTimeNode
  existingTimeEntries: Omit<TimeEntryNode, "company">[]
}

export const TimeEntryForm: React.VFC<TimeEntryFormProps> = ({
  onSubmit,
  form,
  overtimeCustomChoice,
  salaryWorkTime,
  existingTimeEntries
}) => {
  const translations = useTranslate({
    user: 'common.user',
    project: 'common.project',
    department: 'common.department',
    newTimeEntry: 'timesheets.new-time-entry',
    createTimeEntry: 'timesheets.register-timeentry',
    from: 'common.from',
    until: 'common.until',
    comment: 'common.comment',
    needsComment: 'common.needs-comment',
    overtimeMode: 'common.overtime-mode',
    diet: 'common.diet',
    additionalPay: 'common.additional-pay',
    pause: 'common.pause',

    cancel: 'common.cancel',
    submit: 'common.submit',
    success: 'timesheets.success-registration-timeentry',

    error: {
      create: 'timesheets.error-create-timeentry',
      startBeforeEnd: 'timesheets.error.start-before-end',
      required: 'common.required',
      overtimeMode: 'timesheets.error.overtime-mode-required',
    },
    warning: {
      create: 'timesheets.warning.time-entry-exist-in-daterange',
    },
  })

  const admin = useAdmin()

  const { getModuleOptionStatus } = useModuleConfig(Module.TIMESHEETS)

  const {
    formValues: values,
    formErrors: errors,
    formChangeHandler: handler,
    updateForm,
    submitHandler,
  } = form
  function updateTime(key: keyof TimeEntryFormDates) {
    return (value: Date) => {
      const existingValue = values[key]
      const newValue = set(existingValue, {
        hours: value.getHours(),
        minutes: value.getMinutes(),
      })

      updateForm({
        [key]: newValue,
        salaryCodeId: null,
      })
    }
  }
  function updateDate(key: keyof TimeEntryFormDates) {
    return (value: Date) => {
      const existingValue = values[key]
      const newValue = set(existingValue, {
        year: value.getFullYear(),
        month: value.getMonth(),
        date: value.getDate(),
      })

      let newEnd: { end?: Date } = {}
      if (key === 'start')
        newEnd = {
          end: set(values.end, {
            year: value.getFullYear(),
            month: value.getMonth(),
            date: value.getDate(),
          }),
        }

      updateForm({
        ...newEnd,
        [key]: newValue,
        salaryCodeId: null,
      })
    }
  }

  const projectActive = getModuleOptionStatus(
    ModuleOptionsValue.TIMESHEETS_PROJECTS
  )

  const existingWorkingMs = existingTimeEntries.reduce(
    (acc, entry) => acc + (parseISO(entry.datetimeEnd).getTime() - parseISO(entry.datetimeStart).getTime()),
    0
  )
  const thisWorkingMs = (values.end.getTime() - values.start.getTime())
  const totalWorkingSeconds = (existingWorkingMs + thisWorkingMs) / 1000

  const overtimeCustomChoiceActive =
    overtimeCustomChoice &&
    totalWorkingSeconds > salaryWorkTime.dailyWorkLimit
  
  const errorActive = (!!errors.start || !!errors.end) || (overtimeCustomChoiceActive && form.formValues.salaryCodeId === null)

  return (
    <StyledForm
      preventDefault
      onSubmit={submitHandler(onSubmit)}
    >
      {admin &&(
        <StyledFormField>
          <label>{translations.user}</label>
          <UserSelect value={form.formValues.userId} onChange={form.formChangeHandler('userId')} grayed/>
        </StyledFormField>
      )}
      <DateTimeField>
        <label>{translations.from}</label>
        <TimePicker
          value={values.start}
          width="100px"
          onChange={updateTime('start')}
          grayed
        />
        <DatePicker
          value={values.start}
          fullWidth
          onChange={updateDate('start')}
          grayed
        />
      </DateTimeField>

      <DateTimeField>
        <label>{translations.until}</label>
        <TimePicker
          value={values.end}
          width="100px"
          onChange={updateTime('end')}
          grayed
        />
        <DatePicker
          value={values.end}
          fullWidth
          onChange={updateDate('end')}
          grayed
        />
      </DateTimeField>
      {totalWorkingSeconds >= salaryWorkTime.pauseThreshold && (
        <StyledFormField>
          <label>{translations.pause}</label>
          <TimePicker
            value={values.pause}
            width="100%"
            onChange={handler('pause')}
            grayed
          />
          {!!errors.pause &&
            <ErrorDisplay>
              <div className="error">{errors.pause}</div>
            </ErrorDisplay>
          }
        </StyledFormField>
      )}
      {overtimeCustomChoiceActive && (
        <StyledFormField>
          <label>{translations.additionalPay}</label>
            <SalaryCodeSelect
              fullWidth
              nullable
              placeholder={translations.additionalPay}
              value={form.formValues.salaryCodeId}
              showInManualTimeEntryOvertime={true}
              onChange={handler('salaryCodeId')}
              grayed
            />
            {errorActive &&
              <ErrorDisplay>
                {(!!errors.start || !!errors.end) && (
                  <div className="error">{errors.start ?? errors.end}</div>
                )}
                {overtimeCustomChoiceActive &&
                  form.formValues.salaryCodeId === null && (
                    <div className="error">{translations.error.overtimeMode}</div>
                  )}
              </ErrorDisplay>
            }
        </StyledFormField>
      )}
      <StyledFormField>
        <label>{translations.comment}</label>
        <TextArea
          value={values.comment}
          placeholder={translations.needsComment}
          onChange={handler('comment')}
          fullWidth
        />
      </StyledFormField>
      <StyledFormField>
        <label>{translations.diet}</label>
        <Checkbox checked={values.diet} onChange={handler('diet')} grayed />
      </StyledFormField>
      <StyledFormField>
        <label>{translations.department}</label>
        <DepartmentSelect 
          onChange={handler('department')} 
          value={values.department} 
          error={form.formErrors.department} 
          grayed 
          nullable
          searchable
          dataset={salaryWorkTime.availableDepartments}
          alwaysInclude={salaryWorkTime.defaultDepartment ?? undefined}
        />
      </StyledFormField>
      {projectActive && (
        <StyledFormField>
          <label>{translations.project}</label>
          <TimeEntryProjectSelect
            value={values.project}
            onChange={handler('project')}
            background="quaternary"
            optionHoverBackground="white"
            grayed
            nullable
          />
        </StyledFormField>
      )}
    </StyledForm>
  )
}
