import { useTranslate } from '@ur/react-hooks'
import { Title } from 'components'
import { RoutePlanNode } from 'modules/routePlanner/types.graphql'
import styled from 'styled-components'
import { grapheneTimeDeltaToNumeric } from 'util/time'

interface WrapperProps {
  gridArea: string
}

const Wrapper = styled.div<WrapperProps>`
  ${props => props.theme.defaultContentWrapper};
  grid-area: ${props => props.gridArea};
  padding-bottom: 0;
`

export const RouteDetails: React.FC<{
  routePlan: Pick<
    RoutePlanNode,
    'routeLength' | 'estimatedDuration' | 'taskStatus' | 'result'
  >
  gridArea: string
}> = ({ routePlan, gridArea }) => {
  const translations = useTranslate({
    distance: 'common.distance',
    details: 'common.details',
    hours: 'common.hours',

    estimatedDrivingDuration: 'route-planner.estimated-driving-duration',
  })
  return (
    <Wrapper gridArea={gridArea}>
      <Title.H2>{translations.details}</Title.H2>
      <p>
        {`${translations.distance}: ${Math.round(
          routePlan.routeLength / 1000
        )} km`}
      </p>
      <p>
        {`${translations.estimatedDrivingDuration}: ${
          grapheneTimeDeltaToNumeric(routePlan.estimatedDuration, 'hours') ?? 0
        } ${translations.hours.toLowerCase()}`}
      </p>
      {/* Below is for testing and debugging purposes
          TODO: REmove in production in favor of status icon or similar
      */}
      <p>
        Task status:{' '}
        <b>{routePlan?.result ? routePlan.result : routePlan?.taskStatus}</b>
      </p>
    </Wrapper>
  )
}
