export function generateRandomInteger(min: number, max: number) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export function generateRandomColor() {
  const hue = Math.round(generateRandomInteger(1, 10000) * 137.6)
  const sat = generateRandomInteger(76, 84)
  const lum = generateRandomInteger(76, 84)
  return `hsl(${hue}deg, ${sat}%, ${lum}%)`
}
