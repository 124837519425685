import { Icon } from '@ur/react-components'
import {
  RoutePlanInstructionActionValue,
  RoutePlanInstructionDirectionValue,
  RoutePlanInstructionIcon,
} from 'modules/routePlanner/consts'
import { RoutePlanInstructionNode } from 'modules/routePlanner/types.graphql'

export const RouteInstructionIcon: React.VFC<{
  routeInstruction: RoutePlanInstructionNode
}> = ({ routeInstruction }) => {
  const getIconKey = (instruction: RoutePlanInstructionNode) => {
    if (instruction.action === RoutePlanInstructionActionValue.TURN) {
      //  TODO: Adjust this for turn severity etc.
      return instruction.direction === RoutePlanInstructionDirectionValue.LEFT
        ? 'turnLeft'
        : 'turnRight'
    }
    return instruction.action
  }
  const icon = RoutePlanInstructionIcon[getIconKey(routeInstruction)]

  return <Icon icon={icon} />
}
