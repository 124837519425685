import { JSONValue, useForm, useTranslate } from '@ur/react-hooks'
import { ModuleTitle, MonthPicker } from 'components'
import { Card } from 'components/Card'
import { SelectMultipleUsers } from 'components/Select/SelectMultipleUsers'
import { DropdownNames } from 'components/Select/types'
import { startOfMonth } from 'date-fns'
import {
  isMobileOnly,
  MobileView,
  isDesktop,
  isMobile,
} from 'react-device-detect'
import styled from 'styled-components'
import { useAdmin, useUser } from 'util/hooks'

import { InfringementPopupFilter } from '../components'
import { InfringementsTable } from '../components/table/InfringementsTable'
import { InfringementsTableFilter, SeverityCategory } from '../types'

import { InfringementCategory } from '../types.graphl'

const Wrapper = styled.div`
  ${props => props.theme.layout.default};
`

const FilterSection = styled.section`
  grid-area: filters;

  display: flex;
  gap: 1rem;
  > * {
    max-width: 100%;
  }
  ${props => props.theme.layout.largePad} {
    flex-direction: column;
    > * {
      width: 100%;
    }
  }

  ${props => props.theme.layout.mobile} {
    flex-direction: column;
  }
`


const SelectUsersWrapper = styled.div`
  overflow: visible;
  width: calc(100% - 340px - 250px - 2rem);
  height: 54px;
  ${props => props.theme.layout.largePad} {
    width: 100%;
  }
`

const AreaInfringementPopupFilter = styled.div`
  display: flex;
  width: 250px;
  > div {
    width: 250px;
    > * {
      width: 250px;
      > * {
        width: 250px;
      }
    }
  }
  ${props => props.theme.layout.largePad} {
    width: 100%;
    > div {
      width: 100%;
      > * {
        width: 100%;
        > * {
          width: 100%;
        }
      }
    }
  }
  justify-content: flex-end;
`

const MonthFlexArea = styled.div`
  display: flex;
  width: 340px;
  gap: 1rem;

  ${props => props.theme.layout.largePad} {
    width: 100%;
  }
`

const Controls = styled(Card)`
  width: 100%;
  box-sizing: border-box;
  overflow-x: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
`
const MobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`

export const Infringements: React.VFC = () => {
  const admin = useAdmin()
  const me = useUser()
  const translations = useTranslate({
    infringements: 'common.infringements',
    users: 'common.users',
    user: 'common.user',
    export: 'common.export',
    archived: 'common.archived',

    success: 'toasts.email-sent-successfully',
    error: 'errors.generic-error',
  })

  const {
    formValues: filter,
    formChangeHandler: filterHandler,
    updateForm,
  } = useForm<InfringementsTableFilter>({
    values: {
      query: '',
      orderBy: '-infringementTimestamp',
      userOrderBy: '-firstName',
      excludeSeverities: [],
      excludeCategories: [],
      archived: false,
      users: admin ? [] : [me.id],
      month: new Date(),
    },
    config: {
      storage: {
        storeFormState: true,
        retrieveFormStateOnMount: true,
        sessionStorage: true,
        storeFormStateName: 'firmadok__infringements-overview-filter',
        transformValues: {
          orderBy: value => (typeof value === 'string' ? value : undefined),
          userOrderBy: value => (typeof value === 'string' ? value : undefined),
          users: value => {
            if (Array.isArray(value)) {
              return value.filter(val => typeof val === 'string') as string[]
            }
            return []
          },
          excludeSeverities: value => {
            function isSeverityCategory(
              value: JSONValue
            ): value is SeverityCategory {
              return (
                typeof value === 'string' &&
                ['MINOR', 'SERIOUS', 'VERY_SERIOUS', 'MOST_SERIOUS'].includes(
                  value
                )
              )
            }
            if (Array.isArray(value)) {
              return value.filter(val =>
                isSeverityCategory(val)
              ) as SeverityCategory[]
            }
            return []
          },
          excludeCategories: (value) => {
            if (Array.isArray(value)) {
              return value as InfringementCategory[]
            }
            return []
          },
        },
      },
    },
  })
  const monthStartDate = startOfMonth(filter.month)


  return (
    <Wrapper>
      <MobileView>
        <MobileHeader>
          <ModuleTitle inlineHeader>{translations.infringements}</ModuleTitle>
        </MobileHeader>
      </MobileView>

      {!isMobileOnly && (
        <TitleWrapper>
          <ModuleTitle>
            {translations.infringements}{' '}
            {filter.archived
              ? '(' + translations.archived + ')'
              : ''}
          </ModuleTitle>
        </TitleWrapper>
      )}
      <Controls>
        <FilterSection>
          {isDesktop && admin && (
            <SelectUsersWrapper>
              <SelectMultipleUsers
                width="100%"
                selected={filter.users}
                dropdownPageName={DropdownNames.ACTIVITIES}
                placeholder={translations.user}
                onChange={filterHandler('users')}
              />
            </SelectUsersWrapper>
          )}
          <MonthFlexArea>
            <MonthPicker
              value={monthStartDate}
              disableAfter={true}
              fullWidth
              onChange={filterHandler('month')}
            />
          </MonthFlexArea>
          {!isMobile && (
            <AreaInfringementPopupFilter>
              <InfringementPopupFilter
                filter={filter}
                onUpdate={updateForm}
              />
            </AreaInfringementPopupFilter>
          )}
        </FilterSection>
      </Controls>
      <InfringementsTable filter={filter} onSort={filterHandler('orderBy')} />
    </Wrapper>
  )
}
