import { useTranslate } from '@ur/react-hooks'
import React from 'react'
import styled from 'styled-components'

const Layout = styled.div`
  display: grid;

  grid-template-columns: 1fr 4fr;
  align-items: center;
  grid-gap: 1rem;
`

const Icon = styled.i`
  font-size: 32px;
  color: ${props => props.theme.colors.gray7};
`

const Text = styled.h4`
  color: ${props => props.theme.colors.gray6};
  font-weight: normal;
  text-align: left;
`

interface AppNotificationNoItemsProps {}

const AppNotificationNoItems: React.FC<AppNotificationNoItemsProps> = () => {
  const translations = useTranslate({
    youHaveNoNotifications: 'notifications.you-have-no-notifications',
  })
  return (
    <Layout>
      <Icon className="far fa-smile-beam" />
      <Text>{translations.youHaveNoNotifications}</Text>
    </Layout>
  )
}

export default AppNotificationNoItems
