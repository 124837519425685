import { useQuery } from '@apollo/client'
import { usePrompt } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import {
  ExportButton as BaseButton,
  Checkbox,
  ModuleTitle,
  MonthPicker,
  UserSelect,
} from 'components'
import { Card } from 'components/Card'
import { DropdownNames } from 'components/Select/types'
import {
  addMonths,
  endOfDay,
  endOfMonth,
  formatISO,
  parseISO,
  setDate,
  startOfDay,
  startOfMonth,
} from 'date-fns'
import {
  DriverActivityPeriodReportQuery,
  DriverActivityPeriodReportQueryVariables,
} from 'modules/activities'
import { Field, Module, ModuleOptionsValue } from 'modules/companies'
import {
  ActivitiesWithReceiptCoolumnNode,
  AllDynamicColumnsQuery,
  AllDynamicColumnsQueryVariables,
  ALL_DYNAMIC_COLUMNS_QUERY,
  ColumnNames,
} from 'modules/dynamic_tables'
import React, { useEffect, useMemo, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import { format, getWeek } from 'util/date-fns'
import {
  useAdmin,
  useBreadcrumbs,
  useCompany,
  useModuleConfig,
  useOnErrorAuto,
  usePermissions,
  useUser,
} from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { isInvalidDate } from 'util/time'
import { ExportActivitiesForm, WeekReport } from '..'
import { ActivitiesMonthSummaries, ActivitiesWeekTable } from '../components'
import { CheckmarkDiv } from '../components/overview/CheckmarkDiv'
import { ExportActivitiesModal } from '../components/overview/ExportActivitiesModal'
import { useActivityApprovedHandlers } from '../hooks'
import { useActivityExportMutations } from '../mutations.hooks'
import { DRIVER_ACTIVITY_PERIOD_REPORT_QUERY, DRIVER_ACTIVITY_PERIOD_REPORT_SHALLOW_QUERY } from '../queries'

const Wrapper = styled.div`
  ${props => props.theme.layout.default};
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const Controls = styled(Card)`
  display: grid;
  grid-template-columns: 1fr auto;

  margin-bottom: 1rem;
  padding: 0;

  section {
    display: grid;
    grid-template-columns: 1fr auto auto;
    gap: 1rem;
    padding: 1rem;

    & + section {
      grid-template-columns: auto;
      border-left: 1px solid ${props => props.theme.colors.gray8};
      align-items: center;
    }
  }

  ${props => props.theme.media.mobile} {
    grid-template-columns: 1fr;

    section {
      grid-template-columns: 1fr;
      padding: 1rem;

      & + section {
        padding-top: 0;
        border-left: 0;
      }
    }
  }
`
const ExportButton = styled(BaseButton)`
  ${props => props.theme.media.mobile} {
    padding: 1rem !important;
    text-align: center;
    display: flex;
    justify-content: center;

    .--button-content {
      width: auto;
    }
  }

  ${props => props.theme.media.custom({ min: '769px', max: '1250px' })} {
    padding: 1rem 0.4rem 1rem 2rem !important;

    span {
      display: none;
    }
  }
`
const Weeks = styled.div`
  h2 {
    margin: 1.5rem 0 1rem 8px;
    font-weight: 600;
  }
`

interface ActivitiesOverviewProps {}

export const ActivitiesOverview: React.VFC<ActivitiesOverviewProps> = () => {
  const translations = useTranslate({
    activities: 'common.activities',
    export: 'common.export',
    weekN: ['common.week-n', { n: 0 }],

    sumForMonth: ['activities.sum-for-month-title', { month: '' }],
    driving: 'activities.driving',
    otherWork: 'common.other-work',
    overtime: 'activities.sum-overtime',
    available: 'activities.availability-time',

    toast: {
      mailSuccess: 'exports.success-email-process',
    },
    error: {
      generic: 'error.generic-server-error',
    },

    salaryMode: 'common.salary-mode',
    month: 'common.month',
    user: 'common.user',
  })

  const { handler: approveHandler } = useActivityApprovedHandlers()

  const { userId } = useParams<{ userId: string }>()
  const history = useHistory()
  const { setOverride } = useBreadcrumbs()
  const addPrompt = usePrompt()
  const { hasPermissions } = usePermissions()
  const company = useCompany()
  const me = useUser()
  const admin = useAdmin()
  const canAdd = hasPermissions(
    PERMISSIONS.companies.export.exportReports,
    true
  )
  const [filterStorageStateLoading, setFilterStorageStateLoading] =
    useState(true)

  const { getModuleOptionStatus } = useModuleConfig(Module.ACTIVITIES)
  const hasReceiptFunctionality = getModuleOptionStatus(
    ModuleOptionsValue.ACTIVITIES_RECEIPT_FUNCTIONALITY
  )
  const isMe = me?.id === userId
  const canCheck = (
    isMe && hasPermissions(PERMISSIONS.activities.approve.driveractivity, true)
  ) || (
    !isMe && hasPermissions(PERMISSIONS.activities.approve.othersdriveractivity, true)
  )
  const canSeeReceipt = (admin || userId === me.id) && hasReceiptFunctionality
  const canSeeOthers = hasPermissions(PERMISSIONS.activities.change.driveractivity, true)
  const onErrorAuto = useOnErrorAuto()

  const {
    formValues: filter,
    updateForm,
    formChangeHandler: handler,
    loadStoredFormState,
    updateInitialValues,
  } = useForm({
    values: {
      user: userId ?? me.id,
      month: new Date(),
      salaryMode: false,
    },
    config: {
      callbacks: {
        user: updateUrl,
      },
      storage: {
        storeFormState: true,
        excludeFields: ['user'],
        sessionStorage: true,
        storeFormStateName: 'firmadok__activities-overview-filter',
        transformValues: {
          month: val =>
            typeof val === 'string'
              ? isInvalidDate(new Date(val), new Date())
              : undefined,
          salaryMode: val => {
            return (typeof val === 'boolean') ? val : false
          },
        },
      },
    },
  })

  const filterUser = useUser(filter.user, Module.ACTIVITIES)

  const [startDateCalculation, endDateCalculation] = useMemo((): [Date, Date] => {
    // If salary view is active, we need to set interval according to salary start and end dates.
    if (filter.salaryMode && filterUser.salaryWorkTime) {
      const baseDate = startOfDay(filter.month)
      const salaryStart = filterUser.salaryWorkTime?.salaryDateStart ?? 1
      const salaryEnd = filterUser.salaryWorkTime?.salaryDateEnd ?? 1
      
      const forward = (31 - salaryStart) > 15

      // What salary period is connected to which month is based on the salary start date.
      if (forward) {
        const startMonthYearTime = setDate(baseDate, salaryStart)
        const endMonthYearTime = (salaryStart === 1) ? endOfMonth(baseDate) : setDate(addMonths(baseDate, 1), salaryEnd)

        return [startMonthYearTime, endOfDay(endMonthYearTime)]
      }

      const startMonthYearTime = setDate(addMonths(baseDate, -1), salaryStart)
      const endMonthYearTime = setDate(baseDate, salaryEnd)

      return [startMonthYearTime, endOfDay(endMonthYearTime)]
    } else {
      // If salary view is not active, we need to set interval according to the month.
      const startMonthYearTime = startOfMonth(filter.month)
      const endMonthYearTime = endOfMonth(filter.month)
  
      return [startMonthYearTime, endMonthYearTime]
    }
  }, [filter.month, filter.salaryMode, filterUser.salaryWorkTime])


  useEffect(() => {
    /* Ensuring the query for ActivityReport is only run once on render. */
    if (filterStorageStateLoading) {
      const loadedFormData = loadStoredFormState()
      if (loadedFormData) {
        updateInitialValues(loadedFormData)
      }
      setFilterStorageStateLoading(false)
    }
  }, [filter.month, filterStorageStateLoading, updateInitialValues, loadStoredFormState])

  const { data: paddedData, loading } = useQuery<
    DriverActivityPeriodReportQuery,
    DriverActivityPeriodReportQueryVariables
  >(DRIVER_ACTIVITY_PERIOD_REPORT_QUERY, {
    skip: filterStorageStateLoading,
    variables: {
      user: userId,
      dateStart: formatISO(startDateCalculation),
      dateEnd: formatISO(endDateCalculation),
    },
    onCompleted(data) {
      if (!data) return
      setOverride(data.user.id, data.user.fullName)
    },
    onError: onErrorAuto(),
  })

  const { data: calculationRangeData, loading: monthLoading } = useQuery<
    DriverActivityPeriodReportQuery,
    DriverActivityPeriodReportQueryVariables
  >(DRIVER_ACTIVITY_PERIOD_REPORT_SHALLOW_QUERY, {
    skip: filterStorageStateLoading,
    variables: {
      user: userId,
      dateStart: formatISO(startDateCalculation),
      dateEnd: formatISO(endDateCalculation),
      weekPartitions: false,
    },
    onCompleted(data) {
      if (!data) return
      setOverride(data.user.id, data.user.fullName)
    },
    onError: onErrorAuto(),
  })

  const { data: columnData } = useQuery<
    AllDynamicColumnsQuery<ActivitiesWithReceiptCoolumnNode>,
    AllDynamicColumnsQueryVariables
  >(ALL_DYNAMIC_COLUMNS_QUERY, {
    variables: {
      moduleName: Module.ACTIVITIES,
    },
    onError: onErrorAuto(),
  })

  const { exportActivities, loading: mailLoading } =
    useActivityExportMutations()

  function getColumns(withReceipt: boolean) {
    let columns = columnData?.allDynamicColumns.edges
      .map(edge => edge.node)
      .filter(node => node.enabled)
    if (
      !withReceipt &&
      columns?.some(column => column.name === ColumnNames.APPROVED)
    ) {
      return columns.filter(node => !(node.name === ColumnNames.APPROVED))
    } else return columns
  }

  const columns = getColumns(canSeeReceipt)
  const showReceipt =
    canSeeReceipt && columns?.some(column => column.name === ColumnNames.APPROVED)

  function updateUrl(id: string) {
    history.replace(`/activities/${id}`)
  }

  async function doExport(values: ExportActivitiesForm) {
    exportActivities({
      variables: {
        exportType: values.exportType,
        userMail: me.id,
        user: filter.user,
        company: company.id,
        dateStart: formatISO(startDateCalculation),
        dateEnd: formatISO(endDateCalculation),
        exportFormat: values.exportFormat,
        exportOption: values.exportOption,
        weekly: values.weekly,
      },
    })
  }

  async function handleExport() {
    const { data } = await addPrompt<ExportActivitiesForm | null>(resolve => (
      <ExportActivitiesModal onSubmit={resolve} />
    ))
    if (!data) return

    doExport(data)
  }

  function handleOnClickWeek(approve: Boolean, week: WeekReport) {
    if (!canCheck) return
    return () => {
      const startOfWeek = parseISO(week.periodStart)
      const endOfWeek = parseISO(week.periodEnd)
      return approveHandler.week(userId, approve, startOfWeek, endOfWeek)
    }
  }

  const monthApproved =
    paddedData?.report.periodReports.every(periodReport =>
      periodReport.dayReports.every(dayReport =>
        startDateCalculation <= parseISO(dayReport.date) && parseISO(dayReport.date) <= endDateCalculation
          ? dayReport?.approved
          : true
      )
    ) ?? false

  function handleOnClickMonth() {
    if (!canCheck) return
    return approveHandler.month(
      userId,
      !monthApproved,
      startDateCalculation,
      endDateCalculation
    )
  }

  const weeks: WeekReport[] = useMemo(() => {
    const weekReports = paddedData?.report.periodReports ?? []
    return weekReports?.map(weekReport => {
      return {
        ...weekReport,
        approved: weekReport?.dayReports.every(
          dayReport => dayReport?.approved
        ),
        week: getWeek(parseISO(weekReport.periodStart)),
        sums: {
          timeWorkTotal: weekReport.timeWorkTotal,
          timeDriving: weekReport.timeDriving,
          timeOvertime: weekReport.overtime,
          timeAvailability: weekReport.timeAvailability,
          timeRest: weekReport.timeRest,
          timePause: weekReport.timePause,
          timePaidRest: weekReport.timePaidRest,
          timeOtherWork: weekReport.timeOtherWork,
        },
      }
    })
  }, [paddedData?.report])

  const showRightSection = admin || canAdd
  const isLoading = loading || mailLoading

  return (
    <Wrapper>
      <Header>
        <ModuleTitle>{translations.activities} <span>({format(startDateCalculation, 'dd. MMM')} - {format(endDateCalculation, 'dd. MMM')})</span></ModuleTitle>
        {showReceipt && (
          <CheckmarkDiv checked={monthApproved} onClick={handleOnClickMonth} />
        )}
      </Header>
      <Controls>
        <section>
          {canSeeOthers && (
            <Field>
              <label>{translations.user}</label>
              <UserSelect
                value={filter.user}
                dropdownPageName={DropdownNames.ACTIVITIES}
                grayed
                width="100%"
                minWidth={isMobileOnly ? '0' : '260px'}
                height="3.875rem"
                onChange={user => updateForm({ user: user ?? me.id })}
              />
            </Field>
          )}
          <Field>
            <label>{translations.month}</label>
            <MonthPicker
              value={filter.month}
              futureYears={0}
              minWidth={isMobileOnly ? '0' : '380px'}
              width={isMobileOnly ? '100%' : undefined}
              maxWidth={isMobileOnly ? '100%' : '420px'}
              onChange={handler('month')}
            />
          </Field>
          <Field>
            <label>{translations.salaryMode}</label>
            <Checkbox 
              checked={filterUser.salaryWorkTime?.salaryDateStart === 1 ? true : filter.salaryMode} 
              onChange={handler('salaryMode')} 
              boxSize="3.875rem" 
              padding='2rem' 
              boxBorderRadius="8px"
              disabled={filterUser.salaryWorkTime?.salaryDateStart === 1}
            />
          </Field>
        </section>

        

        {showRightSection && (
          <section>
            <ExportButton
              iconLeftProps={{ icon: 'download' }}
              fullWidth={isMobileOnly}
              disabled={isLoading}
              onClick={handleExport}
            >
              {/* TODO not correct icon */}
              <span>{translations.export}</span>
            </ExportButton>
          </section>
        )}
      </Controls>

      <ActivitiesMonthSummaries 
        month={filter.month} 
        report={paddedData?.report} 
        monthReport={calculationRangeData?.report} 
        loading={loading || monthLoading} 
        showOvertime={columns?.some(columns => columns.name === ColumnNames.OVERTIME)}
      />

      <Weeks>
        {weeks?.map(week => (
          <React.Fragment key={week.periodStart}>
            <Header>
              <h2>{translations.weekN({ n: week.week })}</h2>
              {showReceipt && (
                <CheckmarkDiv
                  checked={week.approved}
                  onClick={handleOnClickWeek(!week.approved, week)}
                />
              )}
            </Header>
            <ActivitiesWeekTable
              week={week}
              dateStart={startDateCalculation}
              dateEnd={endDateCalculation}
              userId={filter.user}
              columns={columns}
            />
          </React.Fragment>
        ))}
      </Weeks>
    </Wrapper>
  )
}
