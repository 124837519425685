import { Icon, usePrompt, useToast } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Table, UserSelect } from 'components'
import { PlainLink } from 'components/Link'

import { subWeeks } from 'date-fns'
import {
  AllUserDocumentCategoriesQuery,
  AllUserDocumentsQuery,
  AllUserDocumentsQueryVariables,
  ShallowUserNode,
} from 'modules/users'
import { ALL_USER_DOCUMENTS_QUERY, ALL_USER_DOCUMENT_CATEGORIES_QUERY } from 'modules/users/queries'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { format } from 'util/date-fns'
import { useConfirm, useOnErrorAuto, useUser } from 'util/hooks'
import {
  EditUserDocumentPrompt,
  EditUserDocumentPromptResolve,
  UserDocumentCategoriesSettingsForm,
  UserDocumentCategoryForSettings,
  UserDocumentForSettings,
} from '../components'
import {
  AddButton,
  InfoBox,
  Section,
  SectionHeader,
  SettingsWrapper,
} from '../components/common'
import { useUserDocumentCategoryMutations, useUserDocumentMutations } from '../util/mutations.hooks'
import { UserDocumentCategorySettingsCard } from '../components/UserDocumentSettings/UserDocumentCategorySettingsCard'
import { UserDocumentCategorySettingsTable } from '../components/UserDocumentSettings/UserDocumentCategorySettingsTable'
import { UserDocumentCategoryForm, UserDocumentCategoryPrompt } from '../components/UserDocumentSettings/UserDocumentCategoryPrompt'

const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 1rem;

  h2 {
    margin: 0 1rem 0 0;
  }

  ${props => props.theme.media.mobile} {
    display: grid;
    grid-template-rows: auto auto;
    gap: 0.5rem;

    margin-bottom: 0;
  }
`

interface UserDocumentSettingsProps {}

export const UserDocumentSettings: React.VFC<UserDocumentSettingsProps> =
  () => {
    const translations = useTranslate({
      info1: 'settings.info.user-documents-1',
      info2: 'settings.info.user-documents-2',

      documents: 'common.documents',
      noDocuments: 'documents.no-documents',

      name: 'common.name',
      responsible: 'common.responsible',
      notificationTime: 'documents.notification-time-alt',

      prompt: {
        delete: ['settings.prompts.user-document-delete', { name: '' }],
        deleteTitle: 'settings.prompts.user-document-delete-title',
      },
      validation: {
        nameExists: 'settings.validation.user-document-name-exists',
      },
      userDocumentCategories: 'common.user-document-categories',
    })

    const confirm = useConfirm()
    const addPrompt = usePrompt()
    const onErrorAuto = useOnErrorAuto()
    const mutations = useUserDocumentMutations()
    const categoryMutations = useUserDocumentCategoryMutations()

    const me = useUser()
    const [user, setUser] = useState<ShallowUserNode>(me)
    const [adding, setAdding] = useState(false)
    const [editing, setEditing] = useState<string | null>(null)

    const addToast = useToast() 

    const { data: queryData, loading: queryLoading } = useQuery<
      AllUserDocumentsQuery,
      AllUserDocumentsQueryVariables
    >(ALL_USER_DOCUMENTS_QUERY, {
      variables: {
        user: user.id,
      },
      onError: onErrorAuto(),
    })

    const { data: categoryData } = useQuery<
      AllUserDocumentCategoriesQuery
    >(ALL_USER_DOCUMENT_CATEGORIES_QUERY, {
      onError: onErrorAuto(),
    })

    const editableCategories = categoryData?.allUserDocumentCategories.edges.map(edge => edge.node) ?? []
    
    const {
      formValues: form,
      formChangeHandler: handler,
      updateForm,
    } = useForm<UserDocumentCategoriesSettingsForm>({
      values: {
        newName: '',
        editName: '',
      },
    })

    const documents =
      queryData?.allUserDocuments.edges.map(edge => edge.node) ?? []

    async function handleEdit(document: UserDocumentForSettings) {
      const { data } = await addPrompt<EditUserDocumentPromptResolve | null>(
        resolve => (
          <EditUserDocumentPrompt userDocument={document} onSubmit={resolve} />
        )
      )
      if (data === null) return
      const { form, hasNotification } = data

      const expirationDate = !hasNotification ? null : form.expirationDate
      const notificationTime = !hasNotification
        ? null
        : !expirationDate || !form.notificationTime
        ? null
        : subWeeks(expirationDate, form.notificationTime)

      mutations.patch({
        variables: {
          id: document.id,
          input: {
            name: form.name,
            responsibleUser: !hasNotification
              ? null
              : form.responsibleUser?.id ?? null,
            expirationDate,
            notificationTime,
            categories: form.categories,
          },
        },
      })
    }
    async function handleDelete(document: UserDocumentForSettings) {
      const { data: answer } = await confirm(
        translations.prompt.delete({ name: document.name }),
        translations.prompt.deleteTitle
      )
      if (!answer) return

      mutations.delete({
        variables: { id: document.id },
      })
    }

    const isLoading = queryLoading || mutations.loading

    async function submitNewUserDocumentCategory() {
      if (!validateName(form.newName)) return

      await categoryMutations.create({
        variables: {
          input: {
            name: form.newName,
          },
        },
      })
      setAdding(false)
    }

    async function handleCreatePrompt() {
      const { data } = await addPrompt<UserDocumentCategoryForm | null>(resolve => (
        <UserDocumentCategoryPrompt
          editableCategories={editableCategories}
          onSubmit={resolve}
        />
      ))

      if (data === null) return

      categoryMutations.create({
        variables: {
          input: { ...(data) },
        },
      })
    }



    async function handleEditPrompt(category: UserDocumentCategoryForSettings) {
      const initialData: UserDocumentCategoryForm = {
        name: category.name,
      }
      const { data } = await addPrompt<UserDocumentCategoryForm | null>(resolve => (
        <UserDocumentCategoryPrompt
          initialData={initialData}
          editableCategories={editableCategories}
          onSubmit={resolve}
        />
      ))
      if (data === null) return

      categoryMutations.patch({
        variables: {
          id: category.id,
          input: { ...(data) },
        },
      })
    }
    function validateName(name: string) {
      if (name === '') return false
      if (
        !!editableCategories.find(
          type => type.name === form.newName
        )
      ) {
        addToast('warning', translations.validation.nameExists)
        return false
      }
      return true
    }

    function checkNameHasChanged(id: string) {
      const category = editableCategories.find(type => type.id === id)
      if (typeof category === 'undefined') return true

      return category.name !== form.editName
    }

    async function submitEditUserDocumentCategoryName() {
      if (!validateName(form.editName) || editing === null) return
      if (!checkNameHasChanged(editing)) {
        setEditing(null)
        return
      }

      await categoryMutations.patch({
        variables: {
          id: editing,
          input: { name: form.editName },
        },
      })
      setEditing(null)
    }

    async function handleDeletePrompt(category: UserDocumentCategoryForSettings) {
      const { data: answer } = await confirm(
        translations.prompt.delete({ name: category.name }),
        translations.prompt.deleteTitle
      )
      if (!answer) return

      categoryMutations.delete({
        variables: {
          id: category.id,
        },
      })
    }

    useEffect(() => {
      if (!adding && form.newName !== '') {
        updateForm({
          newName: '',
        })
      }
    }, [adding, form.newName, updateForm])

    useEffect(() => {
      if (editing === null && form.editName !== '') {
        updateForm({ editName: '', })
      }
    }, [editing, form.editName, updateForm])

    return (
      <SettingsWrapper grid={{ flow: 'row' }}>
        <InfoBox initCollapsed={isMobileOnly}>
          <p>{translations.info1}</p>
          <p>{translations.info2}</p>
        </InfoBox>

        <Section>
          <Header>
            <SectionHeader loading={isLoading}>
              {translations.documents}
              {!isMobileOnly ? ':' : ''}
            </SectionHeader>

            <UserSelect
              value={user.id}
              fullWidth={isMobileOnly}
              onChange={(_, user) => setUser(user?.extra ?? me)}
            />
          </Header>

          <Table
            noData={!isLoading && documents.length === 0}
            noDataText={translations.noDocuments}
            noShadow
            noBorders
            noHeaderMargin
            noRowMargin
            rowHoverColor="secondary"
          >
            <thead>
              <tr>
                <th>{translations.name}</th>

                {!isMobileOnly && (
                  <>
                    <th>{translations.responsible}</th>
                    <th>{translations.notificationTime}</th>
                  </>
                )}

                <th></th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {documents.map(document => {
                const notificationTime = document.notificationTime
                  ? format(new Date(document.notificationTime), 'PPP HH:mm')
                  : '-'

                return (
                  <tr key={document.id}>
                    <td>{document.name}</td>

                    {!isMobileOnly && (
                      <>
                        <td>
                          {!document.responsibleUser ? (
                            '-'
                          ) : (
                            <PlainLink
                              to={`/users/${document.responsibleUser.id}`}
                              hoverUnderline
                              hoverColor="secondary"
                            >
                              {document.responsibleUser.fullName}
                            </PlainLink>
                          )}
                        </td>

                        <td>{notificationTime}</td>
                      </>
                    )}

                    <td width="1px">
                      <Icon
                        icon="edit"
                        cursor="pointer"
                        color="gray6"
                        hoverColor="secondary"
                        onClick={() => handleEdit(document)}
                      />
                    </td>

                    <td width="1px">
                      <Icon
                        icon="times"
                        cursor="pointer"
                        color="gray6"
                        hoverColor="red"
                        onClick={() => handleDelete(document)}
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Section>
        <Section>
          <Header>
            <SectionHeader loading={queryLoading}>
              {translations.userDocumentCategories}
            </SectionHeader>
            {!isMobileOnly && (
              <AddButton adding={adding} onClick={handleCreatePrompt} />
            )}
          </Header>
          {isMobileOnly ? (
              <UserDocumentCategorySettingsCard
                editableCategories={editableCategories}
                form={form}
                adding={adding}
                editing={editing}
                onFormChange={handler}
                onUpdateForm={updateForm}
                onAdd={setAdding}
                onAddSubmit={submitNewUserDocumentCategory}
                onEdit={setEditing}
                onEditSubmit={submitEditUserDocumentCategoryName}
                onDelete={handleDeletePrompt}
              />
            ) : (
              <UserDocumentCategorySettingsTable
                editableCategories={editableCategories}
                onEditClick={handleEditPrompt}
                onDelete={handleDeletePrompt}
              />
          )}
        </Section>
      </SettingsWrapper>
    )
  }
