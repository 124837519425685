import { PromptResolve, SelectOption, useToast } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { DateRangePicker, Select, TextArea } from 'components'
import { RegularModal } from 'components/Modal'
import { parseISO, set } from 'date-fns'
import { EditAbsenceForm } from 'modules/timesheets/types'
import React from 'react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import { ALL_ABSENCE_TYPES_QUERY } from '..'
import { AbsenceNode, AllAbsenceTypesQuery } from '../types.graphql'
import { notEmptyValidation } from 'util/forms'

const Form = styled.div`
  display: flex;
  flex-direction: column;
`
interface FieldProps {
  double?: boolean
}
const Field = styled.div<FieldProps>`
  display: grid;
  grid-template-columns: ${props => (props.double ? 'auto 1fr' : '1fr')};
  gap: 0.5rem;

  label {
    grid-row: 1;
    grid-column: 1 / -1;

    margin: 0 0 6px 6px;
    color: ${props => props.theme.colors.gray3};
    font-weight: 600;
  }
  & + div {
    margin-top: 1rem;
  }
`

interface EditAbsenceModalProps {
  absence: Omit<AbsenceNode, 'company'> | null

  onResolve: PromptResolve<EditAbsenceForm | null>
}

export const EditAbsenceModal: React.VFC<EditAbsenceModalProps> = ({
  absence,

  onResolve,
}) => {
  const translations = useTranslate({
    date: 'common.date',
    from: 'common.from',
    until: 'common.until',
    note: 'timesheets.note',

    absenceType: 'timesheets.absence-type',
    editAbsence: 'timesheets.edit-absence',

    cancel: 'common.cancel',
    submit: 'common.submit',

    error: {
      invalid: 'error.invalid-value',
      startBeforeEnd: 'timesheets.error.start-before-end',
      couldNotGetAbsenceTypes: 'error.could-not-get-absence-types',
      required: 'common.required',
    },
  })

  const addToast = useToast()

  const validateChronology = (start: Date, end: Date) =>
    start.getTime() <= end.getTime() ? null : translations.error.invalid

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    updateForm,
  } = useForm<EditAbsenceForm>({
    values: {
      start: absence ? parseISO(absence.datetimeStart) : new Date(),
      end: absence ? parseISO(absence.datetimeEnd) : new Date(),
      reason: absence?.reason ?? '',
      absenceType: absence?.absenceType?.id ?? '',
    },
    validators: {
      start: (start, { end }) => validateChronology(start, end),
      end: (end, { start }) => validateChronology(start, end),
      reason: value =>
        notEmptyValidation(value) ? null : translations.error.required,
    },
  })

  const { data: absenceTypesData, loading: absenceTypesLoading } = useQuery<
    AllAbsenceTypesQuery,
    never
  >(ALL_ABSENCE_TYPES_QUERY, {
    onError: () => {
      addToast('error', translations.error.couldNotGetAbsenceTypes)
    },
  })

  const updateDatePair = (values: [Date, Date]) => {
    const existingStartValue = form['start']
    const existingEndValue = form['end']
    const newStartValue = set(existingStartValue, {
      year: values[0].getFullYear(),
      month: values[0].getMonth(),
      date: values[0].getDate(),
    })
    const newEndValue = set(existingEndValue, {
      year: values[1].getFullYear(),
      month: values[1].getMonth(),
      date: values[1].getDate(),
    })

    updateForm({
      start: newStartValue,
      end: newEndValue,
    })
  }

  const absenceTypeOptions: SelectOption<string>[] =
    absenceTypesData?.allAbsenceTypes.edges.map(({ node: type }) => ({
      value: type.id,
      label: type.name,
    })) ?? []

  return (
    <RegularModal
      title={translations.editAbsence}
      submitText={translations.submit}
      width="600px"
      onCancel={() => onResolve(null)}
      onSubmit={() => onResolve(form)}
      submitDisabled={!formValid}
      cancelOnClickOutside
    >
      <p className="error">
        {(!!errors.start || !!errors.end) && translations.error.startBeforeEnd}
      </p>

      <Form>
        <Field>
          <label>{translations.date}</label>
          <DateRangePicker
            value={[form.start, form.end]}
            onChange={updateDatePair}
          />
        </Field>

        <Field>
          <label>{translations.absenceType}</label>
          <Select
            value={form.absenceType}
            options={absenceTypeOptions}
            loading={absenceTypesLoading}
            fullWidth
            grayed
            onChange={value => updateForm({ absenceType: value ?? '' })}
          />
        </Field>

        <Field>
          <label>{translations.note}</label>
          <TextArea
            value={form.reason}
            error={errors.reason}
            fullWidth
            onChange={reason => updateForm({ reason })}
          />
        </Field>
      </Form>
    </RegularModal>
  )
}
