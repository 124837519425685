const en = {
  'activities.add-salary-day': 'Add salary day',
  'activities.approve-day': 'Approve activities for {date}?',
  'activities.approve-month':
    'Approve activities for {month} ({dateStart} - {dateEnd})?',
  'activities.approve-title': 'Approve activities',
  'activities.approve-week':
    'Approve activities for week {week} ({dateStart} - {dateEnd})?',
  'activities.availability-time': 'Availability time',
  'activities.consumption': 'Fuel consumption',
  'activities.day-end': 'Day end',
  'activities.day-start': 'Day start',
  'activities.driver': 'Driver',
  'activities.driving': 'Driving',
  'activities.driving-grade': 'Driving grade',
  'activities.end-position':
    '{abbreviate, select, true {End pos.} other {End position}}',
  'activities.errors.could-not-load-route-in-map':
    'Could not load route in map',
  'activities.errors.general-error':
    'Something went wrong fetching driver activities',
  'activities.errors.select-multiple-users':
    'Select at least one user to export',
  'activities.errors.user-not-found': 'User not found',
  'activities.export-driving-data': 'Export driving data',
  'activities.export-monthly-weekly': 'Export split up monthly or weekly',
  'activities.fetch-data': 'Fetch driving data',
  'activities.fuel-usage': 'Fuel usage',
  'activities.last-fetched': 'Last fetched',
  'activities.missing-data': 'Missing data',
  'activities.module-title': 'Driving data',
  'activities.paid-pause': 'Paid pause',
  'activities.pause-export': 'Pause export',
  'activities.print-driving-data': 'Print activities',
  'activities.regnr': 'Regnr',
  'activities.rest': 'Rest',
  'activities.start-position':
    '{abbreviate, select, true {Start pos.} other {Start position}}',
  'activities.starting-activity-fetching-process':
    'Starting activity fetching process',
  'activities.success.approve-activities': 'Actviities approved',
  'activities.sum-for-month-title': 'Sum for {month}',
  'activities.sum-next-month':
    'Summation for week {week} is calculated next month',
  'activities.sum-overtime': 'Total overtime',
  'activities.updated-diet-successfully': 'Updated diet successfully',
  'activities.updated-salary-day-successfully': 'Salary day updated',
  'activities.work': 'Work',
  'activities.work-summary': 'Work summary',
  'activities.working-hours': 'Working hours',
  'admin-required.message': 'You need to be an administrator to see this.',
  'auth.admin-or-user-required':
    'You have to be {user} or an administrator to view this.',
  'change-password.description':
    'You must change your password before logging in for security reasons.',
  'change-password.title': 'Change password',
  'checklists.all-checklist-templates': 'All checklist templates',
  'checklists.checklist-item': 'Checklist item',
  'checklists.checklist-template': 'Checklist template',
  'checklists.generate-new': 'Generate new',
  'checklists.no-checklists': 'No checklists available.',
  'commmon.edit-name': 'Edit name',
  'common.about': 'About',
  'common.absence': 'Absence',
  'common.absence-types': 'Absence types',
  'common.absences': 'Absence',
  'common.action': 'Action',
  'common.active': 'Active',
  'common.activities': 'Driving data',
  'common.activity': 'Activity',
  'common.add': 'Add',
  'common.add-attachment': 'Add attachment',
  'common.add-attachments': 'Add attachments',
  'common.add-column': 'Add column',
  'common.add-file': 'Add file',
  'common.add-file-alt': 'Add file',
  'common.add-files': 'Add files',
  'common.additional-pay': 'Additional pay',
  'common.address': 'Address',
  'common.all': 'All',
  'common.ambulance': 'Ambulance',
  'common.app': 'App',
  'common.archive': 'Archive',
  'common.archived': 'Archived',
  'common.article': 'Article',
  'common.attachments': 'Attachments',
  'common.author': 'Author',
  'common.availability': 'Availability',
  'common.basis': 'Basis',
  'common.both': 'Both',
  'common.business': 'Business',
  'common.businessOverview': 'Business overview',
  'common.by-name': 'By {name}',
  'common.cancel': 'Cancel',
  'common.cancel-changes': 'Cancel changes',
  'common.categories': 'Categories',
  'common.category': 'Category',
  'common.category-for-type': 'Category for {type}',
  'common.change-company': 'Change company',
  'common.change-language': 'Change language',
  'common.change-password': 'Change password',
  'common.changes-saved': 'Changes saved',
  'common.checklist-templates': 'Checklist templates',
  'common.checklists': 'Checklists',
  'common.choose-ellipsis': 'Choose...',
  'common.city': 'City',
  'common.close': 'Close',
  'common.closed': 'Closed',
  'common.codes': 'Codes',
  'common.color': 'Color',
  'common.comment': 'Comment',
  'common.comment-ellipsis': 'Comment...',
  'common.comments': 'Comments',
  'common.company': 'Company',
  'common.company-name': 'Company name',
  'common.contact-info': 'Contact info',
  'common.contact-person': 'Contact person',
  'common.content': 'Content',
  'common.count': 'Count',
  'common.count-of-total': '{count} of {total}',
  'common.count-of-total-verbose': 'Seen by {count}/{total} employees',
  'common.countries': 'Countries',
  'common.country': 'Country',
  'common.create': 'Create',
  'common.create-alt': 'Create',
  'common.create-entry': 'Create new entry',
  'common.create-folder': 'Create new folder',
  'common.create-form': 'Create new form',
  'common.create-form-entry': 'Create new form entry',
  'common.create-type': 'Create {type}',
  'common.creating': 'Creating',
  'common.csv': 'CSV',
  'common.current-password': 'Current password',
  'common.daily': 'Daily',
  'common.dashboard': 'Dashboard',
  'common.data': 'Data',
  'common.data-distribution': 'Data distribution',
  'common.date': 'Date',
  'common.date-for-type': 'Date for {type}',
  'common.date-validation-not-in-future':
    'Date must be valid and not in the future',
  'common.day-end': 'Day end',
  'common.day-start': 'Day start',
  'common.days': '{days, plural, =0 {No days} one {# day} other {# days}}',
  'common.deactivated': 'Deactivated',
  'common.default-severity': 'Default severity',
  'common.delete': 'Delete',
  'common.delete-alt': 'Delete',
  'common.delete-type': 'Delete {type}',
  'common.department': 'Department',
  'common.department-expandable-text': '{amount} departments',
  'common.departments': 'Departments',
  'common.description': 'Description',
  'common.detail': 'Detail',
  'common.details': 'Details',
  'common.diet': 'Diet',
  'common.disabled': 'Disabled',
  'common.distance': 'Distance',
  'common.document': 'Document',
  'common.documentation': 'Documentation',
  'common.documentationIssues': 'Documentation issues',
  'common.documents': 'Documents',
  'common.download': 'Download',
  'common.driver': 'Driver',
  'common.drivers': 'Drivers',
  'common.drivers-handbook': 'Drivers handbook',
  'common.driversHandbook': 'Drivers handbook',
  'common.driversHandbookForm': 'Handbookforms',
  'common.driving': 'Driving',
  'common.driving-data': 'Driving data',
  'common.dropdown': 'Dropdown',
  'common.dropdown-on-pages': 'User dropdown on page',
  'common.dropdowns': 'Dropdowns',
  'common.edit': 'Edit',
  'common.edit-alt': 'Edit',
  'common.edit-entry': 'Edit entry',
  'common.edit-name': 'Edit name',
  'common.edit-type': 'Edit {type}',
  'common.email': 'Email',
  'common.email-already-selected': 'Email is already selected',
  'common.employee': 'Employee',
  'common.enabled': 'Enabled',
  'common.end': 'End',
  'common.end-date': 'End date',
  'common.end-time': 'End time',
  'common.enter-url': 'Enter URL',
  'common.entry': 'Entry',
  'common.eurovignette': 'Eurovignette',
  'common.eurovignettes': 'Eurovignettes',
  'common.excel': 'Excel',
  'common.excel-flat': 'Excel (one sheet)',
  'common.expired': 'Expired',
  'common.expires-at': 'Expires at',
  'common.export': 'Export',
  'common.export-format': 'Format',
  'common.export-type': 'Export type',
  'common.exports-settings': 'Export',
  'common.external-id': 'External id',
  'common.fats': 'FATS',
  'common.fats-beta': 'FATS (beta)',
  'common.field': 'Field',
  'common.file': 'File',
  'common.files': 'Files',
  'common.filter': 'Filter',
  'common.finished': 'Finished',
  'common.fire': 'Fire',
  'common.firstName': 'First name',
  'common.folder': 'Folder',
  'common.form-error': 'One or more fields are filled in incorrectly.',
  'common.form-responses': 'Filled out forms',
  'common.formcreator': 'Form creator',
  'common.forms': 'Forms',
  'common.forum': 'Forum',
  'common.forum-posts': 'Forum posts',
  'common.forum-threads': 'Forum threads',
  'common.freight-carrier': 'Freight carrier',
  'common.from': 'From',
  'common.general': 'General',
  'common.generate': 'Generate',
  'common.generate-report': 'Generate report',
  'common.go-back': 'Go back',
  'common.handbook': 'Handbook',
  'common.height': 'Height',
  'common.height-in-cm': 'Height in cm',
  'common.high': 'High',
  'common.holiday-allowances': 'Holiday allowances',
  'common.home': 'Home',
  'common.hourlist': 'Timesheets',
  'common.hours': 'Hours',
  'common.hours-abbrev': 'h',
  'common.hours-lowercase': 'hours',
  'common.id': 'Id',
  'common.in-app': 'In app',
  'common.include-archived': 'Include archived',
  'common.information': 'Information',
  'common.infringement': 'Infringement',
  'common.infringements': 'Infringements',
  'common.integrations': 'Integrations',
  'common.invalid': 'Invalid',
  'common.invalid-date': 'Invalid date',
  'common.invalid-url': 'Invalid URL',
  'common.issue': 'Issue',
  'common.issue-categories': 'Issue categories',
  'common.issued-at': 'Issued at',
  'common.issues': 'Issues',
  'common.items': 'Items',
  'common.kilometers': '{abbreviate, select, true {km} other {kilometers}}',
  'common.km': 'km',
  'common.km-per-hour-short': 'km/h',
  'common.language': 'Language',
  'common.last-revision': 'Last revision',
  'common.lastName': 'Last name',
  'common.length': 'Length',
  'common.length-in-cm': 'Length in cm',
  'common.liters': 'Liters',
  'common.liters-short': 'l',
  'common.load-more': 'Load more',
  'common.load-previous': 'Load previous',
  'common.loading': 'Loading',
  'common.loading...': 'Loading...',
  'common.location': 'Location',
  'common.log-in': 'Log in',
  'common.log-out': 'Log out',
  'common.login': 'Login',
  'common.low': 'Low',
  'common.make-a-choice': 'Make a choice',
  'common.make-a-selection': 'Please make a selection',
  'common.manual': 'Manual',
  'common.map': 'Map',
  'common.mass-print': 'Print mass',
  'common.maximum': 'Maximum',
  'common.medium': 'Medium',
  'common.menu': 'Menu',
  'common.minimum': 'Minimum',
  'common.minutes': 'Minutes',
  'common.minutes-abbrev': 'm',
  'common.module': 'Module',
  'common.month': 'Month',
  'common.monthly': 'Monthly',
  'common.move-down': 'Move down',
  'common.move-up': 'Move up',
  'common.n-categories': '{n} categories',
  'common.n-hours': '{n} hours',
  'common.n-issues': '{n} issues',
  'common.n-minutes': '{n} minutes',
  'common.n-salary-code': '{n} salary codes',
  'common.name': 'Name',
  'common.needs-comment': 'Needs comment',
  'common.new-password': 'New password',
  'common.new-password-again': 'New password again',
  'common.no': 'No',
  'common.no-active-modules': 'No active modules',
  'common.no-attachments': 'No attachments',
  'common.no-changes': 'No changes',
  'common.no-comment': 'No comment',
  'common.no-more-options': 'No more options',
  'common.no-options-to-choose-from': 'No options to choose from',
  'common.no-results': 'No results',
  'common.none': 'None',
  'common.not-archived': 'Not archived',
  'common.not-verified-yet': 'Not verified yet',
  'common.notification': 'Notification',
  'common.notifications': 'Notifications',
  'common.notifications-alt': 'Notifications',
  'common.office': 'Office',
  'common.ok': 'OK',
  'common.open': 'Open',
  'common.open-alt': 'Open',
  'common.opened': 'Opened',
  'common.order': 'Order',
  'common.order-by': 'Order by',
  'common.ordinary': 'Ordinary',
  'common.other': 'Other',
  'common.other-work': 'Other work',
  'common.overtime': 'Overtime',
  'common.overtime-mode': 'Overtime mode',
  'common.overtime-rate': 'Overtime rate',
  'common.password': 'Password',
  'common.pause': 'Pause',
  'common.pdf': 'PDF',
  'common.pdf-salary': 'PDF (Salary)',
  'common.period': 'Period',
  'common.perks': 'Perks',
  'common.permissions': 'Permissions',
  'common.phone': 'Phone',
  'common.phone-number': 'Phone number',
  'common.phone-prefix': 'Country prefix',
  'common.police': 'Police',
  'common.postal-code': 'Postal code',
  'common.poweroffice': 'PowerOffice',
  'common.previous-responses': 'Previous responses',
  'common.print': 'Print',
  'common.print-type': 'Print {type}',
  'common.priority': 'Priority',
  'common.privacy': 'Privacy',
  'common.project': 'Project',
  'common.publish': 'Publish',
  'common.published': 'Published',
  'common.range': 'Range',
  'common.reason': 'Reason',
  'common.reg-nr': 'Reg.nr.',
  'common.register': 'Register',
  'common.remove': 'Remove',
  'common.report': 'Report',
  'common.required': 'Required',
  'common.reset': 'Reset',
  'common.responses': 'Responses',
  'common.responsible': 'Responsible',
  'common.responsible-user': 'Responsible user',
  'common.responsible-users': 'Responsible users',
  'common.rest': 'Rest',
  'common.restore': 'Restore',
  'common.risk-aspect': 'Risk aspect',
  'common.risk-aspects': 'Risk aspects',
  'common.role': 'Role',
  'common.route-planner': 'Route planner',
  'common.salary': 'Salary',
  'common.salary-code': 'Salary code',
  'common.salary-codes': 'Salary codes',
  'common.salary-day': 'Salary day',
  'common.salary-mode': 'Salary mode',
  'common.save': 'Save',
  'common.save-and-close': 'Save and close',
  'common.save-changes': 'Save changes',
  'common.save-changes-to-type': 'Save changes to {type}',
  'common.save-in-user-exports': 'Save in my exports',
  'common.save-languages': 'Save language',
  'common.saving': 'Saving',
  'common.search': 'Search ...',
  'common.seconds-abbrev': 's',
  'common.see-all-responses': 'See all responses',
  'common.seen-by': 'Seen by',
  'common.select': 'Select',
  'common.select-all': 'Select all',
  'common.select-at-least-one-recipient': 'Select at least one recipient',
  'common.select-manual-entry': 'Choose manual entry',
  'common.select-project': 'Select project',
  'common.select-salary-code': 'Select salary code',
  'common.select-user': 'Select user',
  'common.select-users': 'Select users',
  'common.select-week-days': 'Select week days',
  'common.send-print-to-mail': 'Send print to mail?',
  'common.settings': 'Settings',
  'common.severity': 'Severity',
  'common.shortcuts': 'Shortcuts',
  'common.show-all': 'Show all',
  'common.show-sums': 'Show sums',
  'common.singular': 'Singular',
  'common.sms': 'SMS',
  'common.sort-by-user-type': 'Sort by user type',
  'common.start': 'Start',
  'common.start-date': 'Start date',
  'common.start-time': 'Start time',
  'common.statistics': 'Statistics',
  'common.statistics-this-week': 'Statistics (This week)',
  'common.status': 'Status',
  'common.submit': 'Submit',
  'common.sum': 'Sum',
  'common.sums': 'Sums',
  'common.terminal': 'Terminal',
  'common.terminal-network': 'Terminal network',
  'common.the-email': 'The email',
  'common.the-field': 'The field',
  'common.thread': 'Thread',
  'common.time': 'Time',
  'common.time-entries': 'Timesheets',
  'common.time-entry': 'Time entry',
  'common.time-interval': 'Time interval',
  'common.timesheets': 'Time entries',
  'common.timesinceposted': 'Posted ',
  'common.timestamp': 'Timestamp',
  'common.title': 'Title',
  'common.total-work': 'Total work',
  'common.tours': 'Tours',
  'common.tripletex': 'Tripletex',
  'common.tv-display': 'TV-display',
  'common.twentyfour-seven-office': '24SevenOffice',
  'common.two-weekly': 'Two weeks',
  'common.type': 'Type',
  'common.unavailable': 'Unavailable',
  'common.unknown': 'Unknown',
  'common.until': 'Until',
  'common.untitled': 'Untitled',
  'common.update': 'Update',
  'common.update-entry': 'Update entry',
  'common.update-folder': 'Update folder',
  'common.update-form': 'Update form',
  'common.update-form-entry': 'Update form entry',
  'common.update-password': 'Update password',
  'common.update-type': 'Update {type}',
  'common.updated-at': 'Updated at',
  'common.updating': 'Updating',
  'common.uploaded': 'Uploaded',
  'common.uploaded-attachments': 'Uploaded attachments',
  'common.user': 'User',
  'common.user-document-categories': 'User document categories',
  'common.user-documents': 'User documents',
  'common.user-type': 'User type',
  'common.user-types': 'User types',
  'common.username': 'Username',
  'common.users': 'Users',
  'common.usersettings': 'User settings',
  'common.value': 'Value',
  'common.vehicle': 'Vehicle',
  'common.vehicle-expandable-text': '{amount} vehicles',
  'common.vehicle-fleet': 'Vehicle fleet',
  'common.verified': 'Verified',
  'common.view-comment': 'View comment',
  'common.visma-payroll': 'Visma Payroll',
  'common.week': 'Week',
  'common.week-n': 'Week {n}',
  'common.weekly': 'Weekly',
  'common.weeks': '{weeks, plural, =0 {No weeks} one {# week} other {# weeks}}',
  'common.weight': 'Weight',
  'common.when-by-whom': '{when} by {whom}',
  'common.whole-day': 'All day',
  'common.width': 'Width',
  'common.width-in-cm': 'Width in cm',
  'common.work': 'Work',
  'common.work-hours': 'Work hours',
  'common.year': 'Year',
  'common.yes': 'Yes',
  'common.you-have-unsaved-changes': 'You have unsaved changes',
  'common.you-have-unsaved-changes-navigate':
    'You have unsaved changes. Are you sure you want to leave this page?',
  'companies.create-overtime-period': 'Create overtime period',
  'companies.create-period-addon': 'Create period addon',
  'companies.edit-overtime-period': 'Edit overtime period',
  'companies.edit-period-addon': 'Edit period addon',
  'companies.existing-overtime-period': 'Existing overtime period',
  'companies.overlapping-periods-are-not-allowed':
    'Overlapping periods are not allowed',
  'companies.toasts.department-created': 'Department created',
  'companies.toasts.department-deleted': 'Department deleted',
  'companies.toasts.department-updated': 'Departmend updated',
  'companies.toasts.holiday-allowance-created': 'Holiday allowance was added',
  'companies.toasts.holiday-allowance-deleted': 'Holiday allowance was deleted',
  'companies.toasts.holiday-allowance-updated': 'Holiday allowance was updated',
  'companies.toasts.salary-code-created': 'Salary code created',
  'companies.toasts.salary-code-deleted': 'Salary code deleted successfully',
  'companies.toasts.salary-code-updated': 'Salary code updated',
  'countries.codes.AC': 'Ascension Island',
  'countries.codes.AD': 'Andorra',
  'countries.codes.AE': 'United Arab Emirates',
  'countries.codes.AF': 'Afghanistan',
  'countries.codes.AG': 'Antigua & Barbuda',
  'countries.codes.AI': 'Anguilla',
  'countries.codes.AL': 'Albania',
  'countries.codes.AM': 'Armenia',
  'countries.codes.AO': 'Angola',
  'countries.codes.AQ': 'Antarctica',
  'countries.codes.AR': 'Argentina',
  'countries.codes.AS': 'American Samoa',
  'countries.codes.AT': 'Austria',
  'countries.codes.AU': 'Australia',
  'countries.codes.AW': 'Aruba',
  'countries.codes.AX': 'Åland Islands',
  'countries.codes.AZ': 'Azerbaijan',
  'countries.codes.BA': 'Bosnia & Herzegovina',
  'countries.codes.BB': 'Barbados',
  'countries.codes.BD': 'Bangladesh',
  'countries.codes.BE': 'Belgium',
  'countries.codes.BF': 'Burkina Faso',
  'countries.codes.BG': 'Bulgaria',
  'countries.codes.BH': 'Bahrain',
  'countries.codes.BI': 'Burundi',
  'countries.codes.BJ': 'Benin',
  'countries.codes.BL': 'St. Barthélemy',
  'countries.codes.BM': 'Bermuda',
  'countries.codes.BN': 'Brunei',
  'countries.codes.BO': 'Bolivia',
  'countries.codes.BQ': 'Caribbean Netherlands',
  'countries.codes.BR': 'Brazil',
  'countries.codes.BS': 'Bahamas',
  'countries.codes.BT': 'Bhutan',
  'countries.codes.BV': 'Bouvet Island',
  'countries.codes.BW': 'Botswana',
  'countries.codes.BY': 'Belarus',
  'countries.codes.BZ': 'Belize',
  'countries.codes.CA': 'Canada',
  'countries.codes.CC': 'Cocos (Keeling) Islands',
  'countries.codes.CD': 'Congo - Kinshasa',
  'countries.codes.CF': 'Central African Republic',
  'countries.codes.CG': 'Congo - Brazzaville',
  'countries.codes.CH': 'Switzerland',
  'countries.codes.CI': 'Côte d’Ivoire',
  'countries.codes.CK': 'Cook Islands',
  'countries.codes.CL': 'Chile',
  'countries.codes.CM': 'Cameroon',
  'countries.codes.CN': 'China',
  'countries.codes.CO': 'Colombia',
  'countries.codes.CP': 'Clipperton Island',
  'countries.codes.CR': 'Costa Rica',
  'countries.codes.CU': 'Cuba',
  'countries.codes.CV': 'Cape Verde',
  'countries.codes.CW': 'Curaçao',
  'countries.codes.CX': 'Christmas Island',
  'countries.codes.CY': 'Cyprus',
  'countries.codes.CZ': 'Czechia',
  'countries.codes.DE': 'Germany',
  'countries.codes.DG': 'Diego Garcia',
  'countries.codes.DJ': 'Djibouti',
  'countries.codes.DK': 'Denmark',
  'countries.codes.DM': 'Dominica',
  'countries.codes.DO': 'Dominican Republic',
  'countries.codes.DZ': 'Algeria',
  'countries.codes.EA': 'Ceuta & Melilla',
  'countries.codes.EC': 'Ecuador',
  'countries.codes.EE': 'Estonia',
  'countries.codes.EG': 'Egypt',
  'countries.codes.EH': 'Western Sahara',
  'countries.codes.ER': 'Eritrea',
  'countries.codes.ES': 'Spain',
  'countries.codes.ET': 'Ethiopia',
  'countries.codes.EU': 'European Union',
  'countries.codes.FI': 'Finland',
  'countries.codes.FJ': 'Fiji',
  'countries.codes.FK': 'Falkland Islands',
  'countries.codes.FM': 'Micronesia',
  'countries.codes.FO': 'Faroe Islands',
  'countries.codes.FR': 'France',
  'countries.codes.GA': 'Gabon',
  'countries.codes.GB': 'United Kingdom',
  'countries.codes.GD': 'Grenada',
  'countries.codes.GE': 'Georgia',
  'countries.codes.GF': 'French Guiana',
  'countries.codes.GG': 'Guernsey',
  'countries.codes.GH': 'Ghana',
  'countries.codes.GI': 'Gibraltar',
  'countries.codes.GL': 'Greenland',
  'countries.codes.GM': 'Gambia',
  'countries.codes.GN': 'Guinea',
  'countries.codes.GP': 'Guadeloupe',
  'countries.codes.GQ': 'Equatorial Guinea',
  'countries.codes.GR': 'Greece',
  'countries.codes.GS': 'South Georgia & South Sandwich Islands',
  'countries.codes.GT': 'Guatemala',
  'countries.codes.GU': 'Guam',
  'countries.codes.GW': 'Guinea-Bissau',
  'countries.codes.GY': 'Guyana',
  'countries.codes.HK': 'Hong Kong SAR China',
  'countries.codes.HM': 'Heard & McDonald Islands',
  'countries.codes.HN': 'Honduras',
  'countries.codes.HR': 'Croatia',
  'countries.codes.HT': 'Haiti',
  'countries.codes.HU': 'Hungary',
  'countries.codes.IC': 'Canary Islands',
  'countries.codes.ID': 'Indonesia',
  'countries.codes.IE': 'Ireland',
  'countries.codes.IL': 'Israel',
  'countries.codes.IM': 'Isle of Man',
  'countries.codes.IN': 'India',
  'countries.codes.IO': 'British Indian Ocean Territory',
  'countries.codes.IQ': 'Iraq',
  'countries.codes.IR': 'Iran',
  'countries.codes.IS': 'Iceland',
  'countries.codes.IT': 'Italy',
  'countries.codes.JE': 'Jersey',
  'countries.codes.JM': 'Jamaica',
  'countries.codes.JO': 'Jordan',
  'countries.codes.JP': 'Japan',
  'countries.codes.KE': 'Kenya',
  'countries.codes.KG': 'Kyrgyzstan',
  'countries.codes.KH': 'Cambodia',
  'countries.codes.KI': 'Kiribati',
  'countries.codes.KM': 'Comoros',
  'countries.codes.KN': 'St. Kitts & Nevis',
  'countries.codes.KP': 'North Korea',
  'countries.codes.KR': 'South Korea',
  'countries.codes.KW': 'Kuwait',
  'countries.codes.KY': 'Cayman Islands',
  'countries.codes.KZ': 'Kazakhstan',
  'countries.codes.LA': 'Laos',
  'countries.codes.LB': 'Lebanon',
  'countries.codes.LC': 'St. Lucia',
  'countries.codes.LI': 'Liechtenstein',
  'countries.codes.LK': 'Sri Lanka',
  'countries.codes.LR': 'Liberia',
  'countries.codes.LS': 'Lesotho',
  'countries.codes.LT': 'Lithuania',
  'countries.codes.LU': 'Luxembourg',
  'countries.codes.LV': 'Latvia',
  'countries.codes.LY': 'Libya',
  'countries.codes.MA': 'Morocco',
  'countries.codes.MC': 'Monaco',
  'countries.codes.MD': 'Moldova',
  'countries.codes.ME': 'Montenegro',
  'countries.codes.MF': 'St. Martin',
  'countries.codes.MG': 'Madagascar',
  'countries.codes.MH': 'Marshall Islands',
  'countries.codes.MK': 'Macedonia',
  'countries.codes.ML': 'Mali',
  'countries.codes.MM': 'Myanmar (Burma)',
  'countries.codes.MN': 'Mongolia',
  'countries.codes.MO': 'Macau SAR China',
  'countries.codes.MP': 'Northern Mariana Islands',
  'countries.codes.MQ': 'Martinique',
  'countries.codes.MR': 'Mauritania',
  'countries.codes.MS': 'Montserrat',
  'countries.codes.MT': 'Malta',
  'countries.codes.MU': 'Mauritius',
  'countries.codes.MV': 'Maldives',
  'countries.codes.MW': 'Malawi',
  'countries.codes.MX': 'Mexico',
  'countries.codes.MY': 'Malaysia',
  'countries.codes.MZ': 'Mozambique',
  'countries.codes.NA': 'Namibia',
  'countries.codes.NC': 'New Caledonia',
  'countries.codes.NE': 'Niger',
  'countries.codes.NF': 'Norfolk Island',
  'countries.codes.NG': 'Nigeria',
  'countries.codes.NI': 'Nicaragua',
  'countries.codes.NL': 'Netherlands',
  'countries.codes.NO': 'Norway',
  'countries.codes.NP': 'Nepal',
  'countries.codes.NR': 'Nauru',
  'countries.codes.NU': 'Niue',
  'countries.codes.NZ': 'New Zealand',
  'countries.codes.OM': 'Oman',
  'countries.codes.PA': 'Panama',
  'countries.codes.PE': 'Peru',
  'countries.codes.PF': 'French Polynesia',
  'countries.codes.PG': 'Papua New Guinea',
  'countries.codes.PH': 'Philippines',
  'countries.codes.PK': 'Pakistan',
  'countries.codes.PL': 'Poland',
  'countries.codes.PM': 'St. Pierre & Miquelon',
  'countries.codes.PN': 'Pitcairn Islands',
  'countries.codes.PR': 'Puerto Rico',
  'countries.codes.PS': 'Palestinian Territories',
  'countries.codes.PT': 'Portugal',
  'countries.codes.PW': 'Palau',
  'countries.codes.PY': 'Paraguay',
  'countries.codes.QA': 'Qatar',
  'countries.codes.RE': 'Réunion',
  'countries.codes.RO': 'Romania',
  'countries.codes.RS': 'Serbia',
  'countries.codes.RU': 'Russia',
  'countries.codes.RW': 'Rwanda',
  'countries.codes.SA': 'Saudi Arabia',
  'countries.codes.SB': 'Solomon Islands',
  'countries.codes.SC': 'Seychelles',
  'countries.codes.SD': 'Sudan',
  'countries.codes.SE': 'Sweden',
  'countries.codes.SG': 'Singapore',
  'countries.codes.SH': 'St. Helena',
  'countries.codes.SI': 'Slovenia',
  'countries.codes.SJ': 'Svalbard & Jan Mayen',
  'countries.codes.SK': 'Slovakia',
  'countries.codes.SL': 'Sierra Leone',
  'countries.codes.SM': 'San Marino',
  'countries.codes.SN': 'Senegal',
  'countries.codes.SO': 'Somalia',
  'countries.codes.SR': 'Suriname',
  'countries.codes.SS': 'South Sudan',
  'countries.codes.ST': 'São Tomé & Príncipe',
  'countries.codes.SV': 'El Salvador',
  'countries.codes.SX': 'Sint Maarten',
  'countries.codes.SY': 'Syria',
  'countries.codes.SZ': 'Swaziland',
  'countries.codes.TA': 'Tristan da Cunha',
  'countries.codes.TC': 'Turks & Caicos Islands',
  'countries.codes.TD': 'Chad',
  'countries.codes.TF': 'French Southern Territories',
  'countries.codes.TG': 'Togo',
  'countries.codes.TH': 'Thailand',
  'countries.codes.TJ': 'Tajikistan',
  'countries.codes.TK': 'Tokelau',
  'countries.codes.TL': 'Timor-Leste',
  'countries.codes.TM': 'Turkmenistan',
  'countries.codes.TN': 'Tunisia',
  'countries.codes.TO': 'Tonga',
  'countries.codes.TR': 'Turkey',
  'countries.codes.TT': 'Trinidad & Tobago',
  'countries.codes.TV': 'Tuvalu',
  'countries.codes.TW': 'Taiwan',
  'countries.codes.TZ': 'Tanzania',
  'countries.codes.UA': 'Ukraine',
  'countries.codes.UG': 'Uganda',
  'countries.codes.UM': 'U.S. Outlying Islands',
  'countries.codes.UN': 'United Nations',
  'countries.codes.US': 'United States',
  'countries.codes.UY': 'Uruguay',
  'countries.codes.UZ': 'Uzbekistan',
  'countries.codes.VA': 'Vatican City',
  'countries.codes.VC': 'St. Vincent & Grenadines',
  'countries.codes.VE': 'Venezuela',
  'countries.codes.VG': 'British Virgin Islands',
  'countries.codes.VI': 'U.S. Virgin Islands',
  'countries.codes.VN': 'Vietnam',
  'countries.codes.VU': 'Vanuatu',
  'countries.codes.WF': 'Wallis & Futuna',
  'countries.codes.WS': 'Samoa',
  'countries.codes.XK': 'Kosovo',
  'countries.codes.YE': 'Yemen',
  'countries.codes.YT': 'Mayotte',
  'countries.codes.ZA': 'South Africa',
  'countries.codes.ZM': 'Zambia',
  'countries.codes.ZW': 'Zimbabwe',
  'countries.european-community': 'European community',
  'countries.rest-of-europe': 'Rest of Europe',
  'countries.rest-of-world': 'Rest of world',
  'createUser.title': 'Create new user',
  'dashboard.enter-driver-card-number.errors.invalid-number':
    'Invalid driver card number',
  'dashboard.lastForumPosts': 'Last forum posts',
  'dashboard.register-hours': 'Register hours',
  'dashboard.registerHours': 'Register hours',
  'document.files-for-document': 'Files attached to {name}',
  'documentation.documentation-for-control': 'Documentation for control',
  'documentation.drive-and-rest-time': 'Driving and resting time',
  'documents.add-document': 'Add document',
  'documents.continue-to-files': 'Continue to files',
  'documents.documents-of-user': 'Documents: {user}',
  'documents.edit-file': 'Edit file',
  'documents.expiration-date': 'Expiration date',
  'documents.files-for-document': 'Files for {document}',
  'documents.name-on-document': 'Name on document',
  'documents.no-documents': 'No documents',
  'documents.no-files': 'No files',
  'documents.notification-time':
    'Time for notification in weeks before expiration date',
  'documents.notification-time-alt': 'Notification time',
  'documents.number-id': 'Number/ID',
  'documents.open-file': 'Open file',
  'documents.prompts.delete-document': 'Delete document',
  'documents.prompts.delete-document-confirmation':
    'Are you sure you want to delete "{name}"?',
  'documents.prompts.delete-file':
    'Are you sure you want to delete the file "{name}"?',
  'documents.prompts.delete-file-title': 'Delete file',
  'documents.responsible-user': 'Document responsible',
  'documents.show-files': 'Show files',
  'documents.upload-document': 'Upload document',
  'documents.upload-file': 'Upload file',
  'documents.view-file': 'View file',
  'error.activities-pause-duration':
    'Activities pause duration needs to be at least 45 minutes',
  'error.address-required': 'Address is required',
  'error.archive-type': 'Could not archive {type}',
  'error.categories': 'Could not get categories',
  'error.city-required': 'City is required',
  'error.company-missing-tachograph-key':
    'The company is missing a Tachograph API key. Contact admin for further support.',
  'error.contact-person-phone-required':
    'Contact person phone number is required',
  'error.contact-person-required': 'Contact person name is required',
  'error.could-not-change-profile-information':
    'Could not change profile information',
  'error.could-not-check-in': 'Could not check in',
  'error.could-not-check-out': 'Could not check out',
  'error.could-not-create-type': 'Could not create {type}',
  'error.could-not-delete-type': 'Could not delete {type}',
  'error.could-not-edit-type': 'Could not edit {type}',
  'error.could-not-find-data': 'Could not find table data',
  'error.could-not-find-location': 'Could not find location',
  'error.could-not-get-absence-types': 'Could not get absence types',
  'error.could-not-read-file': 'Could not read file',
  'error.create-issue': 'Could not create issue',
  'error.driver-card-expiration-date-not-found':
    'Could not find expiration date for the driver card.',
  'error.driver-card-is-expired': 'Driver card is expired',
  'error.drivercard-between-14-16': 'Field needs 14-16 characters',
  'error.end-time-too-early': 'End time must be later than start time',
  'error.expiration-date-not-found-add-expiration-date-manually':
    'Could not find expiration date. Add date manually.',
  'error.field-can-not-be-empty': "The field can't be empty",
  'error.field-is-required': '{field} is required',
  'error.files.could-not-read': 'Could not read file',
  'error.filled-out-form-entry-does-not-exist':
    'Filled out form entry does not exist.',
  'error.first-name-required': 'First name is required',
  'error.generic': 'An error has occured',
  'error.generic-server-error':
    'Something went wrong on the server. Please try again.',
  'error.generic-title': 'Something went wrong',
  'error.internal-number-required': 'Internal number is required',
  'error.invalid-birth-number': 'The birth number is invalid',
  'error.invalid-file-type': 'Invalid file type',
  'error.invalid-phone-number': 'Invalid phone number',
  'error.invalid-value': 'Invalid value',
  'error.last-name-required': 'Last name is required',
  'error.missing-permission':
    'You are missing the required permission to access this',
  'error.name-exists': 'An entry with this name already exists',
  'error.need-at-least-one-user-type': 'Need at least one user type',
  'error.need-company-name': 'Company name must be present',
  'error.no-first-name': 'Missing first name',
  'error.not-permitted': 'Not permitted',
  'error.not-permitted-to-view': 'You are not permitted to view this',
  'error.number-must-be-positive': 'The number must be positive',
  'error.phone-number-required': 'Phone number is required',
  'error.postal-code-required': 'Postal code is required',
  'error.try-again': 'Try again',
  'error.type-already-exist': '{type} already exist',
  'error.type-does-not-exist': '{type} does not exist',
  'error.un-archive-type': 'Could not re-open {type}',
  'error.update-issue': 'Could not update issue',
  'error.user-has-no-infringements-for-the-selected-period':
    'The user has no infringements for the selected period',
  'error.valid-email-required': 'Valid email is required',
  'error.wrong-password': 'Wrong password',
  'error.wrong-username-or-password': 'Wrong username or password',
  'errors.common.name-already-exists': 'Name already exists',
  'errors.could-not-save-changes': 'Could not save changes',
  'errors.defaults.does-not-exist': 'The object does not exist',
  'errors.defaults.foreign-key-constraint':
    "The object is referencing something that doesn't exist",
  'errors.defaults.invalid-value': 'An invalid value was provided',
  'errors.defaults.not-permitted': 'Your user is not permitted to do this',
  'errors.defaults.unique-constraint': 'The object must be unique',
  'errors.driver-card-already-taken': 'Driver card already taken.',
  'errors.driver-card-not-found-in-external-integration':
    'Driver card not found in external integration. Check if driver card is added in your fleet management systems.',
  'errors.driver-card-not-found-similar-driver-card-already-taken':
    'Driver card not found. Similar driver card already taken.',
  'errors.generic-error': 'An error has occurred',
  'errors.must-be-whole-number': 'Must be whole number',
  'errors.passwords-not-equal': 'Passwords are not equal',
  'errors.users.could-not-change-password': 'Could not change password',
  'exports.error-email-process': 'Could not export to email',
  'exports.error-user-salary-settings':
    'The user has no user types connected to salary settings',
  'exports.export-data': 'Export data',
  'exports.month-export': 'Month export',
  'exports.salary-export': 'Salary basis export',
  'exports.success-email-process':
    'Data is being processed. You will receive a mail after the processing time.',
  'exports.success-user-exports':
    'Data are being processed. You will recieve a notification after the processing time.',
  'exports.user-exports': 'My exports',
  'files.drop-to-upload': 'Drop files here to upload',
  'files.file-too-large':
    'File {name} is larger than the maxium size of {max}MB.',
  'files.upload-files-here': 'Upload files',
  'forgotten-password.forgotten': 'Forgotten password?',
  'forgotten-password.go-back': 'Go back to login',
  'forgotten-password.message':
    'If you have forgotten your password, please enter your username or email. If we can find your account we will send you an email with a new password.',
  'forgotten-password.success':
    "If your account exists, an e-mail is on it's way with password reset instructions.",
  'forgotten-password.title': 'Forgotten password',
  'form.add-field': 'Add field',
  'form.all-responses-loaded': 'All responses loaded',
  'form.archive-form-error': 'Could not archive form',
  'form.archive-form-success': 'Form was archived',
  'form.archive-prompt-text': 'Are you sure you want to archive this form?',
  'form.archive-prompt-title': 'Archive form',
  'form.create-filled-form-success': 'Form filled out',
  'form.create-form': 'Create new form',
  'form.create-form-alt': 'Create form',
  'form.created-form-success': 'Successfully created new form',
  'form.delete-checklist-template-prompt-text':
    'Are you sure you want to delete this checklist template?',
  'form.delete-checklist-template-prompt-title': 'Delete checklist template',
  'form.delete-entry-prompt-text':
    'Are you sure you want to delete this filled form?',
  'form.delete-entry-prompt-title': 'Delete form entry',
  'form.delete-field-prompt-text':
    'Are you sure you want to delete this form field?',
  'form.delete-field-prompt-title': 'Delete form field',
  'form.delete-template-prompt-text':
    'Are you sure you want to delete this form?',
  'form.delete-template-prompt-title': 'Delete form',
  'form.edit-form': 'Edit form',
  'form.edit-this-form': 'Edit this form',
  'form.errors.load-responses': 'Could not load form responses',
  'form.field': 'Field',
  'form.field-type-boolean': 'Yes/No',
  'form.field-type-date': 'Date',
  'form.field-type-number': 'Number',
  'form.field-type-text': 'Text',
  'form.field-type-user': 'User',
  'form.field-type-vehicle-department': 'Department on vehicle',
  'form.fields': 'Fields',
  'form.fill-out-this-form': 'Fill out this form',
  'form.filled-out-n-times-by-user':
    '{n, plural, =0 {You have never filled out this form} one {You have filled out this form once} =2 {You have filled out this form twice} other {You have filled out this form # times}}',
  'form.filled-out-n-times-by-user-including-today':
    '{n, plural, =0 {You have never filled out this form} one {You have filled out this form once, including today} =2 {You have filled out this form twice, including today} other {You have filled out this form # times, including today}}',
  'form.form-entry': 'Form',
  'form.form-entry-deleted': 'The filled out form was deleted',
  'form.form-fields': 'Form fields',
  'form.form-label-name': 'Form name',
  'form.form-settings': 'Formsettings',
  'form.label-archive-date': 'Archiving date',
  'form.label-archive-max-uses': 'Max number of uses before archivation',
  'form.label-archive-mode': 'Archiving method',
  'form.label-field-description': 'Description',
  'form.label-field-input-type': 'Input tyle',
  'form.label-field-name': 'Field name',
  'form.label-field-type': 'Field type',
  'form.label-field-value': 'Text',
  'form.label-include-footer': 'Include footer',
  'form.label-include-icon': 'Include company icon',
  'form.label-ingress': 'Ingress',
  'form.label-type': 'Form type',
  'form.last-response-at': 'Most recent response',
  'form.load-all-responses': 'Load all responses',
  'form.menu-shortcut': 'Menu shortcut',
  'form.no-responses': 'No responses',
  'form.open-overview': 'Open overview',
  'form.prompts.delete-form':
    'Are you completely sure you want to delete this form? All responses will also be deleted.',
  'form.prompts.delete-form-title': 'Delete form',
  'form.prompts.unverify-response':
    'Do you want to cancel the varified status of this response?',
  'form.prompts.unverify-response-title': 'Remove verification',
  'form.prompts.verify-response': 'Do you want to verify this response?',
  'form.prompts.verify-response-title': 'Verify',
  'form.remove-field': 'Remove Field',
  'form.responses-for-form': 'Responses: {name}',
  'form.see-answers': 'See responses',
  'form.select-form': 'Select form',
  'form.showing-num-of-total-responses': 'Showing {num} of {total} responses',
  'form.table-label-created': 'Created',
  'form.table-label-edit': 'Edit',
  'form.table-label-filled-in-by': 'Filled in by',
  'form.table-label-name': 'Name',
  'form.table-label-updated': 'Updated at',
  'form.table-label-use-form': 'Use form',
  'form.table-label-used': 'Responses',
  'form.toasts.create-form-success': 'Form created',
  'form.toasts.delete-form-success': 'Form deleted',
  'form.toasts.patch-form-success': 'Form edited',
  'form.toasts.submit-success': 'The form was submitted',
  'form.un-archive-prompt-text': 'Are you sure you want to re-open this form?',
  'form.un-archive-prompt-title': 'Re-open form',
  'form.update-form-success': 'Form updated',
  'form.updated-form-success': 'Successfully updated form',
  'form.validation.cannot-be-negative': 'Cannot be negative',
  'form.validation.required-fields-missing':
    'All required fields are not filled',
  'form.verified-by-at': 'Verified by {name}, {when}',
  'formcreator.distribution-field': 'Distribution field',
  'formcreator.export-form-submissions': 'Export form submissions',
  'formcreator.filter-field': 'Filter field',
  'formcreator.summation-field': 'Summation field',
  'forum.add-comment': 'Add comment',
  'forum.answers-number': '{num} answers',
  'forum.archived-threads': 'Archived threads',
  'forum.create-new-post': 'Create new post',
  'forum.create-thread': 'Create new thread',
  'forum.create-thread.send-email-error':
    'An error occurred while sending emails',
  'forum.create-thread.send-sms-error': 'An error occurred while sending SMS',
  'forum.create-thread.success': 'Created thread',
  'forum.created': 'Created',
  'forum.edit-post': 'Edit post',
  'forum.edit-thread': 'Edit thread',
  'forum.errors.content-required': 'Content is required',
  'forum.errors.create-thread': 'Could not create new thread',
  'forum.errors.delete-post-failed': 'Could not delete comment',
  'forum.errors.delete-thread-failed': 'Could not delete thread',
  'forum.errors.thread-does-not-exist': 'Thread does not exist',
  'forum.errors.title-required': 'Title is required',
  'forum.last-posts': 'Last posts',
  'forum.last-threads': 'Last threads',
  'forum.new': 'New thread',
  'forum.new-thread': 'New thread',
  'forum.no-views': 'No views',
  'forum.number-of-posts': 'Number of posts',
  'forum.post':
    '{choice, select, definite {The post} plural {Posts} other {Post}}',
  'forum.post-comment': 'Post comment',
  'forum.posts-error-message': 'Some error occurred fetching posts',
  'forum.prompts.delete-attachment':
    'Are you sure you want to delete this attachment?',
  'forum.prompts.delete-attachment-title': 'Delete attachment',
  'forum.prompts.delete-comment-prompt':
    'Are you sure you wish to delete this comment?',
  'forum.prompts.delete-comment-prompt-title': 'Delete comment',
  'forum.prompts.delete-post': 'Are you sure you want to delete this comment?',
  'forum.prompts.delete-post-title': 'Delete comment',
  'forum.prompts.delete-thread': 'Are you dure you want to delete this thread?',
  'forum.prompts.delete-thread-text':
    'Are you sure you want to delete the thread {title}? This action cannot be undone.',
  'forum.prompts.delete-thread-title': 'Delete thread',
  'forum.prompts.download-attachment-text':
    'Cannot open this attachment, do you wish to download it?',
  'forum.prompts.download-attachment-title': 'Download attachment',
  'forum.quill-link-helper':
    'Mark the text you want to convert to a link, then press the link button',
  'forum.read-by-n-employees':
    '{n, plural, =0 {Read by no employees} one {Read by # employee} other {Read by # employees}}',
  'forum.search': 'Search the forum',
  'forum.seen-at': 'Seen at',
  'forum.send-email-label': 'Send email notification',
  'forum.send-sms-label': 'Send sms notification to {employeeNum} employees',
  'forum.sms-notification': 'SMS notification',
  'forum.sticky-thread': 'Sticky thread',
  'forum.sticky-threads': 'Sticky threads',
  'forum.thread':
    '{choice, select, definite {The thread} plural {Threads} other {Thread}}',
  'forum.threads-error-message': 'Some error occurred fetching threads',
  'forum.timesinceposted': 'Hours ago ',
  'forum.toasts.archive-thread-error': 'Could not archive the thread',
  'forum.toasts.archive-thread-success': 'The thread was archived',
  'forum.toasts.create-thread-success': 'Thread created',
  'forum.toasts.delete-thread-error': 'The thread could not be deleted',
  'forum.toasts.delete-thread-success': 'The thread was deleted',
  'forum.toasts.patch-post-error':
    "Something wen't wrong while updating the post",
  'forum.toasts.patch-post-success': 'The post was updated',
  'forum.toasts.patch-thread-error':
    'Something went wrong while updating the thread',
  'forum.toasts.patch-thread-success': 'The thread was updated',
  'forum.toasts.restore-thread-success': 'The thread was restored',
  'forum.unseen-only': 'Unseen only',
  'forum.update-comment': 'Update comment',
  'forum.update-thread': 'Update thread',
  'forum.validation.thread-must-have-content': 'The thread must have content',
  'forum.view-count-title': 'The thread has been seen by',
  'forum.written-when': 'Written {when}',
  'handbook.add-entry': 'Add entry',
  'handbook.add-folder': 'Add folder',
  'handbook.add-sub-folder': 'Add sub folder',
  'handbook.create-entry-success': 'Created entry',
  'handbook.create-folder-success': 'Created folder',
  'handbook.create_entry': 'Create new entry',
  'handbook.dashboard-links': 'Dashboard links',
  'handbook.edit-folder': 'Edit folder',
  'handbook.entry-load-error': 'Error loading entry',
  'handbook.entry.delete-success': 'Deleted entry',
  'handbook.errors.delete-entry': 'Could not delete entry',
  'handbook.errors.delete-folder': 'Could not delete folder',
  'handbook.folder-load-error': 'Error loading folder',
  'handbook.folder.delete-success': 'Deleted folder',
  'handbook.new-root-folder': 'New root folder',
  'handbook.prompts.delete-entry':
    'Are you sure you want to delete this entry?',
  'handbook.prompts.delete-entry-title': 'Delete entry',
  'handbook.prompts.delete-folder':
    'Are you quite sure you would like to delete this folder? All contents will also be deleted.',
  'handbook.prompts.delete-folder-title': 'Delete folder',
  'handbook.search': 'Search the driver handbook',
  'handbook.start': 'Start',
  'handbook.toasts.create-entry-success': 'Handbook entry "{name}" was created',
  'handbook.toasts.create-folder-success': 'Folder created',
  'handbook.toasts.delete-entry-success': 'The entry was deleted',
  'handbook.toasts.delete-folder-success': 'The folder was deleted',
  'handbook.toasts.patch-folder-success': 'Folder saved',
  'handbook.update-entry-success': 'Updated the entry',
  'handbook.update-folder-success': 'Updated the folder',
  'infringements.amount-of-infringements-in-categories':
    'Amount of infringements in categories',
  'infringements.archive-infringement': 'Archive infringement',
  'infringements.archived-basis': 'Archived basis',
  'infringements.archived-date': 'Archived date',
  'infringements.category-infringement': '{category} infringement',
  'infringements.export-infringement': 'Export infringement',
  'infringements.export-infringement-data': 'Export infringement data',
  'infringements.export.all': 'Export infringements for company',
  'infringements.legal-basis': 'Legal basis',
  'infringements.lower-limit': 'Lower limit',
  'infringements.minor-infringement': 'Minor infringement',
  'infringements.most-serious-infringement': 'Most serious infringement',
  'infringements.my-infringements': 'My infringements',
  'infringements.n-points': '{n} points',
  'infringements.no-infringements': 'No infringements',
  'infringements.restore-infringement': 'Restore infringement',
  'infringements.send-email-success': 'Infringements sent to email.',
  'infringements.serious-infringement': 'Serious infringement',
  'infringements.severity': 'Severity',
  'infringements.total-severity': 'Total severity',
  'infringements.upper-limit': 'Upper limit',
  'infringements.very-serious-infringement': 'Very serious infringement',
  'integrations.error-in-API-keys': 'Error in API keys',
  'integrations.error-in-FMS-key': 'Error in FMS API key',
  'integrations.error-in-tachograph-key': 'Error in Tachograph API key',
  'issue.category-responsible-user': 'Issues category responsible',
  'issue.existing-attachments': 'Existing attachments',
  'issues.action-log': 'Action log',
  'issues.actual-cost': 'Actual cost',
  'issues.add-recipient-with-email': 'Add recipient with email',
  'issues.archive-issue': 'Archive issue',
  'issues.archived-by-user': 'Archived by {user}',
  'issues.attachments': 'Attachments',
  'issues.category-name': 'Category name',
  'issues.close-issue': 'Close issue',
  'issues.closed-at': 'Closed at',
  'issues.closed-by': 'Closed by',
  'issues.closed-by-user': 'Closed by {user}',
  'issues.cost-estimation': 'Estimated cost',
  'issues.create-issue': 'Create issue',
  'issues.create-issue-for': 'Create issue for:',
  'issues.created-by-user': 'Created by {user}',
  'issues.damage-description': 'What has been damaged',
  'issues.damage-description-long':
    'What has been damaged — Fill in both direct and indirect objects.',
  'issues.damage-description-short': 'Damaged objects',
  'issues.damage-direct-and-indirect-objects':
    'Fill in direct and indirect objects',
  'issues.damage-location': 'Where did it happen',
  'issues.delete-issue': 'Delete issue',
  'issues.delete-issue-error-message':
    'You are trying to delete a category with issues still associated with it. This is not possible.',
  'issues.describe-the-damage': 'Describe the damage/damaged object',
  'issues.description-of-event-unwanted-event':
    'Description of event/unwanted event',
  'issues.edit-issue': 'Edit issue',
  'issues.equipment-material': 'Equipment/material',
  'issues.error-retrieving-users': 'An error occurred while retrieving users',
  'issues.errors.create-issue': 'Could not create issue',
  'issues.errors.must-enter-actual-cost': 'Must enter actual cost',
  'issues.errors.must-enter-solution': 'Solution is required',
  'issues.errors.patch-issue': 'Could not update issue',
  'issues.export-issue': 'Export issue',
  'issues.extend-issue-form': 'Do you wish to close the issue now?',
  'issues.fill-in-actual-cost': 'Fill in actual cost',
  'issues.fill-in-solution': 'Fill in final solution',
  'issues.fill-in-vehicle-reg-nr':
    'Fill in reg.nr. of concerned vehicle/trailer',
  'issues.final-solution': 'Final solution',
  'issues.find-issue': 'Find issue',
  'issues.give-estimated-cost': 'Give an estimate of the cost of the issue',
  'issues.issue-categories': 'Issue categories',
  'issues.issue-categories.errors.could-not-load-error':
    'Could not load categories',
  'issues.issue-categories.no-categories': 'No categories',
  'issues.issue-category': 'Issue category',
  'issues.issue-description-about': 'About the issue',
  'issues.issue-location': 'Issue location',
  'issues.issue-report': 'Issue report',
  'issues.location-description': 'Where on the location did the issue occur',
  'issues.new-issue': 'New issue',
  'issues.no-issues': 'No issues',
  'issues.opened-by': 'Opened by',
  'issues.reference': '{abbreviate, select, true {Ref.} other {Reference}}',
  'issues.reopen-issue': 'Reopen issue',
  'issues.reopened-by-user': 'Reopened by {user}',
  'issues.responsible': 'Responsible',
  'issues.restore-issue': 'Restore issue',
  'issues.restored-by-user': 'Restored by {user}',
  'issues.search-for-issue': 'Search for issue',
  'issues.select-recipient-from-list': 'Select recipient from list',
  'issues.selected-recipients': 'Selected recipients',
  'issues.send-email-success': 'The report was sent by email.',
  'issues.solution-suggestion-about': 'Measure suggestions',
  'issues.success-creation': 'Created new issue',
  'issues.success-update': 'Updated issue',
  'issues.suggested-improvement': 'Suggested solution',
  'issues.suggested-solution': 'Suggested solution',
  'issues.time-of-issue': 'Time of issue',
  'issues.toasts.create-issue-success': 'The issue was created',
  'issues.toasts.patch-issue-success': 'The issue was updated',
  'issues.treated': 'Treated',
  'issues.untreated': 'Untreated',
  'issues.update-actual-cost': 'Update actual cost',
  'issues.updated-by-user': 'Updated by {user}',
  'issues.validation.solution-required':
    'To close this issue you must supply a solution',
  'issues.view-all-issues': 'All issues',
  'issues.view-your-issues': 'Your issues',
  'login.email-or-phone-number': 'Email or phone number',
  'login.forgotten-password': 'Forgot your password?',
  'login.remember-me': 'Remember me',
  'login.subtitle': 'Welcome to',
  'modal.and-all-its-content': ' and all its content',
  'modal.delete-attachment':
    'Do you want to delete "{title}" from the attachments?',
  'modal.delete-attachment-title': 'Delete attachment',
  'modal.delete-folder': 'Delete folder',
  'modal.delete-folder-description':
    'Do you want to delete the folder "{title}" and its content?',
  'modal.delete-folder-entry-description':
    'Do you want to delete the entry "{title}"?',
  'modal.delete-folder-entry-title': 'Delete entry',
  'modal.delete-folder-title': 'Delete folder',
  'modal.delete-type': 'Are you sure you want to delete {type}?',
  'modal.delete-type-title': 'Delete {type}',
  'modal.remove-attachment':
    'Do you want to remove "{title}" from the attachment list?',
  'modal.remove-attachment-title': 'Remove attachment',
  'notifications-settings.issue_closed_notification':
    'Issue closed notification',
  'notifications-settings.issue_created_notification':
    'Issue created notification',
  'notifications-settings.new_post_created_notification': 'New post created',
  'notifications-settings.new_thread_created_notification':
    'New thread created',
  'notifications-settings.title': 'Notification settings',
  'notifications.add-notifications': 'Add notifications?',
  'notifications.error.general': 'Something went wrong loading notifications',
  'notifications.go-to-absence': 'Go to Absences',
  'notifications.go-to-document': 'Go to document',
  'notifications.go-to-thread': 'Go to thread',
  'notifications.you-have-no-notifications':
    'You do not have any notifications',
  'password.must-contain-nonnumeric':
    'Password must contain at least one non-numeric value',
  'password.must-contain-numeric-value':
    'Password must contain at leas one numeric value',
  'password.too-short': 'Password needs to be atleast 7 characters in length',
  'permissions.add': 'Create',
  'permissions.approve': 'Approve',
  'permissions.change': 'Change',
  'permissions.change_sticky': 'Change sticky',
  'permissions.company-settings-general': 'General',
  'permissions.forum-send-email': 'Send email',
  'permissions.forum-send-sms': 'Send SMS',
  'permissions.no-access-to-page':
    'Your user does not have access to this page',
  'permissions.remove': 'Delete',
  'permissions.view': 'View',
  'permissions.view-and-change': 'View/Change',
  'permissions.view-others': 'View others',
  'print.error-email-process': 'Failed to generate email print',
  'print.export-type': 'Export {type}',
  'print.monthly-print': 'Monthly printout',
  'print.salary-print': 'Salary print',
  'print.send-to-email': 'Send to email',
  'print.success-email-process':
    'Request is being processed. When the process is complete, you will recieve an email.',
  'quill.delete-column': 'Delete selected column',
  'quill.delete-row': 'Delete selected row',
  'quill.delete-table': 'Delete table',
  'quill.insert-column-left': 'Insert column left',
  'quill.insert-column-right': 'Insert column right',
  'quill.insert-row-above': 'Insert row above',
  'quill.insert-row-below': 'Insert row below',
  'quill.new-table': 'New table',
  'quill.table-tooltip': 'Shift + right click to modify table.',
  'register-user.description':
    'Since this is your first time logging in, you will have to fill in some standard information.',
  'register-user.title': 'Fill in your personal info',
  'reset-password.errors.bad-token': 'Bad token',
  'reset-password.errors.expired-token': 'The reset token has expired.',
  'reset-password.go-back': 'Back to login',
  'reset-password.message':
    'Please select a new password. The password must be at least 8 characters long, and cannot solely be made up of numbers.',
  'reset-password.submit': 'Change password and log in',
  'reset-password.success': 'The password has been reset',
  'reset-password.title': 'Reset your password',
  'route-planner.add-new-route': 'Add new route',
  'route-planner.axle-weight-restrictions': 'Axle weight restrictions',
  'route-planner.create-route-plan': 'Create a route plan',
  'route-planner.create-route-plan-success': 'Route plan created successfully',
  'route-planner.delete-route-plan-success': 'Routeplan deleted successfully',
  'route-planner.destination': 'Destionation',
  'route-planner.estimated-driving-duration': 'Estimated driving duration',
  'route-planner.export-route-plan': 'Export route plan',
  'route-planner.extra-load': 'Extra load',
  'route-planner.form.label.total-weight-in-kg': 'Total weight in kg',
  'route-planner.form.label.total-weight-of-vehicle':
    'Total weight of vehicle in kg',
  'route-planner.general-weight-restriction': 'General weight restriction',
  'route-planner.include-departure-time': 'Include departure time?',
  'route-planner.load': 'Load',
  'route-planner.loading-route': 'Loading route...',
  'route-planner.no-route-instructions': 'No route instructions',
  'route-planner.no-routes': 'No routes',
  'route-planner.payload': 'Payload',
  'route-planner.payload-in-kilos': 'Payload in kilos',
  'route-planner.route-instructions': 'Route instructions',
  'route-planner.route-plan': 'Route plan',
  'route-planner.route-plan-no-route-found':
    'No route found for the given constraints',
  'route-planner.route-plan-task-failed':
    'Something went wrong with this route plan',
  'route-planner.route-plan-task-pending': 'This route plan is pending',
  'route-planner.route-restrictions': 'Route restrictions',
  'route-planner.single-axle': 'Single axle',
  'route-planner.start-location': 'Start location',
  'route-planner.tandem-axle': 'Tandem axle',
  'route-planner.tonne': 'Tonne',
  'route-planner.top-speed': 'Top speed',
  'route-planner.top-speed-in-km': 'Top speed in km/h',
  'route-planner.total-weight': 'Total weight',
  'route-planner.trailer': 'Trailer',
  'route-planner.trailer-weight-in-kilos': 'Trailer weight in kilos',
  'route-planner.triple-axle': 'Triple axle',
  'route-planner.weight-in-tonnes': 'Weight in metric tonnes',
  'select-user-settings.title': 'User dropdown settings',
  'selectCompany.select': 'Select company',
  'settings.absence-responsible': 'Absence responsible',
  'settings.add-absence-type': 'Add absence type',
  'settings.add-api-key': 'Add API key',
  'settings.add-department': 'Add department',
  'settings.add-holiday-allowance': 'Add holiday allowance',
  'settings.add-issue-category': 'Add issue category',
  'settings.add-issue-risk-aspect': 'Add risk aspect',
  'settings.add-new-terminal': 'Add new terminal',
  'settings.add-salary-code': 'Add salary code',
  'settings.add-time-entry-project': 'Add project',
  'settings.added-issue-category': 'Added new issue category',
  'settings.available-departments': 'Available departments',
  'settings.change-logo': 'Change logo',
  'settings.change-module': 'Change module',
  'settings.change-responsible-user': 'Change responsible user',
  'settings.company-logo': 'Company logo',
  'settings.company-settings': 'Company settings',
  'settings.create-checklist-template': 'Create checklist template',
  'settings.create-fleet-board-integration': 'Create FleetBoard integration',
  'settings.create-issue-category': 'Create issue category',
  'settings.create-man-integration': 'Create MAN integration',
  'settings.create-name-first': 'Create name first',
  'settings.create-salary-settings': 'Create salary settings for {module}',
  'settings.create-scania-integration': 'Create Scania-integration',
  'settings.create-terminal-area': 'Create terminal area',
  'settings.create-user-document': 'Create user document',
  'settings.create-user-type': 'Create user type',
  'settings.create-volvo-integration': 'Create Volvo-integration',
  'settings.daily-salary': 'Salary day payment',
  'settings.daily-work-limit': 'Daily work limit (hours)',
  'settings.daily-work-limit-short': 'Daily',
  'settings.dashboard-links-preview': 'Preview of dashboard links',
  'settings.day-salary-code': 'Day overtime',
  'settings.default-department': 'Default department',
  'settings.default-salary-code': 'Default salary code',
  'settings.delete-overtime-period': 'Delete overtime period',
  'settings.delete-period-addon': 'Delete period addon',
  'settings.delete-user-type': 'Delete user type',
  'settings.do-you-want-to-delete-overtime-period':
    'Do you want to delete the overtime period?',
  'settings.do-you-want-to-delete-period-addon':
    'Do you want to delete the period addon?',
  'settings.driverdata-print-for-all-drivers': 'Print all activities',
  'settings.edit-absence-responsible': 'Edit absence responsible',
  'settings.edit-absence-type': 'Edit absencetype',
  'settings.edit-checklist-template': 'Edit checklist template',
  'settings.edit-department': 'Update department',
  'settings.edit-dynamic-column': 'Edit column name',
  'settings.edit-fleet-board-integration-n': 'Edit FleetBoard integrasjon {n}',
  'settings.edit-holiday-allowance': 'Edit holiday allowance',
  'settings.edit-infringements-responsible':
    'Edit infringements responsible user',
  'settings.edit-issue-category': 'Edit issue category',
  'settings.edit-issue-risk-aspect': 'Change risk aspect',
  'settings.edit-man-integration-n': 'Change MAN integration {n}',
  'settings.edit-responsible-user': 'Edit responsible user',
  'settings.edit-salary-and-work-time-for-type': 'Edit {type} salary settings',
  'settings.edit-salary-code': 'Edit salary code',
  'settings.edit-salary-settings-type': 'Edit salary settings: {module}',
  'settings.edit-scania-integration-n': 'Edit Scania-integration #{n}',
  'settings.edit-terminal-area': 'Edit terminal area',
  'settings.edit-time-entry-project': 'Edit project',
  'settings.edit-type-settings': 'Edit {type} settings',
  'settings.edit-user-document': 'Edit user document',
  'settings.edit-volvo-integration-n': 'Edit Volvo integration #{n}',
  'settings.errors.change-language': 'Could not change language',
  'settings.errors.company-settings-generic':
    'Could not update company settings',
  'settings.errors.create-absence-type': 'Could not create absence type',
  'settings.errors.create-checklist-template-error':
    'Error creating checklist template',
  'settings.errors.create-fleetboard-integration':
    'Error while creating FleetBoard integration',
  'settings.errors.create-issue-category': 'Could not create issue category',
  'settings.errors.create-issue-risk-aspect': 'Could not create risk aspect',
  'settings.errors.create-salary': 'Could not create salary settings',
  'settings.errors.create-scania-integration':
    'Could not create Scania-integration',
  'settings.errors.create-terminal': 'Could not create terminal area',
  'settings.errors.create-time-entry-project': 'Could not create project',
  'settings.errors.create-user-document': 'Could noe create user document',
  'settings.errors.create-user-document-category': 'Could not create category',
  'settings.errors.create-user-type': 'Could not create user type',
  'settings.errors.create-volvo-integration':
    'Could not create Volvo-integration',
  'settings.errors.delete-absence-type': 'Could not delete absence type',
  'settings.errors.delete-fleetboard-integration':
    'Error while deleting FleetBoard integration',
  'settings.errors.delete-issue-category': 'Could not delete issue category',
  'settings.errors.delete-issue-risk-aspect': 'Could not delete risk aspect',
  'settings.errors.delete-salary': 'Could not delete salary setting',
  'settings.errors.delete-scania-integration':
    'Could not delete Scania-integration',
  'settings.errors.delete-terminal': 'Could not delete terminal area',
  'settings.errors.delete-time-entry-project': 'Could not delete the project',
  'settings.errors.delete-user-document': 'Could not delete user document',
  'settings.errors.delete-user-document-category': 'Could not delete category',
  'settings.errors.delete-user-type': 'Could not delete user type',
  'settings.errors.delete-volvo-integration':
    'Could not delete Volvo-integration',
  'settings.errors.password-change': 'Could not change the password',
  'settings.errors.patch-absence-type': 'Could not update absence type',
  'settings.errors.patch-checklist-template-error':
    'Error while updating checklist template',
  'settings.errors.patch-fleetboard-integration':
    'Error while editing FleetBoard integration',
  'settings.errors.patch-issue-category': 'Could not update issue category',
  'settings.errors.patch-issue-risk-aspect': 'Could not update risk aspect',
  'settings.errors.patch-salary': 'Could not update salary settings',
  'settings.errors.patch-scania-integration':
    'Could not update Scania-integration',
  'settings.errors.patch-terminal': 'Could not update terminal area',
  'settings.errors.patch-time-entry-project': 'Could not edit the project',
  'settings.errors.patch-user-document': 'Could not update user document',
  'settings.errors.patch-user-document-category': 'Could not edit category',
  'settings.errors.patch-user-type': 'Could not update user type',
  'settings.errors.patch-volvo-integration':
    'Could not update Volvo-integration',
  'settings.errors.update-dropdowns': 'Could not update dropdowns',
  'settings.errors.update-notifications':
    'Could not update notification settings',
  'settings.errors.update-user-type': 'Could not update user type',
  'settings.errors.user-type-cannot-be-deleted':
    'This user type cannot be deleted',
  'settings.errors.user-type-cannot-be-edited':
    'This usertype cannot be edited',
  'settings.errors.user-type-cannot-be-edited-with-name':
    'User type "{name}" cannot be edited',
  'settings.export-all-absences-for-period': 'Export all absences for period',
  'settings.export-all-driver-activities': 'Export all driver activities',
  'settings.export-all-issues-for-period': 'Export all issues within period',
  'settings.export-all-terminal-entries': 'Export all terminal entries',
  'settings.export-all-timesheets': 'Export all timesheets',
  'settings.export-driverdata-for-user': 'Export driver activities for user',
  'settings.export-reports': 'Export reports',
  'settings.export-tables': 'Table settings for export',
  'settings.export-tables.preview': 'Preview of export table columns',
  'settings.external-id-in-salary-system': 'External id in salary system',
  'settings.fats.max-daily-work-limit': 'Maximum daily work limit',
  'settings.fats.n-weeks': '{n} weeks',
  'settings.fats.period-generated-for': 'Period generated for',
  'settings.fats.period-length': 'Period length',
  'settings.fats.start-date-not-monday': 'Start date must be a monday',
  'settings.fats.start-date-too-late-to-get-valid-interval':
    'Start date must be earlier to get a valid interval',
  'settings.first-to-last-day-in-month': 'First to last day of month',
  'settings.fixed-salary': 'Fixed salary',
  'settings.fleet-board-key-n': 'FleetBoard integration {n}',
  'settings.fms-access-key': 'FMS access key',
  'settings.fms-secret-key': 'FMS secret key',
  'settings.group-salary-on-vehicle': 'Use department from vehicles',
  'settings.has-notification': 'Has notification',
  'settings.hourly-salary': 'Hourly salary',
  'settings.include-absences': 'Include absences',
  'settings.info.absence-types-1':
    'In this view you can add, edit or delete absence types.',
  'settings.info.absence-types-2':
    'It is advised that you show caution when editing an absence type as it will edit all existing absence entries using that type.',
  'settings.info.checklists':
    'In this view you can add, edit and delete checklist templates.',
  'settings.info.driver-data-1':
    'In this view you can adjust settings and run utility functions for drivers and driver activities.',
  'settings.info.driver-data-2':
    'Two datasets are available. Hours - prints the same values for all drivers that are available in the activities module, or Diet - prints the start/end time and place, but not the calculated work period.',
  'settings.info.dropdowns':
    'This screen lets you choose which user types to be selectable choices in the user dropdowns on certain pages. All users with a user type which is selected below will appear in those dropdowns.',
  'settings.info.exports-1': 'In this view you export any data.',
  'settings.info.fats-1':
    'Int his view you can generate FATS reports. The function is in an early phase and only support a few of the FATS paragraphs. More will be coming soon.',
  'settings.info.general':
    'In this view you can configure some basic settings partaining to the company and upload your company logo.',
  'settings.info.handbook-1':
    'In this view you can change the settings for the driver manual.',
  'settings.info.infringements-data-1':
    'In this view you can download or send infringements to mail.',
  'settings.info.integrations':
    'On this screen you can add or edit integration keys.',
  'settings.info.issue-categories-1':
    'In this view you can add, edit and delete issue categories used in issue forms.',
  'settings.info.issue-categories-2':
    'The responsible user is the person who have the highest responsibility regarding issues of the given category, and will usually be notified on any developments regarding issues of that category. Responsible users need to have a user type which has this view assigned through the dropdown view.',
  'settings.info.issue-categories-uneditable':
    'Only responsible user can be edited.',
  'settings.info.notifications':
    "This table let you choose how different events are notified to the users. Remember to save changes after you're done.",
  'settings.info.route-planner':
    'On this page you can customize the settings for the route planner',
  'settings.info.salary': 'In this view you can edit salary settings.',
  'settings.info.table':
    'On this page you customize the look of tables in the company. ',
  'settings.info.terminal-1':
    'On this site you set which networks should be considered valid terminal networks.',
  'settings.info.terminal-2':
    'The IP address uses the ipv4 format, and the IP filter range is a submask range which when set to 24 means all connections on the IP network is considered valid. If wanted one can change that submask to ensure more granular filtering.',
  'settings.info.time-entries-1':
    'On this page you can configure timesheets. Some choices may change the way an export looks, or how total work time is being calculated.',
  'settings.info.user-documents-1':
    'In this view you can edit and delete users documents.',
  'settings.info.user-documents-2':
    'The document responsible is the user who gets the notification when the document expires. A document will expire on the first day in the month selected for expiration date. The notification will be sent the responsible user in a number of weeks before the expiration set by the notification time-field.',
  'settings.info.user-types-1':
    'On this page you can change user types and permissions.',
  'settings.info.user-types-2':
    'Note that all changes under "Change" and "Delete" grants access to change and delete resources created by others. Everyone who has permission to create a resource is also granted access to change their own resources.',
  'settings.info.vehicles':
    'In this view you can edit vehicle responsible users. Notifications will be sent to these users as a default.',
  'settings.infringements-responsible': 'Infringements responsible user',
  'settings.integration': 'Integration',
  'settings.integrations': 'Integrations',
  'settings.integrations-description':
    'On this screen you can add or edit integration keys.',
  'settings.is-administrator': 'Is administrator',
  'settings.is-administrator-description':
    'Administrator accounts will have all permissions, regardless of this table',
  'settings.last-day-of-month': 'Last day of month',
  'settings.man-key-n': 'MAN api key #{n}',
  'settings.module-options': 'Module options',
  'settings.my-settings': 'My Settings',
  'settings.n-departments': '{n} departments',
  'settings.no-checklist-templates': 'No checklist templates.',
  'settings.no-integration-keys': 'No integration keys found',
  'settings.no-selected-absence-responsible':
    'Nobody is selected as absence responsible',
  'settings.no-selected-infringements-responsible':
    'No infringements responsible user chosen',
  'settings.notifications.notify-driver-on-infringement':
    'Notify driver for new infringements',
  'settings.notifications.setting.absence-created': 'Absence created',
  'settings.notifications.setting.driver-card': 'Driver card expiration',
  'settings.notifications.setting.forum-post': 'New forum comment',
  'settings.notifications.setting.forum-thread': 'New forum thread',
  'settings.notifications.setting.issue-closed': 'Issue closed',
  'settings.notifications.setting.issue-created': 'Issue created',
  'settings.notifications.setting.reset-password': 'Reset password',
  'settings.notifications.setting.user-document': 'Expired user document',
  'settings.notifications.setting.user-export-ready': 'Export completed',
  'settings.notifications.setting.user-registration': 'User registration',
  'settings.notifications.vehicle-document': 'Expired vehicle document',
  'settings.notifications.vehicle-expirations': 'Expired vehicle',
  'settings.ovetime-periods': 'Overtime periods',
  'settings.paid-pause-threshold':
    'Limit for paid pause(every pause under the selected limit)',
  'settings.pause-duration': 'Pause duration',
  'settings.pause-duration-short': 'Break',
  'settings.pause-threshold': 'Work limit for pause activation',
  'settings.pause-threshold-short': 'Work before break',
  'settings.period': 'Period',
  'settings.period-work-limit': 'Weekly work limit (hours)',
  'settings.period-work-limit-short': 'Weekly',
  'settings.prompts.absence-type-create': 'Name of new absence type: ',
  'settings.prompts.absence-type-delete':
    'Are you sure you want to delete the absence type "{name}"?',
  'settings.prompts.absence-type-delete-title': 'Delete absence type',
  'settings.prompts.create-user-type':
    'What should be the name of the new user type?',
  'settings.prompts.delete': 'Do you really want to delete {}?',
  'settings.prompts.delete-checklist-template': 'Delete checklist template',
  'settings.prompts.delete-checklist-template-description':
    'Do you want to delete the checklist template?',
  'settings.prompts.delete-time-entry-project':
    'Are you sure you want to delete the project "{name}"?',
  'settings.prompts.department-delete-title': 'Delete department',
  'settings.prompts.department-setting-delete-title':
    'Do you wish to delete department?',
  'settings.prompts.holiday-allowance-delete-title': 'Delete holiday allowance',
  'settings.prompts.integration-delete-title': 'Delete integration',
  'settings.prompts.integration-fleet-board-delete':
    'Delete FleetBoard integration {n}?',
  'settings.prompts.integration-man-delete':
    'Are you sure you want to delete MAN integration {n}',
  'settings.prompts.integration-scania-delete':
    'Are you sure you want to delete Scania-integration #{n}?',
  'settings.prompts.integration-volvo-delete':
    'Are you sure you want to delete Volvo-integration #{n}?',
  'settings.prompts.issue-category-delete':
    'Are you sure you want to delete the issue category "{name}"?',
  'settings.prompts.issue-category-delete-title': 'Delete issue category',
  'settings.prompts.issue-risk-aspect-delete':
    'Do you wish to delete the risk aspect {name}?',
  'settings.prompts.issue-risk-aspect-delete-title': 'Delete risk aspect',
  'settings.prompts.salary-code-delete-title': 'Delete salary code',
  'settings.prompts.salary-setting-delete-title':
    'Do you widh to delete salary and work time settings?',
  'settings.prompts.terminal-delete':
    'Are you sure you want to delete terminal area "{name}"?',
  'settings.prompts.terminal-delete-title': 'Delete terminal area',
  'settings.prompts.time-entry-project-delete-title': 'Delete project',
  'settings.prompts.user-document-delete':
    'Are you sure you want to delete the user document "{name}"?',
  'settings.prompts.user-document-delete-title': 'Delete user document',
  'settings.prompts.user-type-delete':
    'Are you completely sure you want to delete this user type?',
  'settings.prompts.user-type-delete-title': 'Delete user type',
  'settings.reciept-functionality': 'Reciept functionality',
  'settings.refresh-colors': 'Refresh colors',
  'settings.responsible-user': 'Responsible user',
  'settings.salary-and-work-time': 'Salary and work hours',
  'settings.salary-and-work-time-type': 'salary',
  'settings.salary-code': 'Salary type',
  'settings.salary-code-day': 'Salary code day payment',
  'settings.salary-code-id-field-placeholder': 'External id in salary system',
  'settings.salary-day-other-salary-code-warning':
    'If this setting is set hours are calculated as normal if there is no salary day present.',
  'settings.salary-end-date': 'Salary end date',
  'settings.salary-period': 'Salary period',
  'settings.salary-start-date': 'Salary start date',
  'settings.salary-type': 'Salary type',
  'settings.save-changes': 'Save changes',
  'settings.save-settings': 'Save settings',
  'settings.scania-key': 'Scania key',
  'settings.scania-key-n': 'Scania key #{n}',
  'settings.select-api-key-to-add': 'Choose API key to add',
  'settings.select-categories': 'Select categories',
  'settings.select-category': 'Select category',
  'settings.select-crontab-setting': 'Select generation frequency',
  'settings.select-module': 'Select module',
  'settings.show-column': 'Show column',
  'settings.show-dynamic-column-description':
    'When a column is deactivated, it is hidden from the table and exports from the module.',
  'settings.show-dynamic-column-error': 'At least two columns must be active.',
  'settings.show-in-manual-time-entry-overtime':
    'Show in manual overtime override of time entries',
  'settings.standard-settings': 'Standard settings',
  'settings.static-categories': 'Static categories',
  'settings.tables': 'Table Settings',
  'settings.tables.add-column-description':
    'When a column is added, it is shown in tables from the module.',
  'settings.tables.add-export-column-description':
    'When a column is added, it is shown in export tables from the module.',
  'settings.tables.add-placeholder': 'Choose a column to add',
  'settings.tables.preview': 'Preview of table',
  'settings.tachograph-key': 'Tachograph key',
  'settings.terminal-description-1':
    'On this site you set which networks should be considered valid terminal networks.',
  'settings.terminal-description-2':
    'The IP address is on the form of ipv4, and the IP filter range is a submask range which when set to 24 means all connections on the IP network is considered valid. If wanted one can change that submask to ensure more granular filtering.',
  'settings.time-entry-projects': 'Projects',
  'settings.timesheets.alternative-overtime-option':
    'Alternative overtime option',
  'settings.timesheets.count-pause-as-part-of-total-work':
    'Count pause as part of total work time',
  'settings.timesheets.department-mandatory': 'Department required',
  'settings.timesheets.merge-timesheets-with-activities':
    'Merge timesheet data with activities',
  'settings.timesheets.periodic-overtime': 'Periodic overtime',
  'settings.timesheets.projects': 'Projects',
  'settings.toast.create-man-integration-error': 'Could not create MAN API key',
  'settings.toast.create-man-integration-success': 'MAN API key created',
  'settings.toast.delete-man-integration-error': 'Could not delete MAN API key',
  'settings.toast.delete-man-integration-success': 'MAN API key deleted',
  'settings.toast.patch-man-integration-error': 'Could not change MAN API key',
  'settings.toast.patch-man-integration-success': 'MAN API key changed',
  'settings.toasts.add-column-success': 'The column was added',
  'settings.toasts.change-language-success': 'Language changed',
  'settings.toasts.company-settings-updated': 'Company settings updated',
  'settings.toasts.create-absence-type-success':
    'Absence type "{name}" created',
  'settings.toasts.create-checklist-template-success':
    'Checklist template was added.',
  'settings.toasts.create-fleetboard-integration-success':
    'FleetBoard integration was created',
  'settings.toasts.create-issue-category-success':
    'Issue category "{name}" created',
  'settings.toasts.create-issue-risk-aspect-success':
    'Risk aspect {name} created',
  'settings.toasts.create-salary-success': 'Salary settings created',
  'settings.toasts.create-scania-integration-success':
    'Scania-integration created',
  'settings.toasts.create-terminal-success':
    'Terminal area "{name}" was created',
  'settings.toasts.create-time-entry-project-success':
    'The project "{name}" was created',
  'settings.toasts.create-user-document-category-success': 'Category created',
  'settings.toasts.create-user-document-success': 'User document created',
  'settings.toasts.create-user-type-success': 'User type "{name}" created',
  'settings.toasts.create-volvo-integration-success':
    'Volvo-integration created',
  'settings.toasts.delete-absence-type-success': 'Absence type deleted',
  'settings.toasts.delete-fleetboard-integration-success':
    'FleetBoard integration was deleted',
  'settings.toasts.delete-issue-category-success': 'Issue category deleted',
  'settings.toasts.delete-issue-risk-aspect-success': 'Risk aspect deleted',
  'settings.toasts.delete-salary-success': 'Salary setting deleted',
  'settings.toasts.delete-scania-integration-success':
    'Scania-integration deleted',
  'settings.toasts.delete-terminal-success': 'Terminal area deleted',
  'settings.toasts.delete-time-entry-project-success': 'Project deleted',
  'settings.toasts.delete-user-document-category-success': 'Category deleted',
  'settings.toasts.delete-user-document-success': 'User document deleted',
  'settings.toasts.delete-user-type-success': 'User type deleted',
  'settings.toasts.delete-volvo-integration-success':
    'Volvo-integration deleted',
  'settings.toasts.password-change-success': 'Your password was changed',
  'settings.toasts.patch-absence-type-success': 'Absence type updated',
  'settings.toasts.patch-checklist-template-success':
    'Checklist template updated',
  'settings.toasts.patch-fleetboard-integration-success':
    'FleetBoard-integration changed successfully',
  'settings.toasts.patch-issue-category-success': 'Issue category was updated',
  'settings.toasts.patch-issue-risk-aspect-success': 'Risk aspect updated',
  'settings.toasts.patch-salary-success': 'Salary settings updated',
  'settings.toasts.patch-scania-integration-success':
    'Scania-integration updated',
  'settings.toasts.patch-terminal-success': 'Terminal area updated',
  'settings.toasts.patch-time-entry-project-success': 'The project was changed',
  'settings.toasts.patch-user-document-category-success': 'Category changed',
  'settings.toasts.patch-user-document-success': 'User document updated',
  'settings.toasts.patch-user-type-success': 'User type updated',
  'settings.toasts.patch-volvo-integration-success':
    'Volvo-integration updated',
  'settings.toasts.remove-column-success': 'The column was removed',
  'settings.toasts.update-dropdowns-success': 'Dropdowns updated',
  'settings.toasts.update-notifications-success':
    'Notification settings updated',
  'settings.toasts.update-user-type-success': 'User type updated',
  'settings.tv-view': 'Show on TV',
  'settings.type-of-work': 'Type of work',
  'settings.updated-type': 'Updated {type}',
  'settings.upload': 'Upload',
  'settings.upload-type': 'Upload {type}',
  'settings.use-as-site-logo': 'Use as logo',
  'settings.use-period-addons': 'Use period addons',
  'settings.use-period-overtime': 'Use period overtime',
  'settings.use-salary-day-payment': 'Use salary day payment',
  'settings.use-week-overtime': 'Use week overtime',
  'settings.user-documents-description-title': 'User document settings',
  'settings.user-type.create-user-type': 'Create new user type',
  'settings.user-types-and-permissions': 'User types and permissions',
  'settings.valid-salary-dates':
    'Start date needs to be one day later than stop date.',
  'settings.validation.absence-type-name-exists':
    'This absence type already exists',
  'settings.validation.already-holiday-allowance-on-day':
    'There already exists a holiday allowance on this date',
  'settings.validation.company.logo-file-size':
    'File size of logo cannot exceed 128 MiB',
  'settings.validation.company.must-have-name': 'Company must have a name',
  'settings.validation.ip-address': 'Invalid format',
  'settings.validation.issue-category-name-exists':
    'An issue category with this name already exists',
  'settings.validation.issue-risk-aspect-name-exists':
    'A risk aspect with this name already exists',
  'settings.validation.last-admin-user-type-delete':
    'This is the only admin user type, and thus cannot be deleted.',
  'settings.validation.last-admin-user-type-toggle':
    'This is the only admin user type, and thus cannot be toggled off.',
  'settings.validation.salary-driver-pause-length': 'At least 45 minutes',
  'settings.validation.salary-priority-unique':
    'There already exists a salary setting with this priority for {module}',
  'settings.validation.terminal-range': 'Must be between 1 and 32',
  'settings.validation.time-entry-project-name-exists':
    'A project with the given name already exists',
  'settings.validation.user-document-name-exists':
    'A category with that name already exists',
  'settings.validation.user-documents.must-have-name': 'Must have name',
  'settings.validation.user-documents.must-set-responsible-user':
    'Must set user',
  'settings.validation.user-type-name-exists':
    'A user type with this name already exists',
  'settings.validation.user-type-name-exists-short': 'Name exists',
  'settings.volvo-key': 'Volvo key',
  'settings.volvo-key-n': 'Volvo key #{n}',
  'settings.week-overtime-delayed-overtime-warning':
    'With weekly calculation activated overtime for overlapping weeks are added to the next month.',
  'settings.week-salary-code': 'Week overtime',
  'statistics.average-driver-grade': 'Average driver grad',
  'statistics.average-fuel-consumption': 'Average fuel consumption',
  'statistics.driver-grade': 'Driver grade',
  'statistics.kilometers-driven': 'Kilometers driven',
  'statistics.l-10-km': 'L/10 km',
  'statistics.l-100-km': 'L/100 km',
  'statistics.monthly-company-statistics': 'Monthly company statistics',
  'statistics.monthly-driver-statistics': 'Your monthly statistics',
  'statistics.number-of-issues': 'Number of issues',
  'table.week-sum': 'Weekly sum',
  'terminal.check-in': 'Check in',
  'terminal.check-out': 'Check out',
  'terminal.checked-in': 'Checked in',
  'terminal.checked-in-at': 'Checked in at {time}',
  'terminal.checked-in-message':
    'You checked in at {checkIn} and have worked {total} hours',
  'terminal.checked-out': 'Checked out',
  'terminal.checked-out-at': 'Checked out at {time}',
  'terminal.edit-terminal-entry': 'Edit terminal entry',
  'terminal.error.delete-terminal-entry': 'Could not delete terminal entry',
  'terminal.error.edit-terminal-entry': 'Could not edit terminal entry',
  'terminal.ip-address': 'IP address',
  'terminal.ip-filter-range': 'IP filter range',
  'terminal.monthly-print': 'Monthly printout',
  'terminal.no-activity-period':
    'You have no registered activity for this period',
  'terminal.no-activity-today': 'You have not registered any activity today.',
  'terminal.not-on-network':
    'You need to be on a terminal network to check in or out',
  'terminal.old-checked-in-message': 'You checked in {checkIn}',
  'terminal.prompt.delete-terminal-entry':
    'Do you want to delete this terminal entry?',
  'terminal.prompt.delete-terminal-entry-title': 'Delete terminal entry?',
  'terminal.salary-basis': 'Salary basis',
  'terminal.success.delete-terminal-entry': 'Deleted terminal entry',
  'terminal.success.edit-terminal-entry': 'Edited terminal entry',
  'terminal.terminal-areas': 'Terminal areas',
  'terminal.terminal-entries': 'Terminal entries',
  'terminal.terminal-entry': 'Terminal entry',
  'terminal.terminal-name': 'Terminal name',
  'terminal.time': 'Time',
  'terminal.user-activity': 'Activity for {user}',
  'terminal.user-no-activity-period':
    '{user} has no registered activity for chosen period',
  'terminal.your-activity': 'Your activity',
  'terminal.your-activity-today': 'Your activity today',
  'thread.success-creation': 'Created new thread',
  'timesheets.absence-approved': 'Approved',
  'timesheets.absence-create-type': 'Create new absence type',
  'timesheets.absence-pending': 'Pending',
  'timesheets.absence-rejected': 'Rejected',
  'timesheets.absence-type': 'Absence type',
  'timesheets.absence-type-name': 'Absence type name',
  'timesheets.absence-types': 'Absence types',
  'timesheets.absence-types-error-1':
    'Absences cannot be added more than once per day per employee',
  'timesheets.delete-absence': 'Delete absence',
  'timesheets.delete-time-entry': 'Delete time entry',
  'timesheets.edit-absence': 'Edit absence',
  'timesheets.edit-absence-status': 'Edit absence status',
  'timesheets.edit-time-entry': 'Edit time entry',
  'timesheets.error-create-absence': 'Could not add absence',
  'timesheets.error-create-timeentry':
    'Time entry with same date and time already exists',
  'timesheets.error-registration-absence': 'Error registering new absence',
  'timesheets.error-update-absence': 'Error updating absence',
  'timesheets.error-update-timeentry': 'Error updating time entry',
  'timesheets.error.delete-absence': 'Could not delete absence',
  'timesheets.error.delete-time-entry': 'Could not delete time entry',
  'timesheets.error.overtime-mode-required': 'Overtime mode is required',
  'timesheets.error.patch-absence': 'Could not update absence',
  'timesheets.error.patch-time-entry': 'Could not update time entry',
  'timesheets.error.pause-required': 'Pause has to be longer than {n} minutes',
  'timesheets.error.start-before-end': 'Start time must be before end time',
  'timesheets.export-timesheet': 'Export timesheet',
  'timesheets.new-absence-request': 'New absence request',
  'timesheets.new-time-entry': 'New time entry',
  'timesheets.note': 'Comment regarding absence',
  'timesheets.overtime-alt': 'Overtime',
  'timesheets.print-timesheet': 'Print timesheet',
  'timesheets.prompt.delete-absence':
    'Are you sure you wish to delete this absence?',
  'timesheets.prompt.delete-absence-title': 'Delete absence',
  'timesheets.prompt.delete-time-entry':
    'Are you sure you wish to delete this time entry?',
  'timesheets.prompt.delete-time-entry-title': 'Delete time entry',
  'timesheets.register-absence': 'Register absence',
  'timesheets.register-timeentry': 'Register time entry ',
  'timesheets.select-absence-type': 'Select absence type',
  'timesheets.send-request': 'Send request',
  'timesheets.success-registration-absence': 'New absence registered',
  'timesheets.success-registration-timeentry': 'New time entry registered',
  'timesheets.success-update-absence': 'Absence updated',
  'timesheets.success-update-timeentry': 'Time entry updated',
  'timesheets.timeentry': 'Time entry',
  'timesheets.toast.delete-absence-success': 'Absence deleted',
  'timesheets.toast.delete-time-entry-success': 'Time entry deleted',
  'timesheets.toast.patch-absence-success': 'Absence updated',
  'timesheets.toast.patch-time-entry-success': 'Time entry updated',
  'timesheets.update-absence': 'Update absence',
  'timesheets.update-timeentry': 'Update time entry',
  'timesheets.view-all-absence': 'All absence',
  'timesheets.view-all-timeentries': 'All time entries',
  'timesheets.view-your-absence': 'Your absence',
  'timesheets.view-your-timeentries': 'Your time entries',
  'timesheets.warning.time-entry-exist-in-daterange':
    'A time entry with an overlapping time period already exists',
  'toast.success-archive-type': 'Successfully archived {type}',
  'toast.success-un-archive-type': 'Re opened {type}',
  'toasts.email-sent-successfully': 'Email sent successfully',
  'toasts.error-add-type': 'Could not add {type}',
  'toasts.error-delete-type': 'Could not delete {type}',
  'toasts.error-update-type': 'Could not update {type}',
  'toasts.error.create-type': 'Could not create {type}',
  'toasts.error.update-type': 'Could not update {type}',
  'toasts.error.upload-type': 'Could not upload {type}',
  'toasts.success-add-type': 'Successfully added {type}.',
  'toasts.success-created-type': 'Successfully created {type}',
  'toasts.success-delete-type': 'Deleted {type}',
  'toasts.success-update-type': 'Updated {type}',
  'toasts.success.upload-type': 'Uploaded {type}',
  'user-type-settings.delete-prompt-text':
    'Are you sure you want to delete the user type?',
  'user-type-settings.delete-prompt-title': 'Delete user type',
  'user-type-settings.title': 'User types and permissions',
  'user-type-settings.toasts.error': 'Error updating',
  'user-type-settings.toasts.success': 'Updated user types',
  'user-types.change-settings-label': 'Change',
  'user-types.create-settings-label': 'Create',
  'user-types.driver-activities-self': 'Activities (own)',
  'user-types.everyones-driver-activities': 'Activities (everybody)',
  'user-types.remove-settings-label': 'Delete',
  'user-types.view-settings-label': 'View',
  'users.activate-user': 'Activate user',
  'users.active-driver-card': 'Active driver card',
  'users.add-driver-card': 'Add driver card',
  'users.add-driver-card-number': 'Add driver card number',
  'users.add-expiry-date': 'Add expiry date',
  'users.add-your-driver-number': 'Add your driver card number',
  'users.awaits-configuration': 'Awaiting configuration',
  'users.bio': 'Profile Bio',
  'users.birth-number': 'Birth Number',
  'users.change-picture': 'Upload image',
  'users.change-profile-picture': 'Upload image',
  'users.contact-person-name': 'Contact person name',
  'users.contact-person-phone': 'Contact person phone',
  'users.create-password.description':
    'Create a new password for your newly added user',
  'users.create-password.title': 'Create new password',
  'users.create-user': 'Create user',
  'users.deactivate-user': 'Deactivate user',
  'users.delete-driver-card': 'Delete driver card',
  'users.delete-driver-card-message': 'Do you want to delete this driver card?',
  'users.delete-existing-number':
    'Do you wish to remove the existing number from its current user?',
  'users.delete-expired-driver-card': 'Delete expired driver card',
  'users.delete-expired-driver-card-message':
    'Do you want to delete the expired driver card?',
  'users.delete-user': 'Delete user',
  'users.driver-card': 'Driver card',
  'users.driver-card-number': 'Driver card number',
  'users.edit-driver-card': 'Edit driver card',
  'users.edit-profile': 'Edit profile',
  'users.edit-profile-information': 'Edit profile information',
  'users.edit-user': 'Edit user',
  'users.errors.driver-card-expiry-format': 'Format: MM.YY',
  'users.errors.driver-card-invalid-expiry': 'Expiry date is invalid',
  'users.errors.phone-number-taken':
    'The phone number is attached to another user',
  'users.expired-cards': 'Expired driver cards',
  'users.expired-driver-card': 'Expired driver card',
  'users.has-document': 'User has document:',
  'users.has-no-companies':
    'You are not affiliated with any company. Please contact your administrator.',
  'users.include-expired-documents': 'Include expired documents',
  'users.internal-number': 'Internal number',
  'users.missing-external-id': 'Missing external id',
  'users.my-profile': 'My profile',
  'users.n-has-one-or-more-expired-driver-cards':
    '{n} has one or more expired driver cards',
  'users.n-users': '{n} users',
  'users.new': 'New user',
  'users.new-registration-link-send': 'New invitation sent',
  'users.no-more-user-types': 'No more user types',
  'users.number-already-exists': 'A user, {name}, already use this number',
  'users.personal-info': 'Personal information',
  'users.profile': 'Profile',
  'users.profile-did-change': 'Profile information was updated',
  'users.profile-of-user': 'Profile: {user}',
  'users.prompts.deactivate-self':
    'Are you sure you wish to deactivate your own user?',
  'users.prompts.deactivate-self-title': 'Deactivate own user',
  'users.prompts.deactivate-user':
    'Are you sure you wish to deactivate this user?',
  'users.prompts.deactivate-user-title': 'Deactivate user',
  'users.search': 'Search for user',
  'users.select-user-types': 'Select user types',
  'users.send-new-invitation': 'Send new invitation',
  'users.settings': 'My Settings',
  'users.show-deactivated': 'Show deactivated users',
  'users.stopped-in-control': 'Stopped for inspection?',
  'users.toasts.submit-driver-card-success': 'Successfully added driver card',
  'users.toasts.user-activated': 'The user {name} was activated',
  'users.toasts.user-deactivated': 'The user {name} was deactivated',
  'users.too-weak-password': 'Too weak password',
  'users.user-is-deactivated': 'This user is deactivated',
  'vehicles.add-expiration-date': 'Add expiration date',
  'vehicles.add-vehicle': 'Add vehicle',
  'vehicles.administrative': 'Administrative',
  'vehicles.back-lifter': 'Back lifter',
  'vehicles.brand': 'Brand',
  'vehicles.cool-freeze-heat': 'Cool/freeze/heat',
  'vehicles.crane': 'Crane',
  'vehicles.crane-type': 'Crane type',
  'vehicles.delete-vehicle': 'Delete vehicle',
  'vehicles.delete-vehicle-message':
    'Are you sure you want to delete this vehicle/material?',
  'vehicles.delete-vehicle-title': 'Delete vehicle/material?',
  'vehicles.diesel-card-number': 'Diesel card nr',
  'vehicles.edit-expiration-date': 'Edit expiration date',
  'vehicles.edit-vehicle': 'Edit vehicle',
  'vehicles.edit-vehicle-document': 'Edit vehicle document',
  'vehicles.engine': 'Engine',
  'vehicles.euro-class': 'Euro class',
  'vehicles.existing-vehicle': 'Existing vehicle',
  'vehicles.expiration-dates': 'Expiration dates',
  'vehicles.extension-type': 'Extension type',
  'vehicles.ferry-card-number': 'Ferry card nr',
  'vehicles.first-registered': 'First time registered',
  'vehicles.fleet': 'Fleet',
  'vehicles.fleet-management': 'Fleet management',
  'vehicles.fleet-overview': 'Equipment and materials',
  'vehicles.fridge': 'Fridge',
  'vehicles.fridge-type': 'Fridge type',
  'vehicles.fuel': 'Fuel',
  'vehicles.goods-insurance-type': 'Goods insurance type',
  'vehicles.height': 'Height',
  'vehicles.inner-height': 'Inner height',
  'vehicles.inner-length': 'Inner length',
  'vehicles.inner-width': 'Inner width',
  'vehicles.km-per-month': 'Car km per month',
  'vehicles.km-per-year': 'Car km per year',
  'vehicles.license-number': 'License nr',
  'vehicles.loading-height': 'Loading height',
  'vehicles.max-back-axle-load': 'Maximum back axle load',
  'vehicles.max-front-axle-load': 'Maximum front axle load',
  'vehicles.maximum-coupling-load': 'Maximum coupling load',
  'vehicles.maximum-payload': 'Maximum payload',
  'vehicles.maximum-semi-trailer-weight': 'Maximum semi trailer weight',
  'vehicles.maximum-speed': 'Maximum speed',
  'vehicles.maximum-truck-weight': 'Maximum truck weight',
  'vehicles.maxium-total-weight': 'Maximum total weight',
  'vehicles.meat-rack': 'Meat rack',
  'vehicles.model': 'Model',
  'vehicles.net-weight': 'Net weight',
  'vehicles.new-expiration-date': 'New expiration date',
  'vehicles.new-vehicle-document': 'New vehicle document',
  'vehicles.next-periodic-control': 'Next periodic control',
  'vehicles.no-expiration-dates': 'No expiration dates',
  'vehicles.no-file-selected': 'No file selected',
  'vehicles.owned': 'Owned',
  'vehicles.pallet-spaces': 'Number of pallet spaces',
  'vehicles.pin-code': 'PIN code',
  'vehicles.power': 'Power',
  'vehicles.previous-periodic-control': 'Previous periodic control',
  'vehicles.prompts.delete-expiration-date': 'Delete expiration date',
  'vehicles.registered-country': 'Registered country',
  'vehicles.service-agreement': 'Service agreement',
  'vehicles.service-and-maintenance': 'Service and maintenance',
  'vehicles.side-opening': 'Side opening',
  'vehicles.sold-date': 'Sold date',
  'vehicles.stationing-location': 'Stationing location',
  'vehicles.studded-drive-tire': 'Studs inner drive tire',
  'vehicles.studded-front-tire': 'Studs front tire',
  'vehicles.tires-dimension-bogie': 'Bogie tire dimension',
  'vehicles.tires-dimension-drive': 'Drive tire dimension',
  'vehicles.tires-dimension-front': 'Front tire dimension',
  'vehicles.toasts.vehicle-updated': 'Vehicle updated successfully',
  'vehicles.toll-tag-number': 'Toll tag nr',
  'vehicles.unowned': 'Unowned',
  'vehicles.usage-properties': 'Usage properties',
  'vehicles.vbg-coupling': 'VBG coupling',
  'vehicles.vehicle-details': 'Vehicle details',
  'vehicles.vehicle-technical-type': 'Technical vehicle type',
  'vehicles.vehicle-type': 'Vehicle type',
  'vehicles.view-vehicle-details': 'View vehicle details',
  'vehicles.vin': 'VIN',
  'vehicles.volume': 'Volume',
  'vehicles.weights': 'Weights',
}
export default en
