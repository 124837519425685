import { useState } from 'react'
import delay from 'lodash/delay'

const useModal = <M>(modalItem?: M) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [activeItem, setActiveItem] = useState<M | undefined>(modalItem)

  /* Simple utility functions */
  const closeModal = () => {
    // Delay so exit animations can finish
    delay(() => setActiveItem(undefined), 300)
    setModalOpen(false)
  }
  const openModal = (modalItem?: M): void => {
    if (typeof modalItem !== 'undefined') setActiveItem(modalItem)
    setModalOpen(true)
  }

  return {
    openModal: openModal,
    closeModal: closeModal,

    modalOpen: modalOpen,
    setModalOpen: setModalOpen,

    modalItem: activeItem,
    setModalItem: setActiveItem,
  }
}

export default useModal
