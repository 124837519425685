import { useState } from 'react'

import differenceInSeconds from 'date-fns/differenceInSeconds'
import {
  format,
  formatDistanceToNow,
  FormatDistanceToNowOptions,
} from 'util/date-fns'
import { useInterval } from './useInterval'

/**
 * Time since the given timestamp, updated every second.
 * The time is returned as a localized string
 *
 * @param {Date} timestamp The timestamp to calculate the time from
 * @param {Number} cutOff Time in seconds before the updates stop
 * @param {FormatDistanceToNowOptions} distanceOptions options object
 *  for formatDistanceToNow function. Defaults to {addSuffix: true}.
 */
export function useTimeSince(
  timestamp: Date,
  cutOff?: number,
  distanceOptions: FormatDistanceToNowOptions = { addSuffix: true }
) {
  const update = () => {
    let shouldCutOff = false
    if (cutOff) {
      shouldCutOff = differenceInSeconds(timestamp, new Date()) > cutOff
    }

    return shouldCutOff
      ? format(timestamp, 'LL')
      : formatDistanceToNow(timestamp, distanceOptions)
  }
  const [value, setValue] = useState(update)

  useInterval(() => {
    const newValue = update()
    if (value !== newValue) {
      setValue(newValue)
    }
  }, 1000)

  return value
}
