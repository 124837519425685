import { gql } from '@apollo/client'

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetMyPasswordMutation($username: String!) {
    resetMyPassword(username: $username) {
      ok
    }
  }
`

export const LOGIN_MUTATION = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      ok
      token
      user {
        id
        email
        username
        isStaff
        isSuperuser
      }
    }
  }
`
