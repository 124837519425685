import { Header } from 'modules/header/Header'
import { Sidebar } from 'modules/sidebar'
import React, { useRef } from 'react'
import { isMobile } from 'react-device-detect'
import { SidebarGraph } from 'store/sidebar'
import styled, { css } from 'styled-components'
import { AuthRoutes } from './AuthRoutes'
import { ContentScrollProvider } from './providers'
import { ScrollToTop } from './ScrollToTop'

interface WrapperProps {
  sidebarOpen: boolean
}
const Wrapper = styled.div<WrapperProps>`
  display: grid;

  grid-template-columns: clamp(290px, 15.1vw, 360px) 1fr;
  grid-template-rows: 1fr;

  grid-template-areas:
    'sidebar main'
    'sidebar main';

  width: 100%;
  height: 100%;

  ${props => props.theme.layout.mobile} {
    display: flex;
    flex-direction: column;

    ${props =>
      props.sidebarOpen &&
      css`
        overflow-y: auto;
      `};
  }
`
const ContentWrapper = styled.div`
  grid-area: main;
  position: relative;
  max-height: 100vh;
  overflow-y: auto;

  ${props => props.theme.layout.mobile} {
    padding: 0;
    max-height: auto;
    min-height: 100%;
    width: 100%;
    overflow-x: visible;
    overflow-y: visible;
  }
`

const HeaderWrapper = styled.header`
  ${props => props.theme.layout.mobile} {
    padding-top: ${props => props.theme.layout.headerHeightMobile};

    .breadcrumbs {
      padding: 14px 20px;
      font-size: 0.8em;
    }
  }
`

const SidebarWrapper = styled.aside`
  grid-area: sidebar;
  overflow: hidden auto;

  box-shadow: 2px 0 24px 0 #00000012;
  
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: white;
  }
  &::-webkit-scrollbar-thumb {
    border: 2px solid white;
    border-radius: 999px;
    background-color: white;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: ${props => props.theme.colors.gray9};
    }
  }
`

function MainLayout() {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const [{ open }] = SidebarGraph.useGraph()

  return (
    <Wrapper id="main-wrapper" ref={wrapperRef} sidebarOpen={open}>
      {isMobile && (
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>
      )}

      <SidebarWrapper>
        <Sidebar />
      </SidebarWrapper>

      <ContentWrapper ref={contentRef}>
        {/* This ContentWrapper element is responsible for the main body-scroll, so we add a context provider here */}
        <ContentScrollProvider contentRef={contentRef}>
          <ScrollToTop />

          {!isMobile && (
            <HeaderWrapper>
              <Header />
            </HeaderWrapper>
          )}

          <AuthRoutes />
        </ContentScrollProvider>
      </ContentWrapper>
    </Wrapper>
  )
}

export default MainLayout
