import { gql } from '@apollo/client'

export const TimesheetFragments = {
  absenceInfo: gql`
    fragment AbsenceInfo on AbsenceNode {
      id
      absenceType {
        id
        name
      }
      datetimeStart
      datetimeEnd
      reason
    }
  `,
  timeEntryInfo: gql`
    fragment TimeEntryInfo on TimeEntryNode {
      id
      datetimeStart
      datetimeEnd
      project {
        id
        name
      }
      comment
      pause
      overtime
      totalWork
    }
  `,
}
