import { useTranslate } from '@ur/react-hooks'
import { Card } from 'components/Card'
import omit from 'lodash/omit'
import Delta from 'quill-delta'
import React, { useRef } from 'react'
import { useMutation } from '@apollo/client'
import styled from 'styled-components'
import { OnlyRequired } from 'types/util'
import { FileAttachment, useOnErrorAuto } from 'util/hooks'
import { CREATE_POST_MUTATION } from '../../mutations'
import {
  CreatePostMutation,
  CreatePostMutationVariables,
  ThreadNode,
} from '../../types.graphql'
import { ForumQuillEditor, ForumQuillEditorRef } from '..'
import { quillValidation } from 'util/forms'

const Wrapper = styled(Card)`
  padding: 0;
`

interface CreatePostProps {
  thread: OnlyRequired<ThreadNode, 'id'>
}

export const CreatePost: React.VFC<CreatePostProps> = ({ thread }) => {
  const translations = useTranslate({
    comment: 'common.comment-ellipsis',
  })

  const quillRef = useRef<ForumQuillEditorRef>(null)

  const onErrorAuto = useOnErrorAuto()

  const [createPostMutation, { loading: createPostLoading }] = useMutation<
    CreatePostMutation,
    CreatePostMutationVariables
  >(CREATE_POST_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['ForumThreads', 'ThreadWithPosts'],
    onCompleted() {
      if (!quillRef.current) return

      quillRef.current.quill?.setContents(new Delta())
    },
    onError: onErrorAuto(),
  })

  async function onSubmit(content: string, attachments: FileAttachment[]) {
    const delta = quillRef.current?.quill?.getContents()
    if (!quillValidation(delta)) return
    await createPostMutation({
      variables: {
        input: {
          thread: thread.id,
          content: content,
          attachments: attachments.map(attachment => omit(attachment, 'id')),
        },
      },
    })
  }

  return (
    <Wrapper>
      <ForumQuillEditor
        ref={quillRef}
        placeholder={translations.comment}
        loading={createPostLoading}
        onSubmit={onSubmit}
      />
    </Wrapper>
  )
}
