import { FAIcon } from 'components/icons'
import { useActivityApprovedHandlers } from 'modules/activities/hooks'
import { MouseEventHandler } from 'react'
import theme from 'theme'
import { Cell } from '../Cell'
import { usePermissions, useUser } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'

/* CheckmarkCell */
export interface CheckmarkProps {
  checked: boolean | null

  size?: string
}
export const Checkmark: React.VFC<CheckmarkProps> = ({
  checked,
  size = '1.5rem',
}) => {
  if (checked === null) return <></>
  return (
    <FAIcon
      fixedWidth
      size={size}
      type="light"
      icon={checked ? 'check-circle' : 'times-circle'}
      color={
        checked ? theme.colors.activitiesGreen : theme.colors.activitiesRed
      }
      hoverColor={checked ? theme.colors.green : theme.colors.darkRed}
    />
  )
}

interface CheckmarkCellProps extends CheckmarkProps {
  day: Date
  onClick?: MouseEventHandler<HTMLTableCellElement>
  userId?: string
}
export const CheckmarkCell: React.VFC<CheckmarkCellProps> = ({
  day,
  onClick,
  userId,
  ...checkmarkProps
}) => {
  const me = useUser()
  const { hasPermissions } = usePermissions()
  const isMe = me?.id === userId
  const canCheck = (
    isMe && hasPermissions(PERMISSIONS.activities.approve.driveractivity, true)
  ) || (
    !isMe && hasPermissions(PERMISSIONS.activities.approve.othersdriveractivity, true)
  )
  const { handler: approveHandlers } = useActivityApprovedHandlers()
  const handleOnClick = !!onClick
    ? onClick
    : () => userId ? approveHandlers.day(userId, !checkmarkProps.checked, day) : null
  return (
    <Cell
      narrow
      centered
      onClick={event => {
        if(canCheck) {
          event.stopPropagation()
          handleOnClick(event)
        }
      }}
    >
      <Checkmark {...checkmarkProps} />
    </Cell>
  )
}
