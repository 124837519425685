import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useOnErrorAuto, useUser } from 'util/hooks'
import {
  ChecklistsType,
  ChecklistType,
  ChecklistEntries,
} from 'modules/checklists/types'
import {
  PatchChecklistItem,
  ChecklistQuery,
  ChecklistQueryVariables,
  BatchChangeChecklistItemsMutationVariables,
  BatchChangeChecklistItemsMutation,
} from 'modules/checklists/types.graphql'
import { transformChecklistQueryData } from 'modules/checklists/util'
import { Checklist } from 'modules/checklists/components/CheckList'

import { useQuery, useMutation } from '@apollo/client'
import { USER_CHECKLISTS } from 'modules/checklists/queries'
import { BATCH_CHANGE_CHECKLIST_ITEMS } from './mutations'
import { DetectBlur } from 'containers/util-containers'
import { useForm } from '@ur/react-hooks'

interface TodaysChecklistsProps {
  onEmptyCallback: (isEmpty: boolean) => void
}

export const TodaysChecklists: React.VFC<TodaysChecklistsProps> = ({
  onEmptyCallback,
}) => {
  const onErrorAuto = useOnErrorAuto()

  const [dirtyChecklistItems, setDirtyChecklistItems] = useState<
    PatchChecklistItem[]
  >([])

  const [patchMutation] = useMutation<
    BatchChangeChecklistItemsMutation,
    BatchChangeChecklistItemsMutationVariables
  >(BATCH_CHANGE_CHECKLIST_ITEMS, {
    refetchQueries: ['AllChecklists'],
    onCompleted() {
      setDirtyChecklistItems([])
    },
    onError: onErrorAuto(),
  })
  const user = useUser()

  const handleSubmit = useCallback(() => {
    if (dirtyChecklistItems.length > 0) {
      patchMutation({
        variables: {
          updatedChecklistItems: dirtyChecklistItems,
        },
      })
    }
  }, [dirtyChecklistItems, patchMutation])

  const { formValues, updateForm } = useForm<ChecklistsType>({
    values: {},
  })

  const { loading, data } = useQuery<ChecklistQuery, ChecklistQueryVariables>(
    USER_CHECKLISTS,
    {
      variables: {
        user: user.id,
      },
      onCompleted: data => {
        const queryData = transformChecklistQueryData(data)
        updateForm(queryData)
      },
    }
  )

  useEffect(() => {
    window.addEventListener('beforeunload', handleSubmit)
    return () => {
      window.removeEventListener('beforeunload', handleSubmit)
    }
  }, [handleSubmit])

  const showChecklists = useMemo(
    () => data?.allChecklists && data.allChecklists.edges.length > 0,
    [data]
  )

  useEffect(() => {
    if (!data) return
    if (!showChecklists) return onEmptyCallback(true)
    onEmptyCallback(false)
  }, [data, showChecklists, onEmptyCallback])

  function onChange(
    checklist: ChecklistType,
    checklistItem: PatchChecklistItem,
    newValue: ChecklistEntries
  ) {
    updateForm({
      [checklist.id]: {
        id: checklist.id,
        title: checklist.title,
        entries: newValue,
      },
    })
    if (
      dirtyChecklistItems.filter(dirtyItem => dirtyItem.id === checklistItem.id)
        .length === 0
    ) {
      setDirtyChecklistItems(dirtyChecklistItems.concat([checklistItem]))
    }
  }

  return (
    <DetectBlur
      disableTouchEnd
      active={dirtyChecklistItems.length > 0}
      onClickOutside={() => handleSubmit()}
    >
      {showChecklists &&
        !loading &&
        data &&
        Object.entries(formValues).map(([checklistId, checklist]) => (
          <Checklist
            key={checklistId}
            checklist={checklist}
            onChange={onChange}
          />
        ))}
    </DetectBlur>
  )
}
