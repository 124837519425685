import { SelectOption } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { endOfDay, endOfMonth, formatISO, startOfMonth } from 'date-fns'
import { ExportFormat, ExportType } from 'modules/exports'
import { endOfWeek, format } from 'util/date-fns'
import { ExportOption } from 'modules/exports'
import { useCompany, useConfirm, useUser } from 'util/hooks'
import {
  useActivityExportMutations,
  useActivityMutations,
} from './mutations.hooks'
import { ActivitiesForm, FetchDriverActivitiesForm, UserOrderBy } from './types'
import { isInvalidDate } from 'util/time'
import { notEmptyArrayValidation } from 'util/forms'

export function useActivityApprovedHandlers() {
  const confirm = useConfirm()
  const mutations = useActivityMutations()

  const translations = useTranslate({
    prompt: {
      title: 'activities.approve-title',
      day: ['activities.approve-day', { date: '' }],
      week: [
        'activities.approve-week',
        { week: '', dateStart: '', dateEnd: '' },
      ],
      month: [
        'activities.approve-month',
        { month: '', dateStart: '', dateEnd: '' },
      ],
    },
  })

  async function handleApproveDay(userId: string, approve: Boolean, datetimeStart: Date) {
    const { data } = await confirm(
      translations.prompt.day({ date: format(datetimeStart, 'dd.MM') }),
      translations.prompt.title
    )
    if (!data) return
    const datetimeEnd = endOfDay(datetimeStart)
    mutations.toggleApproved({
      variables: {
        user: userId,
        datetimeStart,
        datetimeEnd,
        approve,
      },
    })
  }

  async function handleApproveWeek(
    userId: string,
    approve: Boolean,
    datetimeStart: Date,
    datetimeEnd: Date
  ) {
    const { data } = await confirm(
      translations.prompt.week({
        week: format(datetimeStart, 'w'),
        dateStart: format(datetimeStart, 'dd.MM'),
        dateEnd: format(datetimeEnd, 'dd.MM'),
      }),
      translations.prompt.title
    )
    if (!data) return
    mutations.toggleApproved({
      variables: {
        user: userId,
        datetimeStart,
        datetimeEnd,
        approve,
      },
    })
  }
  async function handleApproveMonth(
    userId: string,
    approve: Boolean,
    datetimeStart: Date,
    datetimeEnd: Date
  ) {
    const { data } = await confirm(
      translations.prompt.month({
        month: format(endOfWeek(datetimeStart), 'MMMM'),
        dateStart: format(datetimeStart, 'dd.MM'),
        dateEnd: format(datetimeEnd, 'dd.MM'),
      }),
      translations.prompt.title
    )
    if (!data) return
    mutations.toggleApproved({
      variables: {
        datetimeStart,
        user: userId,
        datetimeEnd,
        approve,
      },
    })
  }

  return {
    handler: {
      day: handleApproveDay,
      week: handleApproveWeek,
      month: handleApproveMonth,
    },
  }
}

export function useActivityExportForm(onCompleted?: () => void) {
  const me = useUser()
  const company = useCompany()

  const { exportActivities, loading: mailLoading } =
    useActivityExportMutations(onCompleted)

  const translations = useTranslate({
    hours: 'common.hours',
    diet: 'common.diet',
    tours: 'common.tours',

    error: 'error.generic-server-error',
    mailSuccess: 'exports.success-email-process',
    selectUsersError: 'activities.errors.select-multiple-users',
  })

  const exportFormat: SelectOption<ExportType>[] = [
    {
      value: ExportType.ACTIVITIES,
      label: translations.hours,
    },
    {
      value: ExportType.DIET,
      label: translations.diet,
    },
    {
      value: ExportType.TOURS,
      label: translations.tours,
    },
  ]

  const {
    formValues: form,
    formErrors: errors,
    submitHandler: submit,
    updateForm,
  } = useForm<ActivitiesForm>({
    values: {
      month: new Date(),
      dateStart: new Date(),
      dateEnd: new Date(),
      exportType: ExportType.ACTIVITIES,
      exportOption: ExportOption.DOWNLOAD,
      exportFormat: ExportFormat.PDF,
      orderBy: UserOrderBy.FAMILY_NAME,
      useDateRange: false,
      weekly: false,
      user: null,
      users: [],
    },
    validators: {
      users: value =>
        notEmptyArrayValidation(value) ? translations.selectUsersError : null,
    },
  })

  function handler<K extends keyof ActivitiesForm>(key: K) {
    return (value: ActivitiesForm[K] | null) => {
      if (value === null) return
      updateForm({ [key]: value })
    }
  }

  async function handleExport(values: ActivitiesForm) {
    if (!values.user) return
    const date = form.useDateRange ? form.dateStart : startOfMonth(values.month)
    const [dateStart, dateEnd] = getDateInterval(date, form.dateEnd)
    exportActivities({
      variables: {
        exportType: values.exportType,
        exportFormat: values.exportFormat,
        userMail: me.id,
        company: company.id,
        dateStart: dateStart,
        dateEnd: dateEnd,
        user: values.user,
        exportOption: values.exportOption,
        weekly: values.weekly,
      },
    })
  }

  function getDateInterval(startDate: Date, endDate?: Date) {
    if (form.useDateRange) {
      const dateEnd = endDate || new Date()
      return [formatISO(startDate), formatISO(dateEnd)]
    }
    return [
      formatISO(startOfMonth(startDate)),
      formatISO(endOfMonth(startDate)),
    ]
  }

  return {
    form,
    errors,
    loading: mailLoading,
    mailLoading,
    exportFormat,

    handler,
    updateForm,
    submit,
    handleExport,
  }
}

export function useMassActivityExportForm(onCompleted?: () => void) {
  const me = useUser()
  const company = useCompany()

  const { exportActivitiesMass, loading: mailLoading } =
    useActivityExportMutations(onCompleted)

  const translations = useTranslate({
    hours: 'common.hours',
    diet: 'common.diet',
    tours: 'common.tours',
    summary: 'activities.work-summary',
    overtime: 'common.overtime',

    error: 'error.generic-server-error',
    mailSuccess: 'exports.success-email-process',

    selectUsersError: 'activities.errors.select-multiple-users',
    pauseExport: 'activities.pause-export',
  })

  const exportTypes: SelectOption<ExportType>[] = [
    {
      value: ExportType.MASS_ACTIVITIES,
      label: translations.hours,
    },
    {
      value: ExportType.MASS_DIET,
      label: translations.diet,
    },
    {
      value: ExportType.MASS_TOURS,
      label: translations.tours,
    },
    {
      value: ExportType.MASS_SUMMARY,
      label: translations.summary,
    },
    {
      value: ExportType.MASS_ACTIVITIES_OVERTIME,
      label: translations.overtime,
    },
    {
      value: ExportType.MASS_PAUSE,
      label: translations.pauseExport
    }
  ]

  const excelExportTypes = [ExportType.MASS_ACTIVITIES, ExportType.MASS_DIET]

  const {
    formValues: form,
    formErrors: errors,
    submitHandler: submit,
    updateForm,
  } = useForm<ActivitiesForm>({
    values: {
      month: new Date(),
      dateStart: new Date(),
      dateEnd: new Date(),
      exportType: ExportType.MASS_ACTIVITIES,
      exportOption: ExportOption.DOWNLOAD,
      exportFormat: ExportFormat.PDF,
      useDateRange: false,
      user: null,
      users: [],
      orderBy: UserOrderBy.FAMILY_NAME,
      includeAbsences: false,
      weekly: false,
    },
    validators: {
      users: value =>
        notEmptyArrayValidation(value) ? translations.selectUsersError : null,
    },
    config: {
      storage: {
        storeFormState: true,
        retrieveFormStateOnMount: true,
        storeFormStateName: 'Export-activites-mass-form',
        transformValues: {
          month: value =>
            typeof value === 'string'
              ? isInvalidDate(new Date(value), new Date())
              : undefined,
          exportType: value => value as ExportType,
          exportFormat: value => value as ExportFormat,
          exportOption: value => value as ExportOption,
          weekly: value => value as boolean,
        },
      },
    },
  })

  function handler<K extends keyof ActivitiesForm>(key: K) {
    return (value: ActivitiesForm[K] | null) => {
      if (value === null) return
      updateForm({ [key]: value })
    }
  }

  async function handleExport(values: ActivitiesForm) {
    const date = form.useDateRange ? form.dateStart : startOfMonth(values.month)
    const [dateStart, dateEnd] = getDateInterval(date, form.dateEnd)
    const orderBy = values.orderBy ? values.orderBy : 'last_name'
    const includeAbsences = values.includeAbsences ?? false
    exportActivitiesMass({
      variables: {
        exportType: values.exportType,
        exportFormat: values.exportFormat,
        user: me.id,
        users: form.users,
        company: company.id,
        dateStart: dateStart,
        dateEnd: dateEnd,
        orderBy: orderBy,
        includeAbsences: includeAbsences,
        exportOption: values.exportOption,
        weekly: values.weekly,
      },
    })
  }

  function getDateInterval(startDate: Date, endDate?: Date) {
    if (form.useDateRange) {
      const dateEnd = endDate || new Date()
      return [formatISO(startDate), formatISO(dateEnd)]
    }
    return [
      formatISO(startOfMonth(startDate)),
      formatISO(endOfMonth(startDate)),
    ]
  }

  return {
    form,
    errors,
    loading: mailLoading,
    exportTypes,
    excelExportTypes,
    handler,
    updateForm,
    submit,
    handleExport,
  }
}

export function useFetchDriverActivitiesForm() {
  const { fetch, loading } = useActivityMutations()

  const {
    formValues: form,
    submitHandler: submit,
    updateForm,
  } = useForm<FetchDriverActivitiesForm>({
    values: {
      month: new Date(),
    },
  })

  function handler<K extends keyof ActivitiesForm>(key: K) {
    return (value: ActivitiesForm[K] | null) => {
      if (value === null) return
      updateForm({ [key]: value })
    }
  }

  async function handleFetch(values: FetchDriverActivitiesForm) {
    const date = values.month
    const dateStart = startOfMonth(date)
    const dateEnd = endOfMonth(date)

    fetch({
      variables: {
        dateStart: dateStart,
        dateEnd: dateEnd,
      },
    })
  }

  return {
    loading,
    form,
    handler,
    submit,
    handleFetch,
  }
}
