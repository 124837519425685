import { FontAwesomeIcon } from '@ur/react-components/build/types/css'

export enum AxleWeightRestriction {
  SINGLE = 'SINGLE',
  TANDEM = 'TANDEM',
  TRIPLE = 'TRIPLE',
}

export enum RoutePlanTaskValue {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  NO_ROUTE = 'NO_ROUTE',
  STARTED = 'STARTED',
  PENDING = 'PENDING',
  UNDEFINED = 'UNDEFINED',
}

export enum RoutePlanInstructionActionValue {
  CONTINUE = 'continue',
  TURN = 'turn',
  ROUNDABOUT_EXIT = 'roundaboutExit',
  ARRIVE = 'arrive',
  ROUNDABOUT_PASS = 'roundaboutPass',
  RAMP = 'ramp',
  DEPART = 'depart',
}

export const RoutePlanInstructionIcon: { [key: string]: FontAwesomeIcon } = {
  continue: 'car',
  turnLeft: 'arrow-left',
  turnRight: 'arrow-right',
  roundaboutExit: 'car',
  roundaboutPass: 'car',
  ramp: 'car',
  arrive: 'flag',
  depart: 'car',
}

export enum RoutePlanInstructionDirectionValue {
  RIGHT = 'right',
  LEFT = 'left',
}

export const HERE_PLATFORM = {
  core: {
    scriptId: 'HERE-core',
    bundle: 'https://js.api.here.com/v3/3.1/mapsjs-core.js',
  },
  service: {
    scriptId: 'HERE-service',
    bundle: 'https://js.api.here.com/v3/3.1/mapsjs-service.js',
  },
  ui: {
    scriptId: 'HERE-UI',
    bundle: 'https://js.api.here.com/v3/3.1/mapsjs-ui.js',
  },
  mapEvents: {
    scriptId: 'HERE-map-events',
    bundle: 'https://js.api.here.com/v3/3.1/mapsjs-mapevents.js',
  },
}
