import { Icon, usePrompt } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { Button, Table } from 'components'
import { AllApiKeysQuery, ALL_API_KEYS, Integration } from 'modules/companies'
import {
  EditScaniaIntegrationModal,
  EditVolvoIntegrationModal,
  InfoBox,
  ScaniaIntegrationForm,
  Section,
  SectionHeader,
  SelectIntegrationType,
  SettingsWrapper,
  VolvoIntegrationForm,
} from 'modules/companies/CompanySettings/components'
import {
  FleetBoardApiKeyNode,
  IntegrationType,
  ManApiKeyNode,
  ScaniaApiKeyNode,
  VolvoApiKeyNode,
} from 'modules/companies/types.graphql'
import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { useConfirm, useOnErrorAuto } from 'util/hooks'
import { useIntegrationsMutations } from '../util/mutations.hooks'
import { EditFleetBoardIntegrationModal, FleetBoardIntegrationForm } from '../components/IntegrationsSettings/EditFleetBoardIntegrationModal'
import { EditManIntegrationModal, ManIntegrationForm } from '../components/IntegrationsSettings/EditManIntegrationModal'

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  h2 {
    margin-bottom: 0;
  }
`
const Create = styled.div`
  position: relative;
`

interface IntegrationSettingsProps {}

export const IntegrationsSettings: React.VFC<IntegrationSettingsProps> = () => {
  const translations = useTranslate({
    info: 'settings.info.integrations',
    integrations: 'settings.integrations',

    add: 'common.add',
    noKeys: 'settings.no-integration-keys',
    integration: 'settings.integration',
    active: 'common.active',

    volvoKey: ['settings.volvo-key-n', { n: 0 }],
    fleetBoardKey: ['settings.fleet-board-key-n', { n: 0 }],
    scaniaKey: ['settings.scania-key-n', { n: 0 }],
    manKey: ['settings.man-key-n', { n: 0 }],

    prompt: {
      deleteTitle: 'settings.prompts.integration-delete-title',
      deleteVolvo: ['settings.prompts.integration-volvo-delete', { n: 0 }],
      deleteFleetBoard: ['settings.prompts.integration-fleet-board-delete', { n: 0 }],
      deleteScania: ['settings.prompts.integration-scania-delete', { n: 0 }],
      deleteMan: ['settings.prompts.integration-man-delete', { n: 0 }],
    },
  })

  const confirm = useConfirm()
  const addPrompt = usePrompt()
  const onErrorAuto = useOnErrorAuto()
  const mutations = useIntegrationsMutations()

  const [selectIntegrationOpen, setSelectIntegrationOpen] = useState(false)

  const { data, loading: queryLoading } = useQuery<AllApiKeysQuery, never>(
    ALL_API_KEYS,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      onError: onErrorAuto(),
    }
  )

  const volvoKeys = data?.allVolvoApiKeys.edges.map(edge => edge.node) ?? []
  const scaniaKeys = data?.allScaniaApiKeys.edges.map(edge => edge.node) ?? []
  const fleetBoardKeys = data?.allFleetboardApiKeys.edges.map(edge => edge.node) ?? []
  const manApiKeys = data?.allManApiKeys.edges.map(edge => edge.node) ?? []

  function handleCreateIntegration(type: IntegrationType) {
    setSelectIntegrationOpen(false)

    if (type === Integration.VOLVO) {
      handleEditVolvo(null)
    } else if (type === Integration.SCANIA) {
      handleEditScania(null)
    } else if (type === Integration.FLEETBOARD) {
      handleEditFleetBoard(null)
    } else if (type === Integration.MAN) {
      handleEditMan(null)
    }
  }
  async function handleEditVolvo(
    integration: VolvoApiKeyNode | null,
    index = 0
  ) {
    const { data } = await addPrompt<VolvoIntegrationForm | null>(resolve => (
      <EditVolvoIntegrationModal
        integration={integration}
        index={index}
        onSubmit={resolve}
      />
    ))
    if (!data) return

    if (integration === null) {
      mutations.create.volvo({
        variables: {
          input: {
            ...data,
          },
        },
      })
    } else {
      mutations.patch.volvo({
        variables: {
          id: integration.id,
          input: data,
        },
      })
    }
  }
  async function handleEditScania(
    integration: ScaniaApiKeyNode | null,
    index = 0
  ) {
    const { data } = await addPrompt<ScaniaIntegrationForm | null>(resolve => (
      <EditScaniaIntegrationModal
        integration={integration}
        index={index}
        onSubmit={resolve}
      />
    ))
    if (!data) return

    if (integration === null) {
      mutations.create.scania({
        variables: {
          input: { ...data },
        },
      })
    } else {
      mutations.patch.scania({
        variables: {
          id: integration.id,
          input: data,
        },
      })
    }
  }
  async function handleEditFleetBoard(
    integration: FleetBoardApiKeyNode | null,
    index = 0
  ) {
    const { data } = await addPrompt<FleetBoardIntegrationForm | null>(resolve => (
      <EditFleetBoardIntegrationModal
        integration={integration}
        index={index}
        onSubmit={resolve}
      />
    ))
    if (!data) return

    if (integration === null) {
      mutations.create.fleetBoard({
        variables: {
          input: {
            ...data,
          },
        },
      })
    } else {
      mutations.patch.fleetBoard({
        variables: {
          id: integration.id,
          input: data,
        },
      })
    }
  }

  async function handleEditMan(integration: ManApiKeyNode | null, index = 0) {
    const { data } = await addPrompt<ManIntegrationForm | null>(resolve => (
      <EditManIntegrationModal
        integration={integration}
        index={index}
        onSubmit={resolve}
      />
    ))
    if (!data) return

    if (integration === null) {
      mutations.create.man({
        variables: {
          input: { ...data },
        },
      })
    } else
      mutations.patch.man({
        variables: {
          id: integration.id,
          input: data,
        },
      })
  }

  async function handleDeleteMan(id: string, index: number) {
    const { data } = await confirm(
      translations.prompt.deleteMan({ n: index + 1 }),
      translations.prompt.deleteTitle
    )
    if (!data) return

    mutations.delete.man({
      variables: { id },
    })
  }

  async function handleDeleteFleetBoard(id: string, index: number) {
    const { data: answer } = await confirm(
      translations.prompt.deleteFleetBoard({ n: index + 1 }),
      translations.prompt.deleteTitle
    )
    if (!answer) return

    mutations.delete.fleetBoard({
      variables: { id },
    })
  }

  async function handleDeleteVolvo(id: string, index: number) {
    const { data: answer } = await confirm(
      translations.prompt.deleteVolvo({ n: index + 1 }),
      translations.prompt.deleteTitle
    )
    if (!answer) return

    mutations.delete.volvo({
      variables: { id },
    })
  }
  async function handleDeleteScania(id: string, index: number) {
    const { data } = await confirm(
      translations.prompt.deleteScania({ n: index + 1 }),
      translations.prompt.deleteTitle
    )
    if (!data) return

    mutations.delete.scania({
      variables: { id },
    })
  }

  const isLoading = queryLoading || mutations.loading

  return (
    <SettingsWrapper grid={{ flow: 'row' }}>
      <InfoBox initCollapsed={isMobileOnly}>{translations.info}</InfoBox>

      <Section>
        <Header>
          <SectionHeader loading={isLoading}>
            {translations.integrations}
          </SectionHeader>

          <Create>
            {isMobileOnly ? (
              <Button
                height="32px"
                padding="0 1rem"
                onClick={() => setSelectIntegrationOpen(true)}
              >
                <Icon icon="plus" size="0.8rem" />
              </Button>
            ) : (
              <Button
                height="48px"
                iconLeftProps={{
                  icon: 'plus',
                }}
                onClick={() => setSelectIntegrationOpen(true)}
              >
                {translations.add}
              </Button>
            )}
            <SelectIntegrationType
              open={selectIntegrationOpen}
              onClose={() => setSelectIntegrationOpen(false)}
              onSubmit={handleCreateIntegration}
            />
          </Create>
        </Header>

        <Table
          noData={!isLoading && volvoKeys.length + scaniaKeys.length + fleetBoardKeys.length === 0}
          noDataText={translations.noKeys}
          noShadow
          noBorders
          noHeaderMargin
          noRowMargin
          rowHoverColor="secondary"
        >
          <thead>
            <tr>
              <th>{translations.integration}</th>
              {!isMobileOnly && (
                <th className="center">{translations.active}</th>
              )}
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {volvoKeys.map((key, idx) => (
              <tr key={key.id}>
                <td>{translations.volvoKey({ n: idx + 1 })}</td>

                {!isMobileOnly && (
                  <td width="1px" className="center">
                    <Icon
                      icon={key.active ? 'check' : 'times'}
                      color={key.active ? 'success' : 'error'}
                    />
                  </td>
                )}

                <td width="1px">
                  <Icon
                    icon="edit"
                    cursor="pointer"
                    color="gray6"
                    hoverColor="secondary"
                    onClick={() => handleEditVolvo(key, idx)}
                  />
                </td>

                <td width="1px">
                  <Icon
                    icon="times"
                    cursor="pointer"
                    color="gray6"
                    hoverColor="red"
                    onClick={() => handleDeleteVolvo(key.id, idx)}
                  />
                </td>
              </tr>
            ))}

            {scaniaKeys.map((key, idx) => (
              <tr key={key.id}>
                <td>{translations.scaniaKey({ n: idx + 1 })}</td>

                {!isMobileOnly && (
                  <td width="1px" className="center">
                    <Icon
                      icon={key.active ? 'check' : 'times'}
                      color={key.active ? 'success' : 'error'}
                    />
                  </td>
                )}

                <td width="1px">
                  <Icon
                    icon="edit"
                    cursor="pointer"
                    color="gray6"
                    hoverColor="secondary"
                    onClick={() => handleEditScania(key, idx)}
                  />
                </td>

                <td width="1px">
                  <Icon
                    icon="times"
                    cursor="pointer"
                    color="gray6"
                    hoverColor="red"
                    onClick={() => handleDeleteScania(key.id, idx)}
                  />
                </td>
              </tr>
            ))}
            {fleetBoardKeys.map((key, idx) => (
              <tr key={key.id}>
                <td>{translations.fleetBoardKey({ n: idx + 1 })}</td>

                {!isMobileOnly && (
                  <td width="1px" className="center">
                    <Icon
                      icon={key.active ? 'check' : 'times'}
                      color={key.active ? 'success' : 'error'}
                    />
                  </td>
                )}

                <td width="1px">
                  <Icon
                    icon="edit"
                    cursor="pointer"
                    color="gray6"
                    hoverColor="secondary"
                    onClick={() => handleEditFleetBoard(key, idx)}
                  />
                </td>

                <td width="1px">
                  <Icon
                    icon="times"
                    cursor="pointer"
                    color="gray6"
                    hoverColor="red"
                    onClick={() => handleDeleteFleetBoard(key.id, idx)}
                  />
                </td>
              </tr>
            ))}
            {manApiKeys.map((key, idx) => (
              <tr key={key.id}>
                <td>{translations.manKey({ n: idx + 1 })}</td>

                {!isMobileOnly && (
                  <td width="1px" className="center">
                    <Icon
                      icon={key.active ? 'check' : 'times'}
                      color={key.active ? 'success' : 'error'}
                    />
                  </td>
                )}

                <td width="1px">
                  <Icon
                    icon="edit"
                    cursor="pointer"
                    color="gray6"
                    hoverColor="secondary"
                    onClick={() => handleEditMan(key, idx)}
                  />
                </td>

                <td width="1px">
                  <Icon
                    icon="times"
                    cursor="pointer"
                    color="gray6"
                    hoverColor="red"
                    onClick={() => handleDeleteMan(key.id, idx)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Section>
    </SettingsWrapper>
  )
}
