import { useAdmin, usePermissions } from 'util/hooks'

interface PermissionsRequiredProps {
  permissions?: string | string[]
}

export const PermissionsRequired: React.FC<PermissionsRequiredProps> = ({
  children,
  permissions,
}) => {
  const admin = useAdmin()
  const { hasPermissions } = usePermissions()

  if (typeof permissions === 'undefined') return <>{children}</>
  if (typeof permissions === 'string') permissions = [permissions]

  if (!admin && !hasPermissions(permissions)) return null

  return <>{children}</>
}
