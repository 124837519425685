import { useQuery } from '@apollo/client'
import { PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { RegularModal, Select, TextArea, UserSelect } from 'components'
import { DateRangePicker } from 'components/DatePicker'
import { Form, FormField } from 'components/Form'
import { Label } from 'components/Label'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { notBlankValidation } from 'util/forms'
import { useUser } from 'util/hooks'
import { AllAbsenceTypesQuery } from '..'
import { ALL_ABSENCE_TYPES_QUERY } from '../queries'
import { CreateAbsenceForm } from '../types'

const GridForm = styled(Form)`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  ${props => props.theme.layout.mobile} {
    display: flex;
    flex-direction: column;
  }
  .submit {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  > div + div {
    margin-top: 1rem;
  }
`

export interface CreateAbsenceModalProps {
  onSubmit: PromptResolve<CreateAbsenceForm | null>
  admin?: boolean
}

export const CreateAbsenceModal: React.FC<CreateAbsenceModalProps> = ({
  onSubmit,
  admin,
}) => {
  const translations = useTranslate({
    selectUser: 'common.select-user',
    absence: 'common.absence',
    absenceType: 'timesheets.absence-type',
    fieldIsRequired: 'error.field-can-not-be-empty',
    selectAbsenceType: 'timesheets.select-absence-type',
    loading: 'common.loading',
    create: 'timesheets.send-request',
    note: 'timesheets.note',
    date: 'common.date',
    newAbsenceRequest: 'timesheets.new-absence-request',
  })
  const currentUser = useUser()

  const { data } = useQuery<AllAbsenceTypesQuery>(ALL_ABSENCE_TYPES_QUERY)

  const validateFieldNotEmpty = (errorMessage: string | null) => {
    return (value: string | null) => (!!value ? null : errorMessage)
  }

  const {
    formValues,
    formErrors,
    submitHandler,
    formChangeHandler: handler,
    updateForm,
  } = useForm<CreateAbsenceForm>({
    values: {
      absenceType: '',
      reason: '',
      dates: [new Date(), new Date()],
      user: currentUser.id,
    },
    validators: {
      absenceType: value => {
        if (value)
          return notBlankValidation(value) ? null : translations.fieldIsRequired
        return translations.fieldIsRequired
      },
      reason: validateFieldNotEmpty(translations.fieldIsRequired),
    },
  })

  const absenceTypes = useMemo(
    () => data?.allAbsenceTypes.edges.map(edge => edge.node) ?? [],
    [data]
  )

  const absenceTypeOptions = useMemo(() => {
    return absenceTypes.map(type => ({
      value: type.id,
      label: type.name,
    }))
  }, [absenceTypes])

  return (
    <RegularModal
      title={translations.newAbsenceRequest}
      width="500px"
      submitText={translations.create}
      cancelOnClickOutside={true}
      onCancel={() => onSubmit(null)}
      onSubmit={submitHandler(onSubmit)}
    >
      <GridForm preventDefault onSubmit={submitHandler(onSubmit)}>
        {admin && (
          <FormField>
            <Label>{translations.selectUser}</Label>
            <UserSelect
              value={formValues.user ?? null}
              background="quaternary"
              width="100%"
              height="3.875rem"
              onChange={(id, _) => updateForm({ user: id })}
            />
          </FormField>
        )}
        <FormField>
          <Label>{translations.date}</Label>
          <div>
            <DateRangePicker
              value={[formValues.dates[0], formValues.dates[1]]}
              onChange={handler('dates')}
            />
          </div>
        </FormField>

        <FormField>
          <Label>{translations.absenceType}</Label>
          <Select
            className="user-type-select"
            placeholder={translations.selectAbsenceType}
            value={formValues.absenceType}
            fullWidth
            minWidth="280px"
            grayed={true}
            onChange={handler('absenceType')}
            options={absenceTypeOptions}
            error={formErrors.absenceType}
          />
        </FormField>

        <FormField>
          <Label>{translations.note}</Label>
          <TextArea
            value={formValues.reason}
            onChange={handler('reason')}
            width="100%"
            error={formErrors.reason}
          />
        </FormField>
      </GridForm>
    </RegularModal>
  )
}
