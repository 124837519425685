import styled from 'styled-components'

import { Card } from './Card'

export const StyledCardFooter = styled(Card)`
  background-color: transparent;
  line-height: 30px;
  border-top: 1px solid #ebecec !important;
  font-size: 13px;
`
