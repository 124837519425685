import React from 'react'
import styled, { css } from 'styled-components'
import {
  DatePicker as BaseDatePicker,
  DatePickerProps as BaseDatePickerProps,
} from '@ur/react-components'
import { getLocale } from 'util/date-fns'
import { LocaleCode } from '@ur/react-components/build/types/common'
import { useTranslate } from '@ur/react-hooks'

const ErrorText = styled.div`
  position: absolute;
  top: -2px;
  right: 0;
  will-change: transform;
  color: ${props => props.theme.colors.red};
  font-size: 0.7rem;
  white-space: nowrap;
  transform: translateY(-100%);
  opacity: 1;
`

interface StyledDatePickerProps extends BaseDatePickerProps {
  grayed?: boolean
  disabled?: boolean
}
const StyledDatePicker = styled(BaseDatePicker)<StyledDatePickerProps>`
  .--date-picker-display {
    input {
      padding: 0 1rem;

      font-size: 1.2rem;
      border-top-left-radius: ${props => props.theme.sizes.defaultBorderRadius};
      border-bottom-left-radius: ${props =>
        props.theme.sizes.defaultBorderRadius};
    }
    .--input-icon-right {
      border-top-right-radius: ${props =>
        props.theme.sizes.defaultBorderRadius};
      border-bottom-right-radius: ${props =>
        props.theme.sizes.defaultBorderRadius};
    }

    ${props =>
      props.disabled &&
      css`
        input {
          color: ${props.theme.colors.gray7};
        }
        [class*='--input-icon'] i {
          color: ${props.theme.colors.gray7};
        }
      `};
  }
`

interface DatePickerProps extends StyledDatePickerProps {}

export const DatePicker: React.VFC<DatePickerProps> = ({
  grayed,
  background: backgroundProp,
  ...datePickerProps
}) => {
  const locale = getLocale().code as LocaleCode

  const translations = useTranslate({
    invalidDate: 'common.invalid-date',
  })

  const background = grayed ? 'quaternary' : backgroundProp ?? 'white'
  return (
    <span style={{position: 'relative'}}>
      <ErrorText>{datePickerProps.dateErrorText}</ErrorText>
      <StyledDatePicker
        locale={locale}
        dateErrorText={translations.invalidDate}
        background={background}
        focusBorderColor="secondary"
        height="3.875rem"
        noAnimation={true}
        noWeekNums={true}
        borderColor="gray9"
        displayDateFormat='dd.MM.yyyy'
        {...datePickerProps}
      />
    </span>
  )
}
