import { useForm, useTranslate } from '@ur/react-hooks'
import queryString from 'query-string'
import { addMonths, endOfMonth, setDate, startOfMonth, subDays } from 'date-fns'
import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useOnErrorAuto, usePermissions, useUser } from 'util/hooks'
import { TimesheetControls } from './components/TimesheetControls'
import { USER_ABSENCES_QUERY } from './queries'
import { AbsenceFilterForm } from './types'
import {
  AbsenceTypeNode,
  UserAbsencesQuery,
  UserAbsencesQueryVariables,
} from './types.graphql'
import { ModuleTitle, Toggle, ToggleIconProps } from 'components'
import { AbsenceView } from './consts'
import { isMobile } from 'react-device-detect'
import { AbsencesCalendar } from './components/AbsencesCalendar'
import { endOfWeek, startOfWeek } from 'util/date-fns'
import { isInvalidDate } from 'util/time'
import { AbsencesTable } from './components/AbsencesTable'
import { PERMISSIONS } from 'util/permissions'
import { Module } from 'modules/companies'

const Wrapper = styled.div`
  ${props => props.theme.layout.default};
`

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`

interface AbsencesProps {}

interface InitialFilterParams {
  userId: string
  date: Date
}

export const Absences: React.VFC<AbsencesProps> = () => {
  const translations = useTranslate({
    absences: 'common.absences',
  })
  const [selectedAbsenceType, setSelectedAbsenceType] =
    useState<Pick<AbsenceTypeNode, 'id' | 'name'> | null>(null)
  const me = useUser()
  const location = useLocation()
  const { hasPermissions } = usePermissions()

  const initialFilter: InitialFilterParams = useMemo(() => {
    const search = queryString.parse(location.search)
    const date = (search.date as string) || null
    const userId = (search.user as string) || null
    return {
      date: date ? new Date(date) : new Date(),
      userId: hasPermissions(PERMISSIONS.timesheets.change.absence) ? userId ?? me.id : me.id,
    }
  }, [hasPermissions, location.search, me.id])

  const [initialLoaded, setInitialLoaded] = useState(false)
  
  const { formValues: filter, updateForm } = useForm<AbsenceFilterForm>({
    values: {
      users: [],
      user: undefined,
      month: initialFilter.date,
      orderBy: 'start',
      allUsers: false,
      requestStatus: undefined,
      view: AbsenceView.table,
      salaryMode: false,
    },
    config: {
      storage: {
        storeFormState: true,
        storeFormStateName: 'absence-controls',
        retrieveFormStateOnMount: true,
        transformValues: {
          month: val =>
            typeof val === 'string'
              ? isInvalidDate(new Date(val), new Date())
              : undefined,
          salaryMode: value => (typeof value === 'boolean') ? value : false,
        },
      },
    },
  })

  const user = useUser(filter.user, Module.TIMESHEETS)

  const { datetimeStartLt, datetimeEndGte } = useMemo(() => {
    if (filter.salaryMode && user.salaryWorkTime && filter.view === AbsenceView.table) {
      const salaryStart = user.salaryWorkTime?.salaryDateStart ?? 1
      const salaryEnd = user.salaryWorkTime?.salaryDateEnd ?? 1
      
      const forward = (31 - salaryStart) > 15

      // What salary period is connected to which month is based on the salary start date.
      if (forward) {
        const datetimeStartLt = setDate(filter.month, salaryStart)
        const datetimeEndGte = (salaryStart === 1) ? endOfMonth(filter.month) : setDate(addMonths(filter.month, 1), salaryEnd)

        return {datetimeStartLt, datetimeEndGte}
      }

      const datetimeStartLt = setDate(addMonths(filter.month, -1), salaryStart)
      const datetimeEndGte = setDate(filter.month, salaryEnd)

      return {datetimeStartLt, datetimeEndGte}
    } else {
      const startOfMonthDate = startOfMonth(filter.month)
      const endOfMonthDate = subDays(addMonths(startOfMonthDate, 1), 1)

      const datetimeStartLt =
        filter.view === AbsenceView.table
          ? startOfMonthDate
          : startOfWeek(startOfMonthDate)
      const datetimeEndGte =
        filter.view === AbsenceView.table
          ? endOfMonthDate
          : endOfWeek(endOfMonthDate)

      return {
        datetimeStartLt,
        datetimeEndGte,
      }
    }
  }, [filter.month, filter.salaryMode, filter.view, user.salaryWorkTime])

  const onErrorAuto = useOnErrorAuto()

  const { data, loading } = useQuery<
    UserAbsencesQuery,
    UserAbsencesQueryVariables
  >(USER_ABSENCES_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      user:
        !filter.allUsers && filter.view === AbsenceView.table
          ? filter.user
          : undefined,
      users:
        !filter.allUsers && filter.view === AbsenceView.calendar
          ? filter.users
          : undefined,
      absenceStartDate: datetimeStartLt,
      absenceEndDate: datetimeEndGte,
      absenceType: selectedAbsenceType?.id,
      requestStatus: filter.requestStatus,
    },
    onError: onErrorAuto(),
  })

  const absences = data?.allAbsences.edges.map(edge => edge.node) ?? []

  const iconProps: Omit<ToggleIconProps, 'icon'> = {
    size: '1.6rem',
  }

  useEffect(() => {
    if (initialFilter.date !== filter.month && !initialLoaded) {
      updateForm({ month: initialFilter.date })
      setInitialLoaded(true)
    }
  }, [filter.month, initialFilter.date, initialLoaded, updateForm])

  return (
    <Wrapper>
      <TitleWrapper>
        <ModuleTitle>{translations.absences}</ModuleTitle>
        {!isMobile && (
          <div>
            <Toggle
              value={filter.view === AbsenceView.table}
              offIconProps={{
                icon: 'calendar-alt',
                ...iconProps,
                color: !(filter.view === AbsenceView.table)
                  ? 'white'
                  : undefined,
              }}
              onIconProps={{
                icon: 'list',
                ...iconProps,
                color: filter.view === AbsenceView.table ? 'white' : undefined,
              }}
              thumbBackground="secondary"
              background="white"
              height="3.875rem"
              onChange={value =>
                updateForm({
                  view: value ? AbsenceView.table : AbsenceView.calendar,
                })
              }
            />
          </div>
        )}
      </TitleWrapper>
      <TimesheetControls
        filter={filter}
        type="absences"
        onUpdate={updateForm}
      />

      {filter.view === AbsenceView.table ? (
        <AbsencesTable
          absences={absences}
          loading={loading}
          showEmployee={filter.user !== me.id}
          selectedAbsenceType={selectedAbsenceType}
          onSelectAbsenceType={(absenceType) => {
            setSelectedAbsenceType(absenceType)
          }}
        />
      ) : (
        <AbsencesCalendar
          startDate={datetimeStartLt}
          endDate={datetimeEndGte}
          absences={absences}
        />
      )}
    </Wrapper>
  )
}
