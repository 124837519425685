import styled from 'styled-components'

interface AddAttachmentProps {
  height?: string
}

export const AddAttachment = styled.div.attrs<AddAttachmentProps>({
  role: 'button',
})<AddAttachmentProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => props.height};
  width: ${props => props.height};
  border: 2px dashed ${props => props.theme.colors.gray7};
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    border-color: ${props => props.theme.colors.secondary};

    i {
      color: ${props => props.theme.colors.secondary};
    }
  }

  ${props => props.theme.media.mobile} {
    width: calc(${props => props.height} * 4 / 3);
    height: ${props => props.height};
  }
`
