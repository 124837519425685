import { useForm, useTranslate } from '@ur/react-hooks'
import client from 'apollo'
import firmadokLoginImage from 'assets/images/firmadok_login_image.jpg'
import firmadokLogo from 'assets/images/firmadok_logo.png'
import { Button, Input } from 'components'
import { CenteredErrorMessage } from 'components/CenteredErrorMessage'
import { Form } from 'components/Form'
import { FormField } from 'components/Form'
import { Label } from 'components/Label'
import { isBefore, isValid } from 'date-fns'
import {
  CreatePassworsByTokenMutation,
  CREATE_PASSWORD_BY_TOKEN_MUTATION,
  useJwtTokenFromUrlId,
} from 'modules/authentication'
import { setLoginToken } from 'modules/login/util'
import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useOnErrorAuto } from 'util/hooks'
import { usePasswordValidation } from 'util/hooks/usePasswordValidation'

const Layout = styled.div`
  display: grid;
  grid-template-columns: 500px 1fr;
  height: 100%;
  min-height: 600px;

  ${props => props.theme.layout.mobile} {
    display: block;
  }
`
const CreatePasswordSection = styled.div`
  padding: 4rem 3rem;
  background-color: #f7f8fc;
  position: relative;

  height: 100%;
  box-sizing: border-box;

  ${props => props.theme.layout.mobile} {
    padding: 48px 24px;
  }

  form div + div {
    margin-top: 1rem;
  }
`

const ImageSection = styled.div`
  background: url(${firmadokLoginImage});
  background-size: cover;
  background-position: 50% 50%;

  ${props => props.theme.layout.mobile} {
    display: none;
  }
`

const TitleImage = styled.img`
  height: 50px;
`

const Subtitle = styled.h4`
  font-size: 24px;
  font-weight: normal;
  color: ${props => props.theme.colors.gray3};

  margin-top: 2rem;
  margin-bottom: 0rem;

  b {
    color: ${props => props.theme.colors.black};
    font-weight: 600;
  }

  ${props => props.theme.layout.mobile} {
    margin-bottom: 1.5rem;
  }
`

const Message = styled.p`
  margin-top: 1.5rem;
  margin-bottom: 2rem;

  max-width: 100%;
  color: ${props => props.theme.colors.gray4};

  ${props => props.theme.layout.mobile} {
    font-size: 12px;
    margin-bottom: 1.5rem;
  }
`

const LoginLink = styled(Link)`
  color: ${props => props.theme.colors.gray4};
  font-size: 12px;
  text-decoration: underline;
  padding-top: 2rem;
`

const Copyright = styled.div`
  position: absolute;
  bottom: 32px;
  left: 48px;
  font-size: 12px;
  color: ${props => props.theme.colors.gray6};
  ${props => props.theme.layout.mobile} {
    left: 24px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 1.5rem;
`

interface CreatePasswordProps {}

interface CreatePasswordForm {
  newPassword: string
  newPasswordAgain: string
}

export const CreatePassword: React.VFC<CreatePasswordProps> = () => {
  const translations = useTranslate({
    form: {
      title: 'users.create-password.title',
      description: 'users.create-password.description',
      labels: {
        newPassword: 'common.new-password',
        newPasswordAgain: 'common.new-password-again',
      },
      submit: 'common.submit',
    },
    goBack: 'reset-password.go-back',
    errors: {
      badToken: 'reset-password.errors.bad-token',
      expiredToken: 'reset-password.errors.expired-token',
      generic: 'error.generic-server-error',
    },
    validation: {
      weakPassword: 'users.too-weak-password',
      passwordUnequal: 'errors.passwords-not-equal',
    },
  })
  const onErrorAuto = useOnErrorAuto()
  const history = useHistory()
  const [remoteError, setRemoteError] = useState<string | null>(null)
  const { token, expirationDate, tokenIsValid } = useJwtTokenFromUrlId()

  const { validatePassword } = usePasswordValidation()

  const validatePasswordAgain = (value: string, other: string) =>
    value === other ? null : translations.validation.passwordUnequal

  const {
    formValues: form,
    formErrors: errors,
    formChangeHandler: handler,
    submitHandler,
  } = useForm({
    values: {
      newPassword: '',
      newPasswordAgain: '',
    },
    validators: {
      newPassword: validatePassword,
      newPasswordAgain: (value, { newPassword }) =>
        validatePasswordAgain(value, newPassword),
    },
  })

  const [resetPasswordByToken] = useMutation<CreatePassworsByTokenMutation>(
    CREATE_PASSWORD_BY_TOKEN_MUTATION,
    {
      onCompleted: ({ createPasswordByToken: { loginToken } }) => {
        setLoginToken(loginToken)
        client.resetStore()

        history.push('/')
      },
      onError: onErrorAuto(
        {},
        {
          showToast: false,
          callback({ message }: { message: string }) {
            if (
              message.includes('bad action') ||
              message.includes('Invalid token')
            ) {
              setRemoteError(translations.errors.badToken)
            } else if (message.includes('expired')) {
              setRemoteError(translations.errors.expiredToken)
            } else {
              setRemoteError(translations.errors.generic)
            }
          },
        }
      ),
    }
  )

  async function submit(values: CreatePasswordForm) {
    await resetPasswordByToken({
      variables: {
        newPassword: values.newPassword,
        token: token,
      },
    })
  }

  if (remoteError) {
    return (
      <Layout>
        <CreatePasswordSection>
          <CenteredErrorMessage message={remoteError} />
          <LoginLink to="/login">{translations.goBack}</LoginLink>
        </CreatePasswordSection>
        <ImageSection />
      </Layout>
    )
  }

  // Perform a very shallow token validation check. It must be non-empty, and contain
  // exactly two dots.
  if (tokenIsValid) {
    return (
      <Layout>
        <CreatePasswordSection>
          <CenteredErrorMessage message={translations.errors.badToken} />
          <LoginLink to="/login">{translations.goBack}</LoginLink>
        </CreatePasswordSection>
        <ImageSection />
      </Layout>
    )
  }

  // We can't do a full token verification here, as the token is issued
  // on the backend with a secret key. We can, however, ensure the token has
  // not expired, and contains the correct action
  if (!isValid(expirationDate) || isBefore(expirationDate, new Date())) {
    return (
      <Layout>
        <CreatePasswordSection>
          <CenteredErrorMessage message={translations.errors.expiredToken} />
          <LoginLink to="/login">{translations.goBack}</LoginLink>
        </CreatePasswordSection>
        <ImageSection />
      </Layout>
    )
  }

  return (
    <Layout>
      <CreatePasswordSection>
        <TitleImage src={firmadokLogo} />
        <Subtitle>{translations.form.title}</Subtitle>
        <Message>{translations.form.description}</Message>
        <Form preventDefault onSubmit={submitHandler(submit)}>
          <FormField>
            <Label htmlFor="newPassword">
              {translations.form.labels.newPassword}
            </Label>
            <Input
              name="newPassword"
              type="password"
              value={form.newPassword}
              error={errors.newPassword}
              fullWidth
              background="white"
              onChange={handler('newPassword')}
            />
          </FormField>
          <FormField>
            <Label htmlFor="newPasswordAgain">
              {translations.form.labels.newPasswordAgain}
            </Label>
            <Input
              name="newPasswordAgain"
              type="password"
              value={form.newPasswordAgain}
              fullWidth
              background="white"
              onChange={handler('newPasswordAgain')}
            />
          </FormField>
          <ButtonWrapper>
            <Button type="submit">{translations.form.submit}</Button>
          </ButtonWrapper>
        </Form>
        <Copyright>&copy; Firmadok AS, {new Date().getFullYear()}</Copyright>
      </CreatePasswordSection>
      <ImageSection />
    </Layout>
  )
}
