import React from 'react'

export const Truck: React.VFC<React.SVGAttributes<SVGSVGElement>> = props => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 164.785 164.785"
    enableBackground="new 0 0 164.785 164.785"
    xmlSpace="preserve"
    fill="#003057"
    {...props}
  >
    <g>
      <g>
        <path
          className="st0"
          d="M143.322,84.257h-13.914V64.96c0-18.909-15.383-34.292-34.291-34.292h-8.794c-4.265,0-7.735,3.47-7.735,7.735
			v19.383H65.49V42.682c0-0.746-0.297-1.462-0.824-1.989l-9.012-9.011c-1.099-1.099-2.88-1.099-3.978,0
			c-1.099,1.099-1.099,2.879,0,3.978l8.187,8.187v33.377c0,1.554,1.26,2.813,2.813,2.813c1.554,0,2.813-1.26,2.813-2.813V63.412
			h13.097v56.404H65.49v-16.928c0-1.554-1.26-2.813-2.813-2.813c-1.554,0-2.813,1.26-2.813,2.813v9.971
			c-1.751-1.081-3.805-1.716-6.009-1.716c-3.463,0-6.566,1.547-8.674,3.979c-2.108-2.432-5.211-3.979-8.674-3.979
			c-5.362,0-9.864,3.698-11.124,8.673H9.782c-1.554,0-2.813,1.26-2.813,2.813c0,1.554,1.26,2.813,2.813,2.813h15.6
			c1.26,4.976,5.762,8.674,11.124,8.674c3.463,0,6.566-1.547,8.674-3.979c2.108,2.432,5.211,3.979,8.674,3.979
			c5.362,0,9.865-3.698,11.124-8.674h41.962c1.26,4.976,5.762,8.674,11.124,8.674s9.865-3.698,11.124-8.674h14.133
			c7.992,0,14.494-6.503,14.494-14.494V98.751C157.816,90.759,151.314,84.257,143.322,84.257z M103.099,84.257V63.412h20.605
			c0.028,0.516,0.078,1.026,0.078,1.548v19.296H103.099z M84.214,38.403c0-1.162,0.946-2.108,2.108-2.108h8.794
			c13.325,0,24.522,9.152,27.72,21.491H84.214V38.403z M36.507,128.491c-3.237,0-5.861-2.624-5.861-5.861
			c0-3.237,2.624-5.861,5.861-5.861c3.237,0,5.861,2.624,5.861,5.861C42.368,125.867,39.744,128.491,36.507,128.491z
			 M53.855,128.491c-3.237,0-5.861-2.624-5.861-5.861c0-3.237,2.624-5.861,5.861-5.861c3.237,0,5.861,2.624,5.861,5.861
			C59.715,125.867,57.091,128.491,53.855,128.491z M118.065,128.491c-3.237,0-5.861-2.624-5.861-5.861
			c0-3.237,2.624-5.861,5.861-5.861c3.237,0,5.861,2.624,5.861,5.861C123.925,125.867,121.302,128.491,118.065,128.491z
			 M143.322,119.816h-14.133c-1.26-4.976-5.762-8.673-11.124-8.673c-5.362,0-9.864,3.698-11.124,8.673H84.214V63.412h13.259V87.07
			c0,1.554,1.26,2.813,2.813,2.813h43.036c4.41,0,8.068,3.239,8.745,7.461h-4.574c-1.554,0-2.813,1.26-2.813,2.813
			c0,1.554,1.26,2.813,2.813,2.813h4.696v7.978C152.19,115.839,148.212,119.816,143.322,119.816z"
        />
        <path
          className="st0"
          d="M62.678,90.049c0.745,0,1.448-0.295,1.983-0.83c0.141-0.126,0.253-0.282,0.352-0.422
			c0.113-0.155,0.197-0.324,0.268-0.493c0.07-0.169,0.112-0.338,0.155-0.521c0.042-0.183,0.056-0.365,0.056-0.548
			c0-0.183-0.014-0.365-0.056-0.548c-0.043-0.184-0.084-0.367-0.155-0.536c-0.071-0.168-0.169-0.337-0.268-0.478
			c-0.098-0.154-0.211-0.309-0.352-0.435c-0.126-0.126-0.267-0.239-0.422-0.352c-0.155-0.099-0.309-0.183-0.492-0.254
			c-0.168-0.07-0.337-0.126-0.52-0.154c-0.929-0.198-1.885,0.113-2.532,0.76c-0.141,0.126-0.253,0.282-0.352,0.435
			c-0.113,0.141-0.197,0.31-0.267,0.478c-0.07,0.169-0.126,0.352-0.155,0.536c-0.043,0.183-0.056,0.365-0.056,0.548
			c0,0.746,0.295,1.449,0.83,1.983C61.215,89.754,61.932,90.049,62.678,90.049z"
        />
      </g>
    </g>
  </svg>
)
