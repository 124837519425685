import { CurrentCompanyNotificationSettingsQuery } from 'modules/companies/types.graphql'

export type CompanyWithNotificationSettings =
  CurrentCompanyNotificationSettingsQuery['currentCompany']

export const NotificationSettings = {
  USER_REGISTRATION: 'userRegistration',
  RESET_PASSWORD: 'resetPassword',
  USER_DOCUMENT: 'userDocument',
  ISSUE_CREATED: 'issueCreated',
  ISSUE_CLOSED: 'issueClosed',
  ABSENCE_CREATED: 'absenceCreated',
  FORUM_THREAD_CREATED: 'forumThreadCreated',
  FORUM_POST_CREATED: 'forumPostCreated',
  DRIVER_CARD_WILL_EXPIRE: 'driverCardWillExpire',
  USER_EXPORT_READY: 'userExportReady',
  VEHICLE_EXPIRATION: 'vehicleExpiration',
  VEHICLE_DOCUMENT: 'vehicleDocument',
  NEW_INFRINGEMENT: 'notifyDriverOnInfringement',
}

export type NotificationSettingsNames = keyof typeof NotificationSettings
