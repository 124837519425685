import React from 'react'
import styled from 'styled-components'
import {
  Checkbox as BaseCheckbox,
  CheckboxProps as BaseCheckboxProps,
} from '@ur/react-components'

const StyledCheckbox = styled(BaseCheckbox)`
  .--checkbox-box {
    border-color: ${props => props.theme.colors.gray9};
  }
  &:focus {
    outline: none;

    .--checkbox-box {
      border-color: ${props => props.theme.colors.secondary};
    }
  }
`

interface WrapperProps {
  height: string
  padding: string
}
const Wrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  height: ${props => props.height};
  padding: ${props => props.padding};

  background: ${props => props.theme.colors.quaternary};
  border: 1px solid ${props => props.theme.colors.gray9};
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
`

interface CheckboxProps extends BaseCheckboxProps {
  /** Whether to wrap this checkbox and label in a container */
  wrapped?: boolean
  /** Only applies if wrapped is true */
  height?: string
  /** Only applies if wrapped is true */
  padding?: string

  /** Whether to use gray styling for the checkbox */
  grayed?: boolean
}

export const Checkbox: React.VFC<CheckboxProps> = ({
  wrapped = false,
  height = '3.875rem',
  padding = '12px 12px 12px 22px',
  grayed = false,
  ...props
}) => {
  const wrap = (cb: JSX.Element) =>
    !wrapped ? (
      cb
    ) : (
      <Wrapper height={height} padding={padding}>
        {cb}
      </Wrapper>
    )

  const background = grayed ? 'quaternary' : props.boxColor ?? 'white'

  return wrap(
    <StyledCheckbox
      tabIndex={0}
      boxSize="38px"
      boxBorderRadius="6px"
      boxColor="secondary"
      boxBackground={background}
      labelMargin="12px"
      {...props}
    />
  )
}
