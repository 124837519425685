import {
  ChecklistNode,
  ChecklistQuery,
  ChecklistsType,
  CrontabSettings,
} from 'modules/checklists'

export const transformChecklistQueryData = (data: ChecklistQuery) => {
  const checklistData: ChecklistNode[] = data.allChecklists.edges.map(
    edge => edge.node
  )

  return Object.assign(
    {},
    ...checklistData.map(checklist => {
      return {
        [checklist.id]: {
          id: checklist.id,
          title: checklist.title,
          entries: Object.assign(
            {},
            ...checklist.items.map(item => {
              return { [item.id]: item }
            })
          ),
        },
      }
    })
  ) as ChecklistsType
}

export const getCrontabSettingsFromString = (s: string) => {
  const dayOfWeek = s === 'daily' ? 'mon-fri' : s === 'weekly' ? 'mon' : '*'
  const dayOfMonth = s === 'monthly' ? '0' : '*'
  return {
    minute: '0',
    hour: '3',
    dayOfWeek,
    dayOfMonth,
    monthOfYear: '*',
  } as CrontabSettings
}

export const getCrontabStringFromSettings = (crontab: string) => {
  return crontab.split(' ')[2] === 'mon-fri'
    ? 'daily'
    : crontab.split(' ')[2] === 'mon'
    ? 'weekly'
    : 'monthly'
}
