import { Icon, useToast } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { Button, CheckedTableValue, UnsavedChanges } from 'components'
import groupBy from 'lodash/groupBy'
import { UpdatedUserTypesInput } from 'modules/companies/types.graphql'
import { UserTypesWithPermissionsQuery } from 'modules/users'
import { USER_TYPES_WITH_PERMISSIONS_QUERY } from 'modules/users/queries'
import React, { useCallback, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import {
  useAdmin,
  useConfirm,
  useNavigateConfirm,
  useOnErrorAuto,
  usePromptString,
} from 'util/hooks'
import { safeParseJson } from 'util/parsing'
import { PermissionSettingsTable } from '../components'
import {
  InfoBox,
  Section,
  SectionHeader,
  SettingsWrapper as BaseSettingsWrapper,
} from '../components/common'
import { UserTypeWithPermissions } from '../util'
import { usePermissionMutations } from '../util/mutations.hooks'

const SettingsWrapper = styled(BaseSettingsWrapper)`
  margin-bottom: 96px !important;
`
const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  h2 {
    margin-bottom: 0;
  }
`

interface PermissionSettingsProps {}

export const PermissionSettings: React.VFC<PermissionSettingsProps> = () => {
  const translations = useTranslate({
    info1: 'settings.info.user-types-1',
    info2: 'settings.info.user-types-2',

    userTypesAndPermissions: 'settings.user-types-and-permissions',
    createUserType: 'settings.create-user-type',
    createUserTypePrompt: 'settings.prompts.create-user-type',

    validation: {
      nameExists: 'settings.validation.user-type-name-exists-short',
      lastAdminToggle: 'settings.validation.last-admin-user-type-toggle',
      lastAdminDelete: 'settings.validation.last-admin-user-type-delete',
    },
    prompt: {
      delete: 'settings.prompts.user-type-delete',
      deleteTitle: 'settings.prompts.user-type-delete-title',

      unsavedChanges: 'common.you-have-unsaved-changes',
      unsavedChangesNavigate: 'common.you-have-unsaved-changes-navigate',
    },
  })

  const onErrorAuto = useOnErrorAuto()
  const promptString = usePromptString()
  const confirm = useConfirm()
  const addToast = useToast()
  const admin = useAdmin()

  const [permissionDiff, setPermissionDiff] = useState<CheckedTableValue[]>([])

  const { data: queryData, loading: queryLoading } = useQuery<
    UserTypesWithPermissionsQuery,
    never
  >(USER_TYPES_WITH_PERMISSIONS_QUERY, {
    onError: onErrorAuto(),
  })

  const userTypes = useMemo(
    () =>
      queryData?.allUserTypes.edges
        .map(edge => edge.node)
        .sort((a, b) => a.name.localeCompare(b.name)) ?? [],
    [queryData]
  )

  const mutations = usePermissionMutations()

  const checkLastAdminType = useCallback(
    (userType: UserTypeWithPermissions) => {
      const count = userTypes.reduce(
        (acc, type) => (type.isAdministrator ? acc + 1 : acc),
        0
      )
      return userType.isAdministrator && count === 1
    },
    [userTypes]
  )

  const handleToggleAdministrator = useCallback(
    async (userType: UserTypeWithPermissions) => {
      // Don't need to show a toast here since this functionality
      // isn't available for non-admin users anyway
      if (!admin) return

      if (checkLastAdminType(userType)) {
        addToast('warning', translations.validation.lastAdminToggle)
        return
      }

      await mutations.patch({
        variables: {
          id: userType.id,
          input: {
            isAdministrator: !userType.isAdministrator,
          },
        },
      })
    },
    [
      addToast,
      admin,
      checkLastAdminType,
      mutations,
      translations.validation.lastAdminToggle,
    ]
  )

  const handleSave = useCallback(async () => {
    const grouped = groupBy(permissionDiff, 'column')
    const [updatedUserTypes, updatedAdminAccess] = Object.entries(
      grouped
    ).reduce<[UpdatedUserTypesInput[], UserTypeWithPermissions[]]>(
      (acc, [id, permissions]) => {
        for (const permission of permissions) {
          if (permission.row === 'admin') {
            const type = userTypes.find(type => type.id === permission.column)
            if (!!type) acc[1].push(type)
          } else {
            let found = acc[0].find(input => input.id === id)
            if (!found) {
              found = {
                id,
                permissions: [],
              }
              acc[0].push(found)
            }

            found.permissions = [
              ...found.permissions,
              ...safeParseJson<string[]>(permission.row, []).map(perm => ({
                permission: perm,
                value: permission.checked,
              })),
            ]
          }
        }

        return acc
      },
      [[], []]
    )

    if (updatedUserTypes.length > 0)
      await mutations.update({
        ...(() =>
          updatedAdminAccess.length > 0 ? { refetchQueries: [] } : {})(),
        variables: {
          updatedUserTypes,
        },
      })

    if (updatedAdminAccess.length > 0)
      await Promise.all(updatedAdminAccess.map(handleToggleAdministrator))
  }, [handleToggleAdministrator, mutations, permissionDiff, userTypes])

  async function handleSubmitName(id: string, name: string) {
    await mutations.patch({
      variables: {
        id,
        input: {
          name,
        },
      },
    })
  }

  async function handleCreateUserType() {
    const name = await promptString(
      translations.createUserTypePrompt,
      translations.createUserType,
      {
        validate: value =>
          !!userTypes.find(type => type.name === value)
            ? translations.validation.nameExists
            : null,
      }
    )
    if (name === null) return

    mutations.create({
      variables: {
        input: {
          name,
        },
      },
    })
  }

  async function handleDelete(userType: UserTypeWithPermissions) {
    // Don't need to show a toast here since this functionality
    // isn't available for non-editabled user types anyway
    if (!userType.editable) return

    if (checkLastAdminType(userType)) {
      addToast('warning', translations.validation.lastAdminDelete)
      return
    }

    const { data: answer } = await confirm(
      translations.prompt.delete,
      translations.prompt.deleteTitle
    )
    if (!answer) return

    mutations.delete({
      variables: { id: userType.id },
    })
  }

  useNavigateConfirm(
    permissionDiff.length > 0,
    translations.prompt.unsavedChangesNavigate
  )

  const isLoading = queryLoading || mutations.loading

  return (
    <SettingsWrapper grid={{ flow: 'row' }}>
      <InfoBox initCollapsed={isMobileOnly}>
        <p>{translations.info1}</p>
        <p>{translations.info2}</p>
      </InfoBox>

      <Section>
        <Header>
          <SectionHeader loading={isLoading}>
            {translations.userTypesAndPermissions}
          </SectionHeader>

          {isMobileOnly ? (
            <Button
              height="32px"
              padding="0 1rem"
              onClick={handleCreateUserType}
            >
              <Icon icon="plus" size="0.8rem" />
            </Button>
          ) : (
            <Button
              height="48px"
              iconLeftProps={{
                icon: 'plus',
              }}
              onClick={handleCreateUserType}
            >
              {translations.createUserType}
            </Button>
          )}
        </Header>

        <PermissionSettingsTable
          userTypes={userTypes}
          onChange={setPermissionDiff}
          onSubmitName={handleSubmitName}
          onDelete={handleDelete}
        />
      </Section>

      <UnsavedChanges
        show={permissionDiff.length > 0}
        message={translations.prompt.unsavedChanges}
        onSave={handleSave}
      />
    </SettingsWrapper>
  )
}
