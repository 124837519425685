import { Icon, usePrompt } from '@ur/react-components'
import omit from 'lodash/omit'
import {
  EditVehicleDetailsModalResolve,
  VehicleDetailInput,
  VehicleDetailNode,
} from 'modules/vehicles'
import { useMemo } from 'react'
import styled from 'styled-components'
import { useVehicleDetailFormatter } from '../hooks'
import { useVehicleMutations } from '../mutations.hooks'
import { EditVehicleDetailsModal } from './EditVehicleDetailsModal'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadow.default};
  border-radius: 5px;
`
const DetailCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.gray8};
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  h4 {
    margin: 0;
  }
`

export const DetailCardRow = styled.div`
  display: flex;
  margin: 0.3rem 0;
  div {
    flex: 1;
  }
  div:last-child {
    text-align: right;
  }
  font-weight: 500;
  .unit {
    margin-left: 0.2rem;
    color: ${props => props.theme.colors.gray4};
    font-weight: 400;
  }
`

interface DetailCardProps {
  title: string
  fields: VehicleDetailInput[]
  vehicle: VehicleDetailNode
}

export const DetailCard: React.VFC<DetailCardProps> = ({
  title,
  fields,
  vehicle,
}) => {
  const addPrompt = usePrompt()
  const {
    mutations: { patch: patchVehicle },
  } = useVehicleMutations()
  const { formatValue } = useVehicleDetailFormatter(vehicle)

  const rows = useMemo(() => {
    return (
      fields
        ?.filter(
          field => vehicle[field.key] !== null && vehicle[field.key] !== ''
        )
        .map(field => {
          const value = formatValue(field)
          const { unit, label } = field
          return (
            <DetailCardRow key={field.key}>
              <div>{label}</div>
              <div>
                {value}
                {unit && <span className="unit">{unit}</span>}
              </div>
            </DetailCardRow>
          )
        }) || []
    )
  }, [fields, vehicle, formatValue])

  async function handleEdit() {
    const { data } = await addPrompt<EditVehicleDetailsModalResolve | null>(
      resolve => (
        <EditVehicleDetailsModal
          title={title}
          vehicle={vehicle}
          fields={fields}
          onSubmit={resolve}
        />
      )
    )

    if (!data) return
    await patchVehicle({
      variables: {
        id: vehicle.id,
        input: omit(data.form, ['vin', ...fields.filter(field => field.readOnly === true).map(field => field.key)]),
      },
    })
  }

  return (
    <Wrapper>
      <DetailCardHeader>
        <h4>{title}</h4>
        <Icon
          icon="edit"
          cursor="pointer"
          color="gray6"
          hoverColor="secondary"
          onClick={() => handleEdit()}
        />
      </DetailCardHeader>
      {rows}
    </Wrapper>
  )
}
