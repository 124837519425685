import { useMutation } from '@apollo/client'
import { useForm, useTranslate } from '@ur/react-hooks'
import firmadokLoginImage from 'assets/images/firmadok_login_image.jpg'
import firmadokLogo from 'assets/images/firmadok_logo.png'
import { Button, Input } from 'components'
import { Form, FormField } from 'components/Form'
import { Label } from 'components/Label'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { notEmptyValidation } from 'util/forms'
import { useOnErrorAuto } from 'util/hooks'
import { RESET_PASSWORD_MUTATION } from './mutations'
import {
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
} from './types.graphql'

const Layout = styled.div`
  display: grid;
  grid-template-columns: 500px 1fr;
  height: 100%;
  min-height: 600px;

  ${props => props.theme.layout.mobile} {
    display: block;
  }
`

const ForgottenPasswordSection = styled.div`
  padding: 4rem 3rem;
  background-color: #f7f8fc;
  position: relative;

  height: 100%;
  box-sizing: border-box;

  ${props => props.theme.layout.mobile} {
    padding: 48px 24px;
  }
`

const ImageSection = styled.div`
  background: url(${firmadokLoginImage});
  background-size: cover;
  background-position: 50% 50%;

  ${props => props.theme.layout.mobile} {
    display: none;
  }
`

const TitleImage = styled.img`
  height: 50px;
`

const Subtitle = styled.h4`
  font-size: 24px;
  font-weight: normal;
  color: ${props => props.theme.colors.gray3};

  margin-top: 2rem;
  margin-bottom: 0rem;

  b {
    color: ${props => props.theme.colors.black};
    font-weight: 600;
  }

  ${props => props.theme.layout.mobile} {
    margin-bottom: 1.5rem;
  }
`

const Message = styled.p`
  margin-top: 1.5rem;
  margin-bottom: 2rem;

  max-width: 100%;
  color: ${props => props.theme.colors.gray4};

  ${props => props.theme.layout.mobile} {
    font-size: 12px;
    margin-bottom: 1.5rem;
  }
`

const LoginLink = styled(Link)`
  color: ${props => props.theme.colors.gray4};
  font-size: 12px;
  text-decoration: underline;
`

const Copyright = styled.div`
  position: absolute;
  bottom: 32px;
  left: 48px;
  font-size: 12px;
  color: ${props => props.theme.colors.gray6};
  ${props => props.theme.layout.mobile} {
    left: 24px;
  }
`

const Success = styled.p`
  color: ${props => props.theme.colors.gray4};
`

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  input {
    background-color: white;
  }

  button {
    margin-top: 1rem;
    align-self: flex-end;
  }
`

export function ForgottenPassword() {
  const translations = useTranslate({
    title: 'forgotten-password.title',
    message: 'forgotten-password.message',
    messageSuccess: 'forgotten-password.success',
    submit: 'common.submit',
    username: 'login.email-or-phone-number',
    goBack: 'forgotten-password.go-back',

    required: 'common.required',
  })

  const onErrorAuto = useOnErrorAuto()

  const [resetPassword] = useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(RESET_PASSWORD_MUTATION, {
    onCompleted: ({ resetMyPassword: { ok } }) => {
      if (ok) setPasswordSent(true)
    },
    onError: onErrorAuto(),
  })
  const [passwordSent, setPasswordSent] = useState(false)

  const {
    formValues: form,
    formChangeHandler: handler,
    formErrors: errors,
    submitHandler,
  } = useForm({
    values: {
      username: '',
    },
    validators: {
      username: value =>
        notEmptyValidation(value) ? null : translations.required,
    },
  })

  function onSubmit() {
    resetPassword({ variables: { ...form } })
  }

  return (
    <Layout>
      <ForgottenPasswordSection>
        <TitleImage src={firmadokLogo} />
        <Subtitle>{translations.title}</Subtitle>
        {passwordSent ? (
          <Success>{translations.messageSuccess}</Success>
        ) : (
          <>
            <Message>{translations.message}</Message>
            <StyledForm onSubmit={submitHandler(onSubmit)} preventDefault>
              <FormField>
                <Label htmlFor="username">{translations.username}</Label>
                <Input
                  id="username"
                  name="username"
                  error={errors.username}
                  autoComplete="username"
                  autoFocus
                  fullWidth
                  value={form.username}
                  onChange={handler('username')}
                />
              </FormField>
              <Button type="submit">{translations.submit}</Button>
            </StyledForm>
          </>
        )}
        <LoginLink to="/login">{translations.goBack}</LoginLink>
        <Copyright>&copy; Firmadok AS, {new Date().getFullYear()}</Copyright>
      </ForgottenPasswordSection>
      <ImageSection />
    </Layout>
  )
}
