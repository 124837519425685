import { PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { RegularModal } from 'components'
import { SalaryCodeSelect } from 'components/Select/SalaryCodeSelect'
import { DetectBlur } from 'containers/util-containers'
import { Field } from 'modules/companies'

export interface DriverDietForm {
  userId: string
  salaryCodeId: string | null
}

interface DriverDietPromptProps {
  initialData: DriverDietForm
  onSubmit: PromptResolve<DriverDietForm | null>
}

export const DriverDietPrompt: React.VFC<DriverDietPromptProps> = ({
  initialData,
  onSubmit,
}) => {
  const translations = useTranslate({
    selectSalaryCode: 'common.select-salary-code',
    salaryCode: 'settings.salary-code',

    update: 'common.update',
  })

  const {
    formValues: form,
    formValid,
    formChangeHandler: handler,
    submitHandler: submit,
  } = useForm<DriverDietForm>({
    values: initialData ?? {
      userId: '',
      salaryCodeId: '',
    },
  })

  return (
    <DetectBlur active onClickOutside={() => onSubmit(null)}>
      <RegularModal
        title={translations.selectSalaryCode}
        submitText={translations.update}
        submitDisabled={!formValid}
        width="400px"
        onCancel={() => onSubmit(null)}
        onSubmit={submit(onSubmit)}
      >
        <form>
          <Field>
          <label>{translations.salaryCode}</label>
            <SalaryCodeSelect
              fullWidth
              nullable
              value={form.salaryCodeId}
              isDiet={true}
              onChange={handler('salaryCodeId')}
            />
          </Field>
        </form>
      </RegularModal>
    </DetectBlur>
  )
}
