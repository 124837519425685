import React from 'react'
import { isSafari } from 'react-device-detect'
import styled, { css } from 'styled-components'
import { AbsenceTypesListProps } from './types'
import { useTranslate } from '@ur/react-hooks'

// TODO change this shit as soon as Apple get's their ass off the ground
interface WrapperProps {
  isSafari: boolean
}
const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  ${props =>
    props.isSafari &&
    css`
      gap: 0;
      margin-left: -0.5rem;
      margin-bottom: -0.5rem;

      & > * {
        margin-bottom: 0.5rem;
        margin-left: 0.5rem;
      }
    `};
`

const AbsenceType = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  min-width: 220px;
  height: 3.875rem;

  background: ${props => props.theme.colors.secondary};
  border-radius: 31px 6px;
  font-weight: 600;
  overflow: hidden;

  span {
    justify-self: center;
    align-self: center;
    padding: 4px 1rem 0;
  }
  div.buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;

    width: 100%;
    padding: 6px 0 4px;

    background-color: rgba(255, 255, 255, 0.5);
    font-size: 0.8rem;
    text-transform: uppercase;

    div[role='button'] {
      cursor: pointer;

      i {
        font-size: 1.2rem;
      }

      &.edit {
        border-top-right-radius: 6px;

        &:hover {
          color: ${props => props.theme.colors.quaternary};
        }
      }
      &.delete {
        border-bottom-right-radius: 6px;

        &:hover {
          color: ${props => props.theme.colors.red};
        }
      }
    }
  }

  div.cancel {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 2rem;
    height: 36px;
    margin: 0 1.5rem 0 0;

    border-top-right-radius: ${props => props.theme.sizes.defaultBorderRadius};
    border-bottom-right-radius: ${props =>
      props.theme.sizes.defaultBorderRadius};
    background-color: rgba(255, 255, 255, 0.6);
    cursor: pointer;

    &:hover {
      i {
        color: ${props => props.theme.colors.red};
      }
    }
  }

  ${props => props.theme.media.mobile} {
    width: 100%;
  }
`

const AddAbsenceType = styled(AbsenceType).attrs({
  editing: false,
})`
  display: flex;
  justify-content: center;
  align-items: center;

  user-select: none;
  cursor: pointer;
`

interface AbsenceSettingsCardsProps extends AbsenceTypesListProps {}

export const AbsenceSettingsCards: React.VFC<AbsenceSettingsCardsProps> = ({
  absenceTypes,

  onEditSubmit,
  onAddSubmit,
  onDelete,
}) => {
  const translations = useTranslate({
    add: 'common.add',
    edit: 'common.edit',
    delete: 'common.delete-alt',
  })

  return (
    <Wrapper isSafari={isSafari}>
      {absenceTypes.map(type => (
        <AbsenceType key={type.id}>
          <>
            <span>{type.name}</span>

            <div className="buttons">
              <div
                role="button"
                className="edit"
                onClick={() => onEditSubmit(type)}
              >
                {translations.edit}
              </div>
              <div
                role="button"
                className="delete"
                onClick={() => onDelete(type)}
              >
                {translations.delete}
              </div>
            </div>
          </>
        </AbsenceType>
      ))}

      <AddAbsenceType onClick={() => onAddSubmit()}>
        {translations.add}
      </AddAbsenceType>
    </Wrapper>
  )
}
