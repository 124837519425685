import { addDays } from 'date-fns'
import { useState } from 'react'
import styled from 'styled-components'
import { format, startOfWeek } from 'util/date-fns'

const SelectWeekDayWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: ${props => props.theme.colors.gray11};
  border-radius: 1rem;
  max-width: 500px;
  ${props => props.theme.media.mobile} {
    width: 100%;
    max-width: 100%;
    margin-right: 1rem;
  }
`

const WeekDay = styled.div<{ selected: boolean; disabled: boolean }>`
  background-color: ${props =>
    props.selected
      ? props.disabled
        ? props.theme.colors.gray9
        : props.theme.colors.primary
      : props.theme.colors.gray11};
  color: ${props =>
    props.selected && !props.disabled
      ? props.theme.colors.gray11
      : props.theme.colors.primary};
  :hover {
    background-color: ${props =>
      !props.selected
        ? props.disabled
          ? props.theme.colors.gray11
          : props.theme.colors.lightPrimary
        : props.disabled
        ? props.theme.colors.gray9
        : props.theme.colors.primary};

    color: ${props =>
      props.disabled
        ? props.theme.colors.primary
        : props.selected
        ? props.theme.colors.white
        : props.theme.colors.gray1};
  }
  border-radius: 8px;
  font-weight: 600;
  width: 48px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  padding: 8px;
  text-align: center;
  ${props =>
    !props.disabled &&
    `
    cursor: pointer;
  `}
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  ${props => props.theme.media.mobile} {
    width: 34px;
    height: 30px;
    font-size: 12px;
    padding: 4px;
  }
`

const Error = styled.div`
  position: absolute;
  color: ${props => props.theme.colors.red};
  font-size: 0.7rem;
  top: -14px;
  right: 0;
`

interface SelectWeekDayProps {
  initialValues?: boolean[]
  onSelectDay?: (dayIndex: number, selected: boolean) => void
  disabled?: boolean
  error?: string | null
}

export const SelectWeekDays: React.UFC<SelectWeekDayProps> = ({
  initialValues,
  disabled = false,
  onSelectDay,
  error,
}) => {
  const monday = startOfWeek(new Date())
  const shortWeekDaysArray = Array.from(Array(7)).map((e, i) =>
    format(addDays(monday, i), 'eee').capitalizeFirst()
  )
  const [weekDayStates, setWeekDayStates] = useState<boolean[]>(
    initialValues ?? [false, false, false, false, false, false, false]
  )

  const handleClickWeekDay = (index: number) => {
    if (disabled) return
    let newWeekDayStates = [...weekDayStates]
    newWeekDayStates[index] = !newWeekDayStates[index]
    setWeekDayStates(newWeekDayStates)
    onSelectDay && onSelectDay(index, newWeekDayStates[index])
  }
  return (
    <SelectWeekDayWrapper>
      {shortWeekDaysArray.map((day, index) => (
        <WeekDay
          key={index}
          selected={weekDayStates[index]}
          onClick={() => handleClickWeekDay(index)}
          disabled={disabled}
        >
          {day}
        </WeekDay>
      ))}
      <Error>{error}</Error>
    </SelectWeekDayWrapper>
  )
}
