import { Icon, usePrompt } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { Button, ExternalLink, Table as BaseTable } from 'components'
import { subWeeks } from 'date-fns'
import assign from 'lodash/assign'
import {
  VehicleDocumentsQuery,
  VehicleDocumentsQueryVariables,
  VehicleDocumentNode,
  VEHICLE_DOCUMENTS_QUERY,
} from 'modules/vehicles'
import { EditVehicleDocumentModal } from 'modules/vehicles/fleet'
import {
  EditVehicleDocumentForm,
  EditVehicleDocumentPromptResolve,
} from 'modules/vehicles/types'
import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import { format, formatDistanceStrict } from 'util/date-fns'
import { getFileIconAndColor } from 'util/file'
import { useConfirm, useOnErrorAuto, usePermissions } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { useVehicleDocumentMutations } from '../mutations.hooks'

const Wrapper = styled.div`
  margin-top: 1rem;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
`

const Table = styled(props => <BaseTable {...props} />)`
  position: relative;

  thead {
    text-align: left;
  }

  tbody tr {
    :hover {
      background: ${props => props.theme.colors.gray10};
    }
  }
`

interface VehicleDocumentsProps {
  vehicleId: string
}

export const VehicleDocuments: React.VFC<VehicleDocumentsProps> = ({
  vehicleId,
}) => {
  const translations = useTranslate({
    documents: 'common.documents',
    name: 'common.name',
    add: 'documents.add-document',
    description: 'common.description',
    expirationDate: 'common.expires-at',
    notificationTime: 'common.notification',
    noFiles: 'documents.no-files',
    prompt: {
      delete: 'documents.prompts.delete-document',
      deleteConfirmation: [
        'documents.prompts.delete-document-confirmation',
        { name: '' },
      ],
    },
  })

  const addPrompt = usePrompt()
  const confirm = useConfirm()
  const onErrorAuto = useOnErrorAuto()

  const { hasPermissions } = usePermissions()
  const canEdit = hasPermissions(
    PERMISSIONS.vehicles.change.vehicledocument,
    true
  )
  const canDelete = hasPermissions(
    PERMISSIONS.vehicles.delete.vehicledocument,
    true
  )

  const { data, loading } = useQuery<
    VehicleDocumentsQuery,
    VehicleDocumentsQueryVariables
  >(VEHICLE_DOCUMENTS_QUERY, {
    variables: {
      vehicle: vehicleId,
      orderBy: 'expirationDate',
    },
    onError: onErrorAuto(),
  })

  const documents = useMemo(() => {
    return data?.allVehicleDocuments?.edges?.map(edge => edge?.node) || []
  }, [data])

  const { createDocument, patchDocument, deleteDocument } =
    useVehicleDocumentMutations()

  function getExpirationAndNotificationTime(
    form: EditVehicleDocumentForm,
    hasNotification: boolean
  ) {
    const expirationDate = !hasNotification ? null : form.expirationDate
    const notificationTime = !hasNotification
      ? null
      : !expirationDate || !form.notificationTime
      ? null
      : subWeeks(expirationDate, form.notificationTime)
    return { expirationDate, notificationTime }
  }

  async function handleCreate() {
    const { data } = await addPrompt<EditVehicleDocumentPromptResolve | null>(
      resolve => <EditVehicleDocumentModal onSubmit={resolve} />
    )
    if (!data) return
    const { form, hasNotification } = data

    const { expirationDate, notificationTime } =
      getExpirationAndNotificationTime(form, hasNotification)

    const input = assign(form, {
      vehicle: vehicleId,
      expirationDate,
      notificationTime,
    })
    if (!input.file) return

    await createDocument({
      variables: {
        input: input,
      },
    })
  }

  async function handleEdit(document: VehicleDocumentNode) {
    const { data } = await addPrompt<EditVehicleDocumentPromptResolve | null>(
      resolve => (
        <EditVehicleDocumentModal
          vehicleDocument={document}
          onSubmit={resolve}
        />
      )
    )
    if (!data) return
    const { form, hasNotification } = data
    const { expirationDate, notificationTime } =
      getExpirationAndNotificationTime(form, hasNotification)
    await patchDocument({
      variables: {
        id: document.id,
        input: {
          name: form.name,
          description: form.description,
          expirationDate,
          notificationTime,
          responsibleUser: form.responsibleUser,
        },
      },
    })
  }

  async function handleDelete(document: VehicleDocumentNode) {
    const { data: answer } = await confirm(
      translations.prompt.deleteConfirmation({ name: document.name }),
      translations.prompt.delete
    )
    if (!answer) return

    deleteDocument({
      variables: {
        id: document.id,
      },
    })
  }

  return (
    <Wrapper>
      <Header>
        <h2>{translations.documents}</h2>
        <Button
          iconLeftProps={{ icon: 'plus', size: '1rem', type: 'solid' }}
          height="3rem"
          onClick={handleCreate}
        >
          {translations.add}
        </Button>
      </Header>
      <Table
        loading={loading}
        noShadow
        noData={!documents.length}
        noDataText={translations.noFiles}
      >
        <thead>
          <tr>
            <th>{translations.name}</th>
            <th>{translations.description}</th>
            <th>{translations.expirationDate}</th>
            <th>{translations.notificationTime}</th>
            {canEdit && <th></th>}
            {canDelete && <th></th>}
          </tr>
        </thead>
        <tbody>
          {documents.map(document => {
            const expirationDate = !!document.expirationDate
              ? format(new Date(document.expirationDate), 'PP')
              : '-'
            const notificationTime =
              !!document.notificationTime && !!document.expirationDate
                ? formatDistanceStrict(
                    new Date(document.expirationDate),
                    new Date(document.notificationTime)
                  )
                : '-'
            const [icon, color] = getFileIconAndColor(document.originalName)
            return (
              <tr key={document.id}>
                <td>
                  <ExternalLink href={document.file}>
                    <Icon
                      icon={icon}
                      color={color}
                      fixedWidth
                      margin="0 4px 0 0"
                    />
                    {document.name}
                  </ExternalLink>
                </td>
                <td className="wrap">{document.description}</td>
                <td>{expirationDate}</td>
                <td>{notificationTime}</td>
                {canEdit && (
                  <td width="1px" className="center">
                    <Icon
                      icon="edit"
                      cursor="pointer"
                      color="gray6"
                      hoverColor="secondary"
                      onClick={() => handleEdit(document)}
                    />
                  </td>
                )}
                {canDelete && (
                  <td>
                    <Icon
                      icon="times"
                      cursor="pointer"
                      color="gray6"
                      hoverColor="red"
                      onClick={() => handleDelete(document)}
                    />
                  </td>
                )}
              </tr>
            )
          })}
        </tbody>
      </Table>
    </Wrapper>
  )
}
