import { Icon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { Input } from 'components'
import React from 'react'
import { isSafari } from 'react-device-detect'
import styled, { css } from 'styled-components'
import { SimpleColorPicker } from 'components/ColorPicker'
import { IssueCategoriesSettingsViewProps } from './types'

// TODO change this shit as soon as Apple get's their ass off the ground
interface WrapperProps {
  isSafari: boolean
}
const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  ${props =>
    props.isSafari &&
    css`
      gap: 0;
      margin-left: -0.5rem;
      margin-bottom: -0.5rem;

      & > * {
        margin-bottom: 0.5rem;
        margin-left: 0.5rem;
      }
    `};

  ${props => props.theme.media.mobile} {
    flex-direction: column;
  }
`

interface IssueCategoryProps {
  color: string
}
const IssueCategory = styled.div<IssueCategoryProps>`
  position: relative;
  display: flex;
  flex-direction: column;

  min-width: 260px;
  padding: 1rem;

  background-color: ${props => props.color};
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};

  i.delete {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  h3 {
    display: flex;
    align-items: center;
    margin: 0 1rem 0 0;

    font-size: 1rem;
  }
  div.responsible {
    margin: 0.2rem 0 0.5rem;
    font-size: 0.8rem;

    label {
      font-weight: 600;
      color: ${props => props.theme.colors.gray3};
    }
    span[role='button'] {
      cursor: pointer;

      &:hover {
        color: ${props => props.theme.colors.secondary};
      }
    }
  }
`
const EditName = styled.div`
  display: flex;

  div[role='button'] {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 2rem;
    height: 28px;
    margin: -7px 0 0 0;

    background-color: rgba(255, 255, 255, 0.6);
    cursor: pointer;

    &:hover {
      i {
        color: ${props => props.theme.colors.red};
      }
    }

    &.submit {
      border-top-right-radius: ${props =>
        props.theme.sizes.defaultBorderRadius};
      border-bottom-right-radius: ${props =>
        props.theme.sizes.defaultBorderRadius};
    }
  }
`
const AddName = styled(EditName)`
  input {
    width: 100% !important;
  }
`
const NameInput = styled(Input).attrs({
  width: 'calc(260px - 5rem)',
  height: '28px',
  noBorders: true,
})`
  margin: -7px 0 0 -4px;

  input {
    font-size: 1rem;
    font-weight: 500;
    background-color: rgba(255, 255, 255, 0.4);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  ${props => props.theme.media.mobile} {
    width: calc(100vw - 12rem);

    input {
      width: calc(100vw - 12rem);
    }
  }
`
interface NewIssueCategoryProps {
  adding: boolean
}
const NewIssueCategory = styled(IssueCategory)<NewIssueCategoryProps>`
  ${props =>
    !props.adding
      ? css`
          justify-content: center;
          align-items: center;

          font-weight: 600;
          user-select: none;
          cursor: pointer;
          border: 1px dashed ${props => props.theme.colors.secondary};

          &:hover {
            color: white;
            background-color: ${props => props.theme.colors.secondary};
            border: 0;
          }
        `
      : css`
          ${NameInput} {
            width: calc(100% - 1.8rem) !important;
          }
        `};
`

interface IssueCategorySettingsCardProps
  extends IssueCategoriesSettingsViewProps {}

export const IssueCategorySettingsCard: React.VFC<IssueCategorySettingsCardProps> =
  ({
    editableCategories,
    uneditableCategories,

    form,
    adding,
    editing,

    temporaryEditColor,

    onFormChange,
    onUpdateForm,
    onSelectResponsibleUser,
    onUpdateColor,
    onAdd,
    onAddSubmit,
    onEdit,
    onEditSubmit,
    onDelete,
  }) => {
    const translations = useTranslate({
      responsible: 'common.responsible',
      changeResponsibleUser: 'settings.change-responsible-user',

      add: 'common.add',
      choose: 'common.choose-ellipsis',
      createNameFirst: 'settings.create-name-first',
    })

    function handleEditKeyDown(evt: React.KeyboardEvent<HTMLInputElement>) {
      if (evt.key === 'Enter') onEditSubmit()
      else if (evt.key === 'Escape') onEdit(null)
    }
    function handleAddKeyDown(evt: React.KeyboardEvent<HTMLInputElement>) {
      if (evt.key === 'Enter') onAddSubmit()
      else if (evt.key === 'Escape') onAdd(false)
    }

    return (
      <Wrapper isSafari={isSafari}>
        {editableCategories.map(category => (
          <IssueCategory key={category.id} color={category.color ?? 'white'}>
            <Icon
              className="delete"
              icon="times"
              color="gray3"
              hoverColor="red"
              cursor="pointer"
              onClick={() => onDelete(category)}
            />

            {editing !== category.id ? (
              <h3>
                {category.name}

                <Icon
                  icon="pen"
                  type="solid"
                  color="gray3"
                  size="0.8rem"
                  hoverColor="secondary"
                  cursor="pointer"
                  margin="0 0 0 6px"
                  translateY="-1px"
                  onClick={() => {
                    onUpdateForm({ editName: category.name })
                    onEdit(category.id)
                  }}
                />
              </h3>
            ) : (
              <EditName>
                <NameInput
                  value={form.editName}
                  autoFocus
                  onFocus={evt => evt.target.select()}
                  onKeyDown={handleEditKeyDown}
                  onChange={onFormChange('editName')}
                />

                <div
                  className="cancel"
                  role="button"
                  onClick={evt => {
                    evt.stopPropagation()
                    onEdit(null)
                  }}
                >
                  <Icon icon="times" type="light" size="1rem" color="gray2" />
                </div>

                <div
                  className="submit"
                  role="button"
                  onClick={evt => {
                    evt.stopPropagation()
                    onEditSubmit()
                  }}
                >
                  <Icon icon="check" type="light" size="1rem" color="gray2" />
                </div>
              </EditName>
            )}

            <div className="responsible">
              <label>{translations.responsible}</label>:&nbsp;
              <span
                role="button"
                title={translations.changeResponsibleUser}
                onClick={() =>
                  onSelectResponsibleUser(category.id, category.responsibleUser)
                }
              >
                {category.responsibleUser?.fullName ?? translations.choose}
              </span>
            </div>

            <SimpleColorPicker
              value={temporaryEditColor[category.id] ?? category.color}
              onChange={onUpdateColor(category.id)}
            />
          </IssueCategory>
        ))}

        <NewIssueCategory
          color={adding ? form.newColor : 'white'}
          adding={adding}
          onClick={() => onAdd(true)}
        >
          {adding ? (
            <>
              <AddName>
                <NameInput
                  value={form.newName}
                  autoFocus
                  onKeyDown={handleAddKeyDown}
                  onChange={onFormChange('newName')}
                />

                <div
                  className="cancel"
                  role="button"
                  onClick={evt => {
                    evt.stopPropagation()
                    onAdd(false)
                  }}
                >
                  <Icon icon="times" type="light" size="1rem" color="gray2" />
                </div>

                <div
                  className="submit"
                  role="button"
                  onClick={evt => {
                    evt.stopPropagation()
                    onAddSubmit()
                  }}
                >
                  <Icon icon="check" type="light" size="1rem" color="gray2" />
                </div>
              </AddName>

              <div className="responsible">
                <label>{translations.responsible}</label>:&nbsp;
                <span title={translations.changeResponsibleUser}>
                  {translations.createNameFirst}
                </span>
              </div>

              <SimpleColorPicker
                value={form.newColor}
                onChange={onFormChange('newColor')}
              />
            </>
          ) : (
            translations.add
          )}
        </NewIssueCategory>
      </Wrapper>
    )
  }
