import React from 'react'

export const UserX: React.VFC<React.SVGAttributes<SVGSVGElement>> = props => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 164.785 164.785"
    enableBackground="new 0 0 164.785 164.785"
    xmlSpace="preserve"
    fill="#003057"
    {...props}
  >
    <g>
      <g>
        <g>
          <g>
            <g>
              <g>
                <path
                  className="st0"
                  d="M81.065,85.262c0.126,0,0.251,0,0.402,0c0.05,0,0.101,0,0.151,0c0.075,0,0.176,0,0.251,0
							c7.362-0.126,13.317-2.714,17.714-7.664c9.674-10.905,8.066-29.599,7.89-31.383c-0.628-13.392-6.96-19.8-12.186-22.79
							c-3.895-2.236-8.442-3.442-13.518-3.543h-0.176c-0.025,0-0.075,0-0.101,0h-0.151c-2.789,0-8.267,0.452-13.518,3.442
							c-5.277,2.99-11.709,9.397-12.337,22.89c-0.176,1.784-1.784,20.478,7.89,31.383C67.748,82.548,73.703,85.136,81.065,85.262z
							 M62.195,46.843c0-0.075,0.025-0.151,0.025-0.201c0.829-18.016,13.619-19.95,19.096-19.95h0.101c0.05,0,0.126,0,0.201,0
							c6.784,0.151,18.317,2.915,19.096,19.95c0,0.075,0,0.151,0.025,0.201c0.025,0.176,1.784,17.262-6.206,26.257
							c-3.166,3.568-7.387,5.327-12.94,5.377c-0.05,0-0.075,0-0.126,0l0,0c-0.05,0-0.075,0-0.126,0
							c-5.528-0.05-9.774-1.809-12.915-5.377C60.461,64.155,62.169,46.994,62.195,46.843z"
                />
              </g>
            </g>
            <g>
              <path
                className="st0"
                d="M100.507,136.725c0-2.064-1.819-3.637-3.861-3.343c-4.59,0.662-9.639,1.077-15.128,1.077
						c-24.172,0-40.227-8.015-44.323-10.327c-0.402-2.01-0.578-5.503-0.528-7.764c0-0.201,0.025-0.402,0.025-0.628
						c0.176-5.729,0.829-11.986,6.684-14.071c12.513-3.216,22.513-10.201,22.94-10.478c1.533-1.081,1.91-3.191,0.829-4.724
						c-1.081-1.533-3.191-1.91-4.724-0.829c-0.1,0.075-9.473,6.608-20.855,9.498c-0.1,0.025-0.176,0.05-0.251,0.075
						c-10.905,3.744-11.232,15.377-11.382,20.327c0,0.226,0,0.427-0.025,0.628v0.075c-0.025,1.307-0.05,8.015,1.281,11.382
						c0.251,0.653,0.704,1.206,1.307,1.583c0.754,0.502,18.82,12.01,49.047,12.01c5.82,0,11.167-0.438,16.042-1.136
						C99.26,139.842,100.507,138.418,100.507,136.725L100.507,136.725z"
              />
            </g>
            <g>
              <path
                className="st0"
                d="M96.693,91.216c0.427,0.301,10.428,7.262,22.94,10.478c3.671,1.308,5.295,4.257,6.039,7.687
						c0.336,1.549,1.711,2.651,3.296,2.651l0,0c2.157,0,3.779-1.99,3.316-4.096c-1.088-4.952-3.769-10.373-10.591-12.7
						c-0.075-0.025-0.176-0.05-0.251-0.075c-11.332-2.89-20.754-9.423-20.855-9.498c-1.533-1.081-3.643-0.704-4.724,0.829
						C94.784,88.025,95.161,90.136,96.693,91.216z"
              />
            </g>
          </g>
        </g>
      </g>
      <g>
        <path
          className="st0"
          d="M125.839,130.737l7.986-7.986c1.414-1.414,1.414-3.705,0-5.119s-3.705-1.414-5.119,0l-7.986,7.986
			l-7.986-7.986c-1.414-1.414-3.705-1.414-5.119,0c-1.414,1.414-1.414,3.705,0,5.119l7.986,7.986l-7.986,7.986
			c-1.414,1.414-1.414,3.705,0,5.119c0.707,0.707,1.633,1.061,2.56,1.061c0.926,0,1.853-0.354,2.56-1.061l7.986-7.986l7.986,7.986
			c0.707,0.707,1.633,1.061,2.56,1.061s1.853-0.354,2.56-1.061c1.414-1.414,1.414-3.705,0-5.119L125.839,130.737z"
        />
      </g>
    </g>
  </svg>
)
