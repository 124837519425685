import styled from "styled-components";
import { FleetUserWithLocation, FleetVehicle, RemainingDriving } from "./types.graphql";
import { formatRelative } from "util/date-fns";
import { parseISO } from "date-fns";
import { ZIndexRange } from "types/style";
import { Select } from "components";
import { useTranslate } from "@ur/react-hooks";
import { useState } from "react";
import { isMobile } from "react-device-detect";

const Data = styled.div`
    position: absolute;
    bottom: -15vh;
    ${isMobile && `bottom: -20vh;`}
    left: 0;
    
    width: 100%;
    height: 40vh;
    
    background-color: white;
    ${props => props.hidden && `display: hidden;`}

    padding-left: 1rem;
    padding-top: 1rem;
    z-index: ${ZIndexRange.Globals};

`

const StyledSelect = styled(Select)`
    .--select-curtain-inner {
        max-height: 132px;
    }
    .--select-curtain-option {
        display: flex;
        justify-content: space-between;
    }
`

interface DataAreaProps {
    user: FleetUserWithLocation | null
    allUsers: FleetUserWithLocation[]
    vehicle: FleetVehicle | null
    remainingDriving: RemainingDriving | null
    onClose: () => void
    updateActiveUser: (id: string | null, moveCenter: boolean) => void
} 

export const DataArea: React.FC<DataAreaProps> = ({user, allUsers, vehicle, remainingDriving, onClose, updateActiveUser}) => {
    const [search, setSearch] = useState<string>('')
    const translations = useTranslate({
        selectUser: 'common.select-user',
        noUsersFound: 'common.no-results',
        daily: 'common.daily',
        weekly: 'common.weekly',
        twoWeekly: 'common.two-weekly',
    })

    const displayTime = (time: number | undefined) => {
        if (!time) return 'Ukjent'
        const hours = Math.floor(time/60).toString().padStart(2, '0')
        const minutes = (time%60).toString().padStart(2, '0')
        return `${hours}:${minutes}`
    }

    const options = allUsers.filter(user => user.fullName.toLowerCase().includes(search.toLowerCase())).map(user => ({value: user.id, label: <><span>{user.fullName}</span> <span>{formatRelative(parseISO(user.lastLocation.datetimeStart), new Date())}</span></>}))

    return (
        <>
            <Data>
                <StyledSelect 
                    value={user?.id ?? null}
                    options={options}
                    onChange={(id) => {
                        updateActiveUser(id?.toString() ?? user?.id ?? null, true)
                        if(id === null) onClose()
                    }}
                    searchable="custom"
                    nullable
                    placeholder={translations.selectUser}
                    noOptionsText={translations.noUsersFound}
                    width="500px"
                    maxWidth="calc(100% - 1rem)"
                    onFilterChange={(value) => setSearch(value)}
                />
                {user &&
                    <>
                        <p><b>{vehicle?.registrationNumber}</b></p>
                        <p>{translations.daily}: {displayTime(remainingDriving?.remainingDaily)}</p>
                        <p>{translations.weekly}: {displayTime(remainingDriving?.remainingWeekly)}</p>
                        <p>{translations.twoWeekly}: {displayTime(remainingDriving?.remainingTwoWeekly)}</p>
                    </>
                }
            </Data>
        </>
    )
}