import React, { RefObject } from 'react'
import ScrollContext from 'context/ScrollContext'

// This component exists to provide a scrolling context for the main content.
// The reason this is required at all, is to ensure that the provider contents
// aren't rerendered everytime the context value changes. See https://frontarm.com/james-k-nelson/react-context-performance/ for
// more information.
//
// Be careful when changing this component

interface ContentScrollProviderProps {
  contentRef: RefObject<HTMLElement>
}

export const ContentScrollProvider: React.FC<ContentScrollProviderProps> = ({
  children,
  contentRef,
}) => {
  return (
    <ScrollContext.Provider value={contentRef}>
      {children}
    </ScrollContext.Provider>
  )
}
