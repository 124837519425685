import { useQuery } from '@apollo/client'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Card } from 'components/Card'
import { addDays, formatISO, startOfDay } from 'date-fns'
import React, { useEffect, useMemo } from 'react'
import { MobileOnlyView } from 'react-device-detect'
import { useHistory, useParams } from 'react-router'
import styled, { useTheme } from 'styled-components'
import { format } from 'util/date-fns'
import { correctLocalDateWithTimezone } from 'util/date/localeCorrection'
import { useAdmin, useBreadcrumbs, useModuleConfig, useOnErrorAuto, usePermissions, useUser } from 'util/hooks'
import {
  enforceHourMinuteString,
  generateActivityMapColors,
  optimizeActivities,
  parseNumberToTimestamp,
} from '..'
import { MainInfo, Map, Schedule, TripInfo } from '../components/detail'
import { DRIVER_ACTIVITY_DAY_REPORT_QUERY } from '../queries'
import { VehicleColorDictionary } from '../types'
import {
  DriverActivityDayReportQuery,
  DriverActivityDayReportQueryVariables,
} from '../types.graphql'
import { CheckmarkDiv } from '../components/overview/CheckmarkDiv'
import { PERMISSIONS } from 'util/permissions'
import { useActivityApprovedHandlers } from '../hooks'
import { Icon, usePrompt } from '@ur/react-components'
import { Module, ModuleOptionsValue } from 'modules/companies'
import { ALL_DYNAMIC_COLUMNS_QUERY, ActivitiesWithReceiptCoolumnNode, AllDynamicColumnsQuery, AllDynamicColumnsQueryVariables, ColumnNames } from 'modules/dynamic_tables'
import { SalaryDay } from '../components/overview/SalaryDayCell'
import { Diet } from '../components/overview/DietCell'
import { DriverDietForm, DriverDietPrompt } from '../components/overview/DriverDietPrompt'
import { useDietDayMutations, useSalaryDayMutations } from '../mutations.hooks'
import { SalaryDayForm, SalaryDayPrompt } from '../components/overview/SalaryDayPrompt'
import { GoogleMap } from 'components/GoogleMap'
import { AllActivitiesSumTable } from '../components/overview/AllActivitiesSumTable'
import { Checkbox } from 'components'

const TableWrapper = styled.div`
  margin-bottom: 1rem;
`

const StyledMap = styled(Map)``

const Wrapper = styled.div`
  ${props => props.theme.layout.default};

  header {
    margin-bottom: 0.5rem;

    h2 {
      margin: 0 0 0 0.5rem;
    }
  }

  ${StyledMap} {
    min-width: 500px;
  }
`
const Content = styled(Card)`
  display: grid;
  grid-template-columns: 2fr minmax(300px, 1fr);
  grid-template-rows: 120px 380px auto;
  grid-template-areas:
    'main-info map'
    'trip-info map'
    'schedule  schedule';
  padding: 0;

  ${props => props.theme.media.mobile} {
    grid-template-columns: 100%;
    grid-template-rows: 280px auto;
    grid-template-areas:
      'map'
      'main-info'
      'trip-info'
      'schedule';

    overflow: hidden;
  }
`

const ActionBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: ${props => props.theme.standardBorderRadius};
  margin-bottom: 1rem;

  ${props => props.theme.media.mobile} {
    flex-direction: column;
    gap: 1rem;
  }
`

const ActionBarCommon = styled.div`
  height: 44px;
`

const Navigation = styled(ActionBarCommon)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${props => props.theme.standardBorderRadius};
  background-color: ${props => props.theme.colors.white};
  font-size: 1.2rem;
  font-weight: 600;
  height: 44px;
`



const PrevButton = styled(ActionBarCommon)`
  background-color: ${props => props.theme.colors.white};
  :hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.gray9};
  }
  padding: 1rem;
  border-radius: ${props => props.theme.standardBorderRadius} 0 0 ${props => props.theme.standardBorderRadius};

  width: 38px;
`

const DateWrapper = styled(ActionBarCommon)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  height: 44px;
  border-left: 1px solid ${props => props.theme.colors.gray9};
  border-right: 1px solid ${props => props.theme.colors.gray9};
  ${props => props.theme.media.mobile} {
    min-width: 75vw;
  }
`

const NextButton = styled(ActionBarCommon)`
  background-color: ${props => props.theme.colors.white};
  :hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.gray9};
  }
  padding: 1rem;
  border-radius: 0 ${props => props.theme.standardBorderRadius} ${props => props.theme.standardBorderRadius} 0;

  width: 38px;
`

const TextAndAction = styled(ActionBarCommon)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  border-radius: ${props => props.theme.standardBorderRadius};
  background-color: ${props => props.theme.colors.white};
  > *:first-child {
    border-right: 1px solid ${props => props.theme.colors.gray9};
    margin-right: 1rem;
    padding-right: 1rem;
  }
  > *:last-child {
    cursor: pointer;
  }
`

const TextAndActionGroup = styled(ActionBarCommon)`
  display: flex;
  align-items: center;
  border-radius: ${props => props.theme.standardBorderRadius};
  gap: 1rem;
  height: 44px;
  ${props => props.theme.media.mobile} {
    width: 100%;
    justify-content: space-between;
  }
`


interface ActivitiesDetailProps {}

export const ActivitiesDetail: React.VFC<ActivitiesDetailProps> = () => {
  const translations = useTranslate({
    loading: 'common.loading',
    drivingData: 'common.driving-data',
    diet: 'common.diet',
    salaryDay: 'common.salary-day',
    showSums: 'common.show-sums',
  })
  const { hasPermissions } = usePermissions()
  const me = useUser()
  const admin = useAdmin()
  const theme = useTheme()
  const addPrompt = usePrompt()

  const { userId, date: dateString } =
    useParams<{
      userId: string
      date: string
    }>()
  
  const isMe = userId === me.id
  const canCheck = (
    isMe && hasPermissions(PERMISSIONS.activities.approve.driveractivity, true)
  ) || (
    !isMe && hasPermissions(PERMISSIONS.activities.approve.othersdriveractivity, true)
  )
  const { getModuleOptionStatus } = useModuleConfig(Module.ACTIVITIES)
  const hasReceiptFunctionality = getModuleOptionStatus(
    ModuleOptionsValue.ACTIVITIES_RECEIPT_FUNCTIONALITY
  )

  const canSeeReceipt = (admin || userId === me.id) && hasReceiptFunctionality

  const history = useHistory()
  const { handler: approveHandler } = useActivityApprovedHandlers()
  const paramDate = new Date(dateString)
  const { setOverride, setOverrides, overrides } = useBreadcrumbs()
  const onErrorAuto = useOnErrorAuto()

  // We want to query on 00:00 related to our timezone
  const tempDate = startOfDay(paramDate)

  
  const dietMutations = useDietDayMutations()
  const salaryMutations = useSalaryDayMutations()

  const {
    loading: activityLoading,
    data: activityData,
    refetch,
  } = useQuery<
    DriverActivityDayReportQuery,
    DriverActivityDayReportQueryVariables
  >(DRIVER_ACTIVITY_DAY_REPORT_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      user: userId,
      date: formatISO(tempDate),
    },
    onError: onErrorAuto(),
    onCompleted: data => {
      if (data.driverActivityDayReport.timezoneData?.offset) {
        // This is pretty ugly, but untill we have a CompanyTimezone solution this'll refetch
        // the correct activities if the user's browser is in a different timezone than
        // the report's timezone. Additionally this is required to correct the date of
        // of the report if the user's browser is in a timezone that's -x UTC and report's +y UTC while x > y on
        // the day to not get the previous day.
        //TODO: clean this up when CompanyTimezone is implemented
        const offset = data.driverActivityDayReport.timezoneData?.offset
        if (offset !== tempDate.getTimezoneOffset() / -60) {
          const newFetchDateString =
            dateString + `T00:00:00+` + parseNumberToTimestamp(offset)
          refetch({
            user: userId,
            date: newFetchDateString,
          })
        }
      }
    },
  })
  const { data: columnData } = useQuery<
    AllDynamicColumnsQuery<ActivitiesWithReceiptCoolumnNode>,
    AllDynamicColumnsQueryVariables
  >(ALL_DYNAMIC_COLUMNS_QUERY, {
    variables: {
      moduleName: Module.ACTIVITIES,
    },
    onError: onErrorAuto(),
  })

  useEffect(() => {
    if (
      !activityData?.driverActivityDayReport &&
      overrides[userId] !== translations.loading
    )
      setOverride(userId, translations.loading)
    else if (
      activityData?.driverActivityDayReport &&
      overrides[userId] === translations.loading
    ) {
      const { user } = activityData.driverActivityDayReport
      setOverrides({
        [user.id]: user.fullName,
        [dateString]: format(new Date(dateString), 'PP'),
      })
    }
  }, [
    setOverride,
    setOverrides,
    activityData,
    translations.loading,
    userId,
    dateString,
    overrides,
  ])

  const user = activityData?.driverActivityDayReport.user ?? null
  const report = activityData?.driverActivityDayReport ?? null
  const activities = report?.activities ?? []
  const issues = report?.issues ?? []
  const vehicleLocations = report?.drivenCoordinates ?? []
  const columns = getColumns(canSeeReceipt)

  const date = startOfDay(
    correctLocalDateWithTimezone(paramDate, report?.timezoneData)
  )
  

  // We want the color to be consistent for each vehicle. Vehicle Vin is used as a [key: color]
  const vehicleColors = useMemo<VehicleColorDictionary>(() => {
    const vehicleObject: VehicleColorDictionary = {}
    const vehicleList =
      report?.drivenCoordinates
        .map(drivenCoordinate => drivenCoordinate.vehicleVin ?? 'Unknown')
        .filter(
          (value, index, vehicles) => vehicles.indexOf(value) === index
        ) ?? []
    const vehicleColorList = generateActivityMapColors(vehicleList.length)

    vehicleList.forEach((vehicle, index) => {
      vehicleObject[vehicle] = vehicleColorList[index]
    })

    return vehicleObject
  }, [report?.drivenCoordinates])

  const optimizedActivities = optimizeActivities(activities)

  const hasDietColumn = columns?.some(column => column.name === ColumnNames.DIET)
  const hasSalaryColumn = columns?.some(column => column.name === ColumnNames.SALARY_DAY)

  const form = useForm({
    values: {
      showSums: false,
    },
    config: {
      storage: {
        storeFormState: true,
        storeFormStateName: 'activities-detail-controls',
        retrieveFormStateOnMount: true,
      },
    }
  })

  function getColumns(withReceipt: boolean) {
    let columns = columnData?.allDynamicColumns.edges
      .map(edge => edge.node)
      .filter(node => node.enabled)
    if (
      !withReceipt &&
      columns?.some(column => column.name === ColumnNames.APPROVED)
    ) {
      return columns.filter(node => !(node.name === ColumnNames.APPROVED))
    } else return columns
  }

  async function handleAddDriverDiet() {
    const { data } = await addPrompt<DriverDietForm | null>(resolve => (
      <DriverDietPrompt
        initialData={{
          userId: userId,
          salaryCodeId: report?.diet?.salaryCode?.id ?? null,
        }}
        onSubmit={resolve}
      />
    ))
    if (data === null) return

    dietMutations.updateOrCreateDriverDiet({
      variables: {
        input: {
          ...data,
          date: format(date, 'yyyy-MM-dd'),
        }
      },
    })
  }

  async function handleAddSalaryDay() {
    const { data } = await addPrompt<SalaryDayForm | null>(resolve => (
      <SalaryDayPrompt
        initialData={{
          userId: userId,
          partOfDay: report?.salaryDay?.partOfDay ?? 1,
        }}
        onSubmit={resolve}
      />
    ))
    if (data === null) return

    salaryMutations.updateOrCreateSalaryDay({
      variables: {
        input: {
          ...data,
          date: format(date, 'yyyy-MM-dd'),
        }
      },
    })
  }


  function handleOnClickApprove() {
    if (!canCheck) return
    return () => {
      return approveHandler.day(userId, !report?.approved ?? true, date)
    }
  }

  return (
    <Wrapper>
      <MobileOnlyView>
        <header>
          <h2>{translations.drivingData}</h2>
        </header>
      </MobileOnlyView>

      <ActionBarWrapper>
        <Navigation>
          <PrevButton onClick={() => history.push(`/activities/${userId}/${format(addDays(date, -1), 'yyyy-MM-dd')}`)}>
            <Icon icon='chevron-left'/>
          </PrevButton>
          <DateWrapper>{format(date, 'PP')}</DateWrapper>
          <NextButton onClick={() => history.push(`/activities/${userId}/${format(addDays(date, 1), 'yyyy-MM-dd')}`)}>
            <Icon icon='chevron-right'/>
          </NextButton>
        </Navigation>

        <TextAndActionGroup>
            <TextAndAction>
              <div>{translations.showSums}</div>
              <Checkbox 
                checked={form.formValues.showSums} onChange={form.formChangeHandler('showSums')}
                boxSize='28px'  
              />
            </TextAndAction>
          {report && hasDietColumn &&
            <TextAndAction>
              <div>{translations.diet}</div>
              <Diet report={report} canEdit={canCheck} onClick={handleAddDriverDiet}/>
            </TextAndAction>
          }

          {report && hasSalaryColumn &&
            <TextAndAction>
              <div>{translations.salaryDay}</div>
              <SalaryDay report={report} canEdit={canCheck} onClick={handleAddSalaryDay}/>
            </TextAndAction>
          }

          {canSeeReceipt && 
            <CheckmarkDiv
              checked={report?.approved ?? false}
              onClick={handleOnClickApprove()}
            />
          }
        </TextAndActionGroup>
        
        
        
      </ActionBarWrapper>

      {form.formValues.showSums && (
        <TableWrapper>
          <AllActivitiesSumTable report={report}/>
        </TableWrapper>
      )}

      <Content>
        <MainInfo
          user={user}
          date={date}
          registrationNumbers={report?.registrationNumbers ?? []}
          loading={activityLoading}
        />
        <TripInfo
          startTime={report?.startTime}
          endTime={report?.endTime}
          startLocation={report?.dayStartLocation}
          endLocation={report?.dayEndLocation}
          timeWorkTotal={enforceHourMinuteString(report?.timeWorkTotal)}
          distance={report?.distance}
          fuelConsumption={report?.fuelUsage}
          driverGrade={report?.driverGrade}
          drivingGrade={report?.drivingGrade}
        />
        {activityLoading && vehicleLocations.length === 0 ? (
          <GoogleMap 
            mapContainerStyle={{
              borderTopRightRadius: theme.sizes.defaultBorderRadius,
            }}
            options={{
              streetViewControl: false,
              zoomControl: false,
              mapTypeControl: false,
            }}
          />
        ) : (
          <StyledMap
            pathColors={vehicleColors}
            vehicleLocations={vehicleLocations}
          />
        )}
        
        <Schedule
          activities={optimizedActivities}
          vehicleColors={vehicleColors}
          issues={issues}
          report={report}
          date={date}
        />
      </Content>
    </Wrapper>
  )
}
