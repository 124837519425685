import traph from 'react-traph'
import ToastGraph from './toasts'
import SearchGraph from './search'
import SettingsGraph from './settings'
import { SidebarGraph } from './sidebar'

const Graph = traph({
  toasts: ToastGraph,
  search: SearchGraph,
  settings: SettingsGraph,
  sidebar: SidebarGraph,
})

export default Graph
