import { Icon, usePrompt } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Button, CenteredLoader, Table } from 'components'
import { Card } from 'components/Card'
import { PermissionsRequired } from 'containers/permission-containers'
import { formatISO } from 'date-fns'
import uniq from 'lodash/uniq'
import { Coordinates } from 'types/graphql/maps'
import { ALL_ISSUE_RISK_ASPECTS_QUERY, ExportIssueForm, ExportIssuePrompt } from 'modules/issues'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from '@apollo/client'
import { isMobile } from 'react-device-detect'
import { useLocation, useParams } from 'react-router'
import styled from 'styled-components'
import { IdVariable } from 'types/graphql'
import { Nullable } from 'types/util'
import { format } from 'util/date-fns'
import {
  FileAttachment,
  useBreadcrumbs,
  useOnErrorAuto,
  useUser,
} from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { EditIssueForm, ISSUE_QUERY } from '..'
import {
  Attachments,
  AttachmentsRef,
  Details,
  MainInfo,
  Map,
} from '../components'
import {
  usePatchIssueMutation,
  useExportIssuesMutations,
} from '../mutations.hooks'
import { AllIssueRiskAspectsQuery, AllIssueRiskAspectsQueryVariables, IssueQuery, PatchIssueInput } from '../types.graphl'

const Wrapper = styled.div`
  ${props => props.theme.layout.default};

  header {
    display: flex;
    justify-content: space-between;
  }
`

const ActionLogTable = styled(Table)`
  margin-top: 1rem;
`

const Buttons = styled.div`
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: ${isMobile ? 'space-between' : 'flex-end'};

  button {
    height: 3rem;

    & + button {
      margin-left: 1rem;
    }
  }
`
const Content = styled(Card)`
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-areas:
    'main        map'
    'details     map'
    'attachments map';

  padding: 0;

  ${props => props.theme.media.custom({ max: '1500px' })} {
    display: flex;
    flex-direction: column;
  }
`
const Loading = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  background-color: rgba(255, 255, 255, 0.5);
`

interface IssueProps {}

export const Issue: React.VFC<IssueProps> = () => {
  const translations = useTranslate({
    issue: 'common.issue',
    loading: 'common.loading...',
    edit: 'common.edit',
    cancel: 'common.cancel',
    save: 'common.save',

    open: 'common.open-alt',
    closed: 'common.closed',
    archived: ['common.archived', val => val.toLowerCase()],

    status: 'common.status',
    category: 'common.category',
    responsible: 'common.responsible',
    date: 'common.date',
    reference: ['issues.reference', { abbreviate: false }],
    regNr: 'common.reg-nr',
    about: 'common.about',
    action: 'common.action',
    timestamp: 'common.timestamp',
    actionLogTitle: 'issues.action-log',

    export: 'common.export',

    validation: {
      solutionRequired: 'issues.validation.solution-required',
      required: 'common.required',
    },
    actionLog: {
      CREATED: ['issues.created-by-user', { user: 'user'}],
      UPDATED: ['issues.updated-by-user', { user: 'user'}],

      ARCHIVED: ['issues.archived-by-user', { user: 'user'}],
      RESTORED: ['issues.restored-by-user', { user: 'user'}],

      CLOSED: ['issues.closed-by-user', { user: 'user'}],
      REOPENED: ['issues.reopened-by-user', { user: 'user'}],
    }
  })

  const attachmentsRef = useRef<AttachmentsRef>(null)

  const { issueId } = useParams<{ issueId: string }>()
  const location = useLocation<{ edit?: boolean }>()
  const me = useUser()
  const { setOverride } = useBreadcrumbs()
  const addPrompt = usePrompt()
  const onErrorAuto = useOnErrorAuto()

  const [editing, setEditing] = useState(!!location.state?.edit)
  const [coordinates, setCoordinates] = useState<Nullable<Coordinates>>({
    lat: null,
    lng: null,
  })
  const [attachmentsAdd, setAttachmentsAdd] = useState<
    Omit<FileAttachment, 'id'>[]
  >([])
  const [attachmentsRemove, setattachmentsRemove] = useState<string[]>([])

  const validateSolution = (
    solution: string | undefined,
    form: EditIssueForm
  ) => {
    if (form.closed && solution?.trim() === '')
      return translations.validation.solutionRequired
    return null
  }

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formEdited,
    resetForm,
    updateForm,
    updateInitialValues,
    formChangeHandler: handler,
    submitHandler: submit,
  } = useForm<EditIssueForm>({
    values: {
      user: '',
      responsibleUser: '',
      closed: false,
      archived: false,
      category: '',
      date: new Date(),

      placename: '',
      regNr: '',
      description: '',
      damage: '',
      locationDescription: '',

      estimatedCost: 0,
      actualCost: 0,
      suggestedSolution: '',
      solution: '',
      severity: null,
      riskAspect: null,
    },
    validators: {
      solution: validateSolution,
      category: val => (!!val ? null : translations.validation.required),
      placename: val => (!!val ? null : translations.validation.required),
      description: val => (!!val ? null : translations.validation.required),
      damage: val => (!!val ? null : translations.validation.required),
      suggestedSolution: val =>
        !!val ? null : translations.validation.required,
      riskAspect: val => (!hasRiskAspects || !!val ? null : translations.validation.required),
      severity: val => (!!val ? null : translations.validation.required),
    },
    config: {
      onlyValidateAfterFirstUpdate: false,
    },
  })

  const { data, loading: queryLoading } = useQuery<IssueQuery, IdVariable>(
    ISSUE_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        id: issueId,
      },
      onCompleted(data) {
        if (!data) return

        const { issue } = data
        const reference = issue.referenceId
        const date = format(new Date(issue.timeOfIssue), 'PP')

        setOverride(issue.id, reference ? `${reference} (${date})` : date)

        setCoordinates({
          lat: issue.locationLatitude,
          lng: issue.locationLongitude,
        })

        const values: EditIssueForm = {
          user: issue.user?.id ?? null,
          responsibleUser: issue.responsibleUser?.id ?? null,
          closed: issue.closedAt !== null,
          archived: issue.archivedAt !== null,
          category: issue.category?.id ?? null,
          date: new Date(issue.timeOfIssue),

          placename: issue.locationPlacename,
          regNr: issue.vehiclePlateNumber ?? '',
          description: issue.issueDescription,
          damage: issue.damageDescription,
          locationDescription: issue.locationDescription,

          estimatedCost: issue.costEstimation,
          suggestedSolution: issue.solutionSuggestion,
          actualCost: issue.actualCost,
          solution: issue.solution,
          severity: issue.severity,
          riskAspect: issue.riskAspect?.id ?? null,
        }
        updateForm(values)
        updateInitialValues(values)
      },
      onError: onErrorAuto(),
    }
  )

  const { data: riskAspectData } = useQuery<AllIssueRiskAspectsQuery, AllIssueRiskAspectsQueryVariables>(
    ALL_ISSUE_RISK_ASPECTS_QUERY,
  )

  const hasRiskAspects = (riskAspectData?.allIssueRiskAspects.edges.length ?? 0) > 0

  const mutations = usePatchIssueMutation()
  const { exportIssue, loading: emailLoading } = useExportIssuesMutations()

  useEffect(() => {
    if (!!data) return
    setOverride(issueId, translations.loading)
  })

  const issue = useMemo(() => data?.issue, [data])
  const attachments = useMemo(() => issue?.attachments ?? [], [issue])

  useEffect(() => {
    if (!issue) return

    if (!editing) {
      setCoordinates({
        lat: issue.locationLatitude,
        lng: issue.locationLongitude,
      })
    }
  }, [editing, issue])

  function handleLocationUpdate(
    results: google.maps.GeocoderResult[] | null,
    status: google.maps.GeocoderStatus
  ) {
    if (!editing || status !== google.maps.GeocoderStatus.OK || !results) return
    const [place] = results
    if (!place || !place.formatted_address) return

    const [lat, lng] = [
      place.geometry.location.lat(),
      place.geometry.location.lng(),
    ]
    setCoordinates({
      lat,
      lng,
    })

    updateForm({
      placename: place.formatted_address,
    })
  }
  function handleAttachmentsUpdate(add: FileAttachment[], remove: string[]) {
    setAttachmentsAdd(
      add.map(attachment => ({
        name: attachment.name,
        file: attachment.file,
      }))
    )
    setattachmentsRemove(remove)
  }

  async function doExport(values: ExportIssueForm) {
    if (!issue) return
    await exportIssue({
      variables: {
        userId: me.id,
        reportId: issue?.id,
        recipients: uniq(values.recipients),
        exportFormat: values.exportFormat,
        exportType: values.exportFormat,
        exportOption: values.exportOption,
      },
    })
  }

  async function handleExport() {
    if (!issue) return

    const { data } = await addPrompt<ExportIssueForm | null>(resolve => (
      <ExportIssuePrompt onSubmit={resolve} user={me} />
    ))
    if (!data) return
    doExport(data)
  }

  function handleCancel() {
    resetForm()
    setEditing(false)

    if (!attachmentsRef.current) return
    attachmentsRef.current.resetEditedAttachments()
  }
  function handleSubmit(values: EditIssueForm) {
    if (!issue || !values.category) return

    if (typeof google === 'undefined')
      throw new Error('Issue submit: google is undefined')

    async function runMutation(input: PatchIssueInput) {
      if (!issue) return

      try {
        await mutations.patch({
          variables: { id: issue.id, input },
        })
        handleCancel()
      } catch {}
    }

    let input: PatchIssueInput = {
      responsibleUser: values.responsibleUser,
      category: values.category,
      timeOfIssue: formatISO(values.date),

      locationPlacename: values.placename,
      locationDescription: values.locationDescription,

      vehiclePlateNumber: values.regNr,
      issueDescription: values.description,
      damageDescription: values.damage,

      costEstimation: values.estimatedCost,
      solutionSuggestion: values.suggestedSolution,
      actualCost: values.actualCost,
      solution: values.solution ?? '',

      attachmentsAdd,
      attachmentsRemove,
      severity: values.severity,
      riskAspect: values.riskAspect,
    }
    if (issue.closedAt === null && values.closed)
      input = {
        ...input,
        closedAt: formatISO(new Date()),
        closedBy: me.id,
      }
    else if (!values.closed)
      input = {
        ...input,
        closedAt: null,
        closedBy: null,
      }

    if (coordinates.lat === null || coordinates.lng === null) {
      const service = new google.maps.Geocoder()
      service.geocode(
        {
          address: values.placename,
        },
        (results, status) => {
          const [place] = results ?? [null]
          if (status !== google.maps.GeocoderStatus.OK || !place) {
            runMutation(input)
            return
          }

          const [lat, lng] = [
            place.geometry.location.lat(),
            place.geometry.location.lng(),
          ]
          input = {
            ...input,
            locationLatitude: lat,
            locationLongitude: lng,
          }
          runMutation(input)
        }
      )
    } else {
      input = {
        ...input,
        locationLatitude: coordinates.lat,
        locationLongitude: coordinates.lng,
      }
      runMutation(input)
    }
  }

  const isLoading = queryLoading || mutations.loading || emailLoading
  const isFormEdited = useMemo(
    () => formEdited || !!attachmentsAdd.length || !!attachmentsRemove.length,
    [formEdited, attachmentsAdd.length, attachmentsRemove.length]
  )

  return (
    <Wrapper>
      <header>
        <h2>{translations.issue}</h2>
      </header>

      <Content>
        {isLoading && (
          <Loading>
            <CenteredLoader marginTop="0" size={72} thickness={8} />
          </Loading>
        )}

        <Map
          editing={editing}
          latitude={coordinates.lat}
          longitude={coordinates.lng}
          onClick={handleLocationUpdate}
        />

        <MainInfo
          loading={queryLoading}
          editing={editing}
          form={form}
          errors={errors}
          category={issue?.category}
          date={issue?.timeOfIssue}
          closedAt={issue?.closedAt}
          archivedAt={issue?.archivedAt}
          reference={issue?.referenceId}
          user={issue?.user}
          responsible={issue?.responsibleUser}
          severity={issue?.severity}
          riskAspect={issue?.riskAspect}
          onChange={handler}
          updateForm={updateForm}
        />

        <Details
          loading={queryLoading}
          editing={editing}
          form={form}
          errors={errors}
          placename={issue?.locationPlacename}
          regNr={issue?.vehiclePlateNumber}
          damage={issue?.damageDescription}
          description={issue?.issueDescription}
          locationDescription={issue?.locationDescription}
          costEstimate={issue?.costEstimation}
          actualCost={issue?.actualCost}
          suggestedSolution={issue?.solutionSuggestion}
          solution={issue?.solution}
          onLocationUpdate={handleLocationUpdate}
          onChange={handler}
        />

        <Attachments
          ref={attachmentsRef}
          editing={editing}
          attachments={attachments}
          onUpdate={handleAttachmentsUpdate}
        />
      </Content>
      <Buttons>
        {!editing && (
          <PermissionsRequired
            permissions={PERMISSIONS.companies.export.exportReports}
          >
            <Button
              disabled={isLoading || !issue}
              padding="0 2rem"
              onClick={handleExport}
            >
              <Icon icon="inbox-in" fixedWidth margin="0 6px 0 0" />
              {translations.export}
            </Button>
          </PermissionsRequired>
        )}

        {editing && (
          <Button
            disabled={isLoading}
            background="gray8"
            onClick={handleCancel}
          >
            {translations.cancel}
          </Button>
        )}
        <PermissionsRequired permissions={PERMISSIONS.issues.change.issue}>
          <Button
            disabled={isLoading || (editing && (!formValid || !isFormEdited))}
            onClick={editing ? submit(handleSubmit) : () => setEditing(true)}
          >
            {editing ? translations.save : translations.edit}
          </Button>
        </PermissionsRequired>
      </Buttons>

      {(issue?.actionLogs?.edges?.length ?? 0) > 0 && (
        <>
          <h2>{translations.actionLogTitle}</h2>
          <ActionLogTable>
            <thead>
              <tr>
                <th>{translations.timestamp}</th>
                <th>{translations.action}</th>
              </tr>
            </thead>
            <tbody>
              {issue?.actionLogs.edges.map(({ node }) => {
                const createdAt = format(new Date(node.createdAt), 'PPpp')
                return (
                <tr>
                  <td>{createdAt}</td>
                  <td>{translations.actionLog[node.action]({ user: node.user ? node.user.fullName : "System" })}</td>
                </tr>
                )
              })}
            </tbody>
          </ActionLogTable>
        </>
      )}
    </Wrapper>
  )
}
