export const measureDomNode = (
  node: Node,
  enhanceMeasurableNode = (node: Node) => node
) => {
  const container = document.createElement('div')
  container.style.display = 'inline-block'
  container.style.position = 'absolute'
  container.style.visibility = 'hidden'
  container.style.zIndex = '-1'

  const clonedNode = node.cloneNode(true)
  const content = enhanceMeasurableNode(clonedNode)

  container.appendChild(content)

  document.body.appendChild(container)

  const height = container.clientHeight
  const width = container.clientWidth

  container.parentNode?.removeChild(container)
  return { height, width }
}

export const stopPropagation = (evt: React.SyntheticEvent) =>
  evt.stopPropagation()
