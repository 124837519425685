import { useTranslate } from '@ur/react-hooks'
import { Card } from 'components/Card'
import { PopupMenu } from 'components/PopupMenu'
import { SelectWeekDays } from 'components/SelectWeekday/SelectWeekDays'
import styled from 'styled-components'
import { PeriodAddonForm } from './PeriodAddonPrompt'
import { format } from 'date-fns'

const OvertimePeriodViewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
`

const Wrapper = styled(Card)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
`

const TextWrapper = styled.div`
  padding: 1rem;
  font-size: 16px;
  background-color: ${props => props.theme.colors.gray11};
  color: ${props => props.theme.colors.primary};
  border-radius: 1rem;
  font-weight: 600;
  ${props => props.theme.media.mobile} {
    font-size: 12px;
  }
`

interface PeriodAddonViewProps {
  periodAddon: PeriodAddonForm
  onEdit: () => void
  onDelete: () => void
}

export const PeriodAddonView: React.UFC<PeriodAddonViewProps> = ({
  periodAddon,
  onEdit,
  onDelete,
}) => {
  const translations = useTranslate({
    edit: 'common.edit',
    delete: 'common.delete-alt',
    wholeDay: 'common.whole-day',
  })

  const intervalDisplayString = format(periodAddon.periodStart, 'HH:mm') + ' - '  + format(periodAddon.periodEnd, 'HH:mm')
  const wholeDay =  periodAddon.periodStart.getHours() === 0 && periodAddon.periodStart.getMinutes() === 0 && periodAddon.periodEnd.getHours() === 0 && periodAddon.periodEnd.getMinutes() === 0
  return (
    <Wrapper key={periodAddon.id}>
      <OvertimePeriodViewWrapper>
        <SelectWeekDays
          initialValues={[
            periodAddon.monday,
            periodAddon.tuesday,
            periodAddon.wednesday,
            periodAddon.thursday,
            periodAddon.friday,
            periodAddon.saturday,
            periodAddon.sunday,
          ]}
          disabled
        />

        <TextWrapper>
          {wholeDay ? translations.wholeDay : intervalDisplayString}
        </TextWrapper>

        <TextWrapper>{!!periodAddon.salaryCodeName ? periodAddon.salaryCodeName : ""}</TextWrapper>
      </OvertimePeriodViewWrapper>
      <PopupMenu
        items={[
          { label: translations.edit, onClick: onEdit },
          { label: translations.delete, onClick: onDelete },
        ]}
      />
    </Wrapper>
  )
}
