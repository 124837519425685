import { useTranslate } from '@ur/react-hooks'
import { useTheme } from 'styled-components'
import { BInfringements, CInfringements, DInfringements, InfringementCategory, InfringementCategoryIdentifierEnum, InfringementCategoryIdentifiers, InfringementSeverities, SeverityCategory } from '.'
interface SeverityTranslations {
  minorInfringement: string
  seriousInfringement: string
  verySeriousInfringement: string
  mostSeriousInfringement: string
}

export function useInfringementSeverityFunctions() {
  const baseTranslations = useTranslate({
    minorInfringement: 'infringements.minor-infringement',
    seriousInfringement: 'infringements.serious-infringement',
    verySeriousInfringement: 'infringements.very-serious-infringement',
    mostSeriousInfringement: 'infringements.most-serious-infringement',
  })

  function getSeverityTranslation(
    text: SeverityCategory,
    translations: SeverityTranslations = baseTranslations
  ) {
    switch (text) {
      case InfringementSeverities.MINOR:
        return translations.minorInfringement

      case InfringementSeverities.SERIOUS:
        return translations.seriousInfringement

      case InfringementSeverities.VERY_SERIOUS:
        return translations.verySeriousInfringement

      case InfringementSeverities.MOST_SERIOUS:
        return translations.mostSeriousInfringement

      default:
        return ''
    }
  }

  function getSeverityValue(text: SeverityCategory) {
    switch (text) {
      case InfringementSeverities.MINOR:
        return 1

      case InfringementSeverities.SERIOUS:
        return 10

      case InfringementSeverities.VERY_SERIOUS:
        return 40

      case InfringementSeverities.MOST_SERIOUS:
        return 80

      default:
        return 0
    }
  }

  return { getSeverityTranslation, getSeverityValue }
}

export function useSeverityBackground() {
  const theme = useTheme()

  function getSeverityBackgroundColor(severity: SeverityCategory) {
    switch (severity) {
      case InfringementSeverities.MINOR:
        return theme.colors.infringements.yellow

      case InfringementSeverities.SERIOUS:
        return theme.colors.infringements.orange

      case InfringementSeverities.VERY_SERIOUS:
        return theme.colors.infringements.lightRed

      case InfringementSeverities.MOST_SERIOUS:
        return theme.colors.infringements.red

      default:
        return ''
    }
  }
  return getSeverityBackgroundColor
}

export function useInfringementCategoryFunctions() {
  const baseTranslations = useTranslate({
    categoryInfringement: ['infringements.category-infringement', { category: "" }],
  })

  function getCategoryTranslation(
    text: InfringementCategoryIdentifiers,
    translations = baseTranslations
  ) {
    switch (text) {
      case InfringementCategoryIdentifierEnum.B:
        return translations.categoryInfringement({ category: InfringementCategoryIdentifierEnum.B})

      case InfringementCategoryIdentifierEnum.C:
        return translations.categoryInfringement({ category: InfringementCategoryIdentifierEnum.C })

      case InfringementCategoryIdentifierEnum.D:
        return translations.categoryInfringement({ category: InfringementCategoryIdentifierEnum.D })
      default:
        return ''
    }
  }

  function getCategoriesFromIdentifier(
    identifier: InfringementCategoryIdentifiers
  ): InfringementCategory[] {
    switch (identifier) {
      case InfringementCategoryIdentifierEnum.B:
        return BInfringements

      case InfringementCategoryIdentifierEnum.C:
        return CInfringements

      case InfringementCategoryIdentifierEnum.D:
        return DInfringements

      default:
        return []
    }
  }


  return { getCategoryTranslation, getCategoriesFromIdentifier }
}
