import { Icon } from '@ur/react-components'
import { CenteredLoader, TableMenu, TableMenuItem } from 'components'
import { Card } from 'components/Card'
import { InfringementNodeForAllInfringements } from 'modules/infringements/types.graphl'
import React from 'react'
import styled from 'styled-components'
import { removeCurrentYear } from 'util/date'
import { format, formatDistanceToNow } from 'util/date-fns'
import { safeParsePhoneNumber } from 'util/parsing'
import { SeverityContent as BaseSeverity } from './components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > div + div {
    margin-top: 1rem;
  }
`
const Infringement = styled(Card)`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'user     status   menu'
    'category category category'
    'date     date     date'
    'vehicle  vehicle  vehicle';
  gap: 6px;

  h3 {
    grid-area: user;
    margin: 0;
  }
  div.menu  {
    grid-area: menu;
    margin-left: 1rem;
  }
`
const Severity = styled(BaseSeverity)`
  grid-area: category;
  margin: 0 0 6px -6px;

  div {
    padding: 6px 12px 3px;
    font-size: 0.8rem;
  }
`
const DateField = styled.div`
  grid-area: date;

  div {
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 0.8rem;
  }
  span {
    font-size: 0.7em;
    color: ${props => props.theme.colors.gray6};
  }
`

interface InfringementsCardsProps {
  infringements: InfringementNodeForAllInfringements[]
  queryLoading: boolean
  infringementLoading: string | null

  createMenuItems: (
    infringement: InfringementNodeForAllInfringements
  ) => TableMenuItem<InfringementNodeForAllInfringements>[]

  onLoadMore: () => void
}

export const InfringementsCards: React.VFC<InfringementsCardsProps> = ({
  infringements,
  queryLoading,
  infringementLoading,

  createMenuItems,
}) => {
  return (
    <Wrapper>
      {queryLoading && <CenteredLoader marginTop="1rem" marginBottom="1rem" />}

      {infringements.map(infringement => {
        const date = new Date(infringement.infringementTimestamp)
        const dateString = removeCurrentYear(format(date, 'PPP'))
        const timeAgo = formatDistanceToNow(date, { addSuffix: true })
        const menuItems = createMenuItems(infringement)
        const phone = safeParsePhoneNumber(infringement.user.phoneNumber)

        return (
          <Infringement key={infringement.id}>
            <h3>
              {infringement.user.fullName}

              {!!infringement.user.phoneNumber && (
                <a href={`tel:${infringement.user.phoneNumber}`}>
                  <Icon
                    icon="phone"
                    type="solid"
                    role="link"
                    title={phone}
                    color="secondary"
                    margin="0 0 0 6px"
                  />
                </a>
              )}
            </h3>

            <div className="menu">
              <TableMenu
                items={menuItems}
                loading={infringementLoading === infringement.id}
                loaderSize={18}
                loaderThickness={2}
              />
            </div>

            <Severity severity={infringement.infringementCategory.severity} />

            <DateField>
              <div>{dateString}</div>
              <span>{timeAgo}</span>
            </DateField>
          </Infringement>
        )
      })}
    </Wrapper>
  )
}
