import { gql } from '@apollo/client'

const driverActivityInfo = gql`
  fragment DriverActivityInfo on DriverActivityNode {
    id
    activityType
    datetimeStart
    datetimeEnd
    vehicleVin
    vehicleRegistrationNumber
  }
`

export const ActivitiesFragments = {
  driverActivityInfo,
}
