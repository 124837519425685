import { gql } from '@apollo/client'

export const CREATE_ROUTE_PLAN_MUTATION = gql`
  mutation CreateRoutePlan($input: CreateRoutePlanInput!) {
    createRoutePlan(input: $input) {
      routePlan {
        id
      }
    }
  }
`

export const DELETE_ROUTE_PLAN_MUTATION = gql`
  mutation DeleteRoutePlan($id: ID!) {
    deleteRoutePlan(id: $id) {
      found
      deletedId
    }
  }
`

export const EXPORT_ROUTE_PLAN_MUTATION = gql`
  mutation ExportRoutePlan(
    $userId: ID!
    $companyId: ID!
    $routePlan: ID!
    $mail: Boolean!
  ) {
    exportRoutePlan(
      userId: $userId
      companyId: $companyId
      routePlan: $routePlan
      mail: $mail
    ) {
      ok
      mail
    }
  }
`
