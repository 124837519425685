import React, { UIEvent } from 'react'
import styled from 'styled-components'
import {
  Loader,
  Select as BaseSelect,
  SelectProps as BaseSelectProps,
} from '@ur/react-components'
import { overloadColor } from 'util/style'
import throttle from 'lodash/throttle'
import { ZIndexRange } from 'types/style'
import { useTranslate } from '@ur/react-hooks'

interface WrapperProps {
  filterBackground?: string
  optionHoverBackground?: string
  grayed?: boolean
  width: string
}
const Wrapper = styled.div<WrapperProps>`
  position: relative;
  width: ${props => props.width};

  .--select-display {
    border-color: ${props => props.theme.colors.gray9};
    background: ${props => props.grayed && props.theme.colors.quaternary};
  }
  .--select-display-selected {
    padding: 0 0.6rem;
    font-size: 1.2rem !important;
    font-weight: 500;
  }
  .--select-placeholder {
    padding: 0 0.6rem;
    font-size: 1.2rem !important;
  }
  .--select-curtain {
    border-color: ${props => props.theme.colors.gray9};
    background: ${props => props.grayed && props.theme.colors.quaternary};
    z-index: ${ZIndexRange.Extra};
  }
  .--select-curtain-option {
    padding: 1rem !important;
    font-size: 1rem !important;

    :not(:last-child) {
      border-bottom: 1px solid ${props => props.theme.colors.gray8};
    }

    &:hover {
      background: ${props =>
        props.optionHoverBackground
          ? overloadColor(props.optionHoverBackground)
          : props.grayed
          ? 'white'
          : props.theme.colors.quaternary};
    }
  }
  .--select-curtain-search {
    &::before {
      margin-left: 0rem;
      margin-top: -2px;
    }
    input {
      background: ${props =>
        props.filterBackground
          ? overloadColor(props.filterBackground)
          : props.grayed
          ? 'white'
          : props.theme.colors.quaternary};
      padding: 0.8rem 0.6rem 0.8rem 2.2rem;
      margin: 0;
      border-radius: 8px;
      font-size: 1rem;
      border-radius: 0 0 0 0;
      border-top: 1px solid ${props => props.theme.colors.gray8};
      border-bottom: 1px solid ${props => props.theme.colors.gray8};
    }
    padding: 0;
  }
`
interface LoadingProps {
  background?: string
  grayed?: boolean
}
const Loading = styled.div<LoadingProps>`
  position: absolute;
  z-index: 1;
  top: 3px;
  left: 1rem;

  display: flex;
  align-items: center;
  height: calc(100% - 6px);
  background: ${props => props.grayed && props.theme.colors.quaternary};
`

export interface SelectProps<ValueType extends string | number, ExtraType = any>
  extends BaseSelectProps<ValueType, ExtraType> {
  loading?: boolean
  filterBackground?: string
  optionHoverBackground?: string
  offsetTrigger?: number
  grayed?: boolean
  onFetchMore?: () => void
}

export const Select = <ValueType extends string | number, ExtraType = any>({
  className,
  offsetTrigger = 100,
  loading = false,

  filterBackground,
  optionHoverBackground,
  grayed = false,

  onFetchMore,
  ...selectProps
}: SelectProps<ValueType, ExtraType>) => {
  const { noOptionsText } = useTranslate({
    noOptionsText: 'common.no-options-to-choose-from',
  })
  function handleScroll(event: UIEvent<HTMLDivElement>) {
    if (onFetchMore) {
      const element = event.target as HTMLDivElement
      const scrollHeightDiff = element.scrollHeight - element.clientHeight
      const scrollDiff = scrollHeightDiff - element.scrollTop
      if (scrollDiff < offsetTrigger) {
        onFetchMore()
      }
    }
  }

  const throttledHandleScroll = throttle(handleScroll, 500)

  return (
    <Wrapper
      className={className}
      filterBackground={filterBackground}
      optionHoverBackground={optionHoverBackground}
      grayed={grayed}
      width={selectProps.fullWidth ? '100%' : selectProps.width ?? 'auto'}
    >
      {loading && (
        <Loading background={selectProps.background} grayed>
          <Loader.Dots size={28} />
        </Loading>
      )}
      <BaseSelect
        height="3.875rem"
        onCurtainScroll={throttledHandleScroll}
        noOptionsText={noOptionsText}
        {...selectProps}
      />
    </Wrapper>
  )
}
