import { FormErrors, FormOnChangeFn, useTranslate } from '@ur/react-hooks'
import { useGoogleAutocomplete } from 'util/hooks'
import { UseGoogleAutocompleteOptions } from 'util/hooks/useGoogleAutocomplete/types'
import {
  Input as BaseInput,
  NumberInput as BaseNumberInput,
} from 'components/Input'
import { TextArea as BaseTextArea } from 'components/TextArea'
import React, { useRef, useState, useEffect, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { getLocale } from 'util/date-fns'
import { EditIssueForm } from '../types'
import { GoogleCountryCodes } from 'util/consts'
import { unlessLoadingDots } from 'util/loading'

interface WrapperProps {
  editing: boolean
}
const Wrapper = styled.div<WrapperProps>`
  grid-area: details;

  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'location    reg-nr'
    'locationDescription locationDescription'
    'description description'
    'damage      damage'
    'suggestion  cost-estimate'
    'solution    cost-actual';

  background: ${props => props.theme.colors.quaternary};

  ${props => props.theme.media.mobile} {
    overflow-x: auto;

    ${props =>
      props.editing &&
      css`
        display: flex;
        flex-direction: column;

        & > div {
          border: 1px solid ${props => props.theme.colors.gray8} !important;
          border-width: 1px 0 0 0 !important;
        }
      `};
  }
`
interface BoxProps {
  area: string
  borderTop?: boolean
  borderRight?: boolean
  borderBottom?: boolean
  borderLeft?: boolean
}
export const Box = styled.div<BoxProps>`
  grid-area: ${props => props.area};

  display: flex;
  flex-direction: column;
  padding: 1.5rem 2rem;

  border: 0px solid ${props => props.theme.colors.gray8};
  border-top-width: ${props => props.borderTop && '1px'};
  border-right-width: ${props => props.borderRight && '1px'};
  border-bottom-width: ${props => props.borderBottom && '1px'};
  border-left-width: ${props => props.borderLeft && '1px'};

  h4 {
    margin: 0 0 0.3rem 0;
    color: ${props => props.theme.colors.gray3};
    font-size: 1rem;
    font-weight: 600;
  }
  span {
    font-weight: 600;
    font-size: 1.2rem;
  }

  ${props => props.theme.media.mobile} {
    padding: 1rem;

    h4 {
      margin: 0 0 0.5rem 0;
      font-size: 1rem;
    }
    span {
      font-size: 0.9rem;
    }
  }
`
const Editor = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0.5rem;
`
const Input = styled(BaseInput).attrs({
  background: 'white',
  height: '3rem',
})``
const NumberInput = styled(BaseNumberInput).attrs({
  background: 'white',
  width: '11ch',
  height: '3rem',
})`
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`
const TextArea = styled(BaseTextArea).attrs({
  background: 'white',
  height: '5rem',
})``
const PlaceEditor = styled(Editor)`
  flex-direction: row;
`

const CurrencyEditor = styled(Editor)`
  flex-direction: row;

  div.currency {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 3rem;
    padding: 0 0.5rem;

    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.9rem;

    background-color: white;
    border: 1px solid ${props => props.theme.colors.gray9};
    border-left-width: 0;

    border-top-right-radius: ${props => props.theme.sizes.defaultBorderRadius};
    border-bottom-right-radius: ${props =>
      props.theme.sizes.defaultBorderRadius};
  }
`

interface DetailsProps {
  loading: boolean
  editing: boolean
  form: EditIssueForm
  errors: FormErrors<EditIssueForm>

  placename: string | undefined
  regNr: string | null | undefined
  damage: string | undefined
  locationDescription: string | undefined
  description: string | undefined
  costEstimate: number | undefined
  actualCost: number | null | undefined
  suggestedSolution: string | undefined
  solution: string | undefined

  onLocationUpdate: (
    results: google.maps.GeocoderResult[] | null,
    status: google.maps.GeocoderStatus
  ) => void
  onChange: FormOnChangeFn<EditIssueForm>
}

export const Details: React.VFC<DetailsProps> = ({
  loading,
  editing,
  form,
  errors,

  placename,
  regNr,
  damage,
  description,
  locationDescription,
  costEstimate,
  actualCost,
  suggestedSolution,
  solution,

  onLocationUpdate,
  onChange,
}) => {
  const translations = useTranslate({
    unknown: 'common.unknown',

    location: 'common.location',
    equipment: 'issues.equipment-material',
    damage: 'issues.damage-description',
    description: 'issues.description-of-event-unwanted-event',
    locationDescription: 'issues.location-description',
    suggestedSolution: 'issues.suggested-improvement',
    solution: 'issues.final-solution',
    costEstimate: 'issues.cost-estimation',
    actualCost: 'issues.actual-cost',
  })

  const prevPlacename = useRef<string | null>(null)
  const descriptionPlaceIdMap = useRef(new Map<string, string>())
  const [triggerGeocoding, setTriggerGeocoding] = useState(false)

  const handleFindPlacename = useCallback(() => {
    prevPlacename.current = form.placename
    const service = new google.maps.Geocoder()
    const placeIdValue = descriptionPlaceIdMap.current.get(
      prevPlacename.current
    )

    const geocodeOptions =
      // if we have a placeID from the autocomplete this simply needs to be a lookup with the ID, otherwise we try to find something with geocoding
      placeIdValue !== undefined
        ? { placeId: placeIdValue }
        : { address: form.placename }
    service.geocode(geocodeOptions, onLocationUpdate)
  }, [form.placename, prevPlacename, onLocationUpdate])

  useEffect(() => {
    prevPlacename.current = form.placename

    if (typeof google === 'undefined' || form.placename.trim() === '') return

    if (triggerGeocoding) {
      handleFindPlacename()
      setTriggerGeocoding(false)
    }
  }, [
    form.placename,
    prevPlacename,
    triggerGeocoding,
    onLocationUpdate,
    handleFindPlacename,
  ])

  const predictions = useGoogleAutocomplete(form.placename, {
    componentRestrictions: {
      country: [
        // country code restrictions we use for the autocomplete API
        GoogleCountryCodes.Norway,
        GoogleCountryCodes.Sweden,
        GoogleCountryCodes.Denmark,
        GoogleCountryCodes.Finland,
        GoogleCountryCodes.Estonia,
        GoogleCountryCodes.Latvia,
        GoogleCountryCodes.RussianFederation,
        GoogleCountryCodes.Lithuania,
        GoogleCountryCodes.Poland,
        GoogleCountryCodes.Germany,
        GoogleCountryCodes.Belarus,
        GoogleCountryCodes.Ukraine,
        GoogleCountryCodes.CzechRepublic,
      ],
    },
  } as UseGoogleAutocompleteOptions)

  const autoCompleteSource = predictions.predictions?.map(pred => {
    // The description field is the human readable value, whereas if we pick an option from here
    // we want to use the ID related to said description. We do this by making use of a hashmap which encodes a
    // string with an ID which we can use when we want to perform geocoding. If the key already exists we don't bother.
    if (!descriptionPlaceIdMap.current.has(pred.description)) {
      descriptionPlaceIdMap.current.set(pred.description, pred.place_id)
    }
    return pred.description
  })

  function handlePlacenameKeyDown(
    event: React.KeyboardEvent<HTMLInputElement>
  ) {
    const { key } = event
    if (key === 'Enter') {
      setTriggerGeocoding(true)
    }
  }

  function handleAutoCompleteItemSelect(placename: string) {
    onChange('placename')(placename)
    setTriggerGeocoding(true)
  }

  const unlessLoading = unlessLoadingDots(loading, translations.unknown)

  const localeCode = getLocale().code

  return (
    <Wrapper editing={editing}>
      <Box area="location">
        <h4>{translations.location}</h4>
        {!editing ? (
          <span>{unlessLoading(placename || '-')}</span>
        ) : (
          <PlaceEditor>
            <Input
              value={form.placename}
              fullWidth
              autoCompleteSource={autoCompleteSource}
              onAutoCompleteItemSelect={handleAutoCompleteItemSelect}
              onChange={onChange('placename')}
              onKeyDown={handlePlacenameKeyDown}
            />
          </PlaceEditor>
        )}
      </Box>

      <Box area="reg-nr" borderLeft>
        <h4>{translations.equipment}</h4>
        {!editing ? (
          <span>{unlessLoading(regNr)}</span>
        ) : (
          <Editor>
            <Input
              value={form.regNr ?? ''}
              width="16ch"
              onChange={onChange('regNr')}
            />
          </Editor>
        )}
      </Box>

      <Box area="locationDescription" borderTop>
        <h4>{translations.locationDescription}</h4>
        {!editing ? (
          <span>{unlessLoading(locationDescription || '-')}</span>
        ) : (
          <Editor>
            <Input
              value={form.locationDescription}
              error={errors.locationDescription}
              fullWidth
              onChange={onChange('locationDescription')}
            />
          </Editor>
        )}
      </Box>

      <Box area="description" borderTop>
        <h4>{translations.description}</h4>
        {!editing ? (
          <span>{unlessLoading(description || '-')}</span>
        ) : (
          <Editor>
            <TextArea
              value={form.description}
              error={errors.description}
              fullWidth
              onChange={onChange('description')}
            />
          </Editor>
        )}
      </Box>

      <Box area="damage" borderTop>
        <h4>{translations.damage}</h4>
        {!editing ? (
          <span>{unlessLoading(damage || '-')}</span>
        ) : (
          <Editor>
            <TextArea
              value={form.damage}
              error={errors.damage}
              fullWidth
              onChange={onChange('damage')}
            />
          </Editor>
        )}
      </Box>

      <Box area="suggestion" borderTop>
        <h4>{translations.suggestedSolution}</h4>
        {!editing ? (
          <span>{unlessLoading(suggestedSolution || '-')}</span>
        ) : (
          <Editor>
            <TextArea
              value={form.suggestedSolution}
              error={errors.suggestedSolution}
              fullWidth
              onChange={onChange('suggestedSolution')}
            />
          </Editor>
        )}
      </Box>

      <Box area="cost-estimate" borderTop borderLeft>
        <h4>{translations.costEstimate}</h4>
        {!editing ? (
          <span>
            {unlessLoading(
              (costEstimate ?? 0).toLocaleString(localeCode) + ' NOK'
            )}
          </span>
        ) : (
          <CurrencyEditor>
            <NumberInput
              value={form.estimatedCost}
              onChange={onChange('estimatedCost')}
            />
            <div className="currency">NOK</div>
          </CurrencyEditor>
        )}
      </Box>

      <Box area="solution" borderTop>
        <h4>{translations.solution}</h4>
        {!editing ? (
          <span>{unlessLoading(solution || '-')}</span>
        ) : (
          <Editor>
            <TextArea
              value={form.solution || ''}
              error={errors.solution}
              fullWidth
              onChange={onChange('solution')}
            />
          </Editor>
        )}
      </Box>

      <Box area="cost-actual" borderTop borderLeft>
        <h4>{translations.actualCost}</h4>
        {!editing ? (
          <span>
            {unlessLoading(
              actualCost ? `${actualCost.toLocaleString(localeCode)} NOK` : '-'
            )}
          </span>
        ) : (
          <CurrencyEditor>
            <NumberInput
              value={form.actualCost ?? 0}
              onChange={onChange('actualCost')}
            />
            <div className="currency">NOK</div>
          </CurrencyEditor>
        )}
      </Box>
    </Wrapper>
  )
}
