import { Card } from 'components/Card'
import React from 'react'
import styled from 'styled-components'
import { BaseProps } from 'types/props'
import { Branch } from './Branch'
import { TreeBranch } from './TreeView.types'

const Wrapper = styled(Card)`
  padding: 0;
`

interface TreeViewProps extends BaseProps {
  branches: TreeBranch[]

  /** Pixel size of indent for children */
  indent?: number
  /**
   * Offset level with this amount for indents
   * @example
   * actual level = 2, levelOffset = -1, rendered level = 1
   */
  indentOffset?: number

  levelBackgrounds?: string | string[]

  overrideIndex?: number
  overrideTotalBranches?: number
}

export const TreeView: React.VFC<TreeViewProps> = ({
  className,
  id,

  branches,

  indent = 42,
  indentOffset = 0,

  levelBackgrounds,

  overrideIndex,
  overrideTotalBranches,
}) => {
  return (
    <Wrapper className={className} id={id}>
      {branches.map((branch, index) => (
        <Branch
          key={branch.id}
          branch={branch}
          level={0}
          indent={indent}
          indentOffset={indentOffset}
          levelBackgrounds={levelBackgrounds}
          index={overrideIndex ?? index}
          totalBranches={overrideTotalBranches ?? branches.length}
        />
      ))}
    </Wrapper>
  )
}
