import { UserNode } from './types.graphql'

export const UserPlaceholder: UserNode = {
  id: '',
  firstName: '',
  lastName: '',
  fullName: '',
  birthNumber: '',
  address: '',
  city: '',
  postalCode: '',
  contactPersonName: '',
  contactPersonPhoneNumber: '',
  phoneNumber: '',
  initials: '',
  internalNumber: '',
  username: '',
  email: '',
  isStaff: false,
  isSuperuser: false,
  isActive: false,
  needsPasswordChange: false,
  hasRegistered: false,
  activeDriverCard: undefined,
  driverCards: [],
  profilePicture: '',
  position: '',
  isAdministrator: false,
  unseenNotificationCount: 0,
  primaryCompany: null,
  companies: [],
  allPermissions: [],
  userTypes: [
    {
      id: 'userTypeID',
      permissions: [],
      name: 'UserType',
      color: '#f00',
      isAdministrator: false,
      editable: true,
      dropdowns: [],
    },
  ],
  // Prioritized salary work time for user within a module
  salaryWorkTime: null,

  documents: { edges: [], __typename: '' },
  driverFetchInfo: [],
  hasLoginCode: false,
}
