import { useDebounce, useTranslate } from '@ur/react-hooks'
import { SortableHeader, Table, TableMenu, TableMenuItem } from 'components'
import React, { useCallback, useMemo, useState } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'
import { isMobile } from 'react-device-detect'
import {
  useAdmin,
  useInfiniteScroll,
  useOnErrorAuto,
  usePermissions,
  useUser,
} from 'util/hooks'
import { DEFAULT_PAGE_SIZE } from 'util/pagination'
import { PERMISSIONS } from 'util/permissions'
import { ArchivedInfringementForm, InfringementsTableFilter } from '../../types'
import {
  AllInfringementsQuery,
  AllInfringementsQueryVariables,
  InfringementNodeForAllInfringements,
} from '../../types.graphl'
import { EmployeeCell, SeverityCell, DateCell } from './components'
import { InfringementsCards } from './InfringementsCards'
import { ALL_INFRINGEMENTS_QUERY } from '../../queries'
import { endOfMonth, formatISO, startOfMonth } from 'date-fns'
import { ArchiveInfringementPrompt } from './ArchiveInfringementModal'
import { usePrompt } from '@ur/react-components'
import { IdVariable } from 'types/graphql'
import { useInfringementsMutations } from 'modules/infringements/mutations.hooks'
import styled from 'styled-components'

interface DescriptionCellProps {
  hasData: boolean
}

const DescriptionCell = styled.td<DescriptionCellProps>`
  position: relative;
  max-width: 300px;
  ${props => props.hasData && 'cursor: auto;'};

  & > div {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    font-size: 1rem;

    span {
      &.description {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 0.9em;
        font-weight: 400;
        color: ${props => props.theme.colors.gray5};
      }
    }

    &.popup {
      display: none;
      min-width: 100%;
      max-width: 100%;
    }
  }

  &:hover > div.popup {
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
    z-index: 1000;

    display: flex;
    padding: 1.5rem;

    background: white;
    border-radius: ${props => props.theme.sizes.defaultBorderRadius};
    box-shadow: ${props => props.theme.shadow.default};

    span.description {
      white-space: normal;
      color: ${props => props.theme.colors.text.dark};
    }
  }
`

interface InfringementsTableProps {
  filter: InfringementsTableFilter

  onSort: (orderBy: string) => void
}

export const InfringementsTable: React.VFC<InfringementsTableProps> = ({
  filter,
  onSort,
}) => {
  const translations = useTranslate({
    date: 'common.date',
    employee: 'common.employee',
    archived: 'common.archived',
    category: 'common.category',

    noInfringements: 'infringements.no-infringements',
    noResults: 'common.no-results',

    severity: 'infringements.severity',
    archiveInfringement: 'infringements.archive-infringement',
    restoreInfringement: 'infringements.restore-infringement',
  })

  const me = useUser()
  const admin = useAdmin()
  const { hasPermissionsAndMe } = usePermissions()
  const onErrorAuto = useOnErrorAuto()
  const addPrompt = usePrompt()

  const debouncedArchived = useDebounce(filter.archived)
  const debouncedExcludeSeverities = useDebounce(filter.excludeSeverities, 500)
  const debouncedQuery = useDebounce(filter.query)

  const [infringementLoading, setInfringementLoading] =
    useState<string | null>(null)

  const canEdit = (user: IdVariable) =>
    admin ||
    hasPermissionsAndMe(user, PERMISSIONS.infringements.change.infringement)

  const { datetimeStartGte, datetimeEndLt } = useMemo(() => {
    const datetimeStartGte = startOfMonth(filter.month)
    const datetimeEndLt = endOfMonth(datetimeStartGte)

    return {
      datetimeStartGte,
      datetimeEndLt,
    }
  }, [filter.month])

  const {
    data,
    loading: queryLoading,
    networkStatus,
    fetchMore,
  } = useQuery<AllInfringementsQuery, AllInfringementsQueryVariables>(
    ALL_INFRINGEMENTS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        q: debouncedQuery,
        orderBy: filter.orderBy,
        first: DEFAULT_PAGE_SIZE,
        // Admins can view all data
        users: admin ? filter.users : [me.id],
        archivedAtIsNull: !debouncedArchived,
        excludeSeverities: debouncedExcludeSeverities,
        excludeCategories: filter.excludeCategories,
        infringementTimestamp_Lt: datetimeEndLt,
        infringementTimestamp_Gte: datetimeStartGte,
      },
      onError: onErrorAuto(),
    }
  )
  const cacheLoading: boolean =
    (queryLoading && !data) || networkStatus === NetworkStatus.refetch

  const infringements =
    data?.allInfringements.edges.map(edge => edge.node) ?? []
  const pageInfo = data?.allInfringements.pageInfo

  const { toggleInfringementArchived, loading: archiveInfringementLoading } =
    useInfringementsMutations()

  async function handleArchiveInfringement(
    infringement?: InfringementNodeForAllInfringements
  ) {
    if (typeof infringement === 'undefined') return

    if (infringement.archivedAt !== null) {
      executeArchiveInfringement(infringement)()
      return
    }

    const initialData: ArchivedInfringementForm = {
      archivedBasis: '',
    }

    const { data } = await addPrompt<ArchivedInfringementForm | null>(
      resolve => (
        <ArchiveInfringementPrompt
          initialData={initialData}
          onResolve={resolve}
        />
      )
    )
    if (data === null) return

    executeArchiveInfringement(infringement)(data)
  }

  function executeArchiveInfringement(
    infringement?: InfringementNodeForAllInfringements | null
  ) {
    if (!infringement) return () => void 0

    return async (data?: ArchivedInfringementForm) => {
      const isArchived = infringement.archivedAt !== null
      setInfringementLoading(infringement.id)
      await toggleInfringementArchived({
        variables: {
          id: infringement.id,
          input: {
            archivedBy: me.id,
            archivedAt: isArchived ? null : formatISO(new Date()),
            archivedBasis: data?.archivedBasis,
          },
        },
      })
      setInfringementLoading(null)
    }
  }

  const handleScrollBottom = useCallback(async () => {
    if (
      typeof data === 'undefined' ||
      typeof pageInfo === 'undefined' ||
      !pageInfo.hasNextPage
    )
      return
    try {
      await fetchMore({
        variables: {
          after: pageInfo.endCursor,
        },
      })
    } catch (e) {
      //@ts-ignore
      if (e.name === 'Invariant Violation') return
      throw e
    }
  }, [data, fetchMore, pageInfo])

  useInfiniteScroll(
    handleScrollBottom,
    200,
    !queryLoading && pageInfo?.hasNextPage
  )

  function createMenuItems(infringement: InfringementNodeForAllInfringements) {
    const items: TableMenuItem<InfringementNodeForAllInfringements>[] = []

    if (canEdit(infringement.user)) {
      const isArchived = infringement.archivedAt !== null
      items.push({
        label: isArchived
          ? translations.restoreInfringement
          : translations.archiveInfringement,
        data: infringement,
        role: 'link',
        onClick: handleArchiveInfringement,
      })
    }
    return items
  }

  const filterActive = debouncedQuery !== ''
  const noDataText = filterActive
    ? translations.noResults
    : translations.noInfringements

  return (
    <>
      {isMobile ? (
        <InfringementsCards
          infringements={infringements}
          queryLoading={queryLoading}
          infringementLoading={infringementLoading}
          createMenuItems={createMenuItems}
          onLoadMore={handleScrollBottom}
        />
      ) : (
        <Table
          loading={cacheLoading || archiveInfringementLoading}
          loaderProps={{
            columns: 3,
            rows: 10,
            expectedWidths: [92, 82, 162],
          }}
          noData={infringements.length === 0}
          noDataText={noDataText}
        >
          <thead>
            <tr>
              <SortableHeader
                baseValue="infringementTimestamp"
                sortValue={filter.orderBy}
                invertDirection
                onSort={onSort}
              >
                {translations.date}
              </SortableHeader>
              <SortableHeader
                baseValue="user"
                sortValue={filter.orderBy}
                disabled={!admin}
                onSort={onSort}
              >
                {translations.employee}
              </SortableHeader>

              <th>{translations.category}</th>

              <SortableHeader
                baseValue="severity"
                sortValue={filter.orderBy}
                onSort={onSort}
              >
                {translations.severity}
              </SortableHeader>

              <th></th>
            </tr>
          </thead>

          <tbody>
            {infringements.map(infringement => {
              const menuItems = createMenuItems(infringement)

              const hasData = !!infringement.infringementCategory.description

              return (
                <tr key={infringement.id}>
                  <DateCell
                    infringementId={infringement.id}
                    createdAt={infringement.infringementTimestamp}
                  />

                  <EmployeeCell user={infringement.user} />

                  <DescriptionCell hasData={hasData}>
                    <div className="flat">
                      <span className="description">
                        <b>{infringement.infringementCategory.identifier}.</b>{' '}
                        {infringement.infringementCategory.description}
                      </span>
                    </div>
                    {hasData && (
                      <div className="popup">
                        <span className="description">
                          <b>{infringement.infringementCategory.identifier}.</b>{' '}
                          {infringement.infringementCategory.description}
                        </span>
                      </div>
                    )}
                  </DescriptionCell>

                  <SeverityCell
                    severity={infringement.infringementCategory.severity}
                  />
                  <TableMenu items={menuItems} loading={queryLoading} />
                </tr>
              )
            })}
          </tbody>
        </Table>
      )}
    </>
  )
}
