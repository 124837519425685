import { useTranslate } from '@ur/react-hooks'
import {
  AllDynamicColumnsQuery,
  AllDynamicColumnsQueryVariables,
  ALL_DYNAMIC_COLUMNS_QUERY,
  PATCH_DYNAMIC_COLUMN_MUTATION,
  PatchDynamicColumnMutation,
  PatchDynamicColumnMutationVariables,
} from 'modules/dynamic_tables'
import { useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useOnErrorAuto } from 'util/hooks'
import { DynamicColumn } from './types'
import { DynamicTablePreview, AddDynamicColumnButton } from '.'
import { useToast } from '@ur/react-components'

interface DynamicColumnSettingsProps {
  name: string
  forExport?: boolean
}

export const DynamicTableSettings: React.VFC<DynamicColumnSettingsProps> = ({
  name,
  forExport = false,
}) => {
  const translations = useTranslate({
    tableSettings: 'settings.tables',
    tableExportSettings: 'settings.export-tables',
    removeSuccess: 'settings.toasts.remove-column-success',
  })

  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()

  const { data } = useQuery<
    AllDynamicColumnsQuery,
    AllDynamicColumnsQueryVariables
  >(ALL_DYNAMIC_COLUMNS_QUERY, {
    variables: {
      moduleName: name,
    },
    onError: onErrorAuto(),
  })

  const columns = useMemo(() => {
    return data?.allDynamicColumns.edges.map(edge => edge.node) ?? []
  }, [data])

  const [patchDynamicColumn] = useMutation<
    PatchDynamicColumnMutation,
    PatchDynamicColumnMutationVariables
  >(PATCH_DYNAMIC_COLUMN_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllDynamicColumnsQuery'],
    onError: onErrorAuto(),
  })

  function toggleEnabled(id: string, enabled: boolean) {
    patchDynamicColumn({
      variables: {
        id: id,
        input: { enabled },
      },
    })
  }

  function toggleExport(id: string, inExport: boolean) {
    patchDynamicColumn({
      variables: {
        id: id,
        input: { inExport },
      },
    })
  }
  const title = forExport
    ? translations.tableExportSettings
    : translations.tableSettings
  function patchColumn(id: string, input: Partial<DynamicColumn>) {
    patchDynamicColumn({
      variables: {
        id: id,
        input,
      },
    })

    if (input.enabled === false) {
      addToast('success', translations.removeSuccess)
      return
    }

    if (input.inExport === false) {
      addToast('success', translations.removeSuccess)
    }
  }

  return (
    <div>
      <h2>{title}</h2>
      <AddDynamicColumnButton
        columns={columns}
        onToggle={forExport ? toggleExport : toggleEnabled}
        forExport={forExport}
      />
      <DynamicTablePreview
        columns={columns}
        onEdit={patchColumn}
        forExport={forExport}
      />
    </div>
  )
}
