import { PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Checkbox, RegularModal, TextArea } from 'components'
import { ManApiKeyNode } from 'modules/companies/types.graphql'
import React from 'react'
import styled from 'styled-components'
import { Field, Form } from '..'
import { useIntegrationsMutations } from '../../util/mutations.hooks'

const ActiveWrapper = styled.div`
  margin-left: 1rem;
`

export interface ManIntegrationForm {
  integrationId: string
  active: boolean
}

interface EditManIntegrationModalProps {
  integration: ManApiKeyNode | null
  index: number

  onSubmit: PromptResolve<ManIntegrationForm | null>
}

export const EditManIntegrationModal: React.VFC<EditManIntegrationModalProps> =
  ({
    integration,
    index,

    onSubmit,
  }) => {
    const translations = useTranslate({
      createIntegration: 'settings.create-man-integration',
      editIntegration: ['settings.edit-man-integration-n', { n: 0 }],

      tachographKey: 'settings.tachograph-key',
      fmsAccessKey: 'settings.fms-access-key',
      fmsSecretKey: 'settings.fms-secret-key',
      active: 'common.active',

      save: 'common.save',
      create: 'common.create-alt',

      validation: {
        required: 'common.required',
        tachographError: 'integrations.error-in-tachograph-key',
        FMSError: 'integrations.error-in-FMS-key',
      },
    })

    const mutations = useIntegrationsMutations()
    const editing = integration !== null

    const validateNonEmpty = (value: string) =>
      !!value ? null : translations.validation.required

    const {
      formValues: form,
      formErrors: errors,
      formValid,
      formChangeHandler: handler,
      submitHandler: submit,
    } = useForm<ManIntegrationForm>({
      values: {
        integrationId: integration?.integrationId ?? '',
        active: integration?.active ?? true,
      },
      validators: {
        integrationId: validateNonEmpty,
      },
      config: {
        initAsInvalid: !editing,
      },
    })

    async function handleSubmit() {
      submit(onSubmit)()
    }

    return (
      <RegularModal
        title={
          editing
            ? translations.editIntegration({ n: index + 1 })
            : translations.createIntegration
        }
        width="600px"
        submitText={editing ? translations.save : translations.create}
        submitDisabled={!formValid}
        loading={mutations.loading}
        footerLeft={
          <ActiveWrapper>
            <Checkbox
              checked={form.active}
              label={translations.active}
              boxWidth="32px"
              boxHeight="32px"
              onChange={handler('active')}
            />
          </ActiveWrapper>
        }
        onCancel={() => onSubmit(null)}
        onSubmit={() => handleSubmit()}
      >
        <Form>
          <Field>
            <label>{translations.tachographKey}</label>
            <TextArea
              value={form.integrationId}
              error={errors.integrationId}
              fullWidth
              autoFocus
              resize="none"
              onChange={handler('integrationId')}
            />
          </Field>
        </Form>
      </RegularModal>
    )
  }
