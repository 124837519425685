import React from 'react'
import { usePushHistory } from 'util/hooks'
import styled from 'styled-components'

const ImageLogo = styled.img`
  max-height: 100%;
  max-width: 100%;

  ${props => props.theme.layout.largePad} {
    max-width: 200px;
  }
  ${props => props.theme.layout.mobile} {
    max-width: 200px;
  }
`
export interface CompanyProps {
  logo: string
}
export const CompanyLogo: React.VFC<CompanyProps> = ({ logo }) => {
  const pushHistory = usePushHistory()
  return (
    <ImageLogo
      src={logo}
      alt="Logo not found"
      role="link"
      onClick={evt => pushHistory('/', evt)}
    />
  )
}
