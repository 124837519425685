import { Icon, useToast } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { CountrySelect, Select, Checkbox, UnsavedChanges } from 'components'
import { PATCH_COMPANY_MUTATION } from 'modules/companies/mutations'
import {
  PatchCompanyMutation,
  PatchCompanyMutationVariables,
} from 'modules/companies/types.graphql'
import React, { useRef, useState } from 'react'
import { useMutation } from '@apollo/client'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { MaxMediaSize } from 'util/consts'
import { CountryCode } from 'util/countries'
import { useCompany, useOnErrorAuto } from 'util/hooks'
import {
  Field,
  Form,
  Header,
  InfoBox,
  Input,
  LoadingLabel,
  Section,
  SectionHeader,
  SettingsWrapper,
} from '../components/common'
import { NotificationSettingsTable } from '../components/NotificationSettings'
import { useNotificationSettings } from '../util/NotificationSettings'
import { useLanguageOptions } from 'locale/hooks'

const DEFAULT_LOGO_SIZE = '258px'

interface LogoProps {
  size?: string
}
const Logo = styled(Field)<LogoProps>`
  display: grid;
  grid-template-rows: auto 1fr;
  width: ${props => props.size ?? DEFAULT_LOGO_SIZE};

  div.inner {
    position: relative;
    width: ${props => props.size ?? DEFAULT_LOGO_SIZE};
    height: 100%;

    img {
      width: 100%;
      max-height: ${props => props.size ?? DEFAULT_LOGO_SIZE};

      object-fit: contain;
      border-radius: ${props => props.theme.sizes.defaultBorderRadius};
      border: 1px solid ${props => props.theme.colors.gray9};
    }
    div.upload {
      display: none;

      position: absolute;
      top: 8px;
      right: 8px;

      justify-content: center;
      align-items: center;

      width: 48px;
      height: 48px;
      border-radius: 8px;
      background-color: white;
      box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.1);
      opacity: 0.6;
      cursor: pointer;

      &:hover {
        opacity: 1;

        i {
          color: ${props => props.theme.colors.secondary};
        }

        &:active {
          background-color: ${props => props.theme.colors.secondary};

          i {
            color: white;
          }
        }
      }
    }
    &:hover {
      div.upload {
        display: flex;
      }
    }
  }

  ${props => props.theme.media.mobile} {
    width: 100%;
    height: auto;

    div.inner {
      width: 100%;

      img {
        max-height: auto;
      }
    }
  }
`

interface GeneralSettingsProps {}

export const GeneralSettings: React.VFC<GeneralSettingsProps> = () => {
  const notificationSettings = useNotificationSettings()
  const translations = useTranslate({
    info: 'settings.info.general',

    companyName: 'common.company-name',
    country: 'common.country',
    language: 'common.language',
    logo: 'settings.company-logo',
    useAsLogo: 'settings.use-as-site-logo',

    validation: {
      mustHaveName: 'settings.validation.company.must-have-name',
      fileSize: 'settings.validation.company.logo-file-size',
    },
    success: 'settings.toasts.company-settings-updated',
    error: 'settings.errors.company-settings-generic',
  })

  const fileInputRef = useRef<HTMLInputElement>(null)

  const company = useCompany()
  const addToast = useToast()
  const { languageOptions } = useLanguageOptions()
  const [isLoading, setIsLoading] = useState({
    fullName: false,
    country: false,
    language: false,
    logo: false,
    useCompanyLogo: false,
  })

  const {
    formValues: form,
    formErrors: errors,
    formChangeHandler: handler,
    updateForm,
    validateForm,
  } = useForm({
    values: {
      fullName: company.fullName,
      country: company.country as CountryCode,
      language: company.language.toLowerCase(),
      useCompanyLogo: company.useCompanyLogo,
    },
    validators: {
      fullName: val => (!!val ? null : translations.validation.mustHaveName),
    },
  })

  const onErrorAuto = useOnErrorAuto()

  const [patchCompanyMutation] = useMutation<
    PatchCompanyMutation,
    PatchCompanyMutationVariables
  >(PATCH_COMPANY_MUTATION, {
    refetchQueries: ['Bootstrap', 'CurrentCompanySettings'],
    onCompleted: () => {
      addToast('success', translations.success)
    },
    onError: onErrorAuto(translations.error),
  })

  function updateIsLoading(key: keyof typeof isLoading, value: boolean) {
    setIsLoading(v => ({
      ...v,
      [key]: value,
    }))
  }

  async function handleChangeCountry(value: CountryCode | null) {
    if (!value) return

    const originalCountry = form.country

    updateForm({
      country: value,
    })

    try {
      updateIsLoading('country', true)
      await patchCompanyMutation({
        variables: {
          id: company.id,
          input: {
            country: value,
          },
        },
      })
    } catch {
      updateForm({ country: originalCountry })
    } finally {
      updateIsLoading('country', false)
    }
  }

  async function handleChangeLanguage(value: string | null) {
    if (!value) return

    const originalLanguage = form.language

    updateForm({
      language: value,
    })

    try {
      updateIsLoading('language', true)
      await patchCompanyMutation({
        variables: {
          id: company.id,
          input: {
            language: value.toUpperCase(),
          },
        },
      })
    } catch {
      updateForm({ language: originalLanguage })
    } finally {
      updateIsLoading('language', false)
    }
  }

  async function submitName() {
    if (form.fullName === company.fullName) return

    const { valid } = validateForm(['fullName'])
    if (!valid) return

    try {
      updateIsLoading('fullName', true)
      await patchCompanyMutation({
        variables: {
          id: company.id,
          input: {
            fullName: form.fullName,
          },
        },
      })
    } catch {
      updateForm({ fullName: company.fullName })
    } finally {
      updateIsLoading('fullName', false)
    }
  }

  async function handleUploadLogo(evt: React.ChangeEvent<HTMLInputElement>) {
    const file = evt.target.files?.[0]
    if (!file) return

    if (file.size > MaxMediaSize) {
      addToast('warning', translations.validation.fileSize)
    } else {
      try {
        updateIsLoading('logo', true)
        await patchCompanyMutation({
          variables: {
            id: company.id,
            input: {
              logo: file,
            },
          },
        })
      } finally {
        updateIsLoading('logo', false)
      }
    }

    if (fileInputRef.current) fileInputRef.current.value = ''
  }

  async function handleUseLogo(value: boolean) {
    const originalValue = form.useCompanyLogo

    updateForm({ useCompanyLogo: value })
    try {
      updateIsLoading('useCompanyLogo', true)
      await patchCompanyMutation({
        variables: {
          id: company.id,
          input: {
            useCompanyLogo: value,
          },
        },
      })
    } catch {
      updateForm({ useCompanyLogo: originalValue })
    } finally {
      updateIsLoading('useCompanyLogo', false)
    }
  }

  return (
    <SettingsWrapper
      grid={{
        flow: 'row',
      }}
    >
      <InfoBox initCollapsed={isMobileOnly}>{translations.info}</InfoBox>

      <Section
        grid={{
          columns: !isMobileOnly ? `1fr ${DEFAULT_LOGO_SIZE}` : '1fr',
        }}
      >
        <Form>
          <Field>
            <LoadingLabel loading={isLoading.fullName}>
              {translations.companyName}
            </LoadingLabel>
            <Input
              value={form.fullName}
              error={errors.fullName}
              color="primary"
              {...(!isMobileOnly
                ? {
                    height: 'auto',
                    padding: '0.4em 0.5em 0.2em',
                    fontSize: '2.4rem',
                    fontWeight: 600,
                  }
                : {})}
              onBlur={submitName}
              onChange={handler('fullName')}
            />
          </Field>

          <Field>
            <LoadingLabel loading={isLoading.country}>
              {translations.country}
            </LoadingLabel>
            <CountrySelect
              value={form.country}
              fullWidth
              onChange={handleChangeCountry}
            />
          </Field>

          <Field>
            <LoadingLabel loading={isLoading.language}>
              {translations.language}
            </LoadingLabel>
            <Select
              value={form.language}
              options={languageOptions}
              fullWidth
              onChange={handleChangeLanguage}
            />
          </Field>
        </Form>

        <Logo>
          <LoadingLabel loading={isLoading.logo}>
            {translations.logo}
          </LoadingLabel>

          <div className="inner">
            <input
              ref={fileInputRef}
              hidden
              type="file"
              accept="image/*"
              onChange={handleUploadLogo}
            />
            <img src={company.logo ?? ''} alt="No logo found" />

            <div
              className="upload"
              onClick={() => fileInputRef.current?.click()}
            >
              <Icon icon="upload" type="solid" size="1.2rem" color="gray6" />
            </div>
          </div>

          {!!company.logo && (
            <div className="check">
              <Checkbox
                checked={form.useCompanyLogo}
                label={translations.useAsLogo}
                color="gray4"
                onChange={handleUseLogo}
              />
            </div>
          )}
        </Logo>
      </Section>

      {/* NotificationSettings */}
      <Header>
        <SectionHeader loading={notificationSettings.loading}>
          {notificationSettings.translations.notifications}
        </SectionHeader>
      </Header>

      <InfoBox initCollapsed={isMobileOnly}>
        {notificationSettings.translations.info}
      </InfoBox>

      <Section>
        {!!notificationSettings.data && (
          <NotificationSettingsTable
            company={notificationSettings.data.currentCompany}
            onChange={notificationSettings.onChangeCallback}
          />
        )}
      </Section>

      <UnsavedChanges
        show={notificationSettings.showUnsavedChangesPopup}
        message={notificationSettings.translations.prompt.message}
        onSave={notificationSettings.handleSave}
      />
    </SettingsWrapper>
  )
}
