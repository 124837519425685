import { Button } from 'components'
import ScrollBar from 'react-perfect-scrollbar'
import styled, { css } from 'styled-components'
import { ZIndexRange } from 'types/style'

interface FilterButtonProps {
  open?: boolean
}
export const FilterButton = styled(Button).attrs({
  centerContent: false,
})<FilterButtonProps>`
  position: relative;

  width: 300px;
  padding: 0;
  background: ${props => props.theme.colors.quaternary};
  border: solid ${props => props.theme.colors.gray9};
  border-width: 1px 0;
  color: ${props => props.theme.colors.gray5};
  font-weight: 400;

  .--button-icon-left,
  .--button-icon-right {
    border-style: solid;
    border-color: ${props => props.theme.colors.gray9};
  }
  .--button-icon-left {
    padding-left: 1.6rem;
    border-width: 1px 0 1px 1px;
  }
  .--button-icon-right {
    padding-right: 1.6rem;
    border-width: 1px 1px 1px 0;
    color: ${props => props.theme.colors.gray7};
  }

  &:focus {
    filter: none;
    color: ${props => props.theme.colors.secondary};
    border-color: ${props => props.theme.colors.secondary};

    .--button-icon-left,
    .--button-icon-right {
      border-color: ${props => props.theme.colors.secondary};
      color: ${props => props.theme.colors.secondary};
    }
  }
  ${props =>
    props.open &&
    css`
      border-color: ${props => props.theme.colors.secondary};

      .--button-icon-left,
      .--button-icon-right {
        border-color: ${props => props.theme.colors.secondary};
        color: ${props => props.theme.colors.secondary};
      }
    `}

  ${props => props.theme.layout.mobile} {
    width: 100%;
  }
`
export const Popup = styled.aside`
  position: absolute;
  z-index: ${ZIndexRange.Dropdowns};
  top: -2rem;
  right: -2rem;
  right: -2rem;

  display: grid;
  grid-template-rows: auto 1fr;

  min-width: calc(100% + 4rem);
  padding: 2rem 2rem 1rem;

  text-align: left;
  background: white;
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  box-shadow: ${props => props.theme.shadow.default};
  cursor: default;
  user-select: text;

  h4 {
    margin: 1rem 0 0.5rem 0;
    color: ${props => props.theme.colors.gray1};
    font-weight: 600;
    font-size: 1rem;
  }
`

interface StatusProps {
  statusCount: number
}

export const Status = styled.div<StatusProps>`
  display: grid;
  grid-template-columns: repeat(${props => props.statusCount}, 1fr);
  gap: 0.5rem;
`
interface StatusButtonProps {
  active: boolean
}
export const StatusButton = styled.div.attrs({
  role: 'button',
})<StatusButtonProps>`
  padding: 10px 0 8px;
  border-radius: 999px;

  color: ${props =>
    props.active ? props.theme.colors.secondary : props.theme.colors.gray6};
  background: ${props =>
    props.active ? props.theme.colors.quaternary : 'inherit'};

  text-align: center;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
`

export const FilterCheckmarksWrapper = styled.div`
  position: relative;
  max-height: 300px;
  overflow-y: auto;
  overscroll-behavior: none;
`

export const StyledScrollBar = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > * + * {
    margin-top: 0.3rem;
  }
`
