import { PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Input, RegularModal } from 'components'
import { DetectBlur } from 'containers/util-containers'
import { Field, Form, UserDocumentCategoryForSettings } from '..'

export interface UserDocumentCategoryForm {
  name: string
}

interface UserDocumentCategoryPromptProps {
  initialData?: UserDocumentCategoryForm
  editableCategories: UserDocumentCategoryForSettings[]

  onSubmit: PromptResolve<UserDocumentCategoryForm | null>
}

export const UserDocumentCategoryPrompt: React.VFC<UserDocumentCategoryPromptProps> = ({
  initialData,
  editableCategories,
  onSubmit,
}) => {
  const translations = useTranslate({
    form: {
      label: {
        name: 'common.name',
        color: 'common.color',
        responsibleUser: 'settings.responsible-user',
      },
    },

    addUserDocumentCategory: 'settings.add-issue-category',
    editUserDocumentCategory: 'settings.edit-issue-category',
    issueCategories: 'common.issue-categories',

    create: 'common.create-alt',
    update: 'common.update',

    validation: {
      nameExists: 'settings.validation.issue-category-name-exists',
      required: 'common.required',
    },
    prompt: {
      delete: ['settings.prompts.issue-category-delete', { name: '' }],
      deleteTitle: 'settings.prompts.issue-category-delete-title',
    },
  })

  const editing = typeof initialData !== 'undefined'

  function validateName(name: string, formName: string) {
    if (name === initialData?.name) return null
    if (name === '') return translations.validation.required
    if (
      !!editableCategories.find(
        type => type.name === formName
      )
    ) {
      return translations.validation.nameExists
    }
    return null
  }

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formChangeHandler: handler,
    submitHandler: submit,
  } = useForm<UserDocumentCategoryForm>({
    values: initialData ?? {
      name: '',
    },
    validators: {
      name: (val, form) => validateName(val, form.name),
    },
  })

  return (
    <DetectBlur active onClickOutside={() => onSubmit(null)}>
      <RegularModal
        title={
          editing
            ? translations.editUserDocumentCategory
            : translations.addUserDocumentCategory
        }
        submitText={editing ? translations.update : translations.create}
        submitDisabled={!formValid}
        width="400px"
        onCancel={() => onSubmit(null)}
        onSubmit={submit(onSubmit)}
      >
        <Form>
          <Field>
            <label>{translations.form.label.name}</label>
            <Input
              value={form.name}
              error={errors.name}
              autoFocus
              fullWidth
              onChange={handler('name')}
            />
          </Field>
        </Form>
      </RegularModal>
    </DetectBlur>
  )
}
