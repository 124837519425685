import { SelectOption } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { Select } from 'components'
import { Card } from 'components/Card'
import { ALL_FORM_TEMPLATES_SHALLOW_QUERY } from 'modules/formcreator/queries'
import {
  AllFormTemplatesShallowQuery,
  AllFormTemplatesShallowQueryVariables,
} from 'modules/formcreator/types.graphql'
import React from 'react'
import { useQuery } from '@apollo/client'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'

const Wrapper = styled(Card)`
  margin-bottom: 1rem;
  padding: 2rem;

  .--select-display-selected-clear {
    width: 28px;
    background-color: ${props => props.theme.colors.quaternary};

    &:hover {
      background-color: ${props => props.theme.colors.secondary};

      i {
        color: white;
      }
    }
  }

  ${props => props.theme.media.mobile} {
    padding: 1rem;
  }
`

interface SelectHandbookFormProps {
  value: string | null
  onChange: (value: string | null) => void
}

export const SelectHandbookForm: React.VFC<SelectHandbookFormProps> = ({
  value,
  onChange,
}) => {
  const translations = useTranslate({
    selectForm: 'form.select-form',
  })

  const { data, loading } = useQuery<
    AllFormTemplatesShallowQuery,
    AllFormTemplatesShallowQueryVariables
  >(ALL_FORM_TEMPLATES_SHALLOW_QUERY, {
    variables: {
      excludeArchived: true,
    },
  })

  const options: SelectOption<string, never>[] =
    data?.allFormTemplates.edges.map(({ node }) => ({
      value: node.id,
      label: node.name,
    })) ?? []

  return (
    <Wrapper>
      <Select
        value={value}
        options={options}
        loading={loading}
        nullable
        grayed
        fullWidth={isMobileOnly}
        placeholder={translations.selectForm}
        onChange={onChange}
      />
    </Wrapper>
  )
}
