import { PromptResolve } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { RegularModal } from 'components'
import { Time } from 'lib/time'
import { TimeEntryFormValues } from 'modules/timesheets/types'
import React from 'react'
import { useTimeEntryForm } from '../hooks'
import { TimeEntryForm } from './TimeEntryForm'
import { TimeEntryNode, TimesheetsReportSalaryWorkTimeNode } from '../types.graphql'

interface EditTimeEntryModalProps {
  entry: Omit<TimeEntryNode, 'company'> | null
  onResolve: PromptResolve<TimeEntryFormValues | null>
  salaryWorkTime: TimesheetsReportSalaryWorkTimeNode
  overtimeCustomChoice: boolean
  existingTimeEntries: Omit<TimeEntryNode, 'company'>[]
}

export interface EditTimeEntryFormData {
  dateStart: Date
  timeStart: Time
  dateEnd: Date
  timeEnd: Time
}

export const EditTimeEntryModal: React.VFC<EditTimeEntryModalProps> = ({
  entry,
  salaryWorkTime,
  onResolve,
  overtimeCustomChoice,
  existingTimeEntries,
}) => {
  const translations = useTranslate({
    editTimeEntry: 'timesheets.edit-time-entry',
    from: 'common.from',
    until: 'common.until',

    cancel: 'common.cancel',
    submit: 'common.submit',
    comment: 'common.comment',

    error: {
      invalid: 'error.invalid-value',
      startBeforeEnd: 'timesheets.error.start-before-end',
      required: 'common.required',
    },
  })

  const form = useTimeEntryForm(entry, salaryWorkTime)

  const customOvertimeForm = {
    ...form,
    dayOvertimeLimit: salaryWorkTime.dailyWorkLimit,
  }

  const overtimeOptionActive =
    overtimeCustomChoice &&
    (form.formValues.end.getTime() - form.formValues.start.getTime()) / 1000 >
      salaryWorkTime.dailyWorkLimit

  const formValid =
    form.formValid &&
    ((overtimeOptionActive && !!form.formValues.salaryCodeId) ||
      !overtimeOptionActive ||
      salaryWorkTime.usePeriodOvertime)

  return (
    <RegularModal
      title={translations.editTimeEntry}
      submitText={translations.submit}
      width="600px"
      onCancel={() => onResolve(null)}
      onSubmit={() => onResolve(form.formValues)}
      submitDisabled={!formValid}
      cancelOnClickOutside
      overflowY='auto'
    >
      <TimeEntryForm
        onSubmit={onResolve}
        form={customOvertimeForm}
        overtimeCustomChoice={overtimeCustomChoice}
        salaryWorkTime={salaryWorkTime}
        existingTimeEntries={existingTimeEntries}
      />
    </RegularModal>
  )
}
