import { PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { NumberInput, RegularModal, TextArea } from 'components'
import { Label } from 'components/Label'
import { CloseIssueForm } from 'modules/issues/types'
import React from 'react'
import styled from 'styled-components'

const Form = styled.div`
  display: flex;
  flex-direction: column;
`
const Field = styled.div`
  display: flex;
  flex-direction: column;

  & + div {
    margin-top: 1rem;
  }
`

interface CloseIssuePromptProps {
  initialData?: CloseIssueForm

  onResolve: PromptResolve<CloseIssueForm | null>
}

export const CloseIssuePrompt: React.VFC<CloseIssuePromptProps> = ({
  initialData = {
    solution: '',
    actualCost: null,
  },

  onResolve,
}) => {
  const translations = useTranslate({
    closeIssue: 'issues.close-issue',
    solution: 'issues.final-solution',
    actualCost: 'issues.actual-cost',

    cancel: 'common.cancel',
    submit: 'common.submit',

    error: {
      solutionRequired: 'issues.errors.must-enter-solution',
      mustBeWholeNumber: 'errors.must-be-whole-number',
      mustEnterActualCost: 'issues.errors.must-enter-actual-cost',
    },
  })

  const initialDataIsEmpty =
    initialData.solution === '' && initialData.actualCost === null

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formChangeHandler: handler,
    submitHandler,
  } = useForm<CloseIssueForm>({
    values: initialData,
    validators: {
      solution: val =>
        !!val.trim() ? null : translations.error.solutionRequired,
    },
    config: {
      initAsInvalid: initialDataIsEmpty,
    },
  })

  return (
    <RegularModal
      title={translations.closeIssue}
      width="600px"
      cancelText={translations.cancel}
      submitText={translations.submit}
      submitDisabled={!formValid}
      onCancel={() => onResolve(null)}
      onSubmit={submitHandler(onResolve)}
    >
      <Form>
        <Field>
          <Label>{translations.solution}</Label>
          <TextArea
            value={form.solution}
            error={errors.solution}
            fullWidth
            autoFocus
            onChange={handler('solution')}
          />
        </Field>
        <Field>
          <Label>{translations.actualCost}</Label>
          <NumberInput
            value={form.actualCost ?? 0}
            error={errors.actualCost}
            fullWidth
            onChange={handler('actualCost')}
          />
        </Field>
      </Form>
    </RegularModal>
  )
}
