import { gql } from '@apollo/client'

export const ALL_ROUTE_PLANS_QUERY = gql`
  query AllRoutePlans($first: Int, $after: String, $q: String) {
    allRoutePlans(first: $first, after: $after, q: $q) {
      edges {
        node {
          user {
            id
            fullName
          }

          id
          taskStatus
          result
          createdAt

          startLocationName
          endLocationName
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`

export const ROUTE_PLAN_QUERY = gql`
  query RoutePlan($id: ID!) {
    routePlan(id: $id) {
      user {
        id
        fullName
      }
      company {
        id
        fullName
      }

      id
      createdAt

      startLocationName
      startCoordinates {
        lat
        lng
      }

      endLocationName
      endCoordinates {
        lat
        lng
      }
      taskStatus
      result
      routeLength
      estimatedDuration

      routeRestriction {
        width
        height
        length

        topSpeed
        payloadWeight
        trailerWeight

        axleWeightRestriction
        singleAxleWeightRestriction
        tandemAxleWeightRestriction
        tripleAxleWeightRestriction
      }
    }
  }
`

export const ROUTE_PLAN_ROUTES_QUERY = gql`
  query RoutePlanPoints($id: ID!) {
    routePlan(id: $id) {
      id
      incidents {
        id
        firstCoordinates {
          lat
          lng
        }
        datetimeStart
        datetimeEnd
        summary
        type
      }
      notices {
        id
        coordinates {
          lat
          lng
        }

        details {
          type
          cause
          description
          maxLength
          maxHeight
          timeDependent
          maxWeight
          maxWidth
          axleCount
          tunnelCategory
          vehicleType
          trailerCountDescription
          trailerCountFrom
          trailerCountTo
        }
      }
      points {
        id
        lat
        lng
      }
    }
  }
`

export const ROUTE_PLAN_INSTRUCTIONS_QUERY = gql`
  query RoutePlanInstructionsQuery($routePlanId: ID!) {
    routePlanInstructions(routePlanId: $routePlanId) {
      id
      action
      duration
      length
      instruction
      offset
      direction
      order
      severity
      exit
    }
  }
`
