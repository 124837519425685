import { usePrompt } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { TableMenuItem } from 'components/Table'
import {
  Module,
  ModuleOptionsValue,
} from 'modules/companies'
import { Dispatch, SetStateAction, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import {
  useAdmin,
  useConfirm,
  useModuleConfig,
  useModuleResponsible,
  usePermissions,
} from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { useTimeEntryTableHooks } from '../mutations.hooks'
import {
  EditAbsenceForm,
  EditAbsenceStatusForm,
  TimeEntryFormValues,
} from '../types'
import { AbsenceNode, AbsenceTypeNode, TimeEntryNode, TimesheetsDayReport, TimesheetsReportSalaryWorkTimeNode } from '../types.graphql'
import { EditAbsenceModal } from './EditAbsenceModal'
import { EditAbsenceStatusModal } from './EditAbsenceStatusModal'
import { EditTimeEntryModal } from './EditTimeEntryModal'
import { ViewCommentModal } from './ViewCommentModal'
import { getFullMinutes } from 'util/time'

export type Temp =
  | Omit<TimeEntryNode, 'company'>
  | (Omit<AbsenceNode, 'company'> & {
      absenceType?: Pick<AbsenceTypeNode, 'id' | 'name'> | undefined
    })

export function useTimesheetHandlers(salaryWorkTime: TimesheetsReportSalaryWorkTimeNode) {
  const confirm = useConfirm()
  const addPrompt = usePrompt()
  const [entryLoading, setEntryLoading] = useState<string | null>(null)
  const translations = useTranslate({
    editTimeEntry: 'timesheets.edit-time-entry',
    deleteTimeEntry: 'timesheets.delete-time-entry',
    editAbsence: 'timesheets.edit-absence',
    editAbsenceStatus: 'timesheets.edit-absence-status',
    deleteAbsence: 'timesheets.delete-absence',
    viewComment: 'common.view-comment',
    noComment: 'common.no-comment',
    prompt: {
      deleteTimeEntry: 'timesheets.prompt.delete-time-entry',
      deleteTimeEntryTitle: 'timesheets.prompt.delete-time-entry-title',
      deleteAbsence: 'timesheets.prompt.delete-absence',
      deleteAbsenceTitle: 'timesheets.prompt.delete-absence-title',
    },
  })
  const admin = useAdmin()

  const { hasPermissionsAndMe } = usePermissions()

  const canEdit = (id: string, type: 'timeentry' | 'absence') =>
    admin || hasPermissionsAndMe({ id }, PERMISSIONS.timesheets.change[type])
  const  canDelete = (id: string, type: 'timeentry' | 'absence') =>
    admin || hasPermissionsAndMe({ id }, PERMISSIONS.timesheets.delete[type])

  const {
    patchTimeEntry,
    deleteTimeEntry,
    loading: mutationLoading,
  } = useTimeEntryTableHooks()

  async function handleViewComment(comment: string) {
    await addPrompt<boolean | null>(resolve => (
      <ViewCommentModal onClose={resolve} comment={comment} />
    ))
  }
  const { getModuleOptionStatus } = useModuleConfig(
    Module.TIMESHEETS
  )
  const overtimeCustomChoice = getModuleOptionStatus(
    ModuleOptionsValue.TIMESHEETS_OVERTIME_OPTIONS
  )

  /* Time Entry Handlers */
  const handleEditTimeEntry = async (
    entry: Omit<TimeEntryNode, 'company'>,
    modalOpenCallback?: Dispatch<SetStateAction<boolean>>,
    existingTimeEntries: Omit<TimeEntryNode, 'company'>[] = []
  ) => {
    modalOpenCallback?.(true)
    const { data } = await addPrompt<TimeEntryFormValues | null>(resolve => (
      <EditTimeEntryModal
        entry={entry}
        onResolve={resolve}
        salaryWorkTime={salaryWorkTime}
        overtimeCustomChoice={overtimeCustomChoice}
        existingTimeEntries={existingTimeEntries}
      />
    ))
    modalOpenCallback?.(false)
    if (!data) return

    try {
      setEntryLoading(entry.id)
      await patchTimeEntry({
        variables: {
          id: entry.id,
          input: {
            datetimeStart: data.start,
            datetimeEnd: data.end,
            project: data.project,
            comment: data.comment,
            salaryCode: data.salaryCodeId,
            diet: data.diet,

            department: data.department,
            pause: getFullMinutes(data.pause),
          },
        },
      })
    } finally {
      setEntryLoading(null)
    }
  }

  /* Shared Handlers */
  const handleDeleteTimeEntry = async (
    entry: Omit<TimeEntryNode, 'company'>,
    modalOpenCallback?: Dispatch<SetStateAction<boolean>>
  ) => {
    const promptMessage =translations.prompt.deleteTimeEntry
    const promptTitle = translations.prompt.deleteTimeEntryTitle

    modalOpenCallback?.(true)
    const { data: answer } = await confirm(promptMessage, promptTitle)
    modalOpenCallback?.(false)
    if (!answer) return

    try {
      setEntryLoading(entry.id)
      await deleteTimeEntry({
        variables: { id: entry.id },
      })
    } finally {
      setEntryLoading(null)
    }
  }



  function createTimesheetDayReportMenuItems(dayReport: TimesheetsDayReport) {
    if ((dayReport.timeEntries.length + dayReport.absences.length) > 1 || dayReport.absences.length === 1 || (dayReport.timeEntries.length + dayReport.absences.length) === 0) return []
    const isAbsence = dayReport.timeEntries.length === 0 && dayReport.absences.length === 1

    if (isAbsence) return []
    const items: TableMenuItem<undefined>[] = []

    const canViewComment = (entry: Omit<TimeEntryNode, 'company'>) =>
      entry.comment === '' || entry.comment === translations.noComment
    
    
    const entry = dayReport.timeEntries[0]
    if (canEdit(entry.user.id, 'timeentry')) {
      items.push({
        label: translations.editTimeEntry,
        role: 'link',
        onClick: () => handleEditTimeEntry(entry),
      })
    }
    if (canViewComment(entry) && isMobileOnly) {
      items.push({
        label: translations.viewComment,
        onClick: () => handleViewComment(entry.comment),
      })
    }
    if (canDelete(entry.user.id, 'timeentry')) {
      items.push({
        label: translations.deleteTimeEntry,
        onClick: () => handleDeleteTimeEntry(entry),
      })
    }
    
    return items
  }

  function createTimesheetTimeEntryMenuItems(entry: Omit<TimeEntryNode, 'company'>, existingTimeEntries: Omit<TimeEntryNode, 'company'>[] = []) {
    const items: TableMenuItem<undefined>[] = []

    const canViewComment = (entry: Omit<TimeEntryNode, 'company'>) =>
      entry.comment === '' || entry.comment === translations.noComment
    
    if (canEdit(entry.user.id, 'timeentry')) {
      items.push({
        label: translations.editTimeEntry,
        role: 'link',
        onClick: () => handleEditTimeEntry(entry, undefined, existingTimeEntries),
      })
    }
    if (canViewComment(entry) && isMobileOnly) {
      items.push({
        label: translations.viewComment,
        onClick: () => handleViewComment(entry.comment),
      })
    }
    if (canDelete(entry.user.id, 'timeentry')) {
      items.push({
        label: translations.deleteTimeEntry,
        onClick: () => handleDeleteTimeEntry(entry),
      })
    }
    return items
  }

  return {
    timeEntryHandler: {
      edit: handleEditTimeEntry,
      createTimesheetTimeEntryMenuItems
    },
    timesheetHandler: {
      createTimesheetDayReportMenuItems,
      delete: handleDeleteTimeEntry,
      viewComment: handleViewComment,
    },
    entryLoading,
    mutationLoading,
  }
}


export const useAbsenceHandler = () => {
  const confirm = useConfirm()
  const addPrompt = usePrompt()
  const translations = useTranslate({
    editAbsence: 'timesheets.edit-absence',
    editAbsenceStatus: 'timesheets.edit-absence-status',
    deleteAbsence: 'timesheets.delete-absence',
    prompt: {
      deleteAbsence: 'timesheets.prompt.delete-absence',
      deleteAbsenceTitle: 'timesheets.prompt.delete-absence-title',
    },
  })
  const admin = useAdmin()
  const { isResponsibleUser } = useModuleResponsible(Module.ABSENCES)

  const { hasPermissionsAndMe } = usePermissions()

  const canEdit = (id: string, type: 'timeentry' | 'absence') =>
    admin || hasPermissionsAndMe({ id }, PERMISSIONS.timesheets.change[type])
  const  canDelete = (id: string, type: 'timeentry' | 'absence') =>
    admin || hasPermissionsAndMe({ id }, PERMISSIONS.timesheets.delete[type])
  const canEditAbsenceStatus = admin || isResponsibleUser

  const {
    patchAbsence,
    deleteAbsence,
    loading: mutationLoading,
  } = useTimeEntryTableHooks()

    const handleEditAbsence = async (
      absence: Omit<AbsenceNode, 'company'>,
      modalOpenCallback?: Dispatch<SetStateAction<boolean>>
    ) => {
      modalOpenCallback?.(true)
      const { data } = await addPrompt<EditAbsenceForm | null>(resolve => (
        <EditAbsenceModal absence={absence} onResolve={resolve} />
      ))
      modalOpenCallback?.(false)
      if (!data) return
  
      await patchAbsence({
        variables: {
          id: absence.id,
          input: {
            datetimeStart: data.start,
            datetimeEnd: data.end,
            reason: data.reason,
            absenceType: data.absenceType,
          },
        },
      })
    }


  function createAbsenceMenuItems(absence: Omit<AbsenceNode, 'company'>, absenceView: boolean = true) {
    const items: TableMenuItem<undefined>[] = []
    if (canEditAbsenceStatus) {
      if (canEdit(absence.user.id, 'absence')) {
        if (absenceView) {
          items.push({
            label: translations.editAbsenceStatus,
            role: 'link',
            onClick: () => handleEditAbsenceStatus(absence),
          })
        }
        items.push({
          label: translations.editAbsence,
          role: 'link',
          onClick: () => handleEditAbsence(absence),
        })
      }
      if (canDelete(absence.user.id, 'absence')) {
        items.push({
          label: translations.deleteAbsence,
          onClick: () => handleDeleteAbsence(absence),
        })
      }
    }
    return items
  }

  const handleDeleteAbsence = async (
    entry: Omit<AbsenceNode, 'company'>,
    modalOpenCallback?: Dispatch<SetStateAction<boolean>>
  ) => {
    const promptMessage = translations.prompt.deleteAbsence
    const promptTitle = translations.prompt.deleteAbsenceTitle

    modalOpenCallback?.(true)
    const { data: answer } = await confirm(promptMessage, promptTitle)
    modalOpenCallback?.(false)
    if (!answer) return
    await deleteAbsence({
      variables: { id: entry.id },
    })
  }

  const handleEditAbsenceStatus = async (
    absence: Omit<AbsenceNode, 'company'>,
    modalOpenCallback?: Dispatch<SetStateAction<boolean>>
  ) => {
    modalOpenCallback?.(true)
    const { data } = await addPrompt<EditAbsenceStatusForm | null>(resolve => (
      <EditAbsenceStatusModal absence={absence} onSubmit={resolve} />
    ))
    modalOpenCallback?.(false)
    if (!data) return

    await patchAbsence({
      variables: {
        id: absence.id,
        input: data,
      },
    })
  }


  return {
    edit: handleEditAbsence,
    editStatus: handleEditAbsenceStatus,
    delete: handleDeleteAbsence,
    createAbsenceMenuItems,
    mutationLoading
  }
}