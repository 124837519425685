import { gql } from '@apollo/client'
import { DocumentNode } from 'graphql/language/ast'

interface FormCreatorFragmentsCollection {
  formTemplateInfo: DocumentNode
  formTemplateFieldInfo: DocumentNode
  filledOutFormInfo: DocumentNode
  filledOutFormValueInfo: DocumentNode
  formTemplate?: DocumentNode
  formTemplateFields?: DocumentNode
}

export const FormCreatorFragments: FormCreatorFragmentsCollection = {
  formTemplateInfo: gql`
    fragment FormTemplateInfo on FormTemplateNode {
      id
      name
      ingress
      groups
      maxNumberOfUses
      filledOutFormsCount
      createdAt
      updatedAt
      archived
    }
  `,
  formTemplateFieldInfo: gql`
    fragment FormTemplateFieldInfo on FormTemplateFieldNode {
      id
      name
      label
      description
      fieldType
      order
      required
      createdAt
      updatedAt
    }
  `,
  filledOutFormInfo: gql`
    fragment FilledOutForm on FilledOutFormNode {
      id
      filledBy {
        id
      }
      verifiedBy {
        id
      }
      createdAt
      updatedAt
      verifiedAt
    }
  `,
  filledOutFormValueInfo: gql`
    fragment FilledOutFormValue on FilledOutFormValueNode {
      id
      value
      attachmentFile
      createdAt
      updatedAt
    }
  `,
}

FormCreatorFragments.formTemplate = gql`
  fragment FormTemplate on FormTemplateNode {
    ...FormTemplateInfo
  }
  ${FormCreatorFragments.formTemplateInfo}
`

FormCreatorFragments.formTemplateFields = gql`
  fragment FormTemplateFields on FormTemplateNode {
    formFields {
      edges {
        node {
          ...FormTemplateFieldInfo
        }
      }
    }
  }
  ${FormCreatorFragments.formTemplateFieldInfo}
`
