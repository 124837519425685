import { useTranslate } from '@ur/react-hooks'
import {
  Checkbox,
  DatePicker,
  NumberInput,
  TextArea,
  UserSelect,
} from 'components'
import {
  VehicleSelect
} from 'components/Select'
import { startOfDay } from 'date-fns'
import { FormTemplateFieldNode } from 'modules/formcreator/types.graphql'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { isInvalidDate } from 'util/time'

const Wrapper = styled.section`
  width: 100%;

  h4 {
    margin: 1rem 0 6px 0;

    span {
      margin-left: 0.5ch;
      color: ${props => props.theme.colors.red};
    }
  }
  p {
    margin: 0 0 6px 0;
    color: ${props => props.theme.colors.gray3};
  }
`
const Field = styled.div`
  div.date {
    display: flex;

    .no-date {
      margin-left: 1rem;
    }
  }
`

interface FormFieldProps {
  field: FormTemplateFieldNode
  value: string
  onChange: (value: string) => void
}

export const FormField: React.VFC<FormFieldProps> = ({
  field,
  value,
  onChange,
}) => {
  const translations = useTranslate({
    none: 'common.none',
  })

  const [temporaryDate, setTemporaryDate] = useState<string | null>(null)

  const renderField = useMemo(() => {
    function toggleDate(disabled: boolean, date: Date) {
      setTemporaryDate(disabled ? value : null)
      onChange(disabled ? '' : date.toUTCString())
    }

    switch (field.fieldType) {
      case 'TEXT':
        return <TextArea value={value} fullWidth onChange={onChange} />
      case 'NUMBER':
        return (
          <NumberInput
            value={Number(value)}
            fullWidth
            min={field.minimum ?? undefined}
            max={field.maximum ?? undefined}
            onChange={value => onChange(value + '')}
          />
        )
      case 'DATE':
        const date = isInvalidDate(
          startOfDay(new Date(temporaryDate ?? value)),
          startOfDay(new Date())
        )

        return (
          <div className="date">
            <DatePicker
              value={date}
              fullWidth
              disabled={!field.required && value === ''}
              onChange={value => onChange(value.toUTCString())}
            />

            {!field.required && (
              <div className="no-date">
                <Checkbox
                  checked={value === ''}
                  label={translations.none}
                  labelPosition="left"
                  wrapped
                  onChange={checked => toggleDate(checked, date)}
                />
              </div>
            )}
          </div>
        )
      case 'USER':
        return (
          <UserSelect
            value={value || null}
            grayed
            fullWidth
            onChange={value => onChange(value ?? '')}
          />
        )
      case 'CHECKBOX':
        return (
          <Checkbox
            checked={value === 'true'}
            label={field.description}
            onChange={value => onChange(value.toString())}
          />
        )
      case 'VEHICLE_DEPARTMENT':
        return (
          <VehicleSelect
            onVehicleSelectedCallback={(vehicle) => onChange(vehicle.id)}
          />
        )
    }
  }, [
    field.fieldType,
    field.minimum,
    field.maximum,
    field.required,
    field.description,
    value,
    onChange,
    temporaryDate,
    translations.none,
  ])

  return (
    <Wrapper>
      <h4>
        {field.name}
        {field.required && <span>*</span>}
      </h4>
      {field.fieldType !== 'CHECKBOX' && <p>{field.description}</p>}

      <Field>{renderField}</Field>
    </Wrapper>
  )
}
