import React, { useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
import { SelectMultipleProps as BaseSelectMultipleProps } from '@ur/react-components'
import {
  ALL_DEPARTMENTS_QUERY,
  AllDepartmentsQuery,
  AllDepartmentsQueryVariables,
  DepartmentNode,
} from 'modules/companies'
import { useQuery } from '@apollo/client'
import { useDebounce } from 'util/hooks'
import { SelectMultiple, SelectMultipleOnSelectAll } from './SelectMultiple'
import { useTranslate } from '@ur/react-hooks'
import { ZIndexRange } from 'types/style'
import { DropdownNames } from './types'

const Department = styled.div`
  display: flex;
  align-items: center;

  span.name {
    margin-left: 0.6rem;
  }
`

interface SelectMulitpleRelativeWrapperProps {
  width: string
}

const SelectMultipleRelativeWrapper = styled.div<SelectMulitpleRelativeWrapperProps>`
  position: relative;
  width: ${props => props.width};
`

interface SelectMulitpleWrapperProps {
  open: boolean
  numSelectedText: string
  compact: boolean
  width: string
}

const SelectMulitpleWrapper = styled.div<SelectMulitpleWrapperProps>`
  width: ${props => props.width};
  ${props =>
    !props.open
      ? css`
          ${props.compact &&
          `
          .--select-display-inner > :not(:first-child), .--select-display-selected > i, .--select-display-selected span {
            display: none;
          }
          .--select-display-selected :after {
            content: '${props.numSelectedText}';
          }
        `}
        `
      : `
      position: absolute;
      z-index: ${ZIndexRange.Dropdowns};
    `}
`

export interface SelectMultipleProps
  extends BaseSelectMultipleProps<string, any> {
  compact?: boolean
  grayed?: boolean
  dropdownPageName?: DropdownNames
  withSelectAll?: boolean
  onSelectAll?: SelectMultipleOnSelectAll
  dataset?: DepartmentNode[]
}
export interface SelectMultipleOptions {
  value: string
  label: JSX.Element
}
export const SelectMultipleDepartments: React.VFC<
  Omit<SelectMultipleProps, 'options'>
> = ({
  selected,
  onChange,
  dataset=[],
  compact = true,
  dropdownPageName = DropdownNames.TIME_ENTRIES,
  width = '300px',
  grayed = true,
  ...selectMultipleProps
}) => {
  const [open, setOpen] = useState(false)
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query)

  const translations = useTranslate({
    nDepartments: ['settings.n-departments', { n: 0 }],
  })

  const { data } = useQuery<
    AllDepartmentsQuery,
    AllDepartmentsQueryVariables
  >(ALL_DEPARTMENTS_QUERY, {
    variables: {
      q: debouncedQuery,
      orderBy: 'lastName',
      dropdown: dropdownPageName,
    },
    fetchPolicy: 'cache-and-network',
  })

  const getLabel = useCallback((department: DepartmentNode) => {

    return (
      <Department>
        <span className="name">{department.name}</span>
      </Department>
    )
  }, [])

  const options = 
    data?.allDepartments.edges.map(({ node: department }) => ({
      value: department.id,
      label: getLabel(department),
    })) ?? []

  return (
    <SelectMultipleRelativeWrapper width={width}>
      <SelectMulitpleWrapper
        open={open}
        numSelectedText={translations.nDepartments({ n: selected.length })}
        compact={compact}
        width={width}
      >
        <SelectMultiple
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          selected={selected}
          options={options}
          onChange={onChange}
          searchable={'custom'}
          onFilterChange={setQuery}
          fullWidth
          grayed={grayed}
          {...selectMultipleProps}
        />
      </SelectMulitpleWrapper>
    </SelectMultipleRelativeWrapper>
  )
}
