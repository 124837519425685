import traph from 'react-traph'

export const SidebarGraph = traph({
  open: false,
  toggle(value?: boolean) {
    this.updateGraph({
      open: value ?? !this.open,
    })
  },
})
