import styled from 'styled-components'
import { enforceHourMinuteString } from '../../util'
import { overloadColor } from 'util/style'
import { Icon, Loader } from '@ur/react-components'
import { SVGIcon } from 'components'
import { format } from 'util/date-fns'
import { useTranslate } from '@ur/react-hooks'
import {
  DriverActivityReport,
} from 'modules/activities/types.graphql'
import { AllActivitiesSumTable } from './AllActivitiesSumTable'

const Summaries = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem 1.5rem;

  ${props => props.theme.media.custom({ min: '769px', max: '1500px' })} {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem 1.5rem;
  }

  h2 {
    grid-column: 1 / -1;
    display: flex;

    margin: 1rem 0 0 8px;
    font-weight: 600;

    div {
      margin-left: 1rem;
    }
  }

  ${props => props.theme.media.mobile} {
    grid-template-columns: 1fr;
  }
`
interface SummaryProps {
  color: string
}
const Summary = styled.div<SummaryProps>`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 2rem;
  padding: 2rem;

  cursor: default;

  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  background-color: white;
  box-shadow: ${props => props.theme.shadow.default};

  div.icon {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 6rem;
    height: 6rem;

    border-radius: 20px;
    background: ${props => overloadColor(props.color)};

    svg {
      width: 60%;
    }
    i {
      font-size: 3rem;
      font-weight: 100;
    }
  }
  div.info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
      font-weight: 600;

      &.label {
        color: ${props => props.theme.colors.gray4};
      }
      &.time {
        font-size: 2rem;
      }
    }
  }

  ${props => props.theme.media.custom({ min: '800px', max: '1500px' })} {
    gap: 1.5rem;
    padding: 1.5rem;

    div.icon {
      width: 5rem;
      height: 5rem;

      border-radius: 16px;

      i {
        font-size: 2.5rem;
      }
    }
  }
  ${props => props.theme.media.mobile} {
    gap: 1rem;
    padding: 1rem;

    div.icon {
      width: 4rem;
      height: 4rem;

      border-radius: 12px;

      i {
        font-size: 2rem;
      }
    }
  }
`

interface ActivitiesMonthSummariesProps {
  month: Date
  report?: DriverActivityReport
  monthReport?: DriverActivityReport
  loading?: boolean
  showOvertime?: boolean
}

export const ActivitiesMonthSummaries: React.FC<ActivitiesMonthSummariesProps> =
  ({ month, report, monthReport, loading, showOvertime }) => {
    const translations = useTranslate({
      sumForMonth: ['activities.sum-for-month-title', { month: '' }],
      driving: 'activities.driving',
      otherWork: 'common.other-work',
      overtime: 'activities.sum-overtime',
      available: 'activities.availability-time',
    })


    return (
      <>
      <Summaries>
        <h2>
          {translations.sumForMonth({ month: format(month, 'MMMM') })}

          {loading && <Loader.Spinner size={24} />}
        </h2>

        <Summary color="#cbede4">
          <div className="icon">
            <SVGIcon icon="truck" />
          </div>
          <div className="info">
            <span className="label">{translations.driving}</span>
            <span className="time">
              {enforceHourMinuteString(monthReport?.timeDriving) ?? '-'}
            </span>
          </div>
        </Summary>

        <Summary color="#c4cbe6">
          <div className="icon">
            {/* TODO not correct icon */}
            <Icon icon="briefcase" fixedWidth />
          </div>
          <div className="info">
            <span className="label">{translations.otherWork}</span>
            <span className="time">
              {enforceHourMinuteString(monthReport?.timeOtherWork) ?? '-'}
            </span>
          </div>
        </Summary>

        {showOvertime && (
          <Summary color="#fabbce">
            <div className="icon">
              <SVGIcon icon="clock" />
            </div>
            <div className="info">
              <span className="label">{translations.overtime}</span>
              <span className="time">
                {enforceHourMinuteString(report?.overtime) ?? enforceHourMinuteString(monthReport?.dayOvertime) ?? '-'}
              </span>
            </div>
          </Summary>
        )}
        

        <Summary color="#fde6ba">
          <div className="icon">
            {/* TODO not correct icon */}
            <Icon icon="hand-holding" fixedWidth translateY="-0.8rem" />
          </div>
          <div className="info">
            <span className="label">{translations.available}</span>
            <span className="time">
              {enforceHourMinuteString(monthReport?.timeAvailability) ?? '-'}
            </span>
          </div>
        </Summary>
      </Summaries>
      <AllActivitiesSumTable report={monthReport}/>
      </>
    )
  }
