import { Icon, usePrompt } from "@ur/react-components"
import { useSalaryCodeMutations } from "./hooks"
import { AllSalaryCodesQuery, AllSalaryCodesQueryVariables, SalaryCodeNode } from "modules/companies/types.graphql"
import { useTranslate } from "@ur/react-hooks"
import { AddButton, Header, Section, SectionHeader } from "../common"
import { ALL_SALARY_CODES_QUERY } from "modules/companies/queries"
import { useQuery } from "@apollo/client"
import { useConfirm } from "util/hooks"
import { Table } from "components"
import { SalaryCodePrompt } from "./SalaryCodePrompt"


export interface SalaryCodeForm {
    externalId: string
    name: string
    isDiet: boolean
    showInManualTimeEntryOvertime: boolean
}

  
export const SalaryCodeSettings:React.FC = () => {

  const translations = useTranslate({
    prompt: {
      delete: ['settings.prompts.delete', { name: '' }],
      deleteTitle: 'settings.prompts.salary-code-delete-title',
    },
    salaryCodes: 'common.salary-codes',
    salaryCode: 'settings.salary-type',
    name: 'common.name',
  })

  const addPrompt = usePrompt()
  const confirm = useConfirm()
  const mutations = useSalaryCodeMutations()

  const { data } = useQuery<AllSalaryCodesQuery, AllSalaryCodesQueryVariables>(
    ALL_SALARY_CODES_QUERY,
    {
      variables: {
        showInManualTimeEntryOvertime: false,
      },
    }
  )

  const salaryCodes = data?.allSalaryCodes.edges?.map(edge => edge.node) ?? []

  async function handleCreateSalaryCode() {
    const { data } = await addPrompt<SalaryCodeForm | null>(resolve => (
      <SalaryCodePrompt
        existingSalaryCodes={salaryCodes}
        onSubmit={resolve}
      />
    ))
    if (data === null) return

    mutations.createSalaryCode({
      variables: {
        input: data,
      },
    })
  }

  async function handlePatchSalaryCode(salaryCode: SalaryCodeNode) {
    const { data } = await addPrompt<SalaryCodeForm | null>(resolve => (
      <SalaryCodePrompt
        initialData={salaryCode}
        existingSalaryCodes={salaryCodes}
        onSubmit={resolve}
      />
    ))
    if (data === null) return

    mutations.patchSalaryCode({
      variables: {
        id: salaryCode.id,
        input: {
          externalId: data.externalId,
          name: data.name,
          isDiet: data.isDiet,
          showInManualTimeEntryOvertime: data.showInManualTimeEntryOvertime,
        }
      },
    })
  }

  async function handleDeleteSalaryCode(
    salaryCode: SalaryCodeNode
  ) {
    const { data: answer } = await confirm(
      translations.prompt.delete({ name: salaryCode.name }),
      translations.prompt.deleteTitle
    )
    if (!answer) return

    mutations.deleteSalaryCode({
      variables: {
        id: salaryCode.id,
      },
    })
  }

  return (
    <Section>
      <Header>
        <SectionHeader>{translations.salaryCodes}</SectionHeader>
        <AddButton adding={false} onClick={handleCreateSalaryCode} />
      </Header>

      <Table
        noShadow
        noBorders
        noHeaderMargin
        noRowMargin
        rowHoverColor="secondary"
      >
        <thead>
          <tr>
            <th>{translations.name}</th>
            <th colSpan={2}>{translations.salaryCode}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {salaryCodes.map(salaryCode => (
            <tr key={salaryCode.id}>
              <td>{salaryCode.name}</td>

              <td>{salaryCode.externalId}</td>

              <td width="1px">
                <Icon
                  icon="edit"
                  cursor="pointer"
                  color="gray6"
                  hoverColor="secondary"
                  onClick={() => handlePatchSalaryCode(salaryCode)}
                />
              </td>

              <td width="1px">
                <Icon
                  icon="times"
                  cursor="pointer"
                  color="gray6"
                  hoverColor="red"
                  onClick={() => handleDeleteSalaryCode(salaryCode)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      
    </Section>
  )
}