import traph from 'react-traph'

interface Search {
  [x: string]: any
}
interface SearchTraph {
  search: Search
  addSearchEntry: (name: string, data: any) => void
  updateSearchEntry: (name: string, key: string, value: string) => void
  getSearchEntry: (name: string, initialData: any) => string
}
const SearchGraph = traph<SearchTraph>({
  search: {},
  addSearchEntry(name, data) {
    this.updateGraph({
      search: {
        [name]: data || {},
      },
    })
  },
  updateSearchEntry(name, key, value) {
    this.updateGraph({
      search: {
        ...this.search,
        [name]: {
          ...(this.search[name] || {}),
          [key]: value,
        },
      },
    })
  },
  getSearchEntry(name, initialData) {
    if (!this.search[name]) {
      this.search[name] = initialData
      return initialData
    }
    return this.search[name]
  },
})

export default SearchGraph
