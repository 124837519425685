import { Icon } from '@ur/react-components'
import React, { useState } from 'react'
import styled from 'styled-components'
import { BaseProps } from 'types/props'

const Wrapper = styled.div`
  padding: 1rem !important;
  border: 0 !important;

  svg {
    fill: ${props => props.theme.colors.gray4} !important;

    * {
      fill: ${props => props.theme.colors.gray4} !important;
    }
  }
  i {
    color: ${props => props.theme.colors.gray4};
  }
  .stroke svg {
    stroke: ${props => props.theme.colors.gray4};

    * {
      stroke: ${props => props.theme.colors.gray4};
    }
  }
  .ql-active {
    svg {
      fill: ${props => props.theme.colors.secondary};

      * {
        fill: ${props => props.theme.colors.secondary} !important;
      }
    }
    i {
      color: ${props => props.theme.colors.secondary};
    }

    &.stroke svg {
      stroke: ${props => props.theme.colors.secondary};

      * {
        stroke: ${props => props.theme.colors.secondary};
      }
    }
  }
`
interface ToolbarGroupProps {
  hidden?: boolean
}
const ToolbarGroup = styled.span.attrs({
  className: 'ql-formats',
})<ToolbarGroupProps>`
  display: ${props => props.hidden && 'none'} !important;
`
interface ToolbarElementProps {
  hidden?: boolean
}
const ToolbarElement = styled.button<ToolbarElementProps>`
  display: ${props => props.hidden && 'none'} !important;
`

interface QuillEditorToolbarProps extends BaseProps {
  hasImages?: boolean
  initExpanded?: boolean
}

export const QuillEditorToolbar: React.VFC<QuillEditorToolbarProps> = ({
  className,
  id,

  hasImages = false,
  initExpanded = false,
}) => {
  const [expanded, setExpanded] = useState(initExpanded)

  return (
    <Wrapper id={id ?? 'toolbar'} className={className}>
      <ToolbarGroup>
        <ToolbarElement className="ql-bold" />
        <ToolbarElement className="ql-italic" />
        <ToolbarElement hidden={!expanded} className="ql-underline" />
        <ToolbarElement hidden={!expanded} className="ql-strike" />
      </ToolbarGroup>

      <ToolbarGroup>
        <ToolbarElement className="ql-header" value="1" />
        <ToolbarElement className="ql-header" value="2" />
        <ToolbarElement className="ql-blockquote" />
      </ToolbarGroup>

      <ToolbarGroup hidden={!expanded}>
        <ToolbarElement className="ql-script" value="sub" />
        <ToolbarElement className="ql-script" value="super" />
      </ToolbarGroup>

      <ToolbarGroup>
        <select className="ql-color"></select>
        <select className="ql-background"></select>
      </ToolbarGroup>

      <ToolbarGroup>
        <ToolbarElement className="ql-list" value="ordered" />
        <ToolbarElement className="ql-list" value="bullet" />

        <ToolbarElement
          hidden={!expanded}
          className="ql-indent stroke"
          value="-1"
        />
        <ToolbarElement
          hidden={!expanded}
          className="ql-indent stroke"
          value="+1"
        />
      </ToolbarGroup>

      <ToolbarGroup>
        <ToolbarElement
          hidden={!expanded}
          className="ql-direction stroke"
          value="rtl"
        />
        <select className="ql-align"></select>
      </ToolbarGroup>

      <ToolbarGroup>
        <ToolbarElement className="ql-link" />
        {hasImages && <ToolbarElement className="ql-image" />}

        {expanded && (
          <ToolbarElement hidden={!expanded} className="ql-formula" />
        )}
        <ToolbarElement className="ql-table" />
      </ToolbarGroup>

      <ToolbarGroup>
        <ToolbarElement className="ql-clean stroke" />
      </ToolbarGroup>

      {!expanded && (
        <Icon
          icon="ellipsis-h"
          cursor="pointer"
          onClick={() => setExpanded(true)}
        />
      )}
    </Wrapper>
  )
}
