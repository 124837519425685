import { PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Input, RegularModal, TextArea } from 'components'
import { Label } from 'components/Label'
import React from 'react'
import styled from 'styled-components'
import { EditFolderForm } from '../types'
import { ManualFolderNode } from '../types.graphql'

const Form = styled.div``
const Field = styled.div`
  & + div {
    margin-top: 1rem;
  }
`

interface EditFolderPromptProps {
  initial?: Pick<ManualFolderNode, 'name' | 'description'>

  onResolve: PromptResolve<EditFolderForm | null>
}

export const EditFolderPrompt: React.VFC<EditFolderPromptProps> = ({
  initial,
  onResolve,
}) => {
  const translations = useTranslate({
    addFolder: 'handbook.add-folder',
    editFolder: 'handbook.edit-folder',

    name: 'common.name',
    description: 'common.description',

    validation: {
      required: 'common.required',
    },
  })

  const editing = typeof initial !== 'undefined'

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formChangeHandler: handler,
    submitHandler: submit,
  } = useForm<EditFolderForm>({
    values: {
      name: initial?.name ?? '',
      description: initial?.description ?? '',
    },
    validators: {
      name: val => (!!val ? null : translations.validation.required),
    },
    config: {
      initAsInvalid: !editing,
    },
  })

  return (
    <RegularModal
      width="480px"
      title={editing ? translations.editFolder : translations.addFolder}
      submitDisabled={!formValid}
      onCancel={() => onResolve(null)}
      onSubmit={submit(onResolve)}
    >
      <Form>
        <Field>
          <Label>{translations.name}</Label>
          <Input
            value={form.name}
            error={errors.name}
            autoFocus
            fullWidth
            onChange={handler('name')}
          />
        </Field>

        <Field>
          <Label>{translations.description}</Label>
          <TextArea
            value={form.description}
            fullWidth
            onChange={handler('description')}
          />
        </Field>
      </Form>
    </RegularModal>
  )
}
