import styled, { css } from 'styled-components'
import { Card } from 'components/Card'
import { animated } from 'react-spring'
import { ZIndexRange } from 'types/style'

export const Wrapper = styled.div`
  ${props => props.theme.layout.desktop} {
    ${props => props.theme.layout.default};
  }
`
export const Section = styled(Card)`
  background-color: white;
  border-radius: 5px;
  padding: 0;

  &:not(:first-of-type) {
    margin-top: 2rem;
  }

  ${props => props.theme.layout.mobile} {
    border-radius: 0;

    &:not(:first-of-type) {
      margin-top: 1rem;
    }
  }
`
interface SectionHeaderProps {
  section: 'thread' | 'comments'
}
export const SectionHeader = styled.header<SectionHeaderProps>`
  border-bottom: 1px solid ${props => props.theme.colors.gray8};

  ${props => props.theme.layout.desktop} {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;

    span {
      font-size: 1.2rem;
      font-weight: bold;

      i {
        margin-right: 8px;
      }
    }
    div.grow {
      flex-grow: 1;
    }
    button:not(:first-of-type) {
      margin-left: 0.5rem;
    }
  }
  ${props => props.theme.layout.mobile} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 6px;

    padding: 6px;

    ${props =>
      props.section === 'comments' &&
      css`
        grid-template-columns: 1fr;

        span {
          padding: 2px 0;

          font-size: 1rem;
          font-weight: bold;
          text-align: center;
        }
      `}
  }
`
export const SectionFooter = styled.footer`
  border-top: 1px solid ${props => props.theme.colors.gray8};

  ${props => props.theme.layout.desktop} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }
  ${props => props.theme.layout.mobile} {
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: 6px;
    padding: 6px;
  }
`
export const ThreadContent = styled.div`
  padding: 0 1rem;

  div.quill {
    margin: 0;
    padding: 1.2rem 0;
    font-size: 0.9rem;
    line-height: 22px;
  }
`
export const Attachments = styled.div`
  padding: 1rem 0;
  border-top: 1px solid ${props => props.theme.colors.gray8};

  h2 {
    margin: 0 0 8px 0;
    font-size: 1rem;
    font-weight: normal;
  }
  span.no-attachments {
    color: ${props => props.theme.colors.gray5};
    font-size: 0.8rem;
  }
  div.files {
    display: flex;
    ${props => props.theme.layout.desktop} {
      flex-wrap: wrap;

      & > div:not(:last-of-type) {
        margin-right: 0.5rem;
      }
    }
    ${props => props.theme.layout.mobile} {
      flex-direction: column;
    }
  }
`
export const UserInfo = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    'thumb name'
    'thumb when';
  column-gap: 6px;

  div.thumb {
    grid-area: thumb;
  }
  div.name {
    grid-area: name;
    align-self: end;

    a {
      color: inherit;
    }
  }
  div.when {
    grid-area: when;

    font-size: 0.8rem;

    span.since {
      font-weight: bolder;
    }
    span.date::before {
      content: ' - ';
    }
  }
`
export const Comments = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 1rem;

  ${props => props.theme.layout.mobile} {
    padding: 0;
  }
`

interface LoadMoreProps {
  hasMore: boolean
}
export const LoadMore = styled.div.attrs({ role: 'button' })<LoadMoreProps>`
  display: ${props => (props.hasMore ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3.875rem;

  border-top: 1px solid ${props => props.theme.colors.lightPrimary};
  color: ${props => props.theme.colors.gray4};
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 65%, #d0d7f766);

  user-select: none;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.8rem;
  transition: background-color 0.2s ease, color 0.2s ease;

  &:hover {
    color: inherit;
    background-color: ${props => props.theme.colors.lightPrimary};
  }
  &:active {
    color: inherit;
    background-color: #d0d7f7;
  }
`

export const ReadByModal = animated(styled.div`
  position: fixed;
  z-index: ${ZIndexRange.Modals};
  ${props => props.theme.layout.desktop} {
    top: 20vh;
    left: 50%;
    transform: translateX(-50%);
  }
  ${props => props.theme.layout.mobile} {
    top: calc(${props => props.theme.layout.headerHeight} + 4vw);
    left: 4vw;
    width: 92vw;
  }

  border-radius: ${props => props.theme.standardBorderRadius};
  background-color: white;
  box-shadow: ${props => props.theme.shadow.default};
  overflow: hidden;

  h2 {
    margin: 0;
    padding: 12px 0 8px;
    text-align: center;
    background-color: ${props => props.theme.colors.gray10};
    color: ${props => props.theme.colors.gray3};
    font-weight: lighter;
    font-size: 0.8rem;
    text-transform: uppercase;
    border-bottom: ${props => props.theme.standardBorder};
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 60vh;
    overflow-y: auto;

    ${props => props.theme.layout.mobile} {
      max-height: 76vh;
    }

    li {
      padding: 6px;

      &:not(:last-of-type) {
        border-bottom: ${props => props.theme.standardBorder};
      }

      a {
        display: flex;
        align-items: center;

        div.thumb {
          margin-right: 6px;
        }
      }
    }
  }
`)
