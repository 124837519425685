import { gql } from '@apollo/client'
import { UserFragments } from './fragments'

export const ME_QUERY = gql`
  query Me {
    me {
      ...UserInfo
      ...UserTypes
      driverCards {
        ...DriverCardInfo
      }
      activeDriverCard {
        ...DriverCardInfo
      }
      allPermissions
      companies {
        id
        fullName
      }
    }
  }
  ${UserFragments.info}
  ${UserFragments.types}
  ${UserFragments.driverCard}
`

export const PROFILE_QUERY = gql`
  query Profile($id: ID!, $withDocuments: Boolean = false) {
    user(id: $id) {
      id
      email
      fullName
      profilePicture
      phoneNumber
      address
      city
      postalCode
      birthNumber
      contactPersonName
      contactPersonPhoneNumber

      internalNumber

      activeDriverCard {
        id
      }

      driverCards {
        id
        cardIssuingMemberState
        driverIdentification
        issuedAt
        expiresAt
        isExpired
      }

      userTypes {
        id
        name
      }

      salaryWorkTimes {
        id
        priority
        dailyWorkLimit
        periodWorkLimit
        pauseDuration
        pauseThreshold
        paidPauseThreshold
        salaryDateStart
        salaryDateEnd
        name
        usePeriodOvertime

        user {
          id
          fullName
        }

        moduleConfig {
          name
        }

        defaultSalaryCode {
          id
          name
          externalId
        }

        periodAddons {
          id
          periodStart
          periodEnd

          salaryCode {
            id
            name
            externalId
          }

          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
        }
        overtimePeriods {
          id
          periodStart
          periodEnd

          salaryCode {
            id
            name
            externalId
          }

          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
        }
      }

      documents @include(if: $withDocuments) {
        edges {
          node {
            id
            name

            expirationDate
            notificationTime
            responsibleUser {
              id
              fullName
            }
            hasFiles
          }
        }
      }
    }
  }
`

export const USER_QUERY = gql`
  query User($id: ID!, $withDocuments: Boolean = false, $moduleConfigName: String) {
    user(id: $id) {
      ...UserInfo
      ...UserTypes
      ...UserDocuments @include(if: $withDocuments)
      driverCards {
        ...DriverCardInfo
      }
      activeDriverCard {
        ...DriverCardInfo
      }
      salaryWorkTime(moduleConfigName: $moduleConfigName) {
        id

      }
    }
  }
  ${UserFragments.info}
  ${UserFragments.types}
  ${UserFragments.documentInfo}
  ${UserFragments.driverCard}
`

export const USER_SHALLOW_QUERY = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      fullName
    }
  }
`

export const USER_QUERY_DEEP = gql`
  query UserDeep($id: ID!) {
    user(id: $id) {
      ...UserInfo
      ...UserTypes
    }
  }
`

export const USER_FULL_NAME_QUERY = gql`
  query UserFullName($id: ID!) {
    user(id: $id) {
      id
      fullName
    }
  }
`

export const USER_USER_TYPES_QUERY = gql`
  query UserUserTypes($id: ID!) {
    user(id: $id) {
      ...UserTypes
    }
  }
  ${UserFragments.types}
`

export const USER_SALARY_WORK_TIME_QUERY = gql`
  query UserSalaryWorkTime($id: ID!, $moduleConfigName: String) {
    user(id: $id) {
      salaryWorkTime(moduleConfigName: $moduleConfigName) {
        id
        priority
        dailyWorkLimit
        periodWorkLimit
        pauseDuration
        pauseThreshold
        paidPauseThreshold
        salaryDateStart
        salaryDateEnd
        name
        usePeriodOvertime

        moduleConfig {
          name
        }

        defaultSalaryCode {
          id
          name
          externalId
        }

        periodAddons {
          id
          periodStart
          periodEnd

          salaryCode {
            id
            name
            externalId
          }

          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
        }
        overtimePeriods {
          id
          periodStart
          periodEnd

          salaryCode {
            id
            name
            externalId
          }

          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
        }
      }
    }
  }
`

export const USERS_QUERY = gql`
  query Users(
    $q: String
    $first: Int
    $after: String
    $orderBy: String
    $userType: String
    $dropdown: String
    $isActive: Boolean
    $includeInactive: Boolean
    $includeExpiredDocuments: Boolean
    $onlyIncludeUserDocumentCategories: [String]
  ) {
    allUsers(
      first: $first
      after: $after
      q: $q
      orderBy: $orderBy
      userType: $userType
      dropdown: $dropdown
      isActive: $isActive
      includeInactive: $includeInactive
      includeExpiredDocuments: $includeExpiredDocuments
      onlyIncludeUserDocumentCategories: $onlyIncludeUserDocumentCategories
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...ReducedUserInfo
          driverFetchInfo {
            id
            lastFetch
            integrationEndpoint
          }
        }
      }
    }
  }
  ${UserFragments.reducedInfo}
`
export const ALL_USERS_SHALLOW_QUERY = gql`
  query UsersShallow(
    $q: String
    $first: Int
    $after: String
    $orderBy: String
    $dropdown: String
  ) {
    allUsers(
      q: $q
      first: $first
      after: $after
      orderBy: $orderBy
      dropdown: $dropdown
    ) {
      edges {
        node {
          id
          firstName
          lastName
          fullName
          email
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const USER_TYPES_QUERY = gql`
  query UserTypes($permission: String, $dropdown: String) {
    allUserTypes(permission: $permission, dropdown: $dropdown) {
      edges {
        node {
          id
          name
          users {
            edges {
              node {
                id
                fullName
                username
              }
            }
          }
        }
      }
    }
  }
`

export const USER_TYPES_SHALLOW_QUERY = gql`
  query UserTypesShallow {
    allUserTypes {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const USER_TYPES_WITH_PERMISSIONS_QUERY = gql`
  query UserTypesWithPermissions {
    allUserTypes {
      edges {
        node {
          id
          name
          editable
          isAdministrator
          permissions
        }
      }
    }
  }
`

export const USER_TYPES_WITH_DROPDOWNS_QUERY = gql`
  query UserTypesWithDropdowns {
    allUserTypes {
      edges {
        node {
          id
          name
          dropdowns
        }
      }
    }
  }
`

export const DRIVER_CARD_QUERY = gql`
  query DriverCard($id: ID!) {
    driverCard(id: $id) {
      ...DriverCardInfo
      user {
        fullName
      }
    }
  }
  ${UserFragments.driverCard}
`

export const USER_DOCUMENT_FILES_FOR_DOCUMENT_QUERY = gql`
  query UserDocumentFilesForDocument($userDocument: ID) {
    allUserDocumentFiles(userDocument: $userDocument) {
      edges {
        node {
          id
          file
          name
          originalName
          createdAt
        }
      }
    }
  }
`

export const ALL_USER_DOCUMENTS_QUERY = gql`
  query AllUserDocuments(
    $q: String
    $user: ID
    $responsibleUser: ID
    $orderBy: String
    $first: Int
    $after: String
  ) {
    allUserDocuments(
      q: $q
      user: $user
      responsibleUser: $responsibleUser
      orderBy: $orderBy
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          name

          expirationDate
          notificationTime

          hasFiles

          responsibleUser {
            id
            fullName
            firstName
            lastName
          }
          categories {
            id
            name
          }
        }
      }
    }
  }
`

export const NEEDS_PASSWORD_CHANGE_QUERY = gql`
  query NeedsPasswordChange {
    me {
      id
      needsPasswordChange
    }
  }
`


export const ALL_USER_DOCUMENT_CATEGORIES_QUERY = gql`
  query AllUserDocumentCategories($includeExpiredDocuments: Boolean = false) {
    allUserDocumentCategories {
      edges {
        node {
          id
          name
          editable
          numUsers(includeExpiredDocuments: $includeExpiredDocuments)
        }
      }
    }
  }
`