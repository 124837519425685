import { useTranslate } from '@ur/react-hooks'
import { Checkbox } from 'components'
import { FilterChildren, FilterPopup } from 'components/FilterPopup'
import {
  Status,
  StatusButton,
  StyledScrollBar,
} from 'components/FilterPopup/components'
import xor from 'lodash/xor'
import { VehicleFilter } from 'modules/vehicles/types'

interface FleetFilterProps extends FilterChildren<VehicleFilter> {
  vehicleTypes: string[]
}

export const FleetFilter: React.VFC<FleetFilterProps> = ({
  filter,
  vehicleTypes,
  onUpdate,
}) => {
  const translations = useTranslate({
    status: 'common.status',
    archived: 'common.archived',
    type: 'common.type',

    both: 'common.both',
    open: 'common.open-alt',
    closed: 'common.closed',

    owned: 'vehicles.owned',
    unowned: 'vehicles.unowned',
  })

  function toggleOwned(owned: boolean) {
    return () =>
      onUpdate({
        owned,
      })
  }

  function toggleType(type: string) {
    return () =>
      onUpdate({
        excludeTypes: xor(filter.excludeTypes, [type]),
      })
  }
  return (
    <FilterPopup>
      <h4>{translations.owned}</h4>
      <Status statusCount={2}>
        <StatusButton active={!!filter.owned} onClick={toggleOwned(true)}>
          {translations.owned}
        </StatusButton>
        <StatusButton active={!filter.owned} onClick={toggleOwned(false)}>
          {translations.unowned}
        </StatusButton>
      </Status>

      <h4>{translations.type}</h4>
      <StyledScrollBar>
        {vehicleTypes.map(vehicle => {
          const checked = !filter.excludeTypes.includes(vehicle)
          return (
            <Checkbox
              key={vehicle}
              checked={checked}
              label={vehicle}
              boxWidth="32px"
              boxHeight="32px"
              fontSize="1rem"
              onChange={toggleType(vehicle)}
            />
          )
        })}
      </StyledScrollBar>
    </FilterPopup>
  )
}
