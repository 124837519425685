import { gql } from '@apollo/client'
import { FormCreatorFragments } from './fragments'

export const FORM_TEMPLATE_QUERY = gql`
  query FormTemplate($id: ID!) {
    formTemplate(id: $id) {
      id
      name
      ingress
      menuShortcut

      archivedAt

      formFields {
        edges {
          node {
            id
            fieldType
            name
            description

            order
            required

            minimum
            maximum
          }
        }
      }
    }
  }
`
export const FORM_TEMPLATE_FOR_FORM_SUBMIT_QUERY = gql`
  query FormTemplateForFormSubmit($id: ID!, $userId: ID!) {
    formTemplate(id: $id) {
      id
      name
      ingress

      archived
      myResponsesCount
      userRespondedToday

      formFields {
        edges {
          node {
            id
            fieldType
            name
            description

            order
            required

            minimum
            maximum
          }
        }
      }
      filledOutForms(
        formTemplate: $id
        filledBy: $userId
        first: 20
      ) {
        edges {
          node {
            id
  
            createdAt
            values {
              id
              value
              formTemplateField {
                id
                name
                fieldType
              }
            }
          }
        }
      }
    }
  }
`

export const ALL_FORM_TEMPLATES_QUERY = gql`
  query AllFormTemplates($q: String, $archived: Boolean, $orderBy: String) {
    allFormTemplates(q: $q, archived: $archived, orderBy: $orderBy) {
      edges {
        node {
          id
          name
          updatedAt
          filledOutFormsCount
          myResponsesCount
          lastResponseAt
          fields {
            fieldType
          }
        }
      }
    }
  }
`

export const ALL_FORM_TEMPLATES_SHALLOW_QUERY = gql`
  query AllFormTemplatesShallow(
    $archived: Boolean = false
    $excludeArchived: Boolean = false
  ) {
    allFormTemplates(archived: $archived, excludeArchived: $excludeArchived) {
      edges {
        node {
          id
          name
          menuShortcut
        }
      }
    }
  }
`

export const ALL_FILLED_OUT_FORMS_QUERY = gql`
  query AllFilledOutForms(
    $formTemplate: ID
    $filledBy: ID
    $orderBy: String
    $first: Int
    $after: String
  ) {
    allFilledOutForms(
      formTemplate: $formTemplate
      filledBy: $filledBy
      orderBy: $orderBy
      first: $first
      after: $after
    ) {
      edges {
        node {
          id

          createdAt
          verifiedAt

          filledBy {
            id
            fullName
          }
          verifiedBy {
            id
            fullName
            internalNumber
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`
export const FILLED_OUT_FORM_QUERY = gql`
  query FilledOutForm($id: ID!) {
    filledOutForm(id: $id) {
      id
      createdAt
      updatedAt
      filledBy {
        id
        fullName
        internalNumber
      }
      formTemplate {
        id
        name
        ingress
        archived
        ...FormTemplateFields
      }
      filledOutFormValues {
        edges {
          node {
            id
            value
            userValue
            formTemplateField {
              id
            }
          }
        }
      }
    }
  }
  ${FormCreatorFragments.formTemplateFields}
`

export const FORM_TEMPLATE_RESPONSE_OVERVIEW_QUERY = gql`
  query FormTemplateResponseOverview($formId: ID!, $responseIds: [ID]) {
    formTemplate(id: $formId) {
      id
      name
      ingress
      filledOutFormsCount

      myResponsesCount

      fields {
        id
        name
        description
      }

      responses(responseIds: $responseIds) {
        id
        createdAt

        verifiedAt
        verifiedBy {
          id
          fullName
        }

        filledBy {
          id
          fullName
          internalNumber
        }

        values {
          id
          value

          formTemplateField {
            id
            name
            fieldType
          }
        }
      }
    }
  }
`

export const FORM_TEMPLATE_SUMS_QUERY = gql`
  query FormTemplateSums($companyId: ID!, $formTemplateId: ID!, $startDate: String!, $endDate: String!, $filterFieldId: ID, $summationFieldId: ID, $distributionFieldId: ID) {
    userSummations(companyId: $companyId, formTemplateId: $formTemplateId, startDate: $startDate, endDate: $endDate, filterFieldId: $filterFieldId, summationFieldId: $summationFieldId, distributionFieldId: $distributionFieldId) 
    { 
      formTemplate {
        id
        name
      }
      userSums  {
        user {
          id
          fullName
          internalNumber
        }
        sum
        distribution
      }
    }
    formTemplate(id: $formTemplateId) {
      id
      name

      fields {
        id
        name
        fieldType
      }
    }
  }
`
