import { DynamicColumnNode } from 'modules/dynamic_tables'
import styled from 'styled-components'
import { TableSummation } from 'components/Table/TableSummation'
import { useMemo } from 'react'
import { useTranslate } from '@ur/react-hooks'
import { stripSecondsFromDuration } from 'modules/activities/util'
import { WeekReport } from 'modules/activities/types'

const Summation = styled(TableSummation)`
  height: 5.5rem;

  td {
    padding-top: 1rem !important;
  }

  &:hover {
    background: inherit !important;
  }

  ${props => props.theme.media.mobile} {
    td:first-of-type div {
      margin-left: 1rem !important;
    }
    td:last-of-type div {
      margin-right: 1rem !important;
    }
  }
`

interface ActivitiesWeekTableSummationProps {
  week: WeekReport
  columns?: DynamicColumnNode[]
}

/**
 * A component to display week sums based on dynamic columns.
 *
 * @remarks Uses the first column slot for the 'SUM' label.
 * @remarks Assumes that all columns with type 'TIME' are summable.
 */
export const ActivitiesWeekTableSummation: React.VFC<ActivitiesWeekTableSummationProps> =
  ({ week, columns = [] }) => {
    const translations = useTranslate({
      sum: 'common.sum',
    })

    const summationColumns = useMemo(() => {
      // The first column must contain the SUM label
      const firstColumn = (
        <td key="sum">
          <div className="sum">{translations.sum}</div>
        </td>
      )
      const remainingColumns = columns.slice(1, columns.length).map(column => {
        if (column.columnType === 'TIME') {
          // Assumes that all 'TIME' columns have calculated week sums.
          const timeString = week.sums[column.name]
          const strippedTime = timeString
            ? stripSecondsFromDuration(timeString)
            : null
          return (
            <td key={column.name}>
              <div>
                <span>{column.displayName}</span>
                {strippedTime}
              </div>
            </td>
          )
        } else {
          // Other columns are not summable.
          return (
            <td key={column.name}>
              <div />
            </td>
          )
        }
      })
      return [firstColumn, ...remainingColumns]
    }, [columns, translations.sum, week.sums])

    return <Summation>{summationColumns}</Summation>
  }
