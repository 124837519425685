// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { BaseProps } from '@ur/react-components/build/types/props'
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'ur-react-contextmenu'
import { DetectBlur } from 'containers/util-containers'
import merge from 'lodash/merge'
import Quill, { QuillOptionsStatic, TextChangeHandler } from 'quill'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { ZIndexRange } from 'types/style'
import { useMedia } from 'util/hooks'
import { safeParseJson } from 'util/parsing'
import { QuillTablePicker } from './QuillTablePicker'
import ImageUploader from 'quill-image-uploader'
import { useQuillMedia } from './plugins'
import { useTranslate } from '@ur/react-hooks'

type UrContextMenuType = typeof ContextMenu & { children: React.ReactNode }

const UrContextMenu = ContextMenu as UrContextMenuType

const StyledContextMenu = styled(UrContextMenu)`
  background-color: ${props => props.theme.colors.white};
  border-radius: 5px;
  border: 1px solid ${props => props.theme.colors.gray10};
  box-shadow: ${props => props.theme.shadow.default};
  z-index: ${ZIndexRange.Dropdowns};
`

const StyledMenuItem = styled(MenuItem)`
  :hover {
    background-color: ${props => props.theme.colors.gray11};
    cursor: pointer;
  }

  ${props =>
    props.disabled &&
    `
      display: none;
    `}
  padding: 0.5rem 1rem;
`

interface WrapperProps {
  focused: boolean
  contextMenuOpen: boolean
  tooltipText: string
  error?: string | null
}
const Wrapper = styled.div<WrapperProps>`
  border-radius: 4px;
  border: 1px solid
    ${props =>
      props.focused ? props.theme.colors.primary : props.theme.colors.gray8};

  ${props =>
    props.error &&
    `
    &::after {
      content: '${props.error}';
      position: absolute;
      top: 0;
      right: 0;
      color: ${props.theme.colors.red};
      font-size: 0.8rem;
    }
    border-color: ${props.theme.colors.red};
  `}
  transition: border 0.3s ease-in-out;
  font-family: 'Gilroy', sans-serif !important;

  .ql-toolbar.ql-snow {
    font-family: 'Gilroy', sans-serif !important;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    border-radius: 4px 4px 0 0;
    background: white;
    border: none !important;
  }
  ${props =>
    !props.contextMenuOpen &&
    `
    .ql-editor table{
      position: relative;
      :hover{
        :after{
          position: absolute;
          right: 0;
          box-shadow: ${props.theme.shadow.default};
          content: '${props.tooltipText}';
          padding: 0 0.5rem;
        }

        ${props.theme.layout.mobile}{
          :after{
            content: "";
            padding: 0;
            box-shadow: none;
          }
        }
      }
    }
  `}

  .ql-container.ql-snow {
    border-radius: 0 0 4px 4px;
    background: white;
    border: none !important;
    font-family: 'Gilroy', sans-serif;
  }

  .ql-editor {
    min-height: 200px;
    border-top: 1px solid ${props => props.theme.colors.gray8};
    font-family: 'Gilroy', sans-serif;
    font-size: 1rem;
  }

  .ql-tooltip {
    z-index: 1;
  }

  iframe {
    width: 80%;
    border-radius: 4px;
    min-height: 300px;
    ${props => props.theme.layout.mobile} {
      width: 100%;
    }
  }

  .ql-formats {
    padding: 0 0.5rem;
    margin: 0 !important;
    :not(:first-child) {
      border-left: 1px solid ${props => props.theme.colors.gray8};
    }

    ${props => props.theme.layout.mobile} {
      padding: 0;
    }

    ${props => props.theme.layout.largePad} {
      padding-bottom: 0.5rem;
      :not(:first-child) {
        border-left: 0;
      }
    }

    button {
      width: 24px;
      height: 24px;
      ${props => props.theme.layout.mobile} {
        margin: 0.25rem 0;
      }
    }
    i {
      padding-top: 2px;
      font-size: 1rem;
    }
  }

  .ql-color-picker {
    ${props => props.theme.layout.mobile} {
      margin: 0.25rem 0;
    }
  }

  .fa-font-color {
    border-bottom: 2px solid ${props => props.theme.colors.gray8};
    :hover {
      border-bottom: 2px solid ${props => props.theme.colors.lightPrimary};
    }
  }

  .fa-underline {
    margin-top: 1px;
    font-size: 14px !important;
  }

  .fa-background-color {
    padding: 2px;
    border-radius: 2px;
    background-color: ${props => props.theme.colors.gray8};
    :hover {
      background-color: ${props => props.theme.colors.lightPrimary};
    }
    :before {
      margin-left: 1px;
      margin-top: 1px;
    }
  }

  .ql-header {
    margin-right: 0.25rem;
    ${props => props.theme.layout.mobile} {
      margin: 0.25rem 0.25rem 0.25rem 0;
    }
  }

  .ql-header .ql-picker-label {
    border: 1px solid ${props => props.theme.colors.gray8};
    border-radius: 5px;
  }
`

interface QuillEditorProps extends BaseProps {
  initialContents?: string
  outerQuillRef?: React.MutableRefObject<Quill | null>

  placeholder?: string
  error?: string
  options?: QuillOptionsStatic
  hasImages?: boolean

  onInit?: (quill: Quill) => void
  onChange: (contents: string, editor: Quill) => void
  onClick?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

Quill.register('modules/imageUploader', ImageUploader)

export const QuillEditor: React.VFC<QuillEditorProps> = ({
  className,
  id,

  initialContents = '[]',
  outerQuillRef,
  placeholder,
  error = '',
  options = {},

  onInit = () => void 0,
  onChange,
  onClick,
  hasImages = false,
}) => {
  const mountedRef = useRef(false)
  const translations = useTranslate({
    newTable: 'quill.new-table',
    insertColumnRight: 'quill.insert-column-right',
    insertColumnleft: 'quill.insert-column-left',
    deleteColumn: 'quill.delete-column',
    insertRowAbove: 'quill.insert-row-above',
    insertRowBelow: 'quill.insert-row-below',
    deleteRow: 'quill.delete-row',
    deleteTable: 'quill.delete-table',
    tooltip: 'quill.table-tooltip',
  })
  const quillRef = useRef<HTMLDivElement | null>(null)
  const quillEditorRef = useRef<Quill | null>(null)
  const [focused, setFocused] = useState(false)
  const [contextMenuOpen, setContextMenuOpen] = useState(false)
  const [contextMenuTarget, setContextMenuTarget] =
    useState<HTMLElement | null>(null)
  const [mobile] = useMedia()

  const [tablePickerVisible, setTablePickerVisible] = useState<boolean>(false)

  const handleBlur = () => {
    setTablePickerVisible(false)
  }

  const { handleUpload } = useQuillMedia()

  const defaultToolbarOptions = useMemo(
    () => ({
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' },
        ],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        ['blockquote', 'link'],

        [{ list: 'ordered' }, { list: 'bullet' }],
        ['image', 'video'],
        ['table'],
      ],
      handlers: {
        table: function (value: any) {
          if (value) {
            setTablePickerVisible(true)
          }
        },
      },
    }),
    []
  )

  const mobileToolbarOptions = useMemo(
    () => ({
      container: [
        { header: [1, 2, 3, 4, 5, 6, false] },
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
        'bold',
        'italic',
        'underline',
        'strike',
        { color: [] },
        { background: [] },
        'blockquote',
        'link',
        { list: 'ordered' },
        { list: 'bullet' },
        'image',
        'video',
        'table',
      ],
      handlers: {
        table: function (value: any) {
          if (value) {
            setTablePickerVisible(true)
          }
        },
      },
    }),
    []
  )

  useEffect(() => {
    if (!quillRef.current || mountedRef.current) return

    // Set toolbar icons to FontAwesome
    const icons = Quill.import('ui/icons')

    icons['bold'] = '<i class="fas fa-bold"></i>'
    icons['italic'] = '<i class="fas fa-italic"></i>'
    icons['underline'] = '<i class="fas fa-underline"></i>'
    icons['strike'] = '<i class="fas fa-strikethrough"></i>'

    icons['color'] = '<i class="fa fa-palette" aria-hidden="true"></i>'
    icons['background'] = '<i class="fa fa-fill" aria-hidden="true"></i>'

    icons['blockquote'] = '<i class="fas fa-quote-right"></i>'
    icons['link'] = '<i class="fas fa-link"></i>'

    icons['list']['bullet'] = '<i class="fas fa-list"></i>'
    icons['list']['ordered'] = '<i class="fas fa-list-ol"></i>'

    icons['image'] = '<i class="fas fa-image"></i>'
    icons['video'] = '<i class="fas fa-film"></i>'

    icons['align'][''] = '<i class="fas fa-align-left"></i>'
    icons['align']['center'] = '<i class="fas fa-align-center"></i>'
    icons['align']['right'] = '<i class="fas fa-align-right"></i>'
    icons['align']['justify'] = '<i class="fas fa-align-justify"></i>'

    icons['table'] = '<i class="fas fa-table"></i>'

    const quillOptions: QuillOptionsStatic = merge(
      {
        theme: 'snow',
        modules: {
          toolbar: mobile ? mobileToolbarOptions : defaultToolbarOptions,
          table: true,
          imageUploader: hasImages
            ? {
                upload: handleUpload,
              }
            : false,
        },
        placeholder,
      },
      options
    )

    const quill = new Quill(quillRef.current, quillOptions)

    quill.on('selection-change', (range, oldRange) => {
      if (range === null && oldRange !== null) {
        setFocused(false)
      } else if (range !== null && oldRange === null) {
        setFocused(true)
      }
    })

    const initialContentsParsed = safeParseJson(initialContents)
    quill.setContents(initialContentsParsed)

    onInit(quill)
    quillEditorRef.current = quill

    if (outerQuillRef) outerQuillRef.current = quill

    mountedRef.current = true
  }, [
    defaultToolbarOptions,
    initialContents,
    mobile,
    mobileToolbarOptions,
    onInit,
    outerQuillRef,
    tablePickerVisible,
    options,
    placeholder,
    hasImages,
    handleUpload,
  ])

  useEffect(() => {
    if (!quillEditorRef || !quillEditorRef.current) return

    const handleQuillEditorChanged: TextChangeHandler = () => {
      onChange(JSON.stringify(quill.getContents()), quillEditorRef.current!)
    }

    const quill = quillEditorRef.current
    quill.on('text-change', handleQuillEditorChanged)

    return () => {
      quill.off('text-change', handleQuillEditorChanged)
    }
  }, [onChange])

  const handleTableInsertion = (rowNumber: number, columnNumber: number) => {
    if (!quillEditorRef || !quillEditorRef.current) return
    // Have to refocus since we click outside the QuilEditor
    quillEditorRef.current.focus()
    const quill = quillEditorRef.current
    quill.getModule('table').insertTable(rowNumber, columnNumber)
    setContextMenuOpen(false)
  }

  const alterTable = (mode: string) => {
    if (!quillEditorRef || !quillEditorRef.current) return
    quillEditorRef.current.focus()
    const quill = quillEditorRef.current
    switch (mode) {
      case 'row-below':
        quill.getModule('table').insertRowBelow()
        break
      case 'row-above':
        quill.getModule('table').insertRowAbove()
        break
      case 'column-right':
        quill.getModule('table').insertColumnRight()
        break
      case 'column-left':
        quill.getModule('table').insertColumnLeft()
        break
      case 'delete-column':
        quill.getModule('table').deleteColumn()
        break
      case 'delete-row':
        quill.getModule('table').deleteRow()
        break
      case 'delete-table':
        quill.getModule('table').deleteTable()
        break
    }
    setContextMenuOpen(false)
  }

  const menuItems = [
    {
      key: 'insertTable',
      label: translations.newTable,
      handler: () => handleTableInsertion(3, 3),
    },
    {
      key: 'insertColRight',
      label: translations.insertColumnRight,
      handler: () => alterTable('column-right'),
    },
    {
      key: 'insertColLeft',
      label: translations.insertColumnleft,
      handler: () => alterTable('column-left'),
    },
    {
      key: 'insertRowAbove',
      label: translations.insertRowAbove,
      handler: () => alterTable('row-above'),
    },
    {
      key: 'insertRowBelow',
      label: translations.insertRowBelow,
      handler: () => alterTable('row-below'),
    },
    {
      key: 'deleteCol',
      label: translations.deleteColumn,
      handler: () => alterTable('delete-column'),
    },
    {
      key: 'deleteRow',
      label: translations.deleteRow,
      handler: () => alterTable('delete-row'),
    },
    {
      key: 'deleteTable',
      label: translations.deleteTable,
      handler: () => alterTable('delete-table'),
    },
  ]

  const tableIcon = document.getElementsByClassName('ql-table')[0]
  const TablePicker = useMemo(() => {
    return tableIcon ? (
      tableIcon.parentElement && !document.getElementById('ql-table-picker') ? (
        ReactDOM.createPortal(
          <DetectBlur
            active={tablePickerVisible}
            onClickOutside={handleBlur}
            disableTouchEnd={true}
          >
            <QuillTablePicker
              onCreateTable={handleBlur}
              id="ql-table-picker"
              onSelectTable={handleTableInsertion}
            />
          </DetectBlur>,
          tableIcon.parentElement
        )
      ) : (
        <></>
      )
    ) : (
      <></>
    )
  }, [tableIcon, tablePickerVisible])
  return (
    <>
      {tablePickerVisible && TablePicker}

      <Wrapper
        className={className}
        id={id}
        error={error}
        focused={focused}
        contextMenuOpen={contextMenuOpen}
        tooltipText={translations.tooltip}
        onClick={onClick}
      >
        <ContextMenuTrigger enableIfShiftIsPressed={!mobile} id="table_context">
          <div ref={quillRef}></div>
        </ContextMenuTrigger>
      </Wrapper>

      {quillEditorRef && (
        <StyledContextMenu
          style={contextMenuOpen ? { display: 'block' } : { display: 'none' }}
          onShow={(evt: any) => {
            setContextMenuTarget(evt.detail.data.target)
            setContextMenuOpen(true)
          }}
          onHide={(evt: any) => {
            setContextMenuOpen(false)
          }}
          id="table_context"
        >
          {menuItems.map(item => {
            return (
              <StyledMenuItem
                key={item.key}
                disabled={
                  (contextMenuTarget?.tagName === 'TD' &&
                    item.key === 'insertTable') ||
                  !(
                    contextMenuTarget?.tagName === 'TD' ||
                    item.key === 'insertTable'
                  ) ||
                  contextMenuTarget?.classList.contains('ql-toolbar') ||
                  !quillEditorRef?.current?.root?.contains(contextMenuTarget)
                }
                onClick={item.handler}
                data={{ item: item.label }}
                preventClose={false}
              >
                {item.label}
              </StyledMenuItem>
            )
          })}
        </StyledContextMenu>
      )}
    </>
  )
}
