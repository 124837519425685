import { Icon, Loader, useToast } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Checkbox, Input } from 'components'
import { Card } from 'components/Card'
import React, { useRef } from 'react'
import { useMutation } from '@apollo/client'
import { isMobileOnly } from 'react-device-detect'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import {
  FileAttachment,
  useCompany,
  useOnErrorAuto,
  usePermissions,
} from 'util/hooks'
import { CREATE_THREAD_MUTATION } from '../mutations'
import { CreateThreadForm } from '../types'
import {
  CreateThreadMutation,
  CreateThreadMutationVariables,
} from '../types.graphql'
import { ForumQuillEditor, ForumQuillEditorRef } from '../components'
import { PERMISSIONS } from 'util/permissions'
import { FORUM_THREADS_QUERY } from '../queries'
import { DEFAULT_PAGE_SIZE } from 'util/pagination'

const Wrapper = styled.div`
  ${props => props.theme.layout.default};

  header h1 {
    display: flex;

    div.loader {
      margin-left: 1rem;
    }
  }
`
const Form = styled(Card)`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'title   choices'
    'content content';

  width: clamp(600px, 60vw, 100%);

  padding: 0;

  ${props => props.theme.media.mobile} {
    display: flex;
    flex-direction: column;

    width: 100%;
  }
`
interface SectionProps {
  area: string
}
const Section = styled.div<SectionProps>`
  grid-area: ${props => props.area};
`
const TitleSection = styled(Section).attrs({
  area: 'title',
})`
  display: flex;
  padding: 1rem 1rem 0;

  div.sticky {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 0 1rem;

    border-radius: ${props => props.theme.sizes.defaultBorderRadius} 0 0
      ${props => props.theme.sizes.defaultBorderRadius};
    border: 1px solid ${props => props.theme.colors.gray9};
    border-width: 1px 0 1px 1px;
    background-color: ${props => props.theme.colors.quaternary};

    transition: border-color 0.1s linear;
  }

  &:focus-within div.sticky {
    border-color: ${props => props.theme.colors.secondary};
  }
`
const TitleInput = styled(Input).attrs({
  fullWidth: true,
})`
  input {
    border-width: 1px 1px 1px 0;
    border-radius: 0 ${props => props.theme.sizes.defaultBorderRadius}
      ${props => props.theme.sizes.defaultBorderRadius} 0;
  }
`
const ChoicesSection = styled(Section).attrs({
  area: 'choices',
})`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;

  padding: 1rem 1rem 0 0;

  label {
    font-size: 0.9rem;
  }

  ${props => props.theme.media.mobile} {
    align-items: flex-start;
    padding: 1rem;

    border-bottom: 1px solid ${props => props.theme.colors.gray9};

    & > div + div {
      margin-top: 0.5rem;
    }
  }
`
const ContentSection = styled(Section).attrs({
  area: 'content',
})`
  .ql-editor {
    padding: 0.5rem !important;

    &::before {
      left: 0.5rem !important;
    }
  }
`

interface CreateThreadProps {}

export const CreateThread: React.VFC<CreateThreadProps> = () => {
  const translations = useTranslate({
    newThread: 'forum.new-thread',

    title: 'common.title',
    sticky: 'forum.sticky-thread',
    notification: ['forum.send-sms-label', { employeeNum: 0 }],
    email: 'forum.send-email-label',
    content: 'common.content',

    createThread: 'forum.create-thread',

    validation: {
      required: 'common.required',
      mustHaveContent: 'forum.validation.thread-must-have-content',
    },
    toast: {
      createSuccess: 'forum.toasts.create-thread-success',
    },
    error: {
      create: 'forum.errors.create-thread',
    },
  })
  const quillRef = useRef<ForumQuillEditorRef>(null)

  const company = useCompany()
  const addToast = useToast()
  const history = useHistory()
  const onErrorAuto = useOnErrorAuto()
  const { hasPermissions } = usePermissions()

  const canSendEmail = hasPermissions(PERMISSIONS.forum.send.thread_email)
  const canSendSms = hasPermissions(PERMISSIONS.forum.send.thread_sms)

  const {
    formValues: form,
    formChangeHandler: handler,
    formErrors: errors,
    updateForm,
    submitHandler: submit,
  } = useForm<CreateThreadForm>({
    values: {
      title: '',
      sticky: false,
      sendSms: false,
      sendEmail: false,
    },
    validators: {
      title: val => (!!val ? null : translations.validation.required),
    },
  })

  const [createThread, { loading: createLoading }] = useMutation<
    CreateThreadMutation,
    CreateThreadMutationVariables
  >(CREATE_THREAD_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: FORUM_THREADS_QUERY,
        variables: {
          first: DEFAULT_PAGE_SIZE,
          q: '',
        },
      },
    ],
    onCompleted(data) {
      if (!data?.createThread.ok) {
        addToast('error', translations.error.create)
        return
      }

      addToast('success', translations.toast.createSuccess)
      history.push(`/forum/${data.createThread.thread.id}`)
    },
    onError: onErrorAuto(translations.error.create),
  })

  function handleSubmit(content: string, attachments: FileAttachment[]) {
    if (!quillRef.current?.quill) return () => void 0

    const text = quillRef.current.quill.getText()
    if (typeof text === 'undefined' || text.trim() === '') {
      addToast('warning', translations.validation.mustHaveContent)
      return () => void 0
    }

    return (values: CreateThreadForm) => {
      createThread({
        variables: {
          ...values,
          content,
          attachments: attachments.map(attachment => attachment.file),
        },
      })
    }
  }
  async function handlePostSubmit(
    content: string,
    attachments: FileAttachment[]
  ) {
    submit(handleSubmit(content, attachments))()
  }

  return (
    <Wrapper>
      <header>
        <h1>
          {translations.newThread}

          {createLoading && (
            <div className="loader">
              <Loader.Spinner size={32} />
            </div>
          )}
        </h1>
      </header>

      <Form>
        <TitleSection>
          <div className="sticky">
            <Icon
              icon="thumbtack"
              size="1.6rem"
              type={form.sticky ? 'solid' : 'light'}
              color={form.sticky ? 'orange' : 'gray4'}
              hoverColor="orange"
              cursor="pointer"
              title={translations.sticky}
              onClick={() => updateForm({ sticky: !form.sticky })}
            />
          </div>

          <TitleInput
            value={form.title}
            error={errors.title}
            errorOffsetY="1.4rem"
            errorOffsetX="0.7rem"
            placeholder={translations.title}
            onChange={handler('title')}
          />
        </TitleSection>

        <ChoicesSection>
          {canSendSms && (
            <Checkbox
              checked={form.sendSms}
              label={translations.notification({
                employeeNum: company.numberOfUsers,
              })}
              labelPosition={isMobileOnly ? 'right' : 'left'}
              boxSize={isMobileOnly ? '2rem' : '1.6rem'}
              onChange={handler('sendSms')}
            />
          )}
          {canSendEmail && (
            <Checkbox
              checked={form.sendEmail}
              label={translations.email}
              labelPosition={isMobileOnly ? 'right' : 'left'}
              boxSize={isMobileOnly ? '2rem' : '1.6rem'}
              onChange={handler('sendEmail')}
            />
          )}
        </ChoicesSection>

        <ContentSection>
          <ForumQuillEditor
            ref={quillRef}
            placeholder={translations.content}
            minHeight="8rem"
            submitText={translations.createThread}
            noAttachmentReset
            onSubmit={handlePostSubmit}
          />
        </ContentSection>
      </Form>
    </Wrapper>
  )
}
