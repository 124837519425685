import { PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Input, RegularModal as BaseRegularModal } from 'components'
import { Label } from 'components/Label'
import { Field, Form } from 'modules/users/UserSettings/components'
import {
  CreateVehicleByRegNrForm,
  CreateVehicleByRegNrModalResolve,
} from 'modules/vehicles/types'
import styled from 'styled-components'

const RegularModal = styled(BaseRegularModal)`
  box-shadow: ${props => props.theme.shadow.default};
`

interface CreateVehicleByRegNrModalProps {
  onSubmit: PromptResolve<CreateVehicleByRegNrModalResolve | null>
}

export const CreateVehicleByRegNrModal: React.VFC<CreateVehicleByRegNrModalProps> =
  ({ onSubmit }) => {
    const translations = useTranslate({
      title: 'vehicles.add-vehicle',
      submit: 'common.submit',
      regNr: 'common.reg-nr',
      validation: { required: 'common.required' },
    })
    const {
      formValues: form,
      formValid,
      formChangeHandler: handler,
      submitHandler: submit,
    } = useForm<CreateVehicleByRegNrForm>({
      values: {
        registrationNumber: '',
      },
      validators: {
        registrationNumber: val =>
          !!val ? null : translations.validation.required,
      },
      config: {
        initAsInvalid: true,
      },
    })
    return (
      <RegularModal
        title={translations.title}
        width="480px"
        cancelOnClickOutside
        submitDisabled={!formValid}
        submitText={translations.submit}
        onCancel={() => onSubmit(null)}
        onSubmit={submit(form => onSubmit({ form }))}
      >
        <Form>
          <Field>
            <Label>{translations.regNr}</Label>
            <Input
              value={form.registrationNumber}
              onChange={handler('registrationNumber')}
            ></Input>
          </Field>
        </Form>
      </RegularModal>
    )
  }
