import React, { useCallback, useMemo } from 'react'
import { Select, SelectProps as BaseSelectProps } from './Select'
import { SelectOption } from '@ur/react-components'
import { countries, CountryCode } from 'util/countries'
import { useCountryCodeTranslations } from 'util/hooks/useCountryCodeTranslations'

interface CountrySelectProps
  extends Omit<BaseSelectProps<CountryCode, string>, 'options'> {
  include?: CountryCode[]
  noTranslation?: boolean
  overrideName?: (code: CountryCode, name: string) => string
  overrideFlag?: (code: CountryCode, flag: string) => string
}

export const CountrySelect: React.VFC<CountrySelectProps> = ({
  include,
  noTranslation = false,
  overrideName = (_, name) => name,
  overrideFlag = (_, flag) => flag,
  ...selectProps
}) => {
  // TODO fix exhaustive-deps?
  // eslint-disable-next-line
  const overrideNameCallback = useCallback(overrideName, [])
  // eslint-disable-next-line
  const overrideFlagCallback = useCallback(overrideFlag, [])

  const { getCountryCodeTranslations } = useCountryCodeTranslations()

  const translateName = useCallback(
    (code: CountryCode) => {
      return getCountryCodeTranslations(code)
    },
    [getCountryCodeTranslations]
  )

  const options = useMemo<SelectOption<CountryCode, string>[]>(
    () =>
      Object.entries(countries).reduce<SelectOption<CountryCode, string>[]>(
        (acc, [key, value]) => {
          if (
            typeof include !== 'undefined' &&
            !include.includes(key as CountryCode)
          )
            return acc

          let countryName = value.name
          if (!noTranslation) countryName = translateName(key as CountryCode)

          const name = overrideNameCallback(key as CountryCode, countryName)
          const flag = overrideFlagCallback(key as CountryCode, value.emoji)

          return [
            ...acc,
            {
              value: key as CountryCode,
              label: (
                <span>
                  <span className="flag">{flag}</span>&nbsp;&nbsp;
                  {name}
                </span>
              ),
              extra: name,
            },
          ]
        },
        []
      ),
    [
      include,
      noTranslation,
      translateName,
      overrideNameCallback,
      overrideFlagCallback,
    ]
  )

  const filterCountries = (
    option: SelectOption<CountryCode, string>,
    query: string
  ) => option.extra?.toLowerCase().includes(query.toLowerCase()) ?? true

  return (
    <Select options={options} searchable={filterCountries} {...selectProps} />
  )
}
