import { TerminalEntryNode } from './types.graphql'
import { parseISO, differenceInSeconds } from 'date-fns'

import { durationAsSecondsToString } from 'util/time'
import { TerminalSummation } from './TerminalView/types'

export function getTerminalEntryDurationString(
  terminalEntry: TerminalEntryNode
): string {
  if (terminalEntry.checkOut === null) return '-'

  const secondsInDuration = differenceInSeconds(
    parseISO(terminalEntry.checkOut),
    parseISO(terminalEntry.checkIn)
  )

  return durationAsSecondsToString(secondsInDuration)
}

function timeStringToMinutes(timeString: string) {
  const safeString =
    timeString.length < 6 ? timeString : timeString.substring(0, 5)
  if (!/^\d{2}:\d{2}$/.test(safeString)) return 0
  const hours = parseInt(safeString.substring(0, 2))
  const minutes = parseInt(safeString.substring(3))
  return hours * 60 + minutes
}
function minutesToTimeString(minutes: number) {
  const hours = Math.floor(minutes / 60) + ''
  const mins = (minutes % 60) + ''

  return `${hours.padStart(2, '0')}:${mins.padStart(2, '0')}`
}

/**
 * Sums hours, pauses and overtime for a list of terminal entries
 * @param entries The collection of entries to sum
 */
export function generateSums(entries: TerminalEntryNode[]) {
  const sums: TerminalSummation = { hours: 0, pause: 0, overtime: 0 }
  for (const entry of entries) {
    if (entry.type === 'absence') continue
    sums.hours += timeStringToMinutes(entry.totalWork)
    sums.pause += timeStringToMinutes(entry.pause)
    sums.overtime += timeStringToMinutes(entry.overtime)
  }

  return {
    hours: minutesToTimeString(sums.hours),
    pause: minutesToTimeString(sums.pause),
    overtime: minutesToTimeString(sums.overtime),
  }
}
