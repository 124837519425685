import { Icon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { Popup } from 'components/Popup'
import { Text } from 'components/Text'
import { DetectBlur } from 'containers/util-containers'
import { removeLoginToken } from 'modules/login/util'
import UserThumbOrInitials from 'modules/users/UserThumbOrInitials'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useCompany, useMedia, useUser } from 'util/hooks'

const ItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  user-select: none;

  span {
    padding-left: 0.5rem;
    flex: 8 0;
  }

  i {
    flex: 2 0;
  }
`
const CompaniesItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
`
const PopupHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.gray9};

  div:first-child {
    margin-right: 0.5rem;
  }
`
const COMPANY_ITEM_HEIGHT = 48

const Companies = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${props => (props.open ? '100%' : '0px')};
  overflow: hidden;
  margin-top: ${props => (props.open ? '12px' : '0px')};
`

const Company = styled.div`
  display: flex;
  align-items: center;
  min-height: ${COMPANY_ITEM_HEIGHT}px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

const SettingsWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const HeaderMenuUser: React.VFC = () => {
  const [showPopup, setShowPopup] = useState(false)
  const [showCompanies, setShowCompanies] = useState(false)

  const [mobile] = useMedia()
  const user = useUser()
  const company = useCompany()
  const history = useHistory()
  const translations = useTranslate({
    myProfile: 'users.my-profile',
    userSettings: 'users.settings',
    changeCompany: 'common.change-company',
    logOut: 'common.log-out',
  })

  const companies =
    user.companies?.filter(
      otherCompany => otherCompany.shortName !== company.shortName
    ) || []

  const config = {
    header: function SettingsHeader() {
      return (
        <PopupHeader>
          <UserThumbOrInitials size={60} user={user} />
          <p>{user.fullName}</p>
        </PopupHeader>
      )
    },
    listItems: [
      {
        id: 'my-profile',
        content: () => {
          return (
            <ItemWrapper>
              <Icon icon="user" type="solid" size="1rem" />
              <Text>{translations.myProfile}</Text>
            </ItemWrapper>
          )
        },
        clickable: true,
        onClick: (close: () => void) => {
          history.push(`/users/${user.id}`)
          close()
        },
      },
      {
        id: 'users.settings',
        content: () => {
          return (
            <ItemWrapper>
              <Icon icon="cog" type="solid" size="1rem" />
              <Text>{translations.userSettings}</Text>
            </ItemWrapper>
          )
        },
        clickable: true,
        onClick: (close: () => void) => {
          history.push(`/usersettings`)
          close()
        },
      },
      {
        id: 'change-company',
        hide: companies.length < 1,
        clickable: true,
        content: () => {
          return (
            <DetectBlur active onClickOutside={() => setShowCompanies(false)}>
              <CompaniesItemWrapper>
                <ItemWrapper>
                  <Icon icon="building" type="solid" size="1rem" />
                  <Text>{translations.changeCompany}</Text>
                </ItemWrapper>
                <Companies open={showCompanies}>
                  {companies.map(company => (
                    <Company
                      key={company.shortName}
                      onClick={() => {
                        window.location.href = `/c/${company.shortName}/dashboard`
                      }}
                    >
                      {company.fullName}
                    </Company>
                  ))}
                </Companies>
              </CompaniesItemWrapper>
            </DetectBlur>
          )
        },
        onClick() {
          setShowCompanies(!showCompanies)
        },
      },
      {
        id: 'log-out',
        content: () => {
          return (
            <ItemWrapper>
              <Icon icon="sign-out" type="solid" size="1rem" />
              <Text>{translations.logOut}</Text>
            </ItemWrapper>
          )
        },
        clickable: true,
        onClick: (close: () => void) => {
          removeLoginToken()
          window.location.href = '/login'
          close()
        },
      },
    ],
  }

  return (
    <DetectBlur
      active={showPopup}
      onClickOutside={() => {
        setShowPopup(!showPopup)
      }}
    >
      <SettingsWrapper>
        <UserThumbOrInitials
          size={58}
          user={user}
          cursor="pointer"
          color="primary"
          fontWeight="600"
          backgroundColor="secondary"
          onClick={() => {
            setShowPopup(!showPopup)
          }}
        />
        <Popup
          show={showPopup}
          renderHeader={config.header}
          renderItems={config.listItems}
          width="250px"
          right={mobile ? '0.5rem' : undefined}
          onClose={() => setShowPopup(false)}
        />
      </SettingsWrapper>
    </DetectBlur>
  )
}
