import { UserNode } from 'modules/users'
import { UserProfileLink } from 'modules/users/UserProfile/UserProfileLink'
import styled from 'styled-components'
import { safeParsePhoneNumber } from 'util/parsing'
import { Cell } from '../Cell'

/* UserCell */

const StyledUserCell = styled(Cell)`
  div {
    font-style: normal;
    font-weight: 600;
  }
  a.phone {
    font-size: 0.8em;
    color: ${props => props.theme.colors.gray6};

    &:hover {
      text-decoration: none;
      color: ${props => props.theme.colors.secondary};
    }
  }
`
interface UserCellProps {
  user: Pick<UserNode, 'id' | 'fullName' | 'phoneNumber'>
}
export const UserCell: React.VFC<UserCellProps> = ({ user }) => {
  const phone = safeParsePhoneNumber(user.phoneNumber)

  return (
    <StyledUserCell>
      <div>
        <UserProfileLink user={user} />
      </div>
      {!!user.phoneNumber && (
        <a className="phone" href={`tel:${user.phoneNumber}`}>
          {phone}
        </a>
      )}
    </StyledUserCell>
  )
}
