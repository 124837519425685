import { Table } from 'components'
import { DynamicColumnNode } from 'modules/dynamic_tables'
import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { format } from 'util/date-fns'
import { ActivitiesWeekTableRow, ActivitiesWeekTableSubRow } from './ActivitiesWeekTableRow'
import { ActivitiesWeekTableSummation } from './ActivitiesWeekTableSummation'
import { parseISO } from 'date-fns'
import { WeekReport } from 'modules/activities/types'
import { isMobile } from 'react-device-detect'

interface ActivitiesWeekTableProps {
  week: WeekReport
  dateStart: Date
  dateEnd: Date
  userId: string
  columns?: DynamicColumnNode[]
}

export const ActivitiesWeekTable: React.VFC<ActivitiesWeekTableProps> = ({
  week,
  dateStart,
  dateEnd,
  userId,
  columns = [],
}) => {
  const history = useHistory()

  const tableHeaders = useMemo(
    () => (
      <tr>
        {columns.map(column => (
          <th key={column.name}>{column.displayName || column.name}</th>
        ))}
      </tr>
    ),
    [columns]
  )

  const activityRows = useMemo(() => {
    return week.dayReports.map(dayReport => 
      <ActivitiesWeekTableRowWrapper
        key={dayReport.date}
        dayReport={dayReport}
        userId={userId}
        columns={columns}
        dateStart={dateStart}
        dateEnd={dateEnd}
        history={history}
      />
    )
  }, [week.dayReports, userId, columns, dateStart, dateEnd, history])

  return (
    <Table headerMargin rowHoverBackground="gray10">
      <thead>{tableHeaders}</thead>

      <tbody style={{position: 'relative'}}>
        {activityRows}
        <ActivitiesWeekTableSummation week={week} columns={columns} />
      </tbody>
    </Table>
  )
}

interface ActivitiesWeekTableRowWrapperProps {
  dayReport: WeekReport['dayReports'][0]
  userId: string
  columns: DynamicColumnNode[]
  dateStart: Date
  dateEnd: Date
  history: any
}

const ActivitiesWeekTableRowWrapper: React.VFC<ActivitiesWeekTableRowWrapperProps> = ({
  dayReport,
  userId, 
  columns, 
  dateStart,
  dateEnd,
  history
}) => {
  const [open, setOpen] = useState(false)
  const date = parseISO(dayReport.date)
  const dateUrl = format(date, 'yyyy-MM-dd')
  const redirectUrl = dayReport.absence
    ? `/absences?userId=${userId}&date=${dateUrl}`
    : `/activities/${userId}/${dateUrl}`

  return (
    <>
      <ActivitiesWeekTableRow
        key={dateUrl}
        day={dayReport}
        userId={userId}
        dateStart={dateStart}
        dateEnd={dateEnd}
        columns={columns}
        onClick={() => history.push(redirectUrl)}
        onOpen={() => setOpen(!open)}
        open={open}
      />
      {dayReport.groupedActivities.length > 1 && dayReport.groupedActivities.map((_, index) => (
        <ActivitiesWeekTableSubRow
          key={`${dateUrl}-${index}`}
          open={isMobile ? false : open}
          index={index}
          day={dayReport}
          userId={userId}
          dateStart={dateStart}
          dateEnd={dateEnd}
          columns={columns}
          onClick={() => history.push(redirectUrl)}
          onOpen={() => setOpen(!open)}
        />
      ))}
    </>
    
  )
}
