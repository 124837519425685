import styled from 'styled-components'

interface TabPaneProps {
  active: boolean
}

export const TabPane = styled.div<TabPaneProps>`
  display: none;
  ${props => props.active && 'display: block;'}
`
