import { gql } from '@apollo/client'
import { NotificationFragments } from './fragments'

export const CHANGE_APP_NOTIFICATION_STATUS_MUTATION = gql`
  mutation ChangeAppNotificationStatusMutation(
    $id: ID!
    $newStatus: AppNotificationStatusInput!
  ) {
    changeAppNotificationStatus(id: $id, newStatus: $newStatus) {
      appNotification {
        ...AppNotificationInfo
      }
    }
  }
  ${NotificationFragments.appNotificationInfo}
`

export const BATCH_CHANGE_APP_NOTIFICATION_STATUS_MUTATION = gql`
  mutation BatchChangeAppNotificationStatusMutation(
    $ids: [ID]!
    $newStatus: AppNotificationStatusInput!
  ) {
    batchChangeAppNotificationStatus(ids: $ids, newStatus: $newStatus) {
      appNotifications {
        ...AppNotificationInfo
      }
      user {
        id
        unseenNotificationCount
      }
    }
  }
  ${NotificationFragments.appNotificationInfo}
`
