import { Table } from 'components'
import { DynamicTableSettingsProps } from './types'
import { DynamicColumnNode } from 'modules/dynamic_tables'
import styled from 'styled-components'
import { useTranslate } from '@ur/react-hooks'
import { TableSummation } from 'components'
import { Icon, usePrompt, useToast } from '@ur/react-components'
import {
  DynamicColumnForm,
  EditDynamicColumnModal,
} from './EditDynamicColumnModal'

const Wrapper = styled.div`
  & > div {
    margin-top: 1rem;
  }
`

interface DynamicHeaderProps {
  disabled: boolean
}

const DynamicHeader = styled.th<DynamicHeaderProps>`
  padding-right: 0 !important;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  & > div {
    display: flex;
    align-items: center;
    span {
      text-overflow: hidden;
    }
    i {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.3s linear;
    }
  }
  &:first-child > div {
    margin-right: 8px;
  }

  &:hover {
    i {
      visibility: visible;
      opacity: 1;
    }
  }
`

const ActivitiesSummation = styled(TableSummation)`
  height: 5.5rem;
  td {
    padding-top: 1rem !important;
  }

  &:hover {
    background: inherit !important;
  }

  ${props => props.theme.media.mobile} {
    td:first-of-type div {
      margin-left: 1rem !important;
    }
    td:last-of-type div {
      margin-right: 1rem !important;
    }
  }
`

export const DynamicTablePreview: React.VFC<DynamicTableSettingsProps> = ({
  columns = [],
  forExport,
  onEdit,
}) => {
  const translations = useTranslate({
    changeName: 'commmon.edit-name',
    preview: 'settings.tables.preview',
    exportPreview: 'settings.export-tables.preview',
    error: {
      minimumColumns: 'settings.show-dynamic-column-error',
    },
  })

  const addToast = useToast()
  const addPrompt = usePrompt()

  const enabledColumns = columns.filter(column =>
    forExport ? column.inExport : column.enabled
  )

  async function handleEdit(column: DynamicColumnNode) {
    const { data } = await addPrompt<DynamicColumnForm | null>(resolve => (
      <EditDynamicColumnModal
        column={column}
        disableToggle={enabledColumns.length < 3}
        onSubmit={resolve}
      />
    ))
    if (data === null) return
    const { displayName, enabled } = data

    if (displayName !== column.displayName || enabled !== column.enabled) {
      onEdit?.(column.id, { displayName, enabled })
    }
  }

  function disableColumnExport(column: DynamicColumnNode) {
    if (enabledColumns.length < 3) {
      addToast('error', translations.error.minimumColumns)
      return
    }
    onEdit?.(column.id, { inExport: false })
  }

  const columnIcon = forExport ? (
    <Icon icon="times" cursor="pointer" color="error" margin="0 0 0 8px" />
  ) : (
    <Icon icon="edit" cursor="pointer" color="secondary" margin="0 0 0 8px" />
  )

  const preview = forExport ? translations.exportPreview : translations.preview

  return (
    <Wrapper>
      <h4>{preview}</h4>
      <Table headerMargin rowHoverBackground="gray10">
        <thead>
          <tr>
            {enabledColumns.map(column => (
              <DynamicHeader
                disabled={column.name === 'date'}
                onClick={() =>
                  column.name !== 'date' &&
                  (forExport ? disableColumnExport(column) : handleEdit(column))
                }
                key={column.name}
              >
                <div>
                  <span>{column.displayName || column.name}</span>
                  {column.name !== 'date' && columnIcon}
                </div>
              </DynamicHeader>
            ))}
          </tr>
        </thead>
        <tbody>
          <ActivitiesSummation>
            {enabledColumns.map(column =>
              column.columnType === 'TIME' ? (
                <td key={'sum-' + column.name}>
                  <div>
                    <span>{column.displayName || column.name}</span>
                    00:00
                  </div>
                </td>
              ) : (
                <td key={'sum-' + column.name}>
                  <div />
                </td>
              )
            )}
          </ActivitiesSummation>
        </tbody>
      </Table>
    </Wrapper>
  )
}
