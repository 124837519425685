import React from 'react'
import styled from 'styled-components'
import {
  TextArea as BaseTextArea,
  TextAreaProps as BaseTextAreaProps,
} from '@ur/react-components'

const StyledTextArea = styled(BaseTextArea)`
  textarea {
    padding: 1em;
    font-size: 1.2em;
    border-color: ${props => props.theme.colors.gray9};

    &::placeholder {
      color: ${props => props.theme.colors.gray5};
    }
    &:focus {
      border-color: ${props => props.theme.colors.secondary};
    }
  }
`

interface TextAreaProps extends BaseTextAreaProps {}

export const TextArea: React.VFC<TextAreaProps> = ({ ...textAreaProps }) => {
  return <StyledTextArea background="quaternary" {...textAreaProps} />
}
