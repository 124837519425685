import { TimezoneData } from 'modules/activities'
/**
 * @param timezoneData
 * @returns difference between local timezone for the date and timezone of the TimezoneData as hour.
 */
export function getLocaleCorrectionOffset(
  timezoneData: TimezoneData,
  date: Date
): number {
  const { offset } = timezoneData
  const localOffset = date.getTimezoneOffset() / -60
  const correctionOffset = offset - localOffset
  return correctionOffset
}

/**
 * Corrects date to be the same time as the provided timezone for the local timezone. This is useful
 * when you want to display a date in a different timezone than the local timezone and it's created
 * from a date string without timestamp and timezone.
 *
 * @param date
 * @param timezoneData
 * @returns date corrected to offset timezone difference between local timezone and provided timezone.
 *
 */
export function correctLocalDateWithTimezone(
  date: Date,
  timezoneData: TimezoneData | null | undefined
): Date {
  if (!timezoneData) {
    return date
  }
  const correctedDate = new Date(
    date.getTime() +
      getLocaleCorrectionOffset(timezoneData, date) * 60 * 60 * 1000
  )

  return correctedDate
}
