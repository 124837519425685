import { VehicleDetailNode, VehicleOrdering } from './types.graphql'

/* Forms */
export interface EditVehicleForm {
  id: string
}

export interface VehicleTableFilter {
  query: string
  orderBy: string
  owned: boolean
}

export interface VehicleFilter {
  query?: string
  owned: boolean
  orderBy: VehicleOrdering
  excludeTypes: string[]
}

/* Forms */
export interface CreateVehicleForm {
  vin: string
}

export interface CreateVehicleModalResolve {
  form: CreateVehicleForm
}

export interface CreateVehicleByRegNrForm {
  registrationNumber: string
}

export interface CreateVehicleByRegNrModalResolve {
  form: CreateVehicleByRegNrForm
}

export interface EditVehicleDocumentForm {
  name: string
  description: string
  expirationDate: Date | null
  notificationTime: number | null
  file: File | string | null
  responsibleUser: string | null
}

export interface EditVehicleDocumentPromptResolve {
  form: EditVehicleDocumentForm
  hasNotification: boolean
}

export interface EditVehicleExpirationForm {
  description: string
  expirationDate: Date | null
  notificationTime: number
  expirationType: string
  responsibleUser: string | null
}
export interface EditVehicleExpirationPromptResolve {
  form: EditVehicleExpirationForm
  hasNotification: boolean
}

export interface ExpirationTypes {
  [key: string]: string
  adr: string
  nextAdrControl: string
  nextTailLiftControl: string
  nextEuControl: string
  nextFireExtinguisherControl: string
  nextCraneControl: string
  nextPrinterCalibration: string
  expirationLeasing: string
  expirationServiceAgreement: string
}

/* Vehicle view */
export interface VehicleDetailInput {
  key: keyof VehicleDetailNode
  unit?: string
  label?: string
  type?: string
  readOnly?: boolean
  options?: {
    label: string
    value: any
  }[]
  dependencies?: {
    requireFields?: string[]
  }
}

export type VehicleDetailInputDependency = 'require'

type Nullable<T> = { [P in keyof T]?: T[P] | null }
export interface EditVehicleDetailsForm
  extends Nullable<Omit<VehicleDetailNode, 'registrationType'>> {
  registrationDate?: Date | null | undefined
  soldDate?: Date | null | undefined
  nextPeriodicControl?: Date | null | undefined
  previousPeriodicControl?: Date | null | undefined
}

export interface EditVehicleDetailsModalResolve {
  [key: string]: EditVehicleDetailsForm
  form: EditVehicleDetailsForm
}

export enum VehicleDetailFormField {
  Date = 'date',
  Boolean = 'boolean',
  Number = 'number',
  Country = 'country',
  Select = 'select',
  Radio = 'radio',
}
