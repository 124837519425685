import { useForm } from '@ur/react-hooks'
import { endOfMonth, formatISO, startOfMonth } from 'date-fns'
import { ExportFormat, ExportOption, ExportType } from 'modules/exports'
import { useUser, useCompany } from 'util/hooks'
import { isInvalidDate } from 'util/time'
import { useExportIssuesMutations } from './mutations.hooks'
import { ExportIssueForm, ExportIssuesMassForm } from './types'

export function useIssueExportForm(id: string, onCompleted?: () => void) {
  const me = useUser()

  const { exportIssue, loading: mailLoading } =
    useExportIssuesMutations(onCompleted)

  const {
    formValues: form,
    submitHandler: submit,
    updateForm,
  } = useForm<ExportIssueForm>({
    values: {
      recipients: [],
      exportOption: ExportOption.DOWNLOAD,
      exportType: ExportType.ACTIVITIES,
      exportFormat: ExportFormat.PDF,
    },
  })

  function handler<K extends keyof ExportIssueForm>(key: K) {
    return (value: ExportIssueForm[K] | null) => {
      if (value === null) return
      updateForm({ [key]: value })
    }
  }

  async function handleExport(values: ExportIssueForm) {
    exportIssue({
      variables: {
        userId: me.id,
        reportId: id,
        recipients: values.recipients,
        exportType: values.exportType,
        exportFormat: values.exportFormat,
        exportOption: values.exportOption,
      },
    })
  }

  return {
    form,
    loading: mailLoading,
    mailLoading,

    handler,
    updateForm,
    submit,
    handleExport,
  }
}

export function useMassIssueExportForm(onCompleted?: () => void) {
  const me = useUser()
  const company = useCompany()

  const { exportIssuesMass, loading: formLoading } =
    useExportIssuesMutations(onCompleted)

  const {
    formValues: form,
    submitHandler: submit,
    updateForm,
  } = useForm<ExportIssuesMassForm>({
    values: {
      recipients: [],
      dateStart: new Date(),
      dateEnd: new Date(),
      month: new Date(),
      exportType: ExportType.ISSUES,
      exportOption: ExportOption.SAVE,
      exportFormat: ExportFormat.PDF,
      useDateRange: false,
      user: null,
      includeArchived: false,
    },
    config: {
      storage: {
        storeFormState: true,
        retrieveFormStateOnMount: true,
        storeFormStateName: 'Export-issues-mass-form',
        excludeFields: ['recipients'],
        transformValues: {
          month: value =>
            typeof value === 'string'
              ? isInvalidDate(new Date(value), new Date())
              : undefined,
        },
      },
    },
  })

  function handler<K extends keyof ExportIssuesMassForm>(key: K) {
    return (value: ExportIssuesMassForm[K] | null) => {
      if (value === null) return
      updateForm({ [key]: value })
    }
  }

  async function handleExport(values: ExportIssuesMassForm) {
    const date = form.useDateRange ? form.dateStart : startOfMonth(values.month)
    const [dateStart, dateEnd] = getDateInterval(date, form.dateEnd)
    exportIssuesMass({
      variables: {
        recipients: values.recipients,
        exportType: values.exportType,
        exportFormat: values.exportFormat,
        userId: me.id,
        companyId: company.id,
        dateStart: dateStart,
        dateEnd: dateEnd,
        exportOption: values.exportOption,
        includeArchived: values.includeArchived,
      },
    })
  }

  function getDateInterval(startDate: Date, endDate?: Date) {
    if (form.useDateRange) {
      const dateEnd = endDate || new Date()
      return [formatISO(startDate), formatISO(dateEnd)]
    }
    return [
      formatISO(startOfMonth(startDate)),
      formatISO(endOfMonth(startDate)),
    ]
  }

  return {
    form,
    loading: formLoading,
    handler,
    updateForm,
    submit,
    handleExport,
    getDateInterval,
  }
}
