import styled from 'styled-components'
import { Title } from 'components/Title'
import { useTranslate } from '@ur/react-hooks'
import { useTerminalQueries } from '../hooks'
import { TerminalViewTable } from './TerminalViewTable'

const TextField = styled.div`
  line-height: 1.4;
  padding: 1rem 1.25rem;
`

interface AllTerminalEntriesFilteredProps {
  userId: string
  userName: string
  month: Date
}
export const AllTerminalEntriesFiltered: React.VFC<AllTerminalEntriesFilteredProps> =
  ({ userId, userName, month }) => {
    const translations = useTranslate({
      emptyTitle: ['terminal.user-activity', { user: userName }],
      emptyText: ['terminal.user-no-activity-period', { user: userName }],
    })

    const { terminalEntries, absenceEntries, loading } = useTerminalQueries(
      userId,
      month
    )

    return !loading &&
      terminalEntries.length === 0 &&
      absenceEntries.length === 0 ? (
      <TextField>
        <Title.H4 margin="0 0 1.33rem 0" fontSize="1rem">
          {translations.emptyTitle({ user: userName })}:
        </Title.H4>
        <i>{translations.emptyText({ user: userName })}.</i>
      </TextField>
    ) : (
      <TerminalViewTable
        terminalEntries={terminalEntries}
        absenceEntries={absenceEntries}
        loading={loading}
      />
    )
  }
