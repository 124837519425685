import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import {
  GoogleMap as GMap,
  GoogleMapProps as BaseGoogleMapProps,
  MarkerProps,
  Marker,
} from '@react-google-maps/api'
import merge from 'lodash/merge'
import { Loader } from '@googlemaps/js-api-loader'
import { GOOGLE_API_KEY } from 'util/env'
import { useLanguage } from 'util/hooks/useLanguage'

export interface LatLng {
  lat: number
  lng: number
}

export interface GoogleMapRef {
  map: google.maps.Map | null
}

const googleLoader = new Loader({
  apiKey: GOOGLE_API_KEY,
  libraries: ['places'],
})

interface GoogleMapProps {
  center?: LatLng
  zoom?: number

  options?: google.maps.MapOptions
  mapContainerStyle?: React.CSSProperties

  markers?: MarkerProps[]

  onLoad?: BaseGoogleMapProps['onLoad']
  onClick?: (event: google.maps.MapMouseEvent) => void
  onZoomChanged?: (zoom: number) => void
}

export const GoogleMap = React.forwardRef<GoogleMapRef, GoogleMapProps>(
  (
    {
      center = {
        lat: 63.2574549,
        lng: 11.1844929,
      },
      zoom = 7.75,

      options = {},
      mapContainerStyle = {},

      markers = [],

      onLoad,
      onClick,
      onZoomChanged,
    },
    ref
  ) => {
    const [scriptLoaded, setScriptLoaded] = useState(false)
    const [map, setMap] = useState<google.maps.Map | null>(null)
    const [language] = useLanguage()

    useImperativeHandle(ref, () => ({
      map,
    }))

    useEffect(() => {
      //@ts-ignore
      googleLoader.language = language
      googleLoader.load().then(() => setScriptLoaded(true))

      // the loader cannot be reloaded with new options no matter what. Only a full reload of the
      // page will be allowed by the google maps library to instanciate a new language option.
      // eslint-disable-next-line
    }, [])

    const handleLoad = useCallback(
      (map: google.maps.Map) => {
        setMap(map)
        onLoad?.(map)
      },
      [onLoad]
    )

    const handleUnmount = useCallback(() => {
      setMap(null)
    }, [])

    const handleZoomChanged = useCallback(() => {
      onZoomChanged?.(map?.getZoom() ?? 8)
    }, [map, onZoomChanged])

    const style = merge(
      {
        width: '100%',
        height: '500px',
      },
      mapContainerStyle
    )


    if (!scriptLoaded) return null

    

    
    return (
      <GMap
        center={center}
        zoom={zoom}
        mapContainerStyle={style}
        options={options}
        onLoad={handleLoad}
        onUnmount={handleUnmount}
        onClick={onClick}
        onZoomChanged={handleZoomChanged}
      >
        {markers.map((marker, index) => (
          <Marker key={index} {...marker} />
        ))}
      </GMap>
    )
  }
)
