import {
  SpinnerLoaderProps,
  DotsLoaderProps,
  Loader,
} from '@ur/react-components'
import merge from 'lodash/merge'
import React, { ReactText, useCallback } from 'react'

export type LoadingPlaceholderContent =
  | ReactText
  | JSX.Element
  | null
  | undefined

interface LoaderProps {
  spinner?: SpinnerLoaderProps
  dots?: DotsLoaderProps
}

export function useLoadingPlaceholder(
  loading: boolean,
  loaderProps: Partial<LoaderProps> = {}
) {
  const spinner = useCallback(
    (content: LoadingPlaceholderContent, props?: SpinnerLoaderProps) => {
      const merged = merge(loaderProps.spinner, props)

      if (loading) return <Loader.Spinner {...merged} />
      return content
    },
    [loaderProps.spinner, loading]
  )

  const dots = useCallback(
    (content: LoadingPlaceholderContent, props?: DotsLoaderProps) => {
      const merged = merge(loaderProps.dots, props)

      if (loading) return <Loader.Dots {...merged} />
      return content
    },
    [loaderProps.dots, loading]
  )

  return {
    spinner,
    dots,
  }
}
