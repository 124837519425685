import { AbsenceCell, CheckmarkCell, VehicleCell } from 'components/Table/cells'
import { isBefore, isSameDay, parseISO, subDays } from 'date-fns'
import { stripSecondsFromDuration } from 'modules/activities/util'
import { ColumnNames, DynamicColumnNode, DynamicColumnType } from 'modules/dynamic_tables'
import styled, { css, useTheme } from 'styled-components'
import { format } from 'util/date-fns'
import { stopPropagation } from 'util/dom'
import { ActivitiesStatusCell } from './ActivitiesStatusCell'
import {
  ShallowDriverActivityDayReport,
  ActivitiesDynamicColumnDayReport,
} from 'modules/activities/types.graphql'
import { Icon } from '@ur/react-components'
import { ZIndexRange } from 'types/style'
import { isMobile } from 'react-device-detect'
import { SalaryCodeCell } from './SalaryCodeCell'
import { DietCell } from './DietCell'
import { SalaryDayCell } from './SalaryDayCell'
import { DepartmentNode } from 'modules/companies'
import { DepartmentCell } from 'components/Table/cells/DepartmentCell'

interface ActivityRowProps {
  day: ShallowDriverActivityDayReport
  dateStart: Date
  dateEnd: Date
  columns: DynamicColumnNode[]
  open: boolean
  userId: string
  onClick: () => void
  onOpen: (open: boolean) => void
}

interface RowProps {
  isActive: boolean
  isAbsence: boolean
}
export const Row = styled.tr<RowProps>`
  height: 68px;
  cursor: pointer;

  ${props =>
    !props.isActive &&
    css`
      background-color: ${props.theme.colors.gray11};
      color: ${props.theme.colors.gray6} !important;
    `};
  ${props =>
    props.isActive &&
    props.isAbsence &&
    css`
      background-color: ${props.theme.colors.red12};
      &:hover {
        color: ${props.theme.colors.red};
      }
    `}
`

const InlineVehicleDropdownButton = styled.span<RowProps & { active: boolean}>`
  display: inline-block;
  position: absolute;
  background-color: ${props => props.theme.colors.white};
  height: 68px;
  width: 1.5rem;
  padding-top: 25px;
  padding-left: 0.275rem;
  content: '';
  margin: 0;
  cursor: pointer;
  z-index: ${ZIndexRange.Overlays};

  :hover {
    background-color: ${props => props.theme.colors.gray10};
  }

  ${props =>
    !props.isActive &&
    css`
      background-color: ${props.theme.colors.gray11};
      color: ${props.theme.colors.gray6} !important;
    `};
  ${props =>
    props.isActive &&
    props.isAbsence &&
    css`
      background-color: ${props.theme.colors.red12};
      &:hover {
        color: ${props.theme.colors.red};
      }
    `}
`

const SubRow = styled.tr<RowProps & {open: boolean}>`
  height: 24px;
  cursor: pointer;
  display: none;

  ${props => props.open && css`
    display: table-row;
  `}

  background-color: ${props => props.theme.colors.gray11};

  ${props =>
    !props.isActive &&
    css`
      color: ${props.theme.colors.gray6} !important;
    `};
  ${props =>
    props.isActive &&
    props.isAbsence &&
    css`
      &:hover {
        color: ${props.theme.colors.red};
      }
    `}
`

const Time = styled.td.attrs({
  className: 'center',
  width: '1px',
})``

interface TimeAndLocationProps {
  hasData: boolean
}
const TimeAndLocation = styled.td<TimeAndLocationProps>`
  position: relative;
  max-width: 180px;
  ${props => props.hasData && 'cursor: auto;'};

  & > div {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    font-size: 1rem;

    span {
      &.time {
        font-weight: 600;
      }
      &.location {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 0.9em;
        font-weight: 400;
        color: ${props => props.theme.colors.gray5};
      }
    }

    &.popup {
      display: none;
      min-width: 100%;
      max-width: 100%;
    }
  }

  &:hover > div.popup {
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
    z-index: ${ZIndexRange.Overlays};

    display: flex;
    padding: 1.5rem;

    background: white;
    border-radius: ${props => props.theme.sizes.defaultBorderRadius};
    box-shadow: ${props => props.theme.shadow.default};

    span.location {
      white-space: normal;
      color: ${props => props.theme.colors.text.dark};
    }
  }
`

/**
 * Creates an array of components corresponding to the Enum type of a DynamicColumn.
 *
 * @param report - the current DayReport (row)
 * @param columns - the DynamicColumns that are currently enabled
 * @returns Array of column components
 */
function computeColumns(
  report: ActivitiesDynamicColumnDayReport,
  columns: DynamicColumnNode[],
  subColumn: boolean = false,
) {
  const date = parseISO(report.date)
  return columns.map(column => {
    switch (column.columnType) {
      case DynamicColumnType.DATE:
        if (subColumn){
          return <td key={column.name}></td>
        }
        const value = parseISO(report[column.name] as string)
        const dateFormatted = format(value, 'dd. MMM')
        return (
          <td key={column.name} width="1px">
            {dateFormatted}
          </td>
        )
      case DynamicColumnType.PLATE_NUMBER:
        if (subColumn) {
          return <VehicleCell key={column.name} plates={report.regNr ? [report.regNr] : []}/>
        }
        return <VehicleCell key={column.name} plates={report.registrationNumbers}/>
      case DynamicColumnType.TIME:
        return (
          <Time key={column.name}>
            {stripSecondsFromDuration(report[column.name] as string)}
          </Time>
        )
      case DynamicColumnType.DATETIME_LOCATION:
        if (subColumn){
          const datetime = column.name === 'dayStartTimeAndLocation' ? report.startTime : report.endTime
          if (typeof datetime === 'string') {
            const datetimeFormatted = datetime
            ? format(new Date(datetime), 'HH:mm')
            : null
          
            if (datetimeFormatted === '00:00' && column.name === 'dayEndTimeAndLocation') {
              return <td key={column.name}>23:59</td>
            }
            return <td key={column.name}>{datetimeFormatted}</td>
          }
          return <td key={column.name}>{datetime}</td>
        }
        return resolveDatetimeLocation(report, column)
      case DynamicColumnType.DATETIME:
        const columnName = column.name === ColumnNames.START_TIME
          ? 'startTime' 
          : column.name === ColumnNames.END_TIME
            ? 'endTime' 
            : column.name
        const datetime = report[columnName]
        if (typeof datetime === 'string') {
          const datetimeFormatted = datetime
            ? format(new Date(datetime), 'HH:mm')
            : null
          
          if (datetimeFormatted === '00:00' && column.name === 'dayEndTime') {
            return <td key={column.name}>23:59</td>
          }
          return <td key={column.name}>{datetimeFormatted}</td>
        }
        return <td key={column.name}>{datetime}</td>
      case DynamicColumnType.CHECKMARK:
        if (typeof report.approved !== 'undefined')
          return (
            <CheckmarkCell
              key={column.name}
              checked={report.approved}
              day={date}
              userId={report.userId}
            />
          )
        return null
      case DynamicColumnType.STATUS:
        if (column.name === 'dataStatus') {
          const theme = useTheme()
          const dataStatusColor = report.dataStatus === null ? theme.colors.red : report.dataStatus ? theme.colors.orange : theme.colors.green
          if (isSameDay(date, new Date())) {
            return (
              <ActivitiesStatusCell
                key={column.name}
                dataStatusColor={theme.colors.orange}
              />
            )
            
          } else if (!isBefore(date, subDays(new Date(), 1))) {
            return <td></td>
          }else {
            return (
              <ActivitiesStatusCell
                key={column.name}
                dataStatusColor={dataStatusColor}
              />
            )
          }
        } 
        return null
      case DynamicColumnType.STRING:
        if (column.name === ColumnNames.SALARY_ID || column.name === ColumnNames.SALARY_NAME) {
          return <SalaryCodeCell report={report} subColumn={subColumn} key={column.name} useExternalId={column.name === ColumnNames.SALARY_ID}/>
        }
        if (column.name === ColumnNames.DIET) {
          return <DietCell report={report} key={column.name} subColumn={subColumn}/>
        }
        if (column.name === ColumnNames.SALARY_DAY) {
          return <SalaryDayCell report={report} key={column.name} subColumn={subColumn}/>
        }
        if (column.name === ColumnNames.DEPARTMENT && Array.isArray(report?.departments)) {
          const departments = report.departments as DepartmentNode[]
          if (subColumn) {
            const department = (report[column.name] as DepartmentNode | null)?.name
            return <DepartmentCell key={column.name} departmentNames={department ? [department] : []}/>
          }
          return <DepartmentCell key={column.name} departmentNames={departments.map(department => department?.name)}/>
        }
        return <td key={column.name}>{report[column.name]}</td>
      default:
        return <td key={column.name}>{report[column.name]}</td>
    }
  })
}

/**
 * A resolver for computing a TimeAndLocation component. The component computes values based on others in a DayReport, and
 * therefore requires special handling.
 *
 * @param report - the current DayReport (row)
 * @param column - a dynamic column of type DATETIME_LOCATION
 * @returns A `<TimeAndLocation />` component.
 */
function resolveDatetimeLocation(
  report: ActivitiesDynamicColumnDayReport,
  column: DynamicColumnNode
) {
  let hasData: boolean = false
  let location: string | null = null
  let datetime: string | null = null

  switch (column.name) {
    case 'dayStartTimeAndLocation':
      location = report.dayStartLocation
      datetime = !report.startTime
        ? null
        : format(new Date(report.startTime), 'HH:mm')
      break
    case 'dayEndTimeAndLocation':
      location = report.dayEndLocation
      datetime = !report.endTime
        ? null
        : format(new Date(report.endTime), 'HH:mm')
      break
    default:
      break
  }

  hasData = !!datetime && !!location

  return (
    <TimeAndLocation
      key={column.name}
      hasData={hasData}
      onClick={hasData ? stopPropagation : () => void 0}
    >
      <div className="flat">
        <span className="time">{datetime}</span>
        <span className="location">{location}</span>
      </div>
      {hasData && (
        <div className="popup">
          <span className="time">{datetime}</span>
          <span className="location">{location}</span>
        </div>
      )}
    </TimeAndLocation>
  )
}

export const ActivitiesWeekTableRow: React.VFC<ActivityRowProps> = ({
  day,
  columns,
  dateStart,
  dateEnd,
  open,
  userId,
  onClick,
  onOpen,
}) => {
  const date = parseISO(day.date)
  const isWithinRange = (isBefore(date, dateEnd) && isBefore(dateStart, date)) || isSameDay(date, dateStart)

  const icon = open ? "chevron-down" : "chevron-right"
  const activeDropdown = day.groupedActivities.length > 1

  const computedColumns = day.absence
    ? [
        <td key="date" width="1px">
          {format(date, 'dd. MMM')}
        </td>,
        <AbsenceCell key="regNr" colSpan={columns.length - 1} width="1px">
          <span>{day.absence.reason}</span>
        </AbsenceCell>,
      ]
    : computeColumns(
        {userId: userId, ...(({ absence, ...day }) => day)(day)} as ActivitiesDynamicColumnDayReport,
        columns,
        false,
      )


  return (
    <>
      {activeDropdown && !isMobile &&
        <InlineVehicleDropdownButton 
          active={open}
          isActive={isWithinRange}
          isAbsence={!!day.absence}
          onClick={(e) => {
            if (activeDropdown) {
              e.stopPropagation() 
              onOpen(!open)
            }
            else {
              onClick()
            }
          }} 
        >
          <Icon margin={open ? '0 0.1rem' : '0 0.3rem'} size='10px' icon={icon}/>
        </InlineVehicleDropdownButton>
      }
      
      <Row
        key={date.getTime()}
        role="link"
        isActive={isWithinRange}
        isAbsence={!!day.absence}
        onClick={onClick}
      >
        {computedColumns}
      </Row>
    </>
  )
}


interface ActivitySubRowProps extends ActivityRowProps {
  index: number
  open: boolean
}

export const ActivitiesWeekTableSubRow: React.VFC<ActivitySubRowProps> = ({
  day,
  index,
  columns,
  dateStart,
  dateEnd,
  onClick,
  open
}) => {
  const date = parseISO(day.date)
  const isWithinRange = (isBefore(date, dateEnd) && isBefore(dateStart, date)) || isSameDay(date, dateStart)

  const values = {
    ...(({ absence, registrationNumbers, dayStartLocation, dayEndLocation, ...day }) => day)(day) as Omit<ShallowDriverActivityDayReport, 'absence'>,
    ...day.groupedActivities[index]
  }

  const computedColumns = computeColumns(
    values as ActivitiesDynamicColumnDayReport,
    columns,
    true,
  )

  return (
    <SubRow
      key={date.getTime()}
      open={open}
      role="link"
      isActive={isWithinRange}
      isAbsence={!!day.absence}
      onClick={onClick}
    >
      {computedColumns}
    </SubRow>
  )
}
