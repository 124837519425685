import { useForm, useTranslate } from '@ur/react-hooks'
import { Button as BaseButton, Input, ModuleTitle } from 'components'
import { Card } from 'components/Card'
import { PlainLink } from 'components/Link'

import { PermissionsRequired } from 'containers/permission-containers'
import { isMobile, MobileView } from 'react-device-detect'
import styled from 'styled-components'
import { useDebounce } from 'util/hooks'
import { UsersTable } from './UsersTable'
import { UserDocumentCategoryFilter } from './UsersTable/UserDocumentCategoryFilter'
import { PERMISSIONS } from 'util/permissions'

const Wrapper = styled.div`
  ${props => props.theme.layout.default};
`
const Controls = styled(Card)`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  gap: 1rem;

  margin-bottom: 1rem;
  padding: 1rem;
  ${props => props.theme.media.mobile} {
    display: flex;
    flex-direction: column;
`
const MobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`
const Button = styled(BaseButton)`
  ${props => props.theme.media.mobile} {
    padding: 0 3rem 0 1rem;
  }
`

const Users: React.VFC = () => {
  const translations = useTranslate({
    users: 'common.users',
    newUser: 'users.new',
    searchForUser: 'users.search',
    showDeactivated: 'users.show-deactivated',
  })

  const { formValues: filter, updateForm } = useForm<{
    query: string
    sort: string
    showDeactivated: boolean
    includeExpiredDocuments: boolean
    documentCategorySelection: string[]
  }>({
    values: {
      query: '',
      sort: 'lastName',
      showDeactivated: false,
      includeExpiredDocuments: false,
      documentCategorySelection: [],
    },
    config: {
      storage: {
        storeFormState: true,
        storeFormStateName: 'users-controls',
        retrieveFormStateOnMount: true,
        excludeFields: ['query', 'documentCategorySelection'],
      },
    }
  })


  const debouncedQuery = useDebounce(filter.query)

  return (
    <Wrapper>
      <MobileView>
        <MobileHeader>
          <ModuleTitle inlineHeader>{translations.users}</ModuleTitle>

          <PermissionsRequired permissions="users.add_user">
            <PlainLink to="/users/create">
              <Button
                iconLeftProps={{ icon: 'plus', margin: '0' }}
                height="42px"
                padding="0"
                fontSize="0.8em"
              >
                {translations.newUser}
              </Button>
            </PlainLink>
          </PermissionsRequired>
        </MobileHeader>
      </MobileView>
      {!isMobile && <ModuleTitle>{translations.users}</ModuleTitle>}
      <Controls>
        <Input
          value={filter.query}
          type="search"
          placeholder={translations.searchForUser}
          onChange={value => updateForm({ query: value })}
        />

        <PermissionsRequired permissions={PERMISSIONS.users.add.user}>
          <UserDocumentCategoryFilter 
            showDeactivated={filter.showDeactivated}
            includeExpiredDocuments={filter.includeExpiredDocuments}
            onSelectionChange={(value) => updateForm({
              documentCategorySelection: value
            })}
            onShowDeactivatedChange={(value) => updateForm({
              showDeactivated: value
            })}
            onIncludeExpiredDocumentsChange={(value) => updateForm({
              includeExpiredDocuments: value
            })}
          />
        </PermissionsRequired>

        {!isMobile && (
          <>
            <PermissionsRequired permissions={PERMISSIONS.users.add.user}>
              <PlainLink to="/users/create">
                <Button iconLeftProps={{ icon: 'plus' }}>
                  {translations.newUser}
                </Button>
              </PlainLink>
            </PermissionsRequired>
          </>
        )}
      </Controls>

      <UsersTable
        query={debouncedQuery}
        sort={filter.sort}
        showDeactivated={filter.showDeactivated}
        includeExpiredDocuments={filter.includeExpiredDocuments}
        onSort={value => updateForm({ sort: value })}
        documentCategorySelection={filter.documentCategorySelection}
      />
    </Wrapper>
  )
}

export default Users
