import { gql } from "@apollo/client";

export const LATEST_LOCATIONS_AND_FORECASTS = gql`
    query LatestLocations {
        allUsers {
            edges {
                node {
                    id
                    fullName
                    email
                    phoneNumber
                    lastLocation {
                        id
                        latitude
                        longitude
                        datetimeStart
                        vehicleVin
                    }
                }
            }
        }
        allVehicles {
            edges {
                node {
                    id
                    vin
                    registrationNumber
                }
            }
        }
        allForecasts {
            user {
                id
                fullName
            }
            timestamp
            remainingDaily
            remainingWeekly
            remainingTwoWeekly
        }
    }
`