import { useTranslate } from '@ur/react-hooks'
import { NotificationType } from './consts'
import { AppNotificationNode } from './types'

interface Translations {
  goToThread: string
  goToDocument: string
  goToAbsence: string
}

export const useAppNotificationActions = () => {
  const baseTranslations = useTranslate({
    goToThread: 'notifications.go-to-thread',
    goToDocument: 'notifications.go-to-document',
    goToAbsence: 'notifications.go-to-absence',
  })

  function getAppNotificationActions(
    appNotification: AppNotificationNode,
    translations: Translations = baseTranslations
  ) {
    switch (appNotification.type) {
      default:
      case NotificationType.ANY:
        return []
      case NotificationType.ABSENCE_STATUS_CHANGED:
        return [
          {
            text: translations.goToAbsence,
            link: appNotification.path || '/absences',
          },
        ]
      case NotificationType.FORUM_THREAD_RESPONSE:
        return [
          {
            text: translations.goToThread,
            link: appNotification.path || '/forum',
          },
        ]
      case NotificationType.NEW_FORUM_THREAD:
        return [
          {
            text: translations.goToThread,
            link: appNotification.path || '/forum',
          },
        ]
      case NotificationType.USER_DOCUMENT_ABOUT_TO_EXPIRE:
        return [
          {
            text: translations.goToDocument,
            link: appNotification.path || '/users',
          },
        ]
      case NotificationType.EXPORT_COMPLETED:
        return [
          {
            text: translations.goToDocument,
            link: appNotification.path || '/user-exports',
          },
        ]
    }
  }

  return {
    getAppNotificationActions,
  }
}
