import { useForm, useTranslate } from '@ur/react-hooks'
import client from 'apollo'
import firmadokLoginImage from 'assets/images/firmadok_login_image.jpg'
import firmadokLogo from 'assets/images/firmadok_logo.png'
import { Button, Checkbox, Input } from 'components'
import { Form, FormField } from 'components/Form'
import { Label } from 'components/Label'
import { useMutation } from '@apollo/client'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useOnErrorAuto } from 'util/hooks'
import { LOGIN_MUTATION } from './mutations'
import { LoginMutation, LoginMutationVariables } from './types.graphql'
import { setLoginToken } from './util'

const Layout = styled.div`
  display: grid;
  grid-template-columns: 500px 1fr;
  height: 100%;
  min-height: 600px;

  ${props => props.theme.layout.mobile} {
    display: block;
  }
`

const LoginSection = styled.div`
  padding: 4rem 3rem;
  background-color: #f7f8fc;
  position: relative;

  height: 100%;
  box-sizing: border-box;

  ${props => props.theme.layout.mobile} {
    padding: 48px 24px;
  }
`

const ImageSection = styled.div`
  background: url(${firmadokLoginImage});
  background-size: cover;
  background-position: 50% 50%;

  ${props => props.theme.layout.mobile} {
    display: none;
  }
`

const TitleImage = styled.img`
  height: 50px;
`

const Subtitle = styled.h4`
  font-size: 24px;
  font-weight: normal;
  color: ${props => props.theme.colors.gray3};

  margin-top: 2rem;
  margin-bottom: 4rem;

  b {
    color: ${props => props.theme.colors.black};
    font-weight: 600;
  }

  ${props => props.theme.layout.mobile} {
    margin-bottom: 3rem;
  }
`

const FormFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
`

const Copyright = styled.div`
  position: absolute;
  bottom: 32px;
  left: 48px;
  font-size: 12px;
  color: ${props => props.theme.colors.gray6};
  ${props => props.theme.layout.mobile} {
    left: 24px;
  }
`

const ForgottenPasswordLink = styled(Link)`
  color: ${props => props.theme.colors.gray4};
  font-size: 12px;
  text-decoration: underline;
`

const LoginFormField = styled(FormField)`
  input {
    background-color: white;
  }
`

const LoginForm = styled(Form)`
  display: flex;
  flex-direction: column;

  button {
    align-self: flex-end;
  }
`

export const Login: React.VFC = () => {
  const translations = useTranslate({
    error: {
      wrongUserPassword: 'error.wrong-username-or-password',
    },
    forgotPassword: 'login.forgotten-password',
    form: {
      label: {
        username: 'login.email-or-phone-number',
        password: 'common.password',
        remember: 'login.remember-me',
      },
    },
    login: 'common.log-in',
    subtitle: 'login.subtitle',
  })

  const rememberMe: { rememberMe: boolean; username: string } = JSON.parse(
    localStorage.getItem('rememberMe') ?? '{}'
  )

  const {
    formValues: form,
    formErrors: errors,
    formChangeHandler: handler,
    submitHandler,
    updateFormErrors,
  } = useForm({
    values: {
      username: rememberMe?.username ?? '',
      password: '',
      rememberMe: rememberMe?.rememberMe ?? false,
    },
  })

  const onErrorAuto = useOnErrorAuto()

  const [login, { loading }] = useMutation<
    LoginMutation,
    LoginMutationVariables
  >(LOGIN_MUTATION, {
    onCompleted: data => {
      const ok = data.login.ok

      if (!ok) {
        updateFormErrors({ username: translations.error.wrongUserPassword })
        return
      }

      const token = data.login.token
      setLoginToken(token)
      client.resetStore().then(() => {
        if (form.rememberMe) {
          localStorage.setItem(
            'rememberMe',
            JSON.stringify({
              rememberMe: form.rememberMe,
              username: form.username,
            })
          )
        }
      })
    },
    onError: onErrorAuto(),
  })

  function onSubmit() {
    if (loading) return
    login({
      variables: {
        username: form.username.replace(/\s/g, ''),
        password: form.password,
      },
    })
  }

  return (
    <Layout>
      {/* TODO: Discuss same CompanyLogo as over sidebar here in future? */}
      <LoginSection>
        <TitleImage src={firmadokLogo} />
        <Subtitle>
          {translations.subtitle} <b>Firmadok</b>
        </Subtitle>
        <LoginForm preventDefault onSubmit={submitHandler(onSubmit)}>
          <LoginFormField margin="0 0 1rem 0">
            <Label htmlFor="username">{translations.form.label.username}</Label>
            <Input
              id="username"
              name="username"
              value={form.username}
              error={errors.username}
              autoFocus
              tabIndex={1}
              autoComplete="username"
              fullWidth
              onFocus={() => updateFormErrors({ username: null })}
              onChange={handler('username')}
            />
          </LoginFormField>
          <LoginFormField margin="0 0 0.75rem 0">
            <Label htmlFor="password">{translations.form.label.password}</Label>
            <Input
              id="password"
              name="password"
              type="password"
              value={form.password}
              autoComplete="current-password"
              tabIndex={2}
              fullWidth
              onFocus={() => updateFormErrors({ username: null })}
              onChange={handler('password')}
            />
          </LoginFormField>
          <FormFooter>
            <Checkbox
              checked={form.rememberMe}
              label={translations.form.label.remember}
              tabIndex={3}
              onChange={handler('rememberMe')}
            />
            <ForgottenPasswordLink to="/forgotten-password" tabIndex={5}>
              {translations.forgotPassword}
            </ForgottenPasswordLink>
          </FormFooter>
          <Button type="submit" tabIndex={4} fontSize="16px" disabled={loading}>
            {translations.login}
          </Button>
        </LoginForm>
        <Copyright>&copy; Firmadok AS, {new Date().getFullYear()}</Copyright>
      </LoginSection>
      <ImageSection />
    </Layout>
  )
}
