import { SelectMultiple } from 'components/Select'
import {
  FormErrors,
  FormOnChangeFn,
  UpdateFormFn,
  useTranslate,
} from '@ur/react-hooks'
import { Input, Select } from 'components'
import { UserTypeNode } from 'modules/users'
import React, { useRef } from 'react'
import { ChecklistTemplateForm } from 'modules/checklists'
import { EditChecklistTemplateItems } from 'modules/companies/CompanySettings/components/ChecklistSettings/EditChecklistTemplateItems'
import { Field, Form } from '..'
import { ChecklistMode } from './consts'
import uniqueId from 'lodash/uniqueId'
import { NewChecklistItemPrefix } from '../consts'

interface ChecklistTemplateFormProps {
  form: ChecklistTemplateForm
  errors: FormErrors<ChecklistTemplateForm>
  initialItems: string[]
  userTypes: UserTypeNode[]

  onUpdate: FormOnChangeFn<ChecklistTemplateForm>
  onUpdateForm: UpdateFormFn<ChecklistTemplateForm>
}

export const ChecklistTemplatePromptForm: React.VFC<ChecklistTemplateFormProps> =
  ({
    form,
    errors,
    initialItems,
    userTypes,

    onUpdate,
    onUpdateForm,
  }) => {
    const translations = useTranslate({
      title: 'common.title',
      userTypes: 'common.user-types',
      generateNew: 'checklists.generate-new',
      selectGenerationFrequency: 'settings.select-crontab-setting',
      selectUserTypes: 'users.select-user-types',
      noOptions: 'common.no-more-options',
      fields: 'form.fields',
      mode: {
        daily: 'common.daily',
        weekly: 'common.weekly',
        monthly: 'common.monthly',
      },
    })
    const ref = useRef<HTMLInputElement>(null)
    const modeOptions = [
      {
        value: ChecklistMode.Daily,
        label: translations.mode.daily,
      },
      {
        value: ChecklistMode.Weekly,
        label: translations.mode.weekly,
      },
      {
        value: ChecklistMode.Monthly,
        label: translations.mode.monthly,
      },
    ]

    const onModeChange = (value: ChecklistMode | null) =>
      value
        ? onUpdateForm({
            mode: value,
          })
        : void 0

    const onChecklistTemplateItemChange = (id: string, text: string) => {
      const keys = Object.keys(form.items)
      if (keys.indexOf(id) === keys.length - 1) {
        onUpdateForm({
          items: {
            ...form.items,
            [id]: text,
            [uniqueId(NewChecklistItemPrefix)]: '',
          },
        })
      } else if (keys.indexOf(id) === keys.length - 2 && text === '') {
        onChecklistTemplateItemRemove(id)
        ref.current?.focus()
      } else {
        onUpdateForm({
          items: {
            ...form.items,
            [id]: text,
          },
        })
      }
    }

    const onChecklistTemplateItemRemove = (id: string) => {
      const items = form.items
      delete items[id]
      if (form.itemsRemove && initialItems.includes(id)) {
        const newItemsRemove = form.itemsRemove.concat([{ id: id }])
        onUpdateForm({
          items: {
            ...items,
          },
          itemsRemove: newItemsRemove,
        })
      } else {
        onUpdateForm({
          items: {
            ...items,
          },
        })
      }
    }

    const selectOptions = userTypes.map(userType => ({
      label: userType.name,
      value: userType.id,
    }))

    return (
      <Form>
        <Field>
          <label>{translations.title}</label>
          <Input
            value={form.title}
            error={errors.title}
            onChange={onUpdate('title')}
          />
        </Field>

        <Field>
          <label>{translations.userTypes}</label>
          <SelectMultiple
            selected={form.userTypes}
            error={form.userTypes === null ? '' : ''}
            fullWidth
            options={selectOptions}
            placeholder={translations.selectUserTypes}
            noOptionsText={translations.noOptions}
            onChange={onUpdate('userTypes')}
            grayed
          />
        </Field>

        <Field>
          <label>{translations.generateNew}</label>
          <Select
            value={form.mode}
            placeholder={translations.selectGenerationFrequency}
            options={modeOptions}
            grayed
            fullWidth
            onChange={onModeChange}
          />
        </Field>

        <Field>
          <label>{translations.fields}</label>
          <EditChecklistTemplateItems
            templateItems={form.items}
            errors={errors.items}
            onChange={onChecklistTemplateItemChange}
            onRemove={onChecklistTemplateItemRemove}
            lastElement={ref}
          />
        </Field>
      </Form>
    )
  }
