import ContentLoader from 'react-content-loader'
import range from 'lodash/range'
import { Cell } from 'components/Table/Cell'
import styled from 'styled-components'

const LoaderCell = styled(Cell)`
  align-text: left;
`

export interface TableContentLoaderProps {
  columns?: number
  rows?: number
  expectedWidths?: (string | number)[]
  expectedHeights?: (string | number)[]
}

export const TableContentLoader: React.FC<TableContentLoaderProps> = ({
  columns = 3,
  rows = 3,
  expectedWidths = [],
  expectedHeights = [],
}) => {
  const rowRange = range(0, rows)
  const columnRange = range(0, columns)

  return (
    <>
      {rowRange.map(i => (
        <tr key={i}>
          {columnRange.map(j => (
            <LoaderCell key={j}>
              <ContentLoader
                style={{
                  width: expectedWidths[j] || 'auto',
                  height: expectedHeights[j] || '60px',
                }}
              >
                <rect x="0" y="20" rx="2" ry="2" width="145" height="10" />
              </ContentLoader>
            </LoaderCell>
          ))}
        </tr>
      ))}
    </>
  )
}
