import { Loader } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { Card as BaseCard } from 'components/Card'
import { SVGIcon } from 'components/icons'
import React from 'react'
import styled, { css } from 'styled-components'
import { buttonUnset } from 'util/style'
import { useStatisticsFilter, useStats } from './hooks'

const Wrapper = styled.div`
  max-width: 100%;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ${props => props.theme.layout.mobile} {
    header {
      margin-bottom: 8px;

      h2 {
        margin: 0;
      }
    }
  }
`
const Cards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  max-width: 100%;

  ${props => props.theme.media.custom({ max: '1280px' })} {
    grid-template-columns: 1fr;
  }
  ${props => props.theme.media.mobile} {
    gap: 0.5rem;
  }
`
interface CardProps {
  highlight?: boolean
}
const Card = styled(BaseCard)<CardProps>`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 1rem 1rem 1rem 3rem;
  min-width: min-content;

  color: ${props => props.theme.colors.text.dark};

  & > div {
    display: grid;
    grid-template-rows: 2rem auto;
    padding-right: 3em;

    & > * {
      align-self: center;
    }

    em {
      font-style: normal;
      font-size: 2rem;
      font-weight: 600;
      white-space: nowrap;

      span {
        margin-left: 4px;
        color: ${props => props.theme.colors.gray5};
        font-size: 0.8em;

        &.clickable {
          cursor: pointer;

          &:hover {
            color: ${props => props.theme.colors.gray7};
          }
        }
      }
    }
    p {
      margin: 0;
      font-weight: 500;
      color: ${props => props.theme.colors.gray4};
    }
  }

  span.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.5rem;
    min-width: 4.5rem;
    height: 4.5rem;
    margin: 1.5em 0;

    border-radius: 20px;
    background: ${props => props.theme.colors.quaternary};

    svg {
      width: 32px;
      fill: ${props => props.theme.colors.text.dark};
    }
    &.smaller svg {
      width: 22px;
    }
    ${props =>
      props.highlight &&
      css`
        background-color: ${props => props.theme.colors.red};

        svg {
          fill: white;
        }
      `}
  }

  ${props => props.theme.media.mobile} {
    padding: 12px 12px 12px 24px;

    & > div {
      padding-right: 0.5em;

      em {
        font-size: 1.4em;
      }
      p {
        font-size: 0.7em;
      }
    }
    span.icon {
      min-width: 52px;
      min-height: 52px;
      max-width: 52px;
      max-height: 52px;
      margin: 0.5em 0;
      border-radius: 16px;

      svg {
        width: 26px;
      }
    }
  }
`
interface PeriodButtonProps {
  active: boolean
}
const PeriodButton = styled.button<PeriodButtonProps>`
  ${buttonUnset}

  padding: 10px 2em 6px;

  border-radius: 12px;
  background-color: ${props => props.active && 'rgba(0, 0, 0, 0.075)'};
  cursor: pointer;
  user-select: none;

  ${props =>
    !props.active &&
    css`
      color: ${props.theme.colors.gray4};
    `};

  ${props => props.theme.media.mobile} {
    font-size: 0.8em;
  }
`

interface DashboardStatisticsProps {}

export const DashboardStatistics: React.VFC<DashboardStatisticsProps> = () => {
  const translations = useTranslate({
    statistics: 'common.statistics',
    statisticsThisWeek: 'common.statistics-this-week',

    km: ['common.kilometers', { abbreviate: true }],
    kilometersDriven: 'statistics.kilometers-driven',
    numberOfIssues: 'statistics.number-of-issues',
    avgFuelConsumption: 'statistics.average-fuel-consumption',
    driverGrade: 'statistics.driver-grade',

    l10km: 'statistics.l-10-km',
    l100km: 'statistics.l-100-km',

    user: 'common.user',
    company: 'common.company',
  })

  const { statType, setStatType, show100km, setShow100km } =
    useStatisticsFilter()

  const { kilometers, numIssues, fuelEco, driverGrade, loading } = useStats(
    statType,
    show100km
  )

  const unlessLoading = (node: React.ReactNode) =>
    loading ? <Loader.Dots /> : node

  return (
    <Wrapper>
      <header>
        <h2>{translations.statisticsThisWeek}</h2>

        <div>
          <PeriodButton
            active={statType === 'user'}
            onClick={() => setStatType('user')}
          >
            {translations.user}
          </PeriodButton>
          <PeriodButton
            active={statType === 'company'}
            onClick={() => setStatType('company')}
          >
            {translations.company}
          </PeriodButton>
        </div>
      </header>

      <Cards>
        <Card>
          <div>
            {unlessLoading(
              <em>
                {kilometers}
                {kilometers !== 'N/A' && (
                  <span>{translations.km({ abbreviate: true })}</span>
                )}
              </em>
            )}
            <p>{translations.kilometersDriven}</p>
          </div>

          <SVGIcon className="icon" icon="truck-side" />
        </Card>

        <Card highlight={numIssues > 0}>
          <div>
            {unlessLoading(<em>{numIssues}</em>)}
            <p>{translations.numberOfIssues}</p>
          </div>

          <SVGIcon className="icon" icon="warning" />
        </Card>

        <Card>
          <div>
            {unlessLoading(
              <em>
                {fuelEco}
                {fuelEco !== 'N/A' && (
                  <span
                    className="clickable"
                    onClick={() => setShow100km(!show100km)}
                  >
                    {show100km ? translations.l100km : translations.l10km}
                  </span>
                )}
              </em>
            )}
            <p>{translations.avgFuelConsumption}</p>
          </div>

          <SVGIcon className="icon smaller" icon="petrol" />
        </Card>

        <Card>
          <div>
            {unlessLoading(<em>{driverGrade}</em>)}
            <p>{translations.driverGrade}</p>
          </div>

          <SVGIcon className="icon" icon="truck-side" />
        </Card>
      </Cards>
    </Wrapper>
  )
}
