import { PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import {
  Checkbox,
  DatePicker,
  Input,
  NumberInput,
  RegularModal,
  UserSelect,
} from 'components'
import { differenceInWeeks, startOfDay } from 'date-fns'
import { ShallowUserNode } from 'modules/users'
import { Field, Form } from 'modules/users/UserSettings/components'
import React, { useEffect, useState } from 'react'
import {
  EditUserDocumentForm,
  EditUserDocumentPromptResolve,
  UserDocumentForSettings,
} from './types'
import { SelectMultipleUserDocumentCategories } from 'components/Select/SelectMultipleUserDocumentCategories'

interface EditUserDocumentPromptProps {
  userDocument?: UserDocumentForSettings
  onSubmit: PromptResolve<EditUserDocumentPromptResolve | null>
}

export const EditUserDocumentPrompt: React.VFC<EditUserDocumentPromptProps> = ({
  userDocument,
  onSubmit,
}) => {
  const translations = useTranslate({
    createUserDocument: 'settings.create-user-document',
    editUserDocument: 'settings.edit-user-document',
    selectCategories: 'settings.select-categories',

    name: 'common.name',
    hasNotification: 'settings.has-notification',
    responsibleUser: 'common.responsible-user',
    expirationDate: 'documents.expiration-date',
    notificationTime: 'documents.notification-time',

    continueToFiles: 'documents.continue-to-files',

    validation: {
      mustHaveName: 'settings.validation.user-documents.must-have-name',
      mustSetUser:
        'settings.validation.user-documents.must-set-responsible-user',
    },
  })

  const editing = typeof userDocument !== 'undefined'

  const [hasNotification, setHasNotification] = useState(
    !!userDocument?.responsibleUser ?? false
  )
  const expirationDate = userDocument?.expirationDate
    ? new Date(userDocument.expirationDate)
    : null
  const notificationTime =
    !userDocument?.expirationDate || !userDocument?.notificationTime
      ? 1
      : differenceInWeeks(
          new Date(userDocument.expirationDate),
          startOfDay(new Date(userDocument.notificationTime))
        )

  const validateResponsibleUser = (val: ShallowUserNode | null) =>
    !hasNotification || val !== null
      ? null
      : translations.validation.mustSetUser

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    validateForm,
    updateForm,
    updateFormErrors,
    formChangeHandler: handler,
    submitHandler: submit,
  } = useForm<EditUserDocumentForm>({
    values: {
      name: userDocument?.name || '',
      categories: userDocument?.categories?.map(category => category.id) || [],
      responsibleUser: userDocument?.responsibleUser ?? null,
      expirationDate: expirationDate,
      notificationTime: notificationTime,
    },
    validators: {
      name: val => (!!val ? null : translations.validation.mustHaveName),
      responsibleUser: validateResponsibleUser,
    },
  })

  useEffect(() => {
    if (hasNotification) {
      updateForm({
        expirationDate: form.expirationDate
          ? form.expirationDate
          : expirationDate ?? new Date(),
        notificationTime: form.notificationTime
          ? form.notificationTime
          : notificationTime ?? 1,
      })
      validateForm(['responsibleUser'])
    } else
      updateFormErrors({
        responsibleUser: null,
        expirationDate: null,
        notificationTime: null,
      })
    // eslint-disable-next-line
  }, [hasNotification])

  return (
    <RegularModal
      title={
        editing
          ? `${translations.editUserDocument}: ${userDocument?.name ?? ''}`
          : translations.createUserDocument
      }
      width="480px"
      submitDisabled={!formValid}
      submitText={translations.continueToFiles}
      onCancel={() => onSubmit(null)}
      onSubmit={submit(form => onSubmit({ form, hasNotification }))}
    >
      <Form>
        <Field>
          <label>{translations.name}</label>
          <Input
            value={form.name}
            error={errors.name}
            fullWidth
            autoFocus
            onChange={handler('name')}
          />
        </Field>

        <Field>
          <label>{translations.selectCategories}</label>
          <SelectMultipleUserDocumentCategories 
            selected={form.categories} 
            onChange={(values) => updateForm({categories: values})} 
            placeholder={translations.selectCategories}
            fullWidth
          />
        </Field>

        <Field alignLeft>
          <Checkbox
            checked={hasNotification}
            label={translations.hasNotification}
            boxSize="32px"
            onChange={setHasNotification}
          />
        </Field>

        {hasNotification && (
          <>
            <Field>
              <label>{translations.responsibleUser}</label>
              <UserSelect
                value={form.responsibleUser?.id ?? null}
                error={errors.responsibleUser}
                alwaysInclude={form.responsibleUser}
                dropdownPageName="responsible_user"
                fullWidth
                grayed
                onChange={(_, item) =>
                  updateForm({
                    responsibleUser: item?.extra ?? null,
                  })
                }
              />
            </Field>

            <Field>
              <label>{translations.expirationDate}</label>
              <DatePicker
                value={form.expirationDate ?? new Date()}
                fullWidth
                onChange={handler('expirationDate')}
              />
            </Field>

            <Field>
              <label>{translations.notificationTime}</label>
              <NumberInput
                value={form.notificationTime ?? 1}
                min={0}
                fullWidth
                onChange={handler('notificationTime')}
              />
            </Field>
          </>
        )}
      </Form>
    </RegularModal>
  )
}
