import { useMutation, useQuery } from '@apollo/client'
import { useToast } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { SVGIcon } from 'components'
import { Circle } from 'components/Circle'
import { Popup, PopupItem } from 'components/Popup'
import { Text } from 'components/Text'
import { DetectBlur } from 'containers/util-containers'
import AppNotificationItem from 'modules/notifications/components/AppNotificationItem'
import AppNotificationLoadingItem from 'modules/notifications/components/AppNotificationLoadingItem'
import AppNotificationNoItems from 'modules/notifications/components/AppNotificationNoItems'
import { APP_NOTIFICATION_STATUS } from 'modules/notifications/consts'
import { BATCH_CHANGE_APP_NOTIFICATION_STATUS_MUTATION } from 'modules/notifications/mutations'
import { MY_APP_NOTIFICATIONS_QUERY } from 'modules/notifications/queries'
import {
  BatchChangeAppNotificationStatusMutation,
  BatchChangeAppNotificationStatusMutationVariables,
  MyAppNotificationsQuery,
  MyAppNotificationsVariables,
} from 'modules/notifications/types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useMedia } from 'util/hooks'

const NotificationsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;

  ${Circle} {
    position: absolute;
    top: 8px;
    right: -8px;
  }
`

const NOTIFICATIONS_SIZE = 14

export const HeaderMenuNotifications: React.VFC = () => {
  const [showPopup, setShowPopup] = useState(false)
  const [mobile] = useMedia()
  const addToast = useToast()
  const translations = useTranslate({
    notificationsError: 'notifications.error.general',
  })
  const {
    loading,
    data,
    refetch,
    fetchMore: fetchMoreNotifications,
  } = useQuery<MyAppNotificationsQuery, MyAppNotificationsVariables>(
    MY_APP_NOTIFICATIONS_QUERY,
    {
      // Check for new notifications every minute
      pollInterval: 60000,
      variables: { first: NOTIFICATIONS_SIZE },
      onError: () => {
        addToast('error', translations.notificationsError)
      },
    }
  )

  const [
    batchChangeAppNotificationStatus,
    { error: mutationError, loading: mutationLoading },
  ] = useMutation<
    BatchChangeAppNotificationStatusMutation,
    BatchChangeAppNotificationStatusMutationVariables
  >(BATCH_CHANGE_APP_NOTIFICATION_STATUS_MUTATION, {
    onError: () => {
      addToast('error', translations.notificationsError)
    },
    ignoreResults: true,
    fetchPolicy: 'no-cache',
  })

  const closeAndRefetch = useCallback(() => {
    setShowPopup(false)
    if (refetch !== undefined) refetch()
  }, [refetch])

  /*  Memoized */
  const [
    pageInfo,
    hasNextPage,
    notificationItems,
    unseenNotificationCount,
    notifications,
  ] = useMemo(() => {
    if (typeof data === 'undefined')
      return [undefined, false, [] as PopupItem[], 0]

    const {
      myAppNotifications: { pageInfo, ...rest },
    } = data
    const finalNotifications = rest.edges.map(edge => edge.node)

    const notificationItems: PopupItem[] = finalNotifications.map(
      notification => ({
        id: notification.id,
        content: () => (
          <AppNotificationItem
            appNotification={notification}
            onActionClicked={closeAndRefetch}
          />
        ),
        onClick: () => null,
        hide: false,
      })
    )

    if (finalNotifications.length === 0) {
      notificationItems.push({
        id: 'no-notifications',
        content: () => <AppNotificationNoItems />,
        onClick: () => null,
        hide: false,
      })
    }

    const hasNextPage = pageInfo?.hasNextPage || false

    const unseenNotifications =
      finalNotifications.filter(
        notification => notification.status !== APP_NOTIFICATION_STATUS.SEEN
      ).length || 0

    return [
      pageInfo,
      hasNextPage,
      notificationItems,
      unseenNotifications,
      finalNotifications,
    ]
  }, [closeAndRefetch, data])

  // Add loading items
  if (loading) {
    notificationItems.push({
      id: 'loader-1',
      content: () => <AppNotificationLoadingItem />,
      onClick: () => null,
      hide: false,
    })
    notificationItems.push({
      id: 'loader-2',
      content: () => <AppNotificationLoadingItem />,
      onClick: () => null,
      hide: false,
    })
  }

  useEffect(() => {
    if (typeof notifications === 'undefined') return
    // Use effect to clear all seen notifications when the popup is open
    const unseen = notifications.filter(
      notification => notification.status === APP_NOTIFICATION_STATUS.UNSEEN
    )

    if (showPopup && unseen.length > 0 && !mutationError && !mutationLoading) {
      batchChangeAppNotificationStatus({
        variables: {
          ids: unseen.map(appNotification => appNotification.id),
          newStatus: APP_NOTIFICATION_STATUS.SEEN,
        },
      })
    }
  })

  const handleScrollBottom = useCallback(async () => {
    if (typeof data === 'undefined' || typeof pageInfo === 'undefined') return
    if (!hasNextPage || !!!pageInfo.endCursor) return

    const cursor = pageInfo.endCursor
    try {
      await fetchMoreNotifications({
        variables: {
          first: NOTIFICATIONS_SIZE,
          after: cursor,
        },
      })
    } catch (e) {
      //@ts-ignore
      if (e.name === 'Invariant Violation') return
      throw e
    }
  }, [data, pageInfo, hasNextPage, fetchMoreNotifications])

  return (
    <DetectBlur active={showPopup} onClickOutside={closeAndRefetch}>
      <NotificationsWrapper>
        {unseenNotificationCount > 0 && (
          <Circle color="darkRed" size={16}>
            <Text fontSize="10px" color="white" userSelect="none">
              {unseenNotificationCount}
            </Text>
          </Circle>
        )}
        <SVGIcon
          icon="bell"
          fill="gray5"
          width="32px"
          cursor="pointer"
          hoverColor="gray6"
          onClick={() => {
            showPopup ? setShowPopup(false) : setShowPopup(!showPopup)
            if (showPopup) refetch()
          }}
        />
        <Popup
          handleScrollBottom={handleScrollBottom}
          scrollActive={true}
          show={showPopup}
          maxHeight={'60vh'}
          onClose={() => null}
          renderItems={notificationItems}
          width={mobile ? 'auto' : '375px'}
          itemHoverEffect={false}
          right={mobile ? '0.5rem' : undefined}
          left={mobile ? '0.5rem' : undefined}
          // Magic constant found by eyesight
          arrowOffset={mobile ? '80px' : '8px'}
        />
      </NotificationsWrapper>
    </DetectBlur>
  )
}
