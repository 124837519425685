import React from 'react'

export const Bell: React.VFC<React.SVGAttributes<SVGSVGElement>> = props => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 50 50"
    enableBackground="new 0 0 50 50"
    xmlSpace="preserve"
    fill="#003057"
    {...props}
  >
    <g>
      <path
        className="st0"
        d="M42.754,36.516l-3.208-3.207c-1.071-1.073-1.675-2.529-1.675-4.048V19.202c0-6.179-4.207-11.637-10.046-12.98
		c0.014-0.11,0.033-0.218,0.033-0.331c0-1.579-1.285-2.862-2.861-2.862c-1.575,0-2.859,1.283-2.859,2.862
		c0,0.11,0.021,0.215,0.034,0.322c-5.748,1.292-10.044,6.412-10.044,12.547v10.5c0,1.519-0.606,2.976-1.675,4.048l-3.208,3.207
		c-0.538,0.535-0.838,1.263-0.838,2.021v0.005c0,1.714,1.387,3.103,3.103,3.103h30.978c1.713,0,3.105-1.388,3.105-3.103v-0.005
		C43.593,37.779,43.289,37.052,42.754,36.516z"
      />
      <path
        className="st0"
        d="M18.951,43.041c0.334,2.209,2.913,3.929,6.046,3.929c3.139,0,5.717-1.72,6.05-3.929H18.951z"
      />
    </g>
  </svg>
)
