import { Input } from 'components'
import styled from 'styled-components'

export const TitleInput = styled(Input)`
  height: 3rem;

  input {
    background-color: white;
  }
`
export const DescriptionInput = styled(Input)`
  height: 3rem;
`
