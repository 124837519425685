import { useTranslate } from '@ur/react-hooks'
import { Card } from 'components/Card'
import {
  Table,
  TableMenu,
  TableMenuItem,
  TableSummation,
} from 'components/Table'
import { isAfter, parseISO } from 'date-fns'
import { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { format } from 'util/date-fns'
import { useAdmin } from 'util/hooks'
import { EditTerminalEntryModal } from '../modals'
import { useTerminalEntriesMutations } from '../mutations.hooks'
import { TerminalEntryNode } from '../types.graphql'
import { generateSums } from '../util'
import { AbsenceEntriesForTerminalData } from './types'

const WrapperCard = styled(Card)`
  display: grid;
  grid-template-columns: 1fr;

  ${props => props.theme.layout.mobile} {
    .table {
      padding: 0;
      td:first-of-type div {
        margin-left: 1rem !important;
        text-transform: uppercase;
      }

      th:first-of-type {
        padding: 1rem !important;
      }
    }
  }

  .table {
    max-width: 100%;
    font-size: 0.8rem;
    padding: 0;
  }

  td div {
    padding: 0.86rem 0;
  }

  overflow-x: auto;
`

interface RowProps {
  isAbsence?: boolean
}
const Row = styled.tr<RowProps>`
  background-color: ${props => props.isAbsence && props.theme.colors.red12};
  ${props =>
    props.isAbsence &&
    css`
      td + td div {
        padding-left: 1rem;
      }
    `}
`

const Summation = styled(TableSummation)`
  height: 5.5rem;

  td {
    padding-top: 1rem !important;
    div {
      padding: 0.86rem 1rem;
    }
  }

  &:hover {
    background: inherit !important;
  }

  ${props => props.theme.media.mobile} {
    td:first-of-type div {
      margin-left: 1rem !important;
    }
    td:last-of-type div {
      margin-right: 1rem !important;
    }
  }
`

interface TerminalViewTableProps {
  terminalEntries: TerminalEntryNode[]
  absenceEntries?: AbsenceEntriesForTerminalData[]
  tableAsCard?: boolean
  loading: boolean
}

export const TerminalViewTable: React.FC<TerminalViewTableProps> = ({
  terminalEntries,
  absenceEntries = [],
  loading,
}) => {
  const admin = useAdmin()

  const {
    editTerminalEntry,
    entryLoading,
    setEditTerminalEntry,
    handleEditTerminalEntry,
    handleDeleteTerminalEntry,
  } = useTerminalEntriesMutations()

  const translations = useTranslate({
    labels: {
      date: 'common.date',
      checkedIn: 'terminal.checked-in',
      checkedOut: 'terminal.checked-out',
      totalTime: 'common.work-hours',
      pause: 'common.pause',
      overtime: 'activities.sum-overtime',
      sum: 'common.sum',
    },
    menu: {
      editTerminalEntry: 'common.edit',
      deleteTerminalEntry: 'common.delete',
    },
  })

  const canEdit = admin
  const canDelete = admin
  const showMenu = canEdit || canDelete

  function createMenuItems(entry: TerminalEntryNode) {
    const items: TableMenuItem<TerminalEntryNode>[] = []
    const setEdit = setEditTerminalEntry

    if (canEdit)
      items.push({
        label: translations.menu.editTerminalEntry,
        role: 'link',
        onClick: () => setEdit(entry),
      })
    if (canDelete)
      items.push({
        label: translations.menu.deleteTerminalEntry,
        onClick: () => handleDeleteTerminalEntry(entry),
      })
    return items
  }

  const terminalEntriesRows = terminalEntries.map(entry => {
    const checkInTime = parseISO(entry.checkIn)
    const checkOutTime = entry.checkOut ? parseISO(entry.checkOut) : ''
    const checkoutRow = checkOutTime
      ? format(checkOutTime, 'dd.MM.yyyy HH:mm')
      : '-'

    const totalWork = entry?.totalWork || ''
    /* TODO Marius: remove after TimeDelta update */
    const cleanedTotalWork =
      totalWork.length > 5 ? totalWork.slice(0, 5) : totalWork
    const pause = entry.checkOut ? entry.pause : ''
    const overtime = entry?.overtime || ''
    /* TODO Marius: remove after TimeDelta update */
    const cleanedOvertime =
      overtime.length > 5 ? overtime.slice(0, 5) : overtime

    const menuItems = createMenuItems(entry)

    const row = () => (
      <Row key={entry.id}>
        <td>
          <div>{format(checkInTime, 'dd.MM.yyyy HH:mm')}</div>
        </td>
        <td>
          <div>{checkoutRow}</div>
        </td>
        <td>
          <div>{cleanedTotalWork}</div>
        </td>
        <td>
          <div>{pause}</div>
        </td>
        <td>
          <div>{cleanedOvertime}</div>
        </td>
        {canEdit && (
          <TableMenu items={menuItems} loading={entryLoading === entry.id} />
        )}
      </Row>
    )
    return { checkIn: checkInTime, row }
  })

  const sums = useMemo(() => generateSums(terminalEntries), [terminalEntries])

  const absenceEntriesRows = absenceEntries.map(entry => {
    const row = () => (
      <Row isAbsence key={entry.id}>
        <td onClick={entry.props?.onClick}>
          <div>{format(entry.checkIn, 'dd.MM.yyyy')}</div>
        </td>
        <td colSpan={canEdit ? 5 : 6} onClick={entry.props?.onClick}>
          <div>{entry.checkOut}</div>
        </td>
      </Row>
    )

    return { checkIn: entry.checkIn, row }
  })

  const combinedEntries = terminalEntriesRows
    .concat(absenceEntriesRows)
    .sort((a, b) => {
      if (isAfter(a.checkIn, b.checkIn)) return 1
      if (isAfter(b.checkIn, a.checkIn)) return -1
      return 0
    })

  if (loading) {
    return null
  }

  return (
    <>
      <EditTerminalEntryModal
        entry={editTerminalEntry}
        onClose={() => setEditTerminalEntry(null)}
        onSubmit={handleEditTerminalEntry}
      />
      <WrapperCard padding="0">
        <Table noShadow>
          <thead>
            <tr>
              <th>{translations.labels.checkedIn}</th>
              <th>{translations.labels.checkedOut}</th>
              <th>{translations.labels.totalTime}</th>
              <th>{translations.labels.pause}</th>
              <th>{translations.labels.overtime}</th>
              {showMenu && <th></th>}
            </tr>
          </thead>
          <tbody>
            {combinedEntries.map(entry => entry.row())}
            <Summation key="terminal-sum-row">
              <td>
                <div className="sum">
                  {translations.labels.sum.toUpperCase().concat(':')}
                </div>
              </td>
              <td>
                <div></div>
              </td>
              <td>
                <div>
                  <span>{translations.labels.totalTime}</span>
                  {sums.hours}
                </div>
              </td>
              <td>
                <div>
                  <span>{translations.labels.pause}</span>
                  {sums.pause}
                </div>
              </td>
              <td>
                <div>
                  <span>{translations.labels.overtime}</span>
                  {sums.overtime}
                </div>
              </td>
              {canEdit && (
                <td>
                  <div></div>
                </td>
              )}
            </Summation>
          </tbody>
        </Table>
      </WrapperCard>
    </>
  )
}
