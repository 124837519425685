import { Icon, useToast } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import {
  CheckedTable,
  CheckedTableRow,
  CheckedTableValue,
  Input,
} from 'components'
import differenceWith from 'lodash/differenceWith'
import isEqual from 'lodash/isEqual'
import { ModuleAsPermissionPrefixes } from 'modules/companies/consts'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import styled from 'styled-components'
import { useAdmin } from 'util/hooks'
import { useModuleConfigs } from '../../hooks'
import { createPermissionDomains, UserTypeWithPermissions } from '../../util'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow-x: auto;
`
const AdminCell = styled.div`
  font-size: 0.9rem;

  span {
    font-weight: 600;
  }
  i {
    position: relative;
  }
  p {
    max-width: 290px;
    margin: 0;
    color: ${props => props.theme.colors.gray4};
  }
`
const NameEdit = styled.div`
  display: flex;
  justify-content: center;
  height: 22px;
  margin-bottom: -2px;
`
const NameEditInput = styled(Input)`
  width: 120px;
  height: 100%;

  input {
    padding: 0 6px;

    border: 0;
    border-radius: 6px 0 0 0;
    font-size: 0.9rem;
    text-align: center;
  }
`
const EditCancel = styled.div`
  width: 22px;
  height: 100%;

  border-radius: 0 6px 0 0;
  background: ${props => props.theme.colors.quaternary};
`

interface PermissionSettingsTableProps {
  userTypes: UserTypeWithPermissions[]

  onChange: (diff: CheckedTableValue[]) => void
  onSubmitName: (userTypeId: string, name: string) => Promise<void>
  onDelete: (userType: UserTypeWithPermissions) => void
}

export const PermissionSettingsTable: React.VFC<PermissionSettingsTableProps> =
  ({
    userTypes,

    onChange,
    onSubmitName,
    onDelete,
  }) => {
    const translations = useTranslate({
      editName: 'common.edit-name',
      deleteUserType: 'settings.delete-user-type',
      isAdministrator: 'settings.is-administrator',
      isAdministratorDescription: 'settings.is-administrator-description',

      validation: {
        nameExists: 'settings.validation.user-type-name-exists',
      },
    })

    const domainTranslations = useTranslate({
      driverCard: 'users.driver-card',
      view: 'permissions.view',
      viewOthers: 'permissions.view-others',
      add: 'permissions.add',
      change: 'permissions.change',
      remove: 'permissions.remove',
      viewChange: 'permissions.view-and-change',
      approve: 'permissions.approve',
      forumSendSms: 'permissions.forum-send-sms',
      forumSendEmail: 'permissions.forum-send-email',
      forumChangeSticky: 'permissions.change_sticky',
      general: 'permissions.company-settings-general',
      absence: 'common.absence',
      infringements: 'common.infringements',
      issues: 'common.issues',
      sms: 'common.sms',
      terminal: 'common.terminal',
      userTypes: 'common.user-types',
      userDocuments: 'common.documents',
      company: 'common.company',
      users: 'common.users',
      documents: 'common.documents',
      forum: 'common.forum',
      forumThreads: 'common.forum-threads',
      forumPosts: 'common.forum-posts',
      driverdataSelf: 'user-types.driver-activities-self',
      driverdataAll: 'user-types.everyones-driver-activities',
      timesheets: 'common.hourlist',
      handbook: 'common.handbook',
      formcreator: 'common.formcreator',
      formResponses: 'common.form-responses',
      companySettings: 'settings.company-settings',
      dropdowns: 'common.dropdowns',
      tableSettings: 'settings.tables',
      vehicles: 'vehicles.fleet-overview',
      exports: 'exports.export-data',
      routePlanner: 'common.route-planner',
    })

    const prevUserTypes = useRef<UserTypeWithPermissions[] | null>(null)
    const initialValues = useRef<CheckedTableValue[]>([])

    const addToast = useToast()
    const admin = useAdmin()

    const [editingUserType, setEditingUserType] = useState<string | null>(null)
    const [editingName, setEditingName] = useState('')

    const [checkedCollection, setCheckedCollection] = useState<
      CheckedTableValue[]
    >([])

    const { moduleNames } = useModuleConfigs()
    const permissionDomains = useMemo(
      () => createPermissionDomains(domainTranslations),
      [domainTranslations]
    )

    function beginEditUserType(userType: UserTypeWithPermissions) {
      setEditingName(userType.name)
      setEditingUserType(userType.id)
    }
    function endEditUserType() {
      setEditingUserType(null)
      setEditingName('')
    }
    const submitUserTypeName = useCallback(
      async (userType: UserTypeWithPermissions) => {
        if (
          editingUserType === null ||
          userType.id !== editingUserType ||
          editingName === ''
        )
          return

        if (editingName === userType.name) {
          endEditUserType()
          return
        }

        if (!!userTypes.find(type => type.name === editingName)) {
          addToast('warning', translations.validation.nameExists)
          return
        }

        await onSubmitName(userType.id, editingName)
        endEditUserType()
      },
      [
        addToast,
        editingName,
        editingUserType,
        onSubmitName,
        translations.validation.nameExists,
        userTypes,
      ]
    )

    const createCheckedValues = useCallback(
      (types: UserTypeWithPermissions[]) => {
        let ret: CheckedTableValue[] = []

        if (admin)
          ret = ret.concat(
            types.map(type => ({
              column: type.id,
              row: 'admin',
              checked: type.isAdministrator,
            }))
          )

        for (const domain of permissionDomains) {
          const domainPermissions = Object.values(domain.permissions)

          for (const type of types) {
            for (const permission of domainPermissions) {
              const checked = permission.every(permission =>
                type.permissions.includes(permission)
              )

              ret.push({
                column: type.id,
                row: JSON.stringify(permission),
                checked,
              })
            }
          }
        }

        return ret
      },
      [admin, permissionDomains]
    )

    useEffect(() => {
      if (isEqual(prevUserTypes.current, userTypes)) return
      prevUserTypes.current = userTypes

      const values = createCheckedValues(userTypes)
      initialValues.current = values
      setCheckedCollection(values)
    }, [createCheckedValues, userTypes])

    function handleChange(value: CheckedTableValue[]) {
      const diff = differenceWith(value, initialValues.current, isEqual)
      setCheckedCollection(value)

      onChange(diff)
    }

    const columns = useMemo(
      () =>
        userTypes.map(type =>
          editingUserType === type.id && type.editable ? (
            <NameEdit>
              <NameEditInput
                value={editingName}
                autoFocus
                onFocus={evt => evt.target.select()}
                onEnter={() => submitUserTypeName(type)}
                onEscape={endEditUserType}
                onChange={setEditingName}
              />
              <EditCancel>
                <Icon
                  icon="times"
                  type="light"
                  size="0.8rem"
                  translateY="2px"
                  hoverColor="red"
                  cursor="pointer"
                  onClick={endEditUserType}
                />
              </EditCancel>
            </NameEdit>
          ) : (
            <>
              {type.name}
              {type.editable && (
                <>
                  <Icon
                    icon="pen"
                    type="solid"
                    size="0.8rem"
                    margin="0 0 0 4px"
                    color="gray7"
                    hoverColor="text.dark"
                    cursor="pointer"
                    title={translations.editName}
                    onClick={() => beginEditUserType(type)}
                  />

                  <Icon
                    icon="times"
                    margin="0 0 0 8px"
                    color="gray7"
                    hoverColor="red"
                    cursor="pointer"
                    translateY="1px"
                    title={translations.deleteUserType}
                    onClick={() => onDelete(type)}
                  />
                </>
              )}
            </>
          )
        ),
      [
        editingName,
        editingUserType,
        onDelete,
        submitUserTypeName,
        translations.deleteUserType,
        translations.editName,
        userTypes,
      ]
    )

    const rows = useMemo<CheckedTableRow[]>(() => {
      const ret: CheckedTableRow[] = []

      // If we have modules: Get the module prefixes from const at format string[][]
      // Else: return only common prefixes at format string[][]
      // Then flatten array to string[] with [].concat.appply([], arrayOfArrays)
      const nonFlatPrefixArray = moduleNames
        ? moduleNames
            .map(
              module =>
                ModuleAsPermissionPrefixes[
                  module.toUpperCase() as keyof typeof ModuleAsPermissionPrefixes
                ]
            )
            .concat(ModuleAsPermissionPrefixes.OTHER)
        : [ModuleAsPermissionPrefixes.OTHER]

      const hasModuleAsPermissionPrefixes = ([] as string[]).concat.apply(
        [],
        nonFlatPrefixArray
      )
      if (admin)
        ret.push({
          type: 'regular',
          value: 'admin',
          title: (
            <AdminCell>
              <span>{translations.isAdministrator}</span>
              <Icon
                icon="question-circle"
                type="solid"
                size="0.9em"
                color="gray6"
                margin="0 0 0 6px"
                title={translations.isAdministratorDescription}
              />
            </AdminCell>
          ),
        })
      for (const domain of permissionDomains) {
        const prefix = Object.values(domain.permissions)[0][0]?.split('.')[0]

        if (hasModuleAsPermissionPrefixes.includes(prefix)) {
          ret.push({
            type: 'header',
            title: domain.title,
            value: domain.title,
          })

          for (const [title, permissions] of Object.entries(
            domain.permissions
          )) {
            ret.push({
              type: 'regular',
              title,
              value: JSON.stringify(permissions),
            })
          }
        }
      }

      return ret
    }, [
      admin,
      moduleNames,
      permissionDomains,
      translations.isAdministrator,
      translations.isAdministratorDescription,
    ])

    return (
      <Wrapper>
        <ScrollBar>
          <CheckedTable
            value={checkedCollection}
            columns={columns}
            rows={rows}
            onChange={handleChange}
          />
        </ScrollBar>
      </Wrapper>
    )
  }
