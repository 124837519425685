export enum AbsenceView {
  table = 'table',
  calendar = 'calendar',
}

export enum AbsenceStatusValue {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export type TimeEntryView = keyof typeof TimeEntryViewEnum

export enum TimeEntryViewEnum {
  table = 'table',
  week = 'week',
}

export enum TimeEntyOvertimeValue {
  FIFTY = 'FIFTY',
  HUNDRED = 'HUNDRED',
}
