import styled from 'styled-components'
import { Button as BaseButton, ButtonProps } from './Button'
import { Icon } from '@ur/react-components'

import { motion, useAnimation } from 'framer-motion'
import { useRotateWhileTrueAnimation } from 'util/hooks/animations'
import { useEffect, useState } from 'react'

const Button = styled(BaseButton)`
  border: 1px solid ${props => props.theme.colors.gray7};
  span {
    display: none;
  }
  ${props => props.theme.media.mobile} {
    padding: 2rem !important;
    text-align: center;
    margin-bottom: 1rem;
  }
`
const SyncSpinner = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const motionStyle = {
  width: '23.75px',
  height: '19px',
}

interface SyncButtonProps extends ButtonProps {
  reset: boolean
  refetchQuery: any
  onClick?: () => void
}

export const SyncButton: React.VFC<SyncButtonProps> = ({
  reset,
  refetchQuery,

  onClick,
  ...props
}) => {
  const [loading, setLoading] = useState(reset)
  const controls = useAnimation()
  const { handleSync, resetAnimation, loadingStateEffect } =
    useRotateWhileTrueAnimation(controls, refetchQuery)

  useEffect(() => {
    loadingStateEffect(reset, loading, setLoading)
  }, [reset, loading, setLoading, loadingStateEffect])

  const handleOnClick = () => {
    setLoading(true)
    handleSync()
    onClick?.()
  }

  return (
    <Button
      background={props.background ?? 'secondary'}
      onClick={handleOnClick}
      {...props}
    >
      <SyncSpinner
        initial={{ rotate: 0 }}
        style={motionStyle}
        animate={controls}
        onAnimationComplete={resetAnimation(loading)}
      >
        <Icon size="19px" icon="sync" fixedWidth />
      </SyncSpinner>
    </Button>
  )
}
