import { PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Checkbox, Input, RegularModal } from 'components'
import { FleetBoardApiKeyNode } from 'modules/companies/types.graphql'
import React from 'react'
import styled from 'styled-components'
import { Field, Form } from '..'

const ActiveWrapper = styled.div`
  margin-left: 1rem;
`

export interface FleetBoardIntegrationForm {
  fleet: string
  username: string
  password: string
  active: boolean
}

interface EditFleetBoardIntegrationModalProps {
  integration: FleetBoardApiKeyNode | null
  index: number

  onSubmit: PromptResolve<FleetBoardIntegrationForm | null>
}

export const EditFleetBoardIntegrationModal: React.VFC<EditFleetBoardIntegrationModalProps> =
  ({
    integration,
    index,

    onSubmit,
  }) => {
    const translations = useTranslate({
      createIntegration: 'settings.create-fleet-board-integration',
      editIntegration: ['settings.edit-fleet-board-integration-n', { n: 0 }],

      fleet: 'vehicles.fleet',
      username: 'common.username',
      password: 'common.password',
      active: 'common.active',

      save: 'common.save',
      create: 'common.create-alt',

      validation: {
        required: 'common.required',
        errorInApiKeys: 'integrations.error-in-API-keys',
      },
    })

    const editing = integration !== null

    const validateNonEmpty = (value: string) =>
      !!value ? null : translations.validation.required

    const {
      formValues: form,
      formErrors: errors,
      formValid,
      formChangeHandler: handler,
      submitHandler: submit,
    } = useForm<FleetBoardIntegrationForm>({
      values: {
        fleet: integration?.fleet ?? '',
        username: integration?.username ?? '',
        password: integration?.password ?? '',
        active: integration?.active ?? true,
      },
      validators: {
        username: validateNonEmpty,
        password: validateNonEmpty,
      },
      config: {
        initAsInvalid: !editing,
      },
    })

    return (
      <RegularModal
        title={
          editing
            ? translations.editIntegration({ n: index + 1 })
            : translations.createIntegration
        }
        width="600px"
        submitText={editing ? translations.save : translations.create}
        submitDisabled={!formValid}
        footerLeft={
          <ActiveWrapper>
            <Checkbox
              checked={form.active}
              label={translations.active}
              boxWidth="32px"
              boxHeight="32px"
              onChange={handler('active')}
            />
          </ActiveWrapper>
        }
        onCancel={() => onSubmit(null)}
        onSubmit={() => submit(onSubmit)()}
      >
        <Form>
          <Field>
            <label>{translations.fleet}</label>
            <Input
              value={form.fleet}
              error={errors.fleet}
              fullWidth
              autoFocus
              onChange={handler('fleet')}
            />
          </Field>
          <Field>
            <label>{translations.username}</label>
            <Input
              value={form.username}
              error={errors.username}
              fullWidth
              onChange={handler('username')}
            />
          </Field>

          <Field>
            <label>{translations.password}</label>
            <Input
              value={form.password}
              error={errors.password}
              fullWidth
              onChange={handler('password')}
            />
          </Field>
        </Form>
      </RegularModal>
    )
  }
