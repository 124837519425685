import React from 'react'
import { useTranslate } from '@ur/react-hooks'
import { TimeEntryProjectNode } from 'modules/timesheets/types.graphql'
import { Icon } from '@ur/react-components'
import { Table } from 'components/Table'

interface TimeEntryProjectSettingsCardProps {
  timeEntryProjects: TimeEntryProjectNode[]

  onEditSubmit: (project: TimeEntryProjectNode) => void
  onDelete: (project: TimeEntryProjectNode) => void
}

export const TimeEntryProjectSettingsTable: React.VFC<TimeEntryProjectSettingsCardProps> =
  ({
    timeEntryProjects,

    onEditSubmit,
    onDelete,
  }) => {
    const translations = useTranslate({
      name: 'common.name',

      add: 'common.add',
      edit: 'common.edit',
      delete: 'common.delete-alt',
      cancel: 'common.cancel',
    })

    function handleEditClick(project: TimeEntryProjectNode) {
      onEditSubmit(project)
    }

    return (
      <Table
        noShadow
        noBorders
        noHeaderMargin
        noRowMargin
        rowHoverColor="secondary"
      >
        <thead>
          <tr>
            <th colSpan={2}>{translations.name}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {timeEntryProjects.map(project => (
            <tr key={project.id}>
              <td>{project.name}</td>

              <td width="1px">
                <Icon
                  icon="edit"
                  cursor="pointer"
                  color="gray6"
                  hoverColor="secondary"
                  onClick={() => handleEditClick(project)}
                />
              </td>

              <td width="1px">
                <Icon
                  icon="times"
                  cursor="pointer"
                  color="gray6"
                  hoverColor="red"
                  onClick={() => onDelete(project)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }
