import { useMutation } from '@apollo/client'
import { IdVariable } from 'types/graphql'
import { useOnErrorAuto } from 'util/hooks'
import {
  CreateVehicleDocumentMutation,
  CreateVehicleDocumentMutationVariables,
  CreateVehicleExpirationMutation,
  CreateVehicleExpirationMutationVariables,
  DeleteVehicleDocumentMutation,
  PatchVehicleDocumentMutation,
  PatchVehicleDocumentMutationVariables,
} from '..'
import {
  CREATE_VEHICLE_BY_REGISTRATION_NUMBER_MUTATION,
  CREATE_VEHICLE_DOCUMENT_MUTATION,
  CREATE_VEHICLE_EXPIRATION_MUTATION,
  CREATE_VEHICLE_MUTATION,
  DELETE_VEHICLE_DOCUMENT_MUTATION,
  DELETE_VEHICLE_EXPIRATION_MUTATION,
  DELETE_VEHICLE_MUTATION,
  PATCH_VEHICLE_DOCUMENT_MUTATION,
  PATCH_VEHICLE_EXPIRATION_MUTATION,
  PATCH_VEHICLE_MUTATION,
} from '../mutations'
import {
  CreateVehicleByRegNrMutation,
  CreateVehicleByRegNrMutationVariables,
  CreateVehicleMutation,
  CreateVehicleMutationVariables,
  DeleteVehicleExpirationMutation,
  DeleteVehicleMutation,
  DeleteVehicleMutationVariables,
  PatchVehicleDetailMutation,
  PatchVehicleDetailMutationVariables,
  PatchVehicleExpirationMutation,
  PatchVehicleExpirationMutationVariables,
} from '../types.graphql'
import { useToast } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'

export function useVehicleMutations() {
  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()
  const translations = useTranslate({
    update: 'vehicles.toasts.vehicle-updated',
  })
  const refetchQueries = ['VehicleDetail']
  const [createVehicle] = useMutation<
    CreateVehicleMutation,
    CreateVehicleMutationVariables
  >(CREATE_VEHICLE_MUTATION)

  const [createVehicleByRegNr] = useMutation<
    CreateVehicleByRegNrMutation,
    CreateVehicleByRegNrMutationVariables
  >(CREATE_VEHICLE_BY_REGISTRATION_NUMBER_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllVehicles'],
  })

  const [patchVehicle, { loading }] = useMutation<
    PatchVehicleDetailMutation,
    PatchVehicleDetailMutationVariables
  >(PATCH_VEHICLE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries,
    onCompleted: () => {
      addToast('success', translations.update)
    },
    onError(error) {
      onErrorAuto()(error)
    },
  })

  const [deleteVehicle] = useMutation<
    DeleteVehicleMutation,
    DeleteVehicleMutationVariables
  >(DELETE_VEHICLE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllVehicles'],
  })

  return {
    loading,
    mutations: {
      create: createVehicle,
      createByRegNr: createVehicleByRegNr,
      patch: patchVehicle,
      delete: deleteVehicle,
    },
  }
}

export function useVehicleDocumentMutations() {
  const onErrorAuto = useOnErrorAuto()
  const refetchQueries = ['VehicleDocuments']

  const [createDocument, { loading: createLoading }] = useMutation<
    CreateVehicleDocumentMutation,
    CreateVehicleDocumentMutationVariables
  >(CREATE_VEHICLE_DOCUMENT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries,
    onError: onErrorAuto(),
  })

  const [patchDocument, { loading: patchLoading }] = useMutation<
    PatchVehicleDocumentMutation,
    PatchVehicleDocumentMutationVariables
  >(PATCH_VEHICLE_DOCUMENT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries,
    onError: onErrorAuto(),
  })

  const [deleteDocument, { loading: deleteLoading }] = useMutation<
    DeleteVehicleDocumentMutation,
    IdVariable
  >(DELETE_VEHICLE_DOCUMENT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries,
    onError: onErrorAuto(),
  })

  return {
    createDocument,
    patchDocument,
    deleteDocument,
    loading: createLoading || patchLoading || deleteLoading,
  }
}

export function useVehicleExpirationMutations() {
  const onErrorAuto = useOnErrorAuto()
  const refetchQueries = ['VehicleExpirations']

  const [createExpiration, { loading: createLoading }] = useMutation<
    CreateVehicleExpirationMutation,
    CreateVehicleExpirationMutationVariables
  >(CREATE_VEHICLE_EXPIRATION_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries,
    onError: onErrorAuto(),
  })

  const [patchExpiration, { loading: patchLoading }] = useMutation<
    PatchVehicleExpirationMutation,
    PatchVehicleExpirationMutationVariables
  >(PATCH_VEHICLE_EXPIRATION_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries,
    onError: onErrorAuto(),
  })

  const [deleteExpiration, { loading: deleteLoading }] = useMutation<
    DeleteVehicleExpirationMutation,
    IdVariable
  >(DELETE_VEHICLE_EXPIRATION_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries,
    onError: onErrorAuto(),
  })

  return {
    createExpiration,
    patchExpiration,
    deleteExpiration,
    loading: createLoading || patchLoading || deleteLoading,
  }
}
