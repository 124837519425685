import { useMutation } from '@apollo/client'
import { useToast } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Button, Input, PhoneInput, Title } from 'components'
import { Card } from 'components/Card'
import { Form, FormField } from 'components/Form'
import { Label } from 'components/Label'
import { parsePhoneNumber } from 'libphonenumber-js'
import React from 'react'
import styled from 'styled-components'
import { internalNumberValidation, notEmptyValidation, positiveNumbersValidaton, postalCodeValidation } from 'util/forms'
import { useOnErrorAuto } from 'util/hooks'
import { REGISTER_USER_MUTATION } from '../mutations'
import { RegisterUserForm } from './types'
import {
  RegisterUserMutation,
  RegisterUserMutationVariables,
} from './types.graphql'

const Layout = styled.div`
  ${props => props.theme.layout.default};
`

const FormGrid = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1rem;

  ${props => props.theme.layout.mobile} {
    grid-template-columns: 100%;
  }
`

const StyledTitle = styled(Title.H2)`
  padding: 0 0 1rem 0;
`

const Titlewrapper = styled.div`
  margin-bottom: 2rem;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StyledCard = styled(Card)`
  max-width: 1200px;
`

interface RegisterUserProps {
  user: RegisterUserForm
}

export const RegisterUser: React.VFC<RegisterUserProps> = ({ user }) => {
  const translations = useTranslate({
    description: 'register-user.description',
    form: {
      label: {
        firstName: 'common.firstName',
        lastName: 'common.lastName',
        phoneNumber: 'common.phone-number',
        address: 'common.address',
        birthNumber: 'users.birth-number',
        internalNumber: 'users.internal-number',
        city: 'common.city',
        postalCode: 'common.postal-code',
        contactPersonName: 'users.contact-person-name',
        contactPersonPhoneNumber: 'users.contact-person-phone',
      },
      errors: {
        generic: 'common.form-error',
        required: ['error.field-is-required', { field: 'The field' }],
        phoneNumber: 'error.invalid-phone-number',
        postalCode: 'error.postal-code-required',
        birthNumber: 'error.invalid-birth-number',
        internalNumber: 'error.internal-number-required',
      },
    },
    submit: 'common.submit',
    title: 'register-user.title',
  })

  const standardValidation = (x: string, field: keyof RegisterUserForm) =>
    notEmptyValidation(x)
      ? null
      : translations.form.errors.required({
          field: translations.form.label[field],
        })

  const postalValidation = (x: string) =>
    postalCodeValidation(x) ? null : translations.form.errors.postalCode
  const phoneNumberValidation = (x: string) => {
    try {
      return parsePhoneNumber(x)?.isValid()
        ? null
        : translations.form.errors.phoneNumber
    } catch {
      return translations.form.errors.phoneNumber
    }
  }

  const birthNumberValidation = (x: string) =>
    positiveNumbersValidaton(x)
      ? null
      : translations.form.errors.birthNumber

  const internalValidation = (x: string) =>
    internalNumberValidation(x) ? null : translations.form.errors.internalNumber


  const {
    formValues: form,
    formErrors: errors,
    formChangeHandler: handler,
    submitHandler,
  } = useForm<RegisterUserForm>({
    values: {
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      address: user.address,
      city: user.city,
      postalCode: user.postalCode,
      contactPersonName: user.contactPersonName,
      contactPersonPhoneNumber: user.contactPersonPhoneNumber,
      birthNumber: user.birthNumber,
      internalNumber: user.internalNumber,
    },
    validators: {
      firstName: x => standardValidation(x, 'firstName'),
      lastName: x => standardValidation(x, 'lastName'),
      phoneNumber: x => phoneNumberValidation(x),
      address: x => standardValidation(x, 'address'),
      city: x => standardValidation(x, 'city'),
      postalCode: x => postalValidation(x),
      contactPersonName: x => standardValidation(x, 'contactPersonName'),
      contactPersonPhoneNumber: x => phoneNumberValidation(x),
      birthNumber: x => birthNumberValidation(x),
      internalNumber: x => internalValidation(x),
    },
  })

  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()

  const [registerUser] = useMutation<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >(REGISTER_USER_MUTATION, {
    onError: onErrorAuto(),
  })

  function onSubmit() {
    registerUser({
      variables: {
        ...form,
      },
    })
  }

  function onError() {
    addToast('error', translations.form.errors.generic)
  }

  return (
    <Layout>
      <Titlewrapper>
        <StyledTitle>{translations.title}</StyledTitle>
        <p>{translations.description}</p>
      </Titlewrapper>
      <StyledCard>
        <Form
          formProps={{ width: '100%' }}
          preventDefault
          onSubmit={submitHandler(onSubmit, onError)}
        >
          <FormGrid>
            <FormField>
              <Label htmlFor="firstName">
                {translations.form.label.firstName}
              </Label>
              <Input
                id="firstName"
                value={form.firstName}
                error={errors.firstName}
                fullWidth
                background="white"
                onChange={handler('firstName')}
              />
            </FormField>
            <FormField>
              <Label htmlFor="lastName">
                {translations.form.label.lastName}
              </Label>
              <Input
                id="lastName"
                value={form.lastName}
                error={errors.lastName}
                fullWidth
                background="white"
                onChange={handler('lastName')}
              />
            </FormField>
            <FormField>
              <Label htmlFor="phoneNumber">
                {translations.form.label.phoneNumber}
              </Label>
              <PhoneInput
                id="phoneNumber"
                value={form.phoneNumber}
                error={errors.phoneNumber}
                fullWidth
                background="white"
                onChange={handler('phoneNumber')}
              />
            </FormField>
            <FormField>
              <Label htmlFor="birthNumber">
                {translations.form.label.birthNumber}
              </Label>
              <Input
                id="birthNumber"
                value={form.birthNumber}
                error={errors.birthNumber}
                fullWidth
                background="white"
                onChange={handler('birthNumber')}
              />
            </FormField>
            <FormField>
              <Label htmlFor="internalNumber">
                {translations.form.label.internalNumber}
              </Label>
              <Input
                id="internalNumber"
                value={form.internalNumber}
                error={errors.internalNumber}
                fullWidth
                background="white"
                onChange={handler('internalNumber')}
              />
            </FormField>
            <FormField>
              <Label htmlFor="address">{translations.form.label.address}</Label>
              <Input
                id="address"
                value={form.address}
                error={errors.address}
                fullWidth
                background="white"
                onChange={handler('address')}
              />
            </FormField>
            <FormField>
              <Label htmlFor="postalCode">
                {translations.form.label.postalCode}
              </Label>
              <Input
                id="postalCode"
                value={form.postalCode}
                error={errors.postalCode}
                fullWidth
                background="white"
                onChange={handler('postalCode')}
              />
            </FormField>
            <FormField>
              <Label htmlFor="city">{translations.form.label.city}</Label>
              <Input
                id="city"
                value={form.city}
                error={errors.city}
                fullWidth
                background="white"
                onChange={handler('city')}
              />
            </FormField>
            <FormField>
              <Label htmlFor="contactPersonName">
                {translations.form.label.contactPersonName}
              </Label>
              <Input
                id="contactPersonName"
                value={form.contactPersonName}
                error={errors.contactPersonName}
                fullWidth
                background="white"
                onChange={handler('contactPersonName')}
              />
            </FormField>
            <FormField>
              <Label htmlFor="contactPersonPhoneNumber">
                {translations.form.label.contactPersonPhoneNumber}
              </Label>
              <PhoneInput
                id="contactPersonPhoneNumber"
                value={form.contactPersonPhoneNumber}
                error={errors.contactPersonPhoneNumber}
                fullWidth
                background="white"
                onChange={handler('contactPersonPhoneNumber')}
              />
            </FormField>
          </FormGrid>
          <ButtonWrapper>
            <Button>{translations.submit}</Button>
          </ButtonWrapper>
        </Form>
      </StyledCard>
    </Layout>
  )
}
