import { useTranslate } from '@ur/react-hooks'
import { isMobileOnly } from 'react-device-detect'
import { InfoBox, SettingsWrapper } from '..'
import { EditDashboardLinksCard } from '../components/HandbookSettings'

interface HandbookSettingsProps {}

export const HandbookSettings: React.VFC<HandbookSettingsProps> = () => {
  const translations = useTranslate({
    info1: 'settings.info.handbook-1',
  })
  return (
    <SettingsWrapper
      grid={{
        flow: 'row',
      }}
    >
      <InfoBox initCollapsed={isMobileOnly}>
        <p>{translations.info1}</p>
      </InfoBox>
      <EditDashboardLinksCard />
    </SettingsWrapper>
  )
}
