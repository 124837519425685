import { enGB, nb } from 'date-fns/locale'
import { getPreferredLanguage } from 'locale/util'

const locales: { [locale: string]: Locale } = { en: enGB, nb }

export function getLocale() {
  const locale = getPreferredLanguage()

  return locales[locale] || locales['nb']
}
