import styled from 'styled-components'
import { Input as BaseInput } from 'components'
import { Card } from 'components/Card'

export interface AreaProps {
  area: string
}
export const Area = styled.div<AreaProps>`
  grid-area: ${props => props.area};
`
export const InputArea = styled(Area)`
  display: flex;
  flex-direction: column;

  label {
    margin: 0 0 4px 4px;
    color: ${props => props.theme.colors.gray5};
    font-weight: 600;
  }
`
interface InputProps {
  editing: boolean
}
export const Input = styled(BaseInput).attrs(({ editing }: InputProps) => ({
  disabled: !editing,
  noBorders: !editing,
  background: editing ? 'white' : 'quaternary',
}))<InputProps>`
  height: 54px;

  input {
    font-weight: 500;
    -webkit-text-fill-color: ${props => props.theme.colors.text.dark};
    -webkit-opacity: 1;
    color: ${props => props.theme.colors.text.dark};

    opacity: 1;
  }
  i {
    color: ${props =>
      props.editing ? props.theme.colors.gray8 : props.theme.colors.secondary};
  }
`

export interface ProfileCardProps {
  area?: string
}
export const ProfileCard = styled(Card)<ProfileCardProps>`
  grid-area: ${props => props.area};
  padding: 1.5rem;

  header {
    justify-content: flex-start!important;
    gap: 1rem;
    h3 {
      margin: 0;
      font-size: 1.6rem;
      font-weight: 600;
    }
  }

  ${props => props.theme.media.mobile} {
    padding: 1rem;
  }
`
