import { Card } from 'components/Card'
import styled from 'styled-components'

interface CalendarCellProps {
  borderTop: boolean
  borderRight: boolean
}

export const CalendarCell = styled.div<CalendarCellProps>`
  padding-top: 1rem;
  border-top: ${props =>
    props.borderTop ? props.theme.layout.defaultBorder : 'None'};
  border-right: ${props =>
    props.borderRight ? props.theme.layout.defaultBorder : 'None'};
  display: flex;
  flex-direction: column;
`

export const CalendarWrapper = styled(Card)`
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));

  padding: 0;
`

export const WeekDayHeader = styled.div`
  text-align: center;
  font-size: 12px;
  color: ${props => props.theme.colors.gray5};
`

export const CalendarCellHeader = styled.div`
  text-align: center;
  padding-bottom: 0.5rem;
  font-weight: 600;
  font-size: 12px;
  color: ${props => props.theme.colors.gray5};
`

/* Calendar blocks */

export const BlockWrapper = styled.div`
  height: 100px;
`

export const DefaultCalendarBlockWrapper = styled.div`
  cursor: pointer;
  height: 18px;
  line-height: 20px;
  padding-left: 0.25rem;
  width: calc(100% + 2px);
  margin-left: -1px;
  font-size: 12px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const EmptyBlock = styled.div`
  height: 20px;
  padding-left: 0.25rem;
  margin-left: -1px;
`

export const ExtraSlotBlock = styled(DefaultCalendarBlockWrapper)<{
  starting: boolean
}>`
  font-weight: 600;

  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.gray5};

  padding-left: 0.25rem;
  margin-left: -1px;

  ${props =>
    props.starting &&
    `
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
      `}
`

interface CalendarBlockWrapperProps {
  background: string
  ending: boolean
  new: boolean
}

export const CalendarBlockWrapper = styled(
  DefaultCalendarBlockWrapper
)<CalendarBlockWrapperProps>`
  font-weight: 400;
  color: ${props => props.theme.colors.black};
  background-color: ${props => props.background};
  margin-bottom: 2px;

  ${props =>
    props.new &&
    `
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    `}

  ${props =>
    props.ending &&
    `
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    `}
`

/* Calendar popup */

export const PopupWrapper = styled.div`
  position: relative;
  width: calc(100% / 7);
`

export const CalendarExtraBlocksPopupWrapper = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 1rem;
  width: calc(100% + 2rem);
  margin-left: -1rem;
  border-radius: ${props => props.theme.standardBorderRadius};
  box-shadow: ${props => props.theme.shadow.default};
`

export const CalendarPopupHeader = styled.h3`
  margin: 0;
  height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const CalendarExtraBlocksPopupHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 24px;
  line-height: 20px;
  padding-bottom: 2rem;
`

export const TimesIconWrapper = styled.div`
  padding-top: 2px;
  &:hover i {
    cursor: pointer;
    color: ${props => props.theme.colors.red};
  }
`
