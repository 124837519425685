import React from 'react'

export const RoutePlanner: React.VFC<React.SVGAttributes<SVGSVGElement>> =
  props => (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 19.3 17.4"
      enableBackground="new 0 0 19.3 17.4"
      xmlSpace="preserve"
      fill="#003057"
      {...props}
    >
      <path
        className="st0"
        d="M15.3,11.8h-3.6c-0.9,0-1.5-0.7-1.5-1.5c0-0.9,0.7-1.5,1.5-1.5h3.1c0,0,3.1-3.4,3.1-5.1s-1.4-3.1-3.1-3.1
	s-3.1,1.4-3.1,3.1c0,1.1,1.3,3,2.2,4.1h-2.2c-1.4,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6h3.6c0.9,0,1.5,0.7,1.5,1.5
	c0,0.9-0.7,1.5-1.5,1.5H5.4c0.9-1.1,2.2-3,2.2-4.1c0-1.7-1.4-3.1-3.1-3.1s-3.1,1.4-3.1,3.1s3.1,5.1,3.1,5.1h10.8
	c1.4,0,2.6-1.2,2.6-2.6S16.7,11.8,15.3,11.8z M12.8,3.5c0-1.1,0.9-2.1,2.1-2.1s2.1,0.9,2.1,2.1c0,0.7-1,2.2-2.1,3.5
	C13.7,5.7,12.8,4.2,12.8,3.5z M2.5,11.8c0-1.1,0.9-2.1,2.1-2.1s2.1,0.9,2.1,2.1c0,0.7-1,2.2-2.1,3.5C3.5,13.9,2.5,12.4,2.5,11.8z
	 M4.5,11.2c-0.7,0-0.7,1,0,1C5.2,12.3,5.2,11.2,4.5,11.2z M14.8,3c-0.7,0-0.7,1,0,1C15.5,4.1,15.5,3,14.8,3z"
      />
    </svg>
  )
