import { fromUnixTime } from 'date-fns'
import queryString from 'query-string'
import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useLocation, useParams } from 'react-router'
import { GET_JWT_TOKEN_FROM_SHORT_URL } from './queries'
import {
  GetJwtTokenFormShortUrlQuery,
  GetJwtTokenFormShortUrlQueryVariables,
} from './types.graphql'

/**
 * Hook for handling basic JWT token quering and decoding from urlId parameter
 */
export function useJwtTokenFromUrlId() {
  const [token, setToken] = useState<string>()
  const { urlId } = useParams<{ urlId: string }>()

  const { data } = useQuery<
    GetJwtTokenFormShortUrlQuery,
    GetJwtTokenFormShortUrlQueryVariables
  >(GET_JWT_TOKEN_FROM_SHORT_URL, {
    variables: {
      urlId: urlId,
    },
  })

  useEffect(() => {
    if (!data) return
    setToken(data?.getJwtTokenFromShortUrl)
    return () => {
      setToken(undefined)
    }
  }, [data])
  const tokenData =
    token &&
    JSON.parse(
      // Base64-decode
      atob(
        // Data is contained after the first dot
        token.split('.')[1]
      )
    )

  const expirationTimestamp = (tokenData?.exp as number) || 0
  const expirationDate = fromUnixTime(expirationTimestamp)
  const tokenIsValid = !token || (token.match(/\./g) || []).length !== 2

  return {
    /**
     * The jwt token in its entirety.
     */
    token,
    /**
     * The expiration date for the token.
     */
    expirationDate,
    /**
     * A boolean checking shallowly to ensure the token at least exist and
     * has two dots.
     */
    tokenIsValid,
  }
}

/**
 * Hook for handling basic JWT token decoding from url query string
 */
export function useJwtTokenFromQueryString() {
  const location = useLocation()

  const search = queryString.parse(location.search)
  const token = search.token as string

  const tokenData =
    token &&
    JSON.parse(
      // Base64-decode
      atob(
        // Data is contained after the first dot
        token.split('.')[1]
      )
    )

  const expirationTimestamp = (tokenData?.exp as number) || 0
  const expirationDate = fromUnixTime(expirationTimestamp)

  return {
    /**
     * The jwt token in its entirety.
     */
    token,
    /**
     * The expiration date for the token.
     */
    expirationDate,
  }
}
