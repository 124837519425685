import React from 'react'
import styled from 'styled-components'

import { Text } from 'components/Text'
import { Thumbnail } from 'components/Thumbnail'
import { useMedia, useCompany } from 'util/hooks'

import blankProfile from 'assets/images/blank_profile.png'
import { UserNode } from '..'

const ProfileImage = styled.div`
  text-align: center;
  align-self: flex-start;
`

const Name = styled.h2`
  margin-bottom: 0;
  position: relative;
  font-size: 1.1625rem;
  line-height: 1.4;

  ${props => props.theme.layout.mobile} {
    text-align: center;
  }
`

const UserTypes = styled.div`
  ${props => props.theme.layout.mobile} {
    display: flex;
    justify-content: center;
  }

  span + span {
    margin-left: 0.5rem;
  }
`

const UserInfo = styled.div<{ gridArea: string }>`
  grid-area: ${props => props.gridArea};

  display: grid;
  grid-template-columns: auto min-content;
  align-items: flex-start;
  justify-content: left;
  grid-gap: 1rem 0.625rem;

  ${props => props.theme.layout.mobile} {
    grid-template-columns: 1fr;
    margin: 1rem 0;
    grid-gap: 0.5rem;
  }
`

const UserInfoWrapper = styled.div`
  position: relative;
`
export interface ProfileNameRoleProps {
  user: UserNode
  gridArea: string
}
export const ProfileNameRole: React.FC<ProfileNameRoleProps> = ({
  user,
  gridArea,
}) => {
  const [mobile] = useMedia()
  const company = useCompany()

  const filteredUserTypes = user?.userTypes?.filter(usertype =>
    company.userTypes.some(
      companyUserTypes => companyUserTypes.id === usertype.id
    )
  )

  return (
    <UserInfo gridArea={gridArea}>
      <ProfileImage>
        <Thumbnail
          src={user.profilePicture || blankProfile}
          size={mobile ? 80 : 100}
          shape="roundedRectangle"
        />
      </ProfileImage>
      <UserInfoWrapper>
        <Name>
          <p>{user.fullName}</p>
        </Name>
        <UserTypes>
          {filteredUserTypes?.map(type => (
            <Text fontSize={'0.875rem'} key={type.name}>
              {type.name}
            </Text>
          ))}
        </UserTypes>
      </UserInfoWrapper>
    </UserInfo>
  )
}
