import { parsePhoneNumber } from 'libphonenumber-js'

/**
 * Tries to parse json, and if the passed in string is not
 * legal json, it returns the empty object
 */
export function safeParseJson<T>(jsonString: string, fallback: T): T
export function safeParseJson(jsonString: string): any
export function safeParseJson<T = any>(jsonString: string, fallback?: T) {
  try {
    const parsed: T = JSON.parse(jsonString)
    return parsed
  } catch {
    return fallback ?? ({} as any)
  }
}

export const urlIsValid = (url: string) =>
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/.test(
    url
  )

export function safeParsePhoneNumber(
  phone: string,
  type: 'national' | 'international' = 'international'
) {
  try {
    const parsed = parsePhoneNumber(phone)
    if (!parsed) return phone
    return type === 'international'
      ? parsed.formatInternational()
      : parsed.formatNational()
  } catch {
    return phone
  }
}

/**
 * Convert a relay id to it's constituent components
 * @param relayId Relay id
 */
export function fromGlobalId(
  relayId: string
): [node: string, id: number] | null {
  const [node, id] = window.atob(relayId).split(':')
  return !!node && !!id && /^\d+$/.test(id) ? [node, parseInt(id, 10)] : null
}
/**
 * Convert a node type and id to relay id
 * @param node Name of node type
 * @param id Numeric id
 */
export function toGlobalId(node: string, id: number) {
  return btoa(`${node}:${id}`)
}

export const emailIsValid = /.+@.+[.].{1,63}/gi
