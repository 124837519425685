import { useTranslate } from '@ur/react-hooks'
import { CenteredLoader } from 'components'
import React, { useEffect, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router'
import { IdVariable } from 'types/graphql'
import { useBreadcrumbs, useOnErrorAuto } from 'util/hooks'
import { FORM_TEMPLATE_QUERY } from '../queries'
import { EditFormForm, FormField } from '../types'
import { FormTemplateQuery } from '../types.graphql'
import { CreateForm } from './CreateForm'

interface EditFormProps {}

export const EditForm: React.VFC<EditFormProps> = () => {
  const translations = useTranslate({
    loading: 'common.loading...',
  })

  const { formId } = useParams<{ formId: string }>()
  const onErrorAuto = useOnErrorAuto()
  const { setOverride } = useBreadcrumbs()

  const { data, loading } = useQuery<FormTemplateQuery, IdVariable>(
    FORM_TEMPLATE_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: { id: formId },
      onCompleted(data) {
        if (!data) return
        setOverride(data.formTemplate.id, data.formTemplate.name)
      },
      onError: onErrorAuto(),
    }
  )

  useEffect(() => {
    if (!!data) return
    setOverride(formId, translations.loading)
  }, [data, formId, setOverride, translations.loading])

  const form = useMemo<(EditFormForm & { id: string }) | null>(() => {
    if (!data) return null

    const sortableData = [...data.formTemplate.formFields.edges]
    const sortedData = sortableData.sort((a, b) => (a.node.order ?? 0) - (b.node.order ?? 0))

    const fields: FormField[] = 
      sortedData.map(({ node: field }) => ({
        id: field.id,
        type: field.fieldType,
        name: field.name,
        description: field.description,

        required: field.required,
        minimum: field.minimum ?? 0,
        maximum: field.maximum ?? 10,
        minimumEnabled: field.fieldType === 'NUMBER' && field.minimum !== null,
        maximumEnabled: field.fieldType === 'NUMBER' && field.maximum !== null,
      }))

    return {
      id: data.formTemplate.id,
      name: data.formTemplate.name,
      ingress: data.formTemplate.ingress,
      archived: data.formTemplate.archivedAt !== null,
      menuShortcut: data.formTemplate.menuShortcut,
      fields,
    }
  }, [data])

  if (form === null || loading) return <CenteredLoader />

  return <CreateForm initialData={form} />
}
