import { gql } from '@apollo/client'
import { TimesheetFragments } from './fragments'

export const CREATE_ABSENCE_MUTATION = gql`
  mutation CreateAbsence($input: CreateAbsenceInput!) {
    createAbsence(input: $input) {
      absence {
        ...AbsenceInfo
      }
    }
  }
  ${TimesheetFragments.absenceInfo}
`

export const CREATE_TIME_ENTRY_MUTATION = gql`
  mutation CreateTimeEntry($input: CreateTimeEntryInput!) {
    createTimeEntry(input: $input) {
      timeEntry {
        ...TimeEntryInfo
      }
    }
  }
  ${TimesheetFragments.timeEntryInfo}
`

export const UPDATE_ABSENCE_MUTATION = gql`
  mutation UpdateAbsence($id: ID!, $input: UpdateAbsenceInput!) {
    updateAbsence(id: $id, input: $input) {
      absence {
        ...AbsenceInfo
      }
    }
  }
  ${TimesheetFragments.absenceInfo}
`

export const PATCH_ABSENCE_MUTATION = gql`
  mutation PatchAbsence($id: ID!, $input: PatchAbsenceInput!) {
    patchAbsence(id: $id, input: $input) {
      absence {
        ...AbsenceInfo
      }
    }
  }
  ${TimesheetFragments.absenceInfo}
`

export const CREATE_ABSENCE_TYPE_MUTATION = gql`
  mutation CreateAbsenceTypeMutation($input: CreateAbsenceTypeInput!) {
    createAbsenceType(input: $input) {
      absenceType {
        id
        name
      }
    }
  }
`

export const PATCH_ABSENCE_TYPE_MUTATION = gql`
  mutation UpdateAbsenceTypeMutation($id: ID!, $input: PatchAbsenceTypeInput!) {
    patchAbsenceType(id: $id, input: $input) {
      absenceType {
        id
        name
      }
    }
  }
`

export const DELETE_ABSENCE_TYPE_MUTATION = gql`
  mutation DeleteAbsenceTypeMutation($id: ID!) {
    deleteAbsenceType(id: $id) {
      found
    }
  }
`

export const UPDATE_TIME_ENTRY_MUTATION = gql`
  mutation UpdateTimeEntry($id: ID!, $input: UpdateTimeEntryInput!) {
    updateTimeEntry(id: $id, input: $input) {
      timeEntry {
        ...TimeEntryInfo
      }
    }
  }
  ${TimesheetFragments.timeEntryInfo}
`

export const PATCH_TIME_ENTRY_MUTATION = gql`
  mutation PatchTimeEntry($id: ID!, $input: PatchTimeEntryInput!) {
    patchTimeEntry(id: $id, input: $input) {
      timeEntry {
        user {
          id
        }
        ...TimeEntryInfo
      }
    }
  }
  ${TimesheetFragments.timeEntryInfo}
`

export const DELETE_ABSENCE_MUTATION = gql`
  mutation DeleteAbsence($id: ID!) {
    deleteAbsence(id: $id) {
      found
      deletedId
    }
  }
`

export const DELETE_TIME_ENTRY_MUTATION = gql`
  mutation DeleteTimeEntry($id: ID!) {
    deleteTimeEntry(id: $id) {
      found
      deletedId
    }
  }
`

export const CREATE_TIME_ENTRY_PROJECT_MUTATION = gql`
  mutation CreateTimeEntryProject($input: CreateTimeEntryProjectInput!) {
    createTimeEntryProject(input: $input) {
      timeEntryProject {
        id
        name
      }
    }
  }
`

export const DELETE_TIME_ENTRY_PROJECT_MUTATION = gql`
  mutation DeleteTimeEntryProject($id: ID!) {
    deleteTimeEntryProject(id: $id) {
      found
      deletedId
    }
  }
`

export const PATCH_TIME_ENTRY_PROJECT_MUTATION = gql`
  mutation PatchTimeEntryProject(
    $id: ID!
    $input: PatchTimeEntryProjectInput!
  ) {
    patchTimeEntryProject(id: $id, input: $input) {
      timeEntryProject {
        id
      }
    }
  }
`
