import { formatDistanceStrict as formatDistanceStrictBase } from 'date-fns'
import { getLocale } from './getLocale'

interface FormatDistanceStrictOptions {
  unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year'
  roundingMethod?: 'floor' | 'ceil' | 'round'
  addSuffix?: boolean
}

export function formatDistanceStrict(
  firstDate: Date,
  secondDate: Date,
  options: FormatDistanceStrictOptions = {}
) {
  return formatDistanceStrictBase(firstDate, secondDate, {
    locale: getLocale(),
    ...options,
  })
}
