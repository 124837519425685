import { useTranslate } from '@ur/react-hooks'
import { CheckedTable, CheckedTableRow, CheckedTableValue } from 'components'
import differenceWith from 'lodash/differenceWith'
import isEqual from 'lodash/isEqual'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import styled from 'styled-components'
import { createDropdownDomains, UserTypeWithDropdowns } from '../../util'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow-x: auto;
`

interface DropdownsSettingsTableProps {
  userTypes: UserTypeWithDropdowns[]

  onChange: (diff: CheckedTableValue[]) => void
}

export const DropdownsSettingsTable: React.VFC<DropdownsSettingsTableProps> = ({
  userTypes,

  onChange,
}) => {
  const domainTranslations = useTranslate({
    dropdownTitle: 'common.dropdown-on-pages',
    activities: 'common.activities',
    terminal: 'common.terminal',
    timeEntries: 'common.time-entries',
    absence: 'common.absence',
    responsibleUser: 'documents.responsible-user',
    issueCategoryResponsible: 'issue.category-responsible-user',
  })

  const prevUserTypes = useRef<UserTypeWithDropdowns[] | null>(null)
  const initialValues = useRef<CheckedTableValue[]>([])

  const [checkedCollection, setCheckedCollection] = useState<
    CheckedTableValue[]
  >([])

  const domains = useMemo(
    () => createDropdownDomains(domainTranslations),
    [domainTranslations]
  )

  const columns = useMemo(() => userTypes.map(type => type.name), [userTypes])

  const rows = useMemo<CheckedTableRow[]>(() => {
    const rows: CheckedTableRow[] = []

    for (let idx = 0; idx < domains.length; idx++) {
      const domain = domains[idx]
      rows.push({
        type: 'header',
        title: domain.title,
        value: domain.title,
        noStrike: idx === 0,
      })

      for (const [title, dropdown] of Object.entries(domain.dropdowns)) {
        rows.push({
          type: 'regular',
          title,
          value: dropdown,
        })
      }
    }

    return rows
  }, [domains])

  const createCheckedValues = useCallback(
    (types: UserTypeWithDropdowns[]) => {
      let values: CheckedTableValue[] = []

      for (const domain of domains) {
        const dropdowns = Object.values(domain.dropdowns)

        for (const type of types) {
          for (const dropdown of dropdowns) {
            const checked = type.dropdowns.includes(dropdown)

            values.push({
              column: type.id,
              row: dropdown,
              checked,
            })
          }
        }
      }

      return values
    },
    [domains]
  )

  useEffect(() => {
    if (isEqual(prevUserTypes.current, userTypes)) return
    prevUserTypes.current = userTypes

    const values = createCheckedValues(userTypes)
    initialValues.current = values
    setCheckedCollection(values)
  }, [createCheckedValues, userTypes])

  function handleChange(value: CheckedTableValue[]) {
    const diff = differenceWith(value, initialValues.current, isEqual)
    setCheckedCollection(value)

    onChange(diff)
  }

  return (
    <Wrapper>
      <ScrollBar>
        <CheckedTable
          value={checkedCollection}
          columns={columns}
          rows={rows}
          onChange={handleChange}
        />
      </ScrollBar>
    </Wrapper>
  )
}
