import { useTranslate } from '@ur/react-hooks'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { format } from 'util/date-fns'
import { isNullOrUndefined } from 'util/typechecks'
import { ActivityArea } from './ActivityArea'
import { DriverGradeNode } from 'modules/activities/types.graphql'

const Wrapper = styled(ActivityArea)`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-areas:
    'start  hours distance'
    'end    grade consumption';

  background: ${props => props.theme.colors.quaternary};

  ${props => props.theme.media.mobile} {
    overflow-x: auto;
  }
`
interface BoxProps {
  area: string
  borderLeft?: boolean
  borderBottom?: boolean
}
const Box = styled.div<BoxProps>`
  grid-area: ${props => props.area};
  height: 190px;
  padding: 2rem;

  border: 0px solid ${props => props.theme.colors.gray8};
  ${props => props.borderLeft && 'border-left-width: 1px;'};
  ${props => props.borderBottom && 'border-bottom-width: 1px;'};

  h4 {
    margin: 0 0 1.5rem 0;
    color: ${props => props.theme.colors.gray5};
    font-size: 1rem;
    font-weight: 600;
  }
  span {
    font-weight: 600;
    font-size: 1.2rem;
  }

  .location {
    margin: 1.5rem 0 0 0;
    font-weight: 600;
    font-size: 1rem;
    max-height: 5.5rem;
    overflow-y: auto;
    h4 {
      margin: 0 0 0.3rem;
    }
  }
  .time {
    align-items: flex-start;
    h4 {
      margin: 0 0 0.3rem;
    }
  }
  em {
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;

    & + span {
      margin-left: 0.6ch;
      color: ${props => props.theme.colors.gray5};
      font-size: 1.2rem;
    }
  }

  ${props => props.theme.media.mobile} {
    height: auto;
    padding: 1rem;

    h4 {
      margin: 0 0 0.5rem 0;
      font-size: 0.7rem;
    }
    span {
      font-size: 0.9rem;
    }
  }
`
const GradeRow = styled.div`
  ${props => props.theme.media.mobile} {
    display: flex;
  }
`
interface GradeLetterProps {
  active: boolean
}
const GradeLetter = styled.div<GradeLetterProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 4px 0 0 2px;

  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.theme.colors.gray5};
  border-radius: 8px;

  ${props =>
    props.active &&
    css`
      color: ${props.theme.colors.text.dark};
    `};

  ${props => props.theme.media.mobile} {
    width: 24px;
    height: 24px;
    font-size: 0.9rem;
  }
`
type DrivingGrade = 'A' | 'B' | 'C' | 'D' | 'E'
interface GradeProps {
  grade: DrivingGrade | null
}
const Grades: React.VFC<GradeProps> = ({ grade }) => (
  <GradeRow>
    {['A', 'B', 'C', 'D', 'E'].map(letter => (
      <GradeLetter key={letter} active={grade === letter}>
        {letter}
      </GradeLetter>
    ))}
  </GradeRow>
)

type NullOrUndef<T> = T | null | undefined
interface TripInfoProps {
  startTime: NullOrUndef<string>
  startLocation: NullOrUndef<string>
  endTime: NullOrUndef<string>
  endLocation: NullOrUndef<string>
  timeWorkTotal: NullOrUndef<string>
  distance: NullOrUndef<number>
  fuelConsumption: NullOrUndef<number>
  driverGrade: NullOrUndef<DriverGradeNode>
  drivingGrade: NullOrUndef<string>
}

export const TripInfo: React.VFC<TripInfoProps> = ({
  startTime,
  startLocation,
  endTime,
  endLocation,
  timeWorkTotal,
  distance,
  fuelConsumption,
  driverGrade,
  drivingGrade
}) => {
  const translations = useTranslate({
    start: 'common.start',
    end: 'common.end',
    location: 'common.location',
    workingHours: 'activities.working-hours',
    grade: 'activities.driving-grade',
    distance: 'common.distance',
    consumption: 'activities.consumption',

    hours: ['common.hours', val => val.toLowerCase()],
    km: 'common.km',
    liters: ['common.liters', val => val.toLowerCase()],
  })

  const {
    timeStart,
    timeEnd,
    locationStart,
    locationEnd,
    drivenDistance,
    fuel,
    grade,
    scaniaDrivingGrade,
  } = useMemo(() => {
    const notUndef = <T,>(value: NullOrUndef<T>, transform?: (value: T) => T) =>
      isNullOrUndefined(value) ? '-' : transform?.(value) ?? value

    return {
      timeStart: notUndef(startTime, val => format(new Date(val), 'HH:mm')),
      timeEnd: notUndef(endTime, val => format(new Date(val), 'HH:mm')),
      locationStart: notUndef(startLocation),
      locationEnd: notUndef(endLocation),
      drivenDistance: notUndef(distance) || '-',
      fuel: notUndef(fuelConsumption),
      grade: driverGrade,
      scaniaDrivingGrade: !!drivingGrade && ['A', 'B', 'C', 'D', 'E'].includes(drivingGrade) ? (drivingGrade as DrivingGrade) : null
    }
  }, [distance, driverGrade, drivingGrade, endLocation, endTime, fuelConsumption, startLocation, startTime])

  return (
    <Wrapper area="trip-info">
      <Box area="start" borderBottom>
        <div className="time">
          <h4>{translations.start}</h4>
          <span>{timeStart}</span>
        </div>
        <div className="location">
          <h4>{translations.location}</h4>
          <span>{locationStart}</span>
        </div>
      </Box>
      <Box area="hours" borderBottom borderLeft>
        <h4>{translations.workingHours}</h4>
        <div>
          <em>{timeWorkTotal ?? '00:00'}</em>
        </div>
      </Box>
      <Box area="distance" borderBottom borderLeft>
        <h4>{translations.distance}</h4>
        <div>
          <em>{drivenDistance}</em>
          <span>{translations.km}</span>
        </div>
      </Box>

      <Box area="end">
        <div className="time">
          <h4>{translations.end}</h4>
        </div>
        <span>{timeEnd}</span>
        <div className="location">
          <h4>{translations.location}</h4>
          <span>{locationEnd}</span>
        </div>
      </Box>
      <Box area="grade" borderLeft>
        <h4>{translations.grade}</h4>
        {grade?.grade ?
          <div>
            <em>{grade?.grade}</em>
          </div>
        :
          <Grades grade={scaniaDrivingGrade} />
        }
        
      </Box>
      <Box area="consumption" borderLeft>
        <h4>{translations.consumption}</h4>
        <em>{fuel}</em>
        <span>{translations.liters}</span>
      </Box>
    </Wrapper>
  )
}
