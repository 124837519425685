import { Icon, useToast } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { Button } from 'components'
import { Card } from 'components/Card'
import { CREATE_FILLED_OUT_FORM_MUTATION } from 'modules/formcreator/mutations'
import {
  CreateFilledOutFormInput,
  CreateFilledOutFormValueInput,
} from 'modules/formcreator/types.graphql'
import React, { useCallback, useMemo, useState } from 'react'
import { useMutation } from '@apollo/client'
import { isMobile } from 'react-device-detect'
import styled, { css } from 'styled-components'
import { BaseProps } from 'types/props'
import { useOnErrorAuto, useUser } from 'util/hooks'
import { FormTemplate } from '../types'
import { createFieldValues, validateFieldValues } from '../util'
import { FormField } from './FormField'

interface WrapperProps {
  collapsible: boolean
  open: boolean
}
const Wrapper = styled(Card)<WrapperProps>`
  margin-bottom: 1rem;
  padding: 2rem;
  ${props =>
    props.collapsible &&
    !props.open &&
    css`
      cursor: pointer;

      &:hover header i.toggle-collapsed {
        color: ${props => props.theme.colors.secondary};
      }
    `}

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      margin: 0;

      span.response-count {
        margin-left: 1ch;

        font-size: 1rem;
        color: ${props => props.theme.colors.gray6};

        i {
          margin-right: 2px;
        }
        span {
          font-weight: 600;
        }
      }
    }
  }

  ${props => props.theme.media.mobile} {
    padding: 1rem;
  }
`
const Ingress = styled.p`
  white-space: pre-wrap;
`
const Fields = styled.div`
  max-width: 600px;

  ${props => props.theme.media.mobile} {
    width: 100%;
    max-width: 100%;
  }
`
const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;

  button {
    height: 3rem;

    & + button {
      margin-left: 0.5rem;
    }
  }
  ${props => props.theme.media.mobile} {
    button {
      width: 3rem;
      padding: 0;
    }
  }
`

interface FormFormProps extends BaseProps {
  form: FormTemplate
  collapsible?: boolean
  submitSuccessText?: string

  onAfterSubmit?: (success: boolean) => void
}

export const FormForm: React.VFC<FormFormProps> = ({
  className,
  id,

  form,
  collapsible = false,
  submitSuccessText,

  onAfterSubmit,
}) => {
  const translations = useTranslate({
    reset: 'common.reset',
    submit: 'common.submit',

    responseCount: ['form.filled-out-n-times-by-user', { n: 0 }],
    responseCountIncludingToday: [
      'form.filled-out-n-times-by-user-including-today',
      { n: 0 },
    ],

    toast: {
      submitSuccess: 'form.create-filled-form-success',
    },
    validation: {
      invalid: 'form.validation.required-fields-missing',
    },
  })

  const fields = form.formFields.edges
    .map(edge => edge.node)
    .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))

  const initialValues = useMemo(() => createFieldValues(fields), [fields])
  const initialValuesJSON = useMemo(
    () => JSON.stringify(initialValues),
    [initialValues]
  )

  const me = useUser()
  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()

  const [open, setOpen] = useState(!collapsible)
  const [fieldValues, setFieldValues] = useState(initialValues)

  const [createFilledOutForm] = useMutation(CREATE_FILLED_OUT_FORM_MUTATION, {
    refetchQueries: ['ManualEntryBySlug'],
    onCompleted() {
      addToast('success', submitSuccessText ?? translations.toast.submitSuccess)
      handleReset()
      setOpen(!collapsible)

      onAfterSubmit?.(true)
    },
    onError: onErrorAuto(
      {},
      {
        callback: () => onAfterSubmit?.(false),
      }
    ),
  })

  const handleReset = () => setFieldValues(initialValues)

  const handleChange = useCallback((id: string) => {
    return (value: string) => {
      setFieldValues(v => ({
        ...v,
        [id]: value,
      }))
    }
  }, [])

  function handleSubmit() {
    if (!validateFieldValues(fields, fieldValues)) {
      addToast('error', translations.validation.invalid)
      return
    }

    const filledOutFormValuesAdd = Object.entries(fieldValues).reduce<
      CreateFilledOutFormValueInput[]
    >((acc, [id, value]) => [...acc, { formTemplateField: id, value }], [])

    const input: CreateFilledOutFormInput = {
      formTemplate: form.id,
      filledBy: me.id,
      filledOutFormValuesAdd,
    }
    createFilledOutForm({
      variables: { input },
    })
  }

  const noneChanged = useMemo(
    () => JSON.stringify(fieldValues) === initialValuesJSON,
    [fieldValues, initialValuesJSON]
  )
  const formValid = useMemo(
    () => validateFieldValues(fields, fieldValues),
    [fieldValues, fields]
  )

  function handleWrapperClick(evt: React.MouseEvent<HTMLElement>) {
    evt.stopPropagation()
    if (!collapsible || open) return
    setOpen(true)
  }

  return (
    <Wrapper
      className={className}
      id={id}
      collapsible={collapsible}
      open={open}
      onClick={handleWrapperClick}
    >
      <header>
        <h3>
          <Icon icon="clipboard-list" margin="0 1ch 0 0" />
          {form.name}
          {form.myResponsesCount > 0 && (
            <span
              className="response-count"
              title={
                form.userRespondedToday
                  ? translations.responseCountIncludingToday({
                      n: form.myResponsesCount,
                    })
                  : translations.responseCount({ n: form.myResponsesCount })
              }
            >
              <Icon
                icon="check"
                color={form.userRespondedToday ? 'green' : 'gray6'}
              />
              {form.myResponsesCount > 1 && (
                <span>x{form.myResponsesCount}</span>
              )}
            </span>
          )}
        </h3>

        {collapsible && (
          <Icon
            className="toggle-collapsed"
            icon={open ? 'chevron-up' : 'chevron-down'}
            size="1.2rem"
            color="gray6"
            hoverColor="secondary"
            cursor="pointer"
            onClick={() => setOpen(v => !v)}
          />
        )}
      </header>

      {open && (
        <>
          {form.ingress && <Ingress>{form.ingress}</Ingress>}

          <Fields className="form-fields">
            {fields.map(field => (
              <FormField
                key={field.id}
                field={field}
                value={fieldValues[field.id] ?? ''}
                onChange={handleChange(field.id)}
              />
            ))}

            <Buttons>
              <Button
                background="gray8"
                disabled={noneChanged}
                onClick={handleReset}
              >
                {isMobile ? <Icon icon="undo" /> : translations.reset}
              </Button>
              <Button disabled={!formValid} onClick={handleSubmit}>
                {isMobile ? <Icon icon="save" /> : translations.submit}
              </Button>
            </Buttons>
          </Fields>
        </>
      )}
    </Wrapper>
  )
}
