import { countries } from 'util/countries'
import { SelectOption } from '@ur/react-components/build/Select'
import { languages } from './languages'
import { AllowedLocale } from './util'

export function useLanguageOptions() {
  function getFlag(code: AllowedLocale) {
    switch (code) {
      case 'nb':
        return countries.NO.emoji
      case 'en':
        return countries.GB.emoji
      case 'ru':
        return countries.RU.emoji
      case 'lv':
        return countries.LV.emoji
      case 'ro':
        return countries.RO.emoji
      case 'pl':
        return countries.PL.emoji
    }
  }

  const languageOptions: SelectOption<AllowedLocale>[] = languages.map(
    lang => ({
      value: lang.code,
      label: `${getFlag(lang.code)} ${lang.name}`,
    })
  )

  return {
    languageOptions,
  }
}
