import { useQuery } from '@apollo/client'
import { useTranslate } from '@ur/react-hooks'
import { CenteredLoader } from 'components'
import { Card } from 'components/Card/index'
import { PlainLink } from 'components/Link'
import { parseISO } from 'date-fns'
import React from 'react'
import styled from 'styled-components'
import { ZIndexRange } from 'types/style'
import { format } from 'util/date-fns'
import { useOnErrorAuto, useWindowSize } from 'util/hooks'
import { ALL_ABSENCES_QUERY } from './queries'
import { AllAbsencesQuery, AllAbsencesQueryVariables } from './types.graphql'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${ZIndexRange.Extra};
`

const Content = styled(Card)`
  padding: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
`

const Table = styled.table`
  width: 100%;
  padding: 1rem 0 0 1rem;
`

const TableHead = styled.thead`
  text-align: left;
`

const TableRow = styled.tr`
  border: 2px solif red;
`

const TableHeaderCell = styled.th`
  padding: 0.5rem 0;
  border-bottom: 2px solid ${props => props.theme.colors.gray5};
`

const TableCell = styled.td`
  padding: 0.5rem 0;
  border-bottom: 1px solid ${props => props.theme.colors.gray8};
`

export const AbsencesTvDisplay: React.VFC = () => {
  const size = useWindowSize()
  const translations = useTranslate({
    label: {
      date: 'common.date',
      user: 'common.user',
      reason: 'common.reason',
    },
  })

  // Each row is roughly 60px tall. The total page padding is roughly 150px. Hence:
  const numberOfAbsencesToFetch = (size.height - 150) / 60

  const onErrorAuto = useOnErrorAuto()

  const { data, loading } = useQuery<
    AllAbsencesQuery,
    AllAbsencesQueryVariables
  >(ALL_ABSENCES_QUERY, {
    pollInterval: 30000,
    variables: {
      first: numberOfAbsencesToFetch,
      orderBy: '-datetimeStart',
    },
    onError: onErrorAuto(),
  })

  const absences = data?.allAbsences?.edges.map(edge => edge.node) || []

  const rows = absences.map(absence => {
    return {
      id: absence.id,
      data: {
        date: format(parseISO(absence.datetimeStart), 'yyyy.MM.dd'),
        user: (
          <PlainLink to={`/users/${absence.user.id}`}>
            {absence.user.fullName}
          </PlainLink>
        ),
        reason: absence.reason,
      },
    }
  })

  return (
    <Wrapper>
      {loading && <CenteredLoader />}
      <Content>
        <Table>
          <TableHead>
            <TableHeaderCell>{translations.label.date}</TableHeaderCell>
            <TableHeaderCell>{translations.label.user}</TableHeaderCell>
            <TableHeaderCell>{translations.label.reason}</TableHeaderCell>
          </TableHead>
          <tbody>
            {rows.map(row => {
              return (
                <TableRow key={row.id}>
                  <TableCell>{row.data.date}</TableCell>
                  <TableCell>{row.data.user}</TableCell>
                  <TableCell>{row.data.reason}</TableCell>
                </TableRow>
              )
            })}
          </tbody>
        </Table>
      </Content>
    </Wrapper>
  )
}
