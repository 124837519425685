import { SelectOption, SelectProps } from '@ur/react-components/build/Select'
import { useDebounce } from '@ur/react-hooks'
import { Select } from 'components'
import { VEHICLE_PLATE_NUMBERS_QUERY } from 'modules/vehicles'
import React, { useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import { useOnErrorAuto } from 'util/hooks'
import {
  VehiclePlateNumbersQuery,
  VehiclePlateNumbersQueryVariables,
} from '../types.graphl'

const Wrapper = styled.div<{ width?: string }>`
  width: ${props => props.width ?? '100%'};
  .--select-display-inner {
    width: 100%;
  }
  .--select-display-selected {
    color: ${props => props.theme.colors.text.dark};
    max-width: calc(${props => props.width} - 4ch);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

interface VehicleSelectProps extends Omit<SelectProps<string>, 'options'> {
  value: string | null
}

export const VehicleSelect: React.VFC<VehicleSelectProps> = ({
  value,
  onChange,
  nullable,
  width,

  ...selectProps
}) => {
  const onErrorAuto = useOnErrorAuto()
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query)

  const { data, loading } = useQuery<
    VehiclePlateNumbersQuery,
    VehiclePlateNumbersQueryVariables
  >(VEHICLE_PLATE_NUMBERS_QUERY, {
    variables: {
      q: debouncedQuery,
    },
    onError: onErrorAuto(),
  })

  function handleChange(
    id: string | null,
    option: SelectOption<string> | null
  ) {
    if (id === null || option === null) return
    onChange(id, option.extra?.responsibleUser ?? null)
  }

  const options = useMemo<SelectOption<string>[]>(() => {
    if (!data) return []

    const plateNumberArray = data.vehiclePlateNumbers.map(vin => ({
      value: vin,
      label: vin,
    }))
    if (nullable) return [{ value: '', label: '--' }].concat(plateNumberArray)
    else return plateNumberArray
  }, [data, nullable])

  return (
    <Wrapper width={width}>
      <Select
        value={value}
        options={options}
        loading={loading}
        height="3rem"
        width={width}
        onChange={handleChange}
        onFilterChange={setQuery}
        {...selectProps}
      />
    </Wrapper>
  )
}
