import React from 'react'
import styled from 'styled-components'
import { overloadColorProp } from 'util/style'
import { CSSTextTransform, CSSFontStyle, CSSFontWeight } from 'types/style'

interface StyledLabelProps {
  margin?: string
  marginTop: string
  marginRight: string
  marginBottom: string
  marginLeft: string

  color: string
  textTransform: CSSTextTransform
  fontSize: string
  fontStyle: CSSFontStyle
  fontWeight: CSSFontWeight
  lineHeight: string
}
const StyledLabel = styled.label<StyledLabelProps>`
  display: block;

  margin-top: ${props => props.marginTop};
  margin-right: ${props => props.marginRight};
  margin-bottom: ${props => props.marginBottom};
  margin-left: ${props => props.marginLeft};
  margin: ${props => props.margin};

  text-align: left;
  color: ${overloadColorProp};
  text-transform: ${props => props.textTransform};
  font-size: ${props => props.fontSize};
  font-style: ${props => props.fontStyle};
  font-weight: ${props => props.fontWeight};
  line-height: ${props => props.lineHeight};

  ${props => props.theme.layout.mobile} {
    /* Based on earlier 14px used earlier and 1rem = 16px, (14/16 * fontSize)
    should be the right mobile font size */
    font-size: calc(0.875 * ${props => props.fontSize});
  }
`

const RequiredSpan = styled.span`
  color: ${props => props.theme.colors.red};
  font-weight: 600;
  margin-left: 0.2rem;
`
interface LabelProps extends Partial<StyledLabelProps> {
  htmlFor?: string
  fieldRequired?: boolean

  onClick?: (evt: React.MouseEvent<HTMLLabelElement>) => void
}

export const Label: React.FC<LabelProps> = ({
  children,
  htmlFor,

  fieldRequired = false,

  margin,
  marginTop = '0',
  marginRight = '0',
  marginBottom = '5px',
  marginLeft = '4px',

  color = 'inherit',
  textTransform = 'none',
  fontSize = '0.75rem',
  fontStyle = 'normal',
  fontWeight = 700,
  lineHeight = '1.82',

  onClick = () => void 0,
}) => {
  return (
    <StyledLabel
      htmlFor={htmlFor}
      margin={margin}
      marginTop={marginTop}
      marginRight={marginRight}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      color={color}
      textTransform={textTransform}
      fontSize={fontSize}
      fontStyle={fontStyle}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      onClick={onClick}
    >
      {children}
      {fieldRequired && <RequiredSpan>*</RequiredSpan>}
    </StyledLabel>
  )
}
