import { useQuery } from '@apollo/client'
import { Modal } from '@ur/react-components'
import { useDebounce, useTranslate } from '@ur/react-hooks'
import { Checkbox, Input, RegularModal as BaseRegularModal } from 'components'
import { THREAD_VIEWS_QUERY } from 'modules/forum/queries'
import {
  ThreadViewsQuery,
  ThreadViewsQueryVariables,
} from 'modules/forum/types.graphql'
import UserThumbOrInitials, {
  ThumbnailUser,
} from 'modules/users/UserThumbOrInitials'
import React, { useCallback, useMemo, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import ScrollBar from 'react-perfect-scrollbar'
import styled, { css } from 'styled-components'
import { useOnErrorAuto, usePushHistory } from 'util/hooks'

const RegularModal = styled(BaseRegularModal)`
  border-width: 0;

  header {
    padding: 2rem 2rem 1rem;
  }
  div.content {
    padding: 0;
  }
`
const Filter = styled.div`
  position: relative;
  display: flex;
  padding: 0 2rem 1rem;

  div.unseen {
    margin-left: 1rem;

    label {
      white-space: nowrap;
    }
  }

  ${props => props.theme.media.mobile} {
    padding: 0 1rem 1rem;
  }
`
interface ShadowProps {
  visible: boolean
}
const Shadow = styled.div<ShadowProps>`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 5px;

  background-image: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0),
    rgba(0, 0, 0, 0.2)
  );

  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 0.1s linear;
`
const List = styled.ul`
  width: 100%;
  max-height: 60vh;
  margin: 0;
  padding: 0;

  list-style: none;
`
interface ItemProps {
  seen: boolean
}
const Item = styled.div<ItemProps>`
  display: flex;
  align-items: center;
  padding: 0.8rem 2rem;

  cursor: pointer;

  span {
    margin-left: 1rem;
  }

  &:hover span {
    color: ${props => props.theme.colors.secondary};
  }

  ${props =>
    !props.seen &&
    css`
      color: ${props.theme.colors.gray5};

      .thumb {
        filter: grayscale(1) opacity(0.5);
      }
    `};

  & + div {
    border-top: 1px solid ${props => props.theme.colors.gray8};
  }
`
const NoViews = styled(Item).attrs({
  seen: true,
})`
  justify-content: center;
  padding-bottom: 2rem;
`

interface SeenByModalProps {
  open: boolean
  threadId: string

  onClose: () => void
}

interface View {
  seen: boolean
  user: ThumbnailUser
}

export const SeenByModal: React.VFC<SeenByModalProps> = ({
  open,
  threadId,

  onClose,
}) => {
  const translations = useTranslate({
    seenBy: 'common.seen-by',
    filter: 'common.filter',
    unseenOnly: 'forum.unseen-only',

    noViews: 'forum.no-views',
    noResults: 'common.no-results',
  })

  const pushHistory = usePushHistory()
  const onErrorAuto = useOnErrorAuto()

  const [query, setQuery] = useState('')
  const [onlyUnseen, setOnlyUnseen] = useState(false)
  const [showShadow, setShowShadow] = useState(false)
  const [fetchMoreRunning, setFetchMoreRunning] = useState(false)

  const debouncedQuery = useDebounce(query)
  const variables = {
    id: threadId,
    seenFirst: 10,
    unseenFirst: 0,
    q: debouncedQuery,
  }

  const { data, loading, fetchMore } = useQuery<
    ThreadViewsQuery,
    ThreadViewsQueryVariables
  >(THREAD_VIEWS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables,
    onError: onErrorAuto(),
    notifyOnNetworkStatusChange: true,
  })

  function handleScrollY(el: HTMLElement) {
    setShowShadow(el.scrollTop > 0)
  }

  const [pageInfo, hasNextPage, views] = useMemo(() => {
    if (typeof data === 'undefined') return [undefined, undefined, [] as View[]]

    const {
      seenBy: { pageInfo: seenByPageInfo, ...seenRest },
    } = data.threadViews

    const {
      notSeenBy: { pageInfo: notSeenByPageInfo, ...notSeenRest },
    } = data.threadViews

    const seenView = !onlyUnseen
      ? seenRest.edges
          .map(edge => edge.node)
          .map(user => ({ seen: true, user: user }))
      : []
    const notSeenView = notSeenRest.edges
      .map(edge => edge.node)
      .map(user => ({ seen: false, user: user }))

    const seenHasNextPage = seenByPageInfo?.hasNextPage || false
    const notSeenHasNextPage = notSeenByPageInfo?.hasNextPage || false

    const hasNextPage = { seen: seenHasNextPage, unseen: notSeenHasNextPage }
    const pageInfo = { seen: seenByPageInfo, unseen: notSeenByPageInfo }

    const views = seenView.concat(notSeenView) as View[]

    return [pageInfo, hasNextPage, views]
  }, [data, onlyUnseen])

  const fetchMoreViews = useCallback(async () => {
    if (typeof data === 'undefined' || typeof pageInfo === 'undefined') return
    if (!hasNextPage?.seen && !hasNextPage?.unseen) return

    const seenCursor = pageInfo.seen.endCursor
    const unseenCursor = pageInfo.unseen.endCursor

    try {
      await fetchMore({
        variables: {
          seenFirst: 10,
          unseenFirst: 10,
          seenAfter: seenCursor,
          unseenAfter: unseenCursor,
        },
      })
    } catch (e: any) {
      if (e?.name && e.name === 'Invariant Violation') return
      throw e
    }

    setFetchMoreRunning(false)
  }, [data, pageInfo, hasNextPage, fetchMore])

  return (
    <Modal
      open={open}
      placement="top-center"
      direction="from-top"
      offset={!isMobileOnly ? 32 : 8}
      onClose={onClose}
    >
      <RegularModal
        title={translations.seenBy}
        width="600px"
        loading={loading}
        onClose={onClose}
      >
        <Filter>
          <Input
            value={query}
            fullWidth
            autoFocus
            placeholder={translations.filter}
            onChange={setQuery}
          />

          {!isMobileOnly && (
            <div className="unseen">
              <Checkbox
                checked={onlyUnseen}
                wrapped
                label={translations.unseenOnly}
                labelPosition="left"
                onChange={setOnlyUnseen}
              />
            </div>
          )}

          <Shadow visible={showShadow} />
        </Filter>
        <ScrollBar
          onScrollY={handleScrollY}
          onYReachEnd={
            loading || fetchMoreRunning
              ? () => {}
              : () => {
                  setFetchMoreRunning(true)
                  fetchMoreViews()
                }
          }
        >
          <List>
            {views.map((view: View) => (
              <Item
                key={view.user.id}
                role="link"
                seen={view.seen}
                onClick={evt => pushHistory(`/users/${view.user.id}`, evt)}
              >
                <UserThumbOrInitials
                  className="thumb"
                  user={view.user}
                  fontSize="0.8rem"
                />
                <span>{view.user.fullName}</span>
              </Item>
            ))}
          </List>
        </ScrollBar>

        {!views.length && (
          <>
            <NoViews>
              {debouncedQuery === '' && !onlyUnseen
                ? translations.noViews
                : translations.noResults}
            </NoViews>
          </>
        )}
      </RegularModal>
    </Modal>
  )
}
