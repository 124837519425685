import React from 'react'

export const Book: React.VFC<React.SVGAttributes<SVGSVGElement>> = props => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 164.785 164.785"
    enableBackground="new 0 0 164.785 164.785"
    xmlSpace="preserve"
    fill="#003057"
    {...props}
  >
    <g>
      <g>
        <g>
          <path
            className="st0"
            d="M127.241,44.444H44.444c-2.513,0-4.87-0.672-6.9-1.846v91.542c0,3.812,3.089,6.9,6.9,6.9h82.796V44.444z
				 M133.13,38.555c0.624,0.624,1.01,1.487,1.01,2.439V144.49c0,1.905-1.545,3.45-3.45,3.45H44.444
				c-7.62,0-13.799-6.176-13.799-13.799V30.645c0-7.623,6.179-13.799,13.799-13.799h79.347c1.905,0,3.45,1.545,3.45,3.45v17.249
				h3.45C131.643,37.545,132.506,37.931,133.13,38.555z M120.341,37.545V23.745H44.444c-3.81,0-6.9,3.088-6.9,6.9s3.089,6.9,6.9,6.9
				H120.341z"
          />
        </g>
      </g>
      <path
        className="st0"
        d="M53.701,61.65c-1.71,0-2.773,1.062-2.773,2.773c0,1.71,1.062,2.773,2.773,2.773h44.364
		c1.71,0,2.774-1.062,2.774-2.773c0-1.71-1.062-2.773-2.773-2.773H53.701z"
      />
      <path
        className="st0"
        d="M53.701,91.264c-1.71,0-2.773,1.062-2.773,2.773c0,1.71,1.062,2.773,2.773,2.773h44.364
		c1.71,0,2.774-1.062,2.774-2.773c0-1.71-1.062-2.773-2.773-2.773H53.701z"
      />
      <path
        className="st0"
        d="M86.981,76.457c-1.71,0-2.773,1.062-2.773,2.773c0,1.71,1.062,2.773,2.773,2.773h11.084
		c1.71,0,2.774-1.062,2.774-2.773c0-1.71-1.063-2.773-2.774-2.773H86.981z"
      />
      <path
        className="st0"
        d="M53.701,76.428c-1.71,0-2.774,1.062-2.774,2.773c0,1.71,1.063,2.773,2.774,2.773h18.502
		c1.71,0,2.773-1.062,2.773-2.773c0-1.71-1.062-2.773-2.773-2.773H53.701z"
      />
    </g>
  </svg>
)
