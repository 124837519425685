import { gql } from '@apollo/client'

export const NotificationFragments = {
  appNotificationInfo: gql`
    fragment AppNotificationInfo on AppNotificationNode {
      id
      type

      url
      path
      imageUrl

      title
      message
      data

      status

      createdAt
      updatedAt
    }
  `,
}
