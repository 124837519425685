import { useTranslate } from '@ur/react-hooks'

const appPaths = {
  activities: 'common.activities',
  forum: 'common.forum',
  users: 'common.users',
  user: 'common.user',
  handbook: 'common.handbook',
  formcreator: 'common.formcreator',
  issues: 'common.issues',
  absences: 'common.absences',
  usersettings: 'common.usersettings',
  settings: 'common.settings',
  terminal: 'common.terminal',
  infringements: 'common.infringements',
  dashboard: 'common.dashboard',
  'vehicle-fleet': 'common.vehicle-fleet',
  'tv-display': 'common.tv-display',
  'time-entries': 'common.time-entries',
  'route-planner': 'common.route-planner',
  register: 'common.register',

  responses: 'common.responses',
  create: 'common.create',
  edit: 'common.edit',
  salary: 'common.salary',
  export: 'common.export',
  integrations: 'common.integrations',
  dropdowns: 'common.dropdowns',
  'user-types': 'common.user-types',
  'user-documents': 'common.user-documents',
  'checklist-templates': 'common.checklists',
  'issue-categories': 'common.issues',
  absence: 'common.absence',
}

export type AppPath = keyof typeof appPaths

type AppPathTranslations = Record<AppPath, string>

export const useAppPathTranslations = () => {
  const entries = Object.keys(appPaths).reduce((acc, cur) => {
    return {
      ...acc,
      [cur]: appPaths[cur as AppPath],
    }
  }, {})
  const baseTranslations = useTranslate(entries) as AppPathTranslations
  function getCountryCodeTranslations(
    code: AppPath,
    translations: AppPathTranslations = baseTranslations
  ) {
    return translations[code]
  }
  return getCountryCodeTranslations
}
