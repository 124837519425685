import { gql } from '@apollo/client'
import { UserFragments } from 'modules/users/fragments'
import { ForumFragments } from './queries'

// We have custom mutation handling here, and hence the
// input is slightly non-standard
export const CREATE_THREAD_MUTATION = gql`
  mutation CreateThread(
    $title: String!
    $content: String!
    $attachments: [Upload]
    $sticky: Boolean
    $sendSms: Boolean
    $sendEmail: Boolean
  ) {
    createThread(
      title: $title
      content: $content
      attachments: $attachments
      sticky: $sticky
      sendSms: $sendSms
      sendEmail: $sendEmail
    ) {
      ok
      thread {
        id
      }
    }
  }
`

export const PATCH_THREAD_MUTATION = gql`
  mutation PatchThread($id: ID!, $input: PatchThreadInput!) {
    patchThread(id: $id, input: $input) {
      thread {
        ...ThreadInfo
      }
    }
  }
  ${ForumFragments.threadInfo}
`

export const ADD_THREAD_INTERACTION_MUTATION = gql`
  mutation AddThreadInteraction($thread: ID!) {
    addThreadInteraction(thread: $thread) {
      threadUserInteraction {
        id
        thread {
          id
          seen
        }
      }
    }
  }
`

export const DELETE_THREAD_MUTATION = gql`
  mutation DeleteThread($id: ID!) {
    deleteThread(id: $id) {
      found
      deletedId
    }
  }
`

export const CREATE_POST_MUTATION = gql`
  mutation CreatePost($input: CreatePostInput!) {
    createPost(input: $input) {
      post {
        id
      }
    }
  }
`

export const PATCH_POST_MUTATION = gql`
  mutation PatchPost($id: ID!, $input: PatchPostInput!) {
    patchPost(id: $id, input: $input) {
      post {
        ...PostInfo
        user {
          ...UserInfo
        }
        attachments {
          ...PostAttachmentInfo
        }
      }
    }
  }
  ${ForumFragments.postInfo}
  ${ForumFragments.postAttachmentInfo}
  ${UserFragments.info}
`

export const DELETE_POST_MUTATION = gql`
  mutation DeletePost($id: ID!) {
    deletePost(id: $id) {
      found
      deletedId
    }
  }
`

export const DELETE_POST_ATTACHMENT_MUTATION = gql`
  mutation DeletePostAttachment($id: ID!) {
    deletePostAttachment(id: $id) {
      deletedId
      found
    }
  }
`

export const DELETE_THREAD_ATTACHMENT_MUTATION = gql`
  mutation DeleteThreadAttachment($id: ID!) {
    deleteThreadAttachment(id: $id) {
      deletedId
      found
    }
  }
`
