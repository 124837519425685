import { useCallback, useLayoutEffect, useState } from 'react'

function getSize(el: HTMLElement | null) {
  if (!el) {
    return {
      width: 0,
      height: 0,
    }
  }

  return {
    width: el.offsetWidth,
    height: el.offsetHeight,
  }
}

export function useElementSize(el: HTMLElement | null) {
  const [size, setSize] = useState(getSize(el))

  const handleResize = useCallback(() => {
    if (!el) return
    setSize(getSize(el))
  }, [el])

  useLayoutEffect(() => {
    if (!el) return

    handleResize()

    if (typeof ResizeObserver === 'function') {
      let observer: ResizeObserver | null = new ResizeObserver(() =>
        handleResize()
      )
      observer.observe(el)

      return () => {
        observer?.disconnect()
        observer = null
      }
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [el, handleResize])

  return size
}
