import styled from 'styled-components'
import { useUser, useAdmin, usePermissions } from 'util/hooks'

import { PermissionsOrMeRequired } from 'containers/permission-containers/PermissionsOrMeRequired'
import { usePrompt } from '@ur/react-components'
import {
  ExportButton as BaseButton,
  ModuleTitle,
  MonthPicker,
  UserSelect,
} from 'components'
import { useTranslate } from '@ur/react-hooks'
import { isMobileOnly } from 'react-device-detect'
import { PERMISSIONS } from 'util/permissions'
import { Card } from 'components/Card'
import { useExportTerminalEntriesMutations } from '../mutations.hooks'
import { AllTerminalEntriesFiltered } from './AllTerminalEntriesFiltered'
import { ExportTerminalEntriesPrompt } from './ExportTerminalEntriesPrompt'
import { ExportTerminalEntriesForm } from './types'
import { DropdownNames } from 'components/Select/types'

const ContentWrapper = styled.div`
  ${props => props.theme.layout.default};
`

const ExportButton = styled(BaseButton)`
  ${props => props.theme.media.mobile} {
    padding: 1rem !important;
    text-align: center;
  }

  ${props => props.theme.media.mediumScreen} {
    padding: 1rem 0.4rem 1rem 2rem !important;

    span {
      display: none;
    }
  }
`

const Controls = styled(Card)`
  display: grid;
  grid-template-columns: 1fr auto;

  margin-bottom: 1rem;
  padding: 0;

  section {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1rem;
    padding: 1rem;

    & + section {
      grid-template-columns: auto;
      border-left: 1px solid ${props => props.theme.colors.gray8};
    }
  }

  ${props => props.theme.media.mobile} {
    grid-template-columns: 1fr;

    section {
      grid-template-columns: 1fr;
      padding: 1rem;

      & + section {
        padding-top: 0;
        border-left: 0;
      }
    }
  }
`

export const Terminal: React.FC = () => {
  const me = useUser()
  const admin = useAdmin()
  const addPrompt = usePrompt()

  const { hasPermissions } = usePermissions()
  const canExport = hasPermissions(
    PERMISSIONS.companies.export.exportReports,
    true
  )

  const translations = useTranslate({
    terminal: 'common.terminal',
    title: 'common.type',
    options: {
      monthly: 'exports.month-export',
      salary: 'terminal.salary-basis',
    },
    export: 'common.export',
  })

  /* Filter */
  const {
    filter,
    loading,
    startMonthYearTime,
    salaryDates,
    doExport,
    updateFilter,
    filterHandler,
  } = useExportTerminalEntriesMutations()

  async function handleExport() {
    const { data } = await addPrompt<ExportTerminalEntriesForm | null>(
      resolve => (
        <ExportTerminalEntriesPrompt
          onSubmit={resolve}
          salaryDates={salaryDates}
        />
      )
    )
    if (!data) return

    doExport(data)
  }

  const isLoading = loading

  return (
    <PermissionsOrMeRequired
      user={me}
      permissions={PERMISSIONS.terminal.view.terminalentry}
    >
      <ContentWrapper>
        <ModuleTitle>{translations.terminal}</ModuleTitle>
        <Controls>
          <section>
            {admin && (
              <UserSelect
                value={filter.user}
                grayed
                dropdownPageName={DropdownNames.TERMINAL}
                width="100%"
                minWidth={isMobileOnly ? '0' : '260px'}
                height="3.875rem"
                onChange={user => updateFilter({ user: user ?? me.id })}
              />
            )}

            <MonthPicker
              value={filter.month}
              futureYears={0}
              minWidth={isMobileOnly ? '0' : '380px'}
              width={isMobileOnly ? '100%' : undefined}
              maxWidth={isMobileOnly ? '100%' : '420px'}
              onChange={filterHandler('month')}
            />
          </section>

          {canExport && (
            <section>
              <ExportButton
                iconLeftProps={{ icon: 'download' }}
                fullWidth={isMobileOnly}
                disabled={isLoading}
                onClick={handleExport}
              >
                {/* TODO not correct icon */}
                {isMobileOnly ? (
                  translations.export
                ) : (
                  <span>{translations.export}</span>
                )}
              </ExportButton>
            </section>
          )}
        </Controls>
        <AllTerminalEntriesFiltered
          userId={filter.user}
          userName={me.fullName}
          month={startMonthYearTime}
        />
      </ContentWrapper>
    </PermissionsOrMeRequired>
  )
}
