import React from 'react'
import styled from 'styled-components'
import { format } from 'util/date-fns'
import { UserNode } from 'modules/users'
import { useTranslate } from '@ur/react-hooks'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  width: 100%;
`

const Filter = styled.div`
  padding: 2em;
  background: white;
  border-right: 1px solid ${props => props.theme.colors.gray11};
  border-bottom: 1px solid ${props => props.theme.colors.gray11};

  :last-of-type {
    border-right: none;
  }

  ${props => props.theme.layout.mobile} {
    padding: 1.2rem;
  }
`

const FilterLabel = styled.div`
  font-weight: 300;
  color: ${props => props.theme.colors.tertiary};
  font-size: 14px;
`

const FilterValue = styled.div`
  font-size: 26px;
  ${props => props.theme.layout.mobile} {
    font-size: 16px;
  }
`
export interface ActivitiesDetailFiltersProps {
  vehicleRegistrationNumber: string
  date: Date
  user: UserNode
}

export const ActivitiesDetailFilters: React.FC<ActivitiesDetailFiltersProps> =
  ({ vehicleRegistrationNumber, date, user }) => {
    const translations = useTranslate({
      regNr: 'common.reg-nr',
      date: 'common.date',
      driver: 'common.driver',
    })
    return (
      <Wrapper>
        <Filter>
          <FilterLabel>{translations.regNr}</FilterLabel>
          <FilterValue>{vehicleRegistrationNumber}</FilterValue>
        </Filter>
        <Filter>
          <FilterLabel>{translations.date}</FilterLabel>
          <FilterValue>{format(date, 'dd.MM.yyyy')}</FilterValue>
        </Filter>
        <Filter>
          <FilterLabel>{translations.driver}</FilterLabel>
          <FilterValue>{user?.fullName ?? ''}</FilterValue>
        </Filter>
      </Wrapper>
    )
  }
