import { useTranslate } from '@ur/react-hooks'
import {
  PostAttachmentsContainer,
  QuillRenderer,
  TableMenuItem,
} from 'components'
import { Card } from 'components/Card'
import { PopupMenu } from 'components/PopupMenu'
import omit from 'lodash/omit'
import { PostAttachmentNode, ThreadAttachmentNode } from 'modules/forum'
import { UserProfileLink } from 'modules/users/UserProfile/UserProfileLink'
import UserThumbOrInitials, {
  ThumbnailUser,
} from 'modules/users/UserThumbOrInitials'
import Quill from 'quill'
import Delta from 'quill-delta'
import Op from 'quill-delta/dist/Op'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import styled, { css } from 'styled-components'
import { removeCurrentYear } from 'util/date'
import { format } from 'util/date-fns'
import {
  Attachment,
  FileAttachment,
  useAdmin,
  useConfirm,
  usePermissions,
} from 'util/hooks'
import { safeParseJson } from 'util/parsing'
import { PERMISSIONS } from 'util/permissions'
import { ForumQuillEditor, ForumQuillEditorRef, PostAttachment } from '..'
import { SeenBar, usePostCardMutations } from '../thread'

const Wrapper = styled(Card)`
  padding: 0;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem 1rem 1.5rem !important;
    border-bottom: 1px solid ${props => props.theme.colors.gray8};
  }

  ${props => props.theme.media.mobile} {
    header {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`
const HeaderRight = styled.div`
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 1rem;
  }

  ${props => props.theme.media.mobile} {
    justify-content: center;
    width: 100%;
    margin-top: 1rem;

    & > * + * {
      margin-left: 0;
    }
  }
`
const PostInfo = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 0.8rem;

  .thumb {
    grid-column: 1;
    grid-row: span 2;
  }
  a {
    grid-column: 2;
    grid-row: 1;
    color: ${props => props.theme.colors.primary};
  }
  span.date {
    grid-column: 2;
    grid-row: 2;
    color: ${props => props.theme.colors.gray5};
  }
  & > * {
    align-self: center;
  }

  ${props => props.theme.media.mobile} {
    grid-template-columns: auto 1fr auto;
    width: 100%;
  }
`
interface ContentProps {
  editing: boolean
}
const Content = styled.div<ContentProps>`
  padding: ${props => !props.editing && '1.5rem'};

  ${props =>
    props.editing &&
    css`
      border-bottom-left-radius: ${props.theme.sizes.defaultBorderRadius};
      border-bottom-right-radius: ${props.theme.sizes.defaultBorderRadius};
    `};
`
interface PostCardProps {
  thread?: boolean
  /** Id of thread or post */
  id: string

  user: ThumbnailUser
  content: string
  createdAt: Date
  attachments: ThreadAttachmentNode[] | PostAttachmentNode[]
  numberOfViews?: number
  editThreadValues?: {
    title: string
    sticky: boolean
  }

  onAttachmentClick: (
    attachment: ThreadAttachmentNode | PostAttachmentNode
  ) => void
  onEditThread?: (editing: boolean) => void
}

export const PostCard: React.VFC<PostCardProps> = ({
  thread = false,
  id,

  user,
  content,
  createdAt,
  attachments,
  numberOfViews,
  editThreadValues,

  onAttachmentClick,
  onEditThread,
}) => {
  const translations = useTranslate({
    writtenWhen: ['forum.written-when', { when: '' }],

    edit: 'common.edit',
    cancelEdit: 'common.cancel-changes',
    delete: 'common.delete-alt',

    updateThread: 'forum.update-thread',
    updateComment: 'forum.update-comment',

    prompt: {
      deleteThread: 'forum.prompts.delete-thread',
      deleteThreadTitle: 'forum.prompts.delete-thread-title',
      deletePost: 'forum.prompts.delete-post',
      deletePostTitle: 'forum.prompts.delete-post-title',
      deleteAttachment: 'forum.prompts.delete-attachment',
      deleteAttachmentTitle: 'forum.prompts.delete-attachment-title',
    },
    error: {
      deleteThread: 'forum.errors.delete-thread-failed',
      deletePost: 'forum.errors.delete-post-failed',
    },
  })

  const quillRef = useRef<ForumQuillEditorRef>(null)

  const confirm = useConfirm()
  const admin = useAdmin()
  const { hasPermissionsAndMe } = usePermissions()

  const [delta, setDelta] = useState(
    new Delta(safeParseJson<Op[]>(content, []))
  )
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    if (typeof onEditThread === 'undefined') return
    onEditThread(editing)
  }, [editing, onEditThread])

  const canEdit =
    admin || !!hasPermissionsAndMe(user, PERMISSIONS.forum.change.post)
  const canDelete =
    admin || !!hasPermissionsAndMe(user, PERMISSIONS.forum.delete.post)
  const showMenu = canEdit || canDelete

  const {
    patchThread,
    patchPost,
    deleteThread,
    deletePost,
    deleteThreadAttachment,
    deletePostAttachment,
    loading: mutationLoading,
  } = usePostCardMutations(translations)

  const handleDeletePost = useCallback(async () => {
    if (!canDelete) return

    const message = thread
      ? translations.prompt.deleteThread
      : translations.prompt.deletePost
    const title = thread
      ? translations.prompt.deleteThreadTitle
      : translations.prompt.deletePostTitle

    const { data: answer } = await confirm(message, title)
    if (!answer) return

    const mutation = thread ? deleteThread : deletePost
    mutation({
      variables: {
        id,
      },
    })
  }, [
    canDelete,
    confirm,
    deletePost,
    deleteThread,
    id,
    thread,
    translations.prompt.deletePost,
    translations.prompt.deletePostTitle,
    translations.prompt.deleteThread,
    translations.prompt.deleteThreadTitle,
  ])

  async function handleDeleteAttachment(attachment: Attachment) {
    if (!canEdit) return

    const { data: answer } = await confirm(
      translations.prompt.deleteAttachment,
      translations.prompt.deleteAttachmentTitle
    )
    if (!answer) return

    if (!thread)
      deletePostAttachment({
        variables: {
          id: attachment.id,
        },
      })
    else
      deleteThreadAttachment({
        variables: {
          id: attachment.id,
        },
      })
  }

  function onInitQuill(quill: Quill) {
    quill.setContents(delta)
    quill.setSelection(quill.getLength(), 0)
  }
  async function onEditSubmit(
    content: string,
    attachmentsAdd: FileAttachment[],
    attachmentsRemove: string[]
  ) {
    try {
      if (!thread) {
        await patchPost({
          variables: {
            id,
            input: {
              content: content,
              attachmentsAdd: attachmentsAdd.map(attachment =>
                omit(attachment, 'id')
              ),
              attachmentsRemove,
            },
          },
        })
        setDelta(new Delta(safeParseJson<Op[]>(content, [])))
      } else {
        await patchThread({
          variables: {
            id,
            input: {
              title: editThreadValues?.title,
              sticky: editThreadValues?.sticky,
              content: content,
              attachmentsAdd: attachmentsAdd.map(attachment =>
                omit(attachment, 'id')
              ),
              attachmentsRemove,
            },
          },
        })
        setDelta(new Delta(safeParseJson<Op[]>(content, [])))
      }
    } finally {
      setEditing(false)
    }
  }

  const menuItems = useMemo<TableMenuItem<never>[]>(() => {
    if (!showMenu) return []

    const items: TableMenuItem<never>[] = []

    if (canEdit)
      items.push({
        label: editing ? translations.cancelEdit : translations.edit,
        onClick: () => setEditing(!editing),
      })
    if (canDelete)
      items.push({
        label: translations.delete,
        onClick: handleDeletePost,
      })

    return items
  }, [
    showMenu,
    canEdit,
    editing,
    translations.edit,
    translations.cancelEdit,
    translations.delete,
    canDelete,
    handleDeletePost,
  ])

  return (
    <Wrapper>
      <header>
        <PostInfo>
          <UserThumbOrInitials
            className="thumb"
            user={user}
            size={48}
            fontSize="0.8em"
          />
          <UserProfileLink user={user} />
          <span className="date">
            {translations.writtenWhen({
              when: removeCurrentYear(format(createdAt, 'PPP HH:mm')),
            })}
          </span>

          {isMobile && showMenu && (
            <PopupMenu items={menuItems} loading={mutationLoading} />
          )}
        </PostInfo>

        <HeaderRight>
          {thread && admin && typeof numberOfViews !== 'undefined' && (
            <SeenBar
              views={numberOfViews}
              verbose
              width="200px"
              clickable
              asModal
              threadId={id}
            />
          )}
          {!isMobile && showMenu && (
            <PopupMenu items={menuItems} loading={mutationLoading} />
          )}
        </HeaderRight>
      </header>

      <Content editing={editing}>
        {editing ? (
          <ForumQuillEditor
            ref={quillRef}
            initialContent={content}
            attachments={attachments}
            roundedTopCorners
            submitText={
              thread ? translations.updateThread : translations.updateComment
            }
            onInit={onInitQuill}
            onSubmit={onEditSubmit}
          />
        ) : (
          <QuillRenderer contents={delta} />
        )}
      </Content>

      {!!attachments.length && !editing && (
        <PostAttachmentsContainer>
          {attachments.map(attachment => (
            <PostAttachment
              key={attachment.id}
              attachment={attachment}
              deletable={canEdit && !isMobile}
              onDelete={handleDeleteAttachment}
              onClick={() => onAttachmentClick(attachment)}
            />
          ))}
        </PostAttachmentsContainer>
      )}
    </Wrapper>
  )
}
