import { Loader } from '@ur/react-components'
import { FirmadokIcon, SVGIcon } from 'components'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { usePermissions } from 'util/hooks'

const growBackground = keyframes`
  from {
    top: 31px;
    left: 31px;
    width: 0%;
    height: 0%;
  }
  to {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  padding: 0 16px;
  min-width: 54px;

  border-radius: 999px;
  font-size: 0.9em;
  color: ${props => props.theme.colors.gray2};
  background-color: ${props => props.theme.colors.tertiary}55;
`

interface NavLinkProps {
  marginoverride?: string
}

const Item = styled(NavLink)<NavLinkProps>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.875rem;
  padding: 0 16px 0 0;

  color: ${props => props.theme.colors.gray4};
  border-radius: 16px;

  & > div {
    display: flex;
    align-items: center;
  }

  span {
    margin-left: ${props => props.marginoverride ?? '1em'};
  }
  svg,
  span {
    transition: transform 0.2s ease-out;
  }

  &::before {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 0;
    height: 0;

    background-color: ${props => props.theme.colors.primary};
    border-radius: 16px;
  }

  &:hover {
    text-decoration: none;

    span {
      font-weight: 600;
    }
    svg {
      fill: black;
    }
  }

  &.active {
    &::before {
      content: '';
      animation: ${growBackground} 200ms ease-out forwards;
    }

    svg {
      fill: white;
    }
    span {
      color: white;
      font-weight: 600;
    }

    ${Badge} {
      color: white;
      font-weight: 600;
      background-color: ${props => props.theme.colors.tertiary}aa;
    }
  }
`

export interface SidebarNavItemProps {
  className?: string

  to: string
  target?: string
  text: string
  badge?: string | number
  badgeLoading?: boolean

  icon: FirmadokIcon
  iconWidth?: string
  marginoverride?: string

  exact?: boolean
  permissionsRequired?: string | string[]
}

export const SidebarNavItem: React.VFC<SidebarNavItemProps> = ({
  className,

  to,
  target,
  text,
  badge,
  badgeLoading = false,

  icon,
  iconWidth = '28px',
  marginoverride,

  exact,
  permissionsRequired = [],
}) => {
  const { hasPermissions } = usePermissions()

  if (!hasPermissions(permissionsRequired)) return null

  return (
    <Item
      className={className}
      exact={exact}
      to={to}
      target={target}
      marginoverride={marginoverride}
    >
      <div>
        <SVGIcon icon={icon} width={iconWidth} fill="gray4" />
        <span>{text}</span>
      </div>

      {typeof badge !== 'undefined' && (
        <Badge>{badgeLoading ? <Loader.Dots size={26} /> : badge}</Badge>
      )}
    </Item>
  )
}
