import { useTranslate } from '@ur/react-hooks'
import {
  useCompositeModules,
  useModuleConfigs,
} from 'modules/companies/CompanySettings/hooks'
import { useCompanyHasDashboardShortcuts } from 'modules/handbook'
import { useHandbookDashboardLinks } from 'modules/handbook/hooks'
import { TerminalCard } from 'modules/terminal/TerminalView/TerminalCard'
import React from 'react'
import styled from 'styled-components'
import { userHasPermissions } from 'util/auth'
import { useAdmin, useUser } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { DashboardShortcuts } from '.'
import { DashboardChecklists } from './DashboardChecklists'
import { DashboardControl } from './DashboardControl'
import { DashboardDriverCard } from './DashboardDriverCard'
import { DashboardLinks } from './DashboardLinks'
import { DashboardStatistics } from './DashboardStatistics'
import { isMobile } from 'react-device-detect'
import { DashboardNotifications } from './DashboardNotifications'

interface WrapperProps {
  showDriverCard: boolean
  showTerminal: boolean
  showShortcuts: boolean
}
const Wrapper = styled.div<WrapperProps>`
  ${props => props.theme.layout.default};

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 1fr));
  grid-template-areas:
    'notifications notifications'
    ${props => props.showDriverCard && "'driver-card driver-card'"}
    ${props => props.showShortcuts && "'shortcuts   shortcuts'"}
    ${props => props.showShortcuts && "'links links'"}
    ${props => props.showTerminal && "'terminal terminal'"}
    'checklist checklist'
    'stats       control';

  h2 {
    margin-top: 0;
    padding-left: 12px;
    font-size: 1.6em;
    color: ${props => props.theme.colors.primary};
  }

  ${props => props.theme.layout.mobile} {
    display: flex;
    flex-direction: column;
    gap: 0;

    width: 100%;

    h2 {
      margin-bottom: 8px;
      font-size: 1.4em;
      font-weight: 600;
    }

    & > * + * {
      margin-top: 38px;
    }
  }
`
interface AreaProps {
  area: string
  padRight?: boolean
}
const Area = styled.div<AreaProps>`
  grid-area: ${props => props.area};
  margin-bottom: 52px;

  ${props => props.padRight && 'margin-right: 52px;'};

  ${props => props.theme.layout.mobile} {
    width: 100%;
  }
`

const NoModulesWrapper = styled.div`
  ${props => props.theme.layout.default};
`

export const Dashboard: React.VFC = () => {
  const user = useUser()
  const admin = useAdmin()
  const { activitiesActive, terminalActive, hasAnyModule, handbookActive } =
    useModuleConfigs()
  const translations = useTranslate({
    noActiveModules: 'common.no-active-modules',
  })

  const { links: handbookLinks } = useHandbookDashboardLinks()

  const showHandbookLinks =
    userHasPermissions(user, PERMISSIONS.handbook.view.manualentry) &&
    handbookActive

  const showTerminal =
    userHasPermissions(user, PERMISSIONS.terminal.view.terminalentry) &&
    terminalActive

  const showDriverCard =
    userHasPermissions(user, PERMISSIONS.users.add.driver_card) &&
    !user.activeDriverCard &&
    activitiesActive &&
    !admin
  
  const showNotifications = (
    userHasPermissions(user, PERMISSIONS.users.change.driver_card)
    && userHasPermissions(user, PERMISSIONS.users.change.user)
  ) || admin
    


  const { dashboardStatisticsModuleActive, activitiesHandbookIssuesActive } =
    useCompositeModules()

  const showControl = activitiesHandbookIssuesActive
  const showStats = dashboardStatisticsModuleActive

  const hasHandbookShortcuts = useCompanyHasDashboardShortcuts()

  return (
    <>
      {hasAnyModule ? (
        <Wrapper
          showShortcuts={hasAnyModule ?? false}
          showDriverCard={showDriverCard ?? false}
          showTerminal={showTerminal ?? false}
        >

          {showNotifications && (
            <Area area="notifications">
              <DashboardNotifications />
            </Area>
          )}
          {showDriverCard && (
            <Area area="driver-card">
              <DashboardDriverCard />
            </Area>
          )}
          {showControl && (
            <Area area="control">
              <DashboardControl />
            </Area>
          )}

          {hasAnyModule && (
            <Area area="shortcuts">
              <DashboardShortcuts />
            </Area>
          )}

          {showHandbookLinks && hasHandbookShortcuts && (
            <Area area="links">
              <DashboardLinks links={handbookLinks} />
            </Area>
          )}

          {showTerminal && (
            <Area area="terminal">
              <TerminalCard />
            </Area>
          )}

          {showStats && (
            <Area area="stats" padRight={!isMobile}>
              <DashboardStatistics />
            </Area>
          )}

          <Area area="checklist">
            <DashboardChecklists />
          </Area>
        </Wrapper>
      ) : (
        <NoModulesWrapper>{translations.noActiveModules}</NoModulesWrapper>
      )}
    </>
  )
}
