import { IntegrationType, Integration } from 'modules/companies'
import React, { useRef } from 'react'
import styled from 'styled-components'
import useClickOutside from 'util/hooks/useClickOutside'

const Wrapper = styled.aside`
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;

  background-color: ${props => props.theme.colors.secondary};
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  overflow: hidden;
  cursor: pointer;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 3px 2rem 0;

    font-weight: 600;

    & + div {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
`

interface SelectIntegrationTypeProps {
  open: boolean
  onClose: () => void
  onSubmit: (type: IntegrationType) => void
}

export const SelectIntegrationType: React.VFC<SelectIntegrationTypeProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const wrapperRef = useRef<HTMLElement>(null)

  useClickOutside(wrapperRef, () => onClose(), true)

  if (!open) return null

  return (
    <Wrapper ref={wrapperRef}>
      {Object.values(Integration).map(integration => (
        <div onClick={() => onSubmit(integration)}>{integration}</div>
      ))}
    </Wrapper>
  )
}
