import { Icon, Image, ImageViewer } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import {
  Button,
  DropArea,
  HandbookEntryAttachmentsContainer,
  QuillRenderer,
  QuillRendererRef,
  TableMenuItem,
} from 'components'
import { PopupMenu } from 'components/PopupMenu'
import { FormForm } from 'modules/formcreator/components'
import { PostAttachment } from 'modules/forum/components/PostAttachment'
import Quill from 'quill'
import Delta from 'quill-delta'
import Op from 'quill-delta/dist/Op'
import React, { useMemo, useRef, useState } from 'react'
import { isMobile, isMobileOnly } from 'react-device-detect'
import { useLocation, useParams } from 'react-router'
import styled, { useTheme } from 'styled-components'
import { ZIndexRange } from 'types/style'
import { format } from 'util/date-fns'
import { quillValidOrHasAttachments } from 'util/forms'
import { useAttachmentHandlers, useConfirm, usePermissions } from 'util/hooks'
import { safeParseJson } from 'util/parsing'
import { PERMISSIONS } from 'util/permissions'
import {
  AddAttachment,
  ContentWrapper,
  Description,
  DescriptionInput,
  EditButtons,
  EditHandbookEntryForm,
  EntryQuillEditor,
  EntryQuillEditorRef,
  SelectHandbookForm,
  TitleInput,
} from '../components'
import { ManualEntryPlaceholder } from '../consts'
import { useHandbookEntryMutations, useHandbookEntryQuery } from '../hooks'

const Wrapper = styled.div`
  ${props => props.theme.layout.default};

  & > header {
    margin-bottom: 1rem;
    padding-left: 4px;

    h2 {
      margin: 0;
    }
    p {
      margin: 6px 0 0 0;
      font-weight: 500;
      color: ${props => props.theme.colors.gray5};
    }
  }
`
interface RichtextProps {
  editing: boolean
}
const Richtext = styled.article<RichtextProps>`
  overflow-x: hidden;
  ${props => props.editing && 'padding: 0 !important;'};
`
interface HandbookEntryParams {
  folderSlug: string
  entrySlug: string
}

export const HandbookEntry: React.VFC = () => {
  const translations = useTranslate({
    edit: 'common.edit',
    delete: 'common.delete-alt',
    cancel: 'common.cancel',
    save: 'common.save',
    published: 'common.published',
    lastRevision: 'common.last-revision',

    validation: {
      required: 'common.required',
    },
    prompt: {
      deleteEntry: 'handbook.prompts.delete-entry',
      deleteEntryTitle: 'handbook.prompts.delete-entry-title',
    },
  })

  const theme = useTheme()

  const quillRendererRef = useRef<QuillRendererRef>(null)
  const quillEditorRef = useRef<EntryQuillEditorRef>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const { folderSlug, entrySlug } = useParams<HandbookEntryParams>()
  const location = useLocation<{ edit: boolean }>()
  const confirm = useConfirm()
  const { hasPermissions } = usePermissions()

  const [editing, setEditing] = useState(!!location.state?.edit)
  const [imageViewerInitialImage, setImageViewerInitialImage] = useState('')
  const [imageViewerAttachments, setImageViewerAttachments] = useState<Image[]>(
    []
  )

  const {
    formValues: editForm,
    formErrors: editErrors,
    formValid: editValid,
    formChangeHandler: handler,
    updateForm,
    resetForm,
    updateInitialValues,
    submitHandler: submit,
  } = useForm<EditHandbookEntryForm>({
    values: {
      name: '',
      description: '',
      richtext: '{}',
      form: null,
    },
    validators: {
      name: val => (!!val ? null : translations.validation.required),
      richtext: () =>
        handlequillValidOrHasAttachments(translations.validation.required),
    },
  })

  const { data, loading: queryLoading } = useHandbookEntryQuery(
    entrySlug,
    folderSlug,
    updateForm,
    updateInitialValues,
    data => {
      if (editing && !!quillEditorRef.current?.quill) {
        const delta = new Delta(
          safeParseJson<Op[]>(data.manualEntry.richtext, [])
        )
        quillEditorRef.current.quill.setContents(delta)
      }
    }
  )

  const entry = data?.manualEntry ?? ManualEntryPlaceholder

  const {
    allAttachments,
    attachmentsAdd,
    attachmentsRemove,
    resetEditedAttachments,
    handleDropAttachment,
    handleAddAttachment,
    handleRemoveAttachment,
    handleAttachmentClick,
  } = useAttachmentHandlers(
    entry.attachments,
    setImageViewerInitialImage,
    setImageViewerAttachments
  )

  const isFormValid = useMemo(
    () => editValid || (!!editForm.name && !!allAttachments.length),
    [allAttachments.length, editValid, editForm.name]
  )
  /**
   * Convenience function to check if the current Quill and Attachments state is valid.
   * @param translation - A valid translation string
   */
  function handlequillValidOrHasAttachments(translation: string) {
    const delta = quillEditorRef.current?.quill?.getContents()
    return quillValidOrHasAttachments(delta, allAttachments)
      ? null
      : translation
  }

  const mutations = useHandbookEntryMutations(
    entrySlug,
    folderSlug,
    editForm,
    updateForm,
    updateInitialValues,
    setEditing,
    resetEditedAttachments
  )

  const form =
    !entry || entry.form?.archived || !entry.form?.formFields.edges.length
      ? null
      : entry.form

  function handleCancel() {
    setEditing(false)
    resetForm()
  }
  async function handleSave(values: EditHandbookEntryForm) {
    if (!entry.id) return

    const plaintext = quillEditorRef.current?.quill?.getText()

    await mutations.patch({
      variables: {
        id: entry.id,
        input: {
          ...values,
          attachmentsAdd: attachmentsAdd.map(attachment => ({
            name: attachment.name,
            file: attachment.file,
          })),
          attachmentsRemove,
          plaintext,
        },
      },
    })

    quillRendererRef.current?.quill?.setContents(
      new Delta(safeParseJson<Op[]>(values.richtext, []))
    )
  }
  async function handleDelete() {
    const { data: answer } = await confirm(
      translations.prompt.deleteEntry,
      translations.prompt.deleteEntryTitle
    )
    if (!answer) return

    mutations.delete({
      variables: { id: entry.id },
    })
  }

  function handleInitQuillEditor(quill: Quill) {
    const delta = new Delta(safeParseJson<Op[]>(editForm.richtext, []))
    quill.setContents(delta)
  }

  const menuItems: TableMenuItem<never>[] = [
    {
      label: translations.edit,
      hide: !hasPermissions(PERMISSIONS.handbook.change.manualentry, true),
      onClick: () => setEditing(true),
    },
    {
      label: translations.delete,
      hide: !hasPermissions(PERMISSIONS.handbook.delete.manualentry, true),
      onClick: handleDelete,
    },
  ]

  const isLoading = queryLoading || mutations.loading
  return (
    <Wrapper>
      <ImageViewer
        images={imageViewerAttachments}
        zIndex={ZIndexRange.Extra}
        initialImage={imageViewerInitialImage}
        popup
        open={imageViewerAttachments.length > 0}
        noShadow
        showArrows
        background="black !important"
        imageSize="contain"
        imageBackgroundColor="black !important"
        onClose={() => setImageViewerAttachments([])}
      />

      <header>
        {editing ? (
          <TitleInput
            value={editForm.name}
            error={editErrors.name}
            autoFocus
            width={isMobileOnly ? '100%' : '450px'}
            fontWeight={600}
            onChange={handler('name')}
          />
        ) : (
          <h2>{entry.name}</h2>
        )}
        <p>{translations.published}: {format(new Date(entry.createdAt), 'PPP')}</p>
        <p>{translations.lastRevision}: {format(new Date(entry.updatedAt), 'PPP')}</p>
      </header>

      {!!form && !editing && <FormForm form={form} collapsible />}
      {editing && (
        <SelectHandbookForm value={editForm.form} onChange={handler('form')} />
      )}

      <ContentWrapper>
        <Description>
          {editing ? (
            <DescriptionInput
              value={editForm.description}
              onChange={handler('description')}
            />
          ) : (
            <p>{entry.description}</p>
          )}

          {!!menuItems.length && (
            <>
              {editing ? (
                <EditButtons>
                  <Button background="gray8" onClick={handleCancel}>
                    {isMobile ? <Icon icon="times" /> : translations.cancel}
                  </Button>
                  <Button disabled={!isFormValid} onClick={submit(handleSave)}>
                    {isMobile ? <Icon icon="save" /> : translations.save}
                  </Button>
                </EditButtons>
              ) : (
                <PopupMenu items={menuItems} loading={isLoading} />
              )}
            </>
          )}
        </Description>

        {((entry.richtext && entry.richtext !== '{}') || editing) && (
          <Richtext
            editing={editing}
            onClick={() => editing && quillEditorRef.current?.quill?.focus()}
          >
            {editing ? (
              <EntryQuillEditor
                ref={quillEditorRef}
                onInit={handleInitQuillEditor}
                onChange={handler('richtext')}
              />
            ) : (
              <QuillRenderer
                ref={quillRendererRef}
                contents={safeParseJson(entry.richtext)}
              />
            )}
          </Richtext>
        )}

        {(allAttachments.length > 0 || editing) && (
          <DropArea
            disabled={!editing || isMobile}
            dragStyle={{
              backgroundColor: theme.colors.primary,
            }}
            onDrop={handleDropAttachment}
          >
            <HandbookEntryAttachmentsContainer>
              {allAttachments.map(attachment => (
                <PostAttachment
                  key={attachment.id}
                  attachment={attachment}
                  deletable={editing}
                  height="100px"
                  onDelete={handleRemoveAttachment}
                  onClick={handleAttachmentClick}
                />
              ))}

              {editing && (
                <AddAttachment
                  height="100px"
                  onClick={() => fileInputRef.current?.click()}
                >
                  <Icon icon="plus" size="1.6rem" color="gray7" />
                  <input
                    hidden
                    ref={fileInputRef}
                    type="file"
                    multiple
                    onChange={handleAddAttachment}
                  />
                </AddAttachment>
              )}
            </HandbookEntryAttachmentsContainer>
          </DropArea>
        )}
      </ContentWrapper>
    </Wrapper>
  )
}
