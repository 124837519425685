import { gql } from '@apollo/client'
import { FormCreatorFragments } from 'modules/formcreator/fragments'

const manualFolderInfo = gql`
  fragment ManualFolderInfo on ManualFolderNode {
    id
    name
    order
    slug
    description
    createdAt
    updatedAt
    slugHierarchy
  }
`

const manualFolderParent = gql`
  fragment ManualFolderParent on ManualFolderNode {
    parentFolder {
      id
      name
      slug
      description
      createdAt
      updatedAt
    }
  }
`

const manualEntryInfo = gql`
  fragment ManualEntryInfo on ManualEntryNode {
    id
    name
    order
    slug
    description
    richtext
    createdAt
    updatedAt
    slugHierarchy
  }
`

const manualEntryParent = gql`
  fragment ManualEntryParent on ManualEntryNode {
    folder {
      id
      name
      slug
      description
      createdAt
      updatedAt
    }
  }
`

const manualFolderChildren = gql`
  fragment ManualFolderChildren on ManualFolderNode {
    childrenFolders {
      edges {
        node {
          ...ManualFolderInfo
        }
      }
    }
  }
  ${manualFolderInfo}
`

const manualFolderEntries = gql`
  fragment ManualFolderEntries on ManualFolderNode {
    entries {
      edges {
        node {
          ...ManualEntryInfo
        }
      }
    }
  }
  ${manualEntryInfo}
`

const manualEntryAttachments = gql`
  fragment ManualEntryAttachments on ManualEntryNode {
    attachments {
      id
      file
      name
    }
  }
`

const manualEntryForm = gql`
  fragment ManualEntryForm on ManualEntryNode {
    form {
      id
      name
      ingress
      archived
      ...FormTemplateFields
    }
  }
  ${FormCreatorFragments.formTemplateFields}
`

export const HandbookFragments = {
  manualFolderInfo,
  manualFolderParent,
  manualEntryInfo,
  manualEntryParent,
  manualFolderChildren,
  manualFolderEntries,
  manualEntryAttachments,
  manualEntryForm,
}
