import { useToast } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Button, CountrySelect, MonthPicker } from 'components'
import React from 'react'
import { useMutation } from '@apollo/client'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import { countries, CountryCode } from 'util/countries'
import {
  CreateDriverCardMutation,
  CreateDriverCardMutationVariables,
  UserNode,
} from '..'
import { CREATE_DRIVER_CARD_MUTATION } from '../mutations'
import {
  convertCountryCodeToDriverCardCountry,
  driverCardCountries,
} from '../util'
import {
  Area,
  Input,
  InputArea,
  ProfileCard,
  ProfileCardProps,
} from './components'
import { addMonths, formatISO } from 'date-fns'

interface WrapperProps extends ProfileCardProps {
  requireDate: boolean
}

const Wrapper = styled(ProfileCard)<WrapperProps>`
  display: grid;
  ${props =>
    props.requireDate
      ? `
    grid-template-columns: 2fr 1fr 2fr;
    grid-template-areas:
      'header  header header'
      'number  country month'
      'submit  submit submit';
  `
      : `
    grid-template-columns: 2fr 1fr;
    grid-template-areas:
      'header  header'
      'number  country'
      'submit  submit';
  `}

  gap: 1.5rem;

  header {
    grid-area: header;
  }

  ${props => props.theme.media.mobile} {
    grid-template-columns: 1fr;
    grid-template-areas:
      'header'
      'number'
      'country'
      'month'
      'submit';
    gap: 1rem;
  }
`
const SubmitArea = styled(Area).attrs({
  area: 'submit',
})`
  display: flex;
  justify-content: flex-end;
`

interface AddDriverCardProps {
  user: Pick<UserNode, 'id'>
}

export const AddDriverCard: React.VFC<AddDriverCardProps> = ({ user }) => {
  const translations = useTranslate({
    addDriverCard: 'users.add-driver-card',
    driverCardNumber: 'users.driver-card-number',
    country: 'common.country',
    expiresAt: 'common.expires-at',

    submit: 'common.submit',
    submitSuccess: 'users.toasts.submit-driver-card-success',

    restOfWorld: 'countries.rest-of-world',
    europeanCommunity: 'countries.european-community',
    restOfEurope: 'countries.rest-of-europe',

    confirm: {
      title: 'users.delete-driver-card',
      message: 'users.delete-driver-card-message',
    },

    error: {
      driverNumberLength: 'error.drivercard-between-14-16',
      generic: 'errors.generic-error',
      invalidNumber: 'dashboard.enter-driver-card-number.errors.invalid-number',
      driverCardNotFoundInExternalIntegration: 'errors.driver-card-not-found-in-external-integration',
      similarCardTaken:
        'errors.driver-card-not-found-similar-driver-card-already-taken',
      cardAlreadyTaken: 'errors.driver-card-already-taken',
      companyMissingKey: 'error.company-missing-tachograph-key',
      cardIsExpired: 'error.driver-card-is-expired',
      cardExpirationDateNotFound: 'error.driver-card-expiration-date-not-found',
      expirationDateNotFoundAddExpirationDateManually:
        'error.expiration-date-not-found-add-expiration-date-manually',
    },
  })

  const addToast = useToast()

  const validateDriverCard = (value: string) => {
    if (value.slice(0,3) === 'NNO') {
      updateForm({ 
        number: value.slice(1),
        country: 'NO'
      })
    }
    return !value
      ? ''
      : value.length < 14 || value.length > 16
      ? translations.error.driverNumberLength
      : null
  }

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formChangeHandler: handler,
    updateForm,
    submitHandler,
  } = useForm({
    values: {
      number: '',
      country: 'NO' as CountryCode,
      expiresAt: addMonths(new Date(), 1),
      requireManualInput: false,
    },
    validators: {
      number: validateDriverCard,
    },
    config: {
      initAsInvalid: true,
      onlyValidateAfterFirstUpdate: false,
    },
  })

  const [createDriverCard] = useMutation<
    CreateDriverCardMutation,
    CreateDriverCardMutationVariables
  >(CREATE_DRIVER_CARD_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['Profile', 'Bootstrap'],
    onCompleted: () => {
      addToast('success', translations.submitSuccess)
      updateForm({ requireManualInput: false })
    },
    onError: error => {
      if (error.message.includes('Company does not have Tachograph API key')) {
        addToast('error', translations.error.companyMissingKey)
      } else if (
        error.message.includes(
          'Company has no active vehicle data integrations.'
        )
      ) {
        addToast('error', translations.error.companyMissingKey)
      } else if (error.message.includes('Driver card already taken.')) {
        addToast('error', translations.error.cardAlreadyTaken)
      } else if (error.message.includes('Invalid driver card number')) {
        addToast('error', translations.error.invalidNumber)
      } else if (error.message.includes('Driver card number not found in external integration')){
        addToast('error', translations.error.driverCardNotFoundInExternalIntegration)
      } else if (error.message.includes('Driver card is expired.')) {
        addToast('error', translations.error.cardIsExpired)
      } else if (
        error.message.includes('Driver card expiration date not found.')
      ) {
        addToast('error', translations.error.cardExpirationDateNotFound)
      } else if (error.message.includes('Add expiration date manually')) {
        addToast(
          'info',
          translations.error.expirationDateNotFoundAddExpirationDateManually
        )
        updateForm({ requireManualInput: true })
      } else {
        addToast('error', translations.error.generic)
      }
    },
  })

  function submit(values: typeof form) {
    const cardIssuingMemberState = convertCountryCodeToDriverCardCountry(
      values.country
    )
    if (cardIssuingMemberState === null) return

    createDriverCard({
      variables: {
        input: {
          user: user.id,
          driverIdentification: values.number,
          expiresAt: form.requireManualInput
            ? formatISO(form.expiresAt)
            : undefined,
          cardIssuingMemberState,
        },
      },
    })
  }

  function overrideCountryName(code: CountryCode, name: string) {
    if (code === 'UN') return translations.restOfWorld
    if (code === 'EU') return translations.europeanCommunity
    if (code === 'VU') return translations.restOfEurope
    return name
  }
  function overrideCountryFlag(code: CountryCode, flag: string) {
    if (code === 'VU') return countries.EU.emoji
    return flag
  }

  return (
    <Wrapper requireDate={form.requireManualInput}>
      <header>
        <h3>{translations.addDriverCard}</h3>
      </header>
      <InputArea area="country">
        <label>{translations.country}</label>
        <CountrySelect
          value={form.country}
          fullWidth
          overrideName={overrideCountryName}
          overrideFlag={overrideCountryFlag}
          include={Object.keys(driverCardCountries) as CountryCode[]}
          onChange={country => updateForm({ country: country! })}
        />
      </InputArea>

      <InputArea area="number">
        <label>{translations.driverCardNumber}</label>
        <Input
          value={form.number}
          error={errors.number}
          editing
          placeholder="XXXXXXXXXXXXXX"
          onChange={handler('number')}
        />
      </InputArea>

      {form.requireManualInput && (
        <InputArea area="month">
          <label>{translations.expiresAt}</label>
          <MonthPicker
            light={true}
            earliestYear={new Date().getFullYear()}
            disableAfter={false}
            value={form.expiresAt}
            onChange={handler('expiresAt')}
          />
        </InputArea>
      )}

      <SubmitArea>
        <Button
          disabled={!formValid}
          fullWidth={isMobile}
          onClick={submitHandler(submit)}
        >
          {translations.submit}
        </Button>
      </SubmitArea>
    </Wrapper>
  )
}
