import { useMutation, useQuery } from '@apollo/client'
import { useToast } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Button, FAIcon, SVGIcon } from 'components'
import { isPossibleNumber } from 'libphonenumber-js'
import difference from 'lodash/difference'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import styled, { css } from 'styled-components'
import { notBlankValidation } from 'util/forms'
import {
  useAdmin,
  useBreadcrumbs,
  useConfirm,
  useOnErrorAuto,
  usePermissions,
  useUser,
} from 'util/hooks'
import { emailIsValid } from 'util/parsing'
import { PERMISSIONS } from 'util/permissions'
import {
  PatchUserMutation,
  PatchUserMutationVariables,
  ProfileQuery,
  ProfileQueryVariables,
  UserNode,
  UserTypeNode,
} from '..'
import { DriverCard } from '../DriverCard'
import { PATCH_USER_MUTATION } from '../mutations'
import { PROFILE_QUERY } from '../queries'
import { ProfileEditForm } from '../types'
import { UserDocuments } from '../UserDocuments/UserDocuments'
import { AddDriverCard } from './AddDriverCard'
import { ContactPerson } from './ContactPerson'
import { MainInfo } from './MainInfo'
import { SalaryWorkTimesTables } from 'modules/companies/CompanySettings/components/SalarySettings/SalaryWorkTimesTables'
import { useSalaryModuleTranslations } from 'modules/companies/CompanySettings/hooks'
import { ProfileCard } from './components'
import { isMobileOnly } from 'react-device-detect'
import { SelectSalaryModule } from 'modules/companies/CompanySettings/components/SalarySettings/SelectSalaryModule'
import { parseISO } from 'date-fns'


const ExpiredCards = styled.div`
  grid-area: expired-cards;

  display: flex;
  flex-direction: column;
  gap: 1rem;
`

interface WrapperProps {
  editing: boolean
}
const Wrapper = styled.div<WrapperProps>`
  ${props => props.theme.layout.default};

  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-template-areas:
    'header           header'
    'main             main'
    'contact-and-card docs'
    'expired-cards    expired-cards'
    'salaryHeader salaryHeader'
    'salary salary';
  gap: 1.5rem;

  header {
    grid-area: header;

    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin: 0;
      color: ${props => props.theme.colors.gray4};

      span {
        color: ${props => props.theme.colors.text.dark};
      }
    }
    div {
      display: flex;

      button + button {
        margin-left: 1rem;
      }
    }
  }

  ${props => props.theme.media.custom({ max: '1620px' })} {
    display: flex;
    flex-direction: column;
    gap: 0;

    & > * + * {
      margin-top: 1.5rem;
    }

    ${props =>
      props.editing &&
      css`
        > header {
          justify-content: flex-end;

          h2 {
            display: none;
          }
        }
      `};
  }

  ${props => props.theme.media.mobile} {
    & > * + * {
      margin-top: 1rem;
    }
  }
`
const ContactAndCard = styled.div`
  grid-area: contact-and-card;
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 1.5rem;
  }

  ${props => props.theme.media.mobile} {
    & > * + * {
      margin-top: 1rem;
    }
  }
`
interface EditButtonProps {
  editing: boolean
}
const EditButton = styled(Button)<EditButtonProps>`
  padding: 0 1rem;

  color: ${props => !props.editing && props.theme.colors.gray3};
  background: ${props => !props.editing && 'inherit'};
  border: ${props => !props.editing && `1px solid ${props.theme.colors.gray7}`};

  ${props => props.theme.media.mobile} {
    height: 42px;
    padding: 0 0.5rem;
    font-size: 0.8rem;
  }
`

const SalaryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  grid-area: salaryHeader;
`

const SalaryBody = styled.div`
  grid-area: salary;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

const Create = styled.div`
  position: relative;
`

export const UserProfile: React.VFC = () => {
  const translations = useTranslate({
    salarySettings: 'settings.salary-and-work-time',
    loading: 'common.loading',
    myProfile: 'users.my-profile',
    profileOfUser: ['users.profile-of-user', { user: <span /> }],

    editProfile: 'users.edit-profile',
    cancel: 'common.cancel',
    saveChanges: 'common.save-changes',

    noChanges: 'common.no-changes',
    profileDidChange: 'users.profile-did-change',
    add: 'common.add',

    expiredCards: 'users.expired-cards',

    error: {
      phoneNumberTaken: 'users.errors.phone-number-taken',
      couldNotChange: 'error.could-not-change-profile-information',
      phoneNumberInvalid: 'error.invalid-phone-number',
      adressrequired: 'error.address-required',
      contactPersonRequired: 'error.contact-person-required',
      validEmailRequired: 'error.valid-email-required',
      needAtleastOneUserType: 'error.need-at-least-one-user-type',
      invalidPhoneNumber: 'error.invalid-phone-number',
    },
    confirm: {
      numberExists: ['users.number-already-exists', { name: '' }],
      deleteExistingNumber: 'users.delete-existing-number',
    },
  })
  const { userId } = useParams<{ userId: string }>()
  const { edit = false } = useLocation<{ edit?: boolean }>()?.state ?? false
  const me = useUser()
  const admin = useAdmin()
  const { hasPermissions } = usePermissions()
  const myProfile = me.id === userId
  const canEdit =
    (myProfile && hasPermissions(PERMISSIONS.users.change.user)) || admin
  const { setOverride, setOverrides, deleteOverrides } = useBreadcrumbs()

  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()
  const confirm = useConfirm()

  const [editing, setEditing] = useState(edit)
  const [initialLoad, setInitialLoad] = useState(false)
  const history = useHistory()
  const [selectSalaryModuleOpen, setSelectSalaryModuleOpen] = useState(false)
  const [originalUserTypes, setOriginalUserTypes] = useState<string[]>([])
  const validateEmail = (value: string) =>
    !!value.match(emailIsValid) ? null : translations.error.validEmailRequired
  const validateUserTypes = (ut: Pick<UserTypeNode, 'name' | 'id'>[]) =>
    ut.length > 0 ? null : translations.error.needAtleastOneUserType
  const validatePhone = (s: string) =>
    isPossibleNumber(s) ? null : translations.error.invalidPhoneNumber

  const validateString = (translation: string) => (s: string) =>
    notBlankValidation(s) ? null : translation

  const {
    formValues: editForm,
    formEdited,
    formValid,
    formErrors: errors,
    updateForm,
    updateForm: updateEditForm,
    updateInitialValues,
    submitHandler,
    resetForm,
  } = useForm<ProfileEditForm>({
    values: {
      phoneNumber: '',
      email: '',
      address: '',
      city: '',
      postalCode: '',
      userTypes: [],
      contactPersonName: '',
      contactPersonPhoneNumber: '',
      profilePicture: null,
      internalNumber: '',
      birthNumber: '',
    },
    validators: {
      email: validateEmail,
      address: validateString(translations.error.adressrequired),
      contactPersonName: validateString(
        translations.error.contactPersonRequired
      ),
      userTypes: validateUserTypes,
      phoneNumber: validatePhone,
      contactPersonPhoneNumber: validatePhone,
    },
  })

  const { data: profileData, loading: profileLoading } = useQuery<
    ProfileQuery,
    ProfileQueryVariables
  >(PROFILE_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { id: userId, withDocuments: false },
    onCompleted(data) {
      if (!data || formEdited) return
      const { user } = data

      const values: ProfileEditForm = {
        phoneNumber: user?.phoneNumber ?? '',
        email: user.email,
        address: user.address,
        city: user.city,
        postalCode: user.postalCode,
        userTypes: user.userTypes,
        contactPersonName: user.contactPersonName,
        contactPersonPhoneNumber: user.contactPersonPhoneNumber,
        profilePicture: user.profilePicture,
        internalNumber: user.internalNumber,
        birthNumber: user.birthNumber,
      }

      setOriginalUserTypes(user.userTypes.map(userType => userType.id))

      updateForm(values)
      updateInitialValues(values)

      setInitialLoad(true)
    },
    onError: onErrorAuto(),
  })

  const [patchUserMutation, { loading: patchLoading }] = useMutation<
    PatchUserMutation,
    PatchUserMutationVariables
  >(PATCH_USER_MUTATION, {
    refetchQueries: ['Profile'],
    onCompleted({ patchUserMutation: { user: userReturn } }) {
      if (userReturn.id !== userId && userReturn.phoneNumber !== '') {
        handlePhoneNumberTaken(userReturn)
      } else if (userReturn.phoneNumber !== '') {
        addToast('success', translations.profileDidChange)
        setEditing(false)
      } else if(!myProfile && admin) {
        addToast('success', translations.profileDidChange)
        setEditing(false)
      }
    },
    onError: onErrorAuto(
      {},
      {
        showToast: false,
        callback({ message }: { message: string }) {
          if (message.includes('The phone number is already taken'))
            addToast('warning', translations.error.phoneNumberTaken)
          else addToast('error', translations.error.couldNotChange)
          resetForm()
        },
      }
    ),
  })

  async function handlePhoneNumberTaken(
    userWithExistingNumber: Pick<UserNode, 'id' | 'fullName'>
  ) {
    const { data: answer } = await confirm(
      translations.confirm.deleteExistingNumber,
      translations.confirm.numberExists({
        name: userWithExistingNumber.fullName,
      })
    )
    if (!answer) return

    try {
      await patchUserMutation({
        variables: {
          id: userWithExistingNumber.id,
          input: {
            phoneNumber: '',
          },
        },
      })
    } finally {
      submit(editForm)
    }
  }

  function cancel() {
    resetForm()
    setEditing(false)
  }
  function submit(values: ProfileEditForm) {
    if (!profileData?.user) return

    if (!formEdited) {
      addToast('info', translations.noChanges)
      setEditing(false)
      return
    }

    const { userTypes, ...rest } = values

    const userTypeIds = userTypes.map(userType => userType.id)
    const userTypesRemove = difference(originalUserTypes, userTypeIds)
    const userTypesAdd = userTypeIds.reduce<string[]>((acc, cur) => {
      if (userTypesRemove.includes(cur) || originalUserTypes.includes(cur))
        return acc
      return [...acc, cur]
    }, [])

    patchUserMutation({
      variables: {
        id: profileData?.user.id,
        input: {
          isActive: true,
          ...rest,
          profilePicture:
            rest.profilePicture instanceof File
              ? rest.profilePicture
              : undefined,
          userTypesAdd,
          userTypesRemove,
        },
      },
    })
  }

  // TODO fix exhaustive-deps?
  // eslint-disable-next-line
  const setOverrideCallback = useCallback(setOverride, [])
  // eslint-disable-next-line
  const setOverridesCallback = useCallback(setOverrides, [])
  // eslint-disable-next-line
  const deleteOverridesCallback = useCallback(deleteOverrides, [])
  useEffect(() => {
    if (myProfile)
      setOverridesCallback({
        users: null,
        [userId]: translations.myProfile,
      })
    else if (!profileData?.user)
      setOverrideCallback(userId, translations.loading)
    else setOverrideCallback(profileData.user.id, profileData.user.fullName)

    return () => deleteOverridesCallback(userId, 'users')
  }, [
    profileData,
    myProfile,
    setOverrideCallback,
    setOverridesCallback,
    deleteOverridesCallback,
    translations.loading,
    translations.myProfile,
    userId,
  ])

  const user = useMemo(() => profileData?.user ?? null, [profileData])

  const getModuleTranslation = useSalaryModuleTranslations()

  const expiredCards = user?.driverCards.filter(driverCard => parseISO(driverCard.expiresAt) < new Date()) ?? []

  return (
    <Wrapper editing={editing}>
      <header>
        <h2>
          {myProfile ? (
            <span>{translations.myProfile}</span>
          ) : (
            translations.profileOfUser({
              user: <span>{user?.fullName ?? translations.loading}</span>,
            })
          )}
        </h2>

        {canEdit && (
          <div>
            {editing && (
              <EditButton editing={false} onClick={cancel}>
                {translations.cancel}
              </EditButton>
            )}
            <EditButton
              editing={editing}
              disabled={editing && !(formEdited && formValid) && (myProfile || !admin)}
              onClick={
                editing 
                  ? (!myProfile && admin) 
                    ? () => submit(editForm) 
                    : submitHandler(submit) 
                  : () => setEditing(v => !v)
              }
            >
              {!editing ? translations.editProfile : translations.saveChanges}
            </EditButton>
          </div>
        )}
      </header>

      <MainInfo
        user={user}
        loading={profileLoading || patchLoading}
        editing={editing}
        initialLoad={initialLoad}
        editForm={editForm}
        errors={errors}
        onUpdateForm={updateEditForm}
      />

      <ContactAndCard>
        <ContactPerson
          user={user}
          editing={editing}
          editForm={editForm}
          errors={errors}
          onUpdateForm={updateEditForm}
        />
        {user &&
          canEdit &&
          (!user.activeDriverCard ? (
            <AddDriverCard user={user} />
          ) : (
            <DriverCard area='driverCard' cardId={user.activeDriverCard.id} active/>
          ))}
      </ContactAndCard>

      {(myProfile || admin) && <UserDocuments userId={userId} />}
      {admin && (
      <>
        {expiredCards.length > 0 && (
          <ExpiredCards>
            <h2>{translations.expiredCards}</h2>
            {expiredCards.map(driverCard => (<DriverCard area='expired-cards' cardId={driverCard.id} active={false} key={driverCard.id} />))}
          </ExpiredCards>
        )}

        <SalaryHeader>
          <h2>{translations.salarySettings}</h2>
          {(user?.salaryWorkTimes.length ?? 0) < 3 && (
            <Create>
            {isMobileOnly ? (
              <Button
                height="32px"
                padding="0 1rem"
                onClick={() => setSelectSalaryModuleOpen(true)}
              >
                <FAIcon icon="plus" size="0.8rem" />
              </Button>
            ) : (
              <Button
                height="48px"
                onClick={() => setSelectSalaryModuleOpen(true)}
              >
                {translations.add}
              </Button>
            )}
            <SelectSalaryModule
              open={selectSalaryModuleOpen}
              exclude={user?.salaryWorkTimes.map(salaryWorkTime => salaryWorkTime.moduleConfig.name) ?? []}
              onClose={() => setSelectSalaryModuleOpen(false)}
              onSubmit={salaryModule => history.push(`/users/${userId}/salary/create`, {
                salaryModule: salaryModule,
              })}
            />
          </Create>
          )}
        </SalaryHeader>
        <SalaryBody>
          {user?.salaryWorkTimes.map((salaryWorkTime) => {
            const icon = salaryWorkTime.moduleConfig.name === "Activities" ? "truck" : salaryWorkTime.moduleConfig.name === "Terminal" ? "house" : "clock"
            const iconWidth = salaryWorkTime.moduleConfig.name === "Timesheets" ? "20px" : "28px"
            return (
              <ProfileCard key={salaryWorkTime.id}>
                <header>
                  <SVGIcon icon={icon} fill="black" width={iconWidth} />
                  <h3>{getModuleTranslation(salaryWorkTime.moduleConfig.name)}</h3>
                </header>
                <SalaryWorkTimesTables salaryWorkTimes={[salaryWorkTime] ?? []} />
              </ProfileCard>
            )
          })}
        </SalaryBody>
      </>
      )}
    </Wrapper>
  )
}
