import { MarkerProps } from '@react-google-maps/api'
import { GoogleMap } from 'components'
import isEqual from 'lodash/isEqual'
import { Coordinates } from 'types/graphql/maps'
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import styled, { useTheme } from 'styled-components'

const Wrapper = styled.div`
  grid-area: map;

  ${props => props.theme.media.custom({ max: '1500px' })} {
    height: 320px;
  }
`

interface MapProps {
  className?: string
  editing: boolean

  latitude: number | null
  longitude: number | null

  onClick: (
    results: google.maps.GeocoderResult[] | null,
    status: google.maps.GeocoderStatus
  ) => void
}

export const Map: React.VFC<MapProps> = ({
  className,
  editing,
  latitude,
  longitude,
  onClick,
}) => {
  const prevCoordinates = useRef<Coordinates | null>(null)

  const theme = useTheme()

  const [mapLoaded, setMapLoaded] = useState(false)
  const [center, setCenter] = useState({
    lat: latitude ?? 63.7196028,
    lng: longitude ?? 11.2339202,
  })

  useLayoutEffect(() => {
    if (latitude === null || longitude === null) return

    const coordinates = {
      lat: latitude,
      lng: longitude,
    }

    if (isEqual(prevCoordinates.current, coordinates)) return
    prevCoordinates.current = coordinates

    setCenter(coordinates)
  }, [latitude, longitude])

  const markers = useMemo<MarkerProps[]>(() => {
    if (
      !mapLoaded ||
      latitude === null ||
      longitude === null ||
      typeof google === 'undefined'
    )
      return []

    return [
      {
        position: new google.maps.LatLng(latitude, longitude),
      },
    ]
  }, [latitude, longitude, mapLoaded])

  function handleClick(event: google.maps.MapMouseEvent | null) {
    if (!editing || event === null || event.latLng === null) return

    const [lat, lng] = [event.latLng.lat(), event.latLng.lng()]
    const service = new google.maps.Geocoder()
    service.geocode(
      {
        location: new google.maps.LatLng(lat, lng),
      },
      onClick
    )
  }

  return (
    <Wrapper className={className}>
      <GoogleMap
        center={center}
        options={{
          streetViewControl: !editing,
          fullscreenControl: !editing,
          rotateControl: false,
          tilt: 0,
        }}
        mapContainerStyle={{
          height: '100%',
          borderTopRightRadius: theme.sizes.defaultBorderRadius,
          ...(isMobile
            ? { borderTopLeftRadius: theme.sizes.defaultBorderRadius }
            : {}),
        }}
        markers={markers}
        onLoad={() => setMapLoaded(true)}
        onClick={handleClick}
      />
    </Wrapper>
  )
}
