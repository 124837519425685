import { useInfiniteScroll } from 'util/hooks'
import React from 'react'

export const InfiniteScroller: React.FC<{
  children: React.ReactNode
  active: boolean
  handleScrollBottom?: () => Promise<void>
}> = ({ children, active = false, handleScrollBottom = async () => {} }) => {
  useInfiniteScroll(handleScrollBottom, 300, active)
  return <>{children}</>
}
