import React, { useState } from 'react'
import styled from 'styled-components'
import { ZIndexRange } from 'types/style'

const Wrapper = styled.div`
  position: absolute;
  ${props => props.theme.layout.mobile} {
    left: calc(50% - 68px);
    top: 210px;
  }
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 3px;
  margin-top: 24px;
  padding: 10px;
  z-index: ${ZIndexRange.Overlays};
`

const PickerRow = styled.div`
  padding-top: 2px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  .hover {
    background-color: ${props => props.theme.colors.lightPrimary};
  }
`

const PickerCell = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${props => props.theme.colors.gray9};

  :not(:first-child) {
    margin-left: 4px;
  }
`

export const QuillTablePicker: React.FC<{
  id: string
  onCreateTable: () => void
  onSelectTable: (width: number, height: number) => void
}> = ({ onCreateTable, onSelectTable, id }) => {
  /* This state styles the table picker cells on hover */
  const [styleState, setStyleState] = useState<number[]>([0, 0])

  const table = () => {
    const handleOnClick = (rowNumber: number, columnNumber: number) => {
      onSelectTable(rowNumber, columnNumber)
      setStyleState([0, 0])
      onCreateTable()
    }

    const makeTable = (width: number, height: number) => {
      const emptyTable = new Array(height).fill(0)
      const table = emptyTable.map((_, rowIndex) => {
        return makeRow(rowIndex, width)
      })
      return table
    }

    const makeRow = (rowIndex: number, width: number) => {
      const emptyRow = new Array(width).fill(0)
      const row = emptyRow.map((_, columnIndex) => {
        return makeCell(rowIndex, columnIndex)
      })
      return <PickerRow key={'row-' + rowIndex.toString()}>{row}</PickerRow>
    }

    const makeCell = (rowIndex: number, columnIndex: number) => {
      const className =
        styleState.length > 1
          ? rowIndex <= styleState[0] && columnIndex <= styleState[1]
            ? 'hover'
            : 'dummy'
          : 'dummy'
      return (
        <PickerCell
          className={className}
          key={
            'row-' +
            (rowIndex + 1).toString() +
            '-col-' +
            (columnIndex + 1).toString()
          }
          onMouseOver={() => setStyleState([rowIndex, columnIndex])}
          onClick={e => handleOnClick(rowIndex + 1, columnIndex + 1)}
        ></PickerCell>
      )
    }

    const table = makeTable(5, 5)
    return table
  }

  return <Wrapper id={id}>{table()}</Wrapper>
}
