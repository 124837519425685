import {
  PromptOptions,
  PromptProvider,
  ToastOptions,
  ToastProvider,
  UrThemeProvider,
} from '@ur/react-components'
import { SearchProvider } from 'context/SearchContext'
import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { isMobileOnly } from 'react-device-detect'
import Graph from 'store/graph'
import client from '../apollo'
import theme from '../theme'
import { Bootstrap } from './Bootstrap'
import { IntlContextProvider } from './providers/IntlContextProvider'
import { UseGlobalProvider } from '@ur/react-hooks'
import { initialStore } from 'store'

const toastOptions: ToastOptions = {
  color: 'text.dark',
  background: 'white',
  borderRadius: '12px',
  progressBarColor: '#00c7b1',
}
const promptOptions: PromptOptions = {
  modalProps: {
    placement: 'top-center',
    direction: 'from-top',
    offset: isMobileOnly ? 8 : 36,
  },
}

export const Root: React.VFC = () => {
  return (
    <ApolloProvider client={client}>
      <IntlContextProvider>
        <UseGlobalProvider store={initialStore}>
          <UrThemeProvider theme={theme}>
            <ToastProvider options={toastOptions}>
              <PromptProvider options={promptOptions}>
                <SearchProvider>
                  <Graph.Provider>
                    <Bootstrap />
                  </Graph.Provider>
                </SearchProvider>
              </PromptProvider>
            </ToastProvider>
          </UrThemeProvider>
        </UseGlobalProvider>
      </IntlContextProvider>
    </ApolloProvider>
  )
}

export { toastOptions as rootToastOptions, promptOptions as rootPromptOptions }
