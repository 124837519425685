import { gql } from '@apollo/client'
import { HandbookFragments } from './fragments'

export const CREATE_MANUAL_ENTRY_MUTATION = gql`
  mutation CreateManualEntry($input: CreateManualEntryInput!) {
    createManualEntry(input: $input) {
      manualEntry {
        id
        slug
        name

        folder {
          id
          slug
        }
      }
    }
  }
`

export const PATCH_MANUAL_ENTRY_MUTATION = gql`
  mutation PatchManualEntry($id: ID!, $input: PatchManualEntryInput!) {
    patchManualEntry(id: $id, input: $input) {
      manualEntry {
        id
      }
    }
  }
`

export const DELETE_MANUAL_ENTRY_MUTATION = gql`
  mutation deleteManualEntry($id: ID!) {
    deleteManualEntry(id: $id) {
      found
      deletedId
    }
  }
`

export const CREATE_MANUAL_FOLDER_MUTATION = gql`
  mutation CreateManualFolder($input: CreateManualFolderInput!) {
    createManualFolder(input: $input) {
      manualFolder {
        id
        slug

        name
        description
        order
        hasChildren
      }
    }
  }
`

export const PATCH_MANUAL_FOLDER_MUTATION = gql`
  mutation PatchManualFolder($id: ID!, $input: PatchManualFolderInput!) {
    patchManualFolder(id: $id, input: $input) {
      manualFolder {
        id
        name
        description
      }
    }
  }
`

export const UPDATE_MANUAL_ENTRY_MUTATION = gql`
  mutation UpdateManualEntry($id: ID!, $input: UpdateManualEntryInput!) {
    updateManualEntry(id: $id, input: $input) {
      manualEntry {
        ...ManualEntryInfo
        ...ManualEntryAttachments
        folder {
          id
          name
          slug
          description
          ...ManualFolderChildren
        }
      }
    }
  }
  ${HandbookFragments.manualEntryInfo}
  ${HandbookFragments.manualFolderChildren}
  ${HandbookFragments.manualEntryAttachments}
`

export const DELETE_MANUAL_ENTRY_ATTACHMENT = gql`
  mutation DeleteManualEntryAttachment($id: ID!) {
    deleteManualEntryAttachment(id: $id) {
      deletedId
      found
    }
  }
`

export const UPDATE_MANUAL_FOLDER_MUTATION = gql`
  mutation UpdateManualFolder($id: ID!, $input: UpdateManualFolderInput!) {
    updateManualFolder(id: $id, input: $input) {
      manualFolder {
        ...ManualFolderInfo
        parentFolder {
          id
          name
          slug
          description
          ...ManualFolderChildren
        }
      }
    }
  }
  ${HandbookFragments.manualFolderInfo}
  ${HandbookFragments.manualFolderChildren}
`

export const DELETE_MANUAL_FOLDER_MUTATION = gql`
  mutation DeleteManualFolder($id: ID!) {
    deleteManualFolder(id: $id) {
      found
      deletedId
    }
  }
`

export const UPDATE_MANUAL_FOLDER_ORDER_MUTATION = gql`
  mutation UpdateManualOrderFolder($id: ID!, $input: PatchManualFolderInput!) {
    patchManualFolder(id: $id, input: $input) {
      manualFolder {
        order
        slugHierarchy
      }
    }
  }
`

export const UPDATE_HANDBOOK_ORDER_MUTATION = gql`
  mutation UpdateHandbookOrder(
    $currentObject: HandbookObject!
    $siblingObject: HandbookObject!
    $up: Boolean!
  ) {
    updateHandbookOrder(
      currentObject: $currentObject
      siblingObject: $siblingObject
      up: $up
    ) {
      ok
    }
  }
`

export const SHIFT_HANDBOOK_ELEMENT_ORDER_MUTATION = gql`
  mutation ShiftHandbookElementOrder($id: ID!, $up: Boolean!) {
    shiftHandbookElementOrder(id: $id, up: $up) {
      ok
      currentElement {
        id
        order
      }
      siblingElement {
        id
        order
      }
    }
  }
`
