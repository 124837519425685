export const Dashboard: React.VFC<React.SVGAttributes<SVGSVGElement>> =
  props => (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 110 110.002"
      enableBackground="new 0 0 110 110.002"
      xmlSpace="preserve"
      fill="#003057"
      {...props}
    >
      <g>
        <path
          className="st0"
          d="M39.531,0H13.013C5.837,0,0,5.837,0,13.013v26.518c0,7.176,5.837,13.013,13.013,13.013h26.518
		c7.176,0,13.013-5.837,13.013-13.013V13.013C52.545,5.837,46.707,0,39.531,0z M44.196,39.531c0,2.572-2.093,4.665-4.665,4.665
		H13.013c-2.572,0-4.665-2.093-4.665-4.665V13.013c0-2.572,2.093-4.665,4.665-4.665h26.518c2.572,0,4.665,2.093,4.665,4.665V39.531z
		"
        />
        <path
          className="st0"
          d="M96.987,0H70.469c-7.174,0-13.013,5.837-13.013,13.013v26.518c0,7.176,5.839,13.013,13.013,13.013h26.518
		c7.174,0,13.013-5.837,13.013-13.013V13.013C110,5.837,104.161,0,96.987,0z M101.652,39.531c0,2.572-2.091,4.665-4.665,4.665
		H70.469c-2.574,0-4.665-2.093-4.665-4.665V13.013c0-2.572,2.091-4.665,4.665-4.665h26.518c2.574,0,4.665,2.093,4.665,4.665V39.531z
		"
        />
        <path
          className="st0"
          d="M39.531,57.457H13.013C5.837,57.457,0,63.296,0,70.471v26.518c0,7.174,5.837,13.013,13.013,13.013h26.518
		c7.176,0,13.013-5.839,13.013-13.013V70.471C52.545,63.296,46.707,57.457,39.531,57.457z M44.196,96.989
		c0,2.574-2.093,4.665-4.665,4.665H13.013c-2.572,0-4.665-2.091-4.665-4.665V70.471c0-2.574,2.093-4.665,4.665-4.665h26.518
		c2.572,0,4.665,2.091,4.665,4.665V96.989z"
        />
        <path
          className="st0"
          d="M96.987,57.457H70.469c-7.174,0-13.013,5.839-13.013,13.013v26.518c0,7.174,5.839,13.013,13.013,13.013h26.518
		c7.174,0,13.013-5.839,13.013-13.013V70.471C110,63.296,104.161,57.457,96.987,57.457z M101.652,96.989
		c0,2.574-2.091,4.665-4.665,4.665H70.469c-2.574,0-4.665-2.091-4.665-4.665V70.471c0-2.574,2.091-4.665,4.665-4.665h26.518
		c2.574,0,4.665,2.091,4.665,4.665V96.989z"
        />
      </g>
    </svg>
  )
