import { SelectOption } from '@ur/react-components'
import { useDebounce, useTranslate } from '@ur/react-hooks'
import React, { useCallback, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { DropdownFilter } from 'types/graphql/enums'
import { DEFAULT_PAGE_SIZE } from 'util/pagination'
import { Select, SelectProps as BaseSelectProps } from './Select'
import {
  ALL_TIME_ENTRY_PROJECTS_QUERY,
  AllTimeEntryProjectsQuery,
  TimeEntryProjectNode,
} from 'modules/timesheets'

interface TimeEntryProjectSelectProps
  extends Omit<
    BaseSelectProps<string, TimeEntryProjectNode>,
    'options' | 'onFetchMore'
  > {
  alwaysInclude?: TimeEntryProjectNode | null
  dropdownPageName?: DropdownFilter
}

export const TimeEntryProjectSelect: React.VFC<TimeEntryProjectSelectProps> = ({
  alwaysInclude = null,
  dropdownPageName,
  ...selectProps
}) => {
  const { placeholder } = useTranslate({
    placeholder: 'common.select-project',
  })

  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query)

  const { data, loading, fetchMore } = useQuery<AllTimeEntryProjectsQuery, {}>(
    ALL_TIME_ENTRY_PROJECTS_QUERY,
    {
      variables: {
        first: DEFAULT_PAGE_SIZE,
        q: debouncedQuery,
        orderBy: 'name',
      },
    }
  )

  const getLabel = useCallback((project: TimeEntryProjectNode) => {
    return <span className="name">{project.name}</span>
  }, [])

  const options = useMemo<SelectOption<string, TimeEntryProjectNode>[]>(() => {
    if (!data) return []

    const options = data.allTimeEntryProjects.edges.map(
      ({ node: project }) => ({
        value: project.id,
        label: getLabel(project),
        extra: project,
      })
    )

    if (alwaysInclude === null) return options

    return [
      {
        value: alwaysInclude.id,
        label: getLabel(alwaysInclude),
        extra: alwaysInclude,
      },
      ...options.filter(option => option.extra.id !== alwaysInclude.id),
    ]
  }, [alwaysInclude, data, getLabel])

  /**
   * Whenever the user select gets close to the bottom, refetch.
   * @remarks Almost a complete copy of `handleScrollBottom` in `UsersTable.tsx`. Might want to refactor to a hook?
   */
  const handleFetchMore = useCallback(async () => {
    if (typeof data === 'undefined') return
    if (loading || data.allTimeEntryProjects.pageInfo?.endCursor === null)
      return
    try {
      await fetchMore({
        variables: {
          first: DEFAULT_PAGE_SIZE,
          q: debouncedQuery,
          orderBy: 'name',
          after: data.allTimeEntryProjects.pageInfo?.endCursor,
        },
      })
    } catch (e) {
      //@ts-ignore
      if (e.name === 'Invariant Violation') return
      throw e
    }
  }, [data, fetchMore, debouncedQuery, loading])

  return (
    <Select
      options={options}
      loading={loading}
      placeholder={placeholder}
      searchable="custom"
      filterBackground="white"
      optionHoverBackground="white"
      onFilterChange={setQuery}
      onFetchMore={handleFetchMore}
      {...selectProps}
    />
  )
}
