import {
  IconProps,
  Input as BaseInput,
  InputProps as BaseInputProps,
} from '@ur/react-components'
import { CSSCursor } from '@ur/react-components/build/types/css'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'

interface StyledInputProps {
  hasLeftIcon: boolean
  hasRightIcon: boolean
  noBorders: boolean
  fontWeight?: string | number
  padding?: string
}
const StyledInput = styled(BaseInput)<StyledInputProps>`
  input {
    padding: ${props => props.padding ?? '0 1em'};
    font-size: ${props => props.fontSize ?? '1.2rem'};
    font-weight: ${props => props.fontWeight};
    border-color: ${props => props.theme.colors.gray9};

    ${props => props.hasLeftIcon && 'border-left: 0; padding-left: 0;'}
    ${props => props.hasRightIcon && 'border-right: 0;'}

    &::placeholder {
      color: ${props => props.theme.colors.gray5};
    }
  }
  .--input-icon-left,
  .--input-icon-right {
    font-size: 1.2em;
    padding: 0 1.4rem 0 2rem;
    border-color: ${props => props.theme.colors.gray9};

    transition: border-color 0.1s linear, color 0.1s linear;
    color: ${props => props.theme.colors.gray5};
  }

  .--input-autocomplete-item {
    padding: 14px 16px;
    border-bottom: 1px solid #eee;
    font-size: 14px;
  }

  &:focus-within {
    .--input-icon-left,
    .--input-icon-right {
      border-color: ${props => props.theme.colors.secondary};
      color: ${props => props.theme.colors.secondary};
    }
  }

  ${props =>
    props.noBorders &&
    css`
      input {
        border: 0 !important;
      }
      .--input-icon-left,
      .--input-icon-right {
        border: 0 !important;
      }
      &:focus-within {
        .--input-icon-left,
        .--input-icon-right {
          border: 0 !important;
        }
      }
    `}
`

interface InputProps extends Omit<BaseInputProps, 'type'> {
  type?: 'text' | 'password' | 'search'
  noBorders?: boolean
  grayed?: boolean
  fontWeight?: string | number
  padding?: string

  onEnter?: (evt: React.KeyboardEvent<HTMLInputElement>) => void
  onEscape?: (evt: React.KeyboardEvent<HTMLInputElement>) => void
}

export interface ExtendedIconProps extends IconProps {
  boxBackground?: string
  boxHoverBackground?: string
  boxHoverIconColor?: string
  boxCursor?: CSSCursor
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type,
      noBorders = false,
      grayed = false,
      fontWeight,
      padding,
      onEnter,
      onEscape,
      ...props
    },
    ref
  ) => {
    const searchIcon: typeof props.iconLeftProps = useMemo(
      () =>
        type !== 'search'
          ? undefined
          : {
              icon: 'search',
            },
      [type]
    )

    function handleKeyDown(evt: React.KeyboardEvent<HTMLInputElement>) {
      props.onKeyDown?.(evt)
      if (evt.defaultPrevented) return

      const { key } = evt
      switch (key) {
        case 'Enter':
          onEnter?.(evt)
          return
        case 'Escape':
          onEscape?.(evt)
          return
      }
    }

    const parsedType = type === 'password' ? 'password' : 'text'
    const background = grayed ? 'quaternary' : props.background ?? 'white'

    return (
      <StyledInput
        ref={ref}
        height="3.875rem"
        background={background}
        focusBorderColor="secondary"
        iconLeftProps={searchIcon ?? props.iconLeftProps}
        {...props}
        type={parsedType}
        hasLeftIcon={
          typeof searchIcon !== 'undefined' ||
          typeof props.iconLeftProps !== 'undefined'
        }
        hasRightIcon={typeof props.iconRightProps !== 'undefined'}
        noBorders={noBorders}
        fontWeight={fontWeight}
        padding={padding}
        onKeyDown={handleKeyDown}
      />
    )
  }
)
