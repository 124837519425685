import { CalendarBlock } from './CalendarBlock'
import { EmptyBlock } from './components'
import { MainCalendarLogicSlots } from '../types'

interface CalendarMainItemsProps {
  items: MainCalendarLogicSlots
}

export const CalendarMainItems: React.VFC<CalendarMainItemsProps> = ({
  items,
}) => {
  return (
    <>
      {items?.map((block, j) => {
        if (block !== null) {
          return (
            <CalendarBlock
              key={'CalendarBlock-' + j}
              background={block.color}
              ending={!block.next}
              starting={!block.previous}
              onClick={block.onClick}
              text={block.text}
            />
          )
        } else {
          return <EmptyBlock key={'CalendarBlock-' + j} />
        }
      })}
    </>
  )
}
