import styled from 'styled-components'
import { useSalaryMutations } from '../../util'
import { useForm, useTranslate } from '@ur/react-hooks'
import {
  SalarySettingsForm,
  SalarySettingsFormView,
} from './SalarySettingsFormView'
import { Fragment, useEffect, useState } from 'react'
import { getFullMinutes } from 'util/time'
import { useSalaryModuleTranslations } from '../../hooks'
import { format, setSeconds } from 'date-fns'
import { SelectSalaryModule } from './SelectSalaryModule'
import { isMobileOnly } from 'react-device-detect'
import { Button, FAIcon } from 'components'
import { useBreadcrumbs, useModuleConfig } from 'util/hooks'
import { Module } from 'modules/companies/consts'
import { useHistory, useParams } from 'react-router'
import { USER_SHALLOW_QUERY } from 'modules/users/queries'
import { ShallowUserQuery } from 'modules/users'
import { useQuery } from '@apollo/client'
import { SalaryType } from 'modules/companies/types.graphql'

const Create = styled.div`
  position: relative;
`

const FlexHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const Wrapper = styled.div`
  ${props => props.theme.layout.default};
`

interface CreateSalaryWorkTimeProps {}

export const CreateSalaryWorkTime: React.UFC<CreateSalaryWorkTimeProps> =
  () => {
    const history = useHistory()
    const initialSalaryModule = (history.location.state as {salaryModule: Module})?.salaryModule
    const { userId } = useParams<{ userId?: string }>()
    const [selectSalaryModuleOpen, setSelectSalaryModuleOpen] = useState(false)
    const getModuleTranslation = useSalaryModuleTranslations()

    const [configName, setConfigName] = useState<Module | null>(initialSalaryModule ?? null)
    const { moduleConfig } = useModuleConfig(configName ?? Module.ACTIVITIES)
    const [initialValidation, setInitialValidation] = useState(false)
    const [breadcrumbsOverridden, setBreadcrumbsOverridden] = useState(false)
    const { setOverride, deleteOverrides } = useBreadcrumbs()

    const translations = useTranslate({
      createSalarySettings: ['settings.create-salary-settings', { module: '' }],

      selectModule: 'settings.select-module',

      validation: {
        driverPause: 'settings.validation.salary-driver-pause-length',
        invalid: 'common.invalid',
        required: 'common.required',
        priority: [
          'settings.validation.salary-priority-unique',
          { module: '' },
        ],
      },
    })

    const { data: userData } = useQuery<
      ShallowUserQuery,
      {
        id: string
      }
    >(USER_SHALLOW_QUERY, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: { id: userId ?? '' },
    })

    const mutations = useSalaryMutations()

    const validatePauseDuration = (val: Date) => {
      if (!moduleConfig) return null

      return moduleConfig.name !== Module.ACTIVITIES
        ? null
        : getFullMinutes(val) < 45
        ? translations.validation.driverPause
        : null
    }

    const secondsToDate = (seconds: number) =>
      setSeconds(new Date(1993, 0, 9), seconds)

    const form = useForm<SalarySettingsForm>({
      values: {
        userTypes: [],
        userId: userId ?? '',
        salaryType: SalaryType.HOUR,
        defaultSalaryCode: '',
        salaryDayCode: '',
        dayOvertimeSalaryCode: '',
        useWeekOvertime: true,
        weekOvertimeSalaryCode: '',
        dailyWorkLimit: 8,
        periodWorkLimit: 40,
        pauseDuration: secondsToDate(2700),
        pauseThreshold: secondsToDate(14400),
        countPauseInTotal: false,
        paidPauseThreshold: secondsToDate(0),
        salaryDateStart: 26,
        salaryDateEnd: 25,
        name: '',
        groupSalaryOnVehicle: false,
        usePeriodOvertime: false,
        periodAddons: [],
        overtimePeriods: [],
        useSalaryDayPayment: false,
        defaultDepartment: '',
        availableDepartments: [],
      },
      validators: {
        pauseDuration: validatePauseDuration,
        salaryDateStart: (val, { salaryDateEnd }) =>
          val === 1 && salaryDateEnd === 31
            ? null
            : val > salaryDateEnd
            ? null
            : translations.validation.invalid,
      },
      config: {
        validateImmediately: true,
        initAsInvalid: true,
      },
    })

    async function handleCreate() {
      if (moduleConfig === null) return
      const { formValues: data } = form
      mutations.create({
        variables: {
          input: {
            moduleConfig: moduleConfig.id,
            user: userId ?? '',
            userTypes: data.userTypes,
            dailyWorkLimit: data.dailyWorkLimit,
            periodWorkLimit: data.periodWorkLimit,
            pauseDuration: getFullMinutes(data.pauseDuration),
            pauseThreshold: getFullMinutes(data.pauseThreshold),
            countPauseInTotal: data.countPauseInTotal,
            paidPauseThreshold: getFullMinutes(data.paidPauseThreshold),
            salaryDateStart: data.salaryDateStart,
            salaryDateEnd: data.salaryDateEnd,
            name: data.name,
            groupSalaryOnVehicle: data.groupSalaryOnVehicle,
            usePeriodOvertime: data.usePeriodOvertime,
            useSalaryDayPayment: data.useSalaryDayPayment,
            salaryType: data.salaryType,
            defaultSalaryCode: data.defaultSalaryCode,
            salaryDayCode: data.salaryDayCode,
            dayOvertimeSalaryCode: data.dayOvertimeSalaryCode,
            useWeekOvertime: data.useWeekOvertime,
            weekOvertimeSalaryCode: data.weekOvertimeSalaryCode,
            periodAddonsManual: data.periodAddons.map(periodAddon => {
              const { id, salaryCodeName, ...periodAddonNodeInput } = periodAddon
              return {
                ...periodAddonNodeInput,
                periodStart: format(periodAddon.periodStart, 'HH:mm'),
                periodEnd: format(periodAddon.periodEnd, 'HH:mm'),
              }
            }),
            overtimePeriodsManual: data.overtimePeriods.map(overtimePeriod => {
              const { id, salaryCodeName, ...overtimePeriodNodeInput } = overtimePeriod
              return {
                ...overtimePeriodNodeInput,
                periodStart: format(overtimePeriod.periodStart, 'HH:mm'),
                periodEnd: format(overtimePeriod.periodEnd, 'HH:mm'),
              }
            }),
            defaultDepartment: data.defaultDepartment,
            availableDepartments: data.availableDepartments,
          },
        },
      })
    }

    async function updateSalaryModule(module: Module) {
      setConfigName(module)
      setSelectSalaryModuleOpen(false)
    }

    const title = moduleConfig
      ? translations.createSalarySettings({
          module: getModuleTranslation(moduleConfig.name),
        })
      : translations.createSalarySettings({ module: '' })
    
    useEffect(() => {
      if (moduleConfig === null || initialValidation) return
      form.validateForm()
      setInitialValidation(true)
    }, [form, initialValidation, moduleConfig])

    useEffect(() => {
      if (!breadcrumbsOverridden && userId && userData && userData.user) {
        setOverride(userData.user.id, userData.user.fullName)
        setBreadcrumbsOverridden(true)
      }
    }, [userData, userId, breadcrumbsOverridden, setOverride, deleteOverrides])
  

    const UserWrapper = userId ? Wrapper : Fragment

    return (
      <UserWrapper>
        <FlexHeader>
          <h2>{title}</h2>
          {!userId && (
            <Create>
              {isMobileOnly ? (
                <Button
                  height="32px"
                  padding="0 1rem"
                  onClick={() => setSelectSalaryModuleOpen(true)}
                >
                  <FAIcon icon="plus" size="0.8rem" />
                </Button>
              ) : (
                <Button
                  height="48px"
                  onClick={() => setSelectSalaryModuleOpen(true)}
                >
                  {translations.selectModule}
                </Button>
              )}
              <SelectSalaryModule
                open={selectSalaryModuleOpen}
                onClose={() => setSelectSalaryModuleOpen(false)}
                onSubmit={salaryModule => updateSalaryModule(salaryModule)}
              />
            </Create>
          )}
        </FlexHeader>
        {moduleConfig && (
          <SalarySettingsFormView
            form={form}
            config={moduleConfig}
            onSubmit={handleCreate}
          />
        )}
      </UserWrapper>
    )
  }
