import { useContext } from 'react'
import { useUserTypes } from './useUserTypes'
import UserContext from 'context/UserContext'
import { UserTypeNode } from 'modules/users'

export const useAdmin = () => {
  const user = useContext(UserContext)
  const { userTypes } = useUserTypes()
  return (
    user.isSuperuser ||
    (userTypes?.some((type: UserTypeNode) => type.isAdministrator) ?? false)
  )
}
