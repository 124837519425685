import { ComponentSwitch, Icon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import {
  DateRangePicker,
  MonthPicker,
  Select,
  Toggle,
  SelectMultipleOnSelectAll,
} from 'components'
import { SelectMultipleUsers } from 'components/Select/SelectMultipleUsers'
import { DropdownNames } from 'components/Select/types'
import { responsiveExportGridAreas } from 'modules/companies/util'
import { useExportOptions } from 'modules/exports/hooks'
import { useMassTerminalExportForm } from 'modules/terminal/hooks'
import { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import { handleDateRangeChange } from 'util/date/handleDateRangeChange'
import { Field, Form, FormButton, Section } from '../common'
import { ExportCardProps } from '../types'
import { ExportSectionHeader, MassExportSectionHeader } from './components'

const SelectMultipleUsersWrapper = styled.div`
  position: relative;
  min-height: 54px;
`

export const ExportTerminalCard: React.VFC<ExportCardProps> = ({
  loading,
  onLoadingChangedCallback,
}) => {
  const translations = useTranslate({
    terminal: 'common.terminal',
    exportAllTerminalActivities: 'settings.export-all-terminal-entries',
    period: 'common.period',
    exportType: 'common.type',
    exportOption: 'common.export-type',

    export: 'common.export',
    orderByUserType: 'common.sort-by-user-type',
    selectUsers: 'common.select-users',
    selectAll: 'common.select-all',
  })

  const {
    loading: formLoading,
    form,
    errors,
    submit,
    updateForm,
    handleExport,
    handler,
    exportTypes,
  } = useMassTerminalExportForm(() => onLoadingChangedCallback(false))

  const exportOptions = useExportOptions(true)

  const selectAllOnChange: SelectMultipleOnSelectAll = (
    options,
    setSelectAll
  ) => {
    return (value: boolean) => {
      if (value) {
        updateForm({ users: options.map(option => option.value) as string[] })
        setSelectAll(true)
      } else {
        updateForm({ users: [] })
        setSelectAll(false)
      }
    }
  }
  useEffect(() => {
    if (!formLoading || loading === formLoading) return
    onLoadingChangedCallback(formLoading)
  }, [formLoading, loading, onLoadingChangedCallback])

  const formButtons: FormButton[] = [
    {
      text: (
        <>
          <Icon
            icon="download"
            type="light"
            color="primary"
            margin="0 0.5ch 0 0"
          />
          {translations.export}
        </>
      ),
      type: 'submit',
      buttonProps: {
        fullWidth: isMobile,
        disabled: loading,
      },
      onClick: submit(handleExport),
    },
  ]

  const areas = responsiveExportGridAreas(true)
  return (
    <>
      <ExportSectionHeader>{translations.terminal}</ExportSectionHeader>

      <Section>
        <MassExportSectionHeader>
          {translations.exportAllTerminalActivities}
          <Toggle
            value={form.useDateRange}
            thumbBackground="secondary"
            iconMode="svg"
            localOnIconProps={{
              icon: 'calendar-selection',
              fill: form.useDateRange ? 'white' : 'primary',
            }}
            localOffIconProps={{
              icon: 'calendar-month',
              fill: !form.useDateRange ? 'white' : 'primary',
            }}
            onChange={() => handler('useDateRange')(!form.useDateRange)}
          />
        </MassExportSectionHeader>

        <Form
          grid={{
            columns: !isMobile ? '3fr 2fr' : '1fr',
            areas: areas,
          }}
          buttons={formButtons}
        >
          <Field area="month">
            <label>{translations.period}</label>
            <ComponentSwitch>
              <ComponentSwitch.Case case={form.useDateRange}>
                <DateRangePicker
                  value={[form.dateStart, form.dateEnd]}
                  grayed={false}
                  onChange={handleDateRangeChange(updateForm)}
                />
              </ComponentSwitch.Case>
              <ComponentSwitch.Case default>
                <MonthPicker
                  value={form.month}
                  light
                  futureYears={0}
                  noYearCycle
                  onChange={handler('month')}
                />
              </ComponentSwitch.Case>
            </ComponentSwitch>
          </Field>

          <Field area="exportType">
            <label>{translations.exportType}</label>
            <Select
              value={form.exportType}
              options={exportTypes}
              fullWidth
              height="3.875rem"
              onChange={handler('exportType')}
            />
          </Field>
          <Field area="exportOption">
            <label>{translations.exportOption}</label>
            <Select
              value={form.exportOption}
              options={exportOptions}
              fullWidth
              height="3.875rem"
              onChange={handler('exportOption')}
            />
          </Field>
          <Field area="user">
            <label>{translations.selectUsers}</label>
            <SelectMultipleUsersWrapper>
              <SelectMultipleUsers
                selected={form.users ?? []}
                dropdownPageName={DropdownNames.TERMINAL}
                grayed={false}
                width="100%"
                minWidth={isMobile ? '0' : '260px'}
                onChange={handler('users')}
                onSelectAll={selectAllOnChange}
                withSelectAll={true}
                error={errors.users}
              />
            </SelectMultipleUsersWrapper>
          </Field>
        </Form>
      </Section>
    </>
  )
}
