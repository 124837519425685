export const beautifyNumberString = (s: string) => {
  const reverseStringArray = s.split('.')[0].split('').reverse()
  const reverseBeautifyArray = reverseStringArray.map((character, i) => {
    if (i % 3 === 0) {
      return character + ' '
    } else {
      return character
    }
  })
  return reverseBeautifyArray.reverse().join('')
}

export const toFixedAsString = (s: string, precision: number) => {
  const number = parseInt(s)
  return number.toFixed(precision).toString()
}

export function simplifyLocationString(locationString: string) {
  return locationString.split(', ')[0]
}
