import React, { ComponentType } from 'react'
import { useQuery } from '@apollo/client'
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
  useHistory,
} from 'react-router-dom'
import { IS_LOGGED_IN_QUERY } from 'modules/login/queries'
import { IsLoggedInQuery } from 'modules/login/types.graphql'

interface PrivateRouteProps extends RouteProps {
  component?: ComponentType<RouteComponentProps<any>>
  render?: (props: RouteComponentProps<any>) => React.ReactNode
  hasModuleAccess?: boolean
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  render,
  hasModuleAccess = true,
  ...rest
}) => {
  const history = useHistory()
  const { loading, data } = useQuery<IsLoggedInQuery>(IS_LOGGED_IN_QUERY, {
    onError: () => {
      console.error(
        'Error detecting whether or not user is logged in. Assuming not.'
      )
      history.push('/login')
    },
    pollInterval: 60000,
  })

  if (loading || !data) {
    return null
  }

  if (!hasModuleAccess) {
    return <Redirect to="/dashboard" />
  }

  const isLoggedIn = data.isLoggedIn

  let renderComponent: (props: RouteComponentProps<any>) => React.ReactNode
  if (typeof Component !== 'undefined') {
    renderComponent = props =>
      isLoggedIn ? <Component {...props} /> : <Redirect to="/login" />
  } else if (typeof render !== 'undefined') {
    renderComponent = props =>
      isLoggedIn ? render(props) : <Redirect to="/login" />
  } else {
    throw new Error('Either component or render must be passed.')
  }

  return <Route {...rest} render={renderComponent} />
}
