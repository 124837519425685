import { PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Input, RegularModal as BaseRegularModal } from 'components'
import { Label } from 'components/Label'
import { Field, Form } from 'modules/users/UserSettings/components'
import {
  CreateVehicleForm,
  CreateVehicleModalResolve,
} from 'modules/vehicles/types'
import styled from 'styled-components'

const RegularModal = styled(BaseRegularModal)`
  box-shadow: ${props => props.theme.shadow.default};
`

interface CreateVehicleModalProps {
  onSubmit: PromptResolve<CreateVehicleModalResolve | null>
}

export const CreateVehicleModal: React.VFC<CreateVehicleModalProps> = ({
  onSubmit,
}) => {
  const translations = useTranslate({
    title: 'vehicles.add-vehicle',
    submit: 'common.submit',
    vin: 'vehicles.vin',
    validation: { required: 'common.required' },
  })
  const {
    formValues: form,
    formValid,
    formChangeHandler: handler,
    submitHandler: submit,
  } = useForm<CreateVehicleForm>({
    values: {
      vin: '',
    },
    validators: {
      vin: val => (!!val ? null : translations.validation.required),
    },
    config: {
      initAsInvalid: true,
    },
  })
  return (
    <RegularModal
      title={translations.title}
      width="480px"
      cancelOnClickOutside
      submitDisabled={!formValid}
      submitText={translations.submit}
      onCancel={() => onSubmit(null)}
      onSubmit={submit(form => onSubmit({ form }))}
    >
      <Form>
        <Field>
          <Label>{translations.vin}</Label>
          <Input value={form.vin} onChange={handler('vin')}></Input>
        </Field>
      </Form>
    </RegularModal>
  )
}
