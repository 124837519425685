import { PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { RegularModal, Select } from 'components'
import { Field, Form } from 'modules/companies'
import { ExportOption } from 'modules/exports'
import { useExportOptions } from 'modules/exports/hooks'
import { ExportRoutePlanForm } from 'modules/routePlanner/types'
import React from 'react'

export const ExportRoutePlanModal: React.FC<{
  onSubmit: PromptResolve<ExportRoutePlanForm | null>
}> = ({ onSubmit }) => {
  const {
    formValues: form,
    updateForm,
    submitHandler: submit,
  } = useForm<ExportRoutePlanForm>({
    values: {
      exportOption: ExportOption.SAVE,
    },
  })

  const translations = useTranslate({
    export: 'common.export',
    exportOption: 'common.export-type',
    exportRoutePlan: 'route-planner.export-route-plan',
  })

  const exportOptions = useExportOptions()
  return (
    <RegularModal
      title={translations.exportRoutePlan}
      width="400px"
      submitText={translations.export}
      onCancel={() => onSubmit(null)}
      onSubmit={submit(onSubmit)}
    >
      <Form>
        <Field>
          <label>{translations.exportOption}</label>
          <Select
            value={form.exportOption}
            options={exportOptions}
            fullWidth
            height="3.875rem"
            onChange={value =>
              updateForm({
                exportOption: value ?? ExportOption.DOWNLOAD,
              })
            }
          />
        </Field>
      </Form>
    </RegularModal>
  )
}
