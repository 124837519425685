import { useMutation, useQuery } from '@apollo/client'
import { Icon, useToast } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import {
  SortableHeader,
  Table as BaseTable,
  TableMenu,
  TableMenuItem,
  Button,
} from 'components'
import React, { useCallback, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router'
import styled, { css, useTheme } from 'styled-components'
import { cleanRegexOperators } from 'util/forms'
import { useAdmin, useInfiniteScroll, useOnErrorAuto, usePermissions } from 'util/hooks'
import { DEFAULT_PAGE_SIZE } from 'util/pagination'
import { safeParsePhoneNumber } from 'util/parsing'
import {
  AllUsersQuery,
  AllUsersQueryVariables,
  SendNewUserRegistration,
  SendNewUserRegistrationVariables,
  ToggleUserActivatedMutation,
  ToggleUserActivatedMutationVariables,
  UserNode,
} from '..'
import { SEND_NEW_USER_REGISTRATION_MUTATION, TOGGLE_USER_ACTIVATED_MUTATION } from '../mutations'
import { USERS_QUERY } from '../queries'
import { UserProfileLink } from '../UserProfile/UserProfileLink'
import BaseUserThumbOrInitials from '../UserThumbOrInitials'
import { getUserLabel } from '../util'
import { ActivitiesStatus } from 'modules/activities/components/overview/ActivitiesStatusCell'
import { differenceInDays, format, parseISO } from 'date-fns'
import { PERMISSIONS } from 'util/permissions'

const Table = styled(props => <BaseTable {...props} />)`
  td.name {
    display: flex;
    align-items: center;
  }
`
const StatusWrapper = styled.span`
  display: flex;
  justify-content: space-between;
  padding-right: 1rem;
`

const StatusText = styled.span`
  display: flex;
  flex-direction: column;
`

const FetchStatusSpan = styled.span`
  display: flex;
`

interface UserThumbOrInitialsProps {
  strike: boolean
}
const UserThumbOrInitials = styled(
  BaseUserThumbOrInitials
)<UserThumbOrInitialsProps>`
  position: relative;
  margin-right: 12px;

  ${props =>
    props.strike &&
    css`
      &::after {
        content: '';
        box-sizing: content-box;
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 0;

        transform: translateY(-50%) rotate(135deg);
        width: 100%;
        height: 3px;

        border: 1px solid white;
        border-radius: 999px;
        background: #a50a0a;
        pointer-events: none;
      }
    `};
`

interface UsersTableProps {
  query?: string
  sort?: string
  showDeactivated?: boolean
  includeExpiredDocuments?: boolean
  documentCategorySelection?: string[]

  onSort?: (value: string) => void
}

export const UsersTable: React.VFC<UsersTableProps> = ({
  query = '',
  sort = 'lastName',
  showDeactivated = false,
  includeExpiredDocuments = false,
  documentCategorySelection = [],

  onSort = () => void 0,
}) => {
  const translations = useTranslate({
    name: 'common.name',
    status: 'common.status',
    data: 'common.data',
    phone: 'common.phone',
    email: 'common.email',
    role: 'common.role',
    active: 'common.active',
    deactivated: 'common.deactivated',
    awaitsConfiguration: 'users.awaits-configuration',
    missingExternalId: 'users.missing-external-id',
    externalId: 'common.external-id',
    sendNewInvitation: 'users.send-new-invitation',

    editUser: 'users.edit-user',
    activateUser: 'users.activate-user',
    deactivateUser: 'users.deactivate-user',

    userIsDeactivated: 'users.user-is-deactivated',
    noResults: 'common.no-results',

    toastUserActivated: ['users.toasts.user-activated', { name: '' }],
    toastUserDeactivated: ['users.toasts.user-deactivated', { name: '' }],
    errorGeneric: 'error.generic-server-error',

    newRegistrationLinkSend: 'users.new-registration-link-send',
  })


  const theme = useTheme()
  const history = useHistory()
  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()

  const [userIdLoading, setUserIdLoading] = useState<string | null>(null)

  const {
    data,
    loading: queryLoading,
    fetchMore,
  } = useQuery<AllUsersQuery, AllUsersQueryVariables>(USERS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      first: DEFAULT_PAGE_SIZE,
      q: cleanRegexOperators(query),
      orderBy: sort,
      isActive: showDeactivated ? undefined : true,
      includeInactive: true,
      includeExpiredDocuments: includeExpiredDocuments,
      onlyIncludeUserDocumentCategories: documentCategorySelection
    },
    onError: onErrorAuto(),
  })

  const [patch, { loading: patchLoading }] = useMutation<
    ToggleUserActivatedMutation,
    ToggleUserActivatedMutationVariables
  >(TOGGLE_USER_ACTIVATED_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['Users'],
    onCompleted(data) {
      if (typeof data === 'undefined') {
        addToast('error', translations.errorGeneric)
        return
      }

      const { user } = data.toggleUserActivated

      const message = user.isActive
        ? translations.toastUserActivated
        : translations.toastUserDeactivated
      addToast('success', message({ name: user.fullName }))
    },
    onError: onErrorAuto(translations.errorGeneric),
  })

  const [sendNewUserRegistration, { loading: newUserRegistrationLoading }] = useMutation<
    SendNewUserRegistration,
    SendNewUserRegistrationVariables
  >(SEND_NEW_USER_REGISTRATION_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['Users'],
    onCompleted(data) {
      if (typeof data === 'undefined') {
        addToast('error', translations.errorGeneric)
        return
      }

      addToast(
        'success',
        translations.newRegistrationLinkSend
      )
    },
    onError: onErrorAuto(translations.errorGeneric),
  })

  const users = data?.allUsers.edges.map(edge => edge.node) ?? []
  const hasNextPage = data?.allUsers.pageInfo.hasNextPage ?? false

  const admin = useAdmin()
  const { hasPermissions } = usePermissions()
  const showMenu = admin || hasPermissions(PERMISSIONS.users.change.user)
  const handleScrollBottom = useCallback(async () => {
    if (typeof data === 'undefined') return

    try {
      await fetchMore({
        variables: {
          first: DEFAULT_PAGE_SIZE,
          q: cleanRegexOperators(query),
          orderBy: sort,
          isActive: showDeactivated ? undefined : true,
          after: data.allUsers.pageInfo.endCursor,
        },
      })
    } catch (e) {
      //@ts-ignore
      if (e.name === 'Invariant Violation') return
      throw e
    }
  }, [data, fetchMore, query, showDeactivated, sort])

  useInfiniteScroll(handleScrollBottom, 200, !queryLoading && hasNextPage)

  function handleEditUser(user?: UserNode) {
    if (typeof user === 'undefined') return

    history.push({
      pathname: `/users/${user.id}`,
      state: { next: '/users', edit: true },
    })
  }
  async function handleDeactivateUser(user?: UserNode) {
    if (typeof user === 'undefined') return

    setUserIdLoading(user.id)
    await patch({
      variables: {
        userId: user.id,
        active: !user.isActive,
      },
    })
    setUserIdLoading(null)
  }

  function createMenuItems(user: UserNode) {
    const items: TableMenuItem<UserNode>[] = []

    items.push({
      label: translations.editUser,
      data: user,
      role: 'link',
      onClick: handleEditUser,
    })

    if (admin) {
      items.push({
        label: user.isActive
          ? translations.deactivateUser
          : translations.activateUser,
        data: user,
        onClick: handleDeactivateUser,
      })
    }

    return items
  }


  function handleResendInvitation(user: UserNode) {
    sendNewUserRegistration({
      variables: {
        userId: user.id,
      },
    })
  }

  function getStatusTextForUser(user: UserNode) {
    return user.hasRegistered
      ? user.isActive
        ? user.internalNumber
          ? translations.active
          : translations.missingExternalId
        : translations.deactivated
      : translations.awaitsConfiguration
  }

  return (
    <Table
      loading={queryLoading}
      loaderProps={{
        columns: !isMobile ? 5 : 1,
        rows: 10,
        expectedWidths: [258].concat(!isMobile ? [360, 120, 20] : []),
      }}
      noData={users.length === 0}
      noDataText={translations.noResults}
    >
      <thead>
        <tr>
          <SortableHeader baseValue="lastName" sortValue={sort} onSort={onSort}>
            {translations.name}
          </SortableHeader>

          {!isMobile && (
            <>
              <th>{translations.phone}</th>
              <th>{translations.email}</th>
              <th>{translations.role}</th>

              {admin && (
                <th>{translations.externalId}</th>
              )}
              <th>{translations.status}</th>
            </>
          )}
          {(admin && !isMobile) && <th>{translations.data}</th>}
          {(isMobile) && <th></th>}
          {(isMobile || showMenu) && <th></th>}
        </tr>
      </thead>

      <tbody>
        {users.map((user, userIndex) => {
          const phone = user.phoneNumber
            ? safeParsePhoneNumber(user.phoneNumber, 'international')
            : null
          const menuItems = createMenuItems(user)
          const deactivated = !user.isActive
          const loading = patchLoading && userIdLoading === user.id
          const statusLabel = admin && !user.hasRegistered && !user.hasLoginCode ? (
            <Button 
              fontSize='1rem' 
              padding='0.25rem!important' 
              height='auto' 
              disabled={newUserRegistrationLoading}
              onClick={() => handleResendInvitation(user)}
            >
              {translations.sendNewInvitation}
            </Button>
          ) 
          : getStatusTextForUser(user)
          return (
            <tr key={user.id}>
              <td className="name">
                <UserThumbOrInitials
                  user={user}
                  size={46}
                  fontWeight={600}
                  strike={deactivated}
                  title={
                    deactivated ? translations.userIsDeactivated : undefined
                  }
                />
                <UserProfileLink user={user} userNameTransform={getUserLabel} />
              </td>

              {!isMobile && (
                <>
                  <td>
                    {!phone ? (
                      '-'
                    ) : (
                      <a href={`tel:${user.phoneNumber}`}>{phone}</a>
                    )}
                  </td>

                  <td>
                    {!user.email ? (
                      '-'
                    ) : (
                      <a href={`mailto:${user.email}`}>{user.email}</a>
                    )}
                  </td>

                  <td>{user.position}</td>
                  {admin && !isMobile && (
                    <td>{user.internalNumber}</td>
                  )}
                  <td>
                    <StatusWrapper>
                      <StatusText>
                      {statusLabel}
                      </StatusText>
                      {user.activeDriverCard !== null && (
                        <Icon icon="id-card" type="solid" color="primary" />
                      )}
                    </StatusWrapper>
                  </td>
                  
                  {admin && !isMobile &&
                    <td>
                      {user.driverFetchInfo.map((driverFetchInfo) => {
                        if (!driverFetchInfo.lastFetch) return <></>
                        const fetchDate = parseISO(driverFetchInfo.lastFetch)
                        const fetchDifference = differenceInDays(new Date(), fetchDate)
                        const color = fetchDifference < 7 ? theme.colors.green : fetchDifference < 10 ? theme.colors.orange : theme.colors.red
                        const tooltipProps = userIndex === users.length - 1 ? { top: '-200%'} : undefined
                        const endpoint = driverFetchInfo.integrationEndpoint.toLowerCase().includes("volvo") ? "Volvo" 
                          : driverFetchInfo.integrationEndpoint.toLowerCase().includes("scania") ? "Scania" : "FleetBoard" 
                        return (
                          <FetchStatusSpan key={driverFetchInfo.id}>
                            <ActivitiesStatus dataStatusColor={color} tooltip={format(fetchDate, 'HH:mm dd.MM.yyyy')} tooltipProps={tooltipProps}/>
                            {endpoint}
                          </FetchStatusSpan>
                        )
                      })}
                    </td>
                  }

                  {showMenu && (
                    <TableMenu items={menuItems} loading={loading} />
                  )}
                </>
              )}

              {isMobile && (
                <>
                  <td width="1px">
                    {!!user.email && (
                      <a href={`mailto:${user.email}`}>
                        <Icon icon="envelope" type="solid" color="primary" />
                      </a>
                    )}
                  </td>
                  <td width="1px">
                    {!!phone && (
                      <a href={`tel:${user.phoneNumber}`}>
                        <Icon icon="phone" type="solid" color="primary" />
                      </a>
                    )}
                  </td>
                </>
              )}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}
