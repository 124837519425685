import { DriverCardNode, ResponsibleUserNode, ShallowUserNode, UserTypeNode } from 'modules/users/types.graphql'
import { DeleteMutation, RelayEdges } from 'types/graphql'
import { Integration, Module, ModuleOptionsValue, SalaryModule } from '.'
import { NotificationSettingsNames } from './CompanySettings/util/NotificationSettings'
import { Nullable } from 'types/util'

export interface ModuleConfigNode {
  id: string
  name: Module
  active: boolean
  salaryWorkTimes: SalaryWorkTimeNode[]
  responsibleUsers: ResponsibleUserNode[]
  hasDashboardIntegration: boolean
  moduleOptions?: ModuleOptionNode[]
}

export type ModuleOptions = keyof typeof ModuleOptionsValue

export interface ActivitiesReceiptOption extends ModuleOptionNode {
  name: ModuleOptionsValue.ACTIVITIES_RECEIPT_FUNCTIONALITY
}

export interface TimesheetOvertimeOption extends ModuleOptionNode {
  name: ModuleOptionsValue.TIMESHEETS_OVERTIME_OPTIONS
}

export interface TimesheetCountPauseOption extends ModuleOptionNode {
  name: ModuleOptionsValue.TIMESHEETS_COUNT_PAUSE_IN_TOTAL
}

export interface TimesheetsModuleConfig extends ModuleConfigNode {
  moduleOptions: [TimesheetOvertimeOption, TimesheetCountPauseOption] &
    ModuleOptionNode[]
}

export interface ActivitiesModuleConfig extends ModuleConfigNode {
  moduleOptions: [ActivitiesReceiptOption] & ModuleOptionNode[]
}

export interface ModuleOptionNode {
  id: string
  name: ModuleOptions
  active: boolean
}

export interface VolvoApiKeyNode {
  id: string
  username: string
  password: string
  active: boolean
}

export interface FleetBoardApiKeyNode {
  id: string
  fleet: string
  username: string
  password: string
  active: boolean
}

export interface ScaniaApiKeyNode {
  id: string
  tachographKey: string
  fmsAccessKey: string
  fmsSecretKey: string
  active: boolean
}

export interface ManApiKeyNode {
  id: string
  integrationId: string
  active: boolean
}

export interface NotificationSettingsNode {
  id: string
  name: NotificationSettingsNames
  sendSms: boolean
  sendEmail: boolean
  sendInApp: boolean
}

export interface CompanyNode {
  id: string
  shortName: string
  fullName: string
  country: string
  language: string
  logo: string | null
  useCompanyLogo: boolean
  numberOfUsers: number
  userTypes: UserTypeNode[]
  moduleConfigs: ModuleConfigNode[]

  notificationSettings: NotificationSettingsNode[]
  useOrdinaryHourCalculation: boolean
}

export interface HolidayAllowanceNode {
  id: string
  day: string
  salaryCode: SalaryCodeNode
}

export interface CompanyDriverCardsQuery {
  companyDriverCards: RelayEdges<DriverCardNode>
}

export interface AllHolidayAllowancesQuery {
  allHolidayAllowances: RelayEdges<HolidayAllowanceNode>
}

export interface CreateHolidayAllowanceMutation {
  createHolidayAllowance: {
    holidayAllowance: HolidayAllowanceNode
  }
}

export interface CreateHolidayAllowanceMutationVariables {
  input: {
    day: string
    salaryCode: string
  }
}

export interface PatchHolidayAllowanceMutation {
  patchHolidayAllowance: {
    holidayAllowance: HolidayAllowanceNode
  }
}

export interface PatchHolidayAllowanceMutationVariables {
  id: string
  input: {
    day: string
    salaryCode: string
  }
}

export interface DeleteHolidayAllowanceMutation {
  deleteHolidayAllowance: {
    found: boolean
    deletedId: string
  }
}

export interface DeleteHolidayAllowanceMutationVariables {
  id: string
}

export interface DepartmentNode {
  id: string
  externalId: string
  name: string
}

export interface AllDepartmentsQuery {
  allDepartments: RelayEdges<DepartmentNode>
}

export interface AllDepartmentsQueryVariables {
}

export interface CreateDepartmentMutation {
  createDepartment: {
    department: DepartmentNode
  }
}

export interface CreateDepartmentMutationVariables {
  input: {
    name: string
    externalId: string
  }
}

export interface PatchDepartmentMutation {
  patchDepartment: {
    department: DepartmentNode
  }
}

export interface PatchDepartmentMutationVariables {
  id: string
  input: {
    name: string
    externalId: string
  }
}

export interface DeleteDepartmentMutation {
  deleteDepartment: {
    found: boolean
    deletedId: string
  }
}

export interface DeleteDepartmentMutationVariables {
  id: string
}

export interface SalaryCodeNode {
  id: string
  externalId: string
  name: string
  isDiet: boolean
  showInManualTimeEntryOvertime: boolean
}

export interface AllSalaryCodesQuery {
  allSalaryCodes: RelayEdges<SalaryCodeNode>
}

export interface AllSalaryCodesQueryVariables {
  isDiet?: boolean
  showInManualTimeEntryOvertime?: boolean
}

export interface CreateSalaryCodeMutation {
  createSalaryCode: {
    salaryCode: SalaryCodeNode
  }
}

export interface CreateSalaryCodeMutationVariables {
  input: {
    name: string
    externalId: string
    isDiet: boolean
    showInManualTimeEntryOvertime: boolean
  }
}

export interface PatchSalaryCodeMutation {
  patchSalaryCode: {
    salaryCode: SalaryCodeNode
  }
}

export interface PatchSalaryCodeMutationVariables {
  id: string
  input: {
    name: string
    externalId: string
    isDiet: boolean
    showInManualTimeEntryOvertime: boolean
  }
}

export interface DeleteSalaryCodeMutation {
  deleteSalaryCode: {
    found: boolean
    deletedId: string
  }
}

export interface DeleteSalaryCodeMutationVariables {
  id: string
}

export interface PeriodAddonNode {
  __typename: 'PeriodAddonNode'
  id: string
  periodStart: string
  periodEnd: string

  salaryCode: SalaryCodeNode | null

  monday: boolean
  tuesday: boolean
  wednesday: boolean
  thursday: boolean
  friday: boolean
  saturday: boolean
  sunday: boolean
}

export interface OvertimePeriodNode {
  __typename: 'OvertimePeriodNode'
  id: string
  periodStart: string
  periodEnd: string

  salaryCode: SalaryCodeNode | null

  monday: boolean
  tuesday: boolean
  wednesday: boolean
  thursday: boolean
  friday: boolean
  saturday: boolean
  sunday: boolean
}

export enum SalaryType {
  HOUR = 'HOUR',
  DAY = 'DAY',
  FIXED = 'FIXED',
}

export interface SalaryWorkTimeNode {
  id: string
  priority: number
  company: CompanyNode
  moduleConfig: ModuleConfigNode

  dailyWorkLimit: number
  periodWorkLimit: number
  pauseDuration: number
  pauseThreshold: number
  countPauseInTotal: boolean
  paidPauseThreshold: number
  salaryDateStart: number
  salaryDateEnd: number
  name: string

  groupSalaryOnVehicle: boolean
  usePeriodOvertime: boolean
  useSalaryDayPayment: boolean

  periodAddons: PeriodAddonNode[]
  overtimePeriods: OvertimePeriodNode[]

  userTypes?: RelayEdges<UserTypeNode>
  user: ShallowUserNode | null

  salaryType: keyof typeof SalaryType
  defaultSalaryCode?: SalaryCodeNode | null
  salaryDayCode?: SalaryCodeNode | null
  dayOvertimeSalaryCode?: SalaryCodeNode | null
  useWeekOvertime: boolean
  weekOvertimeSalaryCode?: SalaryCodeNode | null

  defaultDepartment?: DepartmentNode | null
  availableDepartments?: DepartmentNode[]
}

export type SalaryModuleConfigNode = Pick<
  ModuleConfigNode,
  'id' | 'active' | 'name'
> & {
  salaryWorkTimes: SalaryWorkTimeNode[]
}

/* Inputs */
export interface UserTypePermissionInput {
  permission: string
  value: boolean | null
}

export interface UpdatedUserTypesInput {
  id: string
  permissions: UserTypePermissionInput[]
  name?: string
}

export interface UpdatedUserTypesDropdownInput {
  id: string
  dropdowns: {
    dropdown: string
    value: boolean | null
  }[]
}

export interface NotificationSettingInput {
  sendInApp?: boolean
  sendEmail?: boolean
  sendSms?: boolean
}

export interface BatchPatchNotificationSettingInput {
  id: string
  sendSms: Boolean
  sendEmail: Boolean
  sendInApp?: Boolean
}

export interface PatchCompanyNotificationSettingsInput {
  userRegistrationNotificationSettings?: Omit<
    NotificationSettingInput,
    'sendInApp'
  >
  userDocumentNotificationSettings?: NotificationSettingInput
  issueCreatedNotificationSettings?: NotificationSettingInput
  issueClosedNotificationSettings?: NotificationSettingInput
  forumThreadCreatedNotificationSettings?: NotificationSettingInput
  forumPostCreatedNotificationSetting?: NotificationSettingInput
}

/* Queries */
export interface CurrentCompanyQuery {
  currentCompany: CompanyNode
}

export interface AllApiKeysQuery {
  allVolvoApiKeys: RelayEdges<VolvoApiKeyNode>
  allScaniaApiKeys: RelayEdges<ScaniaApiKeyNode>
  allFleetboardApiKeys: RelayEdges<FleetBoardApiKeyNode>
  allManApiKeys: RelayEdges<ManApiKeyNode>
}

export interface SalaryModuleConfigQuery {
  allSalaryModuleConfigs: RelayEdges<SalaryModuleConfigNode>
}

export interface ModuleConfigQueryVariables {
  name: string
}

export interface ModuleConfigQuery {
  moduleConfig: ModuleConfigNode
}

export interface AllModuleConfigsQuery {
  allModuleConfigs: RelayEdges<Pick<ModuleConfigNode, 'id' | 'active' | 'name'>>
}

export interface VolvoApiKeyQuery {
  volvoApiKey: VolvoApiKeyNode
}

export interface ScaniaApiKeyQuery {
  scaniaApiKey: ScaniaApiKeyNode
}

export interface CurrentCompanyNotificationSettingsQuery {
  currentCompany: Pick<CompanyNode, 'id'> & {
    notificationSettings: RelayEdges<NotificationSettingsNode>
  }
}

/* Mutations */
export interface CreateUserTypeMutation {
  createUserType: {
    userType: Pick<UserTypeNode, 'id' | 'name'>
  }
}
export interface CreateUserTypeVariables {
  input: {
    name: string
    company?: string
    users?: string[]
    isAdministrator?: boolean
    permissions?: string[]
    color?: string
    shouldHaveDataFetchedFromApi?: boolean
    dropdowns?: string[]
  }
}

export interface PatchUserTypeMutation {
  patchUserType: {
    userType: Pick<UserTypeNode, 'id'>
  }
}
export interface PatchUserTypeMutationVariables {
  id: string
  input: {
    name?: string
    isAdministrator?: boolean
  }
}

export interface UpdateCompanyUserTypesMutation {
  updateCompanyUserTypes: {
    updatedUserTypes: Pick<UserTypeNode, 'id'>[]
  }
}
export interface UpdateCompanyUserTypesMutationVariables {
  updatedUserTypes: UpdatedUserTypesInput[]
}

export interface UpdateCompanyUserTypesDropdownsMutation {
  updateCompanyUserTypesDropdowns: {
    updatedUserTypes: Pick<UserTypeNode, 'id'>[]
  }
}
export interface UpdateCompanyUserTypesDropdownsMutationVariables {
  updatedUserTypes: UpdatedUserTypesDropdownInput[]
}

export interface DeleteUserTypeMutation {
  deleteUserType: DeleteMutation
}

export interface PatchVolvoApiKeyVariables {
  id: string
  input: {
    username?: string
    password?: string
    active?: boolean
  }
}

export interface PatchCompanyMutation {
  patchCompany: Pick<CompanyNode, 'id'>
}
export interface PatchCompanyMutationVariables {
  id: string
  input: Partial<
    Pick<
      CompanyNode,
      'fullName' | 'country' | 'language' | 'useCompanyLogo'
    > & {
      logo?: File
    }
  >
}

export interface CreateSalaryWorkTimeMutation {
  createSalaryWorkTime: SalaryWorkTimeNode
}

export interface DeleteSalaryWorkTimeMutation {
  deleteSalaryWorkTime: DeleteMutation
}

export interface PatchSalaryWorkTimeMutation {
  patchSalaryWorkTime: {
    salaryWorkTime: SalaryWorkTimeNode
  }
}

export interface SwapSalaryWorkTimesPriorityMutation {
  swapSalaryWorkTimesPriority: {
    ok: boolean
  }
}

interface CreateSalaryWorkTimeInput {
  moduleConfig: string
  userTypes: string[]
  user?: string
  salaryType: keyof typeof SalaryType
  defaultSalaryCode: string | null
  salaryDayCode: string | null
  dayOvertimeSalaryCode: string | null
  useWeekOvertime: boolean
  weekOvertimeSalaryCode: string | null
  dailyWorkLimit: number
  periodWorkLimit: number
  pauseDuration: number
  pauseThreshold: number
  countPauseInTotal: boolean
  paidPauseThreshold: number
  salaryDateStart: number
  salaryDateEnd: number
  name: string
  groupSalaryOnVehicle: boolean
  usePeriodOvertime: boolean
  useSalaryDayPayment: boolean

  periodAddonsManual: (Omit<PeriodAddonNode, 'id' | '__typename' | 'salaryCode'> & { salaryCode: string | null})[]
  overtimePeriodsManual: (Omit<OvertimePeriodNode, 'id' | '__typename' | 'salaryCode'> & { salaryCode: string | null})[]

  defaultDepartment: Nullable<string>
  availableDepartments?: string[]
}

export interface CreateSalaryWorkTimeMutationVariables {
  input: CreateSalaryWorkTimeInput
}

interface PatchSalaryWorkTimeInput {
  user?: string
  userTypes?: string[]
  salaryType?: keyof typeof SalaryType
  defaultSalaryCode?: string | null
  salaryDayCode?: string | null
  dayOvertimeSalaryCode?: string | null
  useWeekOvertime?: boolean
  weekOvertimeSalaryCode?: string | null
  dailyWorkLimit?: number
  periodWorkLimit?: number
  pauseDuration?: number
  pauseThreshold?: number
  countPauseInTotal?: boolean
  paidPauseThreshold?: number
  salaryDateStart?: number
  salaryDateEnd?: number
  name?: string
  groupSalaryOnVehicle?: boolean
  usePeriodOvertime?: boolean
  useSalaryDayPayment?: boolean

  periodAddonsManual?: (Omit<PeriodAddonNode, 'id' | '__typename' | 'salaryCode'> & { salaryCode: string | null })[]
  overtimePeriodsManual?: (Omit<OvertimePeriodNode, 'id' | '__typename' | 'salaryCode'> & { salaryCode: string | null })[]
  defaultDepartment: Nullable<string>
  availableDepartments?: string[]
}

export interface PatchSalaryWorkTimeMutationVariables {
  id: string
  input: PatchSalaryWorkTimeInput
}

export interface SwapSalaryWorkTimesPriorityMutationVariables {
  input: {
    first: string
    second: string
  }
}

export interface CreateVolvoApiKeyMutation {
  createVolvoApiKey: {
    volvoApiKey: Pick<VolvoApiKeyNode, 'id'>
  }
}
export interface CreateVolvoApiKeyMutationVariables {
  input: {
    username: string
    password: string
    active: boolean
  }
}

export interface ValidateVolvoApiKeyMutationVariables {
  input: {
    company: string
    username: string
    password: string
    active: boolean
  }
}

export interface CreateFleetBoardApiKeyMutation {
  createFleetBoardApiKey: {
    fleetBoardApiKey: Pick<FleetBoardApiKeyNode, 'id'>
  }
}
export interface CreateFleetBoardApiKeyMutationVariables {
  input: {
    fleet: string
    username: string
    password: string
    active: boolean
  }
}

export interface CreateScaniaApiKeyMutation {
  createScaniaApiKey: {
    scaniaApiKey: Pick<ScaniaApiKeyNode, 'id'>
  }
}
export interface CreateScaniaApiKeyMutationVariables {
  input: {
    tachographKey: string
    fmsAccessKey: string
    fmsSecretKey: string
    active: boolean
  }
}

export interface ValidateScaniaApiKeyMutationVariables {
  input: {
    company: string
    tachographKey: string
    fmsAccessKey: string
    fmsSecretKey: string
    active: boolean
  }
}

export interface CreateManApiKeyMutation {
  createManApiKey: {
    manApiKey: Pick<ManApiKeyNode, 'id'>
  }
}

export interface CreateManApiKeyMutationVariables {
  input: {
    integrationId: string
    active: boolean
  }
}

export interface PatchManApiKeyMutation {
  patchManApiKey: {
    manApiKey: Pick<ManApiKeyNode, 'id'>
  }
}

export interface PatchManApiKeyMutationVariables {
  id: string
  input: {
    active?: boolean
  }
}

export interface DeleteManApiKeyMutation {
  deleteManApiKey: DeleteMutation
}

export interface PatchVolvoApiKeyMutation {
  patchVolvoApiKey: {
    volvoApiKey: Pick<VolvoApiKeyNode, 'id'>
  }
}
export interface PatchVolvoApiKeyMutationVariables {
  id: string
  input: {
    username?: string
    password?: string
    active?: boolean
  }
}

export interface PatchFleetBoardApiKeyMutation {
  patchFleetBoardApiKey: {
    fleetBoardApiKey: Pick<FleetBoardApiKeyNode, 'id'>
  }
}
export interface PatchFleetBoardApiKeyMutationVariables {
  id: string
  input: {
    fleet?: string
    username?: string
    password?: string
    active?: boolean
  }
}

export interface PatchScaniaApiKeyMutation {
  patchScaniaApiKey: {
    scaniaApiKey: Pick<ScaniaApiKeyNode, 'id'>
  }
}
export interface PatchScaniaApiKeyMutationVariables {
  id: string
  input: {
    tachographKey?: string
    fmsAccessKey?: string
    fmsSecretKey?: string
    active?: boolean
  }
}

export interface DeleteVolvoApiKeyMutation {
  deleteVolvoApiKey: DeleteMutation
}

export interface DeleteFleetBoardApiKeyMutation {
  deleteFleetBoardApiKey: DeleteMutation
}

export interface DeleteScaniaApiKeyMutation {
  deleteScaniaApiKey: DeleteMutation
}

export interface ValidateScaniaApiKeyMutation {
  validateScaniaApiKey: {
    ok: boolean
    error: string
  }
}

export interface ValidateVolvoApiKeyMutation {
  validateVolvoApiKey: {
    ok: boolean
  }
}

export interface VolvoApiKeysFormData extends Omit<VolvoApiKeyNode, 'id'> {}

export interface ScaniaApiKeysFormData extends Omit<ScaniaApiKeyNode, 'id'> {}

export type IntegrationType = typeof Integration[keyof typeof Integration]

export type SalaryModuleType = typeof SalaryModule[keyof typeof SalaryModule]

export interface BatchPatchNotificationSettingsMutation {
  notificationSettings: NotificationSettingsNode
}
export interface BatchPatchNotificationSettingsMutationVariables {
  input: BatchPatchNotificationSettingInput[]
}

export interface PatchModuleConfigMutation {
  patchModuleConfig: {
    moduleConfig: Pick<ModuleConfigNode, 'id'>
  }
}

export interface PatchModuleConfigMutationVariables {
  id: string
  input: {
    responsibleUsers?: string[]
  }
}

export interface PatchModuleOptionMutation {
  patchModuleOption: {
    moduleOption: Pick<ModuleOptionNode, 'id'>
  }
}

export interface PatchModuleOptionMutationVariables {
  id: string
  input: {
    active?: boolean
  }
}

