import { gql } from '@apollo/client'
import { ChecklistFragments } from './fragments'

export const USER_CHECKLISTS = gql`
  query AllChecklists($user: ID) {
    allChecklists(user: $user, active: true) {
      edges {
        node {
          ...checklistInfo
        }
      }
    }
  }
  ${ChecklistFragments.checklistInfo}
`

export const CHECKLIST_TEMPLATES_QUERY = gql`
  query AllChecklistTemplates {
    allChecklistTemplates {
      edges {
        node {
          ...shallowChecklistTemplateInfo
        }
      }
    }
  }
  ${ChecklistFragments.shallowChecklistTemplateInfo}
`

export const CHECKLIST_TEMPLATE_QUERY = gql`
  query ChecklistTemplate($id: ID!) {
    checklistTemplate(id: $id) {
      ...checklistTemplateInfo
    }
  }
  ${ChecklistFragments.checklistTemplateInfo}
`

export const USER_TYPE_CHECKLIST_TEMPLATES_QUERY = gql`
  query UserTypeChecklistTemplates($userTypes: [UserTypeNode]!) {
    allChecklistTemplates(userTypes: $userTypes) {
      edges {
        node {
          ...checklistTemplateInfo
        }
      }
    }
  }
  ${ChecklistFragments.checklistTemplateInfo}
`
