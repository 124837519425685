import { gql } from '@apollo/client'
import { NotificationFragments } from './fragments'

export const MY_APP_NOTIFICATIONS_QUERY = gql`
  query MyAppNotificationsQuery($first: Int, $after: String) {
    myAppNotifications(first: $first, after: $after) {
      edges {
        node {
          ...AppNotificationInfo
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    me {
      id
      unseenNotificationCount
    }
  }
  ${NotificationFragments.appNotificationInfo}
`
