import { PromptResolve } from "@ur/react-components"
import { useForm, useTranslate } from "@ur/react-hooks"
import { RegularModal } from "components/Modal"
import { Select } from "components/Select"
import { SalaryCodeSelect } from "components/Select/SalaryCodeSelect"
import { Field } from "modules/companies"
import { ExportFormat } from "modules/exports"
import { useExportFormatOptions } from "modules/exports/hooks"
import styled from "styled-components"
import { notBlankValidation } from "util/forms"

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

export interface SelectSalaryCodeAndFormatForm {
    salaryCodeId: string | null
    exportFormat: ExportFormat | null
}

export interface SelectSalaryCodeAndFormatPromptProps {
    onSubmit: PromptResolve<SelectSalaryCodeAndFormatForm | null>
}

export const SelectSalaryCodeAndFormatPrompt: React.VFC<SelectSalaryCodeAndFormatPromptProps> = ({
    onSubmit,
    }) => {
    const translations = useTranslate({
        exportFormSubmissions: 'formcreator.export-form-submissions',
        export: 'common.export',
        required: 'common.required',
        salaryCode: 'common.salary-code',
        exportFormat: 'common.export-format'
    })

    const { formValues, formValid, formChangeHandler: handler } = useForm<SelectSalaryCodeAndFormatForm>({
        values: {
            salaryCodeId: null,
            exportFormat: ExportFormat.EXCEL
        },
        validators: {
            salaryCodeId: (value, others) => others.exportFormat === ExportFormat.EXCEL || notBlankValidation(value) ? null : translations.required,
            exportFormat: (value) => notBlankValidation(value) ? null : translations.required
        }
    })
    
    const exportFormats = useExportFormatOptions([
        ExportFormat.EXCEL_FLAT,
        ExportFormat.PDF
    ])
    
    return (
        <RegularModal
            title={translations.exportFormSubmissions}
            submitText={translations.export}
            width="400px"
            onCancel={() => onSubmit(null)}
            onSubmit={() => onSubmit(formValues)}
            submitDisabled={!formValid}
            >
                <Content>
                <Field>
                    <label>{translations.exportFormat}</label>
                    <Select value={formValues.exportFormat} options={exportFormats} onChange={handler('exportFormat')} />
                </Field>
                {formValues.exportFormat !== ExportFormat.EXCEL && (
                    <Field>
                        <label>{translations.salaryCode}</label>
                        <SalaryCodeSelect value={formValues.salaryCodeId} onChange={handler('salaryCodeId')} />
                    </Field>
                )}
                </Content>
        </RegularModal>
    )
    }