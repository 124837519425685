import { gql } from '@apollo/client'

export const CREATE_VEHICLE_MUTATION = gql`
  mutation CreateVehicle($input: CreateVehicleInput!) {
    createVehicle(input: $input) {
      vehicle {
        id
      }
    }
  }
`

export const CREATE_VEHICLE_BY_REGISTRATION_NUMBER_MUTATION = gql`
  mutation CreateVehicleByRegistrationNumber(
    $input: CreateVehicleByRegistrationNumberInput!
  ) {
    createVehicleByRegistrationNumber(input: $input) {
      vehicle {
        id
      }
    }
  }
`

export const DELETE_VEHICLE_MUTATION = gql`
  mutation DeleteVehicle($id: ID!) {
    deleteVehicle(id: $id) {
      deletedId
    }
  }
`

export const PATCH_VEHICLE_MUTATION = gql`
  mutation PatchVehicle($id: ID!, $input: PatchVehicleInput!) {
    patchVehicle(id: $id, input: $input) {
      vehicle {
        id
      }
    }
  }
`

export const CREATE_VEHICLE_DOCUMENT_MUTATION = gql`
  mutation CreateVehicleDocument($input: CreateVehicleDocumentInput!) {
    createVehicleDocument(input: $input) {
      vehicleDocument {
        id
        name
        originalName
      }
    }
  }
`
export const PATCH_VEHICLE_DOCUMENT_MUTATION = gql`
  mutation PatchVehicleDocument($id: ID!, $input: PatchVehicleDocumentInput!) {
    patchVehicleDocument(id: $id, input: $input) {
      vehicleDocument {
        id
      }
    }
  }
`

export const DELETE_VEHICLE_DOCUMENT_MUTATION = gql`
  mutation DeleteVehicleDocument($id: ID!) {
    deleteVehicleDocument(id: $id) {
      deletedId
    }
  }
`

export const CREATE_VEHICLE_EXPIRATION_MUTATION = gql`
  mutation CreateVehicleExpiration($input: CreateVehicleExpirationInput!) {
    createVehicleExpiration(input: $input) {
      vehicleExpiration {
        id
        expirationType
        expirationDate
      }
    }
  }
`

export const PATCH_VEHICLE_EXPIRATION_MUTATION = gql`
  mutation PatchVehicleExpiration(
    $id: ID!
    $input: PatchVehicleExpirationInput!
  ) {
    patchVehicleExpiration(id: $id, input: $input) {
      vehicleExpiration {
        id
      }
    }
  }
`

export const DELETE_VEHICLE_EXPIRATION_MUTATION = gql`
  mutation DeleteVehicleExpiration($id: ID!) {
    deleteVehicleExpiration(id: $id) {
      deletedId
    }
  }
`
