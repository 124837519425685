import React, { useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { InfoBox, SettingsWrapper } from '../components/common'
import {
  ExportCompanyAbsencesCard,
  ExportCompanyDriverActivitiesCard,
  ExportInfringementsCard,
  ExportCompanyIssuesCard,
  ExportTimesheetsCard,
  ExportTerminalCard,
} from '../components'
import { useTranslate } from '@ur/react-hooks'
import { useModuleConfigs } from '../hooks'

interface ExportsSettingsSettingsProps {}

export const ExportsSettings: React.VFC<ExportsSettingsSettingsProps> = () => {
  const translations = useTranslate({
    info1: 'settings.info.exports-1',
  })

  const [loading, setLoading] = useState(false)

  function handleLoadingChanged(formLoading: boolean) {
    setLoading(formLoading)
  }

  const {
    activitiesActive,
    timesheetsActive,
    terminalActive,
    issuesActive,
    absencesActive,
    infringementsActive,
  } = useModuleConfigs()

  return (
    <SettingsWrapper
      grid={{
        flow: 'row',
      }}
    >
      <InfoBox initCollapsed={isMobileOnly}>
        <p>{translations.info1}</p>
      </InfoBox>

      {activitiesActive && (
        <ExportCompanyDriverActivitiesCard
          loading={loading}
          onLoadingChangedCallback={handleLoadingChanged}
        />
      )}
      {terminalActive && (
        <ExportTerminalCard
          loading={loading}
          onLoadingChangedCallback={handleLoadingChanged}
        />
      )}
      {issuesActive && (
        <ExportCompanyIssuesCard
          loading={loading}
          onLoadingChangedCallback={handleLoadingChanged}
        />
      )}
      {infringementsActive && (
        <ExportInfringementsCard
          loading={loading}
          onLoadingChangedCallback={handleLoadingChanged}
        />
      )}
      {timesheetsActive && (
        <ExportTimesheetsCard
          loading={loading}
          onLoadingChangedCallback={handleLoadingChanged}
        />
      )}
      {absencesActive && (
        <ExportCompanyAbsencesCard
          loading={loading}
          onLoadingChangedCallback={handleLoadingChanged}
        />
      )}
    </SettingsWrapper>
  )
}
