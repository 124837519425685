import { BaseLanguage } from '../util'

const lv: BaseLanguage = {
  'activities.add-salary-day': 'NEEDS TRANSLATION',
  'activities.approve-day': 'NEEDS TRANSLATION',
  'activities.approve-month': 'NEEDS TRANSLATION',
  'activities.approve-title': 'NEEDS TRANSLATION',
  'activities.approve-week': 'NEEDS TRANSLATION',
  'activities.availability-time': 'Pieejamības laiks',
  'activities.consumption': 'Degvielas patēriņš',
  'activities.day-end': 'Dienas beigas',
  'activities.day-start': 'Dienas sākums',
  'activities.driver': 'Vadītājs',
  'activities.driving': 'Braukšana',
  'activities.driving-grade': 'Braukšanas novērtējums',
  'activities.end-position':
    '{abbreviate, select, true {Gala poz.} other {Gala pozīcija}}',
  'activities.errors.could-not-load-route-in-map':
    'Neizdevās ielādēt maršrutu kartē',
  'activities.errors.general-error':
    'Notikusi kļūda, iegūstot informāciju par braukšanas nodarbošanos',
  'activities.errors.select-multiple-users': 'NEEDS TRANSLATION',
  'activities.errors.user-not-found': 'Lietotājs nav atrasts',
  'activities.export-driving-data': 'Eksportēt datus par braukšanu',
  'activities.export-monthly-weekly': 'Eksportēt katru mēnesi vai nedēļu',
  'activities.fetch-data': 'Iegūt datus par braukšanu',
  'activities.fuel-usage': 'Degvielas patēriņš',
  'activities.last-fetched': 'NEEDS TRANSLATION',
  'activities.missing-data': 'NEEDS TRANSLATION',
  'activities.module-title': 'Dati par braukšanu',
  'activities.paid-pause': 'NEEDS TRANSLATION',
  'activities.pause-export': 'NEEDS TRANSLATION',
  'activities.print-driving-data': 'Drukāt datus par braukšanu',
  'activities.regnr': 'Reģ. numurs',
  'activities.rest': 'Atpūta',
  'activities.start-position':
    '{abbreviate, select, true {Sākuma poz.} other {Sākuma pozīcija}}',
  'activities.starting-activity-fetching-process':
    'Начало процесса получение деятельности',
  'activities.success.approve-activities': 'NEEDS TRANSLATION',
  'activities.sum-for-month-title': 'Summa par {month}',
  'activities.sum-next-month':
    'Summa par nedēļu {week} tiks aprēķināta nākamajā mēnesī',
  'activities.sum-overtime': 'Virsstundas',
  'activities.updated-diet-successfully': 'NEEDS TRANSLATION',
  'activities.updated-salary-day-successfully': 'NEEDS TRANSLATION',
  'activities.work': 'Darbs',
  'activities.work-summary': 'Darba kopsavilkums',
  'activities.working-hours': 'Darba laiks',
  'admin-required.message':
    'Jums ir jābūt administratoram, lai iepazīties ar saturu',
  'auth.admin-or-user-required':
    'Jums ir jābūt {user} vai administratoram, lai iepazīties ar saturu',
  'change-password.description': 'Jums ir jāatjauno parole drošības nolūkos',
  'change-password.title': 'Nomainīt paroli',
  'checklists.all-checklist-templates': 'Visu kontrolsarakstu šabloni',
  'checklists.checklist-item': 'Kontrolsaraksta punkts',
  'checklists.checklist-template': 'Kontrolsaraksta šablons',
  'checklists.generate-new': 'Izveidot jauno',
  'checklists.no-checklists': 'Nav pieejamu kontrolsarakstu',
  'commmon.edit-name': 'Rediģēt vārdu',
  'common.about': 'О',
  'common.absence': 'Prombūtne',
  'common.absence-types': 'Prombūtnes iemesli',
  'common.absences': 'Prombūtne',
  'common.action': 'NEEDS TRANSLATION',
  'common.active': 'Aktīvs',
  'common.activities': 'Dati par braukšanu',
  'common.activity': 'Nodarbošanās',
  'common.add': 'Pievienot',
  'common.add-attachment': 'Pievienot pielikumu',
  'common.add-attachments': 'Pievienot pielikumus',
  'common.add-column': 'Pievienot kolonnu',
  'common.add-file': 'Pievienot failu',
  'common.add-file-alt': 'Pievienot failu',
  'common.add-files': 'Pievienot failus',
  'common.additional-pay': 'NEEDS TRANSLATION',
  'common.address': 'Adrese',
  'common.all': 'Visi',
  'common.ambulance': 'Neatliekamās medicīniskās palīdzības dienests',
  'common.app': 'Pielikums',
  'common.archive': 'Arhivēt',
  'common.archived': 'Arhivēts',
  'common.article': 'Raksts',
  'common.attachments': 'Pielikums',
  'common.author': 'Autors',
  'common.availability': 'NEEDS TRANSLATION',
  'common.basis': 'Bāze',
  'common.both': 'Abi',
  'common.business': 'Bizness',
  'common.businessOverview': 'Biznesa pārskats',
  'common.by-name': 'Pēc {name}',
  'common.cancel': 'Atcelt',
  'common.cancel-changes': 'Atcelt izmaiņas',
  'common.categories': 'Kategorijas',
  'common.category': 'Kategorija',
  'common.category-for-type': 'Kategorijas (kam?) {type}',
  'common.change-company': 'Mainīt kompāniju',
  'common.change-language': 'Mainīt valodu',
  'common.change-password': 'Mainīt paroli',
  'common.changes-saved': 'Izmaiņas tika veiksmīgi saglabātas',
  'common.checklist-templates': 'Kontrolsarakstu šabloni',
  'common.checklists': 'Kontrolsaraksti',
  'common.choose-ellipsis': 'Izvēlies',
  'common.city': 'Pilsēta',
  'common.close': 'Aizvērt',
  'common.closed': 'Aizvērts',
  'common.codes': 'Kodi',
  'common.color': 'Krāsa',
  'common.comment': 'NEEDS TRANSLATION',
  'common.comment-ellipsis': 'Komentēt... ',
  'common.comments': 'Komentāri',
  'common.company': 'Kompānija',
  'common.company-name': 'Kompānijas nosaukums',
  'common.contact-info': 'Kontaktinformācija',
  'common.contact-person': 'Kontaktpersona',
  'common.content': 'Saturs',
  'common.count': 'Skaitītājs',
  'common.count-of-total': '{count} no {total}',
  'common.count-of-total-verbose': 'Darbinieki informēti {count}/{total}',
  'common.countries': 'Valstis',
  'common.country': 'Valsts',
  'common.create': 'Izveidot',
  'common.create-alt': 'Izveidot',
  'common.create-entry': 'Izveidot jauno ierakstu',
  'common.create-folder': 'Izveidot jauno mapi',
  'common.create-form': 'Izveidot jauno formu',
  'common.create-form-entry': 'Izveidot jauno formas ierakstu',
  'common.create-type': 'Izveidot {type}',
  'common.creating': 'Izveidošana',
  'common.csv': 'NEEDS TRANSLATION',
  'common.current-password': 'Pašreizējā parole',
  'common.daily': 'Katru dienu',
  'common.dashboard': 'Informācijas panelis',
  'common.data': 'NEEDS TRANSLATION',
  'common.data-distribution': 'NEEDS TRANSLATION',
  'common.date': 'Datums',
  'common.date-for-type': 'Datums {type}',
  'common.date-validation-not-in-future':
    'Datumam ir jābūt derīgam, nevis nākotnē',
  'common.day-end': 'Dienas beigas',
  'common.day-start': 'Dienas sākums',
  'common.days':
    '{days, plural, =0 {Nav dienu} one {# diena} two {# dienas} three {# dienas} four {# dienas} other {# dienas}}',
  'common.deactivated': 'Deaktivēts',
  'common.default-severity': 'NEEDS TRANSLATION',
  'common.delete': 'Dzēst',
  'common.delete-alt': 'Dzēst',
  'common.delete-type': 'Dzēst {type}',
  'common.department': 'NEEDS TRANSLATION',
  'common.department-expandable-text': 'NEEDS TRANSLATION',
  'common.departments': 'NEEDS TRANSLATION',
  'common.description': 'Apraksts',
  'common.detail': 'Detaļa',
  'common.details': 'NEEDS TRANSLATION',
  'common.diet': 'Diēta',
  'common.disabled': 'Atspējots',
  'common.distance': 'Distance',
  'common.document': 'Dokuments',
  'common.documentation': 'Dokumentācija',
  'common.documentationIssues': 'Problēmas ar dokumentāciju',
  'common.documents': 'Dokumenti',
  'common.download': 'Lejuplādēt',
  'common.driver': 'Vadītājs',
  'common.drivers': 'Vadītāji',
  'common.drivers-handbook': 'Vadītāja rokasgrāmata',
  'common.driversHandbook': 'Vadītāja rokasgrāmata',
  'common.driversHandbookForm': 'Rokasgrāmatas',
  'common.driving': 'Braukšana',
  'common.driving-data': 'Dati par braukšanu',
  'common.dropdown': 'Nolaižamā izvēlne',
  'common.dropdown-on-pages': 'Lietotāja nolaižamā izvēlne',
  'common.dropdowns': 'Nolaižamās izvēlnes',
  'common.edit': 'Rediģēt',
  'common.edit-alt': 'Rediģēt',
  'common.edit-entry': 'Rediģēt ierakstu',
  'common.edit-name': 'Rediģēt nosaukumu',
  'common.edit-type': 'Rediģēt {type}',
  'common.email': 'E-pasts',
  'common.email-already-selected': 'E-pasts jau ir izvēlēts',
  'common.employee': 'Darbinieks',
  'common.enabled': 'Ieslēgts',
  'common.end': 'Beigas',
  'common.end-date': 'Beigu datums',
  'common.end-time': 'Beigu laiks',
  'common.enter-url': 'Ievadi URL',
  'common.entry': 'Ieraksts',
  'common.eurovignette': 'Autoceļu lietošanas nodeva',
  'common.eurovignettes': 'Autoceļu lietošanas nodevas',
  'common.excel': 'NEEDS TRANSLATION',
  'common.excel-flat': 'NEEDS TRANSLATION',
  'common.expired': 'Derīguma termiņš beidzies',
  'common.expires-at': 'Beigsies',
  'common.export': 'Eksportēt',
  'common.export-format': 'NEEDS TRANSLATION',
  'common.export-type': 'Eksporta tips',
  'common.exports-settings': 'NEEDS TRANSLATION',
  'common.external-id': 'NEEDS TRANSLATION',
  'common.fats': 'NEEDS TRANSLATION',
  'common.fats-beta': 'NEEDS TRANSLATION',
  'common.field': 'NEEDS TRANSLATION',
  'common.file': 'Fails',
  'common.files': 'Faili',
  'common.filter': 'Filtrs',
  'common.finished': 'Pabeigts',
  'common.fire': 'Ugunsdzēsības un glābšanas dienests',
  'common.firstName': 'Vārds',
  'common.folder': 'Mape',
  'common.form-error': 'Viena vai vairākas ailes tika nekorekti aizpildītas',
  'common.form-responses': 'NEEDS TRANSLATION',
  'common.formcreator': 'Formu izveide',
  'common.forms': 'NEEDS TRANSLATION',
  'common.forum': 'Forums',
  'common.forum-posts': 'NEEDS TRANSLATION',
  'common.forum-threads': 'NEEDS TRANSLATION',
  'common.freight-carrier': 'Kravu pārvadātājs',
  'common.from': 'No',
  'common.general': 'Vispārējs',
  'common.generate': 'NEEDS TRANSLATION',
  'common.generate-report': 'NEEDS TRANSLATION',
  'common.go-back': 'Atpakaļ',
  'common.handbook': 'Rokasgrāmata',
  'common.height': 'NEEDS TRANSLATION',
  'common.height-in-cm': 'NEEDS TRANSLATION',
  'common.high': 'NEEDS TRANSLATION',
  'common.holiday-allowances': 'NEEDS TRANSLATION',
  'common.home': 'Galvenā mājaslapa',
  'common.hourlist': 'Stundu saraksts',
  'common.hours': 'Stundas',
  'common.hours-abbrev': 'h',
  'common.hours-lowercase': 'stundas',
  'common.id': 'NEEDS TRANSLATION',
  'common.in-app': 'Aplikācijā',
  'common.include-archived': 'NEEDS TRANSLATION',
  'common.information': 'Informācija',
  'common.infringement': 'Pārkāpums',
  'common.infringements': 'Pārkāpumi',
  'common.integrations': 'Integrācijas',
  'common.invalid': 'Nav pareizi',
  'common.invalid-date': 'Nav pareizais datums',
  'common.invalid-url': 'Nav pareizais URL',
  'common.issue': 'Problēma',
  'common.issue-categories': 'Problēmas kategorija',
  'common.issued-at': 'Izdots:',
  'common.issues': 'Problēmas',
  'common.items': 'Preces',
  'common.kilometers': '{abbreviate, select, true {km} other {kilometri}}',
  'common.km': 'km',
  'common.km-per-hour-short': 'NEEDS TRANSLATIOn',
  'common.language': 'Valoda',
  'common.last-revision': 'NEEDS TRANSLATION',
  'common.lastName': 'Uzvārds',
  'common.length': 'NEEDS TRANSLATION',
  'common.length-in-cm': 'NEEDS TRANSLATION',
  'common.liters': 'Litri',
  'common.liters-short': 'l',
  'common.load-more': 'Augšupielādēt vairāk',
  'common.load-previous': 'Augšupielādēt iepriekšejo',
  'common.loading': 'Augšupielāde',
  'common.loading...': 'Notiek augšupielāde',
  'common.location': 'Lokācija',
  'common.log-in': 'Ienākt',
  'common.log-out': 'Iziet',
  'common.login': 'Ienākt',
  'common.low': 'NEEDS TRANSLATION',
  'common.make-a-choice': 'Izvēlies',
  'common.make-a-selection': 'Izvēlies',
  'common.manual': 'NEEDS TRANSLATION',
  'common.map': 'NEEDS TRANSLATION',
  'common.mass-print': 'Drukāt visu',
  'common.maximum': 'Maksimums',
  'common.medium': 'NEEDS TRANSLATION',
  'common.menu': 'Izvēlne',
  'common.minimum': 'Minimums',
  'common.minutes': 'Minūtes',
  'common.minutes-abbrev': 'min',
  'common.module': 'Modulis',
  'common.month': 'Mēnesis',
  'common.monthly': 'Katru mēnesi',
  'common.move-down': 'Pārvietot lejup',
  'common.move-up': 'Pārvietot augšup',
  'common.n-categories': 'NEEDS TRANSLATION',
  'common.n-hours': '{n} stundu',
  'common.n-issues': '{n} problēmu',
  'common.n-minutes': '{n} minūšu',
  'common.n-salary-code': 'NEEDS TRANSLATION',
  'common.name': 'Nosaukums',
  'common.needs-comment': 'NEEDS TRANSLATION',
  'common.new-password': 'Jaunā paroles',
  'common.new-password-again': 'Atkārto jauno paroli',
  'common.no': 'Nē',
  'common.no-active-modules': 'Nav aktīvo moduļu',
  'common.no-attachments': 'Nav pielikumu',
  'common.no-changes': 'Nav izmaiņu',
  'common.no-comment': 'NEEDS TRANSLATION',
  'common.no-more-options': 'Vairāk nav variantu',
  'common.no-options-to-choose-from': 'Nav variantu izvēlei',
  'common.no-results': 'Nav rezultātu',
  'common.none': 'Nē',
  'common.not-archived': 'Nav arhivēts',
  'common.not-verified-yet': 'Vēl nav verificēts',
  'common.notification': 'Paziņojums',
  'common.notifications': 'Paziņojumi',
  'common.notifications-alt': 'NEEDS TRANSLATION',
  'common.office': 'Birojs',
  'common.ok': 'ОК',
  'common.open': 'Atvērt',
  'common.open-alt': 'Atvērt',
  'common.opened': 'Atvērts',
  'common.order': 'Pasūtījums',
  'common.order-by': 'NEEDS TRANSLATION',
  'common.ordinary': 'NEEDS TRANSLATION',
  'common.other': 'Cits',
  'common.other-work': 'Cits darbs',
  'common.overtime': 'Virsstundas',
  'common.overtime-mode': 'NEEDS TRANSLATION',
  'common.overtime-rate': 'NEEDS TRANSLATION',
  'common.password': 'Parole',
  'common.pause': 'Pauze',
  'common.pdf': 'NEEDS TRANSLATION',
  'common.pdf-salary': 'NEEDS TRANSLATION',
  'common.period': 'Periods',
  'common.perks': 'Atvieglojumi',
  'common.permissions': 'Atļaujas',
  'common.phone': 'Telefons',
  'common.phone-number': 'Tālruņa numurs',
  'common.phone-prefix': 'Tālruņa kods',
  'common.police': 'Policija',
  'common.postal-code': 'Pasta indekss',
  'common.poweroffice': 'NEEDS TRANSLATION',
  'common.previous-responses': 'NEEDS TRANSLATION',
  'common.print': 'Drukāt',
  'common.print-type': 'Drukāt {type}',
  'common.priority': 'NEEDS TRANSLATION',
  'common.privacy': 'Konfidencialitāte',
  'common.project': 'NEEDS TRANSLATION',
  'common.publish': 'Publicēt',
  'common.published': 'NEEDS TRANSLATION',
  'common.range': 'Diapazons',
  'common.reason': 'Iemesls',
  'common.reg-nr': 'Reģ. numurs',
  'common.register': 'Reģistrēt',
  'common.remove': 'Dzēst',
  'common.report': 'Atskaite',
  'common.required': 'Pieprasīts',
  'common.reset': 'Noņemt',
  'common.responses': 'Atbildes',
  'common.responsible': 'Atbildīgais',
  'common.responsible-user': 'Atbildīgais lietotajs',
  'common.responsible-users': 'NEEDS TRANSLATION',
  'common.rest': 'Atpūta',
  'common.restore': 'Atjaunot',
  'common.risk-aspect': 'NEEDS TRANSLATION',
  'common.risk-aspects': 'NEEDS TRANSLATION',
  'common.role': 'Amats',
  'common.route-planner': 'NEEDS TRANSLATION',
  'common.salary': 'NEEDS TRANSLATION',
  'common.salary-code': 'NEEDS TRANSLATION',
  'common.salary-codes': 'NEEDS TRANSLATION',
  'common.salary-day': 'NEEDS TRANSLATION',
  'common.salary-mode': 'NEEDS TRANSLATION',
  'common.save': 'Saglabāt',
  'common.save-and-close': 'Saglabāt un aizvērt',
  'common.save-changes': 'Saglabāt izmaiņas',
  'common.save-changes-to-type': 'Saglabāt izmaiņas {type}',
  'common.save-in-user-exports': 'NEEDS TRANSLATION',
  'common.save-languages': 'Saglabāt valodu',
  'common.saving': 'Notiek saglabāšana',
  'common.search': 'Meklēt...',
  'common.seconds-abbrev': 's',
  'common.see-all-responses': 'NEEDS TRANSLATION',
  'common.seen-by': 'Redzēts',
  'common.select': 'Izvēlēties',
  'common.select-all': 'NEEDS TRANSLATION',
  'common.select-at-least-one-recipient': 'NEEDS TRANSLATION',
  'common.select-manual-entry': 'NEEDS TRANSLATION',
  'common.select-project': 'NEEDS TRANSLATION',
  'common.select-salary-code': 'NEEDS TRANSLATION',
  'common.select-user': 'Izvēlēties lietotāju',
  'common.select-users': 'Izvēlēties lietotājus',
  'common.select-week-days': 'NEEDS TRANSLATION',
  'common.send-print-to-mail': 'Vai vēlaties saņemt izdruku uz e-pastu?',
  'common.settings': 'Iestadījumi',
  'common.severity': 'Nopietnība',
  'common.shortcuts': 'Īsceļi',
  'common.show-all': 'Rādīt visu',
  'common.show-sums': 'NEEDS TRANSLATION',
  'common.singular': 'Sevišķs',
  'common.sms': 'SMS',
  'common.sort-by-user-type': 'NEEDS TRANSLATION',
  'common.start': 'Sākt',
  'common.start-date': 'Sākuma datums',
  'common.start-time': 'Sākuma laiks',
  'common.statistics': 'Statistika',
  'common.statistics-this-week': 'NEEDS TRANSLATION',
  'common.status': 'Statuss',
  'common.submit': 'Nosūtīt',
  'common.sum': 'Summa',
  'common.sums': 'NEEDS TRANSLATION',
  'common.terminal': 'Terminālis',
  'common.terminal-network': 'Termināļu tīkls',
  'common.the-email': 'E-pasts',
  'common.the-field': 'Aile',
  'common.thread': 'Tēma',
  'common.time': 'Laiks',
  'common.time-entries': 'Laika ieraksti',
  'common.time-entry': 'Laika ieraksts',
  'common.time-interval': 'NEEDS TRANSLATION',
  'common.timesheets': 'Darba laika uzskaites tabulas',
  'common.timesinceposted': 'Publicēts ',
  'common.timestamp': 'NEEDS TRANSLATION',
  'common.title': 'Nosaukums',
  'common.total-work': 'NEEDS TRANSLATION',
  'common.tours': 'NEEDS TRANSLATION',
  'common.tripletex': 'NEEDS TRANSLATION',
  'common.tv-display': 'NEEDS TRANSLATION',
  'common.twentyfour-seven-office': 'NEEDS TRANSLATION',
  'common.two-weekly': 'NEEDS TRANSLATION',
  'common.type': 'Tips',
  'common.unavailable': 'Nav pieejams!',
  'common.unknown': 'Nezināms',
  'common.until': 'Līdz',
  'common.untitled': 'Bez nosaukuma',
  'common.update': 'Atjaunot',
  'common.update-entry': 'Atjaunot rakstu',
  'common.update-folder': 'Atjaunot mapi',
  'common.update-form': 'Atjaunot formu',
  'common.update-form-entry': 'Atjaunot formas rakstu',
  'common.update-password': 'Atjaunot',
  'common.update-type': 'Atjaunot {type}',
  'common.updated-at': 'Atjaunots pulksten',
  'common.updating': 'Atjaunināšana',
  'common.uploaded': 'Augšupielādēts',
  'common.uploaded-attachments': 'Augšupielādēti pielikumi',
  'common.user': 'Lietotājs',
  'common.user-document-categories': 'NEEDS TRANSLATION',
  'common.user-documents': 'Lietotāja dokumenti',
  'common.user-type': 'Lietotāja tips',
  'common.user-types': 'Lietotāju tipi',
  'common.username': 'Lietotājvārds',
  'common.users': 'Lietotāji',
  'common.usersettings': 'Lietotāja iestādījumi',
  'common.value': 'Vērtība',
  'common.vehicle': 'Transportlīdzeklis',
  'common.vehicle-expandable-text': 'NEEDS TRANSLATION',
  'common.vehicle-fleet': 'Autoparks',
  'common.verified': 'Verificēts',
  'common.view-comment': 'NEEDS TRANSLATION',
  'common.visma-payroll': 'NEEDS TRANSLATION',
  'common.week': 'Nedēļa',
  'common.week-n': 'Nedēļa {n}',
  'common.weekly': 'Katru nedēļu',
  'common.weeks':
    '{weeks, plural, =0 {Nav nedēļu} one {# nedēļa} two {# nedēļas} three {# nedēļas} four {# nedēļas} other {# nedēļas}}',
  'common.weight': 'NEEDS TRANSLATION',
  'common.when-by-whom': '{whom} {when}',
  'common.whole-day': 'NEEDS TRANSLATION',
  'common.width': 'NEEDS TRANSLATION',
  'common.width-in-cm': 'NEEDS TRANSLATION',
  'common.work': 'Darbs',
  'common.work-hours': 'Darba stundas',
  'common.year': 'Gads',
  'common.yes': 'Jā',
  'common.you-have-unsaved-changes': 'Šobrīd Jums ir nesaglabātas izmaiņas',
  'common.you-have-unsaved-changes-navigate':
    'Šobrīd Jums ir nesaglabātas izmaiņas. Vai esat pārliecināts, ka vēlaties pamest lapu?',
  'companies.create-overtime-period': 'NEEDS TRANSLATION',
  'companies.create-period-addon': 'NEEDS TRANSLATION',
  'companies.edit-overtime-period': 'NEEDS TRANSLATION',
  'companies.edit-period-addon': 'NEEDS TRANSLATION',
  'companies.existing-overtime-period': 'NEEDS TRANSLATION',
  'companies.overlapping-periods-are-not-allowed': 'NEEDS TRANSLATION',
  'companies.toasts.department-created': 'NEEDS TRANSLATION',
  'companies.toasts.department-deleted': 'NEEDS TRANSLATION',
  'companies.toasts.department-updated': 'NEEDS TRANSLATION',
  'companies.toasts.holiday-allowance-created': 'NEEDS TRANSLATION',
  'companies.toasts.holiday-allowance-deleted': 'NEEDS TRANSLATION',
  'companies.toasts.holiday-allowance-updated': 'NEEDS TRANSLATION',
  'companies.toasts.salary-code-created': 'NEEDS TRANSLATION',
  'companies.toasts.salary-code-deleted': 'NEEDS TRANSLATION',
  'companies.toasts.salary-code-updated': 'NEEDS TRANSLATION',
  'countries.codes.AC': 'Debesbraukšanas sala',
  'countries.codes.AD': 'Andora',
  'countries.codes.AE': 'Apvienotie Arābu Emirāti',
  'countries.codes.AF': 'Afganistāna',
  'countries.codes.AG': 'Antigva un Barbuda',
  'countries.codes.AI': 'Angilja',
  'countries.codes.AL': 'Albānija',
  'countries.codes.AM': 'Armēnija',
  'countries.codes.AO': 'Angola',
  'countries.codes.AQ': 'Antarktīda',
  'countries.codes.AR': 'Argentīna',
  'countries.codes.AS': 'Austrumsamoa',
  'countries.codes.AT': 'Austrija',
  'countries.codes.AU': 'Austrālija',
  'countries.codes.AW': 'Aruba',
  'countries.codes.AX': 'Olande',
  'countries.codes.AZ': 'Azerbaidžāna',
  'countries.codes.BA': 'Bosnija un Hercegovina',
  'countries.codes.BB': 'Barbadosa',
  'countries.codes.BD': 'Bangladeša',
  'countries.codes.BE': 'Beļģija',
  'countries.codes.BF': 'Burkinafaso',
  'countries.codes.BG': 'Bulgārija',
  'countries.codes.BH': 'Bahreina',
  'countries.codes.BI': 'Burundija',
  'countries.codes.BJ': 'Benina',
  'countries.codes.BL': 'Senbartelmī',
  'countries.codes.BM': 'Bermudu salas',
  'countries.codes.BN': 'Bruneja',
  'countries.codes.BO': 'Bolīvija',
  'countries.codes.BQ': 'Nīderlandes Antiļas',
  'countries.codes.BR': 'Brazīlija',
  'countries.codes.BS': 'Bahamu Salas',
  'countries.codes.BT': 'Butāna',
  'countries.codes.BV': 'Buvē sala',
  'countries.codes.BW': 'Botsvāna',
  'countries.codes.BY': 'Baltkrievija',
  'countries.codes.BZ': 'Beliza',
  'countries.codes.CA': 'Kanāda',
  'countries.codes.CC': 'Kokosu (Kīlinga) Salas',
  'countries.codes.CD': 'Kongo Demokrātiskā Republika',
  'countries.codes.CF': 'Centrālāfrikas Republika',
  'countries.codes.CG': 'Kongo Republika',
  'countries.codes.CH': 'Šveice',
  'countries.codes.CI': 'Kotdivuāra',
  'countries.codes.CK': 'Kuka Salas',
  'countries.codes.CL': 'Čīle',
  'countries.codes.CM': 'Kamerūna',
  'countries.codes.CN': 'Ķīna',
  'countries.codes.CO': 'Kolumbija',
  'countries.codes.CP': 'Klipertona sala',
  'countries.codes.CR': 'Kostarika',
  'countries.codes.CU': 'Kuba',
  'countries.codes.CV': 'Kaboverde',
  'countries.codes.CW': 'Kirasao',
  'countries.codes.CX': 'Ziemsvētku Sala',
  'countries.codes.CY': 'Kipra',
  'countries.codes.CZ': 'Čehija',
  'countries.codes.DE': 'Vācija',
  'countries.codes.DG': 'Diego Garcia',
  'countries.codes.DJ': 'Džibutija',
  'countries.codes.DK': 'Dānija',
  'countries.codes.DM': 'Dominika',
  'countries.codes.DO': 'Dominikāna',
  'countries.codes.DZ': 'Alžīrija',
  'countries.codes.EA': 'Seuta un Melilja',
  'countries.codes.EC': 'Ekvadora',
  'countries.codes.EE': 'Igaunija',
  'countries.codes.EG': 'Ēģipte',
  'countries.codes.EH': 'Rietumsahāra',
  'countries.codes.ER': 'Eritreja',
  'countries.codes.ES': 'Spānija',
  'countries.codes.ET': 'Etiopija',
  'countries.codes.EU': 'Eiropas Savienība',
  'countries.codes.FI': 'Somija',
  'countries.codes.FJ': 'Fidži',
  'countries.codes.FK': 'Folklenda Salas',
  'countries.codes.FM': 'Mikronēzija',
  'countries.codes.FO': 'Fēru Salas',
  'countries.codes.FR': 'Francija',
  'countries.codes.GA': 'Gabona',
  'countries.codes.GB': 'Lielbritānija',
  'countries.codes.GD': 'Grenāda',
  'countries.codes.GE': 'Gruzija',
  'countries.codes.GF': 'Gviāna',
  'countries.codes.GG': 'Gērnsija',
  'countries.codes.GH': 'Gana',
  'countries.codes.GI': 'Gibraltārs',
  'countries.codes.GL': 'Grenlande',
  'countries.codes.GM': 'Gambija',
  'countries.codes.GN': 'Gvineja',
  'countries.codes.GP': 'Gvadelupa',
  'countries.codes.GQ': 'Ekvatoriālā Gvineja',
  'countries.codes.GR': 'Grieķija',
  'countries.codes.GS': 'Dienviddžordžija un Dienvidsendviču Salas',
  'countries.codes.GT': 'Gvatemala',
  'countries.codes.GU': 'Guama',
  'countries.codes.GW': 'Gvineja-Bisava',
  'countries.codes.GY': 'Gajāna',
  'countries.codes.HK': 'Honkonga',
  'countries.codes.HM': 'Hērda Sala un Makdonalda Salas',
  'countries.codes.HN': 'Hondurasa',
  'countries.codes.HR': 'Horvātija',
  'countries.codes.HT': 'Haiti',
  'countries.codes.HU': 'Ungārija',
  'countries.codes.IC': 'Kanāriju Salas',
  'countries.codes.ID': 'Indonēzija',
  'countries.codes.IE': 'Īrija',
  'countries.codes.IL': 'Izraēla',
  'countries.codes.IM': 'Menas Sala',
  'countries.codes.IN': 'Indija',
  'countries.codes.IO': 'Britu Indijas Okeāna Teritorija',
  'countries.codes.IQ': 'Irāka',
  'countries.codes.IR': 'Irāna',
  'countries.codes.IS': 'Islande',
  'countries.codes.IT': 'Itālija',
  'countries.codes.JE': 'Džērsija',
  'countries.codes.JM': 'Jamaika',
  'countries.codes.JO': 'Jordāna',
  'countries.codes.JP': 'Japāna',
  'countries.codes.KE': 'Kenija',
  'countries.codes.KG': 'Kirgizstāna',
  'countries.codes.KH': 'Kambodža',
  'countries.codes.KI': 'Kiribati',
  'countries.codes.KM': 'Komoras',
  'countries.codes.KN': 'Sentkitsa un Nevisa',
  'countries.codes.KP': 'Ziemeļkoreja',
  'countries.codes.KR': 'Dienvidkoreja',
  'countries.codes.KW': 'Kuveita',
  'countries.codes.KY': 'Kaimanu Salas',
  'countries.codes.KZ': 'Kazahstāna',
  'countries.codes.LA': 'Laosa',
  'countries.codes.LB': 'Libāna',
  'countries.codes.LC': 'Sentlūsija',
  'countries.codes.LI': 'Lihtenšteina',
  'countries.codes.LK': 'Šrilanka',
  'countries.codes.LR': 'Libērija',
  'countries.codes.LS': 'Lesoto',
  'countries.codes.LT': 'Lietuva',
  'countries.codes.LU': 'Luksemburga',
  'countries.codes.LV': 'Latvija',
  'countries.codes.LY': 'Lībija',
  'countries.codes.MA': 'Maroka',
  'countries.codes.MC': 'Monako',
  'countries.codes.MD': 'Moldova',
  'countries.codes.ME': 'Melnkalne',
  'countries.codes.MF': 'Senmartēna',
  'countries.codes.MG': 'Madagaskara',
  'countries.codes.MH': 'Māršala Salas',
  'countries.codes.MK': 'Ziemeļmaķedonija',
  'countries.codes.ML': 'Mali',
  'countries.codes.MM': 'Mjanma',
  'countries.codes.MN': 'Mongolija',
  'countries.codes.MO': 'Makao',
  'countries.codes.MP': 'NEEDS TRANSLATION',
  'countries.codes.MQ': 'NEEDS TRANSLATION',
  'countries.codes.MR': 'NEEDS TRANSLATION',
  'countries.codes.MS': 'NEEDS TRANSLATION',
  'countries.codes.MT': 'NEEDS TRANSLATION',
  'countries.codes.MU': 'NEEDS TRANSLATION',
  'countries.codes.MV': 'NEEDS TRANSLATION',
  'countries.codes.MW': 'NEEDS TRANSLATION',
  'countries.codes.MX': 'NEEDS TRANSLATION',
  'countries.codes.MY': 'NEEDS TRANSLATION',
  'countries.codes.MZ': 'NEEDS TRANSLATION',
  'countries.codes.NA': 'NEEDS TRANSLATION',
  'countries.codes.NC': 'NEEDS TRANSLATION',
  'countries.codes.NE': 'NEEDS TRANSLATION',
  'countries.codes.NF': 'NEEDS TRANSLATION',
  'countries.codes.NG': 'NEEDS TRANSLATION',
  'countries.codes.NI': 'NEEDS TRANSLATION',
  'countries.codes.NL': 'NEEDS TRANSLATION',
  'countries.codes.NO': 'NEEDS TRANSLATION',
  'countries.codes.NP': 'NEEDS TRANSLATION',
  'countries.codes.NR': 'NEEDS TRANSLATION',
  'countries.codes.NU': 'NEEDS TRANSLATION',
  'countries.codes.NZ': 'NEEDS TRANSLATION',
  'countries.codes.OM': 'NEEDS TRANSLATION',
  'countries.codes.PA': 'NEEDS TRANSLATION',
  'countries.codes.PE': 'NEEDS TRANSLATION',
  'countries.codes.PF': 'NEEDS TRANSLATION',
  'countries.codes.PG': 'NEEDS TRANSLATION',
  'countries.codes.PH': 'NEEDS TRANSLATION',
  'countries.codes.PK': 'NEEDS TRANSLATION',
  'countries.codes.PL': 'NEEDS TRANSLATION',
  'countries.codes.PM': 'NEEDS TRANSLATION',
  'countries.codes.PN': 'NEEDS TRANSLATION',
  'countries.codes.PR': 'NEEDS TRANSLATION',
  'countries.codes.PS': 'NEEDS TRANSLATION',
  'countries.codes.PT': 'NEEDS TRANSLATION',
  'countries.codes.PW': 'NEEDS TRANSLATION',
  'countries.codes.PY': 'NEEDS TRANSLATION',
  'countries.codes.QA': 'NEEDS TRANSLATION',
  'countries.codes.RE': 'NEEDS TRANSLATION',
  'countries.codes.RO': 'NEEDS TRANSLATION',
  'countries.codes.RS': 'NEEDS TRANSLATION',
  'countries.codes.RU': 'NEEDS TRANSLATION',
  'countries.codes.RW': 'NEEDS TRANSLATION',
  'countries.codes.SA': 'NEEDS TRANSLATION',
  'countries.codes.SB': 'NEEDS TRANSLATION',
  'countries.codes.SC': 'NEEDS TRANSLATION',
  'countries.codes.SD': 'NEEDS TRANSLATION',
  'countries.codes.SE': 'NEEDS TRANSLATION',
  'countries.codes.SG': 'NEEDS TRANSLATION',
  'countries.codes.SH': 'NEEDS TRANSLATION',
  'countries.codes.SI': 'NEEDS TRANSLATION',
  'countries.codes.SJ': 'NEEDS TRANSLATION',
  'countries.codes.SK': 'NEEDS TRANSLATION',
  'countries.codes.SL': 'NEEDS TRANSLATION',
  'countries.codes.SM': 'NEEDS TRANSLATION',
  'countries.codes.SN': 'NEEDS TRANSLATION',
  'countries.codes.SO': 'NEEDS TRANSLATION',
  'countries.codes.SR': 'NEEDS TRANSLATION',
  'countries.codes.SS': 'NEEDS TRANSLATION',
  'countries.codes.ST': 'NEEDS TRANSLATION',
  'countries.codes.SV': 'NEEDS TRANSLATION',
  'countries.codes.SX': 'NEEDS TRANSLATION',
  'countries.codes.SY': 'NEEDS TRANSLATION',
  'countries.codes.SZ': 'Svatini',
  'countries.codes.TA': 'Tristana da Kuņas salas',
  'countries.codes.TC': 'Tērksas un Kaikosas',
  'countries.codes.TD': 'Čada',
  'countries.codes.TF': 'Francijas Dienvidjūru un Antarktikas Zemes',
  'countries.codes.TG': 'Togo',
  'countries.codes.TH': 'Taizeme',
  'countries.codes.TJ': 'Tadžikistāna',
  'countries.codes.TK': 'Tokelau',
  'countries.codes.TL': 'Austrumtimora',
  'countries.codes.TM': 'Turkmenistāna',
  'countries.codes.TN': 'Tunisija',
  'countries.codes.TO': 'Tonga',
  'countries.codes.TR': 'Turcija',
  'countries.codes.TT': 'Trinidāda un Tobāgo',
  'countries.codes.TV': 'Tuvalu',
  'countries.codes.TW': 'Taivāna',
  'countries.codes.TZ': 'Tanzānija',
  'countries.codes.UA': 'Ukraina',
  'countries.codes.UG': 'Uganda',
  'countries.codes.UM': 'ASV Mazās aizjūras salas',
  'countries.codes.UN': 'ANO',
  'countries.codes.US': 'ASV',
  'countries.codes.UY': 'Urugvaja',
  'countries.codes.UZ': 'Uzbekistāna',
  'countries.codes.VA': 'Vatikāns',
  'countries.codes.VC': 'Sentvinsenta un Grenadīnas',
  'countries.codes.VE': 'Venecuēla',
  'countries.codes.VG': 'Britu Virdžīnas',
  'countries.codes.VI': 'ASV Virdžīnas',
  'countries.codes.VN': 'Vjetnama',
  'countries.codes.VU': 'Vanuatu',
  'countries.codes.WF': 'Volisa un Futuna',
  'countries.codes.WS': 'Samoa',
  'countries.codes.XK': 'Kosova',
  'countries.codes.YE': 'Jemena',
  'countries.codes.YT': 'Majota',
  'countries.codes.ZA': 'Dienvidāfrika',
  'countries.codes.ZM': 'Zambija',
  'countries.codes.ZW': 'Zimbabve',
  'countries.european-community': 'NEEDS TRANSLATION',
  'countries.rest-of-europe': 'NEEDS TRANSLATION',
  'countries.rest-of-world': 'NEEDS TRANSLATION',
  'createUser.title': 'NEEDS TRANSLATION',
  'dashboard.enter-driver-card-number.errors.invalid-number':
    'NEEDS TRANSLATION',
  'dashboard.lastForumPosts': 'NEEDS TRANSLATION',
  'dashboard.register-hours': 'NEEDS TRANSLATION',
  'dashboard.registerHours': 'NEEDS TRANSLATION',
  'document.files-for-document': 'NEEDS TRANSLATION',
  'documentation.documentation-for-control': 'NEEDS TRANSLATION',
  'documentation.drive-and-rest-time': 'NEEDS TRANSLATION',
  'documents.add-document': 'NEEDS TRANSLATION',
  'documents.continue-to-files': 'NEEDS TRANSLATION',
  'documents.documents-of-user': 'NEEDS TRANSLATION',
  'documents.edit-file': 'NEEDS TRANSLATION',
  'documents.expiration-date': 'NEEDS TRANSLATION',
  'documents.files-for-document': 'NEEDS TRANSLATION',
  'documents.name-on-document': 'NEEDS TRANSLATION',
  'documents.no-documents': 'NEEDS TRANSLATION',
  'documents.no-files': 'NEEDS TRANSLATION',
  'documents.notification-time': 'NEEDS TRANSLATION',
  'documents.notification-time-alt': 'NEEDS TRANSLATION',
  'documents.number-id': 'NEEDS TRANSLATION',
  'documents.open-file': 'NEEDS TRANSLATION',
  'documents.prompts.delete-document': 'NEEDS TRANSLATION',
  'documents.prompts.delete-document-confirmation': 'NEEDS TRANSLATION',
  'documents.prompts.delete-file': 'NEEDS TRANSLATION',
  'documents.prompts.delete-file-title': 'NEEDS TRANSLATION',
  'documents.responsible-user': 'NEEDS TRANSLATION',
  'documents.show-files': 'NEEDS TRANSLATION',
  'documents.upload-document': 'NEEDS TRANSLATION',
  'documents.upload-file': 'NEEDS TRANSLATION',
  'documents.view-file': 'NEEDS TRANSLATION',
  'error.activities-pause-duration': 'NEEDS TRANSLATION',
  'error.address-required': 'NEEDS TRANSLATION',
  'error.archive-type': 'NEEDS TRANSLATION',
  'error.categories': 'NEEDS TRANSLATION',
  'error.city-required': 'NEEDS TRANSLATION',
  'error.company-missing-tachograph-key':
    'Kompānijai trūkst Tachograph API atslēgas. Sazinieties, lūdzu, ar administratoru.',
  'error.contact-person-phone-required':
    'Nepieciešams kontaktpersonas tālruņa numurs',
  'error.contact-person-required': 'Nepieciešams kontaktpersonas vārds',
  'error.could-not-change-profile-information':
    'Neizdevās rediģēt konta informāciju',
  'error.could-not-check-in': 'Neizdevās reģistrēt iebraukšanu',
  'error.could-not-check-out': 'Neizdevās reģistrēt izbraukšanu',
  'error.could-not-create-type': 'Neizdevās izveidot {type}',
  'error.could-not-delete-type': 'Neizdevās dzēst {type}',
  'error.could-not-edit-type': 'Neizdevās rediģēt {type}',
  'error.could-not-find-data': 'Neizdevās atrast datu tabulu',
  'error.could-not-find-location': 'Neizdevās konstatēt lokaciju',
  'error.could-not-get-absence-types': 'Neizdevās atrast prombūtnes veidus',
  'error.could-not-read-file': 'Neizdevās atvērt failu',
  'error.create-issue': 'Neizdevās izveidot problēmu',
  'error.driver-card-expiration-date-not-found': 'NEEDS TRANSLATION',
  'error.driver-card-is-expired': 'NEEDS TRANSLATION',
  'error.drivercard-between-14-16': 'Laukā jābūt 14-16 simboliem',
  'error.end-time-too-early': 'Beigas laiks nevar būt agrāks par sākuma laiku',
  'error.expiration-date-not-found-add-expiration-date-manually':
    'NEEDS TRANSLATION',
  'error.field-can-not-be-empty': 'Lauks nevar būt tukšs',
  'error.field-is-required': 'Nepieciešams {field}',
  'error.files.could-not-read': 'Neizdevās atvērt failu',
  'error.filled-out-form-entry-does-not-exist':
    'Aizpildītās veidlapas ieraksts nepastāv.',
  'error.first-name-required': 'Nepieciešams vārds',
  'error.generic': 'Oops! Notikusi kļūda.',
  'error.generic-server-error':
    'Kaut kas nogāja greizi! Pamēģiniet, lūdzu, no jaunā.',
  'error.generic-title': 'NEEDS TRANSLATION',
  'error.internal-number-required': 'Nepieciešams iekšējais numurs!',
  'error.invalid-birth-number': 'Personas kods nav derīgs!',
  'error.invalid-file-type': 'Nederīgs faila formāts!',
  'error.invalid-phone-number': 'Nederīgs tālruņa numurs',
  'error.invalid-value': 'Nederīga vērtība',
  'error.last-name-required': 'Nepieciešams uzvārds',
  'error.missing-permission':
    'Jums trūkst nepieciešamo tiesību, lai veiktu šo darbību.',
  'error.name-exists': 'Ieraksts ar šādu nosaukumu jau eksistē',
  'error.need-at-least-one-user-type': 'Nepieciešams kaut viens lietotāja tips',
  'error.need-company-name': 'Kompānijas nosaukumam ir jābūt minētam',
  'error.no-first-name': 'Trūkst vārda',
  'error.not-permitted': 'Nav atļauts!',
  'error.not-permitted-to-view': 'Jums nav tiesību, lai skatītu šo.',
  'error.number-must-be-positive': 'Skaitlim ir jābūt pozitīvam',
  'error.phone-number-required': 'Nepieciešams tālruņa numurs',
  'error.postal-code-required': 'Nepieciešams pasta indekss',
  'error.try-again': 'NEEDS TRANSLATION',
  'error.type-already-exist': '{type} jau eksistē',
  'error.type-does-not-exist': '{type} neeksistē',
  'error.un-archive-type': 'Atkārtoti neizdevās atvērt {type}',
  'error.update-issue': 'Neizdevās atjaunot problēmu',
  'error.user-has-no-infringements-for-the-selected-period':
    'NEEDS TRANSLATION',
  'error.valid-email-required': 'Nepieciešams derīgs e-pasts',
  'error.wrong-password': 'Parole nav pareiza!',
  'error.wrong-username-or-password': 'Lietotājvārds vai parole nav pareiza!',
  'errors.common.name-already-exists': 'Vārds jau eksistē!',
  'errors.could-not-save-changes': 'Neizdevās saglabāt izmaiņas',
  'errors.defaults.does-not-exist': 'Objekts neeksistē',
  'errors.defaults.foreign-key-constraint':
    'Objekts pamatojas uz tā, kas neeksistē',
  'errors.defaults.invalid-value': 'Vērtība nav derīga',
  'errors.defaults.not-permitted': 'Jums nav tiesību to darīt.',
  'errors.defaults.unique-constraint': 'Objektam ir jābūt unikālam!',
  'errors.driver-card-already-taken': 'Vadītāja licence jau ir aizņemta!',
  'errors.driver-card-not-found-in-external-integration': 'NEEDS TRANSLATION',
  'errors.driver-card-not-found-similar-driver-card-already-taken':
    'Vadītāja licence nav atrasta. Līdzīga licence jau ir aizņemta.',
  'errors.generic-error': 'Oops! Notikusi kļūda.',
  'errors.must-be-whole-number': 'Skaitlim ir jābūt veselam!',
  'errors.passwords-not-equal': 'Paroles nav identiskās',
  'errors.users.could-not-change-password': 'Neizdevās rediģēt paroli',
  'exports.error-email-process': 'NEEDS TRANSLATION',
  'exports.error-user-salary-settings': 'NEEDS TRANSLATION',
  'exports.export-data': 'NEEDS TRANSLATION',
  'exports.month-export': 'NEEDS TRANSLATION',
  'exports.salary-export': 'NEEDS TRANSLATION',
  'exports.success-email-process': 'NEEDS TRANSLATION',
  'exports.success-user-exports': 'NEEDS TRANSLATION',
  'exports.user-exports': 'NEEDS TRANSLATION',
  'files.drop-to-upload': 'Velciet šeit, lai augšupielādētu!',
  'files.file-too-large':
    'Failam {name} izmērs pārsniedz maksimāli pieļaujamo {max}MB.',
  'files.upload-files-here': 'Augšupielādēt failus',
  'forgotten-password.forgotten': 'Aizmirsi paroli?',
  'forgotten-password.go-back': 'Atgriezties uz sākumlapu',
  'forgotten-password.message':
    'Ja esat aizmirsis paroli, ievadiet savu lietotājvārdu vai e-pastu. Ja mēs atradīsim Jūsu kontu, mēs nosūtīsim Jums vēstuli uz e-pastu.',
  'forgotten-password.success':
    'Ja Jūsu konts eksistē, Jūs saņemsiet vēstuli ar iespēju atjaunot paroli.',
  'forgotten-password.title': 'Aizmirsta parole',
  'form.add-field': 'Pievienot lauku',
  'form.all-responses-loaded': 'Visas atbildes ielādētas',
  'form.archive-form-error': 'Neizdevās arhivēt formu',
  'form.archive-form-success': 'Forma nosūtīta uz arhivu',
  'form.archive-prompt-text':
    'Vai esat pārliecināts, ka vēlaties arhivēt šo formu?',
  'form.archive-prompt-title': 'Arhivēt formu',
  'form.create-filled-form-success': 'Forma aizpildīta',
  'form.create-form': 'Izveidot jauno formu',
  'form.create-form-alt': 'Izveidot formu',
  'form.created-form-success': 'Jaunā forma veiksmīgi izveidota',
  'form.delete-checklist-template-prompt-text':
    'Vai esat pārliecināts, ka vēlaties dzēst šī kontrolsaraksta šablonu?',
  'form.delete-checklist-template-prompt-title':
    'Dzēst kontrolsaraksta šablonu',
  'form.delete-entry-prompt-text':
    'Vai esat pārliecināts, ka vēlaties dzēst šo aizpildīto formu?',
  'form.delete-entry-prompt-title': 'Dzēst formas ierakstu',
  'form.delete-field-prompt-text':
    'Vai esat pārliecināts, ka vēlaties dzēst šīs formas lauku?',
  'form.delete-field-prompt-title': 'Dzēst formas lauku',
  'form.delete-template-prompt-text':
    'Vai esat pārliecināts, ka vēlaties dzēst šo formu?',
  'form.delete-template-prompt-title': 'Dzēst formu',
  'form.edit-form': 'Rediģēt formu',
  'form.edit-this-form': 'Rediģēt šo formu',
  'form.errors.load-responses': 'Neizdevās ielādēt formas atbildes',
  'form.field': 'Lauks',
  'form.field-type-boolean': 'Jā/Nē',
  'form.field-type-date': 'Datums',
  'form.field-type-number': 'Numurs',
  'form.field-type-text': 'Teksts',
  'form.field-type-user': 'Lietotājs',
  'form.field-type-vehicle-department': 'NEEDS TRANSLATION',
  'form.fields': 'Lauki',
  'form.fill-out-this-form': 'Aizpildīt šo formu',
  'form.filled-out-n-times-by-user':
    '{n, plural, =0 {Jūs neesat nekad aizpildījis šo formu} one {Jūs esat aizpildījis šo formu vienu reizi} =2 {Jūs esat aizpildījis šo formu divas reizes} other {Jūs esat aizpildījis šo formu # reizes}}',
  'form.filled-out-n-times-by-user-including-today':
    '{n, plural, =0 {Jūs neesat nekad aizpildījis šo formu} one {Jūs esat aizpildījis šo formu vienu reizi, ieskaitot šodienu} =2 {Jūs esat aizpildījis šo formu divas reizes, ieskaitot šodienu} other {Jūs esat aizpildījis šo formu # reizes, ieskaitot šodienu}}',
  'form.form-entry': 'Forma',
  'form.form-entry-deleted': 'NEEDS TRANSLATION',
  'form.form-fields': 'Formas lauki',
  'form.form-label-name': 'Formas nosaukums',
  'form.form-settings': 'Veidlapas iestatījumi',
  'form.label-archive-date': 'Arhivēšanas datums',
  'form.label-archive-max-uses':
    'Maksimālais lietojumu skaits pirms arhivēšanas',
  'form.label-archive-mode': 'Arhivēšanas metode',
  'form.label-field-description': 'Apraksts',
  'form.label-field-input-type': 'Ievades veids',
  'form.label-field-name': 'Lauka nosaukums',
  'form.label-field-type': 'Lauka veids',
  'form.label-field-value': 'Teksts',
  'form.label-include-footer': 'Iespējot kājeni',
  'form.label-include-icon': 'Iespējot uzņēmuma ikonu',
  'form.label-ingress': 'Ieeja',
  'form.label-type': 'Formas tips',
  'form.last-response-at': 'Pēdējā atbilde',
  'form.load-all-responses': 'Ielādēt visas atbildes',
  'form.menu-shortcut': 'NEEDS TRANSLATION',
  'form.no-responses': 'Nav atbilžu',
  'form.open-overview': 'Atvērt pārskatu',
  'form.prompts.delete-form':
    'Vai esat pārliecināts, ka vēlaties dzēst šo formu? Visas atbildes būs likvidētas.',
  'form.prompts.delete-form-title': 'Dzēst formu',
  'form.prompts.unverify-response':
    'Vēlaties atcelt verifikaciju šai atbildei?',
  'form.prompts.unverify-response-title': 'Atcelt verifikāciju',
  'form.prompts.verify-response': 'Vai vēlaties verificēt šo atbildi?',
  'form.prompts.verify-response-title': 'Verificēt',
  'form.remove-field': 'Dzēst lauku',
  'form.responses-for-form': 'Atbildes: {name}',
  'form.see-answers': 'Skatīt atbildes',
  'form.select-form': 'Izvēlēties formu',
  'form.showing-num-of-total-responses':
    'Atspoguļots {num} no {total} atbildēm',
  'form.table-label-created': 'Izveidots',
  'form.table-label-edit': 'Rediģēt',
  'form.table-label-filled-in-by': 'Aizpildīts',
  'form.table-label-name': 'Nosaukums',
  'form.table-label-updated': 'Atjaunots (cikos?)',
  'form.table-label-use-form': 'Izmantot formu',
  'form.table-label-used': 'Atbildes',
  'form.toasts.create-form-success': 'Forma veiksmīgi izveidota',
  'form.toasts.delete-form-success': 'Forma veiksmīgi dzēsta',
  'form.toasts.patch-form-success': 'Forma veiksmīgi rediģēta',
  'form.toasts.submit-success': 'Forma iesniegta',
  'form.un-archive-prompt-text': 'Vai vēlaties atkārtoti atvērt formu',
  'form.un-archive-prompt-title': 'Atkārtoti atvērt formu',
  'form.update-form-success': 'Forma veiksmīgi atjaunota',
  'form.updated-form-success': 'Forma veiksmīgi atjaunota',
  'form.validation.cannot-be-negative': 'NEEDS TRANSLATION',
  'form.validation.required-fields-missing':
    'Nav aizpildīti visi pieprasītie lauki',
  'form.verified-by-at': 'Verificēts {name} (cikos?) {when}',
  'formcreator.distribution-field': 'NEEDS TRANSLATION',
  'formcreator.export-form-submissions': 'NEEDS TRANSLATION',
  'formcreator.filter-field': 'NEEDS TRANSLATION',
  'formcreator.summation-field': 'NEEDS TRANSLATION',
  'forum.add-comment': 'Atstāt komentāru',
  'forum.answers-number': '{num} atbilžu',
  'forum.archived-threads': 'Arhivētās tēmas',
  'forum.create-new-post': 'Izveidot jauno ierakstu',
  'forum.create-thread': 'Izveidot jauno tēmu',
  'forum.create-thread.send-email-error':
    'Notikusi kļūda, nosūtot vēstuli e-pastā',
  'forum.create-thread.send-sms-error': 'Notikusi kļūda, nosūtot SMS',
  'forum.create-thread.success': 'Tēma veiksmīgi izveidota',
  'forum.created': 'Izveidots',
  'forum.edit-post': 'Rediģēt ierakstu',
  'forum.edit-thread': 'Rediģēt tēmu',
  'forum.errors.content-required': 'Nepieciešams saturs',
  'forum.errors.create-thread': 'Neizdevās dzēst tēmu',
  'forum.errors.delete-post-failed': 'Neizdevās dzēst ierakstu',
  'forum.errors.delete-thread-failed': 'Neizdevās dzēst tēmu',
  'forum.errors.thread-does-not-exist': 'Tēma neeksistē',
  'forum.errors.title-required': 'Ierakstiet virsrakstu!',
  'forum.last-posts': 'Pēdējie ieraksti',
  'forum.last-threads': 'Pēdējās tēmas',
  'forum.new': 'Jaunā tēma',
  'forum.new-thread': 'Jaunā tēma',
  'forum.no-views': 'Nav skatītāju',
  'forum.number-of-posts': 'Ierakstu skaits',
  'forum.post':
    '{choice, select, definite {Ieraksts} plural {Ieraksti} other {Ieraksti}}',
  'forum.post-comment': 'Atstāt komentāru',
  'forum.posts-error-message': 'Notikusi kļūda, iegūstot ierakstus',
  'forum.prompts.delete-attachment':
    'Vai esat pārliecināts, ka vēlaties dzēst šo pielikumu?',
  'forum.prompts.delete-attachment-title': 'Dzēst pielikumu',
  'forum.prompts.delete-comment-prompt':
    'Vai esat pārliecināts, ka vēlaties dzēst šo komentāru?',
  'forum.prompts.delete-comment-prompt-title': 'Dzēst komentāru',
  'forum.prompts.delete-post':
    'Vai esat pārliecināts, ka vēlaties dzēst šo ierakstu?',
  'forum.prompts.delete-post-title': 'Dzēst ierakstu',
  'forum.prompts.delete-thread':
    'Vai esat pārliecināts, ka vēlaties dzēst tēmu?',
  'forum.prompts.delete-thread-text':
    'Vai esat pārliecināts, ka vēlaties dzēst tēmu {title}? Šī darbība nevar būt atcelta.',
  'forum.prompts.delete-thread-title': 'Dzēst tēmu',
  'forum.prompts.download-attachment-text':
    'Neizdodas atvērt šo pielikumu. Vai vēlaties to lejupielādēt?',
  'forum.prompts.download-attachment-title': 'Lejupielādēt pielikumu',
  'forum.quill-link-helper':
    'Atzīmējiet tekstu, kuru vēlaties pārvērst par saiti, un pēc tam noklikšķiniet uz saites pogu',
  'forum.read-by-n-employees':
    '{n, plural, =0 {Neviens darbinieks nav redzējis šo} one {Redzējis # darbinieks} other {Redzējuši # darbinieki}}',
  'forum.search': 'Meklēt forumā',
  'forum.seen-at': 'Skatīts',
  'forum.send-email-label': 'Nosūtīt paziņojumu e-pastā',
  'forum.send-sms-label': 'Nosūtīt SMS paziņojumu {employeeNum} darbiniekiem',
  'forum.sms-notification': 'SMS paziņojums',
  'forum.sticky-thread': 'Piesprausta tēma',
  'forum.sticky-threads': 'Piespraustas tēmas',
  'forum.thread':
    '{choice, select, definite {Tēma} plural {Tēmas} other {Tēma}}',
  'forum.threads-error-message': 'Notikusi kļūda, iegūstot tēmas',
  'forum.timesinceposted': 'Pirms (?) h',
  'forum.toasts.archive-thread-error': 'Neizdevās arhivēt tēmu',
  'forum.toasts.archive-thread-success': 'Tēma tika arhivēta',
  'forum.toasts.create-thread-success': 'Tēma tika izveidota',
  'forum.toasts.delete-thread-error': 'Neizdevās dzēst tēmu',
  'forum.toasts.delete-thread-success': 'Tēma tika dzēsta',
  'forum.toasts.patch-post-error': 'Notikusi kļūda, atjauninot ziņu',
  'forum.toasts.patch-post-success': 'Ziņa tika veiksmīgi atjaunota',
  'forum.toasts.patch-thread-error': 'Notikusi kļūda, atjauninot tēmu',
  'forum.toasts.patch-thread-success': 'Tēma veiksmīgi atjaunota',
  'forum.toasts.restore-thread-success': 'Tēma veiksmīgi atjaunota',
  'forum.unseen-only': 'Tikai neskatītās',
  'forum.update-comment': 'Atjaunināt komentāru',
  'forum.update-thread': 'Atjaunināt tēmu',
  'forum.validation.thread-must-have-content': 'Tēmai ir jābūt saturam',
  'forum.view-count-title': 'Tēma ir apskatīta',
  'forum.written-when': 'Rakstīts {when}',
  'handbook.add-entry': 'Pievienot ierakstu',
  'handbook.add-folder': 'Pievienot mapi',
  'handbook.add-sub-folder': 'Pievienot apakšmapi',
  'handbook.create-entry-success': 'Ieraksts izveidots',
  'handbook.create-folder-success': 'Mape izveidota',
  'handbook.create_entry': 'Izveidot jauno ierakstu',
  'handbook.dashboard-links': 'NEEDS TRANSLATION',
  'handbook.edit-folder': 'Rediģēt mapi',
  'handbook.entry-load-error': 'Notikusi kļūda, ielādējot ierakstu',
  'handbook.entry.delete-success': 'Ieraksts dzēsts',
  'handbook.errors.delete-entry': 'Neizdevās dzēst ierakstu',
  'handbook.errors.delete-folder': 'Neizdevās dzēst mapi',
  'handbook.folder-load-error': 'Notikusi kļūda, ielādējot mapi',
  'handbook.folder.delete-success': 'Mape dzēsta',
  'handbook.new-root-folder': 'Jauna saknes mape',
  'handbook.prompts.delete-entry':
    'Vai esat pārliecināts, ka vēlaties dzēst šo ierakstu?',
  'handbook.prompts.delete-entry-title': 'Dzēst ierakstu',
  'handbook.prompts.delete-folder':
    'Vai esat pārliecināts, ka vēlaties dzēst šo mapi? Viss saturs būs likvidēts.',
  'handbook.prompts.delete-folder-title': 'Dzēst mapi',
  'handbook.search': 'Meklēt vadītāja rokasgrāmatā',
  'handbook.start': 'Sākums',
  'handbook.toasts.create-entry-success':
    'Kataloga ieraksts "{name}" izveidots',
  'handbook.toasts.create-folder-success': 'Mape izveidota',
  'handbook.toasts.delete-entry-success': 'Ieraksts dzēsts',
  'handbook.toasts.delete-folder-success': 'Mape dzēsta',
  'handbook.toasts.patch-folder-success': 'Mape saglabāta',
  'handbook.update-entry-success': 'Ziņa atjaunināta',
  'handbook.update-folder-success': 'Mape atjaunināta',
  'infringements.amount-of-infringements-in-categories':
    'Pārkāpumu skaits kategorijās',
  'infringements.archive-infringement': 'Arhivēt pārkāpumu',
  'infringements.archived-basis': 'Arhivētie pamati',
  'infringements.archived-date': 'Arhivēšanas datums',
  'infringements.category-infringement': 'NEEDS TRANSLATION',
  'infringements.export-infringement': 'NEEDS TRANSLATION',
  'infringements.export-infringement-data': 'Eksportēt pārkāpumu datus',
  'infringements.export.all': 'NEEDS TRANSLATION',
  'infringements.legal-basis': 'Juridiskais pamats',
  'infringements.lower-limit': 'Apakšējā robeža',
  'infringements.minor-infringement': 'Neliels pārkāpums',
  'infringements.most-serious-infringement': 'Vinopietnākais pārkāpums',
  'infringements.my-infringements': 'Mani pārkāpumi',
  'infringements.n-points': 'NEEDS TRANSLATION',
  'infringements.no-infringements': 'Nav pārkāpumu',
  'infringements.restore-infringement': 'Atjaunot pārkāpumu',
  'infringements.send-email-success': 'Pārkāpumi nosūtīti uz e-pastu.',
  'infringements.serious-infringement': 'Nopietns pārkāpums',
  'infringements.severity': 'Smagums',
  'infringements.total-severity': 'Pilns smagums',
  'infringements.upper-limit': 'Augšējā robeža',
  'infringements.very-serious-infringement': 'Diezgan nopietns pārkāpums',
  'integrations.error-in-API-keys': 'NEEDS TRANSLATION',
  'integrations.error-in-FMS-key': 'NEEDS TRANSLATION',
  'integrations.error-in-tachograph-key': 'NEEDS TRANSLATION',
  'issue.category-responsible-user': 'Atbildīgs par problēmu kategoriju',
  'issue.existing-attachments': 'Esošie pielikumi',
  'issues.action-log': 'NEEDS TRANSLATION',
  'issues.actual-cost': 'Faktiskās izmaksas',
  'issues.add-recipient-with-email': 'Pievienot adresātu, izmantojot e-pastu',
  'issues.archive-issue': 'Arhivēt problēmu',
  'issues.archived-by-user': 'NEEDS TRANSLATION',
  'issues.attachments': 'Pielikumi',
  'issues.category-name': 'Kategorijas nosaukums',
  'issues.close-issue': 'Atrisināt problēmu',
  'issues.closed-at': 'Atrisināts',
  'issues.closed-by': 'Atrisināts',
  'issues.closed-by-user': 'NEEDS TRANSLATION',
  'issues.cost-estimation': 'Paredzamās izmaksas',
  'issues.create-issue': 'Izveidot problēmu',
  'issues.create-issue-for': 'Izveidot problēmu (kam?):',
  'issues.created-by-user': 'NEEDS TRANSLATION',
  'issues.damage-description': 'Kas tika bojāts?',
  'issues.damage-description-long':
    'Kas bija bojāts — Aizpildiet pilnīgi tiešos un netiešos objektus.',
  'issues.damage-description-short': 'Bojāti objekti',
  'issues.damage-direct-and-indirect-objects':
    'Aizpildiet pilnīgi tiešos un netiešos objektus',
  'issues.damage-location': 'Kur tas notika?',
  'issues.delete-issue': 'Dzēst problēmu',
  'issues.delete-issue-error-message':
    'Jūs mēģināt noņemt kategoriju, ar kuru joprojām ir saistītas problēmas. Tas ir aizliegts.',
  'issues.describe-the-damage': 'Aprakstiet bojājumu/bojāto objektu',
  'issues.description-of-event-unwanted-event': 'NEEDS TRANSLATION',
  'issues.edit-issue': 'Rediģēt problēmu',
  'issues.equipment-material': 'Aprīkojums/materiāls',
  'issues.error-retrieving-users': 'Notikusi kļūda, iegūstot lietotājus',
  'issues.errors.create-issue': 'Neizdevās izveidot problēmu',
  'issues.errors.must-enter-actual-cost': 'Nepieciešams ievadīt faktisko cenu',
  'issues.errors.must-enter-solution': 'Nepieciešams verdikts',
  'issues.errors.patch-issue': 'Neizdevās atjaunot problēmu',
  'issues.export-issue': 'NEEDS TRANSLATION',
  'issues.extend-issue-form': 'Vēlaties aizvērt problēmu tagad?',
  'issues.fill-in-actual-cost': 'Aizpildiet faktisko cenu',
  'issues.fill-in-solution': 'Aizpildiet verdiktu',
  'issues.fill-in-vehicle-reg-nr':
    'Aizpildiet attiecīgā transportlīdzekļa/piekabes reģistrācijas numuru',
  'issues.final-solution': 'Verdikts',
  'issues.find-issue': 'Atrast problēmu',
  'issues.give-estimated-cost': 'Sniedziet problēmas izmaksu tāmi',
  'issues.issue-categories': 'Problēmu kategorijas',
  'issues.issue-categories.errors.could-not-load-error':
    'Neizdevās ielādēt katerogijas',
  'issues.issue-categories.no-categories': 'Nav kategoriju',
  'issues.issue-category': 'Problēmas kategorija',
  'issues.issue-description-about': 'Par problēmu',
  'issues.issue-location': 'Problēmas atrašanās vieta',
  'issues.issue-report': 'Problēmas atskaite',
  'issues.location-description': 'Kurā vietā radās problēma?',
  'issues.new-issue': 'Jauna problēma',
  'issues.no-issues': 'Nav problēmu',
  'issues.opened-by': 'Atvērts',
  'issues.reference': '{abbreviate, select, true {Saite} other {Saite}}',
  'issues.reopen-issue': 'Atkārtoti atvērt problēmu',
  'issues.reopened-by-user': 'NEEDS TRANSLATION',
  'issues.responsible': 'Atbildīgais',
  'issues.restore-issue': 'Atjaunot problēmu',
  'issues.restored-by-user': 'NEEDS TRANSLATION',
  'issues.search-for-issue': 'Problēmas meklēšana',
  'issues.select-recipient-from-list': 'Atlasiet adresātu sarakstā',
  'issues.selected-recipients': 'Atlasītie adresāti',
  'issues.send-email-success': 'Ziņojums nosūtīts uz e-pastu.',
  'issues.solution-suggestion-about': 'Rīcības priekšlikumi',
  'issues.success-creation': 'Jauna problema izveidota',
  'issues.success-update': 'Problēma atjauntota',
  'issues.suggested-improvement': 'NEEDS TRANSLATION',
  'issues.suggested-solution': 'Ieteiktais risinājums',
  'issues.time-of-issue': 'Problēmas laiks',
  'issues.toasts.create-issue-success': 'Problēma tika veiksmīgi izveidota',
  'issues.toasts.patch-issue-success': 'Problēma tika veiksmīgi atjaunota',
  'issues.treated': 'Apstrādāts',
  'issues.untreated': 'Vēl nav apstrādāts',
  'issues.update-actual-cost': 'Atjaunināt faktisko cenu',
  'issues.updated-by-user': 'NEEDS TRANSLATION',
  'issues.validation.solution-required':
    'Lai aizvērtu šo problēmu, ir jārod risinājums',
  'issues.view-all-issues': 'Visas problēmas',
  'issues.view-your-issues': 'Jūsu problēmas',
  'login.email-or-phone-number': 'E-pasta adrese vai tālruņa numurs',
  'login.forgotten-password': 'Aizmirsi paroli?',
  'login.remember-me': 'Atcerēties mani',
  'login.subtitle': 'Laipni lūdzam',
  'modal.and-all-its-content': 'un ar visu saturu',
  'modal.delete-attachment': 'Vēlaties dzēst "{title}" no pielikuma saraksta?',
  'modal.delete-attachment-title': 'Dzēst pielikumu',
  'modal.delete-folder': 'Dzēst mapi',
  'modal.delete-folder-description':
    'Vēlaties dzēst mapi "{title}" ar tās aprakstu?',
  'modal.delete-folder-entry-description': 'Vēlaties dzēst ierakstu "{title}"?',
  'modal.delete-folder-entry-title': 'Dzēst ierakstu',
  'modal.delete-folder-title': 'Dzēst mapes virsrakstu',
  'modal.delete-type': 'Vai esat pārliecināts, ka vēlaties dzēst {type}?',
  'modal.delete-type-title': 'Dzēst {type}',
  'modal.remove-attachment': 'Vēlaties dzēst "{title}" no pielikuma saraksta?',
  'modal.remove-attachment-title': 'Dzēst pielikumu',
  'notifications-settings.issue_closed_notification':
    'Paziņojums par problēmas atrisināšanu',
  'notifications-settings.issue_created_notification':
    'Paziņojums par izveidoto problēmu',
  'notifications-settings.new_post_created_notification':
    'Jauns ieraksts izveidots',
  'notifications-settings.new_thread_created_notification':
    'Jauna tēma izveidota',
  'notifications-settings.title': 'Paziņojumu iestatījumi',
  'notifications.add-notifications': 'Pievienot paziņojumus?',
  'notifications.error.general': 'Notikusi kļūda, ielādējot paziņojumus',
  'notifications.go-to-absence': 'NEEDS TRANSLATION',
  'notifications.go-to-document': 'Skatīt dokumentu',
  'notifications.go-to-thread': 'Skatīt tēmu',
  'notifications.you-have-no-notifications': 'Šobrīd Jums nav paziņojumu',
  'password.must-contain-nonnumeric': 'NEEDS TRANSLATION',
  'password.must-contain-numeric-value': 'NEEDS TRANSLATION',
  'password.too-short': 'NEEDS TRANSLATION',
  'permissions.add': 'Izveidot',
  'permissions.approve': 'NEEDS TRANSLATION',
  'permissions.change': 'Rediģēt',
  'permissions.change_sticky': 'Rediģēt piesprausto',
  'permissions.company-settings-general': 'Visparīgs',
  'permissions.forum-send-email': 'Nosūtīt vēstuli e-pastā',
  'permissions.forum-send-sms': 'Nosūtīt SMS',
  'permissions.no-access-to-page': 'Jūsu lietotājam nav piekļuves šai lapai',
  'permissions.remove': 'Dzēst',
  'permissions.view': 'Skatīt',
  'permissions.view-and-change': 'Skatīt/Mainīt',
  'permissions.view-others': 'NEEDS TRANSLATION',
  'print.error-email-process': 'Neizdevās ģenerēt e-pasta izdruku',
  'print.export-type': 'Eksportēt {type}',
  'print.monthly-print': 'Ikmēneša izdruka',
  'print.salary-print': 'Algas lapiņa',
  'print.send-to-email': 'Nosūtīt vēstuli e-pastā',
  'print.success-email-process':
    'Dati tiek apstrādāti. Kad apstrāde būs pabeigta, Jūs saņemsit vēstuli e-pastā.',
  'quill.delete-column': 'Dzēst atlasīto kolonnu',
  'quill.delete-row': 'Dzēst atlasīto rindu',
  'quill.delete-table': 'Dzēst tabulu',
  'quill.insert-column-left': 'Ievietot kolonnu pa kreisi',
  'quill.insert-column-right': 'Ievietot kolonnu pa labi',
  'quill.insert-row-above': 'Ievietot rindu augstāk',
  'quill.insert-row-below': 'Ievietot rindu zemāk',
  'quill.new-table': 'Jauna tabula',
  'quill.table-tooltip':
    'Shift + ar peles labo pogu noklikšķiniet, lai mainītu tabulu.',
  'register-user.description':
    'Saistībā ar to, ka šī ir Jūsu pirmā reize šeit, Jums ir nepieciešams ievadīt pieprasītos datus.',
  'register-user.title': 'Aizpildiet savu konta informāciju',
  'reset-password.errors.bad-token': 'Slikts tokens.',
  'reset-password.errors.expired-token':
    'Atiestatīšanas tokena derīguma termiņš ir beidzies.',
  'reset-password.go-back': 'Atgriezties uz pieteikšanās lapu',
  'reset-password.message':
    'Izvēlieties jaunu paroli. Parolei ir jābūt vismaz 8 simbolu garai, un tajā nedrīkst būt tikai cipari.',
  'reset-password.submit': 'Atjaunot paroli un lietotājvārdu',
  'reset-password.success': 'Parole tika veiksmīgi atjaunota',
  'reset-password.title': 'Atjaunot paroli',
  'route-planner.add-new-route': 'NEEDS TRANSLATION',
  'route-planner.axle-weight-restrictions': 'NEEDS TRANSLATION',
  'route-planner.create-route-plan': 'NEEDS TRANSLATION',
  'route-planner.create-route-plan-success': 'NEEDS TRANSLATION',
  'route-planner.delete-route-plan-success': 'NEEDS TRANSLATION',
  'route-planner.destination': 'NEEDS TRANSLATION',
  'route-planner.estimated-driving-duration': 'NEEDS TRANSLATION',
  'route-planner.export-route-plan': 'NEEDS TRANSLATION',
  'route-planner.extra-load': 'NEEDS TRANSLATION',
  'route-planner.form.label.total-weight-in-kg': 'NEEDS TRANSLATION',
  'route-planner.form.label.total-weight-of-vehicle': 'NEEDS TRANSLATION',
  'route-planner.general-weight-restriction': 'NEEDS TRANSLATION',
  'route-planner.include-departure-time': 'NEEDS TRANSLATION',
  'route-planner.load': 'NEEDS TRANSLATION',
  'route-planner.loading-route': 'NEEDS TRANSLATION',
  'route-planner.no-route-instructions': 'NEEDS TRANSLATION',
  'route-planner.no-routes': 'NEEDS TRANSLATION',
  'route-planner.payload': 'NEEDS TRANSLATION',
  'route-planner.payload-in-kilos': 'NEEDS TRANSLATION',
  'route-planner.route-instructions': 'NEEDS TRANSLATION',
  'route-planner.route-plan': 'NEEDS TRANSLATION',
  'route-planner.route-plan-no-route-found': 'NEEDS TRANSLATION',
  'route-planner.route-plan-task-failed': 'NEEDS TRANSLATION',
  'route-planner.route-plan-task-pending': 'NEEDS TRANSLATION',
  'route-planner.route-restrictions': 'NEEDS TRANSLATION',
  'route-planner.single-axle': 'NEEDS TRANSLATION',
  'route-planner.start-location': 'NEEDS TRANSLATION',
  'route-planner.tandem-axle': 'NEEDS TRANSLATION',
  'route-planner.tonne': 'NEEDS TRANSLATION',
  'route-planner.top-speed': 'NEEDS TRANSLATION',
  'route-planner.top-speed-in-km': 'NEEDS TRANSLATION',
  'route-planner.total-weight': 'NEEDS TRANSLATION',
  'route-planner.trailer': 'NEEDS TRANSLATION',
  'route-planner.trailer-weight-in-kilos': 'NEEDS TRANSLATION',
  'route-planner.triple-axle': 'NEEDS TRANSLATION',
  'route-planner.weight-in-tonnes': 'NEEDS TRANSLATION',
  'select-user-settings.title': 'Lietotāja nolaižamās izvēlnes iestatījumi',
  'selectCompany.select': 'Izvēlieties uzņēmumu',
  'settings.absence-responsible': 'Atbildgais lietotājs saskaņā ar prombūtni',
  'settings.add-absence-type': 'Pievienot prombūtnes iemeslu',
  'settings.add-api-key': 'Pievienot API atslēgu',
  'settings.add-department': 'NEEDS TRANSLATION',
  'settings.add-holiday-allowance': 'NEEDS TRANSLATION',
  'settings.add-issue-category': 'Pievienot problēmas kategoriju',
  'settings.add-issue-risk-aspect': 'NEEDS TRANSLATION',
  'settings.add-new-terminal': 'Pievienot jaunu termināli',
  'settings.add-salary-code': 'NEEDS TRANSLATION',
  'settings.add-time-entry-project': 'NEEDS TRANSLATION',
  'settings.added-issue-category': 'Pievienota jauna problēmu kategorija',
  'settings.available-departments': 'NEEDS TRANSLATION',
  'settings.change-logo': 'Mainīt logotipu',
  'settings.change-module': 'NEEDS TRANSLATION',
  'settings.change-responsible-user': 'Mainīt atbildīgo lietotāju',
  'settings.company-logo': 'Uzņēmuma logotips',
  'settings.company-settings': 'Uzņēmuma iestatījumi',
  'settings.create-checklist-template': 'Izveidot kontrolsaraksta šablonu',
  'settings.create-fleet-board-integration': 'NEEDS TRANSLATION',
  'settings.create-issue-category': 'Izveidot problēmu kategoriju',
  'settings.create-man-integration': 'NEEDS TRANSLATION',
  'settings.create-name-first': 'Vispirms izveidojiet vārdu',
  'settings.create-salary-settings': 'NEEDS TRANSLATION',
  'settings.create-scania-integration': 'Izveidot Scania integrāciju',
  'settings.create-terminal-area': 'Izveidot termināļa zonu',
  'settings.create-user-document': 'Izveidot lietotāja dokumentu',
  'settings.create-user-type': 'Izveidot lietotāja veidu',
  'settings.create-volvo-integration': 'Izveidot Volvo integrāciju',
  'settings.daily-salary': 'NEEDS TRANSLATION',
  'settings.daily-work-limit': 'Dienas darba limits (stundās)',
  'settings.daily-work-limit-short': 'Ikdienas',
  'settings.dashboard-links-preview': 'NEEDS TRANSLATION',
  'settings.day-salary-code': 'NEEDS TRANSLATION',
  'settings.default-department': 'NEEDS TRANSLATION',
  'settings.default-salary-code': 'NEEDS TRANSLATION',
  'settings.delete-overtime-period': 'NEEDS TRANSLATION',
  'settings.delete-period-addon': 'NEEDS TRANSLATION',
  'settings.delete-user-type': 'Dzēst lietotāja veidu',
  'settings.do-you-want-to-delete-overtime-period': 'NEEDS TRANSLATION',
  'settings.do-you-want-to-delete-period-addon': 'NEEDS TRANSLATION',
  'settings.driverdata-print-for-all-drivers':
    'Izdrukāt visus braukšanas datus',
  'settings.edit-absence-responsible':
    'Rediģēt atbildīgo lietotāju saskaņā ar prombūtni',
  'settings.edit-absence-type': 'Rediģēt prombūtnes iemeslu',
  'settings.edit-checklist-template': 'Rediģēt kontrolsaraksta šablonu',
  'settings.edit-department': 'NEEDS TRANSLATION',
  'settings.edit-dynamic-column': 'Rediģēt kolonnas nosaukumu',
  'settings.edit-fleet-board-integration-n': 'NEEDS TRANSLATION',
  'settings.edit-holiday-allowance': 'NEEDS TRANSLATION',
  'settings.edit-infringements-responsible': 'NEEDS TRANSLATION',
  'settings.edit-issue-category': 'Rediģēt problēmas kategoriju',
  'settings.edit-issue-risk-aspect': 'NEEDS TRANSLATION',
  'settings.edit-man-integration-n': 'NEEDS TRANSLATION',
  'settings.edit-responsible-user': 'NEEDS TRANSLATION',
  'settings.edit-salary-and-work-time-for-type':
    'Rediģēt algas iestatījumus {type}',
  'settings.edit-salary-code': 'NEEDS TRANSLATION',
  'settings.edit-salary-settings-type': 'Rediģēt algas iestatījumus {module}',
  'settings.edit-scania-integration-n': 'Rediģēt Scania integrāciju #{n}',
  'settings.edit-terminal-area': 'Rediģēt termināļa zonu',
  'settings.edit-time-entry-project': 'NEEDS TRANSLATION',
  'settings.edit-type-settings': 'Rediģēt iestatījumus {type}',
  'settings.edit-user-document': 'Rediģēt lietotāja dokumentu',
  'settings.edit-volvo-integration-n': 'Rediģēt Volvo integrāciju #{n}',
  'settings.errors.change-language': 'Neizdevās izmainīt valodu',
  'settings.errors.company-settings-generic':
    'Neizdevās atjaunināt uzņēmuma iestatījumus',
  'settings.errors.create-absence-type':
    'Neizdevās izveidot prombūtnes iemeslu',
  'settings.errors.create-checklist-template-error':
    'Notikusi kļūda, veidojot kontrolsaraksta šablonu',
  'settings.errors.create-fleetboard-integration': 'NEEDS TRANSLATION',
  'settings.errors.create-issue-category':
    'Neizdevās izveidot problēmas kategoriju',
  'settings.errors.create-issue-risk-aspect': 'NEEDS TRANSLATION',
  'settings.errors.create-salary': 'NEEDS TRANSLATION',
  'settings.errors.create-scania-integration':
    'Neizdevās izveidot Scania integrāciju',
  'settings.errors.create-terminal': 'Neizdevās izveidot termināļa zonu',
  'settings.errors.create-time-entry-project': 'NEEDS TRANSLATION',
  'settings.errors.create-user-document':
    'Neizdevās izveidot lietotāja dokumentu',
  'settings.errors.create-user-document-category': 'NEEDS TRANSLATION',
  'settings.errors.create-user-type': 'Neizdevās izveidot lietotāja veidu',
  'settings.errors.create-volvo-integration':
    'Neizdevās izveidot Volvo integrāciju',
  'settings.errors.delete-absence-type': 'Nevar dzēst prombūtnes iemeslu',
  'settings.errors.delete-fleetboard-integration': 'NEEDS TRANSLATION',
  'settings.errors.delete-issue-category':
    'Neizdevās dzēst problēmas kategoriju',
  'settings.errors.delete-issue-risk-aspect': 'NEEDS TRANSLATION',
  'settings.errors.delete-salary': 'NEEDS TRANSLATION',
  'settings.errors.delete-scania-integration':
    'Neizdevās dzēst Scania integrāciju',
  'settings.errors.delete-terminal': 'Neizdevās dzēst termināļa zonu',
  'settings.errors.delete-time-entry-project': 'NEEDS TRANSLATION',
  'settings.errors.delete-user-document': 'Neizdevās dzēst lietotāja dokumentu',
  'settings.errors.delete-user-document-category': 'NEEDS TRANSLATION',
  'settings.errors.delete-user-type': 'Neizdevās dzēst lietotāja veidu',
  'settings.errors.delete-volvo-integration':
    'Neizdevās dzēst Volvo integrāciju',
  'settings.errors.password-change': 'Neizdevās atjaunot paroli',
  'settings.errors.patch-absence-type':
    'Neizdevās atjaunināt prombūtnes iemeslu',
  'settings.errors.patch-checklist-template-error':
    'Notikusi kļūda, atjauninot kontrolsaraksta šablonu',
  'settings.errors.patch-fleetboard-integration': 'NEEDS TRANSLATION',
  'settings.errors.patch-issue-category':
    'Neizdevās atjaunināt problēmas kategoriju',
  'settings.errors.patch-issue-risk-aspect': 'NEEDS TRANSLATION',
  'settings.errors.patch-salary': 'Neizdevās atjaunināt algas iestatījumus',
  'settings.errors.patch-scania-integration':
    'Neizdevās atjaunināt Scania integrāciju',
  'settings.errors.patch-terminal': 'Neizdevās atjaunināt termināļa zonu',
  'settings.errors.patch-time-entry-project': 'NEEDS TRANSLATION',
  'settings.errors.patch-user-document':
    'Neizdevās atjaunināt lietotāja dokumentu',
  'settings.errors.patch-user-document-category': 'NEEDS TRANSLATION',
  'settings.errors.patch-user-type': 'Neizdevās atjaunināt lietotāja veidu',
  'settings.errors.patch-volvo-integration':
    'Neizdevās atjaunināt Volvo integrāciju',
  'settings.errors.update-dropdowns':
    'Neizdevās atjaunināt nolaižamos sarakstus',
  'settings.errors.update-notifications':
    'Neizdevās atjaunināt paziņojumu iestatījumus',
  'settings.errors.update-user-type': 'Neizdevās atjaunināt lietotāja veidu',
  'settings.errors.user-type-cannot-be-deleted':
    'Šo lietotāja veidu nevar dzēst',
  'settings.errors.user-type-cannot-be-edited':
    'Šo lietotāja veidu nevar rediģēt',
  'settings.errors.user-type-cannot-be-edited-with-name':
    'Lietotāja veidu "{name}" nav iespējams rediģēt',
  'settings.export-all-absences-for-period': 'NEEDS TRANSLATION',
  'settings.export-all-driver-activities': 'NEEDS TRANSLATION',
  'settings.export-all-issues-for-period': 'NEEDS TRANSLATION',
  'settings.export-all-terminal-entries': 'NEEDS TRANSLATION',
  'settings.export-all-timesheets': 'NEEDS TRANSLATION',
  'settings.export-driverdata-for-user': 'NEEDS TRANSLATION',
  'settings.export-reports': 'Eksportēt atskaites',
  'settings.export-tables': 'NEEDS TRANSLATION',
  'settings.export-tables.preview': 'NEEDS TRANSLATION',
  'settings.external-id-in-salary-system': 'NEEDS TRANSLATION',
  'settings.fats.max-daily-work-limit': 'NEEDS TRANSLATION',
  'settings.fats.n-weeks': 'NEEDS TRANSLATION',
  'settings.fats.period-generated-for': 'NEEDS TRANSLATION',
  'settings.fats.period-length': 'NEEDS TRANSLATION',
  'settings.fats.start-date-not-monday': 'NEEDS TRANSLATION',
  'settings.fats.start-date-too-late-to-get-valid-interval':
    'NEEDS TRANSLATION',
  'settings.first-to-last-day-in-month':
    'Kopš mēneša pirmās līdz pēdējai dienai',
  'settings.fixed-salary': 'NEEDS TRANSLATION',
  'settings.fleet-board-key-n': 'NEEDS TRANSLATION',
  'settings.fms-access-key': 'FMS piekļuves atslēga',
  'settings.fms-secret-key': 'FMS slepenā atslēga',
  'settings.group-salary-on-vehicle': 'NEEDS TRANSLATION',
  'settings.has-notification': 'Paziņojums!',
  'settings.hourly-salary': 'NEEDS TRANSLATION',
  'settings.include-absences': 'NEEDS TRANSLATION',
  'settings.info.absence-types-1':
    'Šajā logā varat pievienot, rediģēt vai dzēst prombūtnes iemeslus.',
  'settings.info.absence-types-2':
    'Mēs iesakām ievērot piesardzību, rediģējot prombūtnes iemeslus, jo visi esošie šī prombūtnes veida ieraksti tiks mainīti.',
  'settings.info.checklists':
    'Šajā logā varat pievienot, rediģēt un dzēst kontrolsarakstu šablonus.',
  'settings.info.driver-data-1':
    'Šeit varat izdrukāt visus pieejamos braukšanas aktivitāšu datus par izvēlēto mēneša periodu.',
  'settings.info.driver-data-2':
    'Ir pieejamas divas datu kopas. Stundas - visiem vadītājiem drukā tās pašas vērtības, kuras saņemat braukšanas datu modulī, vai Diēta - izdrukā sākuma un beigu laiku un vietu, bet ne aprēķināto darba periodu.',
  'settings.info.dropdowns':
    'Šis logs ļauj atlasīt, kuri lietotāju veidi būs pieejami atlasei lietotāju nolaižamajos sarakstos noteiktās lapās. Šajās nolaižamajās izvēlnēs tiks parādīti visi lietotāji, kuriem ir atlasīts tālāk norādītais lietotāja veids.',
  'settings.info.exports-1': 'NEEDS TRANSLATION',
  'settings.info.fats-1': 'NEEDS TRANSLATION',
  'settings.info.general':
    'Šajā logā varat konfigurēt ar uzņēmumu saistītos pamata iestatījumus un augšupielādēt uzņēmuma logotipu.',
  'settings.info.handbook-1': 'NEEDS TRANSLATION',
  'settings.info.infringements-data-1': 'NEEDS TRANSLATION',
  'settings.info.integrations':
    'Šajā logā varat pievienot vai rediģēt integrācijas atslēgas.',
  'settings.info.issue-categories-1':
    'Šajā logā varat pievienot, rediģēt vai dzēst problēmu veidlapās izmantotās problēmu kategorijas.',
  'settings.info.issue-categories-2':
    'Atbildīgais lietotājs ir persona, kurai ir vislielākā atbildība par šīs kategorijas jautājumiem, un parasti tiek informēts par jebkādām izmaiņām saistībā ar šīs kategorijas jautājumiem. Atbildīgajiem lietotājiem nolaižamajā sarakstā ir jābūt tādam lietotājam, kuram šis skats ir piešķirts.',
  'settings.info.issue-categories-uneditable':
    'Rediģēts var būt tikai atbildīgais lietotājs.',
  'settings.info.notifications':
    'Šajā logā varat izvēlēties, paziņojumu veidu attiecīgajiem lietotājiem.',
  'settings.info.route-planner': 'NEEDS TRANSLATION',
  'settings.info.salary': 'Šajā logā varat rediģēt algas iestatījumus.',
  'settings.info.table': 'Šajā lapā varat pielāgot uzņēmuma tabulu izskatu.',
  'settings.info.terminal-1':
    'Šajā vietnē jūs iestatāt, kuri tīkli ir uzskatāmi par derīgiem termināļu tīkliem.',
  'settings.info.terminal-2':
    'IP adrese izmanto ipv4 formātu, un IP filtra diapazons ir apakštīkla diapazons, kas, ja iestatīts uz 24, nozīmē, ka visi savienojumi IP tīklā tiek uzskatīti par derīgiem. Ja vēlaties, varat mainīt apakštīklu, lai nodrošinātu precīzāku filtrēšanu.',
  'settings.info.time-entries-1': 'NEEDS TRANSLATION',
  'settings.info.user-documents-1':
    'Šajā logā varat rediģēt un dzēst lietotāja dokumentus.',
  'settings.info.user-documents-2':
    'Dokumenta atbildīgais ir lietotājs, kuram tiek paziņots, ka dokumenta derīguma termiņš ir beidzies. Dokumenta derīguma termiņš beidzas tā mēneša pirmajā dienā, kas izvēlēts kā derīguma termiņš. Dažas nedēļas pirms paziņojuma laika laukā norādītā derīguma termiņa beigām atbildīgajam lietotājam tiks nosūtīts paziņojums.',
  'settings.info.user-types-1':
    'Šajā lapā varat mainīt lietotāju veidus un atļaujas.',
  'settings.info.user-types-2':
    'Lūdzu, ņemiet vērā, ka visas izmaiņas sadaļā "Rediģēt" un "Dzēst" piešķir piekļuvi citu izveidoto resursu modificēšanai un dzēšanai. Ikvienam, kam ir atļauja izveidot resursu, ir arī piešķirta piekļuve, lai mainītu savus resursus.',
  'settings.info.vehicles': 'NEEDS TRANSLATION',
  'settings.infringements-responsible': 'NEEDS TRANSLATION',
  'settings.integration': 'Integrācija',
  'settings.integrations': 'Integrācijas iestatījumi',
  'settings.integrations-description':
    'Šajā logā varat pievienot vai rediģēt integrācijas atslēgas.',
  'settings.is-administrator': 'Administrators',
  'settings.is-administrator-description':
    'Administratora kontiem būs visas piekļuves tiesības neatkarīgi no šīs tabulas.',
  'settings.last-day-of-month': 'Mēneša pēdējā diena',
  'settings.man-key-n': 'NEEDS TRANSLATION',
  'settings.module-options': 'NEEDS TRANSLATION',
  'settings.my-settings': 'Mani iestatījumi',
  'settings.n-departments': 'NEEDS TRANSLATION',
  'settings.no-checklist-templates': 'Nav kontrolsarakstu šablonu.',
  'settings.no-integration-keys': 'Integrācijas atslēgas nav atrastas',
  'settings.no-selected-absence-responsible':
    'Neviens lietotājs nav izvēlēts par atbildīgo saskaņā ar prombūtni',
  'settings.no-selected-infringements-responsible': 'NEEDS TRANSLATION',
  'settings.notifications.notify-driver-on-infringement': 'NEEDS TRANSLATION',
  'settings.notifications.setting.absence-created': 'NEEDS TRANSLATION',
  'settings.notifications.setting.driver-card':
    'Vadītāja kartes derīguma termiņš',
  'settings.notifications.setting.forum-post': 'Jauns komentārs forumā',
  'settings.notifications.setting.forum-thread': 'Jauna tēma forumā',
  'settings.notifications.setting.issue-closed': 'Problēma atrisināta',
  'settings.notifications.setting.issue-created': 'Problēma izveidota',
  'settings.notifications.setting.reset-password': 'Paroles atjaunošana',
  'settings.notifications.setting.user-document': 'Atbildīgais lietotājs',
  'settings.notifications.setting.user-export-ready': 'NEEDS TRANSLATION',
  'settings.notifications.setting.user-registration': 'Lietotāja reģistrācija',
  'settings.notifications.vehicle-document': 'NEEDS TRANSLATION',
  'settings.notifications.vehicle-expirations': 'NEEDS TRANSLATION',
  'settings.ovetime-periods': 'NEEDS TRANSLATION',
  'settings.paid-pause-threshold': 'NEEDS TRANSLATION',
  'settings.pause-duration': 'Pauzes ilgums',
  'settings.pause-duration-short': 'Pauze',
  'settings.pause-threshold': 'Darba limits pauzei',
  'settings.pause-threshold-short': 'Darbs pirms pārtraukuma',
  'settings.period': 'NEEDS TRANSLATION',
  'settings.period-work-limit': 'Nedēļas darba limits (stundās)',
  'settings.period-work-limit-short': 'Iknedēļas',
  'settings.prompts.absence-type-create':
    'Jaunā prombūtnes iemesla nosaukums: ',
  'settings.prompts.absence-type-delete':
    'Vai esat pārliecināts, ka vēlaties dzēst prombūtnes iemeslu "{name}"?',
  'settings.prompts.absence-type-delete-title': 'Dzēst prombūtnes iemeslu',
  'settings.prompts.create-user-type':
    'Kādam ir jābūt jaunā lietotāja veida nosaukumam?',
  'settings.prompts.delete': 'NEEDS TRANSLATION',
  'settings.prompts.delete-checklist-template': 'Dzēst kontrolsaraksta šablonu',
  'settings.prompts.delete-checklist-template-description':
    'Vai vēlaties dzēst kontrolsaraksta šablonu?',
  'settings.prompts.delete-time-entry-project': 'NEEDS TRANSLATION',
  'settings.prompts.department-delete-title': 'NEEDS TRANSLATION',
  'settings.prompts.department-setting-delete-title': 'NEEDS TRANSLATION',
  'settings.prompts.holiday-allowance-delete-title': 'NEEDS TRANSLATION',
  'settings.prompts.integration-delete-title': 'Dzēst integrāciju',
  'settings.prompts.integration-fleet-board-delete': 'NEEDS TRANSLATION',
  'settings.prompts.integration-man-delete': 'NEEDS TRANSLATION',
  'settings.prompts.integration-scania-delete':
    'Vai esat pārliecināts, ka vēlaties dzēst Scania integrāciju #{n}?',
  'settings.prompts.integration-volvo-delete':
    'Vai esat pārliecināts, ka vēlaties dzēst Volvo integrāciju #{n}?',
  'settings.prompts.issue-category-delete':
    'Vai esat pārliecināts, ka vēlaties dzēst problēmu kategoriju "{name}"?',
  'settings.prompts.issue-category-delete-title': 'Dzēst problēmu kategoriju',
  'settings.prompts.issue-risk-aspect-delete': 'NEEDS TRANSLATION',
  'settings.prompts.issue-risk-aspect-delete-title': 'NEEDS TRANSLATION',
  'settings.prompts.salary-code-delete-title': 'NEEDS TRANSLATION',
  'settings.prompts.salary-setting-delete-title': 'NEEDS TRANSLATION',
  'settings.prompts.terminal-delete':
    'Vai esat pārliecināts, ka vēlaties dzēst termināļa zonu "{name}"?',
  'settings.prompts.terminal-delete-title': 'Dzēst termināļa zonu',
  'settings.prompts.time-entry-project-delete-title': 'NEEDS TRANSLATION',
  'settings.prompts.user-document-delete':
    'Vai esat pārliecināts, ka vēlaties dzēst lietotāja dokumentu "{name}"?',
  'settings.prompts.user-document-delete-title': 'Dzēst lietotāja dokumentu',
  'settings.prompts.user-type-delete':
    'Vai esat pārliecināts, ka vēlaties dzēst lietotāja veidu?',
  'settings.prompts.user-type-delete-title': 'Dzēst lietotāja veidu',
  'settings.reciept-functionality': 'NEEDS TRANSLATION',
  'settings.refresh-colors': 'Atjaunot krāsas',
  'settings.responsible-user': 'Atbildīgais lietotājs',
  'settings.salary-and-work-time': 'Algas iestatījumi',
  'settings.salary-and-work-time-type': 'Alga',
  'settings.salary-code': 'NEEDS TRANSLATION',
  'settings.salary-code-day': 'NEEDS TRANSLATION',
  'settings.salary-code-id-field-placeholder': 'NEEDS TRANSLATION',
  'settings.salary-day-other-salary-code-warning': 'NEEDS TRANSLATION',
  'settings.salary-end-date': 'Algas beigu datums',
  'settings.salary-period': 'Apmaksas periods',
  'settings.salary-start-date': 'Algas sākuma datums',
  'settings.salary-type': 'NEEDS TRANSLATION',
  'settings.save-changes': 'Saglabāt izmaiņas',
  'settings.save-settings': 'Saglabāt iestatījumus',
  'settings.scania-key': 'Scania atslēga',
  'settings.scania-key-n': 'Scania atslēga #{n}',
  'settings.select-api-key-to-add': 'Izvēlieties API atslēgu pievienošanai',
  'settings.select-categories': 'NEEDS TRANSLATION',
  'settings.select-category': 'NEEDS TRANSLATION',
  'settings.select-crontab-setting': 'Izvēlieties ģenerēšanas frekvenci',
  'settings.select-module': 'NEEDS TRANSLATION',
  'settings.show-column': 'Rādīt kolonnu',
  'settings.show-dynamic-column-description':
    'Ja kolonna ir atspējota, tā tiek paslēpta tabulā un eksportēta no moduļa.',
  'settings.show-dynamic-column-error': 'Vismaz divām kolonnām jābūt aktīvām.',
  'settings.show-in-manual-time-entry-overtime': 'NEEDS TRANSLATION',
  'settings.standard-settings': 'NEEDS TRANSLATION',
  'settings.static-categories': 'Statiskās kategorijas',
  'settings.tables': 'Tabulas iestatījumi',
  'settings.tables.add-column-description': 'NEEDS TRANSLATION',
  'settings.tables.add-export-column-description': 'NEEDS TRANSLATION',
  'settings.tables.add-placeholder': 'Izvelieties kolonnu pievienošanai',
  'settings.tables.preview': 'Tabulas priekšskatījums',
  'settings.tachograph-key': 'Tahogrāfa atslēga',
  'settings.terminal-description-1':
    'Šajā vietnē jūs iestatāt, kuri tīkli ir uzskatāmi par derīgiem termināļu tīkliem.',
  'settings.terminal-description-2':
    'IP adrese izmanto ipv4 formātu, un IP filtra diapazons ir apakštīkla diapazons, kas, ja iestatīts uz 24, nozīmē, ka visi savienojumi IP tīklā tiek uzskatīti par derīgiem. Ja vēlaties, varat mainīt apakštīklu, lai nodrošinātu precīzāku filtrēšanu.',
  'settings.time-entry-projects': 'NEEDS TRANSLATION',
  'settings.timesheets.alternative-overtime-option': 'NEEDS TRANSLATION',
  'settings.timesheets.count-pause-as-part-of-total-work': 'NEEDS TRANSLATION',
  'settings.timesheets.department-mandatory': 'NEEDS TRANSLATION',
  'settings.timesheets.merge-timesheets-with-activities': 'NEEDS TRANSLATION',
  'settings.timesheets.periodic-overtime': 'NEEDS TRANSLATION',
  'settings.timesheets.projects': 'NEEDS TRANSLATION',
  'settings.toast.create-man-integration-error': 'NEEDS TRANSLATION',
  'settings.toast.create-man-integration-success': 'NEEDS TRANSLATION',
  'settings.toast.delete-man-integration-error': 'NEEDS TRANSLATION',
  'settings.toast.delete-man-integration-success': 'NEEDS TRANSLATION',
  'settings.toast.patch-man-integration-error': 'NEEDS TRANSLATION',
  'settings.toast.patch-man-integration-success': 'NEEDS TRANSLATION',
  'settings.toasts.add-column-success': 'Kolonna ir pievienota',
  'settings.toasts.change-language-success': 'Valoda ir atjaunināta',
  'settings.toasts.company-settings-updated':
    'Uzņēmuma iestatījumi ir atjaunināti',
  'settings.toasts.create-absence-type-success':
    'Prombūtnes iemesls "{name}" izveidots',
  'settings.toasts.create-checklist-template-success':
    'kontrolsaraksta šablons izveidots',
  'settings.toasts.create-fleetboard-integration-success': 'NEEDS TRANSLATION',
  'settings.toasts.create-issue-category-success':
    'Problēmu kategorija "{name}" izveidota',
  'settings.toasts.create-issue-risk-aspect-success': 'NEEDS TRANSLATION',
  'settings.toasts.create-salary-success': 'NEEDS TRANSLATION',
  'settings.toasts.create-scania-integration-success':
    'Scania integrācija izveidota',
  'settings.toasts.create-terminal-success':
    'Termināļa zona "{name}" izveidota',
  'settings.toasts.create-time-entry-project-success': 'NEEDS TRANSLATION',
  'settings.toasts.create-user-document-category-success': 'NEEDS TRANSLATION',
  'settings.toasts.create-user-document-success':
    'Lietotāja dokuments izveidots',
  'settings.toasts.create-user-type-success':
    'Lietotāja veids "{name}" izveidots',
  'settings.toasts.create-volvo-integration-success':
    'Volvo integrācija izveidota',
  'settings.toasts.delete-absence-type-success': 'Prombūtnes iemesls ir dzēsts',
  'settings.toasts.delete-fleetboard-integration-success': 'NEEDS TRANSLATION',
  'settings.toasts.delete-issue-category-success':
    'Problēmu kategorija ir dzēsta',
  'settings.toasts.delete-issue-risk-aspect-success': 'NEEDS TRANSLATION',
  'settings.toasts.delete-salary-success': 'NEEDS TRANSLATION',
  'settings.toasts.delete-scania-integration-success':
    'Scania integrācija dzēsta',
  'settings.toasts.delete-terminal-success': 'Termināļa zona ir dzēsta',
  'settings.toasts.delete-time-entry-project-success': 'NEEDS TRANSLATION',
  'settings.toasts.delete-user-document-category-success': 'NEEDS TRANSLATION',
  'settings.toasts.delete-user-document-success': 'Lietotāja dokuments dzēsts',
  'settings.toasts.delete-user-type-success': 'Lietotāja veids ir dzēsts',
  'settings.toasts.delete-volvo-integration-success':
    'Volvo integrācija dzēsta',
  'settings.toasts.password-change-success':
    'Jūsu parole tika veiksmīgi atjaunota',
  'settings.toasts.patch-absence-type-success':
    'Prombūtnes iemesls atjaunināts',
  'settings.toasts.patch-checklist-template-success':
    'Kontrolsaraksta šablons atjaunināts',
  'settings.toasts.patch-fleetboard-integration-success': 'NEEDS TRANSLATION',
  'settings.toasts.patch-issue-category-success':
    'Problēmu kategorija ir atjaunināta',
  'settings.toasts.patch-issue-risk-aspect-success': 'NEEDS TRANSLATION',
  'settings.toasts.patch-salary-success': 'Algas iestatījumi atjaunināti',
  'settings.toasts.patch-scania-integration-success':
    'Scania integrācija atjaunināta',
  'settings.toasts.patch-terminal-success': 'Termināļa zona ir atjaunināta',
  'settings.toasts.patch-time-entry-project-success': 'NEEDS TRANSLATION',
  'settings.toasts.patch-user-document-category-success': 'NEEDS TRANSLATION',
  'settings.toasts.patch-user-document-success':
    'Lietotāja dokuments atjaunināts',
  'settings.toasts.patch-user-type-success': 'Lietotāja veids atjaunināts',
  'settings.toasts.patch-volvo-integration-success':
    'Volvo integrācija atjaunināta',
  'settings.toasts.remove-column-success': 'Kolonna dzēsta',
  'settings.toasts.update-dropdowns-success':
    'Nolaižamās izvēlnes tika veiksmīgi atjaunotas',
  'settings.toasts.update-notifications-success':
    'Mārketinga materiālu iestatījumi tika veiksmīgi atjaunoti ',
  'settings.toasts.update-user-type-success': 'Lietotāja veids atjaunināts',
  'settings.tv-view': 'Rādīt televīzorā',
  'settings.type-of-work': 'NEEDS TRANSLATION',
  'settings.updated-type': 'Atjaunots {type}',
  'settings.upload': 'Augšpielādēt',
  'settings.upload-type': 'Augšpielādēt {type}',
  'settings.use-as-site-logo': 'Izmantot kā logotipu',
  'settings.use-period-addons': 'NEEDS TRANSLATION',
  'settings.use-period-overtime': 'NEEDS TRANSLATION',
  'settings.use-salary-day-payment': 'NEEDS TRANSLATION',
  'settings.use-week-overtime': 'NEEDS TRANSLATION',
  'settings.user-documents-description-title': 'Dokumenta iestatījumi',
  'settings.user-type.create-user-type': 'Izveidot jaunu lietotāja veidu',
  'settings.user-types-and-permissions': 'Lietotāju veidi un pieejas atļaujas',
  'settings.valid-salary-dates':
    'Sākuma datumam ir jābūt vienu dienu pēc beigu datuma.',
  'settings.validation.absence-type-name-exists':
    'Šāda iemesla prombūtne jau eksistē',
  'settings.validation.already-holiday-allowance-on-day': 'NEEDS TRANSLATION',
  'settings.validation.company.logo-file-size':
    'Logotipa faila lielums nedrīkst pārsniegt 128 MB.',
  'settings.validation.company.must-have-name':
    'Kompānijai ir jābūt nosaukumam',
  'settings.validation.ip-address': 'Nepareizs formāts',
  'settings.validation.issue-category-name-exists':
    'Problēmu kategorija ar šādu nosaukumu jau eksistē',
  'settings.validation.issue-risk-aspect-name-exists': 'NEEDS TRANSLATION',
  'settings.validation.last-admin-user-type-delete':
    'Šis ir vienīgais lietotāju veids ar administratora privilēģijām. Dzēst nav iespējams.',
  'settings.validation.last-admin-user-type-toggle':
    'Šis ir vienīgais lietotāju veids ar administratora privilēģijām. Atspējot nav iespējams.',
  'settings.validation.salary-driver-pause-length': 'Jābūt vismaz 45 minūtēm!',
  'settings.validation.salary-priority-unique': 'NEEDS TRANSLATION',
  'settings.validation.terminal-range': 'Jābūt no 1 līdz 32!',
  'settings.validation.time-entry-project-name-exists': 'NEEDS TRANSLATION',
  'settings.validation.user-document-name-exists': 'NEEDS TRANSLATION',
  'settings.validation.user-documents.must-have-name': 'Jābūt nosaukumam!',
  'settings.validation.user-documents.must-set-responsible-user':
    'Nepieciešams iestatīt atbildīgo lietotāju',
  'settings.validation.user-type-name-exists':
    'Lietotāja veids ar tādu pašu nosaukumu jau eksistē',
  'settings.validation.user-type-name-exists-short': 'Nosaukums jau eksistē',
  'settings.volvo-key': 'Volvo atslēga',
  'settings.volvo-key-n': 'Volvo atslēga #{n}',
  'settings.week-overtime-delayed-overtime-warning': 'NEEDS TRANSLATION',
  'settings.week-salary-code': 'NEEDS TRANSLATION',
  'statistics.average-driver-grade': 'Vidējais vadītāja novērtējums',
  'statistics.average-fuel-consumption': 'Vidējais degvielas patēriņš',
  'statistics.driver-grade': 'Vadītāja novērtējums',
  'statistics.kilometers-driven': 'Nobraukums kilometros',
  'statistics.l-10-km': 'l/10 km',
  'statistics.l-100-km': 'l/100 km',
  'statistics.monthly-company-statistics': 'Uzņēmuma ikmēneša statistika',
  'statistics.monthly-driver-statistics': 'Jūsu ikmēneša statistika',
  'statistics.number-of-issues': 'Problēmu skaits',
  'table.week-sum': 'Nedēļas summa',
  'terminal.check-in': 'Iebraukšanas reģistrācija',
  'terminal.check-out': 'Izbraukšanas reģistrācija',
  'terminal.checked-in': 'Iebraukšana reģistrēta',
  'terminal.checked-in-at': 'Iebraukšana reģistrēta (cikos?) {time}',
  'terminal.checked-in-message':
    'Jūs esat reģistrējies {checkIn} un strādājis {total} stundas.',
  'terminal.checked-out': 'Izbraukšana reģistrēta',
  'terminal.checked-out-at': 'Izbraukšana reģistrēta (cikos?) {time}',
  'terminal.edit-terminal-entry': 'Rediģēt termināļa ierakstu',
  'terminal.error.delete-terminal-entry': 'Neizdevās dzēst termināļa ierakstu',
  'terminal.error.edit-terminal-entry': 'Neizdevās rediģēt termināļa ierakstu',
  'terminal.ip-address': 'IP-adrese',
  'terminal.ip-filter-range': 'IP filtra diapazons',
  'terminal.monthly-print': 'NEEDS TRANSLATION',
  'terminal.no-activity-period':
    'Jums nav reģistrētas aktivitātes par šo periodu',
  'terminal.no-activity-today': 'Jums nav reģistrētas aktivitātes šodien',
  'terminal.not-on-network':
    'Lai iebrauktu vai izbrauktu, Jums ir jābūt termināļa tīklā',
  'terminal.old-checked-in-message': 'Jūs piereģistrējāties {checkIn}',
  'terminal.prompt.delete-terminal-entry':
    'Vai vēlaties dzēst šo termināļa ierakstu?',
  'terminal.prompt.delete-terminal-entry-title': 'Dzēst termināļa ierakstu?',
  'terminal.salary-basis': 'Algas bāze',
  'terminal.success.delete-terminal-entry':
    'Termināļa ieraksts veiksmīgi dzēsts',
  'terminal.success.edit-terminal-entry':
    'Termināļa ieraksts veiksmīgi atjaunots',
  'terminal.terminal-areas': 'Termināļa zonas',
  'terminal.terminal-entries': 'Termināļa ieraksti',
  'terminal.terminal-entry': 'Termināļa ieraksts',
  'terminal.terminal-name': 'Termināļa nosaukums',
  'terminal.time': 'Laiks',
  'terminal.user-activity': 'Aktivitāte (kam?) {user}',
  'terminal.user-no-activity-period':
    '{user} - lietotājam nav reģistrētas aktivitātes atlasītajā periodā',
  'terminal.your-activity': 'Jūsu aktivitāte',
  'terminal.your-activity-today': 'Jūsu šodienas aktivitāte',
  'thread.success-creation': 'Izveidota jauna tēma',
  'timesheets.absence-approved': 'Pieņemts',
  'timesheets.absence-create-type': 'Создать новый тип отсутствия',
  'timesheets.absence-pending': 'Procesā',
  'timesheets.absence-rejected': 'Atteikts',
  'timesheets.absence-type': 'Prombūtnes iemesls',
  'timesheets.absence-type-name': 'Prombūtnes iemeslu saraksts',
  'timesheets.absence-types': 'Prombūtnes iemesli',
  'timesheets.absence-types-error-1':
    'Viena darbinieka prombūtnes nevar pieskaitīt vairāk kā vienu reizi dienā',
  'timesheets.delete-absence': 'Dzēst prombūtni',
  'timesheets.delete-time-entry': 'Dzēst laika ierakstu',
  'timesheets.edit-absence': 'Rediģēt prombūtni',
  'timesheets.edit-absence-status': 'Rediģēt prombūtnes statusu',
  'timesheets.edit-time-entry': 'Rediģēt laika ierakstu',
  'timesheets.error-create-absence': 'Neizdevās pievienot prombūtni',
  'timesheets.error-create-timeentry':
    'Laika ieraksts ar tādu pašu datumu un laiku jau eksistē',
  'timesheets.error-registration-absence':
    'Reģistrējot jauno prombūtni, radās kļūda',
  'timesheets.error-update-absence': 'Prombūtnes atjaunināšanas kļūda',
  'timesheets.error-update-timeentry': 'Laika ieraksta atjaunināšanas kļūda',
  'timesheets.error.delete-absence': 'Neizdevās izdzēst prombūtni',
  'timesheets.error.delete-time-entry': 'Neizdevās izdzēst laika ierakstu',
  'timesheets.error.overtime-mode-required': 'NEEDS TRANSLATION',
  'timesheets.error.patch-absence': 'Neizdevās atjaunināt laika ierakstu',
  'timesheets.error.patch-time-entry': 'Не удалось обновить запись времени',
  'timesheets.error.pause-required': 'NEEDS TRANSLATION',
  'timesheets.error.start-before-end':
    'Sākuma laikam ir jābūt pirms beigu laika',
  'timesheets.export-timesheet': 'NEEDS TRANSLATION',
  'timesheets.new-absence-request': 'Jaunais prombūtnes pieprasījums',
  'timesheets.new-time-entry': 'Jaunais laika ieraksts',
  'timesheets.note': 'Komentārs par prombūtni',
  'timesheets.overtime-alt': 'Virsstundas',
  'timesheets.print-timesheet': 'Printēt grafiku',
  'timesheets.prompt.delete-absence':
    'Vai esat pārliecināts, ka vēlaties dzēst šo prombūtni?',
  'timesheets.prompt.delete-absence-title': 'Dzēst prombūtni',
  'timesheets.prompt.delete-time-entry':
    'Vai esat pārliecināts, ka vēlaties dzēst šo laika ierakstu?',
  'timesheets.prompt.delete-time-entry-title': 'Dzēst laika ierakstu',
  'timesheets.register-absence': 'Reģistrēt prombūtni',
  'timesheets.register-timeentry': 'Reģistrēt laika ierakstu',
  'timesheets.select-absence-type': 'Izvēlēties prombūtnes iemeslu',
  'timesheets.send-request': 'Nosūtīt pieprasījumu',
  'timesheets.success-registration-absence': 'Jaunā prombūtne reģistrēta',
  'timesheets.success-registration-timeentry':
    'Jaunais laika ieraksts reģistrēts',
  'timesheets.success-update-absence': 'Prombūtne atjaunota',
  'timesheets.success-update-timeentry': 'Laika ieraksts atjaunots',
  'timesheets.timeentry': 'Laika ieraksts',
  'timesheets.toast.delete-absence-success': 'Prombūtne tika veiksmīgi dzēsta',
  'timesheets.toast.delete-time-entry-success':
    'Laika ieraksts tika veiksmīgi dzēsts',
  'timesheets.toast.patch-absence-success':
    'Prombūtne tika veiksmīgi atjaunota',
  'timesheets.toast.patch-time-entry-success':
    'Laika ieraksts tika veiksmīgi atjaunots',
  'timesheets.update-absence': 'Atjaunot prombūtni',
  'timesheets.update-timeentry': 'Atjaunot laika ierakstu',
  'timesheets.view-all-absence': 'Visas prombūtnes',
  'timesheets.view-all-timeentries': 'Visi laikieraksti',
  'timesheets.view-your-absence': 'Jūsu prombūtnes',
  'timesheets.view-your-timeentries': 'Jūsu laika ieraksti',
  'timesheets.warning.time-entry-exist-in-daterange':
    'Laika ieraksts jau eksistē datumu diapazonā',
  'toast.success-archive-type': '{type} veiksmīgi arhivēts',
  'toast.success-un-archive-type': '{type} veiksmīgi atkārtoti atvērts',
  'toasts.email-sent-successfully': 'Vēstule tika veiksmīgi nosūtīta',
  'toasts.error-add-type': 'Neizdevās pievienot {type}}',
  'toasts.error-delete-type': 'Neizdevās dzēst {type}',
  'toasts.error-update-type': 'Neizdevās atjaunot {type}',
  'toasts.error.create-type': 'Neizdevās izveidot {type}',
  'toasts.error.update-type': 'Neizdevās atjaunot {type}',
  'toasts.error.upload-type': 'Neizdevās augšpielādēt {type}',
  'toasts.success-add-type': '{type} veiksmīgi pievienots',
  'toasts.success-created-type': '{type} veiksmīgi izveidots',
  'toasts.success-delete-type': '{type} dzēsts',
  'toasts.success-update-type': '{type} atjaunots',
  'toasts.success.upload-type': '{type} augšpielādēts',
  'user-type-settings.delete-prompt-text':
    'Vai esat pārliecināts, ka vēlaties dzēst lietotāja veidu?',
  'user-type-settings.delete-prompt-title': 'Dzēst lietotāja veidu',
  'user-type-settings.title': 'Lietotāju veidi un piekļuves tiesības',
  'user-type-settings.toasts.error': 'Atjaunošanas kļūda',
  'user-type-settings.toasts.success':
    'Lietotāju veidi tika veiksmīgi atjaunoti',
  'user-types.change-settings-label': 'Rediģēt',
  'user-types.create-settings-label': 'Izveidot',
  'user-types.driver-activities-self': 'Paša autovadītāja aktivitātes',
  'user-types.everyones-driver-activities': 'Autovadītāju aktivitātes',
  'user-types.remove-settings-label': 'Dzēst',
  'user-types.view-settings-label': 'Skatīt',
  'users.activate-user': 'Aktivizēt lietotāju',
  'users.active-driver-card': 'Aktīvā vadītāja karte',
  'users.add-driver-card': 'Pievienot vadītāja karti',
  'users.add-driver-card-number': 'Pievienot vadītāja kartes numuru',
  'users.add-expiry-date': 'NEEDS TRANSLATION',
  'users.add-your-driver-number': 'Pievienot Jūsu vadītāja kartes numuru',
  'users.awaits-configuration': 'Tiek gaidīta konfigurācija',
  'users.bio': 'Biogrāfija',
  'users.birth-number': 'Personas kods',
  'users.change-picture': 'Augšpielādēt bildi',
  'users.change-profile-picture': 'Augšpielādēt konta bildi',
  'users.contact-person-name': 'Kontaktpersonas vārds',
  'users.contact-person-phone': 'Kontaktpersonas tālruņa numurs',
  'users.create-password.description':
    'Izveidojiet jauno paroli pievienotājam lietotājam',
  'users.create-password.title': 'Izveidot paroli',
  'users.create-user': 'Izveidot lietotāju',
  'users.deactivate-user': 'Deaktivizēt lietotāju',
  'users.delete-driver-card': 'NEEDS TRANSLATION',
  'users.delete-driver-card-message': 'NEEDS TRANSLATION',
  'users.delete-existing-number':
    'Vai vēlaties dzēst lietotāja eksistējošo numuru?',
  'users.delete-expired-driver-card': 'NEEDS TRANSLATION',
  'users.delete-expired-driver-card-message': 'NEEDS TRANSLATION',
  'users.delete-user': 'Dzēst lietotāju',
  'users.driver-card': 'Vadītāja karte',
  'users.driver-card-number': 'Vadītāja kartes numurs',
  'users.edit-driver-card': 'Rediģēt vadītāja karti',
  'users.edit-profile': 'Rediģēt kontu',
  'users.edit-profile-information': 'Rediģēt konta informāciju',
  'users.edit-user': 'Redigēt lietotāju',
  'users.errors.driver-card-expiry-format': 'Formāts: MM.GG',
  'users.errors.driver-card-invalid-expiry':
    'Derīguma termiņa datums neeksistē',
  'users.errors.phone-number-taken':
    'Tālruņa numuru jau izmanto kāds cits lietotājs',
  'users.expired-cards': 'NEEDS TRANSLATION',
  'users.expired-driver-card': 'NEEDS TRANSLATION',
  'users.has-document': 'NEEDS TRANSLATION',
  'users.has-no-companies':
    'Jūs neesat saistīts ne ar vienu kompāniju. Lūgums sazināties ar savu administratoru.',
  'users.include-expired-documents': 'NEEDS TRANSLATION',
  'users.internal-number': 'Iekšējais numurs',
  'users.missing-external-id': 'NEEDS TRANSLATION',
  'users.my-profile': 'Mans konts',
  'users.n-has-one-or-more-expired-driver-cards': 'NEEDS TRANSLATION',
  'users.n-users': '{n} lietotāju',
  'users.new': 'Jaunais lietotājs',
  'users.new-registration-link-send': 'NEEDS TRANSLATION',
  'users.no-more-user-types': 'Vairs nav lietotāju veidu',
  'users.number-already-exists': 'Lietotājs {name} jau izmanto šo numuru',
  'users.personal-info': 'Konta informācija',
  'users.profile': 'Konts',
  'users.profile-did-change': 'Konta informācija tika veiksmīgi atjaunota',
  'users.profile-of-user': 'Konts: {user}',
  'users.prompts.deactivate-self':
    'Vai esat pārliecināts, ka vēlaties deaktivizēt savu lietotāju?',
  'users.prompts.deactivate-self-title': 'Deaktivizēt savu lietotāju',
  'users.prompts.deactivate-user':
    'Vai esat pārliecināts, ka vēlaties deaktivizēt šo lietotāju?',
  'users.prompts.deactivate-user-title': 'Deaktivizēt lietotāju',
  'users.search': 'Meklēt lietotāju',
  'users.select-user-types': 'Izvēlēties lietotāju veidus',
  'users.send-new-invitation': 'NEEDS TRANSLATION',
  'users.settings': 'Mani Iestatījumi',
  'users.show-deactivated': 'Radīt deaktivizētos lietotājus',
  'users.stopped-in-control': 'Apturēti pārbaudei?',
  'users.toasts.submit-driver-card-success':
    'Vadītāja karte tika veiksmīgi iesniegta',
  'users.toasts.user-activated': 'Lietotājs {name} aktivizēts',
  'users.toasts.user-deactivated': 'Lietotājs {name} deaktivizēts',
  'users.too-weak-password': 'Pārāk viegla parole',
  'users.user-is-deactivated': 'Šis lietotājs ir deaktivizēts',
  'vehicles.add-expiration-date': 'Pievienot derīguma termiņa datumu',
  'vehicles.add-vehicle': 'Pievienot transportlīdzekli',
  'vehicles.administrative': 'Administratīvā',
  'vehicles.back-lifter': 'Kravas pacēlājs',
  'vehicles.brand': 'Zīmols',
  'vehicles.cool-freeze-heat': 'Atdzist/Saldēt/Silt',
  'vehicles.crane': 'Celtnis',
  'vehicles.crane-type': 'Celtņa veids',
  'vehicles.delete-vehicle': 'Dzēst transportlīdzekli',
  'vehicles.delete-vehicle-message':
    'Vai esat pārliecināts, ka vēlaties dzēst transportlīdzekli/materiālu?',
  'vehicles.delete-vehicle-title': 'Dzēst transportlīdzekli/materiālu?',
  'vehicles.diesel-card-number': 'Dīzeļa kartes numurs',
  'vehicles.edit-expiration-date': 'Rediģēt derīguma termiņu',
  'vehicles.edit-vehicle': 'Rediģēt transportlīdzekli',
  'vehicles.edit-vehicle-document': 'Rediģēt transportlīdzekļa dokumentu',
  'vehicles.engine': 'Dzinējs',
  'vehicles.euro-class': 'EURO klase',
  'vehicles.existing-vehicle': 'NEEDS TRANSLATION',
  'vehicles.expiration-dates': 'Derīguma termiņi',
  'vehicles.extension-type': 'Pagarinājuma veids',
  'vehicles.ferry-card-number': 'Prāmja kartes numurs',
  'vehicles.first-registered': 'Pirmās reģistrācijas datums',
  'vehicles.fleet': 'NEEDS TRANSLATION',
  'vehicles.fleet-management': 'NEEDS TRANSLATION',
  'vehicles.fleet-overview': 'Iekārtas un materiāli',
  'vehicles.fridge': 'NEEDS TRANSLATION',
  'vehicles.fridge-type': 'NEEDS TRANSLATION',
  'vehicles.fuel': 'Degviela',
  'vehicles.goods-insurance-type': 'Preču apdrošināšanas veids',
  'vehicles.height': 'Augstums',
  'vehicles.inner-height': 'NEEDS TRANSLATION',
  'vehicles.inner-length': 'NEEDS TRANSLATION',
  'vehicles.inner-width': 'NEEDS TRANSLATION',
  'vehicles.km-per-month': 'Autokilometri mēnesī',
  'vehicles.km-per-year': 'Autokilometri gadā',
  'vehicles.license-number': 'Licences numurs',
  'vehicles.loading-height': 'NEEDS TRANSLATION',
  'vehicles.max-back-axle-load': 'Maksimālā aizmugurējā ass slodze',
  'vehicles.max-front-axle-load': 'Maksimālā priekšējā ass slodze',
  'vehicles.maximum-coupling-load': 'Maksimālā sakabes slodze',
  'vehicles.maximum-payload': 'Maksimālā lietderīgā krava',
  'vehicles.maximum-semi-trailer-weight': 'Puspiekabes maksimālais svars',
  'vehicles.maximum-speed': 'Maksimālais ātrums',
  'vehicles.maximum-truck-weight': 'Kravas automašīnas maksimālais svars',
  'vehicles.maxium-total-weight': 'Pilna masa',
  'vehicles.meat-rack': 'NEEDS TRANSLATION',
  'vehicles.model': 'Modelis',
  'vehicles.net-weight': 'Pašmasa',
  'vehicles.new-expiration-date': 'Jaunais derīguma termiņš',
  'vehicles.new-vehicle-document': 'Jaunais transportlīdzekļas dokuments',
  'vehicles.next-periodic-control': 'NEEDS TRANSLATION',
  'vehicles.no-expiration-dates': 'Nav derīguma termiņu',
  'vehicles.no-file-selected': 'Fails nav izvēlēts',
  'vehicles.owned': 'Īpašumā',
  'vehicles.pallet-spaces': 'Palešu vietas',
  'vehicles.pin-code': 'PIN-kods',
  'vehicles.power': 'Jauda',
  'vehicles.previous-periodic-control': 'NEEDS TRANSLATION',
  'vehicles.prompts.delete-expiration-date': 'Dzēst derīguma termiņa datumu',
  'vehicles.registered-country': 'Reģistrācijas valsts',
  'vehicles.service-agreement': 'Servisa līgums',
  'vehicles.service-and-maintenance': 'Autoserviss un apkope',
  'vehicles.side-opening': 'Sānu atvēršana',
  'vehicles.sold-date': 'Pārdošanas datums',
  'vehicles.stationing-location': 'Autostāvvieta',
  'vehicles.studded-drive-tire': 'Piedziņas riepas radzes',
  'vehicles.studded-front-tire': 'Priekšējās riepas radzes',
  'vehicles.tires-dimension-bogie': 'Ratiņu riepas izmēri',
  'vehicles.tires-dimension-drive': 'Piedziņas riepas izmēri',
  'vehicles.tires-dimension-front': 'Priekšējās riepas izmēri',
  'vehicles.toasts.vehicle-updated': 'NEEDS TRANSLATION',
  'vehicles.toll-tag-number': 'Maksas birkas numurs',
  'vehicles.unowned': 'Nav īpašnieka',
  'vehicles.usage-properties': 'Lietošanas īpašības',
  'vehicles.vbg-coupling': 'VGB savienojums',
  'vehicles.vehicle-details': 'Informācija par transportlīdzekli',
  'vehicles.vehicle-technical-type': 'Transportlīdzekļa tehniskais veids',
  'vehicles.vehicle-type': 'Transportlīdzekļa veids',
  'vehicles.view-vehicle-details': 'Apskatīt transportlīdzekļa informāciju',
  'vehicles.vin': 'VIN',
  'vehicles.volume': 'Apjoms',
  'vehicles.weights': 'Svari',
}
export default lv
