import { SelectOption } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import React, { useCallback, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { DropdownFilter } from 'types/graphql/enums'
import { Select, SelectProps as BaseSelectProps } from './Select'
import { ALL_DEPARTMENTS_QUERY, AllDepartmentsQuery, AllDepartmentsQueryVariables, DepartmentNode } from 'modules/companies'

interface DepartmentSelectSelectProps extends Omit<BaseSelectProps<string, DepartmentNode>, 'options'>{
  alwaysInclude?: DepartmentNode
  dropdownPageName?: DropdownFilter
  isDiet?: boolean
  showInManualTimeEntryOvertime?: boolean
  dataset?: DepartmentNode[]
}

export const DepartmentSelect: React.VFC<DepartmentSelectSelectProps> = ({
  alwaysInclude = null,
  isDiet = false,
  showInManualTimeEntryOvertime = false,
  dropdownPageName,
  nullable = true,
  dataset,
  ...selectProps
}) => {
  const { department: placeholder } = useTranslate({
    department: 'common.department'
  })

  const [query, setQuery] = useState('')
  const { data, loading, } = useQuery<AllDepartmentsQuery, AllDepartmentsQueryVariables>(
    ALL_DEPARTMENTS_QUERY
  )

  const getLabel = useCallback((project: DepartmentNode) => {
    return <span className="name">{project.name}</span>
  }, [])

  const options = useMemo<SelectOption<string, DepartmentNode>[]>(() => {
    if (!data) return []

    const options = dataset && dataset.length > 0 ?
    dataset.filter(department => department.name.toLowerCase().includes(query.toLocaleLowerCase())).map(department => ({
      value: department.id,
      label: getLabel(department),
      extra: department,
    })) : data.allDepartments.edges.filter(edge => edge.node.name.toLowerCase().includes(query.toLowerCase())).map(
      ({ node: department }) => ({
        value: department.id,
        label: getLabel(department),
        extra: department,
      })
    )
    if (alwaysInclude === null) return options

    return [
      {
        value: alwaysInclude.id,
        label: getLabel(alwaysInclude),
        extra: alwaysInclude,
      },
      ...options.filter(option => option.extra.id !== alwaysInclude.id),
    ]
  }, [alwaysInclude, data, dataset, getLabel, query])


  return (
    <Select
      options={options}
      loading={loading}
      placeholder={placeholder}
      searchable={false}
      filterBackground="white"
      optionHoverBackground="white"
      onFilterChange={setQuery}
      nullable={nullable}
      {...selectProps}
    />
  )
}
