import { Icon } from '@ur/react-components'
import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  padding: 20px;

  .document {
    width: 90vw;
    max-width: 800px;
    margin: 0 auto;
    height: calc(90vw * 1.414);
    max-height: calc(800px * 1.414);
    background-color: white;
    padding: 5mm;
    margin-bottom: 1rem;
    size: A4;
  }

  .document th {
    text-align: left;
  }

  @media print {
    padding: 0;
    margin: 0;
    background-color: white;
    .document {
      width: 100%;
      max-width: 100%;
      margin: 0 0;
    }
  }
`

const ActionWrapper = styled.div`
  position: fixed;
  left: 1rem;
  top: 1rem;
  color: ${props => props.theme.colors.gray1};
  background-color: ${props => props.theme.colors.gray9};
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  width: 100px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  > div {
    width: 50%;
    padding: 1rem;
  }
  > div:hover {
    background-color: ${props => props.theme.colors.gray8};
    cursor: pointer;
  }
  @media print {
    display: none;
  }
`

/**
 * This component renders html from string by using dangerouslySetInnerHTML. The html should be sent to the component through history state.
 */
export const HtmlAsPdf: React.FC = () => {
  const location = useLocation()
  const history = useHistory()
  const state = location.state as null | { html: string }
  if (!state) {
    history.push('/')
  }

  return (
    <Wrapper>
      <ActionWrapper>
        <div onClick={() => history.goBack()}>
          <Icon icon="arrow-left" />
        </div>
        <div onClick={() => window.print()}>
          <Icon icon="print" />
        </div>
      </ActionWrapper>
      <div
        id="content"
        dangerouslySetInnerHTML={{ __html: state?.html ?? '' }}
      ></div>
    </Wrapper>
  )
}
