import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import {
  normalizeTuple,
  heuristicIsATimeString,
  fullRegexStrict,
  twoDigitRegex,
} from './util'
import { parseText } from './parse-text'
import { format as dateFnsFormat, parseISO, isDate, set } from 'date-fns'
import { TimeConvertable, TimePart, TimeTuple } from './types'

/**
 * A Time object is a simple quadruplet
 *
 * [hour, minute, second, nanosecond]
 */
export class Time {
  private _hours = 0
  private _minutes = 0
  private _seconds = 0
  private _nanoseconds = 0
  private _valid = true

  constructor(date: Date)
  constructor(dateString: string)
  constructor(
    hours?: number,
    minutes?: number,
    seconds?: number,
    nanoseconds?: number,
    valid?: boolean
  )
  constructor(
    arg1: Date | string | number = 0,
    minutes = 0,
    seconds = 0,
    nanoseconds = 0,
    valid = true
  ) {
    if (arg1 instanceof Date) {
      this._hours = arg1.getHours()
      this._minutes = arg1.getMinutes()
      this._seconds = arg1.getSeconds()
      this._nanoseconds = arg1.getMilliseconds() * 1000
      this._valid = !isNaN(arg1.getTime())
    } else if (typeof arg1 === 'string') {
      const date = new Date(arg1)
      this._hours = date.getHours()
      this._minutes = date.getMinutes()
      this._seconds = date.getSeconds()
      this._nanoseconds = date.getMilliseconds() * 1000
      this._valid = !isNaN(date.getTime())
    } else {
      this._hours = arg1
      this._minutes = minutes
      this._seconds = seconds
      this._nanoseconds = nanoseconds
      this._valid = valid
    }
  }

  /**
   * Format instance as string
   * @param format The desired format of the time string
   * @see https://date-fns.org/docs/format
   */
  format(format = 'HH:mm') {
    const finalString =
      this.hours.toString().padStart(2, '0') +
      ':' +
      this.minutes.toString().padStart(2, '0') +
      ':' +
      this.seconds.toString().padStart(2, '0') +
      '.' +
      this.nanoseconds.toString().padStart(9, '0')

    // Use date-fns as a helper here
    const isoTime = parseISO(`0000-01-01T${finalString}`)

    return dateFnsFormat(isoTime, format)
  }

  /**
   * Return whether instance is valid
   * @deprecated Use time.valid instead
   */
  isValid() {
    return this._valid
  }

  static typeStringToIndex(type: TimePart) {
    switch (type) {
      case 'hours':
      case 'hour':
        return 0
      case 'minutes':
      case 'minute':
        return 1
      case 'seconds':
      case 'second':
        return 2
      case 'nanoseconds':
      case 'nanosecond':
        return 3
      default:
        throw Error('Unknown type ' + type)
    }
  }

  add(amount: number, type: TimePart) {
    const array = this.toArray()
    array[Time.typeStringToIndex(type)] += amount

    return Time.fromArray(array)
  }
  sub(amount: number, type: TimePart) {
    const array = this.toArray()
    array[Time.typeStringToIndex(type)] -= amount

    return Time.fromArray(array)
  }
  /**
   * Reduce this instance to one number reflecting given type. Rounded down.
   * @param type Desired return scope. Default: "nanoseconds"
   * @param floor Whether to floor value, default true
   * @example new Time(4, 24, 8).reduce('seconds') // 15848
   * @example new Time(2, 54, 2).reduce('minutes') // 174
   */
  reduce(type: TimePart = 'nanoseconds', floor = true): number {
    const round = floor ? Math.floor : (x: number) => x

    switch (type) {
      case 'hours':
      case 'hour':
        return round(
          this.hours +
            this.minutes / 60 +
            this.seconds / 3600 +
            this.nanoseconds / 1e9
        )
      case 'minutes':
      case 'minute':
        return round(
          this.minutes +
            this.reduce('hours') * 60 +
            this.seconds / 60 +
            this.nanoseconds / 1e9
        )
      case 'seconds':
      case 'second':
        return round(
          this.seconds + this.reduce('minutes') * 60 + this.nanoseconds / 1e9
        )
      case 'nanoseconds':
      case 'nanosecond':
        return round(this.nanoseconds + this.reduce('seconds') * 1e9)
      default:
        return NaN
    }
  }

  isAfter(other: TimeConvertable) {
    other = time(other)
    if (!other.valid) return true

    return this.reduce() > other.reduce()
  }
  isBefore(other: TimeConvertable) {
    other = time(other)
    if (!other.valid) return true

    return this.reduce() < other.reduce()
  }
  isEqual(other: TimeConvertable) {
    other = time(other)
    if (!other.valid) return true

    return this.reduce() === other.reduce()
  }

  /**
   * Calculate difference between this Time and another
   * @param other Time to compare against
   * @param type Precision of return value
   * @param absolute Whether to make return value absolute
   */
  difference(other: TimeConvertable, type: TimePart, absolute = false) {
    other = time(other)
    const abs = absolute ? Math.abs : (x: number) => x

    return abs(other.reduce(type) - this.reduce(type))
  }

  setHours(hours: number) {
    const array = this.toArray()

    array[0] = hours
    return Time.fromArray(array)
  }

  setMinutes(minutes: number) {
    const array = this.toArray()

    array[1] = minutes
    return Time.fromArray(array)
  }

  setSeconds(seconds: number) {
    const array = this.toArray()

    array[2] = seconds
    return Time.fromArray(array)
  }

  setNanoSeconds(nanoSeconds: number) {
    const array = this.toArray()

    array[3] = nanoSeconds
    return Time.fromArray(array)
  }

  get hours() {
    return this._hours
  }
  set hours(value: number) {
    this._hours = value
  }

  get minutes() {
    return this._minutes
  }
  set minutes(value: number) {
    this._minutes = value
  }

  get seconds() {
    return this._seconds
  }
  set seconds(value: number) {
    this._seconds = value
  }

  get nanoseconds() {
    return this._nanoseconds
  }
  set nanoseconds(value: number) {
    this._nanoseconds = value
  }

  get valid() {
    return this._valid
  }

  /**
   * Check if any object or primitive is a Time object
   * @param time Any object or primitive
   */
  static isTime(time: any): time is Time {
    return time.constructor === Time
  }

  /**
   * Create a Time instance from an array
   * @param array Array to convert
   */
  static fromArray(array: TimeTuple) {
    const normalized = normalizeTuple(array)
    return new Time(normalized[0], normalized[1], normalized[2], normalized[3])
  }

  static fromDate(date: Date) {
    return new Time(
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    )
  }

  /**
   * Return this instance as an array
   */
  toArray(): TimeTuple {
    return [this._hours, this._minutes, this._seconds, this._nanoseconds]
  }

  /**
   * Apply this instance to a date
   * @param date Date to apply. Can be valid date string
   */
  toDate(date: Date | string) {
    return set(new Date(date), {
      hours: this._hours,
      minutes: this._minutes,
      seconds: this._seconds,
      milliseconds: 0,
    })
  }
}

export function now() {
  const _now = new Date()
  return new Time(
    _now.getHours(),
    _now.getMinutes(),
    _now.getSeconds(),
    _now.getMilliseconds() * 1e6
  )
}

export function time(initialValue: TimeConvertable) {
  const parsed = parseTimeValue(initialValue)
  if (!parsed) {
    return new Time(0, 0, 0, 0, false)
  } else {
    return new Time(parsed[0], parsed[1], parsed[2], parsed[3], true)
  }
}

/**
 * This function attempts to parse a value into it's constituent hours, minutes, seconds and nanoseconds.
 *
 * To do this, it performs the following steps:
 *  * If the value is a Time object, return it.
 *  * If the value is a number, parse it as the number of seconds since midnight.
 *  * If the value is a string:
 *    - If the value is an integer and is 2 digits long, and between 0 and 23, parse it as the number of hours since midnight.
 *    - If the value is an integer and is 4 digits long, and between 0000 and 2359, parse it as HHmm
 *    - If the value is an integer is 6 digits long, and between 000000 and 235959, parse it as HHmmss
 *    - Otherwise, parse it as a special string, using the `parseText` helper.
 *
 * @param value
 * @returns Tuple with [hours, minutes, seconds, nanoseconds]
 */
export function parseTimeValue(value: TimeConvertable) {
  if (value === null || value === undefined) return null

  if (value.constructor === Time) {
    // If the value is a Time object, we simply return the internal values using toArray
    return value.toArray()
  } else if (isDate(value)) {
    return [
      (value as Date).getHours(),
      (value as Date).getMinutes(),
      (value as Date).getSeconds(),
      (value as Date).getMilliseconds() * 1e6,
    ] as TimeTuple
  } else if (Array.isArray(value)) {
    // If the value is a tuple, we normalize the tuple
    return normalizeTuple(value)
  } else if (isNumber(value)) {
    const hours = Math.floor(value / 3600) % 24
    const minutes = Math.floor(value / 60) % 60
    const seconds = Math.floor(value) % 60
    // The toFixed-hack is to ensure we don't get weird rounding errors
    const nanosecond = +(value - Math.floor(value)).toFixed(9) * 1e9

    return [hours, minutes, seconds, nanosecond] as TimeTuple
  } else if (isString(value)) {
    if (heuristicIsATimeString(value)) {
      let match = value.match(fullRegexStrict)
      if (match) {
        let [rest, nanoseconds] = match[0].split('.')
        nanoseconds = nanoseconds.padEnd(9, '0')
        const [hours, minutes, seconds] = rest.split(':')
        return normalizeTuple([hours, minutes, seconds, nanoseconds])
      }

      match = value.match(twoDigitRegex)
      if (match) return normalizeTuple(match)
    }

    return parseText(value)
  }

  return null
}
