import { CountryCode } from 'util/countries'
import { ShallowUserNode } from '.'

export const driverCardCountries = {
  AL: 'AL',
  AD: 'AND',
  AM: 'ARM',
  AT: 'A',
  AZ: 'AZ',
  BY: 'BY',
  BE: 'B',
  BA: 'BIH',
  BG: 'BG',
  HR: 'HR',
  CY: 'CY',
  CZ: 'CZ',
  DK: 'DK',
  EE: 'EST',
  FO: 'FO',
  FI: 'FIN',
  FR: 'F',
  GE: 'GE',
  DE: 'D',
  GR: 'GR',
  HU: 'H',
  IS: 'IS',
  IE: 'IRL',
  IT: 'I',
  KZ: 'KZ',
  LV: 'LV',
  LI: 'FL',
  LT: 'LT',
  LU: 'L',
  MT: 'M',
  MD: 'MD',
  MC: 'MC',
  ME: 'MNE',
  NL: 'NL',
  MK: 'MK',
  NO: 'N',
  PL: 'PL',
  PT: 'P',
  RO: 'RO',
  RU: 'RUS',
  SM: 'RSM',
  RS: 'SRB',
  SK: 'SK',
  SI: 'SLO',
  ES: 'E',
  SE: 'S',
  CH: 'CH',
  TJ: 'TJ',
  TR: 'TR',
  TM: 'TM',
  UA: 'UA',
  GB: 'UK',
  UZ: 'UZ',
  VA: 'V',
  EU: 'EC',
  UN: 'WLD',
  // This one is kinda hacky, but shit works
  VU: 'EUR',
  // Not in use, but leaving them here anyway
  RFU: 'RFU',
  EUR: 'EUR',
}

export const convertCountryCodeToDriverCardCountry: (
  code: CountryCode
) => string | null = code =>
  driverCardCountries[code as keyof typeof driverCardCountries] ?? null

export const getUserLabel = (user: ShallowUserNode) => {
  const name =
    user.firstName && user.lastName
      ? `${user.lastName}, ${user.firstName}`
      : user.fullName
  return name
}
