import React from 'react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import { ALL_ISSUES_QUERY } from '../queries'
import { useWindowSize, useCompany } from 'util/hooks'
import { IssueTvCard } from '..'
import { useOnErrorAuto } from 'util/hooks'
import { ZIndexRange } from 'types/style'
import { AllIssuesQuery } from '../types.graphl'
import { CenteredLoader } from 'components'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f7f8fc;

  z-index: ${ZIndexRange.Extra};
`

const Content = styled.div`
  padding: 30px;
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  height: 100%;
`

export const IssuesTvDisplay: React.FC = () => {
  // const [mobile]
  const size = useWindowSize()
  const company = useCompany()

  // Each row is roughly 184px + 30px tall. The total page padding is roughly 60px. Hence:
  const numberOfIssuesToFetch = Math.floor((size.height - 60) / 214)

  const onErrorAuto = useOnErrorAuto()

  const { data, loading } = useQuery<AllIssuesQuery>(ALL_ISSUES_QUERY, {
    variables: {
      first: numberOfIssuesToFetch,
      company: company.id,
      orderBy: '-createdAt',
    },
    pollInterval: 30000,
    onError: onErrorAuto(),
  })

  const issues =
    data && data.allIssues ? data.allIssues.edges.map(edge => edge.node) : []

  return (
    <Wrapper>
      {loading && <CenteredLoader />}
      <Content>
        {issues.map(issue => (
          <IssueTvCard key={issue.id} issue={issue} />
        ))}
      </Content>
    </Wrapper>
  )
}
