import React from 'react'
import styled from 'styled-components'
import { overloadColorProp } from 'util/style'

import { Text } from 'components/Text'
import { Button } from '@ur/react-components'
import {
  CardProps,
  CardHeaderStyleProps,
  CardContentStyleProps,
  CardFooterStyleProps,
  CardStyleProps,
} from './types'
import { CSSJustifyContent, CSSOverflow } from 'types/style'

const MainContent = styled.div<Required<CardContentStyleProps>>`
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  overflow-x: ${props => props.overflowX};
  background-color: ${props => props.backgroundColor ?? 'white'};
`

const Footer = styled.div<Required<CardFooterStyleProps>>`
  display: flex;
  justify-content: ${props => props.justifyContent};
  padding: ${props => props.padding};
  line-height: ${props => props.lineHeight};
  font-size: ${props => props.fontSize};
  border-top: 1px solid ${props => props.theme.colors.gray9};

  background-color: ${props => props.backgroundColor ?? 'transparent'};
`

const Header = styled.div<Required<CardHeaderStyleProps>>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: ${props => props.lineHeight};
  font-weight: ${props => props.fontWeight};
  font-size: ${props => props.fontSize};
  padding: ${props => props.padding};
  background-color: ${props => props.backgroundColor ?? 'white'};
  margin: 0 -1px;
  border-bottom: 1px solid ${props => props.theme.colors.gray9};
`

const CardWrapper = styled.div<CardStyleProps>`
  position: relative;
  grid-area: ${props => props.gridArea};

  background: ${props => props.background ?? 'white'};
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  height: ${props => props.height};
  width: ${props => props.width};

  min-width: ${props => props.minWidth};
  max-width: ${props => props.maxWidth};

  transition: box-shadow 0.2s ease-in-out;
  box-shadow: ${props => props.theme.shadow.default};
  border: 1px solid ${props => overloadColorProp(props, 'borderColor')};
  box-sizing: border-box;
  border-radius: 12px;

  overflow-x: ${props => props.overflowX};
  overflow-y: ${props => props.overflowY};
  overflow: ${props => props.overflow};

  ${props =>
    props.hoverEffect &&
    `
    &:hover{
      cursor: pointer;
      border: 1px solid ${overloadColorProp(props, 'borderHoverColor')};
      transition: box-shadow 0.1s ease;
      box-shadow: 0 2px 12px 0 rgba(49, 78, 255, 0.2);
    }
  `}

  ${props => props.theme.layout.mobile} {
    padding: ${props => (props.padding ? props.padding : '0.25em 0.5em')};
  }

  ${props => !props.border && 'border: none;'}
`

const SimpleCardWrapper = styled(CardWrapper)``

export const Card: React.FC<CardProps> = ({
  className,
  id,

  header,
  children,
  footer,

  headerProps = {},
  contentProps = {},
  footerProps = {},

  onClick,

  ...cardProps
}) => {
  const defaultHeaderProps = {
    fontSize: '1.25rem',
    lineHeight: 1.6,
    fontWeight: 400,
    padding: '1rem 1.25rem',
    backgroundColor: 'white',
    ...headerProps,
  }
  const defaultContentProps = {
    padding: '1rem 1.25rem',
    margin: 'inherit',
    overflowX: 'visible' as CSSOverflow,
    backgroundColor: 'white',
    ...contentProps,
  }
  const defaultFooterProps = {
    fontSize: '0.8125rem',
    lineHeight: 2.3,
    padding: '1rem 1.25rem',
    justifyContent: 'flex-end' as CSSJustifyContent,
    backgroundColor: 'transparent',
    ...footerProps,
  }
  const defaultCardProps =
    !header && !footer
      ? {
          padding: '1rem 1.25rem',
          hoverEffect: false,
          borderColor: 'transparent',
          borderHoverColor: 'primary',
          border: false,
          ...cardProps,
        }
      : {
          padding: '0',
          hoverEffect: false,
          borderColor: 'transparent',
          borderHoverColor: 'primary',
          border: false,
          ...cardProps,
        }

  /* If neither header nor footer is wanted just pass the fully styled card
  almost as it was before */
  if (!header && !footer) {
    return (
      <SimpleCardWrapper
        className={className}
        id={id}
        {...defaultCardProps}
        onClick={onClick}
      >
        {children}
      </SimpleCardWrapper>
    )
  }
  return (
    <CardWrapper className={className} id={id} {...defaultCardProps}>
      {header && (
        <Header {...defaultHeaderProps}>
          <Text>{header.title}</Text>
          {header.renderButton ? (
            header.renderButton()
          ) : header.buttonText ? (
            <Button {...header.buttonProps}>{header.buttonText}</Button>
          ) : null}
        </Header>
      )}
      <MainContent {...defaultContentProps}>{children}</MainContent>
      {footer ? (
        footer.renderFooter ? (
          <Footer {...defaultFooterProps}>{footer.renderFooter()}</Footer>
        ) : (
          <Footer {...defaultFooterProps}></Footer>
        )
      ) : null}
    </CardWrapper>
  )
}

export default Card
