import { formatRelative as formatRelativeBase } from 'date-fns'
import { getLocale } from './getLocale'

interface formatRelativeOptions {
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
}

export function formatRelative(
  firstDate: Date,
  secondDate: Date,
  options: formatRelativeOptions = {}
) {
  return formatRelativeBase(firstDate, secondDate, {
    ...options,
    locale: getLocale(),
  })
}
