import React from 'react'

export const AvailabilityIcon: React.VFC<React.SVGAttributes<SVGSVGElement>> =
  props => (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      width="18px"
      height="18px"
      viewBox="0 0 448 448"
      {...props}
    >
      <path d="M36.83,441.93c8.25,3.88,17.46,6.07,27.17,6.07H384c35.3,0,64-28.7,64-64V64c0-7.3-1.24-14.31-3.5-20.85L36.83,441.93Z" />
      <path d="M412.9,6.92c-8.69-4.41-18.5-6.92-28.9-6.92H64C28.7,0,0,28.7,0,64V384c0,8,1.49,15.66,4.18,22.73L412.9,6.92Z" />
    </svg>
  )
