import { GlobalStore } from '@ur/react-hooks'

export const initialStore: GlobalStore = {
  /** 
  
  Any changes here do not trigger a hot-reload.
  Webapp needs to manually be refreshed and any data
  here will fallback to initial values
  
  */
  breadcrumbs: {
    overrides: {},
    inserts: [],
    excludes: [],
  },
}
