import { gql } from '@apollo/client'
import { ThumbnailUserFragment } from 'modules/users/fragments'

export const IssueFragments = {
  issueCategoryInfo: gql`
    fragment IssueCategoryInfo on IssueCategoryNode {
      id
      name
      color
      responsibleUser {
        id
        fullName
        initials
        profilePicture
      }
      notificationConfig
      defaultSeverity
    }
  `,
  issueAttachmentInfo: gql`
    fragment IssueAttachmentInfo on IssueAttachmentNode {
      id
      name
      file
    }
  `,
  issueCategory: gql`
    fragment IssueCategory on IssueNode {
      category {
        id
        name
        color
        responsibleUser {
          id
          fullName
          initials
          profilePicture
        }
        notificationConfig
      }
    }
  `,
}

export const ISSUE_QUERY = gql`
  query Issue($id: ID!) {
    issue(id: $id) {
      id

      referenceId
      vehiclePlateNumber
      issueDescription
      damageDescription

      costEstimation
      actualCost
      solutionSuggestion
      solution

      locationPlacename
      locationLatitude
      locationLongitude
      locationDescription

      category {
        id
        name
        color

        responsibleUser {
          ...ThumbnailUser
        }
      }
      riskAspect {
        id
        name
      }

      timeOfIssue
      user {
        ...ThumbnailUser
      }
      archivedAt
      archivedBy {
        ...ThumbnailUser
      }
      closedAt
      closedBy {
        ...ThumbnailUser
      }

      attachments {
        id
        name
        file
      }
      responsibleUser {
        ...ThumbnailUser
      }
      severity
      actionLogs {
        edges {
          node {
            id
            action
            createdAt
            user {
              id
              fullName
            }
          }
        }
      }
    }
  }
  ${ThumbnailUserFragment}
`

export const ALL_ISSUES_QUERY = gql`
  query AllIssues(
    $first: Int
    $after: String
    $user: ID
    $company: ID!
    $q: String
    $orderBy: String
    $closedAtIsNull: Boolean
    $archivedAtIsNull: Boolean
    $excludeCategories: [ID]
    $includeSeverities: [String]
  ) {
    allIssues(
      first: $first
      after: $after
      user: $user
      company: $company
      q: $q
      orderBy: $orderBy
      closedAt_Isnull: $closedAtIsNull
      archivedAt_Isnull: $archivedAtIsNull
      excludeCategories: $excludeCategories
      includeSeverities: $includeSeverities
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id

          timeOfIssue
          closed
          archived

          vehiclePlateNumber
          referenceId
          issueDescription

          solution
          actualCost

          riskAspect {
            id
            name
          }
          severity

          category {
            id
            name
            color
          }
          user {
            id
            fullName
            phoneNumber
          }
        }
      }
    }
  }
`

export const ALL_ISSUE_CATEGORIES_QUERY = gql`
  query AllIssueCategories {
    allIssueCategories {
      edges {
        node {
          ...IssueCategoryInfo
        }
      }
    }
  }
  ${IssueFragments.issueCategoryInfo}
`

export const ALL_ISSUE_RISK_ASPECTS_QUERY = gql`
  query AllIssueRiskAspects {
    allIssueRiskAspects {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`
export const ALL_ISSUE_CATEGORIES_SHALLOW_QUERY = gql`
  query AllIssueCategoriesShallow($withResponsibleUser: Boolean = false) {
    allIssueCategories {
      edges {
        node {
          id
          name
          color
          defaultSeverity
          responsibleUser @include(if: $withResponsibleUser) {
            id
            fullName
          }
        }
      }
    }
  }
`
export const ALL_ISSUE_DATA_FOR_SETTINGS_QUERY = gql`
  query AllIssueDataForSettings {
    allIssueCategories {
      edges {
        node {
          id
          name
          color
          editable
          responsibleUser {
            id
            fullName
            firstName
            lastName
            initials
            profilePicture
          }
          defaultSeverity
        }
      }
    }
    allIssueRiskAspects {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const USER_ISSUES = gql`
  query UserIssues($user: ID, $dateGte: DateTime, $dateLt: DateTime) {
    allIssues(user: $user, createdAt_Gte: $dateGte, createdAt_Lt: $dateLt) {
      edges {
        node {
          id
          category {
            id
            name
          }
          user {
            ...UserInfo
          }
          timeOfIssue
          actualCost
          referenceId
          vehiclePlateNumber
          damageDescription
          issueDescription
          locationPlacename
          locationLatitude
          locationLongitude
          locationDescription
          solutionSuggestion
          costEstimation
          closedBy {
            ...UserInfo
          }
          closedAt
          solution
        }
      }
    }
  }
`
