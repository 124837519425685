import React from 'react'
import { Route, RouteProps } from 'react-router-dom'

import { useAdmin, usePermissions } from 'util/hooks'
import { useTranslate } from '@ur/react-hooks'
import { useModuleConfigs } from 'modules/companies/CompanySettings/hooks'
import { CenteredLoader } from 'components'

export interface PermissionsRequiredRouteProps extends RouteProps {
  permissions: string[] | string
  hasModuleAccess?: boolean
}

export const PermissionsRequiredRoute: React.FC<PermissionsRequiredRouteProps> =
  ({
    permissions,
    component: Component,
    render,
    hasModuleAccess = true,
    ...rest
  }) => {
    const admin = useAdmin()
    const translations = useTranslate({
      notPermitted: 'error.not-permitted-to-view',
      loading: 'common.loading...',
    })
    const { hasPermissions } = usePermissions()

    const { loading } = useModuleConfigs()

    if (loading) {
      return <CenteredLoader />
    }

    if ((!admin && !hasPermissions(permissions)) || !hasModuleAccess) {
      return <p>{translations.notPermitted}</p>
    }

    return (
      <Route
        {...rest}
        render={props =>
          render ? render(props) : Component ? <Component {...props} /> : null
        }
      />
    )
  }
