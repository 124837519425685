import { gql } from '@apollo/client'
import { TimesheetFragments } from 'modules/timesheets/fragments'

export const ALL_TERMINAL_ENTRIES = gql`
  query AllTerminalEntries(
    $user: ID!
    $last: Int
    $checkInGte: DateTime
    $checkInLt: DateTime
  ) {
    allTerminalEntries(
      user: $user
      last: $last
      checkIn_Gte: $checkInGte
      checkIn_Lt: $checkInLt
    ) {
      edges {
        node {
          id
          checkIn
          checkOut
          pause
          totalWork
          totalWorkTime
          overtime
          user {
            id
          }
        }
      }
    }
    isOnTerminalNetwork
    isWorking
    allAbsences(
      user: $user
      datetimeEnd_Gte: $checkInGte
      datetimeStart_Lt: $checkInLt
    ) {
      edges {
        node {
          ...AbsenceInfo
        }
      }
    }
  }
  ${TimesheetFragments.absenceInfo}
`

export const TODAYS_TERMINAL_ENTRIES = gql`
  query TodaysTerminalEntries($user: ID!) {
    todaysTerminalEntries(user: $user) {
      edges {
        node {
          id
          checkIn
          checkOut
          totalWorkTime
          user {
            id
          }
        }
      }
    }
    todaysTerminalEntriesTotalWorkTime
    isOnTerminalNetwork
    isWorking
  }
`

export const ALL_TERMINALS = gql`
  query AllTerminals {
    allTerminals {
      edges {
        node {
          id
          name
          ip
          ipFilterRange
        }
      }
    }
  }
`
