import { useToast } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router'
import { useOnErrorAuto } from 'util/hooks'
import { CREATE_ISSUE_MUTATION, PATCH_ISSUE_MUTATION } from './mutations'
import {
  CreateIssueMutation,
  CreateIssueMutationVariables,
  PatchIssueMutation,
  PatchIssueMutationVariables,
  DeliverIssueReportEmailMutation,
  DeliverIssueReportEmailMutationVariables,
  DeliverIssueReportMassEmailMutation,
  DeliverIssueReportMassEmailMutationVariables,
} from './types.graphl'
import { EXPORT_ISSUE, EXPORT_ISSUES_MASS } from 'modules/exports/mutations'

export function useCreateIssueMutation() {
  const translations = useTranslate({
    success: {
      create: 'issues.toasts.create-issue-success',
    },
    error: {
      create: 'issues.errors.create-issue',
    },
  })

  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()
  const history = useHistory()

  const [createIssue, { loading: createLoading }] = useMutation<
    CreateIssueMutation,
    CreateIssueMutationVariables
  >(CREATE_ISSUE_MUTATION, {
    onCompleted(data) {
      if (!data) {
        addToast('error', translations.error.create)
        return
      }

      addToast('success', translations.success.create)
      history.push(`/issues/${data.createIssue.issue.id}`)
    },
    onError: onErrorAuto(translations.error.create),
  })

  return {
    create: createIssue,
    loading: createLoading,
  }
}

export function usePatchIssueMutation() {
  const translations = useTranslate({
    success: {
      patch: 'issues.toasts.patch-issue-success',
    },
    error: {
      patch: 'issues.errors.patch-issue',
    },
  })

  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()

  const [patchIssue, { loading: patchLoading }] = useMutation<
    PatchIssueMutation,
    PatchIssueMutationVariables
  >(PATCH_ISSUE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['Issue'],
    onCompleted() {
      addToast('success', translations.success.patch)
    },
    onError: onErrorAuto(translations.error.patch),
  })

  return {
    patch: patchIssue,
    loading: patchLoading,
  }
}

export const useExportIssuesMutations = (onCompleted?: () => void) => {
  const translations = useTranslate({
    mailSuccess: 'issues.send-email-success',
    downloadSucces: 'exports.success-user-exports',
    error: 'exports.error-email-process',
  })

  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()

  const [exportIssue, { loading: mailLoading }] = useMutation<
    DeliverIssueReportEmailMutation,
    DeliverIssueReportEmailMutationVariables
  >(EXPORT_ISSUE, {
    onCompleted: (data: DeliverIssueReportEmailMutation) => {
      if (data?.exportIssue.ok) {
        addToast('success', translations.downloadSucces)
      } else {
        onErrorAuto(translations.error)
      }
      onCompleted?.()
    },
  })

  const [exportIssuesMass, { loading: massMailLoading }] = useMutation<
    DeliverIssueReportMassEmailMutation,
    DeliverIssueReportMassEmailMutationVariables
  >(EXPORT_ISSUES_MASS, {
    onCompleted: (data: DeliverIssueReportMassEmailMutation) => {
      if (data?.exportIssuesMass.ok) {
        addToast('success', translations.downloadSucces)
      } else {
        onErrorAuto(translations.error)
      }
      onCompleted?.()
    },
  })

  return {
    exportIssue,
    exportIssuesMass,
    loading: mailLoading || massMailLoading,
  }
}
