import { useCallback, useEffect, useRef } from 'react'

export default function useClickOutside(
  ref: React.MutableRefObject<HTMLElement | null>,
  callback: (event: MouseEvent | TouchEvent | KeyboardEvent) => void,
  detectEscape: boolean = true,
  active?: boolean,
  ...include: React.MutableRefObject<HTMLElement | null>[]
) {
  const callbackRef = useRef(callback)

  const handleMouseDown = useCallback(
    (evt: MouseEvent | TouchEvent) => {
      if (!ref.current || !active) return

      const clicked = evt.target as HTMLElement

      if (ref.current.contains(clicked)) return

      for (const other of include) {
        if (!other.current) continue
        if (other.current.contains(clicked)) return
      }

      callbackRef.current(evt)
    },
    [ref, callbackRef, include, active]
  )

  const handleKeyDown = useCallback(
    (evt: Event) => {
      if (
        !detectEscape ||
        !(evt instanceof KeyboardEvent) ||
        evt.key !== 'Escape'
      )
        return
      callbackRef.current(evt)
    },
    [detectEscape, callbackRef]
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown)
    document.addEventListener('touchstart', handleMouseDown)

    if (detectEscape) document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('mousedown', handleMouseDown)
      document.removeEventListener('touchstart', handleMouseDown)

      if (detectEscape) document.removeEventListener('keydown', handleKeyDown)
    }
  }, [detectEscape, handleMouseDown, handleKeyDown])
}
