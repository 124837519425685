import React from 'react'
import styled from 'styled-components'
import { useTranslate } from '@ur/react-hooks'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  color: ${props => props.theme.colors.primary};
`

export const NoCompanies: React.VFC = () => {
  const translations = useTranslate({
    noCompanies: 'users.has-no-companies',
  })
  return (
    <Wrapper>
      <h1>{translations.noCompanies}</h1>
    </Wrapper>
  )
}
