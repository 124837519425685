import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from 'react-router-dom'

import { Login, ForgottenPassword } from 'modules/login'
import { ResetPassword } from 'modules/authentication'
import { CreatePassword } from 'modules/users/RegisterUser/CreatePassword'

export function NoAuthRoutes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route
          exact
          path="/create-password/:urlId"
          component={CreatePassword}
        />
        <Route exact path="/forgotten-password" component={ForgottenPassword} />
        <Route exact path="/reset-password/:urlId" component={ResetPassword} />
        <Redirect from="" to="/login" />
      </Switch>
    </Router>
  )
}
