import { useTranslate } from '@ur/react-hooks'
import { VehicleDetailNode } from 'modules/vehicles'
import { DetailCard } from 'modules/vehicles/fleet'
import styled from 'styled-components'
import { useVehicleDetailFields } from '../hooks'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const DetailCards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  ${props => props.theme.media.mobile} {
    grid-template-columns: 1fr;
  }
  ${props => props.theme.media.largePad} {
    grid-template-columns: repeat(2, 1fr);
  }
`
export interface VehicleDetailsProps {
  vehicle: VehicleDetailNode
}

export const VehicleDetails: React.VFC<VehicleDetailsProps> = ({ vehicle }) => {
  const translations = useTranslate({
    vehicleDetails: 'vehicles.vehicle-details',
    general: 'common.general',
    engine: 'vehicles.engine',
    weights: 'vehicles.weights',
    service: 'vehicles.service-and-maintenance',
    usage: 'vehicles.usage-properties',
    administrative: 'vehicles.administrative',
    close: 'common.close',
  })

  const {
    generalFields,
    engineFields,
    weightFields,
    usageFields,
    administrativeFields,
    serviceFields,
  } = useVehicleDetailFields()

  return (
    <Wrapper>
      <DetailCards>
        <DetailCard
          title={translations.general}
          fields={generalFields}
          vehicle={vehicle}
        />
        <DetailCard
          title={translations.service}
          vehicle={vehicle}
          fields={serviceFields}
        />
        <DetailCard
          title={translations.engine}
          vehicle={vehicle}
          fields={engineFields}
        />
        <DetailCard
          title={translations.weights}
          vehicle={vehicle}
          fields={weightFields}
        />
        <DetailCard
          title={translations.usage}
          vehicle={vehicle}
          fields={usageFields}
        />
        <DetailCard
          title={translations.administrative}
          vehicle={vehicle}
          fields={administrativeFields}
        />
      </DetailCards>
    </Wrapper>
  )
}
