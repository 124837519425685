import { parseISO } from 'date-fns'
import { AbsenceNode, AbsenceTypeNode, OvertimeEntryNode, TimeEntryNode, TimesheetsDayReport, TimesheetsReportSalaryWorkTimeNode } from '../types.graphql'
import styled, { css, useTheme } from 'styled-components'
import { format } from 'util/date-fns'
import { DateCell } from './table-cells'
import { useAbsenceHandler, useTimesheetHandlers } from './handler.hooks'
import { Icon } from '@ur/react-components'
import { TableMenu } from 'components'
import { useTranslate } from '@ur/react-hooks'
import { isMobile } from 'react-device-detect'

const StyledIcon = styled(Icon)`
  cursor: pointer;
`

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`

interface TimesheetRowProps {
  day: TimesheetsDayReport
  isWithinInterval: boolean
  projectActive?: boolean
  selectedAbsenceType?: Pick<AbsenceTypeNode, 'id' | 'name'> | null
  absenceOnlyMode?: boolean
  salaryWorkTime: TimesheetsReportSalaryWorkTimeNode

  onClick: () => void
  onSelectAbsenceType?: (
    absenceType: Pick<AbsenceTypeNode, 'id' | 'name'> | null
  ) => void
}

interface RowProps {
  isActive: boolean
  isAbsence: boolean
}

const Row = styled.tr<RowProps>`


  ${props =>
    !props.isActive &&
    css`
      background-color: ${props.theme.colors.gray11};
      color: ${props.theme.colors.gray6} !important;
    `};
  ${props =>
    props.isActive &&
    props.isAbsence &&
    css`
      background-color: ${props.theme.colors.red12};
      $:hover {
        colors: ${props.theme.colors.red};
      }
    `}
  
   padding: 12px 1rem;

   td {
    box-sizing: content-box;
    height: 30px;
   }

  td.absence-info {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 12px 1rem;
    height: 56px;

    span {
      color: ${props => props.theme.colors.gray5};
      font-weight: 600;
    }
  }
`


export const TimesheetTableRow: React.VFC<TimesheetRowProps> = ({
  day,
  projectActive = false,
  salaryWorkTime,
  isWithinInterval,

  onClick = () => {},
}) => {
  const { timesheetHandler } = useTimesheetHandlers(salaryWorkTime)

  const showMenu = (day.timeEntries.length + day.absences.length) === 1
  
  const salaryCode = day.timeEntries.length === 1 && (day.timeEntries.length + day.overtimeEntries.length) === 1
    ? day.timeEntries[0]?.salaryCode?.name
    : ""

  const menuItems = timesheetHandler.createTimesheetDayReportMenuItems(day)

  const dateColumn = (
    <td key={'date'}>{format(new Date(day.day), 'dd. MMM')}</td>
  )

    const canViewComment = (report: TimesheetsDayReport) =>
    !(
      report.comments === '' ||
      report.comments === 'Ingen kommentar' ||
      report.comments === 'No comment'
    )

  const theme = useTheme()

  return (
    <Row
      key={new Date(day.day).getDate()}
      role="pen"
      isActive={isWithinInterval}
      isAbsence={false}
      onClick={onClick}
    >
      {dateColumn}
      {day.dayStart ? <DateCell date={parseISO(day.dayStart)} side="start" type="timeentry" /> : <td></td>}
      {day.dayEnd ? <DateCell date={parseISO(day.dayEnd)} side="end" type="timeentry"/> : <td></td>}
      <td>{day.totalOrdinary}</td>
      <td>{day.totalPause}</td>
      <td>{day.totalOvertime}</td>
      {!isMobile &&
        <>
          <td>{salaryCode}</td>
          <td>{day?.department?.name}</td>
          {projectActive && <td>{day?.project?.name}</td>}
        </>
      }
      
      <td width="130px">
        {canViewComment(day) && (
          <IconContainer>
            <StyledIcon
              color={theme.colors.gray1}
              hoverColor={theme.colors.secondary}
              icon="comment-alt-lines"
              size="16px"
              onClick={() =>
                timesheetHandler.viewComment(day.comments)
              }
            />
          </IconContainer>
        )}
      </td>
      {showMenu ? <TableMenu items={menuItems} /> : <td></td>}
    </Row>
  )
}

interface TimesheetAbsenceRowProps {
  absence: Omit<AbsenceNode, 'company'>
  date: Date
  isWithinInterval: boolean
  projectActive?: boolean
  salaryWorkTime: TimesheetsReportSalaryWorkTimeNode

  onClick: () => void
}

export const TimesheetTableAbsenceRow: React.VFC<TimesheetAbsenceRowProps> = ({
  absence,
  date,
  isWithinInterval,
  projectActive = false,
  salaryWorkTime,

  onClick = () => {},
}) => {

  const dateColumn = (
    <td key={'date'}>{format(date, 'dd. MMM')}</td>
  )

  const absenceHandler = useAbsenceHandler()
  const { timesheetHandler } = useTimesheetHandlers(salaryWorkTime)
  
  const menuItems = absenceHandler.createAbsenceMenuItems(absence, false)
    
  const theme = useTheme()

  return (
    <Row
      key={date.getDate()}
      role="pen"
      isActive={isWithinInterval}
      isAbsence={true}
      onClick={onClick}
    >
      {dateColumn}
      {absence.datetimeStart ? <DateCell date={parseISO(absence.datetimeStart)} side="start" type="absence" /> : <td></td>}
      {absence.datetimeEnd ? <DateCell date={parseISO(absence.datetimeEnd)} side="end" type="absence"/> : <td></td>}
      <td></td>
      <td></td>
      <td></td>
      {!isMobile && 
        <>
          <td></td>
          <td></td>
          {projectActive && <td></td>}
        </>
      }
      
      <td width="130px">
        <IconContainer>
          <StyledIcon
            color={theme.colors.gray1}
            hoverColor={theme.colors.secondary}
            icon="comment-alt-lines"
            size="16px"
            onClick={() =>
              timesheetHandler.viewComment(absence.absenceType.name + ": " + absence.reason)
            }
          />
        </IconContainer>
      </td>
      <TableMenu items={menuItems} />
    </Row>
  )
}

const SubRow = styled(Row)`
  background-color: ${props => !props.isAbsence && props.theme.colors.gray11};
`

interface TimesheetSubRowProps {
  timeEntry: Omit<TimeEntryNode, 'company'>
  isWithinInterval: boolean
  hidden?: boolean
  otherEntries?: Omit<TimeEntryNode, 'company'>[]
  projectActive?: boolean
  salaryWorkTime: TimesheetsReportSalaryWorkTimeNode
  
  onClick: () => void
}

export const TimesheetTableSubRow: React.VFC<TimesheetSubRowProps> = ({
  timeEntry,
  isWithinInterval,
  hidden = true,
  otherEntries = [],
  projectActive = false,
  salaryWorkTime,

  onClick = () => {},
}) => {
  const translations = useTranslate({
    manual: 'common.manual',
  })
  const { timeEntryHandler, timesheetHandler } = useTimesheetHandlers(salaryWorkTime)
  const theme = useTheme()

  const menuItems = timeEntryHandler.createTimesheetTimeEntryMenuItems(timeEntry, otherEntries)
  return (
    <SubRow
      key={new Date(timeEntry.datetimeStart).getDate()}
      role="pen"
      isActive={isWithinInterval}
      isAbsence={false}
      onClick={onClick}
      hidden={hidden}
    >
      <td key={'date'}><i>{translations.manual}</i></td>
      {timeEntry.datetimeStart ? <DateCell date={parseISO(timeEntry.datetimeStart)} side="start" type="timeentry" /> : <td></td>}
      {timeEntry.datetimeEnd ? <DateCell date={parseISO(timeEntry.datetimeEnd)} side="end" type="timeentry"/> : <td></td>}
      <td>{timeEntry.totalOrdinary}</td>
      <td>{timeEntry.pause}</td>
      <td>{timeEntry.overtime}</td>
      <td>{timeEntry.salaryCode?.name}</td>
      <td>{timeEntry?.department?.name}</td>
      {projectActive && <td>{timeEntry?.project?.name}</td>}
      <td width="130px">
        <IconContainer>
          <StyledIcon
            color={theme.colors.gray1}
            hoverColor={theme.colors.secondary}
            icon="comment-alt-lines"
            size="16px"
            onClick={() =>
              timesheetHandler.viewComment(timeEntry.comment)
            }
          />
        </IconContainer>
      </td>
      <TableMenu items={menuItems} />
    </SubRow>
  )
}

interface TimesheetOvertimeSubRowProps {
  overtimeEntry: OvertimeEntryNode
  isWithinInterval: boolean
  hidden?: boolean
  projectActive?: boolean

  onClick: () => void
}

export const TimesheetTableOvertimeSubRow: React.VFC<TimesheetOvertimeSubRowProps> = ({
  overtimeEntry,
  isWithinInterval,
  hidden = true,
  projectActive = false,

  onClick = () => {},
}) => {

  return (
    <SubRow
      key={new Date(overtimeEntry.startTime).getDate()}
      role="pen"
      isActive={isWithinInterval}
      isAbsence={false}
      onClick={onClick}
      hidden={hidden}
    >
      <td key={'date'}></td>
      {overtimeEntry.startTime ? <DateCell date={parseISO(overtimeEntry.startTime)} side="start" type="timeentry" /> : <td></td>}
      {overtimeEntry.endTime ? <DateCell date={parseISO(overtimeEntry.endTime)} side="end" type="timeentry"/> : <td></td>}
      <td></td>
      <td></td>
      <td>{overtimeEntry.overtime}</td>
      <td>{overtimeEntry.salaryCode?.name}</td>
      <td>{overtimeEntry?.department?.name}</td>
      {projectActive && <td>{overtimeEntry?.project?.name}</td>}
      <td width="130px"></td>
      <td></td>
    </SubRow>
  )
}

interface TimesheetAbsenceSubRowProps {
  absence: Omit<AbsenceNode, 'company'>
  isWithinInterval: boolean
  hidden?: boolean
  projectActive?: boolean
  salaryWorkTime: TimesheetsReportSalaryWorkTimeNode

  onClick: () => void
}

export const TimesheetTableAbsenceSubRow: React.VFC<TimesheetAbsenceSubRowProps> = ({
  absence,
  isWithinInterval,
  hidden = true,
  projectActive = false,
  salaryWorkTime,

  onClick = () => {},
}) => {
  const translations = useTranslate({
    manual: 'common.manual',
  })

  const theme = useTheme()

  const { timesheetHandler } = useTimesheetHandlers(salaryWorkTime)
  const absenceHandler = useAbsenceHandler()

  const menuItems = absenceHandler.createAbsenceMenuItems(absence, false)

  return (
    <SubRow
      key={new Date(absence.datetimeStart).getDate()}
      role="pen"
      isActive={isWithinInterval}
      isAbsence={true}
      onClick={onClick}
      hidden={hidden}
    >
      <td key={'date'}><i>{translations.manual}</i></td>
      {absence.datetimeStart ? <DateCell date={parseISO(absence.datetimeStart)} side="start" type="absence" /> : <td></td>}
      {absence.datetimeEnd ? <DateCell date={parseISO(absence.datetimeEnd)} side="end" type="absence"/> : <td></td>}
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      {projectActive && <td></td>}
      <td width="130px">
        <IconContainer>
          <StyledIcon
            color={theme.colors.gray1}
            hoverColor={theme.colors.secondary}
            icon="comment-alt-lines"
            size="16px"
            onClick={() =>
              timesheetHandler.viewComment(absence.absenceType.name + ": " + absence.reason)
            }
          />
        </IconContainer>
      </td>
      <TableMenu items={menuItems} />
    </SubRow>
  )
}