import { Icon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { Table } from 'components'
import { UserDocumentCategoryForSettings } from './types'

interface UserDocumentCategorySettingsTableProps {
  editableCategories: UserDocumentCategoryForSettings[]

  onEditClick?: (category: UserDocumentCategoryForSettings) => void
  onDelete: (category: UserDocumentCategoryForSettings) => void
}

export const UserDocumentCategorySettingsTable: React.VFC<UserDocumentCategorySettingsTableProps> =
  ({
    editableCategories,
    onEditClick,
    onDelete,
  }) => {
    const translations = useTranslate({
      name: 'common.name',
      responsibleUser: 'common.responsible-user',
      choose: 'common.choose-ellipsis',

      staticCategories: 'settings.static-categories',
      staticCategoriesInfo: 'settings.info.issue-categories-uneditable',
    })

    function handleEditClick(category: UserDocumentCategoryForSettings) {
      onEditClick?.(category)
    }

    return (
      <Table
        noShadow
        noBorders
        noHeaderMargin
        noRowMargin
        rowHoverColor="secondary"
      >
        <thead>
          <tr>
            <th>{translations.name}</th>
            <th></th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {editableCategories.map(category => (
            <tr key={category.id}>

              <td>{category.name}</td>

              <td width="1px">
                <Icon
                  icon="edit"
                  cursor="pointer"
                  color="gray6"
                  hoverColor="secondary"
                  onClick={() => handleEditClick(category)}
                />
              </td>

              <td width="1px">
                <Icon
                  icon="times"
                  cursor="pointer"
                  color="gray6"
                  hoverColor="red"
                  onClick={() => onDelete(category)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }
