import { Link } from 'react-router-dom'
import { OnlyRequired } from 'types/util'
import { useProfileAccess } from 'util/hooks/usePermissions'
import { ShallowUserNode } from '..'
import { UserNode } from '../types.graphql'

interface UserProfileLinkProps {
  user: OnlyRequired<UserNode, 'id' | 'fullName'> | ShallowUserNode
  userNameTransform?: (user: ShallowUserNode) => string
}

export const UserProfileLink: React.VFC<UserProfileLinkProps> = ({
  user,
  userNameTransform,
}) => {
  const canAccessProfile = useProfileAccess(user)
  const label = !!userNameTransform
    ? userNameTransform(user as ShallowUserNode)
    : user.fullName
  return canAccessProfile ? (
    <Link to={`/users/${user.id}`}>{label}</Link>
  ) : (
    <span>{label}</span>
  )
}
