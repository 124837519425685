import { KeyValue } from 'types/util'
import { PERMISSIONS } from 'util/permissions'
import { PermissionDomain } from './types'

export function createPermissionDomains(
  translations: KeyValue<string>
): PermissionDomain[] {
  const { view, add, change, remove, viewChange, viewOthers, approve } = translations

  return [
    {
      title: translations.company,
      permissions: {
        [view]: ['companies.view_company'],
        [translations.exports]: [PERMISSIONS.companies.export.exportReports],
      },
    },
    {
      title: translations.users,
      permissions: {
        [view]: ['users.view_user'],
        [add]: ['users.add_user'],
        [change]: ['users.change_user'],
        [remove]: ['users.delete_user'],
      },
    },
    {
      title: translations.documents,
      permissions: {
        [view]: [PERMISSIONS.users.view.userdocument],
        [add]: [PERMISSIONS.users.add.userdocument],
        [change]: [PERMISSIONS.users.change.userdocument],
        [remove]: [PERMISSIONS.users.delete.userdocument],
      },
    },
    {
      title: translations.forum,
      permissions: {
        [translations.forumSendSms]: ['forum.send_thread_sms'],
        [translations.forumSendEmail]: ['forum.send_thread_email'],
        [translations.forumChangeSticky]: ['forum.change_sticky'],
      }
    },
    {
      title: translations.forumThreads,
      permissions: {
        [view]: [
          PERMISSIONS.forum.view.thread,
          PERMISSIONS.forum.view.threadattachment
        ],
        [add]: [
          PERMISSIONS.forum.add.thread,
          PERMISSIONS.forum.add.threadattachment,
        ],
        [change]: [
          PERMISSIONS.forum.change.thread,
          PERMISSIONS.forum.change.threadattachment,
          PERMISSIONS.forum.delete.thread,
        ],
      },
    },
    {
      title: translations.forumPosts,
      permissions: {
        [view]: [
          PERMISSIONS.forum.view.post,
          PERMISSIONS.forum.view.postattachment,
        ],
        [add]: [
          PERMISSIONS.forum.add.post,
          PERMISSIONS.forum.add.postattachment,
        ],
        [change]: [
          PERMISSIONS.forum.change.post,
          PERMISSIONS.forum.change.postattachment,

          PERMISSIONS.forum.delete.post,
          PERMISSIONS.forum.delete.postattachment,
        ],
      },
    },
    {
      title: translations.driverCard,
      permissions: {
        [view]: [PERMISSIONS.users.view.driver_card],
        [add]: [PERMISSIONS.users.add.driver_card],
      },
    },
    {
      title: translations.driverdataSelf,
      permissions: {
        [view]: [PERMISSIONS.activities.view.driveractivity],
        [approve]: [PERMISSIONS.activities.approve.driveractivity]
      },
    },
    {
      title: translations.driverdataAll,
      permissions: {
        [viewChange]: [
          PERMISSIONS.activities.change.driveractivity,
        ],
        [approve]: [PERMISSIONS.activities.approve.othersdriveractivity]
      },
    },
    {
      title: translations.terminal,
      permissions: {
        [view]: ['terminal.view_terminal', 'terminal.view_terminalentry'],
        [add]: ['terminal.add_terminalentry'],
        [change]: ['terminal.change_terminalentry'],
        [remove]: ['terminal.delete_terminalentry'],
      },
    },
    {
      title: translations.infringements,
      permissions: {
        [view]: [PERMISSIONS.infringements.view.infringement],
        [add]: [PERMISSIONS.infringements.add.infringement],
        [change]: [PERMISSIONS.infringements.change.infringement],
        [remove]: [PERMISSIONS.infringements.delete.infringement],
      },
    },
    {
      title: translations.issues,
      permissions: {
        [view]: [PERMISSIONS.issues.view.issue],
        [add]: [PERMISSIONS.issues.add.issue],
        [change]: [PERMISSIONS.issues.change.issue],
        [remove]: [PERMISSIONS.issues.delete.issue],
      },
    },
    {
      title: translations.timesheets,
      permissions: {
        [view]: ['timesheets.view_timeentry'],
        [add]: ['timesheets.add_timeentry'],
        [change]: ['timesheets.change_timeentry'],
        [remove]: ['timesheets.delete_timeentry'],
      },
    },
    {
      title: translations.absence,
      permissions: {
        [view]: ['timesheets.view_absence'],
        [add]: ['timesheets.add_absence'],
        [change]: ['timesheets.change_absence'],
        [remove]: ['timesheets.delete_absence'],
      },
    },
    {
      title: translations.handbook,
      permissions: {
        [view]: [
          PERMISSIONS.handbook.view.manualfolder,
          PERMISSIONS.handbook.view.manualentry,
          PERMISSIONS.handbook.view.manualentryattachment,
        ],
        [add]: [
          PERMISSIONS.handbook.add.manualfolder,
          PERMISSIONS.handbook.add.manualentry,
          PERMISSIONS.handbook.add.manualentryattachment,
        ],
        [change]: [
          PERMISSIONS.handbook.change.manualfolder,
          PERMISSIONS.handbook.change.manualentry,
          PERMISSIONS.handbook.change.manualentryattachment,
        ],
      },
    },
    {
      title: translations.formcreator,
      permissions: {
        [view]: [
          PERMISSIONS.formcreator.view.formtemplate,
        ],
        [add]: [
          PERMISSIONS.formcreator.add.filledoutform,
          PERMISSIONS.formcreator.add.formtemplate,
        ],
        [change]: [
          PERMISSIONS.formcreator.change.formtemplate,
        ],
        [remove]: [
          PERMISSIONS.formcreator.delete.filledoutform,
          PERMISSIONS.formcreator.delete.formtemplate,
        ],
      },
    },
    {
      title: translations.formResponses,
      permissions: {
        [view]: [
          PERMISSIONS.formcreator.view.filledoutform,
        ],
        [viewOthers]: [
          PERMISSIONS.formcreator.change.filledoutform,
        ],
      },
    },
    {
      title: translations.vehicles,
      permissions: {
        [view]: [
          PERMISSIONS.vehicles.view.vehicle,
          PERMISSIONS.vehicles.view.vehicledocument,
          PERMISSIONS.vehicles.view.vehicleexpiration,
          PERMISSIONS.vehicles.view.vehiclefetchinfo,
        ],
        [add]: [
          PERMISSIONS.vehicles.add.vehicle,
          PERMISSIONS.vehicles.add.vehicledocument,
          PERMISSIONS.vehicles.add.vehicleexpiration,
          PERMISSIONS.vehicles.add.vehiclefetchinfo,
        ],
        [change]: [
          PERMISSIONS.vehicles.change.vehicle,
          PERMISSIONS.vehicles.change.vehicledocument,
          PERMISSIONS.vehicles.change.vehicleexpiration,
          PERMISSIONS.vehicles.change.vehiclefetchinfo,
        ],
        [remove]: [
          PERMISSIONS.vehicles.delete.vehicle,
          PERMISSIONS.vehicles.delete.vehicledocument,
          PERMISSIONS.vehicles.delete.vehicleexpiration,
          PERMISSIONS.vehicles.delete.vehiclefetchinfo,
        ],
      },
    },
    {
      title: translations.routePlanner,
      permissions: {
        [view]: [
          PERMISSIONS.routePlanner.view.routePlan,
          PERMISSIONS.routePlanner.view.routePlanPoint,
          PERMISSIONS.routePlanner.view.routePlanInstruction,
          PERMISSIONS.routePlanner.view.routePlanNotice,
          PERMISSIONS.routePlanner.view.routePlanNoticeDetail,
          PERMISSIONS.routePlanner.view.routeRestriction,
          PERMISSIONS.routePlanner.view.routeWeightRestriction,
        ],
        [add]: [
          PERMISSIONS.routePlanner.add.routePlan,
          PERMISSIONS.routePlanner.add.routeRestriction,
          PERMISSIONS.routePlanner.add.routeWeightRestriction,
        ],
        [change]: [PERMISSIONS.routePlanner.change.routePlan],
        [remove]: [PERMISSIONS.routePlanner.delete.routePlan],
      },
    },
    {
      title: translations.companySettings,
      permissions: {
        [view]: [PERMISSIONS.companies.view.companysetting],
        [change]: [PERMISSIONS.companies.change.companysetting],
        [translations.general]: ['companies.change_company_general_settings'],
        [translations.absence]: [
          'timesheets.view_absencetype',
          'timesheets.add_absencetype',
        ],
        [translations.issues]: [
          'issues.add_issuecategory',
          'issues.change_issuecategory',
          'issues.delete_issuecategory',
        ],
        [translations.sms]: ['companies.change_company_sms_settings'],
        [translations.terminal]: [
          'terminal.view_terminal',
          'terminal.change_terminal',
        ],
        [translations.userTypes]: [
          'users.view_usertype',
          'users.add_usertype',
          'users.change_usertype',
          'users.delete_usertype',
        ],
        [translations.userDocuments]: [
          'users.view_userdocument',
          'users.add_userdocument',
          'users.change_userdocument',
          'users.delete_userdocument',
        ],
        [translations.dropdowns]: [
          PERMISSIONS.users.add.dropdown,
          PERMISSIONS.users.change.dropdown,
        ],
        [translations.tableSettings]: [
          'dynamic_tables.view_dynamiccolumn',
          'dynamic_tables.change_dynamiccolumn',
        ],
      },
    },
  ]
}
