import { Icon, Loader } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Button, Checkbox, Input, TextArea, Toggle, UnsavedChanges } from 'components'
import { Label } from 'components/Label'
import uniqueId from 'lodash/uniqueId'
import React, { useLayoutEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import styled, { css } from 'styled-components'
import { useConfirm, useNavigateConfirm } from 'util/hooks'
import { CreateFormField, EditFormForm, FormField } from '..'
import { Field, FormCard } from '../components'
import { useFormTemplateMutations } from '../mutations.hooks'
import {
  CreateFormTemplateInput,
  PatchFormTemplateInput,
} from '../types.graphql'

const Wrapper = styled.div`
  ${props => props.theme.layout.default};

  display: flex;
  flex-direction: column;
  width: clamp(600px, 60vw, 90%);

  header {
    display: flex;
    justify-content: space-between;

    h1 {
      display: flex;

      span.loader {
        margin-left: 0.5ch;
      }
    }
    div.buttons {
      display: flex;

      button + button {
        margin-left: 0.5rem;
      }
    }
  }

  ${props => props.theme.media.mobile} {
    width: 100%;
  }
`
const DeleteButton = styled(Button)`
  background-color: ${props => props.theme.colors.red};

  ${props => props.theme.media.mobile} {
    width: 3.875rem;
    padding: 0;
  }
`
const SaveButton = styled(Button)`
  ${props => props.theme.media.mobile} {
    width: 3.875rem;
    padding: 0;
  }
`
const AddFieldButton = styled(Button)`
  align-self: center;
  margin-top: 2rem;
  margin-bottom: 6rem;

  i {
    margin-left: 1rem;
  }
`
interface NameFieldProps {
  editing: boolean
}
const NameField = styled(Field)<NameFieldProps>`
  display: grid;
  grid-template-columns: ${props => (props.editing ? '1fr auto' : '1fr')};
  gap: 1rem;

  ${props => props.theme.media.mobile} {
    ${props =>
      props.editing &&
      css`
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;

        & > div.archive {
          justify-self: start;
        }
      `};
  }
`

interface CreateFormProps {
  initialData?: EditFormForm & { id: string }
}

export const CreateForm: React.VFC<CreateFormProps> = ({ initialData }) => {
  const translations = useTranslate({
    createForm: 'form.create-form-alt',
    editForm: 'form.edit-form',

    required: 'common.required',
    name: 'common.name',
    ingress: 'form.label-ingress',
    menuShortcut: 'form.menu-shortcut',
    archived: 'common.archived',
    notArchived: 'common.not-archived',

    addField: 'form.add-field',
    delete: 'common.delete-alt',
    save: 'common.save',

    prompt: {
      deleteForm: 'form.prompts.delete-form',
      deleteFormTitle: 'form.prompts.delete-form-title',

      unsavedChanges: 'common.you-have-unsaved-changes',
      unsavedChangesNavigate: 'common.you-have-unsaved-changes-navigate',
    },
  })

  useLayoutEffect(() => {
    firstMount.current = false
  }, [])

  const firstMount = useRef(true)

  const editing = typeof initialData !== 'undefined'
  const confirm = useConfirm()

  const [deletedFields, setDeletedFields] = useState<string[]>([])

  const validateFields = (fields: FormField[]) =>
    fields.length > 0 && fields.every(({ name }) => name !== '') ? null : 'oops'

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formEdited,
    formChangeHandler: handler,
    updateForm,
    submitHandler: submit,
  } = useForm<EditFormForm>({
    values: {
      name: initialData?.name ?? '',
      ingress: initialData?.ingress ?? '',
      archived: initialData?.archived ?? false,
      fields: initialData?.fields ?? [],
      menuShortcut: initialData?.menuShortcut ?? false,
    },
    validators: {
      name: val => (!!val ? null : translations.required),
      fields: validateFields,
    },
    config: {
      initAsInvalid: true,
    },
  })

  const mutations = useFormTemplateMutations()

  useNavigateConfirm(formEdited, translations.prompt.unsavedChangesNavigate)

  function handleAddField() {
    updateForm({
      fields: [
        ...form.fields,
        {
          id: uniqueId('field'),
          type: 'TEXT',

          name: '',
          description: '',
          required: false,

          minimum: 0,
          maximum: 10,
          minimumEnabled: false,
          maximumEnabled: false,
        },
      ],
    })
  }
  function handleRemoveField(id: string) {
    return () => {
      if (!id.startsWith('field')) setDeletedFields(v => [...v, id])

      updateForm({
        fields: form.fields.filter(field => field.id !== id),
      })
    }
  }
  function handleChangeField(id: string) {
    const fieldIndex = form.fields.findIndex(field => field.id === id)

    return <K extends keyof FormField>(prop: K) =>
      (value: FormField[K]) => {
        if (fieldIndex < 0) return
        const fields = [...form.fields]
        const field = fields[fieldIndex]

        if (
          (prop === 'minimum' || prop === 'maximum') &&
          typeof value === 'number'
        ) {
          field.minimum = Math.min(value, field.minimum)
          field.maximum = Math.max(value, field.maximum)
        }

        fields.splice(fieldIndex, 1, {
          ...field,
          [prop]: value,
        })
        updateForm({
          fields,
        })
      }
  }
  function handleShiftField(id: string) {
    const fieldIndex = form.fields.findIndex(field => field.id === id)

    return (direction: 'up' | 'down') => {
      if (
        (fieldIndex === 0 && direction === 'up') ||
        (fieldIndex === form.fields.length - 1 && direction === 'down')
      )
        return

      const fields = [...form.fields]

      const otherIndex = fieldIndex + (direction === 'up' ? -1 : 1)
      ;[fields[fieldIndex], fields[otherIndex]] = [
        fields[otherIndex],
        fields[fieldIndex],
      ]

      updateForm({
        fields,
      })
    }
  }

  function handleSubmit(values: EditFormForm) {
    const transformFormField = (field: FormField, idx: number) => ({
      fieldType: field.type,
      name: field.name,
      description: field.description,

      order: idx,
      required: field.required,
      minimum:
        field.type === 'NUMBER' && field.minimumEnabled ? field.minimum : null,
      maximum:
        field.type === 'NUMBER' && field.maximumEnabled ? field.maximum : null,
    })

    if (!editing) {
      const input: CreateFormTemplateInput = {
        name: values.name,
        ingress: values.ingress,
        formFieldsAdd: values.fields.map(transformFormField),
        menuShortcut: values.menuShortcut,
      }

      mutations.create({
        variables: { input },
      })
    } else {
      const { formFieldsAdd, formFieldsUpdate } = values.fields.reduce(
        (acc, field, idx) => {
          if (field.id.startsWith('field'))
            acc.formFieldsAdd.push(transformFormField(field, idx))
          else
            acc.formFieldsUpdate.push({
              id: field.id,
              ...transformFormField(field, idx),
            })
          return acc
        },
        {
          formFieldsAdd: [] as Exclude<
            PatchFormTemplateInput['formFieldsAdd'],
            undefined
          >,
          formFieldsUpdate: [] as Exclude<
            PatchFormTemplateInput['formFieldsUpdate'],
            undefined
          >,
        }
      )

      const input: PatchFormTemplateInput = {
        name: values.name,
        ingress: values.ingress,
        menuShortcut: values.menuShortcut,
        archivedAt:
          values.archived && !initialData?.archived ? new Date() : null,
        formFieldsAdd,
        formFieldsUpdate,
        formFieldsDelete: deletedFields,
      }

      mutations.patch({
        variables: {
          id: initialData?.id ?? '',
          input,
        },
      })
    }
  }
  async function handleDelete() {
    const { data: answer } = await confirm(
      translations.prompt.deleteForm,
      translations.prompt.deleteFormTitle
    )
    if (!answer || !initialData) return

    mutations.delete({
      variables: { id: initialData.id },
    })
  }

  const isLoading = mutations.loading

  return (
    <Wrapper>
      <header>
        <h1>
          {editing ? translations.editForm : translations.createForm}

          {isLoading && (
            <span className="loader">
              <Loader.Spinner size={32} />
            </span>
          )}
        </h1>

        <div className="buttons">
          {editing && (
            <DeleteButton disabled={isLoading} onClick={handleDelete}>
              {!isMobile ? (
                translations.delete
              ) : (
                <Icon icon="times" size="1.8rem" />
              )}
            </DeleteButton>
          )}

          <SaveButton
            disabled={!formValid || !formEdited || isLoading}
            onClick={submit(handleSubmit)}
          >
            {!isMobile ? translations.save : <Icon icon="save" size="1.8rem" />}
          </SaveButton>
        </div>
      </header>

      <FormCard>
        <NameField editing={editing}>
          <div>
            <Label>{translations.name}</Label>
            <Input
              value={form.name}
              error={errors.name}
              autoFocus
              fullWidth
              onChange={handler('name')}
            />
          </div>

          {editing && (
            <div className="archive">
              <Label>
                {form.archived
                  ? translations.archived
                  : translations.notArchived}
              </Label>
              <Toggle
                value={form.archived}
                offIconProps={{
                  icon: 'book-open',
                }}
                onIconProps={{
                  icon: 'box-open',
                }}
                onChange={handler('archived')}
              />
            </div>
          )}
        </NameField>

        <Field>
          <Label>{translations.ingress}</Label>
          <TextArea
            value={form.ingress}
            fullWidth
            onChange={handler('ingress')}
          />
        </Field>
        <Field>
          <Label>{translations.menuShortcut}</Label>
          <Checkbox checked={form.menuShortcut} onChange={() => updateForm({menuShortcut: !form.menuShortcut})}/>
        </Field>
      </FormCard>

      {form.fields.map((field, idx) => (
        <CreateFormField
          key={field.id}
          field={field}
          isFirst={idx === 0}
          isLast={idx === form.fields.length - 1}
          autoFocus={!editing || !firstMount.current}
          onShift={handleShiftField(field.id)}
          onChange={handleChangeField(field.id)}
          onRemove={handleRemoveField(field.id)}
        />
      ))}

      <AddFieldButton
        iconRightProps={{ icon: 'plus' }}
        onClick={handleAddField}
      >
        {translations.addField}
      </AddFieldButton>

      <UnsavedChanges
        show={!isLoading && formEdited}
        message={translations.prompt.unsavedChanges}
        onSave={submit(handleSubmit)}
      />
    </Wrapper>
  )
}
