import { PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Input, RegularModal } from 'components'
import { DetectBlur } from 'containers/util-containers'
import { TimeEntryProjectNode } from 'modules/timesheets'
import { Field, Form } from '..'

export interface TimeEntryProjectForm {
  name: string
}

interface TimeEntryProjectPromptProps {
  initialData?: TimeEntryProjectForm
  existingTimeEntryProjects: Pick<TimeEntryProjectNode, 'name' | 'id'>[]
  onSubmit: PromptResolve<TimeEntryProjectForm | null>
}

export const TimeEntryProjectPrompt: React.VFC<TimeEntryProjectPromptProps> = ({
  initialData,
  existingTimeEntryProjects,
  onSubmit,
}) => {
  const translations = useTranslate({
    form: {
      label: {
        name: 'common.name',
      },
    },

    addTimeEntryProject: 'settings.add-time-entry-project',
    editTimeEntryProject: 'settings.edit-time-entry-project',

    create: 'common.create-alt',
    update: 'common.update',

    validation: {
      nameExists: 'settings.validation.time-entry-project-name-exists',
      required: 'common.required',
    },
  })

  const editing = typeof initialData !== 'undefined'

  function validateName(name: string, formName: string) {
    if (name === initialData?.name) return null
    if (name === '') return translations.validation.required
    if (
      !!existingTimeEntryProjects.find(project => project.name === formName)
    ) {
      return translations.validation.nameExists
    }
    return null
  }

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formChangeHandler: handler,
    submitHandler: submit,
  } = useForm<TimeEntryProjectForm>({
    values: initialData ?? {
      name: '',
    },
    validators: {
      name: (val, form) => validateName(val, form.name),
    },
  })

  return (
    <DetectBlur active onClickOutside={() => onSubmit(null)}>
      <RegularModal
        title={
          editing
            ? translations.editTimeEntryProject
            : translations.addTimeEntryProject
        }
        submitText={editing ? translations.update : translations.create}
        submitDisabled={!formValid}
        width="400px"
        onCancel={() => onSubmit(null)}
        onSubmit={submit(onSubmit)}
      >
        <Form>
          <Field>
            <label>{translations.form.label.name}</label>
            <Input
              value={form.name}
              error={errors.name}
              autoFocus
              fullWidth
              onChange={handler('name')}
            />
          </Field>
        </Form>
      </RegularModal>
    </DetectBlur>
  )
}
