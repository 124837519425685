import { gql } from '@apollo/client'

export const RESET_PASSWORD_BY_TOKEN_MUTATION = gql`
  mutation ResetPasswordByTokenMutation(
    $newPassword: String!
    $token: String!
  ) {
    resetPasswordByToken(token: $token, newPassword: $newPassword) {
      ok
      loginToken
    }
  }
`

export const CREATE_PASSWORD_BY_TOKEN_MUTATION = gql`
  mutation CreatePasswordByTokenMutation(
    $newPassword: String!
    $token: String!
  ) {
    createPasswordByToken(token: $token, newPassword: $newPassword) {
      ok
      loginToken
    }
  }
`
