import { Card } from 'components/Card'
import { Text } from 'components/Text'
import { parseISO } from 'date-fns'
import React from 'react'
import styled from 'styled-components'
import { format } from 'util/date-fns'
import { IssueNodeForAllIssues } from '../types.graphl'

const IssueCard = styled(Card)`
  margin-bottom: 30px;
  padding: 0;
  border: 0;
  outline: 0;

  border-bottom: 1px solid #ebecec;
`

const TitleRow = styled.header`
  padding: 20px;
  background: #ff9;

  display: grid;
  grid-template-columns: 1fr 1fr;

  ${Text}:first-child {
    font-size: 26px;
    line-height: 1;
    color: #000;
  }
`

export const Title = styled.h4`
  font-size: 26px;
  line-height: 1;
  color: #000;
  margin: 0;
`

const BodyRow = styled.main`
  padding: 15px 20px;

  margin: 0;
  font-size: 24px;
  font-weight: 500;
`

const FooterRow = styled.footer`
  font-size: 22px;
  font-weight: bold;
  border-top: 1px solid #ebecec;
  line-height: 30px;
  padding: 12px 20px;

  display: grid;
  grid-template-columns: auto auto;
`
export interface IssueTvCardProps {
  issue: IssueNodeForAllIssues
}
export const IssueTvCard: React.FC<IssueTvCardProps> = ({ issue }) => {
  const cardData = {
    title: issue.category.name,
    plateNumber: issue.vehiclePlateNumber,
    description: issue.issueDescription,
    timeOfIssue: issue.timeOfIssue,
    submitter: issue.user.fullName,
  }

  return (
    <IssueCard>
      <TitleRow>
        <Title>{cardData.title}</Title>
        <Text
          textAlign="right"
          fontSize="26px"
          fontWeight="bold"
          lineHeight="1"
          color="#000"
        >
          {cardData.plateNumber}
        </Text>
      </TitleRow>
      <BodyRow>{cardData.description}</BodyRow>
      <FooterRow>
        <Text color="black" fontSize="22px" fontWeight="bold">
          <i className="fa fa-calendar"></i>&nbsp;&nbsp;
          {format(parseISO(cardData.timeOfIssue), 'dd.MM.yyyy - HH:mm')}
        </Text>
        <Text color="black" fontSize="22px" fontWeight="bold" textAlign="right">
          <i className="fa fa-user"></i>&nbsp;&nbsp;
          {cardData.submitter}
        </Text>
      </FooterRow>
    </IssueCard>
  )
}
