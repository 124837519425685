import { useTranslate } from '@ur/react-hooks'
import { IssueStatusCell as BaseStatusCell } from 'modules/issues/components/table/components'
import { UserNode } from 'modules/users'
import { UserProfileLink } from 'modules/users/UserProfile/UserProfileLink'
import React from 'react'
import styled, { css } from 'styled-components'
import { format } from 'util/date-fns'
import { AbsenceTypeNode } from '../types.graphql'

interface CellProps {
  narrow?: boolean
}
const Cell = styled.td.attrs<CellProps>(props => ({
  width: props.narrow ? '1px' : undefined,
}))<CellProps>`
  font-weight: 600 !important;
`

/* DateCell */

interface StyledDateCellProps {
  side: 'start' | 'end'
  type: 'timeentry' | 'absence'
}
const StyledDateCell = styled(Cell)<StyledDateCellProps>`
  width: 98px;
  & > div {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      &.time {
        position: relative;
      }
      &.date {
        font-size: 0.8rem;
        font-weight: 500 !important;
        color: ${props => props.theme.colors.gray6};
      }
    }
  }

  & > div::before {
    content: '';
    position: absolute;
    display: block;
    height: 1px;
    bottom: ${props => (props.type === 'timeentry' ? '15px' : '8px')};
    background: ${props => props.theme.colors.gray8};
  }
  ${props =>
    props.side === 'start'
      ? css`
          & > div::before {
            left: 4.5rem;
            right: -1rem;
            ${props => props.theme.media.mobile} {
              left: 5.5rem;
            }
          }
        `
      : css`
          & > div::before {
            left: -1rem;
            right: 4.5rem;
            ${props.theme.media.mobile} {
              right: 5.5rem;
            }
          }
        `}

  ${props =>
    props.side === 'start'
      ? css`
          ${props => props.theme.media.mobile} {
            & > div {
              padding-left: 1rem;
            }
          }
        `
      : css`
          & > div {
            align-items: flex-end;
          }

          ${props => props.theme.media.mobile} {
            & > div {
              padding-right: 1rem;
            }
          }
        `};
`

interface DateCellProps {
  date: Date
  side: 'start' | 'end'
  type: 'timeentry' | 'absence'
}
export const DateCell: React.VFC<DateCellProps> = ({ date, side, type }) => {
  const timeString = format(date, 'HH:mm')
  const dateString = format(date, 'd. MMM').toLowerCase()

  return (
    <StyledDateCell narrow side={side} type={type}>
      <div>
        <span className="time">
          {type === 'timeentry' ? timeString : dateString}
        </span>
        {type === 'timeentry' && <span className="date">{dateString}</span>}
      </div>
    </StyledDateCell>
  )
}

/* TimeCell */

interface StyledTimeCellProps {
  grayed: boolean
}
const StyledTimeCell = styled(Cell)<StyledTimeCellProps>`
  color: ${props => props.grayed && props.theme.colors.gray6};
  font-weight: ${props => props.grayed && '500 !important'};
`

interface TimeCellProps {
  time: string
}
export const TimeCell: React.VFC<TimeCellProps> = ({ time }) => (
  <StyledTimeCell grayed={time === '00:00' || time === '-'}>
    {time}
  </StyledTimeCell>
)

/* AbsenceType */

interface StyledAbsenceTypeCellProps {
  isSelected: boolean
}
export const StyledAbsenceTypeCell = styled(Cell)<StyledAbsenceTypeCellProps>`
  & > div {
    cursor: pointer;

    &:hover {
      color: ${props =>
        props.isSelected
          ? props.theme.colors.red
          : props.theme.colors.secondary};
    }
  }
`

interface AbsenceTypeProps {
  absenceType?: Pick<AbsenceTypeNode, 'id' | 'name'>
  isSelected: boolean
  onSelect: (absenceType: Pick<AbsenceTypeNode, 'id' | 'name'> | null) => void
}
export const AbsenceTypeCell: React.VFC<AbsenceTypeProps> = ({
  absenceType,
  isSelected,
  onSelect,
}) => (
  <StyledAbsenceTypeCell narrow isSelected={isSelected}>
    {!!absenceType && (
      <div
        role="button"
        onClick={() => onSelect(isSelected ? null : absenceType)}
      >
        {absenceType.name}
      </div>
    )}
  </StyledAbsenceTypeCell>
)

/* AbsenceTypeHeader */

interface StyledAbsenceTypeHeaderProps {
  selected: boolean
}
const StyledAbsenceTypeHeader = styled.th<StyledAbsenceTypeHeaderProps>`
  ${props =>
    props.selected &&
    css`
      padding-top: 0.2rem !important;

      div {
        display: flex;
        flex-direction: column;

        span {
          display: block;
          height: 1rem;

          font-size: 0.7rem;
          color: ${props => props.theme.colors.gray5};
          cursor: pointer;

          &:hover {
            color: ${props => props.theme.colors.red};
          }
        }
      }
    `};
`

interface AbsenceTypeHeaderProps {
  absenceType: Pick<AbsenceTypeNode, 'id' | 'name'> | null
  text: string
  onRemove: () => void
}
export const AbsenceTypeHeader: React.FC<AbsenceTypeHeaderProps> = ({
  absenceType,
  text,
  onRemove,
}) => (
  <StyledAbsenceTypeHeader selected={!!absenceType}>
    {!!absenceType ? (
      <div>
        <span onClick={() => onRemove()}>{text}</span>
        {absenceType.name}
      </div>
    ) : (
      text
    )}
  </StyledAbsenceTypeHeader>
)
/* Absence Status */

const StatusCell = styled(BaseStatusCell)`
  padding-right: 1rem;
`
interface AbsenceStatusProps {
  requestStatus: string | undefined
}
export const AbsenceStatusCell: React.VFC<AbsenceStatusProps> = ({
  requestStatus,
}) => {
  const translations = useTranslate({
    approved: 'timesheets.absence-approved',
    pending: 'timesheets.absence-pending',
    rejected: 'timesheets.absence-rejected',
  })
  if (!requestStatus) return <Cell narrow>-</Cell>
  const pending = requestStatus === 'PENDING'
  const approved = requestStatus === 'APPROVED'
  return (
    <StatusCell
      closed={approved}
      archived={pending}
      openText={translations.rejected}
      archivedText={translations.pending}
      closedText={translations.approved}
    />
  )
}

/* EmployeeCell */
interface EmployeeCellProps {
  user: Pick<UserNode, 'id' | 'fullName'>
}
const StyledEmployeeCell = styled(Cell)`
  div {
    font-style: normal;
    font-weight: 600;
  }
  a.phone {
    font-size: 0.8em;
    color: ${props => props.theme.colors.gray6};

    &:hover {
      text-decoration: none;
      color: ${props => props.theme.colors.secondary};
    }
  }
`

export const EmployeeCell: React.VFC<EmployeeCellProps> = ({ user }) => {
  return (
    <StyledEmployeeCell narrow>
      <UserProfileLink user={user} />
    </StyledEmployeeCell>
  )
}
