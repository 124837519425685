import { useToast } from '@ur/react-components'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router'
import { IdVariable } from 'types/graphql'
import { useOnErrorAuto } from 'util/hooks'
import {
  DELETE_POST_ATTACHMENT_MUTATION,
  DELETE_POST_MUTATION,
  DELETE_THREAD_ATTACHMENT_MUTATION,
  DELETE_THREAD_MUTATION,
  PATCH_POST_MUTATION,
  PATCH_THREAD_MUTATION,
} from '../../mutations'
import {
  DeletePostAttachmentMutation,
  DeletePostMutation,
  DeleteThreadAttachmentMutation,
  DeleteThreadMutation,
  PatchPostMutation,
  PatchPostMutationVariables,
  PatchThreadMutation,
  PatchThreadMutationVariables,
} from '../../types.graphql'

interface Translations {
  error: {
    deletePost: string
    deleteThread: string
  }
}

export function usePostCardMutations(translations: Translations) {
  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()
  const history = useHistory()

  const [patchThread, { loading: patchThreadLoading }] = useMutation<
    PatchThreadMutation,
    PatchThreadMutationVariables
  >(PATCH_THREAD_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['ForumThreads', 'ThreadWithPosts'],
  })
  const [patchPost, { loading: patchPostLoading }] = useMutation<
    PatchPostMutation,
    PatchPostMutationVariables
  >(PATCH_POST_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['ThreadWithPosts'],
    onError: onErrorAuto(),
  })

  const [deleteThread, { loading: deleteThreadLoading }] = useMutation<
    DeleteThreadMutation,
    IdVariable
  >(DELETE_THREAD_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['ForumThreads'],
    onCompleted(data) {
      if (data.deleteThread.found) history.push('/forum')
      else addToast('error', translations.error.deleteThread)
    },
    onError: onErrorAuto(translations.error.deleteThread),
  })
  const [deletePost, { loading: deletePostLoading }] = useMutation<
    DeletePostMutation,
    IdVariable
  >(DELETE_POST_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['ForumThreads', 'ThreadWithPosts'],
    onCompleted(data) {
      if (!data.deletePost.found)
        addToast('error', translations.error.deletePost)
    },
    onError: onErrorAuto(translations.error.deletePost),
  })

  const [deleteThreadAttachment, { loading: deleteThreadAttachmentLoading }] =
    useMutation<DeleteThreadAttachmentMutation, IdVariable>(
      DELETE_THREAD_ATTACHMENT_MUTATION,
      {
        awaitRefetchQueries: true,
        refetchQueries: ['ThreadWithPosts'],
        onError: onErrorAuto(),
      }
    )
  const [deletePostAttachment, { loading: deletePostAttachmentLoading }] =
    useMutation<DeletePostAttachmentMutation, IdVariable>(
      DELETE_POST_ATTACHMENT_MUTATION,
      {
        awaitRefetchQueries: true,
        refetchQueries: ['ThreadWithPosts'],
        onError: onErrorAuto(),
      }
    )

  return {
    patchThread,
    patchPost,
    deleteThread,
    deletePost,
    deleteThreadAttachment,
    deletePostAttachment,
    loading:
      patchThreadLoading ||
      patchPostLoading ||
      deleteThreadLoading ||
      deletePostLoading ||
      deleteThreadAttachmentLoading ||
      deletePostAttachmentLoading,
  }
}
